

import {
    Button, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, makeStyles, Slide
} from '@material-ui/core';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import PieChart from '../../../highcharts/PieChart';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const options = {
    maintainAspectRatio: true
};

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            height: '450px',
            width: '600px'
        }
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            width: '600px'
        }
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000'
    },

}))

const BrandformDialog = (props) => {
    let { open, handleClose } = props
    const classes = useStyles()

    const pieChartData1 = {
        "labels": props.allData?(props.allData[props.brand]?.map(i=> i.brandform) || []):[],
        "datasets": [
            {
                "data": props.allData?(props.allData[props.brand]?.map(i=> i.ratio) || []):[],
                "label": ""
            }
        ]
    }

    return (<>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.dialog}
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#047dd8" }}>
                Brandform Mix - {props.brand}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component="div" id="alert-dialog-slide-description" className={classes.para}>
                    <PieChart page={'props.page'} data={pieChartData1} chartData={{}}
                        dataUrl={null} styles={{}} legendOpts={null}
                        options={options} queryString={null} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <React.Fragment>
                    <Button
                        style={{ textTransform: 'none' }}
                        color="primary" onClick={handleClose}
                        className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {"Close"}
                    </Button>
                </React.Fragment>
            </DialogActions>
        </Dialog>
    </>)
}

export default hot(withRouter(BrandformDialog));
