import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Grid, Paper, FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    tableContainer: {
        maxWidth: "95vw",
        borderRadius: "8px",
        overflowX: "scroll !important",
        '@media(max-width:1366px)': {
            height: 'calc(100vh - 302px)',
        },
        '@media(min-width:1367px)': {
            marginBottom: "15px",
            height: 'calc(100vh - 316px)',
        },
        scrollbarWidth: 'thin',
        height: 'calc(100vh - 316px)',
        '&::-webkit-scrollbar': {
            width: '0.8em',
            height: '0.8em'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cdcdcd',
            outline: '1px solid slategrey',
        },
    },
    table: {
        minWidth: 700,
        paddingRight: 7,
    },
    tableHead: {
        "& th": {
            textAlignLast: "center",
        },
        "& th:not(.adjustBorder)": {
            borderRight: "1px solid rgba(224, 224, 224, 1)"
        },
        "& th.adjustBorder": {
            paddingRight: "0",
            "& span": {
                paddingRight: "16px",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                display: "flex",
                height: "40px",
                alignItems: "center",
            }
        },
        '& th.lastYearHeader': {
            "& span": {
                height: "40px",
                display: "flex",
                padding: "0 16px",
                textAlign: 'center',
                alignItems: "center",
            }
        },
        "& span": {
            fontWeight: "bold"
        },
    },
    tableBody: {
        "& td": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            borderBottom: "0",
            cursor: "pointer",
            textAlign: "center",
            "& input": {
                textAlign: "center"
            }
        },
        "& td.noBorder": {
            border: "0"
        },
        "& p": {
            display: "flex"
        }
    },
    tableBodyNoData: {
        margin: "50px",
        display: "flex",
        minHeight: "50vh",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        "& h3": {
            color: "black",
        }
    },
    tableBodyLoading: {
        display: "flex",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "center",
        "& h3": {
            color: "black",
            width: "100%",
            textAlign: "center"
        }
    },
    tableInput: {
        border: "0",
        height: '30px',
        // width: "100%",
        maxWidth: "75px",
        appearance: "none",
        background: "transparent",
        '&:focus': {
            background: "#fff"
        }
    },
    textInput: {
        border: "0",
        height: '30px',
        width: '90px',
        appearance: "none",
        background: "transparent",
    },
    greenFont: {
        "& span": {
            color: "green"
        }
    },
    blackFont: {
        color: "black !important"
    },
    headerMainCon: {
        height: '60px',
        display: 'flex',
        borderRadius: '10px',
        alignItems: 'center',
        marginBottom: '5px',
        // backgroundColor: '#fff',
        maxWidth: '95vw',
        overflow: 'auto',
        justifyContent: 'space-between',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0.8em',
            height: '0.8em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cdcdcd',
            outline: '1px solid slategrey'
        },
    },
    leftHeaderCon: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rightHeaderCon: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    rowSelectedBg: {
        background: "#CEE7FC !important",
        transition: "background-color 0.5s ease",
    },
    disabledBtn: {
        opacity: "0.65",
        pointerEvents: "none"
    },
    disabledBtnCursor: {
        cursor: "not-allowed",
    },
    combinedHeaderHeading: {
        margin: "5px 0px 0px 0px",
        // margin: "0",
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
        borderRadius: "4px",
        padding: "4px 2px 2px",
        background: "rgb(16, 114, 189)",
    },
    combinedHeaderHeadingGrey: {
        margin: "5px 0px 0px 0px",
        // margin: "0",
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
        borderRadius: "4px",
        padding: "4px 2px 2px",
        background: "rgb(127, 127, 127)",
    },
    combinedHeaderSection: {
        '@media(max-width:1366px)': {
            height: '45px',
        },
        display: "flex",
        alignItems: "center",
        "& p": {
            display: "flex",
            textAlign: 'center',
            alignItems: "center",
            justifyContent: "space-around",
            width: "82px",
        },
        "& p:not(:last-of-type)": {
            height: "40px",
            textAlign: 'center',
            paddingRight: "16px",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            margin: '0px'
        },
        "& p:not(:first-of-type)": {
            paddingLeft: "16px",
            textAlign: 'center',
        }
    },
    combinedBodySection: {
        height: "49px",
        '@media(max-width:1366px)': {
            height: "38px",
        },
        '@media(min-width:1367px) and (max-width:1536px)': {
            height: "45px",
        },
        display: "flex",
        alignItems: "center",
        "& p": {
            display: "flex",
            textAlign: 'center',
            alignItems: "center",
            borderRight: "0",
            width: "82px",
            justifyContent: "space-around",
        },
        "& p:not(:last-of-type)": {
            height: "49px",
            '@media(max-width:1366px)': {
                height: "38px",
            },
            '@media(min-width:1367px) and (max-width:1536px)': {
                height: "45px",
            },
            textAlign: 'center',
            paddingRight: "16px",
            paddingTop: "2px",
            paddingBottom: "2px",
            margin: '0px',
            "& input": {
                height: "100%",
            }
        },
        "& p:not(:first-of-type)": {
            paddingLeft: "16px",
            textAlign: 'center',
        }
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            height: '320px',
            width: '600px'
        }
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000'
    },
    donwloadBtn: {
        '& .MuiButton-startIcon': {
            margin: 0
        }
    },
    btnGroup: {
        display: 'flex',
        alignItems: 'center',
        '@media(min-width:1367px)': {
            paddingRight: '30px',
        },
        '& button': {
            maxHeight: '36px',
            '& span': {
                whiteSpace: 'nowrap'
            }
        }
    },
    updateBtnStyle: {
        backgroundColor: '#ff6503 !important'
    }
}));
const Filter = props => {
    const classes = useStyles();
    const [qtr, setQtr] = useState("");
    const [month, setMonth] = useState("");
    const [channel, setChannel] = useState("");
    const [cluster, setCluster] = useState("");
    const [brandForm, setBrandForm] = useState("");
    const { sptprData } = props
    
    return (<div className={classNames(classes.headerMainCon, "filter-container", 'npd')}>
        <div className={classes.leftHeaderCon}>
            <Select
                value={month}
                displayEmpty={true}
                // disabled
                className="custom-select m-right-10"
            // onChange={handleMonth}
            >
                <MenuItem value="">Month</MenuItem>
                <MenuItem value="jan">Jan</MenuItem>
                <MenuItem value="feb">Feb</MenuItem>
                <MenuItem value="mar">Mar</MenuItem>
                <MenuItem value="apr">Apr</MenuItem>
                <MenuItem value="may">May</MenuItem>
                <MenuItem value="jun">Jun</MenuItem>
                <MenuItem value="jul">Jul</MenuItem>
                <MenuItem value="aug">Aug</MenuItem>
                <MenuItem value="sep">Sep</MenuItem>
                <MenuItem value="oct">Oct</MenuItem>
                <MenuItem value="nov">Nov</MenuItem>
                <MenuItem value="dec">Dec</MenuItem>
            </Select>
            <Select
                value={qtr}
                displayEmpty={true}
                className="custom-select m-right-10"
            // onChange={handleQuarter}
            >
                <MenuItem value="">Quarter</MenuItem>
                <MenuItem value="JAS">JAS</MenuItem>
                <MenuItem value="OND">OND</MenuItem>
                <MenuItem value="JFM">JFM</MenuItem>
                <MenuItem value="AMJ">AMJ</MenuItem>
            </Select>
            <Select
                value={brandForm}
                displayEmpty={true}
                className="custom-select m-right-10"
                style={{ minWidth: "150px" }}
            // onChange={handleBrandForm}
            >
                <MenuItem value="">All Brandforms</MenuItem>
                {React.Children.toArray(
                    sptprData.filterData?.brands.map((item, index) =>
                        <MenuItem value={item.BrandFormName} key={index}>{item.BrandFormName}</MenuItem>
                    )
                )}
            </Select>
            <Select
                value={channel}
                displayEmpty={true}
                className="custom-select m-right-10"
                style={{ minWidth: "150px" }}
            // onChange={handleChannel}
            >
                <MenuItem value="">All Channels</MenuItem>
                {React.Children.toArray(
                    sptprData.filterData?.channels.filter(item => item.channel !== 'All Channels')
                        .map(item => <MenuItem value={item.channel}>{item.channel}</MenuItem>)
                )}
            </Select>
            <Select
                value={cluster}
                displayEmpty={true}
                className="custom-select m-right-10"
                style={{ minWidth: "150px" }}
            // onChange={handleCluster}
            >
                <MenuItem value="">All Clusters</MenuItem>
                {React.Children.toArray(
                    sptprData.filterData?.clusters.map(item =>
                        <MenuItem value={item.ClusterName}>{item.ClusterName}</MenuItem>
                    )
                )}
            </Select>

        </div>
    </div>);
}

const mapStateToProps = state => {
	return {
		user: state.user,
		sptprData: state.tprReducer,
		brand: state.tprReducer?.selectBrand,
	};
};

const mapDispatchToProps = {
};

export default hot(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(Filter)),
);