import React from 'react';
import Layout from './Layout';
import PublicRoute from 'routes/PublicRoute';
import { APP_CONFIG } from 'constants/index';

/**
 * Generates dynamic routes
 * Links navigation with Route components
 * @Param navigation {Object} - route details
 * @Param user {Object} - user details
 * @Param componentsData {Object} - current component page data
 */
const defaultRoute = (navigation, user, componentsData, pages) => {
    let route;
    let path = "/(|" + APP_CONFIG.APP_ID + "|)/"
    navigation.links.forEach(link => {
        let page = pages.find(p =>  p.name === link.title && link.default == "true")
        let name = link.title;
        route = <PublicRoute
            key={link.title}
            isAuthenticated={user.isAuthenticated}
            path={path}
            exact
            component={Layout}
            componentsData={componentsData}
            page={page}
            name={name}
        />
    })
    return route;
};

export default defaultRoute;

export const defaultRedirectRoute = (navigation, user, componentsData, pages) => {
    return navigation.links.find(l =>  l.default == "true");
};