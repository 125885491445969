import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "right",
    marginBottom: "10px",
    marginRight: '100px',
    "& > button": {
      margin: theme.spacing(1),
      borderRadius: 0,
      textTransform: "none",
    },
  },
}));

export const ActionButtons = (props) => {
  const { onClickHandler } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button className={`btn btn-color ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} disableElevation={true} onClick={() => onClickHandler('addRow')}>
        New Product
      </Button>

      <Button className={`btn btn-color ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} disableElevation={true} onClick={() => onClickHandler('addGroup')}>
        New Band
      </Button>

      <Button className={`btn btn-color ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} disableElevation={true} onClick={() => onClickHandler('addField')}>
        New Mapping
      </Button>
    </div>
  );
};


export const FormAction = (props) => {
  const { onSubmitData } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button className={`btn btn-color ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} disableElevation={true} onClick={() => onSubmitData()}>
        Save Configuration
      </Button>
      <Button className={`btn btn-color ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} disableElevation={true}>
        Cancel
      </Button>
    </div>
  );
};

export const BasicMappingActionButtons = (props) => {
  const { onClickHandler } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button className={`btn btn-color ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} disableElevation={true} onClick={() => onClickHandler('addNewBKM')}>
        {"New Basic Key Mapping"}
      </Button>
    </div>
  );
};
