import React from 'react';
// import Select from "@material-ui/core/Select";
// import MenuItem from '@material-ui/core/MenuItem';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BootstrapInput } from './BootStrapInput';
import NativeSelect from '@material-ui/core/NativeSelect';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  selectCss: {
    // marginBottom: '10px',
    width: '100%',
    '& svg': {
      fontSize: '20px',
    },
  },
  labelCSS: {
    display:'inline-block',
    color: '#fff',
    marginBottom: '10px',
  },
}));

function DropDown(props) {
  const classes = useStyles();
  const { options, onDropDownSelect, keyName, keyIndex, pIndex, labelName, value } = props;
  // console.log(value, "test");
  console.log('pIndex, keyIndex', pIndex, keyIndex);
  return (
    <div className="main-select">
      <label className={classes.labelCSS}>{labelName}</label>
      <NativeSelect
        // labelid="demo-simple-select-label"
        // id="demo-simple-select"
        input={<BootstrapInput />}
        value={value}
        className={classes.selectCss}
        IconComponent={ExpandMoreIcon}
        name={keyName}
        onChange={e => onDropDownSelect(e, pIndex, keyIndex)}
      >
        <option value="">choose...</option>
        {options.map((val, ind) => {
          return (
            <React.Fragment key={ind}>
              <option value={val.name}>{val.name || val}</option>
            </React.Fragment>
          );
        })}
      </NativeSelect>
    </div>
  );
}

export default DropDown;
