

import { hot } from 'react-hot-loader/root'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
	Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid, Slide,
	Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
	DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const SendReportDialog = (props) => {
	let { sendDocumentOpen, handleSendReportClose, classes } = props
	console.log('sendDocumentOpen ===', sendDocumentOpen);

	return (<>
		<Dialog
			open={sendDocumentOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleSendReportClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#000" }}>
				{"Sending Report to Insurance Company"}</DialogTitle>
			<DialogContent className={classes.dialog}>
				<DialogContentText id="alert-dialog-slide-description">
					<h1 style={{ color: '#000', textAlign: 'center' }}>
						{"Summary Invoice document has"}<br />
						{"been sent to insurance company."}
					</h1>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleSendReportClose}
					className="btn btn-primary btn-color border-radius-0 text-color-white">
					{"Close"}
				</Button>
			</DialogActions>
		</Dialog>
	</>)
}

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(connect(mapStateToProps, null)(SendReportDialog)));