import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

export default class ValueViewerCheckbox extends PureComponent {
	render() {
		const { value, cell, checkboxOnChanges, isChecked } = this.props;
		const { checkbox, _id, checked } = cell

    return <div className='datasheet-checkbox-container'>
      {checkbox && <input type="checkbox"
                          className="cursor-pointer checkbox" checked={isChecked}
                          onChange={(e) => checkboxOnChanges && checkboxOnChanges(e, _id)} />}
      <span className="value-viewer">{value}</span>
    </div>
	}
}

ValueViewerCheckbox.propTypes = {
	row: PropTypes.number.isRequired,
	col: PropTypes.number.isRequired,
	value: PropTypes.node.isRequired,
};
