import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

export const HandleErrors = (props) => {
  let { errorArr, type } = props;

  let message = `${type} not present in master database:`;
  let obj = {};
  if (type === 'Channels') {
    errorArr.map(e => {
      if (obj[e.channel]) {
        let tempArr = obj[e.channel];
        tempArr.push(e.row);
        obj[e.channel] = tempArr;
      } else {
        obj[e.channel] = [e.row];
      }
    });
  } else if (type === 'Brandforms') {
    errorArr.map(e => {
      if (obj[e.brandform]) {
        let tempArr = obj[e.brandform];
        tempArr.push(e.row);
        obj[e.brandform] = tempArr;
      } else {
        obj[e.brandform] = [e.row];
      }
    });
  } else if (type === 'data') {
    errorArr.map(e => {
      errorArr.map(e => {
        if (obj['Data Issues']) {
          obj['Data Issues'].push(e.row);
        } else {
          obj['Data Issues'] = [e.row];
        }
      })
    });
    message = `Data issues found in following rows: ${obj['Data Issues'].join(', ')}`;
  } else if (type === 'lbebop') {
    let rejectedItemsWithlbebopVal = errorArr.map(e => e.row)
    message = `Rejected rows which has LBE BOP values (${rejectedItemsWithlbebopVal.length} nos.):`
    return (<>
      <Typography variant="h6" gutterBottom component="div" style={{fontWeight: 700, marginTop: 15, color: '#000', textAlign: 'center', }}>{message}</Typography>
      <div style={{color: '#000', textAlign: 'center',fontWeight: 700, fontSize: '16px', }}>{rejectedItemsWithlbebopVal.join(', ')}</div>
    </>)
  } else if (type === 'duplicate') {
    let rejectedItemsWithDuplicate = errorArr.map(e => e.row)
    message = `These rows are Duplicate of other rows and are rejected (${rejectedItemsWithDuplicate.length} nos.):`
    return (<>
      <Typography variant="h6" gutterBottom component="div" style={{fontWeight: 700, marginTop: 15, color: '#000', textAlign: 'center', }}>{message}</Typography>
      <div style={{color: '#000', textAlign: 'center',fontWeight: 700, fontSize: '16px', }}>{rejectedItemsWithDuplicate.join(', ')}</div>
    </>)
  } else if (type === 'spendMillion') {
    let rejectedItemsWithspendM = errorArr.map(e => e.row)
    message = `Rejected rows which has Spend $M values (${rejectedItemsWithspendM.length} nos.):`
    return (<>
      <Typography variant="h6" gutterBottom component="div" style={{fontWeight: 700, marginTop: 15, color: '#000', textAlign: 'center', }}>{message}</Typography>
      <div style={{color: '#000', textAlign: 'center', fontWeight: 700, fontSize: '16px', }}>{rejectedItemsWithspendM.join(', ')}</div>
    </>)
  }
  return (<>
    <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '5px 0' }}>
      {message}</h6>
    {type !== 'data' && <Grid container style={{ textAlign: 'center', fontSize: '16px', fontWeight: 700 }}>
      <Grid item xs={6} sm={6}>{type}</Grid>
      <Grid item xs={6} sm={6}>{'Row No.'}</Grid>
      {React.Children.toArray(Object.keys(obj)
        .map(err => {
          return <>
            <Grid item xs={6} sm={6} style={{ fontWeight: 500 }}>{err}</Grid>
            <Grid item xs={6} sm={6} style={{ fontWeight: 500 }}>{obj[err].join(', ')}</Grid>
          </>;
        }))}
    </Grid>}
  </>);
};
