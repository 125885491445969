import { hot } from 'react-hot-loader/root';
import compose from 'recompose/compose';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, CardActions, Button, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import Chart from 'components/charts/Chart';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import history from 'modules/history';

import NotFound from 'routes/NotFound';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Nav from 'components/Nav';
import LayoutBox from 'components/Layout/LayoutBox';
import OrganisationChart from 'components/charts/orgChart/OrganisationChart';
import Loading from 'components/Loading'

import { loadOrg, loadAppconfig } from 'store/actions';

const styles = theme => ({
	root: {
		flexGrow: 1,
		width: '98%',
		margin: '0 auto',
	},
	paperCards: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		minHeight: '20vh',
	},
	paperLayouts: {
		padding: theme.spacing(1, 1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		minHeight: 'calc(100vh - 50vh)',
	},
	card: {},
	cardContent: {
		padding: theme.spacing(0.5, 0.5),
	},
	tableTitle: {
		textAlign: 'left',
		padding: theme.spacing(1, 0.5),
		color: '#000',
	},
});

const colCard = 3;

class OrgChart extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			appId: "",
			route: ""
		};
	}
	
	componentDidMount() {
		console.log("this.props org", this.props);
		
		let appInfo = this.getAppInfo();
		this.setState(appInfo);

		this.props.loadOrg();
		this.props.loadAppconfig(appInfo.appId);
	}

	getAppInfo() {
		let path = this.props.location.pathname.split('/')
		let appId = path[1];
		let route = path[2];
		return {appId, route};
	}
	
	render(){
        const { classes, org } = this.props;
		let { header, footer, navigation } = { ...this.props.app.theme };
		let user = { isAuthenticated: false, name: 'John Wick', role: 'Admin' };
	console.log("this.props uy", this.props, Object.keys(this.props.org).length);
	
		return (
			<React.Fragment>
          	{this.props.app && header && navigation ? (
				<React.Fragment>
					<Header header={header} user={user} />
					<Nav navigation={navigation} appId={this.state.appId}/>
					{/* <LayoutBox page={page} /> */}
					{Object.keys(this.props.org).length > 0 ?
						<OrganisationChart data={this.props.org} />
						: ''}
					<Footer footer={footer} />
				</React.Fragment>
			) : (
				this.props.error && this.props.loading === false
					? <NotFound />
					: <Loading />
			)}
			</React.Fragment>
		);
	}
};

const mapDispatchToProps = {
	loadOrg,
	loadAppconfig
};

const mapStateToProps = state => {
	return {
		org: state.org.org,
		error: state.org.error,
		loading: state.org.loading,
		app: state.appConfig.app,
	};
};

export default hot(compose(
    withStyles(styles, {
        name: 'Org',
    }),
    connect(mapStateToProps, mapDispatchToProps),
)(OrgChart));