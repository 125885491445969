import { hot } from 'react-hot-loader/root'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { Paper, AppBar, Tabs, Tab, makeStyles, withStyles, IconButton, Button, Slide, Snackbar, TableBody, TableCell, TableHead, TableRow, Table, TableContainer, TablePagination, Checkbox } from '@material-ui/core';
import { getMonth } from "utility/utils";
import Header from '../SpendPlan/Header';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { getCurrentFFForPayload } from '../spendplanUtils';

const useStyles = makeStyles(theme => ({
	appBar: {
		color: "#070707",
		boxShadow: "none",
		backgroundColor: "transparent",
		borderBottom: "1px solid #ccc",
		margin: "10px 0 20px 0",
		'& button': {
			maxWidth: "16.666667%",
			flexBasis: "16.666667%"
		},
		'& span': {
			fontSize: '16px !important',
			fontWeight: 'bold'
		}
	},
	tableContainer: {
		width: "95vw",
		minWidth: "1000px",
		borderRadius: "8px",
		marginBottom: "15px"
	},
	table: {
		minWidth: 700,
	},
	tableHead: {
		"& th": {
			textAlignLast: "center",
			padding: "18px 8px !important",
			lineHeight: "1rem !important"
		},
		"& th:not(.adjustBorder)": {
			borderRight: "1px solid rgba(224, 224, 224, 1)"
		},
		"& span": {
			fontWeight: "bold",
		},
	},
	tableBody: {
		"& td": {
			borderRight: "1px solid rgba(224, 224, 224, 1)",
			borderBottom: "0",
			cursor: "pointer",
			textAlign: "center",
			padding: "8px !important",
			lineHeight: "1.43 !important",
			"& span": {
				textAlign: "center",
				fontSize: "14px"
			}
		},
	},
	actionsContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& a": {
			marginRight: "20px",
			color: '#1977a2',
			fontWeight: 'bold',
			textDecoration: 'none',
			'&:hover': {
				color: '#1977a2'
			}
		}
	},
	tableBodyNoData: {
		margin: "50px",
		display: "flex",
		minHeight: "50vh",
		textAlign: "center",
		alignItems: "center",
		justifyContent: "center",
		"& h3": {
			color: "black",
		}
	},
	notification: {
		'& .MuiPaper-root': {
			minWidth: '180px',
			'& svg': {
				fontSize: '16px'
			}
		}
	}
}));

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const ArchiveReport = props => {
    const classes = useStyles()
    const { match, sptprData, type } = props
    const { selectBrand } = sptprData
	const [brand, setBrand] = useState(selectBrand)
	const actions = brand.actions ?? [];
	const [showNotification, setShowNotification] = useState(false);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(actions.length);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [report, setReport] = useState(props.type === 'tpr' ? 'TradePriceReduction' : props.type === 'setupData' ? 'BOPVolumes' : 'talksheet');
	const reportName = props.type === 'tpr' ? 'Trade Price Reduction - Version' : props.type === 'talksheet' ? 'Talk Sheet - Version' : 'Setup Data - Version';
	const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
		if (selectBrand && typeof selectBrand === 'object' && selectBrand !== null && Object.keys(selectBrand).length > 0) {
			setBrand(selectBrand)
			setPage(0)
			setRowsPerPage(10)
			setCount(selectBrand.actions.length)
		}
	}, [selectBrand])

	const TransitionUp = (props) => {
		return <Slide {...props} direction="up" />;
	}

	const StyledTableCell = withStyles((theme) => ({
		head: {
			backgroundColor: theme.palette.common.black,
			color: theme.palette.common.white,
		},
		body: {
			fontSize: 14,
		},
	}))(TableCell);

	const StyledTableRow = withStyles((theme) => ({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	}))(TableRow);

    const buildURL = () => {
        let urls = {
			to: `/apps/${match.params.appid}/${report}?brand=${brand.BrandName}`,
			display: `${window.location.origin}/apps/${match.params.appid}/${report}?brand=${brand.BrandName}`
		};

		if (brand.CurrentFF) {
		  let m = getMonth(brand.CurrentFF, 'monthNumber');
			urls.to += `&month=${encodeURIComponent(getCurrentFFForPayload(m))}`
			urls.display += `&month=${getCurrentFFForPayload(m)}`
		}
		if (brand.FinancialYear) {
			urls.to += `&year=${encodeURIComponent(brand.FinancialYear)}`
			urls.display += `&year=${brand.FinancialYear}`
		}
        return urls
    }

	const copyToClipboard = str => {
		const el = document.createElement('textarea');
		el.value = str;
		el.setAttribute('readonly', '');
		el.style.position = 'absolute';
		el.style.left = '-9999px';
		document.body.appendChild(el);
		const selected =
			document.getSelection().rangeCount > 0
			? document.getSelection().getRangeAt(0)
			: false;
		el.select();
		document.execCommand('copy');
		setShowNotification(true);
		document.body.removeChild(el);
		if (selected) {
			document.getSelection().removeAllRanges();
			document.getSelection().addRange(selected);
		}
	};

	const handleNotificationClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setShowNotification(false);
	};

	const handleChangePage = (event, page) => {
		setPage(page);
	}

	const renderNoDataText = () => {
		let textToShow = "No Data Found";
		return (
			<StyledTableCell colSpan="100%">
				<h4 style={{ color: '#000' }}>{textToShow}</h4>
			</StyledTableCell>
		);
	}

	const handleChangeRowsPerPage = event => {
		setPage(0);
		setRowsPerPage(event.target.value);
	}

	const handleTabChange = (event, newValue) => {
		const tabValues = ['BOPVolumes', 'RLPperMSU', 'PYRetailing', 'PYRLPMSU', 'rlpytdact', 'rlpSaliences'];
		setReport(tabValues[newValue])
		setTabValue(newValue);
	}

	return (
        <>
            <Header header={reportName} />
			{props.type === 'setupData' ? (
				<AppBar position="static" className={classes.appBar}>
					<Tabs value={tabValue} onChange={handleTabChange} aria-label="setup data tabs">
						<Tab label="BOP Volumes" {...a11yProps(0)} />
						<Tab label="RLP per MSU" {...a11yProps(1)} />
						<Tab label="Past Year Retailing" {...a11yProps(2)} />
						<Tab label="Past Year RLP/MSU" {...a11yProps(0)} />
						<Tab label="YTD Actual Retailing" {...a11yProps(1)} />
						<Tab label="Channel Retailing Salience" {...a11yProps(2)} />
					</Tabs>
				</AppBar>
			) : null}
			<TableContainer className={classes.tableContainer} component={Paper}>
				<Table className={classNames(classes.table, "tpr-table")} aria-label="simple table" >
					<TableHead className={classes.tableHead}>
						<TableRow>
							<TableCell><span>Brand</span></TableCell>
							<TableCell><span>FY</span></TableCell>
							<TableCell><span>FF</span></TableCell>
							<TableCell><span>End Date</span></TableCell>
							<TableCell><span>Actions</span></TableCell>
						</TableRow>
					</TableHead>
					<TableBody className={classes.tableBody}>
						{actions && actions.length > 0 ? actions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
							<StyledTableRow key={index}>
								<StyledTableCell>
									<span>{brand.BrandName}</span>
								</StyledTableCell>
								<StyledTableCell>
									<span>{brand.FinancialYear}</span>
								</StyledTableCell>
								<StyledTableCell>
									<span>{getMonth(brand.CurrentFF, 'month')}</span>
								</StyledTableCell>
								<StyledTableCell>
									<span>{moment(row.actionDate).format('DD-MM-YYYY')}</span>
								</StyledTableCell>
								<StyledTableCell>
									<section className={classes.actionsContainer}>
										<Link to={buildURL()['to']} target={"_blank"} className={classes.mainURL}>
											View
										</Link>
										<FileCopyIcon onClick={()=>{
											copyToClipboard(`${window.location.origin}${buildURL()['to']}`)
										}}/>
									</section>
								</StyledTableCell>
							</StyledTableRow>
						)) :
							renderNoDataText()
						}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 12, 20, 50]}
				component="div"
				count={count}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			<Snackbar
				anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
				}}
				open={showNotification}
				autoHideDuration={2000}
				onClose={handleNotificationClose}
				message="Copied!"
				TransitionComponent={TransitionUp}
				className={classes.notification}
				action={
				<React.Fragment>
					<IconButton size="small" aria-label="close" color="inherit" onClick={handleNotificationClose}>
					<CloseIcon fontSize="small" />
					</IconButton>
				</React.Fragment>
				}
			/>
        </>
    )
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
		sptprData: state.tprReducer,
    }
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ArchiveReport)))