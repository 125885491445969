import { Button, IconButton, makeStyles, MenuItem, Paper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getMonth } from 'utility/utils';
import { brandsFreePages } from '..';
import { MRPlanService } from '../../../../../modules/api';
import { brandformFilterSelect, checkSpBGprocesses, getBrandformfilter, getChannelfilter } from '../../../../../store/actions';
import { capitalize, getFilteredChannelList, monthFilterValues } from '../../../../../utility/utils';
import FilterMultiSelect from '../../../../FilterMultiSelect';
import ConfirmDialog from '../../../spendplan/SpendPlan/ConfirmDialog';
import { checkForBGProcesses, getFiscalYearFormat, MonthsNumberMapping, isNotSuperAdmin, uploadFileTypes } from "../../spendplanUtils";
import Upload from '../Upload';
import TalkSheetModal from '../talkSheet/talkSheetModal';

export const RoleToAccessSpecificSetupPages = {
    'SuperAdmin': true,
    'Finance': true,
}

export const customChannelForITT = ['HSM', 'Cash & Carry', 'Ecommerce'];
export const useStyles = makeStyles(theme => ({
    headerBtnGrp: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    updateBtnStyle: {
        backgroundColor: '#ff6503 !important',
        marginRight: '10px',
        width: 175,
    },
    removeReconBtn: {
        backgroundColor: 'red !important',
        marginRight: '10px',
        width: 175,
    },
    setupConfirmationDialog: {
        '& .MuiDialog-paperWidthSm': {
            height: 320,
            width: 600,
        }
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000',
        minWidth: 500,
    },
    leftHeaderCon: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rightHeaderCon: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        '& button': {
            '& span': {
                whiteSpace: 'nowrap',
            },
        },
    },
    headerMainCon: {
        height: '60px',
        display: 'flex',
        borderRadius: '10px',
        alignItems: 'center',
        marginBottom: '20px',
        backgroundColor: '#fff',
        maxWidth: '95vw',
        overflow: 'auto',
        justifyContent: 'space-between',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0.8em',
            height: '0.8em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cdcdcd',
            outline: '1px solid slategrey'
        },
    },
    planFilter: {
        display: 'flex',
        border: '2px solid #ddd',
        borderRadius: 8,
        paddingLeft: 10,
        marginRight: 10,
        '& span': {
            fontWeight: 'bold',
            alignSelf: 'center',
        }
    },
    active: {
        border: '2px solid tomato'
    }
}))

export const pagesWithBrandFormFilter = ['bopvolumes', 'rlppermsu', 'rlpytdact', 'rlpsalience', 'pyrlp', 'pyrlppermsu']
export const pagesWithChannelFilter = ['rlpytdact', 'rlpsalience', 'pyrlp', 'mrecomm', 'itt', 'cts']
export const pagesWithUoMFilter = ['f1inputs', 'corpbud', 'mrecomm', 'accrualshc']
export const pagesWithMetricFilter = ['f1inputs', 'corpbud',]
export const pagesWithPlanTypeFilter = ['mrecomm', 'itt',]
export const pagesWithMonthFilter = ['mrecomm', 'itt', 'corpbud',]
export const pagesWithQtrFilter = ['mrecomm', 'accrualshc', 'itt', 'corpbud',]
export const ChannelFilterWithTableValues = ['itt', 'mrecomm', 'corpbud']; //these pages with channel filter will populate from table rows

const filters = {
    'f1inputs': ['ff', 'metrics', 'uoM'],
    'corpbud': ['metrics', 'uoM', 'channel'],
    'mrecomm': ['planType', 'uoM', 'channel'],
    'accrualshc': ['spendPool', 'spendBucket', 'uoM'],
    'itt': ['planType', 'channel'],
    'tdc': ['brandCategory', 'tsBrandforms'],
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const FilterWorksheet = props => {
    const classes = useStyles();
    const { getBrandformfilter, getChannelfilter, match, tprReducer, handleFilterChange,
        brandformFilterSelect, edited, view } = props;
    const { selectBrand } = tprReducer
    const [brandForm, setBrandForm] = useState([])
    const [channel, setChannel] = useState([])
    const [subChannel, setSubChannel] = useState([])
    const [brand, setBrand] = useState(selectBrand);
    const [anchorEl, setAnchorEl] = useState(null);
    const [allFilters, setAllFilters] = useState({})
    const [confirmState, setConfirm] = useState({
        isOpen: false,
        confirmMessage: ''
    })
    const [successState, setSuccess] = useState({
        isOpen: false,
        message: ''
    })
    const anchorRef = React.useRef(null);
    const [openBtnSection, setBtnSectionOpen] = React.useState(false);

    const [month, setMonth] = useState([])
    const [qtr, setQtr] = useState([])
    const [ff, setFF] = useState([])
    const [metric, setMetrics] = useState([])
    const [uom, setUom] = useState([])
    const [spendPool, setSpendPool] = useState([])
    const [spendBucket, setSpendBucket] = useState([])
    const [planType, setPlanType] = useState([])
    const [brandCategory, setBrandCategory] = useState([])
    const [tsBrandforms, setTsBrandforms] = useState([])

    const [openTalkSheet, setOpenTalkSheet] = useState(false);

    const checkRole = () => {
        return props.member.selectedRoles.find(role => RoleToAccessSpecificSetupPages[role.label])
    }

    const hideActions = (showActionForType = '') => {
        if (['corpbud', 'mrecomm', 'accrualshc', 'f1inputs'].includes(props.type)) {
            return !checkRole()
        } else if (props.type === 'pysdrlp') {
            return showActionForType !== '' ? false : true;
        } else {
            return false;
        }
    }

    const handleBtnSectionToggle = () => {
        setBtnSectionOpen((prevOpen) => !prevOpen);
    };

    const handleBtnSectionClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setBtnSectionOpen(false);
    };

    useEffect(() => {
        if (selectBrand && typeof selectBrand === 'object' && selectBrand !== null && Object.keys(selectBrand).length > 0) {
            setBrand(selectBrand);
        }
    }, [selectBrand])

    useEffect(() => {
        getFilterData()
    }, [brand])

    useEffect(() => {
        // if (brandForm || channel || subChannel)
        //     handleFilterChange({ 'brandform': brandForm, 'channel': channel, 'subChannel': subChannel })
        if (brand) {
            if (!tprReducer.filterData?.brands?.length || selectBrand.BrandName !== tprReducer.filterData?.brands[0]?.Brand) {
                getBrandformfilter({ brandName: selectBrand.BrandName, appid: match.params.appid })
            }
            !tprReducer.filterData?.channels.length && getChannelfilter({
                brandFormName: '',
                formType: '',
                appid: match.params.appid
            })
        }
    }, [selectBrand])

    useEffect(() => {
        if (props.isPopup) {
            let obj = {
                'brandform': brandForm,
                'channel': channel,
                'subChannel': subChannel,
                qtr, month, uom, spendBucket, spendPool, ff, metric, planType, brandCategory, tsBrandforms,
            }
            if (props?.channel) {
                setChannel([props.channel])
                if (props.type === 'itt' && customChannelForITT.includes(props.channel)) {
                    setSubChannel([props.channel])
                    obj['subChannel'] = [props.channel];
                }
                obj['channel'] = [props.channel];
            }
            if (props?.subChannel) { // ITT case where we have subChannel
                setChannel([props?.subChannel])
                obj['channel'] = [props?.subChannel];
                if (customChannelForITT.includes(props?.subChannel)) {
                    setSubChannel([props?.subChannel])
                    obj['subChannel'] = [props?.subChannel];
                }
            }
            if (props?.brandform) {
                setBrandForm([props?.brandform])
                obj['brandform'] = [props?.brandform];
            }
            handleFilterChange(obj)
        }
    }, [props?.brandform, props?.channel, props?.subChannel])

    useEffect(() => {
        if (brandForm && props.setSelectedBrandForms) {
            props.setSelectedBrandForms(brandForm)
        }
    }, [brandForm])

    const confirmHandler = () => {
        setConfirm({
            ...confirmState, isOpen: true, confirmMessage: `${props.page.name} data update done for  ${getMonth(selectBrand.CurrentFF, 'month')}
		 ${getFiscalYearFormat(selectBrand.FinancialYear)}?`
        })
    }

    const setDeleteCurrentFFConfirmHandler = () => {
        setConfirm({
            ...confirmState,
            isOpen: true,
            confirmMessage: `Are you sure you want to permanently delete ${props.page.name} data for ${getMonth(selectBrand.CurrentFF, 'month')} FF?`,
        });
    };

    const deleteCurrentFFData = () => {
        let { BrandName, FinancialYear, CurrentFF } = props.brand;
        let m = '' + getMonth(CurrentFF, 'monthNumber')
            .toString();
        m = m.length === 1 ? '0' + m : m;
        let data = {
            brand: BrandName,
            currentFF: m,
            fiscalYear: FinancialYear,
            formType: props.type,
        };
        if(props.tprReducer?.selectScenario) data['scenario'] = props.tprReducer?.selectScenario;
        let url = `${API_BASE_URL}/setupdata/delete/currentff`;
        axios({
            url: url,
            method: 'DELETE',
            data: data,
            headers: {
                appId: props.match.params.appid,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            let obj = {
                currentFF: m,
                fiscalYear: FinancialYear,
                brand: encodeURIComponent(brand.BrandName),
                appId: props.match.params.appid,
            }
            checkForBGProcesses(props.type) && props.checkSpBGprocesses(obj)
            setSuccess({ isOpen: true, confirmMessage: response.data?.message });
            props.fetchData();
        })
            .catch((error) => {
                console.log(error);
                setSuccess({ isOpen: true, confirmMessage: error.message ? error.message : 'Something went wrong' });
            });
    };

    const onConfirmResponse = (resp) => {
        if (resp) {
            if (confirmState.confirmMessage.startsWith('Are you sure')) {
                deleteCurrentFFData();
            } else {
                finish();
            }
        }
        setConfirm({ isOpen: false, confirmMessage: '' });
    };

    const onSuccessResponse = (resp) => {
        setSuccess({ isOpen: false, successMessage: '' })
    }

    const finish = () => {
        let { BrandName, FinancialYear, CurrentFF } = props.brand
        let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
        m = m.length === 1 ? '0' + m : m;
        let data = {
            "formType": props.type,
            brand: BrandName,
            fiscalYear: FinancialYear, currentFF: m,
            "action": "finish"
        }
        let url = `${API_BASE_URL}/setupdata/email/send`
        axios({
            url: url,
            method: 'POST',
            data: data,
            headers: {
                appId: props.match.params.appid,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                toast.success(response.data?.message || 'Workflow emails sent successfully');
            })
            .catch((error) => {
                console.log(error);
                toast.error(error?.data?.message || 'Not able to send workflow emails');
            });
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setBtnSectionOpen(false);
        }
    }

    const showYTDSuperUpload = () => {
        return props.type === 'rlpytdact' && checkRole()
    }

    const renderUploadBtnSection = () => {
        return (
            <>
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    style={{ marginRight: '10px' }}
                    startIcon={<CloudUploadIcon />}
                    ref={anchorRef}
                    onClick={handleBtnSectionToggle}
                >
                    Upload
                </Button>
                <Popper open={true} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                    style={{ zIndex: 5, visibility: openBtnSection ? 'visible' : 'hidden' }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleBtnSectionClose}>
                                    <MenuList autoFocusItem={setBtnSectionOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem>
                                            <Upload
                                                btnTitle={props.type === 'pyrlp' ? 'Upload TS Sheet' : `Upload for ${props.brand.BrandName}`}
                                                {...props}
                                                handleSave={(files) => {
                                                    props.handleFileSave();
                                                    getFilterData();
                                                    setBtnSectionOpen(false);
                                                }}
                                                acceptedFiles={uploadFileTypes} /></MenuItem>
                                        <MenuItem>
                                            <Upload
                                                btnTitle={props.type === 'pyrlp' ? 'Upload RTDX Data' : 'Upload for all brands'}
                                                {...props}
                                                handleSave={(files) => {
                                                    props.handleFileSave();
                                                    getFilterData();
                                                    setBtnSectionOpen(false);
                                                }}
                                                acceptedFiles={uploadFileTypes} /></MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        )
    }

    let allChannelFilterValues = ChannelFilterWithTableValues.includes(props.type) ? allFilters['channel'] : (getFilteredChannelList(tprReducer.filterData?.channels) || []).map(i => i.channel)

    const isBrandFormFilterActive = brandForm?.length && !(tprReducer.filterData?.brands?.length === brandForm?.length)
    const isChannelFilterActive = channel?.length && !(allChannelFilterValues.length === channel?.length)
    const isSubChannelFilterActive = subChannel?.length && !(customChannelForITT.length === subChannel?.length)
    const isPlanTypeFilterActive = planType?.length && !(allFilters['planType']?.length === planType?.length)
    const isMonthFilterActive = month?.length && !(13 === month?.length)
    const isQtrFilterActive = qtr?.length && !(5 === qtr?.length)
    const isSpendPoolFilterActive = spendPool?.length && !(allFilters['spendPool']?.length === spendPool?.length)
    const isSpendBucketFilterActive = spendBucket?.length && !(allFilters['spendBucket']?.length === spendBucket?.length)
    const isFFFilterActive = ff?.length && !(allFilters['ff']?.length === ff?.length)
    const isMetricFilterActive = metric?.length && !(allFilters['metrics']?.length === metric?.length)
    const isUoMFilterActive = uom?.length && !(allFilters['uoM']?.length === uom?.length)
    const isTsBrandformFilterActive = tsBrandforms?.length && !(allFilters['tsBrandforms']?.length === tsBrandforms?.length)
    const isBrandCategoryFilterActive = brandCategory?.length && !(allFilters['brandCategory']?.length === brandCategory?.length)

    const multiSelectRenderer1 = [
        {
            val: brandForm,
            allValues: (tprReducer.filterData?.brands || []).map(i => i.BrandFormName) || [],
            visible: anchorEl ? anchorEl['brandforms'] : null,
            type: 'brandforms'
        },
        {
            val: channel,
            allValues: allChannelFilterValues || [],
            visible: anchorEl ? anchorEl['channels'] : null,
            type: 'channels'
        },
        {
            val: subChannel,
            allValues: customChannelForITT,
            visible: anchorEl ? anchorEl['sub Channels'] : null,
            type: 'sub Channels'
        },
    ]

    const monthQtrsFilters = [
        {
            val: month,
            allValues: monthFilterValues,
            visible: anchorEl ? anchorEl['months'] : null,
            type: 'months',
            isCapitalize: true,
        },
        {
            val: qtr,
            allValues: ['JAS', 'OND', 'JFM', 'AMJ', 'FY'],
            visible: anchorEl ? anchorEl['quarters'] : null,
            type: 'quarters'
        },
    ]

    const multiSelectRenderer2 = {
        'f1inputs': [
            {
                val: ff,
                allValues: allFilters['ff'] || [],
                visible: anchorEl ? anchorEl['FF'] : null,
                type: 'FF'
            },
            {
                val: metric,
                allValues: allFilters['metrics'] || [],
                visible: anchorEl ? anchorEl['metrics'] : null,
                type: 'metrics'
            },
            {
                val: uom,
                allValues: allFilters['uoM'] || [],
                visible: anchorEl ? anchorEl['uoM'] : null,
                type: 'uoM'
            },
        ],
        'corpbud': [
            ...monthQtrsFilters,
            {
                val: metric,
                allValues: allFilters['metrics'] || [],
                visible: anchorEl ? anchorEl['metrics'] : null,
                type: 'metrics'
            },
            {
                val: uom,
                allValues: allFilters['uoM'] || [],
                visible: anchorEl ? anchorEl['uoM'] : null,
                type: 'uoM'
            },
        ],
        'mrecomm': [
            ...monthQtrsFilters,
            {
                val: planType,
                allValues: allFilters['planType'] || [],
                visible: anchorEl ? anchorEl['plan Type'] : null,
                type: 'plan Type'
            },
            {
                val: uom,
                allValues: allFilters['uoM'] || [],
                visible: anchorEl ? anchorEl['uoM'] : null,
                type: 'uoM'
            },
        ],
        'accrualshc': [
            ...monthQtrsFilters,
            {
                val: spendPool,
                allValues: allFilters['spendPool'] || [],
                visible: anchorEl ? anchorEl['spend Pool'] : null,
                type: 'spend Pool'
            },
            {
                val: spendBucket,
                allValues: allFilters['spendBucket'] || [],
                visible: anchorEl ? anchorEl['spend Bucket'] : null,
                type: 'spend Bucket'
            },
            {
                val: uom,
                allValues: allFilters['uoM'] || [],
                visible: anchorEl ? anchorEl['uoM'] : null,
                type: 'uoM'
            },
        ],
        'itt': [
            ...monthQtrsFilters,
            {
                val: planType,
                allValues: allFilters['planType'] || [],
                visible: anchorEl ? anchorEl['plan Type'] : null,
                type: 'plan Type'
            },
        ],
        tdc: [
            {
                val: tsBrandforms,
                allValues: allFilters['tsBrandforms'] || [],
                visible: anchorEl ? anchorEl['tS Brandforms'] : null,
                type: 'tS Brandforms'
            },
            {
                val: brandCategory,
                allValues: allFilters['brandCategory'] || [],
                visible: anchorEl ? anchorEl['brand/Category'] : null,
                type: 'brand/Category'
            },
        ]
    }

    const onClose = (isSave, values, e, type) => {
        if (isSave) {
            let obj = {
                'brandform': brandForm,
                'channel': channel,
                'subChannel': subChannel,
                qtr, month, uom, spendBucket, spendPool, ff, metric, planType, tsBrandforms, brandCategory,
            }
            if (type === 'brandforms') {
                setBrandForm(values)
                obj['brandform'] = values;
            }
            if (type === 'channels') {
                setChannel(values)
                obj['channel'] = values;
            }
            if (type === 'sub Channels') {
                setSubChannel(values)
                obj['subChannel'] = values;
            }
            if (type === 'plan Type') {
                setPlanType(values)
                obj['planType'] = values;
            }
            if (type === 'metric') {
                setMetrics(values)
                obj['metric'] = values;
            }
            if (type === 'uoM') {
                setUom(values)
                obj['uom'] = values;
            }
            if (type === 'FF') {
                setFF(values)
                obj['ff'] = values;
            }
            if (type === 'months') {
                setMonth(values)
                obj['month'] = values;
            }
            if (type === 'quarters') {
                setQtr(values)
                obj['qtr'] = values;
            }
            if (type === 'spend Pool') {
                setSpendPool(values)
                obj['spendPool'] = values;
            }
            if (type === 'spend Bucket') {
                setSpendBucket(values)
                obj['spendBucket'] = values;
            }
            if (type === 'tS Brandforms') {
                setTsBrandforms(values)
                obj['tsBrandforms'] = values;
            }
            if (type === 'brand/Category') {
                setBrandCategory(values)
                obj['brandCategory'] = values;
            }
            handleFilterChange(obj, allFilters)
        }
        if (anchorEl.current && anchorEl.current.contains(e.target)) {
            return;
        }
        setAnchorEl(null)
    }

    const handlePlanClick = (event, key) => {
        setAnchorEl({ [key]: event.currentTarget });
    }

    const getCommonConditions = () => {
        if (brand) {
            let conds = [
                {
                    "selectedConditionDataElement": 'fiscalYear',
                    "selectedConditionOperator": "matches",
                    "value": "" + brand.FinancialYear,
                    "selectedConditionClause": "and"
                }, {
                    "selectedConditionDataElement": 'currentFF',
                    "selectedConditionOperator": "matches",
                    "value": "" + MonthsNumberMapping[brand.CurrentFF],
                    "selectedConditionClause": "and"
                }
            ]
            if(props.tprReducer?.selectScenario) {
                let scenario = {
                    "selectedConditionDataElement": 'scenario',
                    "selectedConditionOperator": "matches",
                    "value": "" + props.tprReducer?.selectScenario,
                    "selectedConditionClause": "and"
                }
                conds = [scenario, ...conds]
            }
            if (!brandsFreePages.includes(props.type)) {
                let brandCondition = {
                    "selectedConditionDataElement": props.type === 'f1inputs' ? 'tsBrandCategory' : 'brand',
                    "selectedConditionOperator": "matches",
                    "value": "" + encodeURIComponent(brand.BrandName),
                    "selectedConditionClause": "and"
                }
                conds = [brandCondition, ...conds]
            }
            return conds;
        }

    }

    const getFilterData = () => {
        if (filters[props.type]) {
            let url = '/dataconnect/filter/' + props.page?.pageLevelDataModel + '?type=chart&label=' + filters[props.type].join(',');
            let conditions = getCommonConditions();

            if (conditions?.length) {
                url += '&conditions=' + JSON.stringify(conditions);
            }
            let payload = {
                url: url,
                appId: props.match.params.appid,
            }
            MRPlanService.getMRData(payload).then((res) => {
                setAllFilters(res)
            }).catch(e => {
                console.log('err', e)
            })
        }
    }


    const renderMultiSelectFilter = (type, isActive) => (<div id='tt2323' className={classNames(classes.planFilter, isActive ? classes.active : '')}>
        <span style={{ whiteSpace: 'nowrap' }}>{capitalize(type)}</span><IconButton style={{ padding: 10, color: isActive ? 'tomato' : '' }}
            onClick={(e) => handlePlanClick(e, type)}><FilterListIcon style={{ fontSize: 14 }} /></IconButton></div>)

    return <>
        <div className={classNames(classes.headerMainCon, "filter-container")}>
            <div className={classes.leftHeaderCon}>
                {props.type === 'corpbud' && renderMultiSelectFilter('channels', isChannelFilterActive)}
                {props.type === 'f1inputs' &&
                    renderMultiSelectFilter('FF', isFFFilterActive)
                }
                {props.type === 'f1inputs' &&
                    renderMultiSelectFilter('metrics', isMetricFilterActive)
                }
                {props.type === 'accrualshc' &&
                    renderMultiSelectFilter('spend Pool', isSpendPoolFilterActive)
                }
                {props.type === 'accrualshc' &&
                    renderMultiSelectFilter('spend Bucket', isSpendBucketFilterActive)
                }
                {pagesWithQtrFilter.includes(props.type) &&
                    renderMultiSelectFilter('quarters', isQtrFilterActive)
                }
                {pagesWithMonthFilter.includes(props.type) &&
                    renderMultiSelectFilter('months', isMonthFilterActive)
                }
                {props.type === 'corpbud' &&
                    renderMultiSelectFilter('metrics', isMetricFilterActive)
                }
                {pagesWithBrandFormFilter.includes(props.type) && renderMultiSelectFilter('brandforms', isBrandFormFilterActive)}
                {pagesWithChannelFilter.includes(props.type) && renderMultiSelectFilter('channels', isChannelFilterActive)}
                {['mrecomm', 'itt'].includes(props.type) && renderMultiSelectFilter('sub Channels', isSubChannelFilterActive)}
                {pagesWithPlanTypeFilter.includes(props.type) && renderMultiSelectFilter('plan Type', isPlanTypeFilterActive)}
                {pagesWithUoMFilter.includes(props.type) && renderMultiSelectFilter('uoM', isUoMFilterActive)}
                {props.type === 'tdc' &&
                    <>
                        {renderMultiSelectFilter('brand/Category', isBrandCategoryFilterActive)}
                        {renderMultiSelectFilter('tS Brandforms', isTsBrandformFilterActive)}
                    </>
                }
                {
                    hideActions() &&
                    <span style={{ color: '#000', fontSize: 18, color: 'red' }}><i>* Only Finance and Super Admin role users can perform actions on this page.</i></span>
                }
            </div>
            <div className={classes.rightHeaderCon}>
                {!view &&
                    <div className={classNames(`${classes.headerBtnGrp} mr-10`)} style={{ textAlign: 'right' }}>
                        {!hideActions() && (props.type === 'rlpytdact' ? (!isNotSuperAdmin(props.member)) : true) &&
                            <Button
                                onClick={confirmHandler}
                                className={classNames("btn btn-primary btn-color float-right", classes.updateBtnStyle)}>
                                {`Done Updates  ${getMonth(selectBrand.CurrentFF, 'month')} ${getFiscalYearFormat(selectBrand.FinancialYear)}`}
                            </Button>
                        }
                        {!hideActions() && <Button
                            size="large"
                            color="primary"
                            disabled={!edited}
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={(e) => props.save(null)}
                        >
                            {"Save"}
                        </Button>
                        }
                        <span className="m-right-10" />
                        {(props.type === 'rlppermsu' || props.type === 'bopvolumes') &&
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                disabled={!edited}
                                startIcon={<SaveIcon />}
                                style={{ marginRight: '10px' }}
                                onClick={(e) => props.save(props.type)}
                            >
                                {"Recon"}
                            </Button>
                        }
                        {!hideActions() && !(props.type === 'itt' && !checkRole()) &&
                            <Button
                                className="m-right-10"
                                size="large"
                                variant="contained"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                onClick={props.deleteHandler}
                                disabled={props.disableDelete}
                            >
                                Delete
                            </Button>
                        }
                        <Button size="large" color="primary" variant="contained"
                            style={{ marginRight: '10px' }}
                            onClick={(e) => props.download(allFilters, brand, channel, brandForm, subChannel, qtr, month, uom, spendBucket, spendPool, ff, metric, planType, tsBrandforms, brandCategory)}
                            startIcon={<GetAppIcon />}>
                            {"Download"}
                        </Button>
                        {
                            !['pyrlp', 'f1inputs'].includes(props.type) && !showYTDSuperUpload() && !hideActions('pysdrlp') && !(props.type === 'itt' && !checkRole()) &&
                            <Upload
                                {...props}
                                handleSave={(files) => {
                                    props.handleFileSave()
                                    getFilterData();
                                }}
                                acceptedFiles={[
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                    'application/vnd.ms-excel',
                                    'application/x-csv',
                                    'application/csv',
                                    'text/csv',
                                    'text/x-csv',
                                    'text/tab-separated-values',
                                    'text/comma-separated-values',
                                    'text/x-comma-separated-values'
                                ]} />
                        }
                        {
                            (['pyrlp', 'f1inputs'].includes(props.type) || showYTDSuperUpload()) && !hideActions() && renderUploadBtnSection()
                        }
                        <span className="m-right-10" />
                        {
                            ['bopvolumes', 'rlppermsu', 'rlpsalience'].includes(props.type) &&
                            <Button
                                onClick={setDeleteCurrentFFConfirmHandler}
                                className={classNames('btn btn-primary btn-color float-right', classes.removeReconBtn)}>
                                {`Remove ${getMonth(selectBrand.CurrentFF, 'month')} ${getFiscalYearFormat(selectBrand.FinancialYear)} data`}
                            </Button>
                        }
                        {props.type !== 'pysdrlp' ? (
                            <Button size="large" color="primary" variant="contained"
                                style={{ marginRight: '10px' }}
                                onClick={(e) => setOpenTalkSheet(true)}
                                // startIcon={<GetAppIcon />}
                            >
                                {"By Channel"}
                            </Button>
                        ) : null}
                    </div>
                }
            </div>
        </div>
        {
            [...multiSelectRenderer1, ...(multiSelectRenderer2[props.type] || [])].map(ii => {
                return Boolean(ii.visible) && <FilterMultiSelect key={ii.type} allValues={ii.allValues} anchorEl={ii.visible}
                    id={Boolean(ii.visible) ? 'transitions-popper' : undefined} isCapitalize={ii.isCapitalize}
                    selectedValues={ii.val} onClose={(e1, e2, e3) => onClose(e1, e2, e3, ii.type)} isOpen={Boolean(ii.visible)} />
            })
        }
        <ConfirmDialog open={confirmState.isOpen} handleClose={onConfirmResponse}
            alertMessageText={confirmState.confirmMessage} classes={classes} confirm />
        <ConfirmDialog open={successState.isOpen} handleClose={onSuccessResponse}
            alertMessageText={successState.confirmMessage} classes={classes} />
        {openTalkSheet && <TalkSheetModal open={openTalkSheet} handleClose={() => setOpenTalkSheet(false)} />}
    </>
}

const mapDispatchToProps = {
    getBrandformfilter, getChannelfilter, brandformFilterSelect, checkSpBGprocesses
}

const mapStateToProps = ({ user, navigation, tprReducer, appConfig }) => {
    return {
        user,
        navigation,
        tprReducer,
        brand: tprReducer?.selectBrand,
        member: appConfig.member,
    }
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterWorksheet)))