import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import axios from 'axios'
import { DropzoneDialog } from 'material-ui-dropzone'
import React, { useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getMonth } from "utility/utils"
import { setLoading } from '../../../../store/actions'
import MRPlanUploadConfirmDialog from '../../mrplan/planingPages/Headers/planUploadConfirmDialog'
import { getFiscalYearFormat } from '../../spendplan/spendplanUtils'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
 
const omnichannelType = {
    skuMaster: 'omniSkuMaster',
    ittMaster: 'omniIttMaster',
    pidMapping: 'omniSubBrandFormMapping',
    pyfyActuals: 'omniFyActualPY',
    claims: 'omniClaims',
    skulevelofftake: 'omniSkuLevelOfftake',
    fyActuals: 'omniFyActuals',
    omniMasterClassification: 'omniMasterClassification',
    lymetric: 'lymetric',
    retailBrandVSOD: 'retailBrandVsod',
    offtakeBrandVSOD: 'offtakeBrandVsod',
    tsSkuSalience: 'tsSkuSalience',
    omniRetailingOffTakePlanning: 'omniRetailingOffTakePlanning'
}

const useStyles = makeStyles(theme => ({
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            height: '320px',
            width: '600px'
        }
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000'
    }
}));


const Upload = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({
        open: false,
        progressOpen: false,
        files: [],
        file: null,
        title: "Please wait, still in progress...",
        description: "Please wait, still in progress...",
        fileId: props.fileId
    })
    const [confirmState, setConfirm] = useState({ isOpen: false, confirmMessage: '' });

    const handleClose = (open) => {
        setState({
            [open]: false
        })
    }

    const getModalTitle = ()=> {
        if(props.page.name === 'Fy Actuals pff'){
            return 'FY Actuals ' + getFiscalYearFormat(props.account?.FinancialYear, true) + ' - Save'
        }
        return props.page.name + ' - Save'
    }

    const submitForm = (contentType, data, setResponse) => {
        let url = `${API_BASE_URL}/mrspendplan/upload/${props.page?.pageLevelDataModel}`

        if (props.type === 'claims') {
            url = `${API_BASE_URL}/mrspendplan/upload/omni/claims`
        }
        props.setLoading(true)

        axios({
            url: url,
            method: 'POST',
            data: data,
            headers: {
                'Content-Type': contentType,
                appId: props.match.params.appid
            },
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            }
        }).then((response) => {
            props.setLoading(false)
            setResponse(response.data)
            if (response.data?.status === 400) {
                return setConfirm({
                    ...confirmState, isOpen: true,
                    confirmMessage: { title: "Error", message: response.data?.message }
                });
            }
            setConfirm({
                ...confirmState, isOpen: true,
                confirmMessage: { ...response.data?.result?.data, title: getModalTitle(), }
                    || 'Upload successful',
            });

        }).catch((error) => {
            props.setLoading(false)
            setConfirm({
                ...confirmState, isOpen: true,
                confirmMessage: error.response?.data?.message || 'Details not saved'
            });
        })
    }

    const uploadWithFormData = (files) => {
        const formData = new FormData()
        const { CurrentFF, FinancialYear, AccountName } = props.account;
        let fiscalYear = Number(FinancialYear),
            currentFF = getMonth(CurrentFF, 'monthNumber')
        if(props.type === 'pyskulevel'){
            fiscalYear = fiscalYear - 1
        }
        formData.append("file", files[0])
        formData.append("fiscalYear", fiscalYear)
        formData.append("account", AccountName)
        if(props.type !== 'pyskulevel'){
            formData.append("currentFF", (currentFF > 9 ? '' : '0') + currentFF)
        }
        let formType = '';
        if (props.appName === 'omnichannel') {
            formType = omnichannelType[props.type];
        } else {
            formType = props.type;
        }
        formData.append("formType", formType)
        submitForm("multipart/form-data", formData, () => {
            props.handleSave()
        });
    }

    const handleSave = (files) => {
        setState({
            files: files
        })
        uploadWithFormData(files)
    }

    const handleOpen = (open) => {
        setState({
            [open]: true,
        })
    }

    const confirmHandler = () => {
        setConfirm({ ...confirmState, isOpen: false, confirmMessage: '' });
        if (props.type === 'skuMaster' && props.appName === 'omnichannel') {
            props.history.push(`/apps/${props.match.params.appid}/AutoPopuplate`);
        }
    }

    let acceptedFiles = props.acceptedFiles
        ? props.acceptedFiles
        : ['application/vnd.ms-excel', 'text/csv']

    return (<>
   {
  props.modalData?null : (
        <Button onClick={e => handleOpen('open')} size="large" color="primary" variant="contained" style={{display: !props.showButton ? '': 'none'}}
            startIcon={<CloudUploadIcon />}>

            {props.btnTitle ? props.btnTitle : "Upload"}
            </Button>)}
        <DropzoneDialog
            open={state.open}
            onSave={(files) => {
                handleClose('open')
                handleSave(files)
            }}
            acceptedFiles={acceptedFiles}
            showPreviews={true}
            maxFileSize={18000000}
            filesLimit={1}
            onClose={e => handleClose('open')}
        />
        {confirmState.isOpen &&
            <MRPlanUploadConfirmDialog open={confirmState.isOpen} handleClose={confirmHandler} isUpload={true}
                alertMessageText={confirmState.confirmMessage} title={props.page.title}/>
        }
    </>)
}

const mapDispatchToProps = {
    setLoading,
};

const mapStateToProps = (state) => {
    return {
        account: state.mrReducer.account
    }
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Upload)));
