import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import FileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { URLs } from '../../mrplan/ApiConstants';
import ConfirmDialog from '../SpendPlan/ConfirmDialog';
import ReplaceValues from './replaceValues'
import { getMonth } from "../../../../utility/utils";
import { getCurrentFFForPayload } from '../spendplanUtils'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: 1,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: 2,
    },
    tableContainer: {
        maxWidth: '95vw',
        // margin: "40px auto",
        overflowY: 'hidden !important',
        borderRadius: '8px',
    },
    table: {
        minWidth: 700,
    },
    tableHead: {
        '& th': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
        '& span': {
            fontWeight: 'bold',
        },
    },
    tableBody: {
        '& td': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
            cursor: 'pointer',
        },
        '& p': {
            display: 'flex',
        },
    },
    tableInput: {
        border: '0',
        background: 'transparent',
        maxWidth: '55px',
        appearance: 'none',
    },
    quarterHead: {
        paddingRight: '5px',
        borderRight: '1px solid #7b7272',
        marginRight: '5px',
    },
    quarterBody: {
        paddingRight: '5px',
        marginRight: '5px',
    },
    greenFont: {
        '& span': {
            color: 'green',
        },
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            // height: '320px',
            width: '600px',
        },
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000',
    },
    headingBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    circularBar: {
        color: 'rgb(25, 118, 210)',
        height: 20,
        width: 20,
    },
    countTag: {
        marginRight: 10,
        color: '#fff',
        background: '#ff6503',
        padding: 8,
        height: 36,
        borderRadius: 2,
    }
}));

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const OmniChannelLinks = props => {
    const classes = useStyles();
    const [link, setLink] = useState(props.page.name ? props.page.name : 'BOP Volumes');
    const [url, setUrl] = useState('');
    const [alertMessageText, setAlertMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [showUpdateValuePopup, setShowUpdateValuePopup] = useState(false);
    const hideObj = {visibility: 'hidden'};

    let mrPlanLinks = {
        configure: [
            {
                'Title': 'SKU Master', 'LinkType': 'Internal', 'InternalLinkType': 'Page',
                'keyName': 'OmniChannel SKU Master', 'LinkPageURL': 'OmniChannelSKUMaster'
            },
            {
                'Title': 'ITT Master', 'LinkType': 'Internal', 'InternalLinkType': 'Page', 'LinkPageURL': 'OmniChannelITTMaster',
                'keyName': 'OmniChannel ITT Master',
            },
            {
                'Title': 'Master Classification', 'LinkType': 'Internal', 'InternalLinkType': 'Page', 'LinkPageURL': 'MasterClassificaion',
                'keyName': 'Master Classificaion',
            },
        ],
        setup: [
            {
                'Title': 'PID Mapping', 'LinkType': 'Internal', 'InternalLinkType': 'Page',
                'keyName': 'PID Mapping', 'LinkPageURL': 'PIDMapping'
            },
            {
                'Title': 'PY Actuals Retailing', 'LinkType': 'Internal', 'InternalLinkType': 'Page', 'LinkPageURL': 'PYOmnichannelFYActuals',
                'keyName': 'PYOmnichannel FY Actuals',
            },
            {
                'Title': 'Claims', 'LinkType': 'Internal', 'InternalLinkType': 'Page',
                'keyName': 'Claims', 'LinkPageURL': 'Claims'
            },
            {
                'Title': 'FY Actuals Retailing', 'LinkType': 'Internal', 'InternalLinkType': 'Page', 'LinkPageURL': 'OmniFYActualsRetailing',
                'keyName': 'Omni FY Actuals Retailing',
            },
            {
                'Title': 'SKU Level Offtake', 'LinkType': 'Internal', 'InternalLinkType': 'Page', 'LinkPageURL': 'SKULevelOfftake',
                'keyName': 'SKU Level Offtake',
            },
            {
                'Title': 'PY Metrics', 'LinkType': 'Internal', 'InternalLinkType': 'Page', 'LinkPageURL': 'LYMetric',
                'keyName': 'PY Metrics',
            },
            {
                'Title': 'Retailing Offtake Planning', 'LinkType': 'Internal', 'InternalLinkType': 'Page', 'LinkPageURL': 'RetailingOfftakePlanning',
                'keyName': 'Retailing Offtake Planning',
            },
            {
                'Title': 'Offtake Brand VSOD', 'LinkType': 'Internal', 'InternalLinkType': 'Page', 'LinkPageURL': 'OfftakeBrandVSOD',
                'keyName': 'Offtake Brand VSOD',
            },
            {
                'Title': 'Retail Brand VSOD', 'LinkType': 'Internal', 'InternalLinkType': 'Page', 'LinkPageURL': 'RetailBrandVSOD',
                'keyName': 'Retail Brand VSOD',
            },
            {
                'Title': 'TS SKU Salience', 'LinkType': 'Internal', 'InternalLinkType': 'Page', 'LinkPageURL': 'TSSKUSalience',
                'keyName': 'TS SKU Salience',
            }
        ]
    };

    const downloadTemplate = () => {
        let uri = API_BASE_URL + URLs.templateDownloadOmni;
        setDownloadLoading(true)
        axios({
            url: uri,
            method: 'GET',
            responseType: 'blob',
            headers: {
                appId: props.match.params.appid,
            },
        })
            .then((response) => {
                FileDownload(response.data, 'MRSpend_Upload_Templates.zip');
                setDownloadLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setDownloadLoading(false)
            });
    }

    const handleChange = (value, _url) => {
        setUrl(_url);
        if (props.edited) {
            handleClickOpen();
            setAlertMessage({
                title: 'Confirmation',
                p1: `You have unsaved changes in ${link}.`,
                p2: 'Are you sure you want to discard these changes?',
            });
        } else {
            setLink(value);
            props.history.push(_url);
            if (url !== _url) {
                props.pageURLUpdate()
            }
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (e, res) => {
        setOpen(false);
        if (e) {
            props.history.push(url);
            props.pageURLUpdate()
        }
    };

    const addQueryParamsToUrl = (url = '') => {
        if (url) {
            let urlSearchParams = new URLSearchParams(window.location.search);
            if (urlSearchParams.get('brand') && urlSearchParams.get('year') && urlSearchParams.get('month')) {
                url += `?brand=${urlSearchParams.get('brand')}&year=${urlSearchParams.get('year')}&month=${urlSearchParams.get('month')}`;
            }
        }
        return url;
    };

    const openValuesPopup = () => {
        setShowUpdateValuePopup(!showUpdateValuePopup);
    }

    const handleCloseReplace = (val, payload) => {
        if (val) {
            setShowUpdateValuePopup(false)
        } else {
            payload['currentFF'] = getCurrentFFForPayload(getMonth((props.account && props.account['CurrentFF']) || '', 'monthNumber'));
            payload['fiscalYear'] = (props.account && props.account['FinancialYear']) || '';
            payload['type'] = JSON.parse(JSON.stringify(payload.fieldValue));
            delete payload['fieldValue'];
            let uri = API_BASE_URL + '/mrspendplan/omni/replacement/master';
            axios({
                url: uri,
                method: 'POST',
                responseType: 'blob',
                data: payload,
                headers: {
                    appId: props.match.params.appid,
                },
            })
            .then((response) => {
                console.log(response.data, 'rrr');
            })
            .catch((error) => {
                window.alert(error.message);
            });
        }
    }

    return <div className='setup-data'>
        <div className={classes.headingBox}>
            <div className={`tab-container m-top-10 m-bottom-20 ${props.type === 'pidMapping' ? hideObj: ''}`}>
                {mrPlanLinks[props.linkType] && mrPlanLinks[props.linkType].map((gLink, i) => {
                    let url;
                    if (gLink.LinkType === 'Internal') {
                        url = gLink.InternalLinkType === 'Form'
                            ? `/apps/${props.match.params.appid}/${gLink.LinkFormURL}/new`
                            : `/apps/${props.match.params.appid}/${gLink.LinkPageURL}`;
                        url = gLink.InternalLinkType === 'Page' ? addQueryParamsToUrl(url) : url;
                    } else {
                        url = gLink.ExternalLink;
                    }
                    return <div key={i}
                        className={`setup-data-headings p-bottom-10 p-right-10 p-left-10 ${gLink.keyName === props.page.name ? `active-global-link` : ``}`}
                        onClick={() => {
                            handleChange(gLink.keyName, url);
                        }}>
                        <span className={gLink.keyName === props.page.name ? 'select-global-link' : 'normal-link'}
                            style={{ whiteSpace: "nowrap" }}>{gLink.Title}</span>
                    </div>;
                })}
            </div>

            {/* {
                props.appName === 'omnichannel' && props.type === 'skuMaster' && 
                <Button
                style={{ height: 30, color: 'rgb(25, 118, 210)', marginTop: 15 }}
                size="small"
                class="btn btn-primary"
                onClick={openValuesPopup}>
                    Replace Value
                </Button>
            } */}

            <Button
                onClick={downloadTemplate}
                title={"Template Files for Upload"}
                startIcon={<GetAppIcon />}
                variant="text"
                size="small"
                disabled={downloadLoading}
                style={{ height: 30, color: 'rgb(25, 118, 210)', marginTop: 15 }}
            >
                {
                    downloadLoading ? <CircularProgress className={classes.circularBar} size={24} /> : 'Template'
                }
            </Button>
        </div>
        <ConfirmDialog open={open} handleClose={handleClose} alertMessageText={alertMessageText}
            classes={classes} confirm={true} />
        
        <ReplaceValues open={showUpdateValuePopup} handleClose={handleCloseReplace} alertMessageText={alertMessageText}
            classes={classes} confirm={true} />
    </div>;
};

const mapDispatchToProps = {};

const mapStateToProps = ({ user, navigation, mrReducer }) => {
    return {
        user,
        navigation,
        account: mrReducer.account
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(OmniChannelLinks)));
