import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const NotFound = (props) => (
	<div className="text-center app-not-found">
		<h4>{	props.overrideText ? props.overrideText : "The page that you requested either doesn't exist or you do not have access. Please reach out to application support team."
		}</h4>
		<Link onClick={(e) => {
			e.preventDefault()
			props.history.goBack()
		}}><p>Go Back</p></Link>
	</div>
);

export default withRouter(NotFound);
