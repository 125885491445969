import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React from 'react';

export const MessageDialog = (Msg, description, callback, classes) => {
  if (!Msg) return;
  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.confirmationDialoge}
    >
      <DialogTitle id="alert-dialog-title" style={{ background: '#047dd8' }}>{Msg}</DialogTitle>
      <DialogContent className={classes.dialog}>
        <DialogContentText id="alert-dialog-description">
        <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
								{description} </h6></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          style={{ color: '#fff', background: '#2a9fd6' }}
          onClick={() => {
            callback();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
