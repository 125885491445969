import { Paper, TableContainer } from '@material-ui/core';
import React from 'react';
import CustomTable from '../@table/Table';


const exportArray = [{ key: 'xls', name: "Export as Excel", isVisible: true },
{ key: 'csv', name: "Export as csv", isVisible: true },
{ key: 'txt', name: "Export as text", isVisible: true }];

const View = (props) => {
	let { name } = props.chartData;
	return (<TableContainer component={Paper}>
		<CustomTable onRowClick={props.onRowClick}
			// name={"Pages"}
			name={name}
			{...props}
			{...props.state}
			searchHandler={props.searchHandler}
			isExport={props.isExport !== undefined ? props.isExport : true}
			isViewCoulmns={props.isViewColumn !== undefined ? props.isViewColumn : true}
			isSearch={false}
			isFilter={true}
			hasCheckbox={props.hasCheckbox !== undefined?props.hasCheckbox:true} // TODO: need to be configurable
			actions={{
				edit: props.hideEdit !== undefined ? !props.hideEdit : true, view: false,
				delete: props.hideDelete !== undefined ? !props.hideDelete : true, copy: false
			}}
			hideAddNew={props.hideAddNew}
			isTablePagination={true}
			isTableHead={true}
			isTableSubHeader={false}
			handleInputChange={props.handleInputChange}
			resetFilters={props.resetFilters}
			handleDropdownChange={props.handleDropdownChange}
			handleRequestSort={props.handleRequestSort}
			handleSelectAllClick={props.handleSelectAllClick}
			tableCrudOperations={props.tableCrudOperations}
			handleCheck={props.handleCheck}
			handleChangePage={props.handleChangePage}
			handleChangeRowsPerPage={props.handleChangeRowsPerPage}
			handleExoprtItemClick={props.handleExoprtItemClick}
			exportArray={exportArray}
			headerstyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", textAlign: "center" }}
			subHeaderstyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", textAlign: "center" }}
			rowStyle={{
				fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF",
				row1BackgroundColor: "#FFF", row2BackgroundColor: "#FFF"
			}} />
	</TableContainer>)
}

export default View