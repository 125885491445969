import React, { useState, useEffect } from 'react';
import PieChart from 'components/charts/PieChart';
import LineChart from 'components/charts/LineChart';
import DoughnutChart from 'components/charts/DoughnutChart';
import BarChart from 'components/charts/BarChart';
import HorizontalBarChart from 'components/charts/HorizontalBarChart';
import BulletChart from 'components/charts/bulletChart/BulletChart';
import OrgChart from 'components/charts/orgChart/OrganisationChart';
import Card from 'components/cards/Card';
import HTMLCard from 'components/cards/HTMLCard';
// import Table from 'components/Table';
// import Table from 'components/@table/TableOld';
// import Table from 'components/@table/Table';
import TableWrapper from 'components/@table/TableWrapper';

// import TableWrapper from 'components/table/TableWrapper';
import SummaryTable from 'components/table/SummaryTable';
import SimpleTable from 'components/SimpleTable';

const options = {
    // responsive: true,
    maintainAspectRatio: true
};

const styles = {};

const Chart = (props) => {
    const [data, setData] = useState({});

    useEffect(() => {
        setData(JSON.parse(JSON.stringify(props.data)));
    }, [props.data]);

    const renderChart = (data) => {
        if (data.chartType === "pie") {
            let legendOpts = data.chartData.legendOpts ? data.chartData.legendOpts : null;
            return <PieChart page={props.page} data={data.chartData.data} chartData={data.chartData}
                dataUrl={data.chartData.dataUrl} styles={styles} legendOpts={legendOpts}
                options={options} queryString={props.queryString} />
        }
        else if (data.chartType === "doughnut") {
            let legendOpts = data.chartData.legendOpts ? data.chartData.legendOpts : null;
            return <DoughnutChart page={props.page} data={data.chartData.data} chartData={data.chartData}
                dataUrl={data.chartData.dataUrl} styles={styles} legendOpts={legendOpts}
                options={options} queryString={props.queryString} />
        }
        else if (data.chartType === "bar") {
            return <BarChart page={props.page} data={data.chartData.data} chartData={data.chartData}
                dataUrl={data.chartData.dataUrl} styles={styles} options={options}
                queryString={props.queryString} />
        }
        else if (data.chartType === "stackedbar") {
            return <BarChart page={props.page} data={data.chartData.data} chartData={data.chartData}
                dataUrl={data.chartData.dataUrl} styles={styles} options={options}
                queryString={props.queryString} stacked={true} />
        }
        else if (data.chartType === "line") {
            return <LineChart page={props.page} data={data.chartData.data} chartData={data.chartData}
                dataUrl={data.chartData.dataUrl} styles={styles} options={options}
                queryString={props.queryString} />
        }
        else if (data.chartType === "horizontalBar") {
            return <HorizontalBarChart page={props.page} data={data.chartData.data} chartData={data.chartData}
                dataUrl={data.chartData.dataUrl} styles={styles} options={options}
                queryString={props.queryString} />
        }
        else if (data.chartType === "list" || data.chartType === "table"
            || data.chartType === "simpletable") {
                console.log("data.chartType ====", data.chartType);
            return <TableWrapper page={props.page} data={data.chartData.data} columns={data.chartData.columns}
                width={data.chartData.width} height={data.chartData.height}
                dataUrl={data.chartData.dataUrl} chartData={data.chartData}
                queryString={props.queryString} chartType={data.chartType} appId={props.appId} />
        }
        else if (data.chartType === "summaryTable") {
            return <SummaryTable page={props.page} data={data.chartData.data} columns={data.chartData.columns}
                width={data.chartData.width} height={data.chartData.height}
                dataUrl={data.chartData.dataUrl} chartData={data.chartData}
                queryString={props.queryString} chartType={data.chartType} appId={props.appId} />
        }
        else if (data.chartType === "bullet") {
            return <BulletChart page={props.page} data={data.chartData.data} queryString={props.queryString} />
        }
        else if (data.chartType === "org") {
            return <OrgChart page={props.page} data={data.chartData.data} queryString={props.queryString} />
        } else if (data.chartType === "card") {
            return <Card page={props.page} data={data.chartData} chartData={data.chartData}
                dataUrl={data.chartData.dataUrl} queryString={props.queryString}
                appId={props.appId} _id={data._id}  chartType={data.chartType}/>
        } else if (data.chartType === "html") {
            return <HTMLCard page={props.page} data={data.chartData} chartData={data.chartData}
                dataUrl={data.chartData.dataUrl} queryString={props.queryString} />
        }
    }

    return (
        <>
            {data ? renderChart(data) : ''}
        </>
    )
};

export default Chart;
