import { put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import { ConsumerPromotiosService } from '../../../modules/api/custom/spendplan/consumerPromotios';

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* fetchConsumerPromotionsData(action) {
    try {
        const tableData = yield fetchAsync(ConsumerPromotiosService.getConsumerPromos, action.payload);
      yield put({ type: ActionTypes.GET_CONSUMER_PROMO_SUCCESS, payload: tableData });
    }
    catch (err) {
        yield put({ type: ActionTypes.GET_CONSUMER_PROMO_FAILURE, error: err.message });
    }
}

function* fetchConsumerPromoExtraData(action) {
    try {
        const tableData = yield fetchAsync(ConsumerPromotiosService.getConsumerPromoExtraData, action.payload);
        yield put({ type: ActionTypes.GET_CONSUMER_PROMO_EXTRA_DATA_SUCCESS, payload: tableData.result.data });
    }
    catch (err) {
        yield put({ type: ActionTypes.GET_CONSUMER_PROMO_EXTRA_DATA_FAILURE, extraDataError: err.message });
    }
}

function* addNewConsumerPromo(action) {
    try {
        const response = yield fetchAsync(ConsumerPromotiosService.addNewConsumerPromo, action.payload)
        yield put({ type: ActionTypes.ADD_NEW_CONSUMER_PROMO_SUCCESS, payload: response })
    } catch (err) {
        let errorMsg, error;
        if (err.response.error) {
            error = err.response.error; 
        } else {
            errorMsg = err.message != 'Bad Request' ? err.message : 'Please enter correct Data';
        }
        yield put({ type: ActionTypes.ADD_NEW_CONSUMER_PROMO_FAILURE, error: errorMsg?errorMsg:error });
    }
}

function* deleteCheckedConsumerPromos(action) {
    try {
        const response = yield fetchAsync(ConsumerPromotiosService.deleteCheckedConsumerPromos, action.payload)
        yield put({ type: ActionTypes.DELETE_CHECKED_CONSUMER_PROMO_SUCCESS, payload: response })
    }
    catch (err) {
        let errorMsg = err.message !== 'Bad Request' ? err.message : 'Please enter correct Data';
        yield put({ type: ActionTypes.DELETE_CHECKED_CONSUMER_PROMO_FAILURE, error: errorMsg });
    }
}

function* fetchPlans(action) {
    try {
        const response = yield fetchAsync(ConsumerPromotiosService.getPlans, action.payload)
        yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_CONSUMER_PROMO_SUCCESS, payload: response?.result?.data ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'check request Failed';
        yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_CONSUMER_PROMO_FAILURE, error: errorMsg });
    }
}

export function* consumerPromotion() {
    yield takeLatest(ActionTypes.GET_CONSUMER_PROMO_DATA, fetchConsumerPromotionsData);
    yield takeLatest(ActionTypes.ADD_NEW_CONSUMER_PROMO, addNewConsumerPromo);
    yield takeLatest(ActionTypes.DELETE_CHECKED_CONSUMER_PROMO, deleteCheckedConsumerPromos);
    yield takeLatest(ActionTypes.GET_CONSUMER_PROMO_EXTRA_DATA, fetchConsumerPromoExtraData);
    yield takeLatest(ActionTypes.GET_PLAN_FILTER_DATA_CONSUMER_PROMO, fetchPlans);

}

export default consumerPromotion;
