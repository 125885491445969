import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { capitalize } from '../../../../utility/utils.js';
import { convertToNumber } from '../../spendplan/PlanningPages/planningHelperFunctions.js';
import { StyledTableRow, StyledTableCell, useStyles } from '../ReportPages/Summary/summarySheetTable'

const CockpitTable = (props) => {
    const classes = useStyles();

    const renderHeadingRow = (num, text) => {
        return (
            <StyledTableRow>
                <StyledTableCell className='row-bold top-border' style={{ textAlign: 'start' }} colSpan={'100%'}>
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                </StyledTableCell>
            </StyledTableRow>
        );
    };

    const renderHeadings = (headings) => {
        return (
            <TableRow style={{ backgroundColor: '#3CA7FD', }}>
                {
                    headings.map((heading, index) => {
                        return (<TableCell className={classes.rowBold} key={index} rowSpan={heading.rowSpan || 1} colSpan={heading.colSpan || 1}>
                            {
                                heading.colSpan ? <p className={classes.combinedHeaderHeading} style={{ display: 'block', textAlign: 'center', marginBottom: 0 }}>
                                    {capitalize(heading.title)}</p> :
                                    <span style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: heading.title }} />

                            }
                        </TableCell>)
                    })
                }
            </TableRow>
        )
    }

    const renderBody = (index, mainKeys, subKeys) => {
        let data = props.data;
        if (data) {
            let isTableType1 = props.tableConfig[0]?.tableType === 'table1';
            if(isTableType1){
                data = data.map(i=> ({...i, uom: i.uom === 'INR'?'INR Cr.':i.uom}))
            }

            return data.map((row, index) => {
                let isSDEffectiveRow = isTableType1 && row['type'] === "SDe";
                // in cockpit table 1 if type is SDe then remove  % from uom
                return (
                    <StyledTableRow key={index} onClick={props.onRowClick ? () => props.onRowClick(row) : () => { }}>
                        {mainKeys.filter(i => i.key).map((item, ind) => {
                            let isPercentageRow = row['uom']?.includes('%');
                            let isBlueFont = isTableType1 && row['uom'] === 'IYA'
                            let isRedFont = isTableType1 && row['uom'].includes('%')
                            let val = row[item.key] ? (((item.isPercent || isPercentageRow) && !item.isString) ? row[item.key] * 100 : row[item.key]) : 0
                            let isInnerKey = item.key.split('.')
                            if (isInnerKey?.length === 2) {
                                val = row[isInnerKey[0]] ? (((item.isPercent || isPercentageRow) && !item.isString) ? row[isInnerKey[0]][isInnerKey[1]] * 100 : (row[isInnerKey[0]][isInnerKey[1]])) : 0
                            }
                            if(isSDEffectiveRow && item.key === 'uom'){
                                val = ''
                            }
                            let isDecimalOnePlace = props.tableConfig[0]?.tableType === 'checkbook';
                            return (<StyledTableCell style={{ color: item.isLink ? 'blue' : ((ind !==0 && isBlueFont)?'#0070c0':(ind !==0 && isRedFont && (item.key ==='uom'?(row['uom'] === '%'):true)?'red':undefined)),
                             borderBottom: (index === data.length - 1) ? '1px solid rgba(224, 224, 224, 1)' : undefined, }}
                                className={item.leftAlign ? classes.alignLeft : (item.alignRight ? classes.alignRight : '')} key={index + ind}>
                                <span title={val || ''} style={{ fontWeight: row['brand'] === 'TOTAL' ? 'bold' :  ((ind === 0 && isTableType1)|| (item.isBold || isBlueFont)?'bold': undefined) }}>
                                    {item.isString ? val : convertToNumber(val, isDecimalOnePlace ? 1 : 2).toLocaleString()}
                                    {
                                        ((item.isPercent || isPercentageRow) && !item.isString && !isSDEffectiveRow) && '%'
                                    }
                                </span>
                            </StyledTableCell>)
                        })
                        }
                        {subKeys?.map((item, index) => {
                            let keys = item.key.split('.')
                            let isPercentageRow = row['uom']?.includes('%');
                            let val = row[keys[0]] ? ((item.isPercent || isPercentageRow) ? row[keys[0]][keys[1]] * 100 : row[keys[0]][keys[1]]) : 0
                            if (!keys[1]) {
                                val = row[keys[0]] ? ((item.isPercent || isPercentageRow) ? row[keys[0]] * 100 : row[keys[0]]) : 0
                            }
                            let isRedBG = props.tableConfig[0].tableType === 'checkbook' && val < 0;
                            let isDecimalOnePlace = props.tableConfig[0]?.tableType === 'checkbook';
                            return (<StyledTableCell key={index}
                                style={{ background: (isRedBG ? '#ffc7cd' : item.bg?item.bg:(keys[0] !== 'brand' && row['brand'] === 'TOTAL')?'#d6dce4':''),
                                 color: isRedBG ? '#9c0007' : ((keys[0] !== 'brand' && row['brand'] === 'TOTAL')?'#0070c0':''), }} >
                                <span title={val || ''} style={{ fontWeight: row['brand'] === 'TOTAL' ? 'bold' : undefined }}>
                                    {convertToNumber(val, isDecimalOnePlace ? 1 : 2).toLocaleString()}
                                    {(item.isPercent || isPercentageRow) ? '%' : ''}</span>
                            </StyledTableCell>)
                        })
                        }
                    </StyledTableRow>)
            })
        }
    }

    return (
        <div className='imar1-report'>
            <TableContainer className={classNames(classes.tableContainer, "tpr-container")} style={{ maxHeight: props.height || '100vh' }} component={Paper}>
                <Table className={classNames(classes.table, "tpr-table")} aria-label="simple table">
                    <TableHead className='table-head'>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {
                            props.tableConfig.map((table, index) => {
                                return (<React.Fragment key={index}>
                                    {/* {
                                        renderHeadingRow(index + 1, table.headingData)
                                    } */}
                                    {
                                        renderHeadings(table.headings)
                                    }
                                    {
                                        Boolean(table.subHeadings?.length) && renderHeadings(table.subHeadings)
                                    }
                                    {
                                        renderBody(index, table.headings, table.subHeadings)
                                    }

                                    <StyledTableRow key={`empty-${index}`}>
                                        <StyledTableCell className='white-background remove-right-border'>
                                        </StyledTableCell>
                                        <StyledTableCell className='white-background top-border' colSpan={'100%'}>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key={`empty-${index}-1`}>
                                        <StyledTableCell className='white-background remove-right-border'>
                                        </StyledTableCell>
                                        <StyledTableCell className='white-background ' colSpan={'100%'}>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </React.Fragment>)
                            })
                        }

                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
}

export default CockpitTable;

