
import { hot } from 'react-hot-loader/root'
import React, { useEffect, useState, useRef } from 'react'
import {
	Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid,
	Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
	DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'
// import {
// 	KeyboardDatePicker,
//   } from '@material-ui/pickers';
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import { request } from 'modules/client'
import moment from 'moment';
import '../ibcsupportingdocument.scss'
// import './copy/custom.min.css'
import '../ext-content/bootstrap.scss'
import '../ext-content/custom.min.scss'
import '../ext-content/mystyles.scss'
import '../ext-content/jqcloud.scss'
import '../ext-content/basic.scss'
import '../ext-content/dropzone.scss'
import defaultCategories from '../categories.json'
import toolTips from './toolTips.json'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import Slide from '@material-ui/core/Slide';
import XLSX from 'xlsx';

//TODO: remove extra files from ext-content  folder

import Loading from '../ext-content/images/loading.gif'
import { getEntity } from 'store/actions/app'
import { CSVLink } from "react-csv";
import { Parser } from "json2csv";

import SummaryInvoice from './SummaryInvoice'
import AuditTrail from './AuditTrail'
import ConfirmDialog from './dialogs/ConfirmDialog'
import SendReportDialog from './dialogs/SendReportDialog'
import MembersList from './MembersList'
import CensusValidations from './CensusValidations'
import MappingError from './MappingError'

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	pagination: {
		'& .MuiTablePagination-root': {
			backgroundColor: "#242323 !important",
			color: "white !important",

		},
		'& .MuiSelect-icon': {
			color: "white !important",
			top: '0px !important',
			right: "-5px !important"

		},
		'& .MuiIconButton-root.Mui-disabled': {
			color: "white !important"
		},
		textField: {
			"padding-right": "10px",
			"margin-top": "0px",
			"margin-bottom": "16px",
			"border-radius": 0
		}
	},
	dialog: {
		height: "300px",
		width: "600px",
		margin: '0px auto'
	},
	paymentDialog: {
		height: "80vh",
		width: "700px",
		margin: '0px auto'
	}
}));

let groups = [
	{ name: 'Group Benefits', plans: ['Basic Life', 'AD&D', 'LTD', 'STD'], ERPaidPercentage: "ER Paid 100% ", subTotal: 'Total Group Premium Amount', spData: [] },
	{ name: 'Services', plans: ['FMLA/ADA Administration'], ERPaidPercentage: "", subTotal: 'Total Services Premium Amount', spData: [] },
	{ name: 'Voluntary Benefits', plans: ['Employee Optional Life', 'Spouse Life', 'Child Life', 'Dependent Life'], ERPaidPercentage: "ER Paid 0%", subTotal: 'Total VB Premium Amount', spData: [] }
]

let contributionTypeData = [
	{ name: 'ER-contribution', plans: ['Basic Life', 'AD&D', 'ADD', 'LTD', 'STD', "ER Contribution", "FMLA/ADA Administration Rate", "Total Premium"] },
	{ name: 'EE-contribution', plans: ['Employee Optional Life', 'Spouse Life', 'Child Life', "EE Contribution", "Total Premium", 'EE', 'SP', 'CH'] },
	{ name: 'default', plans: ['BillingPeriod', 'ControlNumber', 'Policy Number', 'Employee ID', 'Employee Name', 'Age', 'Bill Group', 'Class', 'Base Annual Salary', 'Annual Bonus', 'Credit', 'Debit'] }
]

let amountColumns = ['Base Annual Salary', 'Annual Bonus', 'Earnings for Life and ADD',
	'Basic Life Volume', 'Basic Life Prem', 'AD&D Volume', 'AD&D Prem',
	'Earnings for LTD', 'LTD Volume', 'LTD Prem', 'Earnings For STD', 'STD Volume', 'STD Prem',
	'FMLA/ADA Administration', 'Supp EE Life Volume', 'Employee Optional Life Prem', 'Employee Optional Life Volume',
	'Supp SP Life Volume', 'Spouse Life Volume', 'Spouse Life Prem',
	'Child Life Volume', 'Supp CH Life Volume', 'Child Life Prem',
	'EE Contribution', 'ER Contribution', 'Credit', 'Debit', 'Total Premium']

const SummaryInvoiceLayout = (props) => {
	const [state, setState] = useState('initial')
	const [docMode, setDocMode] = useState('new')
	const [form, setForm] = useState({})
	const [respData, setRespData] = useState([]);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [EETotal, setEETotal] = useState(0);
	const [ERTotal, setERTotal] = useState(0);
	const [Credit, setCredit] = useState(0);
	const [Debit, setDebit] = useState(0);
	const [groupTotals, setGroupTotals] = useState(0);
	const [members, setMemberList] = useState([]);
	const [workflow, setWorkflow] = useState([]);
	const [workflowId, setWorkflowId] = useState([]);
	const [displayData, setDisplayList] = useState([]);
	const [billGroups, setBillGroupsList] = useState([]);
	const [categories, setCategories] = useState(defaultCategories)
	const inputRef = useRef(null)
	const tableRef = useRef(null);
	const memberTableRef = useRef(null);
	const [supportingDoc, setSupportingDoc] = useState('');
	const [supportingDocId, setSupportingDocId] = useState('');
	const [type, setType] = useState('suppotingdoc')
	const [billGroupType, setBillGroupType] = useState('All')
	const classes = useStyles();
	const [pageCount, setPageCount] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [page, setPage] = React.useState(0);
	const [open, setOpen] = React.useState(false);
	const [sendDocumentOpen, setSendDocumentOpen] = React.useState(false);
	const [alertMessageText, setAlertMessage] = React.useState("");
	const [employerCase, setEmployerCase] = useState({});
	const [memberSaved, setMemberSaved] = React.useState(false);
	const [docStatus, setDocStatus] = React.useState(false);
	const [uId, setUId] = React.useState({});
	const [payBillShow, setPayBillShow] = React.useState(true);
	const [isPaymentOpen, setPaymentOpen] = React.useState(false);
	const [paymentDate, setPaymentDate] = React.useState("");
	const [bankAccounts, setBankAccounts] = React.useState([]);
	const [bankAccount, setBankAccount] = React.useState("");
	const [paymentType, setPaymentType] = React.useState("PayNow");
	const [paymentSuccess, setPaymentSuccess] = React.useState(false);
	const [billGroupStringData, setBillGroupStringData] = React.useState("");
	const [confirmFinalize, setConfirmFinalize] = React.useState(false);
	const [contributionType, setContributionType] = React.useState("All");
	const [productList, setProductList] = React.useState([]);
	const [classList, setClassList] = React.useState([]);
	const [selectedProduct, setSelectedProduct] = React.useState("All");
	const [selectedClass, setSelectedClass] = React.useState("All");
	const [selectedAgeBand, setSelectedAgeBand] = React.useState("All");
	const [ageBandList, setAgeBandList] = React.useState([]);

	const [fileData, setFileData] = useState({})
	const [uploadedMembers, setUploadedMembers] = React.useState();
	const [validMembers, setValidMembers] = React.useState();
	const [invalidMembers, setInvalidMembers] = React.useState();
	const [censusError, setCensusError] = React.useState();
	const [setup, setSetup] = React.useState();
	const [mappingError, setMappingError] = React.useState();
	const [dataErrors, setDataErrors] = React.useState();
	const [missingData, setMissingDataMembers] = React.useState();
	const [mappedKeys, setMappedKeys] = React.useState();

	const calculatePremium = (e) => {
		setState('loading')
		let data = {
			uId: uId.uId, filePath: uId.filePath, categories: categories.categories,
			'entityId': props.member.entityId
		}
		axios({
			url: API_BASE_URL + '/custom/supportingDocument/generate',
			method: 'POST',
			data: data,
			headers: {
				appId: props.match.params.appid,
				'Content-Type': 'application/json',
			},
			onUploadProgress: (p) => {
				console.log("p", p);
			}
		}).then((response) => {
			let res = response.data;
			setRespData(res)
			setData(segregatePlans(res.supportingDocuments));
			setTotal(res.total)
			setEETotal(res.EETotal)
			setERTotal(res.ERTotal)
			setCredit(res.Credit)
			setDebit(res.Debit)
			// setMemberList(res.membersList);
			setBillGroupsList(res.billGroups)
			let billGroupString = ""
			res.billGroups && res.billGroups.billGroupNames && res.billGroups.billGroupNames.map((item) => {
				if (billGroupString.length > 0) {
					billGroupString = billGroupString + ", " + item
				} else {
					billGroupString = item
				}
			})
			console.log("billGroupString======", billGroupString);
			setBillGroupStringData(billGroupString);
			// setTimeout(() => {
			setState('done')
			// }, 100);
			// setTimeout(() => {
			setDocStatus(true)
			// }, 300)
		}).catch((error) => {
			console.log(error);
			setState('done')
			setAlertMessage('Summary Invoice document generation filed ' + error)
			handleClickOpen();
			// alert(error)
		})
	}

	const saveMembers = (e) => {
		if (!categories) {
			setAlertMessage("Please create a setup")
			handleClickOpen();
		} else if (!employerCase) {
			setAlertMessage("Please create a case")
			handleClickOpen();
		}
		else if (!form.files) {
			setAlertMessage("Please upload a file")
			handleClickOpen();
		} else {
			setState('loading');
			if (basicKeyMappingCheck()) {
				uploadWithFormData(form.files)
			}
		}
	}

	const uploadMembers = (e) => {
		/*if (!categories) {
			setAlertMessage("Please create a setup")
			handleClickOpen();
		} else if (!employerCase) {
			setAlertMessage("Please create a case")
			handleClickOpen();
		}
		else if (!form.files) {
			setAlertMessage("Please upload a file")
			handleClickOpen();
		} else {
			setState('loading');
			uploadWithFormData(form.files)
		}*/
		setState('loading');
		console.log('fileData ???', fileData);
		console.log('setup ....', setup);
		setUploadedMembers(fileData.data)
		if (basicKeyMappingCheck()
			// && dataCheck()
		) {
			setMappingError()
			// setDataErrors()
		}
		setState('done');
	}

	const basicKeyMappingCheck = () => {
		let headers = fileData.headers
		let comp = setup.basicKeyMapping.employeeCompensation.filter(e => {
			return !headers.includes(e.value)
		})
		let info = setup.basicKeyMapping.employeeInformation.filter(e => {
			return !headers.includes(e.value)
		})
		if (comp.length > 0 || info.length > 0) {
			let me = {
				issues: comp.length + info.length,
				list: {}
			}
			if (comp.length > 0)
				me.list['Employee Compensation'] = comp
			if (info.length > 0)
				me.list['Employee Information'] = info
			setMappingError(me)

			setCensusError('Uploaded data sheet missing following header')
			setState('done');

			return false
		}
		return true
	}

	const uploadWithFormData = (files, title, desc) => {
		const formData = new FormData();
		formData.append("file", files[0]);
		formData.append('data', JSON.stringify(categories))
		formData.append('entityId', props.member.entityId)
		console.log('formData:', formData);
		submitForm("multipart/form-data", formData, (res) => {
			console.log("res - fileupload", res)
			if (res.error) {
				setState('done');
				// alert(res.error)
				setAlertMessage("census data save Failed.")
				handleClickOpen();
			} else {
				// setAlertMessage({ p1: "Census member data saved successfully.", p2: "Click on the `Calculate Premium` generate Summary Invoice document" })
				// handleClickOpen();
				console.log('res.membersList', res.membersList);
				setMemberList(res.membersList);

				setInvalidMembers(res.invalidData)
				setMissingDataMembers(res.missingData)

				setDataErrors(res.issues)
				// setMemberList(res.membersList);
				setMemberSaved(true)
				setState('done');
				setUId({ uId: res.uId, filePath: res.filePath })
			}
		});
	}

	const getProductList = (products) => {
		if (!products) {
			return [];
		}
		const pList = products.reduce((acc, curr) => {
			const product = curr[0];
			if (!acc.includes(product)) {
				acc.push(product);
			}
			return acc;
		}, []);
		const cList = products.reduce((acc, curr) => {
			const className = curr.find(c => c.toLowerCase().includes('class'));
			if (!className || !acc.includes(className)) {
				acc.push(className);
			}
			return acc;
		}, []).filter(c => !!c);
		setProductList(pList);
		setClassList(cList);
	}

	const makeCols = refstr => {
		let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
		for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
		return o;
	};

	const handleFile = (file/*:File*/) => {
		/* Boilerplate to set up FileReader */
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
			console.log('wb.SheetNames.length ', wb.SheetNames.length);
			if (wb.SheetNames.length > 1) {
				// setAlertMessage('Multiple worksheets found in the uploaded. Please keep only one sheet and upload again')
				setCensusError('Multiple worksheets found in the uploaded. Please keep only one sheet and upload again')
				// handleClickOpen();
				return false
			} else {
				const ws = wb.Sheets[wb.SheetNames[0]];
				/* Convert array of arrays */
				const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
				const dataObj = XLSX.utils.sheet_to_json(ws);
				console.log("handleFile - ???", { data: data, cols: makeCols(ws['!ref']), headers: [...data[0]] });
				let d = [...data]
				d.shift()
				setFileData({ data: d, dataObj: dataObj, cols: makeCols(ws['!ref']), headers: data[0] })
				console.log('data[0]', data[0]);
				// isValidHeader([...data[0]])
				if (isValidHeader(data[0])) {
					setCensusError()
					setMappingError()
					setState('done');
					// setCensusError('Multiple worksheets found in the uploaded. Please keep only one sheet and upload again')
				}
			}
		};
		if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
	};

	const isValidHeader = (headers) => {
		if (isEmptyHeader(headers)) {
			setCensusError(`Uploaded data sheet contains empty headers`)
			return false
		}
		if (isNumericHeader(headers)) {
			setCensusError(`Uploaded data sheet contains numeric values as headers`)
			return false
		}
		return true
	}

	const isEmptyHeader = (headers) => {
		let emptyHeader = headers.filter(h => {
			console.log("h ====", h);
			return !h || !('' + h).trim()
		})
		console.log('emptyHeader', emptyHeader);
		return emptyHeader.length > 0
	}

	const isNumericHeader = (headers) => {
		let numericHeader = headers.filter(h => {
			if (h)
				return !isNaN(('' + h).replace(' ', '').replace(',', '').replace('$', ''))
			return false
		})
		console.log('numericHeader', numericHeader);
		return numericHeader.length > 0
	}

	const handleInputChange = (evt) => {
		if (inputRef.current.files.length) {
			var fileSize = inputRef.current.files[0].size;
			if (fileSize > 5242880 || fileSize < 1024) {
				// setAlertMessage('File size must be between minimum 1 KB and Max 5 MB. If you want to upload files more than 5 MB, please reach out to the application support team')
				setCensusError('File size must be between minimum 1 KB and Max 5 MB. If you want to upload files more than 5 MB, please reach out to the application support team')
				// handleClickOpen();
				return false;
			} else {
				console.log('file size is correct- ' + fileSize + ' bytes');
			}
		} else {
			// setAlertMessage('Please select a valid file')
			setCensusError('Please select a valid file')
			// handleClickOpen();
			return false;
		}
		console.log('evt ==>', evt);
		handleFile(inputRef.current.files[0])
		setForm({
			...form,
			files: inputRef.current.files,
			fileName: inputRef.current.value
				? inputRef.current.files[0].name
				: ''
		});
		setMemberSaved(false)
	}

	const downloadReport = (e) => {
		console.log('downloadReport', data);
		tableToExcel(tableRef.current, 'Sheet 1', "Summary-Invoice-" + supportingDocId + "-" + moment().subtract(1, 'months').format('MMM YYYY') + '.xls')
	}

	// const downloadMemberList = () => {
	// 	console.log('downloadMemberList', members);
	// 	tableToExcel(memberTableRef.current, 'Sheet 1', "Member-List-" + moment().subtract(1, 'months').format('MMM YYYY') + '.xls')
	// }

	const updateSupportingDocument = (obj, callback) => {
		console.log('payBill', props.user);
		const uri = API_BASE_URL + "/custom/supportingDocument/" + obj._id
		axios({
			url: uri,
			method: 'PUT',
			data: obj,
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
			},
		}).then((response) => {
			console.log("data updateSupportingDocument", response);
			console.log("data", response);
			setSupportingDoc(response.data)
			setSupportingDocId(response.data._id)
			if (callback) {
				callback();
			}
			// setAlertMessage("Payment Success")
			// handleClickOpen();
		}).catch((error) => {
			console.log("error", error);
			setAlertMessage("Payment Failed");
			handleClickOpen();
		})
	}

	const getAgeBandList = (products) => {
		if (!products) {
			return [];
		}
		const ageList = products.reduce((acc, curr) => {
			const className = curr.find(c => c.toLowerCase().includes('under') || c.toLowerCase().includes('to') || c.toLowerCase().includes('over'));
			if (!className || !acc.includes(className)) {
				acc.push(className);
			}
			return acc;
		}, []).filter(c => !!c);
		setAgeBandList(ageList)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const runMemberValidation = () => {
		console.log('uId', uId);
		const uri = API_BASE_URL + "/custom/supportingDocument/member/validations";
		return request(uri, {
			method: 'POST',
			payload: { uId: uId.uId, 'entityId': props.member.entityId },
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log('res.membersList', res.membersList);
			setMemberList(res.membersList);

			setInvalidMembers(res.invalidData)
			setMissingDataMembers(res.missingData)

			setDataErrors(res.issues)
			setMemberSaved(true)
			setState('done');
			console.log("runMemberValidation", res);
		}).catch(err => {
			console.log(err);
		})
	}

	const sendToInsuranceCarrier = (e) => {
		const uri = API_BASE_URL + "/custom/supportingDocument/updateWorkflow";
		const { id, name, status, action, respondentfield, notification } = workflow;
		const workflowObj = {
			workflowId,
			currentTaskId: id,
			currentTaskName: name,
			currentStatus: status,
			currentAction: action,
			currentRespondentField: respondentfield,
			currentRespondent: employerCase[workflow.respondentfield],
			notification
		}
		return request(uri, {
			method: 'POST',
			payload: {
				documentId: supportingDocId,
				workflow: workflowObj,
				vendor: props.entity.name,
				policy: employerCase?.ControlNumber,
				billingGroup: billGroupType,
				employerName: supportingDoc.EmployerName,
				status: "Invoice Submitted"

			},
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log("sendToInsuranceCarrier", res);
			setSendDocumentOpen(true)
		}).catch(err => {
			console.log(err);
		})
	}

	const finalizePayment = (e) => {
		console.log('finalizePayment');
		updateSupportingDocument({ ...supportingDoc, finalize: true, status: "Invoice Finalized" }, null);
	}

	const getData = (collectionName, pageCount, conditions, setResponse) => {

		const uri = API_BASE_URL + "/dataconnect/data/" + collectionName
			+ "?" + (conditions ? "conditions=" + JSON.stringify(conditions) + "&" : '')
			+ "page=0&rowsPerPage=" + (pageCount ? pageCount : 10)

		axios({
			url: uri,
			method: 'GET',
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
				// appId: "5f2ab15279813563bcaa3887"
			},
		}).then((response) => {
			console.log("data", response)
			setResponse(response.data.data);


		}).catch((error) => {
			setResponse({ "error": error });
			// console.log("error", error)
		})
	}

	const getCaseData = () => {
		console.log('getCaseData');
		let conditions = [{
			"selectedConditionDataElement": "EmployerName",
			"selectedConditionOperator": "matches",
			"value": props.entity.name,
			"selectedConditionClause": "and"
		}];

		getData('Case', 1, conditions, (res) => {
			console.log('res == =>', res)
			if (res && Array.isArray(res) && res[0]) {
				let c = res[0]
				setEmployerCase(c)
			} else {
				// alert("You don't have any existing plans, Please contact your case processer.")
			}
		})
	}

	const getBankAccountDetails = () => {
		console.log('getCaseData');
		let conditions = [
			// 	{
			// 	"selectedConditionDataElement": "EmployerName",
			// 	"selectedConditionOperator": "matches",
			// 	"value": props.entity.name,
			// 	"selectedConditionClause": "and"
			// }
		];

		getData('BankAccount', 10, conditions, (res) => {
			console.log('res == => bank', res)
			if (res) {
				setBankAccounts(res)
			} else {
				// alert("You don't have any existing plans, Please contact your case processer.")
			}
		})

	}

	const submitForm = (contentType, data, setResponse) => {
		axios({
			// url: API_BASE_URL + '/dataconnect/report/SupportingDocument',
			url: API_BASE_URL + '/custom/supportingDocument/members/upload',
			method: 'POST',
			data: data,
			headers: {
				'Content-Type': contentType,
				appId: props.match.params.appid
			},
			onUploadProgress: (p) => {
				console.log("p", p);
			}
		}).then((response) => {
			setResponse(response.data);
		}).catch((error) => {
			setResponse({ "error": error });
		})
	}

	const saveSupportingDocument = () => {
		setState('loading');
		console.log('saveSupportingDocument')
		const uri = API_BASE_URL + "/custom/supportingDocument";
		var d = new Date();
		d.setMonth(d.getMonth() - 1);
		d.setDate(1)
		if (respData.billGroups && respData.billGroups.billGroupsSupportingDocuments
			&& respData.billGroups.billGroupNames.length > 0) {
			console.log('respData.billGroups.billGroupsSupportingDocumentCredit', respData.billGroups.billGroupsSupportingDocumentCredit);
			Object.keys(respData.billGroups.billGroupsSupportingDocuments).map((item, i) => {
				return request(uri, {
					method: 'POST',
					payload: {
						memberId: props.member._id,
						EmployerId: props.member.entityId,
						EmployerName: props.entity.name,
						BillingPeriod: d,
						entityId: props.member.entityId,
						document: respData.billGroups.billGroupsSupportingDocuments[item],
						billGroupName: item,
						respondent: employerCase?.CaseProcessor,
						billingRep: employerCase?.CaseProcessor,
						total: respData.billGroups.billGroupsSupportingDocumentTotal[item],
						EETotal: respData.billGroups.billGroupsSupportingDocumentEETotal[item],
						ERTotal: respData.billGroups.billGroupsSupportingDocumentERTotal[item],
						Credit: respData.billGroups.billGroupsSupportingDocumentCredit[item],
						Debit: respData.billGroups.billGroupsSupportingDocumentDebit[item],
						billType: "Automated",
						status: "Invoice Generated",
						UID: uId.uId,
						InvoiceNumber: `INV-${uId.uId}-${i + 1}`,
					},
					headers: { "appId": props.match.params.appid }
				}).then(res => {
					console.log("res", res);
					setSupportingDoc(res)
					setSupportingDocId(res._id)
					setDocMode('saved')
					updateMemberList(res._id, item);
					if (i === Object.keys(respData.billGroups.billGroupsSupportingDocuments).length - 1) {
						props.history.push(`/apps/${props.match.params.appid}/ibcSupportingDocumentView/${res._id}`)
					}
				}).catch(err => {
					console.log(err);
				})
			})
		} else {
			return request(uri, {
				method: 'POST',
				payload: {
					memberId: props.member._id,
					EmployerId: props.member.entityId,
					EmployerName: props.entity.name,
					BillingPeriod: d,
					entityId: props.member.entityId,
					document: data,
					respondent: employerCase?.CaseProcessor,
					total: respData.total,
					EETotal: respData.EETotal,
					ERTotal: respData.ERTotal,
					Credit: respData.Credit,
					Debit: respData.Debit,
					billType: "Automated",
					status: "summary invoice genarated",
					UID: uId.uId
				},
				headers: { "appId": props.match.params.appid }
			}).then(res => {
				console.log("res", res);
				setSupportingDoc(res)
				setSupportingDocId(res._id)
				setDocMode('saved')
				updateMemberList(res._id);
				props.history.push(`/apps/${props.match.params.appid}/SupportingDocs`)
			}).catch(err => {
				console.log(err);
			})
		}
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSendReportClose = () => {
		setSendDocumentOpen(false)
	}


	const handlePaymentClickOpen = () => {
		setPaymentOpen(true);
	};

	const handlePaymentClose = () => {
		setPaymentOpen(false);
	};

	const updateMemberList = (id, billGroupName) => {
		const uri = API_BASE_URL + "/custom/supportingDocument/updateMemberList";
		request(uri, {
			method: 'PUT',
			payload: {
				membersList: billGroupName
					? respData.billGroups.billGroupsData[billGroupName].map((item) => { return item._id })
					: members.map((item) => { return item._id }),
				supportingDocId: id
			},
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log("res", res);
			setState('done');
			// setMemberList(res)
		}).catch(err => {
			setState('done');
			console.log(err);
		})
	}

	const getWorkflowByName = async (name) => {
		// const uri = API_BASE_URL + "/workflows/getByName/" + name;
		const uri = `${API_BASE_URL}/workflows/getByName/${name}`;
		return request(uri, {
			method: 'GET',
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log("getWorkflowByName ===", res);
			// setMemberList(res)
			return res
		}).catch(err => {
			console.log(err);
		})
	}

	const getWorkflowEventStart = (workflowId) => {
		const uri = `${API_BASE_URL}/workflows/execute/${workflowId}/events/start`;
		console.log("	", uri)
		return request(uri, {
			method: 'GET',
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log("execute ===", res);
			// setMemberList(res)
			setWorkflow(res)
			return res
		}).catch(err => {
			console.log("========err", err)
			console.log(err);
		})
	}

	useEffect(() => {
		getCaseData();
		getBankAccountDetails();
	}, [props.entity])

	useEffect(() => {
		if (employerCase) {
			getProductList(employerCase.Products);
			getAgeBandList(employerCase.Products);
		}
	}, [employerCase])

	useEffect(() => {
		props.getEntity(props.member.entityId, props.match.params.appid);
		axios({
			url: API_BASE_URL + '/custom/setup/' + props.member.entityId,
			method: 'GET',
			headers: {
				appId: props.match.params.appid,
				'Content-Type': 'application/json',
			}
		}).then((res) => {
			console.log('response', res)
			if (res.data) {
				setSetup(res.data)
				getMandatoryfields(res.data)
			}
			if (res.data && res.data.config && res.data.categories)
				setCategories({ categories: res.data.categories });
			else
				console.log('No categories')
		}).catch((error) => {
			console.log('error', error)
			set
		})
		// TODO: Change workflow name here
		getWorkflowByName('ibc-wf-planadmin-carrier-supportingdocument').then(res => {
			if (res && res[0]) {
				setWorkflowId(res[0]._id)
				getWorkflowEventStart(res[0]._id)
			}
		})
	}, [])

	const checkMemberAgeBand = (item) => {
		let isValid = false;
		if (selectedAgeBand === "All") {
			isValid = true;
		} else if (generateAgeBand(selectedAgeBand, item["Age"])) {
			isValid = true
		}
		return isValid;
	}

	const generateAgeBand = (band, age) => {
		let obj = {}
		if (band === "All") {
			obj = {
				type: "range",
				band: band,
				min: v[0],
				max: v[1],

			}
		}
		console.log('band, rate', band);
		let v = band.split('to')
		if (v.length > 1) {
			v = v.map(b => b.trim())
			obj = {
				type: "range",
				band: band,
				min: v[0],
				max: v[1],

			}
		} else {
			v = band.split('Under')
			if (v.length > 1) {

				v = v[1].trim()
				obj = {
					type: "range",
					band: band,
					min: 0,
					max: v,

				}
			} else {
				let z = band.split('Over')
				if (z.length <= 1)
					z = band.split('Above')
				v = z[1].trim()
				obj = {
					type: "range",
					band: band,
					min: v,
					max: 100,

				}
			}
		}
		return age >= obj.min && age <= obj.max

	}

	const segregatePlans = (data) => {
		let gs = JSON.parse(JSON.stringify(groups))
		data.map(p => {
			gs.map((g, i) => {
				console.log("g, p", g, p);
				if (g.plans.includes(p.plan)) {
					gs[i].spData.push(p)
				}
			})
		})
		let groupTotals = {}
		gs.map(g => {
			groupTotals[g.name] = g.spData.reduce((r, sp) => {
				return sp.Premium + r
			}, 0)
		})
		setGroupTotals(groupTotals)
		return gs
	}

	const tableToExcel = (function () {
		//TODO: Need to replace code with react excel releated library.
		var uri = 'data:application/vnd.ms-excel;base64,',
			template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
			base64 = function (s) {
				return window.btoa(unescape(encodeURIComponent(s)))
			}, format = function (s, c) {
				return s.replace(/{(\w+)}/g, function (m, p) {
					return c[p];
				})
			}
		return function (table, name, filename) {
			if (!table.nodeType) table = document.getElementById(table)
			var ctx = {
				worksheet: name || 'Worksheet',
				table: table.innerHTML
			}
			document.getElementById("doclink").href = uri + base64(format(template, ctx));
			document.getElementById("doclink").download = filename;
			document.getElementById("doclink").traget = "_blank";
			document.getElementById("doclink").click();
		}
	})();

	const setBGType = (e) => {
		setBillGroupType(e.target.value)
		if (billGroups.billGroupsSupportingDocuments.hasOwnProperty(e.target.value)) {
			var value = billGroups.billGroupsSupportingDocuments[e.target.value];
			setData(segregatePlans(value))
			setTotal(billGroups.billGroupsSupportingDocumentTotal[e.target.value])
			setEETotal(billGroups.billGroupsSupportingDocumentEETotal[e.target.value])
			setERTotal(billGroups.billGroupsSupportingDocumentERTotal[e.target.value])
			setCredit(billGroups.billGroupsSupportingDocumentCredit[e.target.value])
			setDebit(billGroups.billGroupsSupportingDocumentDebit[e.target.value])
		}
		else {
			setTotal(respData.total)
			setEETotal(respData.EETotal)
			setERTotal(respData.ERTotal)
			setCredit(respData.Credit)
			setDebit(respData.Debit)
			setData(segregatePlans(respData.supportingDocuments))
		}
		if (billGroups.billGroupsData.hasOwnProperty(e.target.value)) {
			var value = billGroups.billGroupsData[e.target.value];
			setMemberList(value)
		}
		else {
			setMemberList(respData.membersList)
		}
	}

	const numFormat = (value) => {
		return '$ ' + (value ? value : '0').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}

	const onPaymentDateChange = (e) => {
		setPaymentDate(e.target.value);
	}
	const convertToNumber = (value, point) => {
		if (value) {
			return Number(parseFloat(('' + value).replace(/,/g, '').trim()).toFixed(point || point === 0 ? point : 3))
		}
		else return 0
	}

	const isActionButtonVisible = () => {
		let isVisible = false
		props.member && props.member.selectedRoles.map((item) => {
			if (item.label === "Plan Administrator") {
				isVisible = true
			}
		})
		return isVisible
	}

	const handleMemberClick = () => {
		console.log('handleMemberClick');
	}

	const getMandatoryfields = (setup) => {
		let mappedKeys = {}
		setup.basicKeyMapping.employeeCompensation.map(e => {
			mappedKeys[e.value] = e.key
		})
		setup.basicKeyMapping.employeeInformation.map(e => {
			mappedKeys[e.value] = e.key
		})
		setMappedKeys(mappedKeys)
	}

	console.log('state ==> ', state);
	console.log('form ==> ', form);
	console.log('supportingDoc', supportingDoc);
	console.log('groupTotals', groupTotals);
	console.log('censusError ==?', censusError)
	console.log('docStatus, state ==>', docStatus, state);
	console.log('fileData ==?==', fileData);
	const strokeColor = isIBC ? '#0094ff' : 'white';
	return (<>
		<div className='use-bootstrap ibc ibcsupportingdocument' style={isIBC ? { backgroundColor: "#f2f2f2", color: '#000' } : {}}>
			<a id="doclink" style={{ display: "none" }}></a>
			<div style={isIBC ? { overflowY: 'hidden' }:{ "marginTop": "5px", overflowX: 'hidden' }}>
				<div className="main"
				// style={isIBC ? { backgroundColor: "#f2f2f2", color: '#000' } : { "backgroundColor": "#231F20", "minHeight": "calc(100vh - 170px)" }}
				>
					<ul className={isIBC ? "breadcrumb ibc-black" : "breadcrumb"}
						style={isIBC ? { backgroundColor: "#fff", color: '#000' } : {}}
					>
						<li><a href={`/apps/${props.match.params.appid}/ibcDashboard`} style={isIBC ? { color: '#000' } : {}}>
							{"Dashboard"}
						</a></li>
						<li className="active" style={isIBC ? { color: '#000' } : {}}>
							{"Summary Invoice"}
						</li>
					</ul>
					<div className="row"
					// style={isIBC ? { background: '#fff' } : {}}
					>
						<div className="col-lg-10 col-md-offset-1" style={{ marginTop: '10px', padding: '0px' }}><h3 style={{ display: 'inline', padding: '30px 0 20px 0' }} style={isIBC ? { color: '#000' } : {}}>New Summary Invoice</h3></div>
						<div className="col-lg-10 col-md-offset-1" style={{ padding: '0px' }}><hr style={isIBC ? { "borderTop": "1px solid #000" } : { "borderTop": "1px solid #fff" }} /></div>
						<div className="row" id="upload">
							<div className="col-lg-10 col-md-offset-1" style={{ marginTop: "0px" }}>
								<span style={isIBC ? { float: 'left', color: "#000" } : { color: "#fff", fontSize: "1.6em", float: 'left' }}>{props.entity && props.entity.name}</span>
								<span style={isIBC ? { float: 'right', color: "#000" } : { color: "#fff", fontSize: "1.6em", float: 'right' }}>{"Reporting Period: " + moment().subtract(1, 'months').format('MMM YYYY')}</span>
							</div>

							<div className="col-lg-12" style={{ textAlign: "center", xbackgroundColor: "#add8e6", marginTop: "20px" }}>
								<svg height="250" width="905" styel={{ backgroundColor: "yellow" }}>
									<line id="line" x1="100" y1="100" x2="790" y2="100" stroke={strokeColor} strokeDasharray="3,2" stroke-width=".5%" />
									<circle cx="100" cy="100" r="30" stroke="none" stroke-width="3" fill={strokeColor} />
									<circle onclick="showStep('1')" cx="100" cy="100" r="45" stroke="none" stroke-width="3"
										fill="#2a9fd6" />
									<text x="100" y="110" text-anchor="middle" stroke="black" stroke-width="1px" fontSize="24"> 1</text>
									<text x="120" y="180" text-anchor="middle" stroke={strokeColor} stroke-width="0px" fill="{isIBC?#000:#fff}" fontSize="20">
										Generate Summary Invoice</text>
									<circle cx="450" cy="100" r="45" stroke="none" stroke-width="3" fill={strokeColor} />
									<circle onclick="showStep('2')" cx="450" cy="100" r="45" stroke="none" stroke-width="3"
										fill="#2a9fd6" />
									<text x="450" y="110" text-anchor="middle" stroke="black" stroke-width="1px" fontSize="24"> 2 </text>
									<text x="450" y="180" text-anchor="middle" stroke={strokeColor} stroke-width="0px" fill="{isIBC?#000:#fff}" fontSize="20">
										Send to Insurance Carrier</text>
									<circle cx="800" cy="100" r="45" stroke="none" stroke-width="3" fill={strokeColor} />
									<circle onclick="showStep('3')" cx="800" cy="100" r="45" stroke="none" stroke-width="3" fill="#2a9fd6" />
									<text x="800" y="110" text-anchor="middle" stroke="black" stroke-width="1px" fontSize="24"> 3 </text>
									<text x="800" y="180" text-anchor="middle" stroke={strokeColor} stroke-width="0px" fill="{isIBC?#000:#fff}" fontSize="20">
										Receive Acknowledgement </text>
								</svg>
							</div>

							{!docStatus && <>
								<div className="col-lg-8 col-md-offset-2" style={{ marginTop: "50px" }}>
									<div className="col-lg-3">
										<h5 style={isIBC ? { "color": "#000" } : { "color": "#fff" }}>{"Select census data"}</h5>
									</div>
									<div className="col-lg-6">
										<div style={{ width: "567px", height: "54px", backgroundColor: "#444", border: "1px dotted", display: 'flex', alignItems: 'center' }}>
											<input type='file' id="uploadInput"
												style={{ color: '#fff', marginLeft: '5px' }}
												onChange={handleInputChange}
												ref={inputRef}
												accept={'application/vnd.ms-excel,' // old browser
													+ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
													+ 'application/octet-stream,'
													// + 'application/x-csv,'
													// + 'application/csv,'
													// + 'text/x-csv,'
													// + 'text/csv,'
													// + '.csv,'
													+ 'text/x-comma-separated-values,'
													+ 'text/comma-separated-values,'
													+ 'text/tab-separated-values,'
													+ '.xslx,'
													+ '.xls'}
											/>
										</div>
										<p ><h5 id="uploadInputError" style={{ "color": "red" }}></h5></p>
									</div>
									{!memberSaved && <div className="col-lg-2">
										<button className="btn btn-lg btn-primary" id="btnAnalyze"
											onClick={e => saveMembers(e)}
											// onClick={e => uploadMembers(e)}
											disabled={!!censusError}>
											{"Upload census data"}
										</button>
									</div>}
									{/* {memberSaved && <div className="col-lg-2">
									<button className="btn btn-lg btn-primary" id="btnAnalyze"
										onClick={e => calculatePremium(e)}
										disabled={!!censusError}>
										{"Calculate Premium"}
									</button>
								</div>} */}
								</div>
							</>}
						</div>
						{!docStatus && state !== 'loading' && <>
							{censusError && <h5 style={{
								color: 'red', textAlign: 'center',
								paddingTop: '30px', paddingBottom: '20px',
								fontWeight: 'bold', width: '70%', margin: 'auto'
							}}>{censusError}</h5>}

							{
								// dataErrors && invalidMembers &&
								memberSaved &&
								<CensusValidations members={members} dataErrors={dataErrors} missingData={missingData}
									validMembers={validMembers} invalidMembers={invalidMembers} fileData={fileData}
									mappedKeys={mappedKeys} setup={setup} runMemberValidation={runMemberValidation}
									calculatePremium={calculatePremium} />
							}

							{!memberSaved && mappingError && mappingError.issues && mappingError.list &&
								<MappingError mappingError={mappingError} />
							}
						</>}

						{state === 'loading' &&
							<div className="loading" style={{ marginLeft: "500px", marginTop: "100px", color: '#000' }}>
								<h3>Loading data.....</h3>
								<img src={Loading} />
							</div>}
						{state === 'done' && docStatus &&
							<div className={isIBC ? 'ibc-summary-invoice-filters-con' : ''}>
								<select native defaultValue="" name="supporting document selection" variant="outlined" style={{
									color: "white", marginLeft: "157px", width: "", background: "#000",
									height: '50px'
								}} onChange={(e) => { console.log("e", e); setType(e.target.value) }} value={type}>
									<option value="suppotingdoc" style={{ backgroundColor: "#000" }}>Summary Invoice</option>
									<option value="memberslist" style={{ backgroundColor: "#000" }}>Members List</option>
								</select>
								{billGroups && billGroups.billGroupNames && billGroups.billGroupNames.length &&
									<>
										<span className={isIBC ? 'ibc-select-label' : ''} style={{ color: "#fff", marginLeft: "20px" }}>Bill Groups :</span>
										<select native defaultValue="" name="supporting document selection" variant="outlined" style={{
											color: "white", marginLeft: "5px", width: "", background: "#000",
											height: '50px'
										}} onChange={(e) => { console.log("e", e); setBGType(e) }} value={billGroupType}>
											<option value="All" style={{ backgroundColor: "#000" }}>All</option>
											{billGroups.billGroupNames.map((opt, i) => <option value={opt} key={i} style={{ backgroundColor: "#000" }}>{opt}</option>)}
										</select>
									</>
								}
								{type === "memberslist" &&
									<select native defaultValue="" name="supporting document selection" variant="outlined" style={{
										color: "white", background: "#000", height: '50px', padding: '10px 15px', marginLeft: "20px"
									}} onChange={(e) => { setContributionType(e.target.value); setSelectedProduct('All'); }} value={contributionType}>
										<option value="All" style={{ backgroundColor: "#000" }}>All</option>
										<option value="ER-contribution" style={{ backgroundColor: "#000" }}>ER Contribution</option>
										<option value="EE-contribution" style={{ backgroundColor: "#000" }}>EE Contribution</option>
									</select>
								}
								{type === "memberslist" &&
									<select native defaultValue="" variant="outlined" style={{
										color: "white", background: "#000", height: '50px', padding: '10px 15px', marginLeft: "20px"
									}} onChange={(e) => { setSelectedProduct(e.target.value); setContributionType('All'); }} value={selectedProduct}>
										<option value="All" style={{ backgroundColor: "#000" }}>All</option>
										{
											productList.map((p, i) => {
												return <option key={i} value={p} style={{ backgroundColor: "#000" }}>{p}</option>
											})
										}
									</select>
								}
								{type === "memberslist" &&
									<select native defaultValue="" variant="outlined" style={{
										color: "white", background: "#000", height: '50px', padding: '10px 15px', marginLeft: "20px"
									}} onChange={(e) => { setSelectedClass(e.target.value) }} value={selectedClass}>
										<option value="All" style={{ backgroundColor: "#000" }}>All</option>
										{
											classList.map((c, i) => {
												return <option key={i} value={c} style={{ backgroundColor: "#000" }}>{c}</option>
											})
										}
									</select>
								}
								{type === "memberslist" &&
									<select native defaultValue="" variant="outlined" style={{
										color: "white", background: "#000", height: '50px', padding: '10px 15px', marginLeft: "20px"
									}} onChange={(e) => { setSelectedAgeBand(e.target.value) }} value={selectedAgeBand}>
										<option value="All" style={{ backgroundColor: "#000" }}>All</option>
										{
											ageBandList.map((c, i) => {
												return <option key={i} value={c} style={{ backgroundColor: "#000" }}>{c}</option>
											})
										}
									</select>
								}
								<div id="supdoc" className="row" style={{ marginTop: "50px", marginBottom: "100px" }}>
									<div className="col-lg-1" style={{ marginTop: "20px" }}></div>
									{type === "suppotingdoc" && <>
										<SummaryInvoice
											data={data} employerCase={employerCase}
											billGroupType={billGroupType} BillingPeriod={data.BillingPeriod}
											total={total} ERTotal={ERTotal}
											EETotal={EETotal} groupTotals={groupTotals}
											Credit={Credit} Debit={Debit}
											entity={props.entity}
											respData={respData}
											tableRef={tableRef}
										/>
									</>}
									{type === "memberslist" &&

										<MembersList members={members} memberTableRef={memberTableRef}
											selectedProduct={selectedProduct} contributionType={contributionType}
											selectedClass={selectedClass} checkMemberAgeBand={checkMemberAgeBand} page={page}
											rowsPerPage={rowsPerPage} handleMemberClick={handleMemberClick} numFormat={numFormat}
											classes={classes} handleChangePage={handleChangePage}
											handleChangeRowsPerPage={handleChangeRowsPerPage}
										/>

									}

									{(docMode === 'new' || docMode === 'edit') && (type === "suppotingdoc") && <div className="col-lg-12" style={{ textAlign: 'center', marginTop: "50px" }}>
										<a className="btn btn-lg btn-primary" style={{ marginRight: "50px" }}
											onClick={e => saveSupportingDocument()}
										>Save Summary Invoice</a>
									</div>}
									{(docMode === 'saved' || docMode === 'existing') && (type === "suppotingdoc")
										&& <div className="col-lg-12" style={{ textAlign: 'center', marginTop: "50px" }}>
											{supportingDoc.finalize && <button className="btn btn-lg btn-primary"
												onClick={e => downloadReport()}
											>Download Report</button>}
											{isActionButtonVisible() && (supportingDoc.PaymentStatus ? supportingDoc.PaymentStatus.toLowerCase() : "" === "paid") && (supportingDoc.workflow ? supportingDoc.workflow.currentStatus !== "Approve" : true) &&
												<button className="btn btn-lg btn-primary" style={{ marginLeft: "50px" }} data-toggle="modal" data-target="#myModal"
													onClick={e => sendToInsuranceCarrier()}
												>{workflow && workflow.action ? workflow.action : 'Send to Insurance Carrier'}</button>
											}
											{!supportingDoc.finalize && <button className="btn btn-lg btn-primary" style={{ marginLeft: "50px" }} data-toggle="modal" data-target="#myModal"
												onClick={e => {
													setAlertMessage(`Confirm final summary invoice for the billing period : ${moment(resData.BillingPeriod).subtract(1, 'months').format('MMM YYYY')}`);
													handleClickOpen();
													setConfirmFinalize(true);
												}}
											>Finalize</button>}

											{isActionButtonVisible() && supportingDoc.finalize && payBillShow && <button className="btn btn-lg btn-primary" style={{ marginLeft: "50px" }} data-toggle="modal" data-target="#myModal"
												onClick={e => handlePaymentClickOpen()}
											>Pay the bill</button>}
										</div>}

									{(docMode === 'new' || docMode === 'edit') && (type === "memberslist") && supportingDoc.finalize &&
										<CSVLink
											style={{ textDecoration: "none", color: "#000" }}
											data={members && members.length > 0 && new Parser({ members }).parse(members)}
											filename={"Member-List-" + moment().subtract(1, 'months').format('MMM YYYY') + '.xls'}><div className="col-lg-12" style={{ textAlign: 'center', marginTop: "50px" }}>
												<a className="btn btn-lg btn-primary" style={{ marginRight: "50px" }}
												// onClick={e => downloadMemberList()}
												>Download Members List</a>
											</div></CSVLink>
									}
								</div>

								{supportingDoc && supportingDoc?.actions && supportingDoc?.actions.length > 0
									&& <AuditTrail supportingDoc={supportingDoc}
										tableRef={tableRef} />
								}
							</div>}
					</div>
				</div>
			</div>

			<ConfirmDialog open={open} handleClose={handleClose} alertMessageText={alertMessageText}
				classes={classes} confirmFinalize={confirmFinalize} setConfirmFinalize={setConfirmFinalize}
				finalizePayment={finalizePayment} />

			<SendReportDialog sendDocumentOpen={sendDocumentOpen} handleSendReportClose={handleSendReportClose}
				classes={classes} />
		</div>
	</>)
}
const mapDispatchToProps = {
	getEntity
};

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SummaryInvoiceLayout)));