import { ActionTypes } from 'constants/index';

const initialState = {
    app: {},
    loading: false,
    error: '',
    brands : [],
    brandsLoading: false,
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_APPS_LOADING: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ActionTypes.LOAD_APPS_SUCCESS: {
            return {
                ...state,
                app: action.payload,
                loading: false
            }
        }
        case ActionTypes.LOAD_APPS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.GET_BRAND_LOADING: {
            return {
                ...state,
                brandsLoading: true,
            };
        }
        case ActionTypes.GET_BRAND_SUCCESS: {
            return {
                ...state,
                brands: action.payload,
                brandsLoading: false,
            };
        }
        case ActionTypes.GET_BRAND_FAILURE: {
            return {
                ...state,
                brandsLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}