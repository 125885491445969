import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMrData, removeMRSuccessMessage } from '../../../../store/actions';
import { capitalize, getMonth } from '../../../../utility/utils';
import MixedChart from '../../../highcharts/MixedChart';
import Loader from '../../../Loader';
import PlanningErrorModal from '../../spendplan/PlanningPages/planningErrorModal';
import { convertToNumber } from '../../spendplan/PlanningPages/planningHelperFunctions';
import Header from '../../spendplan/SpendPlan/Header';
import { URLs } from '../ApiConstants';
import CockpitTable from './cockpittable';
import { selectComponent, useStyles } from './index';

const tableConfig1 = [
    {
        headingData: 'Brand Change vs. LR',
        headings: [{ title: 'Brand', rowSpan: 2, key: 'brand', leftAlign: true, isString: true },
            , { title: 'UOM', rowSpan: 2, key: 'uom', leftAlign: true, isString: true },
        { title: 'FY', colSpan: 3 },
        { title: 'JAS', colSpan: 3 },
        { title: 'OND', colSpan: 3 },
        { title: 'JFM', colSpan: 3 },
        { title: 'AMJ', colSpan: 3 },
        ],
        subHeadings: [
            { key: 'forecastQtr.FY', title: 'Forecast', },
            { key: 'previousFF.FY', title: 'Previous FF' },
            { key: 'changeFF.FY', title: 'Change Vs Previous FF' },

            { key: 'forecastQtr.JAS', title: 'Forecast' },
            { key: 'previousFF.JAS', title: 'Previous FF' },
            { key: 'changeFF.JAS', title: 'Change Vs Previous FF' },

            { key: 'forecastQtr.OND', title: 'Forecast' },
            { key: 'previousFF.OND', title: 'Previous FF' },
            { key: 'changeFF.OND', title: 'Change Vs Previous FF' },


            { key: 'forecastQtr.JFM', title: 'Forecast' },
            { key: 'previousFF.JFM', title: 'Previous FF' },
            { key: 'changeFF.JFM', title: 'Change Vs Previous FF' },


            { key: 'forecastQtr.AMJ', title: 'Forecast' },
            { key: 'previousFF.AMJ', title: 'Previous FF' },
            { key: 'changeFF.AMJ', title: 'Change Vs Previous FF' },
        ],
    },
]

const options = {
    maintainAspectRatio: true
};
const OneCr = 10000000
const RetailingSummary = (props) => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [alertMessageText, setAlertMessage] = React.useState("");
    const [brand, setBrand] = useState("All Brands");

    useEffect(() => {
        fetchData()
        getDataFromSKUMasterDataForDropdown()
    }, [props.account])

    const getFilterData = () => {
        if (props.data && props.data['spendReviewDropdown']) {
            return props.data['spendReviewDropdown'] || []
        }
        return []
    }


    const getDataFromSKUMasterDataForDropdown = () => {
        let dataModelId = '617f826eb336d60019d00226'; // TODO: need to make it configurable
        if (props.account) {
            let targetURL = "/dataconnect/filter/" + dataModelId;
            targetURL += `?type=chart`;
            targetURL += '&label=brand';
            let payload = {
                url: targetURL,
                appId: props.match.params.appid,
                dataUniqueId: 'spendReviewDropdown'
            }
            props.getMrData(payload)
        }
    }

    const fetchData = (_brand) => {
        const account = props.account;
        if (account) {
            let params = {
                fiscalYear: props.modalData?.year || account.FinancialYear,
                account: encodeURIComponent(account.AccountName),
            };
            let m = '' + getMonth(account.CurrentFF, 'monthNumber')
            params.currentFF = props.modalData?.month || (m.length === 1 ? '0' + m : m);
            let url = URLs.cockpitRetailing;
            url += `?account=${params.account}&fiscalYear=${params.fiscalYear}&currentFF=${params.currentFF}`
            if (_brand && _brand !== 'All Brands') {
                url += '&brand=' + encodeURIComponent(_brand);
            }
            let payload = {
                url: url,
                appId: props.match.params.appid,
                dataUniqueId: 'GET_dashboardRetailing'
            }
            props.getMrData(payload)
        }

    };

    useEffect(() => {
        if (props.mrData.error && props.mrData.error['GET_dashboardRetailing']) {
            setOpen(true)
            setAlertMessage({
                title: 'Retailing Summary',
                p1: props.mrData.error['GET_dashboardRetailing']?.message || 'Something went wrong',
            });
        }
    }, [props.mrData.error]);

    const handleClose = () => {
        setOpen(false);
        props.removeMRSuccessMessage({ dataUniqueId: 'GET_dashboardRetailing' });
    };

    if (props.mrData && props.mrData['GET_dashboardRetailing' + '_loading']) {
        return (
            <section className={classes.tableBodyLoading}>
                <Loader />
            </section>
        )
    }

    const getData = () => {
        if (props.data && props.data['GET_dashboardRetailing']) {
            return props.data['GET_dashboardRetailing'].result.partOne?.map(i=> ({...i, uom: i.uom === 'INR'?'INR Cr.':i.uom}));
        }
    }
    const getChartData = () => {
        if (props.data && props.data['GET_dashboardRetailing']) {
            return props.data['GET_dashboardRetailing'].result.retailing;
        }
    }
    const getLineChartData = () => {
        if (props.data && props.data['GET_dashboardRetailing']) {
            return props.data['GET_dashboardRetailing'].result.retailingIYA;
        }
    }

    const mixedChartConfig = {
        xAxis: {
            categories: getChartData() ? (Object.keys(getChartData()).map(i => capitalize(i)) || []) : []
        },
        yAxis: {
            title: {
                text: 'Retailing Cr.'
            }
        },
        labels: {
            items: []
        },
        series: [
            {
                type: 'column',
                name: 'Ret',
                data: getChartData() ? (Object.values(getChartData()).map(i => convertToNumber(i / OneCr, 2)) || []) : []
            }, {
                type: 'line',
                name: 'IYA',
                data: getLineChartData() ? (Object.values(getLineChartData()).map(i => convertToNumber(i / OneCr, 2)) || []) : [],
                marker: {
                    lineWidth: 2,
                    fillColor: 'white'
                }
            }
        ]
    }

    const onBrandChange = (v) => {
        if (v !== brand) {
            fetchData(v)
            setBrand(v)
        }

    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Header header={`Retailing Summary`} />
                <>
                    <Button
                        style={{ marginLeft: 10, width: 200 }}
                        variant="contained"
                        onClick={() => {
                            props.history.push({
                                pathname: `/apps/${props.match.params.appid}/Dashboard2`
                            });
                        }}
                        startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} />}
                        color="primary">Back to Cockpit</Button>
                </>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, }}>
            {selectComponent(['All Brands', ...(getFilterData()?.brand || [])], brand, onBrandChange)}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        style={{ width: 150, height: 38, }}
                        onClick={() => {
                            props.history.push({
                                pathname: `/apps/${props.match.params.appid}/VSOD`
                            });
                        }}
                        variant="contained"
                        color="primary">VSOD</Button>
                    <Button
                        style={{ marginLeft: 10, width: 150, height: 38, }}
                        variant="contained"
                        onClick={() => {
                            props.history.push({
                                pathname: `/apps/${props.match.params.appid}/BrandMix`
                            });
                        }
                        }
                        color="primary">Brand Mix</Button>
                    <Button
                        style={{ marginLeft: 10, width: 150, height: 38, }}
                        variant="contained"
                        onClick={() => {
                            props.history.push({
                                pathname: `/apps/${props.match.params.appid}/TopSKUsAnalysis`
                            });
                        }
                        }
                        color="primary">Top SKU Analysis</Button>
                </div>
            </div>
            <CockpitTable data={getData() || []} tableConfig={tableConfig1} />
            <h6 style={{ color: 'black', margin: 10, }}>Retailing by Month {brand === 'All Brands' ? '' : ('- ' + brand)}</h6>

            <div style={{ width: '100%', }}>
                <MixedChart page={'props.page'} data={mixedChartConfig} chartData={{}} isCustom={true}
                    dataUrl={null} styles={{}} legendOpts={null}
                    options={options} queryString={null} appId={props.appId} _id={'data._id'} />
            </div>

            <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} />

        </div>
    )
}

const mapDispatchToProps = {
    getMrData,
    removeMRSuccessMessage,
};

const mapStateToProps = state => {
    return {
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
        account: state.mrReducer?.account,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(RetailingSummary)));