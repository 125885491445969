
import { hot } from 'react-hot-loader/root'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid,
    Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const CensusError = (props) => {
    let { dataErrors } = props
    const getCount = (dataErrors) => {
        console.log('dataErrors', dataErrors);
        if (dataErrors)
            return Object.keys(dataErrors).reduce((acc, item) => {
                return acc + dataErrors[item]
            }, 0)
        return 0
    }

    const generateText = (key) => {
        if (key === "Base Annual Salary")
        return "Base Annual Salary (Not in the range)";

        if (key === "Annual Bonus")
        return "Annual Bonus (Missing/Invalid values)";

        if (key === "Child Life Volume")
        return "Child Life Volume (Missing/Invalid values)";

        if (key === "Credit")
        return "Back Credit ( Missing/Invalid values)";

        if (key === "Debit")
        return "Back Charges ( Missing/Invalid values)";

        if (key === "Employee ID")
        return "Employee ID (Missing/Invalid values)";

        if (key === "Employee Name")
        return "Employee Name (Missing/Invalid values)";

        if (key === "Age")
        return "Age (Missing/Invalid values)";
        
    }

    return (<>
        <table className={`table table-dark`}
            style={{
                width: '50%',
                margin: 'auto',
                marginTop: '50px',
                marginBottom: '50px'
            }}>
            <tr>
                <td className= {isIBC ? "ibcGroupHead" : "grouphead"}>{"Issues"}</td>
                <td className={isIBC ? "ibcGroupHead" : "grouphead"}>{getCount(dataErrors)}</td>
            </tr>
            {Object.keys(dataErrors).map(key => {
                return dataErrors[key]
                    ? <tr>
                        <th className={isIBC ? "ibcGroupBody" :""}>{generateText(key)}</th>
                        <th className={isIBC ? "ibcGroupBody" :""}>{dataErrors[key]}</th>
                    </tr>
                    : ''
            })}
        </table>

    </>)
}

const mapStateToProps = state => {
    return {
        user: state.user,
        pageLoading: state.pages.loading,
        member: state.appConfig.member,
        memberLoading: state.appConfig.memberLoading,
        entity: state.appConfig.entity ? state.appConfig.entity : {}
    };
};
export default hot(withRouter(connect(mapStateToProps, null)(CensusError)));