import { Button, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PieChart from '../../../../components/highcharts/PieChart';
import StackedChart from '../../../../components/highcharts/stackedChart';
import { getMrData, removeMRSuccessMessage } from '../../../../store/actions';
import { getMonth } from '../../../../utility/utils';
import Loader from '../../../Loader';
import PlanningErrorModal from '../../spendplan/PlanningPages/planningErrorModal';
import Header from '../../spendplan/SpendPlan/Header';
import { URLs } from '../ApiConstants';
import BrandformDialog from './brandformModel';
import CockpitTable from './cockpittable';

export const useStyles = makeStyles(theme => ({
    tableBodyLoading: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        height: 'calc(100vh - 200px)',
    },
    circularBar: {
        color: 'rgb(25, 118, 210)',
        margin: 'auto',
    },
    multiFilter: {
        border: '2px solid #ddd',
        borderRadius: 8,
        paddingLeft: 10,
        fontWeight: 'bold',
        height: 45,
    },
    active: {
        border: '2px solid tomato'
    }
}));
export const colors = ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"]

export const selectComponent = (options = [], value = '', onChange, isOptionAoO, label) => { /// AoO-> array of object
    return (
        <div className='filter-container'>
            {label &&
                <div style={{ marginTop: -14, marginLeft: 10 }}><InputLabel style={{ color: 'black', fontWeight: '600' }}>{label}</InputLabel></div>
            }
            <Select
                value={value}
                displayEmpty={true}
                className="custom-select m-right-10"
                style={{ minWidth: '100px' }}
                onChange={e => onChange(e.target.value)}>
                {
                    options.map((item, index) => isOptionAoO ?
                        (<MenuItem value={item.value} key={index}>{item.label}</MenuItem>) : (<MenuItem value={item} key={index}>{item}</MenuItem>))
                }
            </Select>
        </div>)
}


const options = {
    maintainAspectRatio: true
};

const tableConfig1 = [
    {  
        tableType: 'table1',
        subHeadings: [],
        headings: [{ title: 'Metrics', key: 'type', leftAlign: true, isString: true, isBold: true }, { title: 'UOM', key: 'uom', leftAlign: true, isString: true }, { title: 'FY Actuals', key: 'actualFF' },
        { title: 'Target', key: 'targetFF' }, { title: 'Current FF', key: 'currentFF' }, { title: 'Previous FF', key: 'previousFF' },
        { title: 'Change vs. Previous FF', key: 'changeFF' }],
    },

]


const tableConfig2 = [
    {
        tableType: 'checkbook',
        headings: [{ title: 'Change vs. LR', rowSpan: 2, key: 'brand', leftAlign: true, isString: true, isBold: true }, { title: 'Retailing', colSpan: 3 }, { title: 'SD Spends', colSpan: 3 }],
        subHeadings: [
            { key: 'retaling.currentFF', title: 'Current FF' }, { key: 'retaling.previousFF', title: 'Previous FF' }, { key: 'retaling.changeFF', title: 'Change', bg: '#abbaca' },
            { key: 'sdSpends.currentFF', title: 'Current FF', isPercent: true }, { key: 'sdSpends.previousFF', title: 'Previous FF', isPercent: true },
            { key: 'sdSpends.changeFF', title: 'Change In<br/> Spends', bg: '#abbaca' },
        ],
    },
]

const tableConfig3 = [
    {
        tableType: 'checkbook',
        headings: [{ title: 'Brand', rowSpan: 2, key: 'brand', leftAlign: true, isString: true, isBold: true }, { title: 'KBD 1 Under/Overspend', colSpan: 5 }, { title: 'TOTAL SD Under/Overspend', colSpan: 5 }],
        subHeadings: [
            { key: 'kbdObj.FY', title: 'FY', isPercent: true, bg: '#abbaca' }, { key: 'kbdObj.JAS', title: 'JAS', isPercent: true }, { key: 'kbdObj.OND', title: 'OND', isPercent: true }, { key: 'kbdObj.JFM', title: 'JFM', isPercent: true },
            { key: 'kbdObj.AMJ', title: 'AMJ', isPercent: true }, { key: 'totalObj.FY', title: 'FY', isPercent: true, bg: '#abbaca' }, { key: 'totalObj.JAS', title: 'JAS', isPercent: true },
            { key: 'totalObj.OND', title: 'OND', isPercent: true }, { key: 'totalObj.JFM', title: 'JFM', isPercent: true }, { key: 'totalObj.AMJ', title: 'AMJ', isPercent: true },
        ],
    },
]



const MRDashboard2 = (props) => {

    const classes = useStyles();

    const [dialog, setDialog] = useState({ open: false, data: null });
    const [open, setOpen] = useState(false);
    const [alertMessageText, setAlertMessage] = React.useState("");
    useEffect(() => {
        fetchData()
    }, [props.account])

    const fetchData = () => {
        const account = props.account;
        if (account) {
            let params = {
                fiscalYear: props.modalData?.year || account.FinancialYear,
                account: encodeURIComponent(account.AccountName),
            };
            let m = '' + getMonth(account.CurrentFF, 'monthNumber')
            params.currentFF = props.modalData?.month || (m.length === 1 ? '0' + m : m);
            let url = URLs.getDashboardCockpitData;
            url += `?account=${params.account}&fiscalYear=${params.fiscalYear}&currentFF=${params.currentFF}`
            let payload = {
                url: url,
                appId: props.match.params.appid,
                dataUniqueId: 'GET_dashboardCockpit'
            }
            props.getMrData(payload)
        }

    };

    const onClickEvent = (e) => {
        setDialog({
            open: true,
            data: e.point.name,
        });
    }

    const getData = () => {
        if (props.data && props.data['GET_dashboardCockpit']) {
            return props.data['GET_dashboardCockpit'].result.data;
        }
    }

    const pieChartData = {
        "labels": getData()?.brandMix.map(i => i.brand) || [],
        events: {
            click: function (event) {
                console.log('events', event);
            }
        },
        "datasets": [
            {
                "data": getData()?.brandMix?.map(i => i.ratio) || [],
                "label": ""
            }
        ],
    }

    // const stackChartOption = useMemo(() => {
    //     return ({
    //         credits: {
    //             enabled: false
    //         },
    //         title: {
    //             text: null,
    //         },
    //         xAxis: {
    //             categories: ['']
    //         },
    //         yAxis: {
    //             min: 0,
    //             title: {
    //                 text: undefined
    //             }
    //         },
    //         legend: {
    //             reversed: true
    //         },
    //         plotOptions: {
    //             series: {
    //                 stacking: 'normal'
    //             }
    //         },
    //         colors: colors,
    //         series: getData()?.vsodChartData || []
    //     })
    // }, [getData()?.vsodChartData])


    useEffect(() => {
        if (props.mrData.error && props.mrData.error['GET_dashboardCockpit']) {
            setOpen(true)
            setAlertMessage({
                title: 'Cockpit',
                p1: props.mrData.error['GET_dashboardCockpit']?.message || 'Something went wrong',
            });
        }
    }, [props.mrData.error]);


    const handleClose = () => {
        setOpen(false);
        props.removeMRSuccessMessage({ dataUniqueId: 'GET_dashboardCockpit' });
    };

    if (props.mrData && props.mrData['GET_dashboardCockpit' + '_loading']) {
        return (
            <section className={classes.tableBodyLoading}>
                <Loader />
            </section>
        )
    }

    return <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10, }}>
            <Header header={`COCKPIT`} />
            <>
                <Button
                    style={{ width: 200 }}
                    onClick={() => {
                        props.history.push({
                            pathname: `/apps/${props.match.params.appid}/RetailingSummary`
                        });
                    }}
                    variant="contained"
                    color="primary">Retailing Summary</Button>
                <Button
                    style={{ marginLeft: 10, width: 200 }}
                    variant="contained"
                    onClick={() => {
                        props.history.push({
                            pathname: `/apps/${props.match.params.appid}/SpendsReviewsummary`
                        });
                    }
                    }
                    color="primary">Spend Summary</Button>
            </>
        </div>
        <CockpitTable data={getData()?.section1 || []} tableConfig={tableConfig1} />
        <div style={{ display: 'flex' }}>
            <div style={{ width: '35%', }}>
                <h6 style={{ color: 'black', textAlign: 'center', margin: 10, }}>Brand Mix</h6>
                <PieChart page={'props.page'} data={pieChartData} chartData={{}} onClickEvent={onClickEvent}
                    dataUrl={null} styles={{}} legendOpts={null}
                    options={options} queryString={null} />
            </div>
            <div style={{ width: '65%' }}>
                <h6 style={{ color: 'black', textAlign: 'center', margin: 10, }}>Checkbook</h6>
                <CockpitTable data={getData()?.section4 || []} tableConfig={tableConfig3} />
            </div>
        </div>
        <div>
            <CockpitTable data={getData()?.section5 || []} tableConfig={tableConfig2} />
        </div>
        <BrandformDialog allData={getData()?.brandForm} open={dialog.open} brand={dialog.data} handleClose={() => setDialog({ open: false, data: null })} />
        <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} />
    </div>
}

const mapDispatchToProps = {
    getMrData,
    removeMRSuccessMessage,
};

const mapStateToProps = state => {
    return {
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
        account: state.mrReducer?.account,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(MRDashboard2)));