import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  convertToNumber,
  renderNoDataText,
  setSticky,
  StyledTableCell,
  StyledTableRow,
} from '../../PlanningPages/planningHelperFunctions';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ConfirmDialog from '../../SpendPlan/ConfirmDialog';
import Loader from '../../../../Loader';

const useStyles = makeStyles(theme => ({
  rowBold: {
    backgroundColor: '#c0d4ec !important',
    '& span': {
      fontWeight: 'bold !important',
    },
  },
  darkBG: {
    backgroundColor: '#0874c4 !important',
    '& span': {
      fontWeight: 'bold !important',
      color: '#fff',
    },
  },
  tableBodyLoading: {
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100vh - 200px)',
  },
  BG1: {
    backgroundColor: '#bfbfbf !important',
  },
  BG2: {
    backgroundColor: '#ffff99 !important',
  },
  yellowBG: {
    backgroundColor: '#ffff99 !important',
  },
  paddingLeft: {
    paddingLeft: 10,
  },
  green: {
    backgroundColor: '#05af50 !important',
  },
  red: {
    backgroundColor: '#ff0000 !important',
  },
  white: {
    backgroundColor: '#ffffff !important',
  },
}));

const SpendReconciliationTable = (props) => {

  const { loading, mrEcommSpendReconciliationData, error } = props.data;
  let headerOrder = 0;
  const classes = useStyles();
  const [fieldData, setFieldData] = useState([]);

  useEffect(() => {
    setSticky();
  });

  useEffect(() => {
    if (mrEcommSpendReconciliationData) {
      mapDataToView(props.modalData);
    } else if (fieldData) {
      setFieldData([])
    }
  }, [mrEcommSpendReconciliationData]);

  const mapDataToView = (isVersionedData) => {
    try {
      let obj1 = {};
      let response = isVersionedData ? (mrEcommSpendReconciliationData[0] ? (mrEcommSpendReconciliationData[0].detail || []) : []) : mrEcommSpendReconciliationData
      response.forEach(item => {
        if (!obj1[item.Row]) {
          obj1[item.Row] = {};
        }
        if (item.Title === 'Difference (BOP - MR Team)') {
          obj1[item.Row] = { ...obj1[item.Row], mr: item };
        }
        if (item.Title === 'As per MR Ecomm Team') {
          obj1[item.Row] = { ...obj1[item.Row], mrEcomm: item };
        }
        if (item.Title === 'BOP - As per MS&P PM') {
          obj1[item.Row] = { ...obj1[item.Row], msAndP: item };
        }
        if (item.Title === 'As per MR Ecomm Team - LY') {
          obj1[item.Row] = { ...obj1[item.Row], mrEcommLY: item };
        }
      });
      let _fieldData = Object.values(obj1);
      setFieldData(_fieldData);
    } catch (e) {
      console.log(e)
    }
  };

  const highlightedBGRows = [22, 39, 56, 62];
  const BoldRows = [12, 23, 29, 40, 46, 57, 60, 61];
  const PercentageRows = [23, 40, 57, 62];

  const getPercentageVal = (val) => {
    return convertToNumber(val, 2) + '%'
  };

  const getColouredGBClass = (itemRow, column, value) => {
    let _class;
    if (['KBD 1 TFI', 'KBD 2 TFI'].includes(itemRow.mr?.Metrics) && column) {
      if (value > 0) {
        _class = classes.green;
      }
      if (value < 0) {
        _class = classes.red;
      }
      if (value === 0) {
        _class = classes.white;
      }
    }
    if (itemRow.mr?.Metrics === 'FY 2021 Retailing' && itemRow.mr.Channel === 'MR' && column) {
      if (value > 0) {
        _class = classes.green;
      }
      if (value < 0) {
        _class = classes.red;
      }
      if (value === 0) {
        _class = classes.white;
      }
    }
    return _class;
  }

  const getClassForColoredBG = (itemRow) => {
    if (itemRow.mr?.Metrics === 'PY Retailing') {
      return classes.BG1
    }
    if (itemRow.mr?.Metrics === 'KBD 1 + KBD 1 TFI') {
      return classes.BG2
    }
    return '';
  }

  if (loading) {
    return (
      <section className={classes.tableBodyLoading}>
        <Loader />
      </section>
    );
  }

  const getTitle = (value, isPercent) => {
    return (value + (isPercent ? '%' : ''))
  }

  const getValue = (isPercent, value, lastMonthValue, uomVal) => {
    let formatting = uomVal?.includes('INR') ? 'en-In' : 'en-US'
    return (isPercent ? getPercentageVal(value) : convertToNumber(value, 1).toLocaleString(formatting))
    // + (lastMonthValue === undefined ? '' : (" (" + convertToNumber(lastMonthValue, isPercent ? 2 : 1).toLocaleString(formatting) + (isPercent ? '%' : '') + ")"))
  }

  return (
    <div className='spend-reconciliation'>
      <TableContainer className='table-container' component={Paper}>
        <Table className={classNames('table', 'tpr-table')} aria-label="simple table" stickyHeader>
          <TableHead className='table-head'>
            <TableRow className='table-head-row'>
              <TableCell rowSpan={2} data-order={headerOrder++} className="sticky_column"><span>Channel</span></TableCell>
              <TableCell rowSpan={2} data-order={headerOrder++} className="sticky_column"><span>Metrics</span></TableCell>
              <TableCell rowSpan={2} data-order={headerOrder++} className="sticky_column"><span>UoM</span></TableCell>
              <TableCell className='black-font' colSpan="5" style={{ borderBottom: 'none' }}>
                <p className='combined-header-heading'>BOP - As per MS&P PM</p>
              </TableCell>
              <TableCell className='black-font' colSpan="5" style={{ borderBottom: 'none' }}>
                <p className='combined-header-heading'>As per MR Ecomm Team</p>
              </TableCell>
              <TableCell className='black-font' colSpan="5" style={{ borderBottom: 'none' }}>
                <p className='combined-header-heading'>Difference (BOP - MR Team)</p>
              </TableCell>
              <TableCell className='black-font' colSpan="5" style={{ borderBottom: 'none' }}>
                <p className='combined-header-heading'>Previous FF - As per MR Ecomm Team</p>
              </TableCell>
            </TableRow>
            <TableRow className='table-head-row'>
              {
                ['JAS', 'OND', 'JFM', 'AMJ', 'FY'].map(i => {
                  return <TableCell key={i}><p><span>{i}</span></p></TableCell>
                })
              }
              {
                ['JAS', 'OND', 'JFM', 'AMJ', 'FY'].map(i => {
                  return <TableCell key={i + '1'}><p><span>{i}</span></p></TableCell>
                })
              }
              {
                ['JAS', 'OND', 'JFM', 'AMJ', 'FY'].map(i => {
                  return <TableCell key={i + '2'}><p><span>{i}</span></p></TableCell>
                })
              }
              {
                ['JAS', 'OND', 'JFM', 'AMJ', 'FY'].map(i => {
                  return <TableCell key={i + '3'}><p><span>{i}</span></p></TableCell>
                })
              }
            </TableRow>
          </TableHead>
          <TableBody className='table-body'>
            {fieldData && fieldData.length > 0 ? fieldData.map((row, index) => {
              let order = 0;
              let isMsAndPPercentage = PercentageRows.includes(row.msAndP?.Row);
              let isMrEcommPercentage = PercentageRows.includes(row.mrEcomm?.Row);
              let isMRPercentage = PercentageRows.includes(row.mr?.Row);
              const class1 = `${getClassForColoredBG(row)} ${highlightedBGRows.includes(row.mr?.Row) ? classes.darkBG : BoldRows.includes(row.mr?.Row) ? classes.rowBold : ''}`
              const class2 = (!highlightedBGRows.includes(row.mr?.Row) && !BoldRows.includes(row.mr?.Row)) ? classes.yellowBG : '';
              {
                return (
                  <>
                    {[24, 41, 60].includes(row.mr?.Row) &&
                      (
                        React.Children.toArray(
                          <>
                            <StyledTableRow key={index * 100} style={{ height: 12 }}>
                              <TableCell style={{ backgroundColor: '#fff' }} colSpan="3"><span>{' '}</span></TableCell>
                            </StyledTableRow>
                            <StyledTableRow key={index * 100 + 1} style={{ height: 12 }}>
                              <TableCell style={{ backgroundColor: '#fff' }} colSpan="3"><span>{' '}</span></TableCell>
                            </StyledTableRow>
                          </>
                        )
                      )
                    }

                    <StyledTableRow key={index}>
                      <TableCell className={`sticky_body_column align-left ${class1}`}
                        id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                        <span className={classes.paddingLeft}> {row.mr?.Channel}</span>
                      </TableCell>

                      <TableCell className={`sticky_body_column align-left ${class1}`}
                        id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                        <span className={classes.paddingLeft}> {row.mr?.Metrics}</span>
                      </TableCell>

                      <TableCell
                        className={`sticky_body_column ${class1}`}
                        id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                        <span className={classes.paddingLeft}> {row.mr?.UoM}</span>
                      </TableCell>

                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.msAndP?.JAS, isMsAndPPercentage)}>
                            {getValue(isMsAndPPercentage, row.msAndP?.JAS, row.msAndP?.lastMonthJAS, row.msAndP?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.msAndP?.OND, isMsAndPPercentage)}>
                            {getValue(isMsAndPPercentage, row.msAndP?.OND, row.msAndP?.lastMonthOND, row.msAndP?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.msAndP?.JFM, isMsAndPPercentage)}>
                            {getValue(isMsAndPPercentage, row.msAndP?.JFM, row.msAndP?.lastMonthJFM, row.msAndP?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.msAndP?.AMJ, isMsAndPPercentage)}>
                            {getValue(isMsAndPPercentage, row.msAndP?.AMJ, row.msAndP?.lastMonthAMJ, row.msAndP?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={classNames(class1, class2)}>
                        <p>
                          <span title={getTitle(row.msAndP?.FY, isMsAndPPercentage)}>
                            {getValue(isMsAndPPercentage, row.msAndP?.FY, row.msAndP?.lastMonthFY, row.msAndP?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>

                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.mrEcomm?.JAS, isMrEcommPercentage)}>
                            {getValue(isMrEcommPercentage, row.mrEcomm?.JAS, row.mrEcomm?.lastMonthJAS, row.mrEcomm?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.mrEcomm?.OND, isMrEcommPercentage)}>
                            {getValue(isMrEcommPercentage, row.mrEcomm?.OND, row.mrEcomm?.lastMonthOND, row.mrEcomm?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.mrEcomm?.JFM, isMrEcommPercentage)}>
                            {getValue(isMrEcommPercentage, row.mrEcomm?.JFM, row.mrEcomm?.lastMonthJFM, row.mrEcomm?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.mrEcomm?.AMJ, isMrEcommPercentage)}>
                            {getValue(isMrEcommPercentage, row.mrEcomm?.AMJ, row.mrEcomm?.lastMonthAMJ, row.mrEcomm?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={classNames(class1, class2)}>
                        <p>
                          <span title={getTitle(row.mrEcomm?.FY, isMrEcommPercentage)}>
                            {getValue(isMrEcommPercentage, row.mrEcomm?.FY, row.mrEcomm?.lastMonthFY, row.mrEcomm?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>


                      <StyledTableCell className={classNames(class1, getColouredGBClass(row, 'JAS', row.mr?.JAS))}>
                        <p>
                          <span title={getTitle(row.mr?.JAS, isMRPercentage)}>
                            {getValue(isMRPercentage, row.mr?.JAS, row.mr?.lastMonthJAS, row.mr?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={classNames(class1, getColouredGBClass(row, 'OND', row.mr?.OND))}>
                        <p>
                          <span title={getTitle(row.mr?.OND, isMRPercentage)}>
                            {getValue(isMRPercentage, row.mr?.OND, row.mr?.lastMonthOND, row.mr?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={classNames(class1, getColouredGBClass(row, 'JFM', row.mr?.JFM))}>
                        <p>
                          <span title={getTitle(row.mr?.JFM, isMRPercentage)}>
                            {getValue(isMRPercentage, row.mr?.JFM, row.mr?.lastMonthJFM, row.mr?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={classNames(class1, getColouredGBClass(row, 'AMJ', row.mr?.AMJ))}>
                        <p>
                          <span title={getTitle(row.mr?.AMJ, isMRPercentage)}>
                            {getValue(isMRPercentage, row.mr?.AMJ, row.mr?.lastMonthAMJ, row.mr?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>

                      <StyledTableCell className={classNames(class1, class2, getColouredGBClass(row, 'FY', row.mr?.FY))}>
                        <p>
                          <span title={getTitle(row.mr?.FY, isMRPercentage)}>
                            {getValue(isMRPercentage, row.mr?.FY, row.mr?.lastMonthFY, row.mr?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>


                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.mrEcommLY?.JAS, isMrEcommPercentage)}>
                            {getValue(isMrEcommPercentage, row.mrEcommLY?.JAS, row.mrEcommLY?.lastMonthJAS, row.mrEcommLY?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.mrEcommLY?.OND, isMrEcommPercentage)}>
                            {getValue(isMrEcommPercentage, row.mrEcommLY?.OND, row.mrEcommLY?.lastMonthOND, row.mrEcommLY?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.mrEcommLY?.JFM, isMrEcommPercentage)}>
                            {getValue(isMrEcommPercentage, row.mrEcommLY?.JFM, row.mrEcommLY?.lastMonthJFM, row.mrEcommLY?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={class1}>
                        <p>
                          <span title={getTitle(row.mrEcommLY?.AMJ, isMrEcommPercentage)}>
                            {getValue(isMrEcommPercentage, row.mrEcommLY?.AMJ, row.mrEcommLY?.lastMonthAMJ, row.mrEcommLY?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell className={classNames(class1, class2)}>
                        <p>
                          <span title={getTitle(row.mrEcommLY?.FY, isMrEcommPercentage)}>
                            {getValue(isMrEcommPercentage, row.mrEcommLY?.FY, row.mrEcommLY?.lastMonthFY, row.mrEcommLY?.UoM)}
                          </span>
                        </p>
                      </StyledTableCell>

                    </StyledTableRow>
                  </>
                );
              }
            }) :
              renderNoDataText()
            }
          </TableBody>
        </Table>
      </TableContainer>

      {
        error && <ConfirmDialog open={error !== null} handleClose={props.clearError} alertMessageText={{
          title: 'Error Loading Page',
          p2: 'There was an error loading this content. Please reload the page or try again later.', p4: error
        }} classes={classes} />
      }

    </div >
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    data: state.MrEcommSpendReconciliationReducer,
  };
};

export default hot(withRouter(connect(mapStateToProps, null)(SpendReconciliationTable)));
