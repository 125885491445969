import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { TextFieldFormsy } from 'components/formsy';
import {
	withStyles, Button, Divider, Typography, InputAdornment, Icon, Box,
	TextField, form, FormControl, FormLabel, CircularProgress,
} from '@material-ui/core';
import { submitLogin, emptyPrevPath, loadApps } from 'store/actions';
import history from 'modules/history';
import { validateEmail } from '../../modules/common'
import Loading from '../Loading';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isSpendPlan = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'spendplan';

const useStyles = theme => ({
	input: {
		backgroundColor: '#fff'
	},
	loginBox: {
		marginTop: '40px'
	},
	circularBar: {
		color: '#ffff',
		height: 20,
		width: 20,
	},
});

const LoginBox = (props) => {
	let initialState = {
		canSubmit: false
	};
	const [state, setState] = useState(initialState)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState({ email: false, password: false })

	let form = React.createRef();

	const disableButton = () => {
		setState({ canSubmit: false });
	};

	const enableButton = () => {
		setState({ canSubmit: true });
	};

	const onSubmit = (model) => {
		props.submitLogin(model);
	};

	const loginHandler = (login) => {
		if (props.login.error && (props.login.error.email || props.login.error.password)) {
			disableButton();
		}

		if (props.login && props.login.success) {
			if (props.navigation.prevPath) {
				history.push({
					pathname: props.navigation.prevPath,
					search: props.navigation.prevSearch
				});
				props.emptyPrevPath();
			} else if (!props.app?.length) {
				props.loadApps(props.user?.orgId);
				/*if (!props.appLoading && props.app && props.app[0] && (props.app.length === 1 || isSpendPlan)) {
					history.push({
						pathname: `/apps/${props.app[0]._id}`
					});
				} else {
					if (!props.appLoading || props.appError) {
						history.push({
							pathname: '/apps'
						});
					}
				}*/
			}
		}
		return null;
	}

	useEffect(() => {
		if (props.login && props.login.success) {
			if (!props.appLoading && props.app && props.app[0] && props.app.length === 1) {
				let app = props.app[0];
				// let app = props.app.find(a => a.dappCode === "TSP9be0mf")
				history.push({
					pathname: `/apps/${app ? app._id : props.app[0]._id}`
				});
			} else {
				if (!props.appLoading || props.appError) {
					history.push({
						pathname: '/apps'
					});
				}
			}
		}
	}, [props.app, props.appLoading, props.appError]);

	useEffect(() => {
		loginHandler()
		console.log("props.login", props.login);
	}, [props.login]);

	const classes = useStyles();

	const handleBlur = (event) => {
		if (!validateEmail(event.target.value)) {
			setError({ ...error, email: event.target.value && event.target.value.length > 0 ? "Invalid Email" : "Enter Email" });
		} else {
			setError({ ...error, email: false });
		}
	}

	const canSubmit = ({ email, password }) => {
		if (validateEmail(email)) {
			return !email || !password
		} else {
			return true;
		}
	}

	const handleSubmit = (e, credentials) => {
		e.preventDefault()
		credentials.email && credentials.password && props.submitLogin(credentials)
	}

	useEffect(() => {
		let error = props?.login?.error;
		if (error) {
			error?.email && error?.password
				&& props.showAlert("NETWORK ERROR: Please verify your internet connection", {
					id: "login",
					variant: 'danger',
					icon: 'times-circle'
				})
			if (error.email) {
				setError({ ...error, email: error.email })
			} else if (error.password) {
				setError({ ...error, password: error.password })
			} else if (error.message) {
				setError({ ...error, email: error.message })
			}
		}
	}, [props?.login?.error])

	return (
		<div className={classes.root}>
			<div className='loginForm'>
				<form noValidate
					onSubmit={(e) => handleSubmit(e, { email, password })}>
					<FormControl>
						<FormLabel variant="h6" className="label-headings" htmlFor="email">
							Email
						</FormLabel>
						<TextField
							variant="outlined"
							required
							fullWidth
							type="email"
							id="email"
							name="email"
							onBlur={handleBlur}
							placeholder="Enter Email Address"
							autoComplete="email"
							autoFocus
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							helperText={error.email && error.email}
							error={Boolean(error.email)}
						/>
					</FormControl>
					<FormControl>
						<FormLabel variant="h6" className="label-headings" htmlFor="password">
							Password
						</FormLabel>
						<TextField
							variant="outlined"
							required
							fullWidth
							name="password"
							type="password"
							placeholder="Enter Password"
							id="password"
							autoComplete="current-password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							helperText={error.password && error.password}
							error={Boolean(error.password)}
						/>
					</FormControl>
					<FormControl>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							disabled={canSubmit({ email, password })}
						>
							{(props.login && props.login.isLoading) ?
								<CircularProgress className={classes.circularBar} size={24} /> :
								"Sign In"
							}
						</Button>
					</FormControl>
				</form>
			</div>
		</div>
	)
}


const mapDispatchToProps = {
	loadApps,
	submitLogin,
	emptyPrevPath
};

const mapStateToProps = ({ user, login, navigation, app }) => {
	return {
		login,
		user,
		navigation,
		app: app.app,
		appError: app.error,
		appLoading: app.loading,
	}
}

export default hot(connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginBox));
