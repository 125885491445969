import { request } from './../client';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export class DataConnectService {
	
	static getMRAccounts(data) {
		const uri = API_BASE_URL + "/mrspendplan/account"
		return request(uri, {
			method: 'GET',
			payload: data.data,
			headers: {
				appId: data.appId,
			}
		})
	}

}
