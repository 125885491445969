import { Checkbox, ClickAwayListener, FormControlLabel, FormGroup, IconButton, makeStyles, Paper } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';

export const useStyles = makeStyles(() => ({
    paperColumn: {
        border: "1px solid #cecece",
        maxHeight: 360,
        width: 225,
        overflowY: "auto",
    },
    container: {
        color: "rgba(0, 0, 0, 0.87)",
        display: "inline-block",
        alignSelf: 'center',
    },
    icon: { cursor: 'pointer', color: '#8a8787', marginLeft: 10,  marginTop: 2,},
    iconBox: {
        display: 'flex',
        border: '2px solid #cecece',
        height: 40,
        borderRadius: 8,
        padding: '0 15px',
        alignItems: 'center',
        cursor: 'pointer',
    }
}));

const ViewColumns = ({ columns = [], onChange }) => {

    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const onOutsideClick = (event) => {
        if (anchorEl?.current && anchorEl.current.contains(event.target)) {
            return;
        }
        setAnchorEl(null);
    }

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div className={classes.container}>
            <div className={classes.iconBox} onClick={handleClick}>
                <span style={{ alignSelf: 'center' }}>Columns</span>
                <ViewColumnIcon className={classes.icon} />
            </div>
            <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 10, }}>
                <ClickAwayListener onClickAway={onOutsideClick}>
                    <Paper className={classes.paperColumn}>
                        <FormGroup>
                        <FormControlLabel key={'0'} style={{
                                    margin: "0",
                                    height: 35,
                                }}
                                    control={
                                        <Checkbox
                                            style={{
                                                transform: "scale(0.7)",
                                            }}
                                            disableRipple
                                            disableFocusRipple
                                            disableTouchRipple
                                            checked={columns.every(ii=> ii.isActive)}
                                            onChange={(e) => onChange('all', e.target.checked)}
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label={'Select All'}
                                    value={'all'}
                                />
                            {Array.isArray(columns) && columns.map((item, i) => {
                                return <FormControlLabel key={i} style={{
                                    margin: "0",
                                    height: 35,
                                    borderTop:"1px solid #cecece"
                                }}
                                    control={
                                        <Checkbox
                                            style={{
                                                transform: "scale(0.7)",
                                            }}
                                            disableRipple
                                            disableFocusRipple
                                            disableTouchRipple
                                            checked={item.isActive}
                                            onChange={(e) => onChange(i, e.target.checked)}
                                            name={item.key}
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label={item.title}
                                    value={item.key}
                                />
                            })}
                        </FormGroup>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}

export default hot(ViewColumns);
