import React from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

import Picture1 from 'images/spendplan/Picture1.png'
import Picture2 from 'images/spendplan/Picture2.png'
import Picture3 from 'images/spendplan/Picture3.png'
import Picture4 from 'images/spendplan/Picture4.png'
import Picture5 from 'images/spendplan/Picture5.png'

let pictures = [Picture2, Picture2, Picture3, Picture4, Picture5]
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;

const useStyles = makeStyles(theme => ({
	paperTable: {
		textAlign: 'left',
		padding: '5px 15px',
		borderRadius: 0,
		margin: '0 10px'
	},
	anchor: {
		fontSize: '14px !important',
		textDecoration: 'underline !important',
	},
	panel: {
		marginBottom: '20px',
		backgroundColor: '#222222',
		border: '1px solid transparent',
		// borderRadius: '4px',
		boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)',
	},
	panelDefault: {
		borderColor: '#282828',
	},
	panelHeading: {
		color: '#888888',
		// backgroundColor: '#3c3c3c',
		borderColor: '#282828',
		textAlign: 'left',
		// padding: '13px',
		borderBottom: '4px solid #000',
		// marginBottom: '13px',
		'@media(max-width:1366px)': {
			// padding: 5,
			borderBottom: '1px solid #000',
			// marginBottom: 5,
		},
	},
	headingStyle: {
		fontSize: '25px',
		'@media(max-width:1366px)': {
			fontSize: 20,
		},
		fontWeight: 'bold',
		color: '#000',
		display: 'flex',
		alignItems: 'center',
	},
	rowStyle: {
		cursor: 'pointer'
	},
	svgIcon: {
		marginRight: 10,
		fontSize: 21,
		'@media(max-width:1366px)': {
			fontSize: 18,
		},
	},
	headerRowStyle: {
		fontWeight: 'normal'
	}
}));

let topFivePlans = [{
	brandform: "Ariel Perfect Wash",
	currentBudgetInMillions: 2.1,
	previousBudgetInMillions: 2.5,
	"SKU Salience %": '8.33 %',
	"Spend %": "5O %",
	"VSOD %": "90 %",
	"Budget": "$13 M"

}, {
	brandform: "Ariel Perfect Wash Rs. 2",
	currentBudgetInMillions: 2.1,
	previousBudgetInMillions: 2.5,
	"SKU Salience %": '8.33 %',
	"Spend %": "5O %",
	"VSOD %": "90 %",
	"Budget": "$8 M"
}, {
	brandform: "Ariel Base Bags",
	currentBudgetInMillions: 2.1,
	previousBudgetInMillions: 2.5,
	"SKU Salience %": '8.33 %',
	"Spend %": "5O %",
	"VSOD %": "90 %",
	"Budget": "$7 M"
}, {
	brandform: "Ariel Matic Powders",
	currentBudgetInMillions: 2.1,
	previousBudgetInMillions: 2.5,
	"SKU Salience %": '8.33 %',
	"Spend %": "5O %",
	"VSOD %": "90 %",
	"Budget": "$4 M"
}, {
	brandform: "Ariel Matic Liquids",
	currentBudgetInMillions: 2.1,
	previousBudgetInMillions: 2.5,
	"SKU Salience %": '8.33 %',
	"Spend %": "5O %",
	"VSOD %": "90 %",
	"Budget": "$2 M"
}]
function TopFivePlans(props) {
	// const { topFivePlans } = props;
	const classes = useStyles();

	const convertToNumber = (value, point) => {
		if (value) {
			return Number(parseFloat(('' + value).replace(/,/g, '').trim()).toFixed(point || point === 0 ? point : 2))
		}
		else return 0
	}

	const redirectToPlan = (item) => {
		const { brandform, cluster, channel, planName, month, brand, currentFF, fiscalYear } = item;
		// props.history.push({
		// 	pathname: "/apps/" + props.match.params.appid + "/TradePriceReduction",
		// 	search: `brandForm=${encodeURIComponent(brandform)}&cluster=${cluster}&channel=${encodeURIComponent(channel)}&month=${encodeURIComponent(currentFF)}&brand=${encodeURIComponent(brand)}&planMonth=${encodeURIComponent(month)}&year=${encodeURIComponent(fiscalYear)}&planName=${encodeURIComponent(planName)}`,
		// })
	}

	const getPic = (i) => {
		return pictures[i]
	}

	return (<Paper className={classes.paperTable}>
		<div className={(classes.panel, classes.paneldefault)}>
			<div className={classes.panelHeading}>
				<div className={classes.headingStyle}>
					<DescriptionIcon className={classes.svgIcon} />
					{"TOP 5 Ariel PLANS"}
				</div>
			</div>
			<table
				className={`table table-dark spnd-table-container spnd-report-table`}
				style={{ width: '100%' }} >
				{/* <thead>
					<tr>
						<th>{""}</th>
						<th style={{ textAlign: "center" }}>{"SKU Salience %"}</th>
						<th style={{ textAlign: "center" }}>{"Spend %"}</th>
						<th style={{ textAlign: "center" }}>{"VSOD %"}</th>
						<th style={{ textAlign: "center" }}>{"Budget"}</th>
					</tr>
				</thead> */}
				<tbody>
					{topFivePlans && topFivePlans.map((item, i) => {
						return (<>
							<tr key={i} onClick={() => redirectToPlan(item)} className={classes.rowStyle}>
								<td title={item.brandform} style={{
									padding: '0px !important',
									margin: '0px !important'
								}} className="img">
									<img style={{
										width: '50px',
										padding: '0px !important',
										margin: '0px !important'
									}} src={getPic(i)} />
								</td>
								<td style={{ textAlign: "center" }}>
									<div>{"SKU Salience %"}</div>
									<div style={{ fontWeight: 'bold' }}>{item['SKU Salience %']}</div>
								</td>
								<td style={{ textAlign: "center" }}>
									<div>{"Spend %"}</div>
									<div style={{ fontWeight: 'bold' }}>{item['Spend %']}</div>
								</td>
								<td style={{ textAlign: "center" }}>
									<div>{"VSOD %"}</div>
									<div style={{ fontWeight: 'bold' }}>{item['VSOD %']}</div>
								</td>
								<td style={{ textAlign: "center" }}>
									<div>{"Budget"}</div>
									<div style={{ fontWeight: 'bold' }}>{item['Budget']}</div>
								</td>
							</tr>
						</>);
					})}
				</tbody>
			</table>
		</div>
	</Paper>);
}

export default TopFivePlans;
