import React, { useState, useEffect } from 'react';
import { fakeTableData } from './fakeData/SetupJson';

export default function SetupTable(props) {
	// const { tableData } = props;
	console.log(fakeTableData, 'here');
	const [state, setState] = useState({ tableData: props.tableData });
	// const {fields} = fakeTableData
	// const renderTableData = () => {
	//   for (let key in tableData) {
	//   }
	// };
	useEffect(() => {
		let newData = setModifiedData(state.tableData);
		setState({ ...state, tableData: newData })
	}, []);

	const setModifiedData = tableData => {
		const data = JSON.parse(JSON.stringify(tableData));
		let newFieldData = data.fields.map((field, ind) => {
			let pref = field.shift().value;
			return field.map((item, ind) => {
				if (item.id >= 3) {
					item.value = `@${pref}(${item.value ?? ""})`;
					return item;
				} else return item;
			});
		});
		return {
			...data,
			fields: newFieldData
		}
		// console.log(newData,"ksksks");
	};

	return (
		<div className="measure-table" style={{margin: '0px auto'}}>
			{state.tableData.groups.map((group, ind) => (
				<div className="table-parent" key={ind}>
					{group.map(item => {
						return <div className="table-value">{item.value ? item.value : ''}</div>;
					})}
				</div>
			))}

			{state.tableData.fields.map((field, ind) => (
				<div className="table-parent" key={ind}>
					{field.map(item => {
						return <div className={`table-value${item.input ? '-head' : ''}`}>{item.value ? item.value : ''}</div>;
					})}
				</div>
			))}
		</div>
	);
}
