const resultData = [
  {
    plan:
      'Benefit Plan: Basic Accidental Death and\rDismemberment (AD&D) - Televised Co.\rEmployee (Employee)',
    ageband: '(Blank)',
    rate: 0.02,
    metrics: [
      {
        coverage: '176,353,000',
      },
      {
        participants: '1,492',
      },
      {
        premium: '$3,527.06',
      },
    ],
  },
  {
    plan: 'Basic Life - Televised Co. Employee (Employee)',
    ageband: '(Blank)',
    rate: 0.032,
    metrics: [
      {
        coverage: '176,353,000',
      },
      {
        participants: '1,492',
      },
      {
        premium: '$5,643.30',
      },
    ],
  },
  {
    plan: 'Child Life - Televised Co.(Children) ',
    ageband: '(Blank) ',
    rate: 0.2,
    metrics: [
      {
        coverage: ' 1, 940, 000 ',
      },
      {
        participants: '194',
      },
      {
        premium: '$485.00',
      },
    ],
  },
  {
    plan: 'Long Term Disability (LTD) Class 1\rTelevised Co. (Employee)',
    ageband: '(Blank)',
    rate: 0.08800000000000001,
    metrics: [
      {
        coverage: '269,661',
      },
      {
        participants: '20',
      },
      {
        premium: '$467.02',
      },
    ],
  },
  {
    plan: 'Long Term Disability (LTD) Class 2\rTelevised Co. (Employee)',
    ageband: '(Blank)',
    rate: 0.08800000000000001,
    metrics: [
      {
        coverage: '6,447,144',
      },
      {
        participants: '1,198',
      },
      {
        premium: '$9,589.97',
      },
    ],
  },
  {
    plan: 'Long Term Disability (LTD) Class 3\rTelevised Co. (Employee)',
    ageband: '(Blank)',
    rate: 0.172,
    metrics: [
      {
        coverage: '11,959',
      },
      {
        participants: '1',
      },
      {
        premium: '$20.64',
      },
    ],
  },
  {
    plan: 'Long Term Disability Premier Class 1 rTelevised Co.(Employee) ',
    ageband: '(Blank) ',
    rate: 0.08800000000000001,
    metrics: [
      {
        coverage: ' 371, 640 ',
      },
      {
        participants: '27',
      },
      {
        premium: '$639.09',
      },
    ],
  },
  {
    plan: 'Long Term Disability Premier Class 2\rTelevised Co. (Employee) ',
    ageband: '(Blank) ',
    rate: 0.08800000000000001,
    metrics: [
      {
        coverage: ' 1, 484, 680 ',
      },
      {
        participants: '245',
      },
      {
        premium: '$2,209.58',
      },
    ],
  },
  {
    plan: 'Long Term Disability Premier Class 3\rTelevised Co. (Employee)',
    ageband: '(Blank)',
    rate: 0.172,
    metrics: [
      {
        coverage: '14,000',
      },
      {
        participants: '1',
      },
      {
        premium: '$24.08',
      },
    ],
  },
  {
    plan: 'Short Term Disability (STD) - Televised Co.\r(Employee)',
    ageband: '(Blank)',
    rate: 2.4,
    metrics: [
      {
        coverage: '1,761,906',
      },
      {
        participants: '1,473',
      },
      {
        premium: '$3,535.20',
      },
    ],
  },
  {
    plan: 'Short Term Disability (STD) NJ Televised Co.\r(Employee) ',
    ageband: '(Blank) ',
    rate: 9.76,
    metrics: [
      {
        coverage: ' 26, 668 ',
      },
      {
        participants: '19',
      },
      {
        premium: '$185.44',
      },
    ],
  },
];

export default resultData.map(val => {
  if (val.metrics) {
    for (let i = 0; i < val['metrics'].length; i++) {
      val[Object.keys(val.metrics[i])[0]] = Object.values(val.metrics[i])[0];
    }
    delete val.metrics;
    return val;
  }
});
