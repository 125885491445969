import React from 'react';
import { ReactComponent as Logo } from 'images/logo1.svg';

export default class Header extends React.PureComponent {
  handleClickLogout = () => {};

  render() {
    const { header, user } = { ...this.props };

    const styled = {
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingRight: '0.5rem',
      marginRight: '.5rem',
      borderRight: '2px solid #ffffff',
    };
    const styledLeft = {
      display: 'inline-block',
      color: '#ffffff',
      verticalAlign: 'middle',
    };
    const LogoStyle = {
      marginRight: '5px',
      verticalAlign: 'middle',
    };
    const headerBox = {
      backgroundColor: '#515151',
      color: '#ffffff',
      padding: '.5rem 1rem',
    };
    const signOutBox = {
      top: '25%',
      position: 'relative',
    };
    const headerTitle = {
      fontSize: '25px',
    };

    const rightHeader = {
      display: 'flex',
      float: 'right',
      marginTop: '0.4rem',
      alignItems: 'center',
    };

    return (
      <div style={headerBox}>
        <Logo width="2rem" height="2rem" style={LogoStyle} />
        {/* <img src={Logo} /> */}
        <div style={styledLeft}>
          <div style={headerTitle}>{header.title}</div>
          <div>{header.subTitle}</div>
        </div>
        <div style={rightHeader}>
          <div style={styled}>
            <div>{user.name}</div>
            <div>{user.role}</div>
          </div>
          <a onClick={this.handleClickLogout}>
            <div style={signOutBox}>Sign out</div>
          </a>
        </div>
      </div>
    );
  }
}
