import React from 'react';
import {
    TableHead,
    TableSortLabel,
    TableCell,
    TableRow,
    Checkbox,
    Tooltip,
    IconButton,
    Icon,
    Menu,
    MenuList,
    MenuItem,
    ListItemIcon,
    ListItemText,
    withStyles
} from '@material-ui/core';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';
const styles = theme => ({
    root: {
        '& .MuiTableSortLabel-root':{
            fontWeight:'bold'
        }
    },
    actionsButtonWrapper: {
        position: 'absolute',
        top: 0,
        left: 64,
        width: 64,
        height: 63,
        zIndex: 10,
        background: theme.palette.background.paper,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    }
});

class CustomTableHead extends React.Component {
    state = {
        selectedMenu: null
    };

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    openSelectedMenu = (event) => {
        this.setState({ selectedMenu: event.currentTarget });
    };

    closeSelectedMenu = () => {
        this.setState({ selectedMenu: null });
    };


    setCellProps = (cellValue) => {
        let b = {};
        chartData.backgrounds && chartData.backgrounds.map(bg => {
            if (bg.selectedBackgroundDataElement === value
                && bg.selectedBackgroundFieldValue === cellValue) {
                b.style = { backgroundColor: bg.selectedBackgroundValue }
            }
        })
        console.log("cellValue", cellValue, cellValue === "Verify", label, value, b);
        return b;
        // className: classnames({
        // 	[this.props.classes.NameCell]: value === "Complete"
        // })
    }

    getTableHeader = (data) => {
        let tableHeader = [];
        let { chartData } = this.props;
        if (chartData.selectedDataModel && chartData.selectedDataElement
            && !chartData.loadChildData) {
            console.log("chartData.selectedDataElement", chartData.selectedDataElement);
            chartData.selectedDataElement.map(element => {
                let { label, value } = element;
                if (value != '_id') {
                    let headerItem = {
                        id: label.replace(' ', ''),
                        name: value,
                        label: label,
                        disablePadding: false,
                        numeric: false,
                        numeric: false,
                        sort: true
                    }
                    tableHeader.push(headerItem)
                }
            })
        } else {
            const { columns } = data;
            if (columns && columns.length > 0) {
                columns.forEach(element => {
                    const { name, label } = element;
                    tableHeader.push({
                        name,
                        label,
                        sort: true,
                        numeric: false,
                        numeric: false,
                        disablePadding: false,
                        id: name.replace(' ', ''),
                    })
                });
            }
        }
        return tableHeader;
    };


    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, classes, hasCheckbox } = this.props;
        const { selectedMenu } = this.state;

        let tableHeader = this.getTableHeader(this.props.data);
        return (
            <TableHead className={classes.root} 
            style={isIBC?{borderBottom:'1px solid #eee'}:{
            }}
            >
                <TableRow className="h-64">
                    {hasCheckbox && <TableCell padding="checkbox" className="relative pl-4 sm:pl-12 table-general"
                    >
                        <Checkbox
                            style={{ backgroundColor: "#fff" }}
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                        {numSelected > 0 && (
                            <div className={classes.actionsButtonWrapper}>
                                <IconButton
                                    aria-owns={selectedMenu ? 'selectedMenu' : null}
                                    aria-haspopup="true"
                                    onClick={this.openSelectedMenu}
                                >
                                    <Icon>more_horiz</Icon>
                                </IconButton>
                                <Menu
                                    id="selectedMenu"
                                    anchorEl={selectedMenu}
                                    open={Boolean(selectedMenu)}
                                    onClose={this.closeSelectedMenu}
                                >
                                    <MenuList>
                                        <MenuItem
                                            onClick={() => {
                                                this.closeSelectedMenu();
                                            }}
                                        >
                                            <ListItemIcon className={classes.icon}>
                                                <Icon>delete</Icon>
                                            </ListItemIcon>
                                            <ListItemText inset primary="Remove" />
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </div>
                        )}
                    </TableCell>}
                    {tableHeader.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric.toString()}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                                className="table-general"
                            >
                                {row.sort && (
                                    <Tooltip
                                        title="Sort"
                                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={this.createSortHandler(row.id)}
                                        >
                                            {row.label}
                                        </TableSortLabel>
                                    </Tooltip>
                                )}
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CustomTableHead);