export function generateBarData(element, d) {
	let { data, groupByXAxis, barYAxis } = element;
	d.xAxis.categories = data.labels;
	if (groupByXAxis) {
		d.yAxis.title.text = element.barYAxis.label;
		d.series = []
		data.datasets.map((ds, i) => {
			d.series.push({
				name: ds.label,
				data: ds.data
			})
		})
	} else {
		if (data.stacked) {
			d.yAxis.title.text = element.metric;
			d.series = data.datasets.map((item, ind) => ({
				name: data.datasets[ind].label,
				data: data.datasets[ind].data,
			}));
		} else {
			d.yAxis.title.text = data.datasets[0].label;
			d.series = [
				{
					name: data.datasets[0].label,
					data: data.datasets[0].data,
				},
			];
		}
	}
	return d;
}

export function getDefaultBarChart() {
	return {
		xAxis: {
			categories: ['Africa', 'America', 'Asia', 'Europe', 'Oceania'],
			title: {
				text: null
			}
		},
		yAxis: {
			min: 0,
			title: {
				text: 'Population (millions)',
				align: 'high'
			},
			labels: {
				overflow: 'justify'
			}
		},
		series: [{
			name: 'Year 1800',
			data: [107, 31, 635, 203, 2]
		}, {
			name: 'Year 1900',
			data: [133, 156, 947, 408, 6]
		}, {
			name: 'Year 2000',
			data: [814, 841, 3714, 727, 31]
		}, {
			name: 'Year 2016',
			data: [1216, 1001, 4436, 738, 40]
		}]
	}
}
export function getBarChartConfig(data, renderTo, type, isStacked) {
	return {
		chart: {
			type: isStacked ? 'bar' : type || 'bar', //bar, column
			renderTo: renderTo,
      height: '50%',
		},
		title: {
			text: ''
		},
		tooltip: {
			valueSuffix: ''
		},
		...(isStacked
			? {
				legend: {
					reversed: true,
				},
				plotOptions: {
					series: {
						stacking: 'normal',
					},
				},
			}
			: {
				plotOptions: {
					bar: {
						dataLabels: {
							enabled: true
						}
					}
				},
				legend: {
					layout: 'vertical',
					align: 'right',
					verticalAlign: 'bottom',
					x: -80,
					y: 15,
					floating: true,
					// backgroundColor:
					// 	Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
				},
			}),
		credits: {
			enabled: false
		},
		xAxis: data.xAxis,
		yAxis: data.yAxis,
		series: data.series
	}
}
