/**
 * @module Actions/SpendPlan
 * @desc SpendPlan Actions
*/
import { ActionTypes } from 'constants/index';

export const getBrands = (appid) => dispatch => {
	dispatch({ type: ActionTypes.GET_BRANDS, appid });
};

export const getBrandForms = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_BRANDFORMS, payload: data });
};

export const getChannels = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_CHANNELS, payload: data });
};

export const getPlanFilterDataTPR = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_PLAN_FILTER_DATA_TPR, payload: data });
}

export const checkSpBGprocesses = (payload) => dispatch => {
	dispatch({ type: ActionTypes.CHECK_SP_BG_PROCESS, payload: payload });
}

export const checkFreezeProcess = (payload) => dispatch => {
	dispatch({ type: ActionTypes.CHECK_FREEZE_PLAN_PROCESS, payload: payload });
}