
import { hot } from 'react-hot-loader/root'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const SummaryInvoice = (props) => {

	let {
		data, entity, employerCase, billGroupType, BillingPeriod,
		total, ERTotal, EETotal, groupTotals,
		Credit, Debit, respData, tableRef
	} = props

	const getDashletPremium = (optionName, noFormat) => {
		if (!respData || (!respData.supportingDocuments && !respData.document)) {
			return numFormat(0)
		}
		let document
		if (respData.supportingDocuments)
			document = respData.supportingDocuments?.find(({ plan }) => plan === optionName || (optionName === 'Child Life' && plan === 'Dependent Life'));
		else if (respData.document)
			document = respData.document?.find(({ plan }) => plan === optionName || (optionName === 'Child Life' && plan === 'Dependent Life'));
		else
			return numFormat(0)
		if (!document) {
			return numFormat(0)
		}
		if (noFormat) {
			return document.Premium
		}
		return numFormat(convertToNumber(document.Premium, 0));
	}

	const getPlanName = (optionName) => {
		let document
		if (respData.supportingDocuments)
			document = respData.supportingDocuments?.find(({ plan }) => {
				return plan === optionName || (optionName === 'Child Life' && plan === 'Dependent Life')
			});
		else if (respData.document)
			document = respData.document?.find(({ plan }) => {
				return plan === optionName || (optionName === 'Child Life' && plan === 'Dependent Life')
			});
		console.log('document???', document);
		return document?.plan
	}

	const numFormat = (value) => {
		return '$ ' + (value ? value : '0').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}

	const convertToNumber = (value, point) => {
		if (value) {
			return Number(parseFloat(('' + value).replace(/,/g, '').trim()).toFixed(point || point === 0 ? point : 3))
		}
		else return 0
	}

	console.log('props ===> ', props);

	const bgColor = isIBC ? '#fff' : '#000';
	return (<>
		<div className="col-lg-10" style={{ xborder: "1px solid #000" }}>
			<div style={{ backgroundColor: bgColor }}>
				<div style={{ backgroundColor: bgColor, padding: "5px 5px 0 15px" }}>
					<h3 className={isIBC ? 'ibc-main-h3' : ''} style={{ textAlign: 'center' }}>{"Summary Invoice"}</h3>
				</div>
				<div className={isIBC ? 'ibc-siv-text-con' : 'siv-text-con'}>
					<h5 style={{ textAlign: 'center' }}>
						{`Billing Period : ${moment(BillingPeriod).format('MMM YYYY')}`}
					</h5>
				</div>
				<div className={isIBC ? 'ibc-siv-text-con' : 'siv-text-con'} style={{ padding: "0 5px 0 15px", overflow: 'hidden' }}>
					<span style={{ float: 'left' }}>
						<h6 style={{ margin: 0 }}>{`Employer : ${entity && entity.name}`}</h6>
					</span>
					<span style={{ float: 'right', paddingRight: '15px' }}>
						<h6 style={{ margin: 0 }}>{`Remit Payment to : `}</h6>
					</span>
				</div>
				<div className={isIBC ? 'ibc-siv-text-con-pad' : 'siv-text-con-pad'}>
					<h6>{`Control Number : ${employerCase.ControlNumber}`}</h6>
				</div>
				<div className={isIBC ? 'ibc-siv-text-con-pad' : 'siv-text-con-pad'}
					style={{ backgroundColor: bgColor, padding: "0 5px 0px 15px" }}>
					<h6>{`Billing Group : ${billGroupType ? billGroupType : respData.billGroupName}`}</h6>
				</div>
				{/* <div className={isIBC ? 'ibc-siv-text-con-pad' : 'siv-text-con-pad'}
					style={{ backgroundColor: bgColor, padding: "0 5px 5px 15px" }}>
					<h6>{`Total Invoiced Amount : ${numFormat(convertToNumber(total, 0))}`}</h6>
				</div> */}
				<div className="dashlet">
					<div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4 >{"Total Invoiced Amount"}</h4>
						<h3 >{numFormat(convertToNumber(total, 0))}</h3>
					</div>
					<div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4>{"Total Fee"}</h4>
						<h3>{getDashletPremium('FMLA/ADA Administration')}</h3>
					</div>
					{/* <div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4 >{"ER Contribution"}</h4>
						<h3 >{numFormat(convertToNumber(ERTotal, 0))}</h3>
					</div> */}
					<div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4 >{"Total Premium Amount"}</h4>
						<h3 >{numFormat(convertToNumber(total, 0) - convertToNumber(getDashletPremium('FMLA/ADA Administration', true), 0))}</h3>
					</div>
					<div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4 >{"ER Paid Benefits"}</h4>
						<h3 >{numFormat(convertToNumber(groupTotals['Group Benefits'], 0))}</h3>
					</div>
					<div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4 >{"Voluntary Benefits"}</h4>
						<h3 >{numFormat(convertToNumber(groupTotals['Voluntary Benefits'], 0))}</h3>
					</div>
					{/* <div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4 >{"EE Contribution"}</h4>
						<h3 >{numFormat(convertToNumber(EETotal, 0))}</h3>
					</div> */}
					<div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4>{'Employee Optional Life'}</h4>
						<h3>
							{getDashletPremium('Employee Optional Life')}
						</h3>
					</div>
					<div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4>{'Spouse Life'}</h4>
						<h3>{getDashletPremium('Spouse Life')}</h3>
					</div>
					<div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4>{getPlanName('Child Life')}</h4>
						{/* <h4>{'Child Life'}</h4> */}
						<h3>{getDashletPremium('Child Life')}</h3>
					</div>
					<div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4>{'Back Credit'}</h4>
						<h3>{numFormat(convertToNumber(Credit, 0))}</h3>
					</div>
					<div className={`inner-dashlet ${isIBC ? 'ibc-dashlet' : ''}`}>
						<h4>{"Back Charges"}</h4>
						<h3>{numFormat(convertToNumber(Debit, 0))}</h3>
					</div>
				</div>
			</div>
			<table className={`table ${isIBC ? 'ibc-table-dark' : 'table-dark'}`} ref={tableRef}>
				<tr>
					<th>Product</th>
					<th>Coverage Amount/Volume</th>
					<th>Participants</th>
					<th>Rate</th>
					<th className="last">Premium</th>
				</tr>

				{data?.map(innerData => {
					let subTotal = 0
					let r = []
					r.push(<tr>
						<td className="grouphead" style={{ fontSize: "17px" }}>{innerData.name}</td>
						<td className="grouphead" ></td>
						<td className="grouphead" ></td>
						<td className="grouphead" ></td>
						<td className="grouphead" ></td>
					</tr>)
					{
						innerData?.spData?.map(d => {
							subTotal = subTotal + d.Premium
							r.push(<tr>
								<td>{innerData.ERPaidPercentage ? d.plan + " - " + innerData.ERPaidPercentage : d.plan}</td>
								<td className="right">{numFormat(convertToNumber(d.Coverage, 0))}</td>
								<td className="right">{d.Participants}</td>
								<td className="right">{d.Rate}</td>
								<td className="right last">{numFormat(convertToNumber(d.Premium, 0))}</td>
							</tr>)
							if (!d.rows) {
								r.push(<tr>
									<td className="second right">Sub total:</td>
									<td className="second right">{numFormat(convertToNumber(d.Coverage, 0))}</td>
									<td className="second right">{d.Participants}</td>
									<td className="second right">{d.Rate}</td>
									<td className="second right last">{numFormat(convertToNumber(d.Premium, 0))}</td>
								</tr>)
								return r
							} else {
								r = [...r, d.rows.map(dr => {
									return <tr>
										<td className="second right">{dr.plan}</td>
										<td className="second right">{numFormat(convertToNumber(dr.Coverage, 0))}</td>
										<td className="second right">{dr.Participants}</td>
										<td className="second right">{dr.Rate}</td>
										<td className="second right last">{numFormat(convertToNumber(dr.Premium, 0))}</td>
									</tr>
								})]
								r.push(<tr>
									<td className="second right">Sub total:</td>
									<td className="second right">{numFormat(convertToNumber(d.Coverage, 0))}</td>
									<td className="second right">{d.Participants}</td>
									<td className="second right">{d.Rate}</td>
									<td className="second right last">{numFormat(convertToNumber(d.Premium, 0))}</td>
								</tr>)
							}
						})
					}
					r.push(<tr>
						<td className="grouptail">{innerData.subTotal}</td>
						<td className="grouptail" ></td>
						<td className="grouptail" ></td>
						<td className="grouptail" ></td>
						<td className="grouptail" style={{ textAlign: 'center' }} >{numFormat(convertToNumber(subTotal, 0))}</td>
					</tr>)
					return r
				})}
				<tr>
					<td className="eeer">{"ER Paid Benefits"}</td>
					<td className="eeer" ></td>
					<td className="eeer" ></td>
					<td className="eeer" ></td>
					<td className="eeer" style={{ textAlign: 'center' }}>
						{numFormat(convertToNumber(ERTotal, 0))}
					</td>
				</tr>
				<tr>
					<td className="eeer">{"Voluntary Benefits"}</td>
					<td className="eeer" ></td>
					<td className="eeer" ></td>
					<td className="eeer" ></td>
					<td className="eeer" style={{ textAlign: 'center' }}>
						{numFormat(convertToNumber(EETotal, 0))}
					</td>
				</tr>
				<tr>
					<td className="adjustments">{"Back Credit"}</td>
					<td className="adjustments" ></td>
					<td className="adjustments" ></td>
					<td className="adjustments" ></td>
					<td className="adjustments" style={{ textAlign: 'center' }}>
						{numFormat(convertToNumber(Credit, 0))}
					</td>
				</tr>
				<tr>
					<td className="adjustments">{"Back Charges"}</td>
					<td className="adjustments" ></td>
					<td className="adjustments" ></td>
					<td className="adjustments" ></td>
					<td className="adjustments" style={{ textAlign: 'center' }}>
						{numFormat(convertToNumber(Debit, 0))}
					</td>
				</tr>
				<tr>
					<td className="total grouphead right"></td>
					<td className="total grouphead right"><b>Total Invoiced Amount:</b></td>
					<td className="total grouphead right"></td>
					<td className="total grouphead right"></td>
					<td className="total grouphead last" style={{ textAlign: 'center' }}><b>{numFormat(convertToNumber(total, 0))}</b></td>
				</tr>
			</table>
		</div>
	</>)
}

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(
	connect(mapStateToProps, null)(SummaryInvoice)));