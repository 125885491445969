import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Imar1Report from "../ReportsPages/IMARReports/Imar1Report/index";
import { getFiscalYearFormat } from "../spendplanUtils";
import { Transition, useStyles } from './archieveHelpers';

const IMAR1Modal = props => {
    const { open, handleClose } = props;
    const modalData = props.modalData ? props.modalData : '';

    const classes = useStyles();

    const { tprReducer } = props;
    const { selectBrand } = tprReducer;
    const [brand, setBrand] = useState(selectBrand);

    useEffect(() => {
        if (
            selectBrand &&
            typeof selectBrand === 'object' &&
            selectBrand !== null &&
            Object.keys(selectBrand).length > 0
        ) {
            setBrand(selectBrand);
        }
    }, [selectBrand]);

    let versionTitle = `IMAR - MSASD Process - Version - ${modalData.monthName} ${getFiscalYearFormat(modalData.year)}`;

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            disableBackdropClick={true}
            aria-labelledby="Recon Review"
            aria-describedby="Recon Review Table"
            className={classes.confirmationDialog}
        >
            <DialogContent>
                <Imar1Report versionTitle={versionTitle} modalData={modalData} brandData={brand} />
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleClose}
                    className="btn btn-primary btn-color border-radius-0 text-color-white"
                >
                    {'Close'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user,
        tprReducer: state.tprReducer,
    };
};

export default hot(withRouter(connect(mapStateToProps, null)(IMAR1Modal)));
