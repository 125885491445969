import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AppService } from '../../../../modules/api/index';
import { getFileNameWithTimeStamp, getMonth } from '../../../../utility/utils';
import MRPlanLinks from '../../spendplan/GlobalLinks/mrPlanLinks';
import { getFiscalYearFormat, MonthsNumberMapping } from '../../spendplan/spendplanUtils';
import { sortOrder } from '../ApiConstants';
import MRSheetTable from './index';
import FileDownload from "js-file-download";
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const MrSheetTableWrapper = props => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100)
    const [conditions, setConditions] = useState([])
    const [filters, setFilters] = useState({});
    const [state, setState] = useState({ data: [], paste: false })
    const { mrReducer } = props
    const [edited, setEdited] = useState(false)
    const [fyActualData, setLastFyActualData] = useState([])
    const [loading, setLoading] = useState(false)
    const [fyActualsLastFFSum, setFyActualsLastFFSum] = useState(null)
    const modalData = props.modalData ? props.modalData : ''

    const handleChangePage = (event, page) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = event => {
        setPage(0);
        setRowsPerPage(event.target.value)
    };

    const getLastFFConds = (conds) => {
        let _conditions = conds
        return _conditions.map(cc => {
            if (cc.selectedConditionDataElement === 'fiscalYear') {
                cc.value = "" + Number(mrReducer?.account?.FinancialYear - 1)
            }

            if (cc.selectedConditionDataElement === 'currentFF') {
                cc.value = "06"
            }
            return cc;
        })

    }

    const postDataToAPI = (url, payload) => {
        setLoading(true)
        AppService.postDataToAPIUrl(url, props.match.params.appid, payload)
            .then(res => {
                setLastFyActualData(res?.list);
                // setFyActualsLastFFSum(res?.count)
                let totalSum = {
                    value: {},
                    volume: {}
                };
                if (res?.list?.length > 0) {
                    res.list.forEach(li => {
                        if (li.value) {
                            Object.keys(li.value).map(key => {
                                if (totalSum.value.hasOwnProperty(key)) {
                                    totalSum.value[key] += li.value[key]
                                } else {
                                    totalSum.value[key] = li.value[key]
                                }
                            })
                        }
                        if (li.volume) {
                            Object.keys(li.volume).map(key => {
                                if (totalSum.volume.hasOwnProperty(key)) {
                                    totalSum.volume[key] += li.volume[key]
                                } else {
                                    totalSum.volume[key] = li.volume[key]
                                }
                            })
                        }
                    })
                }
                setFyActualsLastFFSum(totalSum)
                setState({ ...state, count: res.count, total: res.count, totalSum: totalSum })
                setLoading(false)
                setIsFiltersAdded(false);
                setIsConditionChange(false);
                setIsPageChange(false);
                setIsRowsPerPageChange(false);
                setClearAllFilters(false);
                if (props.type === 'claims') {
                    for (let i = 0; i < res?.list?.length; i++) {
                        if (!res.list[i]['subBrandForm']) {
                            res.list[i]['subBrandForm'] = '';
                        }
                    }
                }
                getAppliedFilterInfo('again');
                // setState({ ...state, data: res.list, count: res.count, totalSum: res.totalSum, total: res.total })
            }).catch(() => {
                setLoading(false)
            })
    }

    const fetchData = async (conditions, p, rpp, isDownload) => {
        let conds = [];
        if (mrReducer?.account || modalData.account) {
            if (['skuMapping', 'fyActuals', 'fyActualspff', 'pyskulevel'].includes(props.type)) {
                conds.push({
                    "selectedConditionDataElement": 'account',
                    "selectedConditionOperator": "matches",
                    "value": "" + encodeURIComponent(modalData?.account || mrReducer.account?.AccountName),
                    "selectedConditionClause": "and"
                })
            }
        }
        if (mrReducer?.account?.FinancialYear && props.type !== 'pyskulevel') {
            conds.push({
                "selectedConditionDataElement": 'fiscalYear',
                "selectedConditionOperator": "matches",
                "value": "" + Number(modalData?.year || mrReducer?.account?.FinancialYear),
                "selectedConditionClause": "and"
            })
        }
        if (mrReducer?.account?.FinancialYear && props.type === 'pyskulevel') {
            conds.push({
                "selectedConditionDataElement": 'fiscalYear',
                "selectedConditionOperator": "matches",
                "value": "" + (Number(modalData?.year || mrReducer?.account?.FinancialYear) - 1),
                "selectedConditionClause": "and"
            })
        }
        if (MonthsNumberMapping[mrReducer?.account?.CurrentFF] && props.type !== 'pyskulevel') {
            conds.push({
                "selectedConditionDataElement": 'currentFF',
                "selectedConditionOperator": "matches",
                "value": "" + (modalData?.month || MonthsNumberMapping[mrReducer?.account?.CurrentFF]),
                "selectedConditionClause": "and"
            })
        }

        if (conditions)
            conds = [...conds, ...conditions]
        let selectedDataElement = []
        let targetURL = API_BASE_URL + "/dataconnect/" + (isDownload ? 'download/' : 'table/') + props?.page?.pageLevelDataModel;
        const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
        targetURL += `?type=list&`;
        if (props.type === 'fyActuals' || props.type === 'fyActualspff' || props.type === 'skuMapping') {
            targetURL += `sum=totalMR&`;
        }
        targetURL += '&page=' + (p || page) + '&rowsPerPage=' + (rpp || rowsPerPage) + "&";
        targetURL += `sortOrder=${encodeURIComponent(JSON.stringify(sortOrder[props.type]))}&`;
        targetURL += (conds ? "conditions=" + encodeURIComponent(JSON.stringify(conds)) + "&" : '')
        targetURL += selectedDataElement.reduce(reducer, '&fields=');
        if (isDownload) {
            return targetURL;
        }
        if (props.type === 'fyActuals') {
            const payload = {
                data: {
                    account: modalData?.account || mrReducer.account?.AccountName,
                    fiscalYear: "" + Number(modalData?.year || mrReducer?.account?.FinancialYear),
                    currentFF: (modalData?.month || MonthsNumberMapping[mrReducer?.account?.CurrentFF]),
                },
                pagination: {
                    page: (p || page).toString(),
                    rowsPerPage: (rpp || rowsPerPage).toString()
                },
                // sortOrder: {}
            }
            if (Object.keys(filters).length) {
                for (const [key, value] of Object.entries(filters)) {
                    payload.data[key] = [...value];
                }
            }
            let targetURL = API_BASE_URL + "/mrspendplan/setup/mr-fyactual-retailing"
            await postDataToAPI(targetURL, payload)
        } else {
            getApiDataFromDataUrl(targetURL)
        }
    }

    const getFileTitle = (accountName, fyFF) => {
        if (props.page.name === 'Fy Actuals pff') {
            return getFileNameWithTimeStamp('FY Actuals ' + getFiscalYearFormat(props.account?.FinancialYear, true) + '-' + accountName + '-' + getFiscalYearFormat(props.account?.FinancialYear))
        }
        return getFileNameWithTimeStamp(props.page.name + '-' + accountName + '-' + fyFF);
    }

    const onDownload = async () => {
        let url = await fetchData(conditions, 0, state.count, true)
        const { account } = props;
        if (account) {
            if (props.type === 'fyActuals') {
                const payload = {
                    data: {
                        account: modalData?.account || mrReducer.account?.AccountName,
                        fiscalYear: "" + Number(modalData?.year || mrReducer?.account?.FinancialYear),
                        currentFF: (modalData?.month || MonthsNumberMapping[mrReducer?.account?.CurrentFF])
                    },
                }
                url = API_BASE_URL + "/mrspendplan/setup/mr-fyactual-retailing/download";
                axios({
                    url: url,
                    method: 'POST',
                    responseType: 'blob',
                    data: payload,
                    headers: {
                        appId: props.match.params.appid
                    },
                }).then((response) => {
                    let fyFF = getFiscalYearFormat(account.FinancialYear) + '-' + moment().month(account.CurrentFF).format('MMM');
                    FileDownload(response.data, getFileTitle(account?.AccountName, fyFF));
                }).catch((error) => {
                    console.log(error);
                })
            } else {
                let params = {
                    fiscalYear: account?.FinancialYear,
                    account: account?.AccountName,
                };
                let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
                params.currentFF = m.length === 1 ? '0' + m : m;
                axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                        appId: props.match.params.appid
                    },
                }).then((response) => {
                    let fyFF = getFiscalYearFormat(account.FinancialYear) + '-' + moment().month(account.CurrentFF).format('MMM');
                    FileDownload(response.data, getFileTitle(account?.AccountName, fyFF));
                }).catch((error) => {
                    console.log(error);
                })
            }
        }
    }

    const getApiDataFromDataUrl = (url, isFyAcutals) => {
        setLoading(true)
        AppService.getApiDataFromDataUrl(url, props.match.params.appid)
            .then(res => {
                setLoading(false)
                if (isFyAcutals) {
                    setLastFyActualData(res.data)
                    setFyActualsLastFFSum(res.totalSum)
                } else {
                    setState({ ...state, data: res.data, count: res.total, totalSum: res.totalSum })
                }
            }).catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData(conditions)
    }, [rowsPerPage, page, JSON.stringify(conditions), mrReducer?.account?.AccountName, filters])

    const handleFileSave = () => {
        fetchData()
    }

    const handlePasteClick = () => {
        setState({ ...state, paste: true })
    }

    const closePasteClick = () => {
        setState({ ...state, paste: false })
    }

    const handleFilterChange = (filter) => {
        setState({ ...state, data: [], totalSum: null, })
        let conditions = [];
        let filters = {};
        if (props.type === 'fyActuals') {
            if (filter.brandform) {
                filters.brandform = [filter.brandform];
            }
            if (filter.brand) {
                filters.brand = [filter.brand];
            }
            if (filter.subChannel) {
                filters.subChannel = [filter.subChannel];
            }
            if (filter.sku) {
                filters.sku = [filter.sku];
            }
            if (filter.account) {
                filters.account = [filter.account];
            }
            setFilters(filters)
        } else {
            if (filter.brandform)
                conditions.push({
                    "selectedConditionDataElement": 'brandform',
                    "selectedConditionOperator": "matches",
                    "value": filter.brandform,
                    "selectedConditionClause": "and"
                })
            if (filter.subChannel)
                conditions.push({
                    "selectedConditionDataElement": 'subChannel',
                    "selectedConditionOperator": "matches",
                    "value": filter.subChannel,
                    "selectedConditionClause": "and"
                })
            if (filter.skuType !== undefined)
                conditions.push({
                    "selectedConditionDataElement": 'skuType',
                    "selectedConditionOperator": "is_equal_to",
                    "value": filter.skuType,
                    "selectedConditionClause": "and"
                })
            if (filter.sku)
                conditions.push({
                    "selectedConditionDataElement": 'sku',
                    "selectedConditionOperator": "is_equal_to",
                    "value": filter.sku,
                    "selectedConditionClause": "and"
                })
            if (filter.account)
                conditions.push({
                    "selectedConditionDataElement": 'account',
                    "selectedConditionOperator": "is_equal_to",
                    "value": filter.account,
                    "selectedConditionClause": "and"
                })
            if (filter.brand)
                conditions.push({
                    "selectedConditionDataElement": 'brand',
                    "selectedConditionOperator": "is_equal_to",
                    "value": filter.brand,
                    "selectedConditionClause": "and"
                })
            if (filter.metric)
                conditions.push({
                    "selectedConditionDataElement": 'metric',
                    "selectedConditionOperator": "is_equal_to",
                    "value": filter.metric,
                    "selectedConditionClause": "and"
                })
            setPage(0);
            setRowsPerPage(100)
            setConditions(conditions)
        }
    }

    const setEditMode = (edit) => {
        setEdited(edit)
    }

    return (
        <>
            {
                !modalData && <MRPlanLinks total={state?.totalSum} {...props} edited={edited} pageURLUpdate={() => setState({ data: [] })} />
            }
            <MRSheetTable state={state} handleChangePage={handleChangePage} {...props} fetchData={fetchData}
                conditions={conditions} fyActualData={fyActualData} fyActualsLastFFSum={fyActualsLastFFSum}
                handleFilterChange={handleFilterChange} loading={loading}
                pagination={{ page, rowsPerPage }}
                modalData={modalData}
                onDownload={onDownload}
                handleChangeRowsPerPage={handleChangeRowsPerPage} closePasteClick={closePasteClick}
                handleFileSave={handleFileSave}
                handlePasteClick={handlePasteClick} setEditMode={setEditMode} />
        </>
    )
}

const mapStateToProps = ({ mrReducer }) => {
    return {
        mrReducer,
    }
}

export default hot(withRouter(connect(mapStateToProps)(MrSheetTableWrapper)))
