import { ActionTypes } from 'constants/index';

export const addReconReviewBaseline = (data) => dispatch => {
  dispatch({ type: ActionTypes.ADD_RECON_BASELINE, payload: data });
};

export const getReconReviewData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_RECON_REVIEW_DATA, payload: data });
};

export const removeReconData = (data) => dispatch => {
  dispatch({ type: ActionTypes.REMOVE_RECON_REVIEW_DATA, payload: data });
};

export const removeReconColumn = (data) => dispatch => {
  dispatch({ type: ActionTypes.REMOVE_RECON_REVIEW_COLUMN, payload: data });
};

export const updateReconReviewLabel = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_RECON_REVIEW_LABEL, payload: data });
};

export const setSaveReconFlag = (data) => dispatch => {
  dispatch({ type: ActionTypes.SAVE_AND_RECON, payload: data });
};

export const clearError = () => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_ERROR });
};
