/**
 * @module Actions/Pages
 * @desc Pages Actions
 */
import { ActionTypes } from 'constants/index';

export const getAllForms = (appId) => dispatch => {
  dispatch({ type: ActionTypes.GET_FORMS_LOADING, appId });
};
export const getFormByName = (formName, submissionId, appId, ffFy) => dispatch => {
  dispatch({ type: ActionTypes.GET_FORM_BY_NAME_LOADING, formName, submissionId, appId, ffFy});
};
export const getSubmittedDataBySubmissionId = (id, formName, appId) => dispatch => {
  dispatch({ type: ActionTypes.GET_SUBMITTED_DATA_BY_ID_LOADING, id, formName });
};
export const saveSubmittedData = (data, files) => dispatch => {
  dispatch({ type: ActionTypes.SAVE_SUBMITTED_DATA_LOADING, data, files })
}
export const updateSubmittedData = (data, files) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_SUBMITTED_DATA_LOADING, data, files })
}

export const clearSubmittedData = () => dispatch => {
  dispatch({ type: ActionTypes.CLEAN_SUBMITTED_DATA })
}
export const updateSelectedFormData = (cardIndex, outerIndex) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_SELECTED_FORM, cardIndex, outerIndex })
}

export const deleteSelectedFormData = (payload) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_SUBMITTED_DATA_LOADING, payload})
}

export const deleteIsSuccess = () => dispatch => {
  dispatch({ type: ActionTypes.DELETE_IS_SUCCESS})
}