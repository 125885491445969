import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { AppService } from 'modules/api/index';
import { border } from 'polished';
import Highcharts from 'highcharts';
import {
	getDoughnutChartConfig,
	getDefaultPieChart,
	generatePieData
} from 'modules/page/highcharts/pie.module';
import {
	generateGUID
} from 'modules/page/page.module';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const legendOpts = {
	display: false,
	position: 'top',
	fullWidth: true,
	reverse: false,
	labels: {
		fontColor: 'rgb(255, 99, 65)'
	}
};

const opt = {
	maintainAspectRatio: false,
	responsive: false,
	legend: {
		position: 'left',
		labels: {
			boxWidth: 20
		}
	}
};

const DoughnutChart = (props) => {
	const [options, setOptions] = useState(getDefaultPieChart());
	const [id, setId] = useState(`line-${generateGUID()}`)

	const highChartsRender = () => {
		let pieConfig = getDoughnutChartConfig(options.series, id);
		console.log('====================================');
		console.log("pieConfig==", pieConfig);
		console.log('====================================');
		Highcharts.chart(pieConfig);
	}

	const setDatafromApi = (res, data) => {
		let d = { ...data };
		let { chartData } = props;
		console.log('data', data, res);

		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			d.labels = res.labels;
			d.datasets[0].data = res.data;
			d.datasets[0].label = res.label;
		} else {
			d.labels = res.data.labels;
			d.datasets[0].data = res.data.datasets[0].data;
			d.datasets[0].label = res.data.datasets[0].label;
		}
		let series = generatePieData(chartData, options.series)
		setOptions(options);
		highChartsRender();
	}

	const getApiDataFromDataUrl = (url, data) => {
		AppService.getApiDataFromDataUrl(url, props.appId)
			.then(res => {
				setDatafromApi(res, data)
			})
	}

	// useEffect(() => {
	//     setData(props.data);
	//     highChartsRender()
	// }, [props.data]);

	useEffect(() => {
		let { chartData } = props;
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + "/dataconnect/chart/" + chartData.selectedDataModel + "?";
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			// targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
			targetURL += chartData.selectedDataElement.reduce(reducer, '&measure=');
			targetURL += chartData.selectedDataLabels.reduce(reducer, '&label=');
			targetURL += '&metric=' + chartData.metric;
			if (props.queryString) {
				targetURL += '&' + props.queryString;
			}
			//labeltype=date&format=%Y-%m
			if (chartData.datelabeltype) {
				targetURL += '&labeltype=date'
				targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
			}

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (props.page && props.page.conditions && props.page.conditions.length > 0) {
				let cond = JSON.stringify(props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
      let encodedData = encodeURIComponent(targetURL)
      let _targetUrl = JSON.stringify(encodedData);
			getApiDataFromDataUrl(_targetUrl, props.data);
		} else if (props.dataUrl) {
			getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			options.series = generatePieData(props.data, options.series)
			setOptions(options);
			highChartsRender();
		}
	}, [])

	return (
		id && <div id={id} className="chartEqualHeight"></div>
	)
};

export default DoughnutChart;