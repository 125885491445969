import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import View from './View'
import { withRouter } from 'react-router-dom'
import { updateTableData, requestSort, setDatafromApi, handleSelectedRow, isRowselected } from 'modules/common'
import { AppService } from 'modules/api/index'
import { request } from 'modules/client'
import { deleteSelectedFormData, deleteIsSuccess, freezePlans, checkSpBGprocesses } from 'store/actions';
import { connect } from 'react-redux';
import ConfirmDialog from '../customPages/spendplan/SpendPlan/ConfirmDialog'
import { makeStyles } from '@material-ui/core/styles'
import Loading from 'components/Loading'
import FreezePlanModal from '../customPages/spendplan/FreezePlan/freezePlanModal'
import { getMonth } from '../../utility/utils'

const APPRENDERER_URL = process.env.REACT_APP_APPRENDERER_URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const useStyles = makeStyles(theme => ({
	confirmationDialoge: {
		'& .MuiDialog-paperWidthSm': {
			height: '320px',
			width: '600px'
		}
	},
	para: {
		fontSize: '28px !important',
		fontWeight: 600,
		color: '#000'
	}
}))

let initialFreezePlan = {
	isOpen: false,
	message: '',
	ids: [],
	brands: [],
	confirm: true
}

const TableWrapper = (props) => {
	const classes = useStyles();
	let { chartData, brand } = props;
	const [state, setState] = useState({
		order: 'asc',
		orderBy: null,
		selected: [],
		data: [["Loading Data..."]],
		page: 0,
		count: 10,
		rowsPerPage: chartData?.rowsPerPage || 10,
		searchText: "",
		// dataElements: [],
		filterTableData: { columns: [], datasets: [] },
		tableData: [],
		filterList: [],
		filterData: [],
	})
	const [alzebra, setAlzebra] = useState(0)
	const [deleteConfirmState, setDeleteConfirm] = useState({
		isOpen: false,
		deleteMessage: 'Are you sure you want to delete ?', ids: []
	})
	const [dataElements, setDataElements] = useState([])
	const [freezePlan, setFreezePlan] = useState(initialFreezePlan)
	const [freezePlanConfirmModal, setFreezePlanConfirmModal] = useState({ isOpen: false, brands: null })
	const [freezePlanProcessing, setFreezePlanProcessing] = useState(false)


	//TODO: need to use redux
	let fun = async () => {
		var dataModel = await getDataModel();
		if (dataModel) {
			setDataElements(dataModel.dataElements)
			// modaldata.then(res => {
			// 	console.log("state ????", state);
			// 	setState({ ...state, dataElements: res.dataElements })
			// })
		}
		let m = '' + getMonth(brand?.CurrentFF, 'monthNumber');
		m = m.length === 1 ? '0' + m : m;
		let obj = {
			appId: props.match.params.appid,
			brand: encodeURIComponent(brand?.BrandName),
			fiscalYear: brand?.FinancialYear + "",
			currentFF: m
		}
		props.checkSpBGprocesses(obj)
	}

	useEffect(() => {
		// var dataModel = getDataModel();

		/*var dataModel = await getDataModel();
		if (dataModel) {
			setDataElements(dataModel.dataElements)
			// modaldata.then(res => {
			// 	console.log("state ????", state);
			// 	setState({ ...state, dataElements: res.dataElements })
			// })
		}*/
		fun()

		// getApiData(state.page, state.rowsPerPage);
	}, [])

	// useEffect(() => {
	// 	if (dataElements.length === 0)
	// 		fun()
	// }, [state])

	useEffect(() => {
		if (dataElements.length > 0)
			getApiData(state.page, state.rowsPerPage);
	}, [dataElements])

	useEffect(() => {
		getApiData(state.page, state.rowsPerPage);
	}, [state.page, state.rowsPerPage])

	useEffect(() => {
		setState({ ...state, ...updateTableData(state) });
	}, [state.searchText, state.filterList]);

	useEffect(() => {
		if (props.form.deleteSuccess) {
			getApiData(state.page, state.rowsPerPage);
			props.deleteIsSuccess();
		}
	}, [props.form.deleteSuccess])

	const getDataModel = () => {
		let { chartData } = props;
		if (chartData.selectedDataModel) {
			const uri = API_BASE_URL + "/dataModels/" + chartData.selectedDataModel
			return request(uri, {
				method: 'GET',
				headers: { appId: props.appId }
			})
		}
	}

	const getTableHeader = (data) => {
		let tableHeader = [];
		let { chartData } = props;
		if (chartData.selectedDataModel && chartData.selectedDataElement
			&& !chartData.loadChildData) {
			chartData.selectedDataElement.map(element => {
				let { label, value } = element;
				if (value != '_id') {
					let headerItem = {
						id: label.replace(' ', ''),
						name: value,
						label: label,
						disablePadding: false,
						numeric: false,
						numeric: false,
						sort: true
					}
					tableHeader.push(headerItem)
				}
			})
		} else {
			if (data && data.length > 0) {
				for (let key in data[0]) {
					if (key != '_id') {
						let headerItem = {
							id: key.replace(' ', ''),
							name: key,
							label: key,
							disablePadding: false,
							numeric: false,
							numeric: false,
							sort: true
						}
						tableHeader.push(headerItem)
					}
				}
			}
		}
		return tableHeader;
	};

	// const setDatafromApi = (res) => {
	// 	console.log("res === ", res);
	// 	if (res && Array.isArray(res.data) && !res.data.columns && !res.data.datasets) {
	// 		let tableHeader = getTableHeader(res.data);
	// 		console.log("tableHeader === ?", tableHeader);
	// 		let r = {
	// 			columns: tableHeader,
	// 			datasets: res.data
	// 		}
	// 		console.log("r ===", r);
	// 		setState({
	// 			...state,
	// 			filterTableData: r,
	// 			resData: r, data: r, isLoading: false,
	// 			count: res.total ? res.total : res.data.length
	// 		});
	// 	} else if (res.columns && res.datasets) {
	// 		setState({ ...state, data: res, isLoading: false, count: res.total ? res.total : res.datasets.length });
	// 	} else {
	// 		throw new Error('Expect data is object with columns and datasets keys');
	// 	}
	// }

	const getApiDataFromDataUrl = (url) => {
		dataElements.length > 0 && AppService.getApiDataFromDataUrl(url, props.appId)
			.then(res => {
				let keysArr = ['_id', '__v']
				setState({
					...state,
					dataElements,
					...setDatafromApi(res.data, keysArr, state, dataElements, props.chartData),
					count: res.total
				})
			})
			.catch(err => {
				console.log("err ", err);
			})
	}

	const getApiData = (page, rowsPerPage) => {
		setState({ ...state, isLoading: true });
		let { chartData } = props;
		if ((chartData.selectedDataModel && (chartData.selectedDataElement)
			|| (chartData.loadChildData && chartData.parentDataElement))) {
			let targetURL = API_BASE_URL + "/dataconnect/table/" + chartData.selectedDataModel;
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			// targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
			targetURL += `?type=list&`;
			if (chartData.loadChildData) {
				if (chartData.parentDataElement)
					targetURL += `&parentDataElement=${chartData.parentDataElement}`;
				else
					targetURL += '&fields=';
			} else
				targetURL += chartData.selectedDataElement.reduce(reducer, '&fields=');
			targetURL += '&page=' + page + '&rowsPerPage=' + rowsPerPage;
			if (props.queryString) {
				targetURL += '&' + props.queryString;
			}
			targetURL += `&sortOrder=${encodeURIComponent(JSON.stringify({_id: 1}))}`;
			let tempConds = []
			if (props.brands && props.brands.length > 0) {
				if (props.page.name === "Brand" || props.page.name === "BrandForm") {
					tempConds = props.brands.map(b => ({
						"selectedConditionDataElement": props.page.name === "BrandForm" ? "Brand" : "BrandName",
						"selectedConditionOperator": "matches",
						"value": b.BrandName,
						"selectedConditionClause": "or"
					}));
				}
			}

			if ((chartData.conditions && chartData.conditions.length > 0)
				|| (tempConds && tempConds.length > 0)) {
        let encodedData = encodeURIComponent([...chartData.conditions, ...tempConds]);
        let cond = JSON.stringify(encodedData);
				targetURL += "&conditions=" + cond;
			}
			if (props.page.conditions && props.page.conditions.length > 0) {
        let encodedData = encodeURIComponent(props.page.conditions);
        let cond = JSON.stringify(encodedData);
				targetURL += "&pConditions=" + cond;
			}

			getApiDataFromDataUrl(targetURL);
		} else if (props.dataUrl) {
			getApiDataFromDataUrl(props.dataUrl);
		} else {
			setState({ ...state, data: props.data, isLoading: false, count: props.data.datasets.length });
		}
	}

	const handleMoreClick = (e) => {
		props.history.push({
			pathname: `${props.chartData.extendedTablePage.url}`
		});
	}

	const handleAddClick = (e) => {
		props.history.push({
			pathname: `/apps/${props.appId}/form/${props.chartData.associatedFormName}/new`
		});
	}

	const getPageUrl = (selectedLinkValue) => {
		let page = props.pages.find(p => {
			return p._id === selectedLinkValue
		})
		return page && page.url;
	}

	const onSearchChange = (searchText) => {
		let d = { ...data }
		d.datasets = state.resData.datasets.filter(ds => {
			let flag = false
			Object.keys(ds).map((k) => {
				if (String(ds[k]).includes(searchText))
					flag = true
			})
			return flag
		});
		setState({ ...state, data: d, searchText: searchText });
	}

	const onTableChange = (action, tableState) => {

		// a developer could react to change on an action basis or
		// examine the state as a whole and do whatever they want

		switch (action) {
			case 'changePage':
				getApiData(tableState.page, tableState.rowsPerPage);
				break;
		}
	}

	const onRowClick = (rowData, rowMeta) => {
		let { extendedTablePage, associatedFormName } = props.chartData;
		// if (associatedFormName && data.datasets[rowMeta.rowIndex]._id) {
		if (associatedFormName && rowData._id) {
			props.history.push({
				pathname: `/apps/${props.appId}/form/${associatedFormName}/${rowData._id}`
			});
		}
	}

	const onCellClick = (colData, cellMeta) => {
		let { selectedDataPages, selectedDataColumns, links,
			extendedTablePage, associatedFormName, name } = props.chartData;
		let { data } = state;
		if (links && links.length > 0)
			links.map((l, i) => {
				if (cellMeta.name === l.selectedLinkDataElement) {
					let url, search = '';
					if (l.selectedLinkType === "page") {
						let pageUrl = getPageUrl(l.selectedLinkValue);
						if (pageUrl)
							url = `/apps/${props.appId}/${pageUrl}/${l.selectedLinkDataElement}/${colData[cellMeta.name]}`
					} else {
						if (l.selectedLinkDataModel && l.selectedLinkKey) {
							url = `/apps/${props.appId}/form/${l.selectedLinkValue}/new`
							search = `?dm=${l.selectedLinkDataModel}&k=${l.selectedLinkKey}`
							search += `&v=${colData[l.selectedLinkKey]}`
							search += '&page=0&rowsPerPage=10&type=list&fields='
							const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
							if (l.additionalFields)
								search += l.additionalFields.reduce(reducer, '');
						} else
							url = `/apps/${props.appId}/form/${l.selectedLinkValue}/${colData._id}`
					}
					if (url) {
						return props.history.push({
							pathname: url, search: search
						});
					}
					else
						alert(`Please provide valid links for column ${l.selectedLinkDataElement}`)
				}
			})
	}

	const objectByStringKey = (o, s, isCurrency) => {
		s = s.replace(/\[(\w+)\]/g, '.$1');
		s = s.replace(/^\./, '');
		var a = s.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			var k = a[i];
			if (k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		if (isCurrency) {
			o = `$${Number((o || 0).toFixed(1)).toLocaleString()}`;
		}
		return o;
	}

	const [open, setOpen] = React.useState(false);
	const [page, setPage] = React.useState({});

	const handleRequestSort = (event, property) => {
		let obj = requestSort(event, property, state);
		setState({ ...state, ...obj });
	};

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			setState({ ...state, selected: state.filterTableData.datasets.map(n => n._id) });
			return;
		}
		setState({ ...state, selected: [] });
	};

	const handleChangePage = (event, page) => {
		setState({ ...state, page: page })
	}

	const handleChangeRowsPerPage = event => {
		setState({ ...state, rowsPerPage: event.target.value, page: 0 })
	}

	const searchHandler = (e) => {
		setState({ ...state, searchText: e ? e.target.value : "" })
	}

	const handleCheck = (event, id) => {
		const { selected } = state;
		let newSelected;
		if (props.page.name==='OmniChannels') {
			newSelected = [id];
		} else {
			newSelected = handleSelectedRow(event, id, selected)
		}
		setState({ ...state, selected: newSelected });
	};

	const resetFilters = (event) => {
		setState({ ...state, filterList: [] })
	}

	const handleInputChange = (event) => {
		let obj = state.filterTableData;
		let list = obj.columns;
		list.map((item, index) => {
			if (item.name === event.target.value) list[index].checked = event.target.checked
		})
		obj.columns = [...list]
		setState({ ...state, filterTableData: obj });
		setState({ ...state, ...updateTableData(state) });
	}

	const handleDropdownChange = (event, index, column) => {
		const value = event.target.value === "All" ? [] : [event.target.value];
		// filterUpdate(index, value, column, 'dropdown');
		let list = state.filterList;
		list[index] = event.target.value;
		setState({ ...state, filterList: [...list] });
		setState({ ...state, ...updateTableData(state) });
	};

	const tableCrudOperations = (item, type, event) => {
		let { extendedTablePage, associatedFormName } = props.chartData;
		if (type === "edit") {
			props.history.push({ pathname: `/apps/${props.appId}/form/${associatedFormName}/${item._id}` });
		}
		if (type === "add") {
			props.history.push({ pathname: `/apps/${props.appId}/form/${associatedFormName}/new` });
		}
		if (type === "view") {
			event && event.preventDefault()
			window.open(`${APPRENDERER_URL}/apps/${props.appId}/form/${associatedFormName}/${item._id}`, "_blank")
		}
		if (type === "delete") {
			// setOpen(true);
			setDeleteConfirm({ ...deleteConfirmState, isOpen: true, ids: [item._id] })
		}
	}
	const onDeleteResponse = (isDelete) => {
		let { chartData } = props;
		if (isDelete) {
			props.deleteSelectedFormData({
				dataModalId: chartData.selectedDataModel,
				appId: props.match.params.appid, ids: deleteConfirmState.ids
			});
		}
		setDeleteConfirm({ ...deleteConfirmState, isOpen: false, ids: [] })
	}

	const handleFreezePlans = () => {
		let brands = [];
		state.filterTableData.datasets.map(n => {
			let ind = state.selected.indexOf(n._id)
			if (ind > -1) {
				brands[ind] = props.page.name === 'OmniChannels' ? n.AccountName : n.BrandName
			}
		})
		if (brands.length > 0){
			setFreezePlanConfirmModal({ ...freezePlanConfirmModal, isOpen: true, brands: brands.join(', ')})
		} else {
			setFreezePlan({
				...freezePlan, isOpen: true, ids: state.selected, brands, confirm: false,
				message: `Please select the brands you want to freeze.`
			})
		}
	}

	const handleClose = (isFreeze, isCopyData) => {
		if (isFreeze) {
			let d = {
				brands: freezePlanConfirmModal.brands.split(',').map(i=> i.trim()),
				appid: props.match.params.appid,
				copyData: isCopyData === 'true',
				page: props.page.name
			}
			props.freezePlans(d)
			setFreezePlanProcessing(true)
			setFreezePlanConfirmModal({...freezePlanConfirmModal, isOpen: false, brands: null})
		} else {
			setFreezePlanConfirmModal({...freezePlanConfirmModal, isOpen: false, brands: null})
			setFreezePlan({ ...initialFreezePlan })
		}
	}

	useEffect(() => {
		if (props.freezePlan.success || props.freezePlan.failure) {
			if (props.freezePlan.success) {
				setFreezePlanProcessing(false)
				return window.location.reload(false);
			getApiData(state.page, state.rowsPerPage);
		}
		}
	}, [props.freezePlan])


	// useEffect(() => {
	// 	getApiData(state.page, state.rowsPerPage);
	// }, [props.brand])

	return (<><View
		{...props}
		isRadio={props.page.name==='OmniChannels' ? true : false}
		pageObj={props.page}
		state={state}
		handleChangePage={handleChangePage}
		handleChangeRowsPerPage={handleChangeRowsPerPage}
		onRowClick={onRowClick}
		searchHandler={searchHandler}
		handleCheck={handleCheck}
		handleInputChange={handleInputChange}
		resetFilters={resetFilters}
		handleDropdownChange={handleDropdownChange}
		handleRequestSort={handleRequestSort}
		handleSelectAllClick={handleSelectAllClick}
		tableCrudOperations={tableCrudOperations}
		handleFreezePlans={handleFreezePlans}
		freezePlanProcessing={freezePlanProcessing}
	/>
		{props.freezePlan.loading && <Loading />}
		<ConfirmDialog open={deleteConfirmState.isOpen} handleClose={onDeleteResponse} alertMessageText={deleteConfirmState.deleteMessage} classes={classes} confirm />
		<ConfirmDialog open={freezePlan.isOpen} handleClose={handleClose} alertMessageText={freezePlan.message} classes={classes} confirm={freezePlan.confirm} />
		<FreezePlanModal open={freezePlanConfirmModal.isOpen} handleClose={handleClose} classes={classes} brands={freezePlanConfirmModal.brands} page={props?.page?.name} isMR={props.page.name === 'OmniChannels'} />
	</>
	)
}

const mapStateToProps = state => {
	return {
		form: state.form,
		freezePlan: state.tprReducer.freezePlan,
		member: state.appConfig.member,
		brand: state.tprReducer?.selectBrand,
		brands: state.app.brands,
	}
}

const mapDispatchToProps = {
	deleteSelectedFormData,
	deleteIsSuccess,
	freezePlans,
	checkSpBGprocesses,
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(withRouter(TableWrapper)))
