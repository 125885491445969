
import { hot } from 'react-hot-loader/root'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
	Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid,
	Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
	DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'
import moment from 'moment'

import toolTips from '../toolTips.json'

let amountColumns = ['Base Annual Salary', 'Annual Bonus', 'Earnings for Life and ADD',
	'Basic Life Volume', 'Basic Life Prem', 'AD&D Volume', 'AD&D Prem',
	'Earnings for LTD', 'LTD Volume', 'LTD Prem', 'Earnings For STD', 'STD Volume', 'STD Prem',
	'FMLA/ADA Administration', 'Supp EE Life Volume', 'Employee Optional Life Prem', 'Employee Optional Life Volume',
	'Supp SP Life Volume', 'Spouse Life Volume', 'Spouse Life Prem',
	'Child Life Volume', 'Supp CH Life Volume', 'Child Life Prem',
	'EE Contribution', 'ER Contribution', 'Credit', 'Debit', 'Total Premium']


let contributionTypeData = [
	{ name: 'ER-contribution', plans: ['Basic Life', 'AD&D', 'ADD', 'LTD', 'STD', "ER Contribution", "FMLA/ADA Administration Rate", "Total Premium"] },
	{ name: 'EE-contribution', plans: ['Employee Optional Life', 'Spouse Life', 'Child Life', "EE Contribution", "Total Premium", 'EE', 'SP', 'CH'] },
	{ name: 'default', plans: ['BillingPeriod', 'ControlNumber', 'Policy Number', 'Employee ID', 'Employee Name', 'Age', 'Bill Group', 'Class', 'Base Annual Salary', 'Annual Bonus'] }
]

const MemberList = (props) => {
	let { members, memberTableRef, selectedProduct, contributionType,
		selectedClass, checkMemberAgeBand, page, rowsPerPage, handleMemberClick, numFormat,
		classes, handleChangePage, handleChangeRowsPerPage
	} = props

	const showProductTypeData = (columnName) => {
		let isValid = false;
		if (selectedProduct !== "All") {
			if (selectedProduct === "Basic Life") {
				if (columnName.includes(selectedProduct) || columnName.includes('Earnings for Life and ADD')) {
					isValid = true;
				}
			} else {
				isValid = columnName.includes(selectedProduct);
			}
			contributionTypeData[2].plans.forEach((e) => {
				if (columnName.includes(e)) {
					isValid = columnName.includes(e);
				}
			});
		} else {
			isValid = true
		}
		return isValid;
	}

	const showContributionTypeData = (con) => {
		let isValid = false;
		if (contributionType !== "All") {
			if (contributionType === "ER-contribution") {
				contributionTypeData[0].plans.map((e) => {
					if (con.includes(e)) {
						isValid = true;
					}
				})
				contributionTypeData[2].plans.map((e) => {
					if (con.includes(e)) {
						isValid = true;
					}
				})
			}
			else if (contributionType === "EE-contribution") {
				contributionTypeData[1].plans.map((e) => {
					if (con.includes(e)) {
						isValid = true;
					}
				})
				contributionTypeData[2].plans.map((e) => {
					if (con.includes(e)) {
						isValid = true;
					}
				})
			}
		} else {
			isValid = true
		}
		return isValid;
	}

	return (<>
		<div className="col-lg-10" style={{ xborder: "1px solid #000" }}>
			<div className="row" style={{
				backgroundColor: "#000", padding: "5px 5px 5px 15px",
				marginLeft: '0px',
				marginRight: '0px'
			}}>
				<div className="col-lg-6" >
					<h5>{"Members List"}</h5>
				</div>
			</div>
			<div style={{ overflowX: 'scroll' }}>
				<TableContainer>
					{members && members.length > 0 ? <>
						<table className="table table-dark" ref={memberTableRef} style={{ marginBottom: '5px' }}>
							<tr>
								{Object.keys(members[0]).map(key => {
									if (key !== "_id" && key !== "entityId"
										&& key !== "supportingDocId" && key !== "EmployerID"
										&& key !== "createdBy" && key !== "updatedBy"
										&& key !== "createdDateTime" && key !== "updatedDateTime"
										&& key !== "Basic Life Rate Basis" && key !== "AD&D Rate Basis"
										&& key !== "LTD Rate Basis" && key !== "STD Rate Basis"
										&& key !== "Employee Optional Life Rate Basis" && key !== "Spouse Life Rate Basis"
										&& key !== "Child Life Rate Basis"
										&& key !== "Date Of Birth") {
										if (showContributionTypeData(key) && showProductTypeData(key)) {
											if (key === 'EE Contribution')
												return (
													<Tooltip
														title={toolTips[key] || key}
														placement={'bottom'}
														enterDelay={300}
													>
														<th key={key} className="bg-yellow">
															{key}
														</th>
													</Tooltip>
												);
											else if (key === 'ER Contribution')
												return (
													<Tooltip
														title={toolTips[key] || key}
														placement={'bottom'}
														enterDelay={300}
													>
														<th key={key} className="bg-red">
															{key}
														</th>
													</Tooltip>
												);
											else if (key === 'BillingPeriod')
												return (
													<Tooltip
														title={toolTips[key]}
														placement={'bottom'}
														enterDelay={300}
													>
														<th key={key}>{'Billing Period'}</th>
													</Tooltip>
												);
											else if (key === 'ControlNumber')
												return (
													<Tooltip
														title={toolTips[key]}
														placement={'bottom'}
														enterDelay={300}
													>
														<th key={key}>{'Control Number'}</th>
													</Tooltip>
												);
											else if (key === 'Credit')
												return (
													<Tooltip
														title={toolTips[key]}
														placement={'bottom'}
														enterDelay={300}
													>
														<th key={key}>{'Back Credit'}</th>
													</Tooltip>
												);
											else if (key === 'Debit')
												return (
													<Tooltip
														title={toolTips[key]}
														placement={'bottom'}
														enterDelay={300}
													>
														<th key={key}>{'Back Charges'}</th>
													</Tooltip>
												);
											return (
												<Tooltip
													title={toolTips[key] || key}
													placement={'bottom'}
													enterDelay={300}
												>
													<th key={key}>{key}</th>
												</Tooltip>
											);
										}
									}
								})}
							</tr>
							{members?.filter(m => m['Class'] === selectedClass || m.Class === selectedClass || selectedClass === 'All')
								.filter(checkMemberAgeBand)
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((item) => {
									return <tr key={item} style={{ cursor: 'pointer' }} onClick={() => handleMemberClick(item)}>
										{Object.keys(members[0]).map(key => {
											if (key !== "_id" && key !== "entityId"
												&& key !== "supportingDocId" && key !== "EmployerID"
												&& key !== "createdBy" && key !== "updatedBy"
												&& key !== "createdDateTime" && key !== "updatedDateTime"
												&& key !== "Basic Life Rate Basis" && key !== "AD&D Rate Basis"
												&& key !== "LTD Rate Basis" && key !== "STD Rate Basis"
												&& key !== "Employee Optional Life Rate Basis" && key !== "Spouse Life Rate Basis"
												&& key !== "Child Life Rate Basis"
												&& key !== "Date Of Birth") {
												if (showContributionTypeData(key) && showProductTypeData(key)) {
													if (key === 'BillingPeriod')
														return <td key={item + key}
															style={{
																whiteSpace: 'nowrap'
															}}>
															{moment(item[`${key}`]).format("MM/DD/YYYY")}
														</td>
													else if (amountColumns.includes(key))
														return <td key={item + key}
															style={{
																whiteSpace: 'nowrap'
															}}>
															{numFormat(item[`${key}`])}
														</td>
													return <td key={item + key}>{item[`${key}`]}</td>
												}
											}
										})}
									</tr>
								})}
						</table>
						<div className={classes.pagination} style={{
							position: 'absolute',
							width: '100%',
							paddingRight: '30px'
						}}>
							<TablePagination
								rowsPerPageOptions={[5, 10, 15, 20, 50]}
								component="div"
								count={members ? members.filter(m => m['Class'] === selectedClass || m.Class === selectedClass || selectedClass === 'All').filter(checkMemberAgeBand).length : 0}
								rowsPerPage={rowsPerPage}
								page={page}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						</div></>
						: <div style={{ color: '#fff', textAlign: 'center' }}>
							<h3>{"Old records doesn't contain Member list"}</h3>
						</div>}
				</TableContainer>
			</div>
		</div>

	</>)
}

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(connect(mapStateToProps, null)(MemberList)));