import { Button, Checkbox, ClickAwayListener, ListItemText, MenuItem, Paper, Popper } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useEffect, useState } from 'react';
import { capitalize } from '../../utility/utils';
import classnames from 'classnames';

const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 8,
        },
    },
    checkbox: {
        "&$checked": {
            color: 'rgb(16, 114, 189)',
        }
    },
    okBtn: { marginRight: 10, textTransform: 'capitalize', minWidth: 55, height: 25 },
    item: { marginLeft: -25, height: 30, borderBottom: '1px solid #ddd' }
});

const FilterMultiSelect = props => {

    const classes = useStyles();
    const { selectedValues, allValues, onClose, isOpen, anchorEl } = props;
    const [searchText, setSearchedText] = useState('');
    const [selected, setSelected] = useState([]);
    const getDropDownData = () => {
        return (searchText?.length ? (allValues.filter(i => { 
            return typeof i === 'number' ? i.toString().includes(searchText?.toLocaleLowerCase()) : i?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase())
        })) : allValues)
    }

    const selectItem = (item, checkedFalse) => {
        if (item === '(Select All)') {
            if (checkedFalse) {
                setSelected([])
            } else {
                setSelected(allValues)
            }
        } else if (item === '(Select All searched)') {
            if (checkedFalse) {
                setSelected([])
            } else {
                setSelected(getDropDownData())
            }
        } else {
            if (checkedFalse) {
                let _selected = selected.filter(i => i !== item);
                setSelected([..._selected])
            } else {
                let _selected = [...selected, item];
                setSelected(_selected)
            }
        }
    }

    useEffect(() => {
        setSelected(selectedValues)
    }, [selectedValues])

    return (
        <ClickAwayListener onClickAway={(e) => onClose(null, null, e)}>
            <Popper open={isOpen} anchorEl={anchorEl} id={props.id} style={{ zIndex: 1305, marginLeft: 50, }}>
                <Paper style={{ padding: 16, }} elevation={3}>
                    <OutlinedInput
                        id="outlined-adornment-weight"
                        value={searchText}
                        className={classes.root}
                        placeholder='Search here ...'
                        onChange={e => setSearchedText(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                {Boolean(searchText?.length) && <ClearIcon style={{ cursor: 'pointer', fontSize: 14, }} onClick={() => setSearchedText('')} />}
                            </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        labelWidth={0}
                    />
                    <div style={{ height: 150, overflow: 'auto', marginTop: 5, }}>
                        {
                            Boolean(getDropDownData()?.length) && !Boolean(searchText?.length) && <MenuItem className={classes.item}
                                value={allValues} onClick={() => selectItem('(Select All)', selected?.length === allValues?.length)}>
                                <Checkbox style={{
                                    marginLeft: -10,
                                    transform: "scale(0.6)",
                                }} color="primary" checked={selected?.length === allValues?.length} />
                                <ListItemText primary={'(Select All)'} />
                            </MenuItem>
                        }

                         {
                            Boolean(searchText?.length) && <MenuItem className={classes.item}
                                value={allValues} onClick={() => selectItem('(Select All searched)', selected?.length === getDropDownData()?.length)}>
                                <Checkbox style={{
                                    marginLeft: -10,
                                    transform: "scale(0.6)",
                                }} color="primary" checked={selected?.length ===getDropDownData()?.length} />

                                <ListItemText primary={'(Select All Search Results)'} />
                            </MenuItem>
                        }

                        {
                            getDropDownData()?.map((item, index) => {
                                return (<MenuItem className={classes.item} key={index} value={item} onClick={() => selectItem(item, selected?.indexOf(item) > -1)}>
                                    <Checkbox style={{
                                        marginLeft: -10,
                                        transform: "scale(0.6)",
                                    }} color="primary" checked={selected?.indexOf(item) > -1} />
                                    <ListItemText primary={props.isCapitalize ? capitalize(item) : item} />
                                </MenuItem>
                                )
                            })
                        }
                        {
                            getDropDownData()?.length === 0 && <ListItemText style={{ textAlign: 'center', color: 'gray' }} primary="No options" />
                        }
                    </div>
                    <div style={{ paddingTop: 8, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            color="primary" onClick={() => onClose(true, selected)}
                            className={classnames("btn btn-primary btn-color border-radius-0 text-color-white", classes.okBtn)}>
                            {"OK"}
                        </Button>
                        <Button
                            size='small'
                            color="primary" onClick={() => onClose(false)}
                            style={{ textTransform: 'capitalize', minWidth: 55, height: 25 }}
                            className="btn btn-primary btn-color border-radius-0 text-color-white">
                            {"Cancel"}
                        </Button>
                    </div>
                </Paper>

            </Popper>
        </ClickAwayListener>
    );
};


export default FilterMultiSelect;
