import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Slide
} from '@material-ui/core'
import React from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        textAlign: 'center',
    },
    errorText: { color: '#000', textAlign: 'center', textTransform: 'none', margin: '5px 0' },
    confirmationDialoge: {
		'& .MuiDialog-paperWidthSm': {
			height: '320px',
			width: '600px'
		}
	},
	para: {
		fontSize: '28px !important',
		fontWeight: 600,
		color: '#000'
	},
}));
 
const HandleErrors = (props) => {
    let { errorArr, type } = props
    const classes = useStyles();
    let message = `${type} not present in master database:`
    let obj = {}
    if (type === "Duplicate Record") {
        message = `Found duplicate plans (${errorArr.length} nos) with same key fields. Please resolve before saving records.`;
        errorArr.map(e => {
            let { month, brandform, channel, cluster, planName } = e;
            month = month.replace(/./, c => c.toUpperCase())
            let allKeys = (key)=> Object.keys(e).includes(key)?key:false;
            let descriptionKey = allKeys('planName') || allKeys('consumerPromoDescription') || allKeys('conversionFundsDescription')
                 || allKeys('displaysDescription') || allKeys('planDescription') || allKeys('trialDiscountsDescription');
            let _key = month + " " + brandform + " " + (channel || "") + " " + (cluster || "") + " " + e[descriptionKey];
            if (obj[_key]) {
                obj[_key].push(e.row)
            } else {
                obj[_key] = [e.row];
            }
        })
    } else if (type === "Brandforms") {
        errorArr.map(e => {
            if (obj[e.brandform]) {
                obj[e.brandform].push(e.row)
            } else {
                obj[e.brandform] = [e.row];
            }
        })
    } else if(type === 'lbebop'){
        let rejectedItemsWithlbebopVal = errorArr.map(e => e.row)
        message = `Rejected rows which has LBE BOP values (${rejectedItemsWithlbebopVal.length} nos.):`
        return (<>
            <h6 className={classes.errorText} style={{ fontWeight: 500, marginTop: 15 }}>{message}</h6>
            <h6 className={classes.errorText} style={{ fontWeight: 500, marginTop: 15 }}>{rejectedItemsWithlbebopVal.join(',')}</h6>
        </>)
    }
    return (<>
        <h6 className={classes.errorText}>
            {message}</h6>
        {type !== "data" && <Grid container style={{ textAlign: "center", fontSize: '16px', fontWeight: 700 }}>
           { type === "Brandforms"?
            <>
                <Grid item xs={6} sm={6}>{type}</Grid>
                <Grid item xs={6} sm={6}>{"Row No."}</Grid>
                {Object.keys(obj).map((err, index) => {
                    return <>
                        <Grid item xs={6} sm={6} style={{ fontWeight: 500 }}>{err}</Grid>
                        <Grid item xs={6} sm={6} style={{ fontWeight: 500 }}>{obj[err].join(', ')}</Grid>
                    </>
                })}
            </>:
            <>
                {Object.keys(obj).map((err, index) => {
                    return <Grid item xs={12} sm={12} style={{ fontWeight: 500, marginTop:index ===0?15:0 }}>{err}</Grid>
                })}
            </>}
        </Grid>
        }
    </>)
}

const PlanningErrorModal = (props) => {
    let { open, handleClose, alertMessageText, confirm, type } = props
    const classes = useStyles();

    return (<>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.confirmationDialoge}
            onKeyDown={(e)=> (e.key == 'Enter' && !confirm) && handleClose()} tabIndex="0"
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#047dd8" }}>
                {typeof alertMessageText == 'object' && alertMessageText.title ? alertMessageText.title
                    :
                    (alertMessageText.brandformIssues && alertMessageText.brandformIssues.length > 0 ||
                        alertMessageText.tprVersionIssues && alertMessageText.tprVersionIssues.length > 0)
                        ? "Error" : "Confirmation"}
            </DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText component="div" className={classes.para}>
                    <>
                        {typeof alertMessageText == 'object'
                            ? <>
                                {alertMessageText.p1 &&
                                    <h4 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '20px 0' }}>
                                        {alertMessageText.p1} </h4>}
                                {alertMessageText.p2 && <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
                                    {alertMessageText.p2} </h6>}
                                {alertMessageText.p3 && <h3 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
                                    {alertMessageText.p3} </h3>}
                                    
                                {alertMessageText.brandformIssues && alertMessageText.brandformIssues.length > 0
                                    && <HandleErrors errorArr={alertMessageText.brandformIssues} type="Brandforms" />}
                                {alertMessageText.tprVersionIssues && alertMessageText.tprVersionIssues.length > 0
                                    && <HandleErrors errorArr={alertMessageText.tprVersionIssues} type="Duplicate Record" />}
                                {!alertMessageText.p1 && !alertMessageText.p2 && !alertMessageText.p3 && !alertMessageText.brandformIssues?.length && !alertMessageText.tprVersionIssues?.length &&
                                    <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
                                        Something went wrong please try again after some time
                                    </h6>
                                }
                            </>
                            : <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
                                {alertMessageText}
                            </h6>
                        }
                    </>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    confirm ?
                        <React.Fragment>
                            <Button
                                color="primary" onClick={() => {
                                    handleClose(true)
                                }}
                                className="btn btn-primary btn-color border-radius-0 text-color-white">
                                {"Yes"}
                            </Button>
                            <Button
                                color="primary" onClick={() => handleClose(false)}
                                className="btn btn-primary btn-color border-radius-0 text-color-white">
                                {"No"}
                            </Button>
                        </React.Fragment>
                        :
                        <Button
                            color="primary" onClick={() => handleClose()}
                            className="btn btn-primary btn-color border-radius-0 text-color-white">
                            {"OK"}
                        </Button>
                }
            </DialogActions>
        </Dialog>

    </>)
}

export default hot(withRouter(PlanningErrorModal));
