import XLSX from 'xlsx';
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import React, { useRef, useState, useEffect } from 'react';
import KeyMappings from './KeyMappings';
import { BasicMappingActionButtons, FormAction } from '../ActionButtons';
import { getEntity } from 'store/actions/app'
import {
    Dialog, Button, DialogActions, makeStyles, Slide,
    DialogContent, DialogContentText, DialogTitle
} from "@material-ui/core"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let defEmpInfo = [
    { key: "Employee ID", value: "Employee ID" },
    { key: "Employee Name", value: "Employee Name" },
    { key: "Date Of Birth", value: "Date Of Birth" },
    { key: "Bill Group", value: "Bill Group" },
    { key: "Class", value: "Class" }
]
let defEmpComp = [
    { key: "Base Annual Salary", value: "Base Annual Salary" },
    { key: "Annual Bonus", value: "Annual Bonus" },
    { key: "Employee Optional Life Volume", value: "Supp EE Life Volume" },
    { key: "Spouse Life Volume", value: "Supp SP Life Volume" },
    { key: "Child Life Volume", value: "Supp CH Life Volume" },
    { key: "Credit", value: "Credit" },
    { key: "Debit", value: "Debit" }
]


const useStyles = makeStyles((theme) => ({
    pagination: {
        '& .MuiTablePagination-root': {
            backgroundColor: "#242323 !important",
            color: "white !important",

        },
        '& .MuiSelect-icon': {
            color: "white !important",
            top: '0px !important',
            right: "-5px !important"

        },
        '& .MuiIconButton-root.Mui-disabled': {
            color: "white !important"
        },
        textField: {
            "padding-right": "10px",
            "margin-top": "0px",
            "margin-bottom": "16px",
            "border-radius": 0
        }
    },
    dialog: {
        height: "300px",
        width: "600px",
        margin: '0px auto'
    },
    paymentDialog: {
        height: "80vh",
        width: "700px",
        margin: '0px auto'
    },
    mappingTypeCon: {
        width: '10%',
        marginLeft: '5.5%'
    }
}));

let BasicKeyMapping = (props) => {
    const inputRef = useRef(null);
    const [form, setForm] = useState({});
    const [state, setState] = useState({
        fields: []
    });
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = useState("");
    const [fileData, setFileData] = useState({})
    const [resolution, setResolution] = useState();
    const [empComp, setEmpComp] = useState(JSON.parse(JSON.stringify(defEmpComp)))
    const [empInfo, setEmpInfo] = useState(JSON.parse(JSON.stringify(defEmpInfo)))
    const [headers, setHeaders] = useState([])

    const [alertMessageText, setAlertMessage] = React.useState("");
    const classes = useStyles();

    const generateSetup = async (e) => {
        console.log('inside - generateSetup => form', form);
        setEmpComp(JSON.parse(JSON.stringify(defEmpComp)))
        setEmpInfo(JSON.parse(JSON.stringify(defEmpInfo)))
        handleFile(form.files[0])
        await uploadWithFormData(form.files[0])
    }

    /**
     * Generate an array of column objects
     *
     * @param {string} refstr - Reference string for Excel
     *
     * @returns {Array}
     */
    const makeCols = refstr => {
        let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
        for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
        return o;
    };

    /**
     * File handler
     * Reads excel sheet and updates state with headers and data 
     * @param {File} file - Binary string of excel
     *
     * @returns {null}
     */
    const handleFile = (file/*:File*/) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            /* Update state */
            console.log("???", { data: data, cols: makeCols(ws['!ref']), headers: data[0] });
            setFileData({ data: data, cols: makeCols(ws['!ref']), headers: data[0] })
        };
        if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
    };

    const submitForm = (contentType, data, setResponse) => {
        axios({
            url: API_BASE_URL + '/custom/resolution',
            method: 'POST',
            data: data,
            headers: {
                'Content-Type': contentType,
                appId: props.match.params.appid
            },
            onUploadProgress: (p) => {
                console.log("p", p);
            }
        }).then((response) => {
            setResponse(response.data);
        }).catch((error) => {
            setResponse({ "error": error });
        })
    }

    const uploadWithFormData = (file) => {
        setStatus('loading')
        const formData = new FormData();
        formData.append("file", file);
        return submitForm("multipart/form-data", formData, (res) => {
            console.log("res - fileupload", res)
            setResolution(res)
            setTimeout(() => {
                setStatus('done')
            }, 1000);
            if (res.error) {
                setStatus('done')
                setAlertMessage('File upload files' + res.error)
                handleClickOpen();
            }
        });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    // const onSubmitData = () => {
    //     console.log("Save Basic Key Mapping");
    // }

    useEffect(() => {
        let { data } = props.state
        console.log('data ?// ===', data);
        if (data && data._id && data.basicKeyMapping) {
            setEmpComp(data.basicKeyMapping.employeeCompensation)
            setEmpInfo(data.basicKeyMapping.employeeInformation)
            setHeaders(data.basicKeyMapping.headers)
        }
    }, [props.state.data])

    const onSubmitData = () => {
        // console.log('state', state);
        let { state, categories } = props
        let data = {}
        if (state.data) {
            data = {
                ...state.data,
                EmployerId: props.entity._id,
                memberId: props.member._id,
                entityId: props.entity._id,
                basicKeyMapping: { employeeInformation: empInfo, employeeCompensation: empComp, headers }
            }
        } else {
            data = {
                ...state,
                EmployerId: props.entity._id,
                memberId: props.member._id,
                entityId: props.entity._id,
                config: { fields: state.fields, groups: state.groups },
                categories,
                basicKeyMapping: { employeeInformation: empInfo, employeeCompensation: empComp, headers }
            }
        }
        console.log('state ==> inb data', state, data);
        let url = API_BASE_URL + '/custom/setup'
        let method = 'POST'
        if (state.setupExists && state._id) {
            url = url + '/' + state._id
            method = 'PUT'
        }
        axios({
            url: url,
            method: method,
            data: data,
            headers: {
                appId: props.match.params.appid,
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            console.log('response', res)
            // showAlert('Setup saved for successfully', {
            // 	id: 'ABD11',
            // 	position: 'top-left',
            // 	variant: 'success',
            // })
            setAlertMessage({ p1: 'Setup saved for successfully' })
            handleClickOpen();
            setState({ ...state, isTableShow: true });
        }).catch((error) => {
            console.log('error', error)
            setAlertMessage('Setup save is failed' + error)
            handleClickOpen();
        })
    };

    const onClickHandler = type => {
        if (type === 'addNewBKM') {
            let newGroups = JSON.parse(JSON.stringify(state.groups));
            let newFields = JSON.parse(JSON.stringify(state.fields));
            newGroups.map(data => data.push({ id: data.length + 1, options: data[data.length - 1].options }));
            newFields.map(data => data.push({ id: data.length + 1, options: data[data.length - 1].options }));
            setState({ ...state, groups: newGroups, fields: newFields });
        }
    };

    const handleInputChange = (evt) => {
        setForm({
            ...form,
            files: inputRef.current.files,
            fileName: inputRef.current.value
                ? inputRef.current.files[0].name
                : ''
        });
    }

    const fileSelector = () => {
        return (
            <div className="col-lg-8 file-upload-container">
                <div className="col-lg-3">
                    <h5 className={`text-center ${isIBC ? 'ibc-black-heading' : ''}`}>{"Please upload a sample census file to map census fields with carrier template fields"}</h5>
                </div>
                <div className="col-lg-6" style={{ display: "inline-block" }}>
                    <input type='file' id="uploadInput"
                        style={{ width: "600px", height: "35px", padding: "5px", backgroundColor: isIBC ? '#828282' : "#444", border: "none", color: isIBC ? '#fff' : '#000' }}
                        onChange={handleInputChange}
                        ref={inputRef}
                    />
                    <h5 id="uploadInputError" style={{ "color": "red" }}></h5>
                </div>
                <div className="col-lg-2" style={{ display: "inline-block", marginLeft: "20px" }}>
                    <button className={`btn btn-color border-radius-0 ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} id="btnAnalyze"
                        onClick={e => generateSetup(e)}>{"Map Census Fields"}</button>
                </div>
            </div>
        );
    }

    console.log('???????');
    return (
        <React.Fragment>
            {fileSelector()}
            {/* <BasicMappingActionButtons onClickHandler={onClickHandler} /> */}
            {status !== 'loading' &&
                <React.Fragment>
                    <KeyMappings fileData={fileData} resolution={resolution}
                        empInfo={empInfo} setEmpInfo={setEmpInfo}
                        empComp={empComp} setEmpComp={setEmpComp}
                        headers={headers} setHeaders={setHeaders} />
                    <FormAction onSubmitData={onSubmitData} />
                </React.Fragment>
            }
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#000" }}>{"Confirmation"}</DialogTitle>
                <DialogContent className={classes.dialog}>
                    <DialogContentText id="alert-dialog-slide-description">
                        {typeof alertMessageText == 'object'
                            ? <>
                                <h3 style={{ color: '#000', textAlign: 'center' }}> {alertMessageText.p1} </h3>
                                <h5 style={{ color: '#000', textAlign: 'center' }}> {alertMessageText.p2} </h5>
                            </>
                            : alertMessageText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {"OK"}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const mapDispatchToProps = {
    getEntity
};

const mapStateToProps = state => {
    return {
        user: state.user,
        pageLoading: state.pages.loading,
        member: state.appConfig.member,
        memberLoading: state.appConfig.memberLoading,
        entity: state.appConfig.entity ? state.appConfig.entity : {}
    };
};
export default hot(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BasicKeyMapping)));