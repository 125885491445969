import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMrData, removeMRSuccessMessage } from '../../../../store/actions';
import { getMonth } from '../../../../utility/utils';
import PieChart from '../../../highcharts/PieChart';
import Loader from '../../../Loader';
import PlanningErrorModal from '../../spendplan/PlanningPages/planningErrorModal';
import Header from '../../spendplan/SpendPlan/Header';
import { getFiscalYearFormat } from '../../spendplan/spendplanUtils';
import { URLs } from '../ApiConstants';
import CockpitTable from './cockpittable';
import { useStyles } from './index';


const options = {
    // responsive: true,
    maintainAspectRatio: true
};

const BrandMix = (props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [alertMessageText, setAlertMessage] = React.useState("");
    const [brand, setBrand] = useState(null)

    useEffect(() => {
        fetchData()
    }, [props.account])

    const fetchData = () => {
        const account = props.account;
        if (account) {
            let params = {
                fiscalYear: account.FinancialYear,
                account: encodeURIComponent(account.AccountName),
            };
            let m = '' + getMonth(account.CurrentFF, 'monthNumber')
            params.currentFF = (m.length === 1 ? '0' + m : m);
            let url = URLs.cockpitBrandMix;
            url += `?account=${params.account}&fiscalYear=${params.fiscalYear}&currentFF=${params.currentFF}`
            let payload = {
                url: url,
                appId: props.match.params.appid,
                dataUniqueId: 'GET_brandMixData'
            }
            props.getMrData(payload)
        }

    };


    const onClickEvent = (e) => {
        setBrand(e.point.name);
    }


    useEffect(() => {
        if (props.mrData.error && props.mrData.error['GET_brandMixData']) {
            setOpen(true)
            setAlertMessage({
                title: 'Brand Mix',
                p1: props.mrData.error['GET_brandMixData']?.message || 'Something went wrong',
            });
        }
    }, [props.mrData.error]);

    const handleClose = () => {
        setOpen(false);
        props.removeMRSuccessMessage({ dataUniqueId: 'GET_brandMixData' });
    };

    if (props.mrData && props.mrData['GET_brandMixData' + '_loading']) {
        return (
            <section className={classes.tableBodyLoading}>
                <Loader />
            </section>
        )
    }

    const getData = () => {
        if (brand && props.data && props.data['GET_brandMixData']) {
            return props.data['GET_brandMixData']?.result?.brandForm[brand] || [];
        }
        return []
    }
    const getChartData = () => {
        if (props.data && props.data['GET_brandMixData']) {
            return props.data['GET_brandMixData'].result.brandMix;
        }
    }

    const pieChartData1 = {
        "labels": getChartData() ? (getChartData().map(i => i.brand) || []) : [],
        "datasets": [
            {
                "data": getChartData() ? (getChartData().map(i => i.ratio) || []) : [],
                "label": ""
            }
        ]
    }

    const tableConfig1 = [
        {
            headingData: 'Brand Change vs. LR',
            headings: [
                { title: 'Brandform', key: 'brandform', leftAlign: true, isString: true },
                { title: 'Current Salience', key: 'currentSalience', isPercent: true  },
                { title: 'Last FF Salience', key: 'lastFFSalience',isPercent: true  },
                { title: `FY ${getFiscalYearFormat(props.account.FinancialYear)} Salience`, key: 'fySalience', isPercent: true },
            ],
            subHeadings: [
    
            ],
        },
    ]
    

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Header header={`Brand Mix`} />
                <div style={{ display: 'flex' }}>
                    <Button
                        style={{ width: 240 }}
                        variant="contained"
                        onClick={() => {
                            props.history.push({
                                pathname: `/apps/${props.match.params.appid}/RetailingSummary`
                            });
                        }}
                        startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} />}
                        color="primary">Back to Retailing Summary</Button>
                    <Button
                        style={{ marginLeft: 10, width: 200 }}
                        variant="contained"
                        onClick={() => {
                            props.history.push({
                                pathname: `/apps/${props.match.params.appid}/Dashboard2`
                            });
                        }}
                        startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} />}
                        color="primary">Back to Cockpit</Button>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '30%', margin: 'auto' }}>
                <PieChart page={'props.page'} data={pieChartData1} chartData={{}} onClickEvent={onClickEvent}
                    dataUrl={null} styles={{}} legendOpts={null}
                    options={options} queryString={null} />
            </div>

            <h6 style={{ color: 'black', margin: 10, }}>Brandform Wise Mix changes  {brand === null ? <i>- Click on a Brand in the above chart to see their Salience data</i> : ('- ' + brand)}</h6>
            <CockpitTable data={getData()} tableConfig={tableConfig1} />
            <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} />
        </div>
    )
}

const mapDispatchToProps = {
    getMrData,
    removeMRSuccessMessage,
};

const mapStateToProps = state => {
    return {
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
        account: state.mrReducer?.account,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandMix)));