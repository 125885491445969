import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Paper, FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';
import GroupWidget from './GroupWidget';
import { convertDate, getMonth } from "utility/utils";
import Header from './Header'
import ambipur from 'images/spendplan/ambipur.png'
import ariel from 'images/spendplan/ariel-1.png'
import gillette from 'images/spendplan/gillette.png'
import handandshoulders from 'images/spendplan/handandshoulders.png'
import herbal from 'images/spendplan/herbal.png'
import olay from 'images/spendplan/olay.png'
import oldspice from 'images/spendplan/oldspice.png'
import oralb from 'images/spendplan/oralb.png'
import pampers from 'images/spendplan/pampers.png'
import pantene from 'images/spendplan/pantene.png'
import tide from 'images/spendplan/tide.png'
import vicks from 'images/spendplan/vicks.png'
import whisper from 'images/spendplan/whisper.png'
import { getCheckData, getTopFivePlans } from '../../../../store/actions';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const useStyles = makeStyles(theme => ({
	dashboard: {
		'@media(max-width:1366px)': {
			height: "80vh",
		},
	},
	root: {
		display: 'flex',
		// justifyContent:'space-around',
		marginBottom: '20px',
		height: '100%'
	},
	containerMain: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: '0 20px',
		justifyContent: 'space-between',
	},
}));

const Dashboard = props => {
	const { getTopFivePlans, page, brand, getCheckData, updatedData } = props;
	const classes = useStyles();
	const groups = [{
		_id: '1',
		name: 'Baby Care',
		brands: [{ name: "Pampers", image: pampers }],
		data: [{
			name: 'Total',
			data: [7.2, 7.2, 7.2, 7.1]
		}, {
			name: 'Used',
			data: [1.5, 3.0, 4.3, 6.7]
		}]
	}, {
		_id: '2',
		name: 'Fabric Care',
		brands: [{ name: "Ariel", image: ariel }, { name: "Tide", image: tide }],
		data: [{
			name: 'Total',
			data: [7.2, 7.2, 7.2, 7.1]
		}, {
			name: 'Used',
			data: [1.5, 3.0, 4.3, 6.7]
		}]
	}, {
		_id: '3',
		name: 'Feminine Care',
		brands: [{ name: "Whisper", image: whisper }],
		data: [{
			name: 'Total',
			data: [7.3, 7.3, 7.3, 7.2]
		}, {
			name: 'Used',
			data: [1.5, 3.0, 4.3, 6.7]
		}]
	}, {
		_id: '4',
		name: 'Grooming',
		brands: [{ name: "Gillette", image: gillette }],
		data: [{
			name: 'Total',
			data: [7.2, 7.3, 7.3, 7.2]
		}, {
			name: 'Used',
			data: [1.5, 3.0, 4.3, 6.7]
		}]
	}, {
		_id: '5',
		name: 'Hair Care',
		brands: [{ name: "Hand&Shoulders", image: handandshoulders },
		{ name: "Herbal Essences", image: herbal }, { name: "Pantene", image: pantene }],
		data: [{
			name: 'Total',
			data: [7.3, 7.3, 7.3, 7.2]
		}, {
			name: 'Used',
			data: [1.5, 3.0, 4.3, 6.7]
		}]
	}, {
		_id: '6',
		name: 'Home Care',
		brands: [{ name: "Ambipur", image: ambipur }],
		data: [{
			name: 'Total',
			data: [7.1, 7.1, 7.1, 7.2]
		}, {
			name: 'Used',
			data: [1.5, 3.0, 4.3, 6.7]
		}]
	}, {
		_id: '7',
		name: 'Oral Care',
		brands: [{ name: "Oral-B", image: oralb }],
		data: [{
			name: 'Total',
			data: [7.3, 7.3, 7.3, 7.2]
		}, {
			name: 'Used',
			data: [1.5, 3.0, 4.3, 6.7]
		}]
	}, {
		_id: '8',
		name: 'Personal Health Care',
		brands: [{ name: "Vicks", image: vicks }],
		data: [{
			name: 'Total',
			data: [7.0, 7.0, 7.0, 7.1]
		}, {
			name: 'Used',
			data: [1.5, 3.0, 4.3, 6.7]
		}]
	}, {
		_id: '9',
		name: 'Skin & Personal',
		brands: [{ name: "Olay", image: olay }, { name: "Old Spice", image: oldspice }],
		data: [{
			name: 'Total',
			data: [7.0, 7.0, 6.9, 6.9]
		}, {
			name: 'Used',
			data: [1.5, 3.0, 4.3, 6.7]
		}]
	}];

	return (<div className={classes.dashboard}>
		<div style={{ paddingLeft: '10px' }}>
			<Header header="Finance Dashboard" filter={false} />
		</div>

		<div className={classes.root}>
			<Grid container >
				{groups.map((group, i) => {
					return <div key={i} style={{ width: "20%", paddingTop: i < 5 ? '0px' : '15px', height: '40vh' }} >
						<GroupWidget group={group} index={i} />
					</div>
				})}
			</Grid>
		</div>
	</div >);
};

const mapDispatchToProps = {
	getTopFivePlans,
	getCheckData
}

const mapStateToProps = (state) => {
	return {
		brand: state.tprReducer?.selectBrand,
		topFivePlans: state.tprReducer?.topFivePlans,
		updatedData: state.tprReducer?.updatedData
	}
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));
