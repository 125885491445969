/**
 * @module Actions/Pages
 * @desc Pages Actions
 */
import { ActionTypes } from 'constants/index';

export const loadPageconfig = (appId) => dispatch => {
    dispatch({ type: ActionTypes.LOAD_PAGE_CONFIG_LOADING, appId });
};

export const loadPages = (appId) => dispatch => {
    dispatch({ type: ActionTypes.LOAD_PAGES_LOADING, appId });
};

export const savePage = (data) => dispatch => {
    dispatch({ type: ActionTypes.SAVE_PAGE_LOADING, data });
};

export const clearPages = () => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_PAGES });
};

