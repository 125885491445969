import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { Table, TableContainer, TableCell, TableRow, TableHead, TableBody, Paper, Select, MenuItem, FormControl, makeStyles } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
import { loadEntities } from 'store/actions'
import { ReceiptTwoTone } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    table: {
        fontSize: "1.2em!important"
    },
    checkbox: {
        width: "20px",
        height: "20px"
    },
    link: {
        padding: "5px",
        color: "#2b2b2b"
    },
    element: {
        backgroundColor: "#242323 !important",
        color: "white !important",
        borderTop: "1px dotted #eee",
        textAlign: "left"
    },
    head_element: {
        backgroundColor: "#242323 !important",
        borderBottom: "1px dotted #eee",
        color: "white !important",
        textAlign: "left"
    },
    bread_crumb: {
        padding: "8px 15px",
        marginBottom: "0px",
        listStyle: "none",
        backgroundColor: "#222222",
        borderRadius: "4px",
        display: "flex"
    },
    anchor_tag: {
        color: "#FFF"
    }
}));

const Recon = props => {
    const [state, setState] = useState({ tableNum: 0, period: '', product: '' });
    const [reconArray, setReconArray] = useState([]);
    const classes = useStyles();

    const getReconData = (collectionName, pageCount, setResponse) => {
        let conditions = [{
            "id": "230503f3",
            "selectedConditionDataElement": "EmployerId",
            "selectedConditionOperator": "matches",
            "value": props.user.id,
            "selectedConditionClause": "and"
        }];

        const uri = API_BASE_URL + "/dataconnect/data/" + collectionName + "?page=0&rowsPerPage=" + (pageCount ? pageCount : 10)

        axios({
            url: uri,
            method: 'GET',
            headers: {
                'Content-Type': "application/json",
                appId: props.match.params.appid
                // appId: "5f2ab15279813563bcaa3887"
            },
        }).then((response) => {
            console.log("data", response)
            setResponse(response.data.data);
        }).catch((error) => {
            setResponse({ "error": error });
            // console.log("error", error)
        })
    }

    useEffect(() => {
        props.loadEntities(props.match.params.appid, props.user.orgId);
        getReconData("Reconciliation", 10, (res) => {
            console.log("res", res)
            setReconArray(res)
        });
    }, [])

    return (
        <div style={{ marginTop: "5px" }}>
            <div className="main" style={{ backgroundColor: "#231F20", padding: "10px" }}>
                <ul className={classes.bread_crumb}>
                    <li><Link to={`/apps/${props.match.params.appid}/ibcDashboard`} style={{ color: "#FFF", textDecoration: "none" }}>Dashboard</Link></li>
                </ul>
                {/* <div className="row" style={{ padding: "10px", display: "flex" }}>
                    <div className="col-lg-2">
                        <span style={{ padding: "5px", fontSize: "1.2em", color: "#fff" }}>Select Period:</span>
                        <Select style={{ backgroundColor: "#404040", width: "200px" }} onChange={(e) => setState({ ...state, period: e.target.value })}>
                            <MenuItem key={0} value={""}>{"Select"}</MenuItem>
                            <MenuItem>2020 - Quarter 2</MenuItem>
                            <MenuItem>2020 - Quarter 1</MenuItem>
                            <MenuItem>2019 - Quarter 4</MenuItem>
                            <MenuItem>2019 - Quarter 3</MenuItem>
                            <MenuItem>2019 - Quarter 2</MenuItem>
                            <MenuItem>2019 - Quarter 1</MenuItem>
                        </Select>
                    </div>
                    <div className="col-lg-2">
                        <span style={{ padding: "5px", fontSize: "1.2em", color: "#fff" }}>Select Product:</span>
                        <Select style={{ backgroundColor: "#404040", width: "200px" }} onChange={(e) => setState({ ...state, product: e.target.value })}>
                            <MenuItem key={0} value={""}>{"Select"}</MenuItem>
                            <MenuItem>Auto Insurance</MenuItem>
                            <MenuItem>Property & Casuality</MenuItem>
                            <MenuItem>Home Owners</MenuItem>
                            <MenuItem>Travel</MenuItem>
                            <MenuItem>Marine</MenuItem>
                            <MenuItem>Business</MenuItem>
                        </Select>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="panel panel-default">
                            <div className="panel-heading" style={{ color: "#FFF" }}>
                                <h4 style={{ fontSize: "24px" }}>Reconciliation Issues</h4>
                                {/* <button className="btn btn-md btn-primary" style={{ float: "right", marginTop: "-60px", color: "#ffffff", backgroundColor: "#2a9fd6", borderColor: "#2a9fd6", padding: "10px" }}>Download</button>
                                <button className="btn btn-md btn-primary" style={{ float: "right", marginTop: "-60px", marginRight: "100px", color: "#ffffff", backgroundColor: "#2a9fd6", borderColor: "#2a9fd6", padding: "10px" }} href="spotrecon">Spot Recon</button>
                                <button className="btn btn-md btn-primary" style={{ float: "right", marginTop: "-60px", marginRight: "210px", color: "#ffffff", backgroundColor: "#2a9fd6", borderColor: "#2a9fd6", padding: "10px" }}>New Job</button> */}
                            </div>
                            <TableContainer component={Paper}>
                                <Table style={{ backgroundColor: "#eee" }}>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "#242323" }}>
                                            <TableCell className={classes.head_element}><input className={classes.checkbox} type="checkbox" /></TableCell>
                                                <TableCell className={classes.head_element}>Case #</TableCell>
                                                <TableCell className={classes.head_element}>Period</TableCell>
                                                <TableCell className={classes.head_element}>Owner</TableCell>
                                                <TableCell className={classes.head_element}>Sup. Document</TableCell>
                                                <TableCell className={classes.head_element}>Reconciled</TableCell>
                                                <TableCell className={classes.head_element}>Issues</TableCell>
                                                <TableCell className={classes.head_element}>Payment</TableCell>
                                                <TableCell className={classes.head_element}>Diff.</TableCell>
                                                <TableCell className={classes.head_element}>Action Required</TableCell>
                                                <TableCell className={classes.head_element}>Closed</TableCell>
                                                <TableCell className={classes.head_element}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                            {reconArray && reconArray.map((item) => {
                                                // let date = moment(state.month).format("mm/dd/YYYY")
                                                // let isFilter = false;
                                                // if ((state.product === "" || item.Owner.replace(" ", "").includes(state.product.replace(" ", ""))) && (state.period === "" || item.Period.replace(" ", "").includes(state.period))) {
                                                //     isFilter = true;
                                                // }
                                                // console.log("isFilter123",isFilter)
                                                // if (isFilter) {
                                                return <TableRow>
                                                    <TableCell className={classes.element}><input className={classes.checkbox} type="checkbox" /></TableCell>
                                                    <TableCell className={classes.element}>{item.CaseId}</TableCell>
                                                    <TableCell className={classes.element}>{item.Period}</TableCell>
                                                    <TableCell className={classes.element}>{item.Owner}</TableCell>
                                                    <TableCell className={classes.element}>{item.SupDocument}</TableCell>
                                                    <TableCell className={classes.element}>{item.Reconciled}</TableCell>
                                                    <TableCell className={classes.element}>{item.Issues}</TableCell>
                                                    <TableCell className={classes.element}>{item.Payment}</TableCell>
                                                    <TableCell className={classes.element}>{item.Diff}</TableCell>
                                                    <TableCell className={classes.element}>{item.ActionRequired}</TableCell>
                                                    <TableCell className={classes.element}>{item.Closed}</TableCell>
                                                    <TableCell className={classes.element}>
														<span className={classes.link}><a href={item.DetailsLink} className={classes.anchor_tag}>Details</a></span>
														<span className={classes.link}><a href={item.ReportLink} className={classes.anchor_tag}>Report</a></span>
														<span className={classes.link}><a href={item.VerifyLink} className={classes.anchor_tag}>Verify</a></span>
													</TableCell>
                                                </TableRow>
                                            //  }
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
}

const mapStateToProps = state => {
    return {
        user: state.user,
        app: state.appConfig.app,
        member: state.appConfig.member,
        entities: state.entities
    };
};

const mapDispatchToProps = {
    loadEntities
};


export default hot(withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Recon)),
);