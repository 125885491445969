import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    width:"100%",
    marginBottom: '10px',
    marginRight:'10px',
    '& .MuiTypography-root': {
      fontWeight: 700,
      marginBottom: '15px',
    },
    '& .MuiFilledInput-underline': {
      width: '100%',
    },
    '& .MuiSlider-root': {
      color: '#2196f3',
    },
    '& .MuiInput-formControl':{
        marginTop: '16px !important'
    },
    '& .MuiFormLabel-root':{
        padding: '0px !important'
    }
  },
  formControl: {
    width: '100%',
  },
  container: {
    display: 'flex',
    '& .MuiFormControl-root':{
      margin:0
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
export const SelectType = props => {
  const classes = useStyles();
  const { selectData, onFilterChange, page } = props;

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider" gutterBottom>
        {selectData.label}
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={
            page.conditions.find(el => el.selectedConditionDataElement === selectData.value)?.value
          }
          defaultValue=""
          name={selectData.value}
          onChange={onFilterChange}
          // label={selectData.label}
          disabled={
            page.conditions.find(el => el.selectedConditionDataElement === selectData.value)
              ? true
              : false
          }
        >
          {selectData.data?.map(item => (
            <MenuItem value={item} key={item}>
              <em>{item}</em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const DateRangeType = props => {
  const classes = useStyles();
  const { selectData, onFilterDateChange } = props;
  return (
    <div className={classes.root}>
      <Typography id="discrete-slider" style={{marginBottom:"10px"}} gutterBottom>
        {selectData.label}
      </Typography>
      <form className={classes.container} noValidate>
        <TextField
          id="from"
          label="from"
          type="date"
          // defaultValue="2017-05-24"
          name={selectData.value}
          className={classes.textField}
          onChange={onFilterDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="to"
          label="to"
          type="date"
          // defaultValue="2017-05-24"
          name={selectData.value}
          style={{marginLeft:"7px"}}
          className={classes.textField}
          onChange={onFilterDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </form>
    </div>
  );
};

export const SliderType = props => {
  const classes = useStyles();
  const { selectData, onFilterChange } = props;
  function valuetext(value) {
    return `${value}°C`;
  }
  return (
    <div className={classes.root}>
      <Typography id="discrete-slider" gutterBottom>
        {selectData.label}
      </Typography>
      <div style={{ padding: '8px 8px 0 8px' }}>
        <Slider
          defaultValue={30}
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={50}
          marks
          name={selectData.value}
          onChange={(va, val) => onFilterChange({ target: { name: selectData.value, value: val } })}
          min={selectData.data ? Math.min(...selectData.data) : 0}
          max={selectData.data ? Math.max(...selectData.data) : 0}
        />
      </div>
    </div>
  );
};
