import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import * as d3 from "d3";
import bullet from './bullet';
// import './bulletChart.css';

const BulletChart = (props) => {
    const [data, setData] = useState(data);


    const randomize = (d) => {
        if (!d.randomizer) d.randomizer = randomizer(d);
        d.ranges = d.ranges.map(d.randomizer);
        d.markers = d.markers.map(d.randomizer);
        d.measures = d.measures.map(d.randomizer);
        return d;
    }
  
    const randomizer = (d) => { 
        var k = d3.max(d.ranges) * .2;
        return function(d) {
            return Math.max(0, d + k * (Math.random() - .5));
        };
    }

    const drawBulletChart = (data) => {
        var margin = {top: 5, right: 40, bottom: 20, left: 120},
            width = 960 - margin.left - margin.right,
            height = 50 - margin.top - margin.bottom;

        var chart = bullet()
            .width(width)
            .height(height);

        var svg = d3.select("#bullchart").selectAll("svg")
            .data(data)
            .enter().append("svg")
            .attr("class", "bullet")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .call(chart);

        var title = svg.append("g")
            .style("text-anchor", "end")
            .attr("transform", "translate(-6," + height / 2 + ")");

        title.append("text")
            .attr("class", "title")
            .text(function(d) { return d.title; });

        title.append("text")
            .attr("class", "subtitle")
            .attr("dy", "1em")
            .text(function(d) { return d.subtitle; });
    }

    useEffect(() => {
        drawBulletChart(props.data);
    }, [props.data])

    return (
        <div id="bullchart">
            <div id="full-container">
            </div>
        </div>
    )
}
export default BulletChart;