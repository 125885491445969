import { hot } from 'react-hot-loader/root';
import compose from 'recompose/compose';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, CardActions, Button, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import Chart from 'components/charts/Chart';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import history from 'modules/history';

import NotFound from 'routes/NotFound';
import Header from 'components/Header';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import Nav from 'components/Nav';
import PrivilegeFailure from 'components/PrivilegeFailure';
import AskDsiloBox from 'components/Layout/AskDsiloBox';
import Loading from 'components/Loading'
import {
	loadAppconfig,
	loadApps,
	loadEntities,
	loadPageconfig,
	loadPages,
	loadTheme,
} from 'store/actions';
import { APP_CONFIG } from 'constants/index';
import FormSubmit from 'components/Form/FormSubmit'

const styles = theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		margin: '0 auto',
	},
	paperCards: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		minHeight: '20vh',
		square: true
	},
	paperLayouts: {
		padding: theme.spacing(1, 1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		minHeight: 'calc(100vh - 50vh)'

	},
	card: {},
	cardContent: {
		padding: theme.spacing(0.5, 0.5),
	},
	tableTitle: {
		textAlign: 'left',
		padding: theme.spacing(1, 0.5),
		color: '#000',
	},
	layout: {
		backgroundColor: '#060606'
	}
});

const colCard = 3;

class AskDsilo extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			appId: "",
			route: "",
			queryString: "",
			appPrivilegeCheck: null,
			pagePrivilegeCheck: null,
			accessablePages: [],
			validatePrivilegeChecks: (APP_CONFIG.APP_PRIVILEGECHECKS == "true")
		};
	}

	getAppInfo() {
		let path = this.props.location.pathname.split('/')
		let appId = path[2];
		let route = path[3];
		return { appId, route };
	}

	getQureyFromParams() {
		let path = this.props.location.pathname.split('/')
		let key = path[4] ? path[4] : undefined;
		let value = path[5] ? path[5] : undefined;
		return key && value ? "key=" + key + "&" + "value=" + value : '';
	}

	componentDidMount() {
		let appInfo = this.getAppInfo();
		let queryString = this.getQureyFromParams();
		this.props.loadAppconfig(appInfo.appId, this.props.user.orgId);
	}

	componentDidUpdate(prevProps, prevState) {
		let appInfo = this.getAppInfo();

		console.log("this.state.validatePrivilegeChecks", this.state.validatePrivilegeChecks);

		if (this.state.validatePrivilegeChecks && this.props.app && this.props.app._id
			&& !this.props.error && !this.props.entities.entities) {
			this.props.loadEntities(appInfo.appId, this.props.user.orgId);
		}

		if (this.state.validatePrivilegeChecks
			&& this.props.entities.entities && this.props.entities.entities.length > 0
			&& this.props.user.roles && this.props.app.selectedRoles
			&& this.state.appPrivilegeCheck === null) {
			let matchedRoles = this.props.app.selectedRoles.filter(item => {
				for (let i = 0; i < this.props.user.roles.length; i++) {
					if (item.value === this.props.user.roles[i]._id) {
						return true
					}
				}
			})
			this.setState({
				appPrivilegeCheck: !(this.props.app.selectedRoles.length > 0) || matchedRoles.length > 0
			});
		}

		if ((!this.state.validatePrivilegeChecks
			|| (this.props.entities.entities && this.props.entities.entities.length > 0
				&& this.props.entities.entities != prevProps.entities.entities))
			&& !Array.isArray(this.props.pages)) {
			this.props.loadPages(appInfo.appId, this.props.user.orgId);
		}

		if (this.state.validatePrivilegeChecks && !this.state.appPrivilegeCheck
			&& appInfo.route && this.props.pages && this.props.pages.length > 0) {
			let accessablePages = this.props.pages.filter(page => {
				let matchedRoles = page.selectedRoles.filter(role => {
					for (let i = 0; i < this.props.user.roles.length; i++) {
						if (role.value === this.props.user.roles[i]._id) {
							return true
						}
					}
				})
				return matchedRoles.length > 0;
			})
			let page = accessablePages.find(p => p.url === appInfo.route)
			this.setState({ accessablePages, pagePrivilegeCheck: page && page.url === appInfo.route });
		}
	}

	getDefaultPage(navigation) {
		return navigation.links.find((nav) => {
			return nav.default
		})
	}

	render() {
		const { classes, app, user } = this.props;
		const { accessablePages } = this.state;

		let appInfo = this.getAppInfo();

		let pages = this.state.validatePrivilegeChecks &&
			!this.state.appPrivilegeCheck && this.props.pages && appInfo.route
			? accessablePages
			: this.props.pages && this.props.pages

		let page = (pages && pages.length > 0) && pages.find(p => p.url === appInfo.route)

		if (!page && !appInfo.route) {
			page = (pages && pages.length > 0) && pages.find(p => p.default === true)
		}

		if (!page && !appInfo.route) {
			page = pages[0];
		}

		let header = app && {
			title: app.title,
			subTitle: app.subTitle
		}

		let footer = app && {
			title: app.footer
		}
		page = page && JSON.parse(JSON.stringify(page));

		let queryString = this.getQureyFromParams();
		return (
			<React.Fragment>
				{(this.props.app && pages
					&& (this.props.entities.entities && this.props.entities.entities.length > 0
						&& (this.state.appPrivilegeCheck || !appInfo.route || this.state.pagePrivilegeCheck)))
					|| !this.state.validatePrivilegeChecks
					? (
						<React.Fragment>
							<NavBar header={header} user={user} app={app} />
							<Nav pages={pages} appId={appInfo.appId} />
							<AskDsiloBox className="classes.layout" pages={this.props.pages}
								queryString={queryString} loading={this.props.pageLoading}
								accessablePages={accessablePages} appId={appInfo.appId} />

						</React.Fragment>
					) : (
						(this.props.error && this.props.loading === false)
							? <NotFound />
							: ((this.props.app._id
								&& this.props.entities.entities
								&& !this.props.entities.entities.length > 0)
								|| (this.props.app._id && this.props.entities.entities
									&& (!this.state.appPrivilegeCheck || !this.state.pagePrivilegeCheck)
									&& !this.props.entities.entities.loading)
								? <PrivilegeFailure />
								: <Loading />
							)
					)}
			</React.Fragment>
		);
	}
};

const mapDispatchToProps = {
	loadAppconfig,
	loadApps,
	loadPageconfig,
	loadPages,
	loadTheme,
	loadEntities
};

const mapStateToProps = state => {
	return {
		app: state.appConfig.app,
		error: state.appConfig.error || state.app.error,
		loading: state.appConfig.loading,
		apps: state.app.app,
		pages: state.pages.page,
		theme: state.theme,
		user: state.user,
		entities: state.entities,
		pageLoading: state.pages.loading
	};
};

export default hot(compose(
	withStyles(styles, {
		name: 'App',
	}),
	connect(mapStateToProps, mapDispatchToProps),
)(AskDsilo));