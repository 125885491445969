import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { getFileNameWithTimeStamp, getMonth } from 'utility/utils';
import FileDownload from 'js-file-download';
import axios from 'axios';
import GetAppIcon from '@material-ui/icons/GetApp';
import Header from '../../SpendPlan/Header';
import SpendReconciliationTable from './spendReconcilicationTable';
import { getMrEcommSpendReconciliationData } from '../../../../../store/actions/custom/spendplan/mrEcommSpendReconciliation';
import { getCurrentFFForPayload, getFiscalYearFormat } from "../../spendplanUtils";
import moment from 'moment';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  donwloadBtn: {
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    marginRight: 15,
  },
}));

const MrEcommSpendReconciliation = (props) => {
  const classes = useStyles();
  const { brand, scenario } = props;

  useEffect(() => {
    fetchData();
  }, [brand, scenario]);

  const fetchData = () => {
    let { BrandName, FinancialYear, CurrentFF } = brand;
    let currentMonth = getMonth(CurrentFF, 'monthNumber')
      .toString();
    if (currentMonth.length === 1) {
      currentMonth = '0' + currentMonth;   
    }
    let version = false;
    const modalData = props.modalData;
    if (modalData && modalData.brand && modalData.month && modalData.year) {
      BrandName = modalData.brand;
      FinancialYear = modalData.year;
      currentMonth = getCurrentFFForPayload(modalData.month);
      version = true;
    }
    if (currentMonth) {
      let data = {
        appId: props.match.params.appid,
        url: `/${BrandName}/${FinancialYear}/${currentMonth}/${scenario}`,
      };
      if (version) {
        data.url += '?version=true'
      }
      props.getMrEcommSpendReconciliationData(data);
    }
  };


  const downloadHandler = () => {
    if (brand) {
      let currentMonth = getMonth(brand.CurrentFF, 'monthNumber')
        .toString();
      if (currentMonth.length === 1) {
        currentMonth = '0' + currentMonth;
      }
      let _brandName = props.modalData ? props.modalData.brand : brand.BrandName;
      let _financialYear = props.modalData ? props.modalData.year : brand.FinancialYear;
      let _currentMonth = props.modalData ? getCurrentFFForPayload(props.modalData.month) : currentMonth;
      let uri = props?.scenario ? API_BASE_URL + `/imar/mrecomm/download/${_brandName}/${_financialYear}/${_currentMonth}/${props?.scenario}` : API_BASE_URL + `/imar/mrecomm/download/${_brandName}/${_financialYear}/${_currentMonth}`;
      let fyFF = getFiscalYearFormat(_financialYear) + '-' + moment().month(Number(_currentMonth)-1).format('MMM');
      if(props.modalData){
        uri+='?version=true'
      }
      axios({
        url: uri,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          appId: props.match.params.appid,
        },
      })
        .then((response) => {
          FileDownload(response.data, getFileNameWithTimeStamp('MR Ecomm Spends Recon'+ '-'+ brand.BrandName + '-'+ fyFF));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className={classes.root} style={{ paddingBottom: '15px' }}>
     <Grid container justify="space-between">
        <Grid item xs={10} sm={10}>
          <Header header={props.modalData?props.versionTitle:`MR and Ecomm Spends Reconciliation - ${brand && brand.CurrentFF && brand.FinancialYear
            ? `FY ${getFiscalYearFormat(brand.FinancialYear)} ${getMonth(brand.CurrentFF, 'month')} FF`
            : 'FY 2021'}`}/>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.donwloadBtn}
            size="medium"
            onClick={downloadHandler}
            startIcon={<GetAppIcon/>}
          > {'Download'} </Button>
        </Grid>
      </Grid>
      <SpendReconciliationTable modalData={props.modalData} />
    </div>
  );
};


const mapDispatchToProps = {
  getMrEcommSpendReconciliationData,
};

const mapStateToProps = state => {
  return {
    brand: state.tprReducer?.selectBrand,
    scenario: state.tprReducer?.selectScenario,
  };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(MrEcommSpendReconciliation)));
