import { MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMrData } from '../../../../../store/actions';
import { getMonth, getValue } from '../../../../../utility/utils';
import Loader from '../../../../Loader';
import { getFFFYValues } from '../../../../table/navbarHelper';
import PlanningErrorModal from '../../../spendplan/PlanningPages/planningErrorModal';
import { convertToNumber, renderNoDataText } from '../../../spendplan/PlanningPages/planningHelperFunctions.js';
import { getFiscalYearFormat, monthsUpperCase } from '../../../spendplan/spendplanUtils';
import { URLs } from '../../ApiConstants.js';
import { StyledTableCell, StyledTableRow, useStyles } from '../Summary/summarySheetTable.js';
import { changeVsPrevCols, columnTypes, currentFFCols, lastYearCols, previousCols } from './data';
import { Pages } from './index'
import ViewColumns from './viewColumns';

const CustomerViewTable = (props) => {

    const [filterCols, setFilterCols] = useState(columnTypes)
    const date = new Date();
    let year = date.getFullYear() + "";

    const classes = useStyles();
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = useState(false);
    const [alertMessageText, setAlertMessage] = React.useState("");
    const [selectedFFFY, SetFFFY] = useState({ CurrentFF: date.toLocaleString('default', { month: 'long' }), FinancialYear: year })
    const [mainHeadingsWidth, setMainHeadingWidth] = useState([10, 10, 10, 10])
    let isCategoryView = props.selectedBrand === Pages.CategoryView;
    const OneCr = 10000000

    let arr = [
        ...currentFFCols(),
        ...changeVsPrevCols(),
        ...previousCols(),
        ...lastYearCols(),
    ];

    const getHeadingFilterValues = () => {
        return arr.filter(i => i.showInFilter).map(i => ({ ...i, checked: true }))
    }
    const [headings, setHeadings] = useState(getHeadingFilterValues())

    const onChangeHeadings = (ind, isChecked) => {
        let _filterCols = [...filterCols]
        let updatedHeadings
        if (ind === 'all') { // select All case
            _filterCols = _filterCols.map(ii=> ({...ii, isActive: isChecked}))
            updatedHeadings = headings.map(i => ({ ...i, checked: isChecked }))
        } else {
            _filterCols[ind] = { ...filterCols[ind], isActive: isChecked }
            let key = _filterCols[ind].key;
            updatedHeadings = headings.map(i => {
                if (i.mainKey === key) {
                    return {
                        ...i,
                        checked: isChecked
                    }
                }
                return i
            })
        }
        setFilterCols(_filterCols)
        setHeadings(updatedHeadings)
        let section1Width = 0
        let section2Width = 0
        let section3Width = 0
        let section4Width = 0
        /*  Recalculating colSpan of firstRow items whenever filter is checked */
        updatedHeadings.forEach(i => {
            if (i.key.startsWith('currentFF')) {
                // section 1 case
                if (i.colSpan) {
                    section1Width = section1Width + (i.checked ? 1 : 0);
                } else {
                    if (i.collapse) {
                        section1Width = section1Width + (i.checked ? 1 : 0);
                    } else {
                        section1Width = section1Width + (i.checked ? 5 : 0);
                    }
                }
            } else if (i.key.startsWith('changeFF')) {
                // section 2 case
                if (i.colSpan) {
                    section2Width = section2Width + (i.checked ? 1 : 0);
                } else {
                    if (i.collapse) {
                        section2Width = section2Width + (i.checked ? 1 : 0);
                    } else {
                        section2Width = section2Width + (i.checked ? 5 : 0);
                    }
                }

            } else if (i.key.startsWith('previousFF')) {
                // section 3 case
                if (i.colSpan) {
                    section3Width = section3Width + (i.checked ? 1 : 0);
                } else {
                    if (i.collapse) {
                        section3Width = section3Width + (i.checked ? 1 : 0);
                    } else {
                        section3Width = section3Width + (i.checked ? 5 : 0);
                    }
                }

            } else if (i.key.startsWith('fyActual')) {
                // section 4 case
                if (i.colSpan) {
                    section4Width = section4Width + (i.checked ? 1 : 0);
                } else {
                    if (i.collapse) {
                        section4Width = section4Width + (i.checked ? 1 : 0);
                    } else {
                        section4Width = section4Width + (i.checked ? 5 : 0);
                    }
                }
            }
        })
        setMainHeadingWidth([section1Width, section2Width, section3Width, section4Width])
    }

    const getYearHeading = (isMinusOne) => {
        if (selectedFFFY.FinancialYear) {
            return " (FY " + getFiscalYearFormat(selectedFFFY.FinancialYear, isMinusOne) + " FF " + moment().month(selectedFFFY.CurrentFF)?.format("MMM") + ")";
        }
        return ""
    }

    const mainHeadings = [
        { title: "Current FF" + getYearHeading(), colSpan: mainHeadingsWidth[0] },
        { title: "Change vs. Previous FF" + getYearHeading(), colSpan: mainHeadingsWidth[1] },
        { title: "Previous FF" + getYearHeading(), colSpan: mainHeadingsWidth[2] },
        { title: "Past Year (FY " + getFiscalYearFormat(selectedFFFY.FinancialYear, true) + ")", colSpan: mainHeadingsWidth[3] },
    ]

    const fetchData = () => {
        let m = '' + getMonth(selectedFFFY.CurrentFF, 'monthNumber')
        let _currentFF = props.modalData?.month || (m.length === 1 ? '0' + m : m);
        let payloadForAction = {

            url: (isCategoryView ? URLs.categoryView : URLs.customerView) + `?fiscalYear=${selectedFFFY.FinancialYear}&currentFF=${_currentFF}`,
            appId: props.match.params.appid,
            dataUniqueId: 'GET_customerView'
        }
        if (props.selectedBrand && !isCategoryView) {
            payloadForAction.url += '&brand=' + encodeURIComponent(props.selectedBrand);
        }
        props.getMrData(payloadForAction)
    };

    useEffect(() => {
        fetchData();
    }, [selectedFFFY, props.selectedBrand]);

    useEffect(() => {
        if (props.data && props.data['GET_customerView'] && !(props.error ? props.error['GET_customerView'] : false)) {
            let _data = props.data['GET_customerView'].result.data;
            if (props.modalData) {
                if (_data[0] && _data[0].detail) {
                    setTableData(_data[0].detail || [])
                }
            } else {
                setTableData(_data)
            }
        } else if (tableData.length) {
            setTableData([])
        }
    }, [props.data ? props.data['GET_customerView'] : null]);

    useEffect(() => {
        if (props.mrData.error && props.mrData.error.status === 400) {
            setOpen(true)
            setAlertMessage({
                title: 'Customer View',
                p1: props.mrData.error.error.response.error,
            });
        }
    }, [props.mrData.error]);

    const handleClose = () => {
        setOpen(false);

    };

    const getVisibleHeadings = () => {
        let obj = {}
        let collpasedIcon = {}
        headings.forEach(i => {
            obj[i.key] = i.checked
            collpasedIcon[i.key] = i.collapse
        })
        return arr.filter(ii => (obj[ii.key] || obj[ii.parentKey]) && !collpasedIcon[ii.parentKey])

    }

    const headers = getVisibleHeadings();

    const onCollapse = (item) => {
        let _headings = [...headings];
        let ind = _headings.findIndex(i => i.key === item.key)
        let isChecked = !_headings[ind].collapse
        if (ind > -1) {
            _headings[ind].collapse = isChecked
            setHeadings(_headings)

            let key = _headings[ind].key
            let index;
            if (key.startsWith('currentFF')) {
                index = 0
                // section 1 case
            } else if (key.startsWith('changeFF')) {
                // section 2 case
                index = 1
            } else if (key.startsWith('previousFF')) {
                // section 3 case
                index = 2
            } else if (key.startsWith('fyActual')) {
                // section 4 case
                index = 3
            }

            if (index !== undefined) {
                let newHeadingWidth = [...mainHeadingsWidth]
                newHeadingWidth[index] = mainHeadingsWidth[index] - (isChecked ? 4 : -4)
                setMainHeadingWidth(newHeadingWidth)
            }
        }
    }

    const getCollapseIcon = (item) => {
        let _heading = headings.find(ii => ii.key === item.key)
        if (_heading) {
            if (_heading.collapse) {
                return <AddBoxIcon className={classes.icon} onClick={() => onCollapse(item)} />
            } else {
                return <IndeterminateCheckBoxIcon className={classes.icon} onClick={() => onCollapse(item)} />
            }
        }

    }


    const getHeadings = () => {
        let visibleHeadings = getVisibleHeadings();
        return visibleHeadings.map((ii, ind) => {
            return (
                <TableCell key={ind}>
                    <div style={{ height: 20, marginTop: -10 }}>
                        {
                            (ii.collapse !== undefined) && getCollapseIcon(ii)
                        }
                    </div>
                    <span style={{ whiteSpace: 'nowrap', alignSelf: 'center', minWidth: 70, }}>{ii.title} </span>

                </TableCell>
            )
        })
    }

    const getUOMRow = () => {
        return headers.map((item, index) => {
            return (
                <TableCell key={index}>
                    <span style={{ alignSelf: 'center' }}>{item.uom} </span>
                </TableCell>
            )
        })
    }

    const getVal = (row, header) => {
        if (header.key) {
            if (header.isPercent) {
                return getValue(row, header.key) * 100 || 0
            }
            return getValue(row, header.key) || 0
        }
        return 0
    }

    const getFirstColHeading = () => {
        return isCategoryView ? 'Brand Name' : 'Customer Name'
    }

    const onFFFYChange = (e) => {
        let val = e.target.value.split('-');
        let dd = {
            "FinancialYear": val[0],
            "CurrentFF": monthsUpperCase[Number(val[1]) - 1]
        }
        SetFFFY(dd)
    }

    const isLoading = props.mrData && props.mrData['GET_customerView_loading']

    return (
        <React.Fragment>

            <div style={{ display: 'flex' }}>
                <div className={classNames(classes.headerMainCon, 'filter-container')}>
                    <Select
                        value={selectedFFFY.FinancialYear + '-' + (monthsUpperCase.indexOf(selectedFFFY.CurrentFF) + 1)}
                        className="custom-select fontWhite"

                        onChange={onFFFYChange}>
                        {getFFFYValues(selectedFFFY).map((item, i) => {
                            return <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                        })}
                    </Select>
                </div>
                <ViewColumns columns={filterCols} onChange={onChangeHeadings} />
            </div>
            {
                isLoading ? (
                    <section className={classes.tableBodyLoading}>
                        <Loader />
                    </section>
                ) : <TableContainer className={classNames(classes.tableContainer, "tpr-container")} component={Paper}>
                    <Table className={classNames(classes.table, "tpr-table")} aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                            <TableRow className='sticky-row'>
                                <TableCell rowSpan={2}>
                                    <span>{getFirstColHeading()}</span>
                                </TableCell>
                                {
                                    mainHeadings.map((ii, index) => {
                                        if (ii.colSpan) {
                                            return (
                                                <TableCell key={index} colSpan={ii.colSpan} style={{ borderBottom: 'none' }}>
                                                    <p className={classes.combinedHeaderHeading}>{ii.title}</p>
                                                </TableCell>
                                            )
                                        }
                                    })
                                }
                            </TableRow>
                            <TableRow className='sticky-row'>
                                {
                                    getHeadings()
                                }
                            </TableRow>
                            <TableRow className='sticky-row'>
                                <TableCell key={'-1'}>
                                    <span style={{ alignSelf: 'center' }}>{'UOM'} </span>
                                </TableCell>
                                {
                                    getUOMRow()
                                }
                            </TableRow>
                        </TableHead>

                        <TableBody className={classes.tableBody}>
                            {tableData?.map((row, index) => {
                                if (row['account'] && row['account'] === 'All accounts') { // removing common Account which is added for Channel Manager purpose 
                                    return null
                                }
                                return (
                                    <StyledTableRow key={index} className={index === 0 ? classes.rowBold : ''}>
                                        <StyledTableCell key={'-1'}>
                                            <span>{isCategoryView ? row['brand'] : row['account']}</span>
                                        </StyledTableCell>
                                        {
                                            headers.map((header, headIndex) => {
                                                return (
                                                    <StyledTableCell key={headIndex}>
                                                        <span title={getVal(row, header) + (header.isPercent ? '%' : '')}>{convertToNumber(getVal(row, header) / (header.divideByCr ? OneCr : 1), header.isPercent ? 2 : 1) + (header.isPercent ? '%' : '')}</span>
                                                    </StyledTableCell>
                                                )
                                            })
                                        }
                                    </StyledTableRow>)
                            }) || renderNoDataText()}

                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} />

        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
    };
};

const mapDispatchToProps = {
    getMrData,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps,)(CustomerViewTable)),);

