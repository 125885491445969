const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { request } from '../../../client';

export class TradePriceReductionService {
	static getSpTpr(data) {
		let uri = API_BASE_URL + `/sptpr/brand/${data.brand}?page=${data.page}&rowsPerPage=${data.rowsPerPage}`;		
		if (data.brandform?.length) {
			uri = `${uri}${`&brandform=${encodeURIComponent(JSON.stringify(data.brandform))}`}`;
		}
		if (data.channel?.length) {
			uri = `${uri}${`&channel=${encodeURIComponent(JSON.stringify(data.channel))}`}`;
		}
		if (data.cluster?.length) {
			uri = `${uri}${`&cluster=${encodeURIComponent(JSON.stringify(data.cluster))}`}`;
		}
		if (data.month?.length) {
			uri = `${uri}${`&month=${encodeURIComponent(JSON.stringify(data.month))}`}`;
		}
		if (data.quarter?.length) {
			uri = `${uri}${`&quarter=${encodeURIComponent(JSON.stringify(data.quarter))}`}`;
		}
		if (data.currentFF) {
			uri = `${uri}${`&currentFF=${encodeURIComponent(data.currentFF)}`}`;
		}
		if (data.fiscalYear) {
			uri = `${uri}${`&fiscalYear=${encodeURIComponent(data.fiscalYear)}`}`;
		}
		if (data?.scenario) {
			uri = `${uri}${`&scenario=${encodeURIComponent(data.scenario)}`}`;
		}
		if (data.planName?.length) {
			uri = `${uri}${`&planName=${encodeURIComponent(JSON.stringify(data.planName))}`}`;
		}

		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appId
			}
		});
	}
	static getSpTprExtraData(data) {
		const uri = `${API_BASE_URL}/sptpr/calculationData/${data.dataModalId}`;
		return request(uri, {
			method: 'POST',
			payload: data.filteredData,
			headers: {
				appId: data.appid
			}
		});
	}
	static addSpTprRow = (data) => {
		const uri = API_BASE_URL + "/sptpr";
		return request(uri, {
			method: 'POST',
			payload: data.row,
			headers: {
				appId: data.appid
			}
		});
	}

	static getBrandformfilter(data) {
		const uri = API_BASE_URL + "/setupdata/brandforms/" + data?.brandName;
		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appid
			}
		});
	}

	static getChannelFilter(data) {
		const uri = API_BASE_URL + "/setupdata/list/channels";
		return request(uri, {
			method: 'POST',
			payload: {
				brandFormName: data.brandFormName, formType: data.formType
			},
			headers: {
				appId: data.appid
			}
		});
	}

	static getClusterFilter(data) {
		const uri = API_BASE_URL + "/setupdata/clusters";
		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appid
			}
		});
	}

	static getBrandSelectFilter(data) {
		const uri = API_BASE_URL + "/setupdata/list/channels";
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: {
				appId: data.appid
			}
		});
	}

	static deleteCheckedTpr(data) {
		const uri = API_BASE_URL + "/sptpr";
		return request(uri, {
			method: 'DELETE',
			payload: { sptpr_ids: data.sptpr_ids, ...data.commonObj },
			headers: {
				appId: data.appid
			}
		});
	}

	static freezePlans(data) {
		let uri = '';
		let payloadToSend = JSON.parse(JSON.stringify(data));
		if (data.page === 'OmniChannels') {
			payloadToSend['account'] = data.brands && data.brands.length && data.brands[0];
			delete payloadToSend['brands'];
			uri = API_BASE_URL + "/mrspendplan/account/freeze-omnichannel"
		} else {
			uri = API_BASE_URL + "/brands/freezplan"
		}
		delete payloadToSend.page;
		return request(uri, {
			method: 'POST',
			payload: payloadToSend,
			headers: {
				appId: payloadToSend.appid
			}
		});
	}

	static getTopFivePlans(data) {
		let { brand, fiscalYear, currentFF, appid } = data
		const uri = API_BASE_URL + `/sptpr/budget.wise/${brand}?page=0&rowsPerPage=5&fiscalYear=${fiscalYear}&currentFF=${currentFF}`;
		return request(uri, {
			method: 'GET',
			headers: {
				appId: appid
			}
		});
	}

	static fetchCheckData(data) {
		const uri = API_BASE_URL + "/setupdata/actions/getactions";
		return request(uri, {
			method: 'POST',
			payload: data.body,
			headers: {
				appId: data.appid
			}
		});
	}

	static getPlans(data) {
		let { brand, fiscalYear, currentFF, appid, url, scenario } = data
		const uri = API_BASE_URL + `/${url}/plans/${brand}?fiscalYear=${fiscalYear}&currentFF=${currentFF}&scenario=${scenario}`;
		return request(uri, {
			method: 'GET',
			headers: {
				appId: appid
			}
		});
	}
	
	static checkBGProcesses(data) {
		const uri = API_BASE_URL + `/bgprocess?brand=${data.brand}&fiscalYear=${data.fiscalYear}&currentFF=${data.currentFF}`;
		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appId
			}
		});
	}

	static checkFreezePlanProcesses(data) {
		const uri = API_BASE_URL + `/bgprocess?name=FREEZE_PLAN`;
		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appId
			}
		});
	}
	
	static changeFFFY(data) {
		const uri = API_BASE_URL + `/brands/switchFyFF`;
		return request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appId: data.appId
			}
		});
	}

	static removeCurrentFFData(data) {
		const uri = API_BASE_URL + `/brands/removeFyFF`;
		return request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appId: data.appId
			}
		});
	}
	
	static pullDataFromFF(data) {
		const uri = API_BASE_URL + `/brands/pulldata`;
		return request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appId: data.appId
			}
		});
	}
	
	static getTSPData(data) {
        return request(API_BASE_URL + data.url, {
            method: 'GET',
            headers: {
                appId: data.appId
            }
        });
    }

	static doTSPVersioning(data) {
		const uri = API_BASE_URL + `/brands/version`;
		return request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appId: data.appId
			}
		});
	}

	static getImarTableDataDashboard(data) {
		let { brand, fiscalYear, currentFF, appid, url, scenario, scenarioArr = [] } = data
		if(!brand || !fiscalYear || !currentFF) return;
		const uri = API_BASE_URL + `/imar/dashboard/${brand}/${fiscalYear}/${currentFF}/${scenario}?scenarioArr=${scenarioArr}`;
		return request(uri, {
			method: 'GET',
			headers: {
				appId: appid
			}
		});
	}

	static addScenario(data) {
		const uri = API_BASE_URL + `/brands/addScenario`;
		return request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appId: data.appId
			}
		});
	}

	static finalScenario(data) {
		const uri = API_BASE_URL + `/brands/finalScenario`;
		return request(uri, {
			method: 'PUT',
			payload: data.data,
			headers: {
				appId: data.appId
			}
		});
	}

	static getScenarioList(data) {
		const uri = API_BASE_URL + `/brands/getScenarioList?account=${data.account}&fiscalYear=${data.fiscalYear}&currentFF=${data.currentFF}`;
		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appId
			}
		});
	}

	static getScenario(data) {
		const uri = API_BASE_URL + `/brands/getScenario`;
		return request(uri, {
			method: 'PUT',
			payload: data.payload,
			headers: {
				appId: data.appId
			}
		});
	}

	static updateScenarioLable(data) {
		const uri = API_BASE_URL + `/brands/updateScenarioLable`;
		return request(uri, {
			method: 'PUT',
			payload: data.data,
			headers: {
				appId: data.appId
			}
		});
	}
}
