import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMrData } from '../../../../../store/actions';
import { sortAlphabetically } from '../../../../../utility/utils';
import { useStyles } from '../../../spendplan/PlanningPages/PlanHeader';
import CustomerViewTable from './customerViewTable';

// 1st value is not brand it is page name

export const Pages = {
    CustomerView: 'Customer View',
    CategoryView: 'Category View',
}

const CustomerViewReport = (props) => {

    useEffect(() => {
        let dataModelId = '617f826eb336d60019d00226'; // TODO: need to make it configurable
        if (props.account) {
            let targetURL = "/dataconnect/filter/" + dataModelId;
            targetURL += `?type=chart`;
            targetURL += '&label=brand';
            let payload = {
                url: targetURL,
                appId: props.match.params.appid,
                dataUniqueId: 'customerViewBrands'
            }
            props.getMrData(payload)
        }
    }, [])

    const getFilterData = () => {
        if (props.data && props.data['customerViewBrands']) {
            return props.data['customerViewBrands']?.brand || []
        }
        return []
    }

    const classes = useStyles();
    const brands = [Pages.CustomerView, Pages.CategoryView, ...sortAlphabetically(getFilterData())] // using 
    const [tabVal, setTabVal] = useState(brands[0]);


    return (<div style={{ marginTop: -8, paddingTop: 8, }}>
        <div className={classes.headingBox} style={{ overflow: 'auto' }}>
            <div className="tab-container" style={{ height: 40, }}>
                {brands.map((link, i) => {
                    let isActive = link === tabVal;
                    return <div key={i}
                        className={`setup-data-headings p-bottom-10 p-right-20 p-left-20 ${isActive ? `active-global-link` : ``}`}
                        onClick={() => {
                            setTabVal(link);
                        }}>
                        <span style={{ whiteSpace: 'noWrap' }} className={isActive ? 'select-global-link' : 'normal-link'}>{link}</span>
                    </div>;
                })}
            </div>
        </div>
        <CustomerViewTable selectedBrand={(tabVal === brands[0] ? null : tabVal)} />

    </div>)

};

const mapStateToProps = state => {
    return {
        user: state.user,
        account: state.mrReducer?.account,
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
    };
};

const mapDispatchToProps = {
    getMrData,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps,)(CustomerViewReport)),);


