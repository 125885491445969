import React, { Component, useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root'
import { Button, TextField, withStyles } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles, Table } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    head: {
        backgroundColor: "#333",
        color: "#fff",
        whiteSpace: "nowrap"
    },
    customFont: {
        color: "#000",
        fontSize: "14px",
        whiteSpace: "nowrap"
    },
    acitonStyle: {
        padding: '0 5px'
    }
}));
const MultiLine = (props) => {
    let { headers, data, memberEdit, memberDelete, refresh, setup } = props
    const classes = useStyles();
    const [currentIndex, setCurrentIndex] = useState()
    const [currentRow, setCurrentRow] = useState()
    const [columns, setColumns] = useState(["Employee ID", "Employee Name", "Date Of Birth",
        "Bill Group", "Class", "Base Annual Salary", "Annual Bonus",
        "Employee Optional Life Volume", "Spouse Life Volume", "Child Life Volume", 'Credit', 'Debit'])

    const editRowData = (index) => {
        console.log('editRowData', index);
        setCurrentIndex(index)
        setCurrentRow(data[index])
    }

    const editCurrentRow = (e, header) => {
        let row = { ...currentRow }
        let f = setup.basicKeyMapping.employeeCompensation.find(e => {
            return header === e.key
        })

        if (f)
            row[header] = parseFloat(e.target.value)
        else
            row[header] = e.target.value
        setCurrentRow(row)
    }

    const cancel = () => {
        setCurrentIndex()
        setCurrentRow()
    }

    useEffect(() => {
        cancel()
    }, [refresh])

    const getName = (key) => {
        console.log('e.key).toLowerCase()', key.toLowerCase())
        if (key.toLowerCase() === "age") {
            return "Date of Birth"
        } else if (key.toLowerCase() === "credit") {
            return "Back Credit"
        } else if (key.toLowerCase() === "debit") {
            return "Back Charges"
        } else {
            return key
        }
    }

    console.log('headers??===??', headers);
    console.log("data==", data);
    return (
        <>
            <Table className={classNames(classes.table)} size="small" aria-label="a dense table" style={{ color: 'white' }}>
                <TableHead className={classNames(classes.head)}>
                    <TableRow>
                        {/* {headers && Object.values(headers).map((item) => {
                            return (<TableCell className={classNames(classes.head)}>{getName(item)}</TableCell>);
                        })} */}
                        {columns && columns.map((item) => {
                            return (<TableCell className={classNames(classes.head)}>{getName(item)}</TableCell>);
                        })}
                        <TableCell className={classNames(classes.head)}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && Array.isArray(data) && data.map((row, index) => {
                        return (
                            <TableRow>
                                {/* {currentIndex != index && headers && Object.values(headers).map((header) => {
                                    return <TableCell className={classNames(classes.customFont)}>
                                        {row[header]}
                                    </TableCell>
                                })}

                                {currentIndex == index && currentRow && headers && Object.values(headers).map((header) => {
                                    return <TableCell className={classNames(classes.customFont)}>
                                        <TextField style={{ minWidth: '60px' }}
                                            value={currentRow[header] || currentRow[header] === 0 ? currentRow[header] : ''}
                                            onChange={(e) => { editCurrentRow(e, header) }}
                                            variant="outlined" />
                                    </TableCell>
                                })} */}
                                {currentIndex != index && columns && columns.map((header) => {
                                    return <TableCell className={classNames(classes.customFont)}>
                                        {row[header]}
                                    </TableCell>
                                })}

                                {currentIndex == index && currentRow && columns && columns.map((header) => {
                                    return <TableCell className={classNames(classes.customFont)}>
                                        <TextField style={{ minWidth: '60px' }}
                                            value={currentRow[header] || currentRow[header] === 0 ? currentRow[header] : ''}
                                            onChange={(e) => { editCurrentRow(e, header) }}
                                            variant="outlined" />
                                    </TableCell>
                                })}
                                <TableCell>
                                    <div className="button-group pt-7">
                                        {currentIndex != index && <>
                                            <IconButton
                                                onClick={(e) => { editRowData(index) }}
                                                size="medium" color="primary" aria-label="Edit"
                                                className={classes.acitonStyle}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={(e) => { memberDelete(index) }}
                                                color="secondary"
                                                size="medium" aria-label="Delete"
                                                className={classes.acitonStyle}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </>}
                                        {currentIndex == index && currentRow && <>
                                            <IconButton
                                                onClick={(e) => { memberEdit(index, currentRow) }}
                                                size="medium" color="primary" aria-label="Save"
                                                className={classes.acitonStyle}
                                            >
                                                <SaveIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={(e) => { cancel() }}
                                                size="medium" color="primary" aria-label="Save"
                                                className={classes.acitonStyle}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </>}
                                    </div>
                                </TableCell>
                            </TableRow>)
                    })}
                </TableBody>
            </Table>
        </>
    )
}

export default hot(withRouter(connect(null, null)(MultiLine)));