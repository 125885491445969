import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { Table, TableContainer, TableCell, TableRow, TableHead, TableBody, Paper, Select, MenuItem, FormControl, makeStyles, Button, TablePagination } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
import { loadEntities } from 'store/actions'
import Grid from '@material-ui/core/Grid';
import { getEntity } from '../../../store/actions/app'

// import 'bootstrap/dist/css/bootstrap.min.css'

const useStyles = makeStyles((theme) => ({
    table: {
        fontSize: "1.2em!important"
    },
    checkbox: {
        width: "20px",
        height: "20px"
    },
    link: {
        padding: "5px",
        color: "#2b2b2b"
    },
    element: {
        backgroundColor: "#242323 !important",
        color: "white !important",
        textAlign: "left"
    },
    head_element: {
        backgroundColor: "#242323 !important",
        color: "white !important",
        textAlign: "left"
    },
    bread_crumb: {
        padding: "8px 15px",
        marginBottom: "0px",
        listStyle: "none",
        backgroundColor: "#222222",
        borderRadius: "4px",
        display: "flex"
    },
    anchor_tag: {
        color: "#FFF"
    }
}));

const MyWorkSpace = props => {
    const [state, setState] = useState({ tableNum: 0, month: '', client: '', status: '' });
    const [docArray, setDocArray] = useState([]);
    const [paymentArray, setPaymentArray] = useState([]);
    const [auditArray, setAuditArray] = useState([]);
    const classes = useStyles();
    const [monthsList, setMonthsList] = React.useState([]);
    const [monthData, setMonthData] = useState({ value: moment().format("MM/YYYY"), name: moment().format("MMMM-YYYY") });
    const [pageCount, setPageCount] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    const [paymentPageCount, setPaymentPageCount] = React.useState(0);
    const [paymentRowsPerPage, setPaymentRowsPerPage] = React.useState(10);
    const [paymentPage, setPaymentPage] = React.useState(0);
    const [employerCase, setEmployerCase] = useState({});

    const getMyWorkspaceData = (collectionName, rowsPerPage, setResponse) => {
        let conditions = [{
            "selectedConditionDataElement": "createdBy",
            "selectedConditionOperator": "matches",
            "value": props.user.id,
            "selectedConditionClause": "and"
        }];
        // const uri = API_BASE_URL + "/dataconnect/data/" + collectionName + "?type=list&&fields=id,caseId,controlId,clientName,depositName,receivedDate,amount,status,checkId,isPaymentReceived,source" + "&page=0&rowsPerPage=" + (pageCount ? pageCount : 10);

        const uri = API_BASE_URL + "/dataconnect/data/" + collectionName
            + "?" + (conditions ? "conditions=" + JSON.stringify(conditions) + "&" : '')
            + `page=${paymentPage}&rowsPerPage=` + (paymentRowsPerPage ? paymentRowsPerPage : 10)

        axios({
            url: uri,
            method: 'GET',
            headers: {
                'Content-Type': "application/json",
                appId: props.match.params.appid
                // appId: "5f2ab15279813563bcaa3887"
            },
        }).then((response) => {
            console.log("data", response)
            setResponse(response.data.data);
        }).catch((error) => {
            setResponse({ "error": error });
            // console.log("error", error)
        })
    }

    const getData = (collectionName, pageCount, conditions, setResponse) => {

        const uri = API_BASE_URL + "/dataconnect/data/" + collectionName
            + "?" + (conditions ? "conditions=" + JSON.stringify(conditions) + "&" : '')
            + "page=0&rowsPerPage=" + (pageCount ? pageCount : 10)

        axios({
            url: uri,
            method: 'GET',
            headers: {
                'Content-Type': "application/json",
                appId: props.match.params.appid
                // appId: "5f2ab15279813563bcaa3887"
            },
        }).then((response) => {
            console.log("data", response)
            setResponse(response.data.data);


        }).catch((error) => {
            setResponse({ "error": error });
            // console.log("error", error)
        })
    }

    const getCaseData = () => {
        console.log('getCaseData');
        let conditions = [{
            "selectedConditionDataElement": "EmployerName",
            "selectedConditionOperator": "matches",
            "value": props.entity.name,
            "selectedConditionClause": "and"
        }];

        getData('Case', 1, conditions, (res) => {
            console.log('res == =>', res)
            if (res && Array.isArray(res) && res[0]) {
                let c = res[0]
                setEmployerCase(c)
            } else {
                // alert("You don't have any existing plans, Please contact your case processer.")
            }
        })
    }

    useEffect(() => {
        props.loadEntities(props.match.params.appid, props.user.orgId);
        getMyWorkspaceData("AuditReconciliation", 10, (res) => {
            console.log("res", res)
            setAuditArray(res)
        });
    }, [])

    useEffect(() => {
        getCaseData();
    }, [props.entity])

    const MONTHS = () => {
        const months = []
        const dateEnd = moment()
        const dateStart = moment().subtract(6, 'month')

        while (dateEnd.diff(dateStart, 'months') >= 0) {
            months.push({ text: dateStart.format('MMMM-YYYY'), value: dateStart.format('MM/YYYY') })
            dateStart.add(1, 'month')
        }
        return months.reverse()
    }

    useEffect(() => {
        setMonthsList(MONTHS())
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handlePaymentChangePage = (event, newPage) => {
        setPaymentPage(newPage);
    };

    const handlePaymentChangeRowsPerPage = (event) => {
        setPaymentRowsPerPage(parseInt(event.target.value, 10));
        setPaymentPage(0);
    };


    const getSupportingDocs = (setResponse) => {
        let conditions = [{
            "id": "230503f3",
            "selectedConditionDataElement": "BillingPeriod",
            "selectedConditionOperator": "matches",
            "value": monthData && monthData.value ? monthData.value : moment().format("MM/YYYY"),
            "selectedConditionClause": "and"
        }];
        const uri = API_BASE_URL + '/custom/supportingDocument/all/' + props.member.entityId + `?page=${page}&rowsPerPage=${rowsPerPage}` + "&conditions=" + JSON.stringify(conditions);

        // const uri = API_BASE_URL + "/dataconnect/table/" + props.page.pageLevelDataModel + "?type=list&&fields=id,BillingPeriod,period,administrator,billType,billMethod,reviewer,status,deatilsLink,reportLink,verifyLink&page=0&rowsPerPage=10" + "conditions=" + JSON.stringify(conditions);

        axios({
            url: uri,
            method: 'GET',
            headers: {
                'Content-Type': "application/json",
                appId: props.match.params.appid
                // appId: "5f2ab15279813563bcaa3887"
            },
        }).then((response) => {
            console.log("data", response)
            setResponse(response.data.data);
            setPageCount(response.data.count)

        }).catch((error) => {
            setResponse({ "error": error });
            // console.log("error", error)
        })
    }

    useEffect(() => {
        if (monthsList && monthsList.length) {
            getSupportingDocs((res) => {
                console.log("res", res)
                setDocArray(res)
            });
        }
    }, [monthsList, page, rowsPerPage])

    useEffect(() => {
        if (monthsList && monthsList.length) {
            getMyWorkspaceData("Payment", 10, (res) => {
                console.log("res", res)
                setPaymentArray(res)
            });
        }
    }, [monthsList, paymentPage, paymentRowsPerPage])

    return (
        <div className="main app-myworkspace ibc">
            <ul className="breadcrumb" className={classes.bread_crumb}>
                <li><Link to={`/apps/${props.match.params.appid}/ibcDashboard`} style={{ color: "#FFF", textDecoration: "none" }}>Dashboard &nbsp;/</Link></li>
                <li style={{ color: "#888888" }}>&nbsp;Cases&nbsp;</li>
            </ul>
            <div className="row" style={{ padding: "10px", display: "flex" }}>
                <div className="col-lg-2" style={{ marginRight: "10px" }}>
                    <FormControl variant="outlined">
                        <span style={{ padding: "5px", fontSize: "1.2em", color: "#fff" }}>Select Month:</span>
                        {monthsList && monthsList.length && <Select id="custom-select-bg" style={{ backgroundColor: "#404040", width: "300px" }} onChange={(event) => setMonthData(event.target)}>
                            <MenuItem key={0} value={""}>{"Select"}</MenuItem>
                            {monthsList && monthsList.length && monthsList.map((month, i) => <MenuItem key={i} name={month.text} value={month.value}>{month.text}</MenuItem>)}
                        </Select>}
                    </FormControl>
                </div>

                {/* <div className="col-lg-2" style={{ marginRight: "10px" }}>
                    <FormControl variant="outlined">
                        <span style={{ padding: "5px", fontSize: "1.2em", color: "#fff" }}>Select Client:</span>
                        <Select id="custom-select-bg" style={{ backgroundColor: "#404040", width: "300px" }} onChange={(e) => setState({ ...state, client: e.target.value })}>
                            <MenuItem key={0} value={""}>{"Select"}</MenuItem>
                            {props.entities && props.entities.entities.map((item, i) => {
                                return <MenuItem key={i + 1} value={item.name}>{item.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </div>

                <div className="col-lg-2">
                    <FormControl variant="outlined">
                        <span style={{ padding: "5px", fontSize: "1.2em", color: "#fff" }}>Select Status:</span>
                        <Select id="custom-select-bg" style={{ backgroundColor: "#404040", width: "300px" }} onChange={(e) => setState({ ...state, status: e.target.value })}>
                            <MenuItem key={0} value={""}>{"Select"}</MenuItem>
                            <MenuItem key={1} value={"SupportReceived"}>Support Received</MenuItem>
                            <MenuItem key={2} value={"PaymentLinked"}>Payment Linked</MenuItem>
                            <MenuItem key={3} value={"PendingProcessing"}>Pending Processing</MenuItem>
                            <MenuItem key={4} value={"Audit"}>Audit</MenuItem>
                        </Select>
                    </FormControl>
                </div> */}
            </div>
            <div className="m-top-40">
                <div className="text-color-white blackBg ui-sub-tabs" style={{ width: "100vw", display: "flex" }}>
                    <span className="text-color-white content-padding" style={{ backgroundColor: state.tableNum === 0 && "#2a9fd6", cursor: "pointer" }} onClick={() => setState({ ...state, tableNum: 0 })}>Summary Invoice</span>
                    <span className="text-color-white content-padding" style={{ backgroundColor: state.tableNum === 1 && "#2a9fd6", cursor: "pointer" }} onClick={() => setState({ ...state, tableNum: 1 })}>Payments</span>
                    <span className="text-color-white content-padding" style={{ backgroundColor: state.tableNum === 2 && "#2a9fd6", cursor: "pointer" }} onClick={() => setState({ ...state, tableNum: 2 })}>Audit - Reconciliation</span>
                </div>
            </div>
            {
                state.tableNum === 0 &&
                <div className="row" id="cases">
                    <div className="col-lg-12">
                        <div className="panel panel-default">
                            <div className="panel-heading text-color-white">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <h4 style={{ fontSize: "24px" }}>Billing Operations Team Work Queue</h4>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="text-right m-top-40 m-bottom-40">
                                        <button className="btn btn-md btn-primary btn-color m-right-20 text-color-white" href="spotrecon">Perform Audit</button>
                                        <button className="btn btn-md btn-primary btn-color m-right-20 text-color-white" id="btnCheckPayment">Check Payments</button>
                                    </Grid>
                                </Grid>
                            </div>
                            <TableContainer component={Paper}>
                                <Table style={{ backgroundColor: "#eee" }}>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "#242323" }}>
                                            {/* <TableCell className={classes.head_element}><input className={classes.checkbox} type="checkbox" /></TableCell> */}
                                            <TableCell className={classes.head_element}>Employer Name</TableCell>
                                            <TableCell className={classes.head_element}>Billing Period</TableCell>
                                            <TableCell className={classes.head_element}>Control #</TableCell>
                                            <TableCell className={classes.head_element}>Policy #</TableCell>
                                            <TableCell className={classes.head_element}>Received Date</TableCell>
                                            <TableCell className={classes.head_element}>Total Amount</TableCell>
                                            <TableCell className={classes.head_element}>Payment Received</TableCell>
                                            <TableCell className={classes.head_element}>Check #</TableCell>
                                            <TableCell className={classes.head_element}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {docArray && docArray.map((item) => {
                                            return <TableRow>
                                                {/* <TableCell className={classes.element}><input className={classes.checkbox} type="checkbox" /></TableCell> */}
                                                <TableCell className={classes.element}>{item.EmployerName}</TableCell>
                                                <TableCell className={classes.element}>{moment(item.BillingPeriod).format('MMM YYYY')}</TableCell>

                                                <TableCell className={classes.element}>{employerCase.Control}</TableCell>
                                                <TableCell className={classes.element}>{employerCase?.ControlNumber}</TableCell>

                                                <TableCell className={classes.element}>{moment(item.createdDateTime).format('MM/DD/YYYY')}</TableCell>
                                                <TableCell className={classes.element}>{item.total}</TableCell>
                                                <TableCell className={classes.element}>{item.PaymentStatus ? item.PaymentStatus === "Paid" ? "Yes" : "No" : "No"}</TableCell>
                                                <TableCell className={classes.element}>{""}</TableCell>
                                                <TableCell className={classes.element}>
                                                    <span className={classes.link}>
                                                        <Link to={`/apps/${props.match.params.appid}/ibcSupportingDocumentView/${item._id}`}
                                                            className={classes.anchor_tag}>Report</Link>
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                                <div className={classes.pagination}>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 15, 20, 50]}
                                        component="div"
                                        count={pageCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            }
            {
                state.tableNum === 1 &&
                <div className="row" id="payments">
                    <div className="col-lg-12">
                        <div className="panel panel-default">
                            <div className="panel-heading text-color-white">
                                <Grid container spacing={3}>
                                    <Grid item xl={12} sm={6}>
                                        <h4 style={{ fontSize: "24px" }}>Account Manager Payment Queue</h4>
                                    </Grid>
                                    <Grid item xl={12} sm={6} className="text-right m-top-40 m-bottom-40">
                                        <button className="btn btn-md btn-primary btn-color m-right-20" href="supportingdocument">Map to Support</button>
                                    </Grid>
                                </Grid>


                            </div>
                            <TableContainer component={Paper}>
                                <Table style={{ backgroundColor: "#eee" }}>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "#242323" }}>
                                            <TableCell className={classes.head_element}><input className={classes.checkbox} type="checkbox" /></TableCell>
                                            <TableCell className={classes.head_element}>Billing Period</TableCell>
                                            <TableCell className={classes.head_element}>Invoice Number</TableCell>
                                            <TableCell className={classes.head_element}>Invoice Date</TableCell>
                                            <TableCell className={classes.head_element}>Plan Administrator</TableCell>
                                            <TableCell className={classes.head_element}>Case Processor</TableCell>
                                            <TableCell className={classes.head_element}>Toatal Premium Amount Paid</TableCell>
                                            <TableCell className={classes.head_element}>Amount Due</TableCell>
                                            <TableCell className={classes.head_element}>Total Premium Amount Billed</TableCell>
                                            <TableCell className={classes.head_element}>Paymet Status</TableCell>
                                            <TableCell className={classes.head_element}>Payment Date</TableCell>
                                            <TableCell className={classes.head_element}>Bank Account</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paymentArray && paymentArray.map((item) => {
                                            return <TableRow>
                                                <TableCell className={classes.element}><input className={classes.checkbox} type="checkbox" /></TableCell>
                                                <TableCell className={classes.element}>{item.BillingPeriod}</TableCell>
                                                <TableCell className={classes.element}>{item.InvoiceNumber}</TableCell>
                                                <TableCell className={classes.element}>{item.InvoiceDate}</TableCell>
                                                <TableCell className={classes.element}>{item.PlanAdministrator}</TableCell>
                                                <TableCell className={classes.element}>{item.CaseProcessor}</TableCell>
                                                <TableCell className={classes.element}>{item.TotalPremiumAmountPaid}</TableCell>
                                                <TableCell className={classes.element}>{item.AmountDue}</TableCell>
                                                <TableCell className={classes.element}>{item.TotalPremiumAmountBilled}</TableCell>
                                                <TableCell className={classes.element}>{item.PaymentStatus}</TableCell>
                                                <TableCell className={classes.element}>{item.PaymentDate}</TableCell>
                                                <TableCell className={classes.element}>{item.BankAccount}</TableCell>
                                            </TableRow>
                                        })
                                        }
                                    </TableBody>
                                </Table>
                                <div className={classes.pagination}>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 15, 20, 50]}
                                        component="div"
                                        count={paymentPageCount}
                                        rowsPerPage={paymentRowsPerPage}
                                        page={paymentPage}
                                        onChangePage={handlePaymentChangePage}
                                        onChangeRowsPerPage={handlePaymentChangeRowsPerPage}
                                    />
                                </div>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            }
            {
                state.tableNum === 2 &&
                <div className="row" id="audit">
                    <div className="col-lg-12">
                        <div className="panel panel-default">
                            <div className="panel-heading text-color-white">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <h4 style={{ fontSize: "24px" }}>Audit</h4>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="text-right m-top-40 m-bottom-40">
                                        <button className="btn btn-md btn-primary btn-color m-right-20" href="spotrecon">Perform Audit</button>
                                    </Grid>
                                </Grid>
                            </div>
                            <TableContainer component={Paper}>
                                <Table style={{ backgroundColor: "#eee" }}>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "#242323" }}>
                                            <TableCell className={classes.head_element}><input className={classes.checkbox} type="checkbox" /></TableCell>
                                            <TableCell className={classes.head_element}>Case #</TableCell>
                                            <TableCell className={classes.head_element}>Control #</TableCell>
                                            <TableCell className={classes.head_element}>Client Name</TableCell>
                                            <TableCell className={classes.head_element}>Deposit Date</TableCell>
                                            <TableCell className={classes.head_element}>Received Date</TableCell>
                                            <TableCell className={classes.head_element}>Amount</TableCell>
                                            <TableCell className={classes.head_element}>Value Leakage</TableCell>
                                            <TableCell className={classes.head_element}>Bill type</TableCell>
                                            <TableCell className={classes.head_element}>No: of issues</TableCell>
                                            <TableCell className={classes.head_element}>Status</TableCell>
                                            <TableCell className={classes.head_element}>Details</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {auditArray && auditArray.map((item) => {
                                            let date = moment(item.DepositDate).format("MMM");
                                            console.log("date", date, (state.month === "" || date.toString() === state.month.toString()))
                                            let isFilter = false;
                                            if ((state.client === "" || item.ClientName.replace(" ", "").includes(state.client.replace(" ", ""))) && (state.status === "" || item.Status.replace(" ", "").includes(state.status)) && (state.month === "" || date.toString() === state.month.toString())) {
                                                isFilter = true;
                                            }
                                            if (isFilter) {
                                                return <TableRow>
                                                    <TableCell className={classes.element}><input className={classes.checkbox} type="checkbox" /></TableCell>
                                                    <TableCell className={classes.element}>{item.CaseId}</TableCell>
                                                    <TableCell className={classes.element}>{item.ControlId}</TableCell>
                                                    <TableCell className={classes.element}>{item.ClientName}</TableCell>
                                                    <TableCell className={classes.element}>{item.DepositDate}</TableCell>
                                                    <TableCell className={classes.element}>{item.ReceivedDate}</TableCell>
                                                    <TableCell className={classes.element}>{item.Amount}</TableCell>
                                                    <TableCell className={classes.element}>{item.ValueLeakage}</TableCell>
                                                    <TableCell className={classes.element}>{item.BillType}</TableCell>
                                                    <TableCell className={classes.element}>{item.NoOfIssues}</TableCell>
                                                    <TableCell className={classes.element}>{item.Status}</TableCell>
                                                    <TableCell className={classes.element}><a href={item.Details}>View</a></TableCell>
                                                </TableRow>
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        app: state.appConfig.app,
        member: state.appConfig.member,
        entities: state.entities,
        entity: state.appConfig.entity ? state.appConfig.entity : {}
    };
};

const mapDispatchToProps = {
    loadEntities,
    getEntity
};


export default hot(withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(MyWorkSpace)),
);