import { put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import { ConversionFundsService } from '../../../modules/api/custom/spendplan/conversionFunds';


async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* fetchConversionFundsData(action) {
    try {
        const tableData = yield fetchAsync(ConversionFundsService.getConversionFunds, action.payload);
      yield put({ type: ActionTypes.GET_CONVERSION_FUND_SUCCESS, payload: tableData });
    }
    catch (err) {
        yield put({ type: ActionTypes.GET_CONVERSION_FUND_FAILURE, error: err.message });
    }
}

function* fetchConversionFundsExtraData(action) {
    try {
        const tableData = yield fetchAsync(ConversionFundsService.getConversionFundsExtraData, action.payload);
        yield put({ type: ActionTypes.GET_CONVERSION_FUND_EXTRA_DATA_SUCCESS, payload: tableData.result.data });
    }
    catch (err) {
        yield put({ type: ActionTypes.GET_CONVERSION_FUND_EXTRA_DATA_FAILURE, extraDataError: err.message });
    }
}

function* addNewConversionFunds(action) {
    try {
        const response = yield fetchAsync(ConversionFundsService.addNewConversionFunds, action.payload)
        yield put({ type: ActionTypes.ADD_NEW_CONVERSION_FUND_SUCCESS, payload: response })
    } catch (err) {
        let errorMsg, error;
        if (err.response.error) {
            error = err.response.error; 
        } else {
            errorMsg = err.message != 'Bad Request' ? err.message : 'Please enter correct Data';
        }
        yield put({ type: ActionTypes.ADD_NEW_CONVERSION_FUND_FAILURE, error: errorMsg?errorMsg:error });
    }
}

function* deleteCheckedConversionFundss(action) {
    try {
        const response = yield fetchAsync(ConversionFundsService.deleteCheckedConversionFunds, action.payload)
        yield put({ type: ActionTypes.DELETE_CHECKED_CONVERSION_FUND_SUCCESS, payload: response })
    }
    catch (err) {
        let errorMsg = err.message !== 'Bad Request' ? err.message : 'Please enter correct Data';
        yield put({ type: ActionTypes.DELETE_CHECKED_CONVERSION_FUND_FAILURE, error: errorMsg });
    }
}

function* fetchPlans(action) {
    try {
        const response = yield fetchAsync(ConversionFundsService.getPlans, action.payload)
        yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_CONVERSION_FUNDS_SUCCESS, payload: response?.result?.data ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'check request Failed';
        yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_CONVERSION_FUNDS_FAILURE, error: errorMsg });
    }
}

export function* consumerPromotion() {
    yield takeLatest(ActionTypes.GET_CONVERSION_FUND_DATA, fetchConversionFundsData);
    yield takeLatest(ActionTypes.ADD_NEW_CONVERSION_FUND, addNewConversionFunds);
    yield takeLatest(ActionTypes.DELETE_CHECKED_CONVERSION_FUND, deleteCheckedConversionFundss);
    yield takeLatest(ActionTypes.GET_CONVERSION_FUND_EXTRA_DATA, fetchConversionFundsExtraData);
    yield takeLatest(ActionTypes.GET_PLAN_FILTER_DATA_CONVERSION_FUNDS, fetchPlans);

}

export default consumerPromotion;
