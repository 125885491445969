const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { request } from '../../../client';

export class ImarReportsService {

  static getImar1ReportData(data) {
    let uri = API_BASE_URL + '/imar' + data.url;
    return request(uri, {
      method: 'GET',
      headers: {
        appId: data.appId,
      },
    });
  }

  static getImar1Details(data) {
    let uri = API_BASE_URL + '/imar/data'+ data.params;
    return request(uri, {
      method: 'GET',
      headers: {
        appId: data.appId,
      },
    });
  }

  static saveImar1Details(data) {
    let uri = API_BASE_URL + '/imar';
    return request(uri, {
      method: 'POST',
      headers: {
        appId: data.appId,
      },
      payload: data.payload
    });
  }

  static getImar2ReportData(data) {
    let uri = API_BASE_URL + '/imar/two' + data.url;
    return request(uri, {
      method: 'GET',
      headers: {
        appId: data.appId,
      },
    });
  }

  static getOUMCheckListData(data) {
    let uri = API_BASE_URL + '/mrspendplan/omni/checklist/634917cdc2ae80002b8f8481';
    return request(uri, {
      method: 'POST',
      headers: {
        appId: data.appId,
      },
      payload: data.params
    });
  }

  static saveUOMCheckListData(data) {
    let uri = API_BASE_URL + '/mrspendplan/omni/634917cdc2ae80002b8f8481';
    return request(uri, {
      method: 'POST',
      headers: {
        appId: data.appId,
      },
      payload: data.params
    });
  }
}
