import { hot } from 'react-hot-loader/root'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SpendPlan from '../SpendPlan'
import {
	Grid, Paper, FormControl, Select, MenuItem, makeStyles
} from '@material-ui/core'
import Filter from './Filter'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const Header = props => {

	return <Grid container>
		<Grid item xs={6} sm={6} className="setup-data-headings p-top-10 p-bottom-15">
			{props.header ? props.header : "MS&P Dashboard"}
		</Grid>
		<Grid item xs={6} sm={6}>
			<Filter />
		</Grid>
	</Grid>
}

const mapDispatchToProps = {
}

const mapStateToProps = ({ user, navigation }) => {
	return {}
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)))
