import React from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom'
import MrSheetTableWrapper from '../../mrplan/Sheettable/sheetTableApiService'

const PYSKULevel = props => {
    return <MrSheetTableWrapper {...props} />
}

export default hot(withRouter(PYSKULevel))
