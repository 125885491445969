import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import {
  makeStyles,
  Button,
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import TalkSheet from './index';
import Header from '../../SpendPlan/Header';
import { getFiscalYearFormat } from "../../spendplanUtils";

const useStyles = makeStyles(theme => ({
  confirmationDialog: {
    '& .MuiDialog-paperWidthSm': {
        height: '85vh',
        maxWidth: '100%',
        width: '90%'
    },
    '& .setup-data-headings': {
        fontSize: '25px !important',
        fontWeight: 'bold',
        lineHeight: '1',
        color: '#000'
    }
}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TalkSheetModal = props => {
  const { open, handleClose } = props;
  const modalData = props.modalData ? props.modalData : '';

  const classes = useStyles();
  const { tprReducer } = props;
  const { selectBrand } = tprReducer;
  const [brand, setBrand] = useState(selectBrand);

  useEffect(() => {
    if (
      selectBrand &&
      typeof selectBrand === 'object' &&
      selectBrand !== null &&
      Object.keys(selectBrand).length > 0
    ) {
      setBrand(selectBrand);
    }
  }, [selectBrand]);

  let versionTitle = `Talk Sheet - Version - ${modalData.monthName} ${getFiscalYearFormat(modalData.year)}`;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      disableBackdropClick={true}
      // disableEscapeKeyDown={true}
      aria-labelledby="Talk Sheet"
      aria-describedby="Talk Sheet Table"
      className={classes.confirmationDialog}
    >
      <DialogContent>
        <TalkSheet versionTitle={versionTitle} brandData={brand} modalData={modalData}/>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
          className="btn btn-primary btn-color border-radius-0 text-color-white"
        >
          {'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    tprReducer: state.tprReducer,
  };
};

const mapDispatchToProps = {};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(TalkSheetModal)));
