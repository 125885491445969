import React, { Component } from 'react';
import {
    TextField,
    FormGroup,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Select,
    FormControl,
    MenuItem,
    Chip
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import _ from 'modules/lodash';
import withReducer from 'store/withReducer';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Rule = (props) => {
    console.log("props", props);
    let { form, classes, values, selectedComparatorList,
        actionTypesList, dataTypeStringList, dataTypeNumList, pIndex } = props;

    return (
        <div className={classes.tab}>
            <FormGroup row className="items-center">
                <FormLabel component="legend">
                    Conditions
                </FormLabel>
            </FormGroup>

            {values.conditionsUIList.length > 0
                && values.conditionsUIList.map((item, index) => {
                    return <FormGroup row className="items-center  max-w-full" key={index}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                value={values.conditionsUIList[index].dataModelLeftSelected}
                                onChange={(data) => props.handleDataModelLeftChange(data, pIndex, index)}>
                                <MenuItem value="">
                                    <em>Select Data Model</em>
                                </MenuItem>
                                {props.dataModels.data && props.dataModels.data.map((item, i) => {
                                    return <MenuItem key={i} value={item._id} >{item.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                value={values.conditionsUIList[index].dataElementLeftSelected}
                                onChange={(data) => props.handleElementsLeftChange(data, pIndex, index)}>
                                <MenuItem value="">
                                    <em>Select Data Element</em>
                                </MenuItem>
                                {
                                    values.conditionsUIList[index].dataElementLeftList.map((element, i) => {
                                        return <MenuItem key={i} value={element.name}>{element.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                value={values.conditionsUIList[index].operatorSelected}
                                onChange={(data) => props.handleOperatorChange(data, pIndex, index)}>
                                <MenuItem value="">
                                    <em>Select Operator</em>
                                </MenuItem>
                                {values.conditionsUIList[index].dataElementLeftSelected !== "" &&
                                    (values.conditionsUIList[index].dataElementType === "Integer"
                                        ? dataTypeNumList
                                        : dataTypeStringList).map((element, i) => {
                                            return <MenuItem key={i} value={element.value}>{element.name}</MenuItem>
                                        })}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                value={values.conditionsUIList[index].selectedComparator}
                                onChange={(data) => props.handleCompartorChange(data, pIndex, index)}>
                                <MenuItem value="">
                                    <em>Select Comparator</em>
                                </MenuItem>
                                {
                                    (selectedComparatorList).map((element, i) => {
                                        return <MenuItem key={i} value={element.value}>{element.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                        {values.conditionsUIList[index].selectedComparator === 'datamodel'
                            && (<div>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        value={values.conditionsUIList[index].dataModelRightSelected}
                                        onChange={(data) => props.handleDataModelRightChange(data, pIndex, index)}>
                                        <MenuItem value="">
                                            <em>Select Data Model</em>
                                        </MenuItem>
                                        {props.dataModels.data && props.dataModels.data.map((item, i) => {
                                            return <MenuItem key={i} value={item._id} >{item.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        value={values.conditionsUIList[index].dataElementRightSelected}
                                        onChange={(data) => props.handleElementsRightChange(data, pIndex, index)}>
                                        <MenuItem value="">
                                            <em>Select Data Element</em>
                                        </MenuItem>
                                        {
                                            values.conditionsUIList[index].dataElementRightList.map((element, i) => {
                                                return <MenuItem key={i} value={element.name}>{element.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            )}


                        {values.conditionsUIList[index].selectedComparator === 'inputField' && (
                            <TextField
                                label="Value"
                                autoFocus
                                id="inputField"
                                name="inputField"
                                value={values.conditionsUIList[index].inputField}
                                onChange={(data) => props.handleInpuFieldChange(data, pIndex, index)}
                                variant="outlined"
                            />
                        )}

                        <RemoveCircleOutlineIcon onClick={() => props.removeConditions(pIndex, index)} />
                        {index === 0 && <AddCircleOutlineIcon onClick={() => props.addConditions(pIndex)} />}
                    </FormGroup>;
                })}

            <FormGroup row className="items-center">
                <FormLabel component="legend">
                    Actions
                </FormLabel>
            </FormGroup>
            {values.actionsUIList.length > 0 && values.actionsUIList.map((item, index) => {
                return <FormGroup row className="items-center  max-w-full" key={index}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-filled"
                            value={values.actionsUIList[index].actionTypeSelected}
                            onChange={(data) => props.handleActionTypeChange(data, pIndex, index)}>
                            <MenuItem value="">
                                <em>Action Type</em>
                            </MenuItem>
                            {actionTypesList.map((element, i) => {
                                return <MenuItem key={i} value={element.value}>{element.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    {(values.actionsUIList[index].actionTypeSelected == "set" ||
                        values.actionsUIList[index].actionTypeSelected == "get" ||
                        values.actionsUIList[index].actionTypeSelected == "remove" ||
                        values.actionsUIList[index].actionTypeSelected == "create") &&
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                id="demo-simple-select-filled"
                                value={values.actionsUIList[index].dataModelSelected}
                                onChange={(data) => props.handleDataModelActionChange(data, pIndex, index)}>
                                <MenuItem value="">
                                    <em>Select Data Model</em>
                                </MenuItem>
                                {props.dataModels.data && props.dataModels.data.map((item, i) => {
                                    return <MenuItem key={i} value={item._id}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>}

                    {values.actionsUIList[index].actionTypeSelected == "set" &&
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                id="demo-simple-select-filled"
                                value={values.actionsUIList[index].dataElementSelected}
                                onChange={(data) => props.handleElementsActionChange(data, pIndex, index)}>
                                <MenuItem value="">
                                    <em>Select Data Element</em>
                                </MenuItem>
                                {
                                    values.actionsUIList[index].dataElementList.map((element, i) => {
                                        return <MenuItem key={i} value={element.name}>{element.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>}

                    {values.actionsUIList[index].actionTypeSelected == "set" &&
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                value={values.actionsUIList[index].selectedComparator}
                                onChange={(data) => props.handleActionCompartorChange(data, pIndex, index)}>
                                <MenuItem value="">
                                    <em>Select Comparator</em>
                                </MenuItem>
                                {
                                    (selectedComparatorList).map((element, i) => {
                                        return <MenuItem key={i} value={element.value}>{element.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>}
                    {(values.actionsUIList[index].actionTypeSelected == "get" ||
                        values.actionsUIList[index].actionTypeSelected == "remove") &&
                        <Select
                            multiple
                            value={values.actionsUIList[index].dataElementMultiSelected}
                            MenuProps={MenuProps}
                            onChange={(data) => props.handleMultipleDataChange(data, pIndex, index)}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}>
                            <MenuItem value="">
                                <em>Select Data Element</em>
                            </MenuItem>
                            {
                                values.actionsUIList[index].dataElementMultiList.map((element, i) => {
                                    return <MenuItem key={i} value={element.value}>{element.label}</MenuItem>
                                })}
                        </Select>
                    }
                    {values.actionsUIList[index].actionTypeSelected == "set" && values.actionsUIList[index].selectedComparator === 'datamodel'
                        && (<div>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    value={values.actionsUIList[index].dataModelActionRightSelected}
                                    onChange={(data) => props.handleDataModelActionRightChange(data, pIndex, index)}>
                                    <MenuItem value="">
                                        <em>Select Data Model</em>
                                    </MenuItem>
                                    {props.dataModels.data && props.dataModels.data.map((item, i) => {
                                        return <MenuItem key={i} value={item._id} >{item.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    value={values.actionsUIList[index].dataElementActionRightSelected}
                                    onChange={(data) => props.handleElementsActionRightChange(data, pIndex, index)}>
                                    <MenuItem value="">
                                        <em>Select Data Element</em>
                                    </MenuItem>
                                    {
                                        values.actionsUIList[index].dataElementActionRightList.map((element, i) => {
                                            return <MenuItem key={i} value={element.name}>{element.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        )
                    }{values.actionsUIList[index].actionTypeSelected == "set" && values.actionsUIList[index].selectedComparator === 'inputField'
                        && <TextField
                            label="Value"
                            autoFocus
                            id="setValue"
                            name="name"
                            value={values.actionsUIList[index].setValue}
                            onChange={(data) => props.handleSetValueChange(data, pIndex, index)}
                            variant="outlined"
                        />
                    }

                    {values.actionsUIList[index].actionTypeSelected == "create" &&
                        <TextField
                            label="Data Value"
                            autoFocus
                            id="dataValue"
                            name="dataValue"
                            value={values.actionsUIList[index].dataValue}
                            onChange={(data) => props.handleDataValueChange(data, pIndex, index)}
                            variant="outlined"
                        />
                    }
                    <RemoveCircleOutlineIcon onClick={() => props.removeActions(pIndex, index)} />
                    {index === 0 && <AddCircleOutlineIcon onClick={() => props.addActions(pIndex)} />}
                </FormGroup>
            })}
        </div >
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps(state) {
    let d = { data: [...state.cappsConfig.capps.data, state.appConfig.dataModel] }
    return {
        dataModels: d
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Rule)
)
