import { hot } from 'react-hot-loader/root'
import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
	Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid, Slide,
	Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
	DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'

import Loading from '../../ext-content/images/loading.gif'
import MultiLine from '../MultiLine'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

import axios from 'axios'
import { request } from 'modules/client'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	pagination: {
		'& .MuiTablePagination-root': {
			backgroundColor: "#242323 !important",
			color: "white !important",

		},
		'& .MuiSelect-icon': {
			color: "white !important",
			top: '0px !important',
			right: "-5px !important"

		},
		'& .MuiIconButton-root.Mui-disabled': {
			color: "white !important"
		},
		textField: {
			paddingRight: "10px",
			marginTop: "0px",
			marginBottom: "16px",
			borderRadius: 0
		}

	},
	paymentDialog: {
		height: "60vh",
		width: "800px",
		margin: '0px auto',
		textAlign: 'left'

	},
	confirmationDialoge: {
		'& .MuiDialog-paperWidthSm': {
			height: '416px',
			width: '600px'
		}
	},
	para: {
		fontSize: '28px !important',
		fontWeight: 600,
		color: '#000'
	},
	modalAction: {
		'& button': {
			background: 'rgb(33, 128, 172)',
			color: '#fff'
		}
	}
}));

const MemberListDialog = (props) => {
	const classes = useStyles();
	let { editTable, headers, closeEditTable, setup, runMemberValidation } = props
	const [data, setData] = useState()
	const [refresh, setRefresh] = useState(false)
	const [state, setState] = useState('initial')

	useEffect(() => {
		console.log('props.data === ', props.data, props.headers);
		if (props.data && props.headers) {
			// setData(JSON.parse(JSON.stringify(props.data)))
			setData(props.data.map(d => {
				let obj = { _id: d._id }
				Object.values(props.headers).map(k => {
					obj[k] = d[k]
				})
				return obj
			}))
		}
	}, [props.data, props.headers])

	const memberEdit = (index, row) => {
		console.log("memberEdit");
		let d = [...data]
		d[index] = row
		setData(d)
		setRefresh(!refresh)
	}

	const updateEditedMembers = () => {
		setState('loading')
		console.log("updateEditedMembers, API request", data);

		const uri = API_BASE_URL + "/custom/supportingDocument/update/invalid";
		request(uri, {
			method: 'PUT',
			payload: {
				membersList: data,
				'entityId': props.member.entityId
			},
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log("res", res);
			setState('done');
			closeEditTable()
			runMemberValidation()
			// setMemberList(res)
		}).catch(err => {
			setState('done');
			console.log(err);
		})
	}

	const memberDelete = (index) => {
		console.log('memberDelete');
		let d = [...data]
		d.splice(index, 1)
		setData(d)
	}

	const refreshMembers = () => {
		setState('done');
		setData(JSON.parse(JSON.stringify(props.data)))
		setRefresh(!refresh)
	}
	console.log('data ---...---', data);

	return (<>
		{ data && <Dialog
			open={editTable}
			TransitionComponent={Transition}
			keepMounted
			fullWidth={true}
			maxWidth={'lg'}
			onClose={() => closeEditTable()}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description">
			<DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#000" }}>{"Member Details"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description" style={{ color: '#000' }}>
					{state === 'loading' && <div className="loading" style={{ margin: "auto", width: '100%' }}>
						<h3 style={{ color: '#000' }}>Loading data.....</h3>
						<img src={Loading} />
					</div>}
					{state !== 'loading' &&
						<MultiLine data={data} headers={headers} memberEdit={memberEdit} setup={setup}
							refresh={refresh} memberDelete={memberDelete} />
					}
				</DialogContentText>
			</DialogContent>
			<DialogActions className={classes.modalAction}>
				<Button onClick={() => updateEditedMembers()}>{"Update"}</Button>
				<Button onClick={() => refreshMembers()}>{"Refresh"}</Button>
				<Button onClick={() => closeEditTable()}>{"Close"}</Button>
			</DialogActions>
		</Dialog>}
	</>)
}

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(
	connect(mapStateToProps, null)(MemberListDialog)));