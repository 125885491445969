import { Box, Grid, Paper, Typography } from '@material-ui/core';
import LoginBox from 'components/Login/LoginBox';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import Loading from '../components/Loading/index';

const Login = (props) => {

    if(props.app.loading){
        return <Loading />
    }

    return (
        <div className="login-sec">
            <Grid container>
                <Grid item md={6} lg={8}>
                    {/* <img src={Dsilologosmall} alt="" width="200px" /> */}
                    <Typography className="login-title" variant="h3">
                        Driving accelerated value creation and cost savings at enterprises through
						 <Typography variant="inherit" className="login-title textbold"> Automation, intelligence </Typography> and
						 <Typography variant="inherit" className="login-title textbold"> Trust</Typography>
                    </Typography>
                </Grid>

                <Grid item md={6} lg={4}>
                    <Paper className="login-frm">
                        <Typography component="h1" variant="h5">
                            SIGN IN
							{/* <Typography className="r-align" component="label">
								New User? <Typography component="label">Sign Up</Typography>
							</Typography> */}
                        </Typography>
                        <LoginBox />
                        <Box component="div" my={1} whiteSpace="normal" style={{ marginTop: '20px' }}>
                            {/* <Link className={classes.linkOne} to="/register">Don't have an account?</Link> */}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </div>
    )
}

const mapStateToProps = ({ app }) => {
	return {
		app,
	}
}

export default hot(connect(mapStateToProps,	null)(Login));
