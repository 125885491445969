import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { sortAlphabetically } from '../../../../../utility/utils';
import { months } from '../../../spendplan/PlanningPages/planningHelperFunctions';
import { ReportFilter } from '../../ReportPages/reportFilter';

export const FilterType = {
    Category: 'category',
    Brand: 'brand',
    Brandform: 'brandform',
    SKU: 'sku',
    Month: 'month',
    Type: 'type',
    KBDType: 'kbdType',
    Period: 'period',
    UOM: 'uom',
    INR: 'inr',
    TSSKU: 'tsSku',
    TSBrand: 'tsBrand',
    Metric: 'metric',
    EarningOn: 'earningOn',
    Earningtype: 'earningType',
    KBD: 'kbd'
};

const INRFilterValues = [
    {
        label: 'Sort by Brand',
        value: undefined,
    },
    {
        label: 'Top 50 INR',
        value: 50,
    },
    {
        label: 'Top 100 INR',
        value: 100,
    }, 
    {
        label: 'Top 200 INR',
        value: 200,
    },
]


export const HeaderFilters = (props) => {
    let largeMonths = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    const { brand, brandform, sku, month, kbdType, period, type, uom, inrFilterVal, tsSku, metric, tsBrand, earningOn, earningType, kbd, category } = props?.filterValues;
    const { filterData } = props;
    let newMonth;
    if (month && month?.length) {
        newMonth = JSON.parse(JSON.stringify(month));
        // newMonth = newMonth?.toUpperCase();
        newMonth = newMonth?.map((el) => el?.toUpperCase());
    }
    let Filters = {};
    if (props.appName === 'omnichannel') {
        Filters = {
            'category':
                <ReportFilter width={170} id='categoryFilter' label='--All Categories--' value={category|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.Category, e)} allValues={sortAlphabetically(filterData?.category)} />,
            'brand':
                <ReportFilter width={150} id='brandFilter' label='--All Brands--' value={brand|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.Brand, e)} allValues={sortAlphabetically(filterData?.brand)} />,
            'brandform':
                <ReportFilter width={175} id='brandFormFilter' label='--All Brandforms--' value={brandform|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.Brandform, e)} allValues={sortAlphabetically(filterData?.brandform)} />,
            'sku':
                <ReportFilter width={150} id='skuFilter' label='--All SKUs--' value={sku|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.SKU, e)} allValues={sortAlphabetically(filterData?.sku)} />,
            'tsSku':
                <ReportFilter width={170} id='tsSkuFilter' label='--All TS SKUs--' value={tsSku|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.TSSKU, e)} allValues={sortAlphabetically(filterData?.tsSku)} />,
            'month':
                <ReportFilter width={150} id='monthFilter' label='--All Months--' value={newMonth|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.Month, e)} allValues={sortAlphabetically(largeMonths)} />,
            'kbdType':
                <ReportFilter width={150} id='monthFilter' label='--All KBD Types--' value={kbdType|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.KBDType, e)} allValues={sortAlphabetically(filterData?.kbdType)} />,
            'type':
                <ReportFilter width={150} id='typeFilter' label='--All Types--' value={type|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.Type, e)} allValues={sortAlphabetically(filterData?.type)} />,
            'period':
                <ReportFilter width={150} id='periodFilter' label='--All Periods--' value={period|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.Period, e)} allValues={sortAlphabetically(filterData?.period)} />,
            'tsBrand':
                <ReportFilter width={170} id='tsBrandFilter' label='--All TS Brands--' value={tsBrand|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.TSBrand, e)} allValues={sortAlphabetically(filterData?.tsBrand)} />,
            'metric':
                <ReportFilter width={150} id='metricFilter' label='--All Metrics--' value={metric|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.Metric, e)} allValues={sortAlphabetically(filterData?.metric)} />,
            'uom':
                <ReportFilter width={150} id='uomFilter' label='--All UOMs--' value={uom|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.UOM, e)} allValues={sortAlphabetically(filterData?.uom)} />,
            'inr':
                <ReportFilter width={150} id='inrFilter' label='--All INRs--' value={inrFilterVal|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.INR, e)} allValues={sortAlphabetically(INRFilterValues)} />,
            'earningOn':
                <ReportFilter width={150} id='earningOnFilter' label='--All En. Ons--' value={earningOn|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.EarningOn, e)} allValues={sortAlphabetically(filterData?.earningOn)} />,
            'earningType':
                <ReportFilter width={170} id='earningTypeFilter' label='--All En. Types--' value={earningType|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.Earningtype, e)} allValues={sortAlphabetically(filterData?.earningType)} />,
            'kbd':
                <ReportFilter width={150} id='kbdFilter' label='--All KBDs--' value={kbd|| ''} onChangeFilter={(e) => props.onFilterChange(FilterType?.KBD, e)} allValues={sortAlphabetically(filterData?.kbd)} />,
        }; 
    } else {
        Filters = {
            'category':
                <Select
                    value={category || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    style={{ minWidth: '150px' }}
                    onChange={(e) => props.onFilterChange(FilterType.Category, e.target)}>
                    <MenuItem value="">-- All Categories --</MenuItem>
                    {
                        filterData?.category && filterData?.category?.length && sortAlphabetically(filterData?.category)?.map((item, index) =>
                            <MenuItem value={item} key={index}>{item}</MenuItem>,
                        )
                    }
                </Select>,
            'brand':
                <Select
                    value={brand || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    style={{ minWidth: '150px' }}
                    onChange={(e) => props.onFilterChange(FilterType.Brand, e.target)}>
                    <MenuItem value="">-- All Brands --</MenuItem>
                    {
                        filterData?.brand && filterData?.brand?.length && sortAlphabetically(filterData?.brand)?.map((item, index) =>
                            <MenuItem value={item} key={index}>{item}</MenuItem>,
                        )
                    }
                </Select>,
            'brandform':
                <Select
                    value={brandform || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    style={{ minWidth: '150px' }}
                    onChange={(e) => props.onFilterChange(FilterType.Brandform, e.target)}>
                    <MenuItem value="">-- All Brandforms --</MenuItem>
                    {
                        filterData?.brandform && filterData?.brandform?.length && sortAlphabetically(filterData?.brandform)?.filter(i => i).map((item, index) =>
                            <MenuItem value={item} key={index}>{item}</MenuItem>,
                        )
                    }
    
                </Select>,
            'sku':
                <Select
                    value={sku || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    style={{ minWidth: '150px' }}
                    onChange={(e) => props.onFilterChange(FilterType.SKU, e.target)}>
                    <MenuItem value="">-- All SKUs --</MenuItem>
                    {filterData?.sku && filterData?.sku?.length && sortAlphabetically(filterData?.sku)?.map((item, index) =>
                        <MenuItem key={index} value={item}>{item}</MenuItem>,
                    )},
                </Select>,
            'tsSku':
                <Select
                    value={tsSku || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    style={{ minWidth: '150px' }}
                    onChange={(e) => props.onFilterChange(FilterType.TSSKU, e.target)}>
                    <MenuItem value="">-- All TS SKUs --</MenuItem>
                    {filterData?.tsSku && filterData?.tsSku?.length && sortAlphabetically(filterData?.tsSku)?.map((item, index) =>
                        <MenuItem key={index} value={item}>{item}</MenuItem>,
                    )},
                </Select>,
            'month':
                <Select
                    value={month || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    // style={{ minWidth: '100px' }}
                    onChange={(e) => props.onFilterChange(FilterType.Month, e.target)}>
                    <MenuItem value="">Month</MenuItem>
                    {
                        months.map((m, index) => {
                            return <MenuItem key={index} value={m.toLowerCase()}>{m}</MenuItem>;
                        })
                    }
                </Select>,
            'kbdType':
                <Select
                    value={kbdType || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    onChange={(e) => props.onFilterChange(FilterType.KBDType, e.target)}>
                    <MenuItem value="">KBD Type</MenuItem>
                    {filterData?.kbdType && filterData?.kbdType?.length && sortAlphabetically(filterData?.kbdType)?.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                </Select>,
            'type':
                <Select
                    value={type || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    onChange={(e) => props.onFilterChange(FilterType.Type, e.target)}>
                    <MenuItem value="">-- All Types --</MenuItem>
                    {filterData?.type && filterData?.type?.length && sortAlphabetically(filterData?.type)?.map((item, index) =>
                        <MenuItem key={index} value={item}>{item}</MenuItem>,
                    )}
                </Select>,
            'period':
                <Select
                    value={period}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    onChange={(e) => props.onFilterChange(FilterType.Period, e.target)}>
                    <MenuItem value={undefined}>Period (Qtr)</MenuItem>
                    {filterData?.period && filterData?.period?.length && sortAlphabetically(filterData?.period)?.map((item, index) =>
                        <MenuItem key={index} value={item}>{item}</MenuItem>,
                    )}
                </Select>,
            'tsBrand':
                <Select
                    value={tsBrand || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    onChange={(e) => props.onFilterChange(FilterType.TSBrand, e.target)}>
                    <MenuItem value="">-- All TS Brand --</MenuItem>
                    {filterData?.tsBrand && filterData?.tsBrand?.length && sortAlphabetically(filterData?.tsBrand)?.map((item, index) =>
                        <MenuItem key={index} value={item}>{item}</MenuItem>,
                    )}
                </Select>,
            'metric':
                <Select
                    value={metric || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    onChange={(e) => props.onFilterChange(FilterType.Metric, e.target)}>
                    <MenuItem value="">-- All Metric --</MenuItem>
                    {filterData?.metric && filterData?.metric?.length && sortAlphabetically(filterData?.metric)?.map((item, index) =>
                        <MenuItem key={index} value={item}>{item}</MenuItem>,
                    )}
                </Select>,
            'uom':
                <Select
                    value={uom}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    onChange={(e) => props.onFilterChange(FilterType.UOM, e.target)}>
                    <MenuItem value={undefined}>-- All UOM --</MenuItem>
                    {filterData?.uom && filterData?.uom?.length && sortAlphabetically(filterData?.uom)?.map((item, index) =>
                        <MenuItem key={index} value={item}>{item}</MenuItem>,
                    )}
                </Select>,
            'inr':
                <Select
                    value={inrFilterVal}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    onChange={(e) => props.onFilterChange(FilterType.INR, e.target)}>
                    {INRFilterValues.map((item, index) =>
                        <MenuItem key={item.label} value={item?.value}>{item.label}</MenuItem>,
                    )}
                </Select>,
             'earningOn':
                <Select
                    value={earningOn || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    style={{ minWidth: '150px' }}
                    onChange={(e) => props.onFilterChange(FilterType.EarningOn, e.target)}>
                    <MenuItem value="">-- All Earnings On --</MenuItem>
                    {filterData?.earningOn && filterData?.earningOn?.length && sortAlphabetically(filterData?.earningOn)?.map((item, index) =>
                        <MenuItem key={index} value={item}>{item}</MenuItem>,
                    )},
                </Select>,
             'earningType':
                <Select
                    value={earningType || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    style={{ minWidth: '150px' }}
                    onChange={(e) => props.onFilterChange(FilterType.Earningtype, e.target)}>
                    <MenuItem value="">-- All Earnings Types --</MenuItem>
                    {filterData?.earningType && filterData?.earningType?.length && sortAlphabetically(filterData?.earningType)?.map((item, index) =>
                        <MenuItem key={index} value={item}>{item}</MenuItem>,
                    )},
                </Select>,
            'kbd':
                <Select
                    value={kbd || ''}
                    displayEmpty={true}
                    className="custom-select m-right-10"
                    style={{ minWidth: '150px' }}
                    onChange={(e) => props.onFilterChange(FilterType.KBD, e.target)}>
                    <MenuItem value="">-- All KBDs --</MenuItem>
                    {filterData?.kbd && filterData?.kbd?.length && sortAlphabetically(filterData?.kbd)?.map((item, index) =>
                        <MenuItem key={index} value={item}>{item?.toUpperCase()}</MenuItem>,
                    )},
                </Select>,
        };
    }


    return <>
        {
            (filterData && filterData.length) || (filterData && filterData.brand?.length) ?
            props.filters?.map((i, index) => {
                return <div key={index}>{Filters[i]}</div>
            }) : ''
        }
    </>;
};
