import { listDataModal } from './JsonData';

const data1 = ['list Data Modal', 'Plan Design'];
export const data2 = ['Basic Life', 'AD&D', 'Employee Optional Life'];
const data3 = ['Any', 'under29', '30 to 34', '35 to 39'];
const data4 = ['Total', 'Count', 'Average', 'Value'];
export const data5 = ['Basic Life Volume', 'Basic Life Prem', 'Basic ADD Volume', 'Basic ADD Prem'];

export const dropDownData = {
	SupportingDocumentData: listDataModal[0].dataElements,
	SupportingDocData: listDataModal[1].dataElements,
	EmployeePremiumDat: listDataModal[2].dataElements,
};

export const group1 = [
	{ id: 1, options: listDataModal, type: 'Data Model' },
	{ id: 2, options: [] },
	{ id: 3, options: [] },
	{ id: 4, options: [] },
	{ id: 5, options: [] },
];
export const group2 = [
	{ id: 1, options: listDataModal, type: 'Data Model' },
	{ id: 2, options: [] },
	{ id: 3, options: [] },
	{ id: 4, options: [] },
	{ id: 5, options: [] },
];

export const field1 = [
	{ id: 1, options: data4 },
	{ id: 2, options: listDataModal, type: 'Data Model' },
	{ id: 3, input: { name: '', value: '' } },
	{ id: 4, options: [] },
	{ id: 5, options: [] },
	{ id: 6, options: [] },
];