import React, { Component } from 'react';
import CustomTable from './Table'
import {
    withStyles, Icon, TableBody, TableCell,
    Button, TablePagination, TableRow, Checkbox
} from '@material-ui/core';
import { CSVDownload } from "react-csv";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import TableHead from './TableHead';
import _ from 'modules/lodash';
import history from 'modules/history';
import { AppService } from 'modules/api/index';

import {
    getFilteredArray,
    handleSelectedRow,
    isRowselected,
    requestSort,
    handleTableSelectAllClick,
} from 'modules/page/common';

const csvdata = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const exportArray = [{ key: 'xls', name: "Export as Excel", isVisible: true }, { key: 'csv', name: "Export as csv", isVisible: true }, { key: 'txt', name: "Export as text", isVisible: true }, { key: 'image', name: "Export as image", isVisible: true }];
class TableWrapper extends Component {

    state = {
        order: 'asc',
        orderBy: "",
        selected: [],
        tableData: [],
        filterTableData: [],
        page: 0,
        count: 10,
        rowsPerPage: 10,
        searchText: "",
        filterList: [],
        filterData: [],
        options: {

        }
    };

    componentDidMount() {
        getApiData(state.page, state.rowsPerPage);
        setState({ filterData: props.data && props.data.columns ? props.data.columns.map((col) => props.data.datasets.map(item => item[col.name])) : [] });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page != state.page
            || prevState.rowsPerPage != state.rowsPerPage) {
            getApiData(state.page, state.rowsPerPage);
        }
    }

    handleRequestSort = (event, property) => {
        let obj = requestSort(event, property, state);
        console.log("obj", obj);
        setState(obj);
    };

    handleSelectAllClick = event => {
        setState({ selected: handleTableSelectAllClick(event, state.filterTableData.datasets) });
    };

    handleCheck = (event, id) => {
        console.log('====================================');
        console.log('handleCheck====', event, id);
        console.log('====================================');
        const { selected } = state;
        let newSelected = handleSelectedRow(event, id, selected)
        setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        setState({ page });
    };

    handleChangeRowsPerPage = event => {
        setState({ rowsPerPage: event.target.value, page: 0 });
    };

    getTableHeader = (data) => {
        let tableHeader = [];
        let { chartData } = props;
        if (chartData.selectedDataModel && chartData.selectedDataElement
            && !chartData.loadChildData) {
            chartData.selectedDataElement.map(element => {
                let { label, value } = element;
                if (value != '_id') {
                    let headerItem = {
                        id: label.replace(' ', ''),
                        name: value,
                        label: label,
                        disablePadding: false,
                        numeric: false,
                        sort: true
                    }
                    tableHeader.push(headerItem)
                }
            })
        } else {
            if (data && data.length > 0) {
                for (let key in data[0]) {
                    if (key != '_id') {
                        let headerItem = {
                            id: key.replace(' ', ''),
                            name: key,
                            label: key,
                            disablePadding: false,
                            numeric: false,
                            sort: true
                        }
                        tableHeader.push(headerItem)
                    }
                }
            }
        }
        return tableHeader;
    };

    getApiDataFromDataUrl = (url) => {
        AppService.getApiDataFromDataUrl(url, props.appId)
            .then(res => {
                setDatafromApi(res)
            })
    }

    getApiData = (page, rowsPerPage) => {
        console.log("fired==================================>", "fire");
        setState({ isLoading: true });
        let { chartData } = props;
        let targetURL = '';
        if ((chartData.selectedDataModel && (chartData.selectedDataElement)
            || (chartData.loadChildData && chartData.parentDataElement))) {
            targetURL = API_BASE_URL + "/dataconnect/table/" + chartData.selectedDataModel;
            const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
            // targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
            targetURL += `?type=list&`;
            if (chartData.loadChildData) {
                if (chartData.parentDataElement)
                    targetURL += `&parentDataElement=${chartData.parentDataElement}`;
                else
                    targetURL += '&fields=';
            } else
                targetURL += chartData.selectedDataElement.reduce(reducer, '&fields=');
            targetURL += '&page=' + page + '&rowsPerPage=' + rowsPerPage;
            if (props.queryString) {
                targetURL += '&' + props.queryString;
            }
            if (chartData.conditions && chartData.conditions.length > 0) {
                let cond = JSON.stringify(chartData.conditions);
                targetURL += "&conditions=" + cond;
            }
            if (props.page.conditions && props.page.conditions.length > 0) {
                let cond = JSON.stringify(props.page.conditions);
                targetURL += "&pConditions=" + cond;
            }
          let encodedData = encodeURIComponent(targetURL)
          let _targetUrl = JSON.stringify(encodedData);
            console.log("getApiDataFromDataUrl=====>", targetURL);
            getApiDataFromDataUrl(_targetUrl);
        } else if (props.dataUrl) {
            console.log("getApiDataFromDataUrl1=====>", targetURL);
            getApiDataFromDataUrl(props.dataUrl);
        } else {
            console.log("getApiDataFromDataUrl2=====>", targetURL, props.data);
            let obj = JSON.parse(JSON.stringify(props.data));
            let list = obj && obj.columns.map((item) => {
                return { ...item, checked: true }
            });
            obj.columns = [...list];
            setState({ tableData: props.data, filterTableData: obj, isLoading: false, count: props.data.length });
        }
    }

    setDatafromApi = (res) => {
        if (res && Array.isArray(res.data) && !res.data.columns && !res.data.datasets) {
            let tableHeader = getTableHeader(res.data);
            let r = {
                columns: tableHeader,
                datasets: res.data
            }
            let obj = JSON.parse(JSON.stringify(r));
            let list = obj.columns.map((item) => {
                return { ...item, checked: true }
            });
            obj.columns = [...list];
            setState({
                resData: r, tableData: r, filterTableData: obj, isLoading: false,
                count: res.total ? res.total : res.data.length, filterData: r.columns ? r.columns.map((col) => r.datasets.map(item => item[col.name])) : []
            });
        } else if (res.columns && res.datasets) {
            let obj = JSON.parse(JSON.stringify(res));
            let list = obj.columns.map((item) => {
                return { ...item, checked: true }
            });
            obj.columns = [...list];
            setState({ tableData: res, filterTableData: obj, isLoading: false, count: res.total ? res.total : res.datasets.length });
        } else {
            throw new Error('Expect data is object with columns and datasets keys');
        }
    }

    handleMoreClick = (e) => {
        console.log("handleMoreClick", e);
        history.push({
            pathname: `${props.chartData.extendedTablePage.url}`
        });
    }

    handleAddClick = (e) => {
        console.log("handleAddClick", e);
        history.push({
            pathname: `/apps/${props.appId}/form/${props.chartData.associatedFormName}/new`
        });
    }

    getPageUrl = (selectedLinkValue) => {
        let page = props.pages.find(p => {
            return p._id === selectedLinkValue
        })
        return page && page.url;
    }

    onSearchChange = (searchText) => {
        console.log("searchText", searchText);
        // console.log("data", data);
        let d = { ...state.tableData };
        d.datasets = state.resData.datasets.filter(ds => {
            let flag = false
            Object.keys(ds).map((k) => {
                console.log("ds", ds, k, ds[k]);

                if (String(ds[k]).includes(searchText))
                    flag = true
            })
            return flag
        });
        console.log("datatttt => d", d);
        setState({ tableData: d, filterTableData: d, searchText: searchText });
    }

    onTableChange = (action, tableState) => {

        // a developer could react to change on an action basis or
        // examine the state as a whole and do whatever they want

        switch (action) {
            case 'changePage':
                getApiData(tableState.page, tableState.rowsPerPage);
                break;
        }
    }

    onRowClick = (rowData, rowMeta) => {
        console.log('onRowClick');
        let { extendedTablePage, associatedFormName } = props.chartData;
        // if (associatedFormName && data.datasets[rowMeta.rowIndex]._id) {
        if (associatedFormName && rowData._id) {
            history.push({
                pathname: `/apps/${props.appId}/form/${associatedFormName}/${rowData._id}`
            });
        }
    }

    onCellClick = (colData, cellMeta) => {
        console.log('onCellClick');
        let { selectedDataPages, selectedDataColumns, links,
            extendedTablePage, associatedFormName, name } = props.chartData;
        let { data } = state;
        if (links && links.length > 0)
            links.map((l, i) => {
                if (cellMeta.name === l.selectedLinkDataElement) {
                    let url, search = '';
                    if (l.selectedLinkType === "page") {
                        let pageUrl = getPageUrl(l.selectedLinkValue);
                        if (pageUrl)
                            url = `/apps/${props.appId}/${pageUrl}/${l.selectedLinkDataElement}/${colData[cellMeta.name]}`
                    } else {
                        if (l.selectedLinkDataModel && l.selectedLinkKey) {
                            url = `/apps/${props.appId}/form/${l.selectedLinkValue}/new`
                            search = `?dm=${l.selectedLinkDataModel}&k=${l.selectedLinkKey}`
                            search += `&v=${colData[l.selectedLinkKey]}`
                            search += '&page=0&rowsPerPage=10&type=list&fields='
                            const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
                            if (l.additionalFields)
                                search += l.additionalFields.reduce(reducer, '');
                        } else
                            url = `/apps/${props.appId}/form/${l.selectedLinkValue}/${colData._id}`
                    }
                    if (url) {
                        console.log('url', url);
                        return history.push({
                            pathname: url, search: search
                        });
                    }
                    else
                        alert(`Please provide valid links for column ${l.selectedLinkDataElement}`)
                }
            })
    }

    searchHandler = (e) => {
        console.log("e====>", e);
        setState({ searchText: e ? e.target.value : "" }, () => updateTableData());
    }

    handleInputChange = (event) => {
        console.log("value:" + event.target.value + " checked: " + event.target.checked);
        let obj = state.filterTableData;
        let list = obj.columns;
        list.map((item, index) => {
            if (item.name === event.target.value) list[index].checked = event.target.checked
        })
        obj.columns = [...list]
        setState({ filterTableData: obj }, () => updateTableData());
    }
    resetFilters = (event) => {
        setState({ filterList: [] }, () => updateTableData())
    }


    handleDropdownChange = (event, index, column) => {
        const value = event.target.value === "All" ? [] : [event.target.value];
        // filterUpdate(index, value, column, 'dropdown');
        console.log("event.target.value" + event.target.value + "index" + index + "column" + column)
        let list = state.filterList;
        list[index] = event.target.value;
        setState({ filterList: [...list] }, () => updateTableData())
    };

    updateTableData() {
        let obj = state.tableData;
        let filterObj = state.filterTableData;
        let list = obj.datasets;
        console.log("Final Table LIst", list);
        let finalList = [];
        if (state.searchText !== "") {
            finalList = list.map((item) => {
                let isFilter = false;
                Object.keys(item).map((key) => {
                    filterObj.columns.map(keyobj => {//when columns are check
                        if (keyobj.checked && key === keyobj.name) {
                            if (item[key].toString().includes(state.searchText)) {
                                isFilter = true;
                            }
                        }
                    })
                })
                if (isFilter) return item;
            }).filter(function (element) {
                return element !== undefined;
            });
        } else {
            finalList = [...list];
        }

        // state.viewColumnsList.map((item) => {
        //     if (!item.checked) {
        //         finalList = finalList.map(cData => {
        //             let obj = cData;
        //             delete obj[`${item.name}`]
        //             return { ...obj };
        //         })
        //     }
        // })
        console.log("finalList", finalList);
        state.filterList.map((item, index) => {
            finalList = finalList.map((dataSet) => {
                if (item && item !== "All") {
                    if (dataSet[`${filterObj.columns[index].name}`] === item) {
                        return dataSet
                    }
                } else {
                    return dataSet
                }
            }).filter(function (element) {
                return element !== undefined;
            });
        })


        filterObj.datasets = [...finalList]
        setState({ filterTableData: { ...filterObj } });
    }


    tableCrudOperations = (item, type) => {
        console.log("CRUD operations==>", item + "    Type===>" + type);

    }

    handleExoprtItemClick = (data, index) => {
        console.log("handleExoprtItemClick", data, index);
    }

    render() {
        console.log("props.chartData=====>", props);
        console.log("state=====>", state);
        return <CustomTable
            onRowClick={onRowClick}
            selectedDataPages={state.selectedDataPages}
            {...props}
            {...state}
            searchHandler={searchHandler}
            isExport={true}
            isViewCoulmns={true}
            isSearch={true}
            isFilter={true}
            hasCheckbox={true}
            actions={{ edit: true, view: true, delete: true, copy: true }}
            isTablePagination={true}
            isTableHead={true}
            isTableSubHeader={true}
            handleInputChange={handleInputChange}
            resetFilters={resetFilters}
            handleDropdownChange={handleDropdownChange}
            handleRequestSort={handleRequestSort}
            handleSelectAllClick={handleSelectAllClick}
            tableCrudOperations={tableCrudOperations}
            handleCheck={handleCheck}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleExoprtItemClick={handleExoprtItemClick}
            exportArray={exportArray}
            // headerstyle={{ fontSize: "14px", color: "blue", backgroundColor: "Red", textAlign: "center" }}
            // subHeaderstyle={{ fontSize: "14px", color: "red", backgroundColor: "#FFF", textAlign: "center" }}
            // rowStyle={{ fontSize: "14px", color: "blue", backgroundColor: "#000", row1BackgroundColor: "#000", row2BackgroundColor: "#FFF" }}
            headerstyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", textAlign: "center" }}
            subHeaderstyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", textAlign: "center" }}
            rowStyle={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#FFF", row1BackgroundColor: "#FFF", row2BackgroundColor: "#FFF" }}
        >
        </CustomTable >
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

const mapStateToProps = state => {
    return {
        pages: state.pages.page,
    };
};

export default connect(mapStateToProps, null)(TableWrapper)