import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    makeStyles,
    Slide,
    Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { capitalize } from '../../../../../utility/utils';
import { months } from '../../../mrplan/Sheettable/data';
import { getFiscalYearFormat, getPast12months, isActualColumns } from '../../spendplanUtils';
import ConfirmDialog from '../ConfirmDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    paper: { minWidth: 650, height: 360, },
    contents: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000',
    },
    textInput: {
        marginLeft: 20,
        border: '1px solid lightgray',
        width: 50,
        height: 35,
        background: 'transparent',
        outline: 'none',
    },
    monthsRow: {
        display: 'flex',
    },
    heading1: {
        fontSize: '20px !important',
        fontWeight: 500,
        color: '#000',
    },
    monthContainer: {
        marginTop: 20,
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            minHeight: '280px',
            width: '600px'
        }
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000'
    }
}));

const SalienceModal = (props) => {
    let { open, handleClose } = props;
    let { heading, month } = props.data

    const classes = useStyles();
    const [selectedMonths, selectMonths] = useState([])
    const [isOpen, setOpen] = useState(false);

    const minusOneYear = ((Number(props.brand?.FinancialYear) - 1) + "").slice(2)
    const currentYear = (Number(props.brand?.FinancialYear) + "").slice(2)

    const handleConfirmModal = (v) => {
        if (v) {
            let currentYearMonths = [];
            let sleMonths = [];

            selectedMonths.forEach(sle => {
                if (sle.includes(currentYear)) {
                    currentYearMonths.push(sle.replace(` ${currentYear}`, ''))
                } else {
                    sleMonths.push(sle)
                }
            })
            let months = {
                fromMonth: sleMonths,
                toMonth: month
            }
            if (currentYearMonths.length) {
                months['currentYearMonths'] = {
                    currentYear: props.brand?.FinancialYear,
                    months: currentYearMonths
                }
            }
            handleClose(true, months)
        }
        setOpen(false);
    };

    const getAlertMessage = () => {
        const getMonthYearFormat = (m) => {
            if (getPast12months(props?.brand?.CurrentFF)[0].includes(m)) {
                return capitalize(m) + " " + getYearFormat(m, true)
            }
            return capitalize(m) + " " + getYearFormat(m, false)
        }
        if (selectedMonths.length) {
            return {
                p1: `Are you sure you want to apply Salience Forecast on ${capitalize(month)} ${getFiscalYearFormat(props.brand?.FinancialYear) || ''}?`,
                p2: 'Reference Months: ' + selectedMonths.map(m => {
                    if (m.length < 4) {
                        return getMonthYearFormat(m)
                    } else {
                        return capitalize(m)
                    }
                }).join(', ')
            }
        }
        return {
            p2: 'Please select one or more Reference Months.'
        }
    }

    const checkMonth = (month, value) => {
        if (value) {
            selectMonths([...selectedMonths, month])
        } else {
            selectMonths(selectedMonths.filter(m => m !== month))
        }
    }

    const getYearFormat = (m, isMinusOne) => {
        if (months.indexOf(m) < 6) {
            return ((Number(props.brand?.FinancialYear) - 1 + (isMinusOne ? -1 : 0)) + "").slice(2)
        }
        return ((Number(props.brand?.FinancialYear) + (isMinusOne ? -1 : 0)) + "").slice(2)
    }

    const renderCheckBox = (m, index, isMinusOne, displayOnlyMonth = false) => {
        return (
            <Grid item xs={2} key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    style={{
                        transform: "scale(0.6)", padding: 0,
                    }} onChange={(e, v) => checkMonth(m, v)}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    checked={selectedMonths.includes(m)}
                />
                <span style={{ whiteSpace: 'nowrap' }}>{displayOnlyMonth ? capitalize(m) : (capitalize(m) + " " + getYearFormat(m, isMinusOne))}</span>
            </Grid>
        )
    }

    return (<>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            classes={{ paper: classes.paper }}
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: '#047dd8' }}>
                {heading}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component="div" className={classes.contents}>
                    <div>
                        <label className={classes.heading1}>Reference Months:</label>
                        <div className={classes.monthContainer}>
                            <Grid container>
                                {
                                    (getPast12months(props?.brand?.CurrentFF)[0]).map((m, index) => {
                                        return renderCheckBox(m, index, true)
                                    })
                                }
                                {
                                    (getPast12months(props?.brand?.CurrentFF)[1]).map((m, index) => {
                                        return renderCheckBox(m, index, false)
                                    })
                                }
                                {
                                    (getPast12months(props?.brand?.CurrentFF)[0]).map((m, index) => {
                                        let month = m + " " + currentYear;
                                        return renderCheckBox(month, index, false, true)
                                    })
                                }
                                <Grid item xs={12} style={{ marginTop: "12px" }}>
                                    <Typography color={"secondary"}>
                                        Note: These changes apply to all the brandforms. Please select specific Brandform from the filters to apply changes to the respective Brandform.
                                    </Typography>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <React.Fragment>
                    <Button
                        style={{ textTransform: 'none' }}
                        color="primary" onClick={() => setOpen(true)}
                        className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {"OK"}
                    </Button>
                    <Button
                        style={{ textTransform: 'none' }}
                        color="primary" onClick={() => handleClose(false)}
                        className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {"Cancel"}
                    </Button>
                </React.Fragment>
            </DialogActions>
        </Dialog>
        {isOpen && <ConfirmDialog open={true} handleClose={handleConfirmModal}
            alertMessageText={getAlertMessage()}
            classes={classes} confirm={selectedMonths.length ? confirm : null} />}
    </>);
};

const mapStateToProps = state => {
    return {
        account: state.mrReducer?.account,
    };
};

export default hot(withRouter(connect(mapStateToProps, null)(SalienceModal)));