import { CircularProgress } from '@material-ui/core';
import { Button, ClickAwayListener, Grid, Grow, makeStyles, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import classNames from 'classnames';
import FileDownload from 'js-file-download';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFileNameWithTimeStamp, getMonth } from 'utility/utils';
import { ImarReportsService } from '../../../../../../modules/api/custom/spendplan/ImarReports';
import { getImar1Reports } from '../../../../../../store/actions';
import Header from '../../../SpendPlan/Header';
import { getCurrentFFForPayload, getFiscalYearFormat } from "../../../spendplanUtils";
import Imar1Table from './imar1Table';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  imar1table: {
    '& .MuiTableContainer-root': {
      height: 'fit-content',
    },
  },
  downloadBtn: {
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    marginRight: 15,
  },
  circularBar: {
    color: 'rgb(25, 118, 210)',
    height: 20,
    width: 20,
  }
}));

const Imar1Report = (props) => {
  const classes = useStyles();
  const { brand, scenario } = props;
  const [_brand, setBrand] = useState(brand);
  const [imarDetails, setImarDetails] = useState(null)
  const [loading, setLoading] = useState(false);
  const [openBtnSection, setBtnSectionOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [scenario]);

  useEffect(() => {
    if (_brand !== brand) {
      setBrand(brand)
      fetchData();
    }
  }, [brand]);

  const fetchData = () => {
    let { BrandName, FinancialYear, CurrentFF } = brand;
    let currentMonth = getMonth(CurrentFF, 'monthNumber')
      .toString();
    if (currentMonth.length === 1) {
      currentMonth = '0' + currentMonth;
    }
    const modalData = props.modalData;
    let version = false;
    if (modalData && modalData.brand && modalData.month && modalData.year) {
      BrandName = modalData.brand;
      FinancialYear = modalData.year;
      currentMonth = getCurrentFFForPayload(modalData.month);
      version = true
    }
    if (currentMonth) {
      let data1 = {
        appId: props.match.params.appid,
        url: `/${BrandName}/${FinancialYear}/${currentMonth}/${scenario}`,
      };
      if (version) {
        data1.url += '?version=true'
      }
      props.getImar1Reports(data1)
      let data2 = {
        appId: props.match.params.appid,
        params: `?brand=${encodeURIComponent(BrandName)}&fiscalYear=${FinancialYear}&currentFF=${currentMonth}&scenario=${scenario}`,
      };
      fetchImarDetails(data2)
    }
  };

  const fetchImarDetails = (payload) => {
    setLoading(true)
    ImarReportsService.getImar1Details(payload).then(res => {
      setImarDetails(res.result.data?.sections)
      setLoading(false)
    }).catch(e => {
      console.log('error', e)
      setLoading(false)
    })
  }

  const downloadHandler = (isPDF = false) => {
    if (brand) {
      let currentMonth = getMonth(brand.CurrentFF, 'monthNumber')
        .toString();
      if (currentMonth.length === 1) {
        currentMonth = '0' + currentMonth;
      }
      let _brandName = props.modalData ? props.modalData.brand : brand.BrandName;
      let _financialYear = props.modalData ? props.modalData.year : brand.FinancialYear;
      let _currentMonth = props.modalData ? getCurrentFFForPayload(props.modalData.month) : currentMonth;
      let uri = props?.scenario ? API_BASE_URL + `/imar/download${isPDF ? '-pdf' : ''}/${_brandName}/${_financialYear}/${_currentMonth}/${props?.scenario}` : API_BASE_URL + `/imar/download${isPDF ? '-pdf' : ''}/${_brandName}/${_financialYear}/${_currentMonth}`;
      if (props.modalData) {
        uri += '?version=true'
      }
      let fyFF = getFiscalYearFormat(_financialYear) + '-' + moment().month(Number(_currentMonth) - 1).format('MMM');
      setDownloadLoading(true)
      setBtnSectionOpen(false)
      axios({
        url: uri,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          appId: props.match.params.appid,
        },
      })
        .then((response) => {
          FileDownload(response.data, getFileNameWithTimeStamp('IMAR - MSASD Process' + '-' + brand.BrandName + '-' + fyFF, isPDF));
          setDownloadLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setDownloadLoading(false)
        });
    }
  };

  const onChangeDetails = (type, value, num, index) => {
    let _imarDetails = imarDetails?.length ? [...imarDetails] : [];
    if (type === 'status') {
      if (index > -1) {
        _imarDetails[index] = { ..._imarDetails[index], status: value }
      } else {
        _imarDetails.push({
          section: num,
          status: value,
        })
      }
      setImarDetails([..._imarDetails])
    }
    if (type === 'remarks') {
      if (index > -1) {
        _imarDetails[index] = { ..._imarDetails[index], remarks: value }
      } else {
        _imarDetails.push({
          section: num,
          remarks: value,
        })
      }
      setImarDetails([..._imarDetails])
    }
  }

  const saveRemarks = () => {
    let { BrandName, FinancialYear, CurrentFF } = brand;
    let currentMonth = getMonth(CurrentFF, 'monthNumber')
    let data = {
      appId: props.match.params.appid,
      payload: {
        "brand": BrandName,
        "fiscalYear": "" + FinancialYear,
        "currentFF": getCurrentFFForPayload(currentMonth),
        sections: imarDetails || []
      }
    }
    setLoading(true);
    ImarReportsService.saveImar1Details(data).then(res => {
      setLoading(false)
      let data = {
        appId: props.match.params.appid,
        params: `?brand=${encodeURIComponent(BrandName)}&fiscalYear=${FinancialYear}&currentFF=${getCurrentFFForPayload(currentMonth)}`,
      };
      fetchImarDetails(data)
    }).catch(e => {
      console.log('error', e)
      setLoading(false)
    })
  }


  const handleBtnSectionClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setBtnSectionOpen(false);
  };


  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setBtnSectionOpen(false);
    }
  }


  return (
    <div className={classNames(classes.root, classes.imar1table)} style={{ paddingBottom: '15px' }}>
      <Grid container justify="space-between">
        <Grid item xs={9} sm={9}>
          <Header header={props.modalData ? props.versionTitle : (`IMAR - MSASD Process - ${brand && brand.CurrentFF && brand.FinancialYear
            ? `FY ${getFiscalYearFormat(brand.FinancialYear)} ${getMonth(brand.CurrentFF, 'month')} FF`
            : 'FY 2021'}`)} />
        </Grid>
        <Grid item>
          {!props.modalData && <Button
            variant="contained"
            color="primary"
            className={classes.downloadBtn}
            size="medium"
            startIcon={<SaveIcon />}
            onClick={saveRemarks}
          > {'Save Remarks'} </Button>}
          <Button
            variant="contained"
            color="primary"
            className={classes.downloadBtn}
            size="medium"
            onClick={() => setBtnSectionOpen((prevOpen) => !prevOpen)}
            startIcon={<GetAppIcon />}
            ref={anchorRef}
          >
            {
              downloadLoading ? <CircularProgress className={classes.circularBar} size={24} /> : 'Download'
            }
          </Button>
        </Grid>
        <Popper open={true} anchorEl={anchorRef.current} role={undefined} transition disablePortal
          style={{ zIndex: 5, visibility: openBtnSection ? 'visible' : 'hidden' }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleBtnSectionClose}>
                  <MenuList autoFocusItem={setBtnSectionOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.downloadBtn}
                        size="medium"
                        onClick={() => downloadHandler(true)}
                      > {'Download Pdf'} </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.downloadBtn}
                        size="medium"
                        onClick={()=> downloadHandler(false)}
                      > {'Download Excel'} </Button>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
      <Imar1Table loading={loading} imarDetails={imarDetails} brandData={brand}
        onChangeDetails={onChangeDetails}
        modalData={props.modalData} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    brand: state.tprReducer?.selectBrand,
    scenario: state.tprReducer?.selectScenario
  };
};


const mapDispatchToProps = {
  getImar1Reports,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Imar1Report)),);
