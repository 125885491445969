import { put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import { DisplaysService } from '../../../modules/api/custom/spendplan/displays';


async function fetchAsync(func, arg) {
  const response = arg ? await func(arg) : await func();
  return response;
}

function* fetchDisplaysData(action) {
  try {
    const tableData = yield fetchAsync(DisplaysService.getDisplaysData, action.payload);
    yield put({ type: ActionTypes.GET_DISPLAYS_SUCCESS, payload: tableData });
  }
  catch (err) {
    yield put({ type: ActionTypes.GET_DISPLAYS_FAILURE, error: err.message });
  }
}

function* fetchDisplaysExtraData(action) {
  try {
    const tableData = yield fetchAsync(DisplaysService.getDisplaysExtraData, action.payload);
    yield put({ type: ActionTypes.GET_DISPLAYS_EXTRA_DATA_SUCCESS, payload: tableData.result.data });
  }
  catch (err) {
    yield put({ type: ActionTypes.GET_DISPLAYS_EXTRA_DATA_FAILURE, extraDataError: err.message });
  }
}

function* addNewDisplays(action) {
  try {
    const response = yield fetchAsync(DisplaysService.addNewDisplays, action.payload)
    yield put({ type: ActionTypes.ADD_NEW_DISPLAYS_SUCCESS, payload: response })
  } catch (err) {
     let errorMsg, error;
        if (err.response.error) {
            error = err.response.error; 
        } else {
            errorMsg = err.message != 'Bad Request' ? err.message : 'Please enter correct Data';
        }
        yield put({ type: ActionTypes.ADD_NEW_DISPLAYS_FAILURE, error: errorMsg?errorMsg:error });
  }
}

function* deleteCheckedDisplays(action) {
  try {
    const response = yield fetchAsync(DisplaysService.deleteCheckedDisplays, action.payload)
    yield put({ type: ActionTypes.DELETE_CHECKED_DISPLAYS_SUCCESS, payload: response })
  }
  catch (err) {
    let errorMsg = err.message !== 'Bad Request' ? err.message : 'Please enter correct Data';
    yield put({ type: ActionTypes.DELETE_CHECKED_DISPLAYS_FAILURE, error: errorMsg });
  }
}

function* fetchPlans(action) {
  try {
      const response = yield fetchAsync(DisplaysService.getPlans, action.payload)
      yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_DISPLAYS_SUCCESS, payload: response?.result?.data ?? [] })
  }
  catch (err) {
      let errorMsg = err.message != 'Bad Request' ? err.message : 'check request Failed';
      yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_DISPLAYS_FAILURE, error: errorMsg });
  }
}

export function* displays() {
  yield takeLatest(ActionTypes.GET_DISPLAYS_DATA, fetchDisplaysData);
  yield takeLatest(ActionTypes.ADD_NEW_DISPLAYS, addNewDisplays);
  yield takeLatest(ActionTypes.DELETE_CHECKED_DISPLAYS, deleteCheckedDisplays);
  yield takeLatest(ActionTypes.GET_DISPLAYS_EXTRA_DATA, fetchDisplaysExtraData);
  yield takeLatest(ActionTypes.GET_PLAN_FILTER_DATA_DISPLAYS, fetchPlans);

  
}

export default displays;
