import { ActionTypes } from 'constants/index';

let initialFreezePlan = {
    loading: false,
    loading1: false,
    message: null,
    brands: null,
    freezedBrands: null,
    failedBrands: null,
    success: false,
    failure: false
}

const initialState = {
    data: {},
    error: '',
    loading: false,
    rowLoading: false,
    getNewData: false,
    extraDataError: '',
    extraDataLoading: false,
    filterData: {
        brands: [],
        channels: [],
        clusters: [],
        plans: [],
    },
    selectBrand: {},
    brandEmpty: false,
    accountEmpty: false,
    freezePlan: initialFreezePlan,
    topFivePlans: [],
    updatedData: [],
    planUploadLoading: false,
    successMsg: '',
    bgProcesses: null,
    saved: false,
    freezePlanProcesses: null,
    setupUploadLoading: false,
    modifiedRecords: {},
    getData: null,
    errorTSP: null,
    deleteLoading: false,
    scenarioList: [],
    selectScenario: '01'
};

export default function tprReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.TOPFIVEPLANS_SUCCESS: {
            return {
                ...state,
                topFivePlans: action.payload
            };
        }
        case ActionTypes.FREEZE_PLANS: {
            return {
                ...state,
                freezePlan: { ...initialFreezePlan, loading: true, brands: action.payload }
            };
        }
        case ActionTypes.FREEZE_PLANS_SUCCESS: {
            return {
                ...state,
                freezePlan: {
                    ...state.freezePlan, loading: false, success: true,
                    freezedBrands: action.payload.freezedBrands,
                    failedBrands: action.payload.failedBrands
                }
            };
        }
        case ActionTypes.FREEZE_PLANS_FAILURE: {
            return {
                ...state,
                freezePlan: {
                    ...state.freezePlan, loading: false, failure: false,
                    freezedBrands: action.payload.freezedBrands,
                    failedBrands: action.payload.failedBrands
                }
            };
        }
        case ActionTypes.SET_BRAND_EMPTY: {
            return {
                ...state,
                brandEmpty: action.payload
            };
        }
          case ActionTypes.SET_ACCOUNT_EMPTY: {
            return {
                ...state,
                accountEmpty: action.payload
            };
        }
        case ActionTypes.USER_SELECTED_BRAND: {
            return {
                ...state,
                selectBrand: action.payload,
                modifiedRecords: {},
            };
        }
        case ActionTypes.GET_SP_TPR_DATA: {
            return {
                ...state,
                loading1: true,
                getNewData: false,
                error: ''
            };
        }
        case ActionTypes.GET_SP_TPR_SUCCESS: {
            let _modifiedRecords = Object.keys(state.modifiedRecords)
            let fetchedRecords = action.payload;
            let rows;
            if(_modifiedRecords.length){
                rows = fetchedRecords.result.data.map(item=> {
                    if(state.modifiedRecords[item._id]){
                        return state.modifiedRecords[item._id]
                    }
                    return item;
                })
                fetchedRecords.result.data = rows
            }
            return {
                ...state,
                data: fetchedRecords,
                getNewData: false,
                loading1: false
            }
        }
        case ActionTypes.GET_SP_TPR_FAILURE: {
            return {
                ...state,
                loading1: false,
                getNewData: false,
                error: action.error
            };
        }
        case ActionTypes.GET_SP_TPR_EXTRA_DATA: {
            return {
                ...state,
                error: '',
                loading: false,
                getNewData: false,
                extraDataError: '',
                extraDataLoading: true,
            };
        }
        case ActionTypes.GET_SP_TPR_EXTRA_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                getNewData: false,
                extraDataLoading: false,
                extraData: action.payload,
            }
        }
        case ActionTypes.GET_SP_TPR_EXTRA_DATA_FAILURE: {
            return {
                ...state,
                error: '',
                loading: false,
                getNewData: false,
                extraDataLoading: false,
                extraDataError: action.extraDataError,
            };
        }
        case ActionTypes.ADD_SP_TPR_ROW_SUCCESS: {
            return {
                ...state,
                modifiedRecords: {},
                getNewData: true,
                rowLoading: false,
                error: '',
                successMsg: action.payload.message,
                saved: true,
            }
        }
        case ActionTypes.ADD_SP_TPR_ROW_FAILURE: {
            return {
                ...state,
                rowLoading: false,
                getNewData: false,
                error: action.error
            };
        }
        case ActionTypes.GET_BRANDFORM_FILTER_SUCCESS: {
            return {
                ...state,
                filterData: {
                    ...state.filterData,
                    brands: action.payload
                }
            }
        }
        case ActionTypes.GET_BRANDFORM_FILTER_FAILURE: {
            return {
                ...state,
                loading: false,
                // error: action.error
            };
        }
        case ActionTypes.GET_CHANNEL_FILTER_SUCCESS: {
            return {
                ...state,
                filterData: {
                    ...state.filterData,
                    channels: action.payload.data
                }
            }
        }
        case ActionTypes.GET_CHANNEL_FILTER_FAILURE: {
            return {
                ...state,
                loading: false,
                // error: action.error
            };
        }
        case ActionTypes.GET_CLUSTER_FILTER_SUCCESS: {
            return {
                ...state,
                filterData: {
                    ...state.filterData,
                    clusters: action.payload.data
                }
            }
        }
        case ActionTypes.GET_CLUSTER_FILTER_FAILURE: {
            return {
                ...state,
                loading: false,
                // error: action.error
            };
        }
        case ActionTypes.DELETE_CHECKED_TPR: {
            return {
                ...state,
                deleteLoading: true,
            };
        }
        case ActionTypes.DELETE_CHECKED_TPR_FAILURE: {
            return {
                ...state,
                deleteLoading: false,
            };
        }
        case ActionTypes.DELETE_CHECKED_TPR_SUCCESS: {
            let __result = action.payload?.result?.data ?? []
            let _modifiedRecords = {...state.modifiedRecords};
            __result?._id?.forEach(i => delete _modifiedRecords[i]);
            return {
                ...state,
                deleteLoading: false,
                modifiedRecords: _modifiedRecords,
                data: {
                    ...state.data,
                    result: {
                        ...state.data.result,
                        total: state.data.result?.total - __result?.deletedCount,
                        data: state.data.result.data.filter(item => {
                            return !__result?._id.includes(item._id)
                        })
                    }
                },
              getNewData: true,
              successMsg: action.payload.message,
            };
        }

        case ActionTypes.CHECKDATA_SUCCESS:{
            return{
                ...state,
                updatedData:action.payload
            }
        }
        case ActionTypes.SET_UPLOAD_RTDX_DATA_LOADNIG:{
            return{
                ...state,
                setupUploadLoading: action.payload
            }
        }
        case ActionTypes.SET_UPLOAD_PLAN_LOADNIG:{
            return{
                ...state,
                planUploadLoading: action.payload
            }
        }
        case ActionTypes.REMOVE_SUCCESS_MSG:{
            return{
                ...state,
                successMsg: '',
                error: '',
                saved: false,
            }
        }
        
        case ActionTypes.GET_PLAN_FILTER_DATA_TPR:{
            return{
                ...state,
                loading: action.payload
            }
        }
        case ActionTypes.GET_PLAN_FILTER_DATA_TPR_SUCCESS:{
            return{
                ...state,
                loading: false,
                filterData: {
                    ...state.filterData,
                    plans: action.payload
                }
            }
        }
        case ActionTypes.GET_PLAN_FILTER_DATA_TPR_FAILURE: {
            return {
                ...state,
                successMsg: '',
                saved: false,
            }
        }
        case ActionTypes.CHECK_SP_BG_PROCESS_SUCCESS: {
            return {
                ...state,
                bgProcesses: action.payload,
            }
        }
        case ActionTypes.CHECK_FREEZE_PLAN_PROCESS_SUCCESS: {
            return {
                ...state,
                freezePlanProcesses: action.payload,
            }
        }
        case ActionTypes.MODIFY_TPR: {
            let records = JSON.parse(JSON.stringify(state.modifiedRecords));
            records[action.payload._id] = action.payload;
            return {
                ...state,
                modifiedRecords: {...records},
            }
        }
        case ActionTypes.CLEAR_MODIFY_TPR: {
            return {
                ...state,
                modifiedRecords: {},
            }
        }
        case ActionTypes.REMOVE_MODIFY_TPR: {
            let _modifiedRecords = {...state.modifiedRecords};
            action.payload.forEach(i => delete _modifiedRecords[i]);
            return {
                ...state,
                modifiedRecords: _modifiedRecords,
            }
        }
        case ActionTypes.GET_TSP_DATA: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true
            };
        }
        case ActionTypes.GET_TSP_DATA_SUCCESS: {
            let updatedData;
            updatedData = { ...state.getData, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                getData: updatedData,
            }
        }
        case ActionTypes.GET_TSP_DATA_FAILURE: {
            let error = { ...state.errorTSP, [action.payload.dataUniqueId]: action.payload.error }
            let updatedData = { ...state.getData, [action.payload.dataUniqueId]: null }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                errorTSP: error,
                getData: updatedData,

            };
        }
        case ActionTypes.GET_IMARTABLE_DASHBOARD_SUCCESS: {
            return {
                ...state,
                imarDataDashboard: action.payload
            };
        }

        case ActionTypes.GET_TSP_SCENARIO_LIST_SUCCESS: {
            const isActive = action.payload.find(element => element.isActive);
            return {
                ...state,
                scenarioList: action.payload,
                selectScenario: isActive?.value || state.selectScenario
            }
        }

        case ActionTypes.GET_TSP_SCENARIO_SUCCESS: {
            return {
                ...state,
                selectScenario: action.payload?.selScenario
            }
        }

        default: {
            return state;
        }
    }
}
