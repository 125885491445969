import { ActionTypes } from 'constants/index';

const initialState = {
  dialogVisible: false,
  reconData: [],
  getNewData: false,
  data: null,
  saveReconFlag: false,
};

export default function ReconReviewReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADD_RECON_BASELINE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.ADD_RECON_BASELINE_SUCCESS: {
      return {
        ...state,
        loading: false,
        getNewData: true,
      };
    }
    case ActionTypes.ADD_RECON_BASELINE_FAILURE: {
      return {
        ...state,
        loading: false,
        getNewData: false,
      };
    }
    case ActionTypes.GET_RECON_REVIEW_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GET_RECON_REVIEW_DATA_SUCCESS: {
      return {
        ...state,
        reconData: action.payload,
        loading: false,
        getNewData: false,
      };
    }
    case ActionTypes.GET_RECON_REVIEW_DATA_FAILURE: {
      return {
        ...state,
        reconData: [],
        loading: false,
        error: action.error,
      };
    }

    case ActionTypes.REMOVE_RECON_REVIEW_COLUMN_SUCCESS: {
      return {
        ...state,
        getNewData: true,
      };
    }
    case ActionTypes.REMOVE_RECON_REVIEW_COLUMN_FAILURE: {
      return {
        ...state,
      };
    }
    case ActionTypes.SAVE_AND_RECON: {
      return {
        ...state,
        saveReconFlag: action.payload,
      };
    }
    case ActionTypes.CLEAR_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default: {
      return state;
    }

  }

}
