import React, { Component, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import axios from 'axios'
import { DropzoneDialog } from 'material-ui-dropzone'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { convertDate, getMonth } from "utility/utils"
import SetupConfirmDialog from './SetupConfirmDialog';
import { checkSpBGprocesses, setLoading } from '../../../../store/actions';
import { checkForBGProcesses } from "../spendplanUtils";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
	confirmationDialoge: {
		'& .MuiDialog-paperWidthSm': {
			height: '320px',
			width: '600px'
		}
	},
	para: {
		fontSize: '28px !important',
		fontWeight: 600,
		color: '#000'
	}
}));

const Upload = (props) => {
	const classes = useStyles();
	const [state, setState] = useState({
		open: false,
		progressOpen: false,
		files: [],
		file: null,
		title: "Please wait, still in progress...",
		description: "Please wait, still in progress...",
		fileId: props.fileId
	})
	const [confirmState, setConfirm] = useState({ isOpen: false, confirmMessage: '' });

	const handleClose = (open) => {
		setState({
			[open]: false
		})
	}

	const isAllBrandUpload = () => {
		return props.btnTitle === 'Upload for all brands';
	}

	const submitForm = (contentType, data, setResponse) => {
		let url = `${API_BASE_URL}/setupdata/${props.page?.pageLevelDataModel}`
		if (props.btnTitle === 'Upload RTDX Data') {
			url = `${API_BASE_URL}/setupdata/rtdx/${props.page?.pageLevelDataModel}`
		}
		if (isAllBrandUpload() || props.type === 'pysdrlp') {
			url = `${API_BASE_URL}/setupdata/central-upload/${props.page?.pageLevelDataModel}`
		}
		props.setLoading(true)
		axios({
			url: url,
			method: 'POST',
			data: data,
			headers: {
				'Content-Type': contentType,
				appId: props.match.params.appid
			},
			onUploadProgress: (progressEvent) => {
				let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
				console.log('percentCompleted', percentCompleted)
			}
		}).then((response) => {
			props.setLoading(false)
			const { BrandName, CurrentFF, FinancialYear } = props.selectBrand
			let m = '' + getMonth(CurrentFF, 'monthNumber');
			m = m.length === 1 ? '0' + m : m;
			let obj = {
					appId: props.match.params.appid,
					brand: encodeURIComponent(BrandName),
					fiscalYear: FinancialYear + "",
					currentFF: m + "",
					scenario: props?.selectScenario || '01'
				}
			checkForBGProcesses(props.type) && props.checkSpBGprocesses(obj)
			setResponse(response.data)
			if (response.data?.status === 400)
				return setConfirm({
					...confirmState, isOpen: true,
					confirmMessage: { title: "Error", message: response.data?.message }
				});
			setConfirm({
				...confirmState, isOpen: true,
				confirmMessage: isAllBrandUpload() ? response?.data?.data : response.data?.message
			});
		}).catch((error) => {
			props.setLoading(false)
			setResponse({ "error": error })
		})
	}

	const uploadWithFormData = (files) => {
		const formData = new FormData()
		const { BrandName, CurrentFF, FinancialYear } = props.selectBrand
		let fiscalYear = Number(FinancialYear),
			currentFF = getMonth(CurrentFF, 'monthNumber')
		formData.append("file", files[0])
		formData.append("fiscalYear", fiscalYear)
		formData.append("brand", BrandName)
		formData.append("currentFF", (currentFF > 9 ? '' : '0') + currentFF)
		formData.append("formType", props.type)
		formData.append('scenario', props?.selectScenario || '01')

		submitForm("multipart/form-data", formData, (res) => {
			props.handleSave()
		});
	}

	const handleSave = (files) => {
		setState({
			files: files
		})
		uploadWithFormData(files)
	}

	const handleOpen = (open) => {
		setState({
			[open]: true,
		})
	}

	const confirmHandler = () => {
		setConfirm({ ...confirmState, isOpen: false, confirmMessage: '' });
	}

	let acceptedFiles = props.acceptedFiles
		? props.acceptedFiles
		: ['application/vnd.ms-excel', 'text/csv']

	return (<>
		<Button onClick={e => handleOpen('open')} size="large" color="primary" variant="contained"
			startIcon={<CloudUploadIcon />}>
			{props.btnTitle ? props.btnTitle : "Upload"}
		</Button>
		<DropzoneDialog
			open={state.open}
			onSave={(files) => {
				handleClose('open')
				handleSave(files)
			}}
			acceptedFiles={acceptedFiles}
			showPreviews={true}
			maxFileSize={18000000}
			filesLimit={1}
			onClose={e => handleClose('open')}
		/>
		{confirmState.isOpen && <SetupConfirmDialog open={confirmState.isOpen} handleClose={confirmHandler}
			uploadType={isAllBrandUpload() ? 'allUpload' : null}
			alertMessageText={confirmState.confirmMessage} classes={classes} type="setup" />}
	</>)
}

const mapDispatchToProps = {
	setLoading,
	checkSpBGprocesses,
};

const mapStateToProps = (state) => {
	return {
		selectBrand: state.tprReducer.selectBrand,
		selectScenario: state.tprReducer?.selectScenario
	}
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Upload)));
