/**
 * @module Actions/Application
 * @desc Application Actions
*/
import { ActionTypes } from 'constants/index';

export const selectBrand = (data) => dispatch => {
	dispatch({ type: ActionTypes.USER_SELECTED_BRAND, payload: data });
};

export const setBrandEmpty = (data) => dispatch => {
	dispatch({ type: ActionTypes.SET_BRAND_EMPTY, payload: data });
};

export const getSpTprData = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_SP_TPR_DATA, payload: data });
};

export const addSpTprRow = (data) => dispatch => {
	dispatch({ type: ActionTypes.ADD_SP_TPR_ROW, payload: data });
};

export const getBrandformfilter = (data) => dispatch => {
	dispatch({
		type: ActionTypes.GET_BRANDFORM_FILTER,
		payload: data
	})
}

export const getChannelfilter = (data) => dispatch => {
	dispatch({
		type: ActionTypes.GET_CHANNEL_FILTER,
		payload: data
	})
}

export const getClusterfilter = (data) => dispatch => {
	dispatch({
		type: ActionTypes.GET_CLUSTER_FILTER,
		payload: data
	})
}

export const brandformFilterSelect = (data) => dispatch => {
	dispatch({
		type: ActionTypes.BRANDFORM_FILTER_SELECT,
		payload: data
	})
}

export const getSpTprExtraData = (payload) => dispatch => {
	dispatch({ type: ActionTypes.GET_SP_TPR_EXTRA_DATA, payload });
}

export const deleteCheckedTpr = (payload) => dispatch => {
	dispatch({ type: ActionTypes.DELETE_CHECKED_TPR, payload })
}

export const freezePlans = (payload) => dispatch => {
	console.log("payload ===", payload);
	dispatch({ type: ActionTypes.FREEZE_PLANS, payload })
}

export const getTopFivePlans = (payload) => dispatch => {
	dispatch({ type: ActionTypes.GET_TOPFIVEPLANS, payload });
}

export const getCheckData = (payload) => dispatch => {
	dispatch({ type: ActionTypes.GET_CHECKDATA, payload });
}

export const setLoading = (loading) => dispatch => {
	dispatch({ type: ActionTypes.SET_UPLOAD_RTDX_DATA_LOADNIG, payload: loading });
}

export const setPlanUploadLoading = (loading) => dispatch => {
	dispatch({ type: ActionTypes.SET_UPLOAD_PLAN_LOADNIG, payload: loading });
}

export const removeSuccessMessage = (msg) => dispatch => {
	dispatch({ type: ActionTypes.REMOVE_SUCCESS_MSG, payload: msg });
}

export const modifyTPRs = (records) => dispatch => {
	dispatch({ type: ActionTypes.MODIFY_TPR, payload: records });
}

export const clearModifyTPRs = () => dispatch => {
	dispatch({ type: ActionTypes.CLEAR_MODIFY_TPR });
}

export const removeModifyTPRs = (ids) => dispatch => {
	dispatch({ type: ActionTypes.REMOVE_MODIFY_TPR, payload: ids });
}

export const getTSPData = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_TSP_DATA, payload: data });
}

export const getImarTableDataDashboard = (payload) => dispatch => {
	dispatch({ type: ActionTypes.GET_IMARTABLE_DASHBOARD, payload });
}

export const getImarScenarioList = (payload) => dispatch => {
	dispatch({ type: ActionTypes.GET_TSP_SCENARIO_LIST, payload });
}

export const getSelScenario = (payload) => dispatch => {
	dispatch({ type: ActionTypes.GET_TSP_SCENARIO, payload });
}

export const finalScenario = (data) => dispatch => {
	dispatch({ type: ActionTypes.UPDATE_TSP_SCENARIO, payload: data });
  };

  export const updateScenarioLable = (data) => dispatch => {
	dispatch({ type: ActionTypes.UPDATE_TSP_SCENARIO_LABLE, payload: data });
  };

