import { hot } from 'react-hot-loader/root';
import compose from 'recompose/compose';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, CardActions, Button, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Chart from 'components/charts/Chart';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import history from 'modules/history';

import NotFound from 'routes/NotFound';
import Header from 'components/Header';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import Nav from 'components/Nav';
import PrivilegeFailure from 'components/PrivilegeFailure';
import LayoutBox from 'components/Layout/LayoutBox';
import Loading from 'components/Loading'
import _ from 'modules/lodash';
import NavBarLeft from 'components/NavBarLeft';
const REACT_THEME_DESIGN = process.env.REACT_APP_THEME_DESIGN;
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;

// import '../styles/styles.scss'
import {
	loadAppconfig,
	loadApps,
	loadEntities,
	loadPageconfig,
	loadPages,
	loadTheme,
	getMember,
	getEntity
} from 'store/actions';
import { APP_CONFIG } from 'constants/index';
import FormSubmit from 'components/Form/FormSubmit'

const styles = theme => ({
});

const colCard = 3;

class Layout extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			appId: "",
			route: "",
			queryString: "",
			appPrivilegeCheck: null,
			pagePrivilegeCheck: null,
			accessablePages: [],
			// validatePrivilegeChecks: false, // Only for test purpose
			// validatePrivilegeChecks: (process.env['REACT_APP_Dsilo.apprenederer.privilegechecks'] == "true"),
			validatePrivilegeChecks: (APP_CONFIG.APP_PRIVILEGECHECKS == "true"),
			checker: false,
			parentConditions: [],
			internalLoading: true,
			currentAccountSelected: {}
		};
		this.selectAccountChange = this.selectAccountChange.bind(this);
	}

	selectAccountChange = (a) => {
		this.setState({...this.state, currentAccountSelected: a});
	}

	getAppInfo() {
		let path = this.props.location.pathname.split('/')
		let appId = path[2];
		let route = path[3];
		return { appId, route };
	}

	getQureyFromParams() {
		let path = this.props.location.pathname.split('/')
		let key = path[4] ? path[4] : undefined;
		let value = path[5] ? path[5] : undefined;
		return key && value ? "key=" + key + "&" + "value=" + value : '';
	}

	getFormNameFromParams() {
		let path = this.props.location.pathname.split('/')

		if (path[3] === 'form') {
			let data = {
				appId: path[2],
				formName: path[4],
				submissionId: (path[5] !== 'new') && path[5]
			}
			return data
		} else {
			return null
		}
	}

	componentDidMount() {
		let appInfo = this.getAppInfo();
		this.props.loadAppconfig(appInfo.appId, this.props.user.orgId);
		if (this.props.user.id || this.props.user.email)
			this.props.getMember(this.props.user, appInfo.appId);
	}

	componentDidUpdate(prevProps, prevState) {
		let appInfo = this.getAppInfo();

		if ((this.props.user.id  || this.props.user.email) && !_.isEqual(this.props.user, prevProps.user)) {
			this.props.getMember(this.props.user, appInfo.appId);
		}


		if (this.props.app && this.props.app.theme != prevProps.app.theme) {
			// this.props.loadTheme(this.props.app.theme);
		}

		if (this.props.member && prevProps.member && this.props.member._id && prevProps.member._id !== this.props.member._id && this.props.member.entityId) {
			this.props.getEntity(this.props.member.entityId, this.props.match.params.appid)
		}

		if (this.state.validatePrivilegeChecks && this.props.app && this.props.app._id
			&& !this.props.error && !this.props.entities.entities
			&& this.props.member && !this.props.memberLoading) {
			this.props.loadEntities(appInfo.appId, this.props.user.orgId);
		}

		if (this.state.validatePrivilegeChecks
			&& this.props.entities.entities && this.props.entities.entities.length > 0
			&& this.props.member.selectedRoles && this.props.app.selectedRoles
			&& this.state.appPrivilegeCheck === null) {
			let matchedRoles = this.props.app.selectedRoles.filter(item => {
				for (let i = 0; i < this.props.member.selectedRoles.length; i++) {
					if ((this.props.member.selectedRoles[i]._id
						&& item.value === this.props.member.selectedRoles[i]._id)
						|| item.value === this.props.member.selectedRoles[i]) {
						return true
					}
				}
			})
			this.setState({
				appPrivilegeCheck: !(this.props.app.selectedRoles.length > 0) || matchedRoles.length > 0
			});
		}

		if ((!this.state.validatePrivilegeChecks
			|| (this.props.entities.entities && this.props.entities.entities.length > 0
				&& this.props.entities.entities != prevProps.entities.entities))
				&& !Array.isArray(this.props.pages) || this.props.app._id !== prevProps.app._id) {
				this.props.loadPages(appInfo.appId, this.props.user.orgId);
		}
		if (this.state.validatePrivilegeChecks && this.state.appPrivilegeCheck
			&& this.props.pages && this.props.pages.length > 0 && !this.state.checker) {
			let accessablePages = this.props.pages.filter(page => {
				let matchedRoles = page.selectedRoles.filter(role => {
					for (let i = 0; i < this.props.member.selectedRoles.length; i++) {
						if ((this.props.member.selectedRoles[i].value
							&& role.value === this.props.member.selectedRoles[i].value)
							|| role.value === this.props.member.selectedRoles[i]) {
							return true
						}
					}
				})
				return matchedRoles.length > 0;
			})



			let params = {};
			let queryParams = window.location.search
			if (queryParams) {

				var query = queryParams.substring(1);
				var vars = query.split('&');
				for (var i = 0; i < vars.length; i++) {
					var pair = vars[i].split('=');
					params[pair[0]] = decodeURIComponent(pair[1]);
				}
			}
			let parentConditions = [];
			let parentPage = accessablePages.find(p => p._id === params.pid)
			if (parentPage && parentPage.conditions) {
				parentConditions = [...parentPage.conditions]
				let parentComp = parentPage.chart.find(c => c._id === params.cid)
				if (parentComp && parentComp.chartData)
					parentConditions = parentComp && [...parentConditions, ...parentComp.chartData.conditions]
			}

			let page = accessablePages.find(p => p.url === appInfo.route)
			this.setState({
				accessablePages,
				parentConditions,
				pagePrivilegeCheck: appInfo.route ? page && page.url === appInfo.route : true,
				checker: true,
				internalLoading: appInfo.route ? !!page : accessablePages.length > 0
			});
		}
	}

	getDefaultPage(navigation) {
		return navigation.links.find((nav) => {
			return nav.default
		})
	}

	render() {
		const { classes, app, user } = this.props;
		const { accessablePages } = this.state;

		let appInfo = this.getAppInfo();

		let pages = this.state.validatePrivilegeChecks &&
			this.state.appPrivilegeCheck && this.props.pages
			? accessablePages
			: this.props.pages && this.props.pages
		let page = (pages && pages.length > 0) && pages.find(p => p.url === appInfo.route)
		if (!page && !appInfo.route) {
			page = (pages && pages.length > 0) && pages.find(p => p.default === true)
		}

		if (!page && !appInfo.route) {
			page = pages[0];
		}

		let header = app && {
			title: app.title,
			subTitle: app.subTitle
		}

		let footer = app && {
			title: app.footer
		}
		page = page && JSON.parse(JSON.stringify(page));
		if (page && page.conditions) {
			page.conditions = [...page.conditions, ...this.state.parentConditions]
		}

		let queryString = this.getQureyFromParams();
		let formQueryString = this.getFormNameFromParams()
		console.log('page:', page);
		return (
			<React.Fragment>
				{(this.props.app && pages
					&& (this.props.entities.entities && this.props.entities.entities.length > 0
						&& (this.state.appPrivilegeCheck || !appInfo.route
							|| this.state.pagePrivilegeCheck)))
					|| formQueryString || !this.state.validatePrivilegeChecks
					? <React.Fragment>
						<NavBar header={header} user={user} app={this.props.app} member={this.props.member} selectAccountChange={this.selectAccountChange} name={page?.name}/>
						{this.props.app.navbarPosition === 'top'
							&& <Nav pages={pages} appId={appInfo.appId} page={page} />}
						<NavBarLeft pages={pages} appId={appInfo.appId} page={page} selectedAcc={this.state.currentAccountSelected}>
							<div className={"app--dashboard--main--page p-all-10"}>
								{formQueryString
									? <FormSubmit className="classes.layout" queryString={formQueryString} />
									: <>{page
										? <LayoutBox className="classes.layout" page={page} pages={pages}
											queryString={queryString} loading={this.props.pageLoading || this.props.loading}
											accessablePages={accessablePages} appId={appInfo.appId}
										/> : (this.state.internalLoading === false
											? <div className={"app--dashboard--404--page"}><NotFound /></div>
											: <Loading />)
									}</>
								}
							</div>
						</NavBarLeft>
					</React.Fragment>
					: (
						this.props.error && this.props.loading === false && this.state.internalLoading === false
							? <div className={"app--dashboard--404--page"}><NotFound /></div>
							: ((this.props.app._id
								&& this.props.entities.entities
								&& !this.props.entities.entities.length > 0)
								|| (this.props.app._id && this.props.entities.entities
									&& (!this.state.appPrivilegeCheck || !this.state.pagePrivilegeCheck)
									&& !this.props.entities.entities.loading)
								? <PrivilegeFailure />
								: <Loading />
							)
					)}

			</React.Fragment>
		);
	}
};

const mapDispatchToProps = {
	loadAppconfig,
	loadApps,
	loadPageconfig,
	loadPages,
	loadTheme,
	loadEntities,
	getMember,
	getEntity
};

const mapStateToProps = state => {
	return {
		app: state.appConfig.app,
		error: state.appConfig.error || state.app.error,
		loading: state.appConfig.loading,
		apps: state.app.app,
		pages: state.pages.page,
		theme: state.theme,
		user: state.user,
		entities: state.entities,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
	};
};

export default hot(compose(
	withStyles(styles, {
		name: 'App',
	}),
	connect(mapStateToProps, mapDispatchToProps),
)(Layout));

// export default hot(connect(mapStateToProps, mapDispatchToProps)(Layout));
