
import { hot } from 'react-hot-loader/root'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
    Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid, Slide,
    Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'

import MemberDetails from '../MembersList/MemberDetails'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MemberListDialog = (props) => {
    let {showMemberDeails, selectedMember, memberDetailsEmployer, classes, setShowMemberDetails}  = props
    
    return (<>
        <Dialog
            open={showMemberDeails}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth={'md'}
            onClose={() => setShowMemberDetails(false)}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#000" }}>{"Member Details"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{ color: '#000' }}>
                    <MemberDetails selectedMember={selectedMember} memberDetailsEmployer={memberDetailsEmployer} />
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.modalAction}>
                <Button onClick={() => setShowMemberDetails(false)}>{"Adjust Premium"}</Button>
                <Button onClick={() => setShowMemberDetails(false)}>{"Close"}</Button>
            </DialogActions>
        </Dialog>

    </>)
}

const mapStateToProps = state => {
    return {
        user: state.user,
        pageLoading: state.pages.loading,
        member: state.appConfig.member,
        memberLoading: state.appConfig.memberLoading,
        entity: state.appConfig.entity ? state.appConfig.entity : {}
    };
};
export default hot(withRouter(
    connect(mapStateToProps, null)(MemberListDialog)));