function generateGUID() {
	function S4() {
		return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	}
	return S4() + S4();
}

export function calculateMetrix(data, metric) {
	if (metric === "count") {
		return data.length;
	} else if (metric === "total") {
		return data.reduce((acc, item) => {
			return parseFloat(('' + acc).replace(/,/g, '')) + item;
		}, 0);
	} else if (metric === "average") {
		let sum = data.reduce((acc, item) => {
			return ('' + acc).replace(/,/g, '') + item;
		}, 0);
		return sum / data.length;
	} else if (metric === "max") {
		return Math.max(...data.map(Number));
	} else if (metric === "min") {
		return Math.min(...data.map(Number));
	} else if (metric === "percentage") {
		let sum = data.reduce((acc, item) => {
			return parseFloat(('' + acc).replace(/,/g, '')) + item;
		}, 0)
		return data.map(d => {
			return parseFloat((('' + d).replace(/,/g, '') / sum) * 100) + "%";
		})
	} else {
		return data[0];
	}
}

export function calculateMetrixFromArray(data, metric) {
	console.log("data, metric", data, metric);
	let l = data.length;
	let sum = data.reduce(function (r, a) {
		a.data.forEach(function (b, i) {
			r[i] = (r[i] || 0) + b;
		});
		return r;
	}, []);

	console.log("sum=+=", sum);


	if (metric === "count") {
		return data.length;
	} else if (metric === "total") {
		return sum;
	} else if (metric === "average") {
		return sum.map(d => d / l)
	} else if (metric === "max") {
		return data.map(d => {
			return Math.max(...d.data.map(Number));
		})
	} else if (metric === "min") {
		return data.map(d => {
			return Math.min(...data.map(Number));
		})
	} else if (metric === "percentage") {
		let s = sum.reduce((acc, item) => {
			return acc + item;
		}, 0)
		return data.map(d => {
			return ((d / s) * 100) + "%";
		})
	} else {
		return data[0];
	}
}

export function getTheme() {
	return {
		colors: ["#f45b5b", "#90ee7e", "#7798BF", "#aaeeee", "#ff0066",
			"#55BF3B", "#DF5353", "#f45b5b", "#7798BF", "#aaeeee"],
		chart: {
			backgroundColor: {
				linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
				stops: [
					[0, '#2a2a2b'],
					[1, '#3e3e40']
				]
			},
			style: {
				fontFamily: "'Unica One', sans-serif"
			},
			plotBorderColor: '#606063'
		},
		title: {
			style: {
				color: '#E0E0E3',
				textTransform: 'uppercase',
				fontSize: '20px'
			}
		},
		subtitle: {
			style: {
				color: '#E0E0E3',
				textTransform: 'uppercase'
			}
		},
		xAxis: {
			gridLineColor: '#707073',
			labels: {
				style: {
					color: '#E0E0E3'
				}
			},
			lineColor: '#707073',
			minorGridLineColor: '#505053',
			tickColor: '#707073',
			title: {
				style: {
					color: '#A0A0A3'

				}
			}
		},
		yAxis: {
			gridLineColor: '#707073',
			labels: {
				style: {
					color: '#E0E0E3'
				}
			},
			lineColor: '#707073',
			minorGridLineColor: '#505053',
			tickColor: '#707073',
			tickWidth: 1,
			title: {
				style: {
					color: '#A0A0A3'
				}
			}
		},
		tooltip: {
			backgroundColor: 'rgba(0, 0, 0, 0.85)',
			style: {
				color: '#F0F0F0'
			}
		},
		plotOptions: {
			series: {
				dataLabels: {
					color: '#B0B0B3'
				},
				marker: {
					lineColor: '#333'
				}
			},
			boxplot: {
				fillColor: '#505053'
			},
			candlestick: {
				lineColor: 'white'
			},
			errorbar: {
				color: 'white'
			}
		},
		legend: {
			itemStyle: {
				color: '#E0E0E3'
			},
			itemHoverStyle: {
				color: '#FFF'
			},
			itemHiddenStyle: {
				color: '#606063'
			}
		},
		credits: {
			style: {
				color: '#666'
			}
		},
		labels: {
			style: {
				color: '#707073'
			}
		},

		drilldown: {
			activeAxisLabelStyle: {
				color: '#F0F0F3'
			},
			activeDataLabelStyle: {
				color: '#F0F0F3'
			}
		},

		navigation: {
			buttonOptions: {
				symbolStroke: '#DDDDDD',
				theme: {
					fill: '#505053'
				}
			}
		},

		// scroll charts
		rangeSelector: {
			buttonTheme: {
				fill: '#505053',
				stroke: '#000000',
				style: {
					color: '#CCC'
				},
				states: {
					hover: {
						fill: '#707073',
						stroke: '#000000',
						style: {
							color: 'white'
						}
					},
					select: {
						fill: '#000003',
						stroke: '#000000',
						style: {
							color: 'white'
						}
					}
				}
			},
			inputBoxBorderColor: '#505053',
			inputStyle: {
				backgroundColor: '#333',
				color: 'silver'
			},
			labelStyle: {
				color: 'silver'
			}
		},

		navigator: {
			handles: {
				backgroundColor: '#666',
				borderColor: '#AAA'
			},
			outlineColor: '#CCC',
			maskFill: 'rgba(255,255,255,0.1)',
			series: {
				color: '#7798BF',
				lineColor: '#A6C7ED'
			},
			xAxis: {
				gridLineColor: '#505053'
			}
		},

		scrollbar: {
			barBackgroundColor: '#808083',
			barBorderColor: '#808083',
			buttonArrowColor: '#CCC',
			buttonBackgroundColor: '#606063',
			buttonBorderColor: '#606063',
			rifleColor: '#FFF',
			trackBackgroundColor: '#404043',
			trackBorderColor: '#404043'
		},

		// special colors for some of the
		legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
		background2: '#505053',
		dataLabelsColor: '#B0B0B3',
		textColor: '#C0C0C0',
		contrastTextColor: '#F0F0F3',
		maskColor: 'rgba(255,255,255,0.3)'
	}
};