import { hot } from 'react-hot-loader/root'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SpendPlan from '../../SpendPlan'
import {
    Grid, Paper, FormControl, Select, MenuItem, makeStyles
} from '@material-ui/core'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const PYRLPMSU = props => {
    return <SpendPlan {...props} />
}

const mapDispatchToProps = {
}

const mapStateToProps = ({ user, navigation }) => {
    return {}
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PYRLPMSU)))
