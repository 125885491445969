import React from 'react';

const commonColumns = ['Brandform', 'Channel', 'NOS_Million', 'MSASD_RLP', 'GM_NOS', '', 'NOS_Salience', 'MSASD_Salience', 'Delta', 'GM_Salience', '',
  'JAS', 'OND', 'JFM', 'AMJ', 'FY'];

export const data = {
  data1: {
    headingData: 'What are the <span style=color:#0070C0>Brandform-Channel combinations</span> that account for the ' +
      '<span style=color:#0070C0>bulk of MSASD</span> spends? What are the growth projections for these combinations?',
    subHeadingData: [
      'a) Check the NOS, Spend and Gross Margin salience of these combinations',
      'b) Asess whether some of the spends should be rebalanced across brandforms',
    ],
    tableData: {
      headings: ['Rank', ...commonColumns],
      rows: [],
    },
  },

  data2: {
    headingData: 'Are there any Brandform-Channel combinations with <span style=color:#0070C0><10% Gross Margin?</span>',
    subHeadingData: [
      'a) If GM is -ve, immediately modify the plans.',
      'b) For plans which lead to low gross margins, evaluate if the slabs/communication needs to be changed',
    ],
    tableData: {
      headings: ['Rank', ...commonColumns],
      rows: [],
    },
  },
};

export const headingsLabels = {
  Rank: 'Spend Rank',
  Brandform: 'Brandform',
  Channel: 'Channel',
  NOS_Million: 'NOS <br/>$M',
  MSASD_RLP: 'MSASD <br/>% RLP',
  GM_NOS: 'GM <br/>% NOS',
  NOS_Salience: 'NOS<br/>Salience',
  MSASD_Salience: 'MSASD<br/>Salience',
  Delta: 'Delta',
  GM_Salience: 'GM <br/>Salience',

  JAS: 'JAS <br/>IYA',
  OND: 'OND <br/>IYA',
  JFM: 'JFM <br/>IYA',
  AMJ: 'AMJ <br/>IYA',
  FY: 'FY <br/>IYA',
};

export const PercentageColumns = ['MSASD_RLP', 'GM_NOS', '', 'NOS_Salience', 'MSASD_Salience', 'Delta', 'GM_Salience'];
export const NumberColumns = ['NOS_Million', 'JAS', 'OND', 'JFM', 'AMJ', 'FY']
export const FixedWidthColumns = ['JAS', 'OND', 'JFM', 'AMJ', 'FY']
