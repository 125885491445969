
import { hot } from 'react-hot-loader/root'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
    Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid, Slide,
    Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentDialog = (props) => {
    let { isPaymentOpen, handlePaymentClose, classes, employerCase, billGroupStringData, resData,
        total, bankAccounts, setBankAccount, bankAccount, setPaymentType, paymentType, paymentDate, data,
        paymentSuccess, payBill
    } = props


    const numFormat = (value) => {
        return '$ ' + (value ? value : '0').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return (<>

        <Dialog
            open={isPaymentOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handlePaymentClose}
            maxWidth={"lg"}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#000" }}>{"Payment"}</DialogTitle>
            <DialogContent className={classes.paymentDialog}>
                <DialogContentText id="alert-dialog-slide-description" style={{ color: '#000' }}>
                    <h3 style={{ textAlign: 'center', color: '#000' }}>{"Payment Receipt"}</h3>
                    <div style={{ textAlign: "end" }}><b>{"Date: #" + moment(new Date()).format("MM/DD/YYYY")}</b></div>
                    <Grid container spacing={3} style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Grid item xs={6} lg={6} style={{ textAlign: 'left', color: '#000' }}>
                            <div><b>{"Vendor: " + props.entity.name}</b></div>
                            <div><b>{"Billing Period: " + moment().subtract(1, 'months').format('MMM YYYY')}</b></div>
                            <div><b>{"Policy #: " + employerCase?.ControlNumber}</b></div>
                            <div><b>{"Billing Group: "}</b>{billGroupStringData}</div>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <div><b>{`Invoice #: ${(data?.InvoiceNumber || data?._id)}`}</b></div>
                            <div><b>{"Remit to: Dsilo Inc"}</b></div>
                            <div><b>{"Billing Rep: "
                                + (resData?.billingRepName ? resData?.billingRepName : employerCase?.CaseProcessor)}</b></div>

                        </Grid>
                    </Grid>
                    <div>
                        <table style={{ border: "2px solid #000000", width: "100%" }}>
                            <tr >
                                <th style={{ backgroundColor: "#000", color: "#FFF", padding: '10px', fontWeight: '900', textAlign: "center" }}>{"Policy #"}</th>
                                <th style={{ backgroundColor: "#000", color: "#FFF", padding: '10px', textAlign: "end" }}>{"Total Amount"}</th>
                            </tr>
                            <tr >
                                <td style={{ borderBottom: "2px solid #000", borderRight: "2px solid #000", padding: '10px', fontWeight: '900', textAlign: "center" }}>{employerCase?.ControlNumber}</td>
                                <td style={{ borderBottom: "2px solid #000", padding: '10px', fontWeight: '900', textAlign: 'right' }}>{numFormat(total)}</td>
                            </tr>
                            <tr>
                                <td style={{ borderBottom: "2px solid #000", borderRight: "1px solid #000", padding: '10px', fontWeight: '900', textAlign: 'right' }}>
                                    {"Past due:"}</td>
                                <td style={{ borderBottom: "2px solid #000", padding: '10px', fontWeight: '900', textAlign: 'right' }}>{"$ 0.00"}</td>
                            </tr>
                            <tr>
                                <td style={{ borderBottom: "2px solid #000", borderRight: "2px solid #000", padding: '10px', fontWeight: '900', textAlign: 'right' }}>{"Fees:"}</td>
                                <td style={{ borderBottom: "2px solid #000", padding: '10px', fontWeight: '900', textAlign: 'right' }}>{"$ 0.00"}</td>
                            </tr>
                            <tr>
                                <td style={{ borderBottom: "0px solid #000", padding: '10px', fontWeight: '900', textAlign: 'right' }}>{"Total Amount"}</td>
                                <td style={{ borderBottom: "0px solid #000", padding: '10px', fontWeight: '900', textAlign: 'right' }} >{numFormat(total)}</td>
                            </tr>
                        </table>
                    </div>
                    {!paymentSuccess ?
                        <>
                            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                {
                                    console.log("bankAccounts", bankAccounts)
                                }
                                <span><b>{"Bank Account:"}</b></span>
                                <select native defaultValue="" name="Bank account selection" variant="outlined" style={{
                                    color: "white", marginLeft: "157px", width: "", background: "#000",
                                    height: '50px'
                                }} onChange={(e) => { console.log("e", e); setBankAccount(e.target.value) }} value={bankAccount}>
                                    {
                                        bankAccounts && bankAccounts.map((item) => {
                                            return <option value={item.BankAccountName} style={{ backgroundColor: "#000" }}>{item.BankAccountName}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div style={{ display: "flex" }}>
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" defaultValue="end" onChange={(e) => setPaymentType(e.target.value)} value={paymentType}>
                                        <FormControlLabel value="PayNow" control={<Radio color="primary" size="small" />} label="Pay Now" style={{ color: "#000" }} />
                                        <FormControlLabel value="PayLater" control={<Radio color="primary" size="small" />} label="Pay Later" style={{ color: "#000" }} />
                                    </RadioGroup>
                                </FormControl>
                                {
                                    paymentType === "PayLater" && <FormControl><TextField
                                        onChange={(e) => onPaymentDateChange(e)}
                                        type="date"
                                        variant={"standard"}
                                        value={paymentDate}
                                        style={{ border: '1px solid' }}
                                    /></FormControl>
                                }
                            </div>
                        </> : <>
                            <div>
                                <img src={require("images/tick_green.png")} style={{ width: "24px", height: "24px" }} />

                                <span>{"Payment has been posted successfully. Payment # " + data.PaymentId}</span>
                            </div>
                        </>}

                </DialogContentText>
            </DialogContent>
            <DialogActions>

                {!paymentSuccess ?
                    <Button onClick={() => payBill()} color="primary" style={{
                        color: "#ffffff",
                        backgroundColor: "#2180ac", borderColor: "#1f79a3",
                        width: '150px',
                        height: '50px',
                        fontWeight: '900',
                        fontSize: '16px'
                    }}>{"Pay"}
                    </Button> :
                    <>
                        <Button onClick={() => null} color="primary" style={{
                            color: "#ffffff",
                            backgroundColor: "#2180ac", borderColor: "#1f79a3"
                        }} onClick={() => handlePaymentClose()}>
                            {"Download a copy"}
                        </Button>
                        <Button onClick={() => null} color="primary" style={{
                            color: "#ffffff",
                            backgroundColor: "#2180ac", borderColor: "#1f79a3"
                        }} onClick={() => handlePaymentClose()}>
                            {"Print"}
                        </Button>
                        <Button onClick={() => null} color="primary" style={{
                            color: "#ffffff",
                            backgroundColor: "#2180ac", borderColor: "#1f79a3"
                        }} onClick={() => handlePaymentClose()}>
                            {"Close"}
                        </Button>
                    </>
                }
            </DialogActions>
        </Dialog>

    </>)
}

const mapStateToProps = state => {
    return {
        user: state.user,
        pageLoading: state.pages.loading,
        member: state.appConfig.member,
        memberLoading: state.appConfig.memberLoading,
        entity: state.appConfig.entity ? state.appConfig.entity : {}
    };
};
export default hot(withRouter(
    connect(mapStateToProps, null)(PaymentDialog)));