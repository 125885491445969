/**
 * @module Sagas/App
 * @desc App
 */
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { AppService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';

/**
 * Application
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* fetchOrg() {
    try {
        const apps = yield fetchAsync(AppService.getOrg);
        yield put({type: ActionTypes.LOAD_ORG_SUCCESS, payload: apps});
    } catch (e) {
        yield put({type: ActionTypes.LOAD_ORG_ERROR, error: e.message});
    }
}

export function* watchOrgSaga() {
    yield takeEvery(ActionTypes.LOAD_ORG_LOADING, fetchOrg);
}

export default watchOrgSaga;
