import React, { useState, useEffect } from 'react';
import { Grid, Paper, makeStyles, Divider } from '@material-ui/core';
import Highcharts from 'highcharts';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const useStyles = makeStyles(theme => ({
    paperTable: {
        textAlign: 'left',
        padding: '5px 15px',
        // backgroundColor: 'transparent',
        borderRadius: 0,
        margin: '0 10px 0 10px',
        height: '100%'
    },
    anchor: {
        fontSize: '14px !important',
        textDecoration: 'underline !important',
    },
    panel: {
        marginBottom: '20px',
        backgroundColor: '#222222',
        border: '1px solid transparent',
        // borderRadius: '4px',
        boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)',
    },
    panelDefault: {
        borderColor: '#282828',
    },
    panelHeading: {
        color: '#888888',
        // backgroundColor: '#3c3c3c',
        borderColor: '#282828',
        textAlign: 'left',
        padding: '13px',
        borderBottom: '4px solid #000',
        marginBottom: '13px',
        '@media(max-width:1366px)': {
            padding: 5,
            borderBottom: '1px solid #000',
            marginBottom: 5,
        },
    },
    headingStyle: {
        fontSize: '25px',
        '@media(max-width:1366px)': {
            fontSize: 20,
        },
        fontWeight: 'bold',
        color: '#000',
        // display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
    },
    svgIcon: {
        marginRight: 10,
        fontSize: 21,
        '@media(max-width:1366px)': {
            fontSize: 18,
        },
    },
    checkIcon: {
        color: '#fff',
        background: '#008000',
        borderRadius: '19px',
        display: 'flex'
    },
    innerHeading: {
        textAlign: 'center'
    },
    innerBody: {
        paddingTop: '35px'
    },
    innerValue: {
        paddingTop: '35px',
        fontSize: '45px',
    },
    innerDivider: {
        paddingTop: '15px',
    },
    dividerColor: {
        backgroundColor: '#4472c4',
        color: '#4472c4'
    }
}));

let data = {
    chart: {
        type: 'column',
        renderTo: 'budgetline'
    },
    title: {
        text: 'Budget by Brands'
    },
    xAxis: {
        categories: [
            'JAS',
            'OND',
            'JFM',
            'AMJ'
        ],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Budget ($ M)'
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [{
        name: 'Ariel',
        data: [49.9, 71.5, 106.4, 129.2]

    }, {
        name: 'Pantene',
        data: [83.6, 78.8, 98.5, 93.4]

    }, {
        name: 'Tide',
        data: [48.9, 38.8, 39.3, 41.4]

    }, {
        name: 'Vicks',
        data: [42.4, 33.2, 34.5, 39.7]

    }],
    credits: {
        enabled: false
    }
}

function BudgetBar(props) {
    const classes = useStyles();

    useEffect(() => {
        Highcharts.chart(data)
    }, [])

    return (<>
        <Paper className={classes.paperTable}>
            <div className={classes.budgetPie}>
                <div id={"budgetline"} ></div>
            </div>
        </Paper>
    </>);
}

export default BudgetBar;
