import { Button, Grid, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import classNames from 'classnames';
import FileDownload from 'js-file-download';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getFileNameWithTimeStamp, getMonth } from 'utility/utils';
import { getImar2Reports } from '../../../../../../store/actions';
import Header from '../../../SpendPlan/Header';
import { getCurrentFFForPayload, getFiscalYearFormat } from "../../../spendplanUtils";
import Imar1Table from './imar2Table';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  imar2table: {
    '& .MuiTableContainer-root': {
      height: 'fit-content',
    },
  },
  donwloadBtn: {
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    marginRight: 15,
  },
}));

const Imar1Report = (props) => {
  const classes = useStyles();
  const { brand, scenario } = props;

  const [_brand, setBrand] = useState(brand);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [scenario]);

  useEffect(() => {
    if (_brand!== brand) {
      setBrand(brand)
      fetchData();
    }
  }, [brand]);

  const fetchData = () => {
    let { BrandName, FinancialYear, CurrentFF } = brand;
    let m = getMonth(CurrentFF, 'monthNumber');
    let currentMonth = getCurrentFFForPayload(m)
    const modalData = props.modalData;
    let version = false;
    if (modalData && modalData.brand && modalData.month && modalData.year) {
      BrandName = modalData.brand;
      FinancialYear = modalData.year;
      currentMonth = getCurrentFFForPayload(modalData.month);
      version = true
    }
      let data = {
        appId: props.match.params.appid,
        url: `/${BrandName}/${FinancialYear}/${currentMonth}/${scenario}`,
      };
      if (version) {
        data.url += '?version=true'
      }
      props.getImar2Reports(data);
  };

  const downloadTalkSheetHandler = () => {
    if (brand) {
      let m = getMonth(brand.CurrentFF, 'monthNumber');
      let _brandName = props.modalData ? props.modalData.brand : brand.BrandName;
      let _financialYear = props.modalData ? props.modalData.year : brand.FinancialYear;
      let _currentMonth = props.modalData ? getCurrentFFForPayload(props.modalData.month) : getCurrentFFForPayload(m);
      let uri = props?.scenario ? API_BASE_URL + `/imar/two/download/${_brandName}/${_financialYear}/${_currentMonth}/${props?.scenario}` : API_BASE_URL + `/imar/two/download/${_brandName}/${_financialYear}/${_currentMonth}`;
      let fyFF = getFiscalYearFormat(_financialYear) + '-' + moment().month(Number(_currentMonth)-1).format('MMM');

      if(props.modalData){
        uri+='?version=true'
      }
      axios({
        url: uri,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          appId: props.match.params.appid,
        },
      })
        .then((response) => {
          FileDownload(response.data, getFileNameWithTimeStamp('IMAR - MSASD Business Analysis'+ '-'+ brand.BrandName + '-'+ fyFF));
        })
        .catch((error) => {
          console.log(error)
        });
    }
  };

  return (
    <div className={classNames(classes.root, classes.imar2table)} style={{ paddingBottom: '15px' }}>

          <Grid container justify="space-between">
            <Grid item xs={9} sm={9}>
              <Header header={props.modalData?props.versionTitle:(`IMAR - MSASD Business Analysis - ${brand && brand.CurrentFF && brand.FinancialYear
                ? `FY ${getFiscalYearFormat(brand.FinancialYear)} ${getMonth(brand.CurrentFF, 'month')} FF`
                : 'FY 2021'}`)}/>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.donwloadBtn}
                size="medium"
                onClick={downloadTalkSheetHandler}
                startIcon={<GetAppIcon/>}
              > {'Download'} </Button>
            </Grid>
        </Grid>
      <Imar1Table brandData={brand} modalData={props.modalData} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    brand: state.tprReducer?.selectBrand,
    scenario: state.tprReducer?.selectScenario
  };
};

const mapDispatchToProps = {
  getImar2Reports,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Imar1Report)),);
