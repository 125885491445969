
import { hot } from 'react-hot-loader/root'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const AuditTrail = (props) => {
	let { supportingDoc,
		tableRef } = props

	return (<>
		<div className="row" style={{ marginTop: "50px", marginBottom: "100px" }}>
			<div className="col-lg-1"></div>
			<div className="col-lg-10" style={{ xborder: "1px solid #000" }}>
				<div className={isIBC ? 'ibc-autid-trail-heading' : 'autid-trail-heading'}>
					<h5>{"Audit Trail"}</h5>
				</div>

				<table className={`table ${isIBC ? 'ibc-autid-table-dark' : 'table-dark'}`} ref={tableRef}>
					<tr>
						<th>{"Date Time"}</th>
						<th>{"Action"}</th>
						<th className="last">{"User"}</th>
					</tr>
					{supportingDoc?.actions?.map(a => {
						let r = []
						r.push(<tr>
							<td className="second">{moment(a.actionDate).format("MM/DD/YYYY HH:MM:SS")}</td>
							<td className="second"> {a.paymentId
								? <Link style={{
									color: 'black',
									fontWeight: '900 !important',
								}}
									to={`/apps/${props.match.params.appid}/form/Payment/${a.paymentId}`}>
									<span style={{
										color: 'black',
										fontWeight: '900 !important'
									}}>{a.action}</span>
								</Link>
								: a.action} </td>
							<td className="second">{a.user}</td>
						</tr>)
						return r
					})}
				</table>
			</div>
		</div>
	</>)
}

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(
	connect(mapStateToProps, null)(AuditTrail)));