import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getReconReviewData,
  removeReconColumn,
  updateReconReviewLabel,
  clearError,
} from '../../../../../../store/actions';
import { getMonth } from '../../../../../../utility/utils';
import moment from 'moment';
import classNames from 'classnames';
import ConfirmDialog from '../../../SpendPlan/ConfirmDialog';
import { getCurrentFFForPayload } from "../../../spendplanUtils";
import { convertToNumber } from '../../../PlanningPages/planningHelperFunctions'
import Loader from '../../../../../Loader';
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  actionIcon: {
    fontSize: '12px !important',
    cursor: 'pointer',
  },
  tableBodyLoading: {
    display: 'flex',
    height: 'calc(100vh - 200px)',
    justifyContent: 'center',
  },
  input: {
    textAlign:'center',
    outline: 'none',
    fontWeight: 'bold',
    resize: 'none',
  },
  rowBold: {
    backgroundColor: '#c0d4ec !important',
    '& span': {
      fontWeight: 'bold !important',
    },
  },
  darkBG: {
    backgroundColor: '#0874c4 !important',
    '& span': {
      fontWeight: 'bold !important',
      color: '#fff',
    },
  },
  stickyCell: {
    position: 'sticky',
    left: 0,
    zIndex: 3,
  },
  alignLeft: {
    textAlign: 'left !important',
  },
  tableHead: {
    "& th": {
      backgroundColor: '#3CA7FD',
      textAlignLast: 'center',
      textAlign:'center',
      color: '#fff',
      height: 90,
      padding: '12px !important',
      "& span": {
        display: 'block',
        marginTop: -20,
        fontWeight: 'bold',
      },
    },
      "& th:not(.adjustBorder)": {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
      },
      "& th.adjustBorder": {
        paddingRight: 0,
        "& span": {
          paddingRight: 16,
          borderRight: '1px solid rgba(224, 224, 224, 1)',
          display: 'flex',
          height: 40,
          alignItems: 'center',
        }
      },
    },
  table: {
    "&:focus": {
      border: "0"
    },
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
  },
  tableBody: {
    "& td": {
      whiteSpace: 'nowrap',
      padding: "12px 8px 12px 8px !important",
      "@media(max-width:1366px)": {
        padding: "9px !important"
      },
      borderRight: "1px solid rgba(224, 224, 224, 1)",
      borderBottom: "0",
      textAlign: "right",
    },
    "& td.noBorder": {
      border: "0"
    },
    "& p": {
      display: "flex"
    },
  },
  tableContainer: {
    maxWidth: "94vw",
    "@media(max-width:1700px)": {
      maxWidth: "93.5vw",
    },
    borderRadius: "8px",
    margin: '20px 5px 5px',
    maxHeight: 'calc(100vh - 200px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.8em',
      height: '0.8em'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#cdcdcd',
      outline: '1px solid slategrey',
    },
  },
}));

const IconTooltip = withStyles({
  tooltip: {
    fontSize: 12,
  },
})(Tooltip);

const ReconReviewTable = (props) => {

  const classes = useStyles();
  const { brand, scenario } = props;
  const [edit, setEdit] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [deleteConfirmState, setDeleteConfirm] = useState({
    isOpen: false,
    deleteMessage: 'Delete this Recon Baseline?',
    id: null,
  });
  const { reconData, loading, getNewData, data, error } = props.reconReviewData;
  const isBold = [13, 25, 36, 14];
  const DarkBGRows = [];
  const percentRange = ['26-36'];

  useEffect(() => {
    fetchData();
  }, [brand, scenario]);

  useEffect(() => {
    if (getNewData) {
      fetchData();
    }
  }, [getNewData]);

  const fetchData = () => {
    let { BrandName, FinancialYear, CurrentFF } = brand;
    let currentMonth = getMonth(CurrentFF, 'monthNumber')
    .toString();
    if (currentMonth.length === 1) {
      currentMonth = '0' + currentMonth;
    }
    const modalData = props.modalData;
    if (modalData && modalData.brand && modalData.month && modalData.year) {
      BrandName = modalData.brand;
      FinancialYear = modalData.year;
      currentMonth = getCurrentFFForPayload(modalData.month);
    } 
  
    if (currentMonth) {
      let data = {
        appId: props.match.params.appid,
        url: `/${BrandName}/${FinancialYear}/${currentMonth}/${scenario}`,
      };
      props.getReconReviewData(data);
    }
  };

  useEffect(() => {
    if (reconData && reconData.length) {
      mapReconData();
    } else {
      setColumns([])
      setRows([])
    }
  }, [reconData]);

  const getColumns = (data) => {
    let _columns = [{
      label: 'Metrics',
      createdDate: null,
      _id: null,
    }];
    data.forEach((i, index) => {
        _columns.push({
          comment: i.comment,
          edit: false,
          label: i.label,
          order: i.order,
          createdDate: i.createdDate ? i.createdDate : null,
          _id: i._id ? i._id : null,
        });
    });
    return _columns;
  };

  const getRows = (data) => {
    if (data.length) {
      let arrOfArray = data.map(i => i.data);
      let transpose = arrOfArray[0].map((_, colIndex) => arrOfArray.map(row => row[colIndex]));
      return transpose;
    }
    return [];
  };

  const mapReconData = () => {
    let _reconData = JSON.parse(JSON.stringify(reconData));
    let _columns = getColumns(_reconData);

    const _rows = getRows(_reconData);
    setColumns(_columns);
    setRows(_rows);
  };

  const isPercent = (x) => {
    let percent = false;
    if (x) {
      percent = percentRange.some(range => {
        let minMax = range.split('-');
        if (x >= minMax[0] && x <= minMax[1])
          return true;
      });
    }
    return percent;
  };

  const onAction = (actionType, column, value) => {
    if (actionType === 'edit') {
      let index = columns.findIndex(i => i._id === column._id);
      let __columns = [...columns];
      __columns[index] = {
        ...column,
        edit: value,
      };
      if (!value) {
        let { BrandName, FinancialYear, CurrentFF } = brand;
        let currentMonth = getMonth(CurrentFF, 'monthNumber')
          .toString();
        if (currentMonth.length === 1) {
          currentMonth = '0' + currentMonth;
        }
        let data = {
          currentFF: currentMonth,
          fiscalYear: FinancialYear + "",
          label: column.label.trim(),
          brand: BrandName,
          id: column._id
        };
        props.updateReconReviewLabel({ appId: props.match.params.appid, data });
      }
      setColumns(__columns);
    }
    if (actionType === 'change') {
      let index = columns.findIndex(i => i._id === column._id);
      let __columns = [...columns];
      __columns[index] = {
        ...column,
        label: value,
      };
      setColumns(__columns);
    }
  };

  const onDeleteColumn = (id) => {
    setDeleteConfirm({ ...deleteConfirmState, isOpen: true, id: id });
  };

  const onDeleteResponse = (isDelete) => {
    if (isDelete) {
      let { BrandName, FinancialYear, CurrentFF } = brand;
      let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
      m = m.length === 1 ? '0' + m : m;
      let commonObj= {
        currentFF: m,
        brand: BrandName,
        fiscalYear: FinancialYear,
      }
      let data = {
        appId: props.match.params.appid,
        id: deleteConfirmState.id,
        commonObj,
      };
      props.removeReconColumn(data);
    }
    setDeleteConfirm({ ...deleteConfirmState, isOpen: false, id: null });
  };

  const renderNoDataText = () => {
    let textToShow = 'No Data Found';
    return (
          <h4 style={{ color: '#000', textAlign: 'center'}}>{textToShow}</h4>
    );
  };


  return (
    <>
      {
        loading ? <div className={classes.tableBodyLoading}><Loader /></div> :
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} aria-label="simple table"
                   style={{ width: reconData.length?`${columns.length * 9}vw`:'100%' }} stickyHeader>
              <TableHead className={ classes.tableHead}>
                <TableRow>
                  {
                    columns.map((column, index) => {
                        if (column.title !== 'ROW') {
                          if (index < 2) {
                            return (
                              <TableCell key={index} style={index === 0 ? { left: 0, zIndex: 4,width:120 } : {width:120}}>
                                <span>{column.label}</span>
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={index}>
                                <textarea className={classes.input} autoFocus disabled={!column.edit} value={column.label}
                                       onBlur={() => onAction('edit', column, false)}
                                       onChange={(e) => onAction('change', column, e.target.value)}
                                       style={{
                                         width: `${column.label.length+1}ch`,
                                         maxWidth: '20ch',
                                         minWidth: 100,
                                         color: column.edit?'#000':'#fff',
                                         background: column.edit?'#fff':'transparent',
                                         border: column.edit ? '1px solid #fff' : 'none',
                                         marginLeft: column.edit ? -1 : 0,
                                         marginRight: column.edit ? -1 : 0,
                                       }}/>
                                <div style={{
                                  display: 'flex',
                                  marginTop: 10,
                                  marginLeft: 5,
                                  marginRight: 5,
                                  justifyContent: 'space-between',
                                }}>
                                  {!props.modalData && <div>
                                    <IconTooltip title='Delete this baseline column'>
                                      <RemoveCircleOutlinedIcon className={classes.actionIcon}
                                                                onClick={() => onDeleteColumn(column._id)}/>
                                    </IconTooltip>

                                    <IconTooltip title='Rename baseline label'>
                                      <EditOutlinedIcon className={classes.actionIcon}
                                                        onClick={() => onAction('edit', column, true)}/>
                                    </IconTooltip>
                                  </div>}
                                  <IconTooltip style={{width: 300}} title={
                                    <>
                                  {`Baseline created on ${moment(column.createdDate)
                                    .format('DD MMMM YYYY, hh:mm a')}`}
                                    {column.comment  && <>
                                      <hr/>
                                    {`${column.comment}`}
                                    </>}
                                   </>}>
                                    <InfoOutlinedIcon className={classes.actionIcon}/>
                                  </IconTooltip>
                                </div>
                              </TableCell>
                            );
                          }
                        }
                      },
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {
                  rows && rows.map((i, index) => {
                      if (i.length > 0) {
                        let isBOLD = isBold.includes(i[0].Row);
                        let isDarkBG = DarkBGRows.includes(i[0].Row);
                        let ispercent = isPercent(i[0].Row);
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              className={classNames(classes.alignLeft, classes.stickyCell, isBOLD ? classes.rowBold : isDarkBG ? classes.darkBG : '',)}
                              style={{
                                backgroundColor: index % 2 === 0 ? '#F5F5F5' : '#fff',
                              }}>
                              <span>{i[0].Row ===25?(i[0].Metrics + ' $M') :i[0].Metrics}</span>
                            </StyledTableCell>
                            {
                              i.map((j, index1) => {
                                  return (
                                    <StyledTableCell key={index1}
                                                     className={classNames(isBOLD ? classes.rowBold : isDarkBG ? classes.darkBG : '')}>
                                          <span title={(j.value + (ispercent?'%':''))}> {convertToNumber(j.value, ispercent?2:1).toLocaleString()
                                            + (ispercent?'%':'')}</span>
                                    </StyledTableCell>
                                  );
                                },
                              )
                            }
                          </StyledTableRow>
                        );
                      }
                    },
                  )
                }
              </TableBody>
            </Table>
            { !reconData.length?renderNoDataText():null }
          </TableContainer>
      }
      <ConfirmDialog open={deleteConfirmState.isOpen} handleClose={onDeleteResponse}
                     alertMessageText={deleteConfirmState.deleteMessage} classes={classes} confirm/>
{ error && <ConfirmDialog open={ error !==null } handleClose={props.clearError} alertMessageText={{title: 'Error Loading Page',
             p2: 'There was an error loading this content. Please reload the page or try again later.', p4: error}}  classes={classes} /> }
    </>);
};

const mapStateToProps = state => {
  return {
    reconReviewData: state.ReconReviewReducer,
  };
};

const mapDispatchToProps = {
  getReconReviewData,
  removeReconColumn,
  updateReconReviewLabel,
  clearError,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReconReviewTable)));
