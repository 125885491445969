import Highcharts from 'highcharts';
import {
	calculateMetrix,
	calculateMetrixFromArray
} from './../component.module'

export function generateMixedData(data, series) {
	if (Array.isArray(data.datasets)) {
		// let { data } = element.chartData;
		let d = data.datasets.map((ds, i) => {
			return {
				name: ds.label,
				data: ds.data
			}
		})

		let bar = data.datasets.map(d => {
			return {
				...d,
				type: 'column',
			}
		})

		let cal = calculateMetrixFromArray(data.datasets, 'average')
		let line = {
			type: 'spline',
			name: 'Average',
			data: cal,
			marker: {
				lineWidth: 2,
				lineColor: Highcharts.getOptions().colors[3],
				fillColor: 'white'
			}
		}

		let calP = calculateMetrixFromArray(data.datasets, 'average')
		let pieData = calP.map((d, i) => {
			return {
				name: data.labels[i],
				y: d,
				color: Highcharts.getOptions().colors[i]
			}
		})

		let pie = {
			type: 'pie',
			name: 'No: of Mismatch',
			data: pieData,
			center: [100, 80],
			size: 100,
			showInLegend: false,
			dataLabels: {
				enabled: false
			}
		}

		return {
			xAxis: {
				categories: data.labels
			},
			labels: {
				items: [{
					html: 'Issue types',
					style: {
						left: '50px',
						top: '18px',
						color: (Highcharts.theme && Highcharts.theme.textColor) || 'black'
					}
				}]
			},
			series: [...bar, line, pie]
		}
	} else {
		return data;
	}
}

export function getDefaultMixedChart(renderTo) {
	return {
		xAxis: {
			categories: ['Qwerty Distributors Inc', 'Poiuy Manufactures Ltd', 'Vybzior Inc', 'Jutiet Manufacturer LLC', 'Kiopet Manufacturer LLC']
		},
		labels: {
			items: [{
				html: 'Issue types',
				style: {
					left: '50px',
					top: '18px',
					color: (Highcharts.theme && Highcharts.theme.textColor) || 'black'
				}
			}]
		},
		series: [{
			type: 'column',
			name: 'No/Wrong contracts',
			data: [3, 2, 1, 3, 4]
		}, {
			type: 'column',
			name: 'Quantity Mismatch',
			data: [2, 3, 5, 7, 6]
		}, {
			type: 'column',
			name: 'Tax mismatch',
			data: [4, 3, 3, 9, 0]
		}, {
			type: 'spline',
			name: 'Average',
			data: [3, 2.67, 3, 6.33, 3.33],
			marker: {
				lineWidth: 2,
				lineColor: Highcharts.getOptions().colors[3],
				fillColor: 'white'
			}
		}, {
			type: 'pie',
			name: 'No: of attacks',
			data: [{
				name: 'Financial Hacker',
				y: 13,
				color: Highcharts.getOptions().colors[0] // Jane's color
			}, {
				name: 'Internal Employee',
				y: 23,
				color: Highcharts.getOptions().colors[1] // John's color
			}, {
				name: 'Policitical Cyber-Warrior',
				y: 19,
				color: Highcharts.getOptions().colors[2] // Joe's color
			}],
			credits: {
				enabled: false
			},
			center: [100, 80],
			size: 100,
			showInLegend: false,
			dataLabels: {
				enabled: false
			}
		}]
	}
}

export function getMixedChartConfig(data, renderTo) {
	return {
		chart: {
			renderTo: renderTo
		},
		title: {
			text: ''
		},
		credits: {
			enabled: false
		},
		xAxis: data.xAxis,
		labels: data.labels,
		series: data.series,
		yAxis: data.yAxis,

	}
}