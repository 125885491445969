import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
	table: {
		background: '#424242',
		minWidth: 650,
		'& .MuiTableCell-root': {
			padding:'13px',
		},
		'& .MuiTableCell-root': {
			borderBottom: 'none',
		},
		'& .MuiTableCell-head': {
			color: '#fff',
		},
		'& .MuiTableCell-body': {
			'& input': {
				width: '90px',
				height: '30px',
				color: '#fff',
				background: '#222222',
				border: 'none',
				borderRadius: '0'
			},
		},
	},
});

function ResultTable(props) {
	const classes = useStyles();
	const { tableData, supportingDocument } = props;

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						{tableData &&
							tableData.headings.map((heading, index) => (
								<React.Fragment key={index}>
									<TableCell>{heading}</TableCell>
								</React.Fragment>
							))}
					</TableRow>
				</TableHead>
				<TableBody>
					{supportingDocument && supportingDocument.document &&
						supportingDocument.document.map((row, index) => (
							<TableRow key={index}>
								<TableCell component="th" scope="row">
									<input
										type="text"
										defaultValue={row.plan}
										onChange={(e) => props.onChange(e, index)}
										name="plan"
									// onChange={() => console.log(row.plan, ind)}
									/>
								</TableCell>
								<TableCell>
									<input
										type="text"
										defaultValue={row.ageband}
										onChange={(e) => props.onChange(e, index)}
										name="ageband"
									// onChange={() => console.log(row.plan, ind)}
									/>
								</TableCell>
								<TableCell>
									<input
										type="text"
										defaultValue={row.Coverage}
										onChange={(e) => props.onChange(e, index)}
										name="Coverage"
									// onChange={() => console.log(row.plan, ind)}
									/>
								</TableCell>
								<TableCell>
									<input
										type="text"
										defaultValue={row.Participants}
										onChange={(e) => props.onChange(e, index)}
										name="Participants"
									// onChange={() => console.log(row.plan, ind)}
									/>
								</TableCell>
								<TableCell>
									<input
										type="text"
										defaultValue={row.rate}
										width="10"
										onChange={(e) => props.onChange(e, index)}
										name="rate"
									// onChange={() => console.log(row.plan, ind)}
									/>
								</TableCell>
								<TableCell>
									<input
										type="text"
										defaultValue={row.Premium}
										onChange={(e) => props.onChange(e, index)}
										name="Premium"
									// onChange={() => console.log(row.plan, ind)}
									/>
								</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default ResultTable;
