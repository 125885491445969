import { ActionTypes } from 'constants/index';

const initialState = {
  loading: false,
  mrEcommSpendReconciliationData: null,
  commonReportData: null,
  error: null,
};

export default function MrEcommSpendReconciliationReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_MRECOMMSPENDRECONCILIATION_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GET_MRECOMMSPENDRECONCILIATION_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        mrEcommSpendReconciliationData: action.payload,
      };
    }
    case ActionTypes.GET_MRECOMMSPENDRECONCILIATION_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        mrEcommSpendReconciliationData: null,
      };
    }
    case ActionTypes.GET_COMMON_REPORT_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GET_COMMON_REPORT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        commonReportData: action.payload,
      };
    }
    case ActionTypes.GET_COMMON_REPORT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        commonReportData: null,
      };
    }
    case ActionTypes.CLEAR_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default: {
      return state;
    }

  }

}
