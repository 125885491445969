import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import { Table, TableContainer, TablePagination, TableCell, TableRow, TableHead, TableBody, Paper, Select, MenuItem, FormControl, makeStyles } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const useStyles = makeStyles((theme) => ({
	element: {
		backgroundColor: "#242323 !important",
		color: "white !important",
		borderTop: "none !important",
		textAlign: "left",
		borderBottom: "1px solid #eee !important",
		padding: '10px !important'
	},
	head_element: {
		backgroundColor: "#242323 !important",
		borderBottom: "1px solid #eee",
		color: "white !important",
		textAlign: "left",
		padding: '10px !important',
		fontWeight: 'bold'
	},

	checkbox: {
		width: "13px",
		height: "13px"
	},
	link: {
		padding: "5px",
		color: "#2b2b2b"
	},
	bread_crumb: {
		padding: "8px 15px",
		marginBottom: "0px",
		listStyle: "none",
		backgroundColor: "#222222",
		borderRadius: "4px"
	},
	anchor_tag: {
		color: "#2a9fd6 !important"
	},
	pagination: {
		'& .MuiTablePagination-root': {
			...(isIBC? {}
			:{ backgroundColor: "#242323 !important",color: "white !important",}),

		},
		'& .MuiSelect-icon': {
			...(isIBC? {color: "#000 !important"} :	{color: "white !important"}),
			top: '0px !important',
			right: "-5px !important"

		},
		'& .MuiIconButton-root.Mui-disabled': {
			...(isIBC? {color: "#000 !important"} :	{color: "white !important"}),
		}

	},
	tableTheme:{
		'& .MuiTableRow-head':{
			'& .MuiTableCell-head':{
				backgroundColor:'#fff !important',
				color: '#000 !important'
			}
		},
		'& .MuiTableCell-body ':{
			backgroundColor:'#fff !important',
			color: '#000 !important'
		}
	}
}));

const SupportingDocs = props => {
	const [state, setState] = useState({ docsArray: [] });
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [monthData, setMonthData] = useState({ value: moment().format("MM/YYYY"), name: moment().format("MMMM-YYYY") });
	const [pageCount, setPageCount] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [monthsList, setMonthsList] = React.useState([]);

	const getSupportingDocs = (setResponse) => {
		let conditions = [{
			"id": "230503f3",
			"selectedConditionDataElement": "BillingPeriod",
			"selectedConditionOperator": "matches",
			"value": monthData && monthData.value ? monthData.value : moment().format("MM/YYYY"),
			"selectedConditionClause": "and"
		}];
		console.log('props.member+', props.member);
		let isAll = false;
		props.member.selectedRoles.map(r => {
			isAll = r.label === "Case Processor" || r.label === "Billing Representative" || r.label === "Billing Rep"
		})
		const uri = API_BASE_URL + '/custom/supportingDocument/all/' + (isAll ? 'all' : props.member.entityId) + `?page=${page}&rowsPerPage=${rowsPerPage}` + "&conditions=" + JSON.stringify(conditions);

		// const uri = API_BASE_URL + "/dataconnect/table/" + props.page.pageLevelDataModel + "?type=list&&fields=id,BillingPeriod,period,administrator,billType,billMethod,reviewer,status,deatilsLink,reportLink,verifyLink&page=0&rowsPerPage=10" + "conditions=" + JSON.stringify(conditions);

		axios({
			url: uri,
			method: 'GET',
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
				// appId: "5f2ab15279813563bcaa3887"
			},
		}).then((response) => {
			console.log("data", response)
			setResponse(response.data.data);
			setPageCount(response.data.count)

		}).catch((error) => {
			setResponse({ "error": error });
			// console.log("error", error)
		})
	}

	const MONTHS = () => {
		const months = []
		const dateEnd = moment()
		const dateStart = moment().subtract(6, 'month')

		while (dateEnd.diff(dateStart, 'months') >= 0) {
			months.push({ text: dateStart.format('MMMM-YYYY'), value: dateStart.format('MM/YYYY') })
			dateStart.add(1, 'month')
		}
		return months.reverse()
	}

	useEffect(() => {
		setMonthsList(MONTHS())
		// getSupportingDocs((res) => {
		// 	console.log("res", res)
		// 	setState({ ...state, docsArray: res })
		// });
	}, [])

	useEffect(() => {
		if (monthsList && monthsList.length) {
			getSupportingDocs((res) => {
				console.log("res", res)
				setState({ ...state, docsArray: res, month: monthsList[0] })
			});
		}
	}, [monthsList, page, rowsPerPage])

	useEffect(() => {
		getSupportingDocs((res) => {
			setState({ ...state, docsArray: res })
		});
	}, [monthData])

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.docsArray.length - page * rowsPerPage);

	return (
		<div className='use-bootstrap ibc'>
			<div className="app-supporting-doc" style={{backgroundColor: isIBC ? '#f2f2f2' : '#231F20', marginTop: "5px" }}>
				{/* <div className="main" style={{ backgroundColor: "#231F20", padding: "10px" }}> */}
				<div className="main" style={{ backgroundColor: isIBC ? '#f2f2f2' : '#231F20', "minHeight": "calc(100vh - 170px)", padding: "10px" }}>
					{/* <ul className={classes.bread_crumb}>
						<li><a href="./main.html" style={{ color: "#FFF", textDecoration: "none" }}>Dashboard</a></li>
					</ul> */}
					{/* <div className="row m-bottom-10">
						<div className="col-lg-2">
							<FormControl variant="outlined">
								<span style={{ padding: "5px", fontSize: "1.2em", color: "#fff" }}>Select Month:</span>
								{monthsList && monthsList.length && <Select id="custom-select-bg" style={{ backgroundColor: "#404040", width: "300px" }} className="custom-select-bg" value={monthData && monthData.value} onChange={(event) => setMonthData(event.target)}>
									{monthsList && monthsList.length && monthsList.map((month, i) => <MenuItem key={i} name={month.text} value={month.value}>{month.text}</MenuItem>)}
								</Select>}
							</FormControl>
						</div>
					</div> */}
					<div className="row">
						<div className="col-lg-12">
							<div className="panel panel-default" style={isIBC?{border:'none'}:{}}>
								<div 
								style={{
									color: "#888888",...(isIBC?{}:{backgroundColor: "#3c3c3c"}) , borderColor: "#282828", padding: "5px", overflow: "hidden", height: "auto", position: "relative"
								}}
								className={isIBC ? 'ibc-panelHeading':"panel-heading"} 
								>
									<h4 style={{ float: "left", color: "#FFF", fontSize: "18px" }}>Summary Invoices </h4>
									<div style={{ float: "right" }}>
										{console.log('roles selectedRoles', props.member && props.member.selectedRoles[0].label === "Plan Administrator")}
										{props.member && props.member.selectedRoles[0].label === "Plan Administrator"
											&& <button className={`btn btn-primary m-right-15`} style={isIBC?{background:'#ff6503'}:{}} href="supportingdocument"
												onClick={() => props.history.push({ pathname: "/apps/" + props.match.params.appid + "/ibcSupportingDocument" })}>
												{"Create Summary Invoice"}
											</button>}
										{/* <button className="btn btn-primary">Download</button> */}
									</div>
								</div>
								<TableContainer component={Paper}>
									<Table style={{ backgroundColor: "#eee" }} className={isIBC?classes.tableTheme:""}>
										<TableHead>
											<TableRow style={{ backgroundColor: "#242323" }}>
												<TableCell className={classes.head_element}><input className={classes.checkbox} type="checkbox" /></TableCell>
												<TableCell className={classes.head_element}>Invoice Number</TableCell>
												<TableCell className={classes.head_element}>Period</TableCell>
												<TableCell className={classes.head_element}>Employer Name</TableCell>
												<TableCell className={classes.head_element}>Plan Administrator</TableCell>
												<TableCell className={classes.head_element}>Bill Type</TableCell>
												<TableCell className={classes.head_element}>Bill Group Name</TableCell>
												<TableCell className={classes.head_element}>Bill Method</TableCell>
												<TableCell className={classes.head_element}>Created Date</TableCell>
												<TableCell className={classes.head_element}>Created By</TableCell>
												<TableCell className={classes.head_element}>Billing Rep</TableCell>
												<TableCell className={classes.head_element}>Status</TableCell>
												<TableCell className={classes.head_element}>Actions</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{/* emptyRows > 0 &&  */}
											{state.docsArray.map((item) => {
												return <TableRow>
													<TableCell className={classes.element}><input className={classes.checkbox} type="checkbox" /></TableCell>
													<TableCell className={classes.element}>{item.InvoiceNumber}</TableCell>
													<TableCell className={classes.element}>{moment(item.BillingPeriod).format('MMM YYYY')}</TableCell>
													{/* <TableCell className={classes.element}>{props.member.firstName + " " + props.member.lastName}</TableCell> */}
													<TableCell className={classes.element}>{item.EmployerName}</TableCell>
													<TableCell className={classes.element}>{item.createdByName ? item.createdByName : item.createdBy}</TableCell>
													<TableCell className={classes.element}>{item.billType}</TableCell>
													<TableCell className={classes.element}>{item.billGroupName ? item.billGroupName : ''}</TableCell>
													<TableCell className={classes.element}>{"SAB"}</TableCell>
													<TableCell className={classes.element}>{moment(item.createdDateTime).format('MM/DD/YYYY')}</TableCell>
													<TableCell className={classes.element}>{item.createdByName ? item.createdByName : item.createdBy}</TableCell>
													<TableCell className={classes.element}>{item.billingRepName}</TableCell>
													<TableCell className={classes.element}>{item.status}</TableCell>
													<TableCell className={classes.element}>
														<span className={classes.link}>
															<Link to={`/apps/${props.match.params.appid}/ibcSupportingDocumentView/${item._id}`}
																className={isIBC? classes.anchor_tag : ""}>Report</Link>
														</span>
														{/* <span className={classes.link}><a href={item.detailsLink} className={classes.anchor_tag}>Details</a></span>
														<span className={classes.link}><a href={item.reportLink} className={classes.anchor_tag}>Report</a></span>
														<span className={classes.link}><a href={item.verifyLink} className={classes.anchor_tag}>Verify</a></span> */}
													</TableCell>
												</TableRow>
											})}
										</TableBody>
									</Table>
									<div className={classes.pagination}>
										<TablePagination
											rowsPerPageOptions={[5, 10, 15, 20, 50]}
											component="div"
											count={pageCount}
											rowsPerPage={rowsPerPage}
											page={page}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</div>
								</TableContainer>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	)

}

const mapStateToProps = state => {
	return {
		user: state.user,
		app: state.appConfig.app,
		member: state.appConfig.member,
	};
};

const mapDispatchToProps = {
};


export default hot(withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(SupportingDocs)),
);