import { Grid, Typography } from '@material-ui/core';
import React from 'react';

export const errorMsgs = {
  brandIssues: 'Brands not present in master database',
  skuIssues: 'SKUs not present in master database',
  accountIssues: 'Accounts not present in master database',
  dataValidationIssues: 'Data validation',
  duplicate: 'Duplicate issues',
  skuMasterIssues: 'SKU master issues',
  skuMappingIssues: 'Sku Mapping issues',
  brandFormIssues: 'Brandform not present in master database',
  skuTypeIssues: 'SKU Type not present in master database',
  tsBrandIssues: 'TS Brands not present in master database',
  tsSkuIssues: 'TS SKU not present in master database',
  subBrandformIssues: 'Sub Brandform not present in master database',
  productIdIssues: 'Product Id not present in master database',
  subBrandFormMappingIssue: 'Sub Brandform mapping not present in master database',
  monthIssues: 'Month not present in master database',
  numaricIssues: 'Numeric data issues',
}

const titles = {
  brandIssues: 'Brands issues',
  skuIssues: 'SKUs issues',
  brandFormIssues: 'Brandform issues',
  accountIssues: 'Accounts issues',
  dataValidationIssues: 'Data validation issues',
  duplicate: 'Duplicate Records',
  skuMasterIssues: 'SKU master Records',
  skuMappingIssues: 'Sku Mapping Records',
  skuTypeIssues: 'SKU Type issues',
  dateIssues: 'Date issues',
  tsBrandIssues: 'TS Brand issues',
  tsSkuIssues: 'TS SKU issues',
  subBrandformIssues: 'Sub Brandform issues',
  productIdIssues: 'Product Id issues',
  subBrandFormMappingIssue: 'Sub Brandform mapping issues',
  monthIssues: 'Month issues',
  typeIssues: 'Type Issues',
  ittIssues: 'ITT Issues',
  numaricIssues: 'Numeric data issues'
}

export const HandleErrors = (props) => {
  let { errorArr, type } = props;

  let message = errorMsgs[type];

  let obj = {};
  let newArray = [];
  if (type === 'ittIssues') {
    for (let i = 0; i < errorArr.length; i++) {
      let keys = Object.keys(errorArr[i]);
      for (let j = 0; j < keys.length; j++) {
        newArray.push({key: keys[j], value: errorArr[i][keys[j]]})
      }
    }
    
  } else if (!['skuMappingIssues', 'skuMasterIssues', 'duplicate'].includes(type)) {
    errorArr.map(e => {
      if (obj[e.message]) {
        let tempArr = obj[e.message];
        tempArr.push(e.row);
        obj[e.message] = tempArr;
      } else {
        obj[e.message] = [e.row];
      }
    });
  }
  else if (type === 'skuMappingIssues' || type === 'skuMasterIssues') {
    errorArr.map(e => {
      if (obj[e.message]) {
        let tempArr = obj[e.message];
        tempArr.push(e.row);
        obj[e.message] = tempArr;
      } else {
        obj[e.message] = [e.row];
      }
    });
    return (<>
      {
        Object.keys(obj).map(err => {
          message = `${err} (${obj[err].length} nos.):`
          return <>
            <Typography variant="h6" gutterBottom component="div"
              style={{ fontWeight: 700, marginTop: 15, color: '#000', textAlign: 'center', }}>{message}</Typography>
            <div style={{ color: '#000', textAlign: 'center', fontWeight: 700, fontSize: '16px', }}>{obj[err].join(', ')}</div>
          </>
        })
      }

    </>)
  } else if (type === 'duplicate') {
    let rejectedItemsWithDuplicate = errorArr.map(e => e.row)
    message = `These rows are Duplicate of other rows and are rejected (${rejectedItemsWithDuplicate.length} nos.):`
    return (<>
      <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: 700, marginTop: 15, color: '#000', textAlign: 'center', }}>{message}</Typography>
      <div style={{ color: '#000', textAlign: 'center', fontWeight: 700, fontSize: '16px', }}>{rejectedItemsWithDuplicate.join(', ')}</div>
    </>)
  }


  return (<>
    <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '5px 0' }}>
      {message}</h6>
    {type !== 'data' && <Grid container style={{ textAlign: 'center', fontSize: '16px', fontWeight: 700 }}>
      <Grid item xs={6} sm={6}>{titles[type] || type}</Grid>
      <Grid item xs={6} sm={6}>{type === 'ittIssues' ? 'Values' :'Row No.'}</Grid>
      <br />
      {React.Children.toArray(Object.keys(obj)
        .map(err => {
          return <>
            <Grid item xs={6} sm={6} style={{ fontWeight: 500 }}>{err}</Grid>
            <Grid item xs={6} sm={6} style={{ fontWeight: 500 }}>{obj[err].join(', ')}</Grid>
          </>;
        }))}
        {newArray && (newArray.length > 0) && newArray.map(err => {
          return <>
          <Grid item xs={6} sm={6} style={{ fontWeight: 500 }}>{err.key}</Grid>
          <Grid item xs={6} sm={6} style={{ fontWeight: 500 }}>{err.value}</Grid>
        </>;
      })}
    </Grid>}
  </>);
};
