import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import classNames from 'classnames';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import Header from '../../../spendplan/SpendPlan/Header';
import { URLs } from '../../ApiConstants';
import { useDownload } from '../downloadHook';
import SummarySheetTable from './summarySheetTable';

export const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  downloadButton: {
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    marginRight: 15,
  },
  circularBar: {
    color: 'rgb(25, 118, 210)',
    height: 20,
    width: 20,
  }
}));


const MRSummaryVolPlanning = props => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  useDownload(props.account, props.modalData, props.page, props.match.params.appid, URLs.getSummaryVolumeDataDownload, loading, setLoading)

  return (
    <div className={classNames(classes.root, classes.talkSheetTable)} style={{ paddingBottom: '5px' }}>
      <Grid container justify="space-between">
        <Grid item xs={9} sm={9}>
          {!props.modalData && <Header header={`Summary - Volume Planning`} />}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.downloadButton}
            size="medium"
            onClick={()=> setLoading(true)}
            startIcon={<GetAppIcon />}
          >
            {
              loading ? <CircularProgress className={classes.circularBar} size={24} /> : 'Download'
            }
          </Button>
        </Grid>
      </Grid>
      <div className='mt-10'>
        <SummarySheetTable modalData={props.modalData} />
      </div>
    </div>
  )
}

export default hot(withRouter(MRSummaryVolPlanning))
