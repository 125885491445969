import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { AppService } from 'modules/api/index';
import { border } from 'polished';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const legendOpts = {
    display: false,
    position: 'top',
    fullWidth: true,
    reverse: false,
    labels: {
        fontColor: 'rgb(255, 99, 65)'
    }
};

const opt = {
    maintainAspectRatio: false,
    responsive: false,
    legend: {
        position: 'left',
        labels: {
            boxWidth: 20
        }
    }
};

const PieChart = (props) => {
    const [data, setData] = useState({});
    const [legend, setLegend] = useState(legendOpts);

    const [options, setOptions] = useState(opt);

    const setDatafromApi = (res, data) => {
        let d = { ...data };
        let { chartData } = props; console.log('data', data, res);


        // let labels = [], dArray = [], label= '';
        if (chartData.selectedDataModel && chartData.selectedDataElement) {
            // let label = chartData.selectedDataElement[0].label
            // res.data.map((o) => {
            //     labels.push(o[chartData.selectedDataElement[0].value])
            //     dArray.push(o[chartData.selectedDataElement[1].value])
            // })
            d.labels = res.labels;
            d.datasets[0].data = res.data;
            d.datasets[0].label = res.label;
        } else {
            d.labels = res.data.labels;
            d.datasets[0].data = res.data.datasets[0].data;
            d.datasets[0].label = res.data.datasets[0].label;
        }
        setData(d);
    }

    const getApiDataFromDataUrl = (url, data) => {
        AppService.getApiDataFromDataUrl(url)
            .then(res => {
                setDatafromApi(res, data)
            })
    }

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    useEffect(() => {
        setLegend(props.legendOpts);
    }, [props.legendOpts]);

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    useEffect(() => {
        let { chartData } = props;
        if (chartData.selectedDataModel && chartData.selectedDataElement) {
            let targetURL = API_BASE_URL + "/dataconnect/chart/" + chartData.selectedDataModel + "?";
            const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
            // targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
            targetURL += chartData.selectedDataElement.reduce(reducer, '&measure=');
            targetURL += chartData.selectedDataLabels.reduce(reducer, '&label=');
            targetURL += '&metric='+ chartData.metric;
            if (props.queryString) {
                targetURL += '&' + props.queryString;
            }
            //labeltype=date&format=%Y-%m
            if (chartData.datelabeltype) {
                targetURL += '&labeltype=date'
                targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
            }

            if (chartData.conditions && chartData.conditions.length > 0) {
                let cond = JSON.stringify(chartData.conditions);
                targetURL += "&conditions=" + cond;
            }
            if (props.page && props.page.conditions && props.page.conditions.length > 0) {
                let cond = JSON.stringify(props.page.conditions);
                targetURL += "&pConditions=" + cond;
            }
            let encodedData = encodeURIComponent(targetURL)
            let _targetUrl = JSON.stringify(encodedData);
            getApiDataFromDataUrl(_targetUrl, props.data);
        } else if (props.dataUrl) {
            getApiDataFromDataUrl(props.dataUrl, props.data);
        } else {
            setData(props.data);
        }
    }, [])


    const d1 = (canvas) => {
        if (canvas && data.datasets && data.datasets[0]) {
            // TO DO - Make dynamic colors
            /* const ctx = canvas.getContext("2d")
            // const gradient = ctx.createLinearGradient(0, 0, 100, 0);
            var gradient = ctx.createLinearGradient(450, 0, 0, 0);
            gradient.addColorStop(0, "rgb(0, 99, 132)");
            gradient.addColorStop(1, "rgb(255, 99, 132)");
            var gradient1 = ctx.createLinearGradient(250, 0, 0, 0);
            gradient1.addColorStop(0, "rgb(0, 109, 2)");
            gradient1.addColorStop(1, "rgb(255, 109, 2)"); */
            let d2 = { ...data };
            /* d2.datasets[0].backgroundColor = gradient;
            d2.datasets[1].backgroundColor = gradient1; */
            const ctx = canvas.getContext("2d")
            var gradient1 = ctx.createLinearGradient(0, 0, 0, 400);
            var gradient2 = ctx.createLinearGradient(100, 0, 120, 400);
            var gradient3 = ctx.createLinearGradient(0, 200, 100, 400);
            var gradient4 = ctx.createLinearGradient(0, 0, 0, 400);
            gradient1.addColorStop(0, "rgb(0, 109, 2)");
            gradient1.addColorStop(1, "rgb(255, 109, 2)");
            gradient2.addColorStop(0, "rgb(0, 109, 2)");
            gradient2.addColorStop(1, "rgb(255, 109, 2)");
            gradient3.addColorStop(0, "rgb(0, 109, 2)");
            gradient3.addColorStop(1, "rgb(255, 109, 2)");
            gradient4.addColorStop(0, "rgb(0, 109, 2)");
            gradient4.addColorStop(1, "rgb(255, 109, 2)");
            // gradient2.addColorStop(0, "#3EDA71");
            // gradient2.addColorStop(1, "#00B24B");
            // gradient3.addColorStop(0, "#BEF2F2");
            // gradient3.addColorStop(1, "#6DD0D0");
            // gradient4.addColorStop(0, "#6A94B6");
            // gradient4.addColorStop(1, "#3F7197");
            console.log("d2", d2);
            d2.datasets[0].borderWidth=1
            d2.datasets[0].borderColor="#fff"
            d2.datasets[0].backgroundColor = [gradient1, gradient2, gradient3, gradient4],
            // d2.datasets[0].fillColor = [gradient1, gradient2, gradient3, gradient4],
                // d2.datasets[0].fillColor = gradient;
                console.log("d2", d2);
            // d
            return d2;
            // return {
            //     backgroundColor: gradient
            // }
        }
        return data;
    }

    return (
        <>
            {data && options ?
                <Pie
                    data={data}
                    options={{
                        maintainAspectRatio: false,
                    }}
                />
                : ''
            }
        </>
    )
};

export default PieChart;