import { ActionTypes } from 'constants/index';
export const uploadCappsConfiguration = (cappsData) => dispatch => {
    dispatch({ type: ActionTypes.UPLOAD_CAPPS_LOADING, payload: cappsData });
};

export const getCappConfiguration = (cappsData) => dispatch => {
    dispatch({ type: ActionTypes.GET_CAPP_LOADING, payload: cappsData });
};

export const filterCappConfiguration = (cappsData) => dispatch => {
    dispatch({ type: ActionTypes.FILTER_CAPP_LOADING, payload: cappsData });
};

export const ruleExcution = (rulesData) => dispatch => {
    console.log("fire", JSON.stringify(rulesData));
    dispatch({ type: ActionTypes.RULE_EXCUTER_LOADING, payload: rulesData });
};
