import { ActionTypes } from 'constants/index';

const initialState = {
    org: {},
    loading: false,
    error: ''
};

export default function appReducer(state = initialState, action) {
    
    switch (action.type) {
        case ActionTypes.LOAD_ORG_LOADING: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ActionTypes.LOAD_ORG_SUCCESS: {
            return {
                ...state,
                org: action.payload,
                loading: false
            }
        }
        case ActionTypes.LOAD_ORG_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
}