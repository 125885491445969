const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { URLs } from '../../../../components/customPages/mrplan/ApiConstants';
import { request } from '../../../client';

export class MRPlanService {
    static getMRData(data) {
        return request(API_BASE_URL + data.url, {
            method: 'GET',
            headers: {
                appId: data.appId
            }
        });
    }

    static getUploadXlList(data) {
        return request(API_BASE_URL + data.url, {
            method: 'GET',
            headers: {
                appId: data.appId
            }
        });
    }

    static deleteMRData(data) {
        return request(API_BASE_URL + data.url, {
            method: 'DELETE',
            payload: data.data,
            headers: {
                appId: data.appid
            },
        });
    }
    static saveMRData(data) {
        return request(API_BASE_URL + data.url, {
            method: 'POST',
            payload: data.payload,
            headers: {
                appId: data.appId
            },
        });
    }


    static updateMRData(data) {
        return request(API_BASE_URL + data.url, {
            method: 'PUT',
            payload: data.payload,
            headers: {
                appId: data.appId
            },
        });
    }

    static freezeMRPlans(data) {
		const uri = API_BASE_URL + URLs.freezeMRPlan;
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: {
				appId: data.appid
			}
		});
	}

    static getMRDataPostReq(data) {
        return request(API_BASE_URL + data.url, {
            method: 'POST',
            payload: data.payload,
            headers: {
                appId: data.appId
            },
        });
    }

}
