import { Button, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
const styles = theme => ({
    textField: {
        "padding-right": "10px",
    },
    staticText: {
        "width": "45%",
        "top": "50%"
    }
    ,
    checkStyle: {
        "padding-left": '19px',
        width: '20%'
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    modal: {
        top: `30%`,
        left: `30%`,
        transform: `translate(-30%, -30%)`,
        position: 'absolute',

    }
});

class InnerCard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    generateRow(Columns, outerIndex) {
        return Columns.map((item, index) => {
            return (
                <Grid container>
                    <Grid item xs={6} style={{ paddingTop: 10 }}>
                        <label>{item.name} : </label>
                        <label> {this.props.filledData[item.name]} </label>
                    </Grid>
                </Grid>
            )
        })
    }

    generateFormFromJson() {
        const Jsondata = this.props.design.rows;
        return Jsondata.map((row, outerIndex) => {
            const Columns = row.columns
            return (
                <React.Fragment key={outerIndex}>
                    {this.generateRow(Columns, outerIndex)}
                </React.Fragment>
            )
        })
    }

    render() {
        return (
            <div>
                <Grid container>
                    {this.generateFormFromJson()}
                </Grid>
            </div>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // clearDraggedElement: Actions.clearDraggedElement,
    }, dispatch);
}

const mapStateToProps = state => {
    return {
        selectedForm: state.form.selectedForm,
        submittedDataToBeEdited: state.form.submittedDataToBeEdited,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(InnerCard));
