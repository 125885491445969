import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { AppService } from 'modules/api/index';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LineChart = (props) => {
    const [d, setData] = useState(null);

    const [options, setOptions] = useState({
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
    });

    const setDatafromApi = (res, data) => {
        let d = { ...data };
        let { chartData } = props;

        let labels = [], dArray = [], label = '';
        if (chartData.selectedDataModel && chartData.selectedDataElement) {
            // d.labels = res.labels;
            // d.datasets = [d.datasets[0]]
            // d.datasets[0].data = res.data;
            // d.datasets[0].label = res.label;

            d.labels = res.labels;
            d.datasets = res.datasets.map((ds, i) => {
                if (!d.datasets)
                    d.datasets = []
                if (!d.datasets[i])
                    d.datasets[i] = {};
                return { ...d.datasets[i].data, ...ds };
            })
            //d.datasets.slice(res.label.length);
        } else {
            d.labels = res.labels;
            d.datasets = res.datasets;
        }
        setData(d);
    }

    const getApiDataFromDataUrl = (url, data) => {
        AppService.getApiDataFromDataUrl(url)
            .then(res => {
                setDatafromApi(res, data)
            })
    }

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    useEffect(() => {
        let { chartData } = props;
        if (chartData.selectedDataModel && chartData.selectedDataElement) {
            // let targetURL = API_BASE_URL + "/dataconnect/" + chartData.selectedDataModel;
            // const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
            // targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
            // if (props.queryString) {
            //     targetURL += '&' + props.queryString;
            // }
            let targetURL = API_BASE_URL + "/dataconnect/line/" + chartData.selectedDataModel + "?";
            const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
            // targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
            targetURL += chartData.selectedDataElement.reduce(reducer, '&measure=');
            targetURL += chartData.selectedDataLabels.reduce(reducer, '&label=');
            targetURL += '&metric='+ chartData.metric;
            if (props.queryString) {
                targetURL += '&' + props.queryString;
            }
            //labeltype=date&format=%Y-%m
            if (chartData.datelabeltype) {
                targetURL += '&labeltype=date'
                targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
            }

            if (chartData.conditions && chartData.conditions.length > 0) {
                let cond = JSON.stringify(chartData.conditions);
                targetURL += "&conditions=" + cond;
            }
            if (props.page && props.page.conditions && props.page.conditions.length > 0) {
                let cond = JSON.stringify(props.page.conditions);
                targetURL += "&pConditions=" + cond;
            }
          let encodedData = encodeURIComponent(targetURL)
          let _targetUrl = JSON.stringify(encodedData);
            getApiDataFromDataUrl(_targetUrl, props.data);
        } else if (props.dataUrl) {
            getApiDataFromDataUrl(props.dataUrl, props.data);
        } else {
            setData(props.data);
        }
    }, [])

    const d1 = (canvas) => {
        // TO DO - Make dynamic colors
        const ctx = canvas.getContext("2d")
        //const gradient = ctx.createLinearGradient(0, 0, 100, 0);
        var gradient = ctx && ctx.createLinearGradient(450, 0, 0, 0);
        gradient.addColorStop(0, "rgb(0, 99, 132)");
        gradient.addColorStop(1, "rgb(255, 99, 132)");
        var gradient1 = ctx && ctx.createLinearGradient(250, 0, 0, 0);
        gradient1.addColorStop(0, "rgb(0, 109, 2)");
        gradient1.addColorStop(1, "rgb(255, 109, 2)");
        let d2 = { ...d };
        //d2.datasets[0].backgroundColor = gradient;
        //d2.datasets[1].backgroundColor = gradient1;
        return d2;
        // return {
        //     backgroundColor: gradient
        // }
    }

    return (
        <div>
            {d ?
                <div>
                    <div>{d.title}</div>
                    <Line
                        data={d}
                        options={options}
                    />
                </div>
                : ''
            }
        </div>
    )
}
export default LineChart;