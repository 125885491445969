import { Button, MenuItem, Select } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMrData } from '../../../../store/actions';
import { getMonth, sortAlphabetically } from '../../../../utility/utils';
import ConfirmDialog from '../../spendplan/SpendPlan/ConfirmDialog';
import { useStyles } from '../../spendplan/SpendPlan/FilterWorksheet';
import { getFiscalYearFormat, MonthsNumberMapping } from '../../spendplan/spendplanUtils';
import Upload from './uploadSheetable';
import { isNotSuperAdmin } from '../../spendplan/spendplanUtils'

const FilterType = {
    Account: 'account',
    Brand: 'brand',
    Brandform: 'brandform',
    SKU: 'sku',
    SkuType: 'skuType',
    Metric: 'metric',
    TSBrand: 'tsBrand',
    SubBrandForm: 'subBrandForm',
    ProductId: 'productId',
    KBD: 'kbd',
    UOM: 'uom',
    Metric: 'metric',
    EventName: 'eventName',
    LegalEntity: 'legalEntity',
    BuisnessUnit : 'buisnessUnit',
    Category: 'category',
    Type: 'type'
}

export const skuTypeValues = [
    {
        value: 'Promo',
        label: 'Promo',
    },
    {
        value: 'Non Promo',
        label: 'Non Promo',
    },
    {
        value: '',
        label: 'Undefined',
    },
]

const SheetHeader = props => {
    const classes = useStyles();
    const { handleFilterChange, edited } = props;
    const [brandform, setBrandform] = useState("")
    const [skuType, setSkuType] = useState(undefined)
    const [metric, setMetric] = useState('')
    const [sku, setSku] = useState('')
    const [brand, setBrand] = useState('');
    const [account, setAccount] = useState('');
    const [tsBrand, setTSBrand] = useState('');
    const [subBrandForm, setSubBrandForm] = useState('');
    const [productId, setProductId] = useState('');
    const [kbd, setKBD] = useState('');
    const [uom, setUOM] = useState('')
    const [eventName, setEventName] = useState('');
    const [legalEntity, setLegalEntity] = useState('');
    const [buisnessUnit, setBuisnessUnit] = useState('');
    const [category, setCategory] = useState('');
    const [type, setType] = useState('');
    const [successState, setSuccess] = useState({
        isOpen: false,
        message: ''
    })

    useEffect(() => {
        if (props.account) {
            getFilterData()
        }
    }, [props.account])
 
    let getFilterFields = {
        fyActuals: {
            account: ['brand', 'brandform', 'sku'],
            brand: ['brandform', 'sku'],
            brandform: ['sku'],
            sku: [],
        },
        skuMaster: {
            brand: ['brandform', 'sku'],
            brandform: ['sku'],
            sku: [],
        },
        ittMaster: {
            brand: ['brandform'],
            brandform: [],
        },
        skuMapping: {
            brand: ['brandform', 'sku'],
            brandform: ['sku'],
            sku: [],
        },
        fyActualspff: {
            brand: ['brandform', 'metric'],
            brandform: ['metric'],
            metric: [],
        },
        pyskulevel: {
            brand: ['brandform', 'sku'],
            brandform: ['sku'],
            sku: [],
        },
    }
    if (props.appName === 'omnichannel') {
        getFilterFields = {
            skuMaster: {
                legalEntity: ["category", "brand", "tsBrand", "brandform", "tsSku", "subBrandForm"],
                category: ["brand", "tsBrand", "brandform", "tsSku", "subBrandForm"],
                brand: ["tsBrand", "brandform", "tsSku", "subBrandForm"],
                tsBrand: ["brandform", "tsSku", "subBrandForm"],
                brandform: ["tsSku", "subBrandForm"],
                tsSku: ["subBrandForm"],
                subBrandForm: []
            },
            ittMaster: {
                brand: ['brandform', 'tsSku', 'type'],
                brandform: ['tsSku', 'type'],
                tsSku: ['type'],
                type: []
            },
            omniMasterClassification: {
                brand: ["tsBrand", "brandform", "tsSku", "subBrandForm", "kbd"],
                tsBrand: ["brandform", "tsSku", "subBrandForm", "kbd"],
                brandform: ["tsSku", "subBrandForm", "kbd"],
                tsSku: ["subBrandForm", "kbd"],
                subBrandForm: ["kbd"],
                kbd: []
            },
            pidMapping: {
                subBrandForm: ["productId"],
                productId: []
            },
            pyfyActuals: {
                account: ["brand", "tsBrand", "brandform", "tsSku", "subBrandForm"],
                brand: ["tsBrand", "brandform", "tsSku", "subBrandForm"],
                tsBrand: ["brandform", "tsSku", "subBrandForm"],
                brandform: ["tsSku", "subBrandForm"],
                tsSku: ["subBrandForm"],
                subBrandForm: []
            },
            claims: {
                productId: ["eventName"],
                eventName: []
            },
            fyActuals: {
                account: ["brand", "tsBrand", "brandform", "tsSku", "subBrandForm"],
                brand: ["tsBrand", "brandform", "tsSku", "subBrandForm"],
                tsBrand: ["brandform", "tsSku", "subBrandForm"],
                brandform: ["tsSku", "subBrandForm"],
                tsSku: ["subBrandForm"],
                subBrandForm: []
            },
            skulevelofftake: {
                brand: ["tsBrand", "brandform", "tsSku", "subBrandForm"],
                tsBrand: ["brandform", "tsSku", "subBrandForm"],
                brandform: ["tsSku", "subBrandForm"],
                tsSku: ["subBrandForm"],
                subBrandForm: []
            }, 
            lymetric: {
                brand: ["metric", "uom"],
                metric: ["uom"],
                uom: [],
            },
            omniRetailingOffTakePlanning: { brand: []},
            retailBrandVSOD: {
                brand: ["eventName"],
                eventName: [],
            },
            offtakeBrandVSOD: {
                brand: ["eventName"],
                eventName: [],
            },
            tsSkuSalience: {
                category: ["tsSku", "metric"],
                tsSku: ["metric"],
                metric: []
            }
        }
    }

    const getFilters = () => {
        let allFilters = {
            fyActuals: ["account", "brand", "brandform", "sku"],
            fyActualspff: ["brand", "brandform", "metric"],
            skuMaster: ["brand", "brandform", "sku"],
            ittMaster: ['brand', "brandform"],
            skuMapping: ["brand", "brandform", "sku"],
            pyskulevel: ["brand", "brandform", "sku"],

        }
        if (props.appName === 'omnichannel') {
            allFilters = {
                skuMaster: ["legalEntity", "category", "brand", "tsBrand", "brandform", "tsSku", "subBrandForm"],
                ittMaster: ["brand", "brandform", "tsSku", "type"],
                omniMasterClassification: ["brand", "tsBrand", "brandform", "tsSku", "subBrandForm", "kbd"],
                pidMapping: ["subBrandForm", "productId"],
                pyfyActuals: ["account", "brand", "tsBrand", "brandform", "tsSku", "subBrandForm"],
                claims: ["productId", "eventName"],
                fyActuals: ["account", "brand", "tsBrand", "brandform", "tsSku", "subBrandForm"],
                skulevelofftake: ["brand", "tsBrand", "brandform", "tsSku", "subBrandForm"],
                lymetric: ["brand", "metric", "uom"],
                omniRetailingOffTakePlanning: ["brand"],
                retailBrandVSOD: ["brand", "eventName"],
                offtakeBrandVSOD: ["brand", "eventName"],
                tsSkuSalience: ["category", "tsSku", "metric"]
            }
        }
        return allFilters[props.type]
    }

    const getFilterData = (fields = getFilters()?.join(','), _conditions) => {
        if(!fields) return;
        let url = '/dataconnect/filter/' + props.page?.pageLevelDataModel + '?type=chart&label=' + fields;
        // if (props.type !== 'pidMapping') {
        //     + '&label=' + fields;
        // }

        let conditions = [];
        if (props.account || props.modalData.account) {
            if (['skuMapping', 'fyActuals', 'fyActualspff', 'pyskulevel', 'pidMapping', 'claims', 'pyfyActuals', 'omniMasterClassification', 'skulevelofftake', 'lymetric', 'omniRetailingOffTakePlanning', 'offtakeBrandVSOD', 'retailBrandVSOD', 'tsSkuSalience'].includes(props.type)) {
                conditions.push({
                    "selectedConditionDataElement": 'account',
                    "selectedConditionOperator": "matches",
                    "value": "" + encodeURIComponent(props.modalData?.account || props.account?.AccountName),
                    "selectedConditionClause": "and"
                })
            }
        }
        if (props.account?.FinancialYear && (props.type !== 'pyskulevel' && props.type !== 'pidMapping')) {
            conditions.push({
                "selectedConditionDataElement": 'fiscalYear',
                "selectedConditionOperator": "matches",
                "value": "" + (props.type === 'pyfyActuals' || props.type === 'lymetric' ? Number(props.modalData?.year || props.account?.FinancialYear) - 1 : Number(props.modalData?.year || props.account?.FinancialYear)),
                "selectedConditionClause": "and"
            })
        }

        if (MonthsNumberMapping[props.account?.CurrentFF] && props.type !== 'fyActualspff' && props.type !== 'pyskulevel' && props.type !== 'pidMapping' && props.type !== 'lymetric' && props.type !== 'pyfyActuals') {
            conditions.push({
                "selectedConditionDataElement": 'currentFF',
                "selectedConditionOperator": "matches",
                "value": "" + (props.modalData?.month || MonthsNumberMapping[props.account?.CurrentFF]),
                "selectedConditionClause": "and"
            })
        }
        if (_conditions?.length) {
            conditions = [...conditions, ..._conditions]
        }
        if (conditions?.length) {
            url += '&conditions=' + (conditions?.length ? JSON.stringify(conditions) : []);
        }
        let payload = {
            url: url,
            appId: props.match.params.appid,
            dataUniqueId: 'GET_FILTER_' + props.type,
            isMerge: true,
        }
        props.getMrData(payload) // true means merge data;
    }

    const onSuccessResponse = (resp) => {
        setSuccess({ isOpen: false, successMessage: '' })
    }

    const onFilterChange = (filterType, { value }) => {
        let _conditions = [];
        switch (filterType) {
            case FilterType.Account:
                if (account !== value) {
                    setAccount(value);
                    setBrand('');
                    setBrandform('');
                    setSku('')
                    break;
                }
            case FilterType.LegalEntity:
                if (legalEntity !== value) {
                    setBuisnessUnit('');
                    setCategory('');
                    setLegalEntity(value);
                    setBrand('');
                    setBrandform('');
                    setSku('')
                    setTSBrand('')
                    setSubBrandForm('')
                    break;
                }
            // case FilterType.BuisnessUnit:
            //     if (buisnessUnit !== value) {
            //         setCategory('');
            //         setBuisnessUnit(value);
            //         setBrand('');
            //         setBrandform('');
            //         setSku('')
            //         setTSBrand('')
            //         setSubBrandForm('')
            //         if (legalEntity) {
            //             _conditions.push({
            //                 "selectedConditionDataElement": 'legalEntity',
            //                 "selectedConditionOperator": "is_equal_to",
            //                 "value": encodeURIComponent(legalEntity),
            //                 "selectedConditionClause": "and"
            //             })
            //         }
            //         break;
            //     }
            case FilterType.Category:
                if (category !== value) {
                    setCategory(value);
                    setBrand('');
                    setBrandform('');
                    setSku('')
                    setTSBrand('')
                    setSubBrandForm('');
                    setMetric('');
                    if (legalEntity) {
                        _conditions.push({
                            "selectedConditionDataElement": 'legalEntity',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(legalEntity),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (buisnessUnit) {
                        _conditions.push({
                            "selectedConditionDataElement": 'buisnessUnit',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(buisnessUnit),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.Brand:
                if (brand !== value) {
                    setBrand(value);
                    setBrandform('');
                    setSku('')
                    setTSBrand('')
                    setSubBrandForm('')
                    setType('')
                    if (account) {
                        _conditions.push({
                            "selectedConditionDataElement": 'account',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(account),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (legalEntity) {
                        _conditions.push({
                            "selectedConditionDataElement": 'legalEntity',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(legalEntity),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (buisnessUnit) {
                        _conditions.push({
                            "selectedConditionDataElement": 'buisnessUnit',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(buisnessUnit),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (category) {
                        _conditions.push({
                            "selectedConditionDataElement": 'category',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(category),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.Brandform:
                if (brandform !== value) {
                    setBrandform(value);
                    setSku('')
                    setSubBrandForm('')
                    setType('')
                    if (account) {
                        _conditions.push({
                            "selectedConditionDataElement": 'account',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(account),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsBrand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsBrand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(tsBrand),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.SKU:
                if (sku !== value) {
                    setSku(value);
                    setSubBrandForm('');
                    setType('')
                    if (account) {
                        _conditions.push({
                            "selectedConditionDataElement": 'account',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(account),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsBrand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsBrand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(tsBrand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.SkuType:
                if (skuType !== value) {
                    setSkuType(value);
                    break;
                }
            case FilterType.Metric:
                if (metric !== value) {
                    setUOM('');
                    setMetric(value);
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (sku) {
                        _conditions.push({
                            "selectedConditionDataElement":  props.appName === 'omnichannel' ? 'tsSku' : 'sku',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(sku),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (category) {
                        _conditions.push({
                            "selectedConditionDataElement": 'category',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(category),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.TSBrand:
                if (tsBrand !== value) {
                    setTSBrand(value);
                    setBrandform('');
                    setSku('')
                    setSubBrandForm('');
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.SubBrandForm:
                if (subBrandForm !== value) {
                    setSubBrandForm(value);
                    setProductId('');
                    setKBD('');
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsBrand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsBrand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(tsBrand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (sku) {
                        _conditions.push({
                            "selectedConditionDataElement": 'sku',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(sku),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.ProductId:
                if (productId !== value) {
                    setProductId(value);
                    setEventName('');
                    if (subBrandForm) {
                        _conditions.push({
                            "selectedConditionDataElement": 'subBrandForm',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(subBrandForm),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.KBD:
            if (kbd !== value) {
                setKBD(value);
                if (subBrandForm) {
                    _conditions.push({
                        "selectedConditionDataElement": 'subBrandForm',
                        "selectedConditionOperator": "is_equal_to",
                        "value": encodeURIComponent(subBrandForm),
                        "selectedConditionClause": "and"
                    })
                }
                break;
            }
            case FilterType.UOM:
            if (uom !== value) {
                setUOM(value);
                if (brand) {
                    _conditions.push({
                        "selectedConditionDataElement": 'brand',
                        "selectedConditionOperator": "is_equal_to",
                        "value": encodeURIComponent(brand),
                        "selectedConditionClause": "and"
                    })
                }
                if (metric) {
                    _conditions.push({
                        "selectedConditionDataElement": 'metric',
                        "selectedConditionOperator": "is_equal_to",
                        "value": encodeURIComponent(metric),
                        "selectedConditionClause": "and"
                    })
                }
                break;
            }
            case FilterType.EventName:
                if (eventName !== value) {
                    setEventName(value);
                    if (productId) {
                        _conditions.push({
                            "selectedConditionDataElement": 'productId',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(productId),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.Type:
                if (type !== value) {
                    setType(value);
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            
        }

        if ([FilterType.Brand, FilterType.SKU, FilterType.Brandform, FilterType.Account, FilterType.TSBrand, FilterType.SubBrandForm, FilterType.ProductId, FilterType.UOM, FilterType.Metric, FilterType.EventName,
        FilterType.LegalEntity, FilterType.BuisnessUnit, FilterType.Category, FilterType.Type].includes(filterType)) {
            if (value === '') {
                let fields = getFilterFields[props.type][filterType];
                if (!fields.includes(filterType)) {
                    fields.push(filterType)
                }
                getFilterData(fields, _conditions)
            } else if (getFilterFields[props.type][filterType]?.length) {
                if (props.appName === 'omnichannel' && filterType === 'sku') {
                    filterType = 'tsSku';
                }
                _conditions.push({
                    "selectedConditionDataElement": filterType,
                    "selectedConditionOperator": "is_equal_to",
                    "value": encodeURIComponent(value),
                    "selectedConditionClause": "and"
                })
                getFilterData(getFilterFields[props.type][filterType], _conditions)
            }
        }
    };

    const isViewMode = () => {
        return ['skuMaster', 'ittMaster'].includes(props.type) && isNotSuperAdmin(props.member)
    }

    const noDataIttMaster = () => {
        if (props.appName === 'omnichannel' && props.type === 'ittMaster') {
            if (props.state?.data?.length) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (props.appName !== 'omnichannel') {
            handleFilterChange({ account, brand, brandform, sku, skuType, metric })
        }
    }, [brand, brandform, sku, skuType, account, metric])

    useEffect(() => {
        if (props.appName === 'omnichannel') {
            handleFilterChange({ account, brand, brandform, sku, skuType, metric, tsBrand, subBrandForm, productId, kbd, eventName, category, legalEntity, buisnessUnit, type })
        }
    }, [brand, brandform, sku, tsBrand, subBrandForm, productId, kbd, eventName, category, legalEntity, buisnessUnit, type, metric])

    let deleteData = props.data ? props.data['DELETE_' + props.type] : null
    useEffect(() => {
        if (deleteData) {
            getFilterData()
        }
    }, [deleteData])

    let filterDataN = props.data ? props.data['GET_FILTER_' + props.type] : {};
    // removing empty fields from all filter values
    let filterData = {};
    if (filterDataN) {
        let keys = Object.keys(filterDataN)
        for (let i = 0; i < keys.length; i++) {
            let nArray = [];
            for (let j = 0; j < filterDataN[keys[i]].length; j++) {
                if (filterDataN[keys[i]][j] !== '') {
                    nArray.push(filterDataN[keys[i]][j]);
                }
            }
            filterData[keys[i]] = JSON.parse(JSON.stringify(nArray));
        }
    }
    // filterData = JSON.parse(JSON.stringify(filterDataN));
    // if (filterData && filterData.eventName && filterData.eventName.length) {
    //     filterData.eventName = filterData.eventName.filter((d) => {return (d !== '')})
    // }

    return <>
        <div className={classNames(classes.headerMainCon, "filter-container")}>
            <div className={classes.leftHeaderCon}>
                {['fyActuals', 'pyfyActuals'].includes(props.type) &&
                    <Select
                        value={account}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('account', e.target)}>
                        <MenuItem value="">{'-- All Accounts --'}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.account)?.map(item =>
                                <MenuItem value={item}>{item}</MenuItem>,
                            ),
                        )}
                    </Select>
                }
                {props.appName === "omnichannel" && ['skuMaster'].includes(props.type) &&
                    <Select
                        value={legalEntity}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('legalEntity', e.target)}>
                        <MenuItem value="">{'-- All Legal Entities --'}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.legalEntity)?.map(item =>
                                <MenuItem value={item}>{item}</MenuItem>,
                            ),
                        )}
                    </Select>
                    
                }
                 {/* {props.appName === "omnichannel" && ['skuMaster'].includes(props.type) &&
                    <Select
                        value={buisnessUnit}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('buisnessUnit', e.target)}>
                        <MenuItem value="">{'-- All Business Units --'}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.buisnessUnit)?.map(item =>
                                <MenuItem value={item}>{item}</MenuItem>,
                            ),
                        )}
                    </Select>
                    
                } */}
                 {props.appName === "omnichannel" && ['skuMaster', 'tsSkuSalience'].includes(props.type) &&
                    <Select
                        value={category}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('category', e.target)}>
                        <MenuItem value="">{'-- All Categories --'}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.category)?.map(item =>
                                <MenuItem value={item}>{item}</MenuItem>,
                            ),
                        )}
                    </Select>
                    
                }

                {['ittMaster', 'skuMaster', 'skuMapping', 'fyActuals', 'fyActualspff','pyskulevel', 'skulevelofftake', 'pyfyActuals', 'omniMasterClassification', 'lymetric', 'retailBrandVSOD', 'offtakeBrandVSOD', 'omniRetailingOffTakePlanning'].includes(props.type) &&
                    <Select
                        value={brand}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('brand', e.target)}>
                        <MenuItem value="">{'-- All Brands --'}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.brand)?.map(item =>
                                <MenuItem value={item}>{item}</MenuItem>,
                            ),
                        )}
                    </Select>
                }

                {props.appName === "omnichannel" && ['skuMaster', 'fyActuals', 'skulevelofftake', 'pyfyActuals', 'omniMasterClassification'].includes(props.type) && <>
                    <Select
                        value={tsBrand}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('tsBrand', e.target)}>
                        <MenuItem value="">{'-- All TS Brands --'}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.tsBrand)?.map(item =>
                                <MenuItem value={item}>{item}</MenuItem>,
                            ),
                        )}
                    </Select>
                </>}


                {['skuMaster', 'ittMaster', 'skuMapping', 'fyActuals', 'fyActualspff', 'pyskulevel', 'skulevelofftake', 'pyfyActuals', 'omniMasterClassification'].includes(props.type) &&
                    <Select
                        value={brandform}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('brandform', e.target)}>
                        <MenuItem value="">{'-- All Brandforms --'}</MenuItem>
                        {
                            sortAlphabetically(filterData?.brandform)?.map(item =>
                                <MenuItem key={item} value={item}>{item}</MenuItem>,
                            )
                        }
                    </Select>
                }

                {['skuMaster', 'ittMaster', 'skuMapping', 'fyActuals', 'pyskulevel', 'skulevelofftake', 'pyfyActuals', 'omniMasterClassification', 'tsSkuSalience'].includes(props.type) &&
                    <Select
                        value={sku}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('sku', e.target)}>
                        <MenuItem value="">{props.appName === 'omnichannel' ? '-- All TS SKUs --' : '-- All SKUs --'}</MenuItem>
                        {
                            sortAlphabetically(props.appName === 'omnichannel' ? filterData?.tsSku : filterData?.sku)?.map(item =>
                                <MenuItem key={item} value={item}>{item}</MenuItem>,
                            )
                        }
                    </Select>
                }
                  {props.appName === "omnichannel" && ['ittMaster'].includes(props.type) && <>
                    <Select
                        value={type}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('type', e.target)}>
                        <MenuItem value="">{'-- ALL TYPES --'}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.type)?.map(item =>
                                <MenuItem value={item}>{item}</MenuItem>,
                            ),
                        )}
                    </Select>
                </>}
                  {props.appName === "omnichannel" && ['skuMaster', 'pidMapping', 'fyActuals', 'skulevelofftake', 'pyfyActuals', 'omniMasterClassification'].includes(props.type) && <>
                    <Select
                        value={subBrandForm}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('subBrandForm', e.target)}>
                        <MenuItem value="">{'-- ALL SUB BRANDFORM --'}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.subBrandForm)?.map(item =>
                                <MenuItem value={item}>{item}</MenuItem>,
                            ),
                        )}
                    </Select>
                </>}
                {props.appName === "omnichannel" && ['pidMapping', 'claims'].includes(props.type) && <>
                    <Select
                        value={productId}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('productId', e.target)}>
                        <MenuItem value="">{'-- ALL PRODUCT IDS --'}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.productId)?.map(item =>
                                <MenuItem value={item}>{item}</MenuItem>,
                            ),
                        )}
                    </Select>
                </>}
                {props.appName === "omnichannel" && ['omniMasterClassification'].includes(props.type) && <>
                    <Select
                        value={kbd}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: '150px' }}
                        onChange={e => onFilterChange('kbd', e.target)}>
                        <MenuItem value="">{'-- ALL KBDs --'}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.kbd)?.map(item =>
                                <MenuItem value={item}>{item}</MenuItem>,
                            ),
                        )}
                    </Select>
                </>}
                {props.type === "skuMapping" && <>
                    <Select
                        value={skuType}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: "150px" }}
                        onChange={e => onFilterChange('skuType', e.target)}>
                        <MenuItem value={undefined}>{"-- All SKU Types --"}</MenuItem>
                        {React.Children.toArray(
                            skuTypeValues.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
                        )}
                    </Select>
                </>}

                {(props.type === "fyActualspff" || props.type === 'lymetric' || props.type === 'tsSkuSalience') && <>
                    <Select
                        value={metric}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: "150px" }}
                        onChange={e => onFilterChange('metric', e.target)}>
                        <MenuItem value={''}>{"-- All Metrics --"}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.metric)?.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)
                        )}
                    </Select>
                </>}
                {(props.type === 'lymetric') && <>
                    <Select
                        value={uom}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: "150px" }}
                        onChange={e => onFilterChange('uom', e.target)}>
                        <MenuItem value={''}>{"-- All UOM --"}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.uom)?.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)
                        )}
                    </Select>
                </>}
                {(props.type === 'claims' || props.type === 'retailBrandVSOD' || props.type === 'offtakeBrandVSOD') && <>
                    <Select
                        value={eventName}
                        displayEmpty={true}
                        className="custom-select m-right-10"
                        style={{ minWidth: "150px" }}
                        onChange={e => onFilterChange('eventName', e.target)}>
                        <MenuItem value="">{"-- All Event Names --"}</MenuItem>
                        {React.Children.toArray(
                            sortAlphabetically(filterData?.eventName)?.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)
                        )}
                    </Select>
                </>}
            </div>
            {!props.modalData && <div className={classes.rightHeaderCon}>
                <div className={classNames(`${classes.headerBtnGrp} mr-10`)} style={{ textAlign: 'right' }}>
                    {
                        !isViewMode() &&
                        <>
                            <Button size="large" color="primary" variant="contained"
                                onClick={(e) => props.save(null)} disabled={!edited} startIcon={<SaveIcon />}>
                                {"Save"}
                            </Button>
                            <span className="m-right-10" />
                        </>
                    }
                    {
                        <>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                onClick={props.deleteHandler}
                            >
                                Delete
                            </Button>
                            <span className="m-right-10" />
                        </>
                    }
                    <Button size="large" color="primary" variant="contained"
                        style={{ marginRight: '10px' }}
                        onClick={props.onDownload}
                        startIcon={<GetAppIcon />}>
                        {"Download"}
                    </Button>
                    {
                        !isViewMode() && <Upload
                            {...props}
                            handleSave={(files) => {
                                props.handleFileSave()
                                getFilterData()
                            }}
                            showButton={noDataIttMaster()}
                            acceptedFiles={[
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/vnd.ms-excel',
                                'application/x-csv',
                                'application/csv',
                                'text/csv',
                                'text/x-csv',
                                'text/tab-separated-values',
                                'text/comma-separated-values',
                                'text/x-comma-separated-values'
                            ]} />
                    }

                    <span className="m-right-10" />
                    {
                        props.type === 'fyActuals' && props.appName !== 'omnichannel' &&
                        <Button
                            onClick={() => props.onDoneUpdateClick()}
                            className={classNames('btn btn-primary btn-color m-right-10', classes.updateBtnStyle)}>
                            {`Done Updates  ${getMonth(props?.account?.CurrentFF, 'month')} ${getFiscalYearFormat(props?.account?.FinancialYear)}`}
                        </Button>

                    }
                </div>
            </div>}
        </div>
        <ConfirmDialog open={successState.isOpen} handleClose={onSuccessResponse}
            alertMessageText={successState.confirmMessage} classes={classes} />
    </>
}

const mapDispatchToProps = {
    getMrData,
};

const mapStateToProps = state => {
    return {
        account: state.mrReducer?.account,
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
        member: state.appConfig.member,
        roles: state.appConfig.roles,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(SheetHeader)));