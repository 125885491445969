import { hot } from 'react-hot-loader/root';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { HandleErrors } from './ErrorDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PlanUploadConfirmDialog = (props) => {
  let { open, handleClose, alertMessageText, classes } = props;
  return (<>
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.setupConfirmationDialog}
    >
      <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: '#047dd8' }}>
        {typeof alertMessageText == 'object' && alertMessageText.title ? alertMessageText.title : 'Confirmation'}
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <DialogContentText component="div" className={classes.para}>
          {typeof alertMessageText == 'object'
            ?<>
            <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '5px 0' }}>
              {alertMessageText.message}</h6>
              {alertMessageText.lbebopIssues && alertMessageText.lbebopIssues.length > 0
                && <HandleErrors errorArr={alertMessageText.lbebopIssues} type="lbebop" />}
              {alertMessageText.spendMillionIssues && alertMessageText.spendMillionIssues.length > 0
                && <HandleErrors errorArr={alertMessageText.spendMillionIssues} type="spendMillion" />}
                  {alertMessageText.duplicateIssues && alertMessageText.duplicateIssues.length > 0
                && <HandleErrors errorArr={alertMessageText.duplicateIssues} type="duplicate" />}
            {alertMessageText.brandFormIssues && alertMessageText.brandFormIssues.length > 0
            && <HandleErrors key="Brandforms" errorArr={alertMessageText.brandFormIssues} type="Brandforms"/>}
            {alertMessageText.channelIssues && alertMessageText.channelIssues.length > 0
            && <HandleErrors key="Channels" errorArr={alertMessageText.channelIssues} type="Channels"/>}
            {alertMessageText.descriptionIssues && alertMessageText.descriptionIssues.length > 0
            && <HandleErrors key="data" errorArr={alertMessageText.descriptionIssues} type="data"/>}
          </>: <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
              {alertMessageText}
            </h6>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary" onClick={() => handleClose()}
          className="btn btn-primary btn-color border-radius-0 text-color-white">
          {'OK'}
        </Button>
      </DialogActions>
    </Dialog>

  </>);
};

const mapStateToProps = state => {
  return {
    user: state.user,
    pageLoading: state.pages.loading,
    member: state.appConfig.member,
    memberLoading: state.appConfig.memberLoading,
    entity: state.appConfig.entity ? state.appConfig.entity : {},
  };
};
export default hot(withRouter(connect(mapStateToProps, null)(PlanUploadConfirmDialog)));
