import React from "react";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    tableContainer: {
        maxWidth: '95vw',
        borderRadius: '8px',
        overflowX: 'scroll !important',
        '@media(max-width:1366px)': {
            height: 'calc(100vh - 302px)',
        },
        '@media(min-width:1367px)': {
            marginBottom: '15px',
            height: 'calc(100vh - 320px)',
        },
        scrollbarWidth: 'thin',
        height: 'calc(100vh - 320px)',
        '&::-webkit-scrollbar': {
            width: '0.8em',
            height: '0.8em',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cdcdcd',
            outline: '1px solid slategrey',
        },
    },
    table: {
        minWidth: 700,
        paddingRight: 7,
    },
    tableHead: {
        position: 'sticky', top: 0, zIndex: 10,
        '& th': {
            textAlignLast: 'center',
        },
        '& th:not(.adjustBorder)': {
            boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
        },
        '& th.adjustBorder': {
            paddingRight: '0',
            '& span': {
                paddingRight: '16px',
                boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
                display: 'flex',
                height: '40px',
                alignItems: 'center',
            },
        },
        '& th.lastYearHeader': {
            '& span': {
                height: '40px',
                display: 'flex',
                padding: '0 16px',
                textAlign: 'center',
                alignItems: 'center',
            },
        },
        '& span': {
            fontWeight: 'bold',
            display: 'block',
            wordBreak: 'normal',
            whiteSpace: 'normal',
            textAlign: 'center',
        },
    },
    tableBody: {
        '& td': {
            boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
            borderBottom: '0',
            cursor: 'pointer',
            textAlign: 'center',
            '& input': {
                textAlign: 'center',
            },
        },
        '& td.noBorder': {
            boxShadow: 'none',
        },
        '& p': {
            display: 'flex',
        },
    },
    tableBodyNoData: {
        margin: '50px',
        display: 'flex',
        minHeight: '50vh',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '& h3': {
            color: 'black',
        },
    },
    tableBodyLoading: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 400px)',
    },
    tableInput: {
        border: '0',
        height: '38px',
        maxWidth: 100,
        appearance: 'none',
        margin: '0 5px',
        background: 'transparent',
        '&:focus': {
            background: '#fff',
        },
    },
    textInput: {
        border: '0',
        height: '28px',
        width: '90px',
        appearance: 'none',
        color: 'inherit',
        background: 'transparent',
    },
    greenFont: {
        '& span': {
            color: 'green',
        },
    },
    blackFont: {
        color: 'black !important',
    },
    rowSelectedBg: {
        background: '#CEE7FC !important',
        transition: 'background-color 0.5s ease',
    },
    combinedHeaderHeading: {
        margin: '5px 0px 0px 0px',
        // margin: "0",
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: '4px',
        padding: '4px 2px 2px',
        background: 'rgb(16, 114, 189)',
    },
    combinedHeaderHeadingGrey: {
        margin: '5px 0px 0px 0px',
        // margin: "0",
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: '4px',
        padding: '4px 2px 2px',
        background: 'rgb(127, 127, 127)',
    },
    combinedHeaderSection: {
        justifyContent: 'space-around',
        '@media(max-width:1366px)': {
            height: '45px',
        },
        display: 'flex',
        '& p': {
            flex: 1,
            // alignSelf: 'center',
            paddingLeft: 10,
            paddingRight: 10,
        }
    },
    divider: {
        marginTop: 'auto',
        height: 30,
        '@media(max-width:1366px)': {
            height: 35,
        },
        width: 1,
        background: 'rgba(224, 224, 224, 1)',
        marginBottom: 'auto',
    },
    combinedBodySection: {
        justifyContent: 'space-around',
        height: '38px',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            borderRight: '0',
            width: '82px',
            justifyContent: 'space-around',
        },
        '& p:not(:last-of-type)': {
            height: '38px',
            textAlign: 'center',
            paddingRight: '16px',
            paddingTop: '2px',
            paddingBottom: '2px',
            margin: '0px',
            '& input': {
                height: '100%',
            },
        },
        '& p:not(:first-of-type)': {
            paddingLeft: '16px',
            textAlign: 'center',
        },
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            height: '320px',
            width: '600px',
        },
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    totalValue: {
        marginBottom: 5,
        // color: '#0070C0 !important',
    },
    combinedHeaderTotalSection: {
        height: 20,
        justifyContent: 'space-around',
        display: 'flex',
        '& p': {
            flex: 1,
            alignSelf: 'center',
            paddingLeft: 10,
            paddingRight: 10,
        }
    },
    tableCell: {
        alignSelf: 'center', margin: '0 10px',
        minWidth: 55
    },
    greenClass: {
        color: '#09c209'
    },
    greenClass1: {
        color: '#065606'
    },
    redClass: {
        color: '#f65a22'
    },
    blueClass: {
        color: '#798eec'
    },
    orangeClass: {
        color: '#ecc779'
    }
}));