import jwtService from 'jwtService';
import { ActionTypes } from 'constants/index';

export const submitLogin = ({ email, password }) => dispatch => {
    dispatch({ type: ActionTypes.LOGIN_LOADING, payload: { email, password } });
};

export const submitLoginwithToken = () => dispatch => {
    dispatch({ type: ActionTypes.LOGIN_SUCCESS });
};

export const resetPassword = (payload) => dispatch => {
    console.log("payload", payload);    
    dispatch({ type: ActionTypes.RESET_PASSWORD_LOADING, payload });
};

export const signInWithPingToken = ({ shortName, pingToken }) => dispatch => {
    dispatch({ type: ActionTypes.SIGN_IN_WITH_PING_TOKEN, payload: { shortName, pingToken } });
};

export const isUserExist = (data) => dispatch => {
    dispatch({ type: ActionTypes.IS_USER_EXIST, payload: data });
};