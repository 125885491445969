import { ActionTypes } from 'constants/index';

const initialState = {
  data: {},
  error: '',
  loading: false,
  rowLoading: false,
  getNewData: false,
  extraData: {},
  extraDataError: '',
  extraDataLoading: false,
  selectBrand: {},
  filterData: {
    plans: [],
  },
  successMsg: '',
  modifiedRecords: {},
  deleteLoading: false,
};

export default function ConsumerPromoReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_CONSUMER_PROMO_DATA: {
      return {
        ...state,
        loading: true,
        getNewData: false,
        error: '',
      };
    }
    case ActionTypes.GET_CONSUMER_PROMO_SUCCESS: {
      let _modifiedRecords = Object.keys(state.modifiedRecords)
      let fetchedRecords = action.payload;
      let rows;
      if(_modifiedRecords.length){
          rows = fetchedRecords.result.data.map(item=> {
              if(state.modifiedRecords[item._id]){
                  return state.modifiedRecords[item._id]
              }
              return item;
          })
          fetchedRecords.result.data = rows
      }
      return {
        ...state,
        data: fetchedRecords,
        getNewData: false,
        loading: false,
      };
    }
    case ActionTypes.GET_CONSUMER_PROMO_FAILURE: {
      return {
        ...state,
        loading: false,
        getNewData: false,
        error: action.error,
      };
    }

    case ActionTypes.ADD_NEW_CONSUMER_PROMO_SUCCESS: {
      return {
        ...state,
        modifiedRecords: {},
        getNewData: true,
        rowLoading: false,
        error: '',
        message: action.payload.message,
      };
    }
    case ActionTypes.ADD_NEW_CONSUMER_PROMO_FAILURE: {
      return {
        ...state,
        rowLoading: false,
        getNewData: false,
        error: action.error,
      };
    }
    case ActionTypes.DELETE_CHECKED_CONSUMER_PROMO: {
      return {
        ...state,
        deleteLoading: true,
      };
    }
    case ActionTypes.DELETE_CHECKED_CONSUMER_PROMO_FAILURE: {
      return {
        ...state,
        deleteLoading: false,
      };
    }
    case ActionTypes.DELETE_CHECKED_CONSUMER_PROMO_SUCCESS: {
      let __result = action.payload?.result?.data ?? []
      let _modifiedRecords = {...state.modifiedRecords};
      __result?._id?.forEach(i => delete _modifiedRecords[i]);
      return {
        ...state,
        modifiedRecords: _modifiedRecords,
        deleteLoading: false,
        data: {
          ...state.data,
          result: {
            ...state.data.result,
            total: state.data.result?.total - __result?.deletedCount,
            data: state.data.result.data.filter(item => {
              return !__result?._id.includes(item._id)
            })
          }
        },
        getNewData: true,
        successMsg: action.payload.message,
      };
    }

    case ActionTypes.USER_SELECTED_BRAND: {
      return {
        ...state,
        selectBrand: action.payload,
        modifiedRecords: {},
      };
    }

    case ActionTypes.GET_CONSUMER_PROMO_EXTRA_DATA: {
      return {
        ...state,
        error: '',
        loading: false,
        getNewData: false,
        extraDataLoading: true,
      }
    }
    case ActionTypes.GET_CONSUMER_PROMO_EXTRA_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        getNewData: false,
        extraDataLoading: false,
        extraData: action.payload,
      }
    }
    case ActionTypes.GET_CONSUMER_PROMO_EXTRA_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        getNewData: false,
        extraDataLoading: false,
        extraDataError: action.extraDataError,
      }
    }
    case ActionTypes.HIDE_MESSAGE: {
      return {
        ...state,
        message: null,
      };
    }
    case ActionTypes.REMOVE_SUCCESS_MSG:{
      return{
        ...state,
        successMsg: '',
        error: '',
      }
    }
    case ActionTypes.GET_PLAN_FILTER_DATA_CONSUMER_PROMO:{
      return{
          ...state,
          loading: action.payload
      }
  }
  case ActionTypes.GET_PLAN_FILTER_DATA_CONSUMER_PROMO_SUCCESS:{
      return{
          ...state,
          loading: false,
          filterData: {
              ...state.filterData,
              plans: action.payload
          }
      }
  }
  case ActionTypes.GET_PLAN_FILTER_DATA_CONSUMER_PROMO_FAILURE:{
      return{
          ...state,
          successMsg: ''
      }
  }
  case ActionTypes.MODIFY_CONSUMER_PROMO: {
    let records = JSON.parse(JSON.stringify(state.modifiedRecords));
    records[action.payload._id] = action.payload;
    return {
        ...state,
        modifiedRecords: {...records},
    }
  }
  case ActionTypes.CLEAR_MODIFY_CONSUMER_PROMO: {
      return {
          ...state,
          modifiedRecords: {},
      }
  }
  case ActionTypes.REMOVE_MODIFY_CONSUMER_PROMO: {
      let _modifiedRecords = {...state.modifiedRecords};
      action.payload.forEach(i => delete _modifiedRecords[i]);
      return {
          ...state,
          modifiedRecords: _modifiedRecords,
      }
  }
    default: {
      return state;
    }

  }

}
