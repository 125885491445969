
import {
    generateGUID
} from 'modules/page/page.module'

export function addCondAndLink(ary, data) {
	console.log("ary,data", ary, data);
	let arys = [...data];
	if (ary.id && ary.index > -1 && ary.editMode) {
		arys[ary.index] = { ...ary, editMode: false };
	} else {
		let obj = ary.id ? ary : { id: generateGUID(), ...ary };
		arys.push({ ...obj });
	}
	return arys;
}

export function editCondAndLink(object, index, data) {
	let obj = { ...data[index] };
	obj.editMode = true;
	obj.index = index;
	let ary = [...data];
	ary[index] = obj;
	return { ary, obj };
}

export function deleteCondAndLink(object, index, data) {
	let ary = [...data];
	ary.splice(index, 1);
	return ary;
}
