import React, {useState} from 'react';

import '../../ibcsupportingdocument.scss';
import '../../ext-content/bootstrap.scss';
import '../../ext-content/custom.min.scss';
import '../../ext-content/mystyles.scss';
import '../../ext-content/jqcloud.scss';
import '../../ext-content/basic.scss';
import '../../ext-content/dropzone.scss';
import moment from 'moment';

import Loading from '../../ext-content/images/loading.gif'

import {
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
    TableContainer,
    Select,
    MenuItem,
} from '@material-ui/core';

function MemberDetails(props) {
    const dataObj = {
        _id: '600a80ca1e7f4d211f8b876a',
        employee_name: 'Donnie Moore',
        base_anual_salary: '$423,410.00',
        age: 56,
        employee_class: 'Class 1',
        'Annual Bonus': 0,
        entityId: '5fedc3fedfee0407e898d82f',
        supportingDocId: '55af9000',
        'Earnings for Life and ADD': 0,
        'Basic Life Volume': 0,
        'Basic Life Prem': 0,
        'Basic Life Rate': '0.115',
        'AD&D Volume': 0,
        'AD&D Prem': 0,
        'AD&D Rate': '0.035',
        'Earnings for LTD': 0,
        'LTD Volume': 0,
        'LTD Prem': 0,
        'LTD Rate': '0.236',
        'FMLA/ADA Administration Rate': '0.128',
        'Earnings For STD': 0,
        'STD Volume': 0,
        'STD Prem': 0,
        'STD Rate': '0.321',
        'Employee Optional Life Rate': '0.231',
        'Total Premium': 0,
        'EE Contribution': 0,
        'ER Contribution': 0,
        PreviousPremiums: [
            {
                'Billing Period': 'Jul 2020',
                'Basic Life Prem': '$120.0',
                'AD&D Prem': '$120.0',
                'STD Prem': '$120.0',
                'LTD Prem': '$120.0',
                'FMLA Prem': '$120.0',
                'EE Optional Prem': '$120.0',
                'SP Optional Prem': '$120.0',
                'CH Optional Prem': '$120.0',
                'Total Premium': '$120.0',
            },
            {
                'Billing Period': 'Aug 2020',
                'Basic Life Prem': '$120.0',
                'AD&D Prem': '$120.0',
                'STD Prem': '$120.0',
                'LTD Prem': '$120.0',
                'FMLA Prem': '$120.0',
                'EE Optional Prem': '$120.0',
                'SP Optional Prem': '$120.0',
                'CH Optional Prem': '$120.0',
                'Total Premium': '$120.0',
            },
            {
                'Billing Period': 'Sep 2020',
                'Basic Life Prem': '$120.0',
                'AD&D Prem': '$120.0',
                'STD Prem': '$120.0',
                'LTD Prem': '$120.0',
                'FMLA Prem': '$120.0',
                'EE Optional Prem': '$120.0',
                'SP Optional Prem': '$120.0',
                'CH Optional Prem': '$120.0',
                'Total Premium': '$120.0',
            },
            {
                'Billing Period': 'Oct 2020',
                'Basic Life Prem': '$120.0',
                'AD&D Prem': '$120.0',
                'STD Prem': '$120.0',
                'LTD Prem': '$120.0',
                'FMLA Prem': '$120.0',
                'EE Optional Prem': '$120.0',
                'SP Optional Prem': '$120.0',
                'CH Optional Prem': '$120.0',
                'Total Premium': '$120.0',
            },
            {
                'Billing Period': 'Nov 2020',
                'Basic Life Prem': '$120.0',
                'AD&D Prem': '$120.0',
                'STD Prem': '$120.0',
                'LTD Prem': '$120.0',
                'FMLA Prem': '$120.0',
                'EE Optional Prem': '$120.0',
                'SP Optional Prem': '$120.0',
                'CH Optional Prem': '$120.0',
                'Total Premium': '$120.0',
            },
            {
                'Billing Period': 'Dec 2020',
                'Basic Life Prem': '$120.0',
                'AD&D Prem': '$120.0',
                'STD Prem': '$120.0',
                'LTD Prem': '$120.0',
                'FMLA Prem': '$120.0',
                'EE Optional Prem': '$120.0',
                'SP Optional Prem': '$120.0',
                'CH Optional Prem': '$120.0',
                'Total Premium': '$120.0',
            },
        ],
    };

    const member = props.selectedMember;

    const [type, setType] = React.useState('premium');
    const [policyDate, setPolicyDate] = React.useState('');
    const [terminationaDate, setTerminationDate] = React.useState('');
    const [waiverDate, setWaiverDate] = React.useState('');
    const [memberProduct, setMemberProduct] = React.useState('');
    const [premiumFromDate, setPremiumFromDate] = React.useState('');
    const [premiumToDate, setPremiumToDate] = React.useState('');

    const handleTypeChange = event => {
        setType(event.target.value);
    };

    const getEmployeePreviousPremiums = () => {
        console.log('selectedMember', props.selectedMember);
    };

    React.useEffect(() => {
        getEmployeePreviousPremiums();
        return () => {
            setType('premium');
        };
    }, []);

    const numFormat = (value) => {
        return '$ ' + (value ? value : '0').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    console.log('memberDetailsEmployer =?= ', props.memberDetailsEmployer)

    return (
        <div className="memberdetails">
            <div>
                <Grid container spacing={3} style={{ marginTop: '15px' }}>
                    <Grid item xs={6} lg={6} style={{ textAlign: 'left', color: '#000' }}>
                        <span>Full Name: {member['Employee Name']}</span>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <span>Base Annual Salary: {numFormat(member['Base Annual Salary'])}</span>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginTop: '5px', marginBottom: '10px' }}>
                    <Grid item xs={6} lg={6} style={{ textAlign: 'left', color: '#000' }}>
                        <span>Age: {member['Age']}</span>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <span>Class: {member.Class}</span>
                    </Grid>
                </Grid>
            </div>
            <div>
                <FormControl component="fieldset">
                    <select native defaultValue="" name="supporting document selection" variant="outlined"
                    style={{height: '50px', padding: '10px 15px'}}
                    onChange={(e) => {setType(e.target.value)}} value={type}>
                        <option value="employee" >New Employee Adjustment</option>
                        <option value="terminate" >Terminate</option>
                        <option value="waiver" >Waiver</option>
                        <option value="premium" >Premium Adjustment</option>
                        <option value="coverage" >Coverage Adjustment</option>
                        </select>
                    {/*<RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={type}
                        style={{ display: 'flex', width: '60vw', justifyContent: 'space-between' }}
                        onChange={handleTypeChange}
                    >
                        <FormControlLabel
                            style={{ color: '#000' }}
                            label="New Employee Adjustment"
                            value="employee"
                            control={<Radio color="primary" size="small" />}
                        />
                        <FormControlLabel
                            style={{ color: '#000' }}
                            label="Terminate"
                            value="terminate"
                            control={<Radio color="primary" size="small" />}
                        />
                        <FormControlLabel
                            style={{ color: '#000' }}
                            label="Waiver"
                            value="waiver"
                            control={<Radio color="primary" size="small" />}
                        />
                        <FormControlLabel
                            style={{ color: '#000' }}
                            label="Premium Adjustment"
                            value="premium"
                            control={<Radio color="primary" size="small" />}
                        />
                        <FormControlLabel
                            style={{ color: '#000' }}
                            label="Coverage Adjustment"
                            control={<Radio color="primary" size="small" />}
                            value="coverage"
                        />
                    </RadioGroup>*/}
                </FormControl>
            </div>
            {type === 'employee' && (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                    <span>Employee Effective date of Coverage</span>
                    <TextField
                        type="date"
                        variant={'standard'}
                        value={policyDate}
                        onChange={e => setPolicyDate(e.target.value)}
                        style={{ marginLeft: '15px', border: '1px solid #2196f3', paddingLeft: '10px' }}
                    />
                </div>
            )}
            {type === 'terminate' && (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                    <span>Termination Effective Date</span>
                    <TextField
                        type="date"
                        variant={'standard'}
                        value={terminationaDate}
                        onChange={e => setTerminationDate(e.target.value)}
                        style={{ marginLeft: '15px', border: '1px solid #2196f3', paddingLeft: '10px' }}
                    />
                </div>
            )}
            {type === 'waiver' && (
                <div style={{ marginTop: '10px' }}>
                    <Grid container spacing={3} style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Grid
                            item
                            xs={6}
                            lg={6}
                            style={{ textAlign: 'left', color: '#000', display: 'flex', alignItems: 'center' }}
                        >
                            <span>Waiver Effective Date</span>
                            <TextField
                                type="date"
                                variant={'standard'}
                                value={waiverDate}
                                onChange={e => setWaiverDate(e.target.value)}
                                style={{ marginLeft: '15px', border: '1px solid #2196f3', paddingLeft: '10px' }}
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <span>Select Products</span>
                            <Select
                                native
                                defaultValue=""
                                value={memberProduct}
                                name="supporting document selection"
                                variant="outlined"
                                style={{
                                    color: 'black',
                                    width: '300px',
                                    height: '50px',
                                    marginLeft: '15px',
                                }}
                                onChange={e => setMemberProduct(e.target.value)}
                            >
                                <MenuItem value="">
                                    <em>Select Product</em>
                                </MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </div>
            )}
            {type === 'premium' && (
                <div style={{ marginTop: '10px' }}>
                    <Grid container spacing={3} style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Grid
                            item
                            xs={6}
                            lg={6}
                            style={{
                                textAlign: 'left',
                                color: '#000',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span>From Date</span>
                            <TextField
                                type="date"
                                variant={'standard'}
                                value={premiumFromDate}
                                onChange={e => setPremiumFromDate(e.target.value)}
                                style={{ marginLeft: '50px', border: '1px solid #2196f3', paddingLeft: '10px' }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            lg={6}
                            style={{
                                textAlign: 'left',
                                color: '#000',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span>To Date</span>
                            <TextField
                                type="date"
                                variant={'standard'}
                                value={premiumToDate}
                                onChange={e => setPremiumToDate(e.target.value)}
                                style={{ marginLeft: '50px', border: '1px solid #2196f3', paddingLeft: '10px' }}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
            {props.memberDetailsEmployer.length === 0 &&
                <div className="loading" style={{ margin: 'auto', color: '#000' }}>
                    <h3 style={{ color: '#000' }}>Loading data.....</h3>
                    <img src={Loading} />
                </div>
            }
            {props.memberDetailsEmployer.length > 0 &&
                <div style={{ marginTop: '30px' }}>
                    <TableContainer>
                        <table style={{ width: '100%', textAlign: 'center' }}>
                            <tr>
                                <th>{''}</th>
                                {props.memberDetailsEmployer.map(premium => (
                                    <th className="tablecell">{moment(premium['BillingPeriod']).format("MMM YYYY")}</th>
                                ))}
                            </tr>
                            <tr>
                                <td className="tablecell">{'Basic Life'}</td>
                                {props.memberDetailsEmployer.map(premium => (
                                    <td className="tablecell">${premium['Basic Life Prem']}</td>
                                ))}
                            </tr>
                            <tr>
                                <td className="tablecell">{'ADD'}</td>
                                {props.memberDetailsEmployer.map(premium => (
                                    <td className="tablecell">${premium['AD&D Prem']}</td>
                                ))}
                            </tr>
                            <tr>
                                <td className="tablecell">{'STD'}</td>
                                {props.memberDetailsEmployer.map(premium => (
                                    <td className="tablecell">${premium['STD Prem']}</td>
                                ))}
                            </tr>
                            <tr>
                                <td className="tablecell">{'LTD'}</td>
                                {props.memberDetailsEmployer.map(premium => (
                                    <td className="tablecell">${premium['LTD Prem']}</td>
                                ))}
                            </tr>
                            <tr>
                                <td className="tablecell">{'FMLA'}</td>
                                {props.memberDetailsEmployer.map(premium => (
                                    <td className="tablecell">${premium['FMLA/ADA Administration']}</td>
                                ))}
                            </tr>
                            <tr>
                                <td className="tablecell">{'EE Optional'}</td>
                                {props.memberDetailsEmployer.map(premium => (
                                    <td className="tablecell">${premium['Employee Optional Life Prem']}</td>
                                ))}
                            </tr>
                            <tr>
                                <td className="tablecell">{'SP Optional'}</td>
                                {props.memberDetailsEmployer.map(premium => (
                                    <td className="tablecell">${premium['Spouse Life Prem']}</td>
                                ))}
                            </tr>
                            <tr>
                                <td className="tablecell">{'CH Optional'}</td>
                                {props.memberDetailsEmployer.map(premium => (
                                    <td className="tablecell">${premium['Child Life Prem']}</td>
                                ))}
                            </tr>
                        </table>
                    </TableContainer>
                </div>
            }
        </div>
    );
}

export default MemberDetails;
