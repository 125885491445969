import {
  Button, CircularProgress, IconButton, makeStyles, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import classNames from 'classnames';
import FileDownload from 'js-file-download';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearModifyConsumePromo, clearModifyConversionFunds, clearModifyDisplays, clearModifyTFIs, clearModifyTPRs, clearModifyTrialsDiscount } from '../../../../../store/actions';
import { capitalize, getFilteredChannelList, getMonth } from '../../../../../utility/utils';
import FilterMultiSelect from '../../../../FilterMultiSelect';
import SetupDataDialog from '../../Archives/SetupDataDialog';
import TalkSheetModal from '../../ReportsPages/talkSheet/talkSheetModal';
import ConfirmDialog from '../../SpendPlan/ConfirmDialog';
import { getFiscalYearFormat, monthsShortForm } from "../../spendplanUtils";
import { planTypeByPageName } from '../planningHelperFunctions';
import UploadPlanning from './PlanUpload/UploadPlan';

export const ActionType = {
  Save: 'Save',
  Delete: 'Delete',
  Duplicate: 'Duplicate',
  Download: 'Download',
  RemoveRecon: 'RemoveRecon',
  NewPlan: 'NewPlan',
  DoneUpdates: 'DoneUpdates',
  Upload: 'Upload',
  SaveAndRecon: 'SaveAndRecon',
};

export const FilterType = {
  Month: 'Month',
  Qtr: 'Qtr',
  Brandform: 'Brandform',
  Channel: 'Channel',
  Cluster: 'Cluster',
  Plan: 'Plan',
};

let PlanLinks = [
  {
    'Title': 'RLP (Act + Fcst)',
    'url': 'RLPActFcst',
  },
  {
    'Title': 'Trade Price Reduction',
    'url': 'TradePriceReduction',
  },
  {
    'Title': 'Consumer Promotions',
    'url': 'consumerpromotions',
  },
  {
    'Title': 'Trial Discounts',
    'url': 'trialdiscounts',
  },
  {
    'Title': 'Displays',
    'url': 'displays',
  },
  {
    'Title': 'Conversion Funds',
    'url': 'conversionfunds',
  },
  {
    'Title': 'MR and Ecomm TFIs',
    'url': 'mrandecommtfis',
  },
];

export const useStyles = makeStyles(theme => ({
  confirmationDialoge: {
    '& .MuiDialog-paperWidthSm': {
      width: '600px',
      height: '320px',
    },
  },
  para: {
    fontSize: '28px !important',
    fontWeight: 600,
    color: '#000',
  },
  donwloadBtn: {
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    marginRight: 10,
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      maxHeight: '36px',
      '& span': {
        whiteSpace: 'nowrap',
      },
    },
  },
  updateBtnStyle: {
    backgroundColor: '#ff6503 !important',
  },
  removeReconBtn: {
    backgroundColor: 'red !important',
    color: 'white',
  },
  disabledBtn: {
    opacity: '0.65',
    pointerEvents: 'none',
  },
  disabledBtnCursor: {
    cursor: 'not-allowed',
  },
  headerMainCon: {
    height: '60px',
    display: 'flex',
    borderRadius: '10px',
    alignItems: 'center',
    marginBottom: '8px',
    backgroundColor: '#fff',
    maxWidth: '95vw',
    overflow: 'auto',
    justifyContent: 'space-between',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.8em',
      height: '0.8em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#cdcdcd',
      outline: '1px solid slategrey',
    },
  },
  leftHeaderCon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightHeaderCon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headingBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  circularBar: {
    color: 'rgb(25, 118, 210)',
    height: 20,
    width: 20,
  },
  planFilter: {
    display: 'flex',
    border: '2px solid #ddd',
    borderRadius: 8,
    paddingLeft: 10,
    marginRight: 10,
    '& span': {
      fontWeight: 'bold',
      alignSelf: 'center',
    }
  },
  active: {
    border: '2px solid tomato'
  },
  talkSheetDiv: {
    margin: '10px',
    float: 'left'
  },
  table: {
    padding: '5px',
    maxWidth: '300px',
    background: '#fff',
    borderRadius: '8px'
  },
  MetricHeader: {
    display: 'block',
    padding: '5px 2px',
    fontWeight: 600,
    borderBottom: '1px solid #ccc',
    color: 'rgb(16, 114, 189)',
  },
  MetricText: {
    display: 'block',
    padding: '2px',
  }
}));
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const PlanHeader = props => {
  const classes = useStyles();
  const [link, setLink] = useState(props.page.name ? props.page.name : 'Trade Price Reduction');
  const [url, setUrl] = useState('');
  const [alertMessageText, setAlertMessage] = useState('');
  const [open, setOpen] = useState(false);
  const { month, qtr, brandForm, channel, cluster, plan } = props.filterValues;
  const { dataSource, modalData, readOnly, saveButtonState, selectBrand, rowSelected, downloadLoading1,
    fieldData, filters, customChannel } = props;
  const [openSetupData, setOpenSetupData] = useState(false);
  const [openTalkSheet, setOpenTalkSheet] = useState(false);
  const [successState, setSuccess] = useState({
    isOpen: false,
    message: ''
  })
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const talkSheetFilterData = props.talkSheetFilterData;

  const handleChange = (value, url) => {
    setUrl(url);
    if (saveButtonState) {
      handleClickOpen();
      setAlertMessage({
        title: 'Confirmation',
        p1: `You have unsaved changes in ${link}.`,
        p2: 'Are you sure you want to discard these changes?',
      });
    } else {
      setLink(value);
      props.history.push(url);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e, res) => {
    setOpen(false);
    if (e) {
      if (typeof alertMessageText === 'string') {
        deleteCurrentFFData(); // delete current ff data case
      } else {
        props.history.push(url);
        props.clearModifyTPRs()
        props.clearModifyConsumePromo()
        props.clearModifyConversionFunds()
        props.clearModifyDisplays(),
          props.clearModifyTFIs()
        props.clearModifyTrialsDiscount()
      }
    }
  };

  const renderSaveButton = () => {
    return (
      <Button className={classNames('btn btn-primary  tpr-button m-right-5')}
        color="primary" variant="contained" onClick={() => props.onAction(ActionType.Save)}
        size="medium"
        disabled={props.saveBtnDisabled}
        startIcon={<SaveIcon />}
      >
        <span>Save</span>
      </Button>
    );
  };

  const renderSaveAndReconBtn = () => {
    return (
      <Button className={classNames('btn btn-primary  tpr-button m-right-5')}
        color="primary" variant="contained" onClick={() => props.onAction(ActionType.SaveAndRecon)}
        size="medium"
        startIcon={<SaveIcon />}
      >
        <span>Recon</span>
      </Button>
    );
  };

  const renderHeaderButton = (label, onBtnPress) => {
    return (
      <Button
        className={classNames(`btn btn-primary tpr-button m-right-10 ${rowSelected === '' && label === 'Duplicate Plan' ? classes.disabledBtnCursor : ''}`)}
        color="primary" variant="contained" onClick={() => onBtnPress()}
        disabled={(rowSelected === '' && label === 'Duplicate Plan') || (label === 'New Plan' && props.newPlanDisabled)}
        style={{ color: '#fff' }}
      >
        <span
          className={`${rowSelected === '' && label === 'Duplicate Plan' ? classes.disabledBtn : ''}`}>{label}</span>
      </Button>
    );
  };

  const setDeleteCurrentFFConfirmHandler = () => {
    setAlertMessage(`Are you sure you want to permanently delete ${props.page.name} data for ${getMonth(selectBrand.CurrentFF, 'month')} FF?`)
    setOpen(true)
  }

  const deleteCurrentFFData = () => {
    let { BrandName, FinancialYear, CurrentFF } = props.brand;
    let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
    m = m.length === 1 ? '0' + m : m;
    let data = {
      brand: BrandName,
      currentFF: m,
      fiscalYear: FinancialYear,
      formType: planTypeByPageName[props.page.name],
    };
    if(props.sptprData?.selectScenario) data['scenario'] = props.sptprData?.selectScenario;
    let url = `${API_BASE_URL}/setupdata/delete/currentff`;
    axios({
      url: url,
      method: 'DELETE',
      data: data,
      headers: {
        appId: props.match.params.appid,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      setSuccess({ isOpen: true, confirmMessage: response.data?.message });
      props.onAction(ActionType.RemoveRecon)
    })
      .catch((error) => {
        console.log(error);
        setSuccess({ isOpen: true, confirmMessage: error.message ? error.message : 'Something went wrong' });
      });
  };

  const onSuccessResponse = (resp) => {
    setSuccess({ isOpen: false, successMessage: '' })
  }

  const handlePlanClick = (event, key) => {
    setAnchorEl({ [key]: event.currentTarget });
  }

  const getChannels = () => {
    return customChannel ? customChannel : getFilteredChannelList(props.sptprData.filterData?.channels)
      .filter(item => item.channel !== 'All Channels').map(i => i.channel);
  }

  const isMonthFilterActive = month?.length && !(12 === month?.length)
  const isQtrFilterActive = qtr?.length && !(4 === qtr?.length)
  const isBrandFormFilterActive = brandForm?.length && !(props.sptprData.filterData?.brands?.length === brandForm?.length)
  const isChannelFilterActive = channel?.length && !(getChannels().length === channel?.length)
  const isClusterFilterActive = cluster?.length && !(props.sptprData.filterData?.clusters?.length === cluster?.length)
  const isPlanFilterActive = plan?.length && !(dataSource.filterData?.plans?.length === plan?.length)

  const renderMultiSelectFilter = (type, isActive, label) => (<div className={classNames(classes.planFilter, isActive ? classes.active : '')}>
    <span style={{ whiteSpace: 'nowrap' }}>{label ? label : capitalize(type)}</span><IconButton style={{ padding: 10, color: isActive ? 'tomato' : '' }}
      onClick={(e) => handlePlanClick(e, type)}><FilterListIcon style={{ fontSize: 14 }} /></IconButton></div>)

  const Filters = {
    'months': renderMultiSelectFilter('months', isMonthFilterActive),
    'quarters': renderMultiSelectFilter('quarters', isQtrFilterActive),
    'brandForms': renderMultiSelectFilter('brandForms', isBrandFormFilterActive),
    'channels': renderMultiSelectFilter('channels', isChannelFilterActive, props.page.name === "MR and Ecomm TFIs" ? 'Sub Channels' : null),
    'clusters': renderMultiSelectFilter('clusters', isClusterFilterActive),
    'plans': renderMultiSelectFilter('plans', isPlanFilterActive),

  };

  const downloadTemplate = () => {
    let uri = API_BASE_URL + `/sptemplate/download`;
    setDownloadLoading(true)
    axios({
      url: uri,
      method: 'GET',
      responseType: 'blob',
      headers: {
        appId: props.match.params.appid,
      },
    })
      .then((response) => {
        FileDownload(response.data, 'TradeSpend_Upload_Templates.zip');
        setDownloadLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setDownloadLoading(false)
      });
  }

  const onClose = (isSave, values, e, type) => {
    if (isSave) {
      let AllTypes = {
        months: 'Month',
        quarters: 'Qtr',
        brandForms: 'Brandform',
        channels: 'Channel',
        clusters: 'Cluster',
        plans: 'Plan',
      }
      props.onFilterChange(AllTypes[type], { value: values })
    }
    if (anchorEl.current && anchorEl.current.contains(e.target)) {
      return;
    }
    setAnchorEl(null)
  }

  const multiSelectRenderer = [
    {
      val: month,
      allValues: monthsShortForm,
      visible: anchorEl ? anchorEl['months'] : null,
      type: 'months',
      isCapitalize: true,
    },
    {
      val: qtr,
      allValues: ['JAS', 'OND', 'JFM', 'AMJ'],
      visible: anchorEl ? anchorEl['quarters'] : null,
      type: 'quarters'
    },
    {
      val: brandForm,
      allValues: props.sptprData.filterData?.brands.map(i => i.BrandFormName),
      visible: anchorEl ? anchorEl['brandForms'] : null,
      type: 'brandForms'
    },
    {
      val: channel,
      allValues: getChannels(),
      visible: anchorEl ? anchorEl['channels'] : null,
      type: 'channels'
    },
    {
      val: cluster,
      allValues: props.sptprData.filterData?.clusters.map(i => i.ClusterName),
      visible: anchorEl ? anchorEl['clusters'] : null,
      type: 'clusters'
    },
    {
      val: plan,
      allValues: dataSource.filterData?.plans,
      visible: anchorEl ? anchorEl['plans'] : null,
      type: 'plans'
    }
  ]

  return <>
    <div className={classes.headingBox}>
      {!modalData &&
        <div className="tab-container m-bottom-10">
          {PlanLinks.map((gLink, i) => {
            const url = `/apps/${props.match.params.appid}/${gLink.url}`;
            return <div key={i}
              className={`setup-data-headings p-bottom-10 p-right-20 p-left-20 ${gLink.Title === props.page.name ? `active-global-link` : ``}`}
              onClick={() => {
                handleChange(gLink.Title, url);
              }}>
              <span className={gLink.Title === props.page.name ? 'select-global-link' : 'normal-link'}>{gLink.Title}</span>
            </div>;
          })}
        </div>}
      {!modalData &&
        <Button
          title={"Template Files for Upload"}
          onClick={downloadTemplate}
          startIcon={<GetAppIcon />}
          variant="text"
          size="small"
          disabled={downloadLoading}
          style={{ height: 30, color: 'rgb(25, 118, 210)' }}
        >
          {
            downloadLoading ? <CircularProgress className={classes.circularBar} size={24} /> : 'Template'
          }      </Button>
      }
    </div>
    <div className={classes.talkSheetDiv}>
      <Table className={classNames(classes.table, 'tpr-table')} aria-label="simple table" stickyHeader>
        <TableBody className={classes.tableHead}>
          <TableRow>
          {talkSheetFilterData && talkSheetFilterData.map((item) => (
            <TableCell align="center">
              <span className={classes.MetricHeader}>{item?.talkSheet?.label}</span>
              <span className={classes.MetricText}>{item?.BOP_FY ? item?.UoM === '$M' ? Math.round(item?.BOP_FY) : `${Number(item?.BOP_FY).toFixed(2)}%` : 0}</span>
            </TableCell>
          ))
        }
        </TableRow>
        </TableBody>
      </Table>
    </div>
    <div style={{ marginBottom: 10 }}>
      {!readOnly ? (
        <div className={classes.btnGroup}>
          <Button
            onClick={() => props.onAction(ActionType.DoneUpdates)}
            className={classNames('btn btn-primary btn-color m-right-10', classes.updateBtnStyle)}>
            {`Done Updates  ${getMonth(selectBrand.CurrentFF, 'month')} ${getFiscalYearFormat(selectBrand.FinancialYear)}`}
          </Button>
          {renderHeaderButton('Setup Data', () => setOpenSetupData(true))}
          {renderHeaderButton('Talk Sheet', () => setOpenTalkSheet(true))}
        </div>
      ) : null}
    </div>
    <div style={{display: 'block', clear: 'both'}}></div>
    <div className={classNames(classes.headerMainCon, 'filter-container')}>
      <div className={classes.leftHeaderCon}>
        {
          filters.map((i, index) => {
            return <div key={index}>{Filters[i]}</div>
          })
        }

      </div>
      <div className={classes.rightHeaderCon}>
        {(!readOnly && saveButtonState) && (
          <div className={classes.btnGroup}>
            {renderSaveButton()}
            {props.page.name === 'Trade Price Reduction' && renderSaveAndReconBtn()}
          </div>
        )}
        <div className={classes.btnGroup}>
          {!readOnly ? (
            <>
              {renderHeaderButton('New Plan', () => props.onAction(ActionType.NewPlan))}
              {renderHeaderButton('Duplicate Plan', () => props.onAction(ActionType.Duplicate))}
            </>
          ) : null}
          {!modalData ? (
            <>
              {
                fieldData?.length ?
                  (<Button
                    variant="contained"
                    color="primary"
                    className={classNames(classes.donwloadBtn, 'm-right-10')}
                    size="medium"
                    onClick={() => props.onAction(ActionType.Delete)}
                    startIcon={<DeleteIcon />}
                  >
                    {dataSource?.deleteLoading ? <CircularProgress className={classes.circularBar} size={24} /> : 'Delete'}
                  </Button>)
                  : null
              }

              <Button
                variant="contained"
                color="primary"
                className={classes.donwloadBtn}
                size="medium"
                onClick={() => props.onAction(ActionType.Download)}
                startIcon={<GetAppIcon />}
              >
                {downloadLoading1 ? <CircularProgress className={classes.circularBar} size={24} /> : 'Download'}
              </Button>
              {props.page.name !== 'RLP (Act + Fcst)' && <UploadPlanning
                {...props}
                handleSave={(files) => {
                  props.onAction(ActionType.Upload)
                }}
                acceptedFiles={[
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.ms-excel',
                  'application/x-csv',
                  'application/csv',
                  'text/csv',
                  'text/x-csv',
                  'text/tab-separated-values',
                  'text/comma-separated-values',
                  'text/x-comma-separated-values',
                ]} />}
              {props.page.name !== 'RLP (Act + Fcst)' && <Button
                variant="contained"
                className={classes.removeReconBtn}
                size="medium"
                onClick={setDeleteCurrentFFConfirmHandler}
              >
                {`Remove ${getMonth(selectBrand.CurrentFF, 'month')} ${getFiscalYearFormat(selectBrand.FinancialYear)} Data`}
              </Button>}
            </>
          ) : null}

        </div>
      </div>
    </div>

    <ConfirmDialog open={open} handleClose={handleClose} alertMessageText={alertMessageText}
      classes={classes} confirm={true} />
    <div>
      {openTalkSheet && <TalkSheetModal open={openTalkSheet} handleClose={() => setOpenTalkSheet(false)} />}
      {openSetupData &&
        <SetupDataDialog open={openSetupData} handleClose={() => setOpenSetupData(false)} pages={props.pages}
          row={fieldData[rowSelected] ? fieldData[rowSelected] : {}} />
      }
      <ConfirmDialog open={successState.isOpen} handleClose={onSuccessResponse}
        alertMessageText={successState.confirmMessage} classes={classes} />
    </div>
    {
      multiSelectRenderer.map(ii => {
        if (filters.indexOf(ii.type) > -1) {
          return Boolean(ii.visible) && <FilterMultiSelect key={ii.type} allValues={ii.allValues} anchorEl={ii.visible} isCapitalize={ii.isCapitalize}
            id={Boolean(ii.visible) ? 'transitions-popper' : undefined}
            selectedValues={ii.val} onClose={(e1, e2, e3) => onClose(e1, e2, e3, ii.type)} isOpen={Boolean(ii.visible)} />
        }
      })
    } 
  </>;
};

const mapDispatchToProps = {
  clearModifyTPRs,
  clearModifyConsumePromo,
  clearModifyConversionFunds,
  clearModifyDisplays,
  clearModifyTFIs,
  clearModifyTrialsDiscount,
};
const mapStateToProps = state => {
  return {
    sptprData: state.tprReducer,
  };
};


export default hot(withRouter(connect(null, mapDispatchToProps)(PlanHeader)));
