import { IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterMultiSelect from '../../../FilterMultiSelect';

const useStyles = makeStyles({
    planFilter: {
        border: '2px solid #ddd',
        borderRadius: 8,
        paddingLeft: 10,
        marginRight: 15,
        fontWeight: 'bold',
    },
    active: {
        border: '2px solid tomato'
    }
});

export const ReportFilter = (props) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const visible = Boolean(anchorEl);
    const popperId = visible ? 'transitions-popper' : undefined;

    const openFilter = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const onClose = (isSave, values, e) => {
        if (isSave) {
            props.onChangeFilter(values)
        }
        if (anchorEl.current && anchorEl.current.contains(e.target)) {
            return;
        }
        setAnchorEl(null)
    }

    const isActive = props.value?.length && !(props.value?.length === props.allValues?.length);

    return (
        <div className={classNames(classes.planFilter, isActive ? classes.active : '')} style={{width: props.width}}>
            {props.label}
            <IconButton style={{ padding: 10, color: isActive ? 'tomato' : '' }} onClick={openFilter}>
                <FilterListIcon style={{ fontSize: 14 }} />
            </IconButton>
            {visible && <FilterMultiSelect allValues={props.allValues} anchorEl={anchorEl} id={popperId} isCapitalize = {props.label === 'Month'}
                selectedValues={props.value} onClose={onClose} isOpen={visible} />}
        </div>)
}