import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMonth } from "utility/utils";
import { getMrData, removeMRSuccessMessage } from '../../../../../store/actions/index.js';
import { capitalize } from '../../../../../utility/utils';
import Loader from '../../../../Loader/index';
import PlanningErrorModal from '../../../spendplan/PlanningPages/planningErrorModal';
import { convertToNumber, renderNoDataText, StyledTableCell, StyledTableRow } from '../../../spendplan/PlanningPages/planningHelperFunctions.js';
import { URLs } from '../../ApiConstants.js';
import { shortMonthLowercase } from '../../planingPages/planConfig.js';
import { ReportFilter } from '../reportFilter.js';
import { useStyles } from "../Summary/summarySheetTable";

const FCMPlanningTable = (props) => {

    const classes = useStyles();
    const [account, setAccount] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = useState(false);
    const [alertMessageText, setAlertMessage] = React.useState("");

    const [brandVal, setBrandVal] = useState([]);
    const [brandformVal, setBrandformVal] = useState([]);
    const [allBrandforms, setAllBrandforms] = useState([]);
    const [skuVal, setSKUVal] = useState([]);
    const [allSKUs, setAllSKUs] = useState([]);
    const [parameterVal, setParameterVal] = useState([]);
    const [allParameter, setAllParameter] = useState([])

    const fetchData = () => {
        const account = props.account;
        if (account) {
            let objToSend = {
                fiscalYear: props.modalData?.year || account.FinancialYear,
                account: encodeURIComponent(props.modalData?.account || account.AccountName),
            };
            let m = '' + getMonth(account.CurrentFF, 'monthNumber')
            objToSend.currentFF = props.modalData?.month || (m.length === 1 ? '0' + m : m);
            getDataFromApi(objToSend);
        }
    };

    const getDataFromApi = (params) => {
        let url = URLs.getFCMPlanning
        url += `?account=${params.account}&fiscalYear=${params.fiscalYear}&currentFF=${params.currentFF}`
        let payload = {
            url: props.modalData ? url + ('&version=true') : url,
            appId: props.match.params.appid,
            dataUniqueId: 'GET_FCMPlanning'
        }
        props.getMrData(payload)
    }

    useEffect(() => {
        if (props.account?.AccountName !== account?.AccountName) {
            fetchData();
            setAccount(props.account)
        }
    }, [props.account]);

    useEffect(() => {
        if (props.data && props.data['GET_FCMPlanning'] && !(props.error ? props.error['GET_FCMPlanning'] : false)) {
            let _data = props.data['GET_FCMPlanning'].result.data;
            if (props.modalData) {
                if (_data[0] && _data[0].detail) {
                    setTableData(_data[0].detail || [])
                }
            } else {
                setTableData(_data)
            }
        } else if (tableData.length) {
            setTableData([])
        }
    }, [props.data ? props.data['GET_FCMPlanning'] : null]);


    useEffect(() => {
        if (props.mrData.error && props.mrData.error['GET_FCMPlanning']) {
            setOpen(true)
            setAlertMessage({
                title: 'FCM Planning',
                p1: props.mrData.error['GET_FCMPlanning']?.message || 'Something went wrong',
            });
        }
    }, [props.mrData.error]);

    const isMonthForecast = (monthKey) => {
        // monthKey: jan, feb ,mar
        if (props.account?.CurrentFF && shortMonthLowercase.indexOf(monthKey) + 1 > shortMonthLowercase.indexOf((moment().month(props.account.CurrentFF).format('MMM').toLowerCase()))) {
            return true;
        }
        return null
    }
    const handleClose = () => {
        setOpen(false);
        props.removeMRSuccessMessage({ dataUniqueId: 'GET_FCMPlanning' });
    };

    const getMonths = (isForeCast) => {
        return shortMonthLowercase.map((i) => {
            if (isForeCast) {
                if (!isMonthForecast(i)) {
                    return i;
                }
            } else {
                if (isMonthForecast(i)) {
                    return i;
                }
            }
        }).filter(Boolean)
    }

    const getData = () => {
        return tableData?.filter(item => {
            return (brandVal?.length ? brandVal.includes(item.brand) : true) && (brandformVal?.length ? brandformVal.includes(item.brandform) : true) && 
            (skuVal?.length ? skuVal.includes(item.sku) : true) && (parameterVal?.length ? parameterVal.includes(item.formType) : true);
        }) || []
    }

    let _data = (props.data && props.data['GET_FCMPlanning']) ? props.data['GET_FCMPlanning'].result.data : [];
    useEffect(() => {
        if (_data?.length) {
            setAllSKUs([... new Set(_data?.map(i => i.sku))])
            setAllParameter([... new Set(_data?.map(i => i.formType))])
            setAllBrandforms([... new Set(_data?.map(i => i.brandform))])
        }
    }, [_data])

    const onChangeFilter = (type, values) => {
        if (type === 'brand') {
            setBrandVal(values)
            let _allBrandForms = [... new Set(_data.filter(i => values.includes(i.brand)).map(i => i.brandform))];
            setAllBrandforms(_allBrandForms)
            setBrandformVal([])
        }
        if (type === 'brandform') {
            setBrandformVal(values)
        }
        if (type === 'sku') {
            setSKUVal(values)
        }
        if (type === 'parameter') {
            setParameterVal(values)
        }
    }

    const getBrandFilterValues = () => {
        return _data?.length ? [... new Set(_data?.map(i => i.brand))] : []
    }


    if (props.mrData && props.mrData['GET_FCMPlanning' + '_loading']) {
        return (
            <section className={classes.tableBodyLoading}>
                <Loader />
            </section>
        )
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', marginLeft: 10, }}>
                <ReportFilter id='brandFilter' label='Brand' value={brandVal} onChangeFilter={v => onChangeFilter('brand', v)} allValues={getBrandFilterValues()} />
                <ReportFilter id='brandformFilter' label='Brandform' onChangeFilter={v => onChangeFilter('brandform', v)} value={brandformVal} allValues={allBrandforms} />
                <ReportFilter id='skuFilter' label='SKU' value={skuVal} onChangeFilter={v => onChangeFilter('sku', v)} allValues={allSKUs} />
                <ReportFilter id='parameterFilter' label='Parameter' onChangeFilter={v => onChangeFilter('parameter', v)} value={parameterVal} allValues={allParameter} />
            </div>
            <TableContainer className={classNames(classes.tableContainer, "tpr-container")} component={Paper}>
                <Table className={classNames(classes.table, "tpr-table")} aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell rowSpan={3}>
                                <span>Brand</span>
                            </TableCell>
                            <TableCell rowSpan={3}>
                                <span>Brandform</span>
                            </TableCell>
                            <TableCell rowSpan={3}>
                                <span>SKU</span>
                            </TableCell>
                            <TableCell rowSpan={3}>
                                <span>Parameter</span>
                            </TableCell>
                            <TableCell colSpan={12} style={{ borderBottom: 'none' }}>
                                <span>Retailing</span>
                            </TableCell>
                            <TableCell colSpan={12} style={{ borderBottom: 'none' }}>
                                <span>MSU</span>
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            {
                                Boolean(getMonths(true).length) &&
                                <TableCell colSpan={getMonths(true).length} style={{ borderBottom: 'none' }}>
                                    <p className={classes.combinedHeaderHeading}>Actuals</p>
                                </TableCell>
                            }
                            {
                                Boolean(getMonths(false).length) &&
                                <TableCell colSpan={getMonths(false).length} style={{ borderBottom: 'none' }}>
                                    <p className={classes.combinedHeaderHeading}>Forecast</p>
                                </TableCell>
                            }
                            {
                                Boolean(getMonths(true).length) &&
                                <TableCell colSpan={getMonths(true).length} style={{ borderBottom: 'none' }}>
                                    <p className={classes.combinedHeaderHeading}>Actuals</p>
                                </TableCell>
                            }
                            {
                                Boolean(getMonths(false).length) &&
                                <TableCell colSpan={getMonths(false).length} style={{ borderBottom: 'none' }}>
                                    <p className={classes.combinedHeaderHeading}>Forecast</p>
                                </TableCell>
                            }
                        </TableRow>
                        <TableRow>
                            {
                                Boolean(getMonths(true).length) && getMonths(true).map((month, index) => {
                                    return (<TableCell key={index}>
                                        <span>{capitalize(month)}</span>
                                    </TableCell>)
                                })
                            }
                            {
                                Boolean(getMonths(false).length) && getMonths(false).map((month, index) => {
                                    return (<TableCell key={index}>
                                        <span>{capitalize(month)}</span>
                                    </TableCell>)
                                })
                            }
                             {
                                Boolean(getMonths(true).length) && getMonths(true).map((month, index) => {
                                    return (<TableCell key={index}>
                                        <span>{capitalize(month)}</span>
                                    </TableCell>)
                                })
                            }
                            {
                                Boolean(getMonths(false).length) && getMonths(false).map((month, index) => {
                                    return (<TableCell key={index}>
                                        <span>{capitalize(month)}</span>
                                    </TableCell>)
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {getData().length ? getData().map((row, index) => {
                            return (
                                <StyledTableRow key={index} >
                                    <StyledTableCell className={classes.alignLeft}>
                                        <span>{row.brand}</span>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.alignLeft}>
                                        <span>{row.brandform}</span>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.alignLeft}>
                                        <span>{row.sku}</span>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span>{row.formType}</span>
                                    </StyledTableCell>
                                    {
                                        getMonths(true).map((month, ii) => {
                                            return <StyledTableCell key={ii} title={row.retailing[month]}>{row.retailing ? convertToNumber(row.retailing[month], 1).toLocaleString('en-In') : ''}</StyledTableCell>

                                        })
                                    }
                                    {
                                        getMonths(false).map((month, ii) => {
                                            return <StyledTableCell key={ii} title={row.retailing[month]}>{row.retailing ? convertToNumber(row.retailing[month], 1).toLocaleString('en-In') : ''}</StyledTableCell>
                                        })
                                    }
                                    {
                                        getMonths(true).map((month, ii) => {
                                            return <StyledTableCell key={ii} title={row.msu[month]}>{row.msu ? convertToNumber(row.msu[month], 1).toLocaleString('en-In') : ''}</StyledTableCell>

                                        })
                                    }
                                    {
                                        getMonths(false).map((month, ii) => {
                                            return <StyledTableCell key={ii} title={row.msu[month]}>{row.msu ? convertToNumber(row.msu[month], 1).toLocaleString('en-In') : ''}</StyledTableCell>
                                        })
                                    }
                                </StyledTableRow>)

                        }) :
                            renderNoDataText()
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} />

        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        account: state.mrReducer?.account,
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
    };
};

const mapDispatchToProps = {
    getMrData,
    removeMRSuccessMessage,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps,)(FCMPlanningTable)),);

