import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	card: {
		minHeight: "56vh",
		height: "56vh",
		backgroundColor: '#222222',
		borderRadius: '0px'

	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});
let d = {
	title: "Shrimp and Chorizo Paella",
	subheader: "September 14, 2016",
	"body": "This is simple body, It can be multiple lines.",
	"footer": "This is footer text"
}
const SimpleCard = (props) => {
	const [data, setData] = useState(null);
	const [style, setStyle] = useState({});
	const [chartClassName, setChartClassName] = useState("");
	const classes = useStyles();

	useEffect(() => {
		setData(props.data)
		let s = props.data && isJSON(props.data.style)
			? { ...style, ...(JSON.parse(props.data.style)) } : { ...style };
		let chartClassName = props.data && props.data.className;
		setStyle(s);
		setChartClassName(chartClassName);
	}, [props.data]);

	return (
		<React.Fragment>
			{data ? <Card className={classes.card + ' ' + chartClassName} style={style}
				variant="outlined">
				<CardHeader
					title={data.title}
					subheader={data.subheader}
				/>
				<CardContent>
					<Typography variant="body2" component="p">
						{data.body}
						<br />
					</Typography>
				</CardContent>
				<CardActions>
					<Button size="small">{data.footer}</Button>
				</CardActions>
			</Card>
				: ''}
		</React.Fragment>
	);
}

export default SimpleCard;