import { makeStyles } from "@material-ui/core"

export const typeWiseColumns = {
    corpbud: ["", "channel", "qtr", "month", "metrics", "uoM"],
    mrecomm: ["", "qtr", "month", "channel", "subChannel", "planType", "uoM"],
    accrualshc: ["", "spendPool", "spendBucket", "qtr", "uoM"],
    f1inputs: ["", "tsBrandCategory", "ff", "metrics", "uoM", "ffForexJAS", "ffForexOND", "ffForexJFM", "ffForexAMJ",
        "ffForexFY", " ", "firmForexJAS", "firmForexOND", "firmForexJFM", "firmForexAMJ", "firmForexFY"],
    itt: ["", "qtr", "month", "channel", "subChannel", "planType"],
    tdc: ["", "brandCategory", "tsBrandforms", "tdcSuFirm", "tdcExposureToUsd", "transactionImpactSu", "translationImpactSu",
        "finalTdcSu", "dsdsGos", "odsGos"],
    cts: ["", "channels", "sellersVisibility", "logistics", "dltAdmin", "totalSalaries", "delivery", "brs", "totalLogistics",
        "wholesale", "subD", "totalDiscounts", "rents", "adminExpenses", "interest", "depreciation", "totalNonOperationalExpenses",
        "totalCTS"]
}


export const commonBrands = ["Oral B", "Vicks", "H&S", "HE", "Pantene", "Tide", "Ariel", "Ambipur", "Pampers", "Old Spice",
    "Gillette", "Whisper", "Olay"]

export const headerView1 = {
    oralB: "Oral B", vicks: "Vicks", hAnds: "H&S", pantene: "Pantene", tide: "Tide", ariel: "Ariel", ambipur: "Ambipur", pampers: "Pampers",
    oldSpice: "Old Spice", gillette: "Gillette", whisper: "Whisper", olay: "Olay", channel: "Channel", qtr: "Qtr", month: "Month",
    metrics: "Metrics", uoM: "UoM", subChannel: "Sub Channel", planType: "Plan Type", spendPool: "Spend pool", spendBucket: "Spend Bucket",
    brand: "Brand", ff: "FF", ffForexJAS: "FF Forex JAS", ffForexOND: "FF Forex OND", ffForexJFM: "FF Forex JFM",
    ffForexAMJ: "FF Forex AMJ", ffForexFY: "FF Forex FY", firmForexJAS: "Firm Forex JAS", firmForexOND: "Firm Forex OND",
    firmForexJFM: "Firm Forex JFM", firmForexAMJ: "Firm Forex AMJ", firmForexFY: "Firm Forex FY", total: 'Total',
    brandCategory: 'Brand/Category', tsBrandforms: "TS Brandforms", tdcSuFirm: "TDC $/SU @ FIRM", tdcExposureToUsd: "TDC Exposure to USD",
    transactionImpactSu: "Transaction Impact $/su", translationImpactSu: "Translation Impact $/su", finalTdcSu: "Final TDC $/su",
    dsdsGos: "DSDS % GOS", 'odsGos': "ODS % GOS", channels: "Channels", sellersVisibility: "Sellers + Visibility", logistics: "Logistics", dltAdmin: "DLT, Admin",
    totalSalaries: "Total Salaries", delivery: "Delivery", brs: "BRS", totalLogistics: "Total Logistics", wholesale: "Wholesale",
    subD: "Sub D", totalDiscounts: "Total Discounts",
    rents: "Rents", adminExpenses: "Admin Expenses", interest: "Interest", depreciation: "Depreciation",
    totalNonOperationalExpenses: "Total Non Operational Expenses",
    totalCTS: "Total CTS", tsBrandCategory: "Brand",
}

export const getDownloadedFileTitle = {
    bopvolumes: 'BOP Volumes',
    rlppermsu: 'RLP per MSU',
    pyrlp: 'Past Year Retailing',
    pysdrlp: 'PY SD%RLP',
    pyrlppermsu: 'Past Year RLP/MSU',
    rlpsalience: 'Channel Retailing Salience',
    rlpytdact: 'YTD Actual Retailing',
    f1inputs: 'F1 Inputs',
    corpbud: 'Corp Bud',
    mrecomm: 'MR & Ecomm',
    accrualshc: 'Accruals & HC',
    itt: 'ITT',
    tdc: 'TDC',
    cts: 'CTS',
}

// add columns here to freeze.
export const readOnlyColumn = {
    "": true,
    brandform: true,
    channel: true,
    qtr: true,
    month: true,
    metrics: true,
    uoM: true,
    subChannel: true,
    planType: true,
    spendPool: true,
    spendBucket: true,
    brand: true,
    ff: true,
    brandCategory: true,
    tsBrandforms: true,
    channels: true, // cts page
    pyamj: true, // rlpPerMsu page,
    sku: true,
    account: true,
    skuType: true,
    metric: true,
    tsBrand: true,
    tsSku: true,
    subBrandForm: true,
    eventName: true,
    custName: true,
    kbd: true,
    productId: true,
    uom: true,
    legalEntity: true,
    buisnessUnit: true,
    category: true,
    type: true,
    account: true,
    eventType: true,
    fySalience: true,
    lastYearSalience: true,
    past3mSalience: true,
    promoMonth: true,
    offTakeValue: true,
    or: true
}
export const highlightedChannelsForSalience = [
    'All HFS Channels',
    'All WS (Trad+Beauty+Pharm)',
    'All Large (Trad+ Beauty+Pharma)',
    'All Medium (Trad+ Beauty+Pharma)',
    'All Small (Trad+ Beauty+Pharma)',
    'Large A (Trad+ Beauty+Pharma)',
    'Large B (Trad+ Beauty+Pharma)',
    'Small A (Trad+ Beauty+Pharma)',
    'All Pharmacy (Large+Med+Small)',
    'All Beauty (Large+Med+Small)',
    'Top 50M stores',
    'Top 200M stores',
    '00 New Combination 1',
    '00 New Combination 2',
    '00 New Combination 3',
    '00 New Combination 4',
    '00 New Combination 5',
]

export const parsePaste = (pasted) => {
    // Check if pasted is larger than grid and warn
    // copy-ing empty cells from Excel will be interpreted as a paste value of zero (last element removed, if it is empty string)
    const pastedSplit = pasted.split(/\r\n|\n|\r/)
        .map(row => {
            console.log("test", row.split("\t"))
            return row.split("\t");
        });
    console.log({ pastedSplit });
    if (pastedSplit[pastedSplit.length - 1][0].length < 1)
        pastedSplit.pop(); // if last element is an empty string, remove it

    return pastedSplit;
}

export const setupPagesWithTotalValues = {'bopvolumes': true, 'pyrlp': true, 'rlpytdact': true, 'corpbud': true, 'mrecomm': true}

export const generateSheetKey = (index) => { // genenrating keys like excel columns A, B .... AA, AB ... AAA, AAB etc.
    let numeric = index % 26;
    let letter = String.fromCharCode(65 + numeric);
    let num2 = parseInt(index / 26);
    if (num2 > 0) {
        return generateSheetKey(num2 - 1) + letter;
    } else {
        return letter;
    }
}
export const useStyles = makeStyles(theme => ({
	tableContainer: {
		maxWidth: "95vw",
		// margin: "40px auto",
		overflowY: "auto",
		height: 'calc(100vh - 335px) !important',
		borderRadius: "8px",
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '0.8em',
			height: '0.8em'
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
			webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#cdcdcd',
			outline: '1px solid slategrey',
		},
	},
	table: {
		minWidth: 700,
	},
	tableHead: {
		"& th": {
			borderRight: "1px solid rgba(224, 224, 224, 1)"
		},
		"& span": {
			fontWeight: "bold"
		},
	},
	tableBody: {
		"& td": {
			borderRight: "1px solid rgba(224, 224, 224, 1)",
			cursor: "pointer"
		},
		"& p": {
			display: "flex"
		}
	},
	tableInput: {
		border: "0",
		background: "transparent",
		maxWidth: "55px",
		appearance: "none",
	},
	quarterHead: {
		paddingRight: "5px",
		borderRight: "1px solid #7b7272",
		marginRight: "5px",
	},
	quarterBody: {
		paddingRight: "5px",
		marginRight: "5px",
	},
	greenFont: {
		"& span": {
			color: "green"
		}
	},
	confirmationDialoge: {
		'& .MuiDialog-paperWidthSm': {
			height: '320px',
			width: '600px'
		}
	},
	para: {
		fontSize: '28px !important',
		fontWeight: 600,
		color: '#000'
	},
	headerMainCon: {
		maxWidth: '95vw',
		// overflowX: 'scroll'
	},
	headerPaperContainer: {
		minWidth: '1066px'
	},
	setupConfirmationDialog: {
		'& .MuiDialog-paperWidthSm': {
			height: '320px',
			width: '600px'
		}
	},
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 400px)',
    },
}))

export const setSalienceColumnBold = (type, value, value1, isTPR) => {
    // value ---> channel
    // value1 ---> months value
    if (type === 'rlpsalience') {
        if (value === 'All Channels' || value === 'Channel Combinations') {
            if (parseFloat(Number(value1).toFixed(2), 0) !== 100 && value === 'All Channels' && !isTPR) { // if value more than 100 show background red in salience page
                return ' bg-red'
            } else {
                return ' highlighted-row-1';
            }
        } else if (highlightedChannelsForSalience.includes(value)) {
            return ' highlighted-row-2';
        }
    }
    return '';
}