import React, { Component } from 'react';
import {
	withStyles, Icon, Table, TableBody, TableCell,
	Button, TablePagination, TableRow, Checkbox
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import TableHead from './TableHeadOld';
import _ from 'modules/lodash';
import history from 'modules/history';
import { AppService } from 'modules/api/index';
import { request } from 'modules/client';

import {
	getFilteredArray,
	handleSelectedRow,
	isRowselected,
	requestSort,
	handleTableSelectAllClick,
} from 'modules/page/common';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_THEME_DESIGN = process.env.REACT_APP_THEME_DESIGN;
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';
const styles = theme => ({
	root: {}
});

class CustomTable extends Component {
	state = {
		order: 'asc',
		orderBy: null,
		selected: [],
		data: [["Loading Data..."]],
		page: 0,
		count: 10,
		rowsPerPage: 10,
		searchText: "",
		dataElements: []
	};

	componentWillUnmount() {
	}

	handleRequestSort = (event, property) => {
		let obj = requestSort(event, property, this.state)
		this.setState(obj);
	};

	handleSelectAllClick = event => {
		this.setState({ selected: handleTableSelectAllClick(event, this.state.data.datasets) });
	};

	handleCheck = (event, id) => {
		console.log('====================================');
		console.log('handleCheck====', event, id);
		console.log('====================================');
		const { selected } = this.state;
		let newSelected = handleSelectedRow(event, id, selected)
		this.setState({ selected: newSelected });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value, page: 0 });
	};

	componentDidMount() {
		this.getApiData(this.state.page, this.state.rowsPerPage);
		var modaldata = this.getDataModel();
		if (modaldata) {
			modaldata.then(res => {
				this.setState({ dataElements: res.dataElements })
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.page != this.state.page
			|| prevState.rowsPerPage != this.state.rowsPerPage) {
			this.getApiData(this.state.page, this.state.rowsPerPage);
			var modaldata = this.getDataModel();
			if (modaldata) {
				modaldata.then(res => {
					this.setState({ dataElements: res.dataElements })
				})
			}
		}
		if(prevProps.chartData.conditions !== this.props.chartData.conditions){	
			this.getApiData(this.state.page, this.state.rowsPerPage);
		}
	}

	getDataModel() {
		let { chartData } = this.props;
		if (chartData.selectedDataModel) {
			const uri = API_BASE_URL + "/dataModels/" + chartData.selectedDataModel
			return request(uri, {
				method: 'GET',
				headers: { appId: this.props.appId }
			})
		}
	}

	getTableHeader = (data) => {
		let tableHeader = [];
		let { chartData } = this.props;
		if (chartData.selectedDataModel && chartData.selectedDataElement
			&& !chartData.loadChildData) {
			chartData.selectedDataElement.map(element => {
				let { label, value } = element;
				if (value != '_id') {
					let headerItem = {
						id: label.replace(' ', ''),
						name: value,
						label: label,
						disablePadding: false,
						numeric: false,
						sort: true
					}
					tableHeader.push(headerItem)
				}
			})
		} else {
			if (data && data.length > 0) {
				for (let key in data[0]) {
					if (key != '_id') {
						let headerItem = {
							id: key.replace(' ', ''),
							name: key,
							label: key,
							disablePadding: false,
							numeric: false,
							sort: true
						}
						tableHeader.push(headerItem)
					}
				}
			}
		}
		return tableHeader;
	};

	setDatafromApi = (res) => {
		if (res && Array.isArray(res.data) && !res.data.columns && !res.data.datasets) {
			let tableHeader = this.getTableHeader(res.data);
			let r = {
				columns: tableHeader,
				datasets: res.data
			}
			this.setState({
				resData: r, data: r, isLoading: false,
				count: res.total ? res.total : res.data.length
			});
		} else if (res.columns && res.datasets) {
			this.setState({ data: res, isLoading: false, count: res.total ? res.total : res.datasets.length });
		} else {
			throw new Error('Expect data is object with columns and datasets keys');
		}
	}

	getApiDataFromDataUrl = (url) => {
		AppService.getApiDataFromDataUrl(url, this.props.appId)
			.then(res => {
				this.setDatafromApi(res)
			})
	}

	getApiData = (page, rowsPerPage) => {
		this.setState({ isLoading: true });
		let { chartData } = this.props;
		if ((chartData.selectedDataModel && (chartData.selectedDataElement)
			|| (chartData.loadChildData && chartData.parentDataElement))) {
			let targetURL = API_BASE_URL + "/dataconnect/table/" + chartData.selectedDataModel;
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			// targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
			targetURL += `?type=list&`;
			if (chartData.loadChildData) {
				if (chartData.parentDataElement)
					targetURL += `&parentDataElement=${chartData.parentDataElement}`;
				else
					targetURL += '&fields=';
			} else
				targetURL += chartData.selectedDataElement.reduce(reducer, '&fields=');
			targetURL += '&page=' + page + '&rowsPerPage=' + rowsPerPage;
			if (this.props.queryString) {
				targetURL += '&' + this.props.queryString;
			}
			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (this.props.page.conditions && this.props.page.conditions.length > 0) {
				let cond = JSON.stringify(this.props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
      let encodedData = encodeURIComponent(targetURL)
      let cond = JSON.stringify(encodedData);
			this.getApiDataFromDataUrl(cond);
		} else if (this.props.dataUrl) {
			this.getApiDataFromDataUrl(props.dataUrl);
		} else {
			this.setState({ data: this.props.data, isLoading: false, count: this.props.data.datasets.length });
		}
	}

	handleMoreClick = (e) => {
		console.log("handleMoreClick", e);
		history.push({
			pathname: `${this.props.chartData.extendedTablePage.url}`
		});
	}

	handleAddClick = (e) => {
		console.log("handleAddClick", e);
		history.push({
			pathname: `/apps/${this.props.appId}/form/${this.props.chartData.associatedFormName}/new`
		});
	}

	getPageUrl = (selectedLinkValue) => {
		let page = this.props.pages.find(p => {
			return p._id === selectedLinkValue
		})
		return page && page.url;
	}

	onSearchChange = (searchText) => {
		console.log("searchText", searchText);
		console.log("data", data);
		let d = { ...data }
		d.datasets = this.state.resData.datasets.filter(ds => {
			let flag = false
			Object.keys(ds).map((k) => {
				console.log("ds", ds, k, ds[k]);

				if (String(ds[k]).includes(searchText))
					flag = true
			})
			return flag
		});
		console.log("datatttt => d", d);
		this.setState({ data: d, searchText: searchText });
	}

	onTableChange = (action, tableState) => {

		// a developer could react to change on an action basis or
		// examine the state as a whole and do whatever they want

		switch (action) {
			case 'changePage':
				this.getApiData(tableState.page, tableState.rowsPerPage);
				break;
		}
	}

	onRowClick = (rowData, rowMeta) => {
		console.log('onRowClick');
		let { extendedTablePage, associatedFormName } = this.props.chartData;
		// if (associatedFormName && data.datasets[rowMeta.rowIndex]._id) {
		if (associatedFormName && rowData._id) {
			history.push({
				pathname: `/apps/${this.props.appId}/form/${associatedFormName}/${rowData._id}`
			});
		}
	}

	onCellClick = (colData, cellMeta) => {
		console.log('onCellClick');
		let { selectedDataPages, selectedDataColumns, links,
			extendedTablePage, associatedFormName, name } = this.props.chartData;
		let { data } = this.state;
		if (links && links.length > 0)
			links.map((l, i) => {
				if (cellMeta.name === l.selectedLinkDataElement) {
					let url, search = '';
					if (l.selectedLinkType === "page") {
						let pageUrl = this.getPageUrl(l.selectedLinkValue);
						if (pageUrl)
							url = `/apps/${this.props.appId}/${pageUrl}/${l.selectedLinkDataElement}/${colData[cellMeta.name]}`
					} else {
						if (l.selectedLinkDataModel && l.selectedLinkKey) {
							url = `/apps/${this.props.appId}/form/${l.selectedLinkValue}/new`
							search = `?dm=${l.selectedLinkDataModel}&k=${l.selectedLinkKey}`
							search += `&v=${colData[l.selectedLinkKey]}`
							search += '&page=0&rowsPerPage=10&type=list&fields='
							const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
							if (l.additionalFields)
								search += l.additionalFields.reduce(reducer, '');
						} else
							url = `/apps/${this.props.appId}/form/${l.selectedLinkValue}/${colData._id}`
					}
					if (url) {
						console.log('url', url);
						return history.push({
							pathname: url, search: search
						});
					}
					else
						alert(`Please provide valid links for column ${l.selectedLinkDataElement}`)
				}
			})
	}

	objectByStringKey = (o, s, isCurrency) => {
		s = s.replace(/\[(\w+)\]/g, '.$1');
		s = s.replace(/^\./, '');
		var a = s.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			var k = a[i];
			if (k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		if (isCurrency) {
			o = `$${Number((o || 0).toFixed(1)).toLocaleString()}`;
		}
		return o;
	}


	render() {
		const { order, orderBy, selected, rowsPerPage, page, data } = this.state;
		let { selectedDataPages, selectedDataColumns, links,
			extendedTablePage, associatedFormName, name, selectedDataElement } = this.props.chartData;

		return (<><div className="renderer-new-table">
			<div className="general-table-heading">
				<span>{name}</span>
				{associatedFormName &&
					<Button onClick={this.handleAddClick}
						className="btn btn-primary btn-color border-radius-0 float-right">
						+ NEW
				</Button>}
			</div>
			<div className="w-full flex flex-col report-parent-div">
				<Table className="min-w-xl" aria-labelledby="tableTitle" >
					<TableHead
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onSelectAllClick={this.handleSelectAllClick}
						onRequestSort={this.handleRequestSort}
						rowCount={data.length}
						data={this.state.data}
						chartData={this.props.chartData}
						hasCheckbox={false}
					/>
					<TableBody>
						{_.orderBy(data.datasets, [
							(o) => {
								switch (orderBy) {
									default:
										{
											return o[orderBy];
										}
								}
							}
						], [order])
							// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((n, i) => {
								const isSelected = isRowselected(n._id, this.state.selected);
								return (
									<TableRow
										className="h-64 cursor-pointer"
										style={isIBC ? { backgroundColor: "#fff", color: '#000', borderBottom: '1px solid ##eee' } : {
											// backgroundColor: "#fff",
											backgroundColor: "#181818", color: '#fff',
											borderTop: "1px solid #282828"
										}}
										hover
										role="checkbox"
										aria-checked={isSelected}
										tabIndex={-1}
										key={n._id}
										selected={isSelected}
										onClick={event => this.onRowClick(n, i)}
									>
										{this.props.hasCheckbox
											&& <TableCell className="w-48 pl-4 sm:pl-12" padding="checkbox">
												<Checkbox
													style={{
														backgroundColor: "#fff"
													}}
													checked={isSelected}
													onClick={event => event.stopPropagation()}
													onChange={event => this.handleCheck(event, n._id)}
												/>
											</TableCell>}
										{data && data.columns && data.columns.length > 0 &&
											data.columns.map((c, index) => {
												let { label } = c, isCurrency = false;
												let currentSelDataEle = selectedDataElement.find(a => a.label === label);
												if (currentSelDataEle && this.state.dataElements.length > 0) {
													let currentEleType = this.state.dataElements.find(a => a._id === currentSelDataEle._id);
													if (currentEleType && currentEleType.type === "currency") {
														isCurrency = true;
													}
												}
												return (<TableCell
													key={index}
													component="td" scope="row"
													style={isIBC ? { backgroundColor: "#fff", color: '#000', borderBottom: '1px solid #eee' } : {
														// backgroundColor: "#fff",
														backgroundColor: "#181818", color: '#fff',
														borderTop: "1px solid #282828"
													}}
													onClick={event => this.onCellClick(n, c)}>
													{this.objectByStringKey(n, c.name, isCurrency)}
												</TableCell>)
											})
										}
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					count={this.state.count ? this.state.count : 0}
					className="custom-table-pagination"
					// style={isIBC ? { backgroundColor: "#fff", color: '#000' } : {
					// 	// backgroundColor: "#fff",
					// 	backgroundColor: "#181818", color: '#fff',
					// 	borderTop: "1px solid #282828"
					// }}
					rowsPerPage={rowsPerPage}
					page={page}
					backIconButtonProps={{
						'aria-label': 'Previous Page'
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page',
						'marginTop': '-18px'
					}}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
				/>
			</div>
		</div></>);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({

	}, dispatch);
}

const mapStateToProps = state => {
	return {
		pages: state.pages.page,
	};
};

export default connect(mapStateToProps, null)(CustomTable)