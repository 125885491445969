import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Typography, Button, withStyles, Paper, Grid, TextField, Table, TableBody, TableHead, TableRow, TableCell, FormControlLabel, Checkbox } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios/index';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
import classNames from 'classnames';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: '8px',
        marginRight: '8px',
        width: 200,
    },
});

class DataSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
            selectedData: [],
            dataModel: [],
            dataSelector: [],
            selectedDataObject: null,
            dataSelectorHeader: []
        };
    }

    componentDidMount() {
        // ExternalScripts["testPopulate"]();
        const { dataSelectorDataModal, dataSelectorElement, dataSelectorElementForSearch } = this.props.data
        if (dataSelectorElement) {
            let url = `${API_BASE_URL}/dataconnect/${dataSelectorDataModal}?&type=list&fields=${dataSelectorElement.join()}&page=0&rowsPerPage=10`
            this.getDataModel(url)
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.fillData && !state.selectedDataObject) {
            let dataSelectorHeader = []
            if (props.fillData && props.fillData.length) {
                let row = props.fillData[0]
                let keys = Object.keys(row)

                dataSelectorHeader = keys
            }
            return {
                dataSelectorHeader: dataSelectorHeader,
                selectedDataObject: props.fillData
            }
        }
        return state
    }
    selectData = (item) => {
        const { selectedData } = this.state
        let index = selectedData && selectedData.indexOf(item)
        if (index > -1) {
            let tempData = [...selectedData]
            tempData.splice(index, 1);
            this.setState({
                selectedData: tempData
            })
        } else {
            this.setState({
                selectedData: [...this.state.selectedData, item]
            })
        }

    }

    completedDataSelection = () => {
        const { selectedData } = this.state
        let selectedDataObject = [];
        let dataSelectorHeader = []
        for (let i = 0; i < selectedData.length; i++) {
            selectedDataObject.push(this.state.data[selectedData[i]])
        }
        if (selectedDataObject && selectedDataObject.length) {
            let row = selectedDataObject[0]
            let keys = Object.keys(row)

            dataSelectorHeader = keys
        }
        this.setState({ selectedDataObject, open: false, dataSelectorHeader }, () => this.props.onChangeData(selectedDataObject))
    }

    getDataModel = (url) => {
        axios.get(url, {
            headers: { 
                appId: this.props.match.params.appid,
                'Content-Type': 'application/json',
             }
        })
            .then(res => {
                let rows = res.data.data;
                let header = [];
                if (rows && rows.length) {
                    let row = rows[0]
                    let keys = Object.keys(row)
                    header = keys
                }
                this.setState({ data: rows, header }, () => {
                    const { fillData } = this.props;
                    const { data } = this.state;
                    let _selectedData = fillData && fillData.map(item => {
                        let index = (data || []).findIndex(x => x._id === item._id);
                        return index
                    })
                    this.setState({
                        selectedData: _selectedData || []
                    })
                })
            }).catch((err) => {
                alert("Unable to fetch data from the provided endpoint" + err);
            })
    }

    isSelected = (index) => {
        const { selectedData } = this.state
        console.log("====selectedData", selectedData)
        let _index = selectedData && selectedData.indexOf(index);
        return _index > -1
    }

    render() {
        const { selectedDataObject, dataSelectorHeader } = this.state;
        const { classes, data } = this.props;
        return (<>
            {this.props.editSubmittedData && <>
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.open}
                    maxWidth={'md'}
                >
                    <DialogTitle id="confirmation-dialog-title"></DialogTitle>
                    <DialogContent >
                        <div style={{ width: 800 }}>
                            <Grid container spacing={2}>
                                <Grid xs={6}>
                                    <TextField
                                        placeholder="Search memeber"
                                        style={{}}
                                        onChange={(e) => { console.log("=====onChanges") }}
                                        margin="normal"
                                        // value={value}
                                        variant={"standard"}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid xs={3}>
                                    <Button style={{ marginLeft: "20px" }} variant="contained"
                                        type="submit" color="primary"
                                        onClick={(e) => console.log("====sdf")}
                                    >
                                        Search
                        </Button>
                                </Grid>
                                <Grid xs={3}>
                                    <Button style={{ marginLeft: "20px" }} variant="contained"
                                        type="submit" color="primary"
                                        onClick={(e) => this.completedDataSelection()}
                                    >
                                        Done
                        </Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Table className={classNames(classes.table)} size="small" aria-label="a dense table ds">
                                        <TableHead className={classNames(classes.head)}>
                                            <TableRow>
                                                <TableCell className={classNames(classes.customFont)}>Select</TableCell>
                                                {this.state.header && this.state.header.map((item) => {
                                                    if (item !== '_id') {
                                                        return (<TableCell>{item}</TableCell>);
                                                    }
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.data.map((row, index) => {
                                                let _row = JSON.parse(JSON.stringify(row))
                                                delete _row._id
                                                let values = Object.values(_row);
                                                return (
                                                    <TableRow>
                                                        <TableCell>
                                                            <FormControlLabel value={index} control={<Checkbox
                                                                checked={this.isSelected(index)}
                                                                onChange={(e) => { this.selectData(index) }} />} />
                                                        </TableCell>
                                                        {values.map((item) => {
                                                            return (
                                                                <TableCell className={classNames(classes.customFont)}>
                                                                    {item}
                                                                </TableCell>
                                                            )
                                                        })}
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                </Dialog>

                <Button
                    onClick={(e) => { this.setState({ open: true }) }}
                    variant="contained" color="secondary"
                    disabled={this.props.disabled}
                >{data.label}</Button>
                <br />
                <br />
            </>}
            <Grid xs={12}>
                <Table className={classNames(classes.table)} size="small" aria-label="a dense table ds1">
                    <TableHead className={classNames(classes.head)}>
                        <TableRow>
                            {dataSelectorHeader && dataSelectorHeader.map((item) => {
                                if (item !== '_id') {
                                    return (<TableCell>{item}</TableCell>);
                                }
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedDataObject && selectedDataObject.map((row, index) => {
                            let _row = JSON.parse(JSON.stringify(row))
                            delete _row._id
                            let values = Object.values(_row);
                            return (
                                <TableRow>
                                    {values.map((item) => {
                                        return (
                                            <TableCell className={classNames(classes.customFont)}>
                                                {item}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Grid>

        </>);
    }
}


// export default Upload;
export default withRouter(withStyles(styles, { withTheme: true })(DataSelector));
