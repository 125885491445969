import axios from 'axios';
import FileDownload from "js-file-download";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { getFileNameWithTimeStamp, getMonth } from '../../../../utility/utils';
import { getFiscalYearFormat } from '../../spendplan/spendplanUtils';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useDownload = (account, modalData, page, appId, url, downloadCall, setDownloadCall) => {

    useEffect(() => {
        if (downloadCall) {
            let params;
            if (modalData) {
                params = {
                    fiscalYear: modalData.year,
                    account: modalData.account,
                    currentFF: modalData.month,
                    modelId: page?.pageLevelDataModel
                };
            } else {
                let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
                params = {
                    fiscalYear: account.FinancialYear,
                    account: account.AccountName,
                    currentFF: m.length === 1 ? '0' + m : m,
                    modelId: page?.pageLevelDataModel
                }
            }
            let newUri = ['Summary', 'Engine - Spends', 'Engine - Earnings', 'Brand Summary', 'Executive Summary', 'Cockpit View'];
            let uri = API_BASE_URL + url + `${newUri.includes(page?.name) ? '': '/download'}?account=${params.account}&fiscalYear=${params.fiscalYear}&currentFF=${params.currentFF}&modelId=${params.modelId}`;
            if (modalData) {
                uri += '&version=true'
            }
            axios({
                url: uri,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    'Content-Type': "application/json",
                    appId: appId
                },
            }).then((response) => {
                let fyFF = getFiscalYearFormat(params.fiscalYear) + '-' + moment().month(Number(params.currentFF) - 1).format('MMM');
                let fileName = page?.name ? page?.name.replace('Omni', '').trim() : page?.name;
                FileDownload(response.data, getFileNameWithTimeStamp(fileName + '-' + params.account + '-' + fyFF));
                setDownloadCall(false)
            }).catch((error) => {
                console.log(error);
                toast.error('Unable to download')
                setDownloadCall(false)
            })
        }
    });

};