import { ActionTypes } from 'constants/index';

const initialState = {
    data: {},
    error: '',
    loading: false,
    forCorpData: null,
};

export default function talkSheetReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_TS_DATA: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case ActionTypes.GET_TS_DATA_SUCCESS: {
            if (action.updateToCurrentFF) {
                return {
                    ...state,
                    [`data_${action.updateToCurrentFF}`]: action.payload,
                    loading: false
                }
            } else {
                return {
                    ...state,
                    data: action.payload,
                    loading: false
                }
            }
        }
        case ActionTypes.GET_TS_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.GET_FORCORP_DATA: {
            return {
                ...state,
                loading: true,
                error: ''
            };
        }
        case ActionTypes.GET_FORCORP_DATA_SUCCESS: {
            return {
                ...state,
                forCorpData: action.payload,
                loading: false
            }
        }
        case ActionTypes.GET_FORCORP_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.CLEAR_ERROR: {
            return {
                ...state,
                error: null
            };
        }
        default: {
            return state;
        }
    }
}