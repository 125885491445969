import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { TradePriceReductionService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';


async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* fetchTprData(action) {
    try {
        const tableData = yield fetchAsync(TradePriceReductionService.getSpTpr, action.payload);
        yield put({ type: ActionTypes.GET_SP_TPR_SUCCESS, payload: tableData });
    }
    catch (err) {
        yield put({ type: ActionTypes.GET_SP_TPR_FAILURE, error: err.message });
    }
}

function* fetchTprExtraData(action) {
    try {
        const tableData = yield fetchAsync(TradePriceReductionService.getSpTprExtraData, action.payload);
        yield put({ type: ActionTypes.GET_SP_TPR_EXTRA_DATA_SUCCESS, payload: tableData.result.data });
    }
    catch (err) {
        yield put({ type: ActionTypes.GET_SP_TPR_EXTRA_DATA_FAILURE, extraDataError: err.message });
    }
}

function* addSpTprRow(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.addSpTprRow, action.payload)
        yield put({ type: ActionTypes.ADD_SP_TPR_ROW_SUCCESS, payload: response })
    } catch (err) {
        let errorMsg, error;
        if (err.response.error) {
            error = err.response.error; 
        } else {
            errorMsg = err.message != 'Bad Request' ? err.message : 'Please enter correct Data';
        }
        yield put({ type: ActionTypes.ADD_SP_TPR_ROW_FAILURE, error: errorMsg?errorMsg:error });
    }
}

function* fetchBrandFilterData(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.getBrandformfilter, action.payload)
        yield put({ type: ActionTypes.GET_BRANDFORM_FILTER_SUCCESS, payload: response.data })
    } catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'Please enter correct Data';
        yield put({ type: ActionTypes.GET_BRANDFORM_FILTER_FAILURE, error: errorMsg });
    }
}

function* fetchChannelFilterData(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.getChannelFilter, action.payload)
        if (response.error) {
            let errorMsg = response.message != 'Bad Request' ? response.message : 'Please enter correct Data';
            yield put({ type: ActionTypes.GET_CHANNEL_FILTER_FAILURE, error: errorMsg });
        } else {
            yield put({ type: ActionTypes.GET_CHANNEL_FILTER_SUCCESS, payload: response.data ? response : { ...response, data: [] } })
        }
    } catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'Please enter correct Data';
        yield put({ type: ActionTypes.GET_CHANNEL_FILTER_FAILURE, error: errorMsg });
    }
}

function* fetchClusterFilterData(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.getClusterFilter, action.payload)
        yield put({ type: ActionTypes.GET_CLUSTER_FILTER_SUCCESS, payload: response })
    } catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'Please enter correct Data';
        yield put({ type: ActionTypes.GET_CLUSTER_FILTER_FAILURE, error: errorMsg });
    }
}

function* fetchbrandformFilterSelectData(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.getBrandSelectFilter, action.payload)
        yield put({ type: ActionTypes.GET_CHANNEL_FILTER_SUCCESS, payload: response.data ? response : { ...response, data: [] } })
    } catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'Please enter correct Data';
        yield put({ type: ActionTypes.GET_CHANNEL_FILTER_FAILURE, error: errorMsg });
    }
}

function* deleteCheckedTprData(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.deleteCheckedTpr, action.payload)
        yield put({ type: ActionTypes.DELETE_CHECKED_TPR_SUCCESS, payload: response })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'Please enter correct Data';
        yield put({ type: ActionTypes.DELETE_CHECKED_TPR_FAILURE, error: errorMsg });
    }
}

function* freezePlans(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.freezePlans, action.payload)
        yield put({ type: ActionTypes.FREEZE_PLANS_SUCCESS, payload: response ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'Freeze plan request Failed';
        yield put({ type: ActionTypes.FREEZE_PLANS_FAILURE, error: errorMsg });
    }
}

function* fetchTopFivePlans(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.getTopFivePlans, action.payload)
        yield put({ type: ActionTypes.TOPFIVEPLANS_SUCCESS, payload: response?.result?.data ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'plan request Failed';
        yield put({ type: ActionTypes.TOPFIVEPLANS_FAILURE, error: errorMsg });
    }
}

function* fetchCheckData(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.fetchCheckData, action.payload)
        yield put({ type: ActionTypes.CHECKDATA_SUCCESS, payload: response.data ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'check request Failed';
        yield put({ type: ActionTypes.CHECKDATA_FAILURE, error: errorMsg });
    }
}

function* fetchPlans(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.getPlans, action.payload)
        yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_TPR_SUCCESS, payload: response?.result?.data ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'check request Failed';
        yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_TPR_FAILURE, error: errorMsg });
    }
}

function* checkBGProcesses(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.checkBGProcesses, action.payload)
        yield put({ type: ActionTypes.CHECK_SP_BG_PROCESS_SUCCESS, payload: response?.result?.data ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'check request Failed';
        yield put({ type: ActionTypes.CHECK_SP_BG_PROCESS_FAILURE, error: errorMsg });
    }
}

function* checkFreezePlanProcesses(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.checkFreezePlanProcesses, action.payload)
        yield put({ type: ActionTypes.CHECK_FREEZE_PLAN_PROCESS_SUCCESS, payload: response?.result?.data ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'check request Failed';
        yield put({ type: ActionTypes.CHECK_FREEZE_PLAN_PROCESS_FAILURE, error: errorMsg });
    }
}

function* getTSPData(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.getTSPData, action.payload); 
        yield put({ type: ActionTypes.GET_TSP_DATA_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_TSP_DATA_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getImarTableDataDashboard(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.getImarTableDataDashboard, action.payload)
        yield put({ type: ActionTypes.GET_IMARTABLE_DASHBOARD_SUCCESS, payload: response?.result?.data ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'plan request Failed';
        yield put({ type: ActionTypes.GET_IMARTABLE_DASHBOARD_FAILURE, error: errorMsg });
    }
}

function* getImarScenarioList(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.getScenarioList, action.payload)
        yield put({ type: ActionTypes.GET_TSP_SCENARIO_LIST_SUCCESS, payload: response?.data ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'scenario request Failed';
        yield put({ type: ActionTypes.GET_TSP_SCENARIO_LIST_FAILURE, error: errorMsg });
    }
}

function* getSelScenario(action) {
    try {
        // const response = yield fetchAsync(TradePriceReductionService.getScenarioList, action.payload)
        yield put({ type: ActionTypes.GET_TSP_SCENARIO_SUCCESS, payload: action?.payload?.data })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'scenario request Failed';
        yield put({ type: ActionTypes.GET_TSP_SCENARIO_FAILURE, error: errorMsg });
    }
}

function* finalScenario(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.finalScenario, action.payload)
        yield put({ type: ActionTypes.UPDATE_TSP_SCENARIO_SUCCESS, payload: response?.data })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'scenario request Failed';
        yield put({ type: ActionTypes.UPDATE_TSP_SCENARIO_FAILURE, error: errorMsg });
    }
}

function* updateScenarioLable(action) {
    try {
        const response = yield fetchAsync(TradePriceReductionService.updateScenarioLable, action.payload)
        yield put({ type: ActionTypes.UPDATE_TSP_SCENARIO_LABLE_SUCCESS, payload: response?.data })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'scenario request Failed';
        yield put({ type: ActionTypes.UPDATE_TSP_SCENARIO_LABLE_FAILURE, error: errorMsg });
    }
}



export function* tradePriceReductionSaga() {
    yield takeLatest(ActionTypes.GET_SP_TPR_DATA, fetchTprData);
    yield takeLatest(ActionTypes.ADD_SP_TPR_ROW, addSpTprRow);
    yield takeLatest(ActionTypes.GET_BRANDFORM_FILTER, fetchBrandFilterData);
    yield takeLatest(ActionTypes.GET_SP_TPR_EXTRA_DATA, fetchTprExtraData);
    yield takeLatest(ActionTypes.GET_CHANNEL_FILTER, fetchChannelFilterData);
    yield takeLatest(ActionTypes.GET_CLUSTER_FILTER, fetchClusterFilterData);
    yield takeLatest(ActionTypes.DELETE_CHECKED_TPR, deleteCheckedTprData);
    yield takeLatest(ActionTypes.FREEZE_PLANS, freezePlans);
    yield takeLatest(ActionTypes.GET_TOPFIVEPLANS, fetchTopFivePlans);
    yield takeLatest(ActionTypes.GET_CHECKDATA, fetchCheckData);
    yield takeLatest(ActionTypes.GET_PLAN_FILTER_DATA_TPR, fetchPlans);
    yield takeEvery(ActionTypes.CHECK_SP_BG_PROCESS, checkBGProcesses);
    yield takeEvery(ActionTypes.CHECK_FREEZE_PLAN_PROCESS, checkFreezePlanProcesses);
    yield takeEvery(ActionTypes.GET_TSP_DATA, getTSPData);
    yield takeEvery(ActionTypes.GET_IMARTABLE_DASHBOARD, getImarTableDataDashboard);
    yield takeEvery(ActionTypes.GET_TSP_SCENARIO_LIST, getImarScenarioList)
    yield takeEvery(ActionTypes.GET_TSP_SCENARIO, getSelScenario)
    yield takeEvery(ActionTypes.UPDATE_TSP_SCENARIO, finalScenario)
    yield takeEvery(ActionTypes.UPDATE_TSP_SCENARIO_LABLE, updateScenarioLable)
    // yield takeLatest(ActionTypes.BRANDFORM_FILTER_SELECT, fetchbrandformFilterSelectData);
}

export default tradePriceReductionSaga;