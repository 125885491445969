import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: 200,
    height: 180,
    zIndex: 7,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const UploadLoading = () => {
  const classes = useStyles();
  return (
    <Paper elevation={3} className={classes.root}>
      <div style={{ textAlign: 'center' }}>
        <CircularProgress/><br/>
        <div>Uploading please wait ...</div>
      </div>
    </Paper>
  );
};
