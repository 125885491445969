import { hot } from 'react-hot-loader/root';
import compose from 'recompose/compose';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, CardActions, Button, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { ResizableBox } from 'react-resizable';
// import Chart from 'components/charts/Chart';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import history from 'modules/history';

import NotFound from 'routes/NotFound';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Nav from 'components/Nav';

import SimpleCard from 'components/cards/SimpleCard';
import ComplexCard from 'components/cards/ComplexCard';
import SimpleList from 'components/pageComponents/SimpleList';
import Loading from 'components/Loading'

import Chart from 'components/charts/Chart';
import HighChart from 'components/highcharts/Chart';

import { loadAppconfig } from 'store/actions';

import { Responsive, WidthProvider } from "react-grid-layout";
import "./styles.css";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

import TextField from '@material-ui/core/TextField';

import { isJSON } from "utility/utils"

const useStyles = makeStyles(theme => ({
	
}));

const colCard = 4;

let initialLayout = [], defaultBreakpoint = "lg", mounted = false, compactType = "vertical";
let layoutProps = {
	className: "layout",
	rowHeight: 30,
	onLayoutChange: function () { },
	cols: { lg: 12, md: 12, sm: 12, xs: 4, xxs: 2 },
};
const AskDsiloBox = props => {
	const [breakpoint, setBreakpoint] = useState(defaultBreakpoint);
	const [mounted, setMounted] = useState(mounted);
	const [compactType, setCompactType] = useState(compactType);
	const [selectedCharts, setSelectedCharts] = useState([]);
	const [charts, setCharts] = useState([]);
	const [chartName, setChartName] = useState('');
	const [error, setError] = useState(false);

	useEffect(() => {
		setMounted(true);
		return () => {
			console.log("on return useeffect");
		}
	}, []);

	useEffect(() => {
		setCompactType(props.compactType);
	}, [props.compactType])

	useEffect(() => {
		let charts = []
		Array.isArray(props.pages) && props.pages.map(p => {
			if (p.chart)
				charts = [...charts, ...p.chart];
		})
		setCharts(charts)
	}, [props.pages])

	const onBreakpointChange = breakpoint => {
		setBreakpoint(breakpoint);
	};

	const onLayoutChange = (layout, layouts) => {
		console.log("onLayoutChange");
	}

	const onResize = (layout, oldItem, newItem, placeholder, e, element) => {
		console.log("onResize");
	};

	const onDrop = elemParams => {
		console.log("onDrop");
	};

	const handleAskDsilo = e => {
		let chart = [];
		props.pages.map(p => {
			p.chart.map(c => {
				if (c.chartData && c.chartData.name === chartName)
					chart.push(c);
			})
		})
		if (chart.length > 0) {
			let charts = [...selectedCharts, ...chart];
			setChartName('');
			setSelectedCharts(charts);
		}
	}

	let allChart = null, layouts = { lg: initialLayout };
	let page = props.page && JSON.parse(JSON.stringify(props.page));
	const classes = useStyles();
	let s = "info";

	allChart = selectedCharts &&
		selectedCharts.length > 0 &&
		selectedCharts.map((cData, i) => {
			let s = cData.chartData && isJSON(cData.chartData.style)
				&& { ...(JSON.parse(cData.chartData.style)) };
			let chartClassName = cData.chartData && cData.chartData.className
				? cData.chartData.className : "";
			return (
				<div key={i} id="PageDesignerCard1-wrapper" style={{ "marginTop": "20px" }}>
					{(function () {
						switch (cData.chartType) {
							case 'pie':
							case 'doughnut':
							case 'bar':
							case 'stackedbar':
							case 'line':
							case 'horizontalBar':
							case 'bullet':
								return (<Card className={classes.card + " " + chartClassName}
								style={{ height: "inherit", ...s }} >
								{cData.chartData.name ? <CardHeader title={cData.chartData.name} /> : ''}
								{process.env.REACT_APP_HIGHCHARTS === "true"
									? <>
										<CardContent className={classes.cardContent,"graph--card--content"}>
											<HighChart page={props.page} key={cData.chartUniqueId}
												data={cData}
												queryString={props.queryString} appId={props.appId} />
										</CardContent>
									</>
									: <>
										<CardContent className={classes.cardContent, "graph--card--content"}
											>
											<Chart page={props.page} key={cData.chartUniqueId}
												data={cData}
												queryString={props.queryString} appId={props.appId} />
										</CardContent>
									</>
								}
							</Card>)
							case 'list':
							case 'table':
							case 'simpletable':
							case 'card':
							case 'html':
							case 'org':
								return <Chart page={props.page} key={cData.chartUniqueId} data={cData} queryString={props.queryString} appId={props.appId} />
							case 'simplecard':
								return <SimpleCard page={props.page} key={cData.chartUniqueId} data={cData.data} queryString={props.queryString} />
							case 'complexcard':
								return <ComplexCard page={props.page} key={cData.chartUniqueId} data={cData.data} queryString={props.queryString} />
							case 'simplelist':
								return <SimpleList page={props.page} key={cData.chartUniqueId} chartData={cData} queryString={props.queryString} />
							default:
								return null;
						}
					})()}
				</div>
			);
		});
	const generateXY = (i) => {
		let p = parseInt((i + 1) / 3)
		let q = (i + 1) % 3
		let x = 0, y = 0;
		if (q === 1) {
			x = 0
			y = 8 * p;
		} else if (q === 2) {
			x = 4
			y = 8 * p;
		}
		else if (q === 0) {
			x = 8
			y = 8 * (p - 1);
		}
		return { x, y };

	}
	let lg = selectedCharts &&
		selectedCharts.length > 0 ?
		selectedCharts.map((element, i) => {
			let xy = generateXY(i);
			return {
				...element.chartData.layout,
				"w": 4,
				"h": 8,
				...xy,
				'i': "" + i,
				"moved": false,
				"static": true
			}
		}) : [];
	layouts = { lg };
	return (
		<React.Fragment>
			<div style={{ "minHeight": "760px" }}>
				<Grid container spacing={3}>
					<Grid item xs={3} />
					<Grid item xs={4}>
						<div style={{ "paddingTop": "50px" }}>
							<TextField
								style={{ backgroundColor: "#fff" }}
								className="mt-8 mb-16"
								label="Type your question"
								id="chartName"
								name="chartName"
								value={chartName}
								onChange={(e) => {
									setChartName(e.target.value)
									setError(false)
								}}
								variant="outlined"
								fullWidth
							/>
							
						</div>
					</Grid>
					<Grid item xs={2}>
						<div style={{ "paddingTop": "50px" }}>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								style={{ backgroundColor: "#68389A" }}
								onClick={eve => handleAskDsilo(eve)}
							>
								Ask Dsilo
					</Button>
						</div>
					</Grid>
					<Grid item xs={3}></Grid>
				</Grid>
				<div
					id="componentViewPaneElement">
					{/* style={{width: "87.5%", height: "100%"}} */}
					{allChart && <ResponsiveReactGridLayout
						{...props}
						{...layoutProps}
						layouts={layouts}
						onBreakpointChange={onBreakpointChange}
						onLayoutChange={onLayoutChange}
						onResize={onResize}
						onDrop={onDrop}
						// WidthProvider option
						measureBeforeMount={false}
						// I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
						// and set `measureBeforeMount={true}`.
						useCSSTransforms={mounted}
						compactType={compactType}
						preventCollision={!compactType}
						isDroppable={false}
					>
						{allChart}
					</ResponsiveReactGridLayout>}
				</div>
			</div>
		</React.Fragment>
	);
};

export default hot(AskDsiloBox);
