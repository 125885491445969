import React, { useState, useEffect } from 'react';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { AppService } from 'modules/api/index';
import Highcharts from 'highcharts';
import {
	getBarChartConfig,
	getDefaultBarChart,
	generateBarData
} from 'modules/page/highcharts/bar.module';
import {
	generateGUID
} from 'modules/page/page.module';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const HorizontalBarChart = (props) => {

	const [options, setOptions] = useState(getDefaultBarChart());
	const [id, setId] = useState(`bar-${generateGUID()}`)

	const highChartsRender = () => {
		let barConfig = getBarChartConfig(options, id);
		Highcharts.chart(barConfig);
	}

	const setDatafromApi = (res, data) => {
		let d = { ...data };
		let { chartData } = props;

		let labels = [], dArray = [], label = '';
		if (chartData.selectedDataModel && chartData.selectedDataElement) {

			d.labels = res.labels;
			d.datasets[0].data = res.data;
			d.datasets[0].label = res.label;
			//TO DO - Make fynamic
			d.datasets[0].fillColor = ["rgba(0,10,220,0.5)", "rgba(220,0,10,0.5)", "rgba(220,0,0,0.5)", "rgba(120,250,120,0.5)"];
			d.datasets[0].strokeColor = "rgba(220,220,220,0.8)";
			d.datasets[0].highlightFill = "rgba(220,220,220,0.75)";
			d.datasets[0].highlightStroke = "rgba(220,220,220,1)";
		} else {
			d.labels = res.data.labels;
			d.datasets[0].data = res.data.datasets[0].data;
			d.datasets[0].label = res.data.datasets[0].label;
			d.datasets[0].backgroundColor = 'rgb(255, 99, 132)';
			d.datasets[0].fillColor = ["rgba(0,10,220,0.5)", "rgba(220,0,10,0.5)", "rgba(220,0,0,0.5)", "rgba(120,250,120,0.5)"];
			d.datasets[0].strokeColor = "rgba(220,220,220,0.8)";
			d.datasets[0].highlightFill = "rgba(220,220,220,0.75)";
			d.datasets[0].highlightStroke = "rgba(220,220,220,1)";
		}
		let ops = generateBarData(chartData, options)
		setOptions({ ...options, ...ops });
	}

	const getApiDataFromDataUrl = (url, data) => {
		AppService.getApiDataFromDataUrl(url, props.appId)
			.then(res => {
				setDatafromApi(res, data)
			})
	}

	useEffect(() => {
		highChartsRender();
	}, [options]);

	// useEffect(() => {
	//     // setOptions(props.options);
	// }, [props.options]);

	useEffect(() => {
		let { chartData, stacked } = props;
		if (stacked) {
			options.scales = {
				xAxes: [{ stacked: true }],
				yAxes: [{
					ticks: {
						beginAtZero: true
					}
				}]
			}
			options.maintainAspectRatio = false
			setOptions(options);
		}
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + "/dataconnect/chart/" + chartData.selectedDataModel + "?";
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			// targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
			targetURL += chartData.selectedDataElement.reduce(reducer, '&measure=');
			targetURL += chartData.selectedDataLabels.reduce(reducer, '&label=');
			targetURL += '&metric=' + chartData.metric;
			if (props.queryString) {
				targetURL += '&' + props.queryString;
			}
			if (chartData.datelabeltype) {
				targetURL += '&labeltype=date'
				targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
			}

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (props.page && props.page.conditions && props.page.conditions.length > 0) {
				let cond = JSON.stringify(props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
      let encodedData = encodeURIComponent(targetURL)
      let _targetUrl = JSON.stringify(encodedData);
			getApiDataFromDataUrl(_targetUrl, props.data);
		} else if (props.dataUrl) {
			getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			let ops = generateBarData(props, options)
			setOptions({ ...options, ...ops });
		}
	}, [])

	return (
		id && <div id={id} className="chartEqualHeight"></div>
	)
}
export default HorizontalBarChart;