import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom'
import {
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Grid
} from '@material-ui/core';
import classNames from 'classnames';
import axios from 'axios';
import HistoryIcon from '@material-ui/icons/History';
import { getMonth } from "../../../../utility/utils";
import { getCurrentFFForPayload } from '../../spendplan/spendplanUtils'
import { toast } from 'react-toastify';
import { AppService } from '../../../../modules/api/index';
import PopulateHistory from './PopulateHistory';
import Loading from '../../../Loading'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const displayValue = {
    legalEntity: 'Legal Entity',
    category: 'Category',
    brand: 'Brand',
    tsBrand: 'TS Brand',
    brandform: 'Brandform',
    subBrandForm: 'Sub Brandform',
    tsSku: 'TS Sku'
}

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 300,
        flexGrow: 1,
        minWidth: 300,
        transform: 'translateZ(0)',
        // The position fixed scoping doesn't work in IE 11.
        // Disable this demo to preserve the others.
        '@media all and (-ms-high-contrast: none)': {
            display: 'none',
        },
    },
    imar1table: {
        '& .MuiTableContainer-root': {
            height: 'fit-content',
        },
    },
    downloadBtn: {
        '& .MuiButton-startIcon': {
            margin: 0,
        },
        marginRight: 15,
        float: 'right'
    },
    circularBar: {
        color: 'rgb(25, 118, 210)',
        height: 20,
        width: 20,
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'inherit'
    },
    displayHeading: {
        color: 'black',
        fontSize: '20px',
        float: 'left'
    },



    quarterHead: {
        paddingRight: '5px',
        borderRight: '1px solid #7b7272',
        marginRight: '5px',
    },
    quarterBody: {
        paddingRight: '5px',
        marginRight: '5px',
    },
    greenFont: {
        '& span': {
            color: 'green',
        },
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            // height: '320px',
            width: '600px',
        },
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000',
    },
    headingBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    circularBar: {
        color: 'rgb(25, 118, 210)',
        height: 20,
        width: 20,
    },
    countTag: {
        marginRight: 10,
        color: '#fff',
        background: '#ff6503',
        padding: 8,
        height: 36,
        borderRadius: 2,
    }
}));

let capsValues = {
    omniSkuMaster: 'SKU Master',
    omniIttMaster: 'ITT Master',
    omniMasterClassification: 'Master Classification',
    omniSubBrandFormMapping: 'Sub Brandform Mapping',
    omniSkuLevelOfftake: 'SKU Level Offtake',
    omniRetailingOffTakePlanning: 'Retailing Offtake Planning',
    offtakeBrandVsod: 'Offtake Brand VSOD',
    retailBrandVsod: 'Retail Brand VSOD',
    tsSkuSalience: 'TS SKU Salience',
    omniPromoCalender: 'Promo Calendar',
    omniSpendsPlanning: 'Spends Planning',
    omniTiff: 'TFI Earnings',
    mediaCalendar: 'Media Calendar',
    omniFyActuals: 'FY Actuals'
}

const AutoPopulate = (props) => {
    const classes = useStyles();
    const [openHistory, setOpenHistory] = useState(false);
    const [initialListData, setInitialListData] = useState();
    const [alertMessageText, setAlertMessage] = useState('');
    const [initialTableKeys, setInitialListTableKeys] = useState([]);
    const [allAccounts, setAllAccounts] = useState([]);
    const [viewPagesTable, setViewPagesTable] = useState();
    const [loading, setLoading] = useState(true);
    const [accountFromProps, setAccountFromProps] = useState();

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (!accountFromProps || (accountFromProps._id !== props.account._id)) {
            setLoading(true);
            fetchData();
        }
        setAccountFromProps(props.account);
    }, [props.account, props.account._id])

    useEffect(() => {
        if (initialListData && initialListData.status) {
            fetchAllAccounts();
        }

    }, [initialListData])

    const fetchData = () => {
        setOpenHistory(false);
        let payload = {
            currentFF: getCurrentFFForPayload(getMonth((props.account && props.account['CurrentFF']) || '', 'monthNumber')),
            fiscalYear: props.account && props.account['FinancialYear'] ?  `${props.account['FinancialYear']}` : ''
        }
        axios({
            url: API_BASE_URL + '/mrspendplan/omni/autopopulate',
            method: 'POST',
            data: payload,
            headers: {
                'Content-Type': 'application/json',
                appId: props.match.params.appid,
            },
        }).then((response) => {
            if (response && response.data && response.data.result._id) {
                setInitialListData(response.data.result);
                let res = response.data.result?.skumaster
                if (res && res.length) {
                    let keys = Object.keys(res[0]);
                    setInitialListTableKeys([...keys]);
                }
            }
        }).catch((error) => {
            console.log({ "error": error });
        })
    }

    const fetchAllAccounts = () => {
        let conditions = [];
        conditions.push({
            "selectedConditionDataElement": 'FinancialYear',
            "selectedConditionOperator": "exact_match",
            "value": "" + (props.account && props.account['FinancialYear']) || '',
            "selectedConditionClause": "and"
        }, {
            "selectedConditionDataElement": 'CurrentFF',
            "selectedConditionOperator": "exact_match",
            "value": "" + (props.account && props.account['CurrentFF'] || ''),
            "selectedConditionClause": "and"
        })
        let targetURL = API_BASE_URL + "/dataconnect/table/" + props?.page?.pageLevelDataModel;
        targetURL += `?type=list&`;
        targetURL += '&page=0' + '&rowsPerPage=50' + "&";
        targetURL += (conditions ? "conditions=" + encodeURIComponent(JSON.stringify(conditions)) + "&" : '')
        targetURL += '&fields=AccountName';
        AppService.getApiDataFromDataUrl(targetURL, props.match.params.appid)
            .then(res => {
                let dataToPass = [];
                if (res && res.data && res.data.length) {
                    for (let i = 0; i < res?.data.length; i++) {
                        dataToPass.push ({AccountName: res.data[i].AccountName, _id: res.data[i]._id, isView: checkIfView(res.data[i]), isPopulate: !checkIfAutoPop(res.data[i])});
                    }
                }
                setLoading(false);
                setAllAccounts(dataToPass);
            }).catch((err) => {
                console.log(err, 'error')
            })
    }

    const checkIfView = (data) => {
        let statusKeys = initialListData?.status ? Object.keys(initialListData?.status) : [];
        if (statusKeys && statusKeys.length) {
            return false;
        } else {
            return true;
        }
    }

    const checkIfAutoPop = (data) => {
        let statusKeys = initialListData?.status ? Object.keys(initialListData?.status) : [];
        let flag = false;
        if (statusKeys && statusKeys.length) {
            for (let i = 0; i < statusKeys.length; i++) {
                if (data.AccountName === statusKeys[i]) {
                    let subKeys = Object.keys(initialListData.status[statusKeys[i]]);
                    for (let j = 0; j < subKeys.length; j++) {
                        if (!initialListData.status[statusKeys[i]][subKeys[j]]) {
                            flag = true;
                            break;
                        }
                    }
                    if (flag) break;
                }
            }
        } else {
            flag = true;
        }
        return flag;
    }

    const clickAutoPopulate = (event, val) => {
        let payload = {
            currentFF: getCurrentFFForPayload(getMonth((props.account && props.account['CurrentFF']) || '', 'monthNumber')),
            fiscalYear: props.account && props.account['FinancialYear'] ?  `${props.account['FinancialYear']}` : '',
            account: val.AccountName,
            id: initialListData._id
        }
        axios({
            url: API_BASE_URL + '/mrspendplan/omni/account/autopopulate',
            method: 'POST',
            data: payload,
            headers: {
                'Content-Type': 'application/json',
                appId: props.match.params.appid,
            },
        }).then((response) => {
            fetchData();

        }).catch((error) => {
            console.log({ "error": error });
        })
    }

    const handleCloseReplace = (val, payload) => {
        if (val) {
            setOpenHistory(false)
        } else {
            payload['currentFF'] = getCurrentFFForPayload(getMonth((props.account && props.account['CurrentFF']) || '', 'monthNumber'));
            payload['fiscalYear'] = (props.account && props.account['FinancialYear']) || '';
            payload['type'] = JSON.parse(JSON.stringify(payload.fieldValue));
            delete payload['fieldValue'];
            let uri = API_BASE_URL + '/mrspendplan/omni/replacement/master';
            axios({
                url: uri,
                method: 'POST',
                responseType: 'blob',
                data: payload,
                headers: {
                    appId: props.match.params.appid,
                },
            })
                .then((response) => {
                    toast.success('Update successfull');
                    fetchData();
                })
                .catch((error) => {
                    console.log(error, '--')
                    toast.error('Some issue occured');
                });
        }
    }

    const viewClicked = (event, data) => {
        let statusKeys = initialListData.status ? Object.keys(initialListData.status) : [];
        if (statusKeys && statusKeys.length) {
            for (let i = 0; i < statusKeys.length; i++) {
                if (data.AccountName === statusKeys[i]) {
                    setViewPagesTable(initialListData.status[statusKeys[i]]);
                    break;
                }
            }
            let dataToPass = [];
            for (let i = 0; i < allAccounts.length; i++) {
                dataToPass.push({ AccountName: allAccounts[i].AccountName, _id: allAccounts[i]._id, isView: data.AccountName === allAccounts[i].AccountName, isPopulate: !checkIfAutoPop(allAccounts[i]) });
            }
            setAllAccounts(dataToPass);
        }
    }

    const openhistoryPop = () => {
        setOpenHistory(!openHistory)
    }

    const viewHistoryPageById = (dataVal) => {
        axios({
            url: API_BASE_URL + '/mrspendplan/omni/autopopulate/' + dataVal,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                appId: props.match.params.appid,
            },
        }).then((response) => {
            if (response && response.data && response.data.result._id) {
                setViewPagesTable();
                setInitialListData(response.data.result);
                openhistoryPop();
                let res = response.data.result?.skumaster
                if (res && res.length) {
                    let keys = Object.keys(res[0]);
                    setInitialListTableKeys([...keys]);
                }
            }
        }).catch((error) => {
            console.log({ "error": error });
        })
    }

    return (
        <div className={classNames(classes.root, classes.imar1table)} style={{ padding: '15px', border: '1px solid #c1c1c1' }}>
            <Grid item style={{ display: 'flow-root' }}>
                <h1 className={classes.displayHeading}>Auto Populate</h1>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.downloadBtn}
                    size="medium"
                    startIcon={<HistoryIcon />}
                    onClick={openhistoryPop}
                > {'History'} </Button>
            </Grid>
            <div className={classes.flexContainer}>
                {
                    initialListData && initialListData.skumaster && initialListData.skumaster.length ?
                        <TableContainer className='table-container' style={{ margin: '10px', maxHeight: '340px', overflow: 'auto' }} component={Paper}>
                            <Table className='table' aria-label="simple table"
                            >
                                <TableHead className='table-head-replace'>
                                    <TableRow>
                                        {
                                            initialTableKeys && initialTableKeys.length ?

                                                initialTableKeys.map((val, ind) => <TableCell style={{ width: 150 }} key={ind} colSpan={3}>
                                                    <span>{displayValue[val] || val}</span>
                                                </TableCell>)
                                                : ''
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody className="table-body-replace">
                                    {
                                        initialListData && initialListData.skumaster && initialListData.skumaster.length &&
                                        <>
                                            {
                                                initialListData.skumaster.map((dataVal, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            {
                                                                Object.keys(dataVal).map((res, ind) => <TableCell key={ind} style={{ width: 150 }} colSpan={3}>
                                                                    <span>{dataVal[res]}</span>
                                                                </TableCell>)
                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        : loading ? <Loading /> : 'No Data Found'
                }
            </div>
            <div className={classes.flexContainer}>
                {
                    allAccounts && allAccounts.length ?
                        <TableContainer className='table-container' style={{ margin: '10px' }} component={Paper}>
                            <Table className='table' aria-label="simple table"
                            >
                                <TableHead className='table-head-replace'>
                                    <TableRow>
                                        <TableCell style={{ width: 150 }} colSpan={3}>
                                            <span>Account</span>
                                        </TableCell>
                                        <TableCell style={{ width: 150 }} colSpan={3}>
                                            <span>Auto Populate</span>
                                        </TableCell>
                                        <TableCell style={{ width: 150 }} colSpan={3}>
                                            <span>View</span>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table-body-replace">
                                    {
                                        allAccounts && allAccounts.length &&
                                        <>
                                            {
                                                allAccounts.map((dataVal, index) => {
                                                    const isDisabled = initialListData?.status[dataVal.AccountName] && dataVal.isPopulate;
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ width: 150 }} colSpan={3}>
                                                                <span>{dataVal.AccountName}</span>
                                                            </TableCell>
                                                            <TableCell style={{ width: 150 }} colSpan={3}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.downloadBtn}
                                                                    size="medium"
                                                                    disabled={isDisabled}
                                                                    onClick={(e) => clickAutoPopulate(e, dataVal)}
                                                                > {'Autopopulate'} </Button>
                                                            </TableCell>
                                                            <TableCell style={{ width: 150 }} colSpan={3}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.downloadBtn}
                                                                    size="medium"
                                                                    disabled={dataVal.isView}
                                                                    onClick={(e) => viewClicked(e, dataVal)}
                                                                > {'View'} </Button>
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : ''
                }

                {viewPagesTable && <TableContainer className='table-container' style={{ margin: '10px', maxHeight: 600, overflowY: 'auto' }} component={Paper}>
                    <Table className='table' aria-label="simple table"
                    >
                        <TableHead className='table-head-replace'>
                            <TableRow>
                                <TableCell style={{ width: 150 }} colSpan={3}>
                                    <span>Key</span>
                                </TableCell>
                                <TableCell style={{ width: 150 }} colSpan={3}>
                                    <span>Value</span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody class="table-body-replace">
                            {
                                viewPagesTable &&
                                <>
                                    {
                                        Object.keys(viewPagesTable).map((dataVal, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell style={{ width: 150 }} colSpan={3}>
                                                        <span>{capsValues[dataVal] || dataVal}</span>
                                                    </TableCell>
                                                    <TableCell style={{ width: 150 }} colSpan={3}>
                                                        <span>{viewPagesTable[dataVal] ? 'YES' : 'NO'}</span>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>}
            </div>
            <PopulateHistory open={openHistory} handleClose={handleCloseReplace} alertMessageText={alertMessageText}
            classes={classes} confirm={true} currentFF={getCurrentFFForPayload(getMonth((props.account && props.account['CurrentFF']) || '', 'monthNumber'))} fiscalYear={props.account && props.account['FinancialYear']} viewHistoryPageById={viewHistoryPageById}/>
        </div>
    )

}

export default hot(withRouter(AutoPopulate))