import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { emptyPrevPath, loadApps, submitLogin, signInWithPingToken } from 'store/actions';
import { withRouter } from 'react-router-dom'
import history from 'modules/history';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isSpendPlan = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'spendplan';

const MFAPing = (props) => {

    const checkForUrlParams = () => {
        let urlSearchParams = new URLSearchParams(window.location.search);
        let SAMLResponse = urlSearchParams.get('SAMLResponse');
        return {
            pingToken: SAMLResponse,
        }
    }

    console.log('hello', checkForUrlParams())
    const [authInfo, setAuthInfo] = useState(checkForUrlParams());

    useEffect(() => {
        // make api call for jwt token using pingToken
        if(authInfo){
            props.signInWithPingToken(authInfo)
        }
    }, [])

    const loginHandler = (login) => {
		if (props.login.error?.username) {
			console.log('Something went wrong')
		}
        console.log('props', props)
		if (props.login && props.login.success) {
			if (props.navigation.prevPath) {
				history.push({
					pathname: props.navigation.prevPath,
					search: props.navigation.prevSearch
				});
				props.emptyPrevPath();
			} else {
				props.loadApps();
			}
		}
		return null;
	}

    useEffect(() => {
		loginHandler()
		console.log("props.login", props.login);
	}, [props.login]);

    useEffect(() => {
		if (props.login && props.login.success) {
			if (!props.appLoading && props.app && props.app[0] && (props.app.length === 1 || isSpendPlan)) {
				let app = props.app.find(a => a.dappCode === "TSP9be0mf")
				history.push({
					pathname: `/apps/${app ? app._id : props.app[0]._id}`
				});
			} else {
				if (!props.appLoading || props.appError) {
					history.push({
						pathname: '/apps'
					});
				}
			}
		}
	}, [props.app, props.appLoading, props.appError]);

    return (
        <div>
            Authenticating Please wait ...
        </div>
    )
}


const mapDispatchToProps = {
    loadApps,
    submitLogin,
    emptyPrevPath,
    signInWithPingToken,
};

const mapStateToProps = ({ user, login, navigation, app }) => {
    return {
        login,
        user,
        navigation,
        app: app.app,
        appError: app.error,
        appLoading: app.loading,
    }
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(MFAPing)));
