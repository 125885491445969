import { Button, Grid, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import classNames from 'classnames';
import FileDownload from "js-file-download";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMonth } from 'utility/utils';
import { getFileNameWithTimeStamp } from '../../../../../utility/utils';
import Header from '../../SpendPlan/Header';
import { getCurrentFFForPayload, getFiscalYearFormat } from "../../spendplanUtils";
import TalkSheetTable from './talkSheetTable';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  talkSheetTable: {
    "& .MuiTableContainer-root": {
      height: "fit-content"
    }
  },
  donwloadBtn: {
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    marginRight: 15,
  },
}));


const TalkSheet = (props) => {
  const classes = useStyles();
  const { tsData } = props;
  const { selectBrand } = tsData;
  const [brand, setBrand] = useState(selectBrand)

  useEffect(() => {
    if (selectBrand && typeof selectBrand === 'object' && selectBrand !== null && Object.keys(selectBrand).length > 0) {
      setBrand(selectBrand);
    }
  }, [selectBrand])

  const downloadTalkSheetHandler = () => {
    if (brand) {
      let m = getMonth(brand.CurrentFF, 'monthNumber');
      let _brandName = props.modalData ? props.modalData.brand : brand.BrandName;
      let _financialYear = props.modalData ? props.modalData.year : brand.FinancialYear;
      let _currentMonth = props.modalData ? getCurrentFFForPayload(props.modalData.month) : getCurrentFFForPayload(m);

      let uri = API_BASE_URL + `/talksheet/download/${_brandName}/${_financialYear}/${_currentMonth}`;
      if (props.modalData) {
        uri += '?version=true'
      }
      
      let fyFF = getFiscalYearFormat(_financialYear) + '-' + moment().month(Number(_currentMonth) - 1).format('MMM');

      axios({
        url: uri,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': "application/json",
          appId: props.match.params.appid
        },
      }).then((response) => {
        FileDownload(response.data, getFileNameWithTimeStamp('Trade Spends Summary' + '-' + brand.BrandName + '-' + fyFF));
      }).catch((error) => {
        console.log(error);
      })
    }
  }


  return (
    <div className={classNames(classes.root, classes.talkSheetTable)} style={{ paddingBottom: '5px' }}>
      <Grid container justify="space-between">
        <Grid item xs={10} sm={10}>
          <Header header={props.modalData ? props.versionTitle : `Retailing (INR Cr) and Total %RLP by Channel - ${brand && brand.CurrentFF && brand.FinancialYear
            ? `FY ${getFiscalYearFormat(brand.FinancialYear)} ${getMonth(brand.CurrentFF, 'month')} FF`
            : 'FY 2021'}`} />
        </Grid>
        {/* <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.donwloadBtn}
                size="medium"
                onClick={downloadTalkSheetHandler}
                startIcon={<GetAppIcon />}
              > {"Download"} </Button>
            </Grid> */}
      </Grid>
      <div className='mt3'>
        <TalkSheetTable brandData={brand} modalData={props.modalData} />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.user,
    tsData: state.tprReducer
  };
};

const mapDispatchToProps = {
};

export default hot(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(TalkSheet)),
);
