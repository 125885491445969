import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React from 'react';
import ForCorpReport from '../ReportsPages/ForCorp/index';
import { getFiscalYearFormat } from "../spendplanUtils";
import { Transition, useStyles } from './archieveHelpers';

const ForCorpModal = props => {
    const { open, handleClose } = props;
    const modalData = props.modalData ? props.modalData : '';

    const classes = useStyles();

    let versionTitle = `For Corp - Version - ${modalData.monthName} ${getFiscalYearFormat(modalData.year)}`;

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            disableBackdropClick={true}
            aria-labelledby="Recon Review"
            aria-describedby="Recon Review Table"
            className={classes.confirmationDialog}
        >
            <DialogContent>
                <ForCorpReport versionTitle={versionTitle} modalData={modalData} />
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleClose}
                    className="btn btn-primary btn-color border-radius-0 text-color-white"
                >
                    {'Close'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ForCorpModal;
