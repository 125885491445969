
import React, { useState, useEffect } from 'react';

var dataelements = [
    {
        "po": "-",
        "in": "IN-43543",
        "contract": "CN-67676",
        "item": "Product XYZ",
        "quantity": 15,
        "unitPrice": 150.00,
        "totalPrice": 2250.00,
        "invtaxrate": 7,
        "ordtaxrate": 6.79,
        "provtaxrate": 6.79,
        "invtax": 157.50,
        "ordtax": 152.77,
        "leakage": 4.725,
        "totalLeakage": 4.725,
        "reconciled": 'Yes',
        "issue": "Invoice with No PO",
        "status": "Buyer Review",
        "issueNum": "5ee145ee30e1787469c6f442"
    },
    {
        "po": "PO-45435",
        "in": "IN-657657",
        "contract": "CN-56456",
        "item": "Product ABC",
        "quantity": 100,
        "unitPrice": 5500.00,
        "totalPrice": 550000.00,
        "invtaxrate": 6.83,
        "ordtaxrate": 7,
        "provtaxrate": 6.83,
        "invtax": 38500.00,
        "ordtax": 37565.00,
        "leakage": 935,
        "totalLeakage": 935,
        "reconciled": 'Yes',
        "issue": "No Discount",
        "status": "Draft",
        "issueNum": "5ee2809d30e1787469c6f471"
    },
    {
        "po": "PO-32432",
        "in": "IN-12321",
        "contract": "CN-23432",
        "item": "Product DEF",
        "quantity": 230,
        "unitPrice": 3400.00,
        "totalPrice": 782000.00,
        "invtaxrate": 7.00,
        "ordtaxrate": 7.00,
        "provtaxrate": 6.00,
        "invtax": 54740.00,
        "ordtax": 46920.00,
        "leakage": 7820,
        "totalLeakage": 7820,
        "reconciled": 'Yes',
        "issue": "Not in payment terms",
        "status": "In Resolution",
        "issueNum": ""
    }
]

class SimpleTable extends React.Component {
    render() {

        return (<>
            <div id="dataElementsView" className="col-lg-12"
                style={{ marginTop: "10px", marginBottom: "20px" }}>
                <table className="table">
                    <tr style={{ backgroundColor: "#000" }}>
                        <th>Purchase Order #</th>
                        <th>Invoice #</th>
                        <th>Contract #</th>
                        <th>Item</th>
                        <th>Quantity</th>
                        <th>Unti Price</th>
                        <th>Total Amount</th>
                        <th>Tax Rate (Invoice)</th>
                        <th>Tax Rate (PO)</th>
                        <th>Tax Rate (Vertex)</th>
                        <th>Leakage (000)</th>
                        <th>Reconciled</th>
                        <th>Issue</th>
                        <th>Status</th>
                        <th>Issue#</th>
                    </tr>
                    {dataelements.map((val, index) => {
                        return (<tr key={index}>
                            <td><a href="trackorder.html">{val.po}</a></td>
                            <td>{val.in}</td>
                            <td>{val.contract}</td>
                            <td>{val.item}</td>
                            <td>{val.quantity}</td>
                            <td>{val.unitPrice}</td>
                            <td>{val.totalPrice}</td>
                            <td>{val.invtaxrate}</td>
                            <td>{val.ordtaxrate}</td>
                            <td>{val.provtaxrate}</td>
                            <td>${val.leakage}</td>
                            <td>{val.reconciled}</td>
                            <>{val.issue === 'Matched'
                                ? <td style={{ backgroundColor: "green" }}>{val.issue}</td>
                                : (val.issue === 'Invoice with No PO'
                                    ? <td style={{ backgroundColor: "orange", color: "#000" }}>{val.issue}</td>
                                    : (val.issue === 'No Discount'
                                        ? <td style={{ backgroundColor: "yellow", color: "#000" }}>
                                            {val.issue}</td>
                                        : (val.issue === 'Not in payment terms'
                                            ? <td style={{ backgroundColor: "red", color: "#000" }}>
                                                {val.issue}</td>
                                            : <td>{val.issue}</td>)))
                            }</>
                            <td>{val.status}</td>
                            <td><a target='_blank'
                                href={`http://demoapps.dsilo.io:3000/apps/5ede4c26f8bd9243bd7ba820/form/Compliance%20Issue/${val.issueNum}`}>
                                Open
                                </a>
                            </td>
                        </tr>)
                    })}
                </table></div></>)
    }
}

export default SimpleTable;