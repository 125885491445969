import React from 'react';
import { NavLink } from 'react-router-dom';
const REACT_THEME_DESIGN = process.env.REACT_APP_THEME_DESIGN;
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const navBox = {
	// backgroundColor: REACT_THEME_DESIGN === 'dark' ? '#060606' : '#fff',
	backgroundColor: isIBC ? '#102d5e' : REACT_THEME_DESIGN === 'dark' ? '#060606' : '#fff',
	color: '#ffffff',
	padding: '0 0 0 0',
	// borderTop: "8px solid rgb(40, 40, 40)",
	// borderBottom: REACT_THEME_DESIGN === 'dark' ? '1px solid  #282828' : null,
	flexGrow: 1,
	boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
	lineHeight: 2
};
const ul = {
	listStyle: 'none',
	margin: '0',
	padding: '0',
	
	boxShadow: `inset 2px 15px 20px 20px ${isIBC ? '#102d5e' : '#231f20'}`
};
const li = {
	display: 'inline-block',
	margin: '0',
	padding: '0 10px',
	fontSize: '1.3em !important',
	textTransform: 'capitalize',
	// borderRight: REACT_THEME_DESIGN === 'dark' ? "1px solid #333" : "1px solid #E4E4E4",
};
const a = {
	textDecoration: 'none',
	padding: '5px',
	display: 'block',
	color: REACT_THEME_DESIGN === 'dark' ? '#fff' : '#efefef',
	// borderBottom:'1px solid #222'
};
const actStyle = {
	// backgroundColor: REACT_THEME_DESIGN === 'dark' ? '#060606' :'#A9A9A9',
	borderBottom: "3px solid #ff6503",
	borderBottom: `4px solid ${isIBC ? '#ff6503' : 'rgb(0, 199, 255)'}`,
	// backgroundColor:'#00c7ff',
	fontWeight: "bold"
};

export default class Nav extends React.PureComponent {
	render() {
		const { navigation, appId } = this.props;
		const currentPage = this.props.page
		const pages = Array.isArray(this.props.pages) && [...this.props.pages];
		const compare = (a, b) => {
			let comparison = 0;
			//Hanlde when rank is zero and undefined
			// if(!a.rank && !b.rank)
			//   return comparison
			// else if(!b.rank)
			//   return 1
			if (a.rank > b.rank) {
				comparison = 1;
			} else if (a.rank < b.rank) {
				comparison = -1;
			}
			return comparison;
		}
		if (pages && pages.length > 1)
			pages.sort(compare);

		return (
			<div className="navigation" style={navBox}>
				<ul style={ul}>
					{pages && pages.length > 0 ? pages.map((page, i) => {
						return page.isActive && page.url && !page.hide &&
							<li key={i} style={li}>
								<NavLink activeStyle={actStyle} isActive={() => currentPage && page._id === currentPage._id}
									exact to={'/apps/' + appId + '/' + page.url}
									style={a}>
									{page.title ? page.title : page.name}
								</NavLink>
							</li>
					}) : ''}
				</ul>
			</div>
		);
	}
}
