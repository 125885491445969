
import { hot } from 'react-hot-loader/root'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid,
    Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const MappingError = (props) => {
    let { mappingError } = props
    return (<>
        <table className={`table ${isIBC ? 'ibc-table-dark' : 'table-dark'}`}
            style={{
                width: '50%',
                margin: 'auto'
            }}>
            <tr>
                <td className="grouphead">{"Issues"}</td>
                <td className="grouphead">{mappingError.issues}</td>
            </tr>
            {Object.keys(mappingError.list).map(key => {
                console.log('key', key);
                let r = []
                r.push(<tr>
                    <th>{key}</th>
                    <th>{mappingError.list[key].length}</th>
                </tr>)
                mappingError.list[key].map(l => {
                    r.push(<tr>
                        <td className="center">{l.key}</td>
                        <td className="center" style={{ textAlign: 'left' }}>{l.value}</td>
                    </tr>)
                })
                return r
            })}
        </table>
        <div style={{ paddingBottom: '30px' }} />
    </>)
}

const mapStateToProps = state => {
    return {
        user: state.user,
        pageLoading: state.pages.loading,
        member: state.appConfig.member,
        memberLoading: state.appConfig.memberLoading,
        entity: state.appConfig.entity ? state.appConfig.entity : {}
    };
};
export default hot(withRouter(connect(mapStateToProps, null)(MappingError)));