import app from './app';
import appConfig from './appConfig';
import pages from './pages';
import pageConfig from './pageConfig';
import message from './message';
import user from './user';
import login from './login';
import register from './register';
import org from './org';
import theme from './theme';
import navigation from './navigation';
import entities from './entities';
import form from './form';
import cappsConfig from './cappsConfig';
import tprReducer from './custom/spendplan/tradePriceReduction';
import talkSheetReducer from './custom/spendplan/talkSheet';
import ConsumerPromoReducer from './custom/spendplan/ConsumerPromotions';
import DisplaysReducer from './custom/spendplan/Displays';
import TrialsDiscountsReducer from './custom/spendplan/TrialsDiscounts';
import ConversionFundsReducer from './custom/spendplan/conversionFunds';
import MrAndEcommTFIsReducer from './custom/spendplan/mrAndEcommTFIs';
import ReconReviewReducer from './custom/spendplan/ReconReview';
import ImarReportsReducer from './custom/spendplan/ImarReports';
import MrEcommSpendReconciliationReducer from './custom/spendplan/mrEcommSpendReconciliation';
import dataModelReducer from './dataModel';
import mrReducer from './custom/mrplan';

export default {
  app,
  appConfig,
  pages,
  pageConfig,
  message,
  user,
  login,
  register,
  org,
  theme,
  navigation,
  entities,
  form,
  cappsConfig,
  tprReducer,
  talkSheetReducer,
  ConsumerPromoReducer,
  TrialsDiscountsReducer,
  DisplaysReducer,
  ConversionFundsReducer,
  MrAndEcommTFIsReducer,
  ReconReviewReducer,
  ImarReportsReducer,
  MrEcommSpendReconciliationReducer,
  dataModelReducer,
  mrReducer,
};
