import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import * as d3 from "d3";
// import './orgChart.css';

let params = {
  selector: "#svgChart",
  dataLoadUrl: "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/redesignedChartLongData.json",
  chartWidth: window.innerWidth-40,
  chartHeight: window.innerHeight - 40,
  funcs: {
    showMySelf: function(){},
    search: function(){},
    closeSearchBox: function(){},
    clearResult: function(){},
    findInTree: function(){},
    departmentClick: function(){},
    back: function(){},
    toggleFullScreen: function(){},
    locate:function(){},
    listen:function(){}
  },
  data: null
}






let control = {
  node: {
    // heading: "name",
    heading: "positionName",
    // subheading: "positionName",
    subheading: "name",
    p1: "area",
  },
  tooltip: {
    image: "imageUrl",
    heading: "name",
    // heading: "positionName",
    subheading: "positionName",
    p1: "area",
    p2: "office",
    unit: true,
    unitKey: "unit",
    tagBlock: true,
    tags: "tags",
    url: "profileUrl"
  }
}
let data = {
    "name": "Ian Devling",
    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/cto.jpg",
    "area": "Corporate",
    "profileUrl": "http://example.com/employee/profile",
    "office": "CTO office",
    "tags": "Ceo,tag1,manager,cto",
    "isLoggedUser": false,
    "unit": {
        "type": "business",
        "value": "Business first"
    },
    "positionName": "Cheaf Executive Officer ",
    "children": [
        {
        "name": "Davolio Nancy",
        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
        "area": "Corporate",
        "profileUrl": "http://example.com/employee/profile",
        "office": "CEO office",
        "tags": "Ceo,tag1, tag2",
        "isLoggedUser": false,
        "unit": {
            "type": "business",
            "value": "Business one"
        },
        "positionName": "CTO ",
        "children": [
            {
            "name": " Leverling Janet",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": " Finance Department",
                "desc":"Finance Dept description"
            },
            "positionName": "CTO ",
            "children": [
                {
                "name": " Leverling Janet",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Finance Department"
                },
                "positionName": "CTO ",
                "children": [
                    {
                    "name": "Peter Wilson",
                    "isLoggedUser": false,
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Head of channels",
                    "children": [
                        {
                        "name": " Lars Peterson",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Anakyst Developer"
                        }
                    ]
                    },
                    {
                    "name": "Carlos Diaz",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Senior Android Developer",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Linear Manager",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CEO",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager",
                            "children": [
                                {
                                "name": " Leverling Janet",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "CTO ",
                                "children": [
                                    {
                                    "name": "Peter Wilson",
                                    "isLoggedUser": false,
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Head of channels",
                                    "children": [
                                        {
                                        "name": " Lars Peterson",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Finance Department"
                                        },
                                        "positionName": "Anakyst Developer"
                                        }
                                    ]
                                    },
                                    {
                                    "name": "Carlos Diaz",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Senior Android Developer",
                                    "children": [
                                        {
                                        "name": " Petra Winkler",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Finance Department"
                                        },
                                        "positionName": "Android Developer"
                                        },
                                        {
                                        "name": "Martin Bein",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Finance Department"
                                        },
                                        "positionName": "Ios Developer",
                                        "size": 2023
                                        }
                                    ]
                                    },
                                    {
                                    "name": "Sven Petersen",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Senior Developer",
                                    "children": [
                                        {
                                        "name": "Current User",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Finance Department"
                                        },
                                        "positionName": "Web Developer"
                                        },
                                        {
                                        "name": "Beate Vileid",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Finance Department"
                                        },
                                        "positionName": "Junior Developer"
                                        }
                                    ]
                                    }
                                ]
                                },
                                {
                                "name": "Fuller Andrew",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Linear Manager"
                                },
                                {
                                "name": "Peacock Margaret",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "CEO"
                                },
                                {
                                "name": "Buchanan Steven",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Head of direction"
                                },
                                {
                                "name": "Suyama Michael",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": true,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior sales manager"
                                },
                                {
                                "name": "King Robert",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Sales Manager",
                                "children": [
                                    {
                                    "name": "Callahan Laura",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior sales manager"
                                    },
                                    {
                                    "name": "Dodsworth Anne",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior sales manager"
                                    }
                                ]
                                },
                                {
                                "name": "West Adam",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "CTO"
                                },
                                {
                                "name": "Charlotte Cooper",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Trader",
                                "children": [
                                    {
                                    "name": "Shelley Burke",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Intern"
                                    }
                                ]
                                },
                                {
                                "name": "Yoshi Nagase",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Head of laboratory",
                                "children": [
                                    {
                                    "name": "Valle Saavedra",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior Inovator"
                                    },
                                    {
                                    "name": "Regina Murphy",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Developer"
                                    },
                                    {
                                    "name": "Mayumi Ohno",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Senior developer"
                                    },
                                    {
                                    "name": "SizePalette",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "System analyst"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Intern",
                            "children": [
                                {
                                "name": " Leverling Janet",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "CTO ",
                                "children": [
                                    {
                                    "name": "Peter Wilson",
                                    "isLoggedUser": false,
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Head of channels",
                                    "children": [
                                        {
                                        "name": " Lars Peterson",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Finance Department"
                                        },
                                        "positionName": "Anakyst Developer"
                                        }
                                    ]
                                    },
                                    {
                                    "name": "Carlos Diaz",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Senior Android Developer",
                                    "children": [
                                        {
                                        "name": " Petra Winkler",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Finance Department"
                                        },
                                        "positionName": "Android Developer"
                                        },
                                        {
                                        "name": "Martin Bein",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Finance Department"
                                        },
                                        "positionName": "Ios Developer",
                                        "size": 2023
                                        }
                                    ]
                                    },
                                    {
                                    "name": "Sven Petersen",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Senior Developer",
                                    "children": [
                                        {
                                        "name": "Current User",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Finance Department"
                                        },
                                        "positionName": "Web Developer"
                                        },
                                        {
                                        "name": "Beate Vileid",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Finance Department"
                                        },
                                        "positionName": "Junior Developer"
                                        }
                                    ]
                                    }
                                ]
                                },
                                {
                                "name": "Fuller Andrew",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Linear Manager"
                                },
                                {
                                "name": "Peacock Margaret",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "CEO"
                                },
                                {
                                "name": "Buchanan Steven",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Head of direction"
                                },
                                {
                                "name": "Suyama Michael",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior sales manager"
                                },
                                {
                                "name": "King Robert",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Sales Manager",
                                "children": [
                                    {
                                    "name": "Callahan Laura",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior sales manager"
                                    },
                                    {
                                    "name": "Dodsworth Anne",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior sales manager"
                                    }
                                ]
                                },
                                {
                                "name": "West Adam",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "CTO"
                                },
                                {
                                "name": "Charlotte Cooper",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Trader",
                                "children": [
                                    {
                                    "name": "Shelley Burke",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Intern"
                                    }
                                ]
                                },
                                {
                                "name": "Yoshi Nagase",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Head of laboratory",
                                "children": [
                                    {
                                    "name": "Valle Saavedra",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior Inovator"
                                    },
                                    {
                                    "name": "Regina Murphy",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Developer"
                                    },
                                    {
                                    "name": "Mayumi Ohno",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Senior developer"
                                    },
                                    {
                                    "name": "SizePalette",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "System analyst"
                                    }
                                ]
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Sven Petersen",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Senior Developer",
                    "children": [
                        {
                        "name": "Current User",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Web Developer"
                        },
                        {
                        "name": "Beate Vileid",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Junior Developer"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Fuller Andrew",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Finance Department"
                },
                "positionName": "Linear Manager"
                },
                {
                "name": "Peacock Margaret",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Finance Department"
                },
                "positionName": "CEO"
                },
                {
                "name": "Buchanan Steven",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Finance Department"
                },
                "positionName": "Head of direction"
                },
                {
                "name": "Suyama Michael",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Finance Department"
                },
                "positionName": "Senior sales manager"
                },
                {
                "name": "King Robert",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Finance Department"
                },
                "positionName": "Senior Sales Manager",
                "children": [
                    {
                    "name": "Callahan Laura",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Junior sales manager"
                    },
                    {
                    "name": "Dodsworth Anne",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Junior sales manager"
                    }
                ]
                },
                {
                "name": "West Adam",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Finance Department"
                },
                "positionName": "CTO",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Linear Manager",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "CEO",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Junior sales manager",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Intern",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Charlotte Cooper",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Finance Department"
                },
                "positionName": "Senior Trader",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Linear Manager",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "CEO",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Junior sales manager",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Intern",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Finance Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Finance Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Finance Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Finance Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Yoshi Nagase",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Finance Department"
                },
                "positionName": "Head of laboratory",
                "children": [
                    {
                    "name": "Valle Saavedra",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Junior Inovator"
                    },
                    {
                    "name": "Regina Murphy",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Developer"
                    },
                    {
                    "name": "Mayumi Ohno",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "Senior developer"
                    },
                    {
                    "name": "SizePalette",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Finance Department"
                    },
                    "positionName": "System analyst"
                    }
                ]
                }
            ]
            },
            {
            "name": "Fuller Andrew",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "Marketing Department",
                "desc":"Marketing Department Description"
            },
            "positionName": "Linear Manager",
            "children": [
                {
                "name": " Leverling Janet",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Marketing Department"
                },
                "positionName": "CTO ",
                "children": [
                    {
                    "name": "Peter Wilson",
                    "isLoggedUser": false,
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Head of channels",
                    "children": [
                        {
                        "name": " Lars Peterson",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Anakyst Developer"
                        }
                    ]
                    },
                    {
                    "name": "Carlos Diaz",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Senior Android Developer",
                    "children": [
                        {
                        "name": " Petra Winkler",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Android Developer"
                        },
                        {
                        "name": "Martin Bein",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Ios Developer",
                        "size": 2023
                        }
                    ]
                    },
                    {
                    "name": "Sven Petersen",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Senior Developer",
                    "children": [
                        {
                        "name": "Current User",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Web Developer"
                        },
                        {
                        "name": "Beate Vileid",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Junior Developer"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Fuller Andrew",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Marketing Department"
                },
                "positionName": "Linear Manager"
                },
                {
                "name": "Peacock Margaret",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Marketing Department"
                },
                "positionName": "CEO"
                },
                {
                "name": "Buchanan Steven",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Marketing Department"
                },
                "positionName": "Head of direction"
                },
                {
                "name": "Suyama Michael",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Marketing Department"
                },
                "positionName": "Senior sales manager"
                },
                {
                "name": "King Robert",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Marketing Department"
                },
                "positionName": "Senior Sales Manager",
                "children": [
                    {
                    "name": "Callahan Laura",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Junior sales manager"
                    },
                    {
                    "name": "Dodsworth Anne",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Junior sales manager"
                    }
                ]
                },
                {
                "name": "West Adam",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Marketing Department"
                },
                "positionName": "CTO"
                },
                {
                "name": "Charlotte Cooper",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Marketing Department"
                },
                "positionName": "Senior Trader",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Linear Manager",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "CEO",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Junior sales manager",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Marketing Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Marketing Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Marketing Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Marketing Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Marketing Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Intern",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Marketing Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Marketing Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Marketing Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Marketing Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Marketing Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Marketing Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Marketing Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Marketing Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Yoshi Nagase",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Marketing Department"
                },
                "positionName": "Head of laboratory",
                "children": [
                    {
                    "name": "Valle Saavedra",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Junior Inovator"
                    },
                    {
                    "name": "Regina Murphy",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Developer"
                    },
                    {
                    "name": "Mayumi Ohno",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "Senior developer"
                    },
                    {
                    "name": "SizePalette",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Marketing Department"
                    },
                    "positionName": "System analyst"
                    }
                ]
                }
            ]
            },
            {
            "name": "Peacock Margaret",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "HR Department",
                "desc":"HR Department Description"
            },
            "positionName": "CEO",
            "children": [
                {
                "name": " Leverling Janet",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "HR Department"
                },
                "positionName": "CTO ",
                "children": [
                    {
                    "name": "Peter Wilson",
                    "isLoggedUser": false,
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "unit": {
                        "type": "department",
                        "value": "HR Department"
                    },
                    "positionName": "Head of channels",
                    "children": [
                        {
                        "name": " Lars Peterson",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "HR Department"
                        },
                        "positionName": "Anakyst Developer"
                        }
                    ]
                    },
                    {
                    "name": "Carlos Diaz",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "HR Department"
                    },
                    "positionName": "Senior Android Developer",
                    "children": [
                        {
                        "name": " Petra Winkler",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "HR Department"
                        },
                        "positionName": "Android Developer"
                        },
                        {
                        "name": "Martin Bein",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "HR Department"
                        },
                        "positionName": "Ios Developer",
                        "size": 2023
                        }
                    ]
                    },
                    {
                    "name": "Sven Petersen",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "HR Department"
                    },
                    "positionName": "Senior Developer",
                    "children": [
                        {
                        "name": "Current User",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "HR Department"
                        },
                        "positionName": "Web Developer"
                        },
                        {
                        "name": "Beate Vileid",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "HR Department"
                        },
                        "positionName": "Junior Developer"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Fuller Andrew",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "HR Department"
                },
                "positionName": "Linear Manager"
                },
                {
                "name": "Peacock Margaret",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "HR Department"
                },
                "positionName": "CEO"
                },
                {
                "name": "Buchanan Steven",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "HR Department"
                },
                "positionName": "Head of direction"
                },
                {
                "name": "Suyama Michael",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "HR Department"
                },
                "positionName": "Senior sales manager"
                },
                {
                "name": "King Robert",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "HR Department"
                },
                "positionName": "Senior Sales Manager",
                "children": [
                    {
                    "name": "Callahan Laura",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "HR Department"
                    },
                    "positionName": "Junior sales manager"
                    },
                    {
                    "name": "Dodsworth Anne",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "HR Department"
                    },
                    "positionName": "Junior sales manager"
                    }
                ]
                },
                {
                "name": "West Adam",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "HR Department"
                },
                "positionName": "CTO"
                },
                {
                "name": "Charlotte Cooper",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "HR Department"
                },
                "positionName": "Senior Trader",
                "children": [
                    {
                    "name": "Shelley Burke",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "HR Department"
                    },
                    "positionName": "Intern"
                    }
                ]
                },
                {
                "name": "Yoshi Nagase",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "HR Department"
                },
                "positionName": "Head of laboratory",
                "children": [
                    {
                    "name": "Valle Saavedra",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "HR Department"
                    },
                    "positionName": "Junior Inovator"
                    },
                    {
                    "name": "Regina Murphy",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "HR Department"
                    },
                    "positionName": "Developer"
                    },
                    {
                    "name": "Mayumi Ohno",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "HR Department"
                    },
                    "positionName": "Senior developer"
                    },
                    {
                    "name": "SizePalette",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "HR Department"
                    },
                    "positionName": "System analyst"
                    }
                ]
                }
            ]
            },
            {
            "name": "Buchanan Steven",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "Facility Management",
                "desc":"Facility Dept description"
            },
            "positionName": "Head of direction",
            "children": [
                {
                "name": " Leverling Janet",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Facility Management"
                },
                "positionName": "CTO ",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Fuller Andrew",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Facility Management"
                },
                "positionName": "Linear Manager",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Peacock Margaret",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Facility Management"
                },
                "positionName": "CEO",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Buchanan Steven",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Facility Management"
                },
                "positionName": "Head of direction"
                },
                {
                "name": "Suyama Michael",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Facility Management"
                },
                "positionName": "Senior sales manager"
                },
                {
                "name": "King Robert",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Facility Management"
                },
                "positionName": "Senior Sales Manager",
                "children": [
                    {
                    "name": "Callahan Laura",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Junior sales manager",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Facility Management"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Facility Management"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Facility Management"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Facility Management"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Facility Management"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Dodsworth Anne",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Junior sales manager"
                    }
                ]
                },
                {
                "name": "West Adam",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Facility Management"
                },
                "positionName": "CTO"
                },
                {
                "name": "Charlotte Cooper",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Facility Management"
                },
                "positionName": "Senior Trader",
                "children": [
                    {
                    "name": "Shelley Burke",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Intern",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Facility Management"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Facility Management"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Facility Management"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Facility Management"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Facility Management"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Facility Management"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Facility Management"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Yoshi Nagase",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Facility Management"
                },
                "positionName": "Head of laboratory",
                "children": [
                    {
                    "name": "Valle Saavedra",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Junior Inovator"
                    },
                    {
                    "name": "Regina Murphy",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Developer"
                    },
                    {
                    "name": "Mayumi Ohno",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "Senior developer"
                    },
                    {
                    "name": "SizePalette",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Facility Management"
                    },
                    "positionName": "System analyst"
                    }
                ]
                }
            ]
            },
            {
            "name": "Suyama Michael",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "IT Consulting",
                "desc":"Consulting Department Description"
            },
            "positionName": "Senior sales manager"
            },
            {
            "name": "King Robert",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "Customer Service",
                "desc":"Customer Service Department Description"
            },
            "positionName": "Senior Sales Manager",
            "children": [
                {
                "name": "Callahan Laura",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Customer Service"
                },
                "positionName": "Junior sales manager",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Customer Service"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Customer Service"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Customer Service"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Customer Service"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Customer Service"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Customer Service"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Customer Service"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Customer Service"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Customer Service"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Customer Service"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Customer Service"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Customer Service"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Customer Service"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Customer Service"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Customer Service"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Customer Service"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Customer Service"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Customer Service"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Customer Service"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Customer Service"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Customer Service"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Customer Service"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Customer Service"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Customer Service"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Dodsworth Anne",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Customer Service"
                },
                "positionName": "Junior sales manager"
                }
            ]
            },
            {
            "name": "West Adam",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "Delivery Department",
                "desc":"Delivery Department Description"
            },
            "positionName": "CTO"
            },
            {
            "name": "Charlotte Cooper",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "Media Relation",
                "desc":"Media Relation Description"
            },
            "positionName": "Senior Trader",
            "children": [
                {
                "name": "Shelley Burke",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Media Relation"
                },
                "positionName": "Intern",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Media Relation"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Media Relation"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Media Relation"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Media Relation"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Media Relation"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Media Relation"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Media Relation"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Media Relation"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Media Relation"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Media Relation"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Media Relation"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Media Relation"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Media Relation"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Media Relation"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Media Relation"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Media Relation"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Media Relation"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Media Relation"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Media Relation"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Media Relation"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Media Relation"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Media Relation"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Media Relation"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Media Relation"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                }
            ]
            },
            {
            "name": "Yoshi Nagase",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "Security",
                "desc":"Security Department Description"
            },
            "positionName": "Head of laboratory",
            "children": [
                {
                "name": "Valle Saavedra",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Security"
                },
                "positionName": "Junior Inovator"
                },
                {
                "name": "Regina Murphy",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Security"
                },
                "positionName": "Developer"
                },
                {
                "name": "Mayumi Ohno",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Security"
                },
                "positionName": "Senior developer"
                },
                {
                "name": "SizePalette",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Security"
                },
                "positionName": "System analyst"
                }
            ]
            }
        ]
        },
        {
        "name": " Leverling Janet",
        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        "area": "Corporate",
        "profileUrl": "http://example.com/employee/profile",
        "office": "CEO office",
        "tags": "Ceo,tag1, tag2",
        "isLoggedUser": false,
        "unit": {
            "type": "business",
            "value": "Business two "
        },
        "positionName": "CTO ",
        "children": [
            {
            "name": "Peter Wilson",
            "isLoggedUser": false,
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "unit": {
                "type": "department",
                "value": "Purchases Department",
                "desc":"Purchase Department Description"
            },
            "positionName": "Head of channels",
            "children": [
                {
                "name": " Leverling Janet",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Purchases Department"
                },
                "positionName": "CTO ",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Linear Manager",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Linear Manager",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "CEO",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior sales manager",
                            "children": [
                                {
                                "name": " Leverling Janet",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "CTO ",
                                "children": [
                                    {
                                    "name": "Peter Wilson",
                                    "isLoggedUser": false,
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Head of channels",
                                    "children": [
                                        {
                                        "name": " Lars Peterson",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Purchases Department"
                                        },
                                        "positionName": "Anakyst Developer"
                                        }
                                    ]
                                    },
                                    {
                                    "name": "Carlos Diaz",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Senior Android Developer",
                                    "children": [
                                        {
                                        "name": " Petra Winkler",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Purchases Department"
                                        },
                                        "positionName": "Android Developer"
                                        },
                                        {
                                        "name": "Martin Bein",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Purchases Department"
                                        },
                                        "positionName": "Ios Developer",
                                        "size": 2023
                                        }
                                    ]
                                    },
                                    {
                                    "name": "Sven Petersen",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Senior Developer",
                                    "children": [
                                        {
                                        "name": "Current User",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Purchases Department"
                                        },
                                        "positionName": "Web Developer"
                                        },
                                        {
                                        "name": "Beate Vileid",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Purchases Department"
                                        },
                                        "positionName": "Junior Developer"
                                        }
                                    ]
                                    }
                                ]
                                },
                                {
                                "name": "Fuller Andrew",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Linear Manager"
                                },
                                {
                                "name": "Peacock Margaret",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "CEO"
                                },
                                {
                                "name": "Buchanan Steven",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Head of direction"
                                },
                                {
                                "name": "Suyama Michael",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior sales manager"
                                },
                                {
                                "name": "King Robert",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior Sales Manager",
                                "children": [
                                    {
                                    "name": "Callahan Laura",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Junior sales manager"
                                    },
                                    {
                                    "name": "Dodsworth Anne",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Junior sales manager"
                                    }
                                ]
                                },
                                {
                                "name": "West Adam",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "CTO"
                                },
                                {
                                "name": "Charlotte Cooper",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior Trader",
                                "children": [
                                    {
                                    "name": "Shelley Burke",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Intern"
                                    }
                                ]
                                },
                                {
                                "name": "Yoshi Nagase",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Head of laboratory",
                                "children": [
                                    {
                                    "name": "Valle Saavedra",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Junior Inovator"
                                    },
                                    {
                                    "name": "Regina Murphy",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Developer"
                                    },
                                    {
                                    "name": "Mayumi Ohno",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Senior developer"
                                    },
                                    {
                                    "name": "SizePalette",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "System analyst"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Intern",
                            "children": [
                                {
                                "name": " Leverling Janet",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "CTO ",
                                "children": [
                                    {
                                    "name": "Peter Wilson",
                                    "isLoggedUser": false,
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Head of channels",
                                    "children": [
                                        {
                                        "name": " Lars Peterson",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Purchases Department"
                                        },
                                        "positionName": "Anakyst Developer"
                                        }
                                    ]
                                    },
                                    {
                                    "name": "Carlos Diaz",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Senior Android Developer",
                                    "children": [
                                        {
                                        "name": " Petra Winkler",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Purchases Department"
                                        },
                                        "positionName": "Android Developer"
                                        },
                                        {
                                        "name": "Martin Bein",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Purchases Department"
                                        },
                                        "positionName": "Ios Developer",
                                        "size": 2023
                                        }
                                    ]
                                    },
                                    {
                                    "name": "Sven Petersen",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Senior Developer",
                                    "children": [
                                        {
                                        "name": "Current User",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Purchases Department"
                                        },
                                        "positionName": "Web Developer"
                                        },
                                        {
                                        "name": "Beate Vileid",
                                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                        "area": "Corporate",
                                        "profileUrl": "http://example.com/employee/profile",
                                        "office": "CEO office",
                                        "tags": "Ceo,tag1, tag2",
                                        "isLoggedUser": false,
                                        "unit": {
                                            "type": "department",
                                            "value": "Purchases Department"
                                        },
                                        "positionName": "Junior Developer"
                                        }
                                    ]
                                    }
                                ]
                                },
                                {
                                "name": "Fuller Andrew",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Linear Manager"
                                },
                                {
                                "name": "Peacock Margaret",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "CEO"
                                },
                                {
                                "name": "Buchanan Steven",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Head of direction"
                                },
                                {
                                "name": "Suyama Michael",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior sales manager"
                                },
                                {
                                "name": "King Robert",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior Sales Manager",
                                "children": [
                                    {
                                    "name": "Callahan Laura",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Junior sales manager"
                                    },
                                    {
                                    "name": "Dodsworth Anne",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Junior sales manager"
                                    }
                                ]
                                },
                                {
                                "name": "West Adam",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "CTO"
                                },
                                {
                                "name": "Charlotte Cooper",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Senior Trader",
                                "children": [
                                    {
                                    "name": "Shelley Burke",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Intern"
                                    }
                                ]
                                },
                                {
                                "name": "Yoshi Nagase",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Head of laboratory",
                                "children": [
                                    {
                                    "name": "Valle Saavedra",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Junior Inovator"
                                    },
                                    {
                                    "name": "Regina Murphy",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Developer"
                                    },
                                    {
                                    "name": "Mayumi Ohno",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "Senior developer"
                                    },
                                    {
                                    "name": "SizePalette",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Purchases Department"
                                    },
                                    "positionName": "System analyst"
                                    }
                                ]
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Fuller Andrew",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Purchases Department"
                },
                "positionName": "Linear Manager",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Peacock Margaret",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Purchases Department"
                },
                "positionName": "CEO",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Buchanan Steven",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Purchases Department"
                },
                "positionName": "Head of direction"
                },
                {
                "name": "Suyama Michael",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Purchases Department"
                },
                "positionName": "Senior sales manager"
                },
                {
                "name": "King Robert",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Purchases Department"
                },
                "positionName": "Senior Sales Manager",
                "children": [
                    {
                    "name": "Callahan Laura",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Junior sales manager",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Dodsworth Anne",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Junior sales manager"
                    }
                ]
                },
                {
                "name": "West Adam",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Purchases Department"
                },
                "positionName": "CTO"
                },
                {
                "name": "Charlotte Cooper",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Purchases Department"
                },
                "positionName": "Senior Trader",
                "children": [
                    {
                    "name": "Shelley Burke",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Intern",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Purchases Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Purchases Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Purchases Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Yoshi Nagase",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Purchases Department"
                },
                "positionName": "Head of laboratory",
                "children": [
                    {
                    "name": "Valle Saavedra",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Junior Inovator"
                    },
                    {
                    "name": "Regina Murphy",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Developer"
                    },
                    {
                    "name": "Mayumi Ohno",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "Senior developer"
                    },
                    {
                    "name": "SizePalette",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Purchases Department"
                    },
                    "positionName": "System analyst"
                    }
                ]
                }
            ]
            },
            {
            "name": "Carlos Diaz",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "Inventory  Department",
                "desc":"Inventory Department Description"
            },
            "positionName": "Senior Android Developer",
            "children": [
                {
                "name": " Leverling Janet",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Inventory  Department"
                },
                "positionName": "CTO ",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Fuller Andrew",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Inventory  Department"
                },
                "positionName": "Linear Manager",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Peacock Margaret",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Inventory  Department"
                },
                "positionName": "CEO",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Buchanan Steven",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Inventory  Department"
                },
                "positionName": "Head of direction"
                },
                {
                "name": "Suyama Michael",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Inventory  Department"
                },
                "positionName": "Senior sales manager"
                },
                {
                "name": "King Robert",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Inventory  Department"
                },
                "positionName": "Senior Sales Manager",
                "children": [
                    {
                    "name": "Callahan Laura",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Junior sales manager",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Inventory  Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Inventory  Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Inventory  Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Inventory  Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Inventory  Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Dodsworth Anne",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Junior sales manager"
                    }
                ]
                },
                {
                "name": "West Adam",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Inventory  Department"
                },
                "positionName": "CTO"
                },
                {
                "name": "Charlotte Cooper",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Inventory  Department"
                },
                "positionName": "Senior Trader",
                "children": [
                    {
                    "name": "Shelley Burke",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Intern",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Inventory  Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Inventory  Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Inventory  Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Inventory  Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Inventory  Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Inventory  Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Inventory  Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Yoshi Nagase",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Inventory  Department"
                },
                "positionName": "Head of laboratory",
                "children": [
                    {
                    "name": "Valle Saavedra",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Junior Inovator"
                    },
                    {
                    "name": "Regina Murphy",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Developer"
                    },
                    {
                    "name": "Mayumi Ohno",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "Senior developer"
                    },
                    {
                    "name": "SizePalette",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Inventory  Department"
                    },
                    "positionName": "System analyst"
                    }
                ]
                }
            ]
            },
            {
            "name": "Sven Petersen",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "Operation  Department",
                "desc":"Operation Department Description"
            },
            "positionName": "Senior Developer",
            "children": [
                {
                "name": "Current User",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Operation  Department"
                },
                "positionName": "Web Developer"
                },
                {
                "name": "Beate Vileid",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Operation  Department"
                },
                "positionName": "Junior Developer"
                }
            ]
            }
        ]
        },
        {
        "name": " Leverling Janet",
        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        "area": "Corporate",
        "profileUrl": "http://example.com/employee/profile",
        "office": "CEO office",
        "tags": "Ceo,tag1, tag2",
        "isLoggedUser": false,
        "unit": {
            "type": "business",
            "value": "Business three"
        },
        "positionName": "CTO ",
        "children": [
            {
            "name": "Peter Wilson",
            "isLoggedUser": false,
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "unit": {
                "type": "department",
                "value": "Insurance  Department",
                "desc":"Insurance Department Description"
            },
            "positionName": "Head of channels",
            "children": [
                {
                "name": " Lars Peterson",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Insurance  Department"
                },
                "positionName": "Anakyst Developer"
                }
            ]
            },
            {
            "name": "Carlos Diaz",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "Licenses  Department",
                "desc":"Licenses Department Description"
            },
            "positionName": "Senior Android Developer",
            "children": [
                {
                "name": " Leverling Janet",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Licenses  Department"
                },
                "positionName": "CTO ",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Fuller Andrew",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Licenses  Department"
                },
                "positionName": "Linear Manager",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Peacock Margaret",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Licenses  Department"
                },
                "positionName": "CEO",
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": "Peter Wilson",
                        "isLoggedUser": false,
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Head of channels",
                        "children": [
                            {
                            "name": " Lars Peterson",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Anakyst Developer"
                            }
                        ]
                        },
                        {
                        "name": "Carlos Diaz",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior Android Developer",
                        "children": [
                            {
                            "name": " Petra Winkler",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Android Developer"
                            },
                            {
                            "name": "Martin Bein",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Ios Developer",
                            "size": 2023
                            }
                        ]
                        },
                        {
                        "name": "Sven Petersen",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior Developer",
                        "children": [
                            {
                            "name": "Current User",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Web Developer"
                            },
                            {
                            "name": "Beate Vileid",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Junior Developer"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Linear Manager"
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "CEO"
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Junior sales manager"
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Intern"
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Buchanan Steven",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Licenses  Department"
                },
                "positionName": "Head of direction"
                },
                {
                "name": "Suyama Michael",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Licenses  Department"
                },
                "positionName": "Senior sales manager"
                },
                {
                "name": "King Robert",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Licenses  Department"
                },
                "positionName": "Senior Sales Manager",
                "children": [
                    {
                    "name": "Callahan Laura",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Junior sales manager",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Licenses  Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Licenses  Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Licenses  Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Licenses  Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Licenses  Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Dodsworth Anne",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Junior sales manager"
                    }
                ]
                },
                {
                "name": "West Adam",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Licenses  Department"
                },
                "positionName": "CTO"
                },
                {
                "name": "Charlotte Cooper",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Licenses  Department"
                },
                "positionName": "Senior Trader",
                "children": [
                    {
                    "name": "Shelley Burke",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Intern",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Licenses  Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Licenses  Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Licenses  Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Licenses  Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Licenses  Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Licenses  Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Licenses  Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    }
                ]
                },
                {
                "name": "Yoshi Nagase",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Licenses  Department"
                },
                "positionName": "Head of laboratory",
                "children": [
                    {
                    "name": "Valle Saavedra",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Junior Inovator"
                    },
                    {
                    "name": "Regina Murphy",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Developer"
                    },
                    {
                    "name": "Mayumi Ohno",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "Senior developer"
                    },
                    {
                    "name": "SizePalette",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Licenses  Department"
                    },
                    "positionName": "System analyst"
                    }
                ]
                }
            ]
            },
            {
            "name": "Sven Petersen",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": " Customers  Department",
                "desc":"Customers Department Description"
            },
            "positionName": "Senior Developer",
            "children": [
                {
                "name": "Current User",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": " Customers  Department"
                },
                "positionName": "Web Developer"
                },
                {
                "name": "Beate Vileid",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": " Customers  Department"
                },
                "positionName": "Junior Developer"
                }
            ]
            }
        ]
        },
        {
        "name": " Leverling Janet",
        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
        "area": "Corporate",
        "profileUrl": "http://example.com/employee/profile",
        "office": "CEO office",
        "tags": "Ceo,tag1, tag2",
        "isLoggedUser": false,
        "unit": {
            "type": "business",
            "value": "Business four "
        },
        "positionName": "CTO ",
        "children": [
            {
            "name": "Peter Wilson",
            "isLoggedUser": false,
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "unit": {
                "type": "department",
                "value": "Staf",
                "desc":"Staff Department Description"
            },
            "positionName": "Head of channels",
            "children": [
                {
                "name": " Lars Peterson",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Staf"
                },
                "positionName": "Anakyst Developer"
                }
            ]
            },
            {
            "name": "Carlos Diaz",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "Research Department",
                "desc":"Research Department Description"
            },
            "positionName": "Senior Android Developer",
            "children": [
                {
                "name": " Petra Winkler",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Research Department"
                },
                "positionName": "Android Developer"
                },
                {
                "name": "Martin Bein",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Research Department"
                },
                "positionName": "Ios Developer",
                "size": 2023,
                "children": [
                    {
                    "name": " Leverling Janet",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Research Department"
                    },
                    "positionName": "CTO ",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Fuller Andrew",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Research Department"
                    },
                    "positionName": "Linear Manager",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Peacock Margaret",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Research Department"
                    },
                    "positionName": "CEO",
                    "children": [
                        {
                        "name": " Leverling Janet",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "CTO ",
                        "children": [
                            {
                            "name": "Peter Wilson",
                            "isLoggedUser": false,
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Head of channels",
                            "children": [
                                {
                                "name": " Lars Peterson",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Anakyst Developer"
                                }
                            ]
                            },
                            {
                            "name": "Carlos Diaz",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior Android Developer",
                            "children": [
                                {
                                "name": " Petra Winkler",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Android Developer"
                                },
                                {
                                "name": "Martin Bein",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Ios Developer",
                                "size": 2023
                                }
                            ]
                            },
                            {
                            "name": "Sven Petersen",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior Developer",
                            "children": [
                                {
                                "name": "Current User",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Web Developer"
                                },
                                {
                                "name": "Beate Vileid",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Junior Developer"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Fuller Andrew",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Linear Manager"
                        },
                        {
                        "name": "Peacock Margaret",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "CEO"
                        },
                        {
                        "name": "Buchanan Steven",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Head of direction"
                        },
                        {
                        "name": "Suyama Michael",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Senior sales manager"
                        },
                        {
                        "name": "King Robert",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Senior Sales Manager",
                        "children": [
                            {
                            "name": "Callahan Laura",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Junior sales manager"
                            },
                            {
                            "name": "Dodsworth Anne",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Junior sales manager"
                            }
                        ]
                        },
                        {
                        "name": "West Adam",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "CTO"
                        },
                        {
                        "name": "Charlotte Cooper",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Senior Trader",
                        "children": [
                            {
                            "name": "Shelley Burke",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Intern"
                            }
                        ]
                        },
                        {
                        "name": "Yoshi Nagase",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Head of laboratory",
                        "children": [
                            {
                            "name": "Valle Saavedra",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Junior Inovator"
                            },
                            {
                            "name": "Regina Murphy",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Developer"
                            },
                            {
                            "name": "Mayumi Ohno",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior developer"
                            },
                            {
                            "name": "SizePalette",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "System analyst"
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Buchanan Steven",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Research Department"
                    },
                    "positionName": "Head of direction"
                    },
                    {
                    "name": "Suyama Michael",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Research Department"
                    },
                    "positionName": "Senior sales manager"
                    },
                    {
                    "name": "King Robert",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Research Department"
                    },
                    "positionName": "Senior Sales Manager",
                    "children": [
                        {
                        "name": "Callahan Laura",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Junior sales manager",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Research Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Research Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Research Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Research Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Research Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        },
                        {
                        "name": "Dodsworth Anne",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Junior sales manager"
                        }
                    ]
                    },
                    {
                    "name": "West Adam",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Research Department"
                    },
                    "positionName": "CTO"
                    },
                    {
                    "name": "Charlotte Cooper",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Research Department"
                    },
                    "positionName": "Senior Trader",
                    "children": [
                        {
                        "name": "Shelley Burke",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Intern",
                        "children": [
                            {
                            "name": " Leverling Janet",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/female.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "CTO ",
                            "children": [
                                {
                                "name": "Peter Wilson",
                                "isLoggedUser": false,
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Head of channels",
                                "children": [
                                    {
                                    "name": " Lars Peterson",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Research Department"
                                    },
                                    "positionName": "Anakyst Developer"
                                    }
                                ]
                                },
                                {
                                "name": "Carlos Diaz",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Senior Android Developer",
                                "children": [
                                    {
                                    "name": " Petra Winkler",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Research Department"
                                    },
                                    "positionName": "Android Developer"
                                    },
                                    {
                                    "name": "Martin Bein",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Research Department"
                                    },
                                    "positionName": "Ios Developer",
                                    "size": 2023
                                    }
                                ]
                                },
                                {
                                "name": "Sven Petersen",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Senior Developer",
                                "children": [
                                    {
                                    "name": "Current User",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Research Department"
                                    },
                                    "positionName": "Web Developer"
                                    },
                                    {
                                    "name": "Beate Vileid",
                                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                    "area": "Corporate",
                                    "profileUrl": "http://example.com/employee/profile",
                                    "office": "CEO office",
                                    "tags": "Ceo,tag1, tag2",
                                    "isLoggedUser": false,
                                    "unit": {
                                        "type": "department",
                                        "value": "Research Department"
                                    },
                                    "positionName": "Junior Developer"
                                    }
                                ]
                                }
                            ]
                            },
                            {
                            "name": "Fuller Andrew",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Linear Manager"
                            },
                            {
                            "name": "Peacock Margaret",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "CEO"
                            },
                            {
                            "name": "Buchanan Steven",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Head of direction"
                            },
                            {
                            "name": "Suyama Michael",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior sales manager"
                            },
                            {
                            "name": "King Robert",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior Sales Manager",
                            "children": [
                                {
                                "name": "Callahan Laura",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Junior sales manager"
                                },
                                {
                                "name": "Dodsworth Anne",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Junior sales manager"
                                }
                            ]
                            },
                            {
                            "name": "West Adam",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "CTO"
                            },
                            {
                            "name": "Charlotte Cooper",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Senior Trader",
                            "children": [
                                {
                                "name": "Shelley Burke",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Intern"
                                }
                            ]
                            },
                            {
                            "name": "Yoshi Nagase",
                            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                            "area": "Corporate",
                            "profileUrl": "http://example.com/employee/profile",
                            "office": "CEO office",
                            "tags": "Ceo,tag1, tag2",
                            "isLoggedUser": false,
                            "unit": {
                                "type": "department",
                                "value": "Research Department"
                            },
                            "positionName": "Head of laboratory",
                            "children": [
                                {
                                "name": "Valle Saavedra",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Junior Inovator"
                                },
                                {
                                "name": "Regina Murphy",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Developer"
                                },
                                {
                                "name": "Mayumi Ohno",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "Senior developer"
                                },
                                {
                                "name": "SizePalette",
                                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                                "area": "Corporate",
                                "profileUrl": "http://example.com/employee/profile",
                                "office": "CEO office",
                                "tags": "Ceo,tag1, tag2",
                                "isLoggedUser": false,
                                "unit": {
                                    "type": "department",
                                    "value": "Research Department"
                                },
                                "positionName": "System analyst"
                                }
                            ]
                            }
                        ]
                        }
                    ]
                    },
                    {
                    "name": "Yoshi Nagase",
                    "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                    "area": "Corporate",
                    "profileUrl": "http://example.com/employee/profile",
                    "office": "CEO office",
                    "tags": "Ceo,tag1, tag2",
                    "isLoggedUser": false,
                    "unit": {
                        "type": "department",
                        "value": "Research Department"
                    },
                    "positionName": "Head of laboratory",
                    "children": [
                        {
                        "name": "Valle Saavedra",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Junior Inovator"
                        },
                        {
                        "name": "Regina Murphy",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Developer"
                        },
                        {
                        "name": "Mayumi Ohno",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "Senior developer"
                        },
                        {
                        "name": "SizePalette",
                        "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                        "area": "Corporate",
                        "profileUrl": "http://example.com/employee/profile",
                        "office": "CEO office",
                        "tags": "Ceo,tag1, tag2",
                        "isLoggedUser": false,
                        "unit": {
                            "type": "department",
                            "value": "Research Department"
                        },
                        "positionName": "System analyst"
                        }
                    ]
                    }
                ]
                }
            ]
            },
            {
            "name": "Sven Petersen",
            "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
            "area": "Corporate",
            "profileUrl": "http://example.com/employee/profile",
            "office": "CEO office",
            "tags": "Ceo,tag1, tag2",
            "isLoggedUser": false,
            "unit": {
                "type": "department",
                "value": "Management Department",
                "desc":"management Department Description"
            },
            "positionName": "Senior Developer",
            "children": [
                {
                "name": "Current User",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/bean.png",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Management Department"
                },
                "positionName": "Web Developer"
                },
                {
                "name": "Beate Vileid",
                "imageUrl": "https://raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/general.jpg",
                "area": "Corporate",
                "profileUrl": "http://example.com/employee/profile",
                "office": "CEO office",
                "tags": "Ceo,tag1, tag2",
                "isLoggedUser": false,
                "unit": {
                    "type": "department",
                    "value": "Management Department"
                },
                "positionName": "Junior Developer"
                }
            ]
            }
        ]
        }
    ]
};

params.data = data;
params.pristinaData = JSON.parse(JSON.stringify(data));

const OrganisationChart = (props) => {
    const [d, setData] = useState(null);
    const [item, setItem] = useState(null);
    const [results, setResults] = useState([]);
    const [filter, setFilter] = useState([]);
    const [enableFilter, setEnableFilter] = useState(true)

    const drawOrganizationChart = (params) => {

      params.funcs.showMySelf = showMySelf;
      params.funcs.expandAll = expandAll;
      params.funcs.search = searchUsers;
      params.funcs.closeSearchBox = closeSearchBox;
      params.funcs.findInTree = findInTree;
      params.funcs.clearResult = clearResult;
      params.funcs.departmentClick = departmentClick;
      params.funcs.back = back;
      params.funcs.toggleFullScreen = toggleFullScreen;
      params.funcs.locate=locate;
      params.funcs.listen=listen;

      var attrs = {
        EXPAND_SYMBOL: '\uf067',
        COLLAPSE_SYMBOL: '\uf068',
        selector: params.selector,
        root: params.data,
        width: params.chartWidth,
        height: params.chartHeight,
        index: 0,
        nodePadding: 9,
        collapseCircleRadius: 7,
        nodeHeight: 80,
        nodeWidth: 210,
        duration: 750,
        rootNodeTopMargin: 20,
        minMaxZoomProportions: [0.05, 3],
        linkLineSize: 180,
        collapsibleFontSize: '10px',
        userIcon: '\uf007',
        nodeStroke: "#ccc",
        nodeStrokeWidth: '1px'
      }

      var dynamic = {}
      dynamic.nodeImageWidth = attrs.nodeHeight * 100 / 140;
      dynamic.nodeImageHeight = attrs.nodeHeight - 2 * attrs.nodePadding;
      dynamic.nodeTextLeftMargin = attrs.nodePadding * 2 + dynamic.nodeImageWidth
      dynamic.rootNodeLeftMargin = attrs.width / 2;
      dynamic.nodePositionNameTopMargin = attrs.nodePadding + 8 + dynamic.nodeImageHeight / 4 * 1
      dynamic.nodeChildCountTopMargin = attrs.nodePadding + 14 + dynamic.nodeImageHeight / 4 * 3

      var tree = d3.layout.tree().nodeSize([attrs.nodeWidth + 40, attrs.nodeHeight]);
      var diagonal = d3.svg.diagonal()
        .projection(function(d) {
        debugger;
        return [d.x + attrs.nodeWidth / 2, d.y + attrs.nodeHeight / 2];
        });

      var zoomBehaviours = d3.behavior
        .zoom()
        .scaleExtent(attrs.minMaxZoomProportions)
        .on("zoom", redraw);

      var svg = d3.select(attrs.selector)
        .append("svg")
        .attr("width", attrs.width)
        .attr("height", attrs.height)
        .call(zoomBehaviours)
        .append("g")
        .attr("transform", "translate(" + attrs.width / 2 + "," + 20 + ")");

      //necessary so that zoom knows where to zoom and unzoom from
      zoomBehaviours.translate([dynamic.rootNodeLeftMargin, attrs.rootNodeTopMargin]);

      attrs.root.x0 = 0;
      attrs.root.y0 = dynamic.rootNodeLeftMargin;

      if (params.mode != 'department') {
        // adding unique values to each node recursively
        var uniq = 1;
        addPropertyRecursive('uniqueIdentifier', function(v) {
          return uniq++;
        }, attrs.root);
      }

      expand(attrs.root);
      if (attrs.root.children) {
        attrs.root.children.forEach(collapse);
      }

      update(attrs.root);

      d3.select(attrs.selector).style("height", attrs.height);

      var tooltip = d3.select('.customTooltip-wrapper');

      function update(source, param) {

        // Compute the new tree layout.
        var nodes = tree.nodes(attrs.root).reverse(),
        links = tree.links(nodes);

        // Normalize for fixed-depth.
        nodes.forEach(function(d) {
          d.y = d.depth * attrs.linkLineSize;
        });

        // Update the nodes…
        var node = svg.selectAll("g.node")
          .data(nodes, function(d) {
            return d.id || (d.id = ++attrs.index);
          });

        // Enter any new nodes at the parent's previous position.
        var nodeEnter = node.enter()
          .append("g")
          .attr("class", "node")
          .attr("transform", function(d) {
            return "translate(" + source.x0 + "," + source.y0 + ")";
          })

        var nodeGroup = nodeEnter.append("g")
          .attr("class", "node-group")
          

        nodeGroup.append("rect")
          .attr("width", attrs.nodeWidth)
          .attr("height", attrs.nodeHeight)
          .attr("data-node-group-id",function(d){
          return d.uniqueIdentifier;
          })
          .attr("class", function(d) {
              var res = "";
              if (d.isLoggedUser) res += 'nodeRepresentsCurrentUser ';
              res += d._children || d.children ? "nodeHasChildren" : "nodeDoesNotHaveChildren";
              return res;
          });

        var collapsiblesWrapper = nodeEnter.append('g')
          .attr('data-id', function(v) {
              return v.uniqueIdentifier;
          });

        var collapsibleRects = collapsiblesWrapper.append("rect")
          .attr('class', 'node-collapse-right-rect')
          .attr('height', attrs.collapseCircleRadius)
          .attr('fill', 'black')
          .attr('x', attrs.nodeWidth - attrs.collapseCircleRadius)
          .attr('y', attrs.nodeHeight - 7)
          .attr("width", function(d) {
              if (d.children || d._children) return attrs.collapseCircleRadius;
              return 0;
          })

        var collapsibles = collapsiblesWrapper.append("circle")
          .attr('class', 'node-collapse')
          .attr('cx', attrs.nodeWidth - attrs.collapseCircleRadius)
          .attr('cy', attrs.nodeHeight - 7)
          .attr("", setCollapsibleSymbolProperty);

        //hide collapse rect when node does not have children
        collapsibles.attr("r", function(d) {
            if (d.children || d._children) return attrs.collapseCircleRadius;
            return 0;
        })
        .attr("height", attrs.collapseCircleRadius)

        collapsiblesWrapper.append("text")
          .attr('class', 'text-collapse')
          .attr("x", attrs.nodeWidth - attrs.collapseCircleRadius)
          .attr('y', attrs.nodeHeight - 3)
          .attr('width', attrs.collapseCircleRadius)
          .attr('height', attrs.collapseCircleRadius)
          .style('font-size', attrs.collapsibleFontSize)
          .attr("text-anchor", "middle")
          .style('font-family', 'FontAwesome')
          .text(function(d) {
            return d.collapseText;
          })

        collapsiblesWrapper.on("click", click);

        nodeGroup.append("text")
          .attr("x", dynamic.nodeTextLeftMargin)
          .attr("y", attrs.nodePadding + 10)
          .attr('class', 'emp-name')
          .attr("text-anchor", "left")
          .text(function(d) {
            return d[control.node.heading].trim();
          })
          .call(wrap, attrs.nodeWidth);

        nodeGroup.append("text")
          .attr("x", dynamic.nodeTextLeftMargin)
          .attr("y", dynamic.nodePositionNameTopMargin)
          .attr('class', 'emp-position-name')
          .attr("dy", ".35em")
          .attr("text-anchor", "left")
          .text(function(d) {
            var position =  d[control.node.subheading].substring(0,27);
            if(position.length<d[control.node.subheading].length){
              position = position.substring(0,24)+'...'
            }
            return position;
          })

        nodeGroup.append("text")
          .attr("x", dynamic.nodeTextLeftMargin)
          .attr("y", attrs.nodePadding + 10 + dynamic.nodeImageHeight / 4 * 2)
          .attr('class', 'emp-area')
          .attr("dy", ".35em")
          .attr("text-anchor", "left")
          .text(function(d) {
            return d[control.node.p1];
          })

        nodeGroup.append("text")
          .attr("x", dynamic.nodeTextLeftMargin)
          .attr("y", dynamic.nodeChildCountTopMargin)
          .attr('class', 'emp-count-icon')
          .attr("text-anchor", "left")
          .style('font-family', 'FontAwesome')
          .text(function(d) {
            if (d.children || d._children) return attrs.userIcon;
          });

        nodeGroup.append("text")
          .attr("x", dynamic.nodeTextLeftMargin + 13)
          .attr("y", dynamic.nodeChildCountTopMargin)
          .attr('class', 'emp-count')
          .attr("text-anchor", "left")
          .text(function(d) {
            if (d.children) return d.children.length;
            if (d._children) return d._children.length;
            return;
          })

        nodeGroup.append("defs").append("svg:clipPath")
          .attr("id", "clip")
          .append("svg:rect")
          .attr("id", "clip-rect")
          .attr("rx", 3)
          .attr('x', attrs.nodePadding)
          .attr('y', 2 + attrs.nodePadding)
          .attr('width', dynamic.nodeImageWidth)
          .attr('fill', 'none')
          .attr('height', dynamic.nodeImageHeight - 4)

        nodeGroup.append("svg:image")
          .attr('y', 2 + attrs.nodePadding)
          .attr('x', attrs.nodePadding)
          .attr('preserveAspectRatio', 'none')
          .attr('width', dynamic.nodeImageWidth)
          .attr('height', dynamic.nodeImageHeight - 4)
          .attr('clip-path', "url(#clip)")
          .attr("xlink:href", function(v) {
            return v.imageUrl;
          })

          // Transition nodes to their new position.
        var nodeUpdate = node.transition()
          .duration(attrs.duration)
          .attr("transform", function(d) {
            return "translate(" + d.x + "," + d.y + ")";
          })

          //todo replace with attrs object
        nodeUpdate.select("rect")
          .attr("width", attrs.nodeWidth)
          .attr("height", attrs.nodeHeight)
          .attr('rx', 3)
          .attr("stroke", function(d){
            if(param && d.uniqueIdentifier== param.locate){
              return '#a1ceed'
            }
            return attrs.nodeStroke;
          })
          .attr('stroke-width', function(d){
            if(param && d.uniqueIdentifier== param.locate){
              return 6;
            }
            return attrs.nodeStrokeWidth
          })

        // Transition exiting nodes to the parent's new position.
        var nodeExit = node.exit().transition()
          .duration(attrs.duration)
          .attr("transform", function(d) {
            return "translate(" + source.x + "," + source.y + ")";
          })
          .remove();

        nodeExit.select("rect")
          .attr("width", attrs.nodeWidth)
          .attr("height", attrs.nodeHeight)

          // Update the links…
        var link = svg.selectAll("path.link")
          .data(links, function(d) {
            return d.target.id;
          });

          // Enter any new links at the parent's previous position.
        link.enter().insert("path", "g")
          .attr("class", "link")
          .attr("x", attrs.nodeWidth / 2)
          .attr("y", attrs.nodeHeight / 2)
          .attr("d", function(d) {
            var o = {
              x: source.x0,
              y: source.y0
            };
            return diagonal({
              source: o,
              target: o
            });
          });

        // Transition links to their new position.
        link.transition()
          .duration(attrs.duration)
          .attr("d", diagonal)
          ;

        // Transition exiting nodes to the parent's new position.
        link.exit().transition()
          .duration(attrs.duration)
          .attr("d", function(d) {
            var o = {
              x: source.x,
              y: source.y
            };
            return diagonal({
              source: o,
              target: o
            });
          })
          .remove();

          // Stash the old positions for transition.
        nodes.forEach(function(d) {
          d.x0 = d.x;
          d.y0 = d.y;
        });
          
        if(param && param.locate){
          var x;
          var y;

          nodes.forEach(function(d) {
            if (d.uniqueIdentifier == param.locate) {
              x = d.x;
              y = d.y;
            }
          });

          // normalize for width/height
          var new_x = (-x + (window.innerWidth / 2));
          var new_y = (-y + (window.innerHeight / 2));

          // move the main container g
          svg.attr("transform", "translate(" + new_x + "," + new_y + ")")
          zoomBehaviours.translate([new_x, new_y]);
          zoomBehaviours.scale(1);
        }

        if (param && param.centerMySelf) {
          var x;
          var y;

          nodes.forEach(function(d) {
            if (d.isLoggedUser) {
              x = d.x;
              y = d.y;
            }
          });

          // normalize for width/height
          var new_x = (-x + (window.innerWidth / 2));
          var new_y = (-y + (window.innerHeight / 2));

          // move the main container g
          svg.attr("transform", "translate(" + new_x + "," + new_y + ")")
          zoomBehaviours.translate([new_x, new_y]);
          zoomBehaviours.scale(1);
        }
        function tooltipHoverHandler(d) {

          setItem(d);

          tooltip.transition()
            .duration(200).style("opacity", "1").style('display', 'block');
          d3.select(this).attr('cursor', 'pointer').attr("stroke-width", 50);

          var y = d3.event.pageY;
          var x = d3.event.pageX;
          
          //restrict tooltip to fit in borders
          if (y < 220) {
            y += 220 - y;
            x += 130;
          }
          
          if(y>attrs.height-300){
            y-=300-(attrs.height-y);
          }

          tooltip.style('top', (y - 300) + 'px')
            .style('left', (x - 470) + 'px');
          }

          function tooltipOutHandler() {
            tooltip.transition()
              .duration(200)
              .style('opacity', '0').style('display', 'none');
            d3.select(this).attr("stroke-width", 5);
          }

          nodeGroup.on('click', tooltipHoverHandler);

          nodeGroup.on('dblclick', tooltipOutHandler);

          function equalToEventTarget() {
            return this == d3.event.target;
          }

          d3.select("body").on("click", function() {
          var outside = tooltip.filter(equalToEventTarget).empty();
          if (outside) {
            tooltip.style('opacity', '0').style('display', 'none');
          }
        });

      }

      // Toggle children on click.
      function click(d) {

        d3.select(this).select("text").text(function(dv) {
          if (dv.collapseText == attrs.EXPAND_SYMBOL) {
            dv.collapseText = attrs.COLLAPSE_SYMBOL
          } else {
            if (dv.children) {
              dv.collapseText = attrs.EXPAND_SYMBOL
            }
          }
          return dv.collapseText;

        })

        if (d.children) {
          d._children = d.children;
          d.children = null;
        } else {
          d.children = d._children;
          d._children = null;
        }
        update(d);
      }

      //Redraw for zoom
      function redraw() {
        //console.log("here", d3.event.translate, d3.event.scale);
        svg.attr("transform",
          "translate(" + d3.event.translate + ")" +
          " scale(" + d3.event.scale + ")");
      }

      function wrap(text, width) {
        text.each(function() {
          var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            x = text.attr("x"),
            y = text.attr("y"),
            dy = 0, //parseFloat(text.attr("dy")),
            tspan = text.text(null)
              .append("tspan")
              .attr("x", x)
              .attr("y", y)
              .attr("dy", dy + "em");
          while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text.append("tspan")
                .attr("x", x)
                .attr("y", y)
                .attr("dy", ++lineNumber * lineHeight + dy + "em")
                .text(word);
            }
          }
        });
      }

      function addPropertyRecursive(propertyName, propertyValueFunction, element) {
        if (element[propertyName]) {
          element[propertyName] = element[propertyName] + ' ' + propertyValueFunction(element);
        } else {
          element[propertyName] = propertyValueFunction(element);
        }
        if (element.children) {
          element.children.forEach(function(v) {
              addPropertyRecursive(propertyName, propertyValueFunction, v)
          })
        }
        if (element._children) {
          element._children.forEach(function(v) {
              addPropertyRecursive(propertyName, propertyValueFunction, v)
          })
        }
      }

      function departmentClick(item) {
        hide(['.customTooltip-wrapper']);

        if (item.type == 'department' && params.mode != 'department') {
          //find third level department head user
          var found = false;
          var secondLevelChildren = params.pristinaData.children;
          parentLoop:
            for (var i = 0; i < secondLevelChildren.length; i++) {
              var secondLevelChild = secondLevelChildren[i];
              var thirdLevelChildren = secondLevelChild.children ? secondLevelChild.children : secondLevelChild._children;

              for (var j = 0; j < thirdLevelChildren.length; j++) {
                var thirdLevelChild = thirdLevelChildren[j];
                if (thirdLevelChild.unit.value.trim() == item.value.trim()) {
                  clear(params.selector);

                  hide(['.btn-action']);
                  show(['.btn-action.btn-back', '.btn-action.btn-fullscreen', '.department-information']);
                  set('.dept-name', item.value);
                  
                  set('.dept-emp-count', "Employees Quantity - " + getEmployeesCount(thirdLevelChild));
                  set('.dept-description', thirdLevelChild.unit.desc);

                  params.oldData = params.data;

                  params.data = deepClone(thirdLevelChild);
                  found = true;
                  break parentLoop;
                }
              }
            }
          if (found) {
            params.mode = "department";
            params.funcs.closeSearchBox();
            drawOrganizationChart(params);
          }
        }
      }

      function getEmployeesCount(node) {
        var count = 1;
        countChilds(node);
        return count;

        function countChilds(node) {
          var childs = node.children ? node.children : node._children;
          if (childs) {
            childs.forEach(function(v) {
              count++;
              countChilds(v);
            })
          }
        }
      }

      function clearResult() {
        setResults([])
        set('.user-search-box .result-header', "RESULT");

      }

      function listen(e, key) {
        var value = e.target.value;
        if (value.length < 3) {
          params.funcs.clearResult();
        } else {
          var searchResult = params.funcs.findInTree(params.data, value, key);
          setResults(searchResult);
          set('.user-search-box .result-header', "RESULT - " + searchResult.length);
        }
      }

      function searchUsers() {
        d3.selectAll('.user-search-box')
          .transition()
          .duration(250)
          .style('width', '350px')
      }

      function closeSearchBox() {
        d3.selectAll('.user-search-box')
          .transition()
          .duration(250)
          .style('width', '0px')
          .each("end", function() {
            params.funcs.clearResult();
            clear('.search-input');
          });
      }

      function findInTree(rootElement, searchText, searchKey) {
        var result = [];
        // use regex to achieve case insensitive search and avoid string creation using toLowerCase method
        var regexSearchWord = new RegExp(searchText, "i");

        recursivelyFindIn(rootElement, searchText);

        return result;

        function recursivelyFindIn(user) {
          let cond = searchKey ? user[searchKey].match(regexSearchWord) : (user.name.match(regexSearchWord) ||user.tags.match(regexSearchWord));

          if (cond) {
            result.push(user)
          }

          var childUsers = user.children ? user.children : user._children;
          if (childUsers) {
            childUsers.forEach(function(childUser) {
              recursivelyFindIn(childUser, searchText)
            })
          }
        };
      }

      function back() {

        show(['.btn-action']);
        hide(['.customTooltip-wrapper', '.btn-action.btn-back', '.department-information'])
        clear(params.selector);

        params.mode = "full";
        params.data = deepClone(params.pristinaData)
        drawOrganizationChart(params);

      }

      function expandAll() {
        expand(root);
        update(root);
      }

      function expand(d) {
        if (d.children) {
          d.children.forEach(expand);
        }

        if (d._children) {
          d.children = d._children;
          d.children.forEach(expand);
          d._children = null;
        }

        if (d.children) {
          // if node has children and it's expanded, then  display -
          setToggleSymbol(d, attrs.COLLAPSE_SYMBOL);
        }
      }

      function collapse(d) {
        if (d._children) {
          d._children.forEach(collapse);
        }
        if (d.children) {
          d._children = d.children;
          d._children.forEach(collapse);
          d.children = null;
        }

        if (d._children) {
          // if node has children and it's collapsed, then  display +
          setToggleSymbol(d, attrs.EXPAND_SYMBOL);
        }
      }

      function setCollapsibleSymbolProperty(d) {
        if (d._children) {
          d.collapseText = attrs.EXPAND_SYMBOL;
        } else if (d.children) {
          d.collapseText = attrs.COLLAPSE_SYMBOL;
        }
      }

      function setToggleSymbol(d, symbol) {
        d.collapseText = symbol;
        d3.select("*[data-id='" + d.uniqueIdentifier + "']").select('text').text(symbol);
      }

      /* recursively find logged user in subtree */
      function findmySelf(d) {
        if (d.isLoggedUser) {
          expandParents(d);
        } else if (d._children) {
          d._children.forEach(function(ch) {
            ch.parent = d;
            findmySelf(ch);
          })
        } else if (d.children) {
          d.children.forEach(function(ch) {
            ch.parent = d;
            findmySelf(ch);
          });
        };
      }
      
      function locateRecursive(d,id) {
        if (d.uniqueIdentifier == id) {
          expandParents(d);
        } else if (d._children) {
          d._children.forEach(function(ch) {
            ch.parent = d;
            locateRecursive(ch,id);
          })
        } else if (d.children) {
          d.children.forEach(function(ch) {
            ch.parent = d;
            locateRecursive(ch,id);
          });
        };
      }

      /* expand current nodes collapsed parents */
      function expandParents(d) {
        while (d.parent) {
          d = d.parent;
          if (!d.children) {
            d.children = d._children;
            d._children = null;
            setToggleSymbol(d, attrs.COLLAPSE_SYMBOL);
          }
        }
      }

      function toggleFullScreen() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
          (!document.mozFullScreen && !document.webkitIsFullScreen)) {
          if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          }
          d3.select(params.selector + ' svg').attr('width', screen.width).attr('height', screen.height);
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
          d3.select(params.selector + ' svg').attr('width', params.chartWidth).attr('height', params.chartHeight);
        }
      }

      function showMySelf() {
        /* collapse all and expand logged user nodes */
        if (!attrs.root.children) {
          if (!attrs.root.isLoggedUser) {
            attrs.root.children = attrs.root._children;
          }
        }
        if (attrs.root.children) {
          attrs.root.children.forEach(collapse);
          attrs.root.children.forEach(findmySelf);
        }
        update(attrs.root, {centerMySelf:true});
      }
      
      //locateRecursive
      function locate(id){
        /* collapse all and expand logged user nodes */
        if (!attrs.root.children) {
          if (!attrs.root.uniqueIdentifier == id) {
              attrs.root.children = attrs.root._children;
          }
        }
        if (attrs.root.children) {
          attrs.root.children.forEach(collapse);
          attrs.root.children.forEach(function(ch){
              locateRecursive(ch,id)
          });
        }
        update(attrs.root, {locate:id});
      }

      function deepClone(item) {
        return JSON.parse(JSON.stringify(item));
      }

      function show(selectors) {
        display(selectors, 'initial')
      }

      function hide(selectors) {
        display(selectors, 'none')
      }

      function display(selectors, displayProp) {
        selectors.forEach(function(selector) {
          var elements = getAll(selector);
          elements.forEach(function(element) {
            element.style.display = displayProp;
          })
        });
      }

      function set(selector, value) {
        var elements = getAll(selector);
        elements.forEach(function(element) {
          element.innerHTML = value;
          element.value = value;
        })
      }

      function clear(selector) {
        set(selector, '');
      }

      function get(selector) {
        return document.querySelector(selector);
      }

      function getAll(selector) {
        return document.querySelectorAll(selector);
      }
    }

    useEffect(() => {
        console.log("props.data org chart", props.data);
        // if (Object.keys(props.data).length > 0) {
          // params.data = props.data;
          drawOrganizationChart(params);
          let filter = Object.keys(params.data).filter(k => k!="x0"&&k!="y0"&&k!="x"&&k!="y"&&k!="id"&&k!="depth"&&k!="collapseText"&&k!="uniqueIdentifier"&&k!="children"&&k!="unit");
          setFilter(filter);
        // }
    }, [])

    const handleChange = (event) => {
      setEnableFilter(event.target.checked)
    }

    return (
        <div id="orgchart">
            <div id="full-container">
                <button className="btn-action btn-show-my-self" onClick={() => params.funcs.showMySelf()}> Show myself</button>

                <button className=" btn-action btn-search" onClick={()=>params.funcs.search()}> Search</button>

                <button className=" btn-action btn-back" onClick={()=>params.funcs.back()}>Back</button>

                <div className="department-information">
                    <div className="dept-name">
                        dept name
                    </div>
                    <div className="dept-emp-count">
                        dept description test, this is department description
                    </div>
                    <div className="dept-description">
                        dept description test, this is department description
                    </div>
                </div>

                <div className="user-search-box">
                    <div className="input-box">
                        <div className="close-button-wrapper">
                            <button onClick={()=>params.funcs.closeSearchBox()}     aria-hidden="true">X</button>
                        </div>
                          <b>Enable Filter : </b>
                          <Switch
                            checked={enableFilter}
                            onChange={(e)=> handleChange(e)}
                            value="enableFilter"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        <div className="input-wrapper">
                            <input type="text" className="search-input" placeholder="Search" onChange={(e)=>params.funcs.listen(e)}/>
                            <div className="input-bottom-placeholder">By Firstname, Lastname, Tags</div>
                            <div>
                            </div>
                            { filter.length > 0 && enableFilter ?
                                filter.map(f => (
                                  <div key={f}>
                                    <input type="text" className="search-input" placeholder={f} onChange={(e)=>params.funcs.listen(e, f)}/>
                                    <div className="input-bottom-placeholder">By {f}</div>
                                  </div>
                                ))
                              : ""
                            }
                        </div>
                    </div>
                    <div className="result-box">
                        <div className="result-header"> RESULTS </div>
                        <div className="result-list">
                         {results.length > 0 ? results.map((result,i) => (
                            <div key={result.uniqueIdentifier} className="list-item">
                              <div className="image-wrapper">
                                <img className="image" src={result.imageUrl}/>
                              </div>
                              <div className="description">
                                <p className="name">{result.name}</p>
                                <p className="position-name">{result.positionName}</p>
                                <p className="area">{result.area}</p>
                              </div>
                              <div className="buttons">
                                <a target='_blank' href={result.profileUrl}>
                                  <button className='btn-search-box btn-action'>View Profile</button>
                                </a>
                                <button className='btn-search-box btn-action btn-locate' onClick={()=>params.funcs.locate(result.uniqueIdentifier)}>Locate </button>
                              </div>
                            </div>
                          ))
                          : <div className="buffer"></div>
                          }
                        </div>
                    </div>
                </div>
                <div id="svgChart"></div>
                <div className="customTooltip-wrapper">
                  {item ?
                    <div className="customTooltip">
                    {control.tooltip.image ? 
                      <div className="profile-image-wrapper" style={{"backgroundImage": `url(${item.imageUrl})`}}>
                      </div>
                    :""}
                    <div className="tooltip-hr"></div>
                    <div className="tooltip-desc">
                      <a className="name" href={item[control.tooltip.url]} target="_blank">{item[control.tooltip.heading]}</a>
                      <p className="position">{item[control.tooltip.subheading]}</p>
                      <p className="area">{item[control.tooltip.p1]}</p>
                      <p className="office">{item[control.tooltip.p2]}</p>
                      {control.tooltip.unit ? 
                        <button 
                          className={(item[control.tooltip.unitKey].type == 'business' ? " disabled " : "") + " btn btn-tooltip-department"}
                          onClick={()=>params.funcs.departmentClick(item.unit)}>
                          {item[control.tooltip.unitKey].value}
                        </button>
                      :""}
                      {control.tooltip.tagBlock ? 
                        <h4 className="tags-wrapper">
                          <span className="title"><i class="fa fa-tags" aria-hidden="true"></i>
                          </span>
                          <ul className="tags">
                            { item[control.tooltip.tags].split(',').map((v) => (
                              <li key={v}><div className="tag">{v}</div></li> 
                            ))}
                          </ul>
                        </h4>
                      :""}
                    </div>
                    <div className="bottom-tooltip-hr"></div>
                  </div>
                      : ''}
                </div>
            </div>
        </div>
    )
}

export default OrganisationChart;