import React, { useState, useEffect } from 'react';
import { Grid, Paper, makeStyles, Divider } from '@material-ui/core';
import Highcharts from 'highcharts';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const useStyles = makeStyles(theme => ({
    paperTable: {
        textAlign: 'left',
        padding: '5px 15px',
        // backgroundColor: 'transparent',
        borderRadius: 0,
        margin: '0 10px 0px 0',
    },
}));

let data = {
    title: {
        text: ''
    },
    chart: {
        type: 'line',
        renderTo: 'budgetplan'
    },
    xAxis: {
        categories: ['JAS', 'OND', 'JFM', 'AMJ']
    },
    yAxis: {
        title: {
            text: 'Budget $'
        }
    },
    plotOptions: {
        line: {
            marker: false
        }
    },
    series: [],
    credits: {
        enabled: false
    }
}

function BudgetPie(props) {
    const classes = useStyles();

    useEffect(() => {
        // Highcharts.chart(data)
    }, [])

    useEffect(() => {
        let d = { ...data }
        d.series = props.series
        d.chart.renderTo = "budgetplan" + props.id
        Highcharts.chart(d)
    }, [props.series])

    const { id } = props;

    // return <></>
    return <div id={"budgetplan" + id}
        style={{ height: '59%' }}
    ></div>

}

export default BudgetPie;
