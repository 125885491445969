import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	card: {
		minHeight: "56vh",
		height: "56vh",
		backgroundColor: '#222222',
		borderRadius: '0px'
	},
	content: {
		padding: "8px"
	},
	header: {
		padding: "4px"
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	media: {
		height: "30%",
	},
});
const ComplexCard = (props) => {
	const [data, setData] = useState(null);
	const [style, setStyle] = useState({});
	const [chartClassName, setChartClassName] = useState("");
	const classes = useStyles();

	useEffect(() => {
		setData(props.data)
		let s = props.data && isJSON(props.data.style)
			? { ...style, ...(JSON.parse(props.data.style)) } : { ...style };
		let chartClassName = props.data && props.data.className;
		setStyle(s);
		setChartClassName(chartClassName);
	}, [props.data])

	const imageFirst = () => {
		let m = data.image ? <CardMedia
			className={classes.media}
			image={data.image}
		/> : '';
		let b = data.body ? <CardContent className={classes.content}>
			<Typography variant="body2" component="p">
				{data.body}
				<br />
			</Typography>
		</CardContent> : '';
		if (data.imageFirst) {
			return (
				<React.Fragment>
					{m}
					{b}
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					{b}
					{m}
				</React.Fragment>
			)
		}
	}

	return (
		<React.Fragment>
			{data ? <Card className={classes.card + ' ' + chartClassName} style={style}>
				{data.title ? <CardHeader className={classes.header}
					title={data.title}
					subheader={data.subheader}
				/> : ''}
				{imageFirst()}
				{/* {data.image ? <CardMedia
                className={classes.media}
                image={data.image}
            /> : ''}
            {data.body ? <CardContent>
                <Typography variant="body2" component="p">
                    {data.body}
                    <br />
                </Typography>
            </CardContent> : ''} */}
				{data.footer ? <CardActions>
					<Button size="small">{data.footer}</Button>
				</CardActions> : ''}
			</Card>
				: ''}
		</React.Fragment>
	);
}

export default ComplexCard;