import React from 'react';
import packageJson from '../package.json';
import metaJson from '../assets/meta.json';
global.appVersion = metaJson.version;

global.GITHASH = metaJson.hash;

// version from `meta.json` - first param
// version in bundle file - second param
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

export default class CacheBuster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {

                console.log("caches ==", caches)
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function (names) {
                        for (let name of names) caches.delete(name);
                    });
                }

                navigator.serviceWorker.getRegistrations()
                    .then(function (registrations) {
                        console.log("registrations == ", registrations);
                        for (let registration of registrations) {
                            registration.unregister().then(v => {
                                window.location.reload(true)
                            })
                        }
                    });

                /*if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.ready.then(registration => {
                        console.log("registration == 2", registration);
                        // registration.unregister();
                    });
                }*/
                // delete browser cache and hard reload
                // window.location.reload()
                // window.location.reload(true)
            }
        };
    }

    componentDidMount() {
        fetch('/meta.json')
            .then((response) => {
                console.log('response', response);
                return response.json()
            })
            .then((meta) => {
                const latestVersion = meta.version;
                const GITHASH = meta.hash
                const currentVersion = global.appVersion;
                const currentGITHASH = global.GITHASH
                console.log('GITHASH', GITHASH, 'currentGITHASH', currentGITHASH);

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion, GITHASH, currentGITHASH);
                console.log('shouldForceRefresh ==', shouldForceRefresh, GITHASH !== currentGITHASH);
                if (shouldForceRefresh || GITHASH !== currentGITHASH) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    this.setState({ loading: false, isLatestVersion: false });
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    this.setState({ loading: false, isLatestVersion: true });
                }
            }).catch((err) => {
                console.log(err)
                this.setState({ loading: false, isLatestVersion: true });
            })
    }

    render() {
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
        return <>
            <form action="" method="get" style={{ display: 'none' }}>
                <button type="Submit">Refresh</button>
            </form>
            <form action="" method="post" style={{ display: 'none' }}>
                <button type="Submit">Refresh</button>
            </form>
            {this.props.children({ loading, isLatestVersion, refreshCacheAndReload })}
        </>
    }
}