import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import SupportingDoc from './SupportingDoc';
import Extractedinfo from './ExtractedInfo';
import './extresult.scss';
import { loadPdfAction } from 'store/actions/extractionResult';
import { saveSupportingDocument } from 'store/actions'
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
// import Container from '@material-ui/core/Container';
import { withRouter } from 'react-router-dom'
import axios from 'axios'

function ExtraxtionResult(props) {
	const { loadPdfAction } = props;
	const [supportingDocument, setSupportingDocument] = useState({ ...JSON.parse(JSON.stringify(props.supportingDocument ? props.supportingDocument : {})) });
	// const [state, setState] = useState({ selectedPdf: null });
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

	// const onPdfHandler = event => {
	// 	console.log(window.URL.createObjectURL(event.target.files[0]));
	// 	setState({ ...state, selectedPdf: window.URL.createObjectURL(event.target.files[0]) });
	// 	loadPdfAction(event.target.files[0])
	// };

	const updateSupportingDocument = () => {
		const uri = API_BASE_URL + "/custom/supportingDocument/" + supportingDocument._id
		axios({
			url: uri,
			method: 'PUT',
			data: supportingDocument,
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
			},
		}).then((response) => {
			console.log("data", response)
			setSupportingDocument(response.data);
			props.saveSupportingDocument(response.data);
		}).catch((error) => {
			// setResponse({ "error": error });
			console.log("error", error);
		})
	}

	const onChange = (e, index) => {
		console.log("event", e.target.name, index)
		let doc = { ...supportingDocument };
		doc.document[index][`${e.target.name}`] = e.target.value;
		setSupportingDocument(doc);
	}

	return (
		<div className="extraction-results">
			<div className="extraction-results-mainHeading"> Extraction Results</div>
			<div className="extraction-results-components">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<SupportingDoc {...props} supportingDocument={supportingDocument} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Extractedinfo supportingDocument={supportingDocument} onChange={onChange} />
					</Grid>
					<Grid xs={12} sm={12} className="row"><div className="col-lg-12" style={{ textAlign: "center", padding: "20px", marginBottom: "35px" }} onClick={() => updateSupportingDocument()}>
						<a className="btn btn-lg btn-success" style={{ padding: "10px 40px", color: "#ffffff", backgroundColor: "#77b300", borderColor: "#77b300", textDecoration: "none", cursor: "pointer" }}>Approve</a></div>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		user: state.user,
		supportingDocument: state.appConfig.supportingDocument,
	};
};

const mapDispatchToProps = {
	loadPdfAction,
	saveSupportingDocument
};


export default hot(withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(ExtraxtionResult)),
);
