export const listDataModal=[
    {
      "refDataModels": [
        
      ],
      "allowExternalElements": true,
      "JSONDataFormat": true,
      "XMLDataFormat": false,
      "CSVDataFormat": false,
      "_id": "5fc4d5a84985a60011fba4db",
      "dataFormat": "JSON",
      "name": "SupportingDocument",
      "description": "Supporting Document",
      "dataElements": [
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd395d80fac9b00122e3e1c",
          "name": "EmployerID",
          "description": "Employer ID",
          "type": "select"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd395d80fac9b00122e3e1d",
          "name": "Month",
          "description": "Month",
          "type": "select"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd395d80fac9b00122e3e1e",
          "name": "UploadFile",
          "description": "Upload File"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd395d80fac9b00122e3e1f",
          "name": "SupportDetails",
          "description": "Support Details",
          "type": "dataTable"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd395d80fac9b00122e3e20",
          "name": "Report",
          "description": "Report",
          "type": "reportConfigurator"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d2b",
          "name": "Employee ID",
          "description": "Employee ID",
          "type": "int"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d2c",
          "name": "Employee Name",
          "description": "Employee Name",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d2d",
          "name": "Age",
          "description": "Age",
          "type": "int"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d2e",
          "name": "Class",
          "description": "Class",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d2f",
          "name": "Billing Date",
          "description": "Billing Date",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d30",
          "name": "Base Annual Salary",
          "description": "Base Annual Salary",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d31",
          "name": "Basic Life Volume",
          "description": "Basic Life Volume",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d32",
          "name": "Basic Life Prem",
          "description": "Basic Life Prem",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d33",
          "name": "Basic ADD Volume",
          "description": "Basic ADD Volume",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d34",
          "name": "Basic ADD Prem",
          "description": "Basic ADD Prem",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d35",
          "name": "LTD Volume",
          "description": "LTD Volume",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d36",
          "name": "LTD Prem",
          "description": "LTD Prem",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d37",
          "name": "FMLA/ADA Administration",
          "description": "FMLA/ADA Administration",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d38",
          "name": "STD Volume",
          "description": "STD Volume",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d39",
          "name": "STD Prem",
          "description": "STD Prem",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d3a",
          "name": "Supp EE Life Volume",
          "description": "Supp EE Life Volume",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d3b",
          "name": "Supp EE Life Prem",
          "description": "Supp EE Life Prem",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d3c",
          "name": "Supp SP Life Volume",
          "description": "Supp SP Life Volume",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d3d",
          "name": "Supp SP Life Prem",
          "description": "Supp SP Life Prem",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d3e",
          "name": "Supp CH Life Volume",
          "description": "Supp CH Life Volume",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d3f",
          "name": "Supp CH Life Prem",
          "description": "Supp CH Life Prem",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d40",
          "name": "Total Premium",
          "description": "Total Premium",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d41",
          "name": "employerName",
          "description": "employerName",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fd4900ac14a940011930d42",
          "name": "month",
          "description": "month",
          "type": "string"
        }
      ],
      "appId": "5fc4abaa4985a60011fba4d2",
      "datasourceId": "5fc4abaa4985a60011fba4d9",
      "collectionName": "SupportingDocument",
      "orgId": "5dca073d4b044330e44e7946",
      "__v": 0
    },
    {
      "refDataModels": [
        
      ],
      "allowExternalElements": true,
      "JSONDataFormat": true,
      "XMLDataFormat": false,
      "CSVDataFormat": false,
      "_id": "5fce74fa5ad32800127967db",
      "datasourceId": "5fc4abaa4985a60011fba4d9",
      "dataElements": [
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fce74fa5ad32800127967dc",
          "name": "workflow.currentTaskId",
          "description": "workflow current TaskId",
          "type": "String"
        },
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fce74fa5ad32800127967dd",
          "name": "workflow.currentTaskName",
          "description": "workflow current TaskName",
          "type": "String"
        },
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fce74fa5ad32800127967de",
          "name": "workflow.currentAction",
          "description": "workflow current Action",
          "type": "String"
        },
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fce74fa5ad32800127967df",
          "name": "workflow.currentStatus",
          "description": "workflow current Status",
          "type": "String"
        },
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fce74fa5ad32800127967e0",
          "name": "workflow.currentRespondentField",
          "description": "workflow current Respondent Field",
          "type": "String"
        },
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fce74fa5ad32800127967e1",
          "name": "workflow.currentRespondent",
          "description": "workflow current Respondent",
          "type": "String"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174ef",
          "name": "Employee ID",
          "description": "Employee ID",
          "type": "Integer",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174f0",
          "name": "Employee Name",
          "description": "Employee Name",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174f1",
          "name": "Age",
          "description": "Age",
          "type": "Integer",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174f2",
          "name": "Class",
          "description": "Class",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174f3",
          "name": "Billing Date",
          "description": "Billing Date",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174f4",
          "name": "Base Annual Salary",
          "description": "Base Annual Salary",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174f5",
          "name": "Basic Life Volume",
          "description": "Basic Life Volume",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174f6",
          "name": "Basic Life Prem",
          "description": "Basic Life Prem",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174f7",
          "name": "Basic ADD Volume",
          "description": "Basic ADD Volume",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174f8",
          "name": "Basic ADD Prem",
          "description": "Basic ADD Prem",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174f9",
          "name": "LTD Volume",
          "description": "LTD Volume",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174fa",
          "name": "LTD Prem",
          "description": "LTD Prem",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174fb",
          "name": "FMLA/ADA Administration",
          "description": "FMLA/ADA Administration",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174fc",
          "name": "STD Volume",
          "description": "STD Volume",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174fd",
          "name": "STD Prem",
          "description": "STD Prem",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174fe",
          "name": "Supp EE Life Volume",
          "description": "Supp EE Life Volume",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb00113174ff",
          "name": "Supp EE Life Prem",
          "description": "Supp EE Life Prem",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb0011317500",
          "name": "Supp SP Life Volume",
          "description": "Supp SP Life Volume",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb0011317501",
          "name": "Supp SP Life Prem",
          "description": "Supp SP Life Prem",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb0011317502",
          "name": "Supp CH Life Volume",
          "description": "Supp CH Life Volume",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb0011317503",
          "name": "Supp CH Life Prem",
          "description": "Supp CH Life Prem",
          "type": "String",
          "isRequired": false
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fce751c05baeb0011317504",
          "name": "Total Premium",
          "description": "Total Premium",
          "type": "String",
          "isRequired": false
        }
      ],
      "name": "SupportingDoc",
      "description": "Supporting Doc Data holder",
      "type": "entitydatamodel",
      "collectionName": "SupportingDoc",
      "appId": "5fc4abaa4985a60011fba4d2",
      "orgId": "5dca073d4b044330e44e7946",
      "__v": 1
    },
    {
      "refDataModels": [
        
      ],
      "allowExternalElements": true,
      "JSONDataFormat": true,
      "XMLDataFormat": false,
      "CSVDataFormat": false,
      "_id": "5fcf5b930fac9b00122e37d6",
      "datasourceId": "5fc4abaa4985a60011fba4d9",
      "dataElements": [
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fcf5b930fac9b00122e37d7",
          "name": "workflow.currentTaskId",
          "description": "workflow current TaskId",
          "type": "String"
        },
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fcf5b930fac9b00122e37d8",
          "name": "workflow.currentTaskName",
          "description": "workflow current TaskName",
          "type": "String"
        },
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fcf5b930fac9b00122e37d9",
          "name": "workflow.currentAction",
          "description": "workflow current Action",
          "type": "String"
        },
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fcf5b930fac9b00122e37da",
          "name": "workflow.currentStatus",
          "description": "workflow current Status",
          "type": "String"
        },
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fcf5b930fac9b00122e37db",
          "name": "workflow.currentRespondentField",
          "description": "workflow current Respondent Field",
          "type": "String"
        },
        {
          "defaultValue": "",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "false",
          "_id": "5fcf5b930fac9b00122e37dc",
          "name": "workflow.currentRespondent",
          "description": "workflow current Respondent",
          "type": "String"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37e4",
          "name": "Employee ID_employee_data_oct2020",
          "description": "Employee ID_employee_data_oct2020",
          "type": "int"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37e5",
          "name": "Employee Name_employee_data_oct2020",
          "description": "Employee Name_employee_data_oct2020",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37e6",
          "name": "Billing Date_employee_data_oct2020",
          "description": "Billing Date_employee_data_oct2020",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37e7",
          "name": "Total Premium_employee_data_oct2020",
          "description": "Total Premium_employee_data_oct2020",
          "type": "double"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37e8",
          "name": "Employee ID_employee_data_nov2020",
          "description": "Employee ID_employee_data_nov2020",
          "type": "int"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37e9",
          "name": "Employee Name_employee_data_nov2020",
          "description": "Employee Name_employee_data_nov2020",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37ea",
          "name": "Billing Date_employee_data_nov2020",
          "description": "Billing Date_employee_data_nov2020",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37eb",
          "name": "Total Premium_employee_data_nov2020",
          "description": "Total Premium_employee_data_nov2020",
          "type": "double"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37ec",
          "name": "Difference",
          "description": "Difference",
          "type": "double"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37ed",
          "name": "month_employee_data_nov2020",
          "description": "month_employee_data_nov2020",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37ee",
          "name": "EmployerID_employee_data_nov2020",
          "description": "EmployerID_employee_data_nov2020",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37ef",
          "name": "month_employee_data_oct2020",
          "description": "month_employee_data_oct2020",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37f0",
          "name": "EmployerID_employee_data_oct2020",
          "description": "EmployerID_employee_data_oct2020",
          "type": "string"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37f1",
          "name": "Employee Name Mismatch",
          "description": "Employee Name Mismatch",
          "type": "bool"
        },
        {
          "defaultValue": "-",
          "isUnique": false,
          "searcheable": false,
          "lookup": false,
          "validation": "-",
          "_id": "5fcf5bf30fac9b00122e37f2",
          "name": "TotalPremium",
          "description": "TotalPremium",
          "type": "bool"
        }
      ],
      "name": "EmployeePremium",
      "description": "Employee Premium ",
      "type": "entitydatamodel",
      "collectionName": "EmployeePremium",
      "appId": "5fc4abaa4985a60011fba4d2",
      "orgId": "5dca073d4b044330e44e7946",
      "__v": 0
    }
  ]