import { Button, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FilterListIcon from '@material-ui/icons/FilterList';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StackedChart from '../../../../components/highcharts/stackedChart';
import { getMrData, removeMRSuccessMessage } from '../../../../store/actions';
import { getMonth } from '../../../../utility/utils';
import FilterMultiSelect from '../../../FilterMultiSelect';
import Loader from '../../../Loader';
import PlanningErrorModal from '../../spendplan/PlanningPages/planningErrorModal';
import { convertToNumber } from '../../spendplan/PlanningPages/planningHelperFunctions';
import Header from '../../spendplan/SpendPlan/Header';
import { URLs } from '../ApiConstants';
import CockpitTable from './cockpittable';
import { colors, selectComponent, useStyles } from './index';
const OneCr = 10000000

const SPEND_TYPES = [
    'KBD 1 Spend',
    'KBD 2 Spend - Contracted Off Invoice',
    'KBD 2 Spend - Contracted On Invoice',
    'KBD 2 Spend - Contracted Visibility',
    'KBD 2 Spend - Discretionary Visibility',
    'CTLSIB Spend',
    'SIB Spend',
]

const SpendsReviewSummary = (props) => {

    const [brand, setBrand] = useState("All Brands");
    const [brandform, setBrandform] = useState("All Brandforms");
    const [spendType, setSpendType] = useState([]);
    const [uom, setUOM] = useState("INR");
    const [anchorEl, setAnchorEl] = React.useState(null);

    const visible = Boolean(anchorEl);
    const popperId = visible ? 'transitions-popper' : undefined;

    const openFilter = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const onClose = (isSelect, values, e) => {
        if (isSelect) {
            setSpendType(values)
            fetchData(brand, brandform, values, uom)

        }
        if (anchorEl.current && anchorEl.current.contains(e.target)) {
            return;
        }
        setAnchorEl(null)
    }

    const [open, setOpen] = useState(false);
    const [alertMessageText, setAlertMessage] = React.useState("");
    const classes = useStyles();

    useEffect(() => {
        fetchData(brand, brandform, spendType, uom)
        getDataFromSKUMasterDataForDropdown()
    }, [props.account])

    const getDataFromSKUMasterDataForDropdown = () => {
        let dataModelId = '617f826eb336d60019d00226'; // TODO: need to make it configurable
        if (props.account) {
            let targetURL = "/dataconnect/filter/" + dataModelId;
            targetURL += `?type=chart`;
            targetURL += '&label=brand,brandform';
            let payload = {
                url: targetURL,
                appId: props.match.params.appid,
                dataUniqueId: 'spendReviewDropdown'
            }
            props.getMrData(payload)
        }
    }


    const fetchData = (brand, brandform, spendType, uom) => {
        const account = props.account;
        if (account) {
            let params = {
                fiscalYear: account.FinancialYear,
                account: encodeURIComponent(account.AccountName),
            };
            let m = '' + getMonth(account.CurrentFF, 'monthNumber')
            params.currentFF = (m.length === 1 ? '0' + m : m);
            let url = URLs.cockpitSpendReview;
            url += `?account=${params.account}&fiscalYear=${params.fiscalYear}&currentFF=${params.currentFF}`
            if (brand && brand !== 'All Brands') {
                url += '&brand=' + encodeURIComponent(brand);
            }
            if (spendType?.length) {
                url += '&type=' + `${encodeURIComponent(JSON.stringify(spendType))}`;
            }
            if (brandform && brandform !== 'All Brandforms') {
                url += '&brandform=' + encodeURIComponent(brandform);
            }
            if (uom) {
                url += '&uom=' + encodeURIComponent(uom);
            }
            let payload = {
                url: url,
                appId: props.match.params.appid,
                dataUniqueId: 'GET_SpendsReviewSummary'
            }
            props.getMrData(payload)
        }
    };


    useEffect(() => {
        if (props.mrData.error && props.mrData.error['GET_SpendsReviewSummary']) {
            setOpen(true)
            setAlertMessage({
                title: 'Spends Review Summary',
                p1: props.mrData.error['GET_SpendsReviewSummary']?.message || 'Something went wrong',
            });
        }
    }, [props.mrData.error]);

    const getData = () => {
        if (props.data && props.data['GET_SpendsReviewSummary']) {
            return props.data['GET_SpendsReviewSummary']?.result;
        }
    }

    const onBrandChange = (_brand) => {
        if (_brand !== brand) {
            setBrand(_brand)
            fetchData(_brand, brandform, spendType, uom)
        }
    }

    const onBrandFormChange = (_brandform) => {
        if (_brandform !== brandform) {
            setBrandform(_brandform)
            fetchData(brand, _brandform, spendType, uom)
        }
    }


    const onUOMChange = (_uom) => {
        if (_uom !== uom) {
            setUOM(_uom)
            fetchData(brand, brandform, spendType, _uom)
        }
    }



const tableConfig1 = [
    {
        headings: [
            { title: 'Spend Type', key: 'type', leftAlign: true, isString: true },
            { title: 'Sal %', key: 'sal', isPercent: true, },

            { title: 'CFF Spend', key: 'currSpend', isPercent: uom === '% RLP' },
            { title: 'Prev FF Spend', key: 'prevSpend' , isPercent: uom === '% RLP'},
            { title: 'Change vs FF', key: 'Change', isPercent: uom === '% RLP' },
            { title: 'SDE', key: 'h', isPercent: uom === '% RLP' }],
    }
]

const tableConfig2 = [
    {
        headings: [
            { title: 'Brand', key: 'brand', leftAlign: true, isString: true },
            { title: 'Spend Type', key: 'type', leftAlign: true, isString: true },
            { title: 'CFF Spend', key: 'currSpend' , isPercent: uom === '% RLP'},
            { title: 'Prev FF Spend', key: 'prevSpend' , isPercent: uom === '% RLP'},
            { title: 'Change vs FF', key: 'Change' , isPercent: uom === '% RLP'},
            { title: 'SDE %', key: 'sal', isPercent: true, },
            { title: 'JAS Spend', key: 'q1', isPercent: uom === '% RLP' },
            { title: 'OND Spend', key: 'q2', isPercent: uom === '% RLP' },
            { title: 'JFM Spend', key: 'q3', isPercent: uom === '% RLP' },
            { title: 'AMJ Spend', key: 'q4' , isPercent: uom === '% RLP'},
        ],
    }
]

const tableConfig3 = [
    {
        headings: [
            { title: 'Brand', key: 'brand', leftAlign: true, isString: true },
            { title: 'Brandform', key: 'brandForm', leftAlign: true, isString: true },
            { title: 'Spend Type', key: 'type', leftAlign: true, isString: true },
            { title: 'CFF Spend', key: 'currSpend' , isPercent: uom === '% RLP'},
            { title: 'Prev FF Spend', key: 'prevSpend' , isPercent: uom === '% RLP'},
            { title: 'Change vs FF', key: 'Change' , isPercent: uom === '% RLP'},
            { title: 'SDE %', key: 'changeFF', isPercent: true },
            { title: 'JAS Spend', key: 'q1', isPercent: uom === '% RLP' },
             { title: 'OND Spend', key: 'q2', isPercent: uom === '% RLP' },
            { title: 'JFM Spend', key: 'q3' , isPercent: uom === '% RLP'}, 
            { title: 'AMJ Spend', key: 'q4', isPercent: uom === '% RLP' },
        ],
    }
]



    const handleClose = () => {
        setOpen(false);
        props.removeMRSuccessMessage({ dataUniqueId: 'GET_SpendsReviewSummary' });
    };

    const getChartData = (key) => {
        let data = getData()
        if (data) {
            const { q1, q2, q3, q4 } = data
            let q1Val = convertToNumber(q1[key] / OneCr);
            let q2Val = convertToNumber(q2[key] / OneCr);
            let q3Val = convertToNumber(q3[key] / OneCr);
            let q4Val = convertToNumber(q4[key] / OneCr);
            return [q1Val, q2Val, q3Val, q4Val]
        }
        return []
    }

    const getSeriesData = () => {
        if (!spendType?.length) {
            return SPEND_TYPES.map((item, index) => {
                return (
                    {
                        name: item,
                        data: getChartData(item)
                    }
                )
            })
        } else {
            return SPEND_TYPES.map((item) => {
                if (spendType.includes(item)) {
                    return (
                        {
                            name: item,
                            data: getChartData(item)
                        }
                    )
                }
            }).filter(Boolean)
        }
    }

    const stackChartOption = useMemo(() => {
        return ({
            title: {
                text: null,
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: ['JAS', 'OND', 'JFM', 'AMJ',]
            },
            yAxis: {
                min: 0,
                title: {
                    text: undefined
                }
            },
            colors: colors,
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: getSeriesData()
        })
    }, [props.data]);


    if (props.mrData && props.mrData['GET_SpendsReviewSummary' + '_loading']) {
        return (
            <section className={classes.tableBodyLoading}>
                <Loader />
            </section>
        )
    }

    const getUOMs = () => {
        return [
            {
                label: 'INR Cr.',
                value: 'INR',
            },
            {
                label: '% RLP',
                value: '% RLP',
            },
        ]
    }


    const getFilterData = () => {
        if (props.data && props.data['spendReviewDropdown']) {
            return props.data['spendReviewDropdown'] || []
        }
        return []
    }
    const isActive = spendType?.length && spendType?.length !== 7;

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Header header={`SPEND REVIEW/SUMMARY`} />
                <Button
                    style={{ marginLeft: 10, width: 200 }}
                    variant="contained"
                    onClick={() => {
                        props.history.push({
                            pathname: `/apps/${props.match.params.appid}/Dashboard2`
                        });
                    }}
                    startIcon={<ArrowBackIosIcon style={{ fontSize: 14 }} />}
                    color="primary">Back to Cockpit</Button>
            </div>
            <div style={{ display: 'flex' }}>
                {selectComponent(['All Brands', ...(getFilterData()?.brand || [])], brand, onBrandChange)}
                {selectComponent(['All Brandforms', ...(getFilterData()?.brandform || [])], brandform, onBrandFormChange)}
                <div className='filter-container' style={{ marginRight: 10 }}>
                    <div className={classNames((classes.multiFilter), isActive ? classes.active : '')}>
                        Spend Type
                        <IconButton style={{ padding: 10, color: isActive ? 'tomato' : '' }} onClick={openFilter}>
                            <FilterListIcon style={{ fontSize: 14 }} />
                        </IconButton>
                        {visible && <FilterMultiSelect allValues={SPEND_TYPES} anchorEl={anchorEl} id={popperId}
                            selectedValues={spendType} onClose={onClose} isOpen={visible} />}
                    </div>
                </div>
                {selectComponent(getUOMs(), uom, onUOMChange, true, 'UOM')}
            </div>
            <div style={{ width: '100%', }}>
                <StackedChart options={stackChartOption} />
            </div>
            <h6 style={{ color: 'black', margin: 10, }}>Spend Type Wise Split</h6>
            <CockpitTable data={getData()?.spendSplit || []} tableConfig={tableConfig1} />
            <h6 style={{ color: 'black', margin: 10, }}>Brand-wise Details</h6>
            <CockpitTable height={260} data={getData()?.brandWise || []} tableConfig={tableConfig2} />
            <h6 style={{ color: 'black', margin: 10, }}>Brand-form wise Details</h6>
            <CockpitTable height={260} data={getData()?.brandForm || []} tableConfig={tableConfig3} />
            <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} />
        </div>
    )
}
const mapDispatchToProps = {
    getMrData,
    removeMRSuccessMessage,
};

const mapStateToProps = state => {
    return {
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
        account: state.mrReducer?.account,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(SpendsReviewSummary)));
