import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import axios from 'axios';
import ConfirmDialog from '../SpendPlan/ConfirmDialog';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getFileNameWithTimeStamp } from '../../../../utility/utils';
import FileDownload from 'js-file-download';

// let globalLinks = ["BOP Volumes", "RLP per MSU", "PY Retailing",
// 	"PY RLP/MSU", "Channel Retailing Saliences", "YTD Actual Retailing"]

let initialGlobalLinks = {
  'rowsPerPage': '10', 'page': '0',
  'data': [{
    'Title': 'Channel Retailing Salience', 'LinkType': 'Internal', 'InternalLinkType': 'Page',
    'LinkPageURL': 'rlpSaliences', 'Group': 'Volumes, Retailing, Salience & RTDX Data', 'LinkListOrder': 6,
  },
  {
    'Title': 'YTD Actual Retailing', 'LinkType': 'Internal', 'InternalLinkType': 'Page', 'LinkPageURL': 'rlpytdact',
    'Group': 'Volumes, Retailing, Salience & RTDX Data', 'LinkListOrder': 5,
  },
  {
    'Title': 'Past Year RLP/MSU', 'LinkType': 'Internal', 'Group': 'Volumes, Retailing, Salience & RTDX Data',
    'InternalLinkType': 'Page', 'LinkPageURL': 'PYRLPMSU', 'LinkListOrder': 4,
  },
  {
    'Title': 'RLP per MSU',
    'LinkType': 'Internal',
    'Page': '/setupdata',
    'Group': 'Volumes, Retailing, Salience & RTDX Data',
    'InternalLinkType': 'Page',
    'LinkPageURL': 'RLPperMSU',
    'LinkListOrder': 2,
  },
  {
    'Title': 'BOP Volumes',
    'LinkType': 'Internal',
    'Page': '/setupdata',
    'Group': 'Volumes, Retailing, Salience & RTDX Data',
    'InternalLinkType': 'Page',
    'LinkPageURL': 'BOPVolumes',
    'LinkListOrder': 1,
  },
  {
    'Group': 'Volumes, Retailing, Salience & RTDX Data', 'Page': '/setupdata', 'LinkType': 'Internal',
    'Title': 'Past Year Retailing', 'InternalLinkType': 'Page', 'LinkPageURL': 'PYRetailing', 'LinkListOrder': 3,
  },
  {
    'Group': 'Volumes, Retailing, Salience & RTDX Data', 'Page': '/setupdata', 'LinkType': 'Internal',
    'Title': 'PY SD%RLP', 'InternalLinkType': 'Page', 'LinkPageURL': 'PYSDRLP', 'LinkListOrder': 3,
  },
  {
    'Group': 'Volumes, Retailing, Salience & RTDX Data', 'Page': '/setupdata', 'LinkType': 'Internal',
    'Title': 'F1 Inputs', 'InternalLinkType': 'Page', 'LinkPageURL': 'f1inputs', 'LinkListOrder': 7,
  },
  {
    'Group': 'Volumes, Retailing, Salience & RTDX Data', 'Page': '/setupdata', 'LinkType': 'Internal',
    'Title': 'Corp Bud', 'InternalLinkType': 'Page', 'LinkPageURL': 'corpbud', 'LinkListOrder': 8,
  },
  {
    'Group': 'Volumes, Retailing, Salience & RTDX Data', 'Page': '/setupdata', 'LinkType': 'Internal',
    'Title': 'MR & Ecomm', 'InternalLinkType': 'Page', 'LinkPageURL': 'mrecomm', 'LinkListOrder': 9,
  },
  {
    'Group': 'Volumes, Retailing, Salience & RTDX Data', 'Page': '/setupdata', 'LinkType': 'Internal',
    'Title': 'Accruals & HC', 'InternalLinkType': 'Page', 'LinkPageURL': 'accrualshc', 'LinkListOrder': 10,
  },
  {
    'Group': 'Volumes, Retailing, Salience & RTDX Data', 'Page': '/setupdata', 'LinkType': 'Internal',
    'Title': 'ITT', 'InternalLinkType': 'Page', 'LinkPageURL': 'itt', 'LinkListOrder': 11,
  },
  {
    'Group': 'Volumes, Retailing, Salience & RTDX Data', 'Page': '/setupdata', 'LinkType': 'Internal',
    'Title': 'CTS', 'InternalLinkType': 'Page', 'LinkPageURL': 'CTS', 'LinkListOrder': 12,
  },
  {
    'Group': 'Volumes, Retailing, Salience & RTDX Data', 'Page': '/setupdata', 'LinkType': 'Internal',
    'Title': 'TDC', 'InternalLinkType': 'Page', 'LinkPageURL': 'tdc', 'LinkListOrder': 13,
  },
  ],
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: 1,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 2,
  },
  tableContainer: {
    maxWidth: '95vw',
    // margin: "40px auto",
    overflowY: 'hidden !important',
    borderRadius: '8px',
  },
  table: {
    minWidth: 700,
  },
  tableHead: {
    '& th': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
    '& span': {
      fontWeight: 'bold',
    },
  },
  tableBody: {
    '& td': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      cursor: 'pointer',
    },
    '& p': {
      display: 'flex',
    },
  },
  tableInput: {
    border: '0',
    background: 'transparent',
    maxWidth: '55px',
    appearance: 'none',
  },
  quarterHead: {
    paddingRight: '5px',
    borderRight: '1px solid #7b7272',
    marginRight: '5px',
  },
  quarterBody: {
    paddingRight: '5px',
    marginRight: '5px',
  },
  greenFont: {
    '& span': {
      color: 'green',
    },
  },
  confirmationDialoge: {
    '& .MuiDialog-paperWidthSm': {
      // height: '320px',
      width: '600px',
    },
  },
  para: {
    fontSize: '28px !important',
    fontWeight: 600,
    color: '#000',
  },
  headingBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  circularBar:{
    color: 'rgb(25, 118, 210)',
    height: 20,
    width: 20,
  }
}));


const compare = (a, b) => {
  let comparison = 0;
  if (a.LinkListOrder > b.LinkListOrder) {
    comparison = 1;
  } else if (a.LinkListOrder < b.LinkListOrder) {
    comparison = -1;
  }
  return comparison;
};

const GlobalLinks = props => {
  const classes = useStyles();
  const [link, setLink] = useState(props.page.name ? props.page.name : 'BOP Volumes');
  const [url, setUrl] = useState('');
  const [globalLinks, setGlobalLinks] = useState(initialGlobalLinks.data.sort(compare));
  const [alertMessageText, setAlertMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [alertConfirm, setAlertConfirm] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleChange = (value, url) => {
    setUrl(url);
    if (props.edited) {
      handleClickOpen();
      setAlertMessage({
        title: 'Confirmation',
        p1: `You have unsaved changes in ${link}.`,
        p2: 'Are you sure you want to discard these changes?',
      });
    } else {
      setLink(value);
      props.history.push(url);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e, res) => {
    setOpen(false);
    if (e) {
      props.history.push(url);
    }
  };

  const getData = () => {
    let conditions = [{
      selectedConditionDataElement: 'Group',
      selectedConditionOperator: 'matches',
      value: 'Volumes, Retailing, Salience & RTDX Data',
      selectedConditionClause: 'and',
    }];
    const uri = API_BASE_URL + `/dataconnect/table/${props.page.pageLevelDataModel}`
      // "/dataconnect/data/" + collectionName
      // let cond = encodeURIComponent(JSON.stringify(chartData.conditions));
      + '?' + (conditions ? 'conditions=' + encodeURIComponent(JSON.stringify(conditions)) + '&' : '')
      + 'type=list&fields=&'
      + 'page=0&rowsPerPage=10';

    axios({
      url: uri,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        appId: props.match.params.appid,
      },
    })
      .then((response) => {
        setGlobalLinks(response.data.data.sort(compare));
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const addQueryParamsToUrl = (url = '') => {
    if (url) {
      let urlSearchParams = new URLSearchParams(window.location.search);
      if (urlSearchParams.get('brand') && urlSearchParams.get('year') && urlSearchParams.get('month')) {
        url += `?brand=${urlSearchParams.get('brand')}&year=${urlSearchParams.get('year')}&month=${urlSearchParams.get('month')}`;
      }
    }
    return url;
  };

  useEffect(() => {
    // getData()
  }, []);

  const downloadTemplate = ()=>{
    let uri = API_BASE_URL + `/sptemplate/download`;
    setDownloadLoading(true)
    axios({
      url: uri,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        appId: props.match.params.appid,
      },
    })
      .then((response) => {
        FileDownload(response.data, 'TradeSpend_Upload_Templates.zip');
        setDownloadLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setDownloadLoading(false)
      });
  }

  function findLeft(element) {
    var rec = document.getElementById(element).getBoundingClientRect();
    return rec.left + window.scrollX;
  } 

  useEffect(()=> {
    document.getElementById("tab-container").scrollLeft = parseInt(findLeft(props.page.name))/5 > 180 ? parseInt(findLeft(props.page.name))/5 : parseInt(findLeft(props.page.name))/10;
  },[props.page.name])


  return <div className='setup-data'>
    <div className={classes.headingBox}>
    <div id={'tab-container'} className="tab-container m-top-10 m-bottom-20">
      {globalLinks.map((gLink, i) => {
        let url;
        if (gLink.LinkType === 'Internal') {
          url = gLink.InternalLinkType === 'Form'
            ? `/apps/${props.match.params.appid}/${gLink.LinkFormURL}/new`
            : `/apps/${props.match.params.appid}/${gLink.LinkPageURL}`;
          url = gLink.InternalLinkType === 'Page' ? addQueryParamsToUrl(url) : url;
        } else {
          url = gLink.ExternalLink;
        }
        return <div key={i}
          className={`setup-data-headings p-bottom-10 p-right-10 p-left-10 ${gLink.Title === props.page.name ? `active-global-link` : ``}`}
          onClick={() => {
            handleChange(gLink.Title, url);
          }}>
          <span id={gLink.Title} className={gLink.Title === props.page.name ? 'select-global-link' : 'normal-link'}
            style={{ whiteSpace: "nowrap" }}>{gLink.Title}</span>
        </div>;
      })}
    </div>
    <Button
        onClick={downloadTemplate}
        title={"Template Files for Upload"}
        startIcon={<GetAppIcon />}
        variant="text"
        size="small"
        disabled={downloadLoading}
        style={{ height: 30, color: 'rgb(25, 118, 210)', marginTop: 15}}
      >
        {
          downloadLoading?<CircularProgress className={classes.circularBar} size={24} />:'Template'
        }
      </Button>
    </div>
    <ConfirmDialog open={open} handleClose={handleClose} alertMessageText={alertMessageText}
      classes={classes} confirm={true} />
  </div>;
};

const mapDispatchToProps = {};

const mapStateToProps = ({ user, navigation }) => {
  return {
    user,
    navigation,
  };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalLinks)));
