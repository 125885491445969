import {
  Checkbox,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import axios from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getMonth } from 'utility/utils';
import { generateGUID } from '../../../../../modules/page/page.module';
import {
  addReconReviewBaseline,
  addSpTprRow,
  brandformFilterSelect,
  clearModifyTPRs,
  deleteCheckedTpr,
  getBrandformfilter,
  getChannelfilter,
  getClusterfilter,
  getPlanFilterDataTPR,
  getSpTprData,
  getSpTprExtraData,
  modifyTPRs,
  removeModifyTPRs,
  removeSuccessMessage,
  setSaveReconFlag
} from '../../../../../store/actions';
import { capitalize, getFileNameWithTimeStamp } from '../../../../../utility/utils';
import ConfirmDialog from '../../SpendPlan/ConfirmDialog';
import { getCurrentFFForPayload, getFiscalYearFormat, monthsShortForm } from '../../spendplanUtils';
import { ActionType, FilterType } from '../PlanHeader';
import PlanHeader from '../PlanHeader/index';
import PlanningErrorModal from '../planningErrorModal';
import {
  commonStyle,
  convertToNumber,
  downloadFile, getQuarterBasedOnMonth,
  isReadOnly, PlanningLoading,
  renderNoDataText,
  setSticky,
  StyledTableCell,
  StyledTableRow
} from '../planningHelperFunctions';
import { getTsData } from '../../../../../store/actions';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700,
    paddingRight: 7,
  },
  tableHead: {
    position: 'sticky', top: 0, zIndex: 10,
    '& th': {
      textAlignLast: 'center',
    },
    '& th:not(.adjustBorder)': {
      boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
    },
    '& th.adjustBorder': {
      paddingRight: '0',
      '& span': {
        paddingLeft: '8px',
        boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
        display: 'flex',
        height: '40px',
        alignItems: 'center',
      },
    },
    '& th.lastYearHeader': {
      '& span': {
        height: '40px',
        display: 'flex',
        padding: '0 16px',
        textAlign: 'center',
        alignItems: 'center',
      },
    },
    '& span': {
      fontWeight: 'bold',
    },
  },
  tableBody: {
    '& td': {
      boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
      borderBottom: '0',
      cursor: 'pointer',
      textAlign: 'center',
      '& input': {
        textAlign: 'center',
      },
    },
    '& td.noBorder': {
      boxShadow: 'none',
    },
    '& p': {
      display: 'flex',
    },
  },
  tableInput: {
    border: '0',
    height: '30px',
    // width: "100%",
    maxWidth: '75px',
    appearance: 'none',
    background: 'transparent',
    '&:focus': {
      background: '#fff',
    },
  },
  textInput: {
    border: '0',
    height: '38px',
    appearance: 'none',
    background: 'transparent',
    width: '90px',
    color: 'inherit',
    '@media(max-width:1367px)': {
      height: '35px',
    },
  },
  combinedHeaderSection: {
    '@media(max-width:1366px)': {
      height: '45px',
    },
    display: 'flex',
    alignItems: 'center',
    '& p': {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '82px',
    },
    '& p:not(:last-of-type)': {
      height: '40px',
      textAlign: 'center',
      paddingRight: '16px',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      margin: '0px',
    },
    '& p:not(:first-of-type)': {
      paddingLeft: '16px',
      textAlign: 'center',
    },
  },
  combinedHeaderTotalSection: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    '& p': {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '82px',
    },
    '& p:not(:last-of-type)': {
      height: 20,
      textAlign: 'center',
      paddingRight: '16px',
      margin: '0px',
    },
    '& p:not(:first-of-type)': {
      paddingLeft: '16px',
      textAlign: 'center',
    },
  },
  combinedBodySection: {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      borderRight: '0',
      width: '82px',
      justifyContent: 'space-around',
    },
    '& p:not(:last-of-type)': {
      height: '38px',
      textAlign: 'center',
      paddingRight: '16px',
      paddingTop: '2px',
      paddingBottom: '2px',
      margin: '0px',
      '& input': {
        height: '100%',
      },
    },
    '& p:not(:first-of-type)': {
      paddingLeft: '16px',
      textAlign: 'center',
    },
  },
  cell: {
    height: 38,
  },
  ...commonStyle,
  flexAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  marginTop: {
    marginTop: '25px'
  }
}));

const TradePriceReduction = props => {
  const classes = useStyles();
  const {
    getSpTprData, match, sptprData, addSpTprRow, getChannelfilter,
    getBrandformfilter, getClusterfilter, brandformFilterSelect, getSpTprExtraData, page, deleteCheckedTpr, getPlanFilterDataTPR, scenario, tsData, getTsData
  } = props;
  const modalData = props.modalData ? props.modalData : '';
  const { data: sptprFieldsData, error, extraDataError, extraData: sptprExtraData, getNewData, selectBrand, successMsg, saved } = sptprData;
  const [readOnly, setReadOnly] = useState(false);
  const [urlSetup, setUrlSetup] = useState(true);
  const [urlSetupParams, setUrlSetupParams] = useState(true);
  const [brand, setBrand] = useState(selectBrand);
  const [fieldEdited, setFieldEdited] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false)

  const [talkSheetFilterData, setTalkSheetFilterData] = useState([]);

  const checkForUrlParams = () => {
    if (urlSetupParams) {
      let urlPayload = {}, readonly = false, isModal = false;
      const params = match.params,
        urlSearchParams = new URLSearchParams(window.location.search);

      if (modalData && modalData.brand && modalData.month && modalData.year) {
        urlPayload = {
          brand: modalData.brand, year: modalData.year, currentFF: getCurrentFFForPayload(modalData.month),
        };
        isModal = true;
      } else if (params.brand && params.currentFF && params.year) {
        urlPayload = {
          brand: params.brand, currentFF: getCurrentFFForPayload(params.currentFF), year: params.year,
        };
        readonly = true;
      } else if (urlSearchParams.get('brand') && urlSearchParams.get('year') && urlSearchParams.get('month')) {
        urlPayload = {
          brand: urlSearchParams.get('brand'),
          currentFF: getCurrentFFForPayload(urlSearchParams.get('month')),
          year: urlSearchParams.get('year'),
          channel: decodeURIComponent(urlSearchParams.get('channel')),
          cluster: decodeURIComponent(urlSearchParams.get('cluster')),
          brandForm: decodeURIComponent(urlSearchParams.get('brandForm')),
          planName: urlSearchParams.get('planName'),
          planMonth: urlSearchParams.get('planMonth'),
        };
        readonly = true;
      }
      if ((readonly && isReadOnly(urlPayload.currentFF, urlPayload.year, brand)) || isModal) {
        setReadOnly(true);
      } else {
        setReadOnly(false);
      }
      setUrlSetupParams(false);
      return urlPayload;
    }
    return {};
  };

  const [urlPayload, seturlPayload] = useState(checkForUrlParams());
  const [qtr, setQtr] = useState(urlPayload.planMonth ? [getQuarterBasedOnMonth(urlPayload.planMonth)] : []);
  const [plan, setPlan] = useState(urlPayload.planName ? [urlPayload.planName] : []);
  const [date, setDate] = useState('');
  const [open, setOpen] = useState(false);
  const [month, setMonth] = useState(urlPayload.planMonth ? [urlPayload.planMonth] : []);
  const [channel, setChannel] = useState(urlPayload.channel ? [urlPayload.channel] : []);
  const [cluster, setCluster] = useState(urlPayload.cluster ? [urlPayload.cluster] : []);
  const [pageCount, setPageCount] = useState(0);
  const [fieldData, setFieldData] = useState([]);
  const [extraData, setExtraData] = useState([]);
  const [brandForm, setBrandForm] = useState(urlPayload.brandForm ? [urlPayload?.brandForm] : []);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rowSelected, setRowSelected] = useState('');
  const [saveButtonState, setSaveButton] = useState(false);
  const [alertMessageText, setAlertMessage] = useState('');
  const [checkedTprState, setCheckedTpr] = useState([]);
  const [saveBtnDisabled, disableSaveBtn] = useState(false)
  const [deleteConfirmState, setDeleteConfirm] = useState({
    isOpen: false,
    deleteMessage: '',
  });
  const [confirmState, setConfirm] = useState({ isOpen: false, confirmMessage: '' });
  const [showColumns, setShowColumns] = useState([
    {key: 'BOP', collapse: false},
    {key: 'Actuals', collapse: true},
    {key: 'VSOD Diff%(Fcst-Act)', collapse: true},
    {key: 'ActualRetailing(crores)', collapse: true},
    {key: 'Fcst(BOP)Retailing(crores)', collapse: true},
  ]);

  useEffect(() => {
    setSticky();
  });

  useEffect(() => {
    props.clearModifyTPRs()
    if (brand) {
      !sptprData.filterData?.channels?.length && getChannelfilter({
        brandFormName: '',
        formType: '',
        appid: match.params.appid,
      });
      !sptprData.filterData?.clusters?.length && getClusterfilter({ appid: match.params.appid });
    }
  }, []);

  useEffect(() => {
    if (getNewData) {
      fetchData(brandForm, channel, cluster, month, qtr, currentPage, rowsPerPage, brand, plan);
      fetchPlanFilter()
      setRowSelected('');
      const { saveReconFlag } = props.reconReviewData;
      if (saveReconFlag) {
        let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
        m = m.length === 1 ? '0' + m : m;
        let data = {
          currentFF: m,
          fiscalYear: brand ? `${brand.FinancialYear}` : '',
          label: 'TPR Actualization',
          brand: brand.BrandName,
        }
        props.addReconReviewBaseline({ data, appId: match.params.appid });
        props.setSaveReconFlag(false);
      }
    }
  }, [getNewData]);

  useEffect(() => {
    if (error) {
      if (typeof error === 'string') {
        setAlertMessage({
          title: 'Trade Price Reduction',
          p1: error,
        });
        handleClickOpen();
      } else {
        setAlertMessage({
          title: 'Trade Price Reduction',
          ...error,
        });
        handleClickOpen();
      }
    }
  }, [error]);

  useEffect(() => {
    if (successMsg) {
      setAlertMessage({
        title: saved ? 'TPR - Save' : 'Trade Price Reduction',
        p1: successMsg,
      });
      handleClickOpen();
    }
  }, [successMsg]);

  useEffect(() => {
    if (sptprFieldsData && sptprFieldsData.result && sptprFieldsData.result.data && !error) {
      const { data: { result } } = sptprData, { total, data } = result;
      // in case of delete we will unsaved plan if there are some
      let unsavedPlans = fieldData.filter(i => i._id.startsWith('newplan'));
      setFieldData([...unsavedPlans, ...data]);
      if (unsavedPlans.length === 0 && !Object.keys(props.modifiedRecords).length) {
        setSaveButton(false);
        disableSaveBtn(false)
      }
      callSpTprExtraData(data);
      setPageCount(total);

    }
  }, [sptprFieldsData]);

  useEffect(() => {
    if (selectBrand && typeof selectBrand === 'object' && selectBrand !== null && Object.keys(selectBrand).length > 0) {
      setBrand(selectBrand);
      getBrandformfilter({ brandName: selectBrand.BrandName, appid: match.params.appid });
      let m = '' + getMonth(selectBrand.CurrentFF, 'monthNumber');
      m = m.length === 1 ? '0' + m : m;
      getPlanFilterDataTPR({
        currentFF: m,
        fiscalYear: `${selectBrand.FinancialYear}`,
        scenario: `${scenario || '01'}`,
        url: 'sptpr',
        brand: selectBrand.BrandName,
        appid: match.params.appid,
      });
      if (!urlSetup) {
        setBrandForm('');
        setCluster('');
        setChannel('');
        setPlan([]);
      } else {
        setUrlSetup(false);
      }
    }
  }, [selectBrand]);

  useEffect(() => {
    setCheckedTpr([]);
  }, [sptprFieldsData?.result?.total]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (successMsg || error) {
      props?.removeSuccessMessage(null);
    }
  };

  const callSpTprExtraData = (tableData) => {
    let newData = [...tableData], filteredData = [];
    newData.forEach(element => {
      let month = element.month;
      if (!isNaN(element.month)) {
        month = moment()
          .month(element.month)
          .format('MMM')
          .toLowerCase();
      }
      let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
      m = m.length === 1 ? '0' + m : m;
      let objToAdd = {
        month,
        currentFF: brand ? `${m}` : '',
        fiscalYear: brand ? `${brand.FinancialYear}` : '',
        channel: element.channel,
        brandform: element.brandform,
        scenario: props?.sptprData?.selectScenario
      };

      let find = filteredData.find(a => a.channel === objToAdd.channel && a.brandform === objToAdd.brandform && a.month === month);
      if (!find) {//add the record if not existing already
        filteredData.push(objToAdd);
      }
    });
    getSpTprExtraData({
      filteredData,
      appid: match.params.appid,
      dataModalId: page?.pageLevelDataModel,
    });
  };

  useEffect(() => {
    if (sptprExtraData && !extraDataError) {
      setExtraData([...extraData, ...sptprExtraData]);
    } else if (extraDataError && extraDataError != 'Bad Request') {
      setAlertMessage({
        title: 'Trade Price Reduction',
        p1: extraDataError,
      });
      handleClickOpen();
    }
  }, [sptprExtraData]);

  const saveToStore = (record) => {
    props.modifyTPRs(record)
  }

  const handleFieldChange = (target, index, rowNames = [], id = '') => {
    const { name, value } = target;
    if (name === 'SKUSalience' || name === 'SpendInPercent' || name === 'VSODInPercent' || name === 'IHRYTDActuals' || name === 'EstdAddtlSpend') {
      let fieldsObject = fieldEdited[id] ? { ...fieldEdited[id] } : {};

      setFieldEdited({
        ...fieldEdited, [id]: {
          ...fieldsObject,
          [name]: true,
        },
      });
    }
    setSaveButton(true);
    saveBtnDisabled && disableSaveBtn(false);
    let oldData = JSON.parse(JSON.stringify(fieldData));
    if (oldData && oldData[index]) {
      // if it is new plan and is checked and update all new checked plan
      if (checkedTprState.length > 0 && checkedTprState.includes(oldData[index]._id)) {
        let updateFieldData = oldData.map(i => {
          if (checkedTprState.includes(i._id)) {
            if (rowNames && Array.isArray(rowNames) && rowNames.length > 0) {
              if (rowNames.length === 1) {
                i.planData[name] = value;
              } else if (rowNames.length === 2) {
                i.planData.estimates[name] = value;
              }
            } else {
              i[name] = value;
              if (name === 'month') {
                i['quarter'] = getQuarterBasedOnMonth(value);
              }
            }
            saveToStore(i)
            return i;
          } else {
            return i;
          }
        });
        setFieldData([...updateFieldData]);
      } else {
        if (rowNames && Array.isArray(rowNames) && rowNames.length > 0) {
          if (rowNames.length === 1) {
            oldData[index].planData[name] = value;
          } else if (rowNames.length === 2) {
            oldData[index].planData.estimates[name] = value;
          }
        } else {
          oldData[index][name] = value;
          if (name === 'month') {
            oldData[index]['quarter'] = getQuarterBasedOnMonth(value);
          }
        }
        saveToStore(oldData[index])
        setFieldData([...oldData]);
      }
    }
  };

  const onFieldBlur = (row, key) => {
    if (key === 'brandform' || key === 'channel') {//run only if these 2 fields are there
      const { brandform, channel } = row;
      if ((brandform && brandform.length > 0) && (channel && channel.length > 0)) {
        if (extraData.length > 0) {
          let find = extraData.find(a => a.channel === channel && a.brandform === brandform);
          if (find) {
            return;
          } else {
            callSpTprExtraData([...fieldData]);
          }
        } else {
          callSpTprExtraData([...fieldData]);
        }
      }
    }
  };

  const calculateBudget = (currentRow) => {
    let MSU = 0, budget = 0;
    const { currentFF, brandform, planData, channel, month } = currentRow;
    let matchedExtraData = extraData.find(a => a.month === month.toLowerCase() && a.brandform === brandform && a.channel === channel);
    if (matchedExtraData) {
      const { estimates } = planData;
      const { SKUSalience, SpendInPercent, VSODInPercent } = estimates;
      const { bopvolumes, bopsalience, boprlppermsu, firmForex, gst } = matchedExtraData;
      MSU = Number(bopvolumes) * Number(bopsalience) * Number(SKUSalience);
      budget = MSU * Number(boprlppermsu) * Number(SpendInPercent)
        * Number(VSODInPercent) / Number(firmForex) / 1000 / (1 + gst);
      return budget;
    }
    return 0;
  };

  const calculateLBEBOP = (row) => {
    const { planData } = row;
    let totalActuals = Number(planData.IHRYTDActuals) + Number(planData.EstdAddtlSpend);
    return totalActuals > 0 ? totalActuals : calculateBudget(row);
  };

  const calculateVSODActual = (row) => {
    const { planData, currentFF, brandform, channel, month } = row;
    let lbe_bop = calculateLBEBOP(row);
    let matchedExtraData = extraData.find(a => a.month === month.toLowerCase() && a.brandform === brandform && a.channel === channel);
    if (matchedExtraData) {
      const { estimates: { SKUSalience, SpendInPercent } } = planData;
      const { firmForex } = matchedExtraData;
      const ActualRetailingCrores = calculateActualRetailingCrores(row);
      let calculation = (lbe_bop / (ActualRetailingCrores * SKUSalience * 10000000 / firmForex / 1000)
        / Number(SpendInPercent));
      if (calculation && isFinite(calculation)) {
        calculation = calculation * (1 + matchedExtraData.gst);
        return Math.round(Number(calculation * 100));
      } else {
        return 0;
      }
    }
    return 0;
  };

  const calculateVSODDiff = (row) => {
    const { planData, currentFF, brandform, channel, month } = row;
    let matchedExtraData = extraData.find(a => a.month === month.toLowerCase() && a.brandform === brandform && a.channel === channel);
    if (matchedExtraData) {
      const { isRecordActual } = matchedExtraData;
      if (isRecordActual) {
        let toReturn = Number(planData.estimates['VSODInPercent'] * 100) - Number(calculateVSODActual(row));
        return Number(toReturn).toLocaleString();
      }
      return 0;
    }
    return 0;
  };

  const calculateActualRetailingCrores = (row) => {
    const { currentFF, brandform, channel, month } = row;
    let matchedExtraData = extraData.find(a => a.month === month.toLowerCase() && a.brandform === brandform && a.channel === channel);
    if (matchedExtraData) {
      const { isRecordActual, rlpcrores } = matchedExtraData;
      if (isRecordActual) {
        return Number(rlpcrores) || 0;
      }
      return 0;
    }
    return 0;
  };

  const calculateBOPRetailingCrores = (row) => {
    const { currentFF, brandform, channel, month } = row;
    let matchedExtraData = extraData.find(a => a.month === month.toLowerCase() && a.brandform === brandform && a.channel === channel);
    if (matchedExtraData) {
      const { isRecordActual } = matchedExtraData;
      if (isRecordActual) {
        const { boprlppermsu, bopvolumes, bopsalience } = matchedExtraData;
        let toReturn = ((boprlppermsu * bopvolumes) / 10000000 * bopsalience);
        return Number(toReturn || 0);
      }
      return 0;
    }
    return 0;
  };

  const createNewPlanPress = () => {
    let oldData = JSON.parse(JSON.stringify(fieldData));
    let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
    m = m.length === 1 ? '0' + m : m;
    let lm = '' + getMonth(brand.CurrentFF, 'lastMonth');
    lm = lm.length === 1 ? '0' + lm : lm;
    const dataToAdd = {
      editable: true,
      fiscalYear: brand ? `${brand.FinancialYear}` : '',
      currentFF: brand ? `${m}` : '',
      lastFF: brand ? `${lm}` : '',
      createdBy: brand ? brand.createdBy : '',
      spendType: 'Trade Price Reduction',
      salesManager: '',
      msnpProjectManager: '',
      brand: brand ? brand.BrandName : '',
      brandform: brandForm?.length ? brandForm[0] : '',
      subBrandform: '',
      channel: channel?.length ? channel[0] : '',
      cluster: cluster?.length ? cluster[0] : 'All India',
      planName: 'NewPlan_1',
      plandescription: '',
      month: '',
      year: `${new Date().getFullYear()}`,
      planData: {
        gst: '18%',
        lastYearEstimates: {
          type: 'KP',
          spendInPercent: 0,
          vsodInPercent: 0,
        },
        lastCycleEstimates: {
          type: 'BP',
          spendInPercent: 0,
          vsodInPercent: 0,
        },
        estimates: {
          type: 'LP',
          SKUSalience: 1,
          SpendInPercent: 1,
          VSODInPercent: 0,
          BudgetInMillions: 0,
        },
        IHRYTDActuals: 0,
        EstdAddtlSpend: 0,
        LBEBOP: 0,
        TotalActuals: 0,
        VSODForecastInPercentage: 0,
        VSODActualInPercentage: 0,
        ActualRetailingCrores: 0,
        BOPRetailingCrores: 0,
      },
    };

    let newDataToAdd = monthsShortForm.map((item, index) => {
      let copyDataToAdd = JSON.parse(JSON.stringify({ ...dataToAdd, _id: 'newplan_' + generateGUID() }));
      copyDataToAdd.month = item;
      copyDataToAdd.quarter = getQuarterBasedOnMonth(item);
      props.modifyTPRs(copyDataToAdd);
      return copyDataToAdd;
    });
    oldData.splice(0, 0, ...newDataToAdd);
    setFieldData([...oldData]);
    setRowSelected(0);
    setSaveButton(true);
    saveBtnDisabled && disableSaveBtn(false);
  };

  const duplicateRow = () => {
    const oldData = JSON.parse(JSON.stringify(fieldData));
    const selectedRowData = oldData[rowSelected];
    selectedRowData.duplicated = selectedRowData.duplicated ? selectedRowData.duplicated + 1 : 1;
    let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
    m = m.length === 1 ? '0' + m : m;
    let lm = '' + getMonth(brand.CurrentFF, 'lastMonth');
    lm = lm.length === 1 ? '0' + lm : lm;
    const dataToAdd = {
      brandform: selectedRowData.brandform,
      channel: selectedRowData.channel,
      cluster: selectedRowData.cluster,
      // fiscalYear: selectedRowData.fiscalYear,
      fiscalYear: (brand ? brand.FinancialYear : selectedRowData.fiscalYear) + "",
      currentFF: brand ? `${m}` : '',
      lastFF: brand ? `${lm}` : '',
      planName: selectedRowData.duplicated
        ? `${selectedRowData.planName}_${selectedRowData.duplicated}`
        : selectedRowData.planName + '_1',
      month: selectedRowData.month,
      quarter: selectedRowData.quarter,
      createdBy: brand ? brand.createdBy : '',
      spendType: 'Trade Price Reduction',
      salesManager: '',
      msnpProjectManager: '',
      brand: brand ? brand.BrandName : '',
      year: `${new Date().getFullYear()}`,
      subBrandform: '',
      planData: {
        lastYearEstimates: {
          vsodInPercent: selectedRowData.planData.lastYearEstimates.vsodInPercent,
          spendInPercent: selectedRowData.planData.lastYearEstimates.spendInPercent,
          editable: true,
        },
        lastCycleEstimates: {
          spendInPercent: selectedRowData.planData.lastCycleEstimates.spendInPercent,
          vsodInPercent: selectedRowData.planData.lastCycleEstimates.vsodInPercent,
          editable: true,
        },
        estimates: {
          BudgetInMillions: selectedRowData.planData.estimates.BudgetInMillions,
          SKUSalience: Number(selectedRowData.planData.estimates.SKUSalience),
          SpendInPercent: selectedRowData.planData.estimates.SpendInPercent,
          VSODInPercent: selectedRowData.planData.estimates.VSODInPercent,
          editable: true,
        },
        ActualRetailingCrores: selectedRowData.planData.ActualRetailingCrores,
        BOPRetailingCrores: selectedRowData.planData.BOPRetailingCrores,
        EstdAddtlSpend: selectedRowData.planData.EstdAddtlSpend,
        IHRYTDActuals: selectedRowData.planData.IHRYTDActuals,
        LBEBOP: selectedRowData.planData.LBEBOP,
        TotalActuals: selectedRowData.planData.TotalActuals,
        VSODActualInPercentage: selectedRowData.planData.VSODActualInPercentage,
        VSODDiffFcstAct: selectedRowData.planData.VSODDiffFcstAct,
        VSODForecastInPercentage: selectedRowData.planData.VSODForecastInPercentage,
        editable: true,
      },
      editable: true,
      _id: 'newplan_' + generateGUID(),
    };
    props.modifyTPRs(dataToAdd);
    oldData.splice(rowSelected, 1, selectedRowData, dataToAdd);
    setFieldData([...oldData]);
    setRowSelected(rowSelected + 1);
    setSaveButton(true);
    saveBtnDisabled && disableSaveBtn(false);
  };

  const saveButtonHandler = () => {
    let _modifiedRecords = Object.values(props.modifiedRecords)
    let newFieldData = JSON.parse(JSON.stringify(_modifiedRecords));
    let isFound = newFieldData.find((item, index) => {
      if (item.brandform.length === 0) {
        setAlertMessage({
          title: 'Trade Price Reduction - Mandatory Field',
          p1: 'Brandform field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.month.length === 0) {
        setAlertMessage({
          title: 'Trade Price Reduction - Mandatory Field',
          p1: 'Month is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.channel.length === 0) {
        setAlertMessage({
          title: 'Trade Price Reduction - Mandatory Field',
          p1: 'Channel field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.cluster.length === 0) {
        setAlertMessage({
          title: 'Trade Price Reduction - Mandatory Field',
          p1: 'Cluster field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.planName.length === 0) {
        setAlertMessage({
          title: 'Trade Price Reduction - Mandatory Field',
          p1: 'Plan Name field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.month.length === 0) {
        setAlertMessage({
          title: 'Month - Mandatory Field',
          p1: 'Month Name field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
    });
    if (isFound) {
      return;
    }
    prepareDataForSaving();
  };

  const prepareDataForSaving = () => {
    let _modifiedRecords = Object.values(props.modifiedRecords)
    let dataToSend = JSON.parse(JSON.stringify(_modifiedRecords));
    // update ids of new plans so we can find and remove them when new data comes,
    let updatedFieldData = fieldData.map(i => {
      if (i._id.startsWith('newplan_')) {
        return { ...i, _id: 'Save' + i._id };
      }
      return i;
    });
    setFieldData([...updatedFieldData]);
    dataToSend.forEach(element => {
      if (element.editable) {
        delete element.editable;
      }
      element.dataModelId = props?.page?.pageLevelDataModel;
      if (element._id.startsWith('newplan_')) {
        delete element._id;
      }
      if (element._id) {
        element.sptpr_id = element._id;
        delete element._id;
      }
    });
    addSpTprRow({ row: dataToSend, appid: match.params.appid });
    disableSaveBtn(true)
  };

  const fetchData = (brandForm, channel, cluster, month, qtr, currentPage, rowsPerPage, brandObj = '', planName) => {
    let objToSend = {
      appId: match.params.appid, rowsPerPage, page: currentPage,
      brand: !brandObj ? urlPayload.brand ? urlPayload.brand : brand.BrandName : brand.BrandName,
    };
    if (month?.length !== 12) {
      objToSend['month'] = month
    }
    if (qtr?.length !== 4) {
      objToSend['quarter'] = qtr
    }
    if (sptprData.filterData?.brands.length !== brandForm?.length) { /// brands has brandforms
      objToSend['brandform'] = brandForm
    }
    if (sptprData.filterData?.plans.length !== planName?.length) {
      objToSend['planName'] = planName
    }
    if (sptprData.filterData?.clusters?.length !== cluster?.length) {
      objToSend['cluster'] = cluster
    }
    if (sptprData.filterData?.channels?.filter(item => item.channel !== 'All Channels')?.length !== channel?.length) {
      objToSend['channel'] = channel
    }
    if (Object.keys(urlPayload).length === 0) {
      let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
      objToSend.currentFF = m.length === 1 ? '0' + m : m;
      objToSend.fiscalYear = brand.FinancialYear;
      objToSend.scenario = props?.sptprData?.selectScenario;
    } else {
      let m = urlPayload.currentFF;
      objToSend.currentFF = m.length === 1 ? '0' + m : m;
      objToSend.fiscalYear = urlPayload.year;
      objToSend.scenario = props?.sptprData?.selectScenario;
    }

    getSpTprData(objToSend);
    let payload = {
      brand: brand.BrandName,
      year: `${brand.FinancialYear}`,
      currentFF: brand ? `${getCurrentFFForPayload(getMonth(brand.CurrentFF, 'monthNumber'))}` : '',
      scenario: props?.sptprData?.selectScenario
  }
    getTsData({ appId: match.params.appid, payload });
  };

  useEffect(() => {
    let talkMetricsArr = [
      {
        label: 'TPRs ($M)',
        Metrics: 'TPRs',
        UoM: '$M'
      },
      {
        label: 'HFS Brand Case Rate',
        Metrics: 'All HFS',
        UoM: 'TPR %RLP'
      },
      {
        label: 'HFS Case Rate',
        Metrics: 'All HFS',
        UoM: 'Total %RLP'
      }
    ];
    if (tsData?.data.result?.data) {
      let talkData = tsData?.data.result?.data.filter((el) => talkMetricsArr.some((item) => item.Metrics === el.Metrics && item.UoM === el.UoM));
      let data = talkData.map(({ Metrics, UoM, BOP_FY }) => ({ Metrics, UoM, BOP_FY, talkSheet : talkMetricsArr.find((tEl) => tEl.UoM === UoM) }));
      setTalkSheetFilterData(data);
    }

  }, [tsData]);

  const fetchPlanFilter = () => {
    let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
    m = m.length === 1 ? '0' + m : m;
    getPlanFilterDataTPR({
      currentFF: m,
      fiscalYear: brand ? `${brand.FinancialYear}` : '',
      url: 'sptpr',
      brand: brand.BrandName,
      appid: match.params.appid,
    });
  }

  useEffect(() => {
    fetchData(brandForm, channel, cluster, month, qtr, currentPage, rowsPerPage, brand, plan);
    setRowSelected('');
    setFieldEdited({});
  }, [brandForm, channel, cluster, month, qtr, currentPage, rowsPerPage, brand, plan, scenario]);

  const downloadButtonHandler = () => {
    if (brand) {
      let uri = API_BASE_URL + `/sptpr/download?brand=${encodeURIComponent(brand.BrandName)}`;
      if (brandForm?.length && sptprData.filterData?.brands.length !== brandForm?.length) {/// brands has brandforms
        uri = `${uri}${`&brandform=${encodeURIComponent(JSON.stringify(brandForm))}`}`;
      }
      if (channel?.length && sptprData.filterData?.channels?.filter(item => item.channel !== 'All Channels').length !== channel?.length) {
        uri = `${uri}&channel=${encodeURIComponent(JSON.stringify(channel))}`;
      }
      if (cluster?.length && sptprData.filterData?.clusters?.length !== cluster?.length) {
        uri = `${uri}&cluster=${encodeURIComponent(JSON.stringify(cluster))}`;
      }
      if (plan?.length && plan?.length !== sptprData.filterData?.plans.length) {
        uri = `${uri}${`&planName=${encodeURIComponent(JSON.stringify(plan))}`}`;
      }
      if (month?.length && month?.length !== 12) {
        uri = `${uri}&month=${encodeURIComponent(JSON.stringify(month))}`;
      }
      if (qtr?.length && qtr?.length !== 4) {
        uri = `${uri}&quarter=${encodeURIComponent(JSON.stringify(qtr))}`;
      }
      if (getMonth(brand.CurrentFF, 'monthNumber')) {
        let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
        m = m.length === 1 ? '0' + m : m;
        uri = `${uri}&currentFF=${encodeURIComponent(m)}`;
      }
      if (brand.FinancialYear) {
        uri = `${uri}&fiscalYear=${encodeURIComponent(brand.FinancialYear)}`;
      }
      if (scenario) {
        uri = `${uri}&scenario=${encodeURIComponent(scenario)}`;
      }
      let fyFF = getFiscalYearFormat(brand.FinancialYear) + '-' + moment().month(brand.CurrentFF).format('MMM');

      downloadFile(uri, match.params.appid, getFileNameWithTimeStamp('Trade Price Reduction' + '-' + brand.BrandName + '-' + fyFF), setDownloadLoading)
    }
  };

  const confirmHandler = () => {
    setConfirm({
      ...confirmState, isOpen: true,
      confirmMessage: `${props.page.name} data update done for  ${getMonth(selectBrand.CurrentFF, 'month')} ${getFiscalYearFormat(selectBrand.FinancialYear)}?`,
    });
  };
  const onConfirmResponse = (resp) => {
    if (resp) {
      finish();
    }
    setConfirm({ isOpen: false, confirmMessage: '' });
  };

  const finish = () => {
    let { BrandName, FinancialYear, CurrentFF } = props.brand;
    let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
    m = m.length === 1 ? '0' + m : m;
    let data = {
      'formType': 'tpr',
      brand: BrandName, fiscalYear: FinancialYear, currentFF: m,
      'action': 'finish',
    };
    let url = `${API_BASE_URL}/setupdata/email/send`;
    axios({
      url: url,
      method: 'POST',
      data: data,
      headers: {
        appId: props.match.params.appid,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        toast.success(response.data?.message || 'Workflow emails sent successfully');
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.message || 'Not able to send workflow emails');
      });
  };

  const renderCell = (row, index, key, type = 'number', rowNames) => {
    let value = row[key];
    return (
      <input
        step={type === 'number' ? "any" : undefined}
        style={{ maxWidth: `${value.length}ch`, minWidth: '30ch' }}
        name={key}
        type={type}
        className={classes.textInput}
        onBlur={() => onFieldBlur(row, key)}
        onChange={(e) => {
          const { name, value } = e.currentTarget;
          handleFieldChange({ name, value }, index, rowNames);
        }}
        value={type === 'number' ? Number(value) : value}
      />
    );
  };

  const selectOptions = (type) => {
    if (type === 'brandform') {
      return React.Children.toArray(
        sptprData.filterData?.brands.map((item, index) =>
          <MenuItem value={item.BrandFormName} key={index}>{item.BrandFormName}</MenuItem>,
        ));
    } else if (type === 'channel') {
      return React.Children.toArray(
        sptprData.filterData?.channels.map((item, index) =>
          <MenuItem value={item.channel} key={index}>{item.channel}</MenuItem>,
        ));
    } else if (type === 'cluster') {
      return React.Children.toArray(
        sptprData.filterData?.clusters.map((item, index) =>
          <MenuItem value={item.ClusterName} key={index}>{item.ClusterName}</MenuItem>,
        ));
    } else if (type === 'month') {
      return React.Children.toArray(
        monthsShortForm.map((item) =>
          <MenuItem value={item}>{capitalize(item)}</MenuItem>,
        ));
    }
  };
  let headerOrder = 0;

  const renderSelectCell = (row, index, key, type, rowNames) => {
    const { editable } = row;
    if (editable) {
      return (
        <Select
          name={key}
          value={row[key]}
          displayEmpty={true}
          className="custom-select m-right-10"
          style={{ minWidth: '100px' }}
          onChange={(e) => {
            const { name, value } = e.target;
            handleFieldChange({ name, value }, index, rowNames);
          }}
        >
          {
            rowNames && <MenuItem value="">{rowNames}</MenuItem>
          }
          {selectOptions(key)}
        </Select>
      );
    } else {
      return (
        <span className={key === "month" ? classes.capitalize : ''}>{row[key]}</span>
      );
    }
  };

  const renderEditableCell = (row, index, key, rowNames, showPercent = true, id) => {
    if (!readOnly) {
      const getValue = (isActive) => (showPercent ? row[key] === '' ? '' :
        (Math.round((Number(row[key]) * 100 + Number.EPSILON) * 100) / 100) : row[key] === '' ? '' :
        isActive ? Number(row[key]) : convertToNumber(Number(row[key]), 2))
      return (
        <p>
          <input
            id={"tprInput" + key + index}
            name={key}
            type={'number'}
            step={"any"}
            className={classes.tableInput}
            onChange={(e) => {
              const { name, value } = e.currentTarget;
              handleFieldChange({
                name,
                value: value === '' ? '' : (showPercent ? Number(value) / 100 : Number(value)),
              }, index, rowNames, id);
            }}
            onBlur={(e) => {
              const { name, value } = e.currentTarget;
              handleFieldChange({
                name,
                value: value === '' ? 0 : (showPercent ? Number(value) / 100 : Number(value)),
              }, index, rowNames, id);
            }}
            value={getValue(document.getElementById("tprInput" + key + index) === document.activeElement)}
          />
        </p>
      );
    } else {
      return (
        <p>{showPercent ? Math.round((Number(row[key]) * 100 + Number.EPSILON) * 100) / 100 : Number(row[key])}</p>
      );
    }
  };

  const selectRow = (e, index) => {
    if (rowSelected === index || readOnly) {
      setRowSelected('');
    } else {
      setRowSelected(index);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // getSpTprData({ appId: match.params.appid, rowsPerPage, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
    // getSpTprData({ appId: match.params.appid, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  const onCheckHandler = (row, index) => {
    let newCheckedTpr = JSON.parse(JSON.stringify(checkedTprState));
    if (!newCheckedTpr.includes(row)) {
      newCheckedTpr.push(row);
    } else {
      newCheckedTpr.splice(newCheckedTpr.indexOf(row), 1);
    }
    setCheckedTpr(newCheckedTpr);
  };

  const onDeleteCheckedTpr = () => {
    setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: `Are you sure you want to delete the ${(checkedTprState.length ? 'selected' : 'all')} row(s)?`, });
  };

  const onDeleteResponse = (isDelete) => {
    if (isDelete) {
      let index = checkedTprState.findIndex(i => i.startsWith('newplan'));
      let { BrandName, FinancialYear, CurrentFF } = props.brand;
      let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
      m = m.length === 1 ? '0' + m : m;
      let commonObj = {
        currentFF: m,
        brand: BrandName,
        fiscalYear: FinancialYear,
      }
      if(scenario) commonObj['scenario'] = scenario;
      if (index !== -1) {
        let newPlanIds = checkedTprState.filter(i => i.startsWith('newplan'));
        let _fieldData = fieldData.filter(i => !newPlanIds.includes(i._id));
        props.removeModifyTPRs(newPlanIds)
        setFieldData(_fieldData);
        if (checkedTprState.length > newPlanIds.length) {
          let restIds = checkedTprState.filter(i => !newPlanIds.includes(i));
          deleteCheckedTpr({ sptpr_ids: restIds, appid: match.params.appid, commonObj });
        }
      } else {
        deleteCheckedTpr({ sptpr_ids: checkedTprState, appid: match.params.appid, commonObj });
      }
    }
    setDeleteConfirm({ ...deleteConfirmState, isOpen: false });
  };

  const onAction = (actionType) => {
    switch (actionType) {
      case ActionType.Save:
        saveButtonHandler();
        break;
      case ActionType.NewPlan:
        createNewPlanPress();
        break;
      case ActionType.Duplicate:
        duplicateRow();
        break;
      case ActionType.Delete:
        onDeleteCheckedTpr();
        break;
      case ActionType.Download:
        downloadButtonHandler();
        break;
      case ActionType.DoneUpdates:
        confirmHandler();
        break;
      case ActionType.RemoveRecon:
      case ActionType.Upload:
        fetchData(brandForm, channel, cluster, month, qtr, currentPage, rowsPerPage, brand, plan)
        fetchPlanFilter()
        break;
      case ActionType.SaveAndRecon:
        saveButtonHandler();
        props.setSaveReconFlag(true);
        break;
    }
  };

  const onFilterChange = (filterType, { value }) => {
    switch (filterType) {
      case FilterType.Month:
        setDate(value);
        setMonth(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        setQtr([...new Set(value.map(m => getQuarterBasedOnMonth(m)))]);
        break;
      case FilterType.Qtr:
        setQtr(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
      case FilterType.Channel:
        setChannel(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
      case FilterType.Cluster:
        setCluster(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
      case FilterType.Brandform:
        setBrandForm(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
      case FilterType.Plan:
        setPlan(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
    }
  };

  const onSelectAll = (event, val) => {
    if (val) {
      let newCheckedItems = fieldData.map(i => `${i._id}`)
      setCheckedTpr(newCheckedItems);
    } else {
      setCheckedTpr([]);
    }
  }

  const getTotalValue = (key) => {
    if (sptprData.data?.result?.totalProperties && Number(sptprData.data?.result?.totalProperties[key])) {
      return sptprData.data?.result?.totalProperties[key]
    }
    return ''
  }

  let totalActual = (row) => Number(row.planData.IHRYTDActuals) + Number(row.planData.EstdAddtlSpend);

  const getCollapseIcon = (item) => {
    if (item.collapse) {
      return <IndeterminateCheckBoxIcon className={classes.icon} onClick={() => onCollapse(item, 'close')} />
    } else {
      return <AddBoxIcon className={classes.icon} onClick={() => onCollapse(item, 'open')} />
    }
  }

  const onCollapse = (item, str) => {
    let newHeaders2 = [...showColumns];
    newHeaders2 = newHeaders2.map((val) => {
      if (val.key == item.key) val.collapse = !item.collapse;
      return val;
    }
    )
    setShowColumns([...newHeaders2]);
  }

  return (
    <div className="spendplan-tpr">
      <PlanHeader
        {...props}
        onAction={onAction}
        onFilterChange={onFilterChange}
        filters={["months", "quarters", "brandForms", "channels", "clusters", "plans"]}
        filterValues={{ month, qtr, brandForm, channel, cluster, plan }}
        readOnly={readOnly}
        newPlanDisabled={brandForm?.length > 1 || channel?.length > 1 || cluster?.length > 1}
        saveButtonState={saveButtonState}
        rows={fieldData?.length}
        dataSource={sptprData}
        selectBrand={selectBrand}
        downloadLoading1={downloadLoading}
        rowSelected={rowSelected}
        fieldData={fieldData}
        saveBtnDisabled={saveBtnDisabled}
        talkSheetFilterData={talkSheetFilterData}
      />
      {(!sptprData.loading && !sptprData.loading1) ? (
        <React.Fragment>
          {/* { fieldData && fieldData.length > 0 ? ( */}
          <React.Fragment>
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table className={classNames(classes.table, 'tpr-table')} aria-label="simple table" stickyHeader>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {(!readOnly && fieldData.length) ?
                      <TableCell data-order={headerOrder++} className="sticky_column">
                        <Checkbox
                          onChange={(e, t) => onSelectAll(e, t)}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          checked={checkedTprState.length === fieldData.length}
                        />
                      </TableCell> : null}
                    <TableCell data-order={headerOrder++} className="sticky_column">
                      <span>Month</span>
                    </TableCell>
                    <TableCell data-order={headerOrder++} className="sticky_column"><span>Brandform</span></TableCell>
                    <TableCell data-order={headerOrder++} className="sticky_column"><span>Channel</span></TableCell>
                    <TableCell data-order={headerOrder++} className="sticky_column"><span>Cluster</span></TableCell>
                    <TableCell data-order={headerOrder++} className={classNames("sticky_column", classes.greenFont)} width="50"><span>Plan</span></TableCell>
                    {/* <TableCell colSpan="2">
                      <section className={classes.combinedHeaderTotalSection} />
                      <p className={classes.combinedHeaderHeadingGrey}>{'Last Year'}</p>
                      <section className={classes.combinedHeaderSection}>
                        <p><span><br />{'Spend %'}</span></p>
                        <p><span><br />{'VSOD %'}</span></p>
                      </section>
                    </TableCell> */}
                    {/* <TableCell colSpan="2">
                      <section className={classes.combinedHeaderTotalSection} />
                      <p className={classes.combinedHeaderHeadingGrey}>{'Last Cycle'}</p>
                      <section className={classes.combinedHeaderSection}>
                        <p><span><br />{'Spend %'}</span></p>
                        <p><span><br />{'VSOD %'}</span></p>
                      </section>
                    </TableCell> */}
                    {/* <TableCell><span>Volume</span></TableCell> */}
                    <TableCell className={classes.greenFont} colSpan={showColumns[0].collapse ? "4" : "1"}>
                    <section className={classes.combinedHeaderTotalSection}>
                      {showColumns[0].collapse && <><p><span>SKU<br /> Salience%</span></p>
                        <p><span>Spend%</span></p>
                        <p><span>VSOD%</span></p></>}
                        {showColumns[0].collapse && <><p className={classes.totalValue}></p>
                          <p className={classes.totalValue}></p>
                          <p className={classes.totalValue}></p></>}
                        {/* <p className={classes.totalValue} title={getTotalValue('BudgetInMillions')}>{convertToNumber(getTotalValue('BudgetInMillions'), 2).toLocaleString()}</p> */}
                        <p className={classes.totalValue} title={getTotalValue('BudgetInMillions')}>{Math.round(getTotalValue('BudgetInMillions'))}</p>
                      </section>
                      <p className={`${classes.combinedHeaderHeading} ${classes.flexAlign}`}>{getCollapseIcon(showColumns[0])} BOP</p>
                      <section className={classes.combinedHeaderSection}>
                        {showColumns[0].collapse && <><p><span>SKU<br /> Salience%</span></p>
                          <p><span>Spend%</span></p>
                          <p><span>VSOD%</span></p>
                        </>}
                        <p><span className={classes.blackFont}>Budget<br />$M</span></p>
                      </section>
                    </TableCell>
                    <TableCell colSpan={showColumns[1].collapse ? "6" : "1"}>
                      <section className={classes.combinedHeaderTotalSection}>
                        {/* {showColumns[1].collapse && <><p className={classes.totalValue} title={getTotalValue('IHRYTDActuals')}>{convertToNumber(getTotalValue('IHRYTDActuals'), 2).toLocaleString()}</p> */}
                        {showColumns[1].collapse && <><p className={classes.totalValue} title={getTotalValue('IHRYTDActuals')}>{Math.round(getTotalValue('IHRYTDActuals'))}</p>
                          {/* <p className={classes.totalValue} title={getTotalValue('EstdAddtlSpend')}>{convertToNumber(getTotalValue('EstdAddtlSpend'), 2).toLocaleString()}</p></>} */}
                          <p className={classes.totalValue} title={getTotalValue('EstdAddtlSpend')}>{Math.round(getTotalValue('EstdAddtlSpend'))}</p></>}
                        {/* <p className={classes.totalValue} title={getTotalValue('TotalActuals')}>{convertToNumber(getTotalValue('TotalActuals'), 2).toLocaleString()}</p> */}
                        <p className={classes.totalValue} title={getTotalValue('TotalActuals')}>{Math.round(getTotalValue('TotalActuals'))}</p>
                        {/* {showColumns[1].collapse && <><p className={classes.totalValue} title={getTotalValue('LBEBOP')}>{convertToNumber(getTotalValue('LBEBOP'), 2).toLocaleString()}</p> */}
                        {showColumns[1].collapse && <><p className={classes.totalValue} title={getTotalValue('LBEBOP')}>{Math.round(getTotalValue('LBEBOP'))}</p>
                          <p className={classes.totalValue}></p>
                          <p className={classes.totalValue}></p></>}
                      </section>
                      <p className={`${classes.combinedHeaderHeading} ${classes.flexAlign}`}>{getCollapseIcon(showColumns[1])} Actuals</p>
                      <section className={classes.combinedHeaderSection}>
                        {showColumns[1].collapse && <><p className={classes.greenFont}><span>IHR YTD</span></p>
                          <p className={classes.greenFont}><span>Est Add.<br />Spend</span></p></>}
                        <p><span>Total<br />Actuals</span></p>
                        {showColumns[1].collapse && <><p><span>LBE<br />BOP</span></p>
                          <p><span>VSOD%<br />Forecast</span></p>
                          <p><span>VSOD%<br />Actual</span></p></>}
                      </section>
                    </TableCell>
                    <TableCell className={`${classes.flexAlign} ${classes.marginTop}`}>{getCollapseIcon(showColumns[2])}{showColumns[2].collapse && <><span>VSOD Diff%<br />(Fcst-Act)</span></>}</TableCell>
                    <TableCell>
                    {showColumns[3].collapse && <div>
                        <p className={classes.totalValue} title={getTotalValue('ActualRetailingCrores')}>{convertToNumber(getTotalValue('ActualRetailingCrores'), 2).toLocaleString()}</p>
                      </div>}
                      <section style={{ marginTop: 20 }} className={`${classes.combinedHeaderSection} ${classes.flexAlign}`}>
                      {getCollapseIcon(showColumns[3])}{showColumns[3].collapse && <><span>Actual<br />Retailing<br />(crores)</span></>}
                      </section>
                    </TableCell>
                    <TableCell>
                    {showColumns[4].collapse && <div>
                        <p className={classes.totalValue} title={getTotalValue('BOPRetailingCrores')}>{convertToNumber(getTotalValue('BOPRetailingCrores'), 2).toLocaleString()}</p>
                      </div>}
                      <section style={{ marginTop: 20 }} className={`${classes.combinedHeaderSection} ${classes.flexAlign}`}>
                      {getCollapseIcon(showColumns[4])}{showColumns[4].collapse && <span>Fcst(BOP)<br />Retailing<br />(crores)</span>}
                      {showColumns[3].collapse && <div>
                        {/* <p className={classes.totalValue} title={getTotalValue('ActualRetailingCrores')}>{convertToNumber(getTotalValue('ActualRetailingCrores'), 2).toLocaleString()}</p> */}
                        <p className={classes.totalValue} title={getTotalValue('ActualRetailingCrores')}>{Math.round(getTotalValue('ActualRetailingCrores'))}</p>
                      </div>}
                      </section>
                      <section style={{ marginTop: 20 }} className={`${classes.combinedHeaderSection} ${classes.flexAlign}`}>
                        {getCollapseIcon(showColumns[3])}{showColumns[3].collapse && <><span>Actual<br />Retailing<br />(crores)</span></>}
                      </section>
                    </TableCell>
                    <TableCell>
                      {showColumns[4].collapse && <div>
                        {/* <p className={classes.totalValue} title={getTotalValue('BOPRetailingCrores')}>{convertToNumber(getTotalValue('BOPRetailingCrores'), 2).toLocaleString()}</p> */}
                        <p className={classes.totalValue} title={getTotalValue('BOPRetailingCrores')}>{Math.round(getTotalValue('BOPRetailingCrores'))}</p>
                      </div>}
                      <section style={{ marginTop: 20 }} className={`${classes.combinedHeaderSection} ${classes.flexAlign}`}>
                        {getCollapseIcon(showColumns[4])}{showColumns[4].collapse && <span>Fcst(BOP)<br />Retailing<br />(crores)</span>}
                      </section>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {fieldData && fieldData.length > 0 ? fieldData.map((row, index) => {
                    let order = 0;
                    return (
                      <StyledTableRow key={index} onClick={(e) => selectRow(e, index)}
                        className={rowSelected === index ? classes.rowSelectedBg : ''}>
                        {!readOnly ? (
                          <TableCell
                            className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                            id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                            {
                              <Checkbox
                                onChange={() => onCheckHandler(row._id, index)}
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={checkedTprState.includes(row._id)}
                              />
                            }
                          </TableCell>
                        ) : null}
                        <TableCell
                          className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                          id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                          {renderSelectCell(row, index, 'month', 'text', null)}
                        </TableCell>
                        <TableCell
                          className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                          id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                          {renderSelectCell(row, index, 'brandform', 'text', 'All Brandforms')}
                        </TableCell>
                        <TableCell
                          className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                          id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                          {renderSelectCell(row, index, 'channel', 'text', 'All Channels')}
                        </TableCell>
                        <TableCell
                          className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                          id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                          {renderSelectCell(row, index, 'cluster', 'text', 'All Clusters')}
                        </TableCell>
                        <TableCell
                          title={`${row?.planName || ''}`}
                          className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                          id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                          {renderCell(row, index, 'planName', 'text', '')}
                        </TableCell>
                        {/* <StyledTableCell>
                          <span>{convertToNumber(Number(row.planData.lastYearEstimates.spendInPercent || 0) * 100, 2).toLocaleString()}</span>
                        </StyledTableCell>
                        <StyledTableCell className="noBorder">
                          <span>{convertToNumber(Number(row.planData.lastYearEstimates.vsodInPercent || 0) * 100, 2).toLocaleString()}</span>
                        </StyledTableCell>
                        <StyledTableCell>
                          <span>{convertToNumber(Number(row.planData.lastCycleEstimates.spendInPercent || 0) * 100, 2).toLocaleString()}</span>
                        </StyledTableCell>
                        <StyledTableCell className="noBorder">
                          <span>{convertToNumber(Number(row.planData.lastCycleEstimates.vsodInPercent || 0) * 100, 2).toLocaleString()}</span>
                        </StyledTableCell>
                        <StyledTableCell>
                          <span>{convertToNumber(Number(row.vol || 0), 2).toLocaleString()}</span>
                        </StyledTableCell> */}
                        <StyledTableCell colSpan={showColumns[0]?.collapse ? '4' : '0'}>
                          <section className={classes.combinedBodySection}>
                            {showColumns[0].collapse && <>{renderEditableCell(row.planData.estimates, index, 'SKUSalience', ['planData', 'estimates'], true, row._id)}
                              {renderEditableCell(row.planData.estimates, index, 'SpendInPercent', ['planData', 'estimates'], true, row._id)}
                              {renderEditableCell(row.planData.estimates, index, 'VSODInPercent', ['planData', 'estimates'], true, row._id)}</>}
                            <p title={(convertToNumber(calculateBudget(row) || 0, 2) + "")}>{convertToNumber(calculateBudget(row) || 0, 0).toLocaleString()}</p>
                          </section>
                        </StyledTableCell>
                        <StyledTableCell colSpan={showColumns[1].collapse ? "6" : "1"}>
                          <section className={classes.combinedBodySection}>
                            {showColumns[1].collapse && <>{renderEditableCell(row.planData, index, 'IHRYTDActuals', ['planData'], false, row._id)}
                              {renderEditableCell(row.planData, index, 'EstdAddtlSpend', ['planData'], false, row._id)}</>}
                            <p title={convertToNumber(totalActual(row), 2) + ""}>{convertToNumber(totalActual(row), 0).toLocaleString()}</p>
                            {showColumns[1].collapse && <><p title={convertToNumber(calculateLBEBOP(row), 2) + ""}>{convertToNumber(calculateLBEBOP(row), 0).toLocaleString()}</p>
                              <p>{Math.round((row.planData.estimates['VSODInPercent'] * 100 + Number.EPSILON) * 100) / 100}</p>
                              <p>{convertToNumber(calculateVSODActual(row), 0).toLocaleString()}</p></>}
                          </section>
                        </StyledTableCell>
                        <StyledTableCell>
                          {showColumns[2].collapse && <span>{calculateVSODDiff(row)}</span>}
                        </StyledTableCell>
                        <StyledTableCell>
                          {showColumns[3].collapse && <span title={convertToNumber(row.planData.ActualRetailingCrores ? row.planData.ActualRetailingCrores : calculateBOPRetailingCrores(row), 2) + ""}>
                            {convertToNumber(row.planData.ActualRetailingCrores ? row.planData.ActualRetailingCrores : calculateBOPRetailingCrores(row), 1).toLocaleString()}</span>}
                        </StyledTableCell>
                        <StyledTableCell className="noBorder">
                          {showColumns[4].collapse && <span title={convertToNumber(row.planData.BOPRetailingCrores, 2) + ""}>{convertToNumber(row.planData.BOPRetailingCrores, 1).toLocaleString()}</span>}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  }) :
                    renderNoDataText(brandForm, channel, cluster, month, qtr)
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </React.Fragment>
        </React.Fragment>
      ) : (
        <PlanningLoading />
      )}

      <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} classes={classes} />
      <ConfirmDialog open={deleteConfirmState.isOpen} handleClose={onDeleteResponse}
        alertMessageText={deleteConfirmState.deleteMessage} classes={classes} confirm />
      <ConfirmDialog open={confirmState.isOpen} handleClose={onConfirmResponse}
        alertMessageText={confirmState.confirmMessage} classes={classes} confirm />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    sptprData: state.tprReducer,
    brand: state.tprReducer?.selectBrand,
    reconReviewData: state.ReconReviewReducer,
    modifiedRecords: state.tprReducer.modifiedRecords,
    scenario: state.tprReducer?.selectScenario,
    tsData: state.talkSheetReducer,
  };
};

const mapDispatchToProps = {
  getSpTprData,
  addSpTprRow,
  getBrandformfilter,
  getChannelfilter,
  getClusterfilter,
  getPlanFilterDataTPR,
  brandformFilterSelect,
  getSpTprExtraData,
  deleteCheckedTpr,
  setSaveReconFlag,
  addReconReviewBaseline,
  removeSuccessMessage,
  modifyTPRs,
  clearModifyTPRs,
  removeModifyTPRs,
  getTsData,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(TradePriceReduction)));