import { addStyle, getMonth } from "../../../../utility/utils"
import { ittMasterObjectKeys, lastFFMonths, months } from "./data"

export const save = (fieldData, type, account, appName) => {
    let data = []
    fieldData.forEach((d, i) => {
        let m = getMonth(account.CurrentFF, 'monthNumber')
        let obj = {
            "fiscalYear": account ? '' + account.FinancialYear : '',
            "account": account?.AccountName || "",
            "currentFF": account ? (m > 9 ? '' : '0') + m : '',
            "formType": appName === 'omnichannel' && type ? 'omniSkuMaster' : type,
            _id: d[0].ref && d[0].key ? (d[0].ref || d[0].key) : undefined
        }

        if (i !== 0) { // avoiding headers row.
            if (type.includes('fyActuals')) {
                d.map((o, j) => {
                    if (o.idKey) { // discarding empty column which has id " ".
                        let keys = o.idKey.split('.')
                        if (months.includes(keys[1])) { // picking columns of this currentFF only
                            if (!lastFFMonths(account).includes(keys[1])) {
                                if (!obj[keys[0]]) {
                                    obj[keys[0]] = {}
                                }
                                obj[keys[0]][keys[1]] = Number(o.value) || 0
                            }
                        } else
                            obj[o.id] = o.value
                    }
                })
                delete obj.pasted
                if (obj.value && obj.volume && obj.brand && obj.brandform && (obj.sku || obj.tsSku)) {
                    data.push(obj)
                }
            } else {
                d.map((o, j) => {
                    if (o.idKey) { // discarding empty column which has id " ".
                        let keys = o.idKey.split('.')
                        if (months.includes(keys[1]) || (type === 'ittMaster' && keys.length > 1)) { // picking columns of this currentFF only
                            if (!obj[keys[0]]) {
                                obj[keys[0]] = {}
                            }
                            obj[keys[0]][keys[1]] = Number(o.value) || 0
                        } else
                            obj[o.id] = o.value
                    }
                })
                delete obj.pasted;
                if (type === 'skuMaster' && obj.mrp && obj.gstrate && obj.rlp && obj.frontmargin && (obj.brand && obj.brand !== '-') && (obj.brandform && obj.brandform !== '-') && (obj.tsSku || obj.sku) && (appName === 'omnichannel' || obj.msufactor)) {
                    months.forEach(m => {
                        obj.gstrate[m] = obj.gstrate[m] / 100
                        obj.frontmargin[m] = obj.frontmargin[m] / 100
                    })
                    data.push(obj)
                }
                if (type === 'ittMaster' && (obj.brand && obj.brand !== '-') && (obj.brandform && obj.brandform !== '-') && obj.ctlsib && obj.kbdone && obj.kbdtwo && (appName === 'omnichannel' || (obj.rentals && obj.sib))) {
                    ittMasterObjectKeys.forEach(o => {
                        for (i in obj[o.key]) {
                            obj[o.key][i] = obj[o.key][i] / 100;
                        }
                    })
                    data.push(obj)
                }
                if (type === 'skuMapping' && (obj.brand && obj.brand !== '-') && (obj.brandform && obj.brandform !== '-') && (obj.sku && obj.sku !== '-')) {
                    data.push(obj)
                }
                if (type === 'fyActualspff' && obj.brand && obj.brandform && obj.metric) {
                    data.push(obj)
                }
                if (type === 'pyskulevel' && obj.brand && obj.brandform && obj.sku) {
                    delete obj.currentFF;
                    obj.fiscalYear = (Number(obj.fiscalYear) - 1) + "";
                    data.push(obj)
                }
                if (type === 'pidMapping' && obj.subBrandForm && obj.productId) {
                    data.push(obj);
                }
                if (type === 'skulevelofftake' && (obj.brand && obj.brand !== '-') && (obj.brandform && obj.brandform !== '-') && (obj.tsSku && obj.tsSku !== '-') && (obj.tsBrand && obj.tsBrand !== '-') && (obj.subBrandForm && obj.subBrandForm !== '-')) {
                    data.push(obj)
                }
                if (type === 'omniRetailingOffTakePlanning') {
                    data.push(obj)
                }
            }
        }

    })
    return data?.length ? data : null;
}

export const setSticky = () => {
    try {
        const fixedColumns = document.querySelectorAll('.sticky-column');
        if (fixedColumns?.length > 0) {
            let bodyWidth = 0, bodyNextWidth = 0;
            let k = 0;
            fixedColumns.forEach((column, index) => {
                let item = Object.values(column.classList)
                    .find(i => (i.startsWith('tab-') || i.startsWith('tabtotal-')));
                // rows with different color(salience case when spacial channel name)
                // let isHighlightedCell =  Object.values(column.classList).find(i=> i.startsWith('highlighted'));
                if (item) {
                    let [i, j] = item.slice(item.includes('tabtotal') ? 9 : 4).split('-');
                    if (i > k) {
                        bodyWidth = 0;
                        bodyNextWidth = 0;
                    }
                    k = i;
                    let currentElement;
                    if (item.includes('tabtotal')) {
                        currentElement  = document.getElementsByClassName(`tabtotal-${i}-${j} sticky-column`);
                    } else {
                        currentElement  = document.getElementsByClassName(`tab-${i}-${j} sticky-column`);
                    }
                    if (currentElement && currentElement.length) {
                        if (j === '0') {
                            bodyWidth = -1; // adding + 1 in the end for every element no need to add for this
                            bodyNextWidth = currentElement[0].offsetWidth;
                        } else {
                            bodyWidth = bodyNextWidth - Number(j);
                            bodyNextWidth += currentElement[0].offsetWidth;
                        }
                        currentElement[0].style.position = 'sticky';
                        currentElement[0].style.zIndex = i === '0' ? 4 : 3;
                        currentElement[0].style.left = `${bodyWidth + 1}px`;
                        // style.background not working directly or with setAttribute method !important that's why using below function.
    
                        if (i !== "0") {
                            if (Number(i) % 2) {
                                addStyle(currentElement[0], 'background-color', "#F5F5F5", true)
                            } else {
                                addStyle(currentElement[0], 'background-color', "#fff", true)
                            }
                        }
                    }
                }
            });
        }
    } catch (e) {
        console.log(e);
    }
};
