/**
 * @module Actions/Application
 * @desc Application Actions
 */
import { ActionTypes } from 'constants/index';
export const loadAppconfig = (appId, orgId) => dispatch => {
	dispatch({ type: ActionTypes.LOAD_APP_CONFIG_LOADING, payload: { appId, orgId } });
};

export const loadApps = (orgId) => dispatch => {
	dispatch({ type: ActionTypes.LOAD_APPS_LOADING, orgId });
};

export const getBrand = (appId) => dispatch => {
	dispatch({ type: ActionTypes.GET_BRAND_LOADING, appId });
};

export const getRoles = (appId) => dispatch => {
	dispatch({ type: ActionTypes.LOAD_ROLES_LOADING, appId });
};

export const saveApp = (data) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_APP_LOADING, data });
};

export const saveDatasource = (data) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_DATASOURCE_LOADING, data });
};

export const getDataelements = (datasourceId) => dispatch => {
	dispatch({ type: ActionTypes.LOAD_DATAELEMENTS_LOADING, datasourceId });
};

export const saveDataModel = (data) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_DATAMODEL_LOADING, data });
};
export const getMember = (user, appId) => dispatch => {
	dispatch({ type: ActionTypes.LOAD_MEMBER_LOADING, user: { ...user, appId } });
};
export const getEntity = (entityId, appId) => dispatch => {
	dispatch({ type: ActionTypes.GET_ENTITY_LOADING, payload: { entityId, appId } });
};
export const getMemberById = (appId) => dispatch => {
	dispatch({ type: ActionTypes.GET_MEMBER_LOADING, appId });
};

export const saveSupportingDocument = (data) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_SUPPORTING_DOCUMENT_SUCCESS, payload: data });
};