import { capitalize } from '../../../../utility/utils';
import moment from 'moment';
export const shortMonthLowercase = ['jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'may', 'jun']
export const engineSpends = ['FY', ...shortMonthLowercase]
export const MetricsRow = ['KBD 1 Difference', 'KBD 2 Difference', 'CTLSIB Difference', '(KBD1 + KBD2) difference', 'Total Difference']

export const planningData = {
    promo: {
        columnKeys: [{ key: "brand", type: "select", defaultValue: '-- All Brands --' },
        { key: "brandform", type: "select", defaultValue: '-- All Brandforms --' },
        { key: "sku", type: "select" }, { key: "month", type: "select" },
        { key: "mrp", field: 'number' }, { key: "frontMargin", field: 'number', type: 'percent', }, { key: "rlp", field: 'number' }, { key: "lyPromoAmount", type: "input", field: 'number' },
        { key: "promoDepth", type: "input", field: 'number' },
        { key: "cmYaRetailing", field: 'number', showTotal: true }, { key: "p3mAvgRetailing", field: 'number', showTotal: true }, { 'key': 'retailingForecast', type: "input", field: 'number', showTotal: true },
        { key: "retailingActuals", field: 'number', showTotal: true },
        { key: "offtakeForecast", type: "input", field: 'number', showTotal: true }, { key: "offtakeActuals", type: "input", field: 'number', showTotal: true },
        { key: "retailingValue", field: 'number', formula: `({rlp}*{retailingForecast})`, type: 'customFormula', showTotal: true },
        { key: "hitRate", field: 'number', type: 'percent', },
        { key: "gstrate", field: 'number', type: 'percent', },
        { key: "promoReimbursement", field: 'number' },
        { key: "promoOffTake", field: 'number' },], //customFormula forecast month value will be calculated using formula rest will come using key
        freezeColumnUpto: 4,
    },
    nonPromo: {
        columnKeys: [{ key: "brand", type: "select", defaultValue: '-- All Brands --' },
        { key: "brandform", type: "select", defaultValue: '-- All Brandforms --' }, { key: "sku", type: "select" },
        // ...shortMonthLowercase.map(m => {
        //     return { key: m, subKeys: ['lastYearTotalNonPromoRetailing.' + m, 'lastYearVolumePlanning.' + m,], field: 'number', lastYear: true }
        // }),
        ...shortMonthLowercase.map(m => {
            return {
                key: m, subKeys: [ 'totalNonPromoRetailing.' + m, 'volumePlanning.' + m,
                    //  'retailingIya.' + m
                ], field: 'number'
            }
        })
        ],
        freezeColumnUpto: 3,
    },
    spendsPlanning: {
        columnKeys: [{ key: "brand", type: "select", defaultValue: '-- All Brands --' }, { key: "type", type: "select", defaultValue: '-- All Types --' },
        { key: "brandform", type: "select", defaultValue: '-- All Brandforms --' },  // newInput means only in case of new plan it will be editable
        { key: "uom" },// { key: "fyActuals", type: "input", field: 'number' },
        {
            key: "months",
            subKeys: shortMonthLowercase.map(m => {
                return { key: m, type: "input", field: 'number' }
            })
        },
        {
            key: 'total',
            field: 'number'
        }
        ],
        freezeColumnUpto: 4,
    },
    earningsTFI: {
        columnKeys: [{ key: "format" }, { key: "kbdType", type: "select", defaultValue: '-- All KBD Types --' },
        { key: "brand", type: "select", defaultValue: '-- All Brands --' }, { key: "brandform", type: "select", defaultValue: '-- All Brandforms --' },
        { key: "sku", type: "select", defaultValue: '-- All SKUs --' },
        { key: "earningOn", type: "select", defaultValue: '-- All Earnings On --' },
        { key: "startDate", type: "date", defaultValue: '' },
        { key: "endDate", type: "date", defaultValue: '' },
        ...shortMonthLowercase.map(m => {
            return {
                key: m,
                subKeys: [
                    'tfiEarningsPerc.' + m,
                    'factor.' + m,
                    'retailing.' + m,
                    {
                        key: 'tfiEarningsINR.' + m, type: 'formula',
                        formula: `({tfiEarningsPerc.${m}}*{retailing.${m}}*{factor.${m}})/100`
                    },

                ], field: 'number'
            }
        })
        ],
        freezeColumnUpto: 4,
    },
    omniPromoCalender : {
        columnKeys: [{ key: "category", type: "select", defaultValue: '-- All Categories --' },
        { key: "brand", type: "select", defaultValue: '-- All Brands --' },
        { key: "tsBrand", type: "select", defaultValue: '-- All TS Brands --' },
        { key: "skuType", type: "select", defaultValue: '-- All SKU Types --' },
        { key: "brandform", type: "select", defaultValue: '-- All Brandforms --' },
        { key: "tsSku", type: "select" }, { key: "type", type: "select" }, { key: "month", type: "select" },
        { key: "mrp", field: 'number' }, { key: "frontmargin", type: 'percent' }, { key: "gstrate", type: 'percent' }, { key: "rlp", field: 'number' },
        // { key: 'depthMrp', type: "input", field: 'number'}, { key: 'depthRlp', field: 'number', type: 'percent'},
        { key: 'depthMrp', type: "input", field: 'number'}, { key: 'depthRlp', field: 'number', type: 'input'}, // first cry changes
        { key: 'retailQtyForecast', field: 'number', type: 'input', showTotal: true },
        { key: "retailingValue", field: 'number', formula: `({rlp}*{retailingForecast})`, type: 'number' },
        { key: "offtakeForecastPieces", field: 'number', type: 'input', showTotal: true }, { key: "offtakeActualPieces", field: 'number', type: 'input' },
        { key: 'offtakeActualINR', field: 'number', type: 'number'},
        { key: 'sdSpend', field: 'number', type: 'number'},
        { key: 'sdSpendFinal', field: 'number', type: 'number'},
        {
            key: "For Actualized Month (From Claims Report)",
            subKeys: [
                { key: 'claimAmount', type: "number", field: 'number' },
                { key: 'asp', type: "number", field: 'number' },
                { key: 'minQty', type: "number", field: 'number' },
                { key: 'minPromo%', type: "number", field: 'number' },
                { key: 'promoValue', type: "number", field: 'number' }
            ]
        },
        { key: "hitRate", field: 'number', type: 'percent', },
        // { key: "promoReimbursement", field: 'number' },
        // { key: "offtakeValueRlp", field: 'number' },
    ], 
        //customFormula forecast month value will be calculated using formula rest will come using key
        freezeColumnUpto: 4,
    },
    omniSpendsPlanning: {
        columnKeys: [{ key: "brand", type: "select", defaultValue: '-- All Brands --' }, { key: "tsBrand", type: "select", defaultValue: '-- All TS Brands --' },
        { key: "metric", defaultValue: '-- All Metrics --' },  // newInput means only in case of new plan it will be editable
        { key: "uom" },// { key: "fyActuals", type: "input", field: 'number' },
        {
            key: "months",
            subKeys: shortMonthLowercase.map(m => {
                return { key: m, type: "input", field: 'number' }
            })
        },
        {
            key: 'total',
            field: 'number'
        }
        ],
        freezeColumnUpto: 4,
    },
    omniTiff: {
        columnKeys: [{ key: "brand", type: "select", defaultValue: '-- All Brands --' }, { key: "tsBrand", type: "select", defaultValue: '-- All TS Brands --' },
        { key: "brandform", type: "select", defaultValue: '-- All Brandforms --' }, // newInput means only in case of new plan it will be editable
        { key: "tsSku", type: "select", defaultValue: '-- All TS SKUs --' },
        { key: "earningOn", type: 'select', defaultValue: '-- All Earning On --'},
        { key: "earningType", type: 'select', defaultValue: '-- All Earning Type --'},
        { key: 'kbd', type: 'select', defaultValue: '-- All KBDs --'},
        { key: 'startDate' },
        { key: 'endDate' },
        ...shortMonthLowercase.map(m => {
            return {
                key: m,
                subKeys: [
                    {
                        key: 'tfiPer' , type: 'percent', field: 'number'
                    },
                    {
                        key: 'factor', type: 'number',
                    },
                    {
                        key: 'retailing', type: 'number'
                    },
                    {
                        key: 'tfiInr', type: 'number',
                    },

                ], field: 'number'
            }
        })
        ],
        freezeColumnUpto: 7,
    },
    mediaCalendar : {
        columnKeys: [
        { key: "category", type: "select", defaultValue: '-- All Categories --' },
        { key: "brand", type: "select", defaultValue: '-- All Brands --' },
        { key: "tsBrand", type: "select", defaultValue: '-- All TS Brands --' },
        { key: "brandform", type: "select", defaultValue: '-- All Brandforms --' },
        { key: "month", type: "select", defaultValue: '-- All Months --' },
        // { key: "type", type: "select", defaultValue: '-- All Types --' },
        // { key: "averageRLP", type: "number" }, { key: "month", field: 'select', defaultValue: '-- All Months --' },
        // { key: 'offtakeForecast(Pieces)', type: "number" },
        // { key: "paidUnits", field: 'number'},
        { key: "cost", type: "number", field: 'number', showTotal: true },
        // { key: "cvr", field: 'number', type: 'number', showTotal: true },
        // { key: "ctr", field: 'number', type: 'number', },
        // { key: "imp", field: 'number', type: 'number', },
        // { key: "cpc/cpm", field: 'number' },
        { key: "roi", field: 'number' },
        // { key: "actualOfftakeUnits", type: "number", field: 'number'},
        // { key: "actualCost", field: 'number', type: 'number' },
        // { key: "actualCVR", field: 'number', type: 'number', },
        // { key: "actualCTR", field: 'number', type: 'number', },
        // { key: "actualIMP", field: 'number' },
        // { key: "actualCPC/CPM", field: 'number' },
        // { key: "actualROI", field: 'number' },
        // { key: "actualOfftake", field: 'number' },
    ],
        freezeColumnUpto: 7,
    },
}

export const getFilterFields = {
    promo: {
        brand: ['brandform', 'sku'],
        brandform: ['sku'],
        sku: [],
    },
    nonPromo: {
        brand: ['brandform', 'sku'],
        brandform: ['sku'],
        sku: [],
    },
    spendsPlanning: {
        brand: ['type', 'brandform'],
        type: ['brandform'],
        brandform: [],
    },
    earningsTFI: {
        kbdType: ['brand', 'brandform', 'sku', 'period'],
        brand: ['brandform', 'sku', 'period'],
        brandform: ['sku', 'period'],
        sku: ['period'],
        earningOn: ['period'],
        period: [],
    },
    omniPromoCalender: {
        category: ['brand','tsBrand','brandform', 'tsSku', 'type', 'month'],
        brand: ['tsBrand','brandform', 'tsSku', 'type', 'month'],
        tsBrand: ['brandform', 'tsSku', 'type', 'month'],
        brandform: ['sku', 'type', 'month'],
        tsSku: ['type', 'month'],
        type: ['month'],
        month: [],
    },
    omniSpendsPlanning: {
        brand: ['brandform', 'tsBrand', 'metric'],
        brandform: ['tsBrand', 'metric'],
        tsBrand: ['metric'],
        metric: [],
    },
    mediaCalendar: {
        category: ['brand', 'tsBrand', 'brandform', 'month'],
        brand: ['tsBrand', 'brandform', 'month'],
        tsBrand: ['brandform', 'month'],
        brandform: ['month'],
        month: []
    },
    omniTiff: {
        brand: ['tsBrand','brandform', 'tsSku', 'earningOn', 'earningType', 'kbd'],
        tsBrand: ['brandform', 'tsSku', 'earningOn', 'earningType', 'kbd'],
        brandform: ['tsSku', 'earningOn', 'earningType', 'kbd'],
        tsSku: ['earningOn', 'earningType', 'kbd'],
        earningOn: ['earningType', 'kbd'],
        earningType: ['kbd'],
        kbd: []
    }

}

export const rowDropdownFields = {
    spendsPlanning: 'brand,brandform',
    earningsTFI: 'brand,brandform,sku'
}

export const headingsLabel = {
    'months': 'Months',
    // 'fyActuals': 'FY Actuals ',
    'uom': 'UOM',
    'brand': 'Brand',
    'brandform': 'Brandform',
    'type': 'Type',
    'sku': 'SKU',
    'month': 'Month',
    'retailingForecast': 'Retailing Qty Forecast (Pieces) (Nos)',
    'frontmargin': 'Front Margins (%)',
    'mrp': 'MRP (INR)',
    'rlp': 'RLP (INR)',
    'lyPromoAmount': 'LY Promo (Amt)',
    'volumePlanning': 'VOL',
    'lastYearVolumePlanning': 'VOL',
    'totalNonPromoRetailing': 'INR',
    'lastYearTotalNonPromoRetailing': 'INR',
    'offtakeActuals': 'Offtake Actuals (Pieces)',
    'offtakeForecast': 'Offtake Forecast (Pieces)',
    'cmYaRetailing': 'CM YA Retailing',
    'p3mAvgRetailing': 'P3M Avg Retailing (Pcs)',
    'promoDepth': 'Promo Depth (INR)',
    'base': 'Base',
    'imp': 'IMP',
    'total': 'TOTAL',
    'retailingActuals': 'Retailing Actuals (Pieces)',
    'retailingValue': 'Retailing Value (INR)',
    // 'retailingIya': 'IYA',
    'format': 'Format',
    'kbdType': 'KBD Type',
    'period': 'Period (Qtr)',
    'tfiEarningsPerc': 'TFI Earnings%',
    'retailing': 'Retailing (INR)',
    'tfiEarningsINR': 'TFI Earnings (INR)',
    'hitRate': 'Hit Rate (%)',
    'gstrate': 'GST Rate (%)',
    'promoReimbursement': 'Promo Reimbursement (INR)',
    'promoOffTake': 'Promo Offtake (Basis RLP) (INR)',
    'startDate': 'Start - Date (dd-mm-yyyy)',
    'endDate': 'End - Date (dd-mm-yyyy)',
    'factor': 'Factor (Nos)',
    'tsSku': 'TS SKU',
    'retailQtyForecast': 'Retailing Qty Forecast (Pieces)',
    'offtakeActual': 'Offtake Actuals (Pieces)',
    'offtake': 'Offtake',
    'offtakeValueRlp': 'Offtake Value (basis RLP) (INR)',
    'tsBrand': 'TS Brand',
    'metric': 'Metric',
    'earningOn': 'Earning On',
    'earningType': 'Earning Type',
    'kbd': 'KBD',
    'tfiInr': 'TFI Earnings (INR)',
    'tfiPer': 'TFI%',
    'averageRLP': 'Average RLP',
    'cpc/cpm': 'CPC/CPM',
    'roi': 'ROI (%)',
    'actualOfftakeUnits': 'Actual Offtake Units',
    'actualCost': 'Actual Cost',
    'actualCVR': 'Actual CVR',
    'actualCTR': 'Actual CTR',
    'actualIMP': 'Actual IMP',
    'actualCPC/CPM': 'Actual CPC/CPM',
    'actualROI': 'Actual ROI',
    'actualOfftake': 'Actual Offtake',
    'offtakeForecast(Pieces)': 'Offtake Forecast(Pieces) (Nos)',
    'paidUnits': 'Paid Units',
    'cost': 'Cost (INR)',
    'cvr': 'CVR',
    'ctr': 'CTR',
    'depthMrp': 'Promo Depth (%) on MRP',
    'depthRlp': 'Promo Depth (%) on RLP*',
    'gst': 'GST',
    'category': 'Category',
    'sdSpend': 'SD Spend (INR)',
    'claimAmount': 'Claim Amount (INR)',
    'asp': 'ASP (INR)',
    'minQty': 'Min Qty (Nos)',
    'minPromo%': 'Min Promo %',
    'offtakeActualPieces': 'Offtake Actuals(Pieces) (Nos)',
    'offtakeForecastPieces': 'Offtake Forecast(Pieces)',
    'offtakeActualINR': 'Offtake Actuals (Value) (INR)',
    'sdSpendFinal': 'SD Spend (Final- Actual/Forecast) (INR)',
    'skuType': 'SKU Type',
    'promoValue': 'Promo Value (INR)',

    jul: 'JUL', aug: 'AUG', sep: 'SEP', oct: 'OCT', nov: 'NOV', dec: 'DEC', jan: 'JAN', feb: 'FEB', mar: 'MAR', apr: 'APR', may: 'MAY', jun: 'JUN',
}

export const Msgs = {
    promo: 'Promo Planning',
    nonPromo: 'Non Promo Planning',
    spendsPlanning: 'Spends Planning',
    earningsTFI: 'Earnings Planning',
}

const getNewPlanMonthObj = () => {
    let obj = {};
    shortMonthLowercase.forEach(m => {
        obj[m] = 0;
    })
    return obj;
}


export const newPlanJson = {
    spendsPlanning: {
        editable: true,
        "brand": "",
        "brandform": "",
        "type": "",
        "uom": "",
        // "fyActuals": 0,
        "months": getNewPlanMonthObj(),
    },
    earningsTFI: {
        editable: true,
        "brand": "",
        "brandform": "",
        "sku": "",
        "format": "",
        "kbdType": "",
        // "period": "",
        "startDate": "",
        "endDate": "",
        "tfiEarningsPerc": getNewPlanMonthObj(),
        "tfiEarningsINR": getNewPlanMonthObj(),
        "retailing": getNewPlanMonthObj(),
        "factor": getNewPlanMonthObj(),
    },
}

export const KBDTypeValues = ['KBD 1', 'KBD 2',]
export const TypeValues = (format1) => ([
    "CTLSIB Spend",
    `${format1} Salience`,
    "KBD 2 Spend - Contracted Off Invoice",
    "KBD 2 Spend - Contracted On Invoice",
    "KBD 2 Spend - Contracted Visibility",
    "KBD 2 Spend - Discretionary Visibility",
    "SIB Spend"
])

export const getUoMByType = (format1, type) => {
    let TypeUoMMapping = {
        [`${format1} Salience`]: '%',
        "CTLSIB Spend": '% RLP',
        "KBD 2 Spend - Contracted Off Invoice": '% RLP',
        "KBD 2 Spend - Contracted On Invoice": '% RLP',
        "KBD 2 Spend - Contracted Visibility": 'INR',
        "KBD 2 Spend - Discretionary Visibility": 'INR',
        "SIB Spend": '% RLP',
    }
    return TypeUoMMapping[type]
}

export const getNonPromoHeading = (month, fiscalYear) => {
    let indexOfMonth = shortMonthLowercase.indexOf(month)
    let lastTwoDigit = `${fiscalYear}`.slice(2);
    if (indexOfMonth < 6) {
        lastTwoDigit -= 1
    }
    return capitalize(month) + " " + lastTwoDigit;
}

export const FIRST_CRY = 'FirstCry'