import { ActionTypes } from 'constants/index';

const initialState = {
    loading: false,
    error: '',
    controlData : []
};

export default function dataModelReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_ACCOUNTS: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ActionTypes.GET_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                controlData: action.payload,
                loading: false
            }
        }
        case ActionTypes.GET_ACCOUNTS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        default: {
            return state;
        }
    }
}