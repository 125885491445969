import {
  Checkbox,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import axios from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getMonth } from 'utility/utils';
import { generateGUID } from '../../../../../modules/page/page.module';
import {
  addNewMrAndEcommTFIs,
  deleteCheckedMrAndEcommTFIs,
  getBrandformfilter,
  getMrAndEcommTFIsData,
  getMrAndEcommTFIsExtraData, getPlanFilterDataTFIs, hideMessage,
  removeSuccessMessage,
  modifyTFIs,
  clearModifyTFIs,
  removeModifyTFIs,
  getClusterfilter,
} from '../../../../../store/actions';
import { getFileNameWithTimeStamp } from '../../../../../utility/utils';
import ConfirmDialog from '../../SpendPlan/ConfirmDialog';
import { getCurrentFFForPayload, getFiscalYearFormat } from '../../spendplanUtils';
import PlanHeader, { ActionType, FilterType } from '../PlanHeader';
import PlanningErrorModal from '../planningErrorModal';
import {
  commonStyle,
  convertToNumber,
  downloadFile, getQuarterBasedOnMonth,
  isReadOnly,
  months,
  PlanningLoading,
  renderNoDataText,
  setSticky,
  StyledTableCell,
  StyledTableRow
} from '../planningHelperFunctions';
import { getTsData } from '../../../../../store/actions';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700,
    paddingRight: 7,
  },
  tableHead: {
    '& th': {
      textAlignLast: 'center',
    },
    '& th:not(.adjustBorder)': {
      boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
    },
    '& th.adjustBorder': {
      paddingRight: '0',
      '& span': {
        paddingRight: '16px',
        boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
        display: 'flex',
        height: '40px',
        alignItems: 'center',
      },
    },
    '& th.lastYearHeader': {
      '& span': {
        height: '40px',
        display: 'flex',
        padding: '0 16px',
        textAlign: 'center',
        alignItems: 'center',
      },
    },
    '& span': {
      fontWeight: 'bold',
    },
  },
  tableBody: {
    '& td': {
      boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
      borderBottom: '0',
      cursor: 'pointer',
      textAlign: 'center',
      '& input': {
        textAlign: 'center',
      },
    },
    '& td.noBorder': {
      borderShadow: 'none',
    },
    '& p': {
      display: 'flex',
    },
  },
  tableInput: {
    border: '0',
    height: '38px',
    maxWidth: '75px',
    appearance: 'none',
    background: 'transparent',
    '&:focus': {
      background: '#fff',
    },
  },
  textInput: {
    border: '0',
    height: '38px',
    color: 'inherit',
    width: '90px',
    appearance: 'none',
    background: 'transparent',
  },
  headerMainCon: {
    height: '60px',
    display: 'flex',
    borderRadius: '10px',
    alignItems: 'center',
    marginBottom: '20px',
    backgroundColor: '#fff',
    maxWidth: '95vw',
    overflow: 'auto',
    justifyContent: 'space-between',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.8em',
      height: '0.8em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#cdcdcd',
      outline: '1px solid slategrey',
    },
  },
  leftHeaderCon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightHeaderCon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  rowSelectedBg: {
    background: '#CEE7FC !important',
    transition: 'background-color 0.5s ease',
  },
  disabledBtn: {
    opacity: '0.65',
    pointerEvents: 'none',
  },
  disabledBtnCursor: {
    cursor: 'not-allowed',
  },
  combinedHeaderSection: {
    justifyContent: 'space-around',
    height: 70,
    '@media(max-width:1366px)': {
      height: '65px',
    },
    display: 'flex',
    alignItems: 'center',
    '& p': {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '82px',
    },
    '& p:not(:last-of-type)': {
      width: '40%',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
    '& p:last-of-type': {
      width: '20%',
    },

  },
  combinedBodySection: {
    justifyContent: 'space-around',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      borderRight: '0',
      width: '82px',
      justifyContent: 'space-around',
    },
    '& p:not(:last-of-type)': {
      width: '40%',
      height: '38px',
      textAlign: 'center',
      paddingTop: '2px',
      paddingBottom: '2px',
      margin: '0px',
      '& input': {
        height: '100%',
      },
    },
    '& p:not(:first-of-type)': {
      textAlign: 'center',
    },
    '& p:last-of-type': {
      width: '20%',
    },
  },
  donwloadBtn: {
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    marginRight: 10,
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    '@media(min-width:1367px)': {
      paddingRight: '30px',
    },
    '& button': {
      maxHeight: '36px',
      '& span': {
        whiteSpace: 'nowrap',
      },
    },
  },
  updateBtnStyle: {
    backgroundColor: '#ff6503 !important',
  },
  removeReconBtn: {
    backgroundColor: 'red !important',
    color: 'white',
  },
  combinedHeaderTotalSection: {
    height: 20,
    display: 'flex',
    justifyContent:'flex-end',
    alignItems: 'center',
    '& p': {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '82px',
    },
    '& p:not(:last-of-type)': {
      height: 20,
      textAlign: 'center',
      paddingRight: '16px',
      margin: '0px',
    },
    '& p:not(:first-of-type)': {
      paddingLeft: '16px',
      textAlign: 'center',
    },
  }, ...commonStyle
}));

const editableFields = {
  ZMAX: true,
  MRP: true,
  unitsSU: true,
  returMargin: true,
  priorFYInvtyMSU: true,
  currentFYCoverMSU: true,
  actualsSpend: true,
  actualShipmentMSU: true,
};

const defaultChannels = ['HSM', 'Cash & Carry', 'Ecommerce'];
const defaultPlanType = ['KBD 1 TFI', 'KBD 2 TFI'];

const MrAndEcommTFIs = props => {
  const classes = useStyles();
  const {
    getMrAndEcommTFIsData, match, mrAndEcommTFIsData, addNewMrAndEcommTFIs,
    getMrAndEcommTFIsExtraData, page, deleteCheckedMrAndEcommTFIs, getPlanFilterDataTFIs,
    scenario, tsData 
  } = props;
  const modalData = props.modalData ? props.modalData : '';
  const { data: mrAndEcommTFIsFieldsData, error, extraDataError, getNewData, selectBrand, successMsg } = mrAndEcommTFIsData;
  const [readOnly, setReadOnly] = useState(false);
  const [urlSetup, setUrlSetup] = useState(true);
  const [urlSetupParams, setUrlSetupParams] = useState(true);
  const [brand, setBrand] = useState(selectBrand);
  const [fieldEdited, setFieldEdited] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [talkSheetFilterData, setTalkSheetFilterData] = useState([]);

  const checkForUrlParams = () => {
    if (urlSetupParams) {
      let urlPayload = {}, readonly = false, isModal = false;
      const params = match.params,
        urlSearchParams = new URLSearchParams(window.location.search);

      if (modalData && modalData.brand && modalData.month && modalData.year) {
        urlPayload = {
          brand: modalData.brand, year: modalData.year, currentFF: getCurrentFFForPayload(modalData.month),
        };
        isModal = true;
      } else if (params.brand && params.currentFF && params.year) {
        urlPayload = {
          brand: params.brand, currentFF: getCurrentFFForPayload(params.currentFF), year: params.year,
        };
        readonly = true;
      } else if (urlSearchParams.get('brand') && urlSearchParams.get('year') && urlSearchParams.get('month')) {
        urlPayload = {
          brand: urlSearchParams.get('brand'),
          currentFF: getCurrentFFForPayload(urlSearchParams.get('month')),
          year: urlSearchParams.get('year'),
          channel: decodeURIComponent(urlSearchParams.get('channel')),
          cluster: decodeURIComponent(urlSearchParams.get('cluster')),
          brandForm: decodeURIComponent(urlSearchParams.get('brandForm')),
          planName: urlSearchParams.get('planName'),
          planMonth: urlSearchParams.get('planMonth'),
        };
        readonly = true;
      }
      if ((readonly && isReadOnly(urlPayload.currentFF, urlPayload.year, brand)) || isModal) {
        setReadOnly(true);
      } else {
        setReadOnly(false);
      }
      setUrlSetupParams(false);
      return urlPayload;
    }
    return {};
  };

  const [urlPayload, seturlPayload] = useState(checkForUrlParams());
  const [qtr, setQtr] = useState(urlPayload.planMonth ? [getQuarterBasedOnMonth(urlPayload.planMonth)] : []);
  const [plan, setPlan] = useState(urlPayload.planName?[urlPayload.planName]:[]);
  const [date, setDate] = useState('');
  const [open, setOpen] = useState(false);
  const [month, setMonth] = useState(urlPayload.planMonth ? [urlPayload.planMonth] : []);
  const [channel, setChannel] = useState(urlPayload.channel ? [urlPayload.channel] : []);
  const [cluster, setCluster] = useState(urlPayload.cluster ? [urlPayload.cluster] : []);
  const [pageCount, setPageCount] = useState(0);
  const [fieldData, setFieldData] = useState([]);
  const [extraData, setExtraData] = useState(null);
    const [brandForm, setBrandForm] = useState(urlPayload.brandForm ? [urlPayload?.brandForm] : []);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rowSelected, setRowSelected] = useState('');
  const [saveButtonState, setSaveButton] = useState(false);
  const [saveBtnDisabled, disableSaveBtn] = useState(false)
  const [alertMessageText, setAlertMessage] = useState('');
  const [checkedMrEcommTFIs, setCheckedMrEcommTFIs] = useState([]);
  const [deleteConfirmState, setDeleteConfirm] = useState({
    isOpen: false,
    deleteMessage: '',
  });
  const [confirmState, setConfirm] = useState({ isOpen: false, confirmMessage: '' });
  useEffect(() => {
    setSticky();
  });

  useEffect(() => {
    props.clearModifyTFIs();
      if (brand) {
      !props.sptprData.filterData?.clusters?.length && props.getClusterfilter({ appid: match.params.appid });
    }
  }, []);

  useEffect(() => {
    if (getNewData) {
      fetchData(brandForm, channel, cluster, month, qtr, currentPage, rowsPerPage, brand, plan);
      fetchPlanFilter();
      setRowSelected('');
    }
  }, [getNewData]);

  useEffect(() => {
    if (error) {
      if(typeof error === 'string') {
      setAlertMessage({
        title: 'MR and Ecomm TFIs',
        p1: error,
      });
      handleClickOpen();
    } else {
      setAlertMessage({
        title: 'MR and Ecomm TFIs',
        ...error,
      });
      handleClickOpen();
    }
    }
  }, [error]);

  useEffect(() => {
    if (successMsg) {
      setAlertMessage({
        title: 'MR and Ecomm TFIs',
        p1: successMsg,
      });
      handleClickOpen();
    }
  }, [successMsg]);

  useEffect(() => {
    if (mrAndEcommTFIsFieldsData && mrAndEcommTFIsFieldsData.result && mrAndEcommTFIsFieldsData.result.data && !error) {
      const { data: { result } } = mrAndEcommTFIsData, { total, data } = result;
      // in case of delete we will have unsaved plan if there are some
      let unsavedPlans = fieldData.filter(i => i._id.startsWith('newplan'));
      setFieldData([...unsavedPlans, ...data]);
      if (unsavedPlans.length === 0 && !Object.keys(props.modifiedRecords).length) {
        setSaveButton(false);
        disableSaveBtn(false)
      }
      setPageCount(total);
      callMrAndEcommTFIsExtraData(data);
    }
  }, [mrAndEcommTFIsFieldsData]);

  useEffect(() => {
    if (selectBrand && typeof selectBrand === 'object' && selectBrand !== null && Object.keys(selectBrand).length > 0) {
      setBrand(selectBrand);
      props.getBrandformfilter({ brandName: selectBrand.BrandName, appid: match.params.appid });
      let m = '' + getMonth(selectBrand.CurrentFF, 'monthNumber');
      m = m.length === 1 ? '0' + m : m;
      getPlanFilterDataTFIs({
          currentFF: m,
          fiscalYear: `${selectBrand.FinancialYear}` ,
          url: 'mRandEcommTFIs',
          brand: selectBrand.BrandName,
          appid: match.params.appid,
          scenario: props?.sptprData?.selectScenario
      });
      if (!urlSetup) {
        setBrandForm('');
        setCluster('');
        setChannel('');
        setPlan([]);
      } else {
        setUrlSetup(false);
      }
    }
  }, [selectBrand]);

  useEffect(() => {
    setCheckedMrEcommTFIs([]);
  }, [mrAndEcommTFIsFieldsData?.result?.total]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if(successMsg || error){
      props?.removeSuccessMessage(null);
    }
  };

  const callMrAndEcommTFIsExtraData = (tableData) => {
    if (tableData.length > 0) {
      let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
      m = m.length === 1 ? '0' + m : m;
      let payload = {
        currentFF: brand ? `${m}` : '',
        fiscalYear: brand ? `${brand.FinancialYear}` : '',
        brand: brand ? brand.BrandName : '',
      };
      getMrAndEcommTFIsExtraData({
        payload,
        appid: match.params.appid,
        dataModalId: page.pageLevelDataModel,
      });
    }

  };

  useEffect(() => {
    if (mrAndEcommTFIsData && mrAndEcommTFIsData.extraData && !extraDataError) {
      setExtraData(mrAndEcommTFIsData.extraData);
    } else if (extraDataError && extraDataError !== 'Bad Request') {
      setAlertMessage({
        title: 'MR and Ecomm TFIs',
        p1: extraDataError,
      });
      handleClickOpen();
    }
  }, [mrAndEcommTFIsData.extraData]);

  const saveToStore = (record) => {
    props.modifyTFIs(record)
  }

  const handleFieldChange = (target, index, rowNames = [], id = '') => {
    const { name, value } = target;
    if (editableFields[name]) {
      let fieldsObject = fieldEdited[id] ? { ...fieldEdited[id] } : {};
      setFieldEdited({
        ...fieldEdited, [id]: {
          ...fieldsObject,
          [name]: true,
        },
      });
    }
    setSaveButton(true);
    saveBtnDisabled && disableSaveBtn(false);
    let oldData = JSON.parse(JSON.stringify(fieldData));
    if (oldData && oldData[index]) {
      // if it is new plan and is checked and update all new checked plan
      if (checkedMrEcommTFIs.length > 0 && checkedMrEcommTFIs.includes(oldData[index]._id)) {
        let updateFieldData = oldData.map(i => {
          if (checkedMrEcommTFIs.includes(i._id)) {
            i[name] = value;
            if(name === 'month'){
              i['quarter'] = getQuarterBasedOnMonth(value);
            }
            saveToStore(i)
            return i;
          } else {
            return i;
          }
        });
        setFieldData([...updateFieldData]);
      } else {
        oldData[index][name] = value;
        if(name === 'month'){
          oldData[index]['quarter'] = getQuarterBasedOnMonth(value);
        }
        saveToStore(oldData[index])
        setFieldData([...oldData]);
      }
    }
  };

  const createNewPlanPress = () => {
    let oldData = JSON.parse(JSON.stringify(fieldData));
    let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
    m = m.length === 1 ? '0' + m : m;
    let lm = '' + getMonth(brand.CurrentFF, 'lastMonth');
    lm = lm.length === 1 ? '0' + lm : lm;

    let dataToAdd = {
      editable: true,
      generatedHashKey: '',
      fiscalYear: brand ? `${brand.FinancialYear}` : '',
      currentFF: brand ? `${m}` : '',
      lastFF: brand ? `${lm}` : '',
      spendType: 'Mr and Ecomm TFIs',
      salesManager: '',
      msnpProjectManager: '',
      version: '',
      brand: brand ? brand.BrandName : '',
      brandform: brandForm?.length ? brandForm[0] : '',
      channel: channel?.length ? channel[0] : '',
      cluster: cluster?.length ? cluster[0] : 'All India',
      planDescription: 'NewPlan_1',
      planType: 'KBD 1 TFI',
      month: 'jul',
      quarter: 'AMJ',

      NoOfSKUsOrAssets: 0,
      DiscountPerSKUOrCosPerAsset: 0,

      isActive: true,
      createdBy: brand ? brand.createdBy : '',
      createdDate: new Date().toISOString(),
      _v: 0,
    };

    let newDataToAdd = months.map((item, index) => {
      let copyDataToAdd = JSON.parse(JSON.stringify({ ...dataToAdd, _id: 'newplan_' + generateGUID() }));

      copyDataToAdd.month = item;
      copyDataToAdd.quarter = getQuarterBasedOnMonth(item);
      props.modifyTFIs(copyDataToAdd);
      return copyDataToAdd;
    });
    oldData.splice(0, 0, ...newDataToAdd);
    setFieldData([...oldData]);
    setRowSelected(0);
    setSaveButton(true);
    saveBtnDisabled && disableSaveBtn(false);
  };

  const duplicateRow = () => {
    const oldData = JSON.parse(JSON.stringify(fieldData));
    const selectedRowData = oldData[rowSelected];
    selectedRowData.duplicated = selectedRowData.duplicated ? selectedRowData.duplicated + 1 : 1;
    let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
    m = m.length === 1 ? '0' + m : m;
    let lm = '' + getMonth(brand.CurrentFF, 'lastMonth');
    lm = lm.length === 1 ? '0' + lm : lm;
    let dataToAdd = {
      editable: true,
      generatedHashKey: '',
      // fiscalYear: selectedRowData.fiscalYear,
      fiscalYear: (brand ? brand.FinancialYear : selectedRowData.fiscalYear) + "",
      currentFF: brand ? `${m}` : '',
      lastFF: brand ? `${lm}` : '',
      spendType: selectedRowData.spendType,
      salesManager: '',
      msnpProjectManager: '',
      version: '',
      brand: selectedRowData.brand,
      brandform: selectedRowData.brandform,
      cluster: selectedRowData.cluster,
      channel: selectedRowData.channel,
      planDescription: selectedRowData.planDescription + '_1',
      planType: selectedRowData.planType,
      month: selectedRowData.month,
      quarter: selectedRowData.quarter,
      NoOfSKUsOrAssets: selectedRowData.NoOfSKUsOrAssets,
      DiscountPerSKUOrCosPerAsset: selectedRowData.DiscountPerSKUOrCosPerAsset,

      isActive: true,
      createdBy: brand ? brand.createdBy : '',
      createdDate: new Date().toISOString(),
      _v: 0,
      _id: 'newplan_' + generateGUID(),

    };
    props.modifyTFIs(dataToAdd);
    oldData.splice(rowSelected, 1, selectedRowData, dataToAdd);
    setFieldData([...oldData]);
    setRowSelected(rowSelected + 1);
    setSaveButton(true);
    saveBtnDisabled && disableSaveBtn(false);
  };

  const saveButtonHandler = () => {
    let newFieldData = JSON.parse(JSON.stringify(fieldData));
    let isFound = newFieldData.find((item, index) => {
      if (item.brandform.length === 0) {
        setAlertMessage({
          title: 'MR and Ecomm TFIs - Mandatory Field',
          p1: 'Brandform field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.month.length === 0) {
        setAlertMessage({
          title: 'MR and Ecomm TFIs - Mandatory Field',
          p1: 'Month is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.cluster.length === 0) {
        setAlertMessage({
          title: 'MR and Ecomm TFIs - Mandatory Field',
          p1: 'Cluster field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.channel.length === 0) {
        setAlertMessage({
          title: 'Trade Price Reduction - Mandatory Field',
          p1: 'Channel field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.planDescription.length === 0) {
        setAlertMessage({
          title: 'MR and Ecomm TFIs - Mandatory Field',
          p1: 'Plan Description is mandatory',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.month.length === 0) {
        setAlertMessage({
          title: 'Month - Mandatory Field',
          p1: 'Month Name field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
    });
    if (isFound) {
      return;
    }
    prepareDataForSaving();
  };

  const prepareDataForSaving = () => {
    let _modifiedRecords = Object.values(props.modifiedRecords)
    let dataToSend = JSON.parse(JSON.stringify(_modifiedRecords));
    // update ids of new plans so we can find and remove them when new data comes,
    let updatedFieldData = fieldData.map(i => {
      if (i._id.startsWith('newplan_')) {
        return { ...i, _id: 'Save' + i._id };
      }
      return i;
    });
    setFieldData([...updatedFieldData]);
    dataToSend.forEach(element => {
      if (element.editable) {
        delete element.editable;
      }
      element.month = element.month.toLowerCase();
      element.dataModelId = props.page.pageLevelDataModel;
      if (element._id.startsWith('newplan_')) {
        delete element._id;
      }
      if (element._id) {
        element.mRandEcommTFIsId = element._id;
        delete element._id;
      }
    });
    addNewMrAndEcommTFIs({ row: dataToSend, appid: match.params.appid });
    disableSaveBtn(true)
  };



  const fetchData = (brandForm, channel, cluster, month, qtr, currentPage, rowsPerPage, brandObj = '', planName) => {
    let objToSend = {
      appId: match.params.appid, rowsPerPage, page: currentPage,
      brand: !brandObj ? urlPayload.brand ? urlPayload.brand : brand.BrandName : brand.BrandName,
      scenario: props?.sptprData?.selectScenario
    };
    if (month?.length !== 12) {
      objToSend['month'] = month
    }
    if (qtr?.length !== 4) {
      objToSend['quarter'] = qtr
    }
    if (props.sptprData.filterData?.brands.length !== brandForm?.length) { /// brands has brandforms
      objToSend['brandform'] = brandForm
    }
    if (mrAndEcommTFIsData.filterData?.plans.length !== planName?.length) {
      objToSend['planName'] = planName
    }
    if (props.sptprData.filterData?.clusters?.length !== cluster?.length) {
      objToSend['cluster'] = cluster
    }
    if (defaultChannels.length !== channel.length) {
      objToSend['channel'] = channel
    }
    if (Object.keys(urlPayload).length === 0) {
      let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
      objToSend.currentFF = m.length === 1 ? '0' + m : m;
      objToSend.fiscalYear = brand.FinancialYear;
    } else {
      let m = urlPayload.currentFF;
      objToSend.currentFF = m.length === 1 ? '0' + m : m;
      objToSend.fiscalYear = urlPayload.year;
    }

    getMrAndEcommTFIsData(objToSend);
    let payload = {
      brand: brand.BrandName,
      year: `${brand.FinancialYear}`,
      currentFF: brand ? `${getCurrentFFForPayload(getMonth(brand.CurrentFF, 'monthNumber'))}` : '',
    }
    getTsData({ appId: match.params.appid, payload });
  };

  useEffect(() => {
    let talkMetricsArr = [
      {
        label: 'Modern Retail',
        Metrics: 'Modern Retail Spends',
        UoM: '$M'
      },
      {
        label: 'Ecommerce',
        Metrics: 'Ecommerce Plans',
        UoM: '$M'
      },
      {
        label: 'Ecommerce Total %RLP',
        Metrics: 'Ecommerce',
        UoM: 'Total %RLP'
      },
      {
        label: 'MR Total %RLP',
        Metrics: 'Modern Retail',
        UoM: 'Total %RLP'
      }
    ];
    if (tsData?.data.result?.data) {
      let talkData = tsData?.data.result?.data.filter((el) => talkMetricsArr.some((item) => item.Metrics === el.Metrics && item.UoM === el.UoM));
      let data = talkData.map(({ Metrics, UoM, BOP_FY }) => ({ Metrics, UoM, BOP_FY, talkSheet : talkMetricsArr.find((tEl) => tEl.UoM === UoM && tEl.Metrics === Metrics) }));
      setTalkSheetFilterData(data);
    }

  }, [tsData]);

  const fetchPlanFilter =()=>{
    let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
    m = m.length === 1 ? '0' + m : m;
    getPlanFilterDataTFIs({
        currentFF: m,
        fiscalYear: brand ? `${brand.FinancialYear}` : '',
        scenario: props?.sptprData?.selectScenario,
        url: 'mRandEcommTFIs',
        brand: brand.BrandName,
        appid: match.params.appid,
    });
  }

  useEffect(() => {
    fetchData(brandForm, channel, cluster, month, qtr, currentPage, rowsPerPage, brand, plan);
    setRowSelected('');
    setFieldEdited({});
  }, [brandForm, channel, cluster, month, qtr, currentPage, rowsPerPage, brand, plan, scenario]);

  const downloadButtonHandler = () => {
    if (brand) {
      let uri = API_BASE_URL + `/mRandEcommTFIs/download?brand=${encodeURIComponent(brand.BrandName)}`;
      if (brandForm?.length && props.sptprData.filterData?.brands.length !== brandForm?.length) {/// brands has brandforms
        uri = `${uri}&brandform=${encodeURIComponent(JSON.stringify(brandForm))}`;
      }
      if (channel?.length && defaultChannels.length !== channel?.length) {
        uri = `${uri}&channel=${encodeURIComponent(JSON.stringify(channel))}`;
      }
      if (cluster?.length && props.sptprData.filterData?.clusters?.length !== cluster?.length) {
        uri = `${uri}&cluster=${encodeURIComponent(JSON.stringify(cluster))}`;
      }
      if (plan?.length && plan?.length !== mrAndEcommTFIsData.filterData?.plans.length) {
        uri = `${uri}&planDescription=${encodeURIComponent(JSON.stringify(plan))}`;
      }
      if (month?.length && month?.length !== 12) {
        uri = `${uri}&month=${encodeURIComponent(JSON.stringify(month))}`;
      }
      if (qtr?.length && qtr?.length !== 4) {
        uri = `${uri}&quarter=${encodeURIComponent(JSON.stringify(qtr))}`;
      }
      if (getMonth(brand?.CurrentFF, 'monthNumber')) {
        let m = '' + getMonth(brand?.CurrentFF, 'monthNumber');
        m = m.length === 1 ? '0' + m : m;
        uri = `${uri}&currentFF=${encodeURIComponent(m)}`;
      }
      if (brand?.FinancialYear) {
        uri = `${uri}&fiscalYear=${encodeURIComponent(brand?.FinancialYear)}`;
      }
      let fyFF = getFiscalYearFormat(brand?.FinancialYear) + '-' + moment().month(brand?.CurrentFF).format('MMM');
      downloadFile(uri, match?.params?.appid, getFileNameWithTimeStamp('MR and Ecomm TFIs'+ '-' + brand?.BrandName + '-'+ fyFF), setDownloadLoading)
    }
  };

  const confirmHandler = () => {
    setConfirm({
      ...confirmState, isOpen: true,
      confirmMessage: `${props.page.name} data update done for  ${getMonth(selectBrand.CurrentFF, 'month')} ${getFiscalYearFormat(selectBrand.FinancialYear)}?`,
    });
  };

  const onConfirmResponse = (resp) => {
    if (resp) {
      finish();
    }
    setConfirm({ isOpen: false, confirmMessage: '' });
  };

  const finish = () => {
    let { BrandName, FinancialYear, CurrentFF } = props.brand;
    let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
    m = m.length === 1 ? '0' + m : m;
    let data = {
      formType: 'spMRandEcommTFIs',
      brand: BrandName,
      fiscalYear: FinancialYear,
      currentFF: m,
      'action': 'finish',
    };
    let url = `${API_BASE_URL}/setupdata/email/send`;
    axios({
      url: url,
      method: 'POST',
      data: data,
      headers: {
        appId: props.match.params.appid,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        toast.success(response.data?.message || 'Workflow emails sent successfully');
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.message || 'Not able to send workflow emails');
      });
  };

  const renderCell = (row, index, key, type = 'number', rowNames) => {
    let value = row[key];
      return (
        <input
          name={key}
          step={type === 'number'?"any":undefined}
          style={{width:`${value.length}ch`, minWidth: '14ch'}}
          type={type}
          className={classes.textInput}
          onChange={(e) => {
            const { name, value } = e.currentTarget;
            handleFieldChange({ name, value }, index, rowNames);
          }}
          value={type === 'number' ? Number(value) : value}
        />
      );
  };

  const selectOptions = (type) => {
    if (type === 'brandform') {
      return React.Children.toArray(
        props.sptprData.filterData?.brands.map((item, index) =>
          <MenuItem value={item.BrandFormName} key={index}>{item.BrandFormName}</MenuItem>,
        ));
    } else if (type === 'channel') {
      return defaultChannels.map((item, index) =>
        <MenuItem value={item} key={index}>{item}</MenuItem>);
    } else if (type === 'cluster') {
      return React.Children.toArray(
        props.sptprData.filterData?.clusters?.map((item, index) =>
          <MenuItem value={item.ClusterName} key={index}>{item.ClusterName}</MenuItem>,
        ));
    } else if (type === 'planType') {
      return defaultPlanType.map((item, index) =>
        <MenuItem value={item} key={index}>{item}</MenuItem>);
    } else if (type === 'month') {
      return React.Children.toArray(
        months.map((item) =>
          <MenuItem value={item.toLowerCase()}>{item}</MenuItem>,
        ));
    }
  };
  let headerOrder = 0;

  const renderSelectCell = (row, index, key, type, rowNames) => {
    const { editable } = row;
    if (editable) {
      return (
        <Select
          name={key}
          value={key === 'month'?row[key].toLowerCase():row[key]}
          displayEmpty={true}
          className="custom-select m-right-10"
          style={{ minWidth: '100px' }}
          onChange={(e) => {
            const { name, value } = e.target;
            handleFieldChange({ name, value }, index, rowNames);
          }}
        >
          {
            rowNames && <MenuItem value="">{rowNames}</MenuItem>
          }
          {selectOptions(key)}
        </Select>
      );
    } else {
      return (
        <span className={key === "month"?classes.capitalize:''}>{row[key]}</span>
      );
    }
  };

  const renderEditableCell = (row, index, key, rowNames, showPercent = true, id) => {
    if (!readOnly) {
      return (
        <p>
        <input
          name={key}
          type={'number'}
          step={"any"}
          className={classes.tableInput}
          onChange={(e) => {
            const { name, value } = e.currentTarget;
            handleFieldChange({
              name,
              value: value === '' ? '' : (showPercent ? Number(value) / 100 : Number(value)),
            }, index, rowNames, id);
          }}
          onBlur={(e) => {
            const { name, value } = e.currentTarget;
            handleFieldChange({ name, value: value === '' ? 0 : (showPercent ? Number(value) / 100 : Number(value)) },
              index, rowNames, id);
          }}
          value={showPercent ? row[key] === '' ? '' : (Math.round((Number(row[key]) * 100 + Number.EPSILON) * 100) / 100) : row[key] === '' ? '' : Number(row[key])}
        /></p>
      );
    } else {
      return (
        <p>{showPercent ? Math.round((Number(row[key]) * 100 + Number.EPSILON) * 100) / 100 : Number(row[key])}</p>
      );
    }
  };

  const selectRow = (e, index) => {
    if (rowSelected === index || readOnly) {
      setRowSelected('');
    } else {
      setRowSelected(index);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const onCheckHandler = (row, index) => {
    let newCheckedTpr = JSON.parse(JSON.stringify(checkedMrEcommTFIs));
    if (!newCheckedTpr.includes(row)) {
      newCheckedTpr.push(row);
    } else {
      newCheckedTpr.splice(newCheckedTpr.indexOf(row), 1);
    }
    setCheckedMrEcommTFIs(newCheckedTpr);
  };

  const onDeleteMrEcommTFIs = () => {
    setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: `Are you sure you want to delete the ${(checkedMrEcommTFIs.length?'selected':'all')} row(s)?`, });
  };

  const onDeleteResponse = (isDelete) => {
    if (isDelete) {
      let index = checkedMrEcommTFIs.findIndex(i => i.startsWith('newplan'));
      let { BrandName, FinancialYear, CurrentFF } = props.brand;
      let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
      m = m.length === 1 ? '0' + m : m;
      let commonObj= {
        currentFF: m,
        brand: BrandName,
        fiscalYear: FinancialYear,
      }
      if(scenario) commonObj['scenario'] = scenario;
      if (index !== -1) {
        let newPlanIds = checkedMrEcommTFIs.filter(i => i.startsWith('newplan'));
        let _fieldData = fieldData.filter(i => !newPlanIds.includes(i._id));
        props.removeModifyTFIs(newPlanIds)
        setFieldData(_fieldData);
        if (checkedMrEcommTFIs.length > newPlanIds.length) {
          let restIds = checkedMrEcommTFIs.filter(i => !newPlanIds.includes(i));
          deleteCheckedMrAndEcommTFIs({ mRandEcommTFIsIds: restIds, appid: match.params.appid, commonObj });
        }
      } else {
        deleteCheckedMrAndEcommTFIs({ mRandEcommTFIsIds: checkedMrEcommTFIs, appid: match.params.appid, commonObj });
      }
    }
    setDeleteConfirm({ ...deleteConfirmState, isOpen: false });
  };

  const calculateBudget = (row) => {
    if (extraData) {
      const gst = extraData.gst === 0 ? 0.18 : extraData.gst;
      const firmForex = extraData.firmForex;
      let budget;
      if (row.planType === 'KBD 1 TFI') {
        budget = Number(row.NoOfSKUsOrAssets) * Number(row.DiscountPerSKUOrCosPerAsset) / Number(firmForex) / 1000 / (1 + gst);
      } else {
        budget = Number(row.NoOfSKUsOrAssets) * Number(row.DiscountPerSKUOrCosPerAsset) / Number(firmForex) / 1000;
      }
      return budget;
    } else {
      return 0;
    }
  };

  const onAction = (actionType) => {
    switch (actionType) {
      case ActionType.Save:
        saveButtonHandler();
        break;
      case ActionType.NewPlan:
        createNewPlanPress();
        break;
      case ActionType.Duplicate:
        duplicateRow();
        break;
      case ActionType.Delete:
        onDeleteMrEcommTFIs();
        break;
      case ActionType.Download:
        downloadButtonHandler();
        break;
      case ActionType.DoneUpdates:
        confirmHandler();
        break;
      case ActionType.RemoveRecon:
      case ActionType.Upload:
        fetchData(brandForm, channel, cluster, month, qtr, currentPage, rowsPerPage, brand, plan)
        fetchPlanFilter()
        break;
    }
  };

  const onFilterChange = (filterType, { value }) => {
    switch (filterType) {
      case FilterType.Month:
        setDate(value);
        setMonth(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        setQtr([...new Set(value.map(m => getQuarterBasedOnMonth(m)))]);
        break;
      case FilterType.Qtr:
        setQtr(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
      case FilterType.Channel:
        setChannel(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
      case FilterType.Cluster:
        setCluster(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
      case FilterType.Brandform:
        setBrandForm(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
      case FilterType.Plan:
        setPlan(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
    }
  };

  const onSelectAll = (event, val)=> {
    if(val){
      let newCheckedItems = fieldData.map(i=> `${i._id}`)
      setCheckedMrEcommTFIs(newCheckedItems);
    } else {
      setCheckedMrEcommTFIs([]);
    }
  }

  const getTotalValue = (key)=>{
    if(mrAndEcommTFIsData.data?.result?.totalProperties && Number(mrAndEcommTFIsData.data.result.totalProperties[key])){
      return mrAndEcommTFIsData.data.result.totalProperties[key]
    }
    return '';
 }
  return (
    <div className="spendplan-tpr">
      <PlanHeader
        {...props}
        onAction={onAction}
        onFilterChange={onFilterChange}
        filters={['months', 'quarters', 'brandForms', 'channels', 'clusters', 'plans']}
        newPlanDisabled={brandForm?.length > 1 || channel?.length > 1 || cluster?.length > 1}
        filterValues={{ month, qtr, brandForm, channel, cluster, plan }}
        readOnly={readOnly}
        saveButtonState={saveButtonState}
        dataSource={mrAndEcommTFIsData}
        selectBrand={selectBrand}
        downloadLoading1={downloadLoading}
        rowSelected={rowSelected}
        fieldData={fieldData}
        customChannel={defaultChannels}
        saveBtnDisabled={saveBtnDisabled}
        talkSheetFilterData={talkSheetFilterData}
      />
      {(!mrAndEcommTFIsData.loading)? (
        <React.Fragment>
          <React.Fragment>
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table className={classNames(classes.table, 'tpr-table')} aria-label="simple table" stickyHeader>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {(!readOnly && fieldData.length) ?
                      <TableCell data-order={headerOrder++} className="sticky_column">
                        <Checkbox
                          onChange={(e,v) => onSelectAll(e,v)}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          checked={checkedMrEcommTFIs.length === fieldData.length}
                        />
                      </TableCell> : null}
                    <TableCell data-order={headerOrder++} className="sticky_column">
                      <span>Month</span>
                    </TableCell>
                    <TableCell data-order={headerOrder++} className="sticky_column"><span>Brandform</span></TableCell>
                    <TableCell data-order={headerOrder++} className="sticky_column"><span>Sub-Channel</span></TableCell>
                    <TableCell data-order={headerOrder++} className="sticky_column"><span>Cluster</span></TableCell>
                    <TableCell data-order={headerOrder++} className="sticky_column"><span>Plan Type</span></TableCell>
                    <TableCell data-order={headerOrder++} className={ classNames("sticky_column", classes.greenFont)}><span>Plan Description</span>
                    </TableCell>
                    <TableCell className={classes.blackFont} colSpan="3">
                    <section className={classes.combinedHeaderTotalSection}>
                    <p className={classes.totalValue} title={getTotalValue('lc_BudgetInMillions')}>{convertToNumber(getTotalValue('lc_BudgetInMillions'), 2).toLocaleString()}</p>
                    </section>
                      <p className={classes.combinedHeaderHeadingGrey}>{'Last Cycle'}</p>
                      <section className={classes.combinedHeaderSection}>
                        <p className={classes.blackFont}><span>No of <br/>SKUs/Assets</span></p>
                        <p className={classes.blackFont}><span>Discount per<br/>SKU/Cost per <br/>Assets</span></p>
                        <p className={classes.blackFont}><span>Spend<br/>$M</span></p>
                      </section>
                    </TableCell>
                    <TableCell className={classes.greenFont} colSpan="3">
                      <div style={{display: 'flex', justifyContent: 'space-between', height: 20, alignItems: 'flex-end'}}>
                               <p className={classes.totalValue} style={{width: '40%',}} title={getTotalValue('NoOfSKUsOrAssets')}>{convertToNumber(getTotalValue('NoOfSKUsOrAssets'), 2).toLocaleString()}</p>
                               <p className={classes.totalValue} style={{width: '40%',}}></p>
                               <p className={classes.totalValue} style={{width: '20%',}} title={getTotalValue('budget')}>{convertToNumber(getTotalValue('budget'), 2).toLocaleString()}</p>
                      </div>
                      <p className={classes.combinedHeaderHeading}>For BOP</p>
                      <section className={classes.combinedHeaderSection}>
                        <p className={classes.greenFont}><span>No of <br/>SKUs/Assets</span></p>
                        <p className={classes.greenFont}><span>Discount per<br/>SKU/Cost per <br/>Assets</span></p>
                        <p><span className={classes.blackFont}>Spend<br/>$M</span></p>
                      </section>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {fieldData && fieldData.length > 0 ? fieldData.map((row, index) => {
                      let order = 0;
                      let fieldObject = fieldEdited[row._id] ? fieldEdited[row._id] : {};

                      return (
                        <StyledTableRow key={index} onClick={(e) => selectRow(e, index)}
                                        className={rowSelected === index ? classes.rowSelectedBg : ''}>
                          {!readOnly ? (
                            <TableCell
                              className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                              id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                              {
                                <Checkbox
                                  onChange={() => onCheckHandler(row._id, index)}
                                  color="primary"
                                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  checked={checkedMrEcommTFIs.includes(row._id)}
                                />
                              }
                            </TableCell>
                          ) : null}
                          <TableCell
                            className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                            id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                            {renderSelectCell(row, index, 'month', 'text', null)}
                          </TableCell>
                          <TableCell
                            className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                            id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                            {renderSelectCell(row, index, 'brandform', 'text', 'All Brandforms')}
                          </TableCell>
                          <TableCell
                            className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                            id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                            {renderSelectCell(row, index, 'channel', 'text', 'All Sub-Channels')}
                          </TableCell>
                          <TableCell
                            className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                            id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                            {renderSelectCell(row, index, 'cluster', 'text', 'All Clusters')}
                          </TableCell>
                          <TableCell
                            className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                            id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                            {renderSelectCell(row, index, 'planType', 'text', '')}
                          </TableCell>
                          <TableCell
                            className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                            id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                            {renderCell(row, index, 'planDescription', '')}
                          </TableCell>
                          <StyledTableCell colSpan="3">
                            <section className={classes.combinedBodySection}>
                              <p>{convertToNumber(row.lc_NoOfSKUsOrAssets, 0).toLocaleString()}</p>
                              <p>{convertToNumber(row.lc_DiscountPerSKUOrCosPerAsset, 0).toLocaleString()}</p>
                              <p title={convertToNumber(row.lc_BudgetInMillions, 2) + ""}>{convertToNumber(row.lc_BudgetInMillions, 0).toLocaleString()}</p>
                            </section>
                          </StyledTableCell>
                          <StyledTableCell colSpan="3">
                            <section className={classes.combinedBodySection}>
                              {renderEditableCell(row, index, 'NoOfSKUsOrAssets', '', false, row._id)}
                              {renderEditableCell(row, index, 'DiscountPerSKUOrCosPerAsset', '', false, row._id)}
                              <p title={convertToNumber(calculateBudget(row) ?? row.BudgetInMillions, 2)+ ""}>{convertToNumber(calculateBudget(row) ?? row.BudgetInMillions, 0).toLocaleString()}</p>
                            </section>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }) :
                    renderNoDataText(brandForm, channel, cluster, month, qtr)
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </React.Fragment>
        </React.Fragment>
      ) : (
        <PlanningLoading />
      )}
      <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} classes={classes} />
      <ConfirmDialog open={deleteConfirmState.isOpen} handleClose={onDeleteResponse}
                     alertMessageText={deleteConfirmState.deleteMessage} classes={classes} confirm/>
      <ConfirmDialog open={confirmState.isOpen} handleClose={onConfirmResponse}
                     alertMessageText={confirmState.confirmMessage} classes={classes} confirm/>
      {
        mrAndEcommTFIsData.message &&
        <ConfirmDialog open={true} handleClose={() => props.hideMessage()}
                       alertMessageText={{title : 'TFIs - Save', p1:mrAndEcommTFIsData.message}} classes={classes}/>
      }

    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    mrAndEcommTFIsData: state.MrAndEcommTFIsReducer,
    brand: state.MrAndEcommTFIsReducer?.selectBrand,
    modifiedRecords: state.MrAndEcommTFIsReducer.modifiedRecords,
    sptprData: state.tprReducer,
    scenario: state.tprReducer?.selectScenario,
    tsData: state.talkSheetReducer
  };
};

const mapDispatchToProps = {
  getMrAndEcommTFIsData,
  addNewMrAndEcommTFIs,
  deleteCheckedMrAndEcommTFIs,
  getMrAndEcommTFIsExtraData,
  hideMessage,
  removeSuccessMessage,
  getPlanFilterDataTFIs,
  modifyTFIs,
  clearModifyTFIs,
  removeModifyTFIs,
  getClusterfilter,
  getBrandformfilter,
  getTsData,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(MrAndEcommTFIs)));
