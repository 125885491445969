const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { request } from './../client';

export class pageService {
    static getPageConfig(pageId, appId) {
        const uri = API_BASE_URL + "/pages/" + pageId;

        return request(uri, {
            method: 'GET'
        });
    }
    static getPages(appId) {
        console.log("appId", appId)
        const uri = API_BASE_URL + "/pages/app/";

        return request(uri, {
            method: 'GET',
            headers: {
                appId
            }
        });
    }
    static savePage(data) {
        const uri = API_BASE_URL + "/pages";

        return request(uri, {
            method: 'POST',
            payload: data,
            headers: {
                appId: data.appId
            }
        });
    }
}
