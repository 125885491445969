import FileDownload from 'js-file-download';
import axios from 'axios';
import { TableCell, TableRow, withStyles } from '@material-ui/core';
import { getMonth } from '../../../../utility/utils';
import React from 'react';
import Loader from '../../../Loader';
import { toast } from 'react-toastify';

export const getQuarterBasedOnMonth = (month) => {
  let key = month.toLowerCase();
  switch (key) {
    case 'jul':
    case 'aug':
    case 'sep':
      return 'JAS';
    case 'oct':
    case 'nov':
    case 'dec':
      return 'OND';
    case 'jan':
    case 'feb':
    case 'mar':
      return 'JFM';
    case 'apr':
    case 'may':
    case 'jun':
      return 'AMJ';
  }
};

export const downloadFile = (url, appId, fileName, setLoading) => {
  setLoading(true)
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      appId: appId,
    },
  })
    .then((response) => {
      FileDownload(response.data, fileName);
      setLoading(false)
    })
    .catch(() => {
      setLoading(false)
      toast.error('Unable to download')
    });
};

export const months = ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN'];

export const isReadOnly = (FF, FY, brand) => {
  let currentMonth = brand ? getMonth(brand.CurrentFF, 'monthNumber') : null,
    currentYear = brand ? brand.FinancialYear : null;

  if (currentMonth != null && currentYear != null && typeof FF != 'undefined' && typeof FY != 'undefined') {
    if (Number(FY) < currentYear || Number(FF) < currentMonth)
      return true;
  } else {
    return false;
  }
};

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const setSticky = () => {
  const bodyColumns = document.querySelectorAll('.sticky_body_column');
  const headerColumns = document.querySelectorAll('.sticky_column');
  if (headerColumns.length > 0) {
    let width = 0, nextWidth = 0;
    headerColumns.forEach((column, i) => {
      let order = column.dataset.order,
        currentElement = document.querySelector(`.sticky_column[data-order="${order}"]`);
      if (!currentElement) {
        return;
      }
      if (order === '0') {
        width = 0;
        nextWidth = currentElement.offsetWidth;
      } else {
        width = nextWidth - Number(order);
        nextWidth += currentElement.offsetWidth;
      }
      currentElement.style.position = 'sticky';
      currentElement.style.left = `${width}px`;
      currentElement.style.zIndex = '3';
    });
  }
  if (bodyColumns.length > 0) {
    let bodyWidth = 0, bodyNextWidth = 0;
    bodyColumns.forEach((column, i) => {
      let order = column.dataset.order, elementIndex = column.dataset.index,
        currentElement = document.getElementById(`sticky_body_column_${elementIndex}_${order}`);
      if (!currentElement) {
        return;
      }
      if (order === '0') {
        bodyWidth = 0;
        bodyNextWidth = currentElement.offsetWidth;
      } else {
        bodyWidth = bodyNextWidth - Number(order);
        bodyNextWidth += currentElement.offsetWidth;
      }
      currentElement.style.position = 'sticky';
      currentElement.style.left = `${bodyWidth}px`;
      if (elementIndex % 2) {
        currentElement.style.backgroundColor = '#fff';
      } else {
        currentElement.style.backgroundColor = '#F5F5F5';
      }
    });
  }
};

export const convertToNumber = (value, point) => {
  if (value) {
    let v = Number(parseFloat(('' + value).replace(/,/g, '')
      .trim())
      .toFixed(point || point === 0 ? point : 2));
    return !isNaN(v) ? v : 0;
  } else return 0;
};

export const convertToPercentage = (value, point) => {
  let v = Number(parseFloat(('' + value).replace(/,/g, '')
      .trim()));
  let num = v === 0 ? v.toFixed(point || 2) : (v * 100).toFixed(point || 2);
  return num + "%";
};

export const renderNoDataText = (brandForm, channel, cluster, month, qtr) => {
  let textToShow = 'No Data Found';
  if ((brandForm && brandForm.length > 0) || (channel && channel.length > 0) || (cluster && cluster.length > 0) || (month && month.length > 0) || (qtr && qtr.length > 0)) {
    textToShow = 'No data found for the selected search filters.';
  }
  return (
    <StyledTableRow>
      <StyledTableCell colSpan="100%">
        <h4 style={{ color: '#000' }}>{textToShow}</h4>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export const planTypeByPageName = {
  "Trade Price Reduction": "sptpr",
  "Consumer Promotions": "consumerPromotions",
  "Trial Discounts": "trailDiscounts",
  "Displays": "displays",
  "Conversion Funds": "conversionFunds",
  "MR and Ecomm TFIs": "mRandEcommTFIS",
}


export const renderNoDataTextMR = (brandForm, brand, sku, month) => {
  let textToShow = 'No Data Found';
  if (brandForm?.length > 0 || brand?.length > 0 || month?.length > 0 || sku?.length > 0) {
    textToShow = 'No data found for the selected search filters.';
  }
  return (
    <StyledTableRow>
      <StyledTableCell colSpan="100%">
        <h4 style={{ color: '#000' }}>{textToShow}</h4>
      </StyledTableCell>
    </StyledTableRow>
  );
};
export const PlanningLoading = () => {
  return (
    <section style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 400px)', }}>
      <Loader />
    </section>)
}

export const commonStyle = {
  tableContainer: {
    maxWidth: '95vw',
    borderRadius: '8px',
    overflowX: 'scroll !important',
    '@media(max-width:1366px)': {
      height: 'calc(100vh - 302px)',
    },
    '@media(min-width:1367px)': {
      marginBottom: '15px',
      height: 'calc(100vh - 355px)',
    },
    scrollbarWidth: 'thin',
    height: 'calc(100vh - 355px)',
    '&::-webkit-scrollbar': {
      width: '0.8em',
      height: '0.8em',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#cdcdcd',
      outline: '1px solid slategrey',
    },
  },
  tableBodyNoData: {
    margin: '50px',
    display: 'flex',
    minHeight: '50vh',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    '& h3': {
      color: 'black',
    },
  },
  greenFont: {
    '& span': {
      color: 'green',
    },
  },
  blackFont: {
    color: 'black !important',
  },
  rowSelectedBg: {
    background: '#CEE7FC !important',
    transition: 'background-color 0.5s ease',
  },
  combinedHeaderHeading: {
    margin: '5px 0px 0px 0px',
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '4px',
    padding: '4px 2px 2px',
    background: 'rgb(16, 114, 189)',
  },
  combinedHeaderHeadingGrey: {
    margin: '5px 0px 0px 0px',
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '4px',
    padding: '4px 2px 2px',
    background: 'rgb(127, 127, 127)',
  },
  confirmationDialoge: {
    '& .MuiDialog-paperWidthSm': {
      height: '320px',
      width: '600px',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  totalValue: {
    margin: 0,
    color: '#0070C0',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  noBorder: {
    border: 'none',
  }
}