import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Table, TableContainer, TableCell, TableRow, TableHead, TableBody, Paper, Select, MenuItem, FormControl, makeStyles } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
import { loadEntities, saveSupportingDocument } from 'store/actions'
import { DropzoneArea } from 'material-ui-dropzone'

const acceptedFiles = ['application/pdf']

const UploadFile = props => {

    const [files, setFiles] = useState([]);

    const handleChange = (files) => {
        setFiles(files);
        console.log("fire", files);
        if (files && files.length > 0) {
            uploadWithFormData(files);
        }
    }

    const submitForm = (contentType, data, props, setResponse, onUploadProgress) => {
        let url = API_BASE_URL + '/custom/supportingDocument/pdfupload';
        
        axios({
            url: url,
            method: 'POST',
            data: data,
            headers: {
                'Content-Type': contentType,
                appId: props.match.params.appid
            },
            onUploadProgress: (p) => {
                onUploadProgress && onUploadProgress(p)
            }
        }).then((response) => {
            //Saving files to state for further use and closing Modal.
            setResponse(response.data);
        }).catch((error) => {
            setResponse({ "error": error });
        })
    }

    const uploadWithFormData = (files) => {
        const formData = new FormData();
        // formData.append("files", files);
        formData.append("file", files[0]);

        submitForm("multipart/form-data", formData, props, (res) => {
            console.log("upload success", res);
            if (res.status = "success") {
                // props.getSupportingDocument(res._id);
                props.saveSupportingDocument(res);
                props.history.push({
                    pathname: "/apps/" + props.match.params.appid + "/ibcSupportDocExtraction",
                    query:{qid: res._id}
                });
            } else {
                console.error("error", res);
            }
        });
    }

    return (
        <div>
            {console.log("files", files)}
            <div className="panel-heading" style={{ color: "#000" }}>
                <h4 style={{ fontSize: "24px" }}>Upload File</h4>
                <DropzoneArea
                    onChange={(files) => handleChange(files)}
                    showPreviews={false}
                    acceptedFiles={acceptedFiles}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        app: state.appConfig.app,
        member: state.appConfig.member,
        entities: state.entities
    };
};

const mapDispatchToProps = {
    loadEntities,
    saveSupportingDocument
};


export default hot(withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(UploadFile)),
);