const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { request } from '../client';

export class ReconReviewService {

  static getReconData(data) {
    let uri = API_BASE_URL + '/recon' + data.url;
    return request(uri, {
      method: 'GET',
      headers: {
        appId: data.appId,
      },
    });
  }


  static addReconBaseLine(data) {
    let uri = API_BASE_URL + '/recon';
    return request(uri, {
      method: 'POST',
      payload: data.data,
      headers: {
        appId: data.appId,
      },
    });
  }

  static updateReconLabel(payload) {
    const {data} = payload;
    let id = data.id
    delete data.id;
    let uri = API_BASE_URL + '/recon/'+ id;
    return request(uri, {
      method: 'PUT',
      payload: data,
      headers: {
        appId: payload.appId,
      },
    });
  }

  static removeReconData(data) {
    let uri = API_BASE_URL + `/consumerPromotion/brand/${data.brand}?page=${data.page}&rowsPerPage=${data.rowsPerPage}`;
    if (data.brandform) {
      uri = `${uri}${`&brandform=${encodeURIComponent(data.brandform)}`}`;
    }
    return request(uri, {
      method: 'POST',
      data: data.data,
      headers: {
        appId: data.appId,
      },
    });
  }

  static removeReconColumn(data) {
    let uri = API_BASE_URL + `/recon/${data.id}`;
    return request(uri, {
      method: 'DELETE',
      headers: {
        appId: data.appId,
      },
      payload: { ...data.commonObj},

    });
  }

}
