import React, { useState, useEffect } from 'react';
import { AppService } from 'modules/api/index';
// import Highcharts from 'highcharts';
import Highcharts from '../HighchartsWrapper'
// import HighchartsCustomEvents from 'highcharts-custom-events';
// HighchartsCustomEvents(Highcharts);

import {
	getMixedChartConfig,
	getDefaultMixedChart,
	generateMixedData
} from 'modules/page/highcharts/mixed.module';

import {
	getTheme
} from 'modules/page/component.module';

import {
	generateGUID
} from 'modules/page/page.module';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isSpendplan = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'spendplan';

const MixedChart = (props) => {
	const [options, setOptions] = useState(props.isCustom?props.data:getDefaultMixedChart());
	const [id, setId] = useState(`mixed-${generateGUID()}`)

	const highChartsRender = () => {
		let mixedConfig = getMixedChartConfig(options, id);
		if(!isSpendplan){
			Highcharts.theme = getTheme();
			// Apply the theme
			Highcharts.setOptions(Highcharts.theme);
		}
		// Radialize the colors
		/*Highcharts.getOptions().colors = Highcharts.map(Highcharts.getOptions().colors, function (color) {
			return {
				radialGradient: {
					cx: 0.5,
					cy: 0.3,
					r: 0.7
				},
				stops: [
					[0, color],
					[1, Highcharts.Color(color).brighten(-0.3).get('rgb')] // darken
				]
			};
		});*/
		Highcharts.chart(mixedConfig);
	}

	const setDatafromApi = (res, data) => {
		let d = { ...data };
		let { chartData } = props;
		console.log('data', data, res);

		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			d.labels = res.labels;
			d.datasets = res.datasets;
		}
		let ops = generateMixedData(d, options)
		setOptions({ ...options, ...ops });
	}

	const getApiDataFromDataUrl = (url, data) => {
		AppService.getApiDataFromDataUrl(url, props.appId)
			.then(res => {
				setDatafromApi(res, data)
			})
	}

	// useEffect(() => {
	//     setData(props.data);
	//     highChartsRender()
	// }, [props.data]);

	useEffect(() => {
		highChartsRender();
	}, [options]);

	useEffect(() => {
		let { chartData } = props;
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + "/dataconnect/mixed/" + chartData.selectedDataModel + "?";
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			// targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
			targetURL += chartData.selectedDataElement.reduce(reducer, '&measure=');
			targetURL += chartData.selectedDataLabels.reduce(reducer, '&label=');
			targetURL += '&metric=' + chartData.metric;
			if (props.queryString) {
				targetURL += '&' + props.queryString;
			}
			//labeltype=date&format=%Y-%m
			if (chartData.datelabeltype) {
				targetURL += '&labeltype=date'
				targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
			}

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (props.page && props.page.conditions && props.page.conditions.length > 0) {
				let cond = JSON.stringify(props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
      let encodedData = encodeURIComponent(targetURL)
      let _targetUrl = JSON.stringify(encodedData);
			getApiDataFromDataUrl(_targetUrl, props.data);
		} else if (props.dataUrl) {
			getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			let ops = generateMixedData(props, options)
			setOptions({ ...options, ...ops });
		}
	}, [])

	return (
		id && <div id={id} className="chartEqualHeight"></div>
	)
};

export default MixedChart;