import { put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import { MrAndEcommTFIsService } from '../../../modules/api/custom/spendplan/mrAndEcommTFIs';


async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* fetchMrAndEcommTFIsData(action) {
    try {
        const tableData = yield fetchAsync(MrAndEcommTFIsService.getMrAndEcommTFIs, action.payload);
      yield put({ type: ActionTypes.GET_MRANDECOMMTFIS_SUCCESS, payload: tableData });
    }
    catch (err) {
        yield put({ type: ActionTypes.GET_MRANDECOMMTFIS_FAILURE, error: err.message });
    }
}

function* fetchMrAndEcommTFIsExtraData(action) {
    try {
        const tableData = yield fetchAsync(MrAndEcommTFIsService.getMrAndEcommTFIsExtraData, action.payload);
        yield put({ type: ActionTypes.GET_MRANDECOMMTFIS_EXTRA_DATA_SUCCESS, payload: tableData.result.data });
    }
    catch (err) {
        yield put({ type: ActionTypes.GET_MRANDECOMMTFIS_EXTRA_DATA_FAILURE, extraDataError: err.message });
    }
}

function* addNewMrAndEcommTFIs(action) {
    try {
        const response = yield fetchAsync(MrAndEcommTFIsService.addNewMrAndEcommTFIs, action.payload)
        yield put({ type: ActionTypes.ADD_NEW_MRANDECOMMTFIS_SUCCESS, payload: response })
    } catch (err) {
         let errorMsg, error;
        if (err.response.error) {
            error = err.response.error; 
        } else {
            errorMsg = err.message != 'Bad Request' ? err.message : 'Please enter correct Data';
        }
        yield put({ type: ActionTypes.ADD_NEW_MRANDECOMMTFIS_FAILURE, error: errorMsg?errorMsg:error });
    }
}

function* deleteCheckedMrAndEcommTFIss(action) {
    try {
        const response = yield fetchAsync(MrAndEcommTFIsService.deleteCheckedMrAndEcommTFIs, action.payload)
        yield put({ type: ActionTypes.DELETE_CHECKED_MRANDECOMMTFIS_SUCCESS, payload: response })
    }
    catch (err) {
        let errorMsg = err.message !== 'Bad Request' ? err.message : 'Please enter correct Data';
        yield put({ type: ActionTypes.DELETE_CHECKED_MRANDECOMMTFIS_FAILURE, error: errorMsg });
    }
}

function* fetchPlans(action) {
    try {
        const response = yield fetchAsync(MrAndEcommTFIsService.getPlans, action.payload)
        yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_TFIS_SUCCESS, payload: response?.result?.data ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'check request Failed';
        yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_TFIS_FAILURE, error: errorMsg });
    }
}

export function* consumerPromotion() {
    yield takeLatest(ActionTypes.GET_MRANDECOMMTFIS_DATA, fetchMrAndEcommTFIsData);
    yield takeLatest(ActionTypes.ADD_NEW_MRANDECOMMTFIS, addNewMrAndEcommTFIs);
    yield takeLatest(ActionTypes.DELETE_CHECKED_MRANDECOMMTFIS, deleteCheckedMrAndEcommTFIss);
    yield takeLatest(ActionTypes.GET_MRANDECOMMTFIS_EXTRA_DATA, fetchMrAndEcommTFIsExtraData);
    yield takeLatest(ActionTypes.GET_PLAN_FILTER_DATA_TFIS, fetchPlans);

}

export default consumerPromotion;
