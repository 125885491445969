import { makeStyles } from '@material-ui/core';
import React from "react";
import LoadingIcon from '../../images/loading.gif';

const useStyles = makeStyles({
    flex: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
});

const Loader = () => {

    const classes = useStyles();

    return (
        <div className={classes.flex}>
            <img src={LoadingIcon} alt='loading_img' />
        </div>
    )
}

export default Loader;
