import React, { useState, useEffect } from 'react';
import PieChart from './PieChart';
import MixedChart from './MixedChart';
import LineChart from './LineChart';
import DoughnutChart from './DoughnutChart';
import BarChart from './BarChart';
import HorizontalBarChart from './HorizontalBarChart';
import BulletChart from 'components/charts/bulletChart/BulletChart';
import OrgChart from 'components/charts/orgChart/OrganisationChart';
import Card from 'components/cards/Card';
import HTMLCard from 'components/cards/HTMLCard';
// import Table from 'components/Table';
import TableWrapper from 'components/table/TableWrapper';
import Table from 'components/@table/TableOld';
// import Table from 'components/@table/Table';
import SummaryTable from 'components/table/SummaryTable';

const options = {
	// responsive: true,
	maintainAspectRatio: true
};

const styles = {};

const Chart = (props) => {
	const [data, setData] = useState({});

	console.log("Chart = highcharts");

	useEffect(() => {
		setData(JSON.parse(JSON.stringify(props.data)));
	}, [props.data]);

	const renderChart = (data) => {
		console.log('====================================');
		console.log('props.page=?=', props.page);
		console.log('data.chartType', data.chartType);
		console.log('====================================');
		if (data.chartType === "pie") {
			let legendOpts = data.chartData.legendOpts ? data.chartData.legendOpts : null;
			return <PieChart page={props.page} data={data.chartData.data} chartData={data.chartData}
				dataUrl={data.chartData.dataUrl} styles={styles} legendOpts={legendOpts}
				options={options} queryString={props.queryString} appId={props.appId} _id={data._id} />
		}
		else if (data.chartType === "mixed") {
			let legendOpts = data.chartData.legendOpts ? data.chartData.legendOpts : null;
			return <MixedChart page={props.page} data={data.chartData.data} chartData={data.chartData}
				dataUrl={data.chartData.dataUrl} styles={styles} legendOpts={legendOpts}
				options={options} queryString={props.queryString} appId={props.appId} _id={data._id} />
		}
		else if (data.chartType === "doughnut") {
			let legendOpts = data.chartData.legendOpts ? data.chartData.legendOpts : null;
			return <DoughnutChart page={props.page} data={data.chartData.data} chartData={data.chartData}
				dataUrl={data.chartData.dataUrl} styles={styles} legendOpts={legendOpts}
				options={options} queryString={props.queryString} appId={props.appId} _id={data._id} />
		}
		else if (data.chartType === "bar") {
			return <BarChart page={props.page} data={data.chartData.data} chartData={data.chartData}
				dataUrl={data.chartData.dataUrl} styles={styles} options={options}
				queryString={props.queryString} appId={props.appId} _id={data._id} />
		}
		else if (data.chartType === "stackedbar") {
			return <BarChart page={props.page} data={data.chartData.data} chartData={data.chartData}
				dataUrl={data.chartData.dataUrl} styles={styles} options={options}
				queryString={props.queryString} stacked={true} appId={props.appId} />
		}
		else if (data.chartType === "line") {
			return <LineChart page={props.page} data={data.chartData.data} chartData={data.chartData}
				dataUrl={data.chartData.dataUrl} styles={styles} options={options}
				queryString={props.queryString} appId={props.appId} />
		}
		else if (data.chartType === "horizontalBar") {
			return <HorizontalBarChart page={props.page} data={data.chartData.data} chartData={data.chartData}
				dataUrl={data.chartData.dataUrl} styles={styles} options={options}
				queryString={props.queryString} appId={props.appId} />
		}
		else if (data.chartType === "list" || data.chartType === "table"
			|| data.chartType === "simpletable") {
			return <Table page={props.page} data={data.chartData.data} columns={data.chartData.columns}
				width={data.chartData.width} height={data.chartData.height}
				dataUrl={data.chartData.dataUrl} chartData={data.chartData}
				queryString={props.queryString} chartType={data.chartType} appId={props.appId} />
		}
		else if (data.chartType === "summaryTable") {
			return <SummaryTable page={props.page} data={data.chartData.data} columns={data.chartData.columns}
				width={data.chartData.width} height={data.chartData.height}
				dataUrl={data.chartData.dataUrl} chartData={data.chartData}
				queryString={props.queryString} chartType={data.chartType} appId={props.appId} />
		}
		else if (data.chartType === "bullet") {
			return <BulletChart page={props.page} data={data.chartData.data} queryString={props.queryString}
				appId={props.appId} />
		}
		else if (data.chartType === "org") {
			return <OrgChart page={props.page} data={data.chartData.data} queryString={props.queryString}
				appId={props.appId} />
		} else if (data.chartType === "card") {
			return <Card page={props.page} data={data.chartData} chartData={data.chartData}
				dataUrl={data.chartData.dataUrl} queryString={props.queryString}
				appId={props.appId} _id={data._id} />
		} else if (data.chartType === "html") {
			return <HTMLCard page={props.page} data={data.chartData} chartData={data.chartData}
				dataUrl={data.chartData.dataUrl} queryString={props.queryString}
				appId={props.appId} />
		}
	}

	return (
		<>
			{data ? renderChart(data) : ''}
		</>
	)
};

export default Chart;
