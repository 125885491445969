import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import axios from 'axios';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import PlanUploadConfirmDialog from './planUploadConfirmDialog';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { getMonth } from 'utility/utils';
import { setPlanUploadLoading } from '../../../../../../store/actions';
import { UploadLoading } from '../../../../../Loading/uploadLoading';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
  setupConfirmationDialog: {
    '& .MuiDialog-paperWidthSm': {
      minHeight: '300px',
      minWidth: '600px',
    },
  },
  para: {
    fontSize: '28px !important',
    fontWeight: 600,
    color: '#000',
  },
}));

const getURL = (name) =>{
  const planUploadURLs = {
    'Trade Price Reduction': '/sptpr',
    'Consumer Promotions': '/consumerPromotion',
    'Trial Discounts': '/trialDiscounts',
    'Displays': '/displays',
    'Conversion Funds': '/conversionFunds',
    'MR and Ecomm TFIs': '/mRandEcommTFIs',
  }
  return `${API_BASE_URL}`+ planUploadURLs[name] + '/upload'
}

const UploadPlan = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    open: false,
    files: [],
    file: null,
    fileId: props.fileId,
  });
  const [confirmState, setConfirm] = useState({ isOpen: false, confirmMessage: '' });

  const handleClose = (open) => {
    setState({
      [open]: false,
    });
  };

  const submitForm = (contentType, data, setResponse) => {
    let url = getURL(props.page.name);
    props.setPlanUploadLoading(true);
    axios({
      url: url,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': contentType,
        appId: props.match.params.appid,
      },
      onUploadProgress: (progressEvent) =>{
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log('percentCompleted', percentCompleted)
      }
    })
      .then((response) => {
        setResponse(response.data);
        props.setPlanUploadLoading(false);
        if (response.data?.responseCode === 400)
          return setConfirm({
            ...confirmState, isOpen: true,
            confirmMessage: { title: 'Error', message: response.data?.responseMessage },
          });
        setConfirm({
          ...confirmState, isOpen: true,
          confirmMessage: (response.data && response.data.message)? response.data.message:'Upload successful',
        });
      })
      .catch((error) => {
        props.setPlanUploadLoading(false);
        setResponse({ 'error': error });
      });
  };

  const uploadWithFormData = (files) => {
    const formData = new FormData();
    const { BrandName, CurrentFF, FinancialYear } = props.selectBrand;
    let fiscalYear = Number(FinancialYear),
      currentFF = getMonth(CurrentFF, 'monthNumber');
    formData.append('file', files[0]);
    formData.append('fiscalYear', fiscalYear);
    formData.append('brand', BrandName);
    formData.append('currentFF', (currentFF > 9 ? '' : '0') + currentFF);
    formData.append('dataModelId', props.page.pageLevelDataModel);
    formData.append('scenario', props?.selectScenario || '01')
    submitForm("multipart/form-data", formData, (res) => {
      props.handleSave();
    });
  };

  const handleSave = (files) => {
    setState({
      files: files,
    });
    uploadWithFormData(files);
  };

  const handleOpen = (open) => {
    setState({
      [open]: true,
    });
  };

  const confirmHandler = () => {
    setConfirm({ ...confirmState, isOpen: false, confirmMessage: '' });
  };

  let acceptedFiles = props.acceptedFiles
    ? props.acceptedFiles
    : ['application/vnd.ms-excel', 'text/csv'];

  return (<>
    <Button onClick={e => handleOpen('open')} size="large" color="primary" variant="contained"
            startIcon={<CloudUploadIcon/>} className="m-right-10">
      {'Upload'}
    </Button>
    <DropzoneDialog
      open={state.open}
      onSave={(files) => {
        handleClose('open');
        handleSave(files);
      }}
      acceptedFiles={acceptedFiles}
      showPreviews={true}
      maxFileSize={2000000}
      filesLimit={1}
      onClose={e => handleClose('open')}
    />
    { props.planUploadLoading && <UploadLoading />}

    <PlanUploadConfirmDialog open={confirmState.isOpen} handleClose={confirmHandler}
                             alertMessageText={confirmState.confirmMessage} classes={classes} />
  </>);
};

const mapDispatchToProps = {
  setPlanUploadLoading,
};
const mapStateToProps = (state) => {
  return {
    selectBrand: state.tprReducer.selectBrand,
    planUploadLoading: state.tprReducer.planUploadLoading,
    selectScenario: state.tprReducer?.selectScenario
  };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadPlan)));
