import React from 'react';
import DropDown from '../Select/DropDown';
import Input from '../Input/Input';
import DeleteIcon from '@material-ui/icons/Delete';

function Group(props) {
  const { group, onDropDownSelect, keyName, pIndex, isDeleteShow, onDeleteHandler } = props;
//   console.log(props, 'here');
  return (
    <>
      {group.map((item, index) => (
        <React.Fragment key={index}>
          {item.type === 'null' ? (
            <>
              <div className="measure-select" style={{ height: '2.90em' }}></div>
            </>
          ) : (
            <div className="measure-select">
              {item.options ? (
                <DropDown
                  value={item.value}
                  options={item.options}
                  labelName={item.text}
                  onDropDownSelect={onDropDownSelect}
                  keyName={keyName}
                  keyIndex={index}
                  pIndex={pIndex}
                />
              ) : (
                <Input
                  value={item.value}
                  onDropDownSelect={onDropDownSelect}
                  keyName={keyName}
                  keyIndex={index}
                  pIndex={pIndex}
                />
              )}
              {/* {isDeleteShow && index >= 5 ? (
                <div className="measure-select-rowDelete">
                  <DeleteIcon
                    style={{ color: '#fff' }}
                    onClick={() => {
                      onDeleteHandler('row', item.id);
                    }}
                  />
                </div>
              ) : null} */}
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

export default Group;
