import MomentUtils from "@date-io/moment";
import {
    Checkbox, MenuItem, Paper, Select, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination, TableRow, Tooltip
} from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import axios from 'axios';
import classNames from 'classnames';
import FileDownload from "js-file-download";
import * as mathjs from "mathjs";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getMonth } from 'utility/utils';
import { generateGUID } from '../../../../modules/page/page.module.js';
import { deleteMRData, getMrData, removeMRSuccessMessage, saveMrData, updateMRData } from '../../../../store/actions/index.js';
import { capitalize, formulaCalculation, getFileNameWithTimeStamp } from '../../../../utility/utils.js';
import Loader from '../../../Loader/index';
import { convertToNumber } from '../../spendplan/PlanningPages/planningHelperFunctions';
import {
    renderNoDataTextMR,
    setSticky,
    StyledTableRow
} from '../../spendplan/PlanningPages/planningHelperFunctions.js';
import ConfirmDialog from '../../spendplan/SpendPlan/ConfirmDialog.js';
import { getFiscalYearFormat } from '../../spendplan/spendplanUtils';
import { sortOrder, URLs } from '../ApiConstants.js';
import MRPlanUploadConfirmDialog from '../planingPages/Headers/planUploadConfirmDialog';
import ForeCastModal from './foreCastModal';
import { FilterType } from './Headers/headerFilters.js';
import MRPlanHeader, { ActionType } from './Headers/index';
import {
    getFilterFields, getNonPromoHeading, getUoMByType, headingsLabel, KBDTypeValues, Msgs, newPlanJson, planningData, rowDropdownFields,
    shortMonthLowercase, TypeValues
} from './planConfig';
import { useStyles } from './styles.js';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
let DROPDOWN_DATA = null;
const MRPlanning = props => {
    const classes = useStyles();
    const appid = props.match.params.appid;
    const [open, setOpen] = useState(false);
    const [month, setMonth] = useState('');
    const [brand, setBrand] = useState('');
    const [brandform, setBrandform] = useState('');
    const [sku, setSku] = useState('');
    const [type, setType] = useState('');
    const [kbdType, setKbdType] = useState('');
    // const [period, setPeriod] = useState(undefined);
    const [uom, setUom] = useState(undefined);
    const [inrFilterVal, setInrFilterVal] = useState(undefined);

    const [pageCount, setPageCount] = useState(0);
    const [fieldData, setFieldData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const [rowSelected, setRowSelected] = useState('');
    const [saveButtonState, setSaveButton] = useState(false);
    const [alertMessageText, setAlertMessage] = useState('');
    const [saveBtnDisabled, disableSaveBtn] = useState(false)
    const [checkedRows, setCheckedRows] = useState([]);
    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: 'Are you sure you want to delete the selected row(s)?',
    });
    const [confirmState, setConfirm] = useState({ isOpen: false, confirmMessage: '' });

    const [foreCastModel, setForeCastModel] = useState(null);
    const [rowDropdownData, setRowDropdownData] = useState(null)
    const { modalData } = props;
    DROPDOWN_DATA = props.data ? props.data['ROW_dropdown' + props.type]?.data : null;

    const getFilters = (forApiCall) => {
        let allFilters = {
            promo: ["brand", "brandform", "sku", "month"],
            nonPromo: forApiCall ? ["brand", "brandform", "sku"] : ["brand", "brandform", "sku", "inr"],
            spendsPlanning: ['brand', "type", "brandform", "uom"],
            earningsTFI: ["kbdType", "brand", "brandform", "sku", "earningOn"] // removing period filter
        }
        return allFilters[props.type]
    }

    useEffect(() => {
        setSticky();
    });

    useEffect(() => {
        getFilterData()
    }, [props.account])

    useEffect(() => {
        getTableData()
    }, [props.account, month, currentPage, rowsPerPage, brand, brandform, sku, type, kbdType, type, uom, inrFilterVal])

    useEffect(() => {
        if (props.account) {
            if (['earningsTFI', 'spendsPlanning'].includes(props.type)) { //TODO: need to move it into constants to avoid errors
                getSKUMasterDataForDropdown()
            }
        }
    }, [props.account])

    const getSKUMasterDataForDropdown = () => {
        let dataModelId = '617f826eb336d60019d00226'; // TODO: need to make it configurable
        if (props.account) {
            let targetURL = "/dataconnect/table/" + dataModelId;
            targetURL += `?type=list`;
            targetURL += '&page=0' + '&rowsPerPage=all';
            targetURL += '&sum' + 'totalMR';
            targetURL += '&fields=' + rowDropdownFields[props.type];
            if (props.type === 'earningsTFI') {
                targetURL += '&sortOrder=' + JSON.stringify({ brand: 1, brandform: 1, sku: 1 });
            } else {
                targetURL += '&sortOrder=' + JSON.stringify({ brand: 1, brandform: 1, sku: 1 });
            }
            let payload = {
                url: targetURL,
                appId: appid,
                dataUniqueId: 'ROW_dropdown' + props.type
            }
            props.getMrData(payload)
        }
    }


    const getFilterData = (fields = getFilters(true).join(','), _conditions) => {
        if (!fields || fields.length === 0) {
            return;
        }
        let url = '/dataconnect/filter/' + props.page?.pageLevelDataModel + '?type=chart&label=' + fields;

        let conditions = getCommonConditions();
        if (_conditions?.length) {
            conditions = [...conditions, ..._conditions]
        }
        if (conditions?.length) {
            url += '&conditions=' + (conditions?.length ? JSON.stringify(conditions) : []);
        }
        let payload = {
            url: url,
            appId: props.match.params.appid,
            dataUniqueId: 'GET_FILTER_' + props.type,
            isMerge: true,
        }
        props.getMrData(payload) // true means merge data;
    }

    const getTableData = () => {
        if (props.account) {
            setSaveButton(false);
            setCheckedRows([])
            if (props.account && (props.type === 'promo' || props.type === 'nonPromo' || props.type === 'earningsTFI')) {
                fetchData()
                setRowSelected('');
            } else {
                getApiData(currentPage, rowsPerPage);
            }
        }
    }

    const getCommonConditions = () => {
        let m = '' + getMonth(props?.account?.CurrentFF, 'monthNumber')
        let currentFF = m.length === 1 ? ('0' + m) : m;
        return [{
            "selectedConditionDataElement": 'account',
            "selectedConditionOperator": "matches",
            "value": "" + encodeURIComponent(modalData?.account || props.account?.AccountName),
            "selectedConditionClause": "and"
        }, {
            "selectedConditionDataElement": 'fiscalYear',
            "selectedConditionOperator": "matches",
            "value": "" + Number(modalData?.year || props.account?.FinancialYear),
            "selectedConditionClause": "and"
        }, {
            "selectedConditionDataElement": 'currentFF',
            "selectedConditionOperator": "matches",
            "value": modalData?.month || currentFF,
            "selectedConditionClause": "and"
        }]
    }

    const getURL = (page, rowsPerPage, isDownloadUrl) => {
        let targetURL = "/dataconnect/" + (isDownloadUrl ? 'download/' : 'table/') + props?.page?.pageLevelDataModel;
        targetURL += `?type=list&`;
        targetURL += '&page=' + page || 0;
        if (rowsPerPage) {
            targetURL += '&rowsPerPage=' + rowsPerPage;
        }
        targetURL += '&fields=';
        let conditions = getCommonConditions();
        if (brandform) {
            conditions.push({
                "selectedConditionDataElement": 'brandform',
                "selectedConditionOperator": "is_equal_to",
                "value": encodeURIComponent(brandform),
                "selectedConditionClause": "and"
            })
        }
        if (brand) {
            conditions.push({
                "selectedConditionDataElement": 'brand',
                "selectedConditionOperator": "is_equal_to",
                "value": encodeURIComponent(brand),
                "selectedConditionClause": "and"
            })
        }
        if (type) {
            conditions.push({
                "selectedConditionDataElement": 'type',
                "selectedConditionOperator": "is_equal_to",
                "value": encodeURIComponent(type),
                "selectedConditionClause": "and"
            })
        }
        // if (period) {
        //     conditions.push({
        //         "selectedConditionDataElement": 'period',
        //         "selectedConditionOperator": "is_equal_to",
        //         "value": encodeURIComponent(period),
        //         "selectedConditionClause": "and"
        //     })
        // }
        if (sku) {
            conditions.push({
                "selectedConditionDataElement": 'sku',
                "selectedConditionOperator": "is_equal_to",
                "value": encodeURIComponent(sku),
                "selectedConditionClause": "and"
            })
        }
        if (uom) {
            conditions.push({
                "selectedConditionDataElement": 'uom',
                "selectedConditionOperator": "is_equal_to",
                "value": encodeURIComponent(uom),
                "selectedConditionClause": "and"
            })
        }
        targetURL += '&conditions=' + JSON.stringify(conditions);
        targetURL += `&sortOrder=${encodeURIComponent(JSON.stringify(sortOrder[props.type]))}`;
        return targetURL
    }

    const getApiData = (page, rowsPerPage) => {
        if (props.account) {
            let payload = {
                url: getURL(page, rowsPerPage),
                appId: appid,
                dataUniqueId: 'GET_' + props.type
            }
            props.getMrData(payload)

        }
    }

    let deleteData = props.data ? props.data['DELETE_' + props.type] : null
    useEffect(() => {
        if (deleteData?.message) {
            setAlertMessage({
                title: Msgs[props.type],
                p1: deleteData?.message,
            });
            handleClickOpen();
        }
    }, [deleteData]);

    let isError = props.mrData?.error ? props.mrData.error['GET_' + props.type] : null;
    useEffect(() => {
        if (isError) {
            setAlertMessage({
                title: Msgs[props.type],
                p1: isError.message || isError.response?.error,
            });
            handleClickOpen();
        }
    }, [isError]);

    let saveSuccess = props.data ? props.data['POST_' + props.type] : null;
    let forecastSuccess = props.data ? props.data['PUT_FORECAST_' + props.type] : null;

    useEffect(() => {
        if (saveSuccess) {
            setAlertMessage(saveSuccess.result.data ? saveSuccess.result.data : saveSuccess.result)
            handleClickOpen();
            getTableData()
            getFilterData()

        }
        if (forecastSuccess) {
            let msg = forecastSuccess?.result?.data?.message || 'Updated successfully';
            setAlertMessage({ title: 'Value Forecast - Save', p1: msg })
            handleClickOpen();
            getTableData()
        }
    }, [saveSuccess, forecastSuccess]);

    useEffect(() => {
        if (props.data && props.data['GET_' + props.type] && props.data['GET_' + props.type].result) {
            let planData = props.data['GET_' + props.type].result?.data;
            let total = props.data['GET_' + props.type].result.total;
            if (props.type === 'earningsTFI') {
                let _planData = JSON.parse(JSON.stringify(planData));
                planData = _planData.map(i => {
                    shortMonthLowercase.forEach(m => {
                        if (i.tfiEarningsPerc && i.tfiEarningsPerc[m]) {
                            i.tfiEarningsPerc[m] = convertToNumber(i.tfiEarningsPerc[m] * 100, 2)
                        }
                    })
                    return i
                })
            }
            setPageCount(total);
            setFieldData(planData)
        } else if (props.data && props.data['GET_' + props.type]) {
            let planData = props.data['GET_' + props.type].data;
            let total = props.data['GET_' + props.type].total;
            if (props.type === 'spendsPlanning') {
                let _planData = JSON.parse(JSON.stringify(planData));
                planData = _planData.map(i => {
                    shortMonthLowercase.forEach(m => {
                        if (i.uom?.includes('%')) {
                            i.months[m] = convertToNumber(i.months[m] * 100, 2)
                        }
                    })
                    return i
                })
            }
            setPageCount(total);
            setFieldData(planData)
        }
    }, [props.data]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (deleteData?.message) {
            props.removeMRSuccessMessage({ dataUniqueId: 'DELETE_' + props.type });
            getTableData()
            getFilterData()
            setCheckedRows([])
        } else if (saveSuccess) {
            props.removeMRSuccessMessage({ dataUniqueId: 'POST_' + props.type });
        } else if (forecastSuccess) {
            props.removeMRSuccessMessage({ dataUniqueId: 'PUT_FORECAST_' + props.type });
        } else {
            props.removeMRSuccessMessage({ dataUniqueId: 'GET_' + props.type });
        }

    };

    const handleFieldChange = (target, index, column, subKey) => {
        const { name, value } = target;
        setSaveButton(true);
        saveBtnDisabled && disableSaveBtn(false);
        let oldData = JSON.parse(JSON.stringify(fieldData));
        if (oldData && oldData[index]) {
            if (!subKey) {
                oldData[index][column] = value;
                if (props.type === 'promo' && column === 'retailingForecast') {
                    oldData[index]['offtakeForecast'] = value;
                    oldData[index]['offtakeActuals'] = value;
                }
                if (props.type === 'spendsPlanning' && name === 'brand') {
                    let _rowDropDownData = { ...rowDropdownData };
                    _rowDropDownData[oldData[index]['_id']] = {
                        ..._rowDropDownData[oldData[index]['_id']],
                        _brandform: DROPDOWN_DATA ? ['ALL', ...new Set(DROPDOWN_DATA.filter(i => i.brand === value).map(i => i.brandform))] : [],
                    }
                    setRowDropdownData(_rowDropDownData)
                }
                if (props.type === 'earningsTFI' && name === 'brand') {
                    oldData[index]['brandform'] = ''
                    oldData[index]['sku'] = ''
                    if (value === '') {
                        let _rowDropDownData = { ...rowDropdownData };
                        _rowDropDownData[oldData[index]['_id']] = {
                            ..._rowDropDownData[oldData[index]['_id']],
                            _brandform: [],
                            _sku: [],
                        }
                        setRowDropdownData(_rowDropDownData)
                    } else {
                        let _rowDropDownData = { ...rowDropdownData };
                        _rowDropDownData[oldData[index]['_id']] = {
                            ..._rowDropDownData[oldData[index]['_id']],
                            _brandform: DROPDOWN_DATA ? [...new Set(DROPDOWN_DATA.filter(i => i.brand === value).map(i => i.brandform))] : [],
                        }
                        setRowDropdownData(_rowDropDownData)
                    }
                }
                if (props.type === 'earningsTFI' && name === 'brandform') {
                    oldData[index]['sku'] = ''
                    if (value === '') {
                        let _rowDropDownData = { ...rowDropdownData };
                        _rowDropDownData[oldData[index]['_id']] = {
                            ..._rowDropDownData[oldData[index]['_id']],
                            _sku: [],
                        }
                        setRowDropdownData(_rowDropDownData)
                    } else {
                        let conditions = oldData[index]['brand']
                        if (conditions) {
                            let allSKUs = DROPDOWN_DATA ? [...new Set(DROPDOWN_DATA.filter(i => i.brand === conditions && i.brandform === value).map(i => i.sku))] : []
                            let _rowDropDownData = { ...rowDropdownData };
                            _rowDropDownData[oldData[index]['_id']] = {
                                ..._rowDropDownData[oldData[index]['_id']],
                                _sku: ['ALL', ...allSKUs],
                            }
                            setRowDropdownData(_rowDropDownData)
                        }
                    }
                }
                if (props.type === 'spendsPlanning' && name === 'type') {
                    if (value === '') {
                        oldData[index]['uom'] = ''
                    } else {
                        oldData[index]['uom'] = getUoMByType(props.account.Format1, value) || ''
                    }
                }

            } else {
                oldData[index][column][subKey] = value;
            }
            setFieldData([...oldData]);
        }

    };

    const displayError = (field, rowNo) => {
        setAlertMessage({
            title: Msgs[props.type] + ' - Mandatory Field',
            p1: `${field} field is mandatory for a plan`,
            p2: `Please check row number ${rowNo}`,
        });
        handleClickOpen();
    }

    const validate = () => {
        if (props.type === 'spendsPlanning' || props.type === 'earningsTFI') {
            let isFound = fieldData.find((item, index) => {
                if (props.type === 'earningsTFI') {
                    if (item.format.length === 0) {
                        displayError('Format', index + 1)
                        return item;
                    }
                    if (item.kbdType.length === 0) {
                        displayError('KBD Type', index + 1)
                        return item;
                    }
                }
                if (item.brand.length === 0) {
                    displayError('Brand', index + 1)
                    return item;
                }
                if (props.type === 'spendsPlanning') {
                    if (item.type.length === 0) {
                        displayError('Type', index + 1)
                        return item;
                    }
                    if (item.uom.length === 0) {
                        displayError('UOM', index + 1)
                        return item;
                    }
                }
                if (props.type === 'earningsTFI') {
                    if (item.brandform.length === 0) {
                        displayError('Brandform', index + 1)
                        return item;
                    }
                    if (item.sku.length === 0) {
                        displayError('SKU', index + 1)
                        return item;
                    }
                }
            })
            if (isFound) {
                return false;
            }
        }
        return true;
    }

    const saveButtonHandler = () => {
        let isValidated = validate()
        if (isValidated) {
            let dataToSend = JSON.parse(JSON.stringify(fieldData));
            dataToSend.forEach(element => {
                if (element.editable) {
                    delete element.editable;
                }
                if (element._kbdType) {
                    delete element._kbdType;
                }
                if (element._brand) {
                    delete element._brand;
                }
                if (element._brandform) {
                    delete element._brandform;
                }
                if (element._sku) {
                    delete element._sku;
                }
                if (element._type) {
                    delete element._type;
                }
                if (element._id.startsWith('newplan_')) {
                    delete element._id
                }
                if (props.type === 'earningsTFI') {
                    shortMonthLowercase.forEach(m => {
                        if (element.tfiEarningsPerc && element.tfiEarningsPerc[m]) {
                            element.tfiEarningsPerc[m] = element.tfiEarningsPerc[m] / 100
                        }
                    })
                }
                if (props.type === 'spendsPlanning') {
                    shortMonthLowercase.forEach(m => {
                        if (element.uom?.includes('%')) {
                            element.months[m] = element.months[m] / 100
                        }
                    })
                }
            });
            let formData = {
                url: '/mrspendplan/upload/' + props.page?.pageLevelDataModel,
                appId: appid,
                dataUniqueId: 'POST_' + props.type,
                payload: dataToSend
            }
            props.updateMRData(formData)
            disableSaveBtn(true)
        }
    };

    const fetchData = () => {
        const account = props.account;
        if (account) {
            let objToSend = {
                rowsPerPage: inrFilterVal || rowsPerPage,
                page: currentPage,
                fiscalYear: modalData?.year || account?.FinancialYear,
                account: encodeURIComponent(modalData?.account || account?.AccountName),
            };
            let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
            objToSend.currentFF = modalData?.month || (m.length === 1 ? '0' + m : m);
            getDataFromApi(objToSend);
        }
    };
    //TODO: need to break this files into parts
    const getDataFromApi = (params) => {
        let url;
        if (props.type === 'promo') {
            url = URLs.getPromoData;
        } else if (props.type === 'nonPromo') {
            url = URLs.getNonPromoData;
        } else if (props.type === 'earningsTFI') {
            url = URLs.getEarningsTFI;
        }
        url += '?' + new URLSearchParams(params).toString();
        if (month) {
            url += '&month=' + month;
        }
        if (brand) {
            url += '&brand=' + encodeURIComponent(brand);
        }
        if (brandform) {
            url += '&brandform=' + encodeURIComponent(brandform);
        }
        if (sku) {
            url += '&sku=' + encodeURIComponent(sku);
        }
        if (props.type === 'earningsTFI') {
            if (kbdType) {
                url += '&kbdType=' + encodeURIComponent(kbdType)
            }
        }
        if (inrFilterVal) {
            url += '&sortByINRTotal=' + inrFilterVal;
        }
        url += `&sortOrder=${encodeURIComponent(JSON.stringify(sortOrder[props.type]))}`;

        let payload = {
            url: url,
            appId: appid,
            dataUniqueId: 'GET_' + props.type
        }
        props.getMrData(payload)
    }

    const downloadButtonHandler = () => {
        let uri;
        const account = props.account;
        let params = {}
        if (account) {
            let objToSend = {
                fiscalYear: account?.FinancialYear,
                account: account?.AccountName,
                rowsPerPage: props.data ? (props.data['GET_' + props.type]?.result?.total || 10000) : 10000
            };
            let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
            objToSend.currentFF = m.length === 1 ? '0' + m : m;
            params = objToSend;
            if (props.type === 'promo' || props.type === 'nonPromo') {
                uri = URLs.download[props.type]
                uri += `?account=${params?.account}&fiscalYear=${params?.fiscalYear}&currentFF=${params?.currentFF}&page=0&rowsPerPage=${params?.rowsPerPage}`;
                if (month) {
                    uri += '&month=' + month;
                }
                if (brand) {
                    uri += '&brand=' + encodeURIComponent(brand);
                }
                if (brandform) {
                    uri += '&brandform=' + encodeURIComponent(brandform);
                }
                if (sku) {
                    uri += '&sku=' + encodeURIComponent(sku);
                }
                if (uom) {
                    uri += '&uom=' + encodeURIComponent(uom);
                }
                if (inrFilterVal) {
                    uri += '&sortByINRTotal=' + inrFilterVal;
                }
                uri += `&sortOrder=${encodeURIComponent(JSON.stringify(sortOrder[props.type]))}`;

            } else if (props?.type === 'earningsTFI') {
                uri = URLs.download[props?.type]
                uri += `?account=${params?.account}&fiscalYear=${params?.fiscalYear}&currentFF=${params?.currentFF}`
                if (brand) {
                    uri += '&brand=' + encodeURIComponent(brand);
                }
                if (brandform) {
                    uri += '&brandform=' + encodeURIComponent(brandform);
                }
                if (sku) {
                    uri += '&sku=' + encodeURIComponent(sku);
                }
                if (kbdType) {
                    uri += '&kbdType=' + kbdType;
                }
                uri += `&format=${props.account.Format1}&sortOrder=${encodeURIComponent(JSON.stringify(sortOrder[props?.type]))}`;
            } else {
                let _allRows = props.data ? (props.data['GET_' + props.type]?.total || 1000) : 1000;
                uri = getURL(0, _allRows, true);
            }
            axios({
                url: API_BASE_URL + uri,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    appId: props.match.params.appid
                },
            }).then((response) => {
                let fyFF = getFiscalYearFormat(account.FinancialYear) + '-' + moment().month(account.CurrentFF).format('MMM');
                FileDownload(response.data, getFileNameWithTimeStamp(props.page.name + '-' + params.account + '-' + fyFF));
            }).catch((error) => {
                console.log(error);
            })
        }
    }

    const selectRow = (index) => {
        setRowSelected(rowSelected === index ? '' : index);
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const onCheckHandler = (row, index) => {
        let newCheckedRows = JSON.parse(JSON.stringify(checkedRows));
        if (!newCheckedRows.includes(row)) {
            newCheckedRows.push(row);
        } else {
            newCheckedRows.splice(newCheckedRows.indexOf(row), 1);
        }
        setCheckedRows(newCheckedRows);
    };

    const onDeleteRecords = () => {
        if (checkedRows?.length) {
            setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: 'Are you sure you want to delete the selected row(s)?' })
        } else {
            setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: 'Are you sure you want to delete the all row(s)?' })
        }
    }


    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            const account = props.account;
            let url;
            if (props.type === 'promo') {
                url = URLs.deletePromoData;
            } else if (props.type === 'nonPromo') {
                url = URLs.deleteNonPromoData;
            } else if (props.type === 'earningsTFI' || props.type === 'spendsPlanning') {
                url = URLs.deletePlanning + props.page.pageLevelDataModel;
            }
            let index = checkedRows.findIndex(i => i.startsWith('newplan'));
            let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
            let payload = {
                dataUniqueId: 'DELETE_' + props.type,
                url: url,
                appid,
                data: {
                    ids: checkedRows,
                    fiscalYear: `${account?.FinancialYear}`,
                    account: `${account?.AccountName}`,
                    currentFF: m.length === 1 ? '0' + m : m
                },
            }
            if (index !== -1) {
                let newPlanIds = checkedRows.filter(i => i.startsWith('newplan'));
                let _fieldData = fieldData.filter(i => !newPlanIds.includes(i._id));
                setFieldData(_fieldData);
                if (checkedRows.length > newPlanIds.length) {
                    let restIds = checkedRows.filter(i => !newPlanIds.includes(i));
                    payload.data = { ids: restIds }
                    props.deleteMRData(payload);
                }
            } else {
                props.deleteMRData(payload);
            }
            setDeleteConfirm({ ...deleteConfirmState, isOpen: false });
        };
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false });
    }

    const createNewPlanPress = () => {
        var startTime = performance.now()

        let oldData = JSON.parse(JSON.stringify(fieldData));
        let m = '' + getMonth(props.account?.CurrentFF, 'monthNumber')
        let currentFF = m.length === 1 ? '0' + m : m;
        let dataToAdd = newPlanJson[props.type];
        dataToAdd['account'] = props.account?.AccountName;
        dataToAdd['formType'] = props.type;
        dataToAdd['currentFF'] = currentFF;
        dataToAdd['fiscalYear'] = Number(props.account?.FinancialYear) + "";
        dataToAdd['_id'] = 'newplan_' + generateGUID();
        if (kbdType) {
            dataToAdd['kbdType'] = kbdType;
        }
        if (brand) {
            dataToAdd['brand'] = brand;
        }
        if (brandform) {
            dataToAdd['brandform'] = brandform;
        }
        if (sku) {
            dataToAdd['sku'] = sku;
        }
        if (type) {
            dataToAdd['type'] = type;
        }
        // if (period) {
        //     dataToAdd['period'] = period;
        // }
        if (props.type === 'earningsTFI') {
            dataToAdd['format'] = props.account.Format1;
            let _rowDownData = rowDropdownData ? { ...rowDropdownData } : {};
            _rowDownData[dataToAdd['_id']] = {
                _brand: DROPDOWN_DATA ? [...new Set(DROPDOWN_DATA.map(i => i.brand))] : [],
                _kbdType: KBDTypeValues,
            }
            setRowDropdownData(_rowDownData);
        }
        if (props.type === 'spendsPlanning') {
            let _rowDownData = rowDropdownData ? { ...rowDropdownData } : {};
            _rowDownData[dataToAdd['_id']] = {
                _brand: DROPDOWN_DATA ? [...new Set(DROPDOWN_DATA.map(i => i.brand))] : [],
                _type: TypeValues(props.account.Format1),
            }
            setRowDropdownData(_rowDownData);
        }
        oldData = [dataToAdd, ...oldData];
        setFieldData(oldData);
        setRowSelected(0);
        setSaveButton(true);
        saveBtnDisabled && disableSaveBtn(false);
        var endTime = performance.now()
        console.log(`CreateNewPlan took ${endTime - startTime} milliseconds`)

    }

    const onEdit = () => {
        let record = { ...fieldData[rowSelected] };
        let _rowDropDownData = { ...rowDropdownData };
        if (props.type === 'earningsTFI') {
            let allSKUs = DROPDOWN_DATA ? ['ALL', ...new Set(DROPDOWN_DATA.filter(i => i.brand === record['brand'] &&
                i.brandform === record['brandform']).map(i => i.sku))] : []
            _rowDropDownData[record._id] = { _sku: allSKUs }
            setRowDropdownData(_rowDropDownData)
            record['_sku'] = true;
            let _fieldData = [...fieldData];
            _fieldData[rowSelected] = record;
            setFieldData(_fieldData)
        }
        if (props.type === 'spendsPlanning') {
            let record = { ...fieldData[rowSelected] };
            let _rowDropDownData = { ...rowDropdownData };
            let allBrandforms = DROPDOWN_DATA ? ['ALL', ...new Set(DROPDOWN_DATA.filter(i => i.brand === record['brand']).map(i => i.brandform))] : []
            _rowDropDownData[record._id] = { _brandform: allBrandforms }
            setRowDropdownData(_rowDropDownData)
            record['_brandform'] = true;
            let _fieldData = [...fieldData];
            _fieldData[rowSelected] = record;
            setFieldData(_fieldData)
        }
    }

    const onDoneUpdateClick = () => {
        setConfirm({
            ...confirmState, isOpen: true,
            confirmMessage: `${props.page.name} data update done for  ${getMonth(props.account?.CurrentFF, 'month')} ${getFiscalYearFormat(props.account?.FinancialYear)}?`,
        });
    }

    const onAction = (actionType) => {
        switch (actionType) {
            case ActionType.Save:
                saveButtonHandler();
                break;
            case ActionType.NewPlan:
                createNewPlanPress();
                break;
            case ActionType.DoneUpdates:
                onDoneUpdateClick();
                break;
            case ActionType.Delete:
                onDeleteRecords();
                break;
            case ActionType.Download:
                downloadButtonHandler();
                break;
            case ActionType.Upload:
                getTableData()
                getFilterData()
                break;
            case ActionType.Edit:
                onEdit()
                break;
        }
    };

    const onFilterChange = (filterType, { value }) => {
        let _conditions = [];
        switch (filterType) {
            case FilterType.Month:
                if (month !== value) {
                    setMonth(value);
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (month) {
                        _conditions.push({
                            "selectedConditionDataElement": 'month',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(value),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.KBDType:
                if (kbdType !== value) {
                    setKbdType(value);
                    setBrand('');
                    setBrandform('');
                    setSku('')
                    setType('')
                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    break;
                }
            case FilterType.Brand:
                if (brand !== value) {
                    setBrand(value);
                    setBrandform('');
                    setSku('')
                    setType('')
                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (kbdType) {
                        _conditions.push({
                            "selectedConditionDataElement": 'kbdType',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(kbdType),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.Brandform:
                if (brandform !== value) {
                    setBrandform(value);
                    setSku('')
                    setType('')
                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (kbdType) {
                        _conditions.push({
                            "selectedConditionDataElement": 'kbdType',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(kbdType),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.SKU:
                if (sku !== value) {
                    setSku(value);
                    setType('')
                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (kbdType) {
                        _conditions.push({
                            "selectedConditionDataElement": 'kbdType',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(kbdType),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.Type: {
                if (type !== value) {
                    setType(value);
                    if (props.type === 'spendsPlanning') {
                        setBrandform('')
                    }
                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (kbdType) {
                        _conditions.push({
                            "selectedConditionDataElement": 'kbdType',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(kbdType),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (sku) {
                        _conditions.push({
                            "selectedConditionDataElement": 'sku',
                            "selectedConditionOperator": "is_equal_to",
                            "value": encodeURIComponent(sku),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            }
            // case FilterType.Period:
            //     if (period !== value) {
            //         setPeriod(value);
            //         setCurrentPage(0);
            //         setRowsPerPage(200);
            //         if (kbdType) {
            //             _conditions.push({
            //                 "selectedConditionDataElement": 'kbdType',
            //                 "selectedConditionOperator": "is_equal_to",
            //                 "value": encodeURIComponent(kbdType),
            //                 "selectedConditionClause": "and"
            //             })
            //         }
            //         if (brand) {
            //             _conditions.push({
            //                 "selectedConditionDataElement": 'brand',
            //                 "selectedConditionOperator": "is_equal_to",
            //                 "value": encodeURIComponent(brand),
            //                 "selectedConditionClause": "and"
            //             })
            //         }
            //         if (brandform) {
            //             _conditions.push({
            //                 "selectedConditionDataElement": 'brandform',
            //                 "selectedConditionOperator": "is_equal_to",
            //                 "value": encodeURIComponent(brandform),
            //                 "selectedConditionClause": "and"
            //             })
            //         }
            //         if (sku) {
            //             _conditions.push({
            //                 "selectedConditionDataElement": 'sku',
            //                 "selectedConditionOperator": "is_equal_to",
            //                 "value": encodeURIComponent(sku),
            //                 "selectedConditionClause": "and"
            //             })
            //         }
            //         if (type) {
            //             _conditions.push({
            //                 "selectedConditionDataElement": 'type',
            //                 "selectedConditionOperator": "is_equal_to",
            //                 "value": encodeURIComponent(type),
            //                 "selectedConditionClause": "and"
            //             })
            //         }
            //         break;
            //     }
            case FilterType.UOM:
                if (uom !== value) {
                    setUom(value);
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    break;
                }
            case FilterType.INR:
                if (inrFilterVal !== value) {
                    setInrFilterVal(value);
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    break;
                }
        }

        if ([FilterType.Brand, FilterType.SKU, FilterType.Brandform, FilterType.KBDType, FilterType.Type, FilterType.EarningOn].includes(filterType)) {
            if (value === '') {
                let fields = getFilterFields[props.type][filterType];
                if (!fields.includes(filterType)) {
                    fields.push(filterType)
                }
                getFilterData(fields, _conditions)
            } else if (getFilterFields[props.type][filterType]?.length) {
                _conditions.push({
                    "selectedConditionDataElement": filterType,
                    "selectedConditionOperator": "is_equal_to",
                    "value": encodeURIComponent(value),
                    "selectedConditionClause": "and"
                })
                getFilterData(getFilterFields[props.type][filterType], _conditions)
            }
        }
    };

    const isMonthForecast = (monthKey) => {
        // monthKey: jan, feb ,mar
        if (props.account?.CurrentFF && shortMonthLowercase.indexOf(monthKey) + 1 > shortMonthLowercase.indexOf((moment().month(props.account.CurrentFF).format('MMM').toLowerCase()))) {
            return true;
        }
        return null
    }

    const handleForeCastModalClose = (save, data, lastYearData) => {
        if (save) {
            let m = '' + getMonth(props.account?.CurrentFF, 'monthNumber')
            let currentFF = m.length === 1 ? '0' + m : m;
            let fiscalYear = Number(props.account?.FinancialYear);
            let payload = {
                fiscalYear: Number(props.account?.FinancialYear) + "",
                currentFF: m.length === 1 ? '0' + m : m,
                month: foreCastModel.month,
                forecast: data.foreCast,
                account: props.account?.AccountName,
                data: {
                    actual: {
                        months: data.actualMonths,
                        currentFF: currentFF,
                        fiscalYear: fiscalYear + "",
                    },
                    forecast: {
                        months: data.foreCastMonths,
                        currentFF: "06", // this current ff will be same always
                        fiscalYear: (fiscalYear - 1) + "",
                    }
                },
                lastYear: {
                    actual: {
                        months: lastYearData.actualMonths,
                        currentFF: "06", // this current ff will be same always
                        fiscalYear: (fiscalYear - 1) + "",
                    },
                    forecast: {
                        months: lastYearData.foreCastMonths,
                        currentFF: "06", // this current ff will be same always
                        fiscalYear: (fiscalYear - 2) + "",
                    }
                }
            }
            let url = URLs.saveNonPromoForeCast + "?";
            if (brand) {
                url += '&brand=' + encodeURIComponent(brand)
            }
            if (brandform) {
                url += '&brandform=' + encodeURIComponent(brandform)
            }
            if (sku) {
                url += '&sku=' + encodeURIComponent(sku)
            }
            let formData = {
                url: url,
                appId: appid,
                dataUniqueId: 'PUT_FORECAST_' + props.type,
                payload: payload
            }
            props.updateMRData(formData)
            setForeCastModel({ isOpen: false, heading: null, month: null, monthLabel: null })
        } else {
            setForeCastModel({ isOpen: false, heading: null, month: null, monthLabel: null })
        }

    }

    const onSelectAll = (event, val) => {
        if (val) {
            let newCheckedItems = fieldData.map(i => `${i._id}`)
            setCheckedRows(newCheckedItems);
        } else {
            setCheckedRows([]);
        }
    }

    const getTotal = (key) => {
        let data = props.data ? (props.data['GET_' + props.type]?.result?.sum || props.data['GET_' + props.type]?.totalSum) : null
        if (data) {
            if (typeof (key) === 'string') {
                let keys = key.split('.')
                if (keys.length === 2) {
                    return data[keys[0]] ? (data[keys[0]][keys[1]] || 0) : ''
                }
                return data[key] || 0
            } else {
                key = key.key
                let keys = key.split('.')
                if (keys.length === 2) {
                    return data[keys[0]] ? (data[keys[0]][keys[1]] || 0) : ''
                }
                return data[key] || 0
            }
        }
        return ''
    }

    let headerOrder = 0;

    const isFreezeColumn = (index) => {
        return index < planningData[props.type].freezeColumnUpto
    }

    const isEditableFieldNonPromo = (monthKey) => {
        // monthKey: jan, feb ,mar
        if (props.account && shortMonthLowercase.indexOf(monthKey) + 1 > shortMonthLowercase.indexOf((moment().month(props.account.CurrentFF).format('MMM').toLowerCase()))) {
            return 'input'
        }
        return null
    }

    const getEarningsValue = (row, keys) => {
        if (keys[0] === 'factor') {
            return row[keys[0]][keys[1]]
        }
        if (row[keys[0]]) {
            if (!(keys[0] === 'tfiEarningsPerc')) {
                return Math.round(row[keys[0]][keys[1]])
            }
            return row[keys[0]][keys[1]]
        }
        return null
    }

    const renderSubCell = (row, c, i, rowIndex) => {
        if (typeof i === 'string') {
            let keys = i.split('.');
            if (props.type === 'nonPromo') {
                return renderCellElement(row, row[keys[0]] ? !(keys[0] === 'totalNonPromoRetailing' && isEditableFieldNonPromo(keys[1])) ?
                    Math.round(row[keys[0]][keys[1]]) : row[keys[0]][keys[1]] : null, rowIndex,
                    (keys[0] === 'totalNonPromoRetailing' && isEditableFieldNonPromo(keys[1])) ? 'input' : null, null, keys[0], keys[1], c.field)
            } else if (props.type === 'earningsTFI') {

                return renderCellElement(row, getEarningsValue(row, keys), rowIndex, (keys[0] === 'tfiEarningsPerc') ? 'input' : null, null, keys[0], keys[1], c.field)
            }
        } else if (props.type === 'earningsTFI' && i.formula) {
            return renderCellElement(row, null, rowIndex, 'formula', i.formula)
        }
    }

    const onForecastClick = (month) => {
        setForeCastModel({
            isOpen: true,
            heading: 'Value Forecast for ' + capitalize(month) + " " + getFiscalYearFormat(props.account?.FinancialYear, true).substring(2, 4),
            month: month,
            monthLabel: capitalize(month) + " " + getFiscalYearFormat(props.account?.FinancialYear, true).substring(2, 4),
        })
    }

    const renderCellHeader = (c, i, cIndex) => {
        if (typeof i === 'string') {
            let keys = i.split('.');
            const isNonPromoEditable = () => (keys[0] === 'totalNonPromoRetailing' && isEditableFieldNonPromo(keys[1])) // show value forecast icon on value field
            if (props.type === 'nonPromo') {
                return <>
                    <p className={isNonPromoEditable() ? classes.greenFont : ''} style={props.type === 'nonPromo' ? { height: 35, marginBottom: -5 } : {}}>
                        <span>{headingsLabel[keys[0]] || i.key}</span>
                        {
                            isNonPromoEditable() && <Tooltip title='Value Forecast'>
                                <TrendingUpIcon style={{ cursor: 'pointer', fontSize: 14 }} onClick={() => onForecastClick(c.key)} />
                            </Tooltip>
                        }
                    </p>
                </>
            } else if (props.type === 'earningsTFI') {
                return <> <p className={(keys[0] === 'tfiEarningsPerc') ? classes.greenFont : ''}><span>{headingsLabel[keys[0]] || i.key}</span></p>
                </>
            }
        } else if (props.type === 'earningsTFI' && i.key && c.subKeys) {
            let keys = i.key.split('.');
            return <> <p><span>{headingsLabel[keys[0]]}</span></p>
            </>
        }

    }

    const selectOptions = (type, rowId) => {
        let filterOptions = getFilterOptions();
        if (filterOptions && rowDropdownData && rowDropdownData[rowId]) {
            return React.Children.toArray(
                rowDropdownData[rowId]['_' + type]?.map((item, index) =>
                    <MenuItem value={item} key={index}>{item}</MenuItem>,
                ));
        }
    };

    const renderCellElement = (row, renderVal, index, inputType, formula, column, subKey, fieldType, defaultValue) => {
        if (props.type === 'spendsPlanning' && (row['uom'] !== '$M' && row['uom'] !== 'INR') && column === 'total') {
            return ''
        }
        if (props.type === 'spendsPlanning' && column === 'brandform' && row['type'] !== 'KBD 2 Spend - Discretionary Visibility') {
            return row['brandform']
        }
        if (((row._sku && column === 'sku') || (column === 'brandform' && row._brandform)) || row.editable) { //edit click functionality
            if (inputType === 'select') {
                return (
                    <Select
                        name={column}
                        value={renderVal}
                        displayEmpty={true}
                        className="filter-container"
                        style={{ minWidth: '100px' }}
                        onChange={(e) => {
                            const { name, value } = e.target;
                            handleFieldChange({ name, value }, index, column, subKey);
                        }}
                    >
                        {defaultValue && <MenuItem value="">{defaultValue}</MenuItem>}
                        {selectOptions(column, row._id)}
                    </Select>
                );
            }

            if (inputType === 'newInput') {
                return (
                    <input
                        step={fieldType === 'number' ? "any" : undefined}
                        type={fieldType || 'text'}
                        className={classes.tableInput}
                        onChange={(e) => {
                            const { name, value } = e.currentTarget;
                            handleFieldChange({ name, value }, index, column, subKey);

                        }}
                        onBlur={(e) => {
                            const { name, value } = e.currentTarget;
                            handleFieldChange({ name, value: (fieldType === 'number' && value === "") ? 0 : value }, index, column, subKey)
                        }}
                        value={renderVal}
                    />)
            }
        }
        if (inputType === 'input') {
            const id = props.type + (subKey ? (column + subKey) : column) + index;
            return (
                <input
                    step={fieldType === 'number' ? "any" : undefined}
                    id={id}
                    type={fieldType || 'text'}
                    className={classes.tableInput}
                    onChange={(e) => {
                        const { name, value } = e.currentTarget;
                        handleFieldChange({ name, value }, index, column, subKey);
                    }}
                    onBlur={(e) => {
                        const { name, value } = e.currentTarget;
                        handleFieldChange({ name, value: (fieldType === 'number' && value === "") ? 0 : value }, index, column, subKey)
                    }}
                    value={fieldType !== 'number' ? renderVal : (document.getElementById(id) ===
                        document.activeElement ? renderVal : convertToNumber(renderVal, 2))}
                />
            )
        } else if (inputType === 'formula') {
            let val = mathjs.evaluate(formulaCalculation(row, formula));
            return <span style={{ alignSelf: 'center', margin: '0 10px' }} title={val}>
                {convertToNumber(val, 1).toLocaleString()}
            </span>
        } else if (inputType === 'customFormula') {
            let isFormula = isMonthForecast(row['month']); // promo retailing value doing rlp*forecast only when month is forecast otherwise showing api value
            if (isFormula) {
                let val = mathjs.evaluate(formulaCalculation(row, formula));
                return <span style={{ alignSelf: 'center', margin: '0 10px' }} title={val}>
                    {convertToNumber(val, 1).toLocaleString(column === 'retailingValue' ? 'en-In' : 'en-US')}
                </span>
            }
        } else if (inputType === 'percent') {
            return <span title={fieldType === 'number' ? renderVal : ''} className={classes.tableCell}>
                {convertToNumber(renderVal * 100, 1).toLocaleString() + '%'}</span>
        } else if (inputType === 'date') {
            let minDate = (column === 'endDate' && row['startDate']) ? moment(row['startDate'], 'DD-MM-YYYY').format('MM-DD-YYYY') : 'Invalid date'
            let maxDate = (column === 'startDate' && row['endDate']) ? moment(row['endDate'], 'DD-MM-YYYY').format('MM-DD-YYYY') : 'Invalid date'
            
            if (!minDate || minDate === 'Invalid date') {
                minDate = '07-01-' + row.fiscalYear;
            }
            if (!maxDate || maxDate === 'Invalid date') {
                maxDate = '06-30-' + (parseInt(row.fiscalYear, 0) + 1)   
            }
            return (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        autoOk={true}
                        format="DD-MM-YYYY"
                        inputValue={renderVal}
                        minDate={minDate !== 'Invalid date' ? minDate : undefined}
                        maxDate={maxDate !== 'Invalid date' ? maxDate : undefined}
                        onChange={(momentObj, value) => handleFieldChange({ name: column, value }, index, column, subKey)}
                        rifmFormatter={(str) => str}
                        value={moment(renderVal)}
                        style={{ minWidth: 140 }}
                        error={false}
                        helperText={null}
                    />
                </MuiPickersUtilsProvider>)
        }
        return <span title={fieldType === 'number' ? renderVal : ''} className={classes.tableCell}>
            {fieldType === 'number' ? convertToNumber(renderVal, 1).toLocaleString((column === 'rlp' || column === 'totalNonPromoRetailing' || column === 'retailingValue') ?
                'en-In' : 'en-US') : renderVal}</span>
    }

    const onConfirmResponse = (resp) => {
        if (resp) {
            finish();
        }
        setConfirm({ isOpen: false, confirmMessage: '' });
    };

    const finish = () => {
        let { AccountName, FinancialYear, CurrentFF } = props.account;
        let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
        m = m.length === 1 ? '0' + m : m;
        let data = {
            'formType': props.type,
            account: AccountName, fiscalYear: FinancialYear, currentFF: m,
            'action': 'finish',
        };
        let url = `${API_BASE_URL}` + URLs.doneUpdates;
        axios({
            url: url,
            method: 'POST',
            data: data,
            headers: {
                appId: props.match.params.appid,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                toast.success(response.data?.result?.message || 'Workflow emails sent successfully');
            })
            .catch((error) => {
                toast.error(error?.data?.message || 'Not able to send workflow emails');
            });
    };

    const getFilterOptions = () => {
        return props.data ? props.data['GET_FILTER_' + props.type] : []
    }

    return (
        <div style={{ marginTop: props.modalData ? 30 : 0 }}>
            <MRPlanHeader
                {...props}
                onAction={onAction}
                onFilterChange={onFilterChange}
                filters={getFilters(false)}
                filterValues={{ brand, brandform, sku, month, type, kbdType, uom, inrFilterVal }}
                saveButtonState={saveButtonState}
                filterData={getFilterOptions()}
                showEditBtn={rowSelected && !fieldData[rowSelected]['_id'].startsWith('newplan')}
                rowSelected={rowSelected}
                saveBtnDisabled={saveBtnDisabled}
            />
            {
                props.mrData && props.mrData['GET_' + props.type + '_loading'] ?
                    <section className={classes.tableBodyLoading}>
                        <Loader />
                    </section> :

                    <React.Fragment>
                        {(props.type === 'promo' || props.type === 'spendsPlanning') && <TableContainer className={classes.tableContainer} component={Paper}>
                            <Table className={classNames(classes.table, 'tpr-table')} aria-label="simple table" stickyHeader>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell data-order={headerOrder++} className={"sticky_column"}>
                                            <Checkbox
                                                disabled={fieldData.length === 0}
                                                onChange={(e, v) => onSelectAll(e, v)}
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                checked={fieldData.length !== 0 ? checkedRows.length === fieldData.length : false}
                                            />
                                        </TableCell>
                                        {
                                            planningData[props.type].columnKeys.map((c, index) => {
                                                if (!c.subKeys) {
                                                    return <TableCell data-order={headerOrder++} style={{ paddingTop: 5, paddingBottom: 5 }}
                                                        className={classNames(c.type === 'input' ? classes.greenFont : '', isFreezeColumn(index) ? "sticky_column" : "")}>
                                                        {
                                                            c.showTotal ? <div style={{ display: 'flex', flexDirection: 'column', height: 60, justifyContent: 'flex-start' }}>
                                                                <span className={classes.totalValue} title={getTotal(c.key)}>{convertToNumber(getTotal(c.key), 0).toLocaleString(c.key === 'retailingValue' ? 'en-In' : 'en-US')}</span>
                                                                <span>{headingsLabel[c.key] || c.key} </span>
                                                            </div> : <span>{headingsLabel[c.key] || c.key} </span>
                                                        }

                                                    </TableCell>
                                                } else {
                                                    return (<TableCell data-order={headerOrder++}
                                                        className={classNames(isFreezeColumn(index) ? "sticky_column" : "")}
                                                        colSpan={c.subKeys.length}>
                                                        <p className={classes.combinedHeaderHeading}>{headingsLabel[c.key] || c.key}</p>
                                                        <section className={classes.combinedHeaderSection}>
                                                            {
                                                                c.subKeys.map((i, cIndex) => {
                                                                    return <><p className={i.key !== 'total' ? classes.greenFont : ''}><span>{headingsLabel[i.key] || i.key}</span></p>
                                                                        {cIndex + 1 !== c.subKeys.length && <div className={classes.divider} />}
                                                                    </>
                                                                })
                                                            }

                                                        </section>
                                                    </TableCell>)
                                                }
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {props.type === "promo" || props.type === "spendsPlanning" ?
                                        fieldData?.map((row, rowIndex) => {
                                            let order = 0;
                                            return (
                                                <StyledTableRow key={rowIndex} onClick={() => selectRow(rowIndex)}
                                                    className={rowSelected === rowIndex ? classes.rowSelectedBg : ''}
                                                >
                                                    <TableCell id={`sticky_body_column_${rowIndex}_${order}`} className={`sticky_body_column`}
                                                        data-order={order++} data-index={rowIndex}>
                                                        <Checkbox
                                                            onChange={() => onCheckHandler(row._id, rowIndex)}
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            checked={checkedRows.includes(row._id)}
                                                        />
                                                    </TableCell>
                                                    {
                                                        planningData[props.type].columnKeys.map((c, colIndex) => {
                                                            if (!c.subKeys) {
                                                                return <TableCell style={{ minWidth: 75, textTransform: c.key === 'month' ? 'capitalize' : '', }}
                                                                    className={isFreezeColumn(colIndex) ? `sticky_body_column ${rowSelected === rowIndex ? classes.rowSelectedBg : ''}` : ""}
                                                                    id={`sticky_body_column_${rowIndex}_${order}`} data-order={order++} data-index={rowIndex}>
                                                                    {renderCellElement(row, row[c.key], rowIndex, c.type, c.formula, c.key, null, c.field, c.defaultValue)}
                                                                </TableCell>
                                                            } else {
                                                                return (<TableCell style={{ minWidth: 75 }}
                                                                    className={classNames(isFreezeColumn(colIndex) ? `sticky_body_column  ${rowSelected === rowIndex ? classes.rowSelectedBg : ''}` : "")}
                                                                    id={`sticky_body_column_${rowIndex}_${order}`} data-order={order++} data-index={rowIndex} colSpan={c.subKeys.length}>
                                                                    <section className={classes.combinedHeaderSection}>
                                                                        {
                                                                            c.subKeys?.map(i => {
                                                                                return <div style={{ width: `${(100 / (c.subKeys.length))}%`, display: 'flex', justifyContent: 'center' }}>
                                                                                    {renderCellElement(row, row[c.key] ? row[c.key][i.key] : null, rowIndex, i.type, i.formula, c.key, i.key, i.field)}
                                                                                </div>
                                                                            })
                                                                        }

                                                                    </section>
                                                                </TableCell>)
                                                            }
                                                        })
                                                    }
                                                </StyledTableRow>
                                            );
                                        }) : null}
                                    {!Boolean(fieldData?.length) &&
                                        renderNoDataTextMR(brand, brandform, sku, month)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        }

                        {(props.type === 'nonPromo' || props.type === 'earningsTFI') && <TableContainer className={classes.tableContainer} component={Paper}>
                            <Table className={classNames(classes.table, 'tpr-table')} aria-label="simple table" stickyHeader>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell data-order={headerOrder++} className={"sticky_column"} rowSpan={3}>
                                            <Checkbox
                                                disabled={fieldData.length === 0}
                                                onChange={(e, v) => onSelectAll(e, v)}
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                checked={fieldData.length !== 0 ? checkedRows.length === fieldData.length : false}
                                            />
                                        </TableCell>
                                        {
                                            planningData[props.type].columnKeys.map((c, index) => {
                                                if (!c.subKeys) {
                                                    return <TableCell rowSpan={3} data-order={headerOrder++} className={classNames(isFreezeColumn(index) ? "sticky_column" : "", c.type === 'date' ? classes.greenFont : '')}>
                                                        <span>{headingsLabel[c.key] || c.key}</span></TableCell>
                                                } else {
                                                    return (
                                                        <>
                                                            {c.subKeys.map((subKey, _ind) => {
                                                                return (
                                                                    <TableCell style={{ minWidth: 75, border: 'none', boxShadow: 'none' }}>
                                                                        <span key={_ind} className={classes.totalValue} title={getTotal(subKey)}>
                                                                            {getTotal(subKey) !== '' ? convertToNumber(getTotal(subKey), 0).toLocaleString(_ind !== 0 ? 'en-In' : 'en-US') : ''}
                                                                        </span>
                                                                    </TableCell>)
                                                            })}
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </TableRow>
                                    <TableRow>
                                        {
                                            planningData[props.type].columnKeys.map((c, index) => {
                                                if (!c.subKeys) {
                                                    return null
                                                } else {
                                                    return (
                                                        <TableCell colSpan={c.subKeys.length} className="sticky_column">
                                                            {
                                                                props.type === 'nonPromo' ? <p className={classes.combinedHeaderHeading}>
                                                                    {index > 2 ? getNonPromoHeading(c.key, (c.lastYear ? getFiscalYearFormat(props.account?.FinancialYear, true) :
                                                                        getFiscalYearFormat(props.account?.FinancialYear))) : headingsLabel[c.key] || c.key}</p> :
                                                                    <p className={classes.combinedHeaderHeading}> {headingsLabel[c.key] || c.key}</p>
                                                            }

                                                        </TableCell>
                                                    )
                                                }
                                            })
                                        }
                                    </TableRow>
                                    <TableRow>
                                        {
                                            planningData[props.type].columnKeys.map((c, index) => {
                                                if (c.subKeys?.length) {
                                                    return c.subKeys.map((i, cIndex) => {
                                                        return <TableCell data-order={headerOrder++} style={{ minWidth: 75, }}
                                                            className={classNames(isFreezeColumn(index) ? "sticky_column" : "")}>
                                                            {
                                                                renderCellHeader(c, i, cIndex)
                                                            }

                                                        </TableCell>
                                                    })
                                                }
                                            })
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {fieldData?.map((row, rowIndex) => {
                                        let order = 0;
                                        return (
                                            <StyledTableRow key={rowIndex} onClick={() => selectRow(rowIndex)}
                                                className={rowSelected === rowIndex ? classes.rowSelectedBg : ''}>
                                                <TableCell id={`sticky_body_column_${rowIndex}_${order}`} className={`sticky_body_column`}
                                                    data-order={order++} data-index={rowIndex}>
                                                    <Checkbox
                                                        onChange={() => onCheckHandler(row._id, rowIndex)}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        checked={checkedRows.includes(row._id)}
                                                    />
                                                </TableCell>

                                                {
                                                    planningData[props.type].columnKeys.map((c, colIndex) => {
                                                        if (!c.subKeys) {
                                                            return <TableCell style={{ minWidth: 75 }}
                                                                className={isFreezeColumn(colIndex) ? `sticky_body_column  ${rowSelected === rowIndex ? classes.rowSelectedBg : ''}` : ""}
                                                                id={`sticky_body_column_${rowIndex}_${order}`} data-order={order++} data-index={rowIndex}>
                                                                {renderCellElement(row, row[c.key], rowIndex, c.type, null, c.key, null, c.field, c.defaultValue)}
                                                            </TableCell>
                                                        } else {
                                                            return (
                                                                <>
                                                                    {
                                                                        c.subKeys?.map(i => {

                                                                            return <TableCell className={classNames(isFreezeColumn(colIndex) ? `sticky_body_column` : "")}
                                                                                style={{ minWidth: 75 }}
                                                                                id={`sticky_body_column_${rowIndex}_${order}`} data-order={order++} data-index={rowIndex} >
                                                                                {
                                                                                    renderSubCell(row, c, i, rowIndex)
                                                                                }
                                                                            </TableCell>

                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    })
                                                }
                                            </StyledTableRow>
                                        );
                                    })
                                    }
                                    {!Boolean(fieldData?.length) &&
                                        renderNoDataTextMR(brand, brandform, sku, month)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        }
                        <TablePagination
                            rowsPerPageOptions={[20, 50, 100, 200]}
                            component="div"
                            count={pageCount}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </React.Fragment>
            }
            <MRPlanUploadConfirmDialog open={open} handleClose={handleClose} alertMessageText={alertMessageText} title={props.page.title} />
            {foreCastModel?.isOpen && <ForeCastModal open={true} heading={foreCastModel.heading} monthLabel={foreCastModel.monthLabel}
                handleClose={handleForeCastModalClose} />}
            <ConfirmDialog open={deleteConfirmState.isOpen} handleClose={onDeleteResponse} alertMessageText={deleteConfirmState.deleteMessage}
                classes={classes} confirm />
            <ConfirmDialog open={confirmState.isOpen} handleClose={onConfirmResponse}
                alertMessageText={confirmState.confirmMessage} classes={classes} confirm />
        </div>
    );
};

const mapDispatchToProps = {
    getMrData,
    deleteMRData,
    removeMRSuccessMessage,
    saveMrData,
    updateMRData
};

const mapStateToProps = state => {
    return {
        account: state.mrReducer?.account,
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(MRPlanning)));