import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Radio, Slide } from '@material-ui/core';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { finalScenario } from '../../../../../store/actions';
import { withRouter } from 'react-router-dom';
import ConfirmDialog from '../../SpendPlan/ConfirmDialog';
import { getMonth } from '../../../../../utility/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiSvgIcon-root': {
      height: '20px !important',
      weight: '20px !important',
    },
    paddingLeft: 0,
  },
  addBaseLineModal: {
    '& .MuiDialog-paperWidthSm': {
      height: 400,
      width: 600,
    },
  },
  para: {
    fontSize: '28px !important',
    fontWeight: 600,
    color: '#000',
  },
  textInput: {
    marginTop: 10,
    marginBottom: 15,
    border: '1px solid lightgray',
    width: '100%',
    height: '35px',
    background: 'transparent',
    outline: 'none',
  }, 
  textarea: {
    marginTop: 10,
    marginBottom: 15,
    border: '1px solid lightgray',
    width: '100%',
    height: '50px',
    background: 'transparent',
    outline: 'none',
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
  },
  confirmationDialoge: {
    '& .MuiDialog-paperWidthSm': {
      height: '250px',
      width: '600px'
    }
  },
}));

const FinalScenarioModal = (props) => {
  let { open, handleClose, scenarioList } = props;
  const [scenarioRadio, setScenarioRadio] = useState('');
  const [isOpen, setOpen] = useState(false);

  const classes = useStyles();

  const save = () => {
    const appId = props.match.params.appid;
    let { BrandName, FinancialYear, CurrentFF } = props.brand;
    let currentMonth = getMonth(CurrentFF, 'monthNumber')
      .toString();
    if (currentMonth.length === 1) {
      currentMonth = '0' + currentMonth;
    }
    let data = {
      currentFF: currentMonth,
      fiscalYear: FinancialYear + "",
      brand: BrandName,
      scenario: scenarioRadio
    }
    props.finalScenario({ data, appId });
    handleClose(true);
  };

  const handleConfirmModal = (v) => {
    if (v) {
      save();
    }
    setOpen(false);
  };

  return (<>
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.addBaseLineModal}
    >
      <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: '#047dd8' }}>
        {'Please select Scenario to Finalise'}
      </DialogTitle>
      <DialogContent style={{ paddingTop: 15 }}>
        <div style={{ border: '1px solid lightgray', overflowY: 'scroll', height: 150, marginTop: 10 }}>
          {scenarioList.map(i =>
            (<div key={i.value} onClick={() => setScenarioRadio(i.value)}>
              <Radio
                className={classes.root}
                checked={scenarioRadio === i.value}
                onChange={(e) => {
                  setScenarioRadio(e.target.value);
                }}
                value={i.value}
                color="default"
                size="small"
              />
              <span style={{ fontSize: 18 }}>{i?.label}{i?.newLabel}</span>
            </div>),
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <>
          <Button
            color="primary" onClick={() => {
            handleClose(true);
          }}
            style={{ textTransform: 'none'}}
            className="btn btn-primary btn-color border-radius-0 text-color-white">
            {'Cancel'}
          </Button>
          <Button
            disabled={scenarioRadio.length === 0}
            color="primary" onClick={() => setOpen(true)}
            style={{ textTransform: 'none'}}
            className="btn btn-primary btn-color border-radius-0 text-color-white">
            {'Save'}
          </Button>
        </>
      </DialogActions>
    </Dialog>
    <ConfirmDialog open={isOpen} handleClose={handleConfirmModal}
                   alertMessageText={`Finalise scenario '${scenarioRadio}'?`}
                   classes={classes} confirm/>

  </>);
};

const mapDispatchToProps = {
  finalScenario,
};

const mapStateToProps = state => {
  return {
    brand: state.tprReducer?.selectBrand,
    scenarioList: state.tprReducer?.scenarioList,
  };
};
export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(FinalScenarioModal)));
