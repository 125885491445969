import { MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { skuTypeValues } from './header';

export default class ValueViewerSelect extends PureComponent {
    render() {
        const { value, cell, onSelectChange } = this.props;
        return (
            <select style={{ height: 25, marginTop: -2, marginBottom: -2, borderColor: '#cecece', outline: 'none' }}
                value={value} name="cars" onChange={(e) => onSelectChange(e.target.value, cell.index, cell.subIndex)}>
                {
                    skuTypeValues.map(item => {
                        return <option key={item.label} value={item.value}>{item.label}</option>
                    })
                }

            </select>
        )
    }
}


ValueViewerSelect.propTypes = {
    row: PropTypes.number.isRequired,
    col: PropTypes.number.isRequired,
    value: PropTypes.node.isRequired,
};
