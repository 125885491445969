import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import classNames from 'classnames';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMrData } from '../../../../../store/actions/index.js';
import Header from '../../../spendplan/SpendPlan/Header';
import { URLs } from '../../ApiConstants.js';
import { useDownload } from '../downloadHook.js';
import { useStyles } from '../Summary/index';
import FCMPlanningTable from './fcmPlanningTable';

const FCMPlanning = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  useDownload(props.account, props.modalData, props.page, props.match.params.appid, URLs.getFCMPlanning, loading, setLoading)

  return (
    <div className={classNames(classes.root, classes.talkSheetTable)} style={{ paddingBottom: '5px' }}>
      <Grid container justify="space-between">
        <Grid item xs={9} sm={9}>
          {!props.modalData && <Header header={`FCM Planning`} />}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.downloadButton}
            size="medium"
            onClick={() => setLoading(true)}
            startIcon={<GetAppIcon />}
          >
            {
              loading ? <CircularProgress className={classes.circularBar} size={24} /> : 'Download'
            }
          </Button>
        </Grid>
      </Grid>
      <div className='mt-10'>
        <FCMPlanningTable modalData={props.modalData} />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.user,
    data: state.mrReducer?.data,
    account: state.mrReducer?.account,
  };
};

export default hot(withRouter(connect(mapStateToProps, null)(FCMPlanning)));

