import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AppService } from '../../../../modules/api/index';
import { getFileNameWithTimeStamp, getMonth } from '../../../../utility/utils';
import OmniChannelLinks from '../../spendplan/GlobalLinks/omniChannelLinks';
import { getFiscalYearFormat, MonthsNumberMapping } from '../../spendplan/spendplanUtils';
import { sortOrder } from '../../mrplan/ApiConstants';
import OmniSheetTable from '../omniSheettable/index';
import FileDownload from "js-file-download";
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const OmniSheetTableWrapper = props => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100)
    const [conditions, setConditions] = useState([])
    const [state, setState] = useState({ data: [], paste: false })
    const { mrReducer } = props
    const [edited, setEdited] = useState(false)
    const [fyActualData, setLastFyActualData] = useState([])
    const [loading, setLoading] = useState(false)
    const [fyActualsLastFFSum, setFyActualsLastFFSum] = useState(null)
    const modalData = props.modalData ? props.modalData : '';
    const [isFiltersAdded, setIsFiltersAdded] = useState(false);
    const [isConditionChange, setIsConditionChange] = useState(false);
    const [isRowsPerPageChange, setIsRowsPerPageChange] = useState(false);
    const [isPageChange, setIsPageChange] = useState(false);
    const [showClearFilter, setShowClearFilter] = useState(false);
    const [clearAllFilters, setClearAllFilters] = useState(false);
    const [filterInfoID, setFilterInfoID] = useState('');

    const handleChangePage = (event, page) => {
        setIsPageChange(true)
        setPage(page);
    };

    const handleChangeRowsPerPage = event => {
        setPage(0);
        setIsRowsPerPageChange(true);
        setRowsPerPage(event.target.value)
    };

    const getAppliedFilterInfo = (str) => {
        const account = props.account
        let payloadToPass = {
            account: modalData?.account || account?.AccountName,
            fiscalYear: modalData?.year || account?.FinancialYear,
            dataModelId: props.page.pageLevelDataModel
        }
        let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
        payloadToPass.currentFF = modalData?.month || (m?.length === 1 ? '0' + m : m);
        let url = API_BASE_URL + '/mrspendplan/omni/filter-info'
        postDataToAPIGetFilters(url, payloadToPass, conditions, str);
    }

    const sendFiltersToAPI = (payload) => {
        let payloadToPass = JSON.parse(JSON.stringify(payload));
        let url = API_BASE_URL + '/mrspendplan/omni/filters'
        payloadToPass['filter'] = JSON.parse(JSON.stringify(payloadToPass));
        delete payloadToPass.filter['account'];
        delete payloadToPass.filter['currentFF'];
        delete payloadToPass.filter['fiscalYear'];
        payloadToPass['dataModelId'] = props.page.pageLevelDataModel;
        postDataToAPI(url, payloadToPass);
    }

    const fetchData = (conditions, p, rpp, isDownload) => {
        let conds = [];
        if ((props.type === 'skuMaster' || props.type === 'ittMaster' || props.type === 'omniMasterClassification' || props.type === 'lymetric' || props.type === 'pyfyActuals' || props.type === 'omniRetailingOffTakePlanning' ||
            props.type === 'fyActuals' || props.type === 'skulevelofftake' || props.type === 'omniRetailingOffTakePlanning' || props.type === 'retailBrandVSOD' || props.type === 'offtakeBrandVSOD' || props.type === 'tsSkuSalience') && props.appName === 'omnichannel') {
            if (mrReducer?.account?.FinancialYear) {
                conds.push({
                    "selectedConditionDataElement": 'fiscalYear',
                    "selectedConditionOperator": "exact_match",
                    "value": "" + Number(modalData?.year || mrReducer?.account?.FinancialYear),
                    "selectedConditionClause": "and"
                })
                if (props.type === 'lymetric' || props.type === 'pyfyActuals') {
                    conds[0].value = (parseInt(conds[0].value, 0) - 1).toString()
                }
            }
            if (MonthsNumberMapping[mrReducer?.account?.CurrentFF]) {
                conds.push({
                    "selectedConditionDataElement": 'currentFF',
                    "selectedConditionOperator": "exact_match",
                    "value": "" + (modalData?.month || MonthsNumberMapping[mrReducer?.account?.CurrentFF]),
                    "selectedConditionClause": "and"
                })
            }
        }
        if ((props.type === 'omniMasterClassification' || props.type === 'pidMapping' || props.type === 'claims' || props.type === 'fyActuals' || props.type === 'skulevelofftake' || props.type === 'lymetric' || props.type === 'pyfyActuals' || props.type === 'omniRetailingOffTakePlanning' ||
            props.type === 'retailBrandVSOD' || props.type === 'offtakeBrandVSOD' || props.type === 'tsSkuSalience') && props.appName === 'omnichannel') {
            conds.push({
                "selectedConditionDataElement": 'account',
                "selectedConditionOperator": "exact_match",
                "value": "" + encodeURIComponent(modalData?.account || mrReducer.account?.AccountName),
                "selectedConditionClause": "and"
            })
        }
        let newConditions = [];
        if (conditions && conditions.length) {
            for (let i = 0; i < conditions.length; i++) {
                setShowClearFilter(true);
                if (conditions[i].value && conditions[i].value.length) {
                    newConditions.push(conditions[i]);
                }
            }
        }
        if (!(newConditions && newConditions.length)) {
            setShowClearFilter(false)
        }
        if (newConditions)
            conds = [...conds, ...newConditions]
        let selectedDataElement = []
        let targetURL = API_BASE_URL + "/dataconnect/" + (isDownload ? 'download/' : 'table/') + props.page.pageLevelDataModel;
        // if (props.type === 'skulevelofftake') {
        //     targetURL = API_BASE_URL + '/mrspendplan/setup/sku-level-offtake/';
        // }
        const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
        targetURL += `?type=list&`;
        if (props.type === 'skuMapping') {
            targetURL += `sum=totalMR&`;
        }
        targetURL += '&page=' + (p || page) + '&rowsPerPage=' + (rpp || rowsPerPage) + "&";

        targetURL += (conds ? "conditions=" + encodeURIComponent(JSON.stringify(conds)) + "&" : '')
        targetURL += selectedDataElement.reduce(reducer, '&fields=');

        // check if filter applied
        let filterObj = {};
        for (let i = 0; i < conds.length; i++) {
            filterObj[conds[i].selectedConditionDataElement] = conds[i].value;
        }

        if (isDownload) {
            return targetURL;
        } else {
            if (['fyActuals', 'skulevelofftake', 'omniRetailingOffTakePlanning', 'pyfyActuals'].includes(props.type)) {
                targetURL += '&sum=totalOmni'
            }
        }
        if (props.type === 'claims' || props.type === 'retailBrandVSOD' || props.type === 'offtakeBrandVSOD' || props.type === 'tsSkuSalience') {
            let val;
            switch (props.type) {
                case 'claims': {
                    val = 'claims';
                    break;
                }
                case 'skulevelofftake': {
                    val = 'sku-level-offtake'
                    break;
                }
                case 'fyActuals': {
                    val = 'omnifyactuals'
                    break;
                }
                case 'offtakeBrandVSOD': {
                    val = 'offtake-brand'
                    break;
                }
                case 'retailBrandVSOD': {
                    val = 'retail-brand'
                    break;
                }
                case 'tsSkuSalience': {
                    val = 'tssku-salience';
                    break;
                }
            }
            targetURL = API_BASE_URL + '/mrspendplan/setup/' + val

            const payload = {
                data: {
                    account: modalData?.account || mrReducer.account?.AccountName,
                    fiscalYear: "" + Number(modalData?.year || mrReducer?.account?.FinancialYear),
                    currentFF: (modalData?.month || MonthsNumberMapping[mrReducer?.account?.CurrentFF])
                },
                pagination: {
                    page: (p || page).toString(),
                    rowsPerPage: (rpp || rowsPerPage).toString()
                },
                // sortOrder: {}
            }
            if (conditions && conditions.length) {
                for (let i = 0; i < conditions.length; i++) {
                    payload.data[conditions[i].selectedConditionDataElement] = conditions[i].value;
                }
            }
            if (Object.keys(payload.data).length > 2 && isFiltersAdded) {
                sendFiltersToAPI(payload.data)
            }
            setIsFiltersAdded(false);
            postDataToAPI(targetURL, payload)
        } else {
            let newFilterObj = {};
            newFilterObj['account'] = modalData?.account || mrReducer.account?.AccountName;
            newFilterObj['fiscalYear'] = "" + Number(modalData?.year || mrReducer?.account?.FinancialYear);
            newFilterObj['currentFF'] = (modalData?.month || MonthsNumberMapping[mrReducer?.account?.CurrentFF])
            let keys = Object.keys(filterObj);
            for (let i = 0; i < keys.length; i++) {
                if (!(keys[i] === 'account' || keys[i] === 'fiscalYear' || keys[i] === 'currentFF')) {
                    newFilterObj[keys[i]] = filterObj[keys[i]];
                }
            }
            if (Object.keys(newFilterObj).length > 2 && isFiltersAdded) {
                sendFiltersToAPI(newFilterObj)
            }
            setIsFiltersAdded(false);
            getApiDataFromDataUrl(targetURL)
        }
    }

    const onClearAll = () => {
        let url = `${API_BASE_URL}` + '/mrspendplan/omni/filter/' + filterInfoID;
        let account = props.account;
        axios({
            url: url,
            method: 'DELETE',
            headers: {
                appId: props.match.params.appid,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                setShowClearFilter(false);
                setIsFiltersAdded(true)
                setClearAllFilters(true);
                setConditions([]);
                let conditions = []
                fetchData(conditions, page, rowsPerPage, false)
            })
            .catch((error) => {
               console.log("errors", error)
            });
    }

    const getFileTitle = (accountName, fyFF) => {
        if (props.page.name === 'Fy Actuals pff') {
            return getFileNameWithTimeStamp('FY Actuals ' + getFiscalYearFormat(props.account?.FinancialYear, true) + '-' + accountName + '-' + getFiscalYearFormat(props.account?.FinancialYear))
        }
        return getFileNameWithTimeStamp(props.page.name + '-' + accountName + '-' + fyFF);;
    }

    const onDownload = () => {
        if (props.type === 'claims') {
            let val;
            switch (props.type) {
                case 'claims': {
                    val = '/mrspendplan/setup/claims-download';
                    break;
                }
            }
            let url = API_BASE_URL + val;
            const { account } = props;
            if (account) {
                let payload = {
                    data: {
                        account: modalData?.account || mrReducer.account?.AccountName,
                        fiscalYear: "" + Number(modalData?.year || mrReducer?.account?.FinancialYear),
                        currentFF: (modalData?.month || MonthsNumberMapping[mrReducer?.account?.CurrentFF])
                    },
                }
                for (let i = 0; i < conditions?.length; i++) {
                    payload.data[conditions[i].selectedConditionDataElement] = conditions[i].value;
                }
                let params = {
                    fiscalYear: account?.FinancialYear,
                    account: account?.AccountName,
                };
                let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
                params.currentFF = m.length === 1 ? '0' + m : m;
                axios({
                    url: url,
                    method: 'POST',
                    responseType: 'blob',
                    data: payload,
                    headers: {
                        appId: props.match.params.appid
                    },
                }).then((response) => {
                    let fyFF = getFiscalYearFormat(account.FinancialYear) + '-' + moment().month(account.CurrentFF).format('MMM');
                    FileDownload(response.data, getFileTitle(params.account, fyFF));
                }).catch((error) => {
                    console.log(error);
                })
            }
        } else {
            let url = fetchData(conditions, 0, state.count, true)
            const { account } = props;
            if (account) {
                let params = {
                    fiscalYear: account?.FinancialYear,
                    account: account?.AccountName,
                };
                let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
                params.currentFF = m.length === 1 ? '0' + m : m;
                axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                        appId: props.match.params.appid
                    },
                }).then((response) => {
                    let fyFF = getFiscalYearFormat(account.FinancialYear) + '-' + moment().month(account.CurrentFF).format('MMM');
                    FileDownload(response.data, getFileTitle(params.account, fyFF));
                }).catch((error) => {
                    console.log(error);
                })
            }
        }
    }

    const getApiDataFromDataUrl = (url, isFyAcutals) => {
        setLoading(true)
        AppService.getApiDataFromDataUrl(url, props.match.params.appid)
            .then(res => {
                setLoading(false)
                setIsFiltersAdded(false);
                setIsConditionChange(false);
                setIsPageChange(false);
                setIsRowsPerPageChange(false);
                setClearAllFilters(false);
                if (isFyAcutals) {
                    setLastFyActualData(res.data)
                    setFyActualsLastFFSum(res.totalSum)
                } else {
                    setState({ ...state, data: res.data, count: res.total, totalSum: res.totalSum })
                }
            }).catch(() => {
                setLoading(false)
            })
    }

    const postDataToAPI = (url, payload) => {
        setLoading(true)
        AppService.postDataToAPIUrl(url, props.match.params.appid, payload)
            .then(res => {
                setLoading(false)
                setIsFiltersAdded(false);
                setIsConditionChange(false);
                setIsPageChange(false);
                setIsRowsPerPageChange(false);
                setClearAllFilters(false);
                if (props.type === 'claims') {
                    for (let i = 0; i < res?.list?.length; i++) {
                        if (!res.list[i]['subBrandForm']) {
                            res.list[i]['subBrandForm'] = '';
                        }
                    }
                }
                getAppliedFilterInfo('again');
                setState({ ...state, data: res.list, count: res.count, totalSum: res.totalSum, total: res.total })
            }).catch(() => {
                setLoading(false)
            })
    }

    const postDataToAPIGetFilters = (url, payload, conditions, str) => {
        setLoading(true)
        AppService.postDataToAPIUrl(url, props.match.params.appid, payload)
            .then(res => {
                setLoading(false)
                let conds = [];
                if (res && res.result && res.result.filter) {
                    setFilterInfoID(res.result._id)
                    let keys = Object.keys(res.result.filter);
                    for (let i = 0; i < keys.length; i++) {
                        conds.push({
                            "selectedConditionDataElement": keys[i],
                            "selectedConditionOperator": "in",
                            "value": res.result.filter[keys[i]],
                            "selectedConditionClause": "and"
                        })
                    }
                    setShowClearFilter(true)
                } else {
                    setShowClearFilter(false)
                }
                setConditions([...conds]);
                if (str === 'init') {
                    fetchData([...conds]);
                }
            }).catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (mrReducer?.account?.AccountName && !isFiltersAdded) {
            getAppliedFilterInfo('init');
        }
    }, [mrReducer?.account?.AccountName])

    useEffect(() => {
        if (isConditionChange) {
            fetchData(conditions);
        }
    }, [conditions])

    useEffect(() => {
        if (isPageChange) {
            fetchData(conditions);
        }
    }, [page])


    useEffect(() => {
        if (isRowsPerPageChange) {
            fetchData(conditions);
        }
    }, [rowsPerPage])

    // useEffect(() => {
    //     console.log(rowsPerPage, page, mrReducer?.account?.AccountName, JSON.stringify(conditions), 'sheetable')
    //     // fetchData(conditions)
    //     if (mrReducer?.account?.AccountName && !isFiltersAdded) {
    //         getAppliedFilterInfo(conditions);
    //     }
    // }, [rowsPerPage, page, mrReducer?.account?.AccountName, JSON.stringify(conditions)])



    const handleFileSave = () => {
        fetchData();
    }

    const handlePasteClick = () => {
        setState({ ...state, paste: true })
    }

    const closePasteClick = () => {
        setState({ ...state, paste: false })
    }

    const handleFilterChange = (filter) => {
        setState({ ...state, data: [], totalSum: null, })
        let conditions = [];
        if (filter.account)
            conditions.push({
                "selectedConditionDataElement": 'account',
                "selectedConditionOperator": "in",
                "value": filter.account,
                "selectedConditionClause": "and"
            })
        if (filter.legalEntity)
            conditions.push({
                "selectedConditionDataElement": 'legalEntity',
                "selectedConditionOperator": "in",
                "value": filter.legalEntity,
                "selectedConditionClause": "and"
            })
        if (filter.category && filter.category.length)
            conditions.push({
                "selectedConditionDataElement": 'category',
                "selectedConditionOperator": "in",
                "value": filter.category,
                "selectedConditionClause": "and"
            })
        if (filter.brand)
            conditions.push({
                "selectedConditionDataElement": 'brand',
                "selectedConditionOperator": "in",
                "value": filter.brand,
                "selectedConditionClause": "and"
            })
        if (filter.tsBrand)
            conditions.push({
                "selectedConditionDataElement": 'tsBrand',
                "selectedConditionOperator": "in",
                "value": filter.tsBrand,
                "selectedConditionClause": "and"
            })
        if (filter.brandform)
            conditions.push({
                "selectedConditionDataElement": 'brandform',
                "selectedConditionOperator": "in",
                "value": filter.brandform,
                "selectedConditionClause": "and"
            })
        if (filter.sku)
            conditions.push({
                "selectedConditionDataElement": props.appName === 'omnichannel' ? 'tsSku' : 'sku',
                "selectedConditionOperator": "in",
                "value": filter.sku,
                "selectedConditionClause": "and"
            })
        if (filter.subBrandForm)
            conditions.push({
                "selectedConditionDataElement": 'subBrandForm',
                "selectedConditionOperator": "in",
                "value": filter.subBrandForm,
                "selectedConditionClause": "and"
            })
        if (filter.productId)
            conditions.push({
                "selectedConditionDataElement": 'productId',
                "selectedConditionOperator": "in",
                "value": filter.productId,
                "selectedConditionClause": "and"
            })
        if (filter.eventName)
            conditions.push({
                "selectedConditionDataElement": 'eventName',
                "selectedConditionOperator": "in",
                "value": filter.eventName,
                "selectedConditionClause": "and"
            })
        if (filter.subChannel)
            conditions.push({
                "selectedConditionDataElement": 'subChannel',
                "selectedConditionOperator": "in",
                "value": filter.subChannel,
                "selectedConditionClause": "and"
            })
        if (filter.skuType !== undefined && filter.skuType !== "")
            conditions.push({
                "selectedConditionDataElement": 'skuType',
                "selectedConditionOperator": "in",
                "value": filter.skuType,
                "selectedConditionClause": "and"
            })

        if (filter.metric)
            conditions.push({
                "selectedConditionDataElement": 'metric',
                "selectedConditionOperator": "in",
                "value": filter.metric,
                "selectedConditionClause": "and"
            })
        if (filter.uom)
            conditions.push({
                "selectedConditionDataElement": 'uom',
                "selectedConditionOperator": "in",
                "value": filter.uom,
                "selectedConditionClause": "and"
            })
        if (filter.kbd)
            conditions.push({
                "selectedConditionDataElement": 'kbd',
                "selectedConditionOperator": "in",
                "value": filter.kbd,
                "selectedConditionClause": "and"
            })
        if (filter.buisnessUnit)
            conditions.push({
                "selectedConditionDataElement": 'buisnessUnit',
                "selectedConditionOperator": "in",
                "value": filter.buisnessUnit,
                "selectedConditionClause": "and"
            })
        if (filter.type)
            conditions.push({
                "selectedConditionDataElement": 'type',
                "selectedConditionOperator": "in",
                "value": filter.type,
                "selectedConditionClause": "and"
            })
        setPage(0);
        setRowsPerPage(50)
        if (conditions.length) {
            setIsFiltersAdded(true);
            setIsConditionChange(true);
        }
        setConditions(conditions)
        console.log('conditions', conditions);
    }

    const setEditMode = (edit) => {
        setEdited(edit)
    }

    return (
        <>
            {
                !modalData && <OmniChannelLinks total={state?.totalSum} {...props} edited={edited} pageURLUpdate={() => setState({ data: [] })} />
            }
            <OmniSheetTable state={state} handleChangePage={handleChangePage} {...props} fetchData={fetchData}
                conditions={conditions} fyActualData={fyActualData} fyActualsLastFFSum={fyActualsLastFFSum}
                handleFilterChange={handleFilterChange} loading={loading}
                pagination={{ page, rowsPerPage }}
                modalData={modalData}
                onDownload={onDownload}
                handleChangeRowsPerPage={handleChangeRowsPerPage} closePasteClick={closePasteClick}
                handleFileSave={handleFileSave}
                handlePasteClick={handlePasteClick} setEditMode={setEditMode}
                showClearFilter={showClearFilter}
                clearAllFilters={clearAllFilters}
                onClearAll={onClearAll} />
        </>
    )
}

const mapStateToProps = ({ mrReducer }) => {
    return {
        mrReducer,
    }
}

export default hot(withRouter(connect(mapStateToProps)(OmniSheetTableWrapper)))
