export const fakeTableData = {
    "groups": [
        [{
            "id": 1,
            "type": "null"
        }, {
            "id": 2,
            "options": [],
            "value": "Base Annual Salary"
        }, {
            "id": 3,
            "options": [],
            "value": "percentage"
        }, {
            "id": 4,
            "options": [],
            "value": "Age"
        }],
        [{
            "id": 1,
            "type": "null"
        }, {
            "id": 2,
            "options": [],
            "value": "Age"
        }, {
            "id": 3,
            "options": [],
            "value": "percentage"
        }, {
            "id": 4,
            "options": [],
            "value": "Base Annual Salary"
        }]
    ],
    "fields": [
        [{
            "id": 1,
            "options": ["count", "total", "average", "max", "min", "percentage"],
            "value": "Total"
        }, {
            "id": 2,
            "input": {
                "name": "",
                "value": ""
            },
            "value": "Volume"
        }, {
            "id": 3,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"],
            "value": "Age"
        }, {
            "id": 4,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"]
        }, {
            "id": 5,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"]
        }],
        [{
            "id": 1,
            "options": ["count", "total", "average", "max", "min", "percentage"],
            "value": "percentage"
        }, {
            "id": 2,
            "input": {
                "name": "",
                "value": ""
            },
            "value": "Participants"
        }, {
            "id": 3,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"]
        }, {
            "id": 4,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"],
            "value": "Base Annual Salary"
        }, {
            "id": 5,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"]
        }],
        [{
            "id": 1,
            "options": ["count", "total", "average", "max", "min", "percentage"],
            "value": "max"
        }, {
            "id": 2,
            "input": {
                "name": "",
                "value": ""
            },
            "value": "Rate"
        }, {
            "id": 3,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"]
        }, {
            "id": 4,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"]
        }, {
            "id": 5,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"]
        }],
        [{
            "id": 1,
            "options": ["count", "total", "average", "max", "min", "percentage"],
            "value": "average"
        }, {
            "id": 2,
            "input": {
                "name": "",
                "value": ""
            },
            "value": "Amount"
        }, {
            "id": 3,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"]
        }, {
            "id": 4,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"]
        }, {
            "id": 5,
            "options": ["Employer_ID", "Employer_Name", "Employee ID", "Employee Name", "Age", "Class", "Billing Date", "Base Annual Salary", "Basic Life Volume", "Basic Life Prem", "Basic ADD Volume", "Basic ADD Prem", "LTD Volume", "LTD Prem", "FMLA/ADA Administration", "STD Volume", "STD Prem", "Supp EE Life Volume", "Supp EE Life Prem", "Supp SP Life Volume", "Supp SP Life Prem", "Supp CH Life Volume", "Supp CH Life Prem", "Total Premium"]
        }]
    ],
    "selectedData": {},
    "isTableShow": false
}