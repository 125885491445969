import { Button, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { FormElements } from './index';
const styles = theme => ({
    textField: {
        "padding-right": "10px",
    },
    staticText: {
        "width": "45%",
        "top": "50%"
    }
    ,
    checkStyle: {
        "padding-left": '19px',
        width: '20%'
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    modal: {
        top: `30%`,
        left: `30%`,
        transform: `translate(-30%, -30%)`,
        position: 'absolute',

    }
});

class InnerForm extends Component {
    constructor(props) {
        super(props);
        console.log(this.props.design, "design inner");
        let design = this.props.design;
        let modalData = this.props.modalData;
        this.state = {
            design: design,
            dragging: false,
            elementsData: {},
            elementType: "",
            open: false,
            modalData: modalData,
            editElement: null,
            dataFilled: {},
            showApiResponse: false,
            apiResponse: {},
            saveMessage: null,
            emptyGridToHighlight: null
        }
    }

    componentDidMount() {
        console.log(this.props.lookupDataModelInnerValue, "state of form mount");
        if (this.props.formState) {
            let formState = JSON.parse(JSON.stringify(this.props.formState));
            this.setState({ ...formState });
        }
    }

    componentWillUnmount() {
    }
    deleteElement = (position) => {
        console.log("deleteElement", position);
        let elementNameTobeDeleted = null;
        let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
        let modalJsonCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
        let transformedRow = jsonCopy[position.split("-")[0]].columns.filter((el, index) => {
            return index != position.split("-")[1];
        });
        let transformedRowModalData = modalJsonCopy[position.split("-")[0]].columns.filter((el, index) => {
            if (index == position.split("-")[1]) {
                console.log(el, "element to delete is");
                elementNameTobeDeleted = el.data.basic.name;
            }
            return index != position.split("-")[1];
        });

        jsonCopy[position.split("-")[0]].columns = transformedRow;
        modalJsonCopy[position.split("-")[0]].columns = transformedRowModalData
        jsonCopy = jsonCopy.filter((el) => {
            return el.columns.length > 0
        })
        modalJsonCopy = modalJsonCopy.filter((el) => {
            return el.columns.length > 0
        })
        this.setState({ design: { rows: jsonCopy } })
        this.deleteValidations(elementNameTobeDeleted, modalJsonCopy, jsonCopy);
    }
    editElement = (item, index) => {
        console.log("item to be edited", item, index);
        console.log(this.state.modalData, index, "edit element");
        let editElement = { index: index, data: this.state.modalData.rows[index.split("-")[0]].columns[index.split("-")[1]] }
        console.log(this.state.modalData, editElement, "edit element174");
        this.setState({ open: true, editElement: editElement });
    }
    removeEmptyRows = (variable) => {
        let jsonCopy = JSON.parse(JSON.stringify(variable));
        let filteredRows = jsonCopy.filter((item) => {
            return item.columns.length > 0;
        })
        return filteredRows;
    }
    createNewRowByRearrage(rowIndex) {
        console.log(rowIndex, this.props.draggedElement, "index of row where new row should be created");
        let newrow = {
            columns: []
        }

        let newmodalDataRow = {
            columns: []
        }
        let rowDragged = this.props.draggedElement.data.split("-")[0];
        let columnDragged = this.props.draggedElement.data.split("-")[1];
        let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
        if (jsonCopy.length <= rowDragged || jsonCopy[rowDragged].columns.length <= columnDragged) {
            return;
        }
        let selectedElementJson = JSON.parse(JSON.stringify(jsonCopy[rowDragged].columns[columnDragged]));
        let transformedRow = jsonCopy[rowDragged].columns.filter((el, index) => {
            return index != columnDragged;
        });

        jsonCopy[rowDragged].columns = transformedRow;

        let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
        console.log(modalDataCopy[rowDragged], columnDragged, "170 error");
        let modalDataElement = JSON.parse(JSON.stringify(modalDataCopy[rowDragged].columns[columnDragged]));

        let transformedRowModalData = modalDataCopy[rowDragged].columns.filter((el, index) => {
            return index != columnDragged;
        });

        modalDataCopy[rowDragged].columns = transformedRowModalData;

        if (rowDragged < rowIndex && jsonCopy[rowDragged].columns.length == 0) {
            rowIndex = rowIndex - 1;
        }
        jsonCopy = this.removeEmptyRows(jsonCopy);
        modalDataCopy = this.removeEmptyRows(modalDataCopy);

        newrow.columns.push(selectedElementJson);
        newmodalDataRow.columns.push(modalDataElement);
        jsonCopy = [...jsonCopy.slice(0, rowIndex), newrow, ...jsonCopy.slice(rowIndex)];
        modalDataCopy = [...modalDataCopy.slice(0, rowIndex), newmodalDataRow, ...modalDataCopy.slice(rowIndex)];
        console.log(jsonCopy, "Copy of json", modalDataCopy);
        this.setState({ design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null })
        this.props.innerFormUpdate(jsonCopy, modalDataCopy);
    }
    addElementAtDesiredPosition = (selectedPosition, data) => {
        console.log("selectedPosition is", selectedPosition);
        if ((selectedPosition + "").includes("-")) {
            let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
            let rowDropped = selectedPosition.split("-")[0];
            let columnDropped = selectedPosition.split("-")[1];
            let droppedRowCopy = JSON.parse(JSON.stringify(jsonCopy[rowDropped].columns))


            let transformedDropRow = [...droppedRowCopy.slice(0, columnDropped), this.getJson(this.state.elementType, data), ...droppedRowCopy.slice(columnDropped)]
            jsonCopy[rowDropped].columns = transformedDropRow;

            let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));

            let modalDataDroppedRowCopy = JSON.parse(JSON.stringify(modalDataCopy[rowDropped].columns));

            let transformedDropModalRow = [...modalDataDroppedRowCopy.slice(0, columnDropped), { data: data, type: this.state.elementType }, ...modalDataDroppedRowCopy.slice(columnDropped)]
            modalDataCopy[rowDropped].columns = transformedDropModalRow;
            jsonCopy = this.removeEmptyRows(jsonCopy);
            modalDataCopy = this.removeEmptyRows(modalDataCopy);


            this.setState({
                design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null
                , newElementPosition: null
            })
            this.props.innerFormUpdate(jsonCopy, modalDataCopy);
        }
        else {
            let newrow = {
                columns: []
            }

            let newmodalDataRow = {
                columns: []
            }
            let rowIndex = selectedPosition;
            let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
            let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
            newrow.columns.push(this.getJson(this.state.elementType, data));
            newmodalDataRow.columns.push({ data: data, type: this.state.elementType });
            jsonCopy = [...jsonCopy.slice(0, rowIndex), newrow, ...jsonCopy.slice(rowIndex)];
            modalDataCopy = [...modalDataCopy.slice(0, rowIndex), newmodalDataRow, ...modalDataCopy.slice(rowIndex)];
            console.log(jsonCopy, "Copy of json", modalDataCopy);
            this.setState({ design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null, newElementPosition: null })
            this.props.innerFormUpdate(jsonCopy, modalDataCopy);
        }

    }
    rearrangeElements(selectedElement, selectedPosition) {
        console.log("draggedelement", this.props.draggedElement);
        if (!this.props.draggedElement || !this.props.draggedElement.data || Object.getOwnPropertyNames(this.props.draggedElement.data).length == 0) {
            this.setState({ dragging: false, open: true })
            return;
        }
        let rowDragged = this.props.draggedElement.data.split("-")[0];
        let columnDragged = this.props.draggedElement.data.split("-")[1];
        let rowDropped = selectedPosition.split("-")[0];
        let columnDropped = selectedPosition.split("-")[1];
        console.log(selectedPosition, "selected position inner form", this.props.draggedElement, selectedPosition,
            this.state.modalData.rows);
        let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
        if (rowDragged >= jsonCopy.length || columnDragged >= jsonCopy[rowDragged].columns.length) {
            return;
        }
        let selectedElementJson = JSON.parse(JSON.stringify(jsonCopy[rowDragged].columns[columnDragged]));

        let transformedRow = jsonCopy[rowDragged].columns.filter((el, index) => {
            return index != columnDragged;
        });

        jsonCopy[rowDragged].columns = transformedRow;
        let droppedRowCopy = JSON.parse(JSON.stringify(jsonCopy[rowDropped].columns))


        let transformedDropRow = [...droppedRowCopy.slice(0, columnDropped), selectedElementJson, ...droppedRowCopy.slice(columnDropped)]
        jsonCopy[rowDropped].columns = transformedDropRow;
        // this.props.clearDraggedElement();

        let modalDataCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
        console.log(modalDataCopy[rowDragged], columnDragged, "170 error");

        if (rowDragged >= modalDataCopy.length || columnDragged >= modalDataCopy[rowDragged].columns.length) {
            return;
        }
        let modalDataElement = JSON.parse(JSON.stringify(modalDataCopy[rowDragged].columns[columnDragged]));

        let transformedRowModalData = modalDataCopy[rowDragged].columns.filter((el, index) => {
            return index != columnDragged;
        });

        modalDataCopy[rowDragged].columns = transformedRowModalData;

        let droppedRowCopyModalData = JSON.parse(JSON.stringify(modalDataCopy[rowDropped].columns))
        let transformedDropRowModalData = [...droppedRowCopyModalData.slice(0, columnDropped), modalDataElement, ...droppedRowCopyModalData.slice(columnDropped)]
        modalDataCopy[rowDropped].columns = transformedDropRowModalData;

        jsonCopy = this.removeEmptyRows(jsonCopy);
        modalDataCopy = this.removeEmptyRows(modalDataCopy);


        this.setState({ design: { rows: jsonCopy }, modalData: { rows: modalDataCopy }, emptyGridToHighlight: null })
        this.props.innerFormUpdate(jsonCopy, modalDataCopy);
    }


    useExistingRow(data) {
        let currentRow = this.state.design.rows[this.state.design.rows.length - 1];
        console.log(data, "modal data");
        let draggedElement = this.state.elementType
        currentRow.columns.push(this.getJson(draggedElement, data));
        let Rows = this.state.design.rows;
        Rows = Rows.slice(0, Rows.length - 1);
        Rows.push(currentRow);


        let modalData = JSON.parse(JSON.stringify(this.state.modalData));
        let modalDataRow = modalData.rows[this.state.design.rows.length - 1]
        modalDataRow.columns.push({ data: data, type: draggedElement });
        this.setState({ design: { rows: Rows }, modalData: modalData });

        this.props.innerFormUpdate(Rows, modalData);
        console.log(modalData, "modaldata");
    }
    buttonAction = (position) => {
        let row = position.split("-")[0];
        let column = position.split("-")[1];
        let buttonJson = JSON.parse(JSON.stringify(this.state.design.rows[row].columns[column]));
        let body = this.state.dataFilled;
        let headers = {
            'Content-Type': 'application/json',
        };
        buttonJson.headers.map((el) => {
            headers[el.label] = el.value;
        });
        console.log(buttonJson, "buttonjson");
        if (buttonJson.function == 'api') {
            axios.post(buttonJson.url, this.state.dataFilled, { headers: {...headers, appId: this.props.match.params.appid} }).then(response => {
                this.setState({
                    showApiResponse: true, apiResponse: {
                        body: body,
                        headers: headers,
                        response: response,
                        url: buttonJson.url,
                        buttonType: buttonJson.function
                    }
                });
            }).catch((exp) => { console.log(exp) })

        }
        else if (buttonJson.function == 'save') {
            this.setState({
                showApiResponse: true, apiResponse: {
                    body: body,
                    buttonType: buttonJson.function
                }
            });
        }
    }
    setData = (name, val, item) => {
        this.props.setData(name, val, item);
    }
    generateRow(Columns, outerIndex) {
        console.log("==============this.props.computed", this.props.computed)
        const { classes } = this.props;
        return Columns.map((item, index) => {
            console.log(item, "render")
            let gridSize = 12 / Columns.length;
            let visible = this.checkVisibility(`${outerIndex}-${index}`);
            if (!visible && this.props.filledData[item.name] != null) {
                this.setData(item.name, null, null)
            }
            return (visible || !this.props.viewOnly) && <FormElements gridSize={gridSize} item={item}
                editSubmittedData={this.props.editSubmittedData}
                match={this.props.match}
                deleteElement={(position) => { this.deleteElement(position) }}
                editElement={(item, position) => { this.editElement(item, position) }}
                viewOnly={this.props.viewOnly}
                buttonAction={(position) => { this.buttonAction(position) }}
                setFilesToUpload={(name, files) => { this.props.setFilesToUpload(name, files) }}
                onChange={(name, val, item) => { this.setData(name, val, item) }}
                filledData={this.props.filledData}
                parentType={this.props.parentType}
                parentName={this.props.parentName}
                outerIndex={outerIndex}
                index={index}
                parentIndex={this.props.index}
                lookupDataModelInnerValue={this.props.lookupDataModelInnerValue}
                computed={this.props.computed}
                rearrangeElements={(selectedElement, selectedPosition) => {
                    console.log(selectedElement, selectedPosition, "new element test")
                    if (selectedElement == null) {
                        this.setState({ newElementPosition: selectedPosition })
                    }
                    ; this.rearrangeElements(selectedElement, selectedPosition)
                }} />
        })
    }

    dropBetweenRows = (e, outerIndex) => {
        if (this.props.viewOnly) {
            return;
        }
        this.setState({ emptyGridToHighlight: null }); console.log(this.state.dragging, "form-controls-drag");
        if (!this.props.draggedElement.data) {
            this.setState({ newElementPosition: outerIndex });
            console.log("Place new Element at row number :-", outerIndex);
            return;
        }
        if (!this.state.dragging) { e.stopPropagation() };
        this.createNewRowByRearrage(outerIndex);
        ; console.log("in middle of two elements")
    }
    generateFormFromJson() {
        console.log(this.state.emptyGridToHighlight, this.state.design, "inner form generate form")
        const Jsondata = this.state.design.rows;
        return Jsondata.map((row, outerIndex) => {
            const Columns = row.columns
            return (
                <React.Fragment key={outerIndex}>
                    <Grid
                        // draggable="true"
                        onDragStart={(e) => {
                        }}
                        onDragOver={(event) => {//if(!this.props.draggedElement.data){return};
                            this.setState({ emptyGridToHighlight: outerIndex }); event.preventDefault()
                        }}
                        onDrop={(e) => { this.dropBetweenRows(e, outerIndex) }}
                        xs={12} style={this.state.emptyGridToHighlight == outerIndex ? { "border": "3px solid gray", 'padding-bottom': '45px' } : { 'padding-bottom': '3px' }}></Grid>
                    {this.generateRow(Columns, outerIndex)}
                </React.Fragment>
            )
        })
    }


    createNewRow(data) {
        let row = {
            columns: []
        }
        let modalDataRow = {
            columns: []
        }
        console.log(data, "modal data");
        let draggedElement = this.state.elementType
        row.columns.push(this.getJson(draggedElement, data));
        console.log(row, "rowcreated");
        let Rows = this.state.design.rows;
        Rows.push(row);

        let modalData = JSON.parse(JSON.stringify(this.state.modalData));
        modalDataRow.columns.push({ data: data, type: draggedElement });
        let ModalDatarows = modalData.rows;
        ModalDatarows.push(modalDataRow);

        this.setState({ design: { rows: Rows }, modalData: { rows: ModalDatarows } });
        console.log(modalData, "modaldata");

        this.setState({ design: { rows: Rows } });

        this.props.innerFormUpdate(Rows, ModalDatarows);
    }

    drop = (e) => {
        console.log("dropcalled");
        console.log("draggedelement", this.props.draggedElement);
        console.log(e.dataTransfer.getData("inputType"), e.target, "drop targets");
        console.log("dragged element type", e.dataTransfer.inputType)
        if (e.dataTransfer.getData("inputType")) {
            this.setState({ elementType: e.dataTransfer.getData("inputType"), dragging: false, open: true })
        }
    }
    elementDropped = (e, selectedElement) => {
        console.log("elementdropcalled", selectedElement);
    }
    dragEnd = (event, inputType) => {
        this.setState({ dragging: false });
        console.log("drag end")
    }
    handleCancel = () => {
        console.log("hide modal")
        this.setState({ open: false, editElement: null });
    }
    handleClose = (data) => {
        console.log("Add new Element at position", this.state.newElementPosition);
        this.setState({ open: false, elementsData: data, editElement: null });
        if (this.state.newElementPosition) {
            this.addElementAtDesiredPosition(this.state.newElementPosition, data);
        }
        else if (this.state.design.rows.length == 0) {
            this.createNewRow(data);
        }
        else if (!data.basic.sameRow) {
            this.createNewRow(data);
        }
        else {
            this.useExistingRow(data);
        }
    };
    editElementProperties = (data) => {
        console.log(this.state.editElement, "newpropertieselement", data)
        let edittedJson = this.getJson(this.state.editElement.data.type, data);
        let rowToBeEditted = this.state.editElement.index.split("-")[0];
        let columnToBeEditted = parseInt(this.state.editElement.index.split("-")[1]);
        let currentRows = JSON.parse(JSON.stringify(this.state.design.rows));
        let rowToTransform = JSON.parse(JSON.stringify(this.state.design.rows[rowToBeEditted].columns));
        console.log(rowToTransform, "rowtotransform");
        console.log(edittedJson, "newjson");
        let rowAfterTrasformation = [...rowToTransform.slice(0, columnToBeEditted), edittedJson, ...rowToTransform.slice(columnToBeEditted + 1)]
        currentRows[rowToBeEditted].columns = rowAfterTrasformation;
        console.log(currentRows, "designafterediting");

        let currentRowsModalData = JSON.parse(JSON.stringify(this.state.modalData.rows));
        let rowToTransformModalData = JSON.parse(JSON.stringify(this.state.modalData.rows[rowToBeEditted].columns));
        console.log(rowToTransformModalData, "row to edit", rowToBeEditted, "column to edit", columnToBeEditted);
        console.log([...rowToTransformModalData.slice(0, columnToBeEditted)], "partone");
        console.log([...rowToTransformModalData.slice(columnToBeEditted + 1)], "parttwo");
        let rowAfterTrasformationModalData = [...rowToTransformModalData.slice(0, columnToBeEditted), { data: data, type: this.state.editElement.data.type }, ...rowToTransformModalData.slice(columnToBeEditted + 1)]
        console.log(rowAfterTrasformationModalData, "after transformation edit");
        currentRowsModalData[rowToBeEditted].columns = rowAfterTrasformationModalData;
        console.log(currentRowsModalData, "after edit modify");

        this.setState({
            open: false, elementsData: data, editElement: null,
            design: { rows: currentRows },
            modalData: { rows: currentRowsModalData }
        });
        this.props.innerFormUpdate(currentRows, currentRowsModalData);
    };
    modifyValidations = (previousName, newName) => {
        console.log("here to modify", previousName, newName, this.state.modalData.rows);
        let stateCopy = JSON.parse(JSON.stringify(this.state.modalData.rows));
        let jsonCopy = JSON.parse(JSON.stringify(this.state.design.rows));
        console.log("inner modify 663", stateCopy);
        stateCopy.map((item, index) => {
            item.columns.map((column, columnIndex) => {
                console.log("elements or each columns data", column.data, column.data.conditionalRequired, column.data.visibility);
                let conditionalRequiredcopy = column.data.conditionalRequired && column.data.conditionalRequired.map((item) => {
                    if (item.element == previousName) {
                        return { ...item, element: newName };
                    }
                    else {
                        return item;
                    }
                });

                let visibilitycopy = column.data.visibility && column.data.visibility.map((item) => {
                    if (item.element == previousName) {
                        return { ...item, element: newName };
                    }
                    else {
                        return item;
                    }
                });
                stateCopy[index].columns[columnIndex].data = { ...stateCopy[index].columns[columnIndex].data, visibility: visibilitycopy, conditionalRequired: conditionalRequiredcopy };
                console.log("new copy of conditions", conditionalRequiredcopy, visibilitycopy);
            })
        })
        this.setState({ modalData: { ...this.state.modalData, rows: stateCopy } })
        console.log(stateCopy, "showcopyvalue");
        this.props.innerFormUpdate(jsonCopy, stateCopy);
    }


    deleteValidations = (elementName, jsonRows, jsonCopy) => {
        let stateCopy = JSON.parse(JSON.stringify(jsonRows));
        stateCopy.map((item, index) => {
            item.columns.map((column, columnIndex) => {
                console.log("elements or each columns data", column.data, column.data.conditionalRequired, column.data.visibility);
                let conditionalRequiredcopy = column.data.conditionalRequired.filter((item) => {
                    console.log(item.element, elementName, "element to delete");
                    if (item.element == elementName) {
                        console.log(item.element, elementName, "validation deleted");
                        return false;
                    }
                    else {
                        return true;
                    }
                });

                let visibilitycopy = column.data.visibility.filter((item) => {
                    if (item.element == elementName) {
                        return false;
                    }
                    else {
                        return true;
                    }
                });
                stateCopy[index].columns[columnIndex].data = { ...stateCopy[index].columns[columnIndex].data, visibility: visibilitycopy, conditionalRequired: conditionalRequiredcopy };
                console.log("new copy of conditions", conditionalRequiredcopy, visibilitycopy);
            })
        })
        this.setState({ modalData: { ...this.state.modalData, rows: stateCopy } })
        console.log(stateCopy, "showcopyvaluevalidationdeleted");
        this.props.innerFormUpdate(jsonCopy, stateCopy);
    }

    checkVisibility = (position) => {
        console.log("168 position", position, this.state.modalData);
        if (this.state.modalData.rows.length <= position.split("-")[0] || this.state.modalData.rows[position.split("-")[0]].columns.length <= position.split("-")[1]) {
            return true;
        }
        let visibilityConditions = this.state.modalData.rows[position.split("-")[0]].columns[position.split("-")[1]].data.visibility;
        console.log("conditions for visibility", position, visibilityConditions);
        let visibility = true;
        visibilityConditions.map((el) => {
            if (this.isConditionInvalid(el)) {
                visibility = false;
            }
        })
        return visibility;
    }
    isConditionInvalid = (el, action) => {
        let element = el;
        let testValidationsData = this.props.filledData;
        if (el.operator == "<") {
            if (!eval(`parseInt(testValidationsData[el.element]) < parseInt(element.value) `)) {
                action != null && alert("condition required Failed :- elements [" + el.element + "] <" + element.value)
                return true;
            }
        }
        else if (el.operator == ">") {
            if (!eval(`parseInt(testValidationsData[el.element]) > parseInt(element.value) `)) {
                action != null && alert("condition required Failed :- elements [" + el.element + "] >" + element.value)
                return true;
            }

        }
        else if (el.operator == "==") {
            if (!eval(`testValidationsData[el.element] == element.value `)) {
                action != null && alert("condition required Failed :- elements [" + el.element + "] ==" + element.value)
                return true;
            }
        }
        else if (el.operator == "!=") {
            if (!eval(`testValidationsData[el.element] != element.value `)) {
                action != null && alert("condition required Failed :- elements [" + el.element + "] !=" + element.value)
                return true;
            }
        }
    }


    render() {
        console.log(this.state, "latest state in render inner");
        console.log(this.state.modalData, "modaldata inner form");
        console.log(this.props.filledData, "filled state in render");
        const { classes } = this.props;
        console.log("form builder");
        console.log("rows data render", this.state);
        console.log("new element type ", this.props.newElementType);
        return (
            <div >
                <Dialog
                    open={this.state.showApiResponse}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogActions>
                        <Button color="primary" onClick={() => { this.setState({ showApiResponse: false }) }}>
                            Ok
          </Button>
                    </DialogActions>
                </Dialog>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="form-inner-container"
                            onDrop={(e) => { e.stopPropagation(); this.drop(e) }}
                            onDragOver={(event) => event.preventDefault()}
                        >
                            <Grid container  >
                                {this.generateFormFromJson()}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // clearDraggedElement: Actions.clearDraggedElement,
    }, dispatch);
}

const mapStateToProps = state => {
    return {
        selectedForm: state.form.selectedForm,
        submittedDataToBeEdited: state.form.submittedDataToBeEdited,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(InnerForm)));
