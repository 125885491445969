import { Button, Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import classNames from 'classnames';
import FileDownload from "js-file-download";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMonth } from 'utility/utils';
import { getFileNameWithTimeStamp } from '../../../../../utility/utils';
import Header from '../../SpendPlan/Header';
import { getCurrentFFForPayload, getFiscalYearFormat } from "../../spendplanUtils";
import { useStyles } from '../talkSheet';
import ForCorpReportTable from './forcorpReport';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ForCorpReport = (props) => {
    const classes = useStyles();
    const { brand } = props;


    const downloadTalkSheetHandler = () => {
        if (brand) {
            let m = getMonth(brand.CurrentFF, 'monthNumber');
            let _financialYear = props.modalData ? props.modalData.year : brand.FinancialYear;
            let _currentMonth = props.modalData ? getCurrentFFForPayload(props.modalData.month) : getCurrentFFForPayload(m);

            let uri = props?.scenario ? API_BASE_URL + `/report/forCorp/download/${_financialYear}/${_currentMonth}/${props?.scenario}` : API_BASE_URL + `/report/forCorp/download/${_financialYear}/${_currentMonth}`;
            if (props.modalData) {
                uri += '?version=true'
            }
            let fyFF = getFiscalYearFormat(_financialYear) + '-' + moment().month(Number(_currentMonth)-1).format('MMM');
            axios({
                url: uri,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    'Content-Type': "application/json",
                    appId: props.match.params.appid
                },
            }).then((response) => {
                FileDownload(response.data, getFileNameWithTimeStamp('For Corp' + '-'+ fyFF));
            }).catch((error) => {
                console.log(error);
            })
        }
    }

    return (
        <div className={classNames(classes.root, classes.talkSheetTable)} style={{ paddingBottom: '5px' }}>
            <Grid container justify="space-between">
                <Grid item xs={9} sm={9}>
                    <Header header={props.modalData ? props.versionTitle : `For Corp - ${brand && brand.CurrentFF && brand.FinancialYear
                        ? `FY ${getFiscalYearFormat(brand.FinancialYear)} ${getMonth(brand.CurrentFF, 'month')} FF`
                        : 'FY 2021'}`} />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.donwloadBtn}
                        size="medium"
                        onClick={downloadTalkSheetHandler}
                        startIcon={<GetAppIcon />}
                    > {"Download"} </Button>
                </Grid>
            </Grid>
            <div className='mt3'>
                <ForCorpReportTable brandData={brand} modalData={props.modalData} />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        brand: state.tprReducer?.selectBrand,
        tsData: state.tprReducer.forCorpData,
        scenario: state.tprReducer?.selectScenario
    };
};

const mapDispatchToProps = {
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps,)(ForCorpReport)),);
