import React, { useState, useEffect } from 'react';
import { Grid, Paper, makeStyles, Divider } from '@material-ui/core';
import Highcharts from 'highcharts';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const useStyles = makeStyles(theme => ({
    paperTable: {
        textAlign: 'left',
        padding: '5px 15px',
        // backgroundColor: 'transparent',
        borderRadius: 0,
        margin: '0 10px 0px 0',
        height: '100%'
    },
    anchor: {
        fontSize: '14px !important',
        textDecoration: 'underline !important',
    },
    panel: {
        marginBottom: '20px',
        backgroundColor: '#222222',
        border: '1px solid transparent',
        // borderRadius: '4px',
        boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)',
    },
    panelDefault: {
        borderColor: '#282828',
    },
    panelHeading: {
        color: '#888888',
        // backgroundColor: '#3c3c3c',
        borderColor: '#282828',
        textAlign: 'left',
        padding: '13px',
        borderBottom: '4px solid #000',
        marginBottom: '13px',
        '@media(max-width:1366px)': {
            padding: 5,
            borderBottom: '1px solid #000',
            marginBottom: 5,
        },
    },
    headingStyle: {
        fontSize: '25px',
        '@media(max-width:1366px)': {
            fontSize: 20,
        },
        fontWeight: 'bold',
        color: '#000',
        // display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
    },
    svgIcon: {
        marginRight: 10,
        fontSize: 21,
        '@media(max-width:1366px)': {
            fontSize: 18,
        },
    },
    checkIcon: {
        color: '#fff',
        background: '#008000',
        borderRadius: '19px',
        display: 'flex'
    },
    innerHeading: {
        textAlign: 'center'
    },
    innerBody: {
        paddingTop: '35px'
    },
    innerValue: {
        paddingTop: '35px',
        fontSize: '45px',
    },
    innerDivider: {
        paddingTop: '15px',
    },
    dividerColor: {
        backgroundColor: '#4472c4',
        color: '#4472c4'
    }
}));

let data = {
    chart: {
        type: 'line',
        renderTo: 'budgetplan'
    },
    title: {
        text: 'Budget Planned over last 12 months'
    },
    xAxis: {
        categories: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
    },
    yAxis: {
        title: {
            text: 'Budget ($ M)'
        }
    },
    plotOptions: {
        line: {
            marker: false
            // dataLabels: {
                // enabled: true
            // },
            // enableMouseTracking: false
        }
    },
    series: [{
        name: 'Ariel',
        data: [7.0, 6.9, 6.8, 6.7, 6.8, 6.9, 6.7, 6.9, 7.0, 6.8, 7.0, 6.9]
    }, {
        name: 'Pantene',
        data: [3.9, 4.2, 4.2, 4.2, 3.9, 4.2, 3.9, 3.9, 3.9, 4.2, 3.9, 4.2]
    }, {
        name: 'Tide',
        data: [17.0, 16.6, 14.2, 14.4, 14.3, 14.2, 14.4, 14.3, 14.2, 14.4, 14.3, 14.4]
    }, {
        name: 'Vicks',
        data: [25.2, 25.5, 24.3, 25.5, 24.3, 24.3, 24.3, 24.3, 25.5, 24.3, 25.5, 24.3]
    }],
    credits: {
        enabled: false
    }
}

function BudgetPie(props) {
    const classes = useStyles();

    useEffect(() => {
        Highcharts.chart(data)
    }, [])

    return (<>
        <Paper className={classes.paperTable}>
            <div className={classes.budgetPie}>
                <div id={"budgetplan"} ></div>
            </div>
        </Paper>
    </>);
}

export default BudgetPie;
