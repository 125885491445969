import { Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TalkSheetModal from "../ReportsPages/talkSheet/talkSheetModal";
import Header from '../SpendPlan/Header';
import IMAR2Modal from './imar2dialog';
import IMAR1Modal from './imar1dialog';
import MREcommModal from './mrEcommDialog';
import PlanningDialog from './PlanningDialog';
import SetupDataDialog from './SetupDataDialog';
import ReconReviewModal from './ReconReviewModal'
import { getFiscalYearFormat } from '../spendplanUtils';
import ForCorpModal from './forCorpReportModal';

const Types = {
	Planning: 'Planning',
	Setup: 'Setup',
	Talksheet: 'Talksheet',
	Imar1: 'Imar1',
	Imar2: 'Imar2',
	Recon: 'Recon',
	MrEcomm: 'MrEcomm',
	ForCorp: 'ForCorp',
}
const useStyles = makeStyles(theme => ({
	appBar: {
		color: "#070707",
		boxShadow: "none",
		backgroundColor: "transparent",
		borderBottom: "1px solid #ccc",
		margin: "10px 0 20px 0",
		'& button': {
			maxWidth: "16.666667%",
			flexBasis: "16.666667%"
		},
		'& span': {
			fontSize: '16px !important',
			fontWeight: 'bold'
		}
	},
	tableContainer: {
		width: "93vw",
		minWidth: "1000px",
		borderRadius: "8px",
		marginBottom: "15px"
	},
	table: {
		minWidth: 700,
	},
	tableHead: {
		"& th": {
			textAlignLast: "center",
			padding: "18px 8px !important",
			lineHeight: "1rem !important"
		},
		"& th:not(.adjustBorder)": {
			borderRight: "1px solid rgba(224, 224, 224, 1)"
		},
		"& span": {
			fontWeight: "bold",
		},
	},
	tableBody: {
		"& td": {
			cursor: "pointer",
			borderRight: "1px solid rgba(224, 224, 224, 1)",
			borderBottom: "0",
			textAlign: "center",
			padding: "8px !important",
			lineHeight: "1.43 !important",
			"& span": {
				textAlign: "center",
				fontSize: "14px"
			}
		},
	},
	actionsContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& a": {
			marginRight: "20px",
			color: '#1977a2',
			fontWeight: 'bold',
			textDecoration: 'none',
			'&:hover': {
				color: '#1977a2'
			}
		}
	},
	tableBodyNoData: {
		margin: "50px",
		display: "flex",
		minHeight: "50vh",
		textAlign: "center",
		alignItems: "center",
		justifyContent: "center",
		"& h3": {
			color: "black",
		}
	},
}));

const Archives = props => {
	const classes = useStyles()
	const { match, sptprData } = props
	const { selectBrand } = sptprData
	const [brand, setBrand] = useState(selectBrand)
	const actions = brand.actions && Array.isArray(brand.actions) ? brand.actions : [];
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(actions.length);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [openSetupData, setOpenSetupData] = useState(false)
	const [open, setOpen] = useState(null);

	const [modalData, setModalData] = useState('')
	const months = {
		'january': '01',
		'february': '02',
		'march': '03',
		'april': '04',
		'may': '05',
		'june': '06',
		'july': '07',
		'august': '08',
		'september': '09',
		'october': '10',
		'november': '11',
		'december': '12'
	}

	useEffect(() => {
		if (selectBrand && typeof selectBrand === 'object' && selectBrand !== null && Object.keys(selectBrand).length > 0) {
			setBrand(selectBrand)
			setPage(0)
			setRowsPerPage(10)
			const actions = selectBrand.actions && Array.isArray(selectBrand.actions) ? selectBrand.actions : [];
			setCount(actions.length)
		}
	}, [selectBrand])

	const StyledTableCell = withStyles((theme) => ({
		head: {
			backgroundColor: theme.palette.common.black,
			color: theme.palette.common.white,
		},
		body: {
			fontSize: 14,
		},
	}))(TableCell);

	const StyledTableRow = withStyles((theme) => ({
		root: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
	}))(TableRow);

	const handleChangePage = (event, page) => {
		setPage(page);
	}

	const renderNoDataText = () => {
		let textToShow = "No Data Found";
		return (
			<StyledTableCell colSpan="100%">
				<h4 style={{ color: '#000' }}>{textToShow}</h4>
			</StyledTableCell>
		);
	}

	const handleChangeRowsPerPage = event => {
		setPage(0);
		setRowsPerPage(event.target.value);
	}

	const openDialog = (row, _type) => {
		setOpen(_type)
		setModalData({
			brand: brand.BrandName, month: months[row.CurrentFF.toLowerCase()], year: row.FinancialYear, monthName: row.CurrentFF.slice(0, 3)
		});
	}

	const closeDialog = () => {
		setOpen(null)
		setModalData("");
	}

	const renderHeaderButton = (label, onBtnPress) => {
		return (
			<Button className={classNames(`btn btn-primary tpr-button m-right-10`)}
				color="primary" variant="contained" onClick={() => onBtnPress()}
				style={{ color: '#fff' }}
			>
				<span>{label}</span>
			</Button>
		);
	}

	const getData = () => {
		return actions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a, b) => new Date(b.actionDate) - new Date(a.actionDate))
	}

	return (
		<>
			<Header header="Versions" />
			<TableContainer className={classes.tableContainer} component={Paper}>
				<Table className={classNames(classes.table, "tpr-table")} aria-label="simple table" >
					<TableHead className={classes.tableHead}>
						<TableRow>
							<TableCell><span>Brand</span></TableCell>
							<TableCell><span>FY</span></TableCell>
							<TableCell><span>FF</span></TableCell>
							<TableCell><span>Versioned Date</span></TableCell>
							<TableCell><span>Versioned Resources</span></TableCell>
						</TableRow>
					</TableHead>
					<TableBody className={classes.tableBody}>
						{actions && actions.length > 0 ? getData().map((row, index) => (
							<StyledTableRow key={index}>
								<StyledTableCell>
									<span>{brand.BrandName}</span>
								</StyledTableCell>
								<StyledTableCell>
									<span>{getFiscalYearFormat(row.FinancialYear)}</span>
								</StyledTableCell>
								<StyledTableCell>
									<span>{row.CurrentFF.slice(0, 3)}</span>
								</StyledTableCell>
								<StyledTableCell>
									<span>{moment(row.actionDate).format('DD-MM-YYYY')}</span>
								</StyledTableCell>
								<StyledTableCell>
									<section className={classes.actionsContainer}>
										{renderHeaderButton("Setup Data", () => openDialog(row, Types.Setup))}
										{renderHeaderButton("Planning", () => openDialog(row, Types.Planning))}
										{renderHeaderButton("Talk Sheet", () => openDialog(row, Types.Talksheet))}
										{renderHeaderButton("IMAR 1", () => openDialog(row, Types.Imar1))}
										{renderHeaderButton("IMAR 2", () => openDialog(row, Types.Imar2))}
										{renderHeaderButton("Recon Review", () => openDialog(row, Types.Recon))}
										{renderHeaderButton("MR ECom", () => openDialog(row, Types.MrEcomm))}
										{renderHeaderButton("For Corp", () => openDialog(row, Types.ForCorp))}
									</section>
								</StyledTableCell>
							</StyledTableRow>
						)) :
							renderNoDataText()
						}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 12, 20, 50]}
				component="div"
				count={count}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			{modalData ? (
				<>
					{open === Types.Talksheet && <TalkSheetModal open={true} handleClose={closeDialog} modalData={modalData} />}
					{open === Types.Setup && <SetupDataDialog open={true} handleClose={closeDialog} pages={props.pages} row={{ brandform: '', channel: '' }} modalData={modalData} />}
					{open === Types.Planning && <PlanningDialog open={true} handleClose={closeDialog} pages={props.pages} modalData={modalData} />}
					{open === Types.Recon && <ReconReviewModal open={true} handleClose={closeDialog} pages={props.pages} modalData={modalData} />}
					{open === Types.Imar1 && <IMAR1Modal open={true} handleClose={closeDialog} pages={props.pages} modalData={modalData} />}
					{open === Types.Imar2 && <IMAR2Modal open={true} handleClose={closeDialog} pages={props.pages} modalData={modalData} />}
					{open === Types.MrEcomm && <MREcommModal open={true} handleClose={closeDialog} pages={props.pages} modalData={modalData} />}
					{open === Types.ForCorp && <ForCorpModal open={true} handleClose={closeDialog} pages={props.pages} modalData={modalData} />}
				</>
			) : null}
		</>
	)
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		sptprData: state.tprReducer,
	}
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Archives)))
