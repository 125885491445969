import { hot } from 'react-hot-loader/root';
import React from 'react';
import { withRouter } from 'react-router-dom';
import SpendPlan from '../../SpendPlan';

const ITT = props => {
  return <SpendPlan {...props} />
}

export default hot(withRouter(ITT))
