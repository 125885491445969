import { ActionTypes } from 'constants/index';

export const getTrialsDiscounts = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_TRIALS_DISCOUNT_DATA, payload: data });
};

export const addNewTrialsDiscounts = (data) => dispatch => {
  dispatch({ type: ActionTypes.ADD_NEW_TRIALS_DISCOUNT, payload: data });
};

export const deleteCheckedTrialsDiscounts = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_CHECKED_TRIALS_DISCOUNT, payload: data });
};

export const getTrialDiscountsExtraData = (payload) => dispatch => {
  dispatch({ type: ActionTypes.GET_TRIAL_DISCOUNTS_EXTRA_DATA, payload });
}

export const getPlanFilterDataTrialDiscounts = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_PLAN_FILTER_DATA_TRIAL_DISCOUNT, payload: data });
}

export const modifyTrialsDiscount = (records) => dispatch => {
	dispatch({ type: ActionTypes.MODIFY_TRIALS_DISCOUNT, payload: records });
}

export const clearModifyTrialsDiscount = () => dispatch => {
	dispatch({ type: ActionTypes.CLEAR_MODIFY_TRIALS_DISCOUNT });
}

export const removeModifyTrialsDiscount = (ids) => dispatch => {
	dispatch({ type: ActionTypes.REMOVE_MODIFY_TRIALS_DISCOUNT, payload: ids });
}
