import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, withStyles, Button, Divider, Typography, InputAdornment, Icon, Box, TextField, form, FormControl, FormLabel, Card } from '@material-ui/core';
import {
	submitLogin, emptyPrevPath,
	getEntity
} from 'store/actions';
import moment from 'moment';
import axios from 'axios'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
import { withRouter } from 'react-router-dom'
import Loading from '../ext-content/images/loading.gif'
import { Link } from 'react-router-dom';

import './../ext-content/bootstrap.scss'
import './../ext-content/custom.min.scss'
import './../ext-content/mystyles.scss'
import './../ext-content/jqcloud.scss'
import './../ext-content/basic.scss'
import './../ext-content/dropzone.scss'
//TODO: remove extra files from ext-content  folder
import MySystemsLinks from "./MySystemsLinksTo"

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		color: '#fff'
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: 'center',
		backgroundColor: 'transparent'
	},
	paperTable: {
		textAlign: 'left',
		padding: theme.spacing(1),
		backgroundColor: 'transparent'
	},
	panel: {
		marginBottom: '20px',
		backgroundColor: '#222222',
		border: '1px solid transparent',
		borderRadius: '4px',
		boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)'
	},
	panelDefault: {
		borderColor: '#282828'
	},
	panelHeading: {
		color: '#888888',
		backgroundColor: '#3c3c3c',
		borderColor: '#282828',
		textAlign: 'left',
		padding: '13px'
	},
	customTableFirstCol: {
		padding: '5px',
		color: '#fff',
		fontSize: '14px'
	},
	customTableSecondCol: {
		padding: '5px',
		width: '50px',
		color: '#fff',
		fontSize: '1.4em',
		textAlign: 'right'
	},
	customLabel3: {
		borderBottom: '1px solid #fff',
		padding: '6px 0',
		color: '#fff !important',
		textAlign: 'left !important'
	},
	cardTitle: {
		marginTop: '0 !important',
		color: '#000'
	},
	helptext: {
		color: '#000'
	},
	anchor: {
		fontSize: '14px !important',
		textDecoration: 'underline !important'
	},
	recentActions: {
		fontSize: '1.2em',
		color: '#fff',
		paddingBottom: '10px',
		borderBottom: '1px dotted #eee'
	},
	cardHeading: {
		fontSize: '34px !important'
	},
	cardDescript: {
		fontSize: '14px !important'
	},
	panelCard: {
		backgroundColor: '#ee9511'
	},
	tableBlue: {
		width: '100%',
		margin: 'auto',
		// borderCollapse: 'collapse',
		backgroundColor: '#add9e6 !important',
		color: '#000'
	},
	tdHead: {
		padding: '8px !important',
		borderBottom: '1px solid #ccc',
		color: '#fff',
		backgroundColor: '#000'
	},
	tdBlue: {
		color: '#000',
		padding: '8px !important',
		border: '1px solid #fff'
	}
}));

const BillingRepDashboard = (props) => {
	let initialState = {
		canSubmit: false
	};
	const [state, setState] = useState(initialState)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState({ email: false, password: false })
	const [messages, setMessages] = useState([])
	const [activityLogs, setActivityLogs] = useState([])
	const [externalLinks, setExternalLinks] = useState([])
	const [myTasks, setMyTasks] = useState([])
	const [employerCase, setEmployerCase] = useState({});
	const [memberName, setMemberName] = useState('');
	const [supportingDocsByBillRep, setSupportingDocsByBillRep] = useState([]);

	const [secondaryContactName, setSecondaryContactName] = useState()

	let form = React.createRef();
	const classes = useStyles();
	const [status, setStatus] = useState('loading')

	const getCaseData = () => {
		console.log('getCaseData');
		let conditions = [{
			"selectedConditionDataElement": "EmployerName",
			"selectedConditionOperator": "matches",
			"value": props.entity.name,
			"selectedConditionClause": "and"
		}];

		getData('Case', 1, conditions, (res) => {
			console.log('res == =>', res)
			if (res && Array.isArray(res) && res[0]) {
				setStatus('done')
				let c = res[0]
				setEmployerCase(c)
			} else {
				setStatus('done')
				// alert("You don't have any existing plans, Please contact your case processer.")
			}
		})
	}

	useEffect(() => {
		employerCase && employerCase.CaseProcessor && getUserName();
		setSecondaryContact(employerCase?.SecondaryContact);
	}, [employerCase])

	const setSecondaryContact = (id) => {
		getUserName(id)
	}

	const getUserName = (id) => {
		const uri = API_BASE_URL + "/custom/supportingDocument/user/" + (id ? id : employerCase.CaseProcessor);
		axios({
			url: uri,
			method: 'GET',
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
				// appId: "5f2ab15279813563bcaa3887"
			},
		}).then((response) => {
			console.log("getUserName = ", response.data)
			if (id) {
				setSecondaryContactName(response.data.firstName + " " + response.data.lastName)
			} else {
				setMemberName(response.data.firstName + " " + response.data.lastName);
			}
		}).catch((error) => {
			// setResponse({ "error": error });
			console.log("error", error)
		})
	}

	useEffect(() => {
		getCaseData()
	}, [props.entity.name])

	useEffect(() => {
		console.log('useEffect inti');
		props.getEntity(props.member.entityId, props.match.params.appid)
	}, [])

	const getData = (collectionName, pageCount, conditions, setResponse) => {

		const uri = API_BASE_URL + "/dataconnect/data/" + collectionName
			+ "?" + (conditions ? "conditions=" + JSON.stringify(conditions) + "&" : '')
			+ "page=0&rowsPerPage=" + (pageCount ? pageCount : 10)

		axios({
			url: uri,
			method: 'GET',
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
				// appId: "5f2ab15279813563bcaa3887"
			},
		}).then((response) => {
			console.log("data", response)
			setResponse(response.data.data);


		}).catch((error) => {
			setResponse({ "error": error });
			// console.log("error", error)
		})
	}

	const getSupportngDocsByBillRepID = () => {
		const uri = API_BASE_URL + '/custom/supportingDocument/SuportingdocsByBillRep/' + props.user.id

		axios({
			url: uri,
			method: 'GET',
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
			},
		}).then((response) => {
			console.log("getSupportngDocsByBillRepID", response)
			setSupportingDocsByBillRep(response.data);

		}).catch((error) => {
			// setSupportingDocsByBillRep({ "error": error });
			// console.log("error", error)
		})
	}

	const updateInvoiceDataTasks = () => {
		let list = [];
		list = myTasks.map((item) => {
			return item.DocumentId;
		})
		const uri = API_BASE_URL + '/custom/supportingDocument/Suportingdocs/tasks'

		axios({
			url: uri,
			method: 'POST',
			data: { docsIds: list },
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
			},
		}).then((response) => {
			console.log("updateInvoiceDataTasks", response)
			let list = []
			myTasks.map((item) => {
				response.data.map((doc) => {
					if (doc._id === item.DocumentId) {
						let diffDays = Math.ceil(Math.abs(new Date() - new Date(doc.createdDateTime)) / (1000 * 60 * 60 * 24))
						console.log("diff days", diffDays);
						list.push({
							...item, InvoiceNumber: doc.InvoiceNumber, days: diffDays,
							'billGroupName': doc.billGroupName
						})
					}
				})
			})
			setMyTasks([...list]);
		}).catch((error) => {
			// setSupportingDocsByBillRep({ "error": error });
			// console.log("error", error)
		})
	}

	useEffect(() => {
		updateInvoiceDataTasks();
	}, [myTasks.length > 0])


	const getAllForms = (setResponse) => {
		const uri = API_BASE_URL + "/forms"

		axios({
			url: uri,
			method: 'GET',
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
				// appId: "5f2ab15279813563bcaa3887"
			},
		}).then((response) => {
			console.log("data", response)
			setResponse(response.data);


		}).catch((error) => {
			setResponse({ "error": error });
			// console.log("error", error)
		})
	}

	useEffect(() => {
		let conditions = [{
			"selectedConditionDataElement": "createdBy",
			"selectedConditionOperator": "matches",
			"value": props.user.id,
			"selectedConditionClause": "and"
		}];
		getData('Messages', 4, null, (data) => {
			setMessages(data)
		})
		getData('ExternalLink', 5, conditions, (data) => {
			setExternalLinks(data)
		})
		getData('MyTasks', 5, [{
			"selectedConditionDataElement": "AssginedTo",
			"selectedConditionOperator": "matches",
			"value": props.user.id,
			"selectedConditionClause": "and"
		}], (data) => {
			let tasksArray = [];
			getAllForms((formsData) => {
				data && data.map(task => {
					formsData && formsData.map(form => {
						if (task.form === form._id || task.form === 'ibcSupportingDocumentView') {
							let flag = tasksArray.find(t => t._id === task._id)
							!flag && tasksArray.push({ ...task, formName: form.name })
						}
					})
				})
				setMyTasks(tasksArray);
			})
		})
		getSupportngDocsByBillRepID();
	}, [])

	const numFormat = (value) => {
		return '$ ' + (value ? value : '0').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}

	const convertToNumber = (value, point) => {
		if (value) {
			return Number(parseFloat(('' + value).replace(/,/g, '').trim()).toFixed(point || point === 0 ? point : 3))
		}
		else return 0
	}

	return (<div className='use-bootstrap ibc'>
		<div className="app-dashboard">
			<div className="ibc main" style={{ backgroundColor: isIBC ? '#f2f2f2' : '#231F20', padding: '10px' }}>
				<ul className={`breadcrumb ${isIBC ? 'breadcrumb-white' : ''}`}>
					<li style={{ color: isIBC ? '#000' : '#fff' }}>Dashboard</li>
				</ul>
				<Grid container spacing={0}>
					<Grid item xs={4}>
						<Paper className={classes.paper, "card"}>
							<div className={classes.panel, classes.panelDefault} style={{ height: 'auto', overflow: 'hidden' }}>
								<div className={isIBC ? 'ibc-panelHeading' : classes.panelHeading}>
									<h5>Amount Due </h5>
								</div>
								{/* <div className="card-heading-orange">
									<h4>Current Billing Period</h4>
									<h3>{moment().subtract(1, 'months').startOf('month').format('D MMM YYYY') + " to " + moment().subtract(1, 'months').endOf('month').format('D MMM YYYY')}</h3>
									<h4 className="mb-12-p">{"Monthly summary invoice is overdue by " + moment().endOf('month').fromNow()}</h4>
									<div className="text-center m-top-15">
										<Link to={`/apps/${props.match.params.appid}/ibcSupportingDocument`} className="btn btn-lg btn-danger">Start</Link>
									</div>
								</div> */}
								<table className={`${classes.tableBlue} ${isIBC ? 'ibc-table-blue' : ''}`}>
									<thead>
										<tr>
											<th className={classes.tdHead}>Employer</th>
											<th className={classes.tdHead}>{"Invoiced Amount"}</th>
											<th className={classes.tdHead}>{"Invoiced Date"}</th>
											{/* <th className={classes.tdHead}>Due</th> */}
										</tr>
									</thead>
									<tbody>
										{supportingDocsByBillRep && supportingDocsByBillRep?.map((item) => {
											return <tr>
												<td className={classes.tdBlue}>{item.EmployerName}</td>
												<td className={classes.tdBlue}>{numFormat(convertToNumber(item.total, 0))}</td>
												<td className={classes.tdBlue}>{moment(item.createdDateTime).format('DD MMM YYYY')}</td>
												{/* <td className={classes.tdBlue}>{item.PaymentStatus ? "$ 0.00" : numFormat(convertToNumber(item.total, 3))}</td> */}
											</tr>
										})
										}
									</tbody>
								</table>

							</div>
						</Paper>
					</Grid>
					<Grid item xs={5}>
						<Paper className={classes.paperTable}>
							<div className={classes.panel, classes.paneldefault}>
								<div className={isIBC ? 'ibc-panelHeading' : classes.panelHeading}>
									<h5>My Tasks</h5>
								</div>
								<table className={`table table-dark ${isIBC ? 'ibc-table-container ibc-report-table' : 'report-table'}`}>
									<thead>
										<tr>
											<th>Task #</th>
											<th>Invoice #</th>
											{/* <th>Task</th> */}
											{/* <th>Field Rep</th> */}
											<th>{"Field Office"}</th>
											<th>{"Broker"}</th>
											<th>Aging</th>
											{/* <th>Action Required</th> */}
										</tr>
									</thead>
									<tbody>
										{myTasks && myTasks.map((item, i) => {
											return <tr key={item._id}>
												<td>{i + 1}</td>
												<td>{item.InvoiceNumber}</td>
												{/* <td style={{ maxWidth: '400px' }}><a alt={item.Task} className={classes.anchor}
													href={
														item.form === 'ibcSupportingDocumentView'
															? `/apps/${props.match.params.appid}/${item.form}/${item._id}`
															: `/apps/${props.match.params.appid}/form/${item.formName}/${item._id}`
													}
													target="_blank">{item.Task}</a></td> */}
												{/* <td style={{ maxWidth: '150px' }}>{item.AssignedByName}</td> */}
												<td style={{ maxWidth: '150px' }}>{item.billGroupName}</td>
												<td style={{ maxWidth: '150px' }}>{item.AssignedByName}</td>
												<td>{item.days}</td>
												{/* <td>{item.ActionRequired}</td> */}
											</tr>
										})}
									</tbody>
								</table>
								<div style={{
									fontSize: '30px', color: '#fff',
									top: '50%', left: '50%',
									margin: 'auto',
									left: '1%',
									right: '1%',
									paddingTop: '60px',
									textAlign: 'center',
									color: isIBC && '#000'
								}}>
									{(myTasks && myTasks.length === 0 || !myTasks) && 'No Tasks'}
								</div>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={3}>
						<Paper className={classes.paper}>
							<div className={classes.panel, classes.paneldefault}>
								<div className={isIBC ? 'ibc-panelHeading' : classes.panelHeading}>
									<h5>Messages</h5>
								</div>
								<div className={classes.panelBody}>
									{
										messages && messages.map((item) => {
											const { Sender, Subject } = item;
											if ((Sender && Sender.length > 0) || (Subject && Subject.length > 0)) {
												return (
													<div className={isIBC ? 'ibc-customLabel3' : classes.customLabel3}>
														{item?.Sender && <p className="p-left-5 m-all-0 text-color-white line-height-1 p-5" style={{ paddingTop: '5px' }}>{Sender}</p>}
														{item?.Subject && <p className="p-left-5 m-all-0 text-color-white line-height-1" style={{ paddingTop: '5px' }}>{Subject}</p>}
													</div>
												)
											}
											return null;
										})
									}
									<div style={{
										fontSize: '30px', color: '#fff',
										top: '50%', left: '50%',
										margin: 'auto',
										left: '1%',
										right: '1%',
										paddingTop: '90px',
										textAlign: 'center',
										color: isIBC && '#000'
									}}>
										{(messages && messages.length === 0 || !messages) && 'No Messages'}
									</div>
								</div>
							</div>
						</Paper>
					</Grid>
				</Grid>
				<Grid container spacing={1} style={{ paddingTop: '10px', marginBottom: '50px' }}>
					<Grid item xs={8} style={{ padding: isIBC ? '8px' : '' }}>
						<Paper>
							{/* <div>
							<h4 className={`m-bottom-20 m-left-20 ${isIBC ? 'ibc-blue-txt' : 'text-color-white'}`}>Manage TPA Work</h4>
						</div> */}
							<Grid container spacing={2} className="p-bottom-10">
								<Grid item xs={4}>
									<div className={isIBC ? 'ibc-light-blue-box' : "light-green-box"}>
										<span className="strong-font"> + </span>
										<h3 className="light-box-heading m-all-0">
											<Link className="text-color-black"
												to={`/apps/${props.match.params.appid}/ibcSupportingDocuments`}>Summary Invoices</Link>
										</h3>
									</div>
								</Grid>
								<Grid item xs={4}>
									<div className={isIBC ? 'ibc-light-blue-box' : "light-green-box"}>
										<span className="strong-font"> + </span>
										<h3 className="light-box-heading m-all-0">
											<Link className="text-color-black"
												to={`/apps/${props.match.params.appid}/Payment`}>Payment History</Link>
										</h3>
									</div>
								</Grid>
								<Grid item xs={4}>
									<div className={isIBC ? 'ibc-light-blue-box' : "light-green-box"}>
										<span className="strong-font"> + </span>
										<h3 className="light-box-heading m-all-0">
											<Link className="text-color-black"
												to={`/apps/${props.match.params.appid}/ibcRecon`}>Reconciliation</Link>
										</h3>
									</div>
								</Grid>
								<Grid item xs={4}>
									<div className={isIBC ? 'ibc-light-blue-box' : "light-green-box"}>
										<span className="strong-font"> + </span>
										<h3 className="light-box-heading m-all-0">
											<Link className="text-color-black"
												to={`/apps/${props.match.params.appid}/ibcSetup`}>Census File Mappings</Link>
										</h3>
										<p className="helptext" className={classes.cardDescript}></p>
									</div>
								</Grid>
								<Grid item xs={4}>
									<div className={isIBC ? 'ibc-light-blue-box' : "light-green-box"}>
										<span className="strong-font"> + </span>
										<h3 className="light-box-heading m-all-0">
											<Link className="text-color-black"
												to={`/apps/${props.match.params.appid}/Case`}>Case Setup</Link>
										</h3>
										<p className="helptext" className={classes.cardDescript}></p>
									</div>
								</Grid>
								<Grid item xs={4}>
									<div className={isIBC ? 'ibc-light-blue-box' : "light-green-box"}>
										<span className="strong-font"> + </span>
										<h3 className="light-box-heading m-all-0">
											<Link to={`/apps/${props.match.params.appid}/BankAccount`}>Payment method</Link>
											{/* <a className="text-color-black" href={`/apps/${props.match.params.appid}/BankAccount`}>Bank Accounts</a> */}
										</h3>
										<p className="helptext" className={classes.cardDescript}></p>
									</div>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						{/* if you want card view you can change parent grid layout xs sizes */}
						{/* <MySystemsLinks /> */}
						<Paper style={{ overflow: 'hidden' }}>
							<div>
								<h4 className={`m-bottom-20 m-left-20 ${isIBC ? 'ibc-blue-txt' : 'text-color-white'}`}>
									{"My Systems"}
								</h4>
							</div>
							<Paper className={`${classes.paper} ${isIBC ? 'ibc-paper-white-child-border' : ''}`}>
								{
									externalLinks && externalLinks.map((item) => {
										return <p style={{
											textAlign: 'left',
											borderBottom: '1px solid #fff', padding: '10px 0',
											fontSize: '18px'
										}}><Link className="text-color-black" to={item.LinkURL}
											style={{ color: isIBC ? '#000' : '#fff' }}>{item.Linktitle}</Link></p>
									})
								}
								<div style={{
									fontSize: '30px', color: '#fff',
									top: '50%', left: '50%',
									margin: 'auto',
									left: '1%',
									right: '1%',
									paddingTop: '90px',
									textAlign: 'center',
									color: isIBC && '#000'
								}}>
									{(externalLinks && externalLinks.length === 0 || !externalLinks) && 'Systems are not configured yet'}
								</div>
							</Paper>
						</Paper>
					</Grid>
				</Grid>


				{status === 'loading' && <div className="loading" style={{ marginLeft: "500px", marginTop: "100px" }}>
					<h3>Loading data.....</h3>
					<img src={Loading} />
				</div>}
			</div>
		</div>
	</div >)
}


const mapDispatchToProps = {
	submitLogin,
	emptyPrevPath,
	getEntity
};

const mapStateToProps = ({ user, login, navigation, appConfig }) => {
	return {
		login,
		user,
		navigation,
		member: appConfig.member,
		memberLoading: appConfig.memberLoading,
		entity: appConfig.entity ? appConfig.entity : {}
	}
}


export default hot(withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(BillingRepDashboard)),
);
