import { connect } from 'react-redux';
import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from '@material-ui/core';

import classNames from 'classnames'

import { AppService } from 'modules/api/index';
const APPRENDERER_URL = process.env.REACT_APP_APPRENDERER_URL

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
    linksTitle: {
        fontSize: '18px !important',
        fontWeight: 'bold'
    },
    lineView: {
        width: '100%',
        height: '2px',
        marginTop: '30px',
        backgroundColor: '#cecece'
    },
    itemBorder: {
        width: '100%',
        height: '2px',
        backgroundColor: '#cecece'
    },
    linkCon: {
        paddingLeft: '16px',
        width: 'fit-content',
        '& a': {
            fontWeight: 'bold',
            paddingLeft: '0px',
            paddingRight: '0px',
            marginBottom: '0px',
            '& hover': {
                background: 'transparent'
            }
        }
    }
}))

const Links = (props) => {
    const ListItemLink = (props) => <ListItem button component="a" {...props} />
    const { match, chartData } = props;
    const classes = useStyles();
    const { chartUniqueId } = chartData
    let { data, width, height, error, name, } = chartData.chartData;

    const [state, setState] = useState({ data })

    useEffect(() => {
        let page = 0, rowsPerPage = 10 //default
        getApiData(page, rowsPerPage)
    }, [])

    const compare = (a, b) => {
        let comparison = 0;
        if (a.LinkListOrder > b.LinkListOrder) {
            comparison = 1;
        } else if (a.LinkListOrder < b.LinkListOrder) {
            comparison = -1;
        }
        return comparison;
    }

    const getApiDataFromDataUrl = (url) => {
        AppService.getApiDataFromDataUrl(url, props.appId)
            .then(res => {
                if (res.data && res.data.length > 1)
                    res.data.sort(compare);
                setState({ ...state, data: res.data })
                // setDatafromApi(res)
            })
    }

    const getApiData = (page, rowsPerPage) => {
        let { chartData } = props.chartData;
        if ((chartData.selectedDataModel && (chartData.selectedDataElement)
            || (chartData.loadChildData && chartData.parentDataElement))) {
            let targetURL = API_BASE_URL + "/dataconnect/table/" + chartData.selectedDataModel;
            const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
            // targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
            targetURL += `?type=list&fields=`;
            targetURL += '&page=' + page + '&rowsPerPage=' + rowsPerPage;
            if (props.queryString) {
                targetURL += '&' + props.queryString;
            }
            if (chartData.conditions && chartData.conditions.length > 0) {
                let cond = encodeURIComponent(JSON.stringify(chartData.conditions));
                targetURL += "&conditions=" + cond;
            }
            if (props.page.conditions && props.page.conditions.length > 0) {
                let cond = encodeURIComponent(JSON.stringify(props.page.conditions));
                targetURL += "&pConditions=" + cond;
            }
            getApiDataFromDataUrl(targetURL);
        } else if (props.dataUrl) {
            getApiDataFromDataUrl(props.dataUrl);
        } else {
            setState({ data: data, isLoading: false });
        }
    }
    if (error) {
        return <div className="errorDisplayCenter">{error}</div>
    } else {
        return (
            <div
                id={`LinksContainer${chartUniqueId}`}
                style={{ height: height, width: '100%' }}
            >
                <span className="linkTitle">{name}</span>
                <div className={classNames(classes.lineView)} />
                <List component="nav" className="p-0" aria-label="secondary mailbox folders">
                    {state.data && state.data.length > 0 ?
                        state.data.map((d, i) => {
                            const { title } = d;
                            let url
                            if (d.LinkType === 'Internal') {
                                url = d.InternalLinkType === 'Form'
                                    ? `/apps/${match.params.appid}/${d.LinkFormURL}/new`
                                    : `/apps/${match.params.appid}/${d.LinkPageURL}`
                            } else {
                                url = d.ExternalLink
                            }
                            return (
                                <div className={`${classes.linkCon} card-title-links`} key={i}>
                                    <ListItemLink
                                        onClick={() => {
                                            props.history.push(url);
                                        }}>
                                        <ListItemText primary={d.Title} />
                                    </ListItemLink>
                                    <div className={classes.itemBorder} />
                                </div>
                            )
                        })
                        : "Please wait data has been loading"
                    }
                </List>
            </div>
        )
    }
}

export default withRouter(Links)