import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { store, persistor } from 'store/index';
import './index.css';
import App from './App';
import { register } from './serviceWorker';
import AppError from "./components/ErrorHandler/index"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<AppError>
				<ToastContainer icon={false} theme="colored" hideProgressBar />
				<App />
			</AppError>
		</PersistGate>
	</Provider>,
	document.getElementById('root'),
);

register({
	onUpdate: () => {
		console.log("onUpdate - register");
	},
});
