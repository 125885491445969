import React from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom'
import OmniSheetTableWrapper from '../omniSheettable/omniSheettableApiService'

const MasterClassification = props => {
    return <OmniSheetTableWrapper {...props} />
}

export default hot(withRouter(MasterClassification))













