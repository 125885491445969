import { Card, CardContent, List, ListItem, ListItemText } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AppsIcon from '@material-ui/icons/Apps';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EventIcon from '@material-ui/icons/Event';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import axios from 'axios';
import classNames from 'classnames';
import FileDownload from 'js-file-download';
import { AppService } from 'modules/api/index';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFileNameWithTimeStamp, getMonth } from '../../../../utility/utils';
import { getFiscalYearFormat, TSP_TITLE, compare, MR_TITLE } from '../spendplanUtils';

export const mapJson = {
    'BOP Volumes': "bopvolumes", 'RLP per MSU': "rlppermsu", 'Channel Retailing Salience': "rlpsalience",
    'Past Year Retailing': 'pyrlp', 'Past Year RLP/MSU': 'pyrlppermsu', 'YTD Actual Retailing': 'rlpytdact',
    'F1 Inputs': 'f1inputs', 'Corp Bud': 'corpbud', 'MR & Ecomm': 'mrecomm', 'Accruals & HC': 'accrualshc',
    'Trade Price Reduction': 'tpr', 'Consumer Promotions': 'spConsumerPromotions', 'Conversion Funds': 'spConversionFunds',
    'Displays': 'spDisplays', 'MR and Ecomm TFIs': 'spMRandEcommTFIs', 'Trial Discounts': 'spTrialDiscounts',
    'ITT': 'itt', 'CTS': 'cts', 'TDC': 'tdc',
    'FY Actuals Retailing': 'fyActuals', 'Promo Planning': 'promo',
    'Non Promo Planning': 'nonPromo', 'Spends Planning': 'spendsPlanning',
    'Earnings Planning': 'earningsTFI',
}    // if changing page names then need to update here also

const APPRENDERER_URL = process.env.REACT_APP_APPRENDERER_URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StyledTooltip = withStyles({
    tooltip: {
        fontSize: "14px",
        backgroundColor: "#047dd8"
    }
})(Tooltip);

const useStyles = makeStyles(theme => ({
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between'
    },
    cardContainer: {
        width: '24%',
        '@media(max-width:1366px)': {
            height: '335px',
        },
        maxWidth: '500px',
    },
    cardMain: {
        width: '100%',
        borderRadius: "20px",
        boxShadow: "none",
        '@media(max-width:1366px)': {
            height: 270,

        },
        '& .linkTitle': {
            fontWeight: 'bold',
            marginLeft: '5px',
            fontSize: '1.7rem !important',
            '@media(max-width:1366px)': {
                fontSize: '1.1rem !important',
            },
        },
        '& nav': {
            '@media(max-width:1366px)': {
                marginTop: 0,
            },
        },
        '& .card-title-links': {
            '& svg': {
                fontSize: '18px',
                color: '#fff',
                background: '#008000',
                borderRadius: '19px',
                '@media(max-width:1366px)': {
                    fontSize: 10,
                },
                marginLeft: -7,
            },
            '& span': {
                color: "#fff",
                fontWeight: 'bold'
            },
            '&.setupBg': {
                background: ' linear-gradient(326deg, #719224 0%, #9bbb5c 74%)',
                marginRight: 2,
            },
            '&.setupBg:hover': {
                background: '#8faf4c',
            },
            '&.planningBg': {
                // background: '#208619'
                background: 'linear-gradient(315deg, #157863 0%, #1ca185 74%)',
                marginRight: 2,
            },
            '&.planningBg:hover': {
                background: '#157863'
            },
            '&.reportBg': {
                background: 'linear-gradient(315deg, #2993fd 0%, #3fb0ff 74%)',
                marginRight: 2,
            },
            '&.reportBg:hover': {
                background: '#2993fd'
            },
            '&.configureBg': {
                background: 'linear-gradient(315deg, #323f4f 0%, #52637f 74%)',
                marginRight: 2,
            },
            '&.configureBg:hover': {
                background: '#3c4a5f'
            },
            '&.MuiPaper-rounded': {
                borderTopLeftRadius: 25,
                borderBottomLeftRadius: 25,
            }
        },
        '& .card-title-links:hover': {
            transition: 'box-shadow .3s',
            boxShadow: '0 10px 20px rgba(0,0,0,0.19), 6px 6px 6px rgba(0,0,0,0.23)',
        },
    },
    cardContentMain: {
        padding: '16px 40px 24px 30px',
        '@media(max-width:1366px)': {
            padding: '8px 20px 0 30px',
        },
    },
    titleCss: {
        flex: '0 1 auto',
        '& span': {
            fontSize: '18px !important',
            '@media(max-width:1366px)': {
                fontSize: '14px !important',
            },
            fontWeight: '500 !important',
        },
    },
    listItemLink: {
        '@media(max-width:1366px)': {
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
        },
    },
    svgIcon: {
        marginTop: 0,
    },
    IconContainer: {
        display: 'flex',
        marginRight: 10,
        width: 20,
        '@media(max-width:1366px)': {
            marginRight: 0,
            width: 15,
        },
    },
    listContainer: {
        height: '41.1vh',
        '@media(max-width:1366px)': {
            height: '30.7vh',
        },
        '@media(min-width:1900px)': {
            height: '32vh',
        },
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.4em',
            height: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            background: '#cdcdcd',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: 'gray',
        },
    }
}))

const DashboardMain = (props) => {
    const ListItemLink = (props) => <ListItem button component="a" {...props} />
    const { match, chartData } = props;
    const classes = useStyles();
    const { chartUniqueId } = chartData
    let { error, name, } = chartData.chartData;

    const [state, setState] = useState({ data: [] })

    useEffect(() => {
        let page = 0, rowsPerPage = 15 //default
        getApiData(page, rowsPerPage)
    }, [props.account?.AccountName, props.brand?.BrandName])

    const getApiDataFromDataUrl = (url) => {
        AppService.getApiDataFromDataUrl(url, props.appId)
            .then(res => {
                if (res?.data && res?.data.length > 1)
                    res?.data.sort(compare);
                    console.log('res?.data', res?.data);
                setState({ ...state, data: res?.data })
                // setDatafromApi(res)
            })
    }

    const getTitleName = (name) => {
        switch (name) {
            case 'Setup Data':
                return 'Setup'
            case 'Review Reports':
                return 'Report'
            case 'Monthly Spend Plan':
                return 'Planning'
            default:
                return name
        }
    }

    const getBgName = (name) => {
        switch (name) {
            case 'Setup Data':
                return 'setupBg'
            case 'Review Reports':
                return 'reportBg'
            case 'Monthly Spend Plan':
                return 'planningBg'
            case 'Configure':
                return 'configureBg'
            default:
                return name
        }
    }

    const upDateObj = (d) => {
        const { updatedData } = props;
        const isFound = updatedData && Array.isArray(updatedData) && updatedData.find(item => item.formType === mapJson[d.Title]);
        if (isFound) {
            let dd = { ...d }
            dd.actionUser = isFound.actionUser;
            dd.action = isFound.action;
            dd.finshUpdatedDate = isFound.createdDate;
            return dd;
        }
        return d
    }

    const downloadAllReports = () => {
        let { BrandName, FinancialYear, CurrentFF } = props.brand;
        let m = '' + getMonth(CurrentFF, 'monthNumber')
        m = m.length === 1 ? '0' + m : m;
        let fyFF = getFiscalYearFormat(props.brand.FinancialYear) + '-' + moment().month(props?.brand?.CurrentFF).format('MMM');
        axios({
            url: API_BASE_URL + '/talksheet/all/download/' + `${BrandName}/${FinancialYear}/${m}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                appId: props.match.params.appid,
            },
        }).then((response) => {
            FileDownload(response?.data, 'All-reports' + getFileNameWithTimeStamp('-' + props.brand.BrandName + '-' + fyFF));
        })
            .catch((error) => {
                console.log(error);
        });
        toast.info('Reports download started. Do not close this application.')
    }

    const getApiData = (page, rowsPerPage) => {
        let { chartData } = props.chartData;
        if ((chartData.selectedDataModel && (chartData.selectedDataElement)
            || (chartData.loadChildData && chartData.parentDataElement))) {
            let targetURL = API_BASE_URL + "/dataconnect/table/" + chartData.selectedDataModel;
            const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
            // targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
            targetURL += `?type=list&fields=`;
            targetURL += '&page=' + page + '&rowsPerPage=' + rowsPerPage;
            if (props.queryString) {
                targetURL += '&' + props.queryString;
            }
            if (chartData.conditions && chartData.conditions.length > 0) {
                if (props.app.title === MR_TITLE) {
                    let conditions = chartData.conditions;
                    if (props.account?.event?.length) {
                        conditions.push({
                            "selectedConditionDataElement": "type",
                            "selectedConditionOperator": "is_equal_to",
                            "value": "OmniChannel",
                            "selectedConditionClause": "and"
                        }) 
                    } else {
                        conditions.push({
                            "selectedConditionDataElement": "type",
                            "selectedConditionOperator": "is_equal_to",
                            "value": undefined,
                            "selectedConditionClause": "and"
                        }) 
                    }
                } 
                let cond = encodeURIComponent(JSON.stringify(chartData.conditions));
                targetURL += "&conditions=" + cond;
            }
            if (props.page.conditions && props.page.conditions.length > 0) {
                let cond = encodeURIComponent(JSON.stringify(props.page.conditions));
                targetURL += "&pConditions=" + cond;
            }
            getApiDataFromDataUrl(targetURL);
        } else if (props.dataUrl) {
            getApiDataFromDataUrl(props.dataUrl);
        } else {
            setState({ data: data, isLoading: false });
        }
    }
    if (error) {
        return <div className="errorDisplayCenter">{error}</div>
    } else {
        return (
            <div
                id={`LinksContainer${chartUniqueId}`}
                className={classes.cardContainer}>
                <Card className={classes.cardMain}>
                    <CardContent className={classes.cardContentMain}>
                        <div className={classes.headerContainer}>
                            <div>
                                {name === 'Configure'
                                    ? <AppsIcon /> : name === 'Setup Data'
                                        ? <PermDataSettingIcon /> : name === 'Monthly Spend Plan'
                                            ? <EventIcon /> : <FileCopyIcon />}
                                <span className="linkTitle">{getTitleName(name)}</span>
                            </div>
                            {(props.app.title === TSP_TITLE && name === 'Review Reports') && <GetAppIcon style={{ cursor: 'pointer' }}
                                onClick={downloadAllReports} />}
                        </div>
                        <List className={'p-0'} component="nav" aria-label="secondary mailbox folders">
                            <div className={classes.listContainer}>
                                {state?.data && state?.data.length > 0 ?
                                    state?.data.map((d, i) => {
                                        let dd = upDateObj(d)
                                        const { title } = dd;
                                        let url
                                        if (dd.LinkType === 'Internal') {
                                            url = dd.InternalLinkType === 'Form'
                                                ? `/apps/${match.params.appid}/${dd.LinkFormURL}/new`
                                                : `/apps/${match.params.appid}/${dd.LinkPageURL}`
                                        } else {
                                            url = dd.ExternalLink
                                        }
                                        return (
                                            <Paper className={classNames("card-title-links", getBgName(name))} key={i}>
                                                {
                                                    ((dd.Title !== 'Non General Format') || (dd.Title === 'Non General Format' && (props.account.Format === 'eB2B' || props.account.Format === 'Cash & carry')) ?
                                                        <ListItemLink className={classes.listItemLink}
                                                            onClick={() => {
                                                                props.history.push(url);
                                                            }}>
                                                            <div className={classes.IconContainer}>
                                                                {dd.action === "finish" &&
                                                                    <StyledTooltip
                                                                        title={`Updated by ${dd.actionUser} at ${moment(dd.finshUpdatedDate).format('MM/DD/YYYY HH:mm:ss')}`}>
                                                                        <CheckCircleIcon className={classes.svgIcon} />
                                                                    </StyledTooltip>
                                                                }
                                                            </div>
                                                            <ListItemText className={classes.titleCss}
                                                                primary={dd.Title === 'FY Actuals' ? (dd.Title + " " + getFiscalYearFormat(props.account?.FinancialYear, true)) : dd.Title} />
                                                        </ListItemLink>
                                                        : '')
                                                }

                                            </Paper>
                                        )
                                    })
                                    : ""
                                }
                            </div>
                        </List>
                    </CardContent>
                </Card>
            </div>
        )
    }
}

export default withRouter(DashboardMain)
