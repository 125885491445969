import React, { useState, useEffect } from 'react';
import axios from 'axios'

function SupportinDoc(props) {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [pdfData, setPDFData] = useState(null);
  const getPDFData = (id) => {
    const uri = API_BASE_URL + "/custom/supportingDocument/getpdf/" + id
    axios({
      url: uri,
      method: 'GET',
      headers: {
        'Content-Type': "application/json",
        appId: props.match.params.appid
      },
    }).then((response) => {
      console.log("response", response);
      setPDFData(response.data.pdfData);
    }).catch((error) => {
      // setResponse({ "error": error });
      setPDFData(error);
    })
  }
  useEffect(() => {
    getPDFData(props.supportingDocument._id);
  }, [props.supportingDocument])
  return (
    <div className="extraction-results-SupportinDoc">
      <div className="extraction-results-heading">
        <h3>Summary Invoice</h3>
      </div>
      <div className="extraction-results-pdfFile">
        <embed style={{ width: "100%", height: "100%" }} src={pdfData && "data:application/pdf;base64," + [pdfData]} type="application/pdf"></embed>
      </div>
    </div>
  );
}

export default SupportinDoc;
