import moment from "moment";
import { capitalize } from "../../../../utility/utils";

export const isMonthForecast = (monthKey, account) => {
    // monthKey: jan, feb ,mar
    if (account && months.indexOf(monthKey) + 1 > months.indexOf((moment().month(account.CurrentFF).format('MMM').toLowerCase()))) {
        return true;
    }
    return false;
}

export const getMRMonths = (account) => {
    return months.map((i) => {
        if (!isMonthForecast(i, account)) {
            return i;
        }
    }).filter(Boolean)
}

export const months = ['jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'may', 'jun']
export const columnTitles = { brand: 'Brand', brandform: 'Brandform', sku: 'SKU', skuType: 'SKU Types', account: 'Account', metric: 'Metric', total: 'Total', tsBrand: 'TS Brand', tsSku: 'TS SKU', subBrandForm: 'Sub Brandform', status: 'Status',
productId: 'Product ID', eventName: 'Event Name', custName: 'Customer Name', kbd: 'KBD', uom: 'UOM', jul: 'JUL', aug: 'AUG', sep: 'SEP', oct: 'OCT', nov: 'NOV', dec: 'DEC', jan: 'JAN', feb: 'FEB', mar: 'MAR', apr: 'APR', may: 'MAY', jun: 'JUN',
legalEntity: 'Legal Entity', buisnessUnit: 'Business Unit', category: 'Category', type: 'Type', lastYearSalience: 'Last Year Salience', past3mSalience: 'Past 3 M Salience', fySalience: 'FY Salience', month: 'Promo Month',
year: 'Promo Year', asp: 'ASP (INR)', minQty: 'Min Qty (Nos)', claimAmount: 'Claim Amount (INR)', promoValue: 'Promo value (Ex. of GST) (INR)', 'minPromo%': 'Min Promo %'}

//SKU master
export const skuMasterObjectKeys = [{ key: 'mrp', title: 'MRP' }, { key: 'frontmargin', title: 'Front Margin', isPercent: true },
{ key: 'rlp', title: 'RLP' }, { key: 'gstrate', title: 'GST Rates', isPercent: true },{ key: 'msufactor', title: 'MSU Factor' }, ]
// export const skuKeys = months.map(m => ({ key: m, title: capitalize(m), subKeys: skuMasterObjectKeys }))
// capitalize(m)
export const skuKeys = skuMasterObjectKeys.map(k=> ({ key: k.key, title: capitalize(k.title), subKeys: months.map(m=> ({ key: m, title:  m?.toUpperCase()})) }))

export const skuMasterKeys = ['', 'brand', 'brandform', 'sku', ...skuKeys];

export const omniChannelSkuMasterKeys = ['', 'legalEntity', 'category', 'brand', 'tsBrand', 'brandform', 'tsSku', 'subBrandForm', 'status', ...skuKeys];
/* ------------------------------------------------------------------------------------------------------ */

//ITT master
export const ittMasterObjectKeys = [
    { key: 'kbdone', title: 'KBD1 (INR)', subTitle: 'Cash & Carry', isPercent: true },
    { key: 'kbdtwo', title: 'KBD2 (INR)', subTitle: 'Cash & Carry', isPercent: true },
    { key: 'ctlsib', title: 'CTLSIB (INR)', subTitle: 'Cash & Carry', isPercent: true },
    { key: 'sib', title: 'SIB', subTitle: 'Cash & Carry', overrideSubtitle: true, isPercent: true },
    { key: 'rentals', title: 'Rentals', subTitle: 'Cash & Carry', overrideSubtitle: true, isPercent: true },
]
export const ittSubKeys = [{ key: "tierthree", title: 'Tier 3' }, { key: "hyper", title: "Hyper" }, { key: "discounter", title: "Discounter" },
{ key: "mm", title: "MM" }, { key: "pharmacy", title: "Pharmacy" }, { key: "beauty", title: "Beauty" },{ key: "largepack", title: 'Large Pack' }
    , { key: "smallpack", title: 'Small Pack', }]

export const ittSubKeysOmniChannel = [{ key: "eMarketPlaceAmazon", title: 'eMarket Place<br/>(Amazon)' }, { key: "eMarketPlace", title: 'eMarket Place' }, { key: "eGrocer", title: "eGrocers" }, { key: "eBeauty", title: "eBeauty" },
{ key: "eBaby", title: "eBaby" }, { key: "ePharma", title: "ePharma" }, { key: "eSubAndEcom", title: "Q-Grocers/<br/> Esub and <br/>Ecom <br/>emerging <br/>formats" },
{ key: "coreSku", title: 'eB2B <br/>(core SKUs)' }, { key: "growthSku", title: 'eB2B <br/>(growth SKUs)'}, { key: "consumptionSku", title: 'eB2B <br/>(Consumption<br/> SKUs)'},
 { key: "consumption", title: 'Cash & Carry <br/>(Consumption)'}, { key: "growth", title: 'Cash & Carry <br/>(Growth)'}, { key: "smallPacks", title: 'Cash & Carry <br/>(Small Packs)'}]

export const ittMasterKeys = ['', 'brand', 'brandform', ...ittMasterObjectKeys.map(item => ({ ...item, subKeys: ittSubKeys }))];

export const ittMasterKeysOmniChannel = ['', 'brand', 'brandform', 'tsSku', 'type',  ...ittMasterObjectKeys.map(item => ({ ...item, subKeys: ittSubKeysOmniChannel }))];


export const pIDMapping = ['', 'subBrandForm', 'productId']

export const claimObjectKeys = [{ key: 'qty', title: 'Min Qty', decimalPlaces: 2 }, { key: 'amount', title: 'Amount', decimalPlaces: 2 }]

export const claimsKeys = ['', 'custName', 'subBrandForm', 'productId', 'eventName', ...claimObjectKeys]

/* ------------------------------------------------------------------------------------------------------ */

/* SKU Mapping */

export const skuMappingKeys = ['', 'brand', 'brandform', 'sku', 'skuType']
/* ------------------------------------------------------------------------------------------------------ */

/*FY Actuals  */
export const fyActualsObjectKeys = [{ key: 'value', title: 'Value (INR)', decimalPlaces: 2 }, { key: 'volume', title: 'Volume(Nos)', decimalPlaces: 2 }]

export const fyActualsKeys = ['', 'account', 'brand', 'brandform', 'sku', ...fyActualsObjectKeys];

export const pyskulevelObjectKeys = [{ key: 'inr', title: 'Value', decimalPlaces: 2 }, { key: 'volume', title: 'Volume', decimalPlaces: 2 }]
export const pyskulevelKeys = ['', 'brand', 'brandform', 'sku', ...months.map(m=> {
    return {
        key: m, title: capitalize(m), subTitle: '', isPercent: true , subKeys : pyskulevelObjectKeys
    }
})];

let fyActualSubKeys = [{ key: 'value', title: 'Value', decimalPlaces: 2 }, { key: 'volume', title: 'Volume', decimalPlaces: 2 }]

export const omniFYActualKeys = ['','account' , 'brand', 'tsBrand', 'brandform', 'tsSku', 'subBrandForm', ...months.map(m=> {
    // capitalize(m)
    return {
        key: m, title: m.toUpperCase(), subTitle: '' , subKeys : fyActualsObjectKeys
    }
})];

export const lyMetricKeys = ['','brand' , 'metric', 'uom', 'FY', 'jul', 'aug', 'sep', 'JAS', 'oct', 'nov', 'dec', 'OND', 'jan', 'feb', 'mar', 'JFM', 'apr', 'may', 'jun', 'AMJ', 'h1', 'h2'];

export const offtakeBrandVSODKeys = ['', 'account', 'brand', 'eventName', ...months]

export const retailBrandVSODKeys = ['', 'account', 'brand', 'eventName', ...months]

export const tsSkuSalienceKeys = ['', 'account', 'category', 'tsSku', 'metric', 'lastYearSalience', 'past3mSalience', 'fySalience', ...months]


export const skuLevelOfftakeSub = [{ key: 'units', title: 'Units (Nos)', decimalPlaces: 2 }, { key: 'offtake', title: 'Offtake (INR)', decimalPlaces: 2 }]

export const SKULevelOfftakeKeys = ['','account' , 'brand', 'tsBrand', 'brandform', 'tsSku', 'subBrandForm', ...months.map(m=> {
    return {
        key: m, title: m.toUpperCase(), subTitle: '' , subKeys : skuLevelOfftakeSub
    }
})];

export const omniMasterClassificationObjectKeys = [{key: 'kbd', title: 'KBD', dataType: 'string'}]
export const masterClassificationKeys = omniMasterClassificationObjectKeys.map(k=> ({ key: k.key, title: capitalize(k.title), subKeys: months.map(m=> ({ key: m, title: m.toUpperCase() })) }))
export const omniMasterClassificationKeys = ['', 'brand', 'tsBrand', 'brandform', 'tsSku', 'subBrandForm', ...masterClassificationKeys]

export const retailOfftakeSubKeys = [{ key: 'offTakeValue', title: 'Offtake<br/> Value<br/> (INR Cr)', decimalPlaces: 2 }, { key: 'or', title: 'OR', decimalPlaces: 2 },  { key: 'retailingValue', title: 'Retailing<br/> Value<br/> (INR Cr)', decimalPlaces: 2 }]

export const omniRetailingOffTakePlanningKeys = ['', 'account', 'brand', ...months.map(m=> {
    return {
        key: m, title: m.toUpperCase(), subTitle: '' , subKeys : retailOfftakeSubKeys
    }
})]


export const fyActualsObjectKeys1 = (mon, account, str) => {
    var isNewYear = false;
    return mon.map((m, index)=> {
        let year = Number(account.FinancialYear);
        if(['jul', 'aug', 'sep', 'oct', 'nov', 'dec'].includes(mon[0])){
            year-=1
        }
        let title = ''
        if((m === 'jan' && index!==0) || isNewYear){
            year+=1
            isNewYear = true;
            title = capitalize(m) + " " + (year + "").substring(2, 4)
        } else {
            title = capitalize(m) + " " + (year + "").substring(2, 4)
        }
        return { 
            key: m, 
            title: title, 
            decimalPlaces: 2,
            subKeys: str === 'claims' ? claimObjectKeys : fyActualSubKeys
         }
    })
}

export const fyActualHeadingKeys = (account) => ['', 'account', 'brand', 'brandform', 'sku',
    ...fyActualsObjectKeys1([...getMRMonths(account), ...months.filter(m => !getMRMonths(account).includes(m))], account, 'fyActual')];
    

export const pyskulevelHeadings = (account) => ['', 'brand', 'brandform', 'sku',
    ...fyActualsObjectKeys1([...months.filter(m => !getMRMonths(account).includes(m)), ...getMRMonths(account)], account, 'pyskulevel')];

export const lastFFMonths = (account) => months.filter(m => !getMRMonths(account).includes(m))

export const claimHeadingKeys = (account) => ['', 'custName', 'month', 'year', 'subBrandForm', 'productId', 'eventName', 'asp', 'minQty', 'minPromo%', 'claimAmount', 'promoValue'];
export const claimHeadingTotalKeys = (account) => ['month', 'claimPromoValue', 'promoCalanderValue', 'difference'];

/* ------------------------------------------------------------------------------------------------------ */


/* Fy actuals PFF keys */

export const fyActualspffKeys = ['', 'brand', 'brandform', 'metric', { key: 'months', title: 'Months', subKeys: months.map(i=> ({ key: i, title: capitalize(i)})) },'total']
/* ------------------------------------------------------------------------------------------------------ */


export const ObjectKeyTypes = {
    skuMaster: skuMasterObjectKeys,
    ittMaster: ittMasterObjectKeys,
    fyActuals: fyActualsObjectKeys,
    fyActualspff: [{ key: 'months', title: 'Months' }],
    pyskulevel: pyskulevelObjectKeys,
    claims: claimObjectKeys,
    skulevelofftake: skuLevelOfftakeSub,
    pyfyActuals: fyActualsObjectKeys,
    omniMasterClassification: omniMasterClassificationObjectKeys,
    omniRetailingOffTakePlanning: retailOfftakeSubKeys
}


export const KeyTypes = {
    skuMaster: skuMasterKeys,
    ittMaster: ittMasterKeys,
    fyActuals: fyActualsKeys,
    fyActualHeadingKeys,
    fyActualspff: fyActualspffKeys,
    pyskulevel: pyskulevelKeys,
}

export const OmniChannelKeyTypes = {
    skuMaster: omniChannelSkuMasterKeys,
    ittMaster: ittMasterKeysOmniChannel,
    pidMapping: pIDMapping,
    claims: claimsKeys,
    claimHeadingTotalKeys,
    claimHeadingKeys,
    fyActuals: omniFYActualKeys,
    skulevelofftake: SKULevelOfftakeKeys,
    pyfyActuals: omniFYActualKeys,
    omniMasterClassification: omniMasterClassificationKeys,
    lymetric: lyMetricKeys,
    offtakeBrandVSOD: offtakeBrandVSODKeys,
    retailBrandVSOD: retailBrandVSODKeys,
    omniRetailingOffTakePlanning: omniRetailingOffTakePlanningKeys,
    tsSkuSalience: tsSkuSalienceKeys
}

export const actualForcastMonths = (account) => {
    let obj = {};
    months.forEach(m => {
        obj[m] = isMonthForecast(m, account)
    })
    return obj;
}
