
import { hot } from 'react-hot-loader/root'
import React, { useEffect, useState, useRef } from 'react'
import {
	Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid,
	Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
	DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import { request } from 'modules/client'
import '../ibcsupportingdocument.scss'
// import './copy/custom.min.css'
import '../ext-content/bootstrap.scss'
import '../ext-content/custom.min.scss'
import '../ext-content/mystyles.scss'
import '../ext-content/jqcloud.scss'
import '../ext-content/basic.scss'
import '../ext-content/dropzone.scss'
import moment from 'moment';
import Slide from '@material-ui/core/Slide';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

//TODO: remove extra files from ext-content  folder

import Loading from '../ext-content/images/loading.gif'
import { getEntity } from 'store/actions/app'


import SummaryInvoice from './SummaryInvoice'
import AuditTrail from './AuditTrail'
import PaymentDialog from './dialogs/PaymentDialog'
import MemberListDialog from './dialogs/MemberListDialog'
import ConfirmDialog from './dialogs/ConfirmDialog'
import SendReportDialog from './dialogs/SendReportDialog'
import MembersList from './MembersList/index';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	pagination: {
		'& .MuiTablePagination-root': {
			backgroundColor: "#242323 !important",
			color: "white !important",

		},
		'& .MuiSelect-icon': {
			color: "white !important",
			top: '0px !important',
			right: "-5px !important"

		},
		'& .MuiIconButton-root.Mui-disabled': {
			color: "white !important"
		},
		textField: {
			paddingRight: "10px",
			marginTop: "0px",
			marginBottom: "16px",
			borderRadius: 0
		}

	},
	paymentDialog: {
		height: "60vh",
		width: "800px",
		margin: '0px auto',
		textAlign: 'left'

	},
	confirmationDialoge: {
		'& .MuiDialog-paperWidthSm': {
			height: '416px',
			width: '600px'
		}
	},
	para: {
		fontSize: '28px !important',
		fontWeight: 600,
		color: '#000'
	},
	modalAction: {
		'& button': {
			background: 'rgb(33, 128, 172)',
			color: '#fff'
		}
	},
	filtersContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: '10px',
	},
	filterContainer: {
		display: 'flex',
		alignItems: 'center',
		margin: '0px 20px 20px 0px',
		'& span': {
			color: '#fff'
		},
		'& select': {
			color: "white",
			height: '50px',
			marginLeft: '5px',
			background: "#000",
			padding: '10px 15px',
			height: 'auto !important',
		}
	},
	actionBtnsContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end'
	},
	btnContainer: {
		display: 'flex',
		margin: '0 10px',
		'& a': {
			padding: '8px 12px !important'
		}
	}
}));

let groups = [
	{ name: 'Group Benefits', plans: ['Basic Life', 'AD&D', 'LTD', 'STD'], ERPaidPercentage: "ER Paid 100% ", subTotal: 'Total Group Premium Amount', spData: [] },
	{ name: 'Services', plans: ['FMLA/ADA Administration'], ERPaidPercentage: "", subTotal: 'Total Services Premium Amount', spData: [] },
	{ name: 'Voluntary Benefits', plans: ['Employee Optional Life', 'Spouse Life', 'Child Life', 'Dependent Life'], ERPaidPercentage: "ER Paid 0%", subTotal: 'Total VB Premium Amount', spData: [] }
]

let amountColumns = ['Base Annual Salary', 'Annual Bonus', 'Earnings for Life and ADD',
	'Basic Life Volume', 'Basic Life Prem', 'AD&D Volume', 'AD&D Prem',
	'Earnings for LTD', 'LTD Volume', 'LTD Prem', 'Earnings For STD', 'STD Volume', 'STD Prem',
	'FMLA/ADA Administration', 'Supp EE Life Volume', 'Employee Optional Life Prem', 'Employee Optional Life Volume',
	'Supp SP Life Volume', 'Spouse Life Volume', 'Spouse Life Prem',
	'Child Life Volume', 'Supp CH Life Volume', 'Child Life Prem',
	'EE Contribution', 'ER Contribution', 'Credit', 'Debit', 'Total Premium']

const SupportingDocument = (props) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const [resData, setResData] = useState([])
	const inputRef = useRef(null)
	const tableRef = useRef(null);
	const classes = useStyles();
	const [pageCount, setPageCount] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [page, setPage] = React.useState(0);
	const [members, setMemberList] = useState([]);
	const [type, setType] = useState('suppotingdoc')
	const [workflow, setWorkflow] = useState([]);
	const [workflowId, setWorkflowId] = useState([]);
	const [total, setTotal] = useState(0);
	const [EETotal, setEETotal] = useState(0);
	const [ERTotal, setERTotal] = useState(0);
	const [Credit, setCredit] = useState(0);
	const [Debit, setDebit] = useState(0);
	const [groupTotals, setGroupTotals] = useState(0);
	const memberTableRef = useRef(null);
	const [employerCase, setEmployerCase] = useState({});
	const [payBillShow, setPayBillShow] = React.useState(true);
	const [alertMessageText, setAlertMessage] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [isPaymentOpen, setPaymentOpen] = React.useState(false);
	const [productList, setProductList] = React.useState([]);
	const [classList, setClassList] = React.useState([]);
	const [paymentDate, setPaymentDate] = React.useState("");
	const [bankAccounts, setBankAccounts] = React.useState([]);
	const [bankAccount, setBankAccount] = React.useState("");
	const [paymentType, setPaymentType] = React.useState("PayNow");
	const [paymentSuccess, setPaymentSuccess] = React.useState(false);
	const [billGroupStringData, setBillGroupStringData] = React.useState("");
	const [contributionType, setContributionType] = React.useState("All");
	const [selectedProduct, setSelectedProduct] = React.useState("All");
	const [selectedClass, setSelectedClass] = React.useState("All");
	const [confirmFinalize, setConfirmFinalize] = React.useState(false);
	const [selectedAgeBand, setSelectedAgeBand] = React.useState("All");

	const [showMemberDeails, setShowMemberDetails] = React.useState(false);
	const [selectedMember, setSelectedMember] = React.useState({});
	const [sendDocumentOpen, setSendDocumentOpen] = React.useState(false);
	const [memberDetailsEmployer, setMemberDetailsEmployer] = React.useState([]);
	const [ageBandList, setAgeBandList] = React.useState([]);
	const [billGroupType, setBillGroupType] = useState('')
	const [billGroups, setBillGroupsList] = useState([]);

	const numberFormat = (number, decimals, dec_point, thousands_sep) => {
		var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			toFixedFix = function (n, prec) {
				// Fix for IE parseFloat(0.55).toFixed(0) = 0;
				var k = Math.pow(10, prec);
				return Math.round(n * k) / k;
			},
			s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		return s.join(dec);
	}

	const segregatePlans = (d) => {
		let gs = JSON.parse(JSON.stringify(groups))
		let data = JSON.parse(JSON.stringify(d))
		data.document.map(p => {
			gs.map((g, i) => {
				console.log("g, p ===>", g, p);
				if (g.plans.includes(p.plan)) {
					gs[i].spData.push(p)
				}
			})
		})
		let groupTotals = {}
		gs.map(g => {
			groupTotals[g.name] = g.spData.reduce((r, sp) => {
				console.log(r, sp.Premium, 'sp.Premium');
				return sp.Premium + r
			}, 0)
		})
		setGroupTotals(groupTotals)
		data.document = gs
		return data
	}

	const getSupportingDocument = () => {
		console.log('getSupportingDocument')
		const uri = API_BASE_URL + "/custom/supportingDocument/" + props.match.params.pageDocumentId
		var d = new Date();
		d.setMonth(d.getMonth() - 1);
		d.setDate(1)
		request(uri, {
			method: 'GET',
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log("getSupportingDocument", res)
			setLoading(false)
			setData(segregatePlans(res));
			setResData(res)
			if (res.PaymentStatus ? res.PaymentStatus.toLowerCase() : "" === "paid") {
				setPayBillShow(false);
			}
			setTotal(res.total)
			setEETotal(res.EETotal)
			setERTotal(res.ERTotal)
			setCredit(res.Credit)
			setDebit(res.Debit)
			setBillGroupStringData(res.billGroupName);
			console.log('res.workflow', res.workflow);
			if (!res.workflow) {
				getWorkflowByName('ibc-wf-planadmin-carrier-supportingdocument').then(res => {
					if (res && res[0]) {
						setWorkflowId(res[0]._id)
						getWorkflowEventStart(res[0]._id)
					}
				})
					.catch(err => {
						console.log(err);
					})
			}

		}).catch(err => {
			console.log(err);
			setLoading(false)
		})
	}

	const setBGType = (e) => {
		setBillGroupType(e.target.value)
		let doc = billGroups.billGroupsSupportingDocumentsObj[e.target.value]
		// setBillGroupsList(billGroups)
		// setLoading(false)
		setData(segregatePlans(doc));
		setResData(doc)
		if (doc.PaymentStatus ? doc.PaymentStatus.toLowerCase() : "" === "paid") {
			setPayBillShow(false);
		} else {
			setPayBillShow(true);
		}
		setTotal(doc.total)
		setEETotal(doc.EETotal)
		setERTotal(doc.ERTotal)
		setCredit(doc.Credit)
		setDebit(doc.Debit)
		setBillGroupStringData(doc.billGroupName);
		setMemberList(billGroups.billGroupsData[e.target.value])
	}

	const getSupportingDocumentByUID = () => {
		console.log('getSupportingDocument')
		const uri = API_BASE_URL + "/custom/supportingDocument/" + props.match.params.pageDocumentId + "/uid"
		var d = new Date();
		d.setMonth(d.getMonth() - 1);
		d.setDate(1)
		request(uri, {
			method: 'GET',
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log("getSupportingDocument", res)
			setLoading(false)
			if (res.billGroups && res.billGroups.billGroupNames.length > 0) {
				let doc, billGroupName

				res.billGroups.billGroupNames.map(bg => {
					if (res.billGroups.billGroupsSupportingDocumentsObj[bg]._id === props.match.params.pageDocumentId) {
						billGroupName = bg
						doc = res.billGroups.billGroupsSupportingDocumentsObj[bg]
					}
				})
				setBillGroupType(billGroupName)
				setBillGroupsList(res.billGroups)
				// setLoading(false)
				setData(segregatePlans(doc));
				setResData(doc)
				if (doc.PaymentStatus ? doc.PaymentStatus.toLowerCase() : "" === "paid") {
					setPayBillShow(false);
				}
				setTotal(doc.total)
				setEETotal(doc.EETotal)
				setERTotal(doc.ERTotal)
				setCredit(doc.Credit)
				setDebit(doc.Debit)
				setBillGroupStringData(doc.billGroupName);
				console.log('doc.workflow', doc.workflow);

				setMemberList(res.billGroups.billGroupsData[billGroupName]);

				if (!doc.workflow) {
					getWorkflowByName('ibc-wf-planadmin-carrier-supportingdocument').then(res => {
						console.log('ibc-wf-planadmin-carrier-supportingdocument res ===?', res);
						if (res && res[0]) {
							setWorkflowId(res[0]._id)
							getWorkflowEventStart(res[0]._id)
						}
					})
						.catch(err => {
							console.log(err);
						})
				}

			} else if (res && res._id) {
				// setLoading(false)
				setData(segregatePlans(res));
				setResData(res)
				if (res.PaymentStatus ? res.PaymentStatus.toLowerCase() : "" === "paid") {
					setPayBillShow(false);
				}
				setTotal(res.total)
				setEETotal(res.EETotal)
				setERTotal(res.ERTotal)
				setCredit(res.Credit)
				setDebit(res.Debit)
				setBillGroupStringData(res.billGroupName);
				console.log('res.workflow', res.workflow);
				if (!res.workflow) {
					getWorkflowByName('ibc-wf-planadmin-carrier-supportingdocument').then(res => {
						console.log('ibc-wf-planadmin-carrier-supportingdocument res ===?', res);
						if (res && res[0]) {
							setWorkflowId(res[0]._id)
							getWorkflowEventStart(res[0]._id)
						}
					})
						.catch(err => {
							console.log(err);
						})
				}
			} else {
				setAlertMessage("Unable to fetch sumary invoice, please try again");
				handleClickOpen();
			}
		}).catch(err => {
			console.log(err);
			setLoading(false)
		})
	}

	const getWorkflowByName = async (name) => {
		// const uri = API_BASE_URL + "/workflows/getByName/" + name;
		const uri = `${API_BASE_URL}/workflows/getByName/${name}`;
		return request(uri, {
			method: 'GET',
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log("getWorkflowByName ===", res);
			// setMemberList(res)
			return res
		})
		// .catch(err => {
		// 	console.log(err);
		// })
	}

	const getProductList = (products) => {
		if (!products) {
			return [];
		}
		const pList = products.reduce((acc, curr) => {
			const product = curr[0];
			if (!acc.includes(product)) {
				acc.push(product);
			}
			return acc;
		}, []);
		const cList = products.reduce((acc, curr) => {
			console.log('curr ==', curr);
			const className = curr.find(c => {
				console.log('c === ', c);
				return c.toLowerCase().includes('class')
			});
			console.log('className ===', className);
			if (!className || !acc.includes(className)) {
				acc.push(className);
			}
			return acc;
		}, []).filter(c => !!c);
		setProductList(pList);
		setClassList(cList);
		console.log('cList ==<>==', cList);
	}

	const getAgeBandList = (products) => {
		if (!products) {
			return [];
		}
		const ageList = products.reduce((acc, curr) => {
			const className = curr.find(c => c.toLowerCase().includes('under') || c.toLowerCase().includes('to') || c.toLowerCase().includes('over'));
			if (!className || !acc.includes(className)) {
				acc.push(className);
			}
			return acc;
		}, []).filter(c => !!c);
		setAgeBandList(ageList)
	}

	const getWorkflowEventStart = (workflowId) => {
		const uri = `${API_BASE_URL}/workflows/execute/${workflowId}/events/start`;
		console.log("	", uri)
		return request(uri, {
			method: 'GET',
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log("execute ===", res);
			// setMemberList(res)
			setWorkflow(res)
			return res
		}).catch(err => {
			console.log("========err", err)
			console.log(err);
		})
	}

	const getMembersList = () => {
		console.log('getSupportingDocument')
		const uri = API_BASE_URL + "/custom/supportingDocument/" + props.match.params.pageDocumentId + "/membersdata"
		request(uri, {
			method: 'GET',
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log(res)
			setLoading(false)
			setMemberList(res);
		}).catch(err => {
			console.log(err);
			setLoading(false)
		})
	}

	const getMemberDetailsByEmployeeId = (item) => {
		console.log('getMemberDetails', selectedMember)
		const uri = API_BASE_URL + "/custom/supportingDocument/employer/" + item.EmployerID
		request(uri, {
			method: 'GET',
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			setLoading(false)
			setMemberDetailsEmployer(res);
		}).catch(err => {
			console.log(err);
			setLoading(false)
		})
	}

	const payBill = () => {
		console.log('payBill', props.user);
		// handlePaymentClose(false);
		let obj = {
			actions: [],
			appId: props.match.params.appid,
			data: {
				AmountDue: "0.00",
				BankAccount: bankAccount ? bankAccount : bankAccounts[0].BankAccountName,
				BillingPeriod: moment().subtract(1, 'months').format('MMM YYYY'),
				// BillingPeriod: moment(data.BillingPeriod),
				CaseProcessor: employerCase.CaseProcessor,
				InvoiceDate: moment(data.createdDateTime).format("MM/DD/YYYY"),
				InvoiceNumber: data.InvoiceNumber || data._id,
				PaymentDate: moment(new Date()).format("MM/DD/YYYY"),
				PaymentStatus: "Paid",
				PlanAdministrator: employerCase.PlanAdministrator,
				TotalPremiumAmountBilled: total,
				TotalPremiumAmountPaid: total,
				payLaterDate: paymentDate ? paymentDate : "",
				PaymentMethod: "ACH"
			},
			dataType: {
				AmountDue: { type: "String" },
				BankAccount: { type: "String" },
				BillingPeriod: { type: "String" },
				CaseProcessor: { type: "String" },
				InvoiceDate: { type: "String" },
				InvoiceNumber: { type: "String" },
				PaymentDate: { type: "String" },
				PaymentStatus: { type: "String" },
				PlanAdministrator: { type: "String" },
				TotalPremiumAmountBilled: { type: "String" },
				TotalPremiumAmountPaid: { type: "String" }
			},
			name: "Payment",
			sys_current_user: props.user.id,
			workflow: {}
		};
		const uri = API_BASE_URL + "/submittedData/"
		axios({
			url: uri,
			method: 'POST',
			data: obj,
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
			},
		}).then((response) => {
			console.log("data", response);
			console.log('resData ===>', resData);
			updateSupportingDocument({
				...resData, PaymentStatus: "Paid",
				PaymentId: response.data._id, status: "Invoice Paid"
			}, () => {
				setPayBillShow(false);
				setPaymentSuccess(true);
			});
		}).catch((error) => {
			console.log("error", error);
			setAlertMessage("Payment Failed");
			handleClickOpen();
		})
	}

	const updateSupportingDocument = (obj, callback) => {
		console.log('payBill', props.user);
		const uri = API_BASE_URL + "/custom/supportingDocument/" + obj._id
		axios({
			url: uri,
			method: 'PUT',
			data: obj,
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
			},
		}).then((response) => {
			console.log("data", response);
			setData(segregatePlans(response.data));
			setResData(response.data)
			if (callback) {
				callback();
			}
			// setSupportingDoc(response.data);

		}).catch((error) => {
			console.log("error", error);
			setAlertMessage("Payment Failed");
			handleClickOpen();
		})
	}

	const getBankAccountDetails = () => {
		console.log('getCaseData');
		let conditions = [
			// 	{
			// 	"selectedConditionDataElement": "EmployerName",
			// 	"selectedConditionOperator": "matches",
			// 	"value": props.entity.name,
			// 	"selectedConditionClause": "and"
			// }
		];
		getData('BankAccount', 10, conditions, (res) => {
			console.log('res == => bank', res)
			if (res) {
				setBankAccounts(res)
			} else {
				// alert("You don't have any existing plans, Please contact your case processer.")
			}
		})
	}

	const downloadReport = () => {
		console.log("downloadReport", "fire", tableRef.current);
		tableToExcel(tableRef.current, 'Sheet 1', "Summary-Invoice-" + data?._id + "-" + moment().subtract(1, 'months').format('MMM YYYY') + '.xlsx')
	}

	const tableToExcel = (function () {
		//TODO: Need to replace code with react excel releated library.
		var uri = 'data:application/vnd.ms-excel;base64,',
			template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
			base64 = function (s) {
				return window.btoa(unescape(encodeURIComponent(s)))
			}, format = function (s, c) {
				return s.replace(/{(\w+)}/g, function (m, p) {
					return c[p];
				})
			}
		return function (table, name, filename) {
			if (!table.nodeType) table = document.getElementById(table)
			var ctx = {
				worksheet: name || 'Worksheet',
				table: table.innerHTML
			}
			document.getElementById("dlink").href = uri + base64(format(template, ctx));
			document.getElementById("dlink").download = filename;
			document.getElementById("dlink").traget = "_blank";
			document.getElementById("dlink").click();
		}
	})();


	const getData = (collectionName, pageCount, conditions, setResponse) => {

		const uri = API_BASE_URL + "/dataconnect/data/" + collectionName
			+ "?" + (conditions ? "conditions=" + JSON.stringify(conditions) + "&" : '')
			+ "page=0&rowsPerPage=" + (pageCount ? pageCount : 10)

		axios({
			url: uri,
			method: 'GET',
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
				// appId: "5f2ab15279813563bcaa3887"
			},
		}).then((response) => {
			console.log("data", response)
			setResponse(response.data.data);


		}).catch((error) => {
			setResponse({ "error": error });
			// console.log("error", error)
		})
	}

	const getCaseData = () => {
		console.log('getCaseData');
		let conditions = [{
			"selectedConditionDataElement": "EmployerName",
			"selectedConditionOperator": "matches",
			"value": props.entity.name,
			"selectedConditionClause": "and"
		}];

		getData('Case', 1, conditions, (res) => {
			console.log('res == =>', res)
			if (res && Array.isArray(res) && res[0]) {
				let c = res[0]
				setEmployerCase(c)
			} else {
				// alert("You don't have any existing plans, Please contact your case processer.")
			}
		})
	}

	useEffect(() => {
		if (props.entity.name)
			getCaseData();
		getBankAccountDetails();
	}, [props.entity])

	useEffect(() => {
		if (employerCase) {
			getProductList(employerCase.Products);
			getAgeBandList(employerCase.Products);
		}
	}, [employerCase])

	useEffect(() => {
		// getSupportingDocument();
		getSupportingDocumentByUID();
		getMembersList();
		props.getEntity(props.member.entityId, props.match.params.appid)
	}, [])

	const onPaymentDateChange = (e) => {
		setPaymentDate(e.target.value);
	}

	const downloadMemberList = () => {
		console.log('downloadMemberList', members);
		tableToExcel(memberTableRef.current, 'Sheet 1', "Member-List-" + moment().subtract(1, 'months').format('MMM YYYY') + '.xlsx')
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const numFormat = (value) => {
		return '$ ' + (value ? value : '0').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}


	const sendToInsuranceCarrierStep1 = (e) => {
		console.log('sendToInsuranceCarrierStep1');
		const uri = API_BASE_URL + "/custom/supportingDocument/updateWorkflow";
		const { id, name, status, action, respondentfield, notification } = workflow;
		const workflowObj = {
			workflowId,
			currentTaskId: id,
			currentTaskName: name,
			currentStatus: status,
			currentAction: action,
			currentRespondentField: respondentfield,
			currentRespondent: employerCase[workflow.respondentfield],
			notification
		}
		return request(uri, {
			method: 'POST',
			payload: {
				documentId: resData._id,
				workflow: workflowObj,
				vendor: props.entity.name,
				policy: employerCase?.ControlNumber,
				billingGroup: resData?.billGroupName,
				employerName: resData?.EmployerName,
				status: "Invoice Submitted"
			},
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log("sendToInsuranceCarrier", res);
			setSendDocumentOpen(true)
			getSupportingDocument();
		}).catch(err => {
			console.log(err);
		})
	}

	const sendToInsuranceCarrier = (e) => {
		console.log('sendToInsuranceCarrier', data);
		const uri = API_BASE_URL + "/custom/supportingDocument/updateWorkflow";
		const { currentTaskId, currentTaskName, currentStatus, currentAction, notification, workflowId, currentRespondentField } = data.workflow;
		const workflowObj = {
			workflowId,
			currentTaskId,
			currentTaskName,
			currentStatus,
			currentAction,
			currentRespondentField,
			currentRespondent: employerCase[currentRespondentField],
			notification
		}
		return request(uri, {
			method: 'POST',
			payload: {
				documentId: resData._id,
				workflow: workflowObj,
				myTaskId: resData.myTaskId,
				vendor: props.entity.name,
				policy: employerCase?.ControlNumber,
				billingGroup: resData?.billGroupName,
				employerName: resData?.EmployerName
			},
			headers: { "appId": props.match.params.appid }
		}).then(res => {
			console.log("sendToInsuranceCarrier", res);
			setSendDocumentOpen(true)
		}).catch(err => {
			console.log(err);
		})
	}

	const finalizePayment = (e) => {
		console.log('finalizePayment');
		updateSupportingDocument({ ...resData, finalize: true, status: "Invoice Finalized" }, null);
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handlePaymentClickOpen = () => {
		setPaymentOpen(true);
	};

	const handlePaymentClose = () => {
		setPaymentOpen(false);
	};

	const handleSendReportClose = () => {
		setSendDocumentOpen(false)
	}

	const convertToNumber = (value, point) => {
		if (value) {
			return Number(parseFloat(('' + value).replace(/,/g, '').trim()).toFixed(point || point === 0 ? point : 3))
		}
		else return 0
	}

	const getDashletPremium = (documentName, optionName) => {
		if (!data || !data.document) {
			return 0;
		}
		const document = data.document.find(({ name }) => name === documentName);
		if (!document || !document.spData) {
			return 0;
		}
		const plan = document.spData.find(({ plan }) => plan === optionName);
		if (!plan) {
			return 0;
		}
		return numFormat(convertToNumber(plan.Premium, 3));
	};

	const handleMemberClick = (item) => {
		// alert("working")
		console.log("item", item);
		setSelectedMember(item);
		getMemberDetailsByEmployeeId(item);
		setShowMemberDetails(true);
		setMemberDetailsEmployer([])

	}

	console.log('props.member ===> ', props.member);

	const isActionButtonVisible = () => {
		let isVisible = false
		props.member && props.member.selectedRoles.map((item) => {
			if (item.label === "Plan Administrator") {
				isVisible = true
			}
		})
		return isVisible
	}

	const checkMemberAgeBand = (item) => {
		let isValid = false;
		if (selectedAgeBand === "All") {
			isValid = true;
		} else if (generateAgeBand(selectedAgeBand, item["Age"])) {
			isValid = true
		}
		return isValid;
	}

	const generateAgeBand = (band, age) => {
		let obj = {}
		if (band === "All") {
			obj = {
				type: "range",
				band: band,
				min: v[0],
				max: v[1],

			}
		}
		console.log('band, rate', band);
		let v = band.split('to')
		if (v.length > 1) {
			v = v.map(b => b.trim())
			obj = {
				type: "range",
				band: band,
				min: v[0],
				max: v[1],

			}
		} else {
			v = band.split('Under')
			if (v.length > 1) {

				v = v[1].trim()
				obj = {
					type: "range",
					band: band,
					min: 0,
					max: v,

				}
			} else {
				let z = band.split('Over')
				if (z.length <= 1)
					z = band.split('Above')
				v = z[1].trim()
				obj = {
					type: "range",
					band: band,
					min: v,
					max: 100,

				}
			}
		}
		return age >= obj.min && age <= obj.max

	}

	return (<>
		<div className='use-bootstrap ibc ibcsupportingdocument'>
			<a id="dlink" style={{ display: "none" }}></a>
			<div style={{ "marginTop": "5px", overflowX: 'hidden' }}>
				<div className={`main ${isIBC ? 'ibc-main' : ''}`} style={{ "backgroundColor": "#231F20", "minHeight": "calc(100vh - 170px)" }}>
					<ul className={`breadcrumb ${isIBC ? 'breadcrumb-black' : ''}`}>
						<li><a href={`/apps/${props.match.params.appid}/ibcDashboard`}>Dashboard</a></li>
						<li className="active">Summary Invoice</li>
					</ul>
					<div className="row">
						<div className="col-lg-10 col-md-offset-1" style={{ marginTop: '10px', padding: '0px' }}>
							<h3 className={isIBC ? 'ibc-main-h3' : ''} style={{ display: 'inline', padding: '30px 0 20px 0' }}>{"Summary Invoice # : " + (data?.InvoiceNumber || data?._id)}</h3>
							{/* <a className={`btn btn-lg ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} style={{ marginLeft: "60px", cursor: "pointer" }}
								onClick={e => downloadReport()}
							>Download Report</a> */}
						</div>
						<div className="col-lg-10 col-md-offset-1" style={{ padding: '0px' }}>
							<hr className={isIBC ? 'ibc-hr' : 'hr-line'} />
						</div>
						{/* <div className="row" id="upload">
							<div className="col-lg-12" style={{ paddingLeft: "120px", marginTop: "0px" }}>
								<span style={{ color: "#fff", fontSize: "1.6em", marginRight: "1050px" }}>{props.entity && props.entity.name}</span>
								<span style={{ color: "#fff", fontSize: "1.6em", textAlign: "right" }}>
									{"Reporting Period: " + moment().subtract(1, 'months').format('MMM YYYY')}</span>
							</div>
						</div> */}

						{loading && <div className="loading" style={{ marginLeft: "500px", marginTop: "100px", color: '#000' }}>
							<h3 style={{ color: '#000' }}>Loading data.....</h3>
							<img src={Loading} />
						</div>}
						{!loading && <>
							<div className="row">
								<div className={`col-lg-10 col-md-offset-1 ${classes.filtersContainer}`}>
									<div className={isIBC ? 'ibc-filter-container' : classes.filterContainer}>
										<select native defaultValue="" name="supporting document selection" variant="outlined"
											onChange={(e) => { console.log("e", e); setType(e.target.value) }} value={type}>
											<option value="suppotingdoc" style={{ backgroundColor: "#000" }}>Summary Invoice</option>
											<option value="memberslist" style={{ backgroundColor: "#000" }}>Members List</option>
										</select>
									</div>
									{type === "memberslist" &&
										<div className={isIBC ? 'ibc-filter-container' : classes.filterContainer}>
											<span>Contribution Type :</span>
											<select native defaultValue="" name="supporting document selection" variant="outlined" onChange={(e) => {
												console.log("e", e);
												setContributionType(e.target.value);
												setSelectedProduct('All');
											}} value={contributionType}>
												<option value="All" style={{ backgroundColor: "#000" }}>All</option>
												<option value="ER-contribution" style={{ backgroundColor: "#000" }}>{"ER Contribution"}</option>
												<option value="EE-contribution" style={{ backgroundColor: "#000" }}>{"EE Contribution"}</option>
											</select>
										</div>
									}
									{type === "memberslist" &&
										<div className={isIBC ? 'ibc-filter-container' : classes.filterContainer}>
											<span>Product List :</span>
											<select native defaultValue="" variant="outlined" onChange={(e) => {
												setSelectedProduct(e.target.value);
												setContributionType('All');
											}} value={selectedProduct}>
												<option value="All" style={{ backgroundColor: "#000" }}>{"All"}</option>
												{
													productList.map((p, i) => {
														return <option key={i} value={p} style={{ backgroundColor: "#000" }}>{p}</option>
													})
												}
											</select>
										</div>
									}
									{type === "memberslist" &&
										<div className={isIBC ? 'ibc-filter-container' : classes.filterContainer}>
											<span>Class :</span>
											<select native defaultValue="" variant="outlined" onChange={(e) => { setSelectedClass(e.target.value) }}
												value={selectedClass}>
												<option value="All" style={{ backgroundColor: "#000" }}>All</option>
												{
													classList.map((c, i) => {
														return <option key={i} value={c} style={{ backgroundColor: "#000" }}>{c}</option>
													})
												}
											</select>
										</div>
									}
									{type === "memberslist" &&
										<div className={isIBC ? 'ibc-filter-container' : classes.filterContainer}>
											<span>Age Band :</span>
											<select native defaultValue="" variant="outlined" onChange={(e) => { setSelectedAgeBand(e.target.value) }}
												value={selectedAgeBand}>
												<option value="All" style={{ backgroundColor: "#000" }}>All</option>
												{
													ageBandList.map((c, i) => {
														return <option key={i} value={c} style={{ backgroundColor: "#000" }}>{c}</option>
													})
												}
											</select>
										</div>
									}
									{billGroups && billGroups.billGroupNames && billGroups.billGroupNames.length &&
										<div className={isIBC ? 'ibc-filter-container' : classes.filterContainer}>
											<span>Bill Groups :</span>
											<select native defaultValue="" name="supporting document selection" variant="outlined"
												onChange={(e) => { console.log("e", e); setBGType(e) }} value={billGroupType}>
												<option value="" style={{ backgroundColor: "#000" }}>{"Please select Bill Group"}</option>
												{billGroups.billGroupNames.map((opt, i) => <option value={opt} key={i} style={{ backgroundColor: "#000" }}>{opt}</option>)}
											</select>
										</div>
									}
									<div className={classes.actionBtnsContainer}>
										{resData.PaymentId &&
											<div className={`${classes.btnContainer} ${isIBC ? 'ibc-orangeBtnCon' : ''}`}>
												<a className={`btn btn-lg ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} target="_blank"
													href={`/apps/${props.match.params.appid}/form/Payment/${resData.PaymentId}`}
												>Payment</a>
											</div>
										}
										{(type === "suppotingdoc") && resData.finalize &&
											<div className={`${classes.btnContainer} ${isIBC ? 'ibc-orangeBtnCon' : ''}`}>
												<a className={`btn btn-lg ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} style={{}}
													onClick={e => downloadReport()}
												>Download Report</a>
											</div>
										}
										{(type === "memberslist") && resData.finalize &&
											<div className={`${classes.btnContainer} ${isIBC ? 'ibc-orangeBtnCon' : ''}`}>
												<a className={`btn btn-lg ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} style={{}}
													onClick={e => downloadMemberList()}
												>Download Members List</a>
											</div>
										}
										{
											employerCase && employerCase._id &&
											<div className={`${classes.btnContainer} ${isIBC ? 'ibc-orangeBtnCon' : ''}`}>
												<a className={`btn btn-lg ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} target="_blank" href={`/apps/${props.match.params.appid}/form/Case/${employerCase._id}`}>
													Employer Case</a>
											</div>
										}
									</div>
								</div>
							</div>
							<div id="supdoc" className="row" style={{ marginTop: "50px", marginBottom: "100px" }}>
								{/* <div className="col-lg-12"></div> */}
								<div className="col-lg-1" style={{ marginTop: "20px" }}></div>
								{type === "suppotingdoc" && <>
									<SummaryInvoice
										data={data?.document} employerCase={employerCase}
										billGroupType={data.billGroupType} BillingPeriod={data.BillingPeriod}
										total={total} ERTotal={ERTotal}
										EETotal={EETotal} groupTotals={groupTotals}
										Credit={data.Credit} Debit={data.Debit}
										entity={props.entity}
										respData={resData}
										tableRef={tableRef}
									/>
								</>}
								{/* <div className="col-lg-1" /> */}
								{type === "memberslist" && <>

									<MembersList members={members} memberTableRef={memberTableRef}
										selectedProduct={selectedProduct} contributionType={contributionType}
										selectedClass={selectedClass} checkMemberAgeBand={checkMemberAgeBand} page={page}
										rowsPerPage={rowsPerPage} handleMemberClick={handleMemberClick} numFormat={numFormat}
										classes={classes} handleChangePage={handleChangePage}
										handleChangeRowsPerPage={handleChangeRowsPerPage}
									/>

								</>}

								{(type === "suppotingdoc") && <div className="col-lg-12" style={{ textAlign: 'center', marginTop: "50px" }}>
									{isActionButtonVisible() && (resData.PaymentStatus ? resData.PaymentStatus.toLowerCase() : "" === "paid") && !resData.workflow && (data.workflow ? data.workflow.currentStatus !== "Approve" : true) &&
										<button className={`btn btn-lg ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} style={{ marginLeft: "50px" }} data-toggle="modal" data-target="#myModal"
											onClick={e => sendToInsuranceCarrierStep1()}
										>{workflow && workflow.action ? workflow.action : 'Send to Insurance Carrier'}</button>
									}
									{(resData && resData.workflow && resData.workflow.currentRespondent === props.user.id) && <button className={`btn btn-lg ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} style={{ marginLeft: "50px" }} data-toggle="modal" data-target="#myModal"
										onClick={e => sendToInsuranceCarrier()}
									>{resData.workflow.currentStatus}</button>}
									{!resData.finalize && <button className={`btn btn-lg ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} style={{ marginLeft: "50px" }} data-toggle="modal" data-target="#myModal"
										onClick={e => {
											setAlertMessage({
												p1: `Do you want to finalize this invoice for the billing period '${moment(resData.BillingPeriod).format('MMM YYYY')}'?`,
												p2: 'Please note that once you finalize the invoice can not be adjusted.'
											})
											handleClickOpen();
											setConfirmFinalize(true);
										}}
									>Finalize</button>}
									{resData.finalize && payBillShow && isActionButtonVisible() && <button className={`btn btn-lg ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} style={{ marginLeft: "50px" }} data-toggle="modal" data-target="#myModal"
										onClick={e => handlePaymentClickOpen()}
									>Pay the bill</button>}
								</div>}
							</div>
							<AuditTrail supportingDoc={data} tableRef={tableRef} />
						</>}
					</div>
				</div>
			</div>

			<PaymentDialog isPaymentOpen={isPaymentOpen} handlePaymentClose={handlePaymentClose} classes={classes}
				employerCase={employerCase} billGroupStringData={billGroupStringData} resData={resData}
				total={total} bankAccounts={bankAccounts} setBankAccount={setBankAccount} bankAccount={bankAccount}
				setPaymentType={setPaymentType} paymentType={paymentType} paymentDate={paymentDate} data={data}
				paymentSuccess={paymentSuccess} payBill={payBill}
			/>
			<MemberListDialog showMemberDeails={showMemberDeails} selectedMember={selectedMember}
				memberDetailsEmployer={memberDetailsEmployer} classes={classes} setShowMemberDetails={setShowMemberDetails} />

			<ConfirmDialog open={open} handleClose={handleClose} alertMessageText={alertMessageText}
				classes={classes} confirmFinalize={confirmFinalize} setConfirmFinalize={setConfirmFinalize}
				finalizePayment={finalizePayment} />
			{console.log('sendDocumentOpen=', sendDocumentOpen)}
			<SendReportDialog sendDocumentOpen={sendDocumentOpen} handleSendReportClose={handleSendReportClose}
				classes={classes} />

		</div >
	</>)
}
const mapDispatchToProps = {
	getEntity
};

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupportingDocument)));