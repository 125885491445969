import _ from 'lodash';
import moment from 'moment';

export const getCurrentFFForPayload = (month) => {
  if (('' + month).length === 1) {
    return '0' + month;
  }
  return `${month}`;
};

export const convertToNumber = (value, point) => {
  if (value) {
    let v = Number(parseFloat(('' + value).replace(/,/g, '')
      .trim())
      .toFixed(point ? 1 : 0));
    return !isNaN(v) ? v : 0;
  } else return 0;
};

export const getFiscalYearFormat = (year, isMinusOne) => {
  if (isMinusOne) {
    year = parseInt(year) - 1;
  }
  if (year) {
    let lastTwoDigit = `${year}`.slice(2);
    return Number(lastTwoDigit) - 1 + lastTwoDigit;
  }
  return "";
};


export const setStickyVertical = (hideBorder) => { // sometimes white border is visible to fix this adding hideBorder
  const fixedRows = document?.querySelectorAll('.sticky-row');
  if (fixedRows && (fixedRows?.length > 0)) {
    let nextHeight = 0;
    fixedRows.forEach((row, index) => {
      let bodyNextHeight = 0;
      bodyNextHeight = nextHeight;
      nextHeight += row?.offsetHeight;
      if (row?.style) {
        row.style.position = 'sticky';
        row.style.zIndex = 3;
        row.style.top = (hideBorder && index === 0) ? '2px' : (`${bodyNextHeight + ((index === 1 ? (hideBorder ? 1 : -1) : 0))}px`);
        if (index < 5) {
          if (index % 2) {
            row.style.backgroundColor = '#fff';
          } else {
            row.style.backgroundColor = '#F5F5F5';
          }
        }
      }
    });
  }
};

export const checkForBGProcesses = (type) => {
  return ['bopvolumes', 'rlppermsu', 'rlpsalience', 'rlpytdact'].includes(type)
}

export const uploadFileTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/x-csv',
  'application/csv',
  'text/csv',
  'text/x-csv',
  'text/tab-separated-values',
  'text/comma-separated-values',
  'text/x-comma-separated-values'
]

export const monthsShortForm = ['jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'may', 'jun']

export const NoAccountBrandMsg = "You don’t have the required role or privilege to complete this action. Please contact your administrator to get the necessary access.";

export const FullMonths = {
  'january': '01',
  'february': '02',
  'march': '03',
  'april': '04',
  'may': '05',
  'june': '06',
  'july': '07',
  'august': '08',
  'september': '09',
  'october': '10',
  'november': '11',
  'december': '12'
}

export const MonthsNumberMapping = {
  "January": '01', "February": '02', "March": '03', "April": '04', "May": '05',
  "June": '06', "July": '07', "August": '08', "September": '09', "October": '10', "November": '11', "December": '12'
}

export const monthsUpperCase = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December']


export const TSP_TITLE = 'Trade Spend Plan';
export const MR_TITLE = 'MR Spend Plan';
export const OMNI_TITLE = 'P&G MR, E-Comm & Omni Trade Spend Plan';
export const MR_CAPTION = 'Highly Restricted';

export const isActualColumns = (month, brand) => {
  return brand && brand.CurrentFF && monthsShortForm.indexOf(month) + 1 <= monthsShortForm.indexOf((moment().month(brand?.CurrentFF).format('MMM').toLowerCase()))
}

export const getPast12months = (CurrentFF) => {
  let month = moment().month(CurrentFF).format('MMM').toLowerCase();
  let ind = monthsShortForm.indexOf(month)
  let thisYearMonths = monthsShortForm.slice(0, ind);
  let lastYearMonths = _.takeRight(monthsShortForm, 12 - thisYearMonths.length)
  return [lastYearMonths, thisYearMonths];
}

export const isNotSuperAdmin = (member)=> {
  return !member?.selectedRoles.find(role=> role.label === 'SuperAdmin')
}

export const isDeleteData = (access) => {
  let mode = ['Edit', 'all', '*']
  return mode?.includes(access) ? true : false
}

export const isEditData = (page, config, roles) => {
  const userRole = roles ? roles : config?.member?.selectedRoles[0]?.label;
  const role = config?.roles.find(el => el?.name === userRole);
  const privilege = role?.privileges.find(el => el?.includes(page));
  return privilege?.includes(':Edit') || privilege?.includes(':all')
}

export const compare = (a, b) => {
  let comparison = 0;
  if (a.LinkListOrder > b.LinkListOrder) {
      comparison = 1;
  } else if (a.LinkListOrder < b.LinkListOrder) {
      comparison = -1;
  }
  return comparison;
}


export const acceptedFiles = ['application/vnd.ms-excel',
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
'application/*',
'application/x-csv',
'application/csv',
'text/x-csv',
'text/csv',
'.csv',
'text/x-comma-separated-values',
'text/comma-separated-values',
'text/tab-separated-values',
'text/plain',
'.xslx' ]