import _ from 'modules/lodash';

export function getFilteredArray(data, searchText) {
	if (searchText.length === 0) {
		return data;
	}
	return _.filter(data, item => item.name.toLowerCase().includes(searchText.toLowerCase()));
};

export function selectedRow(event, _id, selected, selectedIndex) {
	let newSelected = [];

	if (selectedIndex === -1) {
		newSelected = newSelected.concat(selected, _id);
	}
	else if (selectedIndex === 0) {
		newSelected = newSelected.concat(selected.slice(1));
	}
	else if (selectedIndex === selected.length - 1) {
		newSelected = newSelected.concat(selected.slice(0, -1));
	}
	else if (selectedIndex > 0) {
		newSelected = newSelected.concat(
			selected.slice(0, selectedIndex),
			selected.slice(selectedIndex + 1)
		);
	}
	return newSelected;
}

export function handleSelectedRow(event, _id, selected) {
	const selectedIndex = selected.indexOf(_id);
	return selectedRow(event, _id, selected, selectedIndex);
}

export function isRowselected(_id, selected) {
	return selected.indexOf(_id) !== -1;
}

export function requestSort(event, property, data) {
	const orderBy = property;
	let order = 'desc';
	if (data.orderBy === property && data.order === 'desc') {
		order = 'asc';
	}
	if (data.orderBy === property && data.order === 'asc') {
		order = 'desc';
	}
	return {
		order,
		orderBy
	};
};

export function handleTableSelectAllClick(event, data) {
	return event.target.checked ? data.map(n => n._id) : [];
};

export function getPageUrl(selectedLinkValue, pages) {
	let page = pages.find(p => {
		return p._id === selectedLinkValue
	})
	return page && page.url;
}

export function numFormatter(num) {
	return (Math.abs(num) > 999999
		? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + 'M'
		: (Math.abs(num) > 999
			? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K'
			: Math.sign(num) * Math.abs(num)))
}

export function getTableHeaderStyles(style, reqStyleArray) {
	let defaultStyle = { backgroundColor: "#000", color: "#FFF", fontSize: "14px", textAlign: "left" }
	let styleObj = {};
	reqStyleArray.map((item) => {
		styleObj[`${item}`] = (style && style[`${item}`]) ? style[`${item}`] : defaultStyle[`${item}`];
	})
	return styleObj;
}

export function getTableRowStyles(style, reqStyleArray, index) {
	let defaultStyle = { fontSize: "14px", color: "#FFF", backgroundColor: "#181818", row1BackgroundColor: "#181818", row2BackgroundColor: "#181818" };
	let styleObj = {};
	reqStyleArray.map((item) => {
		if (item === "backgroundColor") {
			if (style && style.row1BackgroundColor && style.row2BackgroundColor) {
				if (index % 2 === 0) {
					styleObj[`${item}`] = style.row1BackgroundColor;
				} else {
					styleObj[`${item}`] = style.row2BackgroundColor;
				}
			} else {
				styleObj[`${item}`] = (style && style[`${item}`]) ? style[`${item}`] : defaultStyle[`${item}`];
			}

		} else {
			styleObj[`${item}`] = (style && style[`${item}`]) ? style[`${item}`] : defaultStyle[`${item}`];
		}

	})
	return styleObj;
}

export function validateEmail(mail) {
	if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
		return (true)
	}
	return (false)
}

export function validateMobileNumber(number) {
	if (/^\d{10}$/.test(number)) {
		return (true)
	}
	return (false)
}

export function getTableHeader(data, keys) {
	let tableHeader = [];
	if (data && data.length > 0 && keys && keys.length > 0) {
		for (let key in data[0]) {
			if (keys.indexOf(key) === -1) {
				let headerItem = {
					id: key.replace(' ', ''),
					name: key,
					label: key,
					disablePadding: false,
					numeric: false,
					numeric: false,
					sort: true
				}
				tableHeader.push(headerItem)
			}
		}
	}
	return tableHeader;
};

const getChartDataTableHeader = (data, keysArr, chartData) => {
	let tableHeader = [];
	if (chartData.selectedDataModel && chartData.selectedDataElement
		&& !chartData.loadChildData) {
		chartData.selectedDataElement.map(element => {
			let { label, value, id } = element;
			let keys = keysArr && keysArr.length > 0 ? keysArr : []
			if (value != '_id' && keys.indexOf(value) === -1) {
				let headerItem = {
					// id: label.replace(' ', ''),
					id: value,
					name: value,
					// name: value,
					label: label,
					disablePadding: false,
					numeric: false,
					numeric: false,
					sort: true
				}
				tableHeader.push(headerItem)
			}
		})
	} else {
		if (data && data.length > 0) {
			for (let key in data[0]) {
				let keys = keysArr && keysArr.length > 0 ? keysArr : []
				if (key != '_id' && keys.indexOf(key) === -1) {
					let headerItem = {
						id: key.replace(' ', ''),
						name: key,
						label: key,
						disablePadding: false,
						numeric: false,
						numeric: false,
						sort: true
					}
					tableHeader.push(headerItem)
				}
			}
		}
	}
	return tableHeader;
};

export function updateTableData(state) {
	let obj = state.tableData;
	let filterObj = state.filterTableData;
	let list = obj && obj.datasets ? obj.datasets : [];
	let finalList = [];
	if (state.searchText !== "") {
		finalList = list.map((item) => {
			let isFilter = false;
			if (item)
				Object.keys(item).map((key) => {
					filterObj.columns.map(keyobj => {//when columns are check
						if (keyobj.checked && key === keyobj.name) {
							if ((item[key] || "").toString().toLowerCase().includes(state.searchText.toLowerCase())) {
								isFilter = true;
							}
						}
					})
				})
			if (isFilter) return item;
		}).filter(function (element) {
			return element !== undefined;
		});
	} else {
		finalList = [...list];
	}
	state.filterList.map((item, index) => {
		finalList = finalList.map((dataSet) => {
			if (item && item !== "All") {
				if (dataSet[`${filterObj.columns[index].name}`] === item) {
					return dataSet
				}
			} else {
				return dataSet
			}
		}).filter(function (element) {
			return element !== undefined;
		});
	})


	filterObj.datasets = [...finalList]
	return { filterTableData: { ...filterObj } };
}

export function setDatafromApi(data, keysArr, state, rows, chartData) {
	if (data) {
		let tableHeader = chartData ? getChartDataTableHeader(data, keysArr, chartData) : getTableHeader(data, keysArr);
		let r = {
			columns: tableHeader,
			datasets: data
		}
		let obj = JSON.parse(JSON.stringify(r));
		let list = [];
		if (rows && rows.length > 0) {
			obj.columns.map((item) => {
				let isRec = false;
				rows.map((column) => {
					if (column.name === item.id) {
						item.checked = true;
						// item.label = column.label;
						list.push(item);
						isRec = true;
					}
				})
				if (!isRec) {
					item.checked = false;
					list.push(item);
				}
			});
		} else {
			list = obj.columns.map((item) => {
				return { ...item, checked: true, label: item.label }
			});
		}
		obj.columns = [...list];
		let filterData = r.columns ? r.columns.map((col) => r.datasets.map(item => item[col.name])) : []
		return {
			tableData: r, filterTableData: obj, isLoading: false, filterData
			// count: data ? data.length : 0,
		}
	}
}