import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  withStyles,
  Button,
  Divider,
  Typography,
  InputAdornment,
  Icon,
  Box,
  TextField,
  form,
  FormControl,
  FormLabel,
  Card,
} from '@material-ui/core';
import { submitLogin, emptyPrevPath } from 'store/actions';
import {Pie} from 'react-chartjs-2';
import moment from 'moment';
import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
import { withRouter } from 'react-router-dom';

import './../ext-content/bootstrap.scss';
import './../ext-content/custom.min.scss';
import './../ext-content/mystyles.scss';
import './../ext-content/jqcloud.scss';
import './../ext-content/basic.scss';
import './../ext-content/dropzone.scss';
//TODO: remove extra files from ext-content  folder

const data = {
  labels: ['January', 'February', 'March',
           'April', 'May'],
  datasets: [
    {
      label: 'Cases by status',
      backgroundColor: [
        '#B21F00',
        '#C9DE00',
        '#2FDE00',
        '#00A6B4',
        '#6800B4'
      ],
      hoverBackgroundColor: [
      '#501800',
      '#4B5000',
      '#175000',
      '#003350',
      '#35014F'
      ],
      data: [65, 59, 80, 81, 56]
    }
  ]
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    color: '#fff',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: 'transparent',
  },
  panel: {
    marginBottom: '20px',
    backgroundColor: '#222222',
    border: '1px solid transparent',
    borderRadius: '4px',
    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)',
  },
  panelDefault: {
    borderColor: '#282828',
  },
  panelHeading: {
    color: '#888888',
    backgroundColor: '#3c3c3c',
    borderColor: '#282828',
    textAlign: 'left',
    padding: '10px 15px',
  },
  h5: {
    fontSize: '20px',
  },
  h4: {
    fontSize: '140%',
    padding: '10px',
  },
  h6: {
    fontSize: '16px',
    padding: '10px',
    fontWeight: '500',
  },
  customTableFirstCol: {
    padding: '5px',
    color: '#fff',
    fontSize: '1.4em',
  },
  customTableSecondCol: {
    padding: '5px',
    width: '50px',
    color: '#fff',
    fontSize: '1.4em',
    textAlign: 'right',
  },
  customLabel3: {
    borderBottom: '1px solid #fff',
    padding: '10px 0',
    color: '#fff !important',
    textAlign: 'left !important',
  },
  glyphicon: {
    fontSize: '60px',
    color: '#000',
  },
  cardTitle: {
    marginTop: '0 !important',
    color: '#000',
  },
  helptext: {
    color: '#000',
  },
  table: {
    fontSize: '1.8em !important',
    textAlign: 'left',
    width: '100%',
  },
  tableDark: {
    backgroundColor: '#242323 !important',
    color: 'white !important',
    borderTop: '1px dotted #eee',
  },
  tr: {
    padding: 5,
    borderBottom: '1px dotted #eee',
    display: 'block',
  },
  th: {
    padding: '10px !important',
    borderBottom: '1px dotted #eee',
    color: 'white !important',
    fontSize: '18px',
    backgroundColor: '#242323 !important',
  },
  td: {
    padding: '10px !important',
    textAlign: 'left',
    fontSize: '14px',
    borderTop: '1px dotted #eee',
  },
  anchor: {
    fontSize: '14px !important',
    textDecoration: 'underline !important',
  },
  recentActions: {
    fontSize: '1.2em',
    color: '#fff',
    paddingBottom: '10px',
    borderBottom: '1px dotted #eee',
  },
  cardHeading: {
    fontSize: '34px !important',
  },
  cardDescript: {
    fontSize: '14px !important',
  },
  icon: {
    marginTop: '0 !important',
    display: 'block',
    fontSize: '60px !important',
  },
  font18: {
    fontSize: '18px !important',
  },
}));

const BillingDashboard = props => {
  let initialState = {
    canSubmit: false,
  };
  const [state, setState] = useState(initialState);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ email: false, password: false });
  const [messages, setMessages] = useState([]);
  const [activityLogs, setActivityLogs] = useState([]);
  const [myTasks, setMyTasks] = useState([]);

  let form = React.createRef();
  const classes = useStyles();
  return (
    <div className="use-bootstrap ibc">
      <div>
        <div className="ibc main" style={{ backgroundColor: '#231F20', padding: '10px' }}>
          <ul className="breadcrumb">
            <li style={{ color: '#fff' }}>
              <a href="./main.html">Dashboard</a> / Monthly Billing
            </li>
          </ul>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <div
                  className={(classes.panel, classes.panelDefault)}
                  style={{ textAlign: 'center', backgroundColor: '#222222', paddingBottom: '20px' }}
                >
                  <div className={classes.panelHeading}>
                    <h5 className={classes.h5}>Cases Processed</h5>
                  </div>
                  <div className="row">
                  <div className="col-lg-12" id="ibc-chart-1" style={{marginTop: '10px'}}>
                  <Pie
                    data={data}
                    options={{
                      title:{
                        display:true,
                        text:'Average Rainfall per month',
                        fontSize:20
                      },
                      legend:{
                        display:true,
                        position:'right'
                      }
                    }}
                  />
                  </div>                
                </div>
                <div className="row" style={{display:'none'}}>
                  <div className="col-lg-11" style={{backgroundColor: 'orange', height: '200px', textAlign: 'center', marginLeft: '20px', marginBottom: '20px'}}>
                    <h2>634</h2>
                    <h5>Success</h5>
                  </div>
                  <div className="row">
                  <div className="col-lg-5" style={{backgroundColor: '#143254', height: '200px', textAlign: 'center', marginLeft: '40px'}}>
                    <h2>561</h2>
                    <h5>Pending</h5>
                  </div>
                  <div className="col-lg-5" style={{backgroundColor: '#713FED', height: '200px', textAlign: 'center', marginLeft: '20px'}}>
                    <h2>73</h2>
                    <h5>Exception</h5>
                  </div>
                  </div>
                </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <div className={(classes.panel, classes.paneldefault)}>
                  <div className={classes.panelHeading}>
                    <h5 className={classes.h5}>Employers By Issues</h5>
                  </div>
                  <div className="row">
                  <div className="col-lg-12" id="ibc-bar-chart-1" style={{marginTop: '10px'}}>
                  
                  </div>                  
                  <div style={{display: 'none'}}>
                  <div className="col-lg-12" id="ibc-chart-22" style={{marginTop: '10px', marginBottom: '0px'}}>
                    <span style={{display: 'inline-block', padding: '20px', width: '350px', backgroundColor: '#000', fontSize: '24px'}}>Employer</span>
                    <span style={{display: 'inline-block', padding: '20px', width: '100px', backgroundColor: '#000', fontSize: '24px'}}>Issues</span>
                    <span style={{display: 'inline-block', padding: '20px', width: '140px', backgroundColor: '#000', fontSize: '24px'}}>Diff.</span>
                  </div> 
                  <div className="col-lg-12" id="ibc-chart-22" style={{marginTop: '10px', marginBottom: '0px'}}>
                    <span style={{display: 'inline-block', padding: '20px', color: '#000', width: '350px', backgroundColor: 'yellow', fontSize: '24px'}}>ABC Dsitributors LLC</span>
                    <span style={{display: 'inline-block', padding: '20px', width: '100px', backgroundColor: 'red', color: '#fff', fontsize: '24px'}}>342</span>
                    <span style={{display: 'inline-block', padding: '20px', width: '140px', backgroundColor: 'red', color: '#fff', fontSize: '24px'}}>$99,854.67</span>
                  </div>    
                  <div className="col-lg-12" id="ibc-chart-22" style={{marginTop: '10px', marginBottom: '0px'}}>
                    <span style={{display: 'inline-block', padding: '20px', color: '#000', width: '350px', backgroundColor: 'orange', fontSize: '24px'}}>IncorpLaw Consultants LLC</span>
                    <span style={{display: 'inline-block', padding: '20px', width: '100px', backgroundColor: 'red', color: '#fff', fontsize: '24px'}}>211</span>
                    <span style={{display: 'inline-block', padding: '20px', width: '140px', backgroundColor: 'red', color: '#fff', fontSize: '24px'}}>$67,265.89</span>
                  </div>     
                  <div className="col-lg-12" id="ibc-chart-22" style={{marginTop: '10px', marginBottom: '0px'}}>
                    <span style={{display: 'inline-block', padding: '20px', color: '#000', width: '350px', backgroundColor: 'yellow', fontSize: '24px'}}>Teknocorp Services Inc</span>
                    <span style={{display: 'inline-block', padding: '20px', width: '100px', backgroundColor: 'red', color: '#fff', fontsize: '24px'}}>149</span>
                    <span style={{display: 'inline-block', padding: '20px', width: '140px', backgroundColor: 'red', color: '#fff', fontSize: '24px'}}>$43,710.25</span>
                  </div> 
                  <div className="col-lg-12" id="ibc-chart-22" style={{marginTop: '10px', marginBottom: '0px'}}>
                    <span style={{display: 'inline-block', padding: '20px', color: '#000', width: '350px', backgroundColor: 'orange', fontSize: '24px'}}>Televised Partners</span>
                    <span style={{display: 'inline-block', padding: '20px', width: '100px', backgroundColor: 'red', color: '#fff', fontsize: '24px'}}>87</span>
                    <span style={{display: 'inline-block', padding: '20px', width: '100px', backgroundColor: 'red', color: '#fff', fontsize: '24px'}}>$36,719.45</span>
                  </div>   
                  </div>                                                        
                </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <div className={(classes.panel, classes.paneldefault)}>
                  <div className={classes.panelHeading}>
                    <h5 className={classes.h5}>Reconciliation Issues</h5>
                  </div>
                  <div className={classes.panelBody}></div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div className={(classes.panel, classes.paneldefault)}>
                  <div className={classes.panelHeading}>
                    <h4 className={classes.h5}>Cases</h4>
                    <a className="btn btn-md btn-primary" style={{float: 'right', marginTop: '-40px', marginRight: '20px'}} href="cases">View All</a>
                  </div>
                  <table className="table table-dark report-table" className={classes.table}>
                    <thead>
                    <th className={classes.th}><input className="checkbox" type="checkbox"/></th>
                    <th className={classes.th}>Case #</th><th>Control #</th>
                    <th className={classes.th}>Check #</th>
                    <th className={classes.th}>Check Amount</th>
                    <th className={classes.th}>Bill Type</th>
                    <th className={classes.th}>Check Received Date</th>
                    <th className={classes.th}>Processing Status</th>
                    <th className={classes.th}>Crypto #</th>
                    <th className={classes.th}>Actions</th>
                    </thead>
                    <tr>
                      <td className={classes.td}>
                        <input className="checkbox" type="checkbox" />
                      </td>
                      <td className={classes.td}>98223</td>
                      <td className={classes.td}>452128</td>
                      <td className={classes.td}>ECHECK</td>
                      <td className={classes.td}>$7,300.32</td>
                      <td className={classes.td}>Original</td>
                      <td className={classes.td}>11/29/2020</td>
                      <td className={classes.td}>Payment Successfully linked</td>
                      <td className={classes.td}>0x343243wr32532532532532532532</td>
                      <td className={classes.td}>
                        <a
                          href="http://localhost:3001/apps/5fc63437eb0977ffe0fe2105/form/IBC%20Case/5fc657ab5a080404077cabc0"
                          target="_blank"
                        >
                          Details
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.td}>
                        <input className="checkbox" type="checkbox" />
                      </td>
                      <td className={classes.td}>98223</td>
                      <td className={classes.td}>452128</td>
                      <td className={classes.td}>ECHECK</td>
                      <td className={classes.td}>$7,300.32</td>
                      <td className={classes.td}>Original</td>
                      <td className={classes.td}>11/29/2020</td>
                      <td className={classes.td}>Payment Successfully linked</td>
                      <td className={classes.td}>0x343243wr32532532532532532532</td>
                      <td className={classes.td}>
                        <a href="">Details</a>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.td}>
                        <input className="checkbox" type="checkbox" />
                      </td>
                      <td className={classes.td}>98223</td>
                      <td className={classes.td}>452128</td>
                      <td className={classes.td}>ECHECK</td>
                      <td className={classes.td}>$7,300.32</td>
                      <td className={classes.td}>Original</td>
                      <td className={classes.td}>11/29/2020</td>
                      <td className={classes.td}>Payment Successfully linked</td>
                      <td className={classes.td}>0x343243wr32532532532532532532</td>
                      <td className={classes.td}>
                        <a href="">Details</a>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.td}>
                        <input className="checkbox" type="checkbox" />
                      </td>
                      <td className={classes.td}>98223</td>
                      <td className={classes.td}>452128</td>
                      <td className={classes.td}>ECHECK</td>
                      <td className={classes.td}>$7,300.32</td>
                      <td className={classes.td}>Original</td>
                      <td className={classes.td}>11/29/2020</td>
                      <td className={classes.td}>Payment Successfully linked</td>
                      <td className={classes.td}>0x343243wr32532532532532532532</td>
                      <td className={classes.td}>
                        <a href="">Details</a>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.td}>
                        <input className="checkbox" type="checkbox" />
                      </td>
                      <td className={classes.td}>98223</td>
                      <td className={classes.td}>452128</td>
                      <td className={classes.td}>ECHECK</td>
                      <td className={classes.td}>$7,300.32</td>
                      <td className={classes.td}>Original</td>
                      <td className={classes.td}>11/29/2020</td>
                      <td className={classes.td}>Payment Successfully linked</td>
                      <td className={classes.td}>0x343243wr32532532532532532532</td>
                      <td className={classes.td}>
                        <a href="">Details</a>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.td}>
                        <input className="checkbox" type="checkbox" />
                      </td>
                      <td className={classes.td}>98223</td>
                      <td className={classes.td}>452128</td>
                      <td className={classes.td}>ECHECK</td>
                      <td className={classes.td}>$7,300.32</td>
                      <td className={classes.td}>Original</td>
                      <td className={classes.td}>11/29/2020</td>
                      <td className={classes.td}>Payment Successfully linked</td>
                      <td className={classes.td}>0x343243wr32532532532532532532</td>
                      <td className={classes.td}><a href="">Details</a></td>   </tr>
                    <tr>
                      <td className={classes.td}><input className="checkbox" type="checkbox" /></td>
                      <td className={classes.td}>98223</td>
                      <td className={classes.td}>452128</td>
                      <td className={classes.td}>ECHECK</td>
                      <td className={classes.td}>$7,300.32</td>
                      <td className={classes.td}>Original</td>
                      <td className={classes.td}>11/29/2020</td>
                      <td className={classes.td}>Payment Successfully linked</td>
                      <td className={classes.td}>0x343243wr32532532532532532532</td>
                      <td className={classes.td}><a href="">Details</a></td>
                    </tr>
                    <tr>
                      <td className={classes.td}><input className="checkbox" type="checkbox" /></td>
                      <td className={classes.td}>98223</td>
                      <td className={classes.td}>452128</td>
                      <td className={classes.td}>ECHECK</td>
                      <td className={classes.td}>$7,300.32</td>
                      <td className={classes.td}>Original</td>
                      <td className={classes.td}>11/29/2020</td>
                      <td className={classes.td}>Payment Successfully linked</td>
                      <td className={classes.td}>0x343243wr32532532532532532532</td>
                      <td className={classes.td}><a href="">Details</a></td>
                    </tr>
                  </table>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  submitLogin,
  emptyPrevPath,
};

const mapStateToProps = ({ user, login, navigation }) => {
  return {
    login,
    user,
    navigation,
  };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(BillingDashboard)));
