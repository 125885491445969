import { hot } from 'react-hot-loader/root'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import DataSheet from 'components/DataSheet/v2.0'

import { withRouter } from 'react-router-dom'

import _ from 'lodash';

const MathExcelSheet = (props) => {
	return (
		<DataSheet
			checkedRows={props.checkedRows}
			parsePaste={(pasted) => props.parsePaste(pasted)}
			data={props?.data}
			valueRenderer={(cell) => cell.value}
			dataRenderer={(cell) => cell.expr}
			onCellsChanged={props.onCellsChanged}
			type={props.type}
			checkboxOnChanges={props.checkboxOnChanges}
			onSelectChange={props.onSelectChange}
			// filterData={props?.filterData}
		// onContextMenu={(e, cell, i, j) => cell.readOnly ? e.preventDefault() : null}
		/>
	)
}

const mapStateToProps = state => {
	return {}
}

const mapDispatchToProps = {}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(MathExcelSheet)))