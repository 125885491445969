import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    errorWrap: {
        textAlign: 'center',
        '& h4': {
            color: '#000',
        },
        '& h6': {
            color: '#000',
        },
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));
export const NoAppsFound = () => {

    const classes = useStyles();
    return (
        <div className={classes.errorWrap}>
            <h4>Currently you don't have access to any application.</h4>
            <h6>Please contact admin for access</h6>
        </div>);
};
