import { CircularProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { convertToNumber } from "../../../spendplan/PlanningPages/planningHelperFunctions";

const useStyles = makeStyles(theme => ({
    table: {
        "& .MuiTableCell-sizeSmall": {
            padding: '0px 24px 0px 16px',
        },
    },
    container: {
        width: 550,
        marginRight: 15,
        height: 60,
    },
    circularBar: {
        color: 'rgb(25, 118, 210)',
        height: 20,
        width: 20,
        marginRight: 10,
    },
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 30,
        height: 60,
    }
}));

const MetricOverHeadTable = (props) => {
    const classes = useStyles()

    const [allData, setAllData] = useState(null);

    let d = props.data && props.data['GET_meterOverhead']
    useEffect(() => {
        if (d) {
            setAllData(props.data['GET_meterOverhead'].result.data)
        }
    }, [d])

    if (props.mrData && props.mrData['GET_meterOverhead_loading']) {
        return <div className={classes.loaderContainer}><CircularProgress className={classes.circularBar} size={30} /> Fetching metric overhead</div>
    }
    const getData = (key) => {
        if (allData) {
            return allData[key]
        }
        return null;
    }

    let v1 = getData(props.filterValues ? props.filterValues['brand'] : null)
    let accountLevelValue = getData('All Brands')

    return (
        <TableContainer className={classes.container}>
            <Table size="small" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">Retailing INR</TableCell>
                        <TableCell align="right">Ret IYA</TableCell>
                        <TableCell align="right">SD%RLP</TableCell>
                        <TableCell align="right">SDE</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={'1'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                        <TableCell style={{ fontWeight: '500' }} component="th" scope="row">
                            Account
                        </TableCell>
                        <TableCell title={accountLevelValue?.retailing || 0} align="right">{convertToNumber(accountLevelValue?.retailing, 2) || 0}</TableCell>
                        <TableCell title={accountLevelValue?.retailingIya || 0} align="right">{convertToNumber(accountLevelValue?.retailingIya, 2) || 0}</TableCell>
                        <TableCell title={accountLevelValue?.sdSpendRlp * 100 || 0} align="right">{convertToNumber(accountLevelValue?.sdSpendRlp * 100, 2) || 0}</TableCell>
                        <TableCell title={accountLevelValue?.sdEffectiveness * 100 || 0} align="right">{convertToNumber(accountLevelValue?.sdEffectiveness * 100, 2) || 0}</TableCell>
                    </TableRow>
                    <TableRow key={'2'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                        <TableCell style={{ fontWeight: '500' }} component="th" scope="row">Brand
                        </TableCell>
                        <TableCell title={v1?.retailing || 0} align="right">{convertToNumber(v1?.retailing, 2) || 0}</TableCell>
                        <TableCell title={v1?.retailingIya || 0} align="right">{convertToNumber(v1?.retailingIya, 2) || 0}</TableCell>
                        <TableCell title={v1?.sdSpendRlp * 100 || 0} align="right">{convertToNumber(v1?.sdSpendRlp * 100, 2) || 0}</TableCell>
                        <TableCell title={v1?.sdEffectiveness * 100 || 0} align="right">{convertToNumber(v1?.sdEffectiveness * 100, 2) || 0}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default MetricOverHeadTable;