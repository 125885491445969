import React, { Component } from 'react';
import {
	TextField, FormControl, Select, Grid,
	MenuItem, FormGroup, FormLabel, Button
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import _ from 'modules/lodash';
import { withRouter } from 'react-router-dom';


const dataTypeStringList = [{ "value": "starts_with", "name": "Starts with" }, { "value": "ends_with", "name": "Ends with" }, , { "value": "matches", "name": "Matches" }, { "value": "includes", "name": "Includes" }, { "value": "is_equal_to", "name": "Is equal to" }, { "value": "is_greater_than", "name": "Is greater than" }, { "value": "is_less_than", "name": "Is less than" }, , { "value": "in_between", "name": "In between" }]

const BasicRule = (props) => {
	let { classes, values, type, dataModels, BasicRule, pInd } = props;

	return (<>
		<FormGroup row className="items-center  max-w-full">
			<Grid container>
				<Grid xs={2} style={{ "padding": "5px" }}>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<Select
							autoFocus
							value={values[type].ruleLeftDE}
							onChange={(e) => props.onRuleChangeHandler(e, type, pInd)}
							inputProps={{
								name: 'ruleLeftDE',
								id: 'ruleLeftDE',
							}}>
							<MenuItem value="">
								<em>Select a column</em>
							</MenuItem>
							{dataModels && dataModels[0] && dataModels[0].dataElements.map((item, i) => {
								return <MenuItem key={i} value={item.name} >{item.name}</MenuItem>
							})}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={2} style={{ "padding": "5px" }}>
					<FormControl fullWidth variant="outlined" className={classes.formControl}>
						<Select
							value={values[type].ruleRightDE}
							onChange={(e) => props.onRuleChangeHandler(e, type, pInd)}
							inputProps={{
								name: 'ruleRightDE',
								id: 'ruleRightDE',
							}}>
							<MenuItem value="">
								<em>Select a column</em>
							</MenuItem>
							{dataModels && dataModels[1] && dataModels[1].dataElements.map((item, i) => {
								return <MenuItem key={i} value={item.name} >{item.name}</MenuItem>
							})}
						</Select>
					</FormControl>
				</Grid>
				{ "pairs" === type && <Grid xs={2} style={{ "padding": "5px" }}>
					<TextField
						className={classes.formControl}
						label="Enter result"
						id="result"
						name="result"
						value={values[type].result}
						onChange={(e) => props.onRuleChangeHandler(e, type, pInd)}
						variant="outlined"
						fullWidth
					/>
				</Grid>}
				<Grid xs={2} style={{ "padding": "5px 20px" }}>
					<Button
						color="primary"
						variant="contained"
						className={classes.formControl}
						onClick={() => {
							props.addBasicRule(values[type], type, pInd);
						}}
						fullWidth
					>
						Add
            </Button>
				</Grid>
			</Grid>
		</FormGroup>
	</>)
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

function mapStateToProps(state) {
	return {
		dataModels: state.cappsConfig.capps.data,
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BasicRule)
);