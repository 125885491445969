import { Button, NativeSelect, TextField, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';


const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const styles = theme => ({
    head: {
        backgroundColor: "#69b4ef",
        color: "#fff",

    },
    customFont: {
        color: "#fff",
        fontSize: "14px"
    },
    acitonStyle: {
        padding: '0 5px'
    }
});

class MultiLine extends Component {
    constructor(props) {
        super(props);
        console.log("multiline json", this.props.filledData);
        this.state = {
            json: this.props.json,
            rows: this.props.filledData ? this.props.filledData : []
        }
        this.newRow = new Array(this.props.json.data.values.length).fill("");
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.json.data.values.length !== prevProps.json.data.values.length) {
            this.newRow = new Array(this.props.json.data.values.length).fill("");
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            json: props.json,
            rows: props.filledData ? props.filledData : []
        }
    }
    addData = () => {
        console.log(this.newRow, "Data to be added")
        let rowsData = JSON.parse(JSON.stringify(this.state.rows));
        rowsData = Array.isArray(this.state.rows) ? this.state.rows : [];
        rowsData.push([...this.newRow]);
        this.setState({ rows: rowsData })
        this.props.setData(this.props.json.name, rowsData)
    }
    emptyRow = () => {
        const { classes } = this.props;
        return (<TableRow>
            {this.props.json.data.values.map((item, index) => {
                return (<TableCell className={classNames(classes.customFont)}>
                    {
                        item.type === 'select' ?
                            <NativeSelect
                                fullWidth
                                className={item.className ? item.className : classes.nativeStyle}
                                onChange={(e) => { 
                                    console.log('eeeee', e.target.value)
                                    this.newRow[index] = e.target.value; this.forceUpdate() 
                                }}
                                inputProps={{
                                    name: 'age',
                                    id: 'age-native-helper',
                                    required: item.required
                                }}
                            >
                                <option className="option-style" value="" selected disabled>Select an option</option>
                                {item.values.map((el) => {
                                    return <option className="option-style"
                                        value={el}>{el}</option>

                                })}
                            </NativeSelect> : <TextField variant="outlined" value={this.newRow[index]}
                                onChange={(e) => { this.newRow[index] = e.target.value; this.forceUpdate() }} />

                    }
                </TableCell>);
            })}
            <TableCell>
                <Button
                    onClick={(e) => { this.addData(); }} className="btn btn-primary btn-color border-radius-0">
                    Add Data</Button></TableCell>
        </TableRow>)
    }

    delete = (index) => {
        let rowsData = JSON.parse(JSON.stringify(this.state.rows));
        let newRows = rowsData.filter((row, i) => {
            return i != index;
        });
        console.log(newRows, "after filter");
        this.setState({ rows: newRows });
        this.props.setData(this.props.json.name, newRows);
    }
    editRowdata = (editData, index) => {
        if (this.edit == index) {
            let rowsData = JSON.parse(JSON.stringify(this.state.rows));
            rowsData[index] = this.editData;
            console.log(rowsData, "after edit RowData");
            this.edit = null;
            this.editData = null;
            this.setState({ rows: rowsData });
            this.props.setData(this.props.json.name, rowsData)
            return;
        }
        this.edit = index;
        this.editData = editData;
        this.forceUpdate();
    }
    render() {
        const { classes } = this.props;
        const { item, outerIndex, gridSize, index } = this.props;
        console.log("updated data", this.props.filledData);
        return (
            <>
                <Table className={`${classNames(classes.table)} ${isIBC ? 'ibc-case-table' : ''}`} size="small" aria-label="a dense table ml">
                    <TableHead className={classNames(classes.head)}>
                        <TableRow>
                            {this.props.json.data.values.map((item) => {
                                return (<TableCell className={classNames(classes.head)}>{item.value}</TableCell>);
                            })}
                            <TableCell className={classNames(classes.head)}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(this.state.rows) && this.state.rows.map((row, index) => {
                            return (
                                <TableRow>
                                    {this.edit != index && row.map((rowdata) => {
                                        return (<TableCell className={classNames("custom-font")}>
                                            {rowdata}</TableCell>)
                                    })}

                                    {this.edit == index && row.map((item, index) => {
                                        return (<TableCell className={classNames("custom-font")}>
                                            {
                                                this.props.json.data.values[index]?.type === 'select' ?
                                                    <NativeSelect
                                                        fullWidth
                                                        value={this.editData[index]}
                                                        className={item.className ? item.className : classes.nativeStyle}
                                                        onChange={(e) => { this.editData[index] = e.target.value; this.forceUpdate() }}
                                                        inputProps={{
                                                            name: 'age',
                                                            id: 'age-native-helper',
                                                            required: item.required
                                                        }}
                                                    >
                                                        <option className="option-style" value="" selected disabled>Select an option</option>
                                                        {this.props.json.data.values[index]?.values?.map((el) => {
                                                            return <option className="option-style"
                                                                value={el}>{el}</option>

                                                        })}
                                                    </NativeSelect> : <TextField value={this.editData[index]}
                                                        onChange={(e) => { this.editData[index] = e.target.value; this.forceUpdate() }}
                                                        variant="outlined" />

                                            }

                                        </TableCell>)
                                    })}
                                    <TableCell>
                                        <div className="button-group pt-7">
                                            {/* <Button onClick={(e) => { this.editRowdata([...row], index) }}
                                                className="btn btn-primary btn-color border-radius-0"
                                                startIcon={<EditIcon />}>
                                            </Button> */}
                                            {/* <Button onClick={(e) => { this.delete(index) }}
                                                variant="contained"
                                                color="secondary"
                                                className={classes.button, "border-radius-0"}
                                                startIcon={<DeleteIcon />}
                                            >
                                            </Button> */}
                                            <IconButton
                                                onClick={(e) => { this.editRowdata([...row], index) }}
                                                size="medium" color="primary" aria-label="Edit"
                                                className={classes.acitonStyle}
                                            >
                                              {
                                                this.edit != index ?
                                                  <Tooltip title="Edit">
                                                    <EditIcon/>
                                                  </Tooltip> :
                                                  <Tooltip title="Save">
                                                    <SaveIcon/>
                                                  </Tooltip>
                                              }
                                            </IconButton>

                                            <IconButton
                                                onClick={(e) => { this.delete(index) }}
                                                color="secondary"
                                                size="medium" aria-label="Delete"
                                                className={classes.acitonStyle}
                                            >
                                              <Tooltip title="Delete">
                                                <DeleteIcon />
                                              </Tooltip>
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>)
                        })}
                        {this.emptyRow()}
                    </TableBody>
                </Table>
            </>
        )
    };
}


export default (withStyles(styles, { withTheme: true })(MultiLine));
