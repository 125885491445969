import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
// render={props => (isAuthenticated ? <Redirect to={to} /> : <Component {...props} page={page}/>)}
const PublicRoute = ({ component: Component, isAuthenticated, to, page, ...rest }) => {
	return (
		<Route
		{...rest}
		render={props => (<Component {...props} page={page}/>)}
		/>
	)
};

PublicRoute.propTypes = {
	component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
	to: PropTypes.string,
	page: PropTypes.object
};

PublicRoute.defaultProps = {
	to: '/apps',
};

export default PublicRoute;
