import { ActionTypes } from 'constants/index';

const initialState = {
  loading: false,
  imar1ReportData: null,
  imar2ReportData: null,
  error: null,
};

export default function ImarReportsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_IMAR1_REPORT_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GET_IMAR1_REPORT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        imar1ReportData: action.payload,
      };
    }
    case ActionTypes.GET_IMAR1_REPORT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
        imar1ReportData: null,
      };
    }

    case ActionTypes.GET_IMAR2_REPORT_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GET_IMAR2_REPORT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        imar2ReportData: action.payload,
      };
    }
    case ActionTypes.GET_IMAR2_REPORT_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
        imar2ReportData: null,
      };
    }
    case ActionTypes.CLEAR_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default: {
      return state;
    }

  }

}
