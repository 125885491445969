import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Card, CardContent, CardHeader, Typography, Tabs, Tab, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import RenderAnimate from 'components/Login/RenderAnimate';
import LoginBox from 'components/Login/LoginBox';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';

import Header from 'components/Header';
import Footer from 'components/Footer';

import { loadApps } from 'store/actions';
import { AppService } from 'modules/api/index';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
    root: {
        backgroundSize: 'cover',
    },

    card: {
        width: '100%',
        height: 'calc(100-77)',
    },
    content: {
        padding: "8px",
    },
    header: {
        padding: "12px"
    }
})
);

const SimpleList = props => {
    const [data, setData] = useState(null);
    const classes = useStyles();


    const setDatafromApi = (res, data) => {
        let d = { ...data };
        let { chartData } = props;

        let labels = [], dArray = [], label = '';
        if (chartData.chartData.selectedDataModel && chartData.chartData.selectedDataElement) {
            res.map((o) => {
                dArray.push(o[chartData.chartData.selectedDataElement[0].value])
            })
            d.title = chartData.chartData.selectedDataElement[0].label;
            d.data = dArray;
        } else {
            d.title = res.title;
            d.data = res.data;
        }
        setData(d);
    }

    const getApiDataFromDataUrl = (url, data) => {
        AppService.getApiDataFromDataUrl(url, props.appId)
            .then(res => {
                setDatafromApi(res, data)
            })
    }

    useEffect(() => {
        setData(props.chartData.chartData)
    }, [props.chartData])

    useEffect(() => {
        let { chartData } = props;
        if (chartData.chartData.selectedDataModel && chartData.chartData.selectedDataElement) {
            let targetURL = API_BASE_URL + "/dataconnect/" + chartData.chartData.selectedDataModel;
            const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
            targetURL += chartData.chartData.selectedDataElement.reduce(reducer, '?q=');
            if (props.queryString) {
                targetURL += '&' + props.queryString;
            }
            console.log("chartData.conditions", chartData.conditions);

            if (chartData.conditions && chartData.conditions.length > 0) {
                let cond = JSON.stringify(chartData.conditions);
                targetURL += "&conditions=" + cond;
            }
          let encodedData = encodeURIComponent(targetURL)
          let _targetUrl = JSON.stringify(encodedData);
            getApiDataFromDataUrl(_targetUrl, props.chartData.chartData);
        } else if (chartData.chartData.dataUrl) {
            getApiDataFromDataUrl(chartData.chartData.dataUrl, props.chartData.chartData);
        } else {
            setData(props.chartData.chartData);
        }
    }, [])

    const ListItemLink = (props) => <ListItem button component="a" {...props} />

    return (
        <Card className={classNames(classes.card, 'mx-auto m-0')}>
            {data && data.title ? <CardHeader className={classes.header} title={data.title} /> : ''}
            <CardContent className={classes.content + " flex flex-col items-center justify-center"}>
                <List component="nav" className="p-0" aria-label="secondary mailbox folders">
                    {data && data.data.length > 0 ?
                        data.data.map((d, i) => (
                            typeof d === 'object' ?
                                <ListItemLink key={i} href={d.URL}>
                                    <ListItemText primary={d.grade} />
                                </ListItemLink>
                                : <ListItemText key={i} primary={d} />
                        ))
                        : "Please wait data has been loading"
                    }
                </List>
            </CardContent>
        </Card>
    );
};


export default hot(SimpleList);
