

import { hot } from 'react-hot-loader/root'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
	Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid, Slide,
	Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
	DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = (props) => {
	let { open, handleClose, alertMessageText, classes, confirmFinalize,
		setConfirmFinalize, finalizePayment
	} = props

	return (<>
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			className={classes.confirmationDialoge}
		>
			<DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#000" }}>{"Confirmation"}</DialogTitle>
			<DialogContent className={classes.dialog}
			// style={{ display: 'flex', alignItems: 'center' }}
			>
				<DialogContentText component="div" id="alert-dialog-slide-description" className={classes.para}>
					{typeof alertMessageText == 'object'
						? <>
							<h3 style={{ color: '#000', textAlign: 'center' }}> {alertMessageText.p1} </h3>
							<h5 style={{ color: '#000', textAlign: 'center' }}> {alertMessageText.p2} </h5>
						</>
						: <h5 style={{ color: '#000', textAlign: 'center' }}>{alertMessageText}</h5>}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{
					confirmFinalize ?
						<React.Fragment>
							<Button
								color="primary" onClick={() => {
									handleClose();
									if (confirmFinalize) {
										setConfirmFinalize(false);
										finalizePayment();
									}
								}}
								className="btn btn-primary btn-color border-radius-0 text-color-white">
								{"Yes"}
							</Button>
							<Button
								color="primary" onClick={() => handleClose()}
								className="btn btn-primary btn-color border-radius-0 text-color-white">
								{"No"}
							</Button>
						</React.Fragment>
						:
						<Button
							color="primary" onClick={() => handleClose()}
							className="btn btn-primary btn-color border-radius-0 text-color-white">
							{"OK"}
						</Button>
				}
			</DialogActions>
		</Dialog>

	</>)
}

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(connect(mapStateToProps, null)(ConfirmDialog)));