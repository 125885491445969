import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { DropzoneArea } from 'material-ui-dropzone';
import Grid from '@material-ui/core/Grid';
import NavBar from 'components/NavBar';
import {
    Button, Select, Step, StepLabel, Stepper, Typography, withStyles, ButtonGroup,
    makeStyles, FormControl, InputLabel, MenuItem, Table, TableBody, TableCell, TableHead,
    TableRow, TableContainer, TablePagination, TextField, Paper, FormLabel, Box
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Board, { moveCard } from "@lourenci/react-kanban";
import '@lourenci/react-kanban/dist/styles.css'
import Conditions from 'components/appConfig/conditions/Conditions';
import ConditionsTable from 'components/appConfig/conditions/ConditionsTable';
import BasicRule from 'components/appConfig/basicRules/BasicRule';
import BasicRulesTable from 'components/appConfig/basicRules/BasicRulesTable';
import Rule from 'components/appConfig/Rule';
import Results from 'components/appConfig/results/Results';
import FormDialog from 'components/appConfig/FormDialog';

import '../styles/board.scss'

import history from 'modules/history';
import {
    addCondAndLink,
    editCondAndLink,
    deleteCondAndLink
} from 'modules/appConfig.module'

import {
    generateGUID
} from 'modules/page/page.module'

import {
    uploadCappsConfiguration, getCappConfiguration, filterCappConfiguration, ruleExcution,
    saveDataModel, getMember, loadAppconfig
} from 'store/actions';

import { CappsService } from 'modules/api/index'

import { dateFormat } from 'highcharts';

const useStyles = makeStyles((theme) => ({
    stepper: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    fileNameTxt: {
        color: "#2196f3"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    dashlets: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        }
    },
    dashlet: {
        backgroundColor: "#2196f3",
        color: "#FFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    }

}));

const operatorList = [
    { "value": "is_empty", "name": "is empty" },
    { "value": "is_not_empty", "name": "is not empty" },
    { "value": "is_equal_to", "name": "is equal to" },
    { "value": "is_not_equal_to", "name": "is not equal to" },
    { "value": "is_greater_than", "name": "is greater than" },
    { "value": "is_less_than", "name": "is less than" },
    { "value": "in_between", "name": "in between" },
    { "value": "contains", "name": "contains" },
    { "value": "starts_with", "name": "starts with" },
    { "value": "ends_with", "name": "ends with" },
    { "value": "matches", "name": "matches" },
    { "value": "not_matches", "name": "doesn't match" },
    { "value": "includes", "name": "includes" },
    { "value": "is_empty", "name": "is empty" },
    { "value": "is_not_empty", "name": "is not empty" },
    { "value": "is_equal_to", "name": "is equal to" },
    { "value": "is_not_equal_to", "name": "is not equal to" },
    { "value": "is_greater_than", "name": "is greater than" },
    { "value": "is_less_than", "name": "is less than" },
    { "value": "in_between", "name": "in between" }]

function getSteps() {
    return ['Upload', 'Explore', 'Process', 'Results', 'Report'];
}

const dataTypeNumList = [
    { "value": "is_empty", "name": "is empty" },
    { "value": "is_not_empty", "name": "is not empty" },
    { "value": "is_equal_to", "name": "is equal to" },
    { "value": "is_not_equal_to", "name": "is not equal to" },
    { "value": "is_greater_than", "name": "is greater than" },
    { "value": "is_less_than", "name": "is less than" },
    { "value": "in_between", "name": "in between" }]

const dataTypeStringList = [
    { "value": "contains", "name": "contains" },
    { "value": "starts_with", "name": "starts with" },
    { "value": "ends_with", "name": "ends with" },
    { "value": "matches", "name": "matches" },
    { "value": "not_matches", "name": "doesn't match" },
    { "value": "includes", "name": "includes" },
    { "value": "is_empty", "name": "is empty" },
    { "value": "is_not_empty", "name": "is not empty" },
    { "value": "is_equal_to", "name": "is equal to" },
    { "value": "is_not_equal_to", "name": "is not equal to" },
    { "value": "is_greater_than", "name": "is greater than" },
    { "value": "is_less_than", "name": "is less than" },
    { "value": "in_between", "name": "in between" }]

const actionTypesList = [
    { "value": "set", "name": "Set" },
    { "value": "get", "name": "Get" },
    { "value": "remove", "name": "Remove" },
    { "value": "create", "name": "Create" },
    { "value": "send", "name": "Send" }]

const selectedComparatorList = [
    { "value": "datamodel", "name": "Data Model" },
    { "value": "inputField", "name": "Input Field" }]


const board = {
    columns: [
        {
            id: 1,
            title: "Backlog",
            cards: [
                {
                    id: 1,
                    title: "Card title 1",
                    description: "Card content"
                },
                {
                    id: 2,
                    title: "Card title 2",
                    description: "Card content"
                },
                {
                    id: 3,
                    title: "Card title 3",
                    description: "Card content"
                }
            ]
        },
        {
            id: 3,
            title: "Q&A",
            cards: [
                {
                    id: 10,
                    title: "Card title 10",
                    description: "Card content"
                },
                {
                    id: 11,
                    title: "Card title 11",
                    description: "Card content"
                }
            ]
        }
    ]
};

const Configuration = props => {
    let { user, uploadCappsConfiguration, capps, getCappConfiguration,
        cappData, filterCappConfiguration, ruleExcution, app } = props;


    let header = app && {
        title: app.title,
        subTitle: app.subTitle
    }

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [files, setFiles] = React.useState([]);
    const [cappSelected, setCappSelected] = React.useState('');
    const [explorePage, setExplorePage] = React.useState('explore');
    const [processPage, setProcessPage] = React.useState('basic');
    const [controlledBoard, setBoard] = useState({ columns: [] });
    const [conditions, setConditions] = React.useState([]);
    const [condition, setCondition] = React.useState({
        selectedConditionDataElement: "", selectedConditionOperator: "",
        value: "", selectedConditionClause: ""
    });

    //To support basic rules
    const [basicRules, setBasicRules] = React.useState([
        {
            //     name: '',
            //     keys: [{ ruleLeftDE: "", ruleRightDE: "" }],
            //     pairs: [{ ruleLeftDE: "", ruleRightDE: "" }]
        }
    ]);
    const [basicRule, setBasicRule] = React.useState({
        keys: { ruleLeftDE: "", ruleRightDE: "" },
        pairs: { ruleLeftDE: "", ruleRightDE: "", result: "" }
    });

    const [appId, setAppId] = useState()
    const [orgId, setOrgId] = useState()
    const [datasourceId, setDatasourceId] = useState()
    const [dataModels, setDataModels] = useState([])
    const [resultDataElements, setResultDataElements] = useState([])
    const [processFieldList, setProcessFieldList] = React.useState([{
        name: '',
        conditionsUIList: [{
            isSelectedValue: 1,
            dataModelLeftSelected: '',
            dataElementLeftList: [],
            dataElementLeftSelected: '',
            dataElementType: '',
            operatorSelected: '',
            inputField: '',
            selectedComparator: '',
            dataModelRightSelected: '',
            dataElementRightList: [],
            dataElementRightSelected: '',
        }], actionsUIList: [{
            isSelectedValue: 1,
            actionTypeSelected: "",
            dataModelSelected: '',
            dataElementList: [],
            dataElementMultiList: [],
            dataElementMultiSelected: [],
            selectedComparator: '',
            dataElementSelected: '',
            dataModelActionRightSelected: '',
            dataElementActionRightSelected: '',
            dataElementActionRightList: [],
            setValue: '',
            dataValue: '',
            ruleContext: '',
            isError: false
        }]
    }]);
    const [exploreDataElements, setExploreDataElements] = useState([])
    const steps = getSteps();
    let acceptedFiles = ['application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/*',
        'application/x-csv',
        'application/csv',
        'text/x-csv',
        'text/csv',
        '.csv',
        'text/x-comma-separated-values',
        'text/comma-separated-values',
        'text/tab-separated-values',
        'text/plain',
        '.xslx',
        '',
        'application/octet-stream'
    ]

	const [chartName, setChartName] = useState('');
	const [error, setError] = useState(false);

    const handleNext = async () => {
        console.log('props', props, props.match.params.appid);
        if (activeStep === 0) {
            if (files.length > 0) {
                const formData = new FormData();
                files.map((item) => {
                    formData.append('file', item);
                })
                let response = await uploadCappsConfiguration({ files: formData, appId: props.match.params.appid });
                console.log("response", response);
            }
        } else if (activeStep === 1) {
            console.log("props.cappData", props.capps.data)
            let { orgId, appId, datasourceId } = props.capps.data[0];
            let recon = controlledBoard.columns[controlledBoard.columns.length - 1]
            console.log('recon', recon);
            let dataModel = {
                allowExternalElements: false,
                dataFormat: "JSON",
                name: recon.id + "_" + generateGUID(),
                collectionName: recon.id + "_" + generateGUID(),
                description: recon.title,
                dataElements: [...recon.cards, {
                    "defaultValue": "",
                    "isUnique": false,
                    "searcheable": false,
                    "lookup": false,
                    "validation": "false",
                    "name": 'result',
                    "description": 'Result',
                    "type": "String"
                }],
                datasourceId: datasourceId,
                orgId: orgId,
                appId: appId
            }
            props.saveDataModel(dataModel);
            setAppId(appId)
            setOrgId(orgId)
            setDatasourceId(datasourceId)
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    useEffect(() => {
        props.loadAppconfig(props.match.params.appid, props.user.orgId);
        props.getMember(props.user, props.match.params.appid);
    }, [])

    useEffect(() => {
        if (props.uploadSucess && activeStep === 0) {
            setCappSelected(props.capps.data[0]._id)
            setExploreDataElements(props.capps.data[0].dataElements.map(de => de.name))
            getCappConfiguration({ id: props.capps.data[0]._id, page: page, pageSize: rowsPerPage, appId: props.match.params.appid })
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            generateBoard();
        }
    }, [props.uploadSucess])

    useEffect(() => {
        if (props.dataModelSave && activeStep === 1) {
            setDataModels([...props.capps.data, props.dataModel])
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }, [props.dataModelSave])

    useEffect(() => {
        console.log("dataModels", dataModels);
        console.log("cappData", cappData);
        let dm = dataModels[dataModels.length - 1];
        console.log("dm", dm);
        if (dm && props.ruleExecutorSucess) {
            setCappSelected(dm._id)
            setExploreDataElements(dm.dataElements.map(de => de.name))
            // setResultDataElements(dm.dataElements.map())
            console.log('dm', dm._id);
            getCappConfiguration({ id: dm._id, page: page, pageSize: rowsPerPage, appId: props.match.params.appid });
        }
    }, [props.ruleExecutorSucess,
        activeStep //Need to remove later
    ])


    const generateBoard = () => {
        let columns = [];
        if (capps && capps.data) {
            console.log(' capps.data', capps.data);
            capps.data.map((item) => {
                let dataModel = {};
                dataModel.id = item._id;
                dataModel.title = item.name;
                let cards = [];
                item.dataElements && item.dataElements.map((ele, i) => {
                    let element = { ...ele }
                    element.id = ele._id;
                    element.title = ele.name;
                    element.description = ele.description;
                    let dm = JSON.parse(JSON.stringify(item))
                    delete dm.dataElements
                    element.fromDM = dm;
                    // element.dm.name = item.name;
                    // element.dm.collectionName = item.collectionName
                    cards.push(element);
                })
                dataModel.cards = cards;
                columns.push(dataModel);
            })
            let newDm = {
                id: 'Reconciliation',
                name: 'reconciliation',
                title: 'reconciliation',
                cards: []
            }
            let board = controlledBoard;
            board.columns = [...columns, newDm];
            setBoard(board);
        }
        console.log('board', board);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
    }, [files, cappSelected])

    const handleChange = (files) => {
        console.log("files--->", files);
        setFiles(files);
    }

    const handleChangeCapps = (event) => {
        console.log("cappSelected", event.target.value);
        setCappSelected(event.target.value);
        setExploreDataElements(capps.data.find(dm => dm._id === event.target.value).dataElements.map(de => de.name))
        getCappConfiguration({ id: event.target.value, page: page, pageSize: rowsPerPage, appId: props.match.params.appid });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getCappConfiguration({ id: cappSelected, page: newPage, pageSize: rowsPerPage, appId: props.match.params.appid });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        getCappConfiguration({ id: cappSelected, page: 0, pageSize: event.target.value, appId: props.match.params.appid });
    };

    const handleChangeFilter = (event, index) => {
        let dataList = filterList;
        if (event.target) {
            dataList[index][`${event.target.name}`] = event.target.value;
            console.log("dataList", dataList);
        }
        setFilterList(dataList);
        forceUpdate();
        filterCappConfiguration({ filterData: dataList, collectionName: cappSelected });
    };

    const handleAddFilter = () => {
        setFilterList([...filterList, { filterField: '', filterOperator: '', filterValue: '' }])
    };

    const addRecon = (name, desc) => {
        console.log('addRecon', name, desc);
        let ele = {
            id: name + "_" + generateGUID(),
            title: name,
            name: name,
            description: desc
        }
        console.log("controlledBoard.columns ===>",
            JSON.stringify(controlledBoard.columns[controlledBoard.columns.length - 1]));
        if (controlledBoard.columns[controlledBoard.columns.length - 1]) {
            let cb = controlledBoard.columns[controlledBoard.columns.length - 1]
            cb.cards.push(ele)
            console.log('cb ==>', JSON.stringify(cb));
            console.log('controlledBoard <==', controlledBoard);
            setBoard({ ...controlledBoard });
        }
    }

    const handleBasicRule = () => {
        setBasicRules([...basicRules, {
            name: '',
            keys: [{ ruleLeftDE: "", ruleRightDE: "" }],
            pairs: [{ ruleLeftDE: "", ruleRightDE: "", result: "" }]
        }])
    }

    const handleAddProcess = () => {
        setProcessFieldList([...processFieldList, {
            name: '',
            conditionsUIList: [{
                name: '',
                isSelectedValue: 1,
                dataModelLeftSelected: '',
                dataElementLeftList: [],
                dataElementLeftSelected: '',
                dataElementType: '',
                operatorSelected: '',
                inputField: '',
                selectedComparator: '',
                dataModelRightSelected: '',
                dataElementRightList: [],
                dataElementRightSelected: '',
            }], actionsUIList: [{
                isSelectedValue: 1,
                actionTypeSelected: "",
                dataModelSelected: '',
                dataElementList: [],
                selectedComparator: '',
                dataElementMultiList: [],
                dataElementMultiSelected: [],
                dataElementSelected: '',
                dataModelActionRightSelected: '',
                dataElementActionRightSelected: '',
                dataElementActionRightList: [],
                setValue: '',
                dataValue: '',
                ruleContext: '',
                isError: false
            }]
        }])
    };

    const editCondition = (condition, index) => {
        let cond = editCondAndLink(condition, index, conditions);
        setCondition(cond.obj);
        setConditions(cond.ary);
        console.log("conditiomdsds", condition, conditions);
    }

    //Basic rule
    const editBasicRule = (pair, index, pInd, type) => {
        let br = { ...basicRules[pInd] }
        let o = { ...br[type][index] }
        o.editMode = true;
        o.index = index;

        basicRule[type] = o
        setBasicRule({ ...basicRule })
        console.log("editBasicRule - br", basicRule, o);

        br[type][index] = o
        basicRules[pInd] = br
        setBasicRules([...basicRules])
        console.log("editBasicRule - br", br, o);
    }

    const deleteCondition = (condition, index) => {
        let cons = deleteCondAndLink(condition, index, conditions);
        setConditions(cons);
        if (cappSelected) {
            getCappConfiguration({ id: cappSelected, page: page, pageSize: rowsPerPage, conditions: cons, appId: props.match.params.appid });
        }
    }

    //Basic rule
    const deleteBasicRule = (pair, index, pInd, type) => {
        let br = { ...basicRules[pInd] }
        br[type].splice(index, 1)
        basicRules[pInd] = br
        setBasicRules([...basicRules])
        console.log("deleteBasicRule - br", br);
    }

    const addCondition = (condition) => {
        let con = { ...condition, selectedConditionClause: "and" };
        let cons = addCondAndLink(con, conditions);
        setCondition({ selectedConditionDataElement: "", selectedConditionOperator: "", value: "", selectedConditionClause: "" });
        setConditions(cons);
        if (cappSelected) {
            getCappConfiguration({ id: cappSelected, page: page, pageSize: rowsPerPage, conditions: cons, appId: props.match.params.appid });
        }
        console.log("consitions", cons, condition);
    }

    //Basic rule
    const addBasicRule = (pair, type, pInd) => {
        console.log('pair, type, pInd', pair, type, pInd);
        let br = { ...basicRules[pInd] }
        console.log("br", br);
        console.log("pair.editMode && pair.index", pair.editMode, pair.index);
        if (pair.editMode && pair.index > -1) {
            br[type][pair.index] = { ...pair, editMode: false }
        } else {
            if (!br[type])
                br[type] = []
            br[type].push({ ...pair })
        }
        basicRules[pInd] = br
        setBasicRules([...basicRules])
        basicRule[type] = { ruleLeftDE: "", ruleRightDE: "", result: "" }
        setBasicRule({ ...basicRule })
        console.log("addBasicRule - br", br);
    }

    const onConditionChangeHandler = event => {
        console.log("event", event);
        console.log("conditiomn", condition);
        let con = { ...condition };
        con[event.target.name] = event.target.value
        setCondition(con);
    }

    //Basic rule
    const onRuleChangeHandler = (event, type) => {
        console.log("event", event, type);
        console.log("basicRule", basicRule);
        let br = { ...basicRule };
        br[type][event.target.name] = event.target.value
        setBasicRule(br);
        console.log('====================================');
        console.log("onRuleChangeHandler - br", br);
        console.log("onRuleChangeHandler - basicRule", basicRules);
        console.log('====================================');
    }

    //Rule
    const addRuleConditions = (pIndex) => {
        let obj = {
            isSelectedValue: 1,
            dataModelLeftSelected: '',
            dataElementLeftList: [],
            dataElementLeftSelected: '',
            dataElementType: '',
            operatorSelected: '',
            inputField: '',
            selectedComparator: '',
            dataModelRightSelected: '',
            dataElementRightList: [],
            dataElementRightSelected: '',
        }
        let list = processFieldList;
        let conList = list[pIndex].conditionsUIList;
        conList.push(obj);
        list[pIndex].conditionsUIList = [...conList];
        setProcessFieldList([...list]);
    }

    const addRuleActions = (pIndex) => {
        let obj = {
            isSelectedValue: 1,
            actionTypeSelected: "",
            dataModelSelected: '',
            dataElementList: [],
            dataElementMultiList: [],
            dataElementMultiSelected: [],
            dataElementSelected: '',
            selectedComparator: '',
            dataModelActionRightSelected: '',
            dataElementActionRightSelected: '',
            dataElementActionRightList: [],
            setValue: '',
            dataValue: '',
            ruleContext: '',
            isError: false
        }
        let list = processFieldList;
        let actList = list[pIndex].actionsUIList;
        actList.push(obj);
        list[pIndex].actionsUIList = [...actList];
        setProcessFieldList([...list]);
    }

    const removeRuleConditions = (pIndex, cIndex) => {
        let list = processFieldList;
        list[pIndex].conditionsUIList.splice(cIndex, 1);
        setProcessFieldList([...list]);
    }

    const removeRuleActions = (pIndex, cIndex) => {
        let list = processFieldList;
        list[pIndex].actionsUIList.splice(cIndex, 1);
        setProcessFieldList([...list]);
    }


    const handleDataModelLeftChange = (event, pIndex, cIndex) => {
        console.log("handleDataModelLeftChange", "fire", dataModels);
        // let list = capps.data;
        let list = dataModels
        let plist = processFieldList;
        let conditionData = plist[pIndex].conditionsUIList;
        console.log("list -- ", list);
        console.log("conditionData", conditionData);
        for (let i = 0; i < list.length; i++) {
            console.log("list[i]._id === event.target.value", list[i]._id, event.target.value, "list[i]", list[i]);
            if (list[i]._id === event.target.value) {
                conditionData[cIndex].dataModelLeftSelected = event.target.value;
                conditionData[cIndex].dataElementLeftList = [...list[i].dataElements]
                plist[pIndex].conditionsUIList = conditionData
                break;
            } else {
                conditionData[cIndex].dataModelLeftSelected = "";
                conditionData[cIndex].dataElementLeftList = []
                plist[pIndex].conditionsUIList = conditionData
            }
        }
        setProcessFieldList([...plist]);
    };

    const handleElementsLeftChange = (event, pIndex, cIndex) => {
        let plist = processFieldList;
        let conditionData = plist[pIndex].conditionsUIList;
        conditionData[cIndex].dataElementLeftSelected = event.target.value;
        for (let i = 0; i < conditionData[cIndex].dataElementLeftList.length; i++) {
            if (conditionData[cIndex].dataElementLeftList[i].name === event.target.value) {
                conditionData[cIndex].dataElementType = conditionData[cIndex].dataElementLeftList[i].dataElementType;
                break;
            } else {
                conditionData[cIndex].dataElementType = "";
            }
        }
        plist[pIndex].conditionsUIList = conditionData
        setProcessFieldList([...plist]);
    }

    const handleOperatorChange = (event, pIndex, cIndex) => {
        let plist = processFieldList;
        let conditionData = plist[pIndex].conditionsUIList;
        conditionData[cIndex].operatorSelected = event.target.value;
        plist[pIndex].conditionsUIList = conditionData
        setProcessFieldList([...plist]);
    }

    const handleRuleName = (event, index) => {
        console.log("event", event, index);
        let plist = processFieldList;
        plist[index].name = event.target.value;
        setProcessFieldList([...plist]);
    }

    const handleCompartorChange = (event, pIndex, cIndex) => {
        let plist = processFieldList;
        let conditionData = plist[pIndex].conditionsUIList;
        conditionData[cIndex].selectedComparator = event.target.value;
        plist[pIndex].conditionsUIList = conditionData
        setProcessFieldList([...plist]);
    }

    const handleInpuFieldChange = (event, pIndex, cIndex) => {
        let plist = processFieldList;
        let conditionData = plist[pIndex].conditionsUIList;
        conditionData[cIndex].inputField = event.target.value;
        plist[pIndex].conditionsUIList = conditionData
        setProcessFieldList([...plist]);
    }

    const handleDataModelRightChange = (event, pIndex, cIndex) => {
        console.log("handleDataModelRightChange", "fire");
        // let list = capps.data;
        let list = dataModels;
        let plist = processFieldList;
        let conditionData = plist[pIndex].conditionsUIList;
        console.log("list -- ", list);
        console.log("conditionData", conditionData);
        for (let i = 0; i < list.length; i++) {
            console.log("list[i]._id === event.target.value", list[i]._id, event.target.value, "list[i]", list[i]);
            if (list[i]._id === event.target.value) {
                conditionData[cIndex].dataModelRightSelected = event.target.value;
                conditionData[cIndex].dataElementRightList = [...list[i].dataElements]
                plist[pIndex].conditionsUIList = conditionData
                break;
            } else {
                conditionData[cIndex].dataModelRightSelected = "";
                conditionData[cIndex].dataElementRightList = []
                plist[pIndex].conditionsUIList = conditionData
            }
        }
        setProcessFieldList([...plist]);
    };

    const handleElementsRightChange = (event, pIndex, cIndex) => {
        let plist = processFieldList;
        let conditionData = plist[pIndex].conditionsUIList;
        conditionData[cIndex].dataElementRightSelected = event.target.value;
        plist[pIndex].conditionsUIList = conditionData
        setProcessFieldList([...plist]);
    }

    const handleActionTypeChange = (event, pIndex, cIndex) => {
        let plist = processFieldList;
        let actionsData = plist[pIndex].actionsUIList;
        actionsData[cIndex].actionTypeSelected = event.target.value;
        plist[pIndex].actionsUIList = actionsData
        setProcessFieldList([...plist]);
    }

    const handleDataModelActionChange = (event, pIndex, cIndex) => {
        // let list = capps.data;
        let list = dataModels;
        let plist = processFieldList;
        let actionsData = plist[pIndex].actionsUIList;
        console.log("list -- ", list);
        console.log("actionsData", actionsData);
        for (let i = 0; i < list.length; i++) {
            console.log("list[i]._id === event.target.value", list[i]._id, event.target.value, "list[i]", list[i]);
            if (list[i]._id === event.target.value) {
                actionsData[cIndex].dataModelSelected = event.target.value;
                actionsData[cIndex].dataElementList = [...list[i].dataElements];
                actionsData[cIndex].dataElementMultiList = list[i].dataElements.map((item) => ({ label: item.name, value: item.name }));
                plist[pIndex].actionsUIList = actionsData
                break;
            } else {
                actionsData[cIndex].dataModelSelected = "";
                actionsData[cIndex].dataElementList = [];
                actionsData[cIndex].dataElementMultiList = [];
                plist[pIndex].actionsUIList = actionsData;
            }
        }
        setProcessFieldList([...plist]);
    };

    const handleElementsActionChange = (event, pIndex, cIndex) => {
        let plist = processFieldList;
        let actionsData = plist[pIndex].actionsUIList;
        actionsData[cIndex].dataElementSelected = event.target.value;
        plist[pIndex].actionsUIList = actionsData
        setProcessFieldList([...plist]);
    }


    const handleActionCompartorChange = (event, pIndex, cIndex) => {
        let plist = processFieldList;
        let actionsData = plist[pIndex].actionsUIList;
        actionsData[cIndex].selectedComparator = event.target.value;
        plist[pIndex].actionsUIList = actionsData
        setProcessFieldList([...plist]);
    }

    const handleDataModelActionRightChange = (event, pIndex, cIndex) => {
        console.log("handleDataModelRightChange", "fire");
        // let list = capps.data;
        let list = dataModels;
        let plist = processFieldList;
        let actionsData = plist[pIndex].actionsUIList;
        console.log("list -- ", list);
        console.log("actionsData", actionsData);
        for (let i = 0; i < list.length; i++) {
            console.log("list[i]._id === event.target.value", list[i]._id, event.target.value, "list[i]", list[i]);
            if (list[i]._id === event.target.value) {
                actionsData[cIndex].dataModelActionRightSelected = event.target.value;
                actionsData[cIndex].dataElementActionRightList = [...list[i].dataElements]
                plist[pIndex].actionsUIList = actionsData
                break;
            } else {
                actionsData[cIndex].dataModelActionRightSelected = "";
                actionsData[cIndex].dataElementActionRightList = []
                plist[pIndex].actionsUIList = actionsData
            }
        }
        setProcessFieldList([...plist]);
    };

    const handleElementsActionRightChange = (event, pIndex, cIndex) => {
        let plist = processFieldList;
        let actionsData = plist[pIndex].actionsUIList;
        actionsData[cIndex].dataElementActionRightSelected = event.target.value;
        plist[pIndex].actionsUIList = actionsData
        setProcessFieldList([...plist]);
    }

    const handleMultipleDataChange = (data, pIndex, cIndex) => {
        let plist = processFieldList;
        console.log("handleMultipleDataChange", data);
        let actionsData = plist[pIndex].actionsUIList;
        actionsData[cIndex].dataElementMultiSelected = data.target.value;
        plist[pIndex].actionsUIList = actionsData
        setProcessFieldList([...plist]);
    }

    const handleSetValueChange = (event, pIndex, cIndex) => {
        let plist = processFieldList;
        let actionsData = plist[pIndex].actionsUIList;
        actionsData[cIndex].setValue = event.target.value;
        plist[pIndex].actionsUIList = actionsData
        setProcessFieldList([...plist]);
    }

    const handleDataValueChange = (data, pIndex, cIndex) => {
        let plist = processFieldList;
        let actionsData = plist[pIndex].actionsUIList;
        actionsData[cIndex].dataValue = event.target.value;
        plist[pIndex].actionsUIList = actionsData
        setProcessFieldList([...plist]);
    }

    const handleExplorePage = (page) => {
        setExplorePage(page);
    }

    const handleProcessPage = (page) => {
        setProcessPage(page);
    }

    function handleCardMove(_card, source, destination) {
        const updatedBoard = moveCard(controlledBoard, source, destination);
        setBoard(updatedBoard);
    }

    function removeCard(card) {
        console.log('card', card);
        let c = controlledBoard.columns[controlledBoard.columns.length - 1]
        let reconCards
        if (c && Array.isArray(c.cards)) {
            reconCards = c.cards.filter(cd => {
                console.log("cd.id, card.id", cd.id, card.id);
                return cd.id !== card.id
            })
            c.cards = reconCards
            console.log("reconCards", JSON.stringify(reconCards));
            setBoard({ ...controlledBoard });
        }
    }

    const executeBasicRule = () => {
        console.log("basicRules", basicRules);
        let rulesList = basicRules[0].pairs.map((br, i) => {
            return {
                name: `${dataModels[0].name}.${br.ruleLeftDE} Mismatch ${dataModels[1].name}.${br.ruleRightDE}`,
                active: true,
                appId: capps.data[0].appId,
                description: "",
                ruleset: [{
                    rule_definition: {
                        conditions: prepareConditions(br, i),
                        actions: prepareActions(br, i)
                    }
                }]
            }
        })
        console.log("rulesList", rulesList);
        ruleExcution({ rules: rulesList, appId: props.match.params.appid });
    }

    function prepareConditions(br, i) {
        let keyCond = linkKeysToCondition()
        return [...keyCond, {
            "condition_seq": keyCond.length + 1,
            "operator": "not_matches",
            ...condProps(br)
        }]
    }

    function linkKeysToCondition() {
        return basicRules[0].keys.map((br, i) => {
            return {
                "condition_seq": i + 1,
                "operator": "matches",
                ...condProps(br)
            }
        })
    }

    function condProps(br) {
        return {
            "from_datamodel": dataModels[0]._id,
            "to_datamodel": dataModels[1]._id,
            "from_dataelement": br.ruleLeftDE,
            "to_dataelement": br.ruleRightDE,
            "selectedComparator": "datamodel"
        }
    }

    function prepareActions(br, i) {
        return [{
            "action_type": "set",
            "to_data_model": dataModels[dataModels.length - 1]._id,
            "to_data_element": "result",
            "data_value": br.result ? br.result
                : `${dataModels[0].name}.${br.ruleLeftDE} Mismatch ${dataModels[1].name}.${br.ruleRightDE}`,
            "selectedComparator": "inputField",
            "from_data_model": "",
            "from_data_element": ""
        }]
    }

    const resultEditClick = (item) => {
        let formName = "Issue";
        let url = `/apps/${capps.data[0].appId}/form/${formName}/new`
        let search = `?dm=${dataModels[dataModels.length - 1]._id}`
        search += '&page=0&rowsPerPage=10&type=list&fields='
        const reducer = (accumulator, currentValue) => accumulator + currentValue.name + ',';
        if (dataModels[dataModels.length - 1])
            search += dataModels[dataModels.length - 1].dataElements.reduce(reducer, '');
        console.log('url', url, 'search', search);
        history.push({
            pathname: url,
            search: search
        });
    }

    const excuteRules = () => {
        let rulesList = [];
        for (let i = 0; i < processFieldList.length; i++) {
            let data = {};
            data.name = processFieldList[i].name;
            data.active = true;
            data.appId = capps.data[0].appId;
            data.description = ""
            data.ruleset = [];
            data.ruleset.push({ "rule_definition": {} });
            let conditionArray = processFieldList[i].conditionsUIList.map((item, index) => {
                if (item.dataModelLeftSelected !== "" && item.dataElementLeftSelected !== "" && ((item.dataModelRightSelected !== "" && item.dataElementRightSelected !== "") || item.inputField !== "")) {
                    let obj = {};
                    obj.condition_seq = index + 1;
                    //console.log("this.props.dataModels", this.props.dataModels);
                    // capps.data.map((element) => {
                    dataModels.map((element) => {
                        //console.log("this.state.dataModels11", element);
                        if (element._id === item.dataModelLeftSelected) {
                            obj.from_datamodel = element._id;
                        }
                        if (element._id === item.dataModelRightSelected) {
                            obj.to_datamodel = element._id;
                        }
                    });

                    obj.from_dataelement = item.dataElementLeftSelected;
                    obj.to_dataelement = item.dataElementRightSelected;
                    obj.dataElementType = item.dataElementType;
                    obj.operator = item.operatorSelected;
                    obj.selectedComparator = item.selectedComparator;
                    obj.inputField = item.inputField;
                    return obj
                }
            }).filter(function (element) {
                return element !== undefined;
            });

            console.log("data after updating conditions==>", data);
            data.ruleset[0].rule_definition.conditions = conditionArray;

            let actionsArray = processFieldList[i].actionsUIList.map((item) => {
                if (item.actionTypeSelected !== "") {
                    if (item.actionTypeSelected === "get" && item.dataModelSelected !== "" && item.dataElementMultiSelected.length > 0) {
                        let obj = {};
                        obj.action_type = "get";
                        // capps.data.map((ele) => {
                        dataModels.map((ele) => {
                            if (ele._id === item.dataModelSelected) {
                                obj.data_model = ele._id;
                            }
                        });
                        obj.data_elements = [];
                        item.dataElementMultiSelected.map((multiEle) => {
                            obj.data_elements.push(multiEle);
                        })
                        return obj;
                    } else if (item.actionTypeSelected === "set" && item.dataModelSelected !== ""
                        && item.dataElementSelected !== ""
                        && (item.setValue !== ""
                            || (item.dataModelActionRightSelected !== ""
                                && item.dataElementActionRightSelected !== ""))) {
                        let obj = {};
                        obj.action_type = "set";
                        // capps.data.map((ele) => {
                        console.log('dataModels', dataModels);
                        // dataModels.map((ele) => {
                        //     if (ele._id === item.dataModelSelected) {
                        //         obj.to_data_model = ele._id;
                        //     }
                        //     if (ele._id === item.dataModelActionRightSelected) {
                        //         obj.from_data_model = ele._id;
                        //     }
                        // });
                        console.log("item --==>", item);
                        obj.to_data_model = item.dataModelSelected;
                        obj.to_data_element = item.dataElementSelected;
                        obj.data_value = item.setValue;
                        obj.selectedComparator = item.selectedComparator;
                        obj.from_data_model = item.dataModelActionRightSelected;
                        obj.from_data_element = item.dataElementActionRightSelected;
                        console.log('obj', obj);
                        return obj;
                    }
                }
            }).filter(function (element) {
                return element !== undefined;
            });
            data.ruleset[0].rule_definition.actions = actionsArray;
            console.log("data1", data);
            rulesList.push(data);
        }
        console.log("rulesList", rulesList);
        ruleExcution({ rules: rulesList, appId: props.match.params.appid });
    }

    useEffect(() => {
        console.log('controlledBoard', controlledBoard);
    }, [controlledBoard])

    return (
        <React.Fragment>
            {console.log("processFieldList", processFieldList)}
            {
                console.log("cappData.data[0]", cappData.data)
            }
            <NavBar header={header} user={user} app={props.app} member={props.member} />
            <div style={{ backgroundColor: "#FFF", padding: "0px", minHeight: "100vh" }}>
                <div className={classes.stepper}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {console.log("activeStep", activeStep)}
                    <div>
                        {activeStep === 0 &&
                            <DropzoneArea
                                onChange={handleChange}
                                acceptedFiles={acceptedFiles}
                                showPreviews={false}
                                maxFileSize={50000000}
                                filesLimit={5}
                            />
                        }
                        <Grid container spacing={3}>
                            {activeStep === 0 && <Grid item xs={8}>
                                {
                                    files.map((item, i) => {
                                        return <Grid item xs={12} alignItems="center" justify="center" container spacing={0} key={i}>
                                            <Grid item xs={6} container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center" >
                                                <Typography color="primary">
                                                    {item.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} container spacing={0}>
                                                <Button size="small" color="primary" variant="contained" style={{ margin: "5px" }}>Edit</Button>
                                                <Button size="small" color="primary" variant="contained" style={{ margin: "5px" }}>Delete</Button>
                                            </Grid>
                                        </Grid>
                                    })
                                }

                            </Grid>}

                            {activeStep === 1 &&
                                <Grid item xs={12} container>
                                    <Grid item xs={12} container>
                                        <Grid item xs={6} container justify="flex-start" alignItems="center">
                                            <ButtonGroup
                                                style={{
                                                    paddingLeft: "30px"
                                                }}
                                                size="secondary" aria-label="secondary outlined button group">
                                                <Button>
                                                    <Typography onClick={() => handleExplorePage("explore")}
                                                        color={explorePage === "explore" ? "primary" : ""}
                                                        style={{ cursor: "pointer" }}>
                                                        Build
                                                    </Typography>
                                                </Button>
                                                <Button>
                                                    <Typography onClick={() => handleExplorePage("build")}
                                                        color={explorePage === "build" ? "primary" : ""}
                                                        style={{ cursor: "pointer" }}>
                                                        Explore
                                                    </Typography>
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                        {/* <div>
                                            <Button variant="contained" color="primary">
                                                Add
                                            </Button>
                                        </div> */}
                                        <FormDialog addRecon={addRecon} />
                                        {explorePage === "build" && <Grid item xs={6} container justify="flex-end" alignItems="center">
                                            <Typography>Select Datamodel</Typography>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-outlined-label">Select</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={cappSelected}
                                                    onChange={handleChangeCapps}
                                                    label="Select"
                                                >
                                                    <MenuItem value="">
                                                        <em>Select Capps</em>
                                                    </MenuItem>
                                                    {capps && capps.data && capps.data.map((item, index) => {
                                                        return <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>}
                                    </Grid>
                                    {explorePage === "explore" && <React.Fragment>
                                        <Grid item xs={12} md={12} container>
                                            <Board onCardDragEnd={handleCardMove}
                                                allowRemoveLane
                                                allowRenameColumn
                                                allowRemoveCard
                                                onLaneRemove={console.log}
                                                onCardRemove={removeCard}
                                                onLaneRename={console.log}
                                                onCardDragEnd={handleCardMove}
                                                disableColumnDrag>
                                                {controlledBoard}
                                            </Board>
                                        </Grid>
                                    </React.Fragment>}
                                    {explorePage === "build" && <React.Fragment>
                                        <Typography>Filters</Typography>
                                        <Grid item xs={12} container>
                                            <Conditions
                                                values={{
                                                    conditions: conditions, condition: condition,
                                                    dataElements: exploreDataElements
                                                }}
                                                classes={classes}
                                                onConditionChangeHandler={onConditionChangeHandler}
                                                addCondition={addCondition}
                                            />
                                        </Grid>
                                        <Grid item xs={12} container>
                                            <Typography component="legend">
                                                Filters Table
                                            </Typography>
                                            <ConditionsTable
                                                conditions={conditions}
                                                editCondition={editCondition}
                                                deleteCondition={deleteCondition}
                                            />
                                        </Grid>

                                        <div className="w-full flex flex-col" style={{ marginTop: "10px", marginBottom: "20px" }}>
                                            {cappData && cappData.data && (cappData.data.length > 0) && <> <TableContainer><Table className="min-w-xl" aria-labelledby="tableTitle" size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        {Object.keys(cappData.data[0]).map((item, i) => {
                                                            return <TableCell key={i}>{item}</TableCell>
                                                        })}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {cappData.data.map((item, index) => {
                                                        return <TableRow key={index}>
                                                            {Object.keys(cappData.data[0]).map((data, i) => {
                                                                return <TableCell key={i}>
                                                                    {item[`${data}`]}
                                                                </TableCell>
                                                            })}
                                                        </TableRow>
                                                    })}
                                                </TableBody>
                                            </Table></TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 100]}
                                                    component="div"
                                                    count={cappData.total}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </>
                                            }
                                        </div>
                                    </React.Fragment>}
                                </Grid>}

                            {activeStep === 2 &&
                                <Grid item xs={12} container>
                                    <Grid item xs={12} container>
                                        <Grid item xs={6} container justify="flex-start" alignItems="center">
                                            <Typography>Setup rules for reconciliation</Typography>
                                        </Grid>
                                        {processPage !== "basic"
                                            && <Grid item xs={6} container justify="flex-end" alignItems="center">
                                                <Button variant="contained"
                                                    color="primary"
                                                    onClick={handleAddProcess}
                                                    className={classes.formControl}>
                                                    Add New Rule
                                            </Button>
                                            </Grid>}
                                    </Grid>
                                    <Grid item xs={6} container justify="flex-start" alignItems="center">
                                        <ButtonGroup
                                            style={{
                                                paddingLeft: "30px"
                                            }}
                                            size="secondary" aria-label="secondary outlined button group">
                                            <Button>
                                                <Typography onClick={() => handleProcessPage("basic")}
                                                    color={processPage === "basic" ? "primary" : ""}
                                                    style={{ cursor: "pointer" }}>
                                                    Basic
                                                    </Typography>
                                            </Button>
                                            <Button>
                                                <Typography onClick={() => handleProcessPage("advanced")}
                                                    color={processPage === "advanced" ? "primary" : ""}
                                                    style={{ cursor: "pointer" }}>
                                                    Advanced
                                                    </Typography>
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                    {processPage === "basic" &&
                                        <Grid item xs={12}>
                                            {basicRules.map((br, index) => {
                                                return <div key={index}>
                                                    <Box m={2}>
                                                        <Paper variant="outlined">
                                                            <Box p={2}>
                                                                <Grid item xs={12} container justify="flex-start" alignItems="center">
                                                                    <Grid item xs={1}>
                                                                        <Typography>Rule</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            className={classes.formControl}
                                                                            label="Enter Rule Name"
                                                                            autoFocus
                                                                            id="name"
                                                                            name="name"
                                                                            value={processFieldList[index].name}
                                                                            onChange={(e) => handleRuleName(e, index)}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Box m={2}>
                                                                    <Typography>Select key elements</Typography>
                                                                    <BasicRule
                                                                        classes={classes}
                                                                        values={basicRule}
                                                                        pInd={index}
                                                                        type="keys"
                                                                        onRuleChangeHandler={onRuleChangeHandler}
                                                                        addBasicRule={addBasicRule}
                                                                    />
                                                                    <BasicRulesTable
                                                                        classes={classes}
                                                                        values={br}
                                                                        pInd={index}
                                                                        type="keys"
                                                                        editBasicRule={editBasicRule}
                                                                        deleteBasicRule={deleteBasicRule}
                                                                        dataModels={dataModels}
                                                                    />
                                                                </Box>
                                                                <Box m={2}>
                                                                    <Typography>Select pairs</Typography>
                                                                    <BasicRule
                                                                        classes={classes}
                                                                        values={basicRule}
                                                                        pInd={index}
                                                                        type="pairs"
                                                                        onRuleChangeHandler={onRuleChangeHandler}
                                                                        addBasicRule={addBasicRule}
                                                                    />
                                                                    <BasicRulesTable
                                                                        classes={classes}
                                                                        values={br}
                                                                        pInd={index}
                                                                        type="pairs"
                                                                        editBasicRule={editBasicRule}
                                                                        deleteBasicRule={deleteBasicRule}
                                                                        dataModels={dataModels}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Paper>
                                                    </Box>
                                                </div>
                                            })}
                                        </Grid>
                                    }
                                    {processPage === "advanced" &&
                                        <Grid item xs={12}>
                                            {processFieldList.map((item, index) => {
                                                return <div key={index}>
                                                    <Box m={2}>
                                                        <Paper variant="outlined">
                                                            <Box p={2}>
                                                                <Grid item xs={12} container justify="flex-start" alignItems="center">
                                                                    <Grid item xs={1}>
                                                                        <Typography>Rule:</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <TextField
                                                                            className={classes.formControl}
                                                                            label="Enter Rule Name"
                                                                            autoFocus
                                                                            id="name"
                                                                            name="name"
                                                                            value={processFieldList[index].name}
                                                                            onChange={(e) => handleRuleName(e, index)}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Rule
                                                                    pIndex={index}
                                                                    handleDataModelLeftChange={handleDataModelLeftChange}
                                                                    handleElementsLeftChange={handleElementsLeftChange}
                                                                    handleOperatorChange={handleOperatorChange}
                                                                    handleCompartorChange={handleCompartorChange}
                                                                    handleDataModelActionRightChange={handleDataModelActionRightChange}
                                                                    handleElementsActionRightChange={handleElementsActionRightChange}
                                                                    handleDataModelRightChange={handleDataModelRightChange}
                                                                    handleElementsRightChange={handleElementsRightChange}
                                                                    handleInpuFieldChange={handleInpuFieldChange}
                                                                    handleDataModelActionChange={handleDataModelActionChange}
                                                                    handleElementsActionChange={handleElementsActionChange}
                                                                    handleMultipleDataChange={handleMultipleDataChange}
                                                                    handleSetValueChange={handleSetValueChange}
                                                                    handleDataValueChange={handleDataValueChange}
                                                                    handleActionTypeChange={handleActionTypeChange}
                                                                    handleActionCompartorChange={handleActionCompartorChange}
                                                                    removeConditions={removeRuleConditions}
                                                                    addConditions={addRuleConditions}
                                                                    removeActions={removeRuleActions}
                                                                    addActions={addRuleActions}
                                                                    classes={classes}
                                                                    values={{ "actionsUIList": processFieldList[index].actionsUIList, "conditionsUIList": processFieldList[index].conditionsUIList }}
                                                                    selectedComparatorList={selectedComparatorList}
                                                                    actionTypesList={actionTypesList}
                                                                    dataTypeNumList={dataTypeNumList}
                                                                    dataTypeStringList={dataTypeStringList}
                                                                />
                                                            </Box>
                                                        </Paper>
                                                    </Box>
                                                </div>
                                            })}
                                        </Grid>
                                    }
                                    <Grid item xs={12} style={{ textAlign: "end" }}>
                                        <Button variant="contained" color="primary"
                                            onClick={processPage === "advanced" ? excuteRules : executeBasicRule}
                                            className={classes.formControl}
                                            disabled={processPage === "advanced"
                                                ? false
                                                : !(basicRules[0].keys && basicRules[0].keys.length > 0
                                                    && basicRules[0].pairs && basicRules[0].pairs.length > 0)}>
                                            {
                                                props.ruleExecutorSucess
                                                    ? 'Done'
                                                    : 'Execute'
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                            {
                                activeStep === 3 && <Grid item xs={12} container>
                                    <Results classes={classes} dataModels={dataModels} appId={props.match.params.appid} />

                                    <Grid item xs={12} container>
                                        <Grid item xs={8} container>
                                            <Typography>Filter by</Typography>
                                            <Conditions
                                                values={{
                                                    conditions: conditions, condition: condition,
                                                    dataElements: exploreDataElements
                                                }}
                                                classes={classes}
                                                onConditionChangeHandler={onConditionChangeHandler}
                                                addCondition={addCondition}
                                            />
                                        </Grid>
                                        <Grid item xs={4} container>
                                            <Button variant="contained" color="primary">Create Issues</Button>
                                            <Button variant="contained" color="primary">Export</Button>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} container>
                                        <Typography component="legend">
                                            Filters Table
                                    </Typography>
                                        <ConditionsTable
                                            conditions={conditions}
                                            editCondition={editCondition}
                                            deleteCondition={deleteCondition}
                                        />
                                    </Grid>

                                    <Grid item xs={12} container>
                                        <div className="w-full flex flex-col" style={{ marginTop: "10px", marginBottom: "20px" }}>
                                            {dataModels && dataModels[dataModels.length - 1] && dataModels[dataModels.length - 1].dataElements
                                                && <> <TableContainer><Table className="min-w-xl" aria-labelledby="tableTitle" size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            {dataModels[dataModels.length - 1].dataElements.map((de, i) => {
                                                                if (de.name !== "_id" && !de.name.includes("workflow"))
                                                                    return <TableCell key={i}>{de.name}</TableCell>
                                                            })}
                                                            <TableCell >Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {cappData.data.map((item, index) => {
                                                            return <TableRow key={index}>
                                                                {dataModels[dataModels.length - 1].dataElements.map((de, i) => {
                                                                    if (de.name !== "_id" && !de.name.includes("workflow"))
                                                                        return <TableCell key={i}>
                                                                            {item[`${de.name}`]}
                                                                        </TableCell>
                                                                })}
                                                                <TableCell style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                    onClick={event => resultEditClick(item)}>
                                                                    <EditIcon />
                                                                </TableCell>
                                                            </TableRow>
                                                        })}
                                                    </TableBody>
                                                </Table></TableContainer>
                                                    <TablePagination
                                                        rowsPerPageOptions={[10, 25, 100]}
                                                        component="div"
                                                        count={cappData.total}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                </>
                                            }
                                        </div>
                                    </Grid>

                                </Grid>
                            }
                            {
                                activeStep === 4 && <Grid container spacing={3}>
                                    <Grid item xs={3} />
                                    <Grid item xs={4}>
                                        <div style={{ "paddingTop": "50px" }}>
                                            <TextField
                                                style={{ backgroundColor: "#fff" }}
                                                className="mt-8 mb-16"
                                                label="Type your question"
                                                id="chartName"
                                                name="chartName"
                                                value={chartName}
                                                onChange={(e) => {
                                                    setChartName(e.target.value)
                                                    setError(false)
                                                }}
                                                variant="outlined"
                                                fullWidth
                                            />

                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{ "paddingTop": "50px" }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                style={{ backgroundColor: "#68389A" }}
                                                // onClick={eve => handleAskDsilo(eve)}
                                            >
                                                Ask Dsilo
                                </Button>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                </Grid>
                            }
                            <Grid item xs={activeStep === 0 ? 4 : 12}>
                                <div style={{ float: "right" }}>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}>Back</Button>
                                    <Button variant="contained" color="primary" onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );

};
const mapStateToProps = state => {
    return {
        user: state.user,
        app: state.appConfig.app,
        member: state.appConfig.member,
        capps: state.cappsConfig.capps,
        cappData: state.cappsConfig.cappData,
        uploadSucess: state.cappsConfig.uploadSucess,
        ruleExecutorSucess: state.cappsConfig.ruleExecutorSucess,
        dataModelSave: state.appConfig.dataModelSave,
        dataModel: state.appConfig.dataModel
    };
};

const mapDispatchToProps = {
    uploadCappsConfiguration,
    getCappConfiguration,
    filterCappConfiguration,
    ruleExcution,
    getMember,
    saveDataModel,
    loadAppconfig
};

export default hot(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Configuration),
);