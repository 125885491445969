import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import axios from 'axios';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import MRPlanUploadConfirmDialog from './planUploadConfirmDialog';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { setPlanUploadLoading } from '../../../../../store/actions';
import { UploadLoading } from '../../../../Loading/uploadLoading';
import { getMonth } from '../../../../../utility/utils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UploadMRPlan = (props) => {

    const [state, setState] = useState({
        open: false,
        files: [],
        file: null,
        fileId: props.fileId,
    });
    const [confirmState, setConfirm] = useState({ isOpen: false, confirmMessage: '' });

    const handleClose = (open) => {
        setState({ [open]: false, });
    };

    const submitForm = (contentType, data, setResponse) => {
        let url = `${API_BASE_URL}` + '/mrspendplan/upload/' + props.page.pageLevelDataModel

        props.setPlanUploadLoading(true);
        axios({
            url: url,
            method: 'POST',
            data: data,
            headers: {
                'Content-Type': contentType,
                appId: props.match.params.appid,
            },
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log('percentCompleted', percentCompleted)
            }
        })
            .then((response) => {
                setResponse(response.data);
                props.setPlanUploadLoading(false);
                if (response.data?.status === 400) {
                    setConfirm({
                        ...confirmState, isOpen: true,
                        confirmMessage: { title: props.page.name + ' - Save', message: response.data?.result?.message },
                    })
                } else {
                    setConfirm({
                        ...confirmState, isOpen: true,
                        confirmMessage: { ...response.data?.result?.data, title: props.page.name + ' - Save', }
                            || 'Upload successful',
                    });
                }
            })
            .catch((error) => {
                setConfirm({
                    ...confirmState, isOpen: true,
                    confirmMessage: { title: props.page.name + ' - Save', message: error?.response.data?.message || 'Details not ' },
                })
                props.setPlanUploadLoading(false);
                setResponse({ 'error': error });
            });
    };

    const uploadWithFormData = (files) => {
        const { CurrentFF, FinancialYear, AccountName } = props.account;
        const formData = new FormData();
        let fiscalYear = Number(FinancialYear),
        currentFF = getMonth(CurrentFF, 'monthNumber');
        formData.append('file', files[0]);
        formData.append('account', AccountName);
        formData.append('fiscalYear', fiscalYear);
        formData.append('currentFF', (currentFF > 9 ? '' : '0') + currentFF);
        formData.append('formType', props.type);
        submitForm('multipart/form-data', formData, (res) => {
            props.handleSave();
        });
    };

    const handleSave = (files) => {
        setState({
            files: files,
        });
        uploadWithFormData(files);
    };

    const handleOpen = (open) => {
        setState({
            [open]: true,
        });
    };

    const confirmHandler = () => {
        setConfirm({ ...confirmState, isOpen: false, confirmMessage: '' });
    };

    let acceptedFiles = props.acceptedFiles
        ? props.acceptedFiles
        : ['application/vnd.ms-excel', 'text/csv'];


    return (<>
    {
  props.modalData?null : (
        <Button onClick={e => handleOpen('open')} size="large" color="primary" variant="contained"
            startIcon={<CloudUploadIcon />} className="m-right-10">
            {'Upload'}
        </Button>)}
        <DropzoneDialog
            open={state.open}
            onSave={(files) => {
                handleClose('open');
                handleSave(files);
            }}
            acceptedFiles={acceptedFiles}
            showPreviews={true}
            maxFileSize={20000000}
            filesLimit={1}
            onClose={e => handleClose('open')}
        />
        {props.planUploadLoading && <UploadLoading />}

        <MRPlanUploadConfirmDialog open={confirmState.isOpen} handleClose={confirmHandler} alertMessageText={confirmState.confirmMessage} isUpload={true} title={props.page.title}/>
    </>);
};

const mapDispatchToProps = {
    setPlanUploadLoading,
};
const mapStateToProps = (state) => {
    return {
        selectBrand: state.tprReducer.selectBrand,
        planUploadLoading: state.tprReducer.planUploadLoading,
        account: state.mrReducer.account

    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadMRPlan)));
