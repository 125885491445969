import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CellShape from './CellShape';
import { TableRow , withStyles} from '@material-ui/core'
const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow)
class Row extends PureComponent {
	render() {
		return <StyledTableRow  className={`no-wrap ${this.props.row ===0 && 'sheet-header'}`}>{this.props.children}</StyledTableRow>
	}
}

Row.propTypes = {
	row: PropTypes.number.isRequired,
	cells: PropTypes.arrayOf(PropTypes.shape(CellShape)).isRequired,
};

export default Row;
