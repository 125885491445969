import { ActionTypes } from 'constants/index';

const initialState = {
    page: {},
    loading: false,
    error: ''
};

export default function pageReducer(state = initialState, action) {
    
    switch (action.type) {
        case ActionTypes.LOAD_PAGES_LOADING: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }
        case ActionTypes.LOAD_PAGES_SUCCESS: {
            return {
                ...state,
                page: action.payload,
                loading: false
            }
        }
        case ActionTypes.LOAD_PAGES_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case ActionTypes.CLEAR_PAGES: {
            return {
                ...state,
                page: [],
            }
        }
        default: {
            return state;
        }
    }
}