import { put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import { TrialsDiscountsService } from '../../../modules/api/custom/spendplan/trialsDiscounts';


async function fetchAsync(func, arg) {
  const response = arg ? await func(arg) : await func();
  return response;
}

function* fetchTrialDiscountsData(action) {
  try {
    const tableData = yield fetchAsync(TrialsDiscountsService.getTrialDiscounts, action.payload);
    yield put({ type: ActionTypes.GET_TRIALS_DISCOUNT_SUCCESS, payload: tableData });
  }
  catch (err) {
    yield put({ type: ActionTypes.GET_TRIALS_DISCOUNT_FAILURE, error: err.message });
  }
}

function* fetchTrialDiscountsExtraData(action) {
  try {
    const tableData = yield fetchAsync(TrialsDiscountsService.getTrialDiscountExtraData, action.payload);
    yield put({ type: ActionTypes.GET_TRIAL_DISCOUNTS_EXTRA_DATA_SUCCESS, payload: tableData.result.data });
  }
  catch (err) {
    yield put({ type: ActionTypes.GET_TRIAL_DISCOUNTS_EXTRA_DATA_FAILURE, extraDataError: err.message });
  }
}

function* addNewTrialDiscount(action) {
  try {
    const response = yield fetchAsync(TrialsDiscountsService.addNewTrialDiscount, action.payload)
    yield put({ type: ActionTypes.ADD_NEW_TRIALS_DISCOUNT_SUCCESS, payload: response })
  } catch (err) {
     let errorMsg, error;
        if (err.response.error) {
            error = err.response.error; 
        } else {
            errorMsg = err.message != 'Bad Request' ? err.message : 'Please enter correct Data';
        }
        yield put({ type: ActionTypes.ADD_NEW_TRIALS_DISCOUNT_FAILURE, error: errorMsg?errorMsg:error });
  }
}

function* deleteCheckedConsumerPromos(action) {
  try {
    const response = yield fetchAsync(TrialsDiscountsService.deleteCheckedTrialDiscounts, action.payload)
    yield put({ type: ActionTypes.DELETE_CHECKED_TRIALS_DISCOUNT_SUCCESS, payload: response })
  }
  catch (err) {
    let errorMsg = err.message !== 'Bad Request' ? err.message : 'Please enter correct Data';
    yield put({ type: ActionTypes.DELETE_CHECKED_TRIALS_DISCOUNT_FAILURE, error: errorMsg });
  }
}

function* fetchPlans(action) {
    try {
        const response = yield fetchAsync(TrialsDiscountsService.getPlans, action.payload)
        yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_TRIAL_DISCOUNT_SUCCESS, payload: response?.result?.data ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'check request Failed';
        yield put({ type: ActionTypes.GET_PLAN_FILTER_DATA_TRIAL_DISCOUNT_FAILURE, error: errorMsg });
    }
}

export function* trialsDiscounts() {
  yield takeLatest(ActionTypes.GET_TRIALS_DISCOUNT_DATA, fetchTrialDiscountsData);
  yield takeLatest(ActionTypes.ADD_NEW_TRIALS_DISCOUNT, addNewTrialDiscount);
  yield takeLatest(ActionTypes.DELETE_CHECKED_TRIALS_DISCOUNT, deleteCheckedConsumerPromos);
  yield takeLatest(ActionTypes.GET_TRIAL_DISCOUNTS_EXTRA_DATA, fetchTrialDiscountsExtraData);
  yield takeLatest(ActionTypes.GET_PLAN_FILTER_DATA_TRIAL_DISCOUNT, fetchPlans);

}

export default trialsDiscounts;
