import React from 'react';
import {
	TableHead, TableSortLabel, TableCell, TableRow, Checkbox, Tooltip, IconButton, Icon,
	Menu, MenuList, MenuItem, ListItemIcon, ListItemText,
	withStyles
} from '@material-ui/core';
import classNames from 'classnames'
import {
	getTableHeaderStyles
} from 'modules/common';

const styles = theme => ({
	root: {},
	actionsButtonWrapper: {
		position: 'absolute',
		top: 0,
		left: 64,
		// width: 64,
		// height: 63,
		zIndex: 10,
		background: theme.palette.background.paper,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center'
	}
});

class CustomTableHead extends React.Component {
	state = {
		selectedMenu: null
	};

	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	openSelectedMenu = (event) => {
		this.setState({ selectedMenu: event.currentTarget });
	};

	closeSelectedMenu = () => {
		this.setState({ selectedMenu: null });
	};


	setCellProps = (cellValue) => {
		let b = {};
		chartData.backgrounds && chartData.backgrounds.map(bg => {
			if (bg.selectedBackgroundDataElement === value
				&& bg.selectedBackgroundFieldValue === cellValue) {
				b.style = { backgroundColor: bg.selectedBackgroundValue }
			}
		})
		return b;
		// className: classnames({
		// 	[this.props.classes.NameCell]: value === "Complete"
		// })
	}

	getTableHeader = (data) => {
		let tableHeader = [];
		if (data.datasets && data.datasets.length > 0) {
				data.columns.map((item) => {
					for (let key in data.datasets[0]) {
					if (item.checked && item.name === key) {
						if (key != '_id') {
							let headerItem = {
								id: key.replace(' ', ''),
								name: key,
								label: item.label,
								desc: "subtitle",
								disablePadding: false,
								numeric: false,
								sort: true
							}
							tableHeader.push(headerItem)
						}
					}
				}
				})

			data.columns.map((item) => {
				let th = tableHeader.find(h => h.label === item.label)
				if(!th){
					let headerItem = {
						id: item.name.replace(' ', ''),
						name: item.name,
						label: item.label,
						desc: "subtitle",
						disablePadding: false,
						numeric: false,
						sort: true
					}
					tableHeader.push(headerItem)
				}
			})
			tableHeader.push({
				id: "actions",
				name: "actions",
				label: "Actions",
				desc: "subtitle",
				disablePadding: false,
				numeric: false,
				sort: false
			})
		}
		return tableHeader;
	};

	render() {
		const { onSelectAllClick, order, orderBy, numSelected, rowCount, classes, hasCheckbox,
			headerstyle, subHeaderstyle, isTableSubHeader } = this.props;
		const { selectedMenu } = this.state;

		let tableHeader = this.getTableHeader(this.props.data);
		return <TableHead>
			<TableRow className={classNames("h-64 cursor-pointer", this.props.hasCheckbox ? 'checkbox' : '')}>
				{hasCheckbox && <TableCell padding="checkbox"
				// style={getTableHeaderStyles(headerstyle, ["color"])} 
				>
					{!this.props.isRadio  && <Checkbox
						// style={getTableHeaderStyles(headerstyle, ["color"])}
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={numSelected === rowCount}
						onChange={onSelectAllClick}
						size="small"
					/>}
					{/* {numSelected > 0 && ( //TODO: paused for test purpose/ need to make configurable
						<div className={classes.actionsButtonWrapper}>
							<IconButton
								aria-owns={selectedMenu ? 'selectedMenu' : null}
								aria-haspopup="true"
								onClick={this.openSelectedMenu}
							>
								<Icon>{"more_horiz"}</Icon>
							</IconButton>
							<Menu
								id="selectedMenu"
								anchorEl={selectedMenu}
								open={Boolean(selectedMenu)}
								onClose={this.closeSelectedMenu}
							>
								<MenuList>
									<MenuItem
										onClick={() => {
											this.closeSelectedMenu();
										}}
									>
										<ListItemIcon className={classes.icon}>
											<Icon>{"delete"}</Icon>
										</ListItemIcon>
										<ListItemText inset primary="Remove" />
									</MenuItem>
								</MenuList>
							</Menu>
						</div>
					)} */}
				</TableCell>}
				{tableHeader.map(row => {
					return <TableCell
						key={row.id}
						style={{ padding: "0px" }}
						sortDirection={orderBy === row.id ? order : false}>
						<div style={{
							display: "block",
							...getTableHeaderStyles(headerstyle, ["backgroundColor", "color", "textAlign", "fontSize"])
						}}>
							{row.sort
								? <Tooltip
									title={"Sort"}
									placement={row.numeric ? 'bottom-end' : 'bottom-start'}
									enterDelay={300}
									style={{
										display: "block",
										// padding: "16px 0px 16px 0px",
										...getTableHeaderStyles(headerstyle, ["fontSize"])
									}}>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}>
										{typeof row.label === 'object' && row.label !== null ? row.label.labelText : row.label}
									</TableSortLabel>
								</Tooltip>
								: <Tooltip
									title={"Actions"}
									placement={row.numeric ? 'bottom-end' : 'bottom-start'}
									enterDelay={300}
									style={{
										display: "block",
										// padding: "16px 0px 16px 0px",
										...getTableHeaderStyles(headerstyle, ["backgroundColor", "fontSize"])
									}}>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}>
										{row.label}
									</TableSortLabel>
								</Tooltip>
							}
						</div>
						{isTableSubHeader && <div style={{
							display: "block",
							...getTableHeaderStyles(subHeaderstyle, ["backgroundColor", "color", "textAlign", "fontSize"])
						}}>
							<TableSortLabel
								active={orderBy === row.id}
								direction={order}
								onClick={this.createSortHandler(row.id)}>
								{row.desc}
							</TableSortLabel>
						</div>}
					</TableCell>
				}, this)}
			</TableRow>
		</TableHead >
	}
}

export default withStyles(styles, { withTheme: true })(CustomTableHead);
