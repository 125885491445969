import { ActionTypes } from 'constants/index';

export const getDisplays = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_DISPLAYS_DATA, payload: data });
};

export const addNewDisplays = (data) => dispatch => {
  dispatch({ type: ActionTypes.ADD_NEW_DISPLAYS, payload: data });
};

export const deleteCheckedDisplays = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_CHECKED_DISPLAYS, payload: data });
};

export const getDisplaysExtraData = (payload) => dispatch => {
  dispatch({ type: ActionTypes.GET_DISPLAYS_EXTRA_DATA, payload });
}


export const getPlanFilterDataDisplays = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_PLAN_FILTER_DATA_DISPLAYS, payload: data });
}

export const modifyDisplays = (records) => dispatch => {
	dispatch({ type: ActionTypes.MODIFY_DISPLAYS, payload: records });
}

export const clearModifyDisplays = () => dispatch => {
	dispatch({ type: ActionTypes.CLEAR_MODIFY_DISPLAYS });
}

export const removeModifyDisplays = (ids) => dispatch => {
	dispatch({ type: ActionTypes.REMOVE_MODIFY_DISPLAYS, payload: ids });
}
