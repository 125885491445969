import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  withStyles,
  Button,
  Divider,
  Typography,
  InputAdornment,
  Icon,
  Box,
  TextField,
  form,
  FormControl,
  FormLabel,
  Card,
} from '@material-ui/core';
import { submitLogin, emptyPrevPath } from 'store/actions';
import { Pie } from 'react-chartjs-2';
import moment from 'moment';
import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
import { withRouter } from 'react-router-dom';

import './../ext-content/bootstrap.scss';
import './../ext-content/custom.min.scss';
import './../ext-content/mystyles.scss';
import './../ext-content/jqcloud.scss';
import './../ext-content/basic.scss';
import './../ext-content/dropzone.scss';
//TODO: remove extra files from ext-content  folder

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    color: '#fff',
  },
  paper: {
    // padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: 'transparent',
  },
  panel: {
    marginBottom: '20px',
    backgroundColor: '#222222',
    border: '1px solid transparent',
    borderRadius: '4px',
    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)',
  },
  panelDefault: {
    borderColor: '#282828',
  },
  panelHeading: {
    color: '#888888',
    backgroundColor: '#3c3c3c',
    borderColor: '#282828',
    textAlign: 'left',
    padding: '5px 15px',
  },
  h5: {
    fontSize: '20px',
  },
  h4: {
    fontSize: '140%',
    padding: '10px',
  },
  h6: {
    fontSize: '16px',
    padding: '10px',
    fontWeight: '500',
  },
  customTableFirstCol: {
    padding: '5px',
    color: '#fff',
    fontSize: '1.4em',
  },
  customTableSecondCol: {
    padding: '5px',
    width: '50px',
    color: '#fff',
    fontSize: '1.4em',
    textAlign: 'right',
  },
  customLabel3: {
    borderBottom: '1px solid #fff',
    padding: '10px 0',
    color: '#fff !important',
    textAlign: 'left !important',
  },
  glyphicon: {
    fontSize: '60px',
    color: '#000',
  },
  cardTitle: {
    marginTop: '0 !important',
    color: '#000',
  },
  helptext: {
    color: '#000',
  },
  table: {
    fontSize: '1.8em !important',
    textAlign: 'left',
    width: '100%',
  },
  tableDark: {
    backgroundColor: '#242323 !important',
    color: 'white !important',
    borderTop: '1px dotted #eee',
  },
  tr: {
    padding: 5,
    borderBottom: '1px dotted #eee',
    display: 'block',
  },
  th: {
    padding: '10px !important',
    borderBottom: '1px dotted #eee',
    color: 'white !important',
    fontSize: '14px',
    backgroundColor: '#242323 !important',
  },
  td: {
    padding: '8px !important',
    textAlign: 'left',
    fontSize: '14px',
    borderTop: '1px dotted #eee',
  },
  anchor: {
    fontSize: '14px !important',
    textDecoration: 'underline !important',
  },
  recentActions: {
    fontSize: '1.2em',
    color: '#fff',
    paddingBottom: '10px',
    borderBottom: '1px dotted #eee',
  },
  cardHeading: {
    fontSize: '34px !important',
  },
  cardDescript: {
    fontSize: '14px !important',
  },
  icon: {
    marginTop: '0 !important',
    display: 'block',
    fontSize: '60px !important',
  },
  font18: {
    fontSize: '18px !important',
  },
}));

const Memberrecon = props => {
  let initialState = {
    canSubmit: false,
  };
  const [memberReconciliationArray, setMemberReconciliationArray] = useState([]);


  const getMemberReconciliation = (collectionName, pageCount, setResponse) => {
    const uri = API_BASE_URL + "/dataconnect/data/" + collectionName + "?page=0&rowsPerPage=" + (pageCount ? pageCount : 10)
    axios({
      url: uri,
      method: 'GET',
      headers: {
        'Content-Type': "application/json",
        appId: props.match.params.appid
      },
    }).then((response) => {
      console.log("data", response)
      setResponse(response.data.data);
    }).catch((error) => {
      setResponse({ "error": error });
    })
  }

  useEffect(() => {
    getMemberReconciliation("MemberReconciliation", 10, (res) => {
      console.log("res", res)
      setMemberReconciliationArray(res)
    });
  }, [])

  let form = React.createRef();
  const classes = useStyles();
  return (
    <div className="use-bootstrap ibc">
      <div className="app-memberReconciliation">
        <div class="ibc main" style={{ backgroundColor: '#231F20', padding: '10px' }}>

          <div>
            <div className="ibc main" style={{ backgroundColor: '#231F20', padding: '10px' }}>
              <ul className="breadcrumb">
                <li style={{ color: '#fff' }}>
                  <a href={`/apps/${props.match.params.appid}/ibcDashboard`}>Dashboard</a> / Monthly Billing
            </li>
              </ul>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <div className={(classes.panel, classes.paneldefault)}>
                      <div className={classes.panelHeading} style={{ backgroundColor: '#000' }}>
                        <h4 className={classes.h5}>Member-Level Detail Reconciliation</h4>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <table className="table table-dark report-table" className={classes.table}>
                    <tr>
                      <th style={{ textAlign: 'center', backgroundColor: '#444', padding: 5 }}></th>
                      <th style={{ textAlign: 'center', backgroundColor: '#2a9fd6', borderRight: '3px solid #444', padding: '15px' }} colspan="4">Detials from System</th>
                      <th style={{ textAlign: 'center', backgroundColor: 'rgb(223, 191, 9)', padding: '15px' }} colspan="4">Details from Member level details file</th>
                      <th style={{ textAlign: 'center', backgroundColor: 'red', padding: '15px' }} colspan="2">Reconciliation Results</th>
                    </tr>
                    <tr>
                      <th className={classes.th}></th>
                      <th className={classes.th}>SSN</th>
                      <th className={classes.th}>Last Name</th>
                      <th className={classes.th}>First Initials</th>
                      <th className={classes.th} style={{ borderRight: '3px solid #444' }}>Premium Amount</th>
                      <th className={classes.th}>SSN</th>
                      <th className={classes.th}>Last Name</th>
                      <th className={classes.th}>First Initials</th>
                      <th className={classes.th} style={{ borderRight: '3px solid #444' }}>Premium Amount</th>
                      <th className={classes.th}>Difference</th>
                      <th className={classes.th}>Inference</th>
                    </tr>
                    {console.log("memberReconciliationArray", memberReconciliationArray)}
                    {memberReconciliationArray && memberReconciliationArray.map((item, index) => {
                      return <tr>
                        <td className={classes.td}>
                          {index + 1}
                        </td>
                        <td className={classes.td}>{item.System_SSN}</td>
                        <td className={classes.td}>{item.System_LastName}</td>
                        <td className={classes.td}>{item.System_FirstInitials}</td>
                        <td className={classes.td}>{item.System_PremiumAmount}</td>
                        <td className={classes.td}>{item.Member_SSN}</td>
                        <td className={classes.td}>{item.Member_LastName}</td>
                        <td className={classes.td}>{item.Member_FirstInitials}</td>
                        <td className={classes.td}>{item.Member_PremiumAmount}</td>
                        <td className={classes.td}>{item.Difference}</td>
                        <td className={classes.td}>{item.Inference}</td>
                      </tr>
                    })}
                  </table>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

const mapDispatchToProps = {
  submitLogin,
  emptyPrevPath,
};

const mapStateToProps = ({ user, login, navigation }) => {
  return {
    login,
    user,
    navigation,
  };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Memberrecon)));
