import { ActionTypes } from 'constants/index';

export const getConsumerPromotions = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_CONSUMER_PROMO_DATA, payload: data });
};

export const addNewConsumerPromo = (data) => dispatch => {
  dispatch({ type: ActionTypes.ADD_NEW_CONSUMER_PROMO, payload: data });
};

export const deleteCheckedConsumerPromo = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_CHECKED_CONSUMER_PROMO, payload: data });
};

export const getConsumerPromoExtraData = (payload) => dispatch => {
  dispatch({ type: ActionTypes.GET_CONSUMER_PROMO_EXTRA_DATA, payload });
}


export const getPlanFilterDataConsumerPromo = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_PLAN_FILTER_DATA_CONSUMER_PROMO, payload: data });
}

export const modifyConsumePromo = (records) => dispatch => {
	dispatch({ type: ActionTypes.MODIFY_CONSUMER_PROMO, payload: records });
}

export const clearModifyConsumePromo = () => dispatch => {
	dispatch({ type: ActionTypes.CLEAR_MODIFY_CONSUMER_PROMO });
}

export const removeModifyConsumePromo = (ids) => dispatch => {
	dispatch({ type: ActionTypes.REMOVE_MODIFY_CONSUMER_PROMO, payload: ids });
}
