import { Button, CircularProgress, FormControl, FormLabel, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { emptyPrevPath, loadApps, submitLogin } from 'store/actions';
import { AppService } from '../../modules/api/dapp';
import { validateEmail } from '../../modules/common';
import { isUserExist } from '../../store/actions';
import history from 'modules/history';

const useStyles = theme => ({
    input: {
        backgroundColor: '#fff'
    },
    loginBox: {
        marginTop: '40px'
    },
    circularBar: {
        color: '#ffff',
        height: 20,
        width: 20,
    },
});

const EmailInput = (props) => {

    const [email, setEmail] = useState('')
    const [error, setError] = useState({ email: false, })
    const [password, setPassword] = useState('')
    const [passwordBoxVisible, setPasswordBox] = useState(false)
    const classes = useStyles();

    const handleBlur = (event) => {
        if (!validateEmail(event.target.value)) {
            setError({ ...error, email: event.target.value && event.target.value.length > 0 ? "Invalid Email" : "Enter Email" });
        } else {
            setError({ ...error, email: false });
        }
    }


    useEffect(() => {
        if (props.login && props.login.success) {
            if (!props.appLoading && props.app && props.app[0] && props.app.length === 1) {
                let app = props.app[0];
                history.push({
                    pathname: `/apps/${app ? app._id : props.app[0]._id}`
                });
            } else {
                if (!props.appLoading || props.appError) {
                    history.push({
                        pathname: '/apps'
                    });
                }
            }
        }
    }, [props.app, props.appLoading, props.appError]);

    const loginHandler = (login) => {
        if (props.login && props.login.success) {
            if (props.navigation.prevPath) {
                history.push({
                    pathname: props.navigation.prevPath,
                    search: props.navigation.prevSearch
                });
                props.emptyPrevPath();
            } else {
                props.loadApps(props.user?.orgId);
            }
        }
        return null;
    }


    useEffect(() => {
        loginHandler()
        console.log("props.login", props.login);
    }, [props.login]);


    const handleSubmit = (e, credentials) => {
        e.preventDefault()
        if (!passwordBoxVisible) {
            if (credentials.email) {
                props.isUserExist({ email: credentials.email })
            }
        } else {
            credentials.email && credentials.password && props.submitLogin(credentials)
        }
    }

    useEffect(() => {
        console.log('props.login', props.login)
        if (props.login.orgNotFound) {
            setError({ ...error, email: props.login.orgNotFound.message });
        }
        if (props.login.isUserExist && props.login.isUserExist.externalAuthentication) {
            let redirectionInfo = props.login.isUserExist.connectionParameter
            let url = redirectionInfo.url + '?PartnerSpId=' + redirectionInfo.PartnerSpId + '&username=' + email;
            window.location.replace(url);
        }
        if (props.login.isUserExist && !props.login.isUserExist.externalAuthentication) {
            setPasswordBox(true)
        }

    }, [props.login])

    useEffect(() => {
		let error = props?.login?.error;
		if (error) {
			error?.email && error?.password
				&& props.showAlert("NETWORK ERROR: Please verify your internet connection", {
					id: "login",
					variant: 'danger',
					icon: 'times-circle'
				})
			if (error.email) {
				setError({ ...error, email: error.email })
			} else if (error.password) {
				setError({ ...error, password: error.password })
			} else if (error.message) {
				setError({ ...error, email: error.message })
			}
		}
	}, [props?.login?.error])

    const canSubmit = ({ email, password }) => {
        if (passwordBoxVisible && validateEmail(email)) {
            return !email || !password
        } else {
            return true;
        }
    }


    return (
        <div className={classes.root}>
            <div className='loginForm'>
                <form noValidate
                    onSubmit={(e) => handleSubmit(e, { email, password })}>
                    <FormControl>
                        <FormLabel variant="h6" className="label-headings" htmlFor="email">
                            Email
                        </FormLabel>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            type="email"
                            id="email"
                            name="email"
                            onBlur={handleBlur}
                            placeholder="Enter Email Address"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            helperText={error.email && error.email}
                            error={Boolean(error.email)}
                        />
                    </FormControl>
                    {
                        passwordBoxVisible &&
                        <FormControl>
                            <FormLabel variant="h6" className="label-headings" htmlFor="password">
                                Password
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                type="password"
                                placeholder="Enter Password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                helperText={error.password && error.password}
                                error={Boolean(error.password)}
                            />
                        </FormControl>

                    }
                    <FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={!validateEmail(email) || (passwordBoxVisible ? canSubmit({ email, password }) : false)}
                        >
                            {props.login.loading ?
                                <CircularProgress className={classes.circularBar} size={24} /> :
                                "Sign In"
                            }
                        </Button>
                    </FormControl>
                </form>
            </div>
        </div>
    )
}


const mapDispatchToProps = {
    isUserExist,
    emptyPrevPath,
    loadApps,
    submitLogin,
};

const mapStateToProps = ({ user, login, navigation, app }) => {
    return {
        login,
        user,
        navigation,
        app: app.app,
        appError: app.error,
        appLoading: app.loading,
    }
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(EmailInput));
