import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios'
import classNames from 'classnames';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
const styles = theme => ({
    head: {
        backgroundColor: "#333",
        color: "#fff",

    },
    customFont: {
        color: "#fff",
        fontSize: "14px"
    }
});

class TableStatic extends Component {
    constructor(props) {
        super(props);
        this.state = { rows: [], header: [], isDynamicTable: false }
    }
    componentDidMount() {
        let queryParams = window.location.search
        if (queryParams) {
            // queryParams = queryParams.replace('?dm', '');
            // this.getTableData(queryParams)
            var params = {};
            var query = queryParams.substring(1);
            var vars = query.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                params[pair[0]] = decodeURIComponent(pair[1]);
            }
            if (params && params['dm']) {
                let url = `${params['dm']}?${queryParams.replace(`?${vars[0]}`, '')}`;
                this.getTableData(url)
            }


        }
    }

    getTableData = (queryParams) => {
        // let url = 'http://localhost:4000/dataconnect/5ecd3c735f32a035204d16af?type=list&&fields=email,LastName,username,Gender,&page=0&rowsPerPage=10'
        let url = `${API_BASE_URL}/dataconnect/${queryParams}`
        axios.get(url, { headers: { appId: this.props.match.params.appid, 'Content-Type': 'application/json',}})
            .then(res => {
                let rows = res.data.data;
                let header = [];
                if (rows && rows.length) {
                    let row = rows[0]
                    if (row._id) {
                        delete row._id;
                    }
                    let keys = Object.keys(row)

                    header = keys
                }
                this.setState({ rows, header, isDynamicTable: true })
            })
    }
    render() {
        const { classes } = this.props;
        const { isDynamicTable } = this.state;
        return (
            <>
                <Table className={classNames(classes.table)} size="small" aria-label="a dense table">
                    <TableHead className={classNames(classes.head)}>
                        <TableRow>
                            {isDynamicTable && this.state.header.map((item) => {
                                return (<TableCell className={classNames(classes.customFont)}>{item}</TableCell>);
                            })}
                            {!isDynamicTable && this.props.data.values.map((item) => {
                                return (<TableCell className={classNames(classes.customFont)}>{item.value}</TableCell>);
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isDynamicTable && this.state.rows.map((row, index) => {
                            if (row._id) {
                                delete row._id;
                            }
                            let values = Object.values(row);
                            return (
                                // <TableRow>
                                //     {row.map((rowdata) => {
                                //         return (<TableCell className={classNames(classes.customFont)}>
                                //             {rowdata}</TableCell>)
                                //     })}
                                // </TableRow>
                                <TableRow>
                                    {values.map((item) => {
                                        return (
                                            <TableCell className={classNames(classes.customFont)}>
                                                {item}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                        {!isDynamicTable && this.props.data.rows.map((row, index) => {
                            return (
                                <TableRow>
                                    {row.map((rowdata) => {
                                        return (<TableCell className={classNames(classes.customFont)}>
                                            {rowdata}</TableCell>)
                                    })}
                                </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </>
        )
    };
}


export default withRouter(withStyles(styles, { withTheme: true })(TableStatic));
