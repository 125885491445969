import { hot } from 'react-hot-loader/root';
import React from 'react';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import history from 'modules/history';
import theme from 'modules/theme';
import 'styles/styles.scss';

import routeGenerator from 'routes';
import defaultRoute, { defaultRedirectRoute } from 'routes/defaultRoute';
import PublicRoute from 'routes/PublicRoute';
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import Layout from 'routes/Layout';
import AskDsilo from 'routes/AskDsilo';
import NotFound from 'routes/NotFound';
import Login from 'routes/Login';
import IbcLogin from 'routes/IbcLogin';
import ResetPassword from 'routes/ResetPassword';
import OrgChart from 'routes/OrgChart';
import AppDashboard from 'routes/AppDashboard';
import Configuration from 'routes/Configuration';
import Footer from 'components/Footer';
import TalkSheet from 'components/customPages/spendplan/ReportsPages/talkSheet';
import TreeChart from 'components/charts/TreeChart';
// import OrgChart from 'components/charts/orgChart/OrgChart';
import BulletChart from 'components/charts/bulletChart/BulletChart';
import { loadAppconfig } from './store/actions';

import { Auth } from 'components/auth';
import CacheBuster from './CacheBuster';

//custom pages
import Measures from 'components/customPages/ibc/Setup'
import ExtraxtionResult from 'components/customPages/ibc/ExtractionResult'
import UploadFile from 'components/customPages/ibc/UploadFile'
import BillingDashboard from 'components/customPages/ibc/BillingDashboard'
import Memberrecon from 'components/customPages/ibc/Memberrecon'
import MFAPing from 'components/MFAping'
import Loading from 'components/Loading';
import PreAppLoading from './components/Loading/PreAppLoading';
import PingLogin from './components/LoginEmail';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const APP_SERVER_PG = process.env?.APP_SERVER_PG;

const host = window.location.hostname;
const pgDomain = host.indexOf('pg.com') > -1 ? true : false;

let globalClass = 'app '
if (REACT_APP_APPLICATION) {
	if (REACT_APP_APPLICATION === 'ibc')
		globalClass += 'app-ibc'
	else if (REACT_APP_APPLICATION === 'spendplan')
		globalClass += 'app-spendplan'
}
else {
	// globalClass += 'app-light'
	globalClass += 'app-ibc' //Need to remove this after implementing LIGHT theme
}
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';
const isDsiloEnv = process.env.REACT_APP_isdSilo
const isDsiloPlatform = isDsiloEnv && isDsiloEnv === 'true'; 

export class App extends React.Component {
	render() {
		let { user } = this.props;
		let t = createMuiTheme(theme);
		return (
			<React.Fragment>
				<CacheBuster>
					{({ loading, isLatestVersion, refreshCacheAndReload }) => {
						if (loading) return <PreAppLoading />;
						if (!loading && !isLatestVersion) {
							// You can decide how and when you want to force reload
							refreshCacheAndReload();
							return <PreAppLoading />
						}
						console.log('loading, isLatestVersion', loading, isLatestVersion);
						return <>
							<div className={globalClass} >
								<Auth>
									<Router history={history}>
										{/* <BrowserRouter> */}
										<MuiThemeProvider theme={t}>
											<Switch>
												<PublicRoute
													isAuthenticated={user.isAuthenticated}
													path="/"
													exact
													component={!isIBC ? (pgDomain ? PingLogin : Login) : IbcLogin}
													// component={PingLogin}
													to={defaultRedirectRoute.url}
												/>
												<PublicRoute
													isAuthenticated={user.isAuthenticated}
													path="/login"
													exact
													component={!isIBC ? (pgDomain ? PingLogin : Login) : IbcLogin}
													// component={!isIBC ? PingLogin : IbcLogin}
													to={defaultRedirectRoute.url}
												/>
												<PublicRoute
													isAuthenticated={user.isAuthenticated}
													path={"/apps/:appid/activate/:token"}
													exact
													component={ResetPassword}
												/>
												<PublicRoute
													isAuthenticated={user.isAuthenticated}
													path="/org"
													exact
													component={OrgChart}
												/>
												<PublicRoute
													isAuthenticated={user.isAuthenticated}
													path="/bullet"
													exact
													component={BulletChart}
												/>
												<PublicRoute
													isAuthenticated={user.isAuthenticated}
													path="/mfaping"
													exact
													component={MFAPing}
													to={defaultRedirectRoute.url}
												/>
												{/* <AuthenticatedRoute
										isAuthenticated={user.isAuthenticated}
										path="/ibc/setup"
										exact
										component={Measures}
										to={defaultRedirectRoute.url}
									/>
									<AuthenticatedRoute
										isAuthenticated={user.isAuthenticated}
										path="/ibc/extraction"
										exact
										component={ExtraxtionResult}
										to={defaultRedirectRoute.url}
									/>
									<AuthenticatedRoute
										isAuthenticated={user.isAuthenticated}
										path="/ibc/myworkspace"
										exact
										component={MyWorkSpace}
										to={defaultRedirectRoute.url}
									/>
									<AuthenticatedRoute
										isAuthenticated={user.isAuthenticated}
										path="/ibc/billingdashboard"
										exact
										component={BillingDashboard}
										to={defaultRedirectRoute.url}
									/> */}
												{/* <AuthenticatedRoute
										isAuthenticated={user.isAuthenticated}
										path="/configuration"
										exact
										component={Configuration}
										to={defaultRedirectRoute.url}
									/> */}
												{/* <AuthenticatedRoute
										isAuthenticated={user.isAuthenticated}
										path="/ibc/memberrecon"
										exact
										component={Memberrecon}
										to={defaultRedirectRoute.url}
									/> */}
												{/* <AuthenticatedRoute
										isAuthenticated={user.isAuthenticated}
										path="/apps/:appid/ibc/uploadfile"
										exact
										component={UploadFile}
										to={defaultRedirectRoute.url}
									/> */}
												<AuthenticatedRoute
													isAuthenticated={user.isAuthenticated}
													path="/apps"
													exact
													component={AppDashboard}
													to={defaultRedirectRoute.url}
												/>
												<AuthenticatedRoute
													isAuthenticated={user.isAuthenticated}
													path={"/apps/:appid"}
													exact
													component={Layout}
												/>
												<AuthenticatedRoute
													isAuthenticated={user.isAuthenticated}
													path={"/apps/:appid/org"}
													exact
													component={OrgChart}
												/>
												<AuthenticatedRoute
													isAuthenticated={user.isAuthenticated}
													path={"/apps/:appid/configuration"}
													exact
													component={Configuration}
													to={defaultRedirectRoute.url}
												/>
												<AuthenticatedRoute
													isAuthenticated={user.isAuthenticated}
													path={"/apps/:appid/askdsilo"}
													exact
													component={AskDsilo}
												/>
												{/* <AuthenticatedRoute
										isAuthenticated={user.isAuthenticated}
										path={"/apps/:appid/talksheet"}
										exact
										component={TalkSheet}
									/> */}
												<AuthenticatedRoute
													isAuthenticated={user.isAuthenticated}
													path={"/apps/:appid/talksheet/:brand/:year/:currentFF"}
													exact
													component={TalkSheet}
												/>
												{/* <AuthenticatedRoute
										isAuthenticated={user.isAuthenticated}
										path={"/apps/:appid/trade-price-reduction"}
										exact
										component={TradePriceReduction}
										to={defaultRedirectRoute.url}
									/> */}
												<AuthenticatedRoute
													isAuthenticated={user.isAuthenticated}
													path={"/apps/:appid/:page"}
													exact
													component={Layout}
												/>
												<AuthenticatedRoute
													isAuthenticated={user.isAuthenticated}
													path={"/apps/:appid/:page/:pageDocumentId"}
													exact
													component={Layout}
												/>
												<PublicRoute
													isAuthenticated={user.isAuthenticated}
													path={"/apps/:appid/form/:formname/new"}
													exact
													component={Layout}
												/>
												<PublicRoute
													isAuthenticated={user.isAuthenticated}
													path={"/apps/:appid/form/:formname/:id"}
													exact
													component={Layout}
												/>
												<AuthenticatedRoute
													isAuthenticated={user.isAuthenticated}
													path={"/apps/:appid/:page/:key/:value"}
													exact
													component={Layout}
												/>
												<Route
													key="notfound"
													component={NotFound}
												/>
												{/* {this.routes} */}
											</Switch>
										</MuiThemeProvider>
									</Router>
									{/* </BrowserRouter> */}
								</Auth>
							</div>
							<Router history={history}>
								{user.isAuthenticated && <Footer appId={user} />}
							</Router>
						</>
					}}
				</CacheBuster>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = {
	loadAppconfig,
};

const mapStateToProps = state => {
	return {
		appConfig: state.appConfig.app,
		apps: state.app.app,
		login: state.login,
		user: state.user,
	};
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(App));
