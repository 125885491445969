import React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../../SpendPlan/Header';
import { getFileNameWithTimeStamp, getMonth } from '../../../../../../utility/utils';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileDownload from 'js-file-download';
import ReconReviewTable from './reconReviewTable';
import axios from 'axios';
import { getCurrentFFForPayload, getFiscalYearFormat } from "../../../spendplanUtils";
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  downloadBtn: {
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    marginRight: 15,
  },
}));
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ReconReviewReport = (props) => {

  const classes = useStyles();
  const { brand, scenario } = props;

  const downloadReconHandler = () => {

    if (brand) {
      let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
      m = m.length === 1 ? '0' + m : m;
       let _brandName = props.modalData ? props.modalData.brand : brand.BrandName;
      let _financialYear = props.modalData ? props.modalData.year : brand.FinancialYear;
      let _currentMonth = props.modalData ? getCurrentFFForPayload(props.modalData.month) : m;
      let uri = props?.scenario ? API_BASE_URL + `/recon/download/${_brandName}/${_financialYear}/${_currentMonth}/${props?.scenario}` : API_BASE_URL + `/recon/download/${_brandName}/${_financialYear}/${_currentMonth}`;
      let fyFF = getFiscalYearFormat(_financialYear) + '-' + moment().month(Number(_currentMonth)-1).format('MMM');

      axios({
        url: uri,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          appId: props.match.params.appid,
        },
      })
        .then((response) => {
          FileDownload(response.data, getFileNameWithTimeStamp('Recon Review'+ '-'+ brand.BrandName + '-'+ fyFF));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className='recon-review'>
      <Grid container justify="space-between">
        <Grid item xs={9} sm={9}>
          <Header header={props.modalData?props.versionTitle:`Recon Review - ${brand && brand.CurrentFF && brand.FinancialYear
            ? `FY ${getFiscalYearFormat(brand.FinancialYear)} ${getMonth(brand.CurrentFF, 'month')} FF`
            : 'FY 2021'}`}/>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.downloadBtn}
            size="medium"
            onClick={downloadReconHandler}
            startIcon={<GetAppIcon/>}
          > {'Download'} </Button>
        </Grid>
      </Grid>
      <ReconReviewTable brand={brand} modalData={props.modalData} scenario={scenario} />
    </div>);
};

const mapStateToProps = state => {
  return {
    brand: state.tprReducer?.selectBrand,
    scenario: state.tprReducer?.selectScenario,
  };
};

const mapDispatchToProps = {};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReconReviewReport)));
