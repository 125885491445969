import {
	Box,
	Button, Checkbox, FormControl, FormControlLabel, FormGroup, GridList, GridListTile, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Paper, Radio, Select, Table, TableBody, TableCell, TablePagination, TableRow, TextField, Typography, withStyles, CircularProgress, Tooltip
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import classNames from 'classnames';
import { Parser } from "json2csv";
import {
	getTableRowStyles, isRowselected
} from 'modules/common';
import _ from 'modules/lodash';
import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import Input from './Input';
import TableHead from './TableHead';
import Loader from '../Loader';

const styles = theme => ({
	root: {},
	paper: {
		border: "1px solid #cecece",
		padding: theme.spacing(2),
		maxHeight: "350px",
		overflowY: "auto",
		overflowX: "auto"
	},
	actionIcon: {
		border: "1px solid #E4E4E4",
		padding: "5px",
		borderRadius: "5px",
		marginRight: "10px",
		display: "inline-block",
		textAlign: "center"
	},
	reset: {
		alignSelf: 'left',
	},
	resetLink: {
		marginLeft: "16px",
		fontSize: "12px",
		cursor: "pointer"
	},
	loading: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100vh - 200px)',
	},
	hide: {
		display: 'none'
	}
});

class CustomTable extends Component {
	state = {
		order: 'asc',
		orderBy: null,
		selected: [],
		data: [["Loading Data..."]],
		page: 0,
		count: 10,
		rowsPerPage: 10,
		searchText: "",
		isSearchIconHide: false,
		anchorEl: null,
		anchorEl2: null,
		anchorEl3: null,
	};

	componentWillUnmount() {
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState) {
	}

	objectByStringKey = (o, s) => {
		s = s.replace(/\[(\w+)\]/g, '.$1');
		s = s.replace(/^\./, '');
		var a = s.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			var k = a[i];
			if (k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		// To display comma seperated event names for pages
		if (typeof o === 'object') {
			if (o?.length && s === 'event') {
				o = o.join(', ')
			}
		}
		return o;
	}

	handleSearchClick = () => {
		this.setState({ isSearchIconHide: !this.state.isSearchIconHide }, () => {
			if (!this.state.isSearchIconHide)
				this.props.searchHandler()
		});
	}

	handleColumnPopperClick = (event) => {
		this.setState({ anchorEl: this.state.anchorEl ? null : event.currentTarget });
	}
	handleFilterPopperClick = (event) => {
		this.setState({ anchorEl2: this.state.anchorEl2 ? null : event.currentTarget });
	}
	handleExportPopperClick = (event) => {
		this.setState({ anchorEl3: this.state.anchorEl3 ? null : event.currentTarget });
	}

	generateTableRows = (data, orderBy, order) => {
		let toReturn = _.orderBy(data, [
			(o) => {
				switch (orderBy) {
					default: {
						return o[orderBy];
					}
				}
			}
		], [order]);
		// const { page, rowsPerPage } = this.props;
		// if (rowsPerPage > 0) {
		// 	toReturn = toReturn.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		// }
		return toReturn;
	}

	handleMoreClick = (e) => {
		this.props.history.push({
			pathname: `${this.props.chartData.extendedTablePage.url}`
		});
	}

	handleAddClick = (e) => {
		this.props.history.push({
			pathname: `/apps/${this.props.appId}/form/${this.props.chartData.associatedFormName}/new`
		});
	}

	renderSelect(column, index) {
		const { filterList, filterData, handleDropdownChange } = this.props;
		return (

			<GridListTile key={'rs' + index} cols={2}>
				<FormControl key={index} fullWidth>
					<InputLabel htmlFor={column.name}>{column.label}</InputLabel>
					<Select
						fullWidth
						value={filterList[index] ? filterList[index] : "All"}
						name={column.name}
						onChange={event => handleDropdownChange(event, index, column)}
						input={<Input name={column.name} id={column.name} />}>
						<MenuItem value={"All"} key={0}>
							All
						</MenuItem>
						{filterData.length > 0 && [...new Set(filterData[index])].map((filterValue, filterIndex) => (
							<MenuItem value={filterValue} key={filterIndex + 1}>
								{filterValue}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</GridListTile>
		);
	}

	render() {
		const open = Boolean(this.state.anchorEl);
		const id = open ? 'simple-popper' : undefined;

		const open2 = Boolean(this.state.anchorEl2);
		const id2 = open2 ? 'simple-popper-1' : undefined;

		const open3 = Boolean(this.state.anchorEl3);
		const id3 = open3 ? 'simple-popper-2' : undefined;
		const { order, orderBy, selected, rowsPerPage, count, page, filterTableData, classes,
			exportArray, headerstyle, subHeaderstyle, rowStyle, isTableSubHeader, name } = this.props;

		// change table keys for omni channel
		let isOmnichannel = false;
		for (let i = 0; i < filterTableData?.datasets?.length; i++) {
			if (filterTableData?.datasets[i].event && filterTableData?.datasets[i].event.length) {
				isOmnichannel = true;
				break;
			}
		}
		if (isOmnichannel) {
			for (let i = 0; i < filterTableData?.columns?.length; i++) {
				let labelNew = ''
				switch (filterTableData?.columns[i].label) {
					case 'AccountName': {
						labelNew = 'Account Name';
						break;
					}
					case 'FinancialYear': {
						labelNew = 'Financial Year';
						break;
					}
					case 'CurrentFF': {
						labelNew = 'Current FF';
						break;
					}
					case 'UserGroups': {
						labelNew = 'User Groups';
						break;
					}
					case 'event': {
						labelNew = 'Event Type';
						break;
					}
					default: {
						labelNew = filterTableData.columns[i].label;
					}
				}
				filterTableData.columns[i].label = labelNew;
			}
		}


		let { selectedDataPages, selectedDataColumns, links,
			extendedTablePage, associatedFormName, selectedDataElement } = this.props.chartData;

		let columnsArray = filterTableData && filterTableData.columns && filterTableData.columns.map((item) => item.name);

		const role = this.props?.appConfig?.role;
		const roles = this.props?.appConfig?.roles.find((el) => el.name === role);
		const allPages = roles?.privileges?.find((el) => el?.split(':')[1] === 'Pages.OmniChannels');
		const isDisabled = this.props.pageObj.name === 'OmniChannels' && allPages?.split(':')[2] === 'Read' ? true : false;
		
		return (<>
			<div className="renderer-new-table">
				<div className="general-table-heading">
					<span className="general-table-heading-span">{name}</span>
					{(associatedFormName && (this.props.hideAddNew !== undefined ? this.props.hideAddNew : true)) && <Button
						style={{ "marginLeft": "20px" }} color="secondary"
						onClick={this.handleAddClick}
						className="btn btn-primary border-radius-0 float-right pr-new-button">
						{"+ NEW"}
					</Button>}
					<div style={{ float: "right" }}>
						{this.props.isSearch && <>
							{this.state.isSearchIconHide &&
								<div style={{ backgroundColor: "#FFF", display: "inline-block", position: "relative", top: "-18px" }}>
									<TextField id="standard-basic" placeholder="Search" value={this.props.searchText}
										onChange={this.props.searchHandler} />
								</div>}
							{this.state.isSearchIconHide
								? <CloseIcon style={{
									color: "rgba(0, 0, 0, 0.87)", position: "relative", right: "30px", bottom: "3px"
								}} onClick={this.handleSearchClick} />
								: <SearchIcon style={{ color: "rgba(0, 0, 0, 0.87)" }} onClick={this.handleSearchClick} />}
						</>}
						{this.props.isExport && <div style={{ color: "rgba(0, 0, 0, 0.87)", display: "inline-block" }}>
							<CloudDownloadIcon onClick={this.handleExportPopperClick} />
							<Popper id={id3} open={open3} anchorEl={this.state.anchorEl3}>
								<div className={classes.paper}>
									<List component="nav">
										{exportArray.map((exp, index) => {
											return exp.isVisible && <React.Fragment key={'rf' + index}>
												{exp.key === "csv" || exp.key === "txt" || exp.key === "xls"
													? <ListItem button key={index}>
														<CSVLink
															style={{ textDecoration: "none", color: "#000" }}
															data={columnsArray && columnsArray.length > 0 && filterTableData.datasets
																&& filterTableData.datasets.length > 0
																&& new Parser({ columnsArray }).parse(JSON.parse(JSON.stringify(filterTableData.datasets)).map(item => { delete item._id; return item }) || [])
																|| []}
															filename={name + "." + exp.key}>
															<ListItemText primary={exp.name} />
														</CSVLink>
													</ListItem>
													: <ListItem key={index} button
														onClick={() => this.props.handleExoprtItemClick(exp, index)}>
														<ListItemText primary={exp.name} />
													</ListItem>
												}
											</React.Fragment>
										})}
									</List>
								</div>
							</Popper>
						</div>}
						{this.props.isViewCoulmns && <div style={{ color: "rgba(0, 0, 0, 0.87)", display: "inline-block" }}>
							<ViewColumnIcon onClick={this.handleColumnPopperClick} />
							<Popper id={id} open={open} anchorEl={this.state.anchorEl}>
								<div className={classes.paper}>
									<FormGroup>
										{filterTableData && filterTableData.columns && filterTableData.columns.map((item, i) => {
											return <FormControlLabel key={i}
												control={
													<Checkbox
														disableRipple
														disableFocusRipple
														disableTouchRipple
														checked={item.checked}
														onChange={this.props.handleInputChange}
														name={item.name}
														color="primary"
														size="small"
													/>
												}
												label={item.label}
												value={item.name}
											/>
										})}
									</FormGroup>
								</div>
							</Popper>
						</div>}
						{this.props.isFilter && <div style={{ color: "rgba(0, 0, 0, 0.87)", display: "inline-block" }}>
							<IconButton
								title='Filters'
								onClick={this.handleFilterPopperClick}
							>
								<FilterIcon />
							</IconButton>
							<Popper
								id={id2}
								open={open2}
								anchorEl={this.state.anchorEl2}
								style={{ right: 0, maxWidth: 500 }}
							>
								<Paper
									elevation={8}
									className={classes.paper}>
									<div className={classes.reset}>
										<Typography variant="body2" style={{ display: "inline-block" }}>Filters</Typography>
										<Button style={{ float: 'right' }}
											className={classes.resetLink}
											color="primary"
											onClick={this.props.resetFilters}>
											{"Reset"}
										</Button>
									</div>
									<GridList cellHeight="auto" cols={2} spacing={34}
										style={{ marginTop: "13px", width: "100%", color: "rgba(0, 0, 0, 0.87)" }} >
										{filterTableData && filterTableData.columns && filterTableData.columns.map((column, index) => {
											return this.renderSelect(column, index);
										})}
									</GridList>
								</Paper>
							</Popper>
						</div>}
					</div>
					{(this.props.pageObj.name === "Account" || this.props.pageObj.name === 'OmniChannels') && <React.Fragment>
						{this.props.freezePlanProcessing ? <Tooltip
							placement={'top'}
							title={"Freeze plan is under process"}
						>
							<Button
								// disabled={true}
								style={{ backgroundColor: "#f58742", cursor:'default' }}
								onClick={this.props.handleFreezePlans}
								className="btn btn-primary border-radius-0 float-right">
								<CircularProgress size={20} color={'inherit'} /> {"Freeze Monthly Plan"}
							</Button>
						</Tooltip> : <Button
							onClick={this.props.handleFreezePlans}
							disabled={isDisabled}
							className={`btn btn-primary border-radius-0 float-right ${isDisabled ? 'btn-disabled' : 'btn-color'}`}>
							{"Freeze Monthly Plan"}
						</Button>}
					</React.Fragment>}
					{(this.props.pageObj.name === "Brand") &&
						<>
							<Button
								onClick={this.props.handleRemoveAllData}
								style={{ background: 'red' }}
								className="btn btn-primary border-radius-0 float-right">
								{this.props.removeDataBtnTitle}
							</Button>
							<Button
								onClick={this.props.pullDataClicked}
								className="btn btn-primary btn-color border-radius-0 float-right">
								Pull Data
							</Button>
							<Button
								onClick={this.props.versioningClicked}
								style={{ background: '#1d90ed' }}
								className="btn btn-primary border-radius-0 float-right">
								Save this version
							</Button>
							{
								!this.props.hideOverRideUpload &&
								<Button
									onClick={this.props.onUploadOverRideSDE}
									style={{ background: '#1d90ed' }}
									className="btn btn-primary border-radius-0 float-right">
									Upload Overrides & SDEs
								</Button>
							}
						</>}
				</div>
				{
					this.props.state.loading ? <div className={classes.loading}><Loader /></div> :
						<div className="w-full flex flex-col report-parent-div">
							<Table className="min-w-xl" aria-labelledby="tableTitle">
								{filterTableData && filterTableData.columns && filterTableData.columns.length > 0
									&& this.props.isTableHead && <TableHead
										isRadio={this.props.isRadio}
										numSelected={selected.length}
										order={order}
										orderBy={orderBy}
										isTableSubHeader={isTableSubHeader}
										headerstyle={headerstyle}
										subHeaderstyle={subHeaderstyle}
										onSelectAllClick={this.props.handleSelectAllClick}
										onRequestSort={this.props.handleRequestSort}
										rowCount={filterTableData && filterTableData.datasets ? filterTableData.datasets.length : 0}
										data={filterTableData}
										chartData={this.props.chartData}
										hasCheckbox={this.props.hasCheckbox}
									/>}

								<TableBody>
									{this.generateTableRows(filterTableData.datasets, orderBy, order)
										.map((n, i) => {
											const isSelected = isRowselected(n._id, selected);
											return (
												<TableRow
													className={classNames("h-64 cursor-pointer", this.props.hasCheckbox ? 'checkbox' : '')}
													style={getTableRowStyles(rowStyle, ['backgroundColor'], i)}
													hover
													role="checkbox"
													aria-checked={isSelected}
													tabIndex={-1}
													key={n._id}
													selected={isSelected}
												// onClick={event => this.props.onRowClick(n, i)}
												>
													{this.props.hasCheckbox
														&& <TableCell padding="checkbox">
															{
																this.props.isRadio ?
																	<Radio
																		onChange={event => this.props.handleCheck(event, n._id)}
																		checked={isSelected}
																		className={classes.radio} />
																	: <Checkbox
																		disableRipple
																		size="small"
																		checked={isSelected}
																		onClick={event => event.stopPropagation()}
																		onChange={event => this.props.handleCheck(event, n._id)}
																	/>

															}
														</TableCell>}
													{filterTableData && filterTableData.columns && filterTableData.columns.length > 0 &&
														filterTableData.columns.map((c, cInd) => {
															if (c.checked) {
																return (<TableCell key={'cInd' + cInd}
																	component="td" scope="row"
																	style={getTableRowStyles(rowStyle, ['backgroundColor', 'color', 'fontSize'], i)}
																	onClick={event => this.props.onCellClick && this.props.onCellClick(n, c)}>
																	{c.name === "workflow" && n.workflow
																		? <Button variant="contained">{n.workflow.actions}</Button>
																		: this.objectByStringKey(n, c.name)}
																</TableCell>)
															}
														})
													}
													{associatedFormName && <TableCell className="table-action">
														{!isDisabled && this.props.actions.edit && <Box className={classes.actionIcon} component="label"
															onClick={(e) => this.props.tableCrudOperations(n, "edit", e)}
															>
															<EditIcon style={{
																...getTableRowStyles(rowStyle, ['color'], i),
																marginTop: "0px", cursor: "pointer"
															}} />
														</Box>}
														{this.props.actions.view && <Box className={classes.actionIcon} component="label"
															onClick={(e) => this.props.tableCrudOperations(n, "view", e)}>
															<VisibilityIcon style={{
																...getTableRowStyles(rowStyle, ['color'], i),
																marginTop: "0px", cursor: "pointer"
															}} />
														</Box>}
														{!isDisabled && this.props.actions.delete && <Box className={classes.actionIcon} component="label"
															onClick={(e) => this.props.tableCrudOperations(n, "delete", e)}>
															<DeleteIcon style={{
																...getTableRowStyles(rowStyle, ['color'], i),
																marginTop: "0px", cursor: "pointer"
															}} />
														</Box>}
														{this.props.actions.copy && <Box className={classes.actionIcon} component="label"
															onClick={(e) => this.props.tableCrudOperations(n, "copy", e)}>
															<FileCopyIcon style={{
																...getTableRowStyles(rowStyle, ['color'], i),
																marginTop: "0px", cursor: "pointer"
															}} />
														</Box>}
													</TableCell>}
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
							{this.props.isTablePagination &&
								<TablePagination
									component="div"
									count={count ? count : 0}
									style={{ "color": "rgba(0, 0, 0, 0.87)" }}
									rowsPerPage={rowsPerPage}
									page={page}
									backIconButtonProps={{
										'aria-label': 'Previous Page'
									}}
									nextIconButtonProps={{
										'aria-label': 'Next Page',
										// 'marginTop': '-18px'
									}}
									onChangePage={this.props.handleChangePage}
									onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
								/>
							}

						</div>
				}
			</div>
		</>);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

const mapStateToProps = state => {
	return {
		appConfig: state.appConfig,
	};
};

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(CustomTable));