import React, { Component } from 'react';
import { connect } from 'react-redux'; import { hot } from 'react-hot-loader/root';
import { withStyles } from '@material-ui/core/styles/index';
import { Button, Card, CardContent, TextField, Typography, FormLabel } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import _ from 'modules/lodash';
import 'styles/reset.scss';
import { resetPassword } from 'store/actions';
import history from 'modules/history';
import Loading from 'components/Loading';

const styles = theme => ({
	root: {

	},
	card: {
		width: '100%',
		maxWidth: 384
	},
	input: {
		marginTop: '10px',
		marginBottom: '10px'
	}
});

class ResetPassword extends Component {
	state = {
		oldPassword: '',
		password: '',
		passwordConfirm: '',
		token: this.props.token
	};

	handleChange = (event) => {
		this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
	};

	canBeSubmitted() {
		const { oldPassword, password, passwordConfirm } = this.state;
		return (
			oldPassword.length > 0 &&
			password.length > 0 &&
			password.length > 3 &&
			password === passwordConfirm
		);
	}

	onSubmit = () => {
		let data = {
			...this.state,
			token: this.props.match.params.token
		}
		this.props.resetPassword(data);
	}

	componentDidUpdate = (prevProps, prevState) => {
		console.log("reset", this.props.reset);
		console.log("token", this.props.token);
		if (this.props.reset.success && !this.props.reset.isLoading) {
			history.push({
				pathname: '/login'
			});
		}
	}

	render() {
		const { classes } = this.props;
		const { oldPassword, password, passwordConfirm } = this.state;

		return (
			<div className={classNames(classes.root, "flex flex-col flex-auto flex-no-shrink items-center justify-center p-32 forgot-password")}>
				<div className="flex flex-col items-center justify-center w-full">
					<Card className={classes.card}>
						<CardContent className="flex flex-col items-center justify-center p-32">
							<Typography variant="h6" className="mt-16 mb-32">
								RESET YOUR PASSWORD
                                </Typography>
							<form name="resetForm" noValidate
								className="flex flex-col justify-center w-full">
								<FormLabel variant="h6" className="label-headings" htmlFor="oldPassword">
									Old Password
                                              </FormLabel>
								<TextField
									className={classNames(classes.input, "mb-16")}
									// label="Old Password"
									type="password"
									name="oldPassword"
									value={oldPassword}
									onChange={this.handleChange}
									variant="outlined"
									required
									fullWidth
								/>
								<FormLabel variant="h6" className="label-headings" htmlFor="password">
									New Password
                                              </FormLabel>
								<TextField
									className={classNames(classes.input, "mb-16")}
									// label="New Password"
									type="password"
									name="password"
									value={password}
									onChange={this.handleChange}
									variant="outlined"
									required
									fullWidth
								/>
								<FormLabel variant="h6" className="label-headings" htmlFor="passwordConfirm">
									Password (Confirm)
                                              </FormLabel>
								<TextField
									className={classNames(classes.input, "mb-16")}
									// label="Password (Confirm)"
									type="password"
									name="passwordConfirm"
									value={passwordConfirm}
									onChange={this.handleChange}
									variant="outlined"
									required
									fullWidth
								/>
								<Button
									variant="contained"
									color="primary"
									className="w-224 mx-auto mt-16"
									aria-label="Reset"
									disabled={!this.canBeSubmitted()}
									onClick={this.onSubmit}
								>
									RESET MY PASSWORD
                                    </Button>
							</form>
							<div className="flex flex-col items-center justify-center pt-32 pb-24">
								<Link className="font-medium" to="/login">
									Go back to login
                                    </Link>
							</div>
						</CardContent>
					</Card>
				</div>
				{this.props.reset.isLoading && <Loading />}
			</div>
		);
	}
}


const mapDispatchToProps = {
	resetPassword
};

const mapStateToProps = ({ login }) => {
	return {
		reset: login.reset
	}
}

export default hot(withStyles(styles, { withTheme: true })
	(connect(
		mapStateToProps,
		mapDispatchToProps
	)(ResetPassword)));