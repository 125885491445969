import axios from 'axios';
import jwtDecode from 'jwt-decode';
import EventEmitter from './EventEmitter';
import { apiBaseAddress } from 'modules/api/index';

class jwtService extends EventEmitter {

    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    constructor(){
        super();
        this.header = {
            'Content-Type': 'application/json',
          }
    }

    setInterceptors = () => {
        axios.interceptors.response.use(response => {
            return response;
        }, err => {
            return new Promise((resolve, reject) => {
                if (err.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
                    // if you ever get an unauthorized response, logout the user
                    this.emit('onAutoLogout', 'Invalid access_token');
                    this.setSession(null);
                }
                throw err;
            });
        });
    };

    handleAuthentication = () => {

        let access_token = this.getAccessToken();

        if (!access_token) {
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit('onAutoLogin', true);
        }
        else {
            this.setSession(null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            axios.post(apiBaseAddress() + '/api/auth/register', data, this.header)
                .then(response => {
                    if (response.data.user) {
                        this.setSession(response.data.access_token);
                        resolve(response.data.user);
                    }
                    else {
                        reject(response.data.error);
                    }
                });
        });
    };

    signInWithEmailAndPassword = ({ email, password }) => {
        return new Promise((resolve, reject) => {
            axios.post(apiBaseAddress() + '/auth/login', {
                email,
                password
            }, this.header).then(response => {
                const { user, token } = response.data;
                if (user && token) {
                    user.token = token;
                    user.isAuthenticated = true;
                    this.setSession(token);
                    resolve(user);
                }
                else {
                    reject(response.data.error);
                }
            }).catch(err => {
                console.error("err ==> ", err);
                reject(err.response ? err.response.data : err.response);
            });
        });
    };

    signInWithPingToken = ({ pingToken }) => {
        return new Promise((resolve, reject) => {
            axios.post(apiBaseAddress() + '/auth/pglogin', {
                samlResponse: pingToken,
            }).then(response => {
                const { user, token } = response.data;
                if (user && token) {
                    user.token = token;
                    user.isAuthenticated = true;
                    this.setSession(token);
                    resolve(user);
                }
                else {
                    reject(response.data.error);
                }
            }).catch(err => {
                console.error("err ==> ", err);
                reject(err.response ? err.response.data : err.response);
            });
        });
    };


    resetPassword = (data) => {
        console.log("data rest", data);
        return new Promise((resolve, reject) => {
            axios.post(apiBaseAddress() + `/auth/reset/${data.token}`, {
                ...data
            }, this.header).then(response => {
                const user = response.data;
                if (user) {
                    resolve(user);
                }
                else {
                    reject(response.data.error);
                }
            });
        });
    };

    signInWithToken = () => {
        console.log("signInWithToken");
        return new Promise((resolve, reject) => {
            let accessToken = this.getAccessToken();
            if (this.isAuthTokenValid(accessToken)) {
                this.setSession(accessToken);
                const decoded = jwtDecode(accessToken);
                console.log("decoded in signInWithToken " + JSON.stringify(decoded));
                let user = {
                    isAuthenticated: true,
                    "id": decoded.id,  // display name -> firstName & lastName is not there--> shortName 
                    "displayName": decoded.displayName || ((!decoded?.firstname && !decoded?.lastname)?decoded?.shortname:((decoded?.firstname || '') + (decoded?.lastname || ''))) || ((decoded?.firstName || '') + (decoded?.lastName || '')),
                    "role": decoded.role,
                    "roles": decoded.roles,
                    "orgId": decoded.orgId,
                    "email": decoded.email
                };
                resolve(user)
            } else {
                reject("error");
            }

        });
        /* return new Promise((resolve, reject) => {
            axios.get(apiBaseAddress() + '/api/auth/access-token', {
                data: {
                    access_token: this.getAccessToken()
                }
            })
                .then(response => {
                    if ( response.data.user )
                    {
                        this.setSession(response.data.access_token);
                        resolve(response.data.user);
                    }
                    else
                    {
                        reject(response.data.error);
                    }
                });
        }); */
    };


    isUserExist = (data) => {
        return new Promise((resolve, reject) => {
            const uri = apiBaseAddress() + '/auth/email.org.exist';
            axios.post(uri, data).then(response => {
                resolve(response.data)
            }).catch(err => {
                console.error("err ==> ", err);
                reject(err.response ? err.response.data : err.response);
            });
        });
    }


    updateUserData = (user) => {
        return axios.post(apiBaseAddress() + '/api/auth/user/update', {
            user: user
        }, this.header);
    };

    setSession = access_token => {
        if (access_token) {
            localStorage.setItem('jwt_access_token', access_token);
            axios.defaults.headers.common['Authorization'] = access_token;
        }
        else {
            localStorage.removeItem('jwt_access_token');
            delete axios.defaults.headers.common['Authorization'];
        }
    };

    logout = () => {
        console.log("logout = JWT serveive");
        // window.location.reload(true);
        this.setSession(null);
    };

    isAuthTokenValid = access_token => {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        }
        else {
            return true;
        }
    };

    getAccessToken = () => {
        return window.localStorage.getItem('jwt_access_token');
    };
}

const instance = new jwtService();

export default instance;
