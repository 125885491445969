import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { AppService } from 'modules/api/index';
import { SelectType, DateRangeType, SliderType } from './FiltersType';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const drawerWidth = 340;
const useStyles = makeStyles(theme => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  drawerHeaderLeft: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  drawerBodyTop: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      width: '16%',
    },
  },
  fullList: {
    width: 'auto',
  },
  drawerPaper: {
    width: drawerWidth,
    top: 123,
  },
  filterHeading: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  // formWrap: {
  //   // width: '100%',
  //   marginTop: '10px',
  //   marginRight: '10px',
  // },
}));

function FilterDrawer(props) {
  const {
    isFilterOn,
    onFilterClick,
    page,
    onFilterChange,
    onFilterDateChange,
    filterPosition,
  } = props;
  const [state, setState] = useState({
    selectedFilterDataElements: page.selectedFilterDataElements,
  });
  const classes = useStyles();
  const theme = useTheme();

  const getApiDataFromDataUrl = async url => {
    let resp = await AppService.getApiDataFromDataUrl(url, page.appId);
    return resp;
  };

  useEffect(() => {
    console.log('state ===', state, 'props -=-', props);
  }, [state]);

  useEffect(() => {
    if (!state.selectedFilterDataElements) {
      setState({ ...state, selectedFilterDataElements: page.selectedFilterDataElements });
    }

    (async function() {
      let newselectedFilterDataElements = JSON.parse(
        JSON.stringify(page.selectedFilterDataElements),
      );
      let newState = await Promise.all(
        newselectedFilterDataElements?.map(async element => {
          let targetURL = API_BASE_URL + '/dataconnect/table/' + page?.pageLevelDataModel;
          let response = await getApiDataFromDataUrl(
            targetURL + `?type=list&&fields=${element.value}&page=0&rowsPerPage=10`,
          );

          let newData = [...new Set(response.data?.map(item => item[element.value]))];
          return { ...element, data: newData };
        }),
      );

      setState({
        ...state,
        selectedFilterDataElements: newState.filter(
          item => !(item.data.length === 1 && !item.data[0]),
        ),
      });
    })();
  }, [JSON.stringify(page.selectedFilterDataElements)]);

  const typOfFilter = value => {
    switch (value.type) {
      case 'text':
      case 'string':
        return <SelectType selectData={value} onFilterChange={onFilterChange} page={page} />;

      case 'date':
        return <DateRangeType selectData={value} onFilterDateChange={onFilterDateChange} />;

      case 'currency':
      case 'integer':
        return <SliderType selectData={value} onFilterChange={onFilterChange} />;
      default:
        // return <SliderType selectData={value} onFilterChange={onFilterChange} />;
        return <SelectType selectData={value} onFilterChange={onFilterChange} page={page} />;
    }
  };

  const list = anchor => (
    <>
      {anchor !== 'top' && (
        <>
          <div
            className={`${classes.drawerHeader} ${
              filterPosition === 'left' ? classes.drawerHeaderLeft : ''
            }`}
          >
            <IconButton onClick={onFilterClick}>
              {filterPosition === 'left' ? (
                <ArrowBackIosIcon fontSize="small" />
              ) : (
                <ArrowForwardIosIcon fontSize="small" />
              )}
            </IconButton>
            <div className={classes.filterHeading}>Filters</div>
          </div>
          <Divider />
        </>
      )}
      <div style={{ padding: '8px' }} className={anchor === 'top' && classes.drawerBodyTop}>
        {state.selectedFilterDataElements?.map(val => (
          <React.Fragment key={val.value}>{typOfFilter(val)}</React.Fragment>
        ))}
      </div>
    </>
  );

  return (
    <div>
      {filterPosition === 'top' ? (
        <div>{list('top')}</div>
      ) : (
        <Drawer
          anchor={filterPosition}
          variant="persistent"
          open={isFilterOn}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={() => onFilterClick()}
        >
          {list()}
        </Drawer>
      )}
    </div>
  );
}

export default FilterDrawer;
