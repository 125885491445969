import { ActionTypes } from 'constants/index';

const initialState = {
  success: false,
  forms: [],
  deleteSuccess: false,
  isLoading: false,
};

const form = function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_FORMS_SUCCESS:
      {
        return {
          ...state,
          forms: action.payload
        }
      }
    case ActionTypes.GET_FORMS_ERROR:
      {
        return {
          ...state,
          error: action.payload
        }
      }
    case ActionTypes.GET_FORM_BY_NAME_SUCCESS:
      {
        let selectedForm = JSON.parse(JSON.stringify(action.payload.form));
        let rows = selectedForm.design.rows;
        let copyRows = JSON.parse(JSON.stringify(selectedForm.design.rows));

        for (let i = 0; i < rows.length; i++) {
          if (rows[i].columns[0].type === 'card') {
            copyRows[i].columns[0].index = 0;
            copyRows[i].columns[0].isAddbutton = true;
          }
        }
        selectedForm.design.rows = copyRows;
        return { ...state, selectedForm: selectedForm, events: action.payload.events, success: true }
      }
    case ActionTypes.GET_FORM_BY_NAME_ERROR:
      {
        return {
          success: false,
          error: action.payload
        };
      }
    case ActionTypes.GET_SUBMITTED_DATA_BY_ID_SUCCESS:
      {
        if (state.selectedForm) {
          let selectedForm = JSON.parse(JSON.stringify(state.selectedForm));
          let rows = selectedForm.design.rows;
          let copyRows = JSON.parse(JSON.stringify(selectedForm.design.rows));
          let copyModal = JSON.parse(JSON.stringify(selectedForm.modalData.rows));
          for (let i = 0; i < rows.length; i++) {
            if (rows[i].columns[0].type === 'card') {
              let row = action.payload[0][rows[i].columns[0].name];
              for (let j = 1; j < row.length; j++) {
                let tempRows = JSON.parse(JSON.stringify(rows[i]));
                tempRows.columns[0].index = j
                copyRows.push(tempRows);
                copyModal.push(copyModal[i])
              }
            }
          }
          selectedForm.design.rows = copyRows;
          selectedForm.modalData.rows = copyModal;
          return { ...state, selectedForm: selectedForm, submittedDataToBeEdited: action.payload, success: true, deleteSuccess:false }
        } else {
          return { ...state, submittedDataToBeEdited: action.payload, success: true, deleteSuccess:false }
        }

      }
    case ActionTypes.GET_SUBMITTED_DATA_BY_ID_ERROR:
      {
        return {
          success: false,
          error: action.payload
        };
      }
    case ActionTypes.SAVE_SUBMITTED_DATA_SUCCESS:
      {
        return {
          ...state,
          success: true,
          isLoading: false,
        }
      }
    case ActionTypes.SAVE_SUBMITTED_DATA_ERROR:
      {
        return {
          success: false,
          error: action.payload,
          isLoading: false,
        };
      }
      case ActionTypes.SAVE_SUBMITTED_DATA_LOADING:
      {
        return {
          isLoading: true,
        };
      }
      case ActionTypes.UPDATE_SUBMITTED_DATA_LOADING:
      {
        return {
          isLoading: true,
        };
      }
    case ActionTypes.GET_WORKFLOW_EVENTS_SUCCESS:
      {
        return {
          ...state,
          success: true,
          events: action.payload
        };
      }
    case ActionTypes.CLEAN_SUBMITTED_DATA:
      {
        return {
          ...state,
          selectedForm: [],
          submittedDataToBeEdited: []
        };
      }
    case ActionTypes.UPDATE_SELECTED_FORM:
      {
        let copyRows = [...state.selectedForm.design.rows];
        let newRows = JSON.parse(JSON.stringify(copyRows[action.outerIndex]))
        newRows.columns[0].index = action.cardIndex + 1
        copyRows.push(newRows);

        let copyModalRows = [...state.selectedForm.modalData.rows];
        copyModalRows.push(copyModalRows[action.outerIndex])

        return {
          ...state,
          selectedForm: {
            ...state.selectedForm,
            design: {
              ...state.selectedForm.design,
              rows: copyRows
            },
            modalData: {
              ...state.selectedForm.modalData,
              rows: copyModalRows
            }
          }
        };
      }
      case ActionTypes.DELETE_SUBMITTED_DATA_SUCCESS:
        {
          return {
            ...state,
            deleteSuccess:true
          }
        }
      case ActionTypes.DELETE_IS_SUCCESS:
          {
            return {
              ...state,
              deleteSuccess:false
            }
          }
    default:
      {
        return state
      }
  }
};

export default form;