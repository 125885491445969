import React from 'react';
import { ErrorFallBackComponent } from './ErrorFallBackComponent';

class AppError extends React.Component {

  state = {
    hasError: false,
  };

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorFallBackComponent /> : children;
  }
}

export default AppError;
