import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';;
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import {
	setPrevPath,
	emptyPrevPath
} from '../store/actions';

let prevPath, prevSearch;
const AuthenticatedRoute = ({ component: Component, isAuthenticated, to, ...rest }) => {
	// console.log("parps rest", rest, !isAuthenticated);
	// console.log("navigation", rest.navigation);
	// console.log("rest.location.pathname", rest.location);

	if (!isAuthenticated) {
		// prevPath = rest.location.pathname + rest.location.search;
		prevPath = rest.location.pathname
		prevSearch = rest.location.search
		rest.setPrevPath({prevPath, prevSearch});
	}

	return (
		<Route
			{...rest}
			render={props => (isAuthenticated
				?	 <Component {...props} />
				: <Redirect
					to={{
						pathname: to,
						state: { redirect: props.location.pathname, isAuthenticated },
					}}
				/>
			)}
		/>
	);
}

AuthenticatedRoute.propTypes = {
	component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
	location: PropTypes.object,
	to: PropTypes.string,
};

AuthenticatedRoute.defaultProps = {
	to: '/',
};

const mapDispatchToProps = {
	setPrevPath,
	emptyPrevPath,
};

const mapStateToProps = state => {
	return {
		navigation: state.navigation,
	};
};

export default hot(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(AuthenticatedRoute)
);

// export default AuthenticatedRoute;
