
import { hot } from 'react-hot-loader/root'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Grid, Paper, withStyles, Button, Divider, Typography, InputAdornment, Icon, Box, TextField, form, FormControl, FormLabel, Card } from '@material-ui/core';


const MySystemsLinks = (props) => {
    return <>
        <div>
            <h4 className="m-bottom-20 m-left-20 text-color-white">My Systems</h4>
        </div>
        <Grid container spacing={2} className="p-bottom-10">
            <Grid item xs={6}>
                <div className="light-green-box mysystem-link-pad">
                    <span className="strong-font"> + </span>
                    <h3 className="light-box-heading m-all-0">
                        <Link className="text-color-black" to={`/apps/${props.match.params.appid}/ibcDashboard`}>Policy Admin System</Link>
                    </h3>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className="light-green-box mysystem-link-pad">
                    <span className="strong-font"> + </span>
                    <h3 className="light-box-heading m-all-0">
                        <Link className="text-color-black" to={`/apps/${props.match.params.appid}/ibcDashboard`}>Link to the imaging systems</Link>
                    </h3>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className="light-green-box mysystem-link-pad">
                    <span className="strong-font"> + </span>
                    <h3 className="light-box-heading m-all-0">
                        <Link className="text-color-black" to={`/apps/${props.match.params.appid}/ibcDashboard`}>Link to the Banking/treasury system</Link>
                    </h3>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className="light-green-box mysystem-link-pad">
                    <span className="strong-font"> + </span>
                    <h3 className="light-box-heading m-all-0">
                        <Link className="text-color-black" to={`/apps/${props.match.params.appid}/ibcDashboard`}>Link to new business/ UW system</Link>
                    </h3>
                </div>
            </Grid>
        </Grid>
    </>
}

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};

export default hot(withRouter(
	connect(mapStateToProps, null)(MySystemsLinks)));