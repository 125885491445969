import React, { useState, useEffect } from 'react';
import { Grid, Paper, makeStyles, Divider } from '@material-ui/core';
import Highcharts from 'highcharts';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const useStyles = makeStyles(theme => ({
    paperTable: {
        textAlign: 'left',
        padding: '5px 15px',
        // backgroundColor: 'transparent',
        borderRadius: 0,
        margin: '0 20px 0 10px',
        height: '100%'
    },
    anchor: {
        fontSize: '14px !important',
        textDecoration: 'underline !important',
    },
    panel: {
        marginBottom: '20px',
        backgroundColor: '#222222',
        border: '1px solid transparent',
        // borderRadius: '4px',
        boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)',
    },
    panelDefault: {
        borderColor: '#282828',
    },
    panelHeading: {
        color: '#888888',
        // backgroundColor: '#3c3c3c',
        borderColor: '#282828',
        textAlign: 'left',
        padding: '13px',
        borderBottom: '4px solid #000',
        marginBottom: '13px',
        '@media(max-width:1366px)': {
            padding: 5,
            borderBottom: '1px solid #000',
            marginBottom: 5,
        },
    },
    headingStyle: {
        fontSize: '25px',
        '@media(max-width:1366px)': {
            fontSize: 20,
        },
        fontWeight: 'bold',
        color: '#000',
        // display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
    },
    svgIcon: {
        marginRight: 10,
        fontSize: 21,
        '@media(max-width:1366px)': {
            fontSize: 18,
        },
    },
    checkIcon: {
        color: '#fff',
        background: '#008000',
        borderRadius: '19px',
        display: 'flex'
    },
    innerHeading: {
        textAlign: 'center'
    },
    innerBody: {
        paddingTop: '35px'
    },
    innerValue: {
        paddingTop: '35px',
        fontSize: '45px',
    },
    innerDivider: {
        paddingTop: '15px',
    },
    dividerColor: {
        backgroundColor: '#4472c4',
        color: '#4472c4'
    }
}));

let data = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        renderTo: 'budgetbychannelpie'
    },
    title: {
        text: 'Budget by Brands'
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false
            },
            showInLegend: true
        }
    },
    series: [{
        name: 'Brands',
        colorByPoint: true,
        data: [{
            name: 'Ariel',
            y: 2.1,
            sliced: true,
            selected: true
        }, {
            name: 'Pantene',
            y: 1.3
        }, {
            name: 'Tide',
            y: 1.1
        }, {
            name: 'Vicks',
            y: 0.9
        }]
    }],
    credits: {
        enabled: false
    }
}

function BudgetByChannelPie(props) {
    const classes = useStyles();

    useEffect(() => {
        Highcharts.chart(data)
    }, [])

    return (<>
        <Paper className={classes.paperTable}>
            <div className={classes.budgetPie}>
                <div id={"budgetbychannelpie"} ></div>
            </div>
        </Paper>
    </>);
}

export default BudgetByChannelPie;
