import React, { useState, useEffect } from 'react';
import { HorizontalBar } from 'react-chartjs-2';

const HorizontalBarChart = (props) => {
    const [d, setData] = useState(null);

    const [options, setOptions] = useState({
        responsive: true,
        maintainAspectRatio: true,
    });
    
    useEffect(() => {
        setData(props.data);
    }, [props.data]);

	useEffect(() => {
		setOptions(props.options);
	}, [props.options]);

    return (
		<div>
			{ d ?
				<div>
					<div>{d.title}</div>
                    <HorizontalBar data={d} options={options}/>
				</div>
				: ''
			}
		</div>
    )
}
export default HorizontalBarChart;