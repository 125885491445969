import React from 'react';
import Group from './Group/Group';
// import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import { FormAction } from './ActionButtons';

export default function SetupEdit(props) {
	const { groups, fields, onDropDownSelect, onSubmitData, onDeleteHandler } = props;
	return (
		<div className="setup-table">
			<div className="select-measure-container">
				<div className="select-group">
					<div className="mesure-heading">Select Measure Type</div>
					<div className="parent-group">
						{groups.map((group, index) => {
							return (
								<div key={index} className="main-group">
									<div className="measure-label">
										{index === 0 ? 'Product' : 'Band'} {' '}
										{/* {index >= 1 && (
											<DeleteIcon
												fontSize="small"
												onClick={() => onDeleteHandler('groups', index)}
											/>
										)} */}
									</div>
									<Group
										group={group}
										labelTwo={`Group${index + 1}`}
										onDropDownSelect={onDropDownSelect}
										keyName={`Group${index + 1}`}
									/>
								</div>
							);
						})}
					</div>
				</div>
				<div className="parent-field">
					{fields.map((field, index) => {
						return (
							<div key={index} className="main-group">
								<div className="field-label">
									Fields {index + 1}{' '}
									{/* {index >= 2 && (
										<DeleteIcon fontSize="small" onClick={() => onDeleteHandler('fields', index)} />
									)} */}
								</div>
								<Group
									group={field}
									onDropDownSelect={onDropDownSelect}
									keyName={`field${index + 1}`}
									pIndex={index}
									isDeleteShow={fields.length - 1 === index ? true : false}
									onDeleteHandler={onDeleteHandler}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}