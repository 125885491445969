import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Paper, FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';
import BudgetWidget from './BudgetWidget';
import BudgetWidgetFinal from './BudgetWidgetFinal';
import TopFivePlansData from './TopFivePlansData';
import TopFivePlans from './TopFivePlans';
import BudgetPlan from './BudgetPlan';
import BudgetBar from './BudgetBar';
import BudgetPie from './BudgetPie';
import BudgetByChannelPie from './BudgetByChannelPie';
import { convertDate, getMonth } from "utility/utils";
import Header from './Header'
import Highcharts from 'highcharts';
import { getCheckData, getTopFivePlans } from '../../../../store/actions';
import { getCurrentFFForPayload } from '../spendplanUtils';

// Highcharts.getOptions().colors = Highcharts.map(Highcharts.getOptions().colors, function (color) {
// 	return {
// 		radialGradient: {
// 			cx: 0.5,
// 			cy: 0.3,
// 			r: 0.7
// 		},
// 		stops: [
// 			[0, color],
// 			[1, Highcharts.Color(color).brighten(-0.3).get('rgb')] // darken
// 		]
// 	};
// });

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const useStyles = makeStyles(theme => ({
	dashboard: {
		'@media(max-width:1366px)': {
			height: "80vh",
		},
	},
	root: {
		display: 'flex',
		// justifyContent:'space-around',
		marginBottom: '20px',
	},
	containerMain: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: '0 20px',
		justifyContent: 'space-between',
	},
}));

const Dashboard = props => {
	const { getTopFivePlans, page, brand, getCheckData, updatedData } = props;
	const classes = useStyles();
	const myTasks = [{
		_id: '1',
		taskName: 'Upload BOP Volumes for FY 2021 April',
		dueDate: '23-Feb-2021',
	}, {
		_id: '2',
		taskName: 'Upload Past Year Retailing for FY 2021 April',
		dueDate: '23-Feb-2021',
	}, {
		_id: '3',
		taskName: 'Upload Past Year RLP/MSU for FY 2021 April',
		dueDate: '23-Feb-2021',
	}, {
		_id: '4',
		taskName: 'Upload YTD Actual Retailing for FY 2021 April',
		dueDate: '23-Feb-2021',
	}, {
		_id: '5',
		taskName: 'Upload Channel Retailing Salience for FY 2021 April',
		dueDate: '23-Feb-2021',
	}];

	const planFakeData = {
		_id: '1',
		planName: 'Plan 1',
		brandform: 'Ariel Wash Rs2',
		channel: 'All small + large',
		budget: '$4.33 M',
	};

	const sortData =
		page && page.chart && page.chart.length > 0 &&
		page.chart.filter(cData => cData._id != '60484d8f268b0200135e2bc0');

	const newData = [];
	for (let i = 0; i < sortData.length; i++) {
		if (sortData[i].chartData.name === 'Setup Data') {
			newData[0] = sortData[i];
		} else if (sortData[i].chartData.name === 'Review Reports') {
			newData[2] = sortData[i];
		} else if (sortData[i].chartData.name === 'Monthly Spend Plan') {
			newData[1] = sortData[i];
		} else if (sortData[i].chartData.name === 'Configure') {
			newData[3] = sortData[i];
		}
	}

	useEffect(() => {

		let { BrandName, FinancialYear, CurrentFF } = brand;
		getTopFivePlans({
			appid: props.match.params.appid, brand: BrandName,
			fiscalYear: FinancialYear, currentFF: getCurrentFFForPayload(getMonth(CurrentFF, 'monthNumber')),
		});
		getCheckData({
			appid: props.match.params.appid,
			body: {
				formTypes: ["bopvolumes", "rlppermsu", "pyrlp", "rlpsalience", "pyrlppermsu", "rlpytdact", "tpr"],
				brand: BrandName,
				fiscalYear: FinancialYear,
				currentFF: getCurrentFFForPayload(getMonth(CurrentFF, 'monthNumber')),
				action: "finish"
			}
		})

	}, []);

	/*useEffect(() => {
		console.log("props.topFivePlans", props.topFivePlans);
	}, [props.topFivePlans])*/

	return (<div className={classes.dashboard}>
		<div style={{ paddingLeft: '10px' }}>
			<Header header="MS&P Dashboard" />
		</div>

		<div className={classes.root}>
			<Grid container >
				<Grid item xs={7}>
					<BudgetWidgetFinal myTasks={myTasks} {...props} />
				</Grid>
				<Grid item xs={5}>
					<TopFivePlansData topFivePlans={props.topFivePlans} {...props} />
				</Grid>
				{/* <Grid item xs={4}>
					<BudgetWidget myTasks={myTasks} {...props} />
				</Grid>
				<Grid item xs={4}>
					<BudgetWidget myTasks={myTasks} {...props} />
				</Grid>
				<Grid item xs={4}>
					<TopFivePlans topFivePlans={props.topFivePlans} {...props} />
				</Grid> */}
			</Grid>
		</div>
		<div className={classes.root}>
			<Grid container >
				<Grid item xs={5}>
					<BudgetBar />
				</Grid>
				<Grid item xs={3}>
					<BudgetByChannelPie />
				</Grid>
				<Grid item xs={4}>
					<BudgetPlan />
				</Grid>
			</Grid>
			{/* <Grid container >
				<Grid item xs={3}>
					<BudgetPie />
				</Grid>
				<Grid item xs={3}>
					<BudgetByChannelPie />
				</Grid>
				<Grid item xs={6}>
					<BudgetPlan />
				</Grid>
			</Grid> */}
		</div>
	</div >);
};

const mapDispatchToProps = {
	getTopFivePlans,
	getCheckData
}

const mapStateToProps = (state) => {
	return {
		brand: state.tprReducer?.selectBrand,
		topFivePlans: state.tprReducer?.topFivePlans,
		updatedData: state.tprReducer?.updatedData
	}
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));
