import { ActionTypes } from 'constants/index';

export const getConversionFunds = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_CONVERSION_FUND_DATA, payload: data });
};

export const addNewConversionFunds = (data) => dispatch => {
  dispatch({ type: ActionTypes.ADD_NEW_CONVERSION_FUND, payload: data });
};

export const deleteCheckedConversionFunds = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_CHECKED_CONVERSION_FUND, payload: data });
};

export const getConversionFundsExtraData = (payload) => dispatch => {
  dispatch({ type: ActionTypes.GET_CONVERSION_FUND_EXTRA_DATA, payload });
}

export const getPlanFilterDataConversionFunds = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_PLAN_FILTER_DATA_CONVERSION_FUNDS, payload: data });
}

export const modifyConversionFunds = (records) => dispatch => {
	dispatch({ type: ActionTypes.MODIFY_CONVERSION_FUNDS, payload: records });
}

export const clearModifyConversionFunds = () => dispatch => {
	dispatch({ type: ActionTypes.CLEAR_MODIFY_CONVERSION_FUNDS });
}

export const removeModifyConversionFunds = (ids) => dispatch => {
	dispatch({ type: ActionTypes.REMOVE_MODIFY_CONVERSION_FUNDS, payload: ids });
}
