import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import axios from 'axios'
import { DropzoneDialog } from 'material-ui-dropzone'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: '8px',
        marginRight: '8px',
        width: 200,
    },
});

class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            progressOpen: false,
            files: [],
            file: null,
            title: "Please wait, still in progress...",
            description: "Please wait, still in progress...",
            fileId: props.fileId
        };
    }

    componentDidUpdate(prevProps, prevState) {
    }

    handleClose(open) {
        this.setState({
            [open]: false
        });
    }

    submitForm(contentType, data, setResponse) {
        let url;
        if (this.props.type === "logo") {
            url = API_BASE_URL + '/dapps/upload/logo';
        } else {
            url = API_BASE_URL + '/dapps/upload'
        }
        axios({
            url: url,
            method: 'POST',
            data: data,
            headers: {
                'Content-Type': contentType,
                appId: this.props.match.params.appid
            },
            onUploadProgress: (p) => {
                console.log("p", p);
                //this.setState({
                //fileprogress: p.loaded / p.total
                //})
            }
        }).then((response) => {
            //Saving files to state for further use and closing Modal.
            // this.setState({
            //     open: false
            // });
            setResponse(response.data);
        }).catch((error) => {
            setResponse({ "error": error });
        })
    }

    uploadWithFormData(files, title, desc) {
        const formData = new FormData();
        // formData.append("files", files);
        formData.append("file", files[0]);

        this.submitForm("multipart/form-data", formData, (res) => {
            console.log("res - fileupload", res)
            if (res.status = "success") {
                this.setState({
                    fileId: res.files[0].id,
                    file: res.files[0],
                    open: false,
                    progressOpen: true,
                    description: "File has been uploaded to the server successfully."
                });
                this.props.handleFileChange(res.files[0])
            } else {
                console.log("error", res);
            }
        });
    }

    handleSave(files) {
        this.setState({
            files: files
        });
        // this.uploadWithFormData(files);
    }

    handleOpen(open) {
        this.setState({
            [open]: true,
        });
    }

    render() {
        const { classes, handleSave } = this.props;
        let acceptedFiles = this.props.acceptedFiles
            ? this.props.acceptedFiles
            : ['application/vnd.ms-excel', 'text/csv'];
            console.log('this.state.files', this.state.files);
        return (<>
            <div>
                <p>File limit is 10 MB</p>
                <Button disabled={this.props.disabled}  onClick={this.handleOpen.bind(this, 'open')} className={classes.textField,"btn btn-primay btn-color border-radius-0 w-26"}>
                    {this.state.files && this.state.files.length > 0 ? "Update File" : "Add File"}
                </Button>
                {this.state.files && this.state.files.length > 0 && <>
                   <p style={{ color: '#fff'}}>{"Filename : " + this.state.files[0].path}</p> 
                </>}
                <DropzoneDialog
                    open={this.state.open}
                    onSave={(files) => {
                        this.handleClose('open')
                        handleSave(files)
                        this.handleSave(files)
                    }}
                    acceptedFiles={acceptedFiles}
                    showPreviews={true}
                    maxFileSize={100000}
                    filesLimit={1}
                    onClose={this.handleClose.bind(this, 'open')}
                />
            </div>
        </>);
    }
}


// export default Upload;
export default withRouter(withStyles(styles, { withTheme: true })(Upload));
