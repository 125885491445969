/**
 * @module Actions/Application
 * @desc Application Actions
*/
import { ActionTypes } from 'constants/index';

export const getTsData = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_TS_DATA, payload: data });
};

export const getForCorpData = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_FORCORP_DATA, payload: data });
};
