import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { data, FixedWidthColumns, headingsLabels, NumberColumns, PercentageColumns } from './data';
import { getTsData } from '../../../../../../store/actions';
import classNames from 'classnames';
import { fixedWidthColumns } from '../Imar1Report/data';
import ConfirmDialog from '../../../SpendPlan/ConfirmDialog';
import { convertToNumber } from '../../../PlanningPages/planningHelperFunctions';
import Loader from '../../../../../Loader';

const useStyles = makeStyles(theme => ({
  smallWidth: {
    width: 80,
  },
  tableBodyLoading: {
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100vh - 200px)',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Imar2Table = (props) => {
  const classes = useStyles();

  const [reportData, setReportData] = useState(null);
  const { loading, imar2ReportData, error } = props.imar;

  const renderNoDataText = () => {
    let textToShow = 'No Data Found';
    return (
      <StyledTableRow>
        <StyledTableCell colSpan="100%">
          <h4 style={{ color: '#000' }}>{textToShow}</h4>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  useEffect(() => {
    setSticky();
  });

  useEffect(() => {
    if (imar2ReportData){
      mapResponse(props.modalData);
    } else if(reportData){
      setReportData(null)
    }
  }, [imar2ReportData]);

  const mapResponse = (isVersionedData) => {
    try {
      let response = isVersionedData ?(imar2ReportData[0]?(imar2ReportData[0].detail || []):[]) : imar2ReportData
      let data1Rows = response.filter(i => i.Section === 1);
      let data2Rows = response.filter(i => i.Section === 2);
      let _data = JSON.parse(JSON.stringify(data));
      _data['data1']['tableData']['rows'] = data1Rows;
      _data['data2']['tableData']['rows'] = data2Rows;
      setReportData(_data);
    } catch(e){
      console.log(e)
    }  
  };

  const setSticky = () => {
    const fixedRows = document.querySelectorAll('.sticky-row');
    if (fixedRows.length > 0) {
      let nextHeight = 0;
      fixedRows.forEach((row, index) => {
        let bodyNextHeight = 0;
        bodyNextHeight = nextHeight;
        nextHeight += row.offsetHeight;
        if (row.style) {
          row.style.position = 'sticky';
          row.style.zIndex = 3;
          row.style.top = `${bodyNextHeight + 1}px`;
          if (index < 5) {
            if (index % 2) {
              row.style.backgroundColor = '#fff';
            } else {
              row.style.backgroundColor = '#F5F5F5';
            }
          }
        }
      });
    }
  };

  if (loading) {
    return (
      <section className={classes.tableBodyLoading}>
        <Loader />
      </section>
    );
  }

  const renderHeadingRow = ({ num, text, status }) => {
    return (
      <StyledTableRow className={num === 1 ? 'sticky-row row-1' : ''}>
        <StyledTableCell className='white-background row-bold'>
          <span>{num}</span>
        </StyledTableCell>
        <StyledTableCell className='row-bold top-border' style={{ textAlign: 'start' }} colSpan={17}>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const renderSubHeadingRows = (order, arr) => {
    return (<>
      {
        arr.map((i, index) =>
          <StyledTableRow key={index} className={classNames(index + 1 === arr.length ? 'bottom-border' : '',
            order === 1 ? `sticky-row row-${index + 2}` : '')}>
            <StyledTableCell className='white-background remove-bottom-border'>
            </StyledTableCell>
            <StyledTableCell style={{ textAlign: 'start' }} colSpan={17}>
              <span>{i}</span>
            </StyledTableCell>
          </StyledTableRow>)
      }
      <StyledTableRow className={order === 1 ? 'sticky-row row-4' : ''}>
        <StyledTableCell className='white-background remove-right-border' colSpan={18}>
        </StyledTableCell>
      </StyledTableRow>
    </>);
  };

  const renderTableHeading = (order, headings) => {
    const getCss = (header) => {
      if (FixedWidthColumns.includes(header)) {
        return { whiteSpace: 'normal', textAlign: 'center', width: 75 };
      }
      return { textAlign: 'center' };
    };
    return (
      <StyledTableRow className={classNames('sub-table-head', order === 1 ? 'sticky-row row-5' : '')}>
        <StyledTableCell className='white-background'>
        </StyledTableCell>
        {
          headings.map((header, index) =>
          (<StyledTableCell key={index} style={getCss(header)}
            className={classNames('top-border', index === 0 ? classes.smallWidth : '',
              header === '' ? 'white-background remove-top-border' : '')}>
            <span dangerouslySetInnerHTML={{ __html: (header === 'Rank' && order === 2) ? '-ve GM<br/>Rank' : headingsLabels[header] }} />
          </StyledTableCell>))
        }
      </StyledTableRow>
    );
  };

  const renderTableBody = (rows, headers, highlightLastThreeRow, sectionNo) => {

    const leftAlignColumns = ['Channel', 'Brandform'];

    const getVal = (header, i) => {
      if (header === '')
        return '';
      if (i[header] === "")
        return ''
      if (PercentageColumns.includes(header)) {
        return i[header] === 0 ? '0.0%' : (Number(i[header])
          .toFixed(1) + '%');
      }
      return NumberColumns.includes(header) ? convertToNumber(i[header], 1) : i[header];
    };

    const getStyles = (header) => {
      let style = {};
      if (leftAlignColumns.includes(header)) {
       Object.assign(style, { textAlign: 'left' })
      }
      if (FixedWidthColumns.includes(header)) {
        Object.assign(style, { width: 75 })
      }
      return style;
    }

    const getBGColor = (key, value, ind) => {
      if (value === "" || ind + 1 === rows.length) {
        return {}
      }
      let style = {};
      if (key === 'FY') {
        Object.assign(style, { backgroundColor: '#ffff99' })
      }
      if (key === 'Delta') {
        if (value && Number(value.toString().replace("%", "")) >= 0) {
          Object.assign(style, { backgroundColor: '#05af50' })
          return style;
        }
        if (value && Number(value.toString().replace("%", ""))  < 0 || (value.startsWith && value.startsWith("-"))) {
          Object.assign(style, { backgroundColor: '#ff0000' })
          return style;
        }
      }
      if (key === 'GM_NOS' && sectionNo === 1) {
        if (value && (Number(value.toString().replace("%", "")) > 40)) {
          Object.assign(style, { backgroundColor: '#05af50' })
          return style;
        }
        if (value && Number(value.toString().replace("%", "")) < 0  || (value.startsWith && value.startsWith("-"))) {
          Object.assign(style, { backgroundColor: '#ff0000' })
          return style;
        }
        if (value && Number(value.toString().replace("%", "")) < 20) {
          Object.assign(style, { backgroundColor: '#ffff00' })
          return style;
        } else {
          Object.assign(style, { backgroundColor: '#c5e0b3' })
          return style;
        }
      }
      if (['JAS', 'OND', 'JFM', 'AMJ', 'FY'].includes(key)) {
        if (Number(value) === 0) {
          Object.assign(style, { backgroundColor: '#ffffff' })
          return style;
        }
        if (Number(value) > 120) {
          Object.assign(style, { backgroundColor: '#05af50' })
          return style;
        }
        if (Number(value) < 100) {
          Object.assign(style, { backgroundColor: '#ff0000' })
          return style;
        } 
        if(Number(value) > 100 && Number(value) < 110) {
          Object.assign(style, { backgroundColor: '#ffff00' })
        }
      }
      return style;
    }

    return <>
      {
        rows.map((i, index) => {
          return (
            <StyledTableRow key={index} className={index + 1 === rows.length ? 'bottom-border last-row' :
              (highlightLastThreeRow && (index + 2 === rows.length || index + 3 === rows.length)) ? 'last-row' : ''}>
              <StyledTableCell className='white-background remove-bottom-border'>
              </StyledTableCell>
              {
                headers.map((header, index1) => {
                  return (
                    <StyledTableCell
                      style={{ ...getStyles(header), ...getBGColor(header, i[header] || "", index) }}
                      key={index1}
                      className={classNames((index + 1 !== rows.length && header === 'description' && i[header] === 'Difference')
                        ? 'bold-blue' : '', header === '' ? 'white-background remove-top-border' : '')}>
                      <span title={(i[header] + (PercentageColumns.includes(header)?'%':''))}>{NumberColumns.includes(header)?getVal(header, i).toLocaleString():getVal(header, i)}</span>
                    </StyledTableCell>
                  );
                })
              }
            </StyledTableRow>
          );
        })
      }
      <StyledTableRow className='top-border'>
        <StyledTableCell className='white-background remove-right-border'>
        </StyledTableCell>
      </StyledTableRow>
    </>;
  };

  return (
    <div className='imar1-report'>
      <TableContainer className='table-container' component={Paper}>
        <Table className='table' aria-label="simple table" stickyHeader={true}>
          <TableHead className='table-head'>
            <TableRow className='sticky-row row-0'>
              <TableCell colSpan={1}>
                <span>S NO</span>
              </TableCell>
              <TableCell colSpan={17}>
                <span>IMAR Checklist</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='table-body'>
            {
              reportData &&
              <>
                <>
                  {/*table 1 start*/}
                  {
                    renderHeadingRow({ num: 1, text: reportData.data1.headingData, status: 'Yes' })
                  }
                  {
                    renderSubHeadingRows(1, reportData.data1.subHeadingData)
                  }
                  {
                    renderTableHeading(1, reportData.data1.tableData.headings)
                  }
                  {
                    renderTableBody(reportData.data1.tableData.rows, reportData.data1.tableData.headings, false, 1)
                  }
                  {/*table 1 end*/}
                </>
                <>
                  {/*table 2 start*/}
                  {
                    renderHeadingRow({ num: 2, text: reportData.data2.headingData, status: 'Yes' })
                  }
                  {
                    renderSubHeadingRows(2, reportData.data2.subHeadingData)
                  }
                  {
                    renderTableHeading(2, reportData.data2.tableData.headings)
                  }
                  {
                    renderTableBody(reportData.data2.tableData.rows, reportData.data2.tableData.headings, false, 2)
                  }
                  {/*table 2 end*/}
                </>
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>
      { error && <ConfirmDialog open={ error !==null } handleClose={props.clearError} alertMessageText={{title: 'Error Loading Page',
             p2: 'There was an error loading this content. Please reload the page or try again later.', p4: error}} classes={classes} /> }

    </div>
  );
};


const mapStateToProps = state => {
  return {
    user: state.user,
    imar: state.ImarReportsReducer,
  };
};

const mapDispatchToProps = {
  getTsData,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Imar2Table)));
