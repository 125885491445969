import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMonth } from "utility/utils";
import { getMrData, removeMRSuccessMessage } from '../../../../../store/actions/index.js';
import { capitalize } from '../../../../../utility/utils';
import Loader from '../../../../Loader/index.js';
import PlanningErrorModal from '../../../spendplan/PlanningPages/planningErrorModal';
import { convertToNumber } from '../../../spendplan/PlanningPages/planningHelperFunctions.js';
import { URLs } from '../../ApiConstants.js';
import { shortMonthLowercase } from '../../planingPages/planConfig.js';
import { ReportFilter } from '../reportFilter.js';

export const useStyles = makeStyles(theme => ({
    tableContainer: {
        maxWidth: "94vw",
        "@media(max-width:1700px)": {
            maxWidth: "93.5vw",
        },
        borderRadius: "8px",
        margin: "5px",
        maxHeight: 'calc(100vh - 230px)',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.8em',
            height: '0.8em'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cdcdcd',
            outline: '1px solid slategrey',
        },
    },
    table: {
        minWidth: 700,
        "&:focus": {
            border: "0"
        },
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
    },
    tableHead: {
        position: 'sticky', top: 0, zIndex: 10,
        "& th": {
            textAlignLast: "center",
            paddingBottom: 0,
            paddingTop: 5,
            backgroundColor: '#3CA7FD',
            color: '#fff',
            padding: "6px !important",
        },
        "& th:not(.adjustBorder)": {
            borderRight: "1px solid rgba(224, 224, 224, 1)"
        },
        "& th.adjustBorder": {
            paddingRight: "0",
            "& span": {
                paddingRight: "16px",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                display: "flex",
                height: "40px",
                alignItems: "center",
            }
        },
        '& th.lastYearHeader': {
            "& span": {
                height: "40px",
                display: "flex",
                padding: "0 16px",
                textAlign: 'center',
                alignItems: "center",
            }
        },
        "& span": {
            lineHeight: 1.2,
            fontWeight: "bold",
            display: 'block',
            wordBreak: 'normal',
            whiteSpace: 'normal',
            textAlign: 'center',
            paddingLeft: 8,
            paddingRight: 8,
        },

    },
    tableBody: {
        "& td": {
            padding: "9px !important",
            "@media(max-width:1366px)": {
                padding: "8px !important"
            },
            cursor: "pointer",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            borderBottom: "0",
            textAlign: "center",
            "& input": {
                textAlign: "center"
            }
        },
        "& td.noBorder": {
            border: "0"
        },
        "& p": {
            display: "flex"
        },
    },
    combinedHeaderSection: {
        justifyContent: 'center',
        paddingTop: '10px',
        display: 'flex',
        '& p': {
            color: '#fff',
            flex: 1,
            alignSelf: 'center',
            paddingLeft: 10,
            paddingRight: 10,
            margin: 0,
        }
    },
    divider: {
        marginTop: 'auto',
        height: 30,
        '@media(max-width:1366px)': {
            height: 25,
        },
        width: 1,
        background: 'rgba(224, 224, 224, 1)',
        marginBottom: 'auto',
    },
    combinedHeaderHeading: {
        marginTop: 5,
        marginBottom: 5,
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
        borderRadius: "4px",
        padding: "4px 2px 2px",
        background: "rgb(16, 114, 189)",
    },
    tableBodyLoading: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        height: 'calc(100vh - 300px)',
    },
    alignLeft: {
        textAlign: 'left !important',
    },
    combinedBodySection: {
        display: "flex",
        justifyContent: 'space-around',
        margin: 0,
    },
    rowBold: {
        "& span": {
            fontWeight: "bold"
        }
    },
    boldItalic: {
        "& span": {
            fontWeight: "bold",
            fontStyle: "italic",
        }
    },
    noBottomBorder: {
        borderBottom: 'none !important',
    },
    noTopBorder: {
        borderTop: 'none !important',
    },
    alignRight: {
        textAlign: 'end !important',
    },
    icon: {
        fontSize: '12px !important',
        cursor: 'pointer'
    },
    greenFont: {
		color: 'green',
	},
    redFont: {
        color: 'red',
    }
}));

export const renderNoDataText = () => {
    let textToShow = "No Data Found";
    return (
        <StyledTableRow>
            <StyledTableCell colSpan="100%">
                <h4 style={{ color: '#000' }}>{textToShow}</h4>
            </StyledTableCell>
        </StyledTableRow>
    );
}

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        minWidth: 50,
    },
    body: {
        fontSize: 14,
        minWidth: 50,
    },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        overflow: 'hidden',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const oneCr = 10000000;

const SummarySheetTable = (props) => {

    const classes = useStyles();
    const [account, setAccount] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = useState(false);
    const [alertMessageText, setAlertMessage] = React.useState("");

    const [brandVal, setBrandVal] = useState([]);
    const [brandformVal, setBrandformVal] = useState([]);
    const [allBrandforms, setAllBrandforms] = useState([]);
    const [parameterVal, setParameterVal] = useState([]);
    const [filterData, setFilterData] = useState({})
    const [allParameter, setAllParameter] = useState([])

    const fetchData = () => {
        const account = props.account;
        if (account) {
            let objToSend = {
                fiscalYear: props.modalData?.year || account.FinancialYear,
                account: encodeURIComponent(props.modalData?.account || account.AccountName),
            };
            let m = '' + getMonth(account.CurrentFF, 'monthNumber')
            objToSend.currentFF = props.modalData?.month || (m.length === 1 ? '0' + m : m);
            getDataFromApi(objToSend);
        }
    };

    const getDataFromApi = (params) => {
        let url = URLs.getSummaryVolumeData
        url += `?account=${params.account}&fiscalYear=${params.fiscalYear}&currentFF=${params.currentFF}`
        let payload = {
            url: props.modalData ? url + ('&version=true') : url,
            appId: props.match.params.appid,
            dataUniqueId: 'GET_summaryVolumePlanning'
        }
        props.getMrData(payload)
    }

    useEffect(() => {
        if (props.account?.AccountName !== account?.AccountName) {
            fetchData();
            setAccount(props.account)
        }
    }, [props.account]);

    useEffect(() => {
        if (props.data && props.data['GET_summaryVolumePlanning'] && !(props.error ? props.error['GET_summaryVolumePlanning'] : false)) {
            let _data = props.data['GET_summaryVolumePlanning'].result.data;
            if (props.modalData) {
                if (_data[0] && _data[0].detail) {
                    setTableData(_data[0].detail || [])
                }
            } else {
                setTableData(_data)
            }
        } else if (tableData.length) {
            setTableData([])
        }
    }, [props.data ? props.data['GET_summaryVolumePlanning'] : null]);


    useEffect(() => {
        if (props.mrData.error && props.mrData.error['GET_summaryVolumePlanning']) {
            setOpen(true)
            setAlertMessage({
                title: 'Summary - Volume Planning',
                p1: props.mrData.error['GET_summaryVolumePlanning']?.message || 'Something went wrong',
            });
        }
    }, [props.mrData.error]);

    const isMonthForecast = (monthKey) => {
        // monthKey: jan, feb ,mar
        if (props.account?.CurrentFF && shortMonthLowercase.indexOf(monthKey) + 1 > shortMonthLowercase.indexOf((moment().month(props.account.CurrentFF).format('MMM').toLowerCase()))) {
            return true;
        }
        return null
    }
    const handleClose = () => {
        setOpen(false);
        props.removeMRSuccessMessage({ dataUniqueId: 'GET_summaryVolumePlanning' });
    };

    const getMonths = (isForeCast) => {
        return shortMonthLowercase.map((i) => {
            if (isForeCast) {
                if (!isMonthForecast(i)) {
                    return i;
                }
            } else {
                if (isMonthForecast(i)) {
                    return i;
                }
            }
        }).filter(Boolean)
    }

    const getData = () => {
        return tableData?.filter(item => {
            return (brandVal?.length ? brandVal.includes(item.brand) : true) && (brandformVal?.length ? brandformVal.includes(item.brandform) : true)
                && (parameterVal?.length ? parameterVal.includes(item.parameter) : true);
        }) || []
    }

    let xx = (props.data && props.data['GET_summaryVolumePlanning']) ? props.data['GET_summaryVolumePlanning'].result.data : [];
    useEffect(() => {
        if (xx?.length) {
            let _data = xx.map(ii => {
                if (ii['brandform'] !== 'TOTAL' && ii.parameter !== 'Retailing') {
                    return {
                        brand: ii.brand,
                        brandform: ii.brandform,
                        parameter: ii.parameter,
                    }
                }
            }).filter(Boolean)
            setAllBrandforms([... new Set(_data?.map(i => i.brandform))])
            setAllParameter([... new Set(_data?.map(i => i.parameter))])
            setFilterData(_data);
        }
    }, [xx])

    const getBrandFilterValues = () => {
        return filterData?.length ? [... new Set(filterData?.map(i => i.brand))] : []
    }

    const onChangeFilter = (type, values) => {
        if (type === 'brand') {
            setBrandVal(values)
            let _allBrandForms = [... new Set(filterData.filter(i => values.includes(i.brand)).map(i => i.brandform))];
            setAllBrandforms(_allBrandForms)
            setBrandformVal([])
        }
        if (type === 'brandform') {
            setBrandformVal(values)
        }
        if (type === 'parameter') {
            setParameterVal(values)
        }
    }

    const getTotal = (m) => {
        let total = getData().filter(j => j['brandform'] !== 'TOTAL' && j.parameter !== 'Retailing')?.reduce((acc, row) => { return acc + row.months[m]; }, 0) || 0
        return total
    }

    if (props.mrData && props.mrData['GET_summaryVolumePlanning' + '_loading']) {
        return (
            <section className={classes.tableBodyLoading}>
                <Loader />
            </section>
        )
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', marginLeft: 10, }}>
                <ReportFilter id='brandFilter' label='Brand' value={brandVal} onChangeFilter={v => onChangeFilter('brand', v)} allValues={getBrandFilterValues()} />
                <ReportFilter id='brandformFilter' label='Brandform' onChangeFilter={v => onChangeFilter('brandform', v)} value={brandformVal} allValues={allBrandforms} />
                <ReportFilter id='parameterFilter' label='Parameter' onChangeFilter={v => onChangeFilter('parameter', v)} value={parameterVal} allValues={allParameter} />
            </div>
            <TableContainer className={classNames(classes.tableContainer, "tpr-container")} component={Paper}>
                <Table className={classNames(classes.table, "tpr-table")} aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell rowSpan={3}>
                                <span>Brand</span>
                            </TableCell>
                            <TableCell rowSpan={3}>
                                <span>Brandform</span>
                            </TableCell>
                            <TableCell rowSpan={3}>
                                <span>Parameter</span>
                            </TableCell>
                            {
                                Boolean(getMonths(true).length) && getMonths(true).map((month, index) => {
                                    return (<TableCell key={index} style={{ borderBottom: 'none' }}>
                                        <span title={getTotal(month).toLocaleString('en-In')}>{convertToNumber(getTotal(month), 2).toLocaleString('en-In')}</span>
                                    </TableCell>)
                                })
                            }
                            {
                                Boolean(getMonths(false).length) && getMonths(false).map((month, index) => {
                                    return (<TableCell key={index} style={{ borderBottom: 'none' }}>
                                        <span title={getTotal(month).toLocaleString('en-In')}>{convertToNumber(getTotal(month), 2).toLocaleString('en-In')}</span>
                                    </TableCell>)
                                })
                            }
                        </TableRow>
                        <TableRow>
                            {
                                Boolean(getMonths(true).length) &&
                                <TableCell colSpan={getMonths(true).length} style={{ borderBottom: 'none' }}>
                                    <p className={classes.combinedHeaderHeading}>Actuals</p>
                                </TableCell>
                            }
                            {
                                Boolean(getMonths(false).length) &&
                                <TableCell colSpan={getMonths(false).length} style={{ borderBottom: 'none' }}>
                                    <p className={classes.combinedHeaderHeading}>Forecast</p>
                                </TableCell>
                            }
                        </TableRow>
                        <TableRow>
                            {
                                Boolean(getMonths(true).length) && getMonths(true).map((month, index) => {
                                    return (<TableCell key={index}>
                                        <span>{capitalize(month)}</span>
                                    </TableCell>)
                                })
                            }
                            {
                                Boolean(getMonths(false).length) && getMonths(false).map((month, index) => {
                                    return (<TableCell key={index}>
                                        <span>{capitalize(month)}</span>
                                    </TableCell>)
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {getData().length ? getData().map((row, index) => {
                            if (row['brandform'] !== 'TOTAL' && row.parameter !== 'Retailing') {
                                return (
                                    <StyledTableRow key={index} >
                                        <StyledTableCell className={classes.alignLeft}>
                                            <span>{row.brand}</span>
                                        </StyledTableCell>
                                        <StyledTableCell className={classes.alignLeft}>
                                            <span>{row.brandform}</span>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <span>{row.parameter}</span>
                                        </StyledTableCell>
                                        {
                                            getMonths(true).map((month, ii) => {
                                                return <StyledTableCell key={ii} title={row.months[month]}>{row.months ? convertToNumber(row.months[month], 1).toLocaleString('en-In') : ''}</StyledTableCell>

                                            })
                                        }
                                        {
                                            getMonths(false).map((month, ii) => {
                                                return <StyledTableCell key={ii} title={row.months[month]}>{row.months ? convertToNumber(row.months[month], 1).toLocaleString('en-In') : ''}</StyledTableCell>
                                            })
                                        }
                                    </StyledTableRow>)
                            }

                        }) :
                            renderNoDataText()
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} />

        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        account: state.mrReducer?.account,
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
    };
};

const mapDispatchToProps = {
    getMrData,
    removeMRSuccessMessage,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps,)(SummarySheetTable)),);

