import {
  Checkbox,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import axios from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFileNameWithTimeStamp, getMonth } from 'utility/utils';
import { generateGUID } from '../../../../../modules/page/page.module';
import {
  addNewConsumerPromo,
  clearModifyConsumePromo,
  deleteCheckedConsumerPromo,
  getClusterfilter,
  getBrandformfilter,
  getConsumerPromoExtraData,
  getConsumerPromotions,
  getPlanFilterDataConsumerPromo,
  hideMessage,
  modifyConsumePromo,
  removeModifyConsumePromo,
  removeSuccessMessage
} from '../../../../../store/actions';
import ConfirmDialog from '../../SpendPlan/ConfirmDialog';
import { getCurrentFFForPayload, getFiscalYearFormat } from '../../spendplanUtils';
import PlanHeader, { ActionType, FilterType } from '../PlanHeader';
import PlanningErrorModal from '../planningErrorModal';
import {
  commonStyle,
  convertToNumber,
  downloadFile, getQuarterBasedOnMonth,
  isReadOnly,
  months,
  PlanningLoading,
  renderNoDataText,
  setSticky,
  StyledTableCell,
  StyledTableRow
} from '../planningHelperFunctions';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700,
    paddingRight: 7,
  },
  tableHead: {
    '& th': {
      textAlignLast: 'center',
    },
    '& th:not(.adjustBorder)': {
      boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
    },
    '& th.adjustBorder': {
      paddingRight: '0',
      '& span': {
        paddingRight: '16px',
        boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
        display: 'flex',
        height: '40px',
        alignItems: 'center',
      },
    },
    '& th.lastYearHeader': {
      '& span': {
        height: '40px',
        display: 'flex',
        padding: '0 16px',
        textAlign: 'center',
        alignItems: 'center',
      },
    },
    '& span': {
      fontWeight: 'bold',
    },
  },
  tableBody: {
    '& td': {
      boxShadow: 'inset 1px 0 0 rgba(224, 224, 224, 1)',
      cursor: 'pointer',
      textAlign: 'center',
      '& input': {
        textAlign: 'center',
      },
    },
    '& td.noBorder': {
      boxShadow: 'none',
    },
    '& p': {
      display: 'flex',
    },
  },
  tableInput: {
    border: '0',
    height: '38px',
    maxWidth: '75px',
    appearance: 'none',
    background: 'transparent',
    '&:focus': {
      background: '#fff',
    },
  },
  textInput: {
    border: '0',
    height: '38px',
    width: '90px',
    color: 'inherit',
    appearance: 'none',
    background: 'transparent',
  },
  combinedHeaderSection: {
    justifyContent: 'space-around',
    height: 70,
    '@media(max-width:1366px)': {
      height: '65px',
    },
    display: 'flex',
    alignItems: 'center',
    '& p': {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '82px',
    },
    '& p:not(:last-of-type)': {
      height: '40px',
      textAlign: 'center',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      margin: '0px',
    },
    '& p:not(:first-of-type)': {
      textAlign: 'center',
    },
  },
  combinedBodySection: {
    justifyContent: 'space-around',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      borderRight: '0',
      width: '82px',
      justifyContent: 'space-around',
    },
    '& p:not(:last-of-type)': {
      height: '38px',
      textAlign: 'center',
      paddingRight: '16px',
      paddingTop: '2px',
      paddingBottom: '2px',
      margin: '0px',
      '& input': {
        height: '100%',
      },
    },
    '& p:not(:first-of-type)': {
      paddingLeft: '16px',
      textAlign: 'center',
    },
  },
  combinedHeaderTotalSection: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& p': {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '82px',
    },
    '& p:not(:last-of-type)': {
      height: 20,
      textAlign: 'center',
      paddingRight: '16px',
      margin: '0px',
    },
    '& p:not(:first-of-type)': {
      // paddingLeft: '16px',
      textAlign: 'center',
    },
  }, ...commonStyle
}));

const editableFields = {
  ZMAX: true,
  MRP: true,
  unitsSU: true,
  retailMargin: true,
  priorFYInvtyMSU: true,
  currentFYCoverMSU: true,
  actualsSpend: true,
  actualShipmentMSU: true,
};

const ConsumerPromotions = props => {
  const classes = useStyles();
  const {
    getConsumerPromotions, match, consumerPromotionsData, addNewConsumerPromo,
    getConsumerPromoExtraData, page, deleteCheckedConsumerPromo, getPlanFilterDataConsumerPromo, scenario
  } = props;
  const modalData = props.modalData ? props.modalData : '';
  const { data: consumerPromoData, error, extraDataError, getNewData, selectBrand, successMsg } = consumerPromotionsData;
  const [readOnly, setReadOnly] = useState(false);
  const [urlSetup, setUrlSetup] = useState(true);
  const [urlSetupParams, setUrlSetupParams] = useState(true);
  const [brand, setBrand] = useState(selectBrand);
  const [fieldEdited, setFieldEdited] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false)

  const checkForUrlParams = () => {
    if (urlSetupParams) {
      let urlPayload = {}, readonly = false, isModal = false;
      const params = match.params,
        urlSearchParams = new URLSearchParams(window.location.search);

      if (modalData && modalData.brand && modalData.month && modalData.year) {
        urlPayload = {
          brand: modalData.brand, year: modalData.year, currentFF: getCurrentFFForPayload(modalData.month),
        };
        isModal = true;
      } else if (params.brand && params.currentFF && params.year) {
        urlPayload = {
          brand: params.brand, currentFF: getCurrentFFForPayload(params.currentFF), year: params.year,
        };
        readonly = true;
      } else if (urlSearchParams.get('brand') && urlSearchParams.get('year') && urlSearchParams.get('month')) {
        urlPayload = {
          brand: urlSearchParams.get('brand'),
          currentFF: getCurrentFFForPayload(urlSearchParams.get('month')),
          year: urlSearchParams.get('year'),
          cluster: decodeURIComponent(urlSearchParams.get('cluster')),
          brandForm: decodeURIComponent(urlSearchParams.get('brandForm')),
          planName: urlSearchParams.get('planName'),
          planMonth: urlSearchParams.get('planMonth'),
        };
        readonly = true;
      }
      if ((readonly && isReadOnly(urlPayload.currentFF, urlPayload.year, brand)) || isModal) {
        setReadOnly(true);
      } else {
        setReadOnly(false);
      }
      setUrlSetupParams(false);
      return urlPayload;
    }
    return {};
  };

  const [urlPayload, seturlPayload] = useState(checkForUrlParams());
  const [qtr, setQtr] = useState(urlPayload.planMonth ? [getQuarterBasedOnMonth(urlPayload.planMonth)] : []);
  const [plan, setPlan] = useState(urlPayload.planName ? [urlPayload.planName] : []);
  const [date, setDate] = useState('');
  const [open, setOpen] = useState(false);
  const [month, setMonth] = useState(urlPayload.planMonth ? [urlPayload.planMonth] : []);
  const [cluster, setCluster] = useState(urlPayload.cluster ? [urlPayload.cluster] : []);
  const [pageCount, setPageCount] = useState(0);
  const [fieldData, setFieldData] = useState([]);
  const [extraData, setExtraData] = useState(null);
  const [brandForm, setBrandForm] = useState(urlPayload.brandForm ? [urlPayload?.brandForm] : []);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rowSelected, setRowSelected] = useState('');
  const [saveButtonState, setSaveButton] = useState(false);
  const [alertMessageText, setAlertMessage] = useState('');
  const [checkedConsumerPromo, setCheckedConsumerPromo] = useState([]);
  const [saveBtnDisabled, disableSaveBtn] = useState(false)
  const [deleteConfirmState, setDeleteConfirm] = useState({
    isOpen: false,
    deleteMessage: '',
  });
  const [confirmState, setConfirm] = useState({ isOpen: false, confirmMessage: '' });
  useEffect(() => {
    setSticky();
  });

  useEffect(() => {
    props.clearModifyConsumePromo()
    if (brand) {
      !props.sptprData.filterData?.clusters?.length && props.getClusterfilter({ appid: match.params.appid });
    }
  }, []);

  useEffect(() => {
    if (getNewData) {
      fetchData(brandForm, cluster, month, qtr, currentPage, rowsPerPage, brand, plan);
      fetchPlanFilter();
      setRowSelected('');
    }
  }, [getNewData]);

  useEffect(() => {
    if (error) {
      if (typeof error === 'string') {
        setAlertMessage({
          title: 'Consumer Promotions',
          p1: error,
        });
        handleClickOpen();
      } else {
        setAlertMessage({
          title: 'Consumer Promotions',
          ...error,
        });
        handleClickOpen();
      }
    }
  }, [error]);

  useEffect(() => {
    if (successMsg) {
      setAlertMessage({
        title: 'Consumer Promotions',
        p1: successMsg,
      });
      handleClickOpen();
    }
  }, [successMsg]);


  useEffect(() => {
    if (consumerPromoData && consumerPromoData.result && consumerPromoData.result.data && !error) {
      const { data: { result } } = consumerPromotionsData, { total, data } = result;

      // in case of delete we will have unsaved plan if there are some
      let unsavedPlans = fieldData.filter(i => i._id.startsWith('newplan'));
      setFieldData([...unsavedPlans, ...data]);
      if (unsavedPlans.length === 0 && !Object.keys(props.modifiedRecords).length) {
        setSaveButton(false);
        disableSaveBtn(false)
      }
      setPageCount(total);
      callConsumerPromoExtraData(data);
    }
  }, [consumerPromoData]);

  useEffect(() => {
    if (selectBrand && typeof selectBrand === 'object' && selectBrand !== null && Object.keys(selectBrand).length > 0) {
      setBrand(selectBrand);
      props.getBrandformfilter({ brandName: selectBrand.BrandName, appid: match.params.appid });
      let m = '' + getMonth(selectBrand.CurrentFF, 'monthNumber');
      m = m.length === 1 ? '0' + m : m;
      getPlanFilterDataConsumerPromo({
        currentFF: m,
        fiscalYear: `${selectBrand.FinancialYear}`,
        url: 'consumerPromotion',
        brand: selectBrand.BrandName,
        appid: match.params.appid,
        scenario: props?.sptprData?.selectScenario
      });
      if (!urlSetup) {
        setBrandForm('');
        setCluster('');
        setPlan([]);
      } else {
        setUrlSetup(false);
      }
    }
  }, [selectBrand]);

  useEffect(() => {
    setCheckedConsumerPromo([]);
  }, [consumerPromoData?.result?.total]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (successMsg || error) {
      props?.removeSuccessMessage(null);
    }
  };

  const callConsumerPromoExtraData = (tableData) => {
    if (tableData.length > 0) {
      let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
      m = m.length === 1 ? '0' + m : m;
      let payload = {
        currentFF: brand ? `${m}` : '',
        fiscalYear: brand ? `${brand.FinancialYear}` : '',
        brand: brand ? brand.BrandName : '',
        scenario: props?.sptprData?.selectScenario
      };
      getConsumerPromoExtraData({
        payload,
        appid: match.params.appid,
        dataModalId: page.pageLevelDataModel,
      });
    }
  };

  useEffect(() => {
    if (consumerPromotionsData && consumerPromotionsData.extraData && !extraDataError) {
      setExtraData(consumerPromotionsData.extraData);
    } else if (extraDataError && extraDataError !== 'Bad Request') {
      setAlertMessage({
        title: 'Consumer Promotions',
        p1: extraDataError,
      });
      handleClickOpen();
    }
  }, [consumerPromotionsData.extraData]);

  const saveToStore = (record) => {
    props.modifyConsumePromo(record)
  }

  const handleFieldChange = (target, index, rowNames = [], id = '') => {
    const { name, value } = target;
    if (editableFields[name]) {
      let fieldsObject = fieldEdited[id] ? { ...fieldEdited[id] } : {};
      setFieldEdited({
        ...fieldEdited, [id]: {
          ...fieldsObject,
          [name]: true,
        },
      });
    }
    setSaveButton(true);
    saveBtnDisabled && disableSaveBtn(false);
    let oldData = JSON.parse(JSON.stringify(fieldData));
    if (oldData && oldData[index]) {
      // if it is new plan and is checked and update all new checked plan
      if (checkedConsumerPromo.length > 0 && checkedConsumerPromo.includes(oldData[index]._id)) {
        let updateFieldData = oldData.map(i => {
          if (checkedConsumerPromo.includes(i._id)) {
            i[name] = value;
            if (name === 'month') {
              i['quarter'] = getQuarterBasedOnMonth(value);
            }
            saveToStore(i)
            return i
          } else {
            return i;
          }
        })
        setFieldData([...updateFieldData]);
      } else {
        oldData[index][name] = value;
        if (name === 'month') {
          oldData[index]['quarter'] = getQuarterBasedOnMonth(value);
        }
        saveToStore(oldData[index])
        setFieldData([...oldData]);
      }
    }
  };

  const createNewPromoPress = () => {
    let oldData = JSON.parse(JSON.stringify(fieldData));
    let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
    m = m.length === 1 ? '0' + m : m;
    let lm = '' + getMonth(brand.CurrentFF, 'lastMonth');
    lm = lm.length === 1 ? '0' + lm : lm;
    let dataToAdd = {
      editable: true,
      consumerPromotionVersion: 'SPTPR_A_APWR2_2021_04',
      generatedHashKey: '',
      fiscalYear: brand ? `${brand.FinancialYear}` : '',
      currentFF: brand ? `${m}` : '',
      lastFF: brand ? `${lm}` : '',
      spendType: 'Consumer Promotion',
      salesManager: '',
      msnpProjectManager: '',
      version: '',
      brand: brand ? brand.BrandName : '',
      brandform: brandForm?.length ? brandForm[0] : '',
      cluster: cluster?.length ? cluster[0] : 'All India',
      consumerPromoDescription: 'NewPlan_1',
      month: 'jul',
      quarter: 'AMJ',
      unitsSU: 0,
      retailMargin: 0,
      ZMAX: 0,
      MRP: 0,
      priorFYInvtyMSU: 0,
      currentFYCoverMSU: 0,
      actualsSpend: 0,
      actualShipmentMSU: 0,
      isActive: true,
      createdBy: brand ? brand.createdBy : '',
      createdDate: new Date().toISOString(),
      _v: 0,
      dataModelId: '',
    };

    let newDataToAdd = months.map((item, index) => {
      let copyDataToAdd = JSON.parse(JSON.stringify({ ...dataToAdd, _id: 'newplan_' + generateGUID() }));

      copyDataToAdd.month = item;
      copyDataToAdd.quarter = getQuarterBasedOnMonth(item);
      props.modifyConsumePromo(copyDataToAdd);
      return copyDataToAdd;
    });
    oldData.splice(0, 0, ...newDataToAdd);
    setFieldData([...oldData]);
    setRowSelected(0);
    setSaveButton(true);
    saveBtnDisabled && disableSaveBtn(false);
  };

  const duplicateRow = () => {
    const oldData = JSON.parse(JSON.stringify(fieldData));
    const selectedRowData = oldData[rowSelected];
    selectedRowData.duplicated = selectedRowData.duplicated ? selectedRowData.duplicated + 1 : 1;
    let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
    m = m.length === 1 ? '0' + m : m;
    let lm = '' + getMonth(brand.CurrentFF, 'lastMonth');
    lm = lm.length === 1 ? '0' + lm : lm;
    let dataToAdd = {
      editable: true,
      consumerPromotionVersion: 'SPTPR_A_APWR2_2021_04',
      generatedHashKey: '',
      // fiscalYear: selectedRowData.fiscalYear,
      fiscalYear: (brand ? brand.FinancialYear : selectedRowData.fiscalYear) + "",
      currentFF: brand ? `${m}` : '',
      lastFF: brand ? `${lm}` : '',
      spendType: selectedRowData.spendType,
      salesManager: '',
      msnpProjectManager: '',
      version: selectedRowData.version,
      brand: selectedRowData.brand,
      brandform: selectedRowData.brandform,
      cluster: selectedRowData.cluster,
      consumerPromoDescription: selectedRowData.consumerPromoDescription + '_1',
      month: selectedRowData.month,
      quarter: selectedRowData.quarter,
      unitsSU: selectedRowData.unitsSU,
      retailMargin: selectedRowData.retailMargin,
      ZMAX: selectedRowData.ZMAX,
      MRP: selectedRowData.MRP,
      priorFYInvtyMSU: selectedRowData.priorFYInvtyMSU,
      currentFYCoverMSU: selectedRowData.currentFYCoverMSU,
      actualsSpend: selectedRowData.actualsSpend,
      actualShipmentMSU: selectedRowData.actualShipmentMSU,
      lc_BudgetInMillions: selectedRowData.lc_BudgetInMillions,
      lc_MRP: selectedRowData.lc_MRP,
      lc_ZMAX: selectedRowData.lc_ZMAX,
      lc_currentFYCoverMSU: selectedRowData.lc_currentFYCoverMSU,
      lc_priorFYInvtyMSU: selectedRowData.lc_priorFYInvtyMSU,
      lc_totalCoverMSU: selectedRowData.lc_totalCoverMSU,
      totalCoverMSU: selectedRowData.totalCoverMSU,
      LBEBOP: selectedRowData.LBEBOP,
      isActive: selectedRowData.isActive,
      createdBy: selectedRowData.createdBy,
      createdDate: new Date().toISOString(),
      _v: 0,
      _id: 'newplan_' + generateGUID(),
    };
    props.modifyConsumePromo(dataToAdd);
    oldData.splice(rowSelected, 1, selectedRowData, dataToAdd);
    setFieldData([...oldData]);
    setRowSelected(rowSelected + 1);
    setSaveButton(true);
    saveBtnDisabled && disableSaveBtn(false);
  };

  const saveButtonHandler = () => {
    let newFieldData = JSON.parse(JSON.stringify(fieldData));
    let isFound = newFieldData.find((item, index) => {
      if (item.brandform.length === 0) {
        setAlertMessage({
          title: 'Consumer Promotions - Mandatory Field',
          p1: 'Brandform field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.month.length === 0) {
        setAlertMessage({
          title: 'Consumer Promotions - Mandatory Field',
          p1: 'Month is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.cluster.length === 0) {
        setAlertMessage({
          title: 'Consumer Promotions - Mandatory Field',
          p1: 'Cluster field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.consumerPromoDescription.length === 0) {
        setAlertMessage({
          title: 'Consumer Promotions - Mandatory Field',
          p1: 'Consumer Promo Description is mandatory',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
      if (item.month.length === 0) {
        setAlertMessage({
          title: 'Month - Mandatory Field',
          p1: 'Month Name field is mandatory for a plan',
          p2: `Please check row number ${index + 1}`,
        });
        handleClickOpen();
        return item;
      }
    });
    if (isFound) {
      return;
    }
    prepareDataForSaving();
  };

  const prepareDataForSaving = () => {
    let _modifiedRecords = Object.values(props.modifiedRecords)
    let dataToSend = JSON.parse(JSON.stringify(_modifiedRecords));
    let updatedFieldData = fieldData.map(i => {
      if (i._id.startsWith('newplan_')) {
        return { ...i, _id: 'Save' + i._id }
      }
      return i
    })
    setFieldData([...updatedFieldData])
    dataToSend.forEach(element => {
      if (element.editable) {
        delete element.editable;
      }
      element.month = element.month.toLowerCase();
      element.dataModelId = props.page.pageLevelDataModel;
      if (element._id.startsWith('newplan_')) {
        delete element._id
      }
      if (element._id) {
        element.consumerPromotionId = element._id;
        delete element._id;
      }
    });
    addNewConsumerPromo({ row: dataToSend, appid: match.params.appid });
    disableSaveBtn(true)
  };

  const fetchData = (brandForm, cluster, month, qtr, currentPage, rowsPerPage, brandObj = '', planName) => {
    let objToSend = {
      appId: match.params.appid, rowsPerPage, page: currentPage,
      brand: !brandObj ? urlPayload.brand ? urlPayload.brand : brand.BrandName : brand.BrandName,
      scenario: props?.sptprData?.selectScenario
    };
    if (month?.length !== 12) {
      objToSend['month'] = month
    }
    if (qtr?.length !== 4) {
      objToSend['quarter'] = qtr
    }
    if (props.sptprData.filterData?.brands.length !== brandForm?.length) { /// brands has brandforms
      objToSend['brandform'] = brandForm
    }
    if (consumerPromotionsData.filterData?.plans.length !== planName?.length) {
      objToSend['planName'] = planName
    }
    if (props.sptprData.filterData?.clusters?.length !== cluster?.length) {
      objToSend['cluster'] = cluster
    }

    if (Object.keys(urlPayload).length === 0) {
      let m = '' + getMonth(brand.CurrentFF, 'monthNumber')
      objToSend.currentFF = m.length === 1 ? '0' + m : m;
      objToSend.fiscalYear = brand.FinancialYear;
    } else {
      let m = urlPayload.currentFF
      objToSend.currentFF = m.length === 1 ? '0' + m : m;
      objToSend.fiscalYear = urlPayload.year;
    }

    getConsumerPromotions(objToSend);
  };

  const fetchPlanFilter = () => {
    let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
    m = m.length === 1 ? '0' + m : m;
    getPlanFilterDataConsumerPromo({
      currentFF: m,
      fiscalYear: brand ? `${brand.FinancialYear}` : '',
      url: 'consumerPromotion',
      brand: brand.BrandName,
      appid: match.params.appid,
      scenario: props?.sptprData?.selectScenario
    });
  }

  useEffect(() => {
    fetchData(brandForm, cluster, month, qtr, currentPage, rowsPerPage, brand, plan);
    setRowSelected('');
    setFieldEdited({});
  }, [brandForm, cluster, month, qtr, currentPage, rowsPerPage, brand, plan, scenario]);

  const downloadButtonHandler = () => {
    if (brand) {
      let uri = API_BASE_URL + `/consumerPromotion/download?brand=${encodeURIComponent(brand.BrandName)}`;
      if (brandForm?.length && props.sptprData.filterData?.brands.length !== brandForm?.length) {/// brands has brandforms
        uri = `${uri}&brandform=${encodeURIComponent(JSON.stringify(brandForm))}`;
      }
      if (cluster?.length && props.sptprData.filterData?.clusters?.length !== cluster?.length) {
        uri = `${uri}&cluster=${encodeURIComponent(JSON.stringify(cluster))}`;
      }
      if (plan?.length && plan?.length !== consumerPromotionsData.filterData?.plans.length) {
        uri = `${uri}${`&consumerPromoDescription=${encodeURIComponent(JSON.stringify(plan))}`}`;
      }
      if (month?.length && month?.length !== 12) {
        uri = `${uri}&month=${encodeURIComponent(JSON.stringify(month))}`;
      }
      if (qtr?.length && qtr?.length !== 4) {
        uri = `${uri}&quarter=${encodeURIComponent(JSON.stringify(qtr))}`;
      }
      if (getMonth(brand.CurrentFF, 'monthNumber')) {
        let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
        m = m.length === 1 ? '0' + m : m;
        uri = `${uri}&currentFF=${encodeURIComponent(m)}`;
      }
      if (brand.FinancialYear) {
        uri = `${uri}&fiscalYear=${encodeURIComponent(brand.FinancialYear)}`;
      }
      let fyFF = getFiscalYearFormat(brand.FinancialYear) + '-' + moment().month(brand.CurrentFF).format('MMM');
      downloadFile(uri, match.params.appid, getFileNameWithTimeStamp('Consumer Promotions' + '-' + brand.BrandName + '-' + fyFF), setDownloadLoading)
    }
  };

  const confirmHandler = () => {
    setConfirm({
      ...confirmState, isOpen: true,
      confirmMessage: `${props.page.name} data update done for  ${getMonth(selectBrand.CurrentFF, 'month')} ${getFiscalYearFormat(selectBrand.FinancialYear)}?`,
    });
  };

  const onConfirmResponse = (resp) => {
    if (resp) {
      finish();
    }
    setConfirm({ isOpen: false, confirmMessage: '' });
  };

  const finish = () => {
    let { BrandName, FinancialYear, CurrentFF } = props.brand;
    let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
    m = m.length === 1 ? '0' + m : m;
    let data = {
      formType: 'spConsumerPromotions',
      brand: BrandName,
      fiscalYear: FinancialYear,
      currentFF: m,
      'action': 'finish',
    };
    let url = `${API_BASE_URL}/setupdata/email/send`;
    axios({
      url: url,
      method: 'POST',
      data: data,
      headers: {
        appId: props.match.params.appid,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        toast.success(response.data?.message || 'Workflow emails sent successfully');
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.message || 'Not able to send workflow emails');
      });
  };

  const renderCell = (row, index, key, type = 'number', rowNames) => {
    let value = row[key];
    return (
      <input
        name={key}
        step={type === 'number' ? "any" : undefined}
        style={{ width: `${value.length}ch`, minWidth: '14ch' }}
        type={type}
        className={classes.textInput}
        onChange={(e) => {
          const { name, value } = e.currentTarget;
          handleFieldChange({ name, value }, index, rowNames);
        }}
        value={type === 'number' ? Number(value) : value}
      />
    );
  };

  const selectOptions = (type) => {
    if (type === 'brandform') {
      return React.Children.toArray(
        props.sptprData.filterData?.brands.map((item, index) =>
          <MenuItem value={item.BrandFormName} key={index}>{item.BrandFormName}</MenuItem>,
        ));
    } else if (type === 'cluster') {
      return React.Children.toArray(
        props.sptprData.filterData?.clusters?.map((item, index) =>
          <MenuItem value={item.ClusterName} key={index}>{item.ClusterName}</MenuItem>,
        ));
    } else if (type === 'month') {
      return React.Children.toArray(
        months.map((item) =>
          <MenuItem value={item.toLowerCase()}>{item}</MenuItem>,
        ));
    }
  };
  let headerOrder = 0;

  const renderSelectCell = (row, index, key, type, rowNames) => {
    const { editable } = row;
    if (editable) {
      return (
        <Select
          name={key}
          value={key === 'month' ? row[key].toLowerCase() : row[key]}
          displayEmpty={true}
          className="custom-select m-right-10"
          style={{ minWidth: '100px' }}
          onChange={(e) => {
            const { name, value } = e.target;
            handleFieldChange({ name, value }, index, rowNames);
          }}
        >
          {
            rowNames && <MenuItem value="">{rowNames}</MenuItem>
          }
          {selectOptions(key)}
        </Select>
      );
    } else {
      return (
        <span className={key === "month" ? classes.capitalize : ''}>{row[key]}</span>
      );
    }
  };

  const renderEditableCell = (row, index, key, rowNames, showPercent = true, id) => {
    if (!readOnly) {
      return (
        <input
          step={"any"}
          name={key}
          type={'number'}
          className={classes.tableInput}
          onChange={(e) => {
            const { name, value } = e.currentTarget;
            handleFieldChange({
              name,
              value: value === '' ? '' : (showPercent ? Number(value) / 100 : Number(value)),
            }, index, rowNames, id);
          }}
          onBlur={(e) => {
            const { name, value } = e.currentTarget;
            handleFieldChange({ name, value: value === "" ? 0 : (showPercent ? Number(value) / 100 : Number(value)) },
              index, rowNames, id)
          }}
          value={showPercent ? row[key] === '' ? '' : (Math.round((Number(row[key]) * 100 + Number.EPSILON) * 100) / 100) : row[key] === '' ? '' : Number(row[key])}
        />
      );
    } else {
      return (
        <p>{showPercent ? Math.round((Number(row[key]) * 100 + Number.EPSILON) * 100) / 100 : Number(row[key])}</p>
      );
    }
  };

  const selectRow = (e, index) => {
    if (rowSelected === index || readOnly) {
      setRowSelected('');
    } else {
      setRowSelected(index);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const onCheckHandler = (row, index) => {
    let newCheckedTpr = JSON.parse(JSON.stringify(checkedConsumerPromo));
    if (!newCheckedTpr.includes(row)) {
      newCheckedTpr.push(row);
    } else {
      newCheckedTpr.splice(newCheckedTpr.indexOf(row), 1);
    }
    setCheckedConsumerPromo(newCheckedTpr);
  };

  const onDeleteConsumerPromo = () => {
    setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: `Are you sure you want to delete the ${(checkedConsumerPromo.length ? 'selected' : 'all')} row(s)?`, });
  };

  const onDeleteResponse = (isDelete) => {
    if (isDelete) {
      let index = checkedConsumerPromo.findIndex(i => i.startsWith('newplan'));
      let { BrandName, FinancialYear, CurrentFF } = props.brand;
      let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
      m = m.length === 1 ? '0' + m : m;
      let commonObj = {
        currentFF: m,
        brand: BrandName,
        fiscalYear: FinancialYear,
      }
      if(scenario) commonObj['scenario'] = scenario;
      if (index !== -1) {
        let newPlanIds = checkedConsumerPromo.filter(i => i.startsWith('newplan'));
        let _fieldData = fieldData.filter(i => !newPlanIds.includes(i._id));
        props.removeModifyConsumePromo(newPlanIds)
        setFieldData(_fieldData);
        if (checkedConsumerPromo.length > newPlanIds.length) {
          let restIds = checkedConsumerPromo.filter(i => !newPlanIds.includes(i));
          deleteCheckedConsumerPromo({ consumerPromotionIds: restIds, appid: match.params.appid, commonObj });
        }
      } else {
        deleteCheckedConsumerPromo({ consumerPromotionIds: checkedConsumerPromo, appid: match.params.appid, commonObj });
      }
    }
    setDeleteConfirm({ ...deleteConfirmState, isOpen: false });
  };

  const calculateTotalCoverMSU = (row) => {
    return Number(row.priorFYInvtyMSU) + Number(row.currentFYCoverMSU);
  };

  const calculateBudget = (row) => {
    if (extraData) {
      const gst = extraData.gst === 0 ? 0.18 : extraData.gst;
      const firmForex = extraData.firmForex;
      return (Number(row.ZMAX) - Number(row.MRP)) / (1 + Number(row.retailMargin)) / (1 + gst) / (1 + extraData.distributorMargin)
        * calculateTotalCoverMSU(row) * Number(row.unitsSU) * 1000 / Number(firmForex) / 1000;
    } else {
      return 0;
    }
  };

  const calculateLBEBOP = (row) => {
    return Number(row.actualsSpend) > 0 ? row.actualsSpend : calculateBudget(row);
  };

  const onAction = (actionType) => {
    switch (actionType) {
      case ActionType.Save:
        saveButtonHandler();
        break;
      case ActionType.NewPlan:
        createNewPromoPress();
        break;
      case ActionType.Duplicate:
        duplicateRow();
        break;
      case ActionType.Delete:
        onDeleteConsumerPromo();
        break;
      case ActionType.Download:
        downloadButtonHandler();
        break;
      case ActionType.DoneUpdates:
        confirmHandler();
        break;
      case ActionType.RemoveRecon:
      case ActionType.Upload:
        fetchData(brandForm, cluster, month, qtr, currentPage, rowsPerPage, brand, plan)
        fetchPlanFilter();
        break;
    }
  };

  const onFilterChange = (filterType, { value }) => {
    switch (filterType) {
      case FilterType.Month:
        setDate(value);
        setMonth(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        setQtr([...new Set(value.map(m => getQuarterBasedOnMonth(m)))]);
        break;
      case FilterType.Qtr:
        setQtr(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
      case FilterType.Cluster:
        setCluster(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
      case FilterType.Brandform:
        setBrandForm(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
      case FilterType.Plan:
        setPlan(value);
        setCurrentPage(0);
        setRowsPerPage(50);
        break;
    }
  };

  const onSelectAll = (event, val) => {
    if (val) {
      let newCheckedItems = fieldData.map(i => `${i._id}`)
      setCheckedConsumerPromo(newCheckedItems);
    } else {
      setCheckedConsumerPromo([]);
    }
  }

  const getTotalValue = (key) => {
    if (consumerPromotionsData.data?.result?.totalProperties && Number(consumerPromotionsData.data?.result?.totalProperties[key])) {
      return consumerPromotionsData.data?.result?.totalProperties[key]
    }
    return ''
  }

  return (
    <div className="spendplan-tpr">
      <PlanHeader
        {...props}
        onAction={onAction}
        onFilterChange={onFilterChange}
        filters={["months", "quarters", "brandForms", "clusters", "plans"]}
        filterValues={{ month, qtr, brandForm, cluster, plan }}
        newPlanDisabled={brandForm?.length > 1 || cluster?.length > 1}
        readOnly={readOnly}
        saveButtonState={saveButtonState}
        downloadLoading1={downloadLoading}
        dataSource={consumerPromotionsData}
        selectBrand={selectBrand}
        rowSelected={rowSelected}
        fieldData={fieldData}
        saveBtnDisabled={saveBtnDisabled}
      />

      {!consumerPromotionsData.loading ? (
        <React.Fragment>
          {/* { fieldData && fieldData.length > 0 ? ( */}
          <React.Fragment>
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table className={classNames(classes.table, 'tpr-table')} aria-label="simple table" stickyHeader>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {(!readOnly && fieldData.length) ?
                      <TableCell data-order={headerOrder++} className="sticky_column">
                        <Checkbox
                          onChange={(e, v) => onSelectAll(e, v)}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          checked={checkedConsumerPromo.length === fieldData.length}
                        />
                      </TableCell> : null}
                    <TableCell data-order={headerOrder++} className="sticky_column">
                      <span>Month</span>
                    </TableCell>
                    <TableCell data-order={headerOrder++} className="sticky_column"><span>Brandform</span></TableCell>
                    <TableCell data-order={headerOrder++} className="sticky_column"><span>Cluster</span></TableCell>
                    <TableCell data-order={headerOrder++} className={classNames("sticky_column", classes.greenFont)}><span>Consumer Promo<br /> Description</span>
                    </TableCell>
                    <TableCell className={classes.greenFont}>
                      <span>Units /SU</span>
                    </TableCell>
                    <TableCell className={classes.greenFont}>
                      <span>Retailer<br />Mgn%</span>
                    </TableCell>
                    <TableCell colSpan="6">
                      <section className={classes.combinedHeaderTotalSection}>
                        <p></p><p></p><p></p><p></p><p></p>
                        <p className={classes.totalValue} title={getTotalValue('lc_BudgetInMillions')}>{convertToNumber(getTotalValue('lc_BudgetInMillions'), 2).toLocaleString()}</p>
                      </section>
                      <p className={classes.combinedHeaderHeadingGrey}>{'Last Cycle'}</p>
                      <section className={classes.combinedHeaderSection}>
                        <p><span>{'ZMAX'}</span></p>
                        <p><span>{'MRP'}</span></p>
                        <p><span><br />Prior FY <br />(Inv'ty) <br />MSU</span></p>
                        <p><span><br />Current FY <br />Cover<br /> (MSU)</span></p>
                        <p><span><br />Total<br /> Cover<br /> (MSU)</span></p>
                        <p><span className={classes.blackFont}>Budget<br />$M</span></p>
                      </section>
                    </TableCell>
                    <TableCell className={classes.greenFont} colSpan="6">
                      <section className={classes.combinedHeaderTotalSection}>
                        <p className={classes.totalValue} />
                        <p className={classes.totalValue} />
                        <p className={classes.totalValue} />
                        <p className={classes.totalValue} />
                        <p className={classes.totalValue} />
                        <p className={classes.totalValue} title={getTotalValue('budget')}>{convertToNumber(getTotalValue('budget'), 2).toLocaleString()}</p>
                      </section>
                      <p className={classes.combinedHeaderHeading}>For BOP</p>
                      <section className={classes.combinedHeaderSection}>
                        <p className={classes.greenFont}><span>ZMAX</span></p>
                        <p className={classes.greenFont}><span>MRP</span></p>
                        <p className={classes.greenFont}><span><br />Prior FY <br />(Inv'ty) <br />MSU</span></p>
                        <p className={classes.greenFont}><span><br />Current FY <br />Cover<br /> (MSU)</span></p>
                        <p><span className={classes.blackFont}><br />Total<br /> Cover<br /> (MSU)</span></p>
                        <p><span className={classes.blackFont}>Budget<br />$M</span></p>
                      </section>
                    </TableCell>
                    <TableCell colSpan="2">
                      <section className={classes.combinedHeaderTotalSection}>
                        <p className={classes.totalValue} title={getTotalValue('actualsSpend')}>{convertToNumber(getTotalValue('actualsSpend'), 2).toLocaleString()}</p>
                        <p className={classes.totalValue} title={getTotalValue('LBEBOP')}>{convertToNumber(getTotalValue('LBEBOP'), 2).toLocaleString()}</p>
                      </section>
                      <p className={classes.combinedHeaderHeading}>Actuals</p>
                      <section className={classes.combinedHeaderSection}>
                        <p className={classes.greenFont}><span>Actuals <br />Spend</span></p>
                        <p><span>LBE<br /> BOP</span></p>
                      </section>
                    </TableCell>
                    <TableCell className={classes.greenFont}>
                      <section className={classes.combinedHeaderTotalSection}>
                        <p className={classes.totalValue} title={getTotalValue('actualShipmentMSU')}>{convertToNumber(getTotalValue('actualShipmentMSU'), 2).toLocaleString()}</p>
                      </section>
                      <section style={{ marginTop: 20 }} className={classes.combinedHeaderSection}>
                        <p><span>Actual <br />Shipment<br /> MSU</span></p>
                      </section>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {fieldData && fieldData.length > 0 ? fieldData.map((row, index) => {
                    let order = 0;
                    let fieldObject = fieldEdited[row._id] ? fieldEdited[row._id] : {};

                    return (
                      <StyledTableRow key={index} onClick={(e) => selectRow(e, index)}
                        className={rowSelected === index ? classes.rowSelectedBg : ''}>
                        {!readOnly ? (
                          <TableCell className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                            id={`sticky_body_column_${index}_${order}`} data-order={order++} data-index={index}>
                            {
                              <Checkbox
                                onChange={() => onCheckHandler(row._id, index)}
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={checkedConsumerPromo.includes(row._id)}
                              />
                            }
                          </TableCell>
                        ) : null}
                        <TableCell className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                          id={`sticky_body_column_${index}_${order}`}
                          data-order={order++} data-index={index}>
                          {renderSelectCell(row, index, 'month', 'text', null)}
                        </TableCell>
                        <TableCell className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                          id={`sticky_body_column_${index}_${order}`}
                          data-order={order++} data-index={index}>
                          {renderSelectCell(row, index, 'brandform', 'text', 'All Brandforms')}
                        </TableCell>
                        <TableCell className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                          id={`sticky_body_column_${index}_${order}`}
                          data-order={order++} data-index={index}>
                          {renderSelectCell(row, index, 'cluster', 'text', 'All Clusters')}
                        </TableCell>
                        <TableCell className={`sticky_body_column ${rowSelected === index ? classes.rowSelectedBg : ''}`}
                          id={`sticky_body_column_${index}_${order}`}
                          data-order={order++} data-index={index}>
                          {renderCell(row, index, 'consumerPromoDescription', '')}
                        </TableCell>
                        <StyledTableCell>
                          {renderEditableCell(row, index, 'unitsSU', 'unitsSU', false, row._id)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {renderEditableCell(row, index, 'retailMargin', 'retailMargin', true, row._id)}
                        </StyledTableCell>
                        <StyledTableCell colSpan="6">
                          <section className={classes.combinedBodySection}>
                            <p>{convertToNumber(row.lc_ZMAX, 2).toLocaleString()}</p>
                            <p>{convertToNumber(row.lc_MRP, 2).toLocaleString('en-In')}</p>
                            <p>{convertToNumber(row.lc_priorFYInvtyMSU, 2).toLocaleString()}</p>
                            <p>{convertToNumber(row.lc_currentFYCoverMSU, 1).toLocaleString()}</p>
                            <p>{convertToNumber(row.lc_totalCoverMSU, 1).toLocaleString()}</p>
                            <p title={convertToNumber(row.lc_BudgetInMillions, 2) + ""}>{convertToNumber(row.lc_BudgetInMillions, 0)}</p>
                          </section>
                        </StyledTableCell>
                        <StyledTableCell colSpan="6">
                          <section className={classes.combinedBodySection}>
                            {renderEditableCell(row, index, 'ZMAX', '', false, row._id)}
                            {renderEditableCell(row, index, 'MRP', '', false, row._id)}
                            {renderEditableCell(row, index, 'priorFYInvtyMSU', '', false, row._id)}
                            {renderEditableCell(row, index, 'currentFYCoverMSU', '', false, row._id)}
                            <p>{convertToNumber(calculateTotalCoverMSU(row) ?? row.totalCoverMSU, 1).toLocaleString()}</p>
                            <p title={convertToNumber(calculateBudget(row) ?? row.BudgetInMillions, 2) + ""}>{convertToNumber(calculateBudget(row) ?? row.BudgetInMillions, 0).toLocaleString()}</p>
                          </section>
                        </StyledTableCell>
                        <StyledTableCell colSpan="2">
                          <section className={classes.combinedBodySection}>
                            {renderEditableCell(row, index, 'actualsSpend', 'actualsSpend', false, row._id)}
                            <p title={convertToNumber(calculateLBEBOP(row) ?? row.LBEBOP, 2)}>{convertToNumber(calculateLBEBOP(row) ?? row.LBEBOP, 0).toLocaleString()}</p>
                          </section>
                        </StyledTableCell>
                        <StyledTableCell>
                          {renderEditableCell(row, index, 'actualShipmentMSU', 'actualShipmentMSU', false, row._id)}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  }) :
                    renderNoDataText(brandForm, [], cluster, month, qtr)
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </React.Fragment>
        </React.Fragment>
      ) : (
        <PlanningLoading />
      )}
      <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} classes={classes} />
      <ConfirmDialog open={deleteConfirmState.isOpen} handleClose={onDeleteResponse}
        alertMessageText={deleteConfirmState.deleteMessage} classes={classes} confirm />
      <ConfirmDialog open={confirmState.isOpen} handleClose={onConfirmResponse}
        alertMessageText={confirmState.confirmMessage} classes={classes} confirm />
      {
        consumerPromotionsData.message &&
        <ConfirmDialog open={true} handleClose={() => props.hideMessage()}
          alertMessageText={{ title: 'Cons - Save', p1: consumerPromotionsData.message }} classes={classes} />
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    consumerPromotionsData: state.ConsumerPromoReducer,
    brand: state.ConsumerPromoReducer?.selectBrand,
    modifiedRecords: state.ConsumerPromoReducer.modifiedRecords,
    sptprData: state.tprReducer,
    scenario: state.tprReducer.selectScenario
  };
};

const mapDispatchToProps = {
  getConsumerPromotions,
  addNewConsumerPromo,
  deleteCheckedConsumerPromo,
  getConsumerPromoExtraData,
  hideMessage,
  removeSuccessMessage,
  getPlanFilterDataConsumerPromo,
  modifyConsumePromo,
  clearModifyConsumePromo,
  removeModifyConsumePromo,
  getClusterfilter,
  getBrandformfilter,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConsumerPromotions)));
