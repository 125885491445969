import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    makeStyles,
    Slide
} from '@material-ui/core';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { capitalize } from '../../../../utility/utils';
import ConfirmDialog from '../../spendplan/SpendPlan/ConfirmDialog';
import { getPast12months } from '../../spendplan/spendplanUtils';
import { months } from '../Sheettable/data';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    paper: { minWidth: 650, height: 380, },
    contents: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000',
    },
    textInput: {
        marginLeft: 20,
        border: '1px solid lightgray',
        width: 50,
        height: 35,
        background: 'transparent',
        outline: 'none',
    },
    heading1: {
        fontSize: '20px !important',
        fontWeight: 500,
        color: '#000',
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            minHeight: '280px',
            width: '600px'
        }
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000'
    },
}));

const ErrorMessages = {
    noForeCast: 'Please select Forecast between 0 to 100.',
    noMonths: 'Please select one or more Reference Months.',
    noForeCastMonth: 'Please select one or more Reference Months and Forecast between 0 to 100.',
}

const ForeCastModal = (props) => {
    let { open, heading, handleClose } = props;

    const classes = useStyles();
    const [selectedMonths, selectMonths] = useState([])
    const [selectedLYMonths, selectLYMonths] = useState([])
    const [forCastVal, setForCastVal] = useState(0)
    const [isOpen, setOpen] = useState(false);

    let [lastFFMonths, currentFFMonths] = getPast12months(props.account.CurrentFF)

    const checkMonth = (month, value) => {
        if (value) {
            selectMonths([...selectedMonths, month])
        } else {
            selectMonths(selectedMonths.filter(m => m !== month))
        }
    }

    const checkLYMonth = (month, value) => {
        if (value) {
            selectLYMonths([...selectedLYMonths, month])
        } else {
            selectLYMonths(selectedLYMonths.filter(m => m !== month))
        }
    }

    const handleConfirmModal = (v) => {
        if (v) {
            let data = {
                actualMonths: currentFFMonths.filter(i => selectedMonths.includes(i)), // if currentFF is sep then jul, aug data will come from actual
                foreCastMonths: lastFFMonths.filter(i => selectedMonths.includes(i)),
                foreCast: Number(forCastVal) / 100,
            }
            
            let dataLY = {
                actualMonths: currentFFMonths.filter(i => selectedLYMonths.includes(i)), // if currentFF is sep then jul, aug data will come from actual
                foreCastMonths: lastFFMonths.filter(i => selectedLYMonths.includes(i)),
                foreCast: Number(forCastVal) / 100,
            }
            handleClose(true, data, dataLY)
        }
        setOpen(false);
    };

    const getMonthYearFormat = (m) => {
        if (getPast12months(props.account.CurrentFF)[0].includes(m)) {
            return capitalize(m) + " " + getYearFormat(m, true)
        }
        return capitalize(m) + " " + getYearFormat(m, false)
    }

    const getMonthYearFormatLY = (m) => {
        return capitalize(m) + " " + getYearFormatLastYear(m)
    }

    const getAlertMessage = () => {
        if ([...selectedMonths, ...selectedLYMonths].length === 0 && !(Number(forCastVal) > 0 && Number(forCastVal) < 100)) {
            return ErrorMessages.noForeCastMonth;
        } else if ([...selectedMonths, ...selectedLYMonths].length === 0) {
            return ErrorMessages.noMonths;
        } else if (!(Number(forCastVal) > 0 && Number(forCastVal) < 100)) {
            return ErrorMessages.noForeCast
        }
        return {
            p1: 'Are you sure you want to apply Value Forecast on ' + props.monthLabel + "?",
            p2: 'Reference Months: ' + selectedLYMonths.map(m => getMonthYearFormatLY(m)).join(', ') +( selectedLYMonths?.length?", ":"") + selectedMonths.map(m => getMonthYearFormat(m)).join(', '),
            p5: `Forecast: ${forCastVal}%`,
        }
    }

    const getYearFormat = (m, isMinusOne) => {
        if (months.indexOf(m) < 6) {
            return ((Number(props.account?.FinancialYear) - 1 + (isMinusOne ? -1 : 0)) + "").slice(2)
        }
        return ((Number(props.account?.FinancialYear) + (isMinusOne ? -1 : 0)) + "").slice(2)
    }

    const renderCheckBox = (m, index, isMinusOne) => {
        return (
            <Grid item xs={2} key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    style={{
                        transform: "scale(0.6)", padding: 0,
                    }} onChange={(e, v) => checkMonth(m, v)}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    checked={selectedMonths.includes(m)}
                />
                <span style={{ whiteSpace: 'nowrap' }}>{capitalize(m) + " " + getYearFormat(m, isMinusOne)}</span>
            </Grid>
        )
    }

    const getYearFormatLastYear = (m) => {
        if (months.indexOf(m) < 6) {
            return (Number(props.account?.FinancialYear) - 2 + "").slice(2)
        }
        return (Number(props.account?.FinancialYear) -1 + "").slice(2)
    }


    const renderCheckBoxLastYear = (m, index) => {
        return (
            <Grid item xs={2} key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    style={{
                        transform: "scale(0.6)", padding: 0,
                    }} onChange={(e, v) => checkLYMonth(m, v)}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    checked={selectedLYMonths.includes(m)}
                />
                <span style={{ whiteSpace: 'nowrap' }}>{capitalize(m) + " " + getYearFormatLastYear(m)}</span>
            </Grid>
        )
    }

    return (<>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            classes={{ paper: classes.paper }}
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: '#047dd8' }}>
                {heading}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component="div" className={classes.contents}>
                    <label className={classes.heading1}>Reference Months:</label>
                    <Grid container>
                        {
                            months.filter(i=> !lastFFMonths.includes(i)).map((m, index) => {
                                return renderCheckBoxLastYear(m, index)
                            })
                        }
                        {
                            lastFFMonths.map((m, index) => {
                                return renderCheckBox(m, index, true)
                            })
                        }
                        {
                            currentFFMonths.map((m, index) => {
                                return renderCheckBox(m, index, false)
                            })
                        }
                    </Grid>
                    <div style={{ marginTop: 20, }}>
                        <label className={classes.heading1}>Forecast (%):</label>
                        <input
                            className={classes.textInput}
                            autoFocus
                            type="number"
                            value={forCastVal}
                            onChange={(e) => setForCastVal(e.target.value)}
                        />
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <React.Fragment>
                    <Button
                        style={{ textTransform: 'none' }}
                        color="primary" onClick={() => setOpen(true)}
                        className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {"OK"}
                    </Button>
                    <Button
                        style={{ textTransform: 'none' }}
                        color="primary" onClick={() => handleClose(false)}
                        className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {"Cancel"}
                    </Button>
                </React.Fragment>
            </DialogActions>
        </Dialog>
        {isOpen && <ConfirmDialog open={true} handleClose={handleConfirmModal}
            alertMessageText={getAlertMessage()}
            classes={classes} confirm={typeof (getAlertMessage()) === 'object' ? confirm : null} />}
    </>);
};

const mapStateToProps = state => {
    return {
        account: state.mrReducer?.account,
    };
};

export default hot(withRouter(connect(mapStateToProps, null)(ForeCastModal)));