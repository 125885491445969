import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Pie } from 'react-chartjs-2';
import { AppService } from 'modules/api/index';
import { border } from 'polished';
import Highcharts from '../HighchartsWrapper'
// import HighchartsCustomEvents from 'highcharts-custom-events';
// HighchartsCustomEvents(Highcharts);

import {
	getPieChartConfig,
	getDefaultPieChart,
	generatePieData
} from 'modules/page/highcharts/pie.module';

import {
	generateGUID
} from 'modules/page/page.module';

import {
	getTheme
} from 'modules/page/component.module';
import { Paper } from '@material-ui/core';
import { colors } from '../customPages/mrplan/MRDashboard2';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isSpendplan = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'spendplan';

const PieChart = (props) => {
	const [options, setOptions] = useState(getDefaultPieChart());
	const [id, setId] = useState(`pie-${generateGUID()}`)

	const highChartsRender = (chartData, props) => {
		let pieConfig = getPieChartConfig(options.series, id, chartData, props);
		if (!isSpendplan) {
			Highcharts.theme = getTheme();
			// Apply the theme
			Highcharts.setOptions(Highcharts.theme);
		}

		Highcharts.chart({ ...pieConfig, colors: colors, });
	}

	const setDatafromApi = (res, data) => {
		let d = { ...data };
		let { chartData } = props;

		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			d.labels = res.labels;
			d.datasets[0].data = res.data;
			d.datasets[0].label = res.label;
		} else {
			d.labels = res.data.labels;
			d.datasets[0].data = res.data.datasets[0].data;
			d.datasets[0].label = res.data.datasets[0].label;
		}
		let series = generatePieData(d, options.series)
		options.series = series;
		setOptions(options);
		highChartsRender(chartData, props);
	}

	const getApiDataFromDataUrl = (url, data) => {
		AppService.getApiDataFromDataUrl(url, props.appId)
			.then(res => {
				setDatafromApi(res, data)
			})
	}

	// useEffect(() => {
	//     setData(props.data);
	//     highChartsRender()
	// }, [props.data]);

	useEffect(() => {
		let { chartData } = props;
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + "/dataconnect/chart/" + chartData.selectedDataModel + "?";
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			// targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
			targetURL += chartData.selectedDataElement.reduce(reducer, '&measure=');
			targetURL += chartData.selectedDataLabels.reduce(reducer, '&label=');
			targetURL += '&metric=' + chartData.metric;
			if (props.queryString) {
				targetURL += '&' + props.queryString;
			}
			//labeltype=date&format=%Y-%m
			if (chartData.datelabeltype) {
				targetURL += '&labeltype=date'
				targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
			}

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (props.page && props.page.conditions && props.page.conditions.length > 0) {
				let cond = JSON.stringify(props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
			let encodedData = encodeURIComponent(targetURL)
			let _targetUrl = JSON.stringify(encodedData);
			getApiDataFromDataUrl(_targetUrl, props.data);
		} else if (props.dataUrl) {
			getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			options.series = generatePieData(props.data, options.series)
			setOptions(options);
			highChartsRender(chartData, props);
		}
	}, [])

	return (
		id && <Paper elevation={3} style={{ margin: 5 }}>
			<div id={id} className="chartEqualHeight"></div></Paper>
	)
};

const mapStateToProps = state => {
	return {
		pages: state.pages.page,
	};
};

export default connect(mapStateToProps, null)(PieChart)