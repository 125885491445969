import React, { Component, useEffect, useState } from 'react';
import {
    TextField,
    FormGroup,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Select,
    FormControl,
    MenuItem,
    Chip,
    Grid,
    Paper,
    Typography,
    Box
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import _ from 'modules/lodash';
import withReducer from 'store/withReducer';

import { AppService } from 'modules/api/index';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import {
    numFormatter
} from 'modules/page/common'

const Results = (props) => {
    let { classes, dataModels } = props;
    const [records, setRecords] = useState(0)
    const [issues, setIssues] = useState(0)
    const [issueTypes, setIssueTypes] = useState(0)
    const [total, setTotal] = useState(0)
    const [dispute, setDispute] = useState(0)


    const getRecords = () => {
        let url = API_BASE_URL + "/dataconnect/" + dataModels[0]._id;
        url += `?type=card&metric=count`
        url += `&field=${dataModels[0].dataElements[0].name}`
        apiSupport(url, setRecords, "count")
    }

    const getIssues = () => {
        let url = API_BASE_URL + "/dataconnect/" + dataModels[dataModels.length - 1]._id;
        url += `?type=card&metric=count`
        url += `&field=${dataModels[dataModels.length - 1].dataElements[0].name}`
        apiSupport(url, setIssues, "count")
    }

    const getIssueTypes = () => {
        let url = `${API_BASE_URL}/dataconnect/bar/${dataModels[dataModels.length - 1]._id}`;
        url += '?barXAxis=result&barYAxis=result&metric=count';
        apiSupport(url, setIssueTypes)
    }

    const getTotal = () => {
        console.log(dataModels[dataModels.length - 1]);
        let url = API_BASE_URL + "/dataconnect/" + dataModels[0]._id;
        url += `?type=card&metric=total`
        url += `&field=TotalPrice`  //TO DO - Need to be dynamic
        apiSupport(url, setTotal, "total")
    }

    const apiSupport = (url, cb, d) => {
        AppService.getApiDataFromDataUrl(url, props.appId)
            .then(res => {
                let v = d ? res[d] : res.data.length
                cb(v)
            })
            .catch(err => console.log(err))
    }

    const getDispute = () => {
        let q = `?type=card&metric=total&field=TotalPrice` //TO DO - Need to be dynamic
        let url1 = API_BASE_URL + "/dataconnect/" + dataModels[0]._id + q;
        let url2 = API_BASE_URL + "/dataconnect/" + dataModels[1]._id + q;
        Promise.all([AppService.getApiDataFromDataUrl(url1, props.appId),
            AppService.getApiDataFromDataUrl(url2, props.appId)])
            .then((values) => {
                console.log("values", values);
                setDispute(values[0].total - values[1].total)
            });
    }

    useEffect(() => {
        getRecords()
        getIssues()
        getIssueTypes()
        getTotal()
        getDispute()
        return () => {
        }
    }, [])

    return (
        <Grid item xs={12} container justify="center">
            <div className={classes.dashlets}>
                <Paper elevation={3} justify="center" className={classes.dashlet}>
                    <Typography variant="h6" style={{ alignSelf: "center" }}>{records}</Typography>
                    <Typography style={{ textAlign: "center" }}>Toatal no of records reconciled</Typography>
                </Paper>
                <Paper elevation={3} justify="center" className={classes.dashlet}>
                    <Typography variant="h6" style={{ alignSelf: "center" }}>{issues}</Typography>
                    <Typography style={{ textAlign: "center" }}>Issues Identified</Typography>
                </Paper>
                <Paper elevation={3} justify="center" className={classes.dashlet}>
                    <Typography variant="h6" style={{ alignSelf: "center" }}>{issueTypes}</Typography>
                    <Typography style={{ textAlign: "center" }}>Issue Types</Typography>
                </Paper>
                <Paper elevation={3} justify="center" className={classes.dashlet}>
                    <Typography variant="h6" style={{ alignSelf: "center" }}>{numFormatter(total)}</Typography>
                    <Typography style={{ textAlign: "center" }}>Total Value</Typography>
                </Paper>
                <Paper elevation={3} justify="center" className={classes.dashlet}>
                    <Typography variant="h6" style={{ alignSelf: "center" }}>{numFormatter(dispute)}</Typography>
                    <Typography style={{ textAlign: "center" }}>Dispute</Typography>
                </Paper>
            </div>
        </Grid>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        // dataModels: state.cappsConfig.capps.data,
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Results)
)