import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Radio, Slide } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiSvgIcon-root': {
            height: '20px !important',
            weight: '20px !important',
        },
        paddingLeft: 0,
    },
    addBaseLineModal: {
        '& .MuiDialog-paperWidthSm': {
            height: 400,
            width: 600,
        },
    },
    title: {
        fontSize: 16,
        fontWeight: '500',
    }
}));

const data = [{ label: 'Scenario 1', value: '01' }, { label: 'Scenario 2', value: '02' }];

const AddScenarioModal = (props) => {
    let { open, handleClose, scenarioArr } = props;
    const [scenarioRadio, setScenarioRadio] = useState('');
    const classes = useStyles();

    const add = (e, ) => {
        props.add(e, scenarioRadio);
        handleClose(true);
    };

    return (<>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes?.addBaseLineModal}
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: '#047dd8' }}>
                {'Add Scenario'}
            </DialogTitle>
            <DialogContent style={{ paddingTop: 15 }}>
                <div style={{ border: '1px solid lightgray', overflowY: 'scroll', height: 150, marginTop: 10 }}>
                    {scenarioArr?.map(i =>
                    (<div key={i?.value} onClick={() => setScenarioRadio(i?.value)}>
                        <Radio
                            className={classes.root}
                            checked={scenarioRadio === i.value}
                            onChange={(e) => {
                                setScenarioRadio(e.target.value);
                            }}
                            value={i.value}
                            color="default"
                            size="small"
                        />
                        <span style={{ fontSize: 18 }}>{i?.label}{i?.newLabel}</span>
                    </div>),
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <>
                    <Button
                        color="primary" onClick={() => {
                            handleClose(true);
                        }}
                        style={{ textTransform: 'none' }}
                        className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {'Cancel'}
                    </Button>
                    <Button
                        disabled={scenarioRadio !== '' ? false :  true}
                        color="primary" onClick={add}
                        style={{ textTransform: 'none' }}
                        className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {'Add'}
                    </Button>
                </>
            </DialogActions>
        </Dialog>
    </>);
};

const mapDispatchToProps = {
    // addReconReviewBaseline,
};

const mapStateToProps = state => {
    return {
        brand: state.tprReducer?.selectBrand,
    };
};
// export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddScenarioModal)));
export default AddScenarioModal;
