import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AppService } from 'modules/api/index';
import { isJSON } from "utility/utils"
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme=>({
	root:{
			'& p':{
				margin:0,
				marginBottom:'13px'
			}
	},
	card: {
		// minHeight: '27.5vh',
		// height: '27.5vh',
		height: '100%',
		backgroundColor: 'rgb(34, 34, 34)',
		color: '#fff'
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 14,
		textAlign: 'center',
		backgroundColor: '#000',
		color: '#fff'
	},
	pos: {
		marginBottom: 12,
	},
}));
let d = {
	title: 'Shrimp and Chorizo Paella',
	subheader: 'September 14, 2016',
	body: 'This is simple body, It can be multiple lines.',
	footer: 'This is footer text',
};
const SimpleCard = props => {
	const [data, setData] = useState(null);
	const [style, setStyle] = useState({});
	const [chartClassName, setChartClassName] = useState("");
	const classes = useStyles();

	const setDatafromApi = (res, data) => {
		let d = { ...data };
		let { chartData } = props;

		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			d.title = chartData.selectedDataElement[0].label;
			d.data = res.data.map(d => d[chartData.selectedDataElement[0].value]);
			d.html = res.html;
		} else {
			d.data = res.data;
			d.title = res.title;
			d.html = res.html;
		}
		setData(d);
	}

	const getApiDataFromDataUrl = (url, data) => {
		AppService.getApiDataFromDataUrl(url)
			.then(res => {
				setDatafromApi(res, data)
			})
	}

	useEffect(() => {
		setData(props.data);
		let s = props.data && isJSON(props.data.style)
			? { ...style, ...(JSON.parse(props.data.style)) } : { ...style };
		let chartClassName = props.data && props.data.className;
		setStyle(s);
		setChartClassName(chartClassName);
	}, [props.data]);

	useEffect(() => {
		let { chartData } = props;
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + "/dataconnect/" + chartData.selectedDataModel;
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
			getApiDataFromDataUrl(targetURL, props.data);
		} else if (props.dataUrl) {
			getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			setData(props.data);
		}
	}, [])
	console.log("data => HTML", data);
	
	return (
		<React.Fragment>
			{data ? (
				<div dangerouslySetInnerHTML={{
					__html: data.html
				}} className={classes.root}/>
				// <Card className={classes.card + ' ' + chartClassName} style={style}>
				// 	<CardHeader className={classes.title} title={data.name} subheader={data.subheader} />
				// 	<CardContent>
				// 		<div dangerouslySetInnerHTML={{
				// 			__html: data.html
				// 		}} />
				// 	</CardContent>
				// </Card>
			) : (
					''
				)}
		</React.Fragment>
	);
};

export default SimpleCard;
