import { hot } from 'react-hot-loader/root';
import React, { Suspense, useEffect, useState, } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Button, CardActions, Grid, Paper, Typography } from '@material-ui/core/';
import { connect } from 'react-redux';
import Chart from 'components/charts/Chart';
import HighChart from 'components/highcharts/Chart';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import NotFound from 'routes/NotFound';

import SimpleCard from 'components/cards/SimpleCard';
import ComplexCard from 'components/cards/ComplexCard';
import SimpleList from 'components/pageComponents/SimpleList';
import Links from 'components/pageComponents/Links';
import Loading from 'components/Loading';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Responsive, WidthProvider } from 'react-grid-layout';
import './styles.css';
import { isJSON } from 'utility/utils';
// IBC
import SupportingDocument from 'components/customPages/ibc/SupportingDocument/index';
import SupportingDocuments from 'components/customPages/ibc/SupportingDocument/SupportingDocuments';
import SupportingDocumentView from 'components/customPages/ibc/SupportingDocument/SupportingDocumentView';
import EmployeDashboard from 'components/customPages/ibc/EmployeDashboard';
import Setup from 'components/customPages/ibc/Setup';
import ExtractionResult from 'components/customPages/ibc/ExtractionResult';
import MyWorkSpace from 'components/customPages/ibc/MyWorkSpace';
import BillingDashboard from 'components/customPages/ibc/BillingDashboard';
import Reconciliation from 'components/customPages/ibc/Reconciliation';
import MemberRecon from 'components/customPages/ibc/Memberrecon';
import UploadFile from 'components/customPages/ibc/UploadFile';
import BillingRepDashboard from 'components/customPages/ibc/BillingRepDashboard';
// Spendplan

import FilterDrawer from './FilterDrawer';
import Divider from '@material-ui/core/Divider';
import { SpendPlan } from './Apps/spendplan';
import { MR_TITLE, NoAccountBrandMsg, TSP_TITLE } from '../customPages/spendplan/spendplanUtils';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';
const isSpendplan = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'spendplan';

let card = isIBC
	? {
		backgroundColor: '#fff',
		color: '#000',
	}
	: {
		backgroundColor: '#fff',
		color: '#181818',
		// backgroundColor: '#222222'
	}
const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		// backgroundColor: '#060606',
		backgroundColor: '#181818',
		color: '#fff',
		height: '100vh'
	},
	card: card,
	AppHeading: {
		fontWeight: 'bold',
		fontSize: '30px',
		padding: '10px'
	}
}));

const colCard = 4;

let initialLayout = [], defaultBreakpoint = "lg", mount = false, compact = "vertical";
let layoutProps = {
	className: "layout",
	rowHeight: 30,
	onLayoutChange: function () { },
	cols: { lg: 12, md: 12, sm: 12, xs: 4, xxs: 2 },
};
const LayoutBox = props => {
	const [breakpoint, setBreakpoint] = useState(defaultBreakpoint);
	const [mounted, setMounted] = useState(mount);
	const [compactType, setCompactType] = useState(compact);
	const [state, setState] = useState({ isFilterOn: false });
	const [pageState, setPageState] = useState({ page: props.page });
	const [brand, setBrand] = useState("")
	const [account, setAccount] = useState("")
	const matClasses = useStyles();
	const { tprReducer } = props;
	const { selectBrand, brandEmpty, accountEmpty } = tprReducer;

	useEffect(() => {
		setMounted(true);
		return () => {
			console.log("on return useeffect");
		}
	}, []);

	useEffect(() => {
		if (selectBrand && typeof selectBrand === 'object' && selectBrand !== null && Object.keys(selectBrand).length > 0) {
			setBrand(selectBrand);
		}
	}, [props.tprReducer.selectBrand])

	useEffect(() => {
		if (props.account?.AccountName) {
			setAccount(props.account?.AccountName);
		}
	}, [props.account])

	useEffect(() => {
		setPageState({ page: props.page })
	}, [props.page])

	useEffect(() => {
		setCompactType(props.compactType);
	}, [props.compactType])

	const onBreakpointChange = breakpoint => {
		setBreakpoint(breakpoint);
	};

	const onLayoutChange = (layout, layouts) => {
		console.log("onLayoutChange");
	}

	const onResize = (layout, oldItem, newItem, placeholder, e, element) => {
		console.log("onResize");
	};

	const onDrop = elemParams => {
		console.log("onDrop");
	};

	const onFilterChange = event => {
		let newConditionsArray = JSON.parse(JSON.stringify(pageState.page.conditions))
		let newConditions = {
			selectedConditionClause: "and",
			selectedConditionDataElement: event.target.name,
			selectedConditionOperator: isNaN(event.target.value) ? "matches" : "is_equal_to",
			value: event.target.value
		}

		let isPresentIndex = newConditionsArray.findIndex(item => item.selectedConditionDataElement === event.target.name);
		isPresentIndex >= 0 ? newConditionsArray[isPresentIndex] = newConditions : newConditionsArray.push(newConditions)
		setPageState({ ...pageState, page: { ...pageState.page, conditions: newConditionsArray } })
	}

	const onFilterDateChange = event => {
		let newConditionsArray = JSON.parse(JSON.stringify(pageState.page.conditions));
		let isPresent = newConditionsArray.find(
			item => item.selectedConditionDataElement === event.target.name,
		);
		if (isPresent) {
			if (event.target.id === 'from') {
				if (new Date(event.target.value) > new Date(isPresent.value?.to)) {
					alert("wrong date range");
					return;
				}
			}
			else if (event.target.id === 'to') {
				if (new Date(isPresent.value.from) > new Date(event.target.value)) {
					alert("wrong date range");
					return;
				}
			}
			let isPresentIndex = newConditionsArray.findIndex(item => item.selectedConditionDataElement === event.target.name);
			isPresent.value = { ...isPresent.value, [event.target.id]: event.target.value };
			if (isPresentIndex >= 0) { newConditionsArray[isPresentIndex] = isPresent }
		} else {
			let newConditions = {
				selectedConditionClause: 'and',
				selectedConditionDataElement: event.target.name,
				selectedConditionOperator: 'in_between',
				value: { [event.target.id]: event.target.value },
			};
			newConditionsArray.push(newConditions);
		}

		setPageState({ ...pageState, page: { ...pageState.page, conditions: newConditionsArray } });
	};


	let allChart = null, layouts = { lg: initialLayout };
	let page = props.page && JSON.parse(JSON.stringify(props.page));
	const classes = useStyles();
	let s = "info";
	allChart = page &&
		page.chart &&
		page.chart.length > 0 &&
		page.chart.map((cData, i) => {
			let s = cData.chartData && isJSON(cData.chartData.style)
				&& { ...(JSON.parse(cData.chartData.style)) };
			let chartClassName = cData.chartData && cData.chartData.className
				? cData.chartData.className : "";
			return (
				<div key={i} id="PageDesignerCard1-wrapper" style={{
					// backgroundColor: "#181818", color: '#fff'
				}}>
					{(function () {
						switch (cData.chartType) {
							case 'pie':
							case 'mixed':
							case 'doughnut':
							case 'bar':
							case 'stackedbar':
							case 'line':
							case 'horizontalBar':
							case 'bullet':
								return (<Card className={classes.card + " " + chartClassName}
									style={{ height: "inherit", ...s }} >
									{cData.chartData.name ? <CardHeader className={isIBC ? `card-header-title` : ''} title={cData.chartData.name} /> : ''}
									{process.env.REACT_APP_HIGHCHARTS === "true"
										? <>
											<CardContent className={classes.cardContent + "graph--card--content"} >
												<HighChart page={props.page} key={cData.chartUniqueId}
													data={cData}
													queryString={props.queryString} appId={props.appId} />
											</CardContent>
										</>
										: <>
											<CardContent className={classes.cardContent + "graph--card--content"} >
												<Chart page={props.page} key={cData.chartUniqueId}
													data={cData}
													queryString={props.queryString} appId={props.appId} />
											</CardContent>
										</>
									}
								</Card>)
							case 'list':
							case 'table':
							case 'simpletable':
							case 'summaryTable':
							case 'card':
							case 'html':
							case 'org':
								return <Chart page={pageState.page} key={cData.chartUniqueId} data={cData} queryString={props.queryString} appId={props.appId} />
							case 'simplecard':
								return <SimpleCard page={props.page} key={cData.chartUniqueId} data={cData.data} queryString={props.queryString} appId={props.appId} />
							case 'complexcard':
								return <ComplexCard page={props.page} key={cData.chartUniqueId} data={cData.data} queryString={props.queryString} appId={props.appId} />
							case 'links':
								return <Links page={props.page} key={cData.chartUniqueId} chartData={cData} queryString={props.queryString} appId={props.appId} />
							case 'simplelist':
								return <SimpleList page={props.page} key={cData.chartUniqueId} chartData={cData} queryString={props.queryString} appId={props.appId} />
							default:
								return null;
						}
					})()}
				</div>
			);
		});
	let y = 0
	let lg = page && page.chart &&
		page.chart.length > 0 ?
		page.chart.map((element, i) => {
			y = y > element.chartData.layout.y ? y : element.chartData.layout.y + element.chartData.layout.h
			return {
				...element.chartData.layout,
				'i': "" + i,
				"moved": false,
				"static": true
			}
		}) : [];

	if (lg.length === 0) {
		lg = [{
			'x': 0, 'y': 0, w: 12, h: 2,
			'i': "enforce", "moved": false, "static": true
		}];
		allChart = <div key="enforce" align="center"
			style={{ "backgroundColor": "transparent", "borderRadius": "0px" }}>
			{"There is no layout to load"}<br />
		</div>
	} else {
		lg.push({
			'x': 0, 'y': y, w: 12, h: 1,
			'i': "enforce", "moved": false, "static": true
		})
		allChart.push(<div key="enforce" align="center" className="empty-space react-grid-placeholder">
			<div style={{ width: "100%", height: "100%", backgroundColor: "transparent !important", "borderRadius": "0px !important" }}></div></div>)
	}

	layouts = { lg };

	let customComponent
	let access;
	if (page && page.isCustom) {
		let allRoles = props.appConfig?.roles;
		let currentUserRoles = props.appConfig?.member?.selectedRoles;
		if (allRoles && allRoles.length) {
			if (currentUserRoles && currentUserRoles.length) {
				for (let i = 0; i < allRoles.length; i++) {
					for (let j = 0; j < currentUserRoles.length; j++) {
						if (allRoles[i]._id === currentUserRoles[j].value) {
							if (allRoles[i].privileges && allRoles[i].privileges.length) {
								for (let k = 0; k < allRoles[i].privileges.length; k++) {
									let allPriv = allRoles[i].privileges[k];
									let allPrivSplit = allPriv.split(':');
									let allPages = allPrivSplit[1];
									let currPage = allPages.split('.')[1];
									if (page.name === currPage) {
										access = allPrivSplit[2];
									}
								}
							}
						}
					}
				}
			}
		} 
		if (isSpendplan) {
			customComponent = <>
				<Suspense fallback={<Loading />}>
					<SpendPlan {...props} page={page} pages={props.pages} access={access}/>
				</Suspense>
			</>
		} else {
			if (page.name === "ibcSupportingDocument") {
				customComponent = <><SupportingDocument page={page} /></>
			} else if (page.name === 'ibcSupportingDocuments') {
				customComponent = <><SupportingDocuments page={page} /></>
			} else if (page.name === 'ibcSupportingDocumentView') {
				if (!props.match.params.pageDocumentId) {
					alert('missing supporting document id')
					props.history.push(`/apps/${props.match.params.appid}/${props.match.params.page}`)
				}
				customComponent = <><SupportingDocumentView page={page} /></>
			} else if (page.name === 'ibcDashboard') { 
				customComponent = <><EmployeDashboard page={page} /></>
			} else if (page.name === 'ibcSetup') {
				customComponent = <><Setup page={page} /></>
			} else if (page.name === 'ibcSupportDocExtraction') {
				customComponent = <><ExtractionResult page={page} /></>
			} else if (page.name === 'ibcMyWorkspace') {
				customComponent = <><MyWorkSpace page={page} /></>
			} else if (page.name === 'ibcBillingDashboard') {
				customComponent = <><BillingDashboard page={page} /></>
			} else if (page.name === 'ibcReconciliation') {
				customComponent = <><Reconciliation page={page} /></>
			} else if (page.name === 'ibcMemberReconciliation') {
				customComponent = <><MemberRecon page={page} /></>
			} else if (page.name === 'ibcUploadPDF') {
				customComponent = <><UploadFile page={page} /></>
			} else if (page.name === 'ibcBillingRepDashboard') {
				customComponent = <><BillingRepDashboard page={page} /></>
			}
			else {
				//Default cusomt layout
				lg = [{
					'x': 0, 'y': 0, w: 12, h: 2,
					'i': "enforce", "moved": false, "static": true
				}];
				allChart = <div key="enforce" align="center"
					style={{ "backgroundColor": "transparent", "borderRadius": "0px" }}>
					{"This is reserved for a custom page, please create a link a cusotm page"}<br />
				</div>
			}
		}
		return customComponent;
	}

	const onFilterClick = () => {
		setState({ ...state, isFilterOn: !state.isFilterOn })
	}

	if(props.loading){
		return  <Loading />;
	}

	return (	
		<React.Fragment>
			{!isSpendplan
				|| (brand && !brandEmpty) || (account && !accountEmpty)
				? page && page.isCustom && customComponent
					? <div className="custom-pages"> {customComponent} </div>
					: <div
						id="componentViewPaneElement"
						style={{
							width: "100%", minHeight: "100vh", height: "100%", overflowY: "scroll"
						}}>
						{props.page?.isDisplayHeading
							? <>
								<div className={matClasses.AppHeading}>
									{props.page?.title ?? props.page?.name}
								</div>
								<Divider />
							</>
							: null 
						}
						{props.page?.selectedFilterDataElements?.length
							? <div className='mainFilters'>
								{props.page?.filterPosition === "top"
									? null
									: <div style={{ textAlign: props.page?.filterPosition ?? 'right' }}>
										<FilterListIcon onClick={onFilterClick} />
									</div>
								}
								<FilterDrawer isFilterOn={state.isFilterOn} onFilterClick={onFilterClick} page={page}
									onFilterChange={onFilterChange}
									onFilterDateChange={onFilterDateChange}
									filterPosition={props.page?.filterPosition ?? "right"}
								/>
								{/* <FilterTop
							 onFilterClick={onFilterClick} page={page} onFilterChange={onFilterChange}
							 onFilterDateChange={onFilterDateChange}/> */}
							</div>
							: null
						}
						{allChart && <ResponsiveReactGridLayout
							{...props}
							{...layoutProps}
							layouts={layouts}
							onBreakpointChange={onBreakpointChange}
							onLayoutChange={onLayoutChange}
							onResize={onResize}
							onDrop={onDrop}
							// WidthProvider option
							measureBeforeMount={false}
							// I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
							// and set `measureBeforeMount={true}`.
							useCSSTransforms={mounted}
							compactType={compactType}
							preventCollision={!compactType}
							isDroppable={false}>
							{allChart}
						</ResponsiveReactGridLayout>}
					</div> 
					: ((props.appConfig.app.title === TSP_TITLE && brandEmpty) || (props.appConfig.app.title === MR_TITLE && accountEmpty))
					? <div className={"app--dashboard--404--page"}><NotFound overrideText={NoAccountBrandMsg} /></div>
					: <Loading />}
		</React.Fragment>
	);
};

const mapDispatchToProps = {
};

const mapStateToProps = (state) => {
	return {
		tprReducer: state.tprReducer,
		account: state.mrReducer?.account,
		app: state.app,
		appConfig: state.appConfig,
		sptprData: state.tprReducer,
	}
}

export default hot(
	withRouter(
		connect(
			mapStateToProps,
			mapDispatchToProps,
		)(LayoutBox)),
);
