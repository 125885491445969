import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import React, { Component } from 'react';
import axios from 'axios';
import _ from 'modules/lodash'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = theme => ({
    head: {
        backgroundColor: "lightgrey",
        color: theme.palette.common.white,
    },
    customFont: {
        "font-size": "large",
        color: '#fff'
    }
});

class DataTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            header: [],
            modalData: {}
        }
    }

    getData = () => {
        const { dataElement, dataModel, itemPosition, selectedForm, filledData } = this.props

        console.log('selectedForm', this.props.selectedForm);
        console.log('dataElement, dataModel', dataElement, dataModel);
        console.log('itemPosition ===', itemPosition);
        console.log('this.props.filledData', this.props.filledData);
        if (selectedForm && selectedForm.modalData && selectedForm.modalData.rows
            && selectedForm.modalData.rows[itemPosition.split('-')[0]]
            && selectedForm.modalData.rows[itemPosition.split('-')[0]].columns
            && selectedForm.modalData.rows[itemPosition.split('-')[0]].columns[itemPosition.split('-')[1]]) {
            this.state.modalData = selectedForm.modalData.rows[itemPosition.split('-')[0]].columns[itemPosition.split('-')[1]]
        }
        console.log('this.state.modalData', this.state.modalData);
        try {
            let invalid = this.state.modalData.data.data.data.requiredFormElement && this.state.modalData.data.data.data.requiredFormElement.find(rfe => {
                return !filledData[rfe]
            })
            if (!invalid) {
                let conds = this.state.modalData.data.data.data.requiredFormElement.map(rfe => {
                    return {
                        selectedConditionDataElement: rfe,
                        selectedConditionOperator: 'matches',
                        value: filledData[rfe],
                        selectedConditionClause: "and"
                    }
                })
                this.getDataModel(`${API_BASE_URL}/dataconnect/table/${dataModel}?type=list&fields=${dataElement},&conditions=${JSON.stringify(conds)}`);
            }
            else
                console.log('Mandatory fields or empty "' + invalid + '"');

        } catch (error) {
            console.log('error', error)
        }
    }
    componentDidMount() {
        // ExternalScripts["testPopulate"]();
        this.getData();
    }
    componentDidUpdate(prevProps) {
        console.log('this.state.modalData.data.data.data', this.state.modalData.data.data.data);
        let parentFormElement = this.state.modalData.data.data.data.parentFormElement
        console.log('prevProps.filledData[parentFormElement], this.props.filledData[parentFormElement]', prevProps.filledData[parentFormElement], this.props.filledData[parentFormElement]);
        if (!_.isEqual(prevProps.filledData, this.props.filledData)
            && prevProps.filledData[parentFormElement] !== this.props.filledData[parentFormElement]) {
            console.log('componentDidUpdate');
            this.getData()
            // this.getDataModel(`${API_BASE_URL}/dataconnect/${dataModel}?q=${dataElement},&conditions=${conds}`);
        }
    }

    getDataModel = (url) => {
        axios.get(url, { headers: { appId: this.props.match.params.appid, 'Content-Type': 'application/json', } })
            .then(res => {
                let rows = res.data.data;
                let header = [];
                if (rows && rows.length) {
                    let row = rows[0]
                    let keys = Object.keys(row)
                    header = keys
                }
                this.setState({ header, data: rows }, () => console.log("========data", this.state))
            }).catch((err) => {
                alert("Unable to fetch data from the provided endpoint" + err);
            })
    }
    render() {
        const { dataModel, dataElement, classes } = this.props;
        return (
            <>
                {this.state.header.length === 0 && "Please select Data model and data elements."}
                {this.state.header.data === 0 && "No data available to display."}
                <Table className={classNames(classes.table)} size="small" aria-label="a dense table dt">
                    <TableHead className={classNames(classes.head)}>
                        <TableRow>
                            {this.state.header && this.state.header.map((item, i) => {
                                return (<TableCell key={i} className={classNames(classes.customFont)}>{item}</TableCell>);
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.data.map((row, index) => {
                            let values = Object.values(row);
                            return (
                                <TableRow key={index}>
                                    {values.map((rowdata, j) => {
                                        return (<TableCell key={j} className={classNames(classes.customFont)}>
                                            {rowdata}</TableCell>)
                                    })}
                                </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </>
        )
    };
}

const mapDispatchToProps = {
};


const mapStateToProps = state => {
    return {
        selectedForm: state.form.selectedForm
    }
}

export default hot(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(DataTable))))