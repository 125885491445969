import { ActionTypes } from 'constants/index';

const initialState = {
    isAuthenticated: false
};

const user = function (state = initialState, action) {
    switch ( action.type )
    {
        case ActionTypes.SET_USER_DATA:
        {
            return {
                ...initialState,
                ...action.payload
            };
        }
        case ActionTypes.REMOVE_USER_DATA:
        {
            return {
                ...initialState
            };
        }
        case ActionTypes.USER_LOGGED_OUT:
        {
            return initialState;
        }
        default:
        {
            return state
        }
    }
};

export default user;
