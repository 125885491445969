

import { hot } from 'react-hot-loader/root'
import React, {useState, useEffect} from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
	Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid, Slide,
	Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
	DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ReplaceValues = (props) => {
	let { open, handleClose, alertMessageText, classes, confirm, type } = props
    const [allFieldValues, setAllFieldValues] = useState([]);
    const [fieldValue, setFieldValue] = useState('');
    const [oldValue, setOldValue] = useState('');
    const [newValue, setNewValue] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        setAllFieldValues([
            {label: 'Legal Entity', value: 'legalEntity'},
            {label: 'Category', value: 'category'},
            {label: 'Brand', value: 'brand'},
            {label: 'TS Brand', value: 'tsBrand'},
            {label: 'Brandform', value: 'brandform'},
            {label: 'TS SKU', value: 'tsSku'},
            {label: 'Sub Brandform', value: 'subBrandForm'},
        ])
    }, [])

    const onEventChange = (event, val) => {
        switch (event) {
            case 'field': {
                setFieldValue(val.value);
                break;
            }
            case 'oldValue': {
                setOldValue(val.value);
                break;
            }
            case 'newValue': {
                setNewValue(val.value);
                break;
            }
        }
    }

    const closePopup = (val) => {
        if (val) {
            setFieldValue('');
            setOldValue('');
            setNewValue('');
            setMessage('');
            handleClose(true)
        } else {
            if (fieldValue === '' && oldValue === '' && newValue === '') {
                setMessage('Please add all the fields');
            } else {
                setMessage('');
                handleClose(false, {fieldValue, oldValue, newValue})
            }
        }
    }

	return (<>
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			className={classes.confirmationDialoge}
		>
			<DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#047dd8" }}>
				{typeof alertMessageText == 'object' && alertMessageText?.title ? alertMessageText.title : "Confirmation"}
			</DialogTitle>
			<DialogContent className={classes.dialog}>
				<DialogContentText component="div" id="alert-dialog-slide-description" className={classes.para}>
					<div>
                        <span>Field: </span><span>
                        <Select
                            value={fieldValue}
                            className="custom-select m-right-10"
                            style={{ minWidth: "150px" }}
                            onChange={e => onEventChange('field', e.target)}>
                            {React.Children.toArray(
                                allFieldValues?.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
                            )}
                        </Select>
                        </span>
                    </div>
                    <div>
                        <span>Old Value: </span>
                        <span>
                            <input type="text" name="oldVal" placeholder="Enter value" class="form-control" value={oldValue}  onChange={e => onEventChange('oldValue', e.target)}/>
                        </span>
                    </div>
                    <div>
                        <span>New Value: </span>
                        <span>
                            <input type="text" name="newVal" placeholder="Enter value" class="form-control" value={newValue}  onChange={e => onEventChange('newValue', e.target)}/>
                        </span>
                    </div>
                    {message !== '' && <div style={{margin: '10px', fontSize: '11px', color: 'red'}}>
                        {message}
                    </div>}
				</DialogContentText>
			</DialogContent>
            <DialogActions>
                {
                    <React.Fragment>
                        <Button
                            style={{ textTransform: 'none' }}
                            color="primary" onClick={() => {
                                closePopup(false)
                            }}
                            className="btn btn-primary btn-color border-radius-0 text-color-white">
                            {"Replace"}
                        </Button>
                        <Button
                            style={{ textTransform: 'none' }}
                            color="primary" onClick={() => closePopup(true)}
                            className="btn btn-primary btn-color border-radius-0 text-color-white">
                            {"Cancel"}
                        </Button>
                    </React.Fragment>
                }
            </DialogActions>
		</Dialog>

	</>)
}

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(connect(mapStateToProps, null)(ReplaceValues)));