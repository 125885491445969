import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMonth } from "utility/utils";
import { clearError, getForCorpData } from '../../../../../store/actions';
import { capitalize } from '../../../../../utility/utils';
import Loader from '../../../../Loader';
import { convertToNumber } from '../../PlanningPages/planningHelperFunctions';
import ConfirmDialog from '../../SpendPlan/ConfirmDialog';
import { getCurrentFFForPayload, setStickyVertical } from '../../spendplanUtils';
import { StyledTableCell, StyledTableRow, useStyles } from '../talkSheet/talkSheetTable';

const ForCorpReportTable = (props) => {
    const { getForCorpData, match, tsData, brandData, modalData, scenario } = props;
    const classes = useStyles(),
        [tsFieldData, setTsData] = useState([]);
    const [_brand, setBrand] = useState(brandData);
    const [total, setTotal] = useState(null);

    const fetchData = () => {
        let { FinancialYear, CurrentFF } = brandData;
        let m = getMonth(CurrentFF, 'monthNumber');
        let currentMonth = getCurrentFFForPayload(m)
        const modalData = props.modalData;
        let version = false;
        if (modalData && modalData.brand && modalData.month && modalData.year) {
            FinancialYear = modalData.year;
            currentMonth = getCurrentFFForPayload(modalData.month);
            version = true
        }
        let data = {
            appId: props.match.params.appid,
            url: `/${FinancialYear}/${currentMonth}/${scenario}`,
        };
        if (version) {
            data.url += '?version=true'
        }
        props.getForCorpData(data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [scenario]);

    useEffect(() => {
        setStickyVertical(true);
    })

    useEffect(() => {
        if (_brand !== brandData) {
            setBrand(brandData)
            fetchData();
        }
    }, [brandData]);

    useEffect(() => {
        if (tsData?.forCorpData?.result?.data) {
            let _data = tsData?.forCorpData?.result?.data;
            if (modalData) {
                setTsData(_data);
                setTotal(tsData?.forCorpData?.result?.sum)
            } else {
                setTsData(_data);
                setTotal(tsData?.forCorpData?.result?.sum)
            }
        } else if (tsFieldData.length) {
            setTsData([])
        }
    }, [tsData]);

    const renderNoDataText = () => {
        let textToShow = "No Data Found";
        return (
            <StyledTableRow>
                <StyledTableCell colSpan="100%">
                    <h4 style={{ color: '#000' }}>{textToShow}</h4>
                </StyledTableCell>
            </StyledTableRow>
        );
    }

    if (tsData.loading) {
        return (
            <section className={classes.tableBodyLoading}>
                <Loader />
            </section>
        )
    }

    const { error } = tsData;

    const getColumns = () => {
        return (tsFieldData && tsFieldData[0] && tsFieldData[0].bop && Object.keys(tsFieldData[0].bop)) || []
    }

    return (
        <React.Fragment>
            <TableContainer className={classNames(classes.tableContainer, "tpr-container")} component={Paper}>
                <Table className={classNames(classes.table, "tpr-table")} aria-label="simple table" stickyHeader>
                    <TableHead className={classes.tableHead}>
                        <TableRow className='sticky-row'>
                            <TableCell rowSpan={3}>
                                <span>Channel</span>
                            </TableCell>
                            <TableCell rowSpan={3}>
                                <span>Month</span>
                            </TableCell>
                            <TableCell rowSpan={3}>
                                <span>Qtr</span>
                            </TableCell>
                            {
                                getColumns().map((ii, index) => {
                                    return (
                                        <TableCell key={index}> {total && <span title={total[ii]}>{convertToNumber(total[ii], 2).toLocaleString()}</span>}</TableCell>)
                                })
                            }
                        </TableRow>
                        <TableRow className='sticky-row'>
                            <TableCell colSpan={getColumns().length}>
                                <p className={classes.combinedHeaderHeading} style={{margin: 0,}}>RLP Cr BOP</p>
                            </TableCell>
                        </TableRow>
                        <TableRow className='sticky-row'>
                            {
                                getColumns().map((ii, index) => {
                                    return (
                                        <TableCell key={index}> <span>{ii}</span></TableCell>)
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {tsFieldData && tsFieldData.length > 0 ? tsFieldData.map((row, index) => {
                            let isDarkBG = row.Months === 'FY';
                            return (
                                <StyledTableRow key={index} className={isDarkBG ? classes.darkBG : ''}>
                                    <StyledTableCell className={classes.alignLeft}>
                                        <span>{row.channel}</span>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.alignLeft}>
                                        <span>{capitalize(row.Months)}</span>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.alignLeft}>
                                        <span>{row.Qtr}</span>
                                    </StyledTableCell>
                                    {
                                        Object.keys(row.bop).map((ii, index) => {
                                            return (<StyledTableCell key={index}>
                                                <span title={row.bop[ii]}>{convertToNumber(row.bop[ii], 1).toLocaleString()}</span>
                                            </StyledTableCell>)
                                        })
                                    }
                                </StyledTableRow>)
                        }) :
                            renderNoDataText()
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                error && <ConfirmDialog open={error !== null} handleClose={props.clearError} alertMessageText={{
                    title: 'Error Loading Page',
                    p2: 'There was an error loading this content. Please reload the page or try again later.', p4: error
                }} classes={classes} />
            }
        </React.Fragment >
    )
}


const mapStateToProps = state => {
    return {
        tsData: state.talkSheetReducer,
        scenario: state.tprReducer?.selectScenario
    };
};

const mapDispatchToProps = {
    getForCorpData,
    clearError,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps,)(ForCorpReportTable)),);
