import React from "react";
import BOPVolumes from '../../customPages/spendplan/SetupDataPages/BOPVolumes';
import RLPperMSU from '../../customPages/spendplan/SetupDataPages/RLPperMSU';
import PYRetailing from '../../customPages/spendplan/SetupDataPages/PYRetailing';
import PYRLPMSU from '../../customPages/spendplan/SetupDataPages/PYRLPMSU';
import ChannelRetailingSalience from '../../customPages/spendplan/SetupDataPages/ChannelRetailingSalience';
import YTDActualRetailing from '../../customPages/spendplan/SetupDataPages/YTDActualRetailing';
import TradePriceReduction from '../../customPages/spendplan/PlanningPages/tpr';
import ArchiveReport from '../../customPages/spendplan/ArchiveReport';
import Dashboard from '../../customPages/spendplan/Dashboard';
import MSPDashboard from '../../customPages/spendplan/MS&PDashboard';
import FinanceDashboard from '../../customPages/spendplan/FinanceDashboard';
import Archives from '../../customPages/spendplan/Archives';
import ConsumerPromotions from '../../customPages/spendplan/PlanningPages/ConsumerPromotions';
import TrialsDiscounts from '../../customPages/spendplan/PlanningPages/TrialsDiscounts';
import Displays from '../../customPages/spendplan/PlanningPages/Displays';
import ConversionFunds from '../../customPages/spendplan/PlanningPages/ConversionFunds';
import MrAndEcommTFIs from '../../customPages/spendplan/PlanningPages/MrAndEcommTFIs';
import CorpBud from '../../customPages/spendplan/SetupDataPages/CorpBud';
import MREcomm from '../../customPages/spendplan/SetupDataPages/MREcomm';
import AccrualsHC from '../../customPages/spendplan/SetupDataPages/AccrualsHC';
import F1Inputs from '../../customPages/spendplan/SetupDataPages/F1Inputs';
import ITT from '../../customPages/spendplan/SetupDataPages/ITT';
import TDC from '../../customPages/spendplan/SetupDataPages/TDC';
import CTS from '../../customPages/spendplan/SetupDataPages/CTS';
import SKUMaster from '../../customPages/mrplan/skuMaster/index'
import ITTMaster from '../../customPages/mrplan/ittMaster/index'
import SKUMapping from '../../customPages/mrplan/skuMapping/index'
import FYActuals from "../../customPages/mrplan/FYActuals";
import PYSKULevel from "../../customPages/mrplan/PYSKULevel";
import MRPlanning from "../../customPages/mrplan/planingPages/";
import MRDashboard2 from "../../customPages/mrplan/MRDashboard2";
import SpendsReviewSummary from "../../customPages/mrplan/MRDashboard2/spendsReviewSummary";
import RetailingSummary from "../../customPages/mrplan/MRDashboard2/retailingSummary";
import VSOD from "../../customPages/mrplan/MRDashboard2/vsod";
import BrandMix from "../../customPages/mrplan/MRDashboard2/brandMix";
import SKUAnlysis from "../../customPages/mrplan/MRDashboard2/skuAnalysis";
import FyActualspff from "../../customPages/mrplan/FyActualspff";
import FCMPlanning from "../../customPages/mrplan/ReportPages/FCM Planning";
import RLPActFcst from "../../customPages/spendplan/PlanningPages/RLPActFcst";
import CustomerViewReport from "../../customPages/mrplan/ReportPages/CustomerView"
import OmniSKUMaster from "../../customPages/omnichannel/omniskuMaster";
import OmniITTMaster from "../../customPages/omnichannel/ITTMaster";
import PidMapping from "../../customPages/omnichannel/PidMapping";
import OmniFYActuals from "../../customPages/omnichannel/FYActuals";
import OmniClaims from "../../customPages/omnichannel/OmniClaims";
import OmniFYActualsRetailing from "../../customPages/omnichannel/OmniFyRetailing";
import SKULevelOfftake from "../../customPages/omnichannel/SKULevelOfftake";
import MasterClassification from '../../customPages/omnichannel/MasterClassification';
import OmniPlanningPages from '../../customPages/omnichannel/OmniPlanningPages';
import LYMetric from "../../customPages/omnichannel/LYMetric";
import RetailBrandVSOD from "../../customPages/omnichannel/RetailBrandVSOD"
import OfftakeBrandVSOD from "../../customPages/omnichannel/OfftakeBrandVSOD"
import RetailOfftakePlanning from "../../customPages/omnichannel/RetailOfftakePlanning"
import TSSKUSalience from "../../customPages/omnichannel/TSSKUSalience"
import ReplaceValues from "../../customPages/omnichannel/ReplaceValues";
import AutoPopulate from "../../customPages/omnichannel/AutoPopulate";
import PYSDRLP from "../../customPages/spendplan/SetupDataPages/PYSDRLP";

const TalkSheet = React.lazy(() => import('../../customPages/spendplan/ReportsPages/talkSheet'));
const Imar1Report = React.lazy(() => import('../../customPages/spendplan/ReportsPages/IMARReports/Imar1Report'));
const Imar2Report = React.lazy(() => import('../../customPages/spendplan/ReportsPages/IMARReports/Imar2Report'));
const MrEcommSpendReconciliation = React.lazy(() => import('../../customPages/spendplan/ReportsPages/MrEcommSpendReconciliation/index'));
const ReconReviewReport = React.lazy(() => import('../../customPages/spendplan/ReportsPages/ReconReview/ReconReviewReport'));
const CommonReportTable = React.lazy(() => import('../../customPages/spendplan/ReportsPages/CommonReports/commonReportsTable'));
const MRSummaryVolPlanning = React.lazy(() => import("../../customPages/mrplan/ReportPages/Summary"));
const SpendPromo = React.lazy(() => import("../../customPages/mrplan/ReportPages/SpendPromo"));
const MREngineEarnings = React.lazy(() => import('../../customPages/mrplan/ReportPages/EngineEarnings'));
const EngineSpends = React.lazy(() => import('../../customPages/mrplan/ReportPages/EngineSpends'));
const ExecutiveSummary = React.lazy(() => import('../../customPages/mrplan/ReportPages/ExecutiveSummary'));
const BrandSummary = React.lazy(() => import('../../customPages/mrplan/ReportPages/BrandSummary'));
const CockpitView = React.lazy(() => import('../../customPages/mrplan/ReportPages/CockpitView'));
const AccountListWrapper = React.lazy(() => import('../../customPages/mrplan/Accounts/index'));
const MRArchives = React.lazy(() => import("../../customPages/mrplan/MRArchives"));
const BrandListWrapper = React.lazy(() => import('../../customPages/spendplan/Configure/brandList'));
const ForCorpReport = React.lazy(() => import('../../customPages/spendplan/ReportsPages/ForCorp/index'));
const ScenarioComparison = React.lazy(() => import('../../customPages/spendplan/ReportsPages/ScenarioComparison/index'));
const DoneUpdateReport = React.lazy(() => import('../../customPages/spendplan/DoneUpdateReport/index'));
const TsSkuRetailing = React.lazy(() => import('../../customPages/omnichannel/ReportPages/TsSkuRetailing'))
const OmniEngineEarnings = React.lazy(() => import('../../customPages/omnichannel/ReportPages/OmniEngineEarnings'));
const OumCheckListReport = React.lazy(() => import('../../customPages/omnichannel/ReportPages/OUMChecklist'))
const TSBrandSummary = React.lazy(() => import('../../customPages/omnichannel/ReportPages/TSBrandSummary'))
const OmniExecutiveSummary = React.lazy(() => import('../../customPages/omnichannel/ReportPages/OmniExecutiveSummary'))
const OmniAccountSummary = React.lazy(() => import('../../customPages/omnichannel/ReportPages/OmniAccountSummary'))
const OmniAccountSummary2 = React.lazy(() => import('../../customPages/omnichannel/ReportPages/OmniAccountSummary2'))
const OmniVersion = React.lazy(() => import('../../customPages/omnichannel/OmniVersion'))
const BrandformSummary = React.lazy(() => import('../../customPages/omnichannel/ReportPages/BrandformSummary'))
const NonGeneralFormat = React.lazy(() => import('../../customPages/omnichannel/ReportPages/NonGeneralFormat'))
const CommunicationReport = React.lazy(() => import('../../customPages/omnichannel/ReportPages/CommunicationReport'))

export const SpendPlan = (props) => {
  const { page } = props;

  if (page.name === "BOP Volumes") {
    return <BOPVolumes page={page} type="bopvolumes" objectKey="bopvolumes" />
  } else if (page.name === "RLP per MSU") {
    return <RLPperMSU page={page} type="rlppermsu" objectKey="boprlppermsu" />
  } else if (page.name === "Past Year Retailing") {
    return <PYRetailing page={page} type="pyrlp" objectKey="rlpcrores" />
  } else if (page.name === "PY SD%RLP") {
    return <PYSDRLP page={page} type="pysdrlp" objectKey="pysdrlp" />
  } else if (page.name === "Past Year RLP/MSU") {
    return <PYRLPMSU page={page} type="pyrlppermsu" objectKey={["hfsretailingcrores", "hfsretailingmsu"]} /> // dont change key's order
  } else if (page.name === "Channel Retailing Salience") {
    return <ChannelRetailingSalience page={page} objectKey="bopsalience" type="rlpsalience" />
  } else if (page.name === "YTD Actual Retailing") {
    return <YTDActualRetailing page={page} objectKey={["rlpcrores", "rlpsalience"]} type="rlpytdact" /> // dont change key's order
  } else if (page.name === "Trade Price Reduction") {
    return <TradePriceReduction page={page} pages={props.pages} />
  } else if (page.name === "Trade Spends Summary") {
    return <TalkSheet page={page} pages={props.pages} />
  } else if (page.name === "IMAR - MSASD Process") {
    return <Imar1Report page={page} pages={props.pages} />
  } else if (page.name === "IMAR - MSASD Business Analysis") {
    return <Imar2Report page={page} pages={props.pages} />
  } else if (page.name === "MR Ecomm Spends Recon") {
    return <MrEcommSpendReconciliation page={page} pages={props.pages} />
  } else if (page.name === "Recon Review") {
    return <ReconReviewReport page={page} pages={props.pages} />
  } else if (page.name === "Archive TPR Report") {
    return <ArchiveReport page={page} type="tpr" />
  } else if (page.name === "Archive Talk Sheet Report") {
    return <ArchiveReport page={page} type="talksheet" />
  } else if (page.name === "Archive Setup Data Report") {
    return <ArchiveReport page={page} type="setupData" />
  } else if (page.name === "Dashboard") {
    return <Dashboard {...props} />
  } else if (page.name === "MS&P Dashboard") {
    return <MSPDashboard {...props} />
  } else if (page.name === "Finance Dashboard") {
    return <FinanceDashboard {...props} />
  } else if (page.name === "Versions") {
    return <Archives {...props} />
  } else if (page.name === "Consumer Promotions") {
    return <ConsumerPromotions {...props} />
  } else if (page.name === "Trial Discounts") {
    return <TrialsDiscounts {...props} />
  } else if (page.name === "Displays") {
    return <Displays {...props} />
  } else if (page.name === "Conversion Funds") {
    return <ConversionFunds {...props} />
  } else if (page.name === "MR and Ecomm TFIs") {
    return <MrAndEcommTFIs {...props} />
  } else if (page.name === "Corp Bud") {
    return <CorpBud type="corpbud" objectKey="corpbud" {...props} />
  } else if (page.name === "MR & Ecomm") {
    return <MREcomm type="mrecomm" objectKey="mrecomm" {...props} />
  } else if (page.name === "Accruals & HC") {
    return <AccrualsHC type="accrualshc" objectKey="accrualshc" {...props} />
  } else if (page.name === "F1 Inputs") {
    return <F1Inputs type="f1inputs" objectKey="f1inputs" {...props} />
  } else if (page.name === "ITT") {
    return <ITT type="itt" objectKey="itt" {...props} />
  } else if (page.name === "TDC") {
    return <TDC type="tdc" objectKey="tdc" {...props} />
  } else if (page.name === "CTS") {
    return <CTS type="cts" objectKey="cts" {...props} />
  } else if (page.name === "BR2") {
    return <CommonReportTable type="br2" {...props} />
  } else if (page.name === "CHBF") {
    return <><CommonReportTable type="chbf" {...props} /></>
  } else if (page.name === "Brand") {
    return <><BrandListWrapper type="brand" key="brand" {...props} /></>
  } else if (page.name === "BrandForm") {
    return <><BrandListWrapper type="brandform" key="brandform" {...props} /></>
  } else if (page.name === "Channel") {
    return <><BrandListWrapper type="channels" key="channels" {...props} /></>
  } else if (page.name === "Cluster") {
    return <><BrandListWrapper type="clusters" key="clusters" {...props} /></>
  } else if (page.name === "Forex") {
    return <><BrandListWrapper type="forex" key="forex" {...props} /></>
  } else if (page.name === "SKU Master") {
    return <SKUMaster appName="MRPLAN" linkType='configure' type="skuMaster" {...props} />
  } else if (page.name === "ITT Master") {
    return <ITTMaster appName="MRPLAN" linkType='configure' type="ittMaster" {...props} />
  } else if (page.name === "SKU Mapping") {
    return <SKUMapping appName="MRPLAN" linkType='setup' type="skuMapping" {...props} />
  } else if (page.name === "FY Actuals") {
    return <FYActuals appName="MRPLAN" linkType='setup' type="fyActuals" {...props} />
  } else if (page.name === "Fy Actuals pff") {
    return <FyActualspff appName="MRPLAN" linkType='setup' type="fyActualspff" {...props} />
  } else if (page.name === "Promo Planning") {
    return <MRPlanning key="promo" type="promo" {...props} />
  } else if (page.name === "Summary") {
    return <MRSummaryVolPlanning key="summary" type="summary" {...props} />
  } else if (page.name === "Non Promo Planning") {
    return <MRPlanning key="nonPromo" type="nonPromo" {...props} />
  } else if (page.name === "Spends Promo") {
    return <SpendPromo key="spendspromo" type="spendspromo" {...props} />
  } else if (page.name === "Spends Planning") {
    return <MRPlanning key="spendsPlanning" type="spendsPlanning" {...props} />
  } else if (page.name === "Earnings Planning") {
    return <MRPlanning key="earningsTFI" type="earningsTFI" {...props} />
  } else if (page.name === "Engine - Earnings") {
    return <MREngineEarnings key="engineEarnings" type="engineEarnings" {...props} />
  } else if (page.name === "Engine - Spends") {
    return <EngineSpends type="engineSpends" {...props} />
  } else if (page.name === "Executive Summary") {
    return <ExecutiveSummary type="executiveSummary" {...props} />
  } else if (page.name === "Brand Summary") {
    return <BrandSummary type="brandSummary" {...props} />
  } else if (page.name === "Cockpit View") {
    return <CockpitView {...props} />
  } else if (page.name === "FCM Planning") {
    return <FCMPlanning {...props} />
  } else if (page.name === "Account") {
    return <AccountListWrapper type='account' {...props} />
  } else if (page.name === "MR Versions") {
    return <MRArchives type='Versions' {...props} />
  } else if (page.name === "For Corp") {
    return <ForCorpReport {...props} />
  } else if (page.name === "Scenario Comparison") {
    return <ScenarioComparison page={page} pages={props.pages} />
  } else if (page.name === "RLP (Act + Fcst)") {
    return <RLPActFcst {...props} />
  } else if (page.name === "Dashboard2") {
    return <MRDashboard2 {...props} />
  } else if (page.name === "Spends Review summary") {
    return <SpendsReviewSummary {...props} />
  } else if (page.name === "Retailing Summary") {
    return <RetailingSummary {...props} />
  } else if (page.name === "Top SKUs Analysis") {
    return <SKUAnlysis {...props} />
  } else if (page.name === "VSOD") {
    return <VSOD {...props} />
  } else if (page.name === "Brand Mix") {
    return <BrandMix {...props} />
  } else if (page.name === "Customer View") {
    return <CustomerViewReport {...props} />
  }  else if (page.name === "DoneUpdateReport") {
    return <DoneUpdateReport {...props} />
  }  else if (page.name === "PY SKU Level") {
    return <PYSKULevel appName="MRPLAN" linkType='setup' type="pyskulevel" {...props} />
  } else if (page.name === "OmniChannel SKU Master") {
    return <OmniSKUMaster appName="omnichannel" linkType='configure' type="skuMaster" {...props}/>
  } else if (page.name === "OmniChannel ITT Master") {
    return <OmniITTMaster appName="omnichannel" linkType='configure' type="ittMaster" {...props}/>
  } else if (page.name === "PID Mapping") {
    return <PidMapping appName="omnichannel" linkType='setup' type="pidMapping" {...props}/>
  } else if (page.name === "PYOmnichannel FY Actuals") {
    return <OmniFYActuals appName="omnichannel" linkType='setup' type="pyfyActuals" {...props}/>
  } else if (page.name === "Omni FY Actuals Retailing") {
    return <OmniFYActualsRetailing appName="omnichannel" linkType='setup' type="fyActuals" {...props}/>
  } else if (page.name === "Claims") {
    return <OmniClaims appName="omnichannel" linkType='setup' type="claims" {...props}/>
  } else if (page.name === "SKU Level Offtake") {
    return <SKULevelOfftake appName="omnichannel" linkType='setup' type="skulevelofftake" {...props}/>
  } else if (page.name === "Master Classificaion") {
    return <MasterClassification appName="omnichannel" linkType='configure' type="omniMasterClassification" {...props}/>
  } else if (page.name === 'Ts Sku Retailing FyActual') {
    return <TsSkuRetailing {...props} />
  } else if (page.name === 'Promo Calendar') {
    return <OmniPlanningPages appName="omnichannel" key="omniPlanningPages" type="omniPromoCalender" {...props} />
  } else if (page.name === 'Omni Spends Planning') {
    return <OmniPlanningPages appName="omnichannel" key="omniPlanningPages" type="omniSpendsPlanning" {...props} />
  } else if (page.name === 'Omni Tiff') {
    return <OmniPlanningPages appName="omnichannel" key="omniPlanningPages" type="omniTiff" {...props} />
  } else if (page.name === 'Media Calendar') {
    return <OmniPlanningPages appName="omnichannel" key="omniPlanningPages" type="mediaCalendar" {...props} />
  } else if (page.name === 'Omni Engine Earnings') {
    return <OmniEngineEarnings {...props} />
  } else if (page.name === 'OUM Checklist') {
    return <OumCheckListReport page={page} pages={props.pages} {...props} />
  } else if (page.name === 'TS Brand Summary') {
    return <TSBrandSummary page={page} pages={props.pages} {...props} />
  } else if (page.name === 'PY Metrics') {
    return <LYMetric appName="omnichannel" linkType='setup' type="lymetric" {...props}/>
  } else if (page.name === 'Omni Executive Summary') {
    // return <OmniAccountSummary page={page} pages={props.pages} {...props} />
    return <OmniExecutiveSummary page={page} pages={props.pages} {...props} />
  } else if (page.name === 'Omni Account Summary') {
    return <OmniAccountSummary page={page} pages={props.pages} {...props} />
  } else if (page.name === 'Omni Account Summary2') {
    return <OmniAccountSummary2 page={page} pages={props.pages} {...props} />
  } else if (page.name === 'Omni Versions') {
    return <OmniVersion type='Versions' {...props} />
  } else if (page.name === 'Retailing Offtake Planning') {
    return <RetailOfftakePlanning appName="omnichannel" linkType='setup' type="omniRetailingOffTakePlanning" {...props} />
  } else if (page.name === 'Retail Brand VSOD') {
    return <RetailBrandVSOD appName="omnichannel" linkType='setup' type="retailBrandVSOD" {...props} />
  } else if (page.name === 'Offtake Brand VSOD') {
    return <OfftakeBrandVSOD appName="omnichannel" linkType='setup' type="offtakeBrandVSOD" {...props} />
  } else if (page.name === 'TS SKU Salience') {
    return <TSSKUSalience appName="omnichannel" linkType='setup' type="tsSkuSalience" {...props} />
  } else if (page.name === 'Replace Values') {
    return <ReplaceValues appName="omnichannel" {...props} />
  } else if (page.name === 'Auto Populate') {
    return <AutoPopulate appName="omnichannel" {...props} />
  } else if (page.name === 'Brandform Summary') {
    return <BrandformSummary page={page} pages={props.pages} {...props} />
  } else if (page.name === 'Non General Format') {
    return <NonGeneralFormat page={page} pages={props.pages} {...props} />
  } else if (page.name === 'Communication Report') {
    return <CommunicationReport page={page} pages={props.pages} {...props} />
  } else {
    return <div>{page.name}</div>
  } 
}



