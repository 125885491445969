const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { request } from '../../../client';

export class MrEcommSpendReconciliationService {

  static getmrEcommReconciliationData(data) {
    let uri = API_BASE_URL + '/imar/mrecomm' + data.url;
    return request(uri, {
      method: 'GET',
      headers: {
        appId: data.appId,
      },
    });
  }

  static fetchCommonReportData(data) {
    let uri = API_BASE_URL + '/imar/br' + data.url;
    return request(uri, {
      method: 'GET',
      headers: {
        appId: data.appId,
      },
    });
  }
  
}
