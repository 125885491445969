import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import "./styles.scss"
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: 'calc(100% + 8px)',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  }
}));
import Loader from "./loader.svg"
export default function PreAppLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className='pre-app-loader'>
        <img src={Loader} />
        <div className="blob blob-0"></div>
        <div className="blob blob-1"></div>
        <div className="blob blob-2"></div>
        <div className="blob blob-3"></div>
        <div className="blob blob-4"></div>
        <div className="blob blob-5"></div>
      </div>
    </div>
  );
}
