import { keyMirror } from 'modules/helpers';

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
	LOAD_APP_CONFIG_LOADING: undefined,
	LOAD_APP_CONFIG_SUCCESS: undefined,
	LOAD_APP_CONFIG_ERROR: undefined,
	LOAD_APPS_LOADING: undefined,
	LOAD_APPS_SUCCESS: undefined,
	LOAD_APPS_ERROR: undefined,
	LOAD_PAGE_CONFIG_LOADING: undefined,
	LOAD_PAGE_CONFIG_SUCCESS: undefined,
	LOAD_PAGE_CONFIG_ERROR: undefined,
	LOAD_PAGES_LOADING: undefined,
	LOAD_PAGES_SUCCESS: undefined,
	LOAD_PAGES_ERROR: undefined,
	LOAD_ORG_LOADING: undefined,
	LOAD_ORG_SUCCESS: undefined,
	LOAD_ORG_ERROR: undefined,
	LOAD_THEME_LOADING: undefined,
	LOAD_THEME_SUCCESS: undefined,
	LOAD_THEME_ERROR: undefined,
	REGISTER_LOADING: undefined,
	REGISTER_SUCCESS: undefined,
	REGISTER_ERROR: undefined,
	LOGIN_LOADING: undefined,
	LOGIN_SUCCESS: undefined,
	LOGIN_ERROR: undefined,
	USER_LOGGED_OUT: undefined,
	SET_USER_DATA: undefined,
	REMOVE_USER_DATA: undefined,
	HIDE_MESSAGE: undefined,
	SHOW_MESSAGE: undefined,
	SAVE_APP_LOADING: undefined,
	SAVE_APP_SUCCESS: undefined,
	SAVE_APP_ERROR: undefined,
	SAVE_DATASOURCE_LOADING: undefined,
	SAVE_DATASOURCE_SUCCESS: undefined,
	SAVE_DATASOURCE_ERROR: undefined,
	LOAD_DATAELEMENTS_LOADING: undefined,
	LOAD_DATAELEMENTS_SUCCESS: undefined,
	LOAD_DATAELEMENTS_ERROR: undefined,
	SAVE_DATAMODEL_LOADING: undefined,
	SAVE_DATAMODEL_SUCCESS: undefined,
	SAVE_DATAMODEL_ERROR: undefined,
	SAVE_PAGE_LOADING: undefined,
	SAVE_PAGE_SUCCESS: undefined,
	SAVE_PAGE_ERROR: undefined,
	SET_PREV_PATH: undefined,
	EMPTY_PREV_PATH: undefined,
	LOAD_ENTITIES_LOADING: undefined,
	LOAD_ENTITIES_SUCCESS: undefined,
	LOAD_ENTITIES_ERROR: undefined,
	GET_FORM_BY_NAME_LOADING: undefined,
	GET_FORM_BY_NAME_SUCCESS: undefined,
	GET_FORM_BY_NAME_ERROR: undefined,
	GET_FORMS_LOADING: undefined,
	GET_FORMS_SUCCESS: undefined,
	GET_FORMS_ERROR: undefined,
	GET_SUBMITTED_DATA_BY_ID_LOADING: undefined,
	GET_SUBMITTED_DATA_BY_ID_SUCCESS: undefined,
	GET_SUBMITTED_DATA_BY_ID_ERROR: undefined,
	SAVE_SUBMITTED_DATA_LOADING: undefined,
	SAVE_SUBMITTED_DATA_SUCCESS: undefined,
	SAVE_SUBMITTED_DATA_ERROR: undefined,
	UPDATE_SUBMITTED_DATA_LOADING: undefined,
	RESET_PASSWORD_LOADING: undefined,
	RESET_PASSWORD_SUCCESS: undefined,
	RESET_PASSWORD_ERROR: undefined,
	CLEAN_SUBMITTED_DATA: undefined,
	UPDATE_SELECTED_FORM: undefined,
	LOAD_ROLES_LOADING: undefined,
	LOAD_ROLES_SUCCESS: undefined,
	LOAD_ROLES_ERROR: undefined,
	LOAD_MEMBER_LOADING: undefined,
	LOAD_MEMBER_SUCCESS: undefined,
	LOAD_MEMBER_ERROR: undefined,
	GET_MEMBER_LOADING: undefined,
	GET_MEMBER_SUCCESS: undefined,
	GET_MEMBER_ERROR: undefined,
	UPLOAD_CAPPS_LOADING: undefined,
	UPLOAD_CAPPS_SUCCESS: undefined,
	UPLOAD_CAPPS_ERROR: undefined,
	GET_CAPP_LOADING: undefined,
	GET_CAPP_SUCCESS: undefined,
	GET_CAPP_ERROR: undefined,
	FILTER_CAPP_LOADING: undefined,
	FILTER_CAPP_SUCCESS: undefined,
	FILTER_CAPP_ERROR: undefined,
	RULE_EXCUTER_LOADING: undefined,
	RULE_EXCUTER_SUCCESS: undefined,
	RULE_EXCUTER_ERROR: undefined,
	GET_ENTITY_LOADING: undefined,
	GET_ENTITY_SUCCESS: undefined,
	GET_ENTITY_ERROR: undefined,
	LOAD_PDF: undefined,
	SAVE_SUPPORTING_DOCUMENT_SUCCESS: undefined,
	SAVE_SUPPORTING_DOCUMENT_FAILURE: undefined,
	GET_SP_TPR_DATA: undefined,
	GET_SP_TPR_SUCCESS: undefined,
	GET_SP_TPR_FAILURE: undefined,
	ADD_SP_TPR_ROW: undefined,
	ADD_SP_TPR_ROW_SUCCESS: undefined,
	ADD_SP_TPR_ROW_FAILURE: undefined,
	GET_BRANDFORM_FILTER: undefined,
	GET_TS_DATA: undefined,
	GET_TS_DATA_SUCCESS: undefined,
	GET_TS_DATA_FAILURE: undefined,
	GET_BRANDFORM_FILTER_SUCCESS: undefined,
	GET_BRANDFORM_FILTER_FAILURE: undefined,
	GET_CHANNEL_FILTER: undefined,
	GET_CHANNEL_FILTER_SUCCESS: undefined,
	GET_CHANNEL_FILTER_FAILURE: undefined,
	GET_CLUSTER_FILTER: undefined,
	GET_CLUSTER_FILTER_SUCCESS: undefined,
	GET_CLUSTER_FILTER_FAILURE: undefined,
	BRANDFORM_FILTER_SELECT: undefined,
	BRANDFORM_FILTER_SELECT_SUCCESS: undefined,
	BRANDFORM_FILTER_SELECT_FAILURE: undefined,
	//SpendPlan keys
	GET_BRANDS: undefined,
	GET_BRANDFORMS: undefined,
	GET_CHANNELS: undefined,
	GET_BRANDS_SUCCESS: undefined,
	GET_BRANDFORMS_SUCCESS: undefined,
	GET_CHANNELS_SUCCESS: undefined,
	GET_BRANDS_FAILURE: undefined,
	GET_BRANDFORMS_FAILURE: undefined,
	GET_CHANNELS_FAILURE: undefined,
	GET_SP_TPR_EXTRA_DATA: undefined,
	GET_SP_TPR_EXTRA_DATA_SUCCESS: undefined,
	GET_SP_TPR_EXTRA_DATA_FAILURE: undefined,
	DELETE_CHECKED_TPR: undefined,
	DELETE_CHECKED_TPR_SUCCESS: undefined,
	DELETE_CHECKED_TPR_FAILURE: undefined,

	DELETE_SUBMITTED_DATA_LOADING: undefined,
	DELETE_SUBMITTED_DATA_SUCCESS: undefined,
	DELETE_SUBMITTED_DATA_FAILURE: undefined,
	DELETE_IS_SUCCESS: undefined,

	USER_SELECTED_BRAND: undefined,
	GET_BRAND_LOADING: undefined,
	GET_BRAND_SUCCESS: undefined,
	GET_BRAND_FAILURE: undefined,

	SET_BRAND_EMPTY: undefined,

	FREEZE_PLANS: undefined,
	FREEZE_PLANS_SUCCESS: undefined,
	FREEZE_PLANS_FAILURE: undefined,

	GET_TOPFIVEPLANS: undefined,
	TOPFIVEPLANS_SUCCESS: undefined,
	TOPFIVEPLANS_FAILURE: undefined,

	GET_CHECKDATA: undefined,
	CHECKDATA_SUCCESS: undefined,
	CHECKDATA_FAILURE: undefined,

  GET_CONSUMER_PROMO_DATA: undefined,
  GET_CONSUMER_PROMO_SUCCESS: undefined,
  GET_CONSUMER_PROMO_FAILURE: undefined,
  ADD_NEW_CONSUMER_PROMO: undefined,
  ADD_NEW_CONSUMER_PROMO_SUCCESS: undefined,
  ADD_NEW_CONSUMER_PROMO_FAILURE: undefined,
  DELETE_CHECKED_CONSUMER_PROMO: undefined,
  DELETE_CHECKED_CONSUMER_PROMO_SUCCESS: undefined,
  DELETE_CHECKED_CONSUMER_PROMO_FAILURE: undefined,

  GET_CONSUMER_PROMO_EXTRA_DATA: undefined,
  GET_CONSUMER_PROMO_EXTRA_DATA_SUCCESS: undefined,
  GET_CONSUMER_PROMO_EXTRA_DATA_FAILURE: undefined,

  GET_TRIALS_DISCOUNT_DATA: undefined,
  GET_TRIALS_DISCOUNT_SUCCESS: undefined,
  GET_TRIALS_DISCOUNT_FAILURE: undefined,
  ADD_NEW_TRIALS_DISCOUNT: undefined,
  ADD_NEW_TRIALS_DISCOUNT_SUCCESS: undefined,
  ADD_NEW_TRIALS_DISCOUNT_FAILURE: undefined,
  DELETE_CHECKED_TRIALS_DISCOUNT: undefined,
  DELETE_CHECKED_TRIALS_DISCOUNT_SUCCESS: undefined,
  DELETE_CHECKED_TRIALS_DISCOUNT_FAILURE: undefined,
  GET_TRIAL_DISCOUNTS_EXTRA_DATA: undefined,
  GET_TRIAL_DISCOUNTS_EXTRA_DATA_SUCCESS: undefined,
  GET_TRIAL_DISCOUNTS_EXTRA_DATA_FAILURE: undefined,


  GET_DISPLAYS_DATA: undefined,
  GET_DISPLAYS_SUCCESS: undefined,
  GET_DISPLAYS_FAILURE: undefined,
  ADD_NEW_DISPLAYS: undefined,
  ADD_NEW_DISPLAYS_SUCCESS: undefined,
  ADD_NEW_DISPLAYS_FAILURE: undefined,
  DELETE_CHECKED_DISPLAYS: undefined,
  DELETE_CHECKED_DISPLAYS_SUCCESS: undefined,
  DELETE_CHECKED_DISPLAYS_FAILURE: undefined,
  GET_DISPLAYS_EXTRA_DATA: undefined,
  GET_DISPLAYS_EXTRA_DATA_SUCCESS: undefined,
  GET_DISPLAYS_EXTRA_DATA_FAILURE: undefined,

  GET_CONVERSION_FUND_DATA: undefined,
  GET_CONVERSION_FUND_SUCCESS: undefined,
  GET_CONVERSION_FUND_FAILURE: undefined,
  ADD_NEW_CONVERSION_FUND: undefined,
  ADD_NEW_CONVERSION_FUND_SUCCESS: undefined,
  ADD_NEW_CONVERSION_FUND_FAILURE: undefined,
  DELETE_CHECKED_CONVERSION_FUND: undefined,
  DELETE_CHECKED_CONVERSION_FUND_SUCCESS: undefined,
  DELETE_CHECKED_CONVERSION_FUND_FAILURE: undefined,
  GET_CONVERSION_FUND_EXTRA_DATA: undefined,
  GET_CONVERSION_FUND_EXTRA_DATA_SUCCESS: undefined,
  GET_CONVERSION_FUND_EXTRA_DATA_FAILURE: undefined,

  GET_MRANDECOMMTFIS_DATA: undefined,
  GET_MRANDECOMMTFIS_SUCCESS: undefined,
  GET_MRANDECOMMTFIS_FAILURE: undefined,
  ADD_NEW_MRANDECOMMTFIS: undefined,
  ADD_NEW_MRANDECOMMTFIS_SUCCESS: undefined,
  ADD_NEW_MRANDECOMMTFIS_FAILURE: undefined,
  DELETE_CHECKED_MRANDECOMMTFIS: undefined,
  DELETE_CHECKED_MRANDECOMMTFIS_SUCCESS: undefined,
  DELETE_CHECKED_MRANDECOMMTFIS_FAILURE: undefined,

  GET_MRANDECOMMTFIS_EXTRA_DATA: undefined,
  GET_MRANDECOMMTFIS_EXTRA_DATA_SUCCESS: undefined,
  GET_MRANDECOMMTFIS_EXTRA_DATA_FAILURE: undefined,

  ADD_RECON_BASELINE: undefined,
  ADD_RECON_BASELINE_SUCCESS: undefined,
  ADD_RECON_BASELINE_FAILURE: undefined,

  GET_RECON_REVIEW_DATA: undefined,
  GET_RECON_REVIEW_DATA_SUCCESS: undefined,
  GET_RECON_REVIEW_DATA_FAILURE: undefined,

  REMOVE_RECON_REVIEW_DATA: undefined,
  REMOVE_RECON_REVIEW_DATA_SUCCESS: undefined,
  REMOVE_RECON_REVIEW_DATA_FAILURE: undefined,

  UPDATE_RECON_REVIEW_LABEL: undefined,
  UPDATE_RECON_REVIEW_LABEL_SUCCESS: undefined,
  UPDATE_RECON_REVIEW_LABEL_FAILURE: undefined,

  REMOVE_RECON_REVIEW_COLUMN: undefined,
  REMOVE_RECON_REVIEW_COLUMN_SUCCESS: undefined,
  REMOVE_RECON_REVIEW_COLUMN_FAILURE: undefined,

  GET_IMAR1_REPORT_DATA: undefined,
  GET_IMAR1_REPORT_DATA_SUCCESS: undefined,
  GET_IMAR1_REPORT_DATA_FAILURE: undefined,

  GET_IMAR2_REPORT_DATA: undefined,
  GET_IMAR2_REPORT_DATA_SUCCESS: undefined,
  GET_IMAR2_REPORT_DATA_FAILURE: undefined,

  SET_UPLOAD_RTDX_DATA_LOADNIG: undefined,
  SET_UPLOAD_PLAN_LOADNIG: undefined,

  GET_MRECOMMSPENDRECONCILIATION_DATA: undefined,
  GET_MRECOMMSPENDRECONCILIATION_DATA_SUCCESS: undefined,
  GET_MRECOMMSPENDRECONCILIATION_DATA_FAILURE: undefined,
  
  GET_COMMON_REPORT_DATA: undefined,
  GET_COMMON_REPORT_DATA_SUCCESS: undefined,
  GET_COMMON_REPORT_DATA_FAILURE: undefined,

  SAVE_AND_RECON: undefined,
  REMOVE_SUCCESS_MSG: undefined,
  CLEAR_ERROR: undefined,

  GET_PLAN_FILTER_DATA_TPR: undefined,
  GET_PLAN_FILTER_DATA_TPR_SUCCESS: undefined,
  GET_PLAN_FILTER_DATA_TPR_FAILURE: undefined,

  GET_PLAN_FILTER_DATA_CONSUMER_PROMO: undefined,
  GET_PLAN_FILTER_DATA_CONSUMER_PROMO_SUCCESS: undefined,
  GET_PLAN_FILTER_DATA_CONSUMER_PROMO_FAILURE: undefined,

  GET_PLAN_FILTER_DATA_TRIAL_DISCOUNT: undefined,
  GET_PLAN_FILTER_DATA_TRIAL_DISCOUNT_SUCCESS: undefined,
  GET_PLAN_FILTER_DATA_TRIAL_DISCOUNT_FAILURE: undefined,

  GET_PLAN_FILTER_DATA_DISPLAYS: undefined,
  GET_PLAN_FILTER_DATA_DISPLAYS_SUCCESS: undefined,
  GET_PLAN_FILTER_DATA_DISPLAYS_FAILURE: undefined,

  GET_PLAN_FILTER_DATA_CONVERSION_FUNDS: undefined,
  GET_PLAN_FILTER_DATA_CONVERSION_FUNDS_SUCCESS: undefined,
  GET_PLAN_FILTER_DATA_CONVERSION_FUNDS_FAILURE: undefined,

  GET_PLAN_FILTER_DATA_TFIS: undefined,
  GET_PLAN_FILTER_DATA_TFIS_SUCCESS: undefined,
  GET_PLAN_FILTER_DATA_TFIS_FAILURE: undefined,

  GET_ACCOUNTS: undefined,
  GET_ACCOUNTS_SUCCESS: undefined,
  GET_ACCOUNTS_FAILURE: undefined,

  USER_SELECTED_ACCOUNT: undefined,
  GET_DATA: undefined,
  GET_DATA_POST: undefined,
  GET_DATA_SUCCESS: undefined,
  GET_DATA_FAILURE: undefined,

  SAVE_MR_DATA: undefined,
  SAVE_MR_DATA_SUCCESS: undefined,
  SAVE_MR_DATA_FAILURE: undefined,

  DELETE_MR_DATA: undefined,
  DELETE_MR_DATA_SUCCESS: undefined,
  DELETE_MR_DATA_FAILURE: undefined,

  REMOVE_MR_SUCCESS_MESSAGE: undefined,

  UPDATE_MR_DATA: undefined,
  UPDATE_MR_DATA_SUCCESS: undefined,
  UPDATE_MR_DATA_FAILURE: undefined,
  
  CHECK_SP_BG_PROCESS: undefined,
  CHECK_SP_BG_PROCESS_SUCCESS: undefined,
  CHECK_SP_BG_PROCESS_FAILURE: undefined,
  
  CHECK_FREEZE_PLAN_PROCESS: undefined,
  CHECK_FREEZE_PLAN_PROCESS_SUCCESS: undefined,
  CHECK_FREEZE_PLAN_PROCESS_FAILURE: undefined,

  SIGN_IN_WITH_PING_TOKEN: undefined,
  
  SET_ACCOUNT_EMPTY: undefined,
  FREEZE_MR_PLANS: undefined,
  FREEZE_MR_PLANS_SUCCESS: undefined,
  FREEZE_MR_PLANS_FAILURE: undefined,
  
  IS_USER_EXIST: undefined,
  IS_USER_EXIST_SUCCESS: undefined,
  IS_USER_EXIST_FAILURE: undefined,

  CLEAR_PAGES: undefined,

  GET_FORCORP_DATA: undefined,
  GET_FORCORP_DATA_SUCCESS: undefined,
  GET_FORCORP_DATA_FAILURE: undefined,

  MODIFY_TPR: undefined,
  CLEAR_MODIFY_TPR: undefined,
  REMOVE_MODIFY_TPR: undefined,

  MODIFY_CONSUMER_PROMO: undefined,
  CLEAR_MODIFY_CONSUMER_PROMO: undefined,
  REMOVE_MODIFY_CONSUMER_PROMO: undefined,

  MODIFY_TRIALS_DISCOUNT: undefined,
  CLEAR_MODIFY_TRIALS_DISCOUNT: undefined,
  REMOVE_MODIFY_TRIALS_DISCOUNT: undefined,

  MODIFY_CONVERSION_FUNDS: undefined,
  CLEAR_MODIFY_CONVERSION_FUNDS: undefined,
  REMOVE_MODIFY_CONVERSION_FUNDS: undefined,

  MODIFY_DISPLAYS: undefined,
  CLEAR_MODIFY_DISPLAYS: undefined,
  REMOVE_MODIFY_DISPLAYS: undefined,

  MODIFY_MRECOMM_TFI: undefined,
  CLEAR_MODIFY_MRECOMM_TFI: undefined,
  REMOVE_MODIFY_MRECOMM_TFI: undefined,

  GET_TSP_DATA: undefined,
  GET_TSP_DATA_SUCCESS: undefined,
  GET_TSP_DATA_FAILURE: undefined,

  GET_IMARTABLE_DASHBOARD: undefined,
  GET_IMARTABLE_DASHBOARD_SUCCESS: undefined,
  GET_IMARTABLE_DASHBOARD_FAILURE: undefined,

  GET_TSP_SCENARIO_LIST: undefined,
  GET_TSP_SCENARIO_LIST_SUCCESS: undefined,
  GET_TSP_SCENARIO_LIST_FAILURE: undefined,

  GET_TSP_SCENARIO: undefined,
  GET_TSP_SCENARIO_SUCCESS: undefined,
  GET_TSP_SCENARIO_FAILURE: undefined,
  
  UPDATE_TSP_SCENARIO: undefined,
  UPDATE_TSP_SCENARIO_SUCCESS: undefined,
  UPDATE_TSP_SCENARIO_FAILURE: undefined,

  UPDATE_TSP_SCENARIO_LABLE: undefined,
  UPDATE_TSP_SCENARIO_LABLE_SUCCESS: undefined,
  UPDATE_TSP_SCENARIO_LABLE_FAILURE: undefined
});

/**
 * @constant {Object} STATUS
 * @memberof Constants
 */
export const STATUS = {
	IDLE: 'idle',
	RUNNING: 'running',
	READY: 'ready',
	SUCCESS: 'success',
	ERROR: 'error',
};

/**
 * @constant {Object} APP LEVEL CONFIG
 * @memberof Constants
 */
export const APP_CONFIG = {
	APP_ID: process.env.APP_ID,
	APP_URI: '/' + process.env.APP_ID,
	APP_NAME: process.env.APP_NAME,
	APP_PRIVILEGECHECKS: process.env['REACT_APP_Dsilo.apprenederer.privilegechecks']
};
