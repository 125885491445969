const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { request } from './../client';

import { APP_CONFIG } from 'constants/index';
import { useRadioGroup } from '@material-ui/core';

export class AppService {
	static getAppConfig({ appId, orgId }) {
		const uri = API_BASE_URL + "/dapps/" + appId;
		console.log("orgId, appId", orgId, appId);
		return request(uri, {
			method: 'GET',
			headers: {
				orgId
			}
		});
	}
	static getApps(orgId) {
		const uri = API_BASE_URL + "/dapps";
		console.log("orgId", orgId);
		return request(uri, {
			method: 'GET',
			headers: {
				orgId
			}
		});
	}
	static getEntities({ appId, orgId }) {
		const uri = API_BASE_URL + "/entities";
		return request(uri, {
			method: 'GET',
			headers: {
				orgId,
				appId
			}
		});
	}
	static getRoles(appId) {
		const uri = API_BASE_URL + "/roles";
		return request(uri, {
			method: 'GET',
			headers: {
				appId
			}
		});
	}
	static getOrg(appId) {
		const uri = API_BASE_URL + "/org";

		return request(uri, {
			method: 'GET',
			headers: { "appId": appId }
		});
	}
	static getApiDataFromDataUrl(dataUrl, appId) {

		return request(dataUrl, {
			method: 'GET',
			headers: { "appId": appId }
		});
	}
	static saveDapp(data, appId) {
		const uri = API_BASE_URL + "/dapps/add";

		return request(uri, {
			method: 'POST',
			payload: data,
			headers: { "appId": appId }
		});
	}
	static saveDatasource(data) {
		const uri = API_BASE_URL + "/datasources/add";

		return request(uri, {
			method: 'POST',
			payload: data,
			headers: {
				appId: data.appId
			}
		});
	}
	static getDataelements(datasourceId, appId) {
		const uri = `${API_BASE_URL}/datasources/dataelements/${datasourceId}`;

		return request(uri, {
			method: 'GET',
			headers: { "appId": appId }
		});
	}
	static getMember(user) {
		console.log("user=====>", user);
		const uri = `${API_BASE_URL}/member/uid`;
		return request(uri, {
			method: 'POST',
			payload: { id: user.id, email: user.email },
			headers: { 'appId': user.appId }
		});
	}
	static getEntity({ appId, entityId }) {
		const uri = API_BASE_URL + "/entities/" + entityId;
		return request(uri, {
			method: 'GET',
			headers: {
				appId
			}
		});
	}
	static getMemberById(appId) {
		const uri = API_BASE_URL + "/member";

		return request(uri, {
			method: 'GET',
			headers: {
				appId: appId
			}
		});
	}
	static saveDataModel(data) {
		const uri = API_BASE_URL + "/dataModels/add"
		let { appId, orgId } = data
		delete data.appId
		delete data.orgId
		return request(uri, {
			method: 'POST',
			payload: data,
			headers: {
				appId,
				orgId
			}
		})
	}

	static getBrand(appId) {
		const uri = API_BASE_URL + "/brands"
		return request(uri, {
			method: 'GET',
			headers: {
				appId,
			}
		})
	}

	static postDataToAPIUrl(dataUrl, appId, payload) {
		return request(dataUrl, {
			method: 'POST',
			payload,
			headers: { "appId": appId }
		});
	}

}

export function apiBaseAddress() {
	return API_BASE_URL;
}
