import React, { useState, useEffect } from 'react';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { AppService } from 'modules/api/index';
// import Highcharts from 'highcharts';
import Highcharts from '../HighchartsWrapper'
import {
	getBarChartConfig,
	getDefaultBarChart,
	generateBarData
} from 'modules/page/highcharts/bar.module';
import {
	generateGUID
} from 'modules/page/page.module';

import {
	getTheme
} from 'modules/page/component.module';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isSpendplan = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'spendplan';


const BarChart = (props) => {

	const [options, setOptions] = useState(getDefaultBarChart());
	const [id, setId] = useState(`bar-${generateGUID()}`)
	const [orientation, setOrientation] = useState(props.chartData.orientation)

	const highChartsRender = () => {
		const { chartData: { data } } = props;
		let barConfig = getBarChartConfig(options, id, orientation, data && data.stacked);
		console.log();
		if(!isSpendplan){
			Highcharts.theme = getTheme();
			// Apply the theme
			Highcharts.setOptions(Highcharts.theme);
		}
		Highcharts.chart(barConfig);
	}

	const setDatafromApi = (res, data) => {
		let d = { ...data };
		let chartData = { ...props.chartData };
		let { data: { stacked } } = chartData;
		if (chartData.selectedDataModel && chartData.barXAxis && chartData.barYAxis) {
			d.labels = res.labels;
			if (chartData.groupByXAxis) {
				d.datasets = res.datasets;
			} else {
				if (!d.datasets)
					d.datasets = []
				if (!d.datasets[0])
					d.datasets[0] = {};
				if (stacked && !res.data) {
					d.datasets = res.datasets;
					d.stacked = true;
				} else {
					d.datasets[0].data = res.data;
					d.datasets[0].label = res.label;
				}
				// //TO DO - Make fynamic
				// d.datasets[0].fillColor = ["rgba(0,10,220,0.5)", "rgba(220,0,10,0.5)", "rgba(220,0,0,0.5)", "rgba(120,250,120,0.5)"];
				// d.datasets[0].strokeColor = "rgba(220,220,220,0.8)";
				// d.datasets[0].highlightFill = "rgba(220,220,220,0.75)";
				// d.datasets[0].highlightStroke = "rgba(220,220,220,1)";
			}
		} else {
			d.labels = res.data.labels;
			d.datasets[0].data = res.data.datasets[0].data;
			d.datasets[0].label = res.data.datasets[0].label;
			d.datasets[0].backgroundColor = 'rgb(255, 99, 132)';
			d.datasets[0].fillColor = ["rgba(0,10,220,0.5)", "rgba(220,0,10,0.5)", "rgba(220,0,0,0.5)", "rgba(120,250,120,0.5)"];
			d.datasets[0].strokeColor = "rgba(220,220,220,0.8)";
			d.datasets[0].highlightFill = "rgba(220,220,220,0.75)";
			d.datasets[0].highlightStroke = "rgba(220,220,220,1)";
		}
		chartData.data = d;
		console.log("chartData.data", chartData.data)
		let ops = generateBarData(chartData, options)
		setOptions({ ...options, ...ops });
	}

	const getApiDataFromDataUrl = (url, data) => {
		AppService.getApiDataFromDataUrl(url, props.appId)
			.then(res => {
				setDatafromApi(res, data)
			})
	}

	useEffect(() => {
		highChartsRender();
	}, [options]);

	// useEffect(() => {
	//     // setOptions(props.options);
	// }, [props.options]);

	useEffect(() => {
		let { chartData } = props;
		let { data: { stacked } } = chartData;
		if (stacked) {
			options.scales = {
				xAxes: [{ stacked: true }],
				yAxes: [{
					ticks: {
						beginAtZero: true
					}
				}]
			}
			options.maintainAspectRatio = false
			setOptions(options);
		}

		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + `/dataconnect/${stacked ? 'line' : 'bar'}/` + chartData.selectedDataModel + "?";

			if (stacked) {
				targetURL += `&label=${chartData.barXAxis.value}`;
				targetURL += `&measure=${chartData.barYAxis.value}`;
			} else {
				targetURL += `&barXAxis=${chartData.barXAxis.value}`
				targetURL += `&barYAxis=${chartData.barYAxis.value}`
			}

			if (chartData.metric === 'count' && chartData.groupByXAxis) {
				targetURL += `&groupByXAxis=${chartData.groupByXAxis}`
			}
			targetURL += '&metric=' + chartData.metric;
			if (props.queryString) {
				targetURL += '&' + props.queryString;
			}
			if (chartData.datelabeltype) {
				targetURL += '&labeltype=date'
				targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
			}

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (props.page && props.page.conditions && props.page.conditions.length > 0) {
				let cond = JSON.stringify(props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
      let encodedData = encodeURIComponent(targetURL)
      let _targetUrl = JSON.stringify(encodedData);
			getApiDataFromDataUrl(_targetUrl, props.data);
		} else if (props.dataUrl) {
			getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			let ops = generateBarData(props, options)
			setOptions({ ...options, ...ops });
		}
	}, [])

	return (
		id && <div id={id} className="chartEqualHeight"></div>
	)
}
export default BarChart;