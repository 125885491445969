import { getMonth, shortMonthsUpperCase } from "../../utility/utils";
import { getFiscalYearFormat } from "../customPages/spendplan/spendplanUtils";

export const getFFFYValues = (brand, num) => {
    let currentFF = brand.CurrentFF
    let currentYear = Number(brand.FinancialYear);
    let m = getMonth(currentFF, 'monthNumber');
    let obj = {
        value: currentYear + '-' + m,
        label: `FY ${getFiscalYearFormat(currentYear)} ${shortMonthsUpperCase[m - 1]} FF`,
    }
    return [...getPastFFFY(currentYear, currentFF), obj, ...getFutureFFFY(currentYear, currentFF)];

}



export const getFutureFFFY = (year, currentFF, num = 7) => {
    let arr = [];
    Array.from(Array(num)).forEach((e, index) => {
        let m = getMonth(currentFF, 'monthNumber') + index;
        if (m > 11) {
            m = m % 12
        }
        if (m === 6) {
            year = year + 1
        }
        let obj = {
            value: year + '-' + (m + 1),
            label: `FY ${getFiscalYearFormat(year)} ${shortMonthsUpperCase[m]} FF`,
        }
        arr.push(obj)
    })
    return arr
}

export const getPastFFFY = (year, currentFF, num = 7) => {
    let arr = [];
    let arr2 = []
    Array.from(Array(num)).forEach((e, index) => {
        let m = getMonth(currentFF, 'monthNumber') + index - (num + 1);
        if (m < 0) {
            m = m + 12
        }
        if (m > 11) {
            m = m % 12
        }
        arr.push(m)
    })
    arr.reverse().forEach((j) => {
        if (j < 0) {
            j = j + 12
        }

        if (j > 11) {
            j = j % 12
        }
        if (j === 5) {
            year = year - 1
        }
        let obj = {
            value: year + '-' + (j + 1),
            label: `FY ${getFiscalYearFormat(year)} ${shortMonthsUpperCase[j]} FF`,
        }
        arr2.push(obj)
    })
    return arr2.reverse()
}