

import { hot } from 'react-hot-loader/root'
import React, { useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
	Button, AppBar, Tabs, Tab, Typography, Box, Slide, Dialog, DialogActions, DialogContent, makeStyles
} from '@material-ui/core'
import { connect } from 'react-redux'
import classNames from 'classnames'
import Header from '../SpendPlan/Header';
import BOPVolumes from '../SetupDataPages/BOPVolumes'
import RLPperMSU from '../SetupDataPages/RLPperMSU'
import PYRetailing from '../SetupDataPages/PYRetailing'
import PYRLPMSU from '../SetupDataPages/PYRLPMSU'
import ChannelRetailingSalience from '../SetupDataPages/ChannelRetailingSalience'
import YTDActualRetailing from '../SetupDataPages/YTDActualRetailing'
import CorpBud from '../SetupDataPages/CorpBud';
import MREcomm from '../SetupDataPages/MREcomm';
import AccrualsHC from '../SetupDataPages/AccrualsHC';
import F1Inputs from '../SetupDataPages/F1Inputs';
import ITT from '../SetupDataPages/ITT';
import CTS from '../SetupDataPages/CTS';
import TDC from '../SetupDataPages/TDC';
import { getFiscalYearFormat } from "../spendplanUtils";

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	confirmationDialog: {
		'& .MuiDialog-paperWidthSm': {
			height: '80vh',
			maxWidth: '100%',
			width: '90%'
		},
		'& .MuiTab-root': {
			minWidth: 'auto !important',
		},
	},
	para: {
		fontSize: '28px !important',
		fontWeight: 600,
		color: '#000'
	},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box>
					{children}
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}


const SetupDataDialog = (props) => {
	let { open, handleClose, alertMessageText, confirm, pages, row } = props
	const modalData = props.modalData ? props.modalData : '';
	const classes = useStyles();
	const [value, setValue] = React.useState(0);
	const [setupPages, setSetupPages] = React.useState([]);

	const handleChange = (event, newValue) => {
		console.log('newValue ===', newValue);
		setValue(newValue);
	};

	useEffect(() => {
		let setupPagesList = []
		pages.map(p => {
			if (p.name === "BOP Volumes") {
				setupPagesList[0] = p
			} else if (p.name === "RLP per MSU") {
				setupPagesList[1] = p
			} else if (p.name === "Past Year Retailing") {
				setupPagesList[2] = p
			} else if (p.name === "Past Year RLP/MSU") {
				setupPagesList[3] = p
			} else if (p.name === "Channel Retailing Salience") {
				setupPagesList[5] = p
			} else if (p.name === "YTD Actual Retailing") {
				setupPagesList[4] = p
			} else if (p.name === "F1 Inputs") {
				setupPagesList[6] = p
			} else if (p.name === "Corp Bud") {
				setupPagesList[7] = p
			} else if (p.name === "MR & Ecomm") {
				setupPagesList[8] = p
			} else if (p.name === "Accruals & HC") {
				setupPagesList[9] = p
			} else if (p.name === "ITT") {
				setupPagesList[10] = p
			} else if (p.name === "CTS") {
				setupPagesList[11] = p
			} else if (p.name === "TDC") {
				setupPagesList[12] = p
			}
		})
		setSetupPages(setupPagesList)
	}, [pages])

	return (<>
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			disableBackdropClick={true}
			// disableEscapeKeyDown={true}
			disableEnforceFocus
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			className={classNames("app-spendplan", "spendplan-tpr", classes.confirmationDialog)}>
			<DialogContent className={classes.dialog}>
				<div className={classes.root}>
					{modalData ? (
						<Header header={`Setup Data - Version - ${modalData.monthName} ${getFiscalYearFormat(modalData.year)}`} />
					) : null}
					<AppBar position="static">
						<Tabs value={value} onChange={handleChange} variant='scrollable' aria-label="simple tabs example">
							<Tab label="BOP Volumes" {...a11yProps(0)} />
							<Tab label="RLP per MSU" {...a11yProps(1)} />
							<Tab label="Past Year Retailing" {...a11yProps(2)} />
							<Tab label="Past Year RLP/MSU" {...a11yProps(0)} />
							<Tab label="YTD Actual Retailing" {...a11yProps(1)} />
							<Tab label="Channel Retailing Salience" {...a11yProps(2)} />
							<Tab label="F1 Inputs" {...a11yProps(0)} />
							<Tab label="Corp Bud" {...a11yProps(1)} />
							<Tab label="MR & Ecomm" {...a11yProps(2)} />
							<Tab label="Accruals & HC" {...a11yProps(0)} />
							<Tab label="ITT" {...a11yProps(1)} />
							<Tab label="CTS" {...a11yProps(2)} />
							<Tab label="TDC" {...a11yProps(0)} />
						</Tabs>
					</AppBar>
					<TabPanel value={value} index={0}>
						<BOPVolumes isPopup={true} page={setupPages[0]} type="bopvolumes" objectKey="bopvolumes" view={true}
							brandform={row?.brandform} modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<RLPperMSU isPopup={true} page={setupPages[1]} type="rlppermsu" objectKey="boprlppermsu" view={true}
							brandform={row?.brandform} modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<PYRetailing isPopup={true} page={setupPages[2]} type="pyrlp" objectKey="rlpcrores" view={true}
							brandform={row?.brandform}
							channel={row?.channel} modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={3}>
						<PYRLPMSU isPopup={true} page={setupPages[3]} type="pyrlppermsu" objectKey={["hfsretailingcrores", "hfsretailingmsu"]}
							view={true} brandform={row?.brandform} modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={4}>
						<YTDActualRetailing isPopup={true} page={setupPages[4]} objectKey={["rlpcrores", "rlpsalience"]} type="rlpytdact"
							view={true} brandform={row?.brandform} channel={row?.channel}
							modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={5}>
						<ChannelRetailingSalience isPopup={true} page={setupPages[5]} objectKey="bopsalience" type="rlpsalience"
							view={true} brandform={row?.brandform} channel={row?.channel}
							modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={6}>
						<F1Inputs isPopup={true} page={setupPages[6]} objectKey="f1inputs" type="f1inputs"
							view={true} brandform={row?.brandform} channel={row?.channel}
							modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={7}>
						<CorpBud isPopup={true} page={setupPages[7]} objectKey="corpbud" type="corpbud"
							view={true} brandform={row?.brandform} channel={row?.channel}
							modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={8}>
						<MREcomm isPopup={true} page={setupPages[8]} objectKey="mrecomm" type="mrecomm"
							view={true} brandform={row?.brandform} channel={row?.channel}
							modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={9}>
						<AccrualsHC isPopup={true} page={setupPages[9]} objectKey="accrualshc" type="accrualshc"
							view={true} brandform={row?.brandform} channel={row?.channel} modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={10}>
						<ITT isPopup={true} page={setupPages[10]} type="itt" objectKey="itt" view={true} subChannel={row?.channel} modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={11}>
						<CTS isPopup={true} page={setupPages[11]} type="cts" objectKey="cts" view={true} channel={row?.channel} modalData={modalData ? modalData : ''} />
					</TabPanel>
					<TabPanel value={value} index={12}>
						<TDC isPopup={true} type="tdc" objectKey="tdc" page={setupPages[12]} view={true} modalData={modalData ? modalData : ''} />
					</TabPanel>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color="primary" onClick={() => handleClose()}
					className="btn btn-primary btn-color border-radius-0 text-color-white">
					{"Close"}
				</Button>
			</DialogActions>
		</Dialog>

	</>)
}

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(connect(mapStateToProps, null)(SetupDataDialog)));
