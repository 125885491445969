import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { TalkSheetService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* fetchTprData(action) {
    try {
        const tableData = yield fetchAsync(TalkSheetService.getTsData, action.payload);
        let id = action.payload['updateToCurrentFF']
        if (id) {
            id = Number(id)
        }
        yield put({ type: ActionTypes.GET_TS_DATA_SUCCESS, payload: tableData, updateToCurrentFF: id });
    }
    catch (err) {
        yield put({ type: ActionTypes.GET_TS_DATA_FAILURE, error: err?.response?.error || 'Something went wrong' });
    }
}

function* forCorpData(action) {
    try {
        const tableData = yield fetchAsync(TalkSheetService.getForCorpData, action.payload);
        yield put({ type: ActionTypes.GET_FORCORP_DATA_SUCCESS, payload: tableData });
    }
    catch (err) {
        yield put({ type: ActionTypes.GET_FORCORP_DATA_FAILURE, error: err?.response?.error || 'Something went wrong' });
    }
}

export function* talkSheetSaga() {
    // yield takeLatest(ActionTypes.GET_TS_DATA, fetchTprData);
    yield takeEvery(ActionTypes.GET_TS_DATA, fetchTprData);
    yield takeLatest(ActionTypes.GET_FORCORP_DATA, forCorpData);
}

export default talkSheetSaga;