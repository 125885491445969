import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Highcharts from '../HighchartsWrapper';
import {
	generateGUID
} from 'modules/page/page.module';

const StackedChart = (props) => {
	const [id, setId] = useState(`stacked-${generateGUID()}`)

	const highChartsRender = () => {

		Highcharts.chart({
			...props.options, chart: {
				type: 'column',
				renderTo: id,
			}
		});
	}

	useEffect(() => {
		highChartsRender()
	}, [props.options])


	return (
		id && <div id={id} style={{ width: (props.width || 400), margin: 'auto' }}
			className="chartEqualHeight"></div>
	)
};

const mapStateToProps = state => {
	return {
		pages: state.pages.page,
	};
};

export default connect(mapStateToProps, null)(StackedChart)