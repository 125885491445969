import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';
import EmailInput from './emailInput';
import Loading from '../Loading';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';

const useStyles = makeStyles(theme => ({
    container: { display: 'flex', justifyContent: 'center', alignItems: 'center', },
}));

const PingLogin = (props) => {

    const classes = useStyles();


    if(props.appLoading){
        return <Loading />
    }

    return (
        <div className={classNames("login-sec", classes.container)}>
            <Grid container>
                <Grid item md={6} lg={8}>
                    {/* <img src={Dsilologosmall} alt="" width="200px" /> */}
                    <Typography className="login-title" variant="h3">
                        Driving accelerated value creation and cost savings at enterprises through
                        <Typography variant="inherit" className="login-title textbold"> Automation, intelligence </Typography> and
                        <Typography variant="inherit" className="login-title textbold"> Trust</Typography>
                    </Typography>
                </Grid>

                <Grid item md={6} lg={4}>
                    <Paper className="login-frm">
                        <Typography component="h1" variant="h5">
                            SIGN IN
                            {/* <Typography className="r-align" component="label">
								New User? <Typography component="label">Sign Up</Typography>
							</Typography> */}
                        </Typography>
                        <EmailInput />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = ({ user, login, navigation, app }) => {
    return {
        appLoading: app.loading,
    }
}

export default hot(connect(mapStateToProps, null)(PingLogin));
