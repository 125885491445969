import React, { useState, useEffect, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import XLSX from 'xlsx';
import Container from '@material-ui/core/Container';
import {
	Dialog, Button, DialogActions, makeStyles, Slide,
	DialogContent, DialogContentText, DialogTitle
} from "@material-ui/core";

import { ActionButtons } from './ActionButtons';
import SetupEdit from './SetupEdit';
import SetupTable from './SetupTable';
import { FormAction } from './ActionButtons';

import { listDataModal } from './fakeData/JsonData';
import { group1, group2, field1, data2, data5, dropDownData } from './fakeData/fakeData';
import { getEntity } from 'store/actions/app'
import './measures.scss';

import Loading from '../ext-content/images/loading.gif'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
import axios from 'axios'
import defaultCategories from '../categories.json';
import DropDown from './Select/DropDown';
import BasicKeyMapping from './basicKeyMapping';

let defaultColHeaders = ["Coverage", "Participants", "Rate", "Premium"]
let defaultColMetrics = ["total", "count", "value", "count"];
let setupTypeValues = [{ name: "CensusMapping" }, { name: "BasicKeyMapping" }];

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const metrics = [
	{ value: "count", label: "Count" },
	{ value: "total", label: "Total" },
	{ value: "average", label: "Average" },
	{ value: "max", label: "Max" },
	{ value: "min", label: "Min" },
	{ value: "percentage", label: "Percentage" },
	{ value: "value", label: "value" }
]

const group = [
	{ id: 1, type: 'null' },
	{ id: 2, options: [] },
	{ id: 3, options: [] },
	{ id: 4, options: [] },
	// { id: 5, options: [] },
	// { id: 5, options: [] },
]

const field = [
	{ id: 1, options: metrics.map(m => m.value) },
	{ id: 2, input: { name: '', value: '' }, value: '' },
	{ id: 3, options: [] },
	{ id: 4, options: [] },
	{ id: 5, options: [] },
]

const textField = [
	{ id: 1, options: metrics.map(m => m.value) },
	{ id: 2, input: { name: '', value: '' }, value: 0 },
	{ id: 3, input: { name: '', value: '' }, value: 0 },
	{ id: 4, input: { name: '', value: '' }, value: 0 },
	{ id: 5, input: { name: '', value: '' }, value: 0 },
]

const defaultSetUpData = {
	groups: ['', ''].map(m => JSON.parse(JSON.stringify(group))),
	fields: ['', '', ''].map((m, i) => {
		if (i === 2) {
			let f = [...textField];
			f[0].value = defaultColMetrics[i]
			f[1].value = defaultColHeaders[i]
			return [...f]
		}
		let f = [...field];
		field[0].value = defaultColMetrics[i]
		field[1].value = defaultColHeaders[i]
		return JSON.parse(JSON.stringify(field))
	})
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
	pagination: {
		'& .MuiTablePagination-root': {
			backgroundColor: "#242323 !important",
			color: "white !important",

		},
		'& .MuiSelect-icon': {
			color: "white !important",
			top: '0px !important',
			right: "-5px !important"

		},
		'& .MuiIconButton-root.Mui-disabled': {
			color: "white !important"
		},
		textField: {
			"padding-right": "10px",
			"margin-top": "0px",
			"margin-bottom": "16px",
			"border-radius": 0
		}
	},
	dialog: {
		height: "300px",
		width: "600px",
		margin: '0px auto'
	},
	paymentDialog: {
		height: "80vh",
		width: "700px",
		margin: '0px auto'
	},
	mappingTypeCon: {
		width: '10%',
		marginLeft: '5.5%'
	}
}));


console.log('defaultSetUpData', defaultSetUpData);
/**
 * Setup component
 *
 * Renders the setup component
 */
function Setup(props) {
	const [state, setState] = useState({
		// groups: [],
		// groups: [group1, group2],
		// fields: [],
		// fields: [field1, field1, field1, field1],
		...JSON.parse(JSON.stringify(defaultSetUpData)),
		selectedData: {},
		isTableShow: false,
		setupExists: false,
		_id: null,
		data: null
	});
	const [open, setOpen] = React.useState(false);
	const [alertMessageText, setAlertMessage] = React.useState("");
	const [mappingType, setMappingType] = React.useState('BasicKeyMapping');

	console.log('defaultSetUpData inside', defaultSetUpData);

	useEffect(() => {
		// setState({...state, ...defaultSetUpData})
	}, [])

	const [fileData, setFileData] = useState({})
	const [resolution, setResolution] = useState()
	const [status, setStatus] = useState({})
	const [caseData, setCase] = useState()


	const [form, setForm] = useState({})
	const [categories, setCategories] = useState([...defaultCategories.categories])
	const inputRef = useRef(null)
	const classes = useStyles();

	const generateSetup = async (e) => {
		console.log('inside - generateSetup => form', form);
		handleFile(form.files[0])
		await uploadWithFormData(form.files[0])
	}

	/**
	 * Generate an array of column objects
	 *
	 * @param {string} refstr - Reference string for Excel
	 *
	 * @returns {Array}
	 */
	const makeCols = refstr => {
		let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
		for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
		return o;
	};

	/**
	 * File handler
	 * Reads excel sheet and updates state with headers and data 
	 * @param {File} file - Binary string of excel
	 *
	 * @returns {null}
	 */
	const handleFile = (file/*:File*/) => {
		/* Boilerplate to set up FileReader */
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
			/* Update state */
			console.log({ data: data, cols: makeCols(ws['!ref']), headers: data[0] });
			setFileData({ data: data, cols: makeCols(ws['!ref']), headers: data[0] })
			// state.groups.map(group => {
			// 	return group.map(g => ({ ...g, options: data[0] }))
			// })
			let f = state.fields.map(field => {
				return field.map((g, i) => i > 1 && g.options ? ({ ...g, options: data[0] }) : g)
			})
			console.log('categories handleFile', categories);
			categories.map(category => {
			})
			setState({ ...state, fields: f })
		};
		if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
	};

	const submitForm = (contentType, data, setResponse) => {
		let url;
		axios({
			url: API_BASE_URL + '/custom/resolution',
			method: 'POST',
			data: data,
			headers: {
				'Content-Type': contentType,
				appId: props.match.params.appid
			},
			onUploadProgress: (p) => {
				console.log("p", p);
			}
		}).then((response) => {
			setResponse(response.data);
		}).catch((error) => {
			setResponse({ "error": error });
		})
	}

	const uploadWithFormData = (file) => {
		setStatus('loading')
		const formData = new FormData();
		formData.append("file", file);
		return submitForm("multipart/form-data", formData, (res) => {
			console.log("res - fileupload", res)
			setResolution(res)
			setTimeout(() => {
				setStatus('done')
			}, 1000);
			if (res.error) {
				setStatus('done')
				setAlertMessage('File upload files' + res.error)
				handleClickOpen();
			}
		});
	}

	useEffect(() => {
		console.log('state.group', state.groups)
		return () => {
			console.log('ut of scope')
		}
	}, [state.groups])

	useEffect(() => {
		if (caseData && resolution && categories) {
			console.log('inside UE');
			console.log('caseData && resolution ===>', caseData, resolution, categories);

			let c = categories.map(category => {
				let columns = category.columns.map(col => {
					let column = { ...col }
					if (resolution[col.field] || resolution[col.field.trim()] ||
						resolution[col.field.toLowerCase()] || resolution[col.field.replace(' ', '')])
						column.field = resolution[col.field]
					return column
				})
				return { ...category, columns }
			})
			setCategories(c)
			console.log('c......', c);
			let fields = state.fields
			c.map(cat => {
				state.groups[0].map((group, i) => {
					console.log('cat.plan === group.value', cat.plan, group.value);
					if (cat.plan === group.value) {
						console.log('useEffect => grouping', cat.plan, group.value, cat.columns);
						cat.columns.map((col, j) => {
							console.log('field =>', col, j, i + 1, col.field, parseInt(col.field), fields[j][i + 1]);
							if (j !== 2) {
								console.log(isNaN(col.field), 'isNaN(col.field)', cat.plan === 'FMLA/ADA Administration', col.field, fields[j][i + 1].options.indexOf(col.field) === -1);
								if (cat.plan === 'FMLA/ADA Administration') {
									if (isNaN(col.field)) {
										fields[j][i + 1].value = 'FMLA/ADA Administration'
									} else {
										if (fields[j][i + 1].options &&
											fields[j][i + 1].options.indexOf('FMLA/ADA Administration') === -1) {
											fields[j][i + 1].options.push('FMLA/ADA Administration')
										}
										fields[j][i + 1].value = 'FMLA/ADA Administration'
									}
								} else {
									if (isNaN(col.field)) {
										fields[j][i + 1].value = col.field
									} else {
										if (fields[j][i + 1].options &&
											fields[j][i + 1].options.indexOf(cat.plan + ' Volume') === -1) {
											fields[j][i + 1].options.push(cat.plan + ' Volume')
										}
										fields[j][i + 1].value = cat.plan + ' Volume'
									}

								}
							}
						})
						// fields[i+1][0]
					}
				})
			})
			setState({ ...state, fields })
		}
		console.log('caseData && resolution ===> outside', caseData, resolution, categories);
	}, [caseData, resolution])

	const getCaseData = (loading) => {
		loading && setStatus('loading')
		console.log('getCaseData');
		let conditions = [{
			"selectedConditionDataElement": "EmployerName",
			"selectedConditionOperator": "matches",
			"value": props.entity.name,
			"selectedConditionClause": "and"
		}];

		getData('Case', 1, conditions, (res) => {
			setStatus('done')
			console.log('res == =>', res)
			if (res && Array.isArray(res) && res[0]) {
				let c = res[0]
				setCase(c)
				let groups = [...state.groups]
				let fields = [...state.fields]
				let ages = []
				let plans = c.Products.map((product, i) => {
					ages.push(product[2])
					return product[0]
				})

				// groups.map(group => {
				c.Products.map((product, i) => {
					// Plas
					if (groups[0][i + 1]) {
						groups[0][i + 1].options = plans
						groups[0][i + 1].value = product[0]
					} else {
						groups[0].push({ id: i + 1 + 1, options: plans, value: product[0] })
					}
					//Ages
					if (groups[1][i + 1]) {
						groups[1][i + 1].options = ages
						groups[1][i + 1].value = product[2]
					} else {
						groups[1].push({ id: i + 1 + 1, options: ages, value: product[2] })
					}
				})
				// })
				groups = groups.map(group => {
					console.log('c.Products.length', c.Products.length, group.length);
					group.splice(c.Products.length + 1)
					return group
				})
				console.log('resolution =-=', resolution);
				fields = fields.map((field, k) => {
					let f = c.Products.map((product, i) => {
						if (field[i + 2]) {
							if (k === 2)
								return { ...field[i + 2], value: product[3] }
							else
								return field[i + 2]
						} else {
							if (fileData && fileData.headers) {
								if (k === 2)
									return { id: i + 1 + 2, input: { name: '', value: '' }, value: product[3] }
								else
									return { id: i + 1 + 2, options: fileData.headers }
							} else {
								if (k === 2)
									return { id: i + 1 + 2, input: { name: '', value: '' }, value: product[3] }
								else
									return { id: i + 1 + 2, options: [] }
							}
						}
					})
					return [field[0], field[1], ...f]
				})

				setState({ ...state, groups, fields })

				// let group2 = c.Products.map((product, i) => {
				// 	return product[1]
				// })
				// state.groups.map(group => {
				// 	return g.
				// })
			} else {
				setStatus('done')
				// alert("You don't have any existing plans, Please contact your case processer.")
			}
		})
	}


	const getData = (collectionName, pageCount, conditions, setResponse) => {

		const uri = API_BASE_URL + "/dataconnect/data/" + collectionName + "?conditions=" + JSON.stringify(conditions) + "&page=0&rowsPerPage=" + (pageCount ? pageCount : 10)

		axios({
			url: uri,
			method: 'GET',
			headers: {
				'Content-Type': "application/json",
				appId: props.match.params.appid
				// appId: "5f2ab15279813563bcaa3887"
			},
		}).then((response) => {
			console.log("data", response)
			setResponse(response.data.data);


		}).catch((error) => {
			setStatus('done')
			setResponse({ "error": error });
			console.log("error", error)
		})
	}


	const handleInputChange = (evt) => {
		console.log('evt ==>', evt);
		getCaseData(false)
		setForm({
			...form,
			files: inputRef.current.files,
			fileName: inputRef.current.value
				? inputRef.current.files[0].name
				: ''
		});
	}

	const onClickHandler = type => {
		console.log(type);
		if (type === 'addGroup') {
			let newGroups = JSON.parse(JSON.stringify(state.groups));
			if (newGroups.length === 3) return alert('Only 3 groups can be added');
			newGroups.push(newGroups[1]);
			setState({ ...state, groups: newGroups });
		}
		if (type === 'addField') {
			let newFields = JSON.parse(JSON.stringify(state.fields));
			if (newFields.length === 5) return alert('Only 5 groups can be added');
			newFields.push(newFields[1]);
			setState({ ...state, fields: newFields });
		}
		if (type === 'addRow') {
			let newGroups = JSON.parse(JSON.stringify(state.groups));
			let newFields = JSON.parse(JSON.stringify(state.fields));
			newGroups.map(data => data.push({ id: data.length + 1, options: data[data.length - 1].options }));
			newFields.map(data => data.push({ id: data.length + 1, options: data[data.length - 1].options }));
			setState({ ...state, groups: newGroups, fields: newFields });
		}
	};
	const setFieldData = (name, value) => {
		let fieldInd = name.charAt(name.length - 1) - 1;
		let fields = JSON.parse(JSON.stringify(state.fields));
		if (value === 'SupportingDocument') {
			fields[fieldInd].map((item, index) => {
				if (index >= 3) {
					item.options = dropDownData.SupportingDocumentData;
				}
			});
		} else if (value === 'SupportingDoc') {
			fields[fieldInd].map((item, index) => {
				if (index >= 3) {
					item.options = dropDownData.SupportingDocData;
				}
			});
		} else if (value === 'EmployeePremium') {
			fields[fieldInd].map((item, index) => {
				if (index >= 3) {
					item.options = dropDownData.EmployeePremiumDat;
				}
			});
		}
		return fields;
	};
	const setGroupData = (name, value) => {
		// debugger
		let groupInd = name.charAt(name.length - 1) - 1;
		console.log(groupInd, "here", value)
		let groups = JSON.parse(JSON.stringify(state.groups));
		console.log(groups, "this si")
		if (value === 'SupportingDocument') {
			groups[groupInd].map((item, index) => {
				if (index === 1) {
					item.options = dropDownData.SupportingDocumentData;
				}
			});
		} else if (value === 'SupportingDoc') {
			groups[groupInd].map((item, index) => {
				if (index === 1) {
					item.options = dropDownData.SupportingDocData;
				}
			});
		}
		else if (value === 'EmployeePremium') {
			groups[groupInd].map((item, index) => {
				if (index === 1) {
					item.options = dropDownData.EmployeePremiumDat;
				}
			});
		}
		return groups;
	};
	// console.log(state)
	const onDropDownSelect = (e, pIndex, keyIndex) => {
		const { selectedData } = state;
		console.log(e.target.name, '---', e.target.value, '--', pIndex, '--', keyIndex);

		state.fields[pIndex][keyIndex].value = e.target.value
		setState({ ...state })
		// let newFiledsData, newGroupData;
		// if (keyIndex === 1 && e.target.name.includes('field')) {
		// 	newFiledsData = setFieldData(e.target.name, e.target.value);
		// }
		// if (keyIndex === 0 && e.target.name.includes('Group')) {
		// 	// debugger
		// 	newGroupData = setGroupData(e.target.name, e.target.value);
		// }
		// if (selectedData[e.target.name]) {
		// 	let newData = [...selectedData[e.target.name]];
		// 	// newData[keyIndex]=e.target.value;
		// 	for (let i = 0; i <= keyIndex; i++) {
		// 		if (i === keyIndex) {
		// 			newData[i] = e.target.value;
		// 		} else if (!newData[i]) {
		// 			newData[i] = '';
		// 		}
		// 	}
		// 	setState({
		// 		...state,
		// 		selectedData: {
		// 			...state.selectedData,
		// 			[e.target.name]: newData,
		// 		},
		// 		...(newFiledsData ? { fields: newFiledsData } : {}),
		// 		...(newGroupData ? { groups: newGroupData } : {}),
		// 	});
		// } else {
		// 	let arr = new Array(keyIndex).fill('');
		// 	arr[keyIndex] = e.target.value;
		// 	setState({
		// 		...state,
		// 		selectedData: {
		// 			...state.selectedData,
		// 			[e.target.name]: arr,
		// 		},
		// 		...(newFiledsData ? { fields: newFiledsData } : {}),
		// 		...(newGroupData ? { groups: newGroupData } : {}),
		// 	});
		// }
		// setState({...state,selectedData})
	};

	const onDeleteHandler = (name, id) => {
		console.log(name, id, 'ok');
		const copiedState = JSON.parse(JSON.stringify(state));
		if (name === 'row') {
			const newFields = copiedState.fields.map(field => field.filter(data => data.id !== id));
			const newGroups = copiedState.groups.map(group => group.filter(data => data.id !== id - 1));

			// for (let key in copiedState.selectedData) {
			//   if (key.includes('Group')) {
			// 	copiedState.selectedData[key].splice(index - 1, 1);
			//   } else if (key.includes('field')) {
			// 	copiedState.selectedData[key].splice(index, 1);
			//   }
			// }

			console.log(newGroups, newFields, 'here');
			setState({
				...state,
				fields: newFields,
				groups: newGroups,
				//   selectedData: copiedState.selectedData,
			});
			return;
		}
		copiedState[name].splice(id, 1)
		console.log(copiedState, "here")
		setState(copiedState)
	}

	const editMode = () => setState({ ...state, isTableShow: false });
	const editCancel = () => {

		let s = state.setupExists
			? { ...state, isTableShow: true }
			: {
				...state,
				...JSON.parse(JSON.stringify(defaultSetUpData)),
				selectedData: {},
				isTableShow: false,
				setupExists: false,
				_id: null
			}
		setState(s);
	}

	const generateCategoriesFromConfig = (state) => {
		let c = []
		let p = []
		let groups = state.groups
		let fields = state.fields
		groups.map((group, i) => {
			group.map((plan, j) => {
				if (j === 0) return
				console.log('plan ==== ', plan, c.indexOf(plan.value));
				let ind = c.indexOf(plan.value)
				if (i === 0) {
					if (ind === -1) {
						c.push(plan.value)
						p.push({
							"plan": plan.value,
							"columns": fields.map((field, f) => {
								return {
									"title": fields[f][1].value,
									"metricType": fields[f][0].value,
									"field": fields[f][j + 1].value
								}
							}),
							// r: {
							// 	g: plan,
							// 	f: fields.map((field, f) => fields[f][j + 1])
							// }
						})
					}
				} else {
					if (plan.value !== 'Any' && plan.value !== 'any') {
						console.log('groups[0][j]', groups[0][j].value, group);
						let index = c.indexOf(groups[0][j].value)
						console.log('p - c', p[index], p, c);
						p[index] = generateBand(plan.value, p[index], fields[2][j + 1].value)
						console.log('p[index] ==', p[index]);
					}

				}
			})
		})
		console.log('p, c', p, c)
		return p
	}

	const generateBand = (band, obj, rate) => {

		if (band.includes("Class") || band.includes("class")) {
			if (obj.multilevel !== "classbands")
				obj = {
					...obj,
					"multilevel": "classbands",
					"classbands": {
						"field": "Class",
						"bands": []
					}
				}
			// obj.classbands.bands.push(generateAgeBand(band, rate))
			obj.classbands.bands.push({
				type: "string",
				band: band,
				rate
			})
		} else {
			if (obj.multilevel !== "agebands")
				obj = {
					...obj,
					"multilevel": "agebands",
					"agebands": {
						"field": "Age",
						"bands": []
					}
				}
			obj.agebands.bands.push(generateAgeBand(band, rate))
		}
		return obj
	}

	const generateAgeBand = (band, rate) => {
		console.log('band, rate', band, rate);
		let v = band.split('to')
		if (v.length > 1) {
			v = v.map(b => b.trim())
			return {
				type: "range",
				band: band,
				min: v[0],
				max: v[1],
				rate
			}
		} else {
			v = band.split('Under')
			if (v.length > 1) {

				v = v[1].trim()
				return {
					type: "range",
					band: band,
					min: 0,
					max: v,
					rate
				}
			} else {
				let z = band.split('Over')
				if (z.length <= 1)
					z = band.split('Above')
				v = z[1].trim()
				return {
					type: "range",
					band: band,
					min: v,
					max: 100,
					rate
				}
			}
		}

	}
	const onSubmitData = () => {
		// console.log('state', state);
		let c = generateCategoriesFromConfig(state)
		setCategories(c)
		let data = {
			EmployerId: props.entity._id,
			memberId: props.member._id,
			entityId: props.entity._id,
			config: { fields: state.fields, groups: state.groups },
			categories: c
		}
		let url = API_BASE_URL + '/custom/setup'
		let method = 'POST'
		if (state.setupExists && state._id) {
			url = url + '/' + state._id
			method = 'PUT'
		}
		axios({
			url: url,
			method: method,
			data: data,
			headers: {
				appId: props.match.params.appid,
				'Content-Type': 'application/json',
			}
		}).then((res) => {
			console.log('response', res)
			// showAlert('Setup saved for successfully', {
			// 	id: 'ABD11',
			// 	position: 'top-left',
			// 	variant: 'success',
			// })
			setAlertMessage({ p1: 'Setup saved for successfully' })
			handleClickOpen();
			setState({ ...state, isTableShow: true });
		}).catch((error) => {
			console.log('error', error)
			setAlertMessage('Setup save is failed' + error)
			handleClickOpen();
		})
	};

	// useEffect(() => {
	// 	console.log(state);
	// }, [state.selectedData]);

	useEffect(() => {
		console.log('useEffect inti');
		props.getEntity(props.member.entityId, props.match.params.appid)
		axios({
			url: API_BASE_URL + '/custom/setup/' + props.member.entityId,
			method: 'GET',
			headers: {
				appId: props.match.params.appid,
				'Content-Type': 'application/json',
			}
		}).then((res) => {
			console.log('response ====', res)
			setStatus('done')
			if (res.data && res.data.config)
				setState({ ...state, ...res.data.config, isTableShow: true, setupExists: true, _id: res.data._id, data: res.data });
			else {
				if (props.entity && props.entity.name) {
					console.log('props.entity.name', props.entity);
					getCaseData()
				} else {
					setState({ ...state, setupExists: 'loadcase' })
				}
			}

		}).catch((error) => {
			setStatus('done')
			console.log('error', error)
		})
	}, [])

	useEffect(() => {
		console.log('state.setupExists ===', state.setupExists);
		if (props.entity && props.entity.name && state.setupExists === 'loadcase') {
			console.log('props.entity.name', props.entity);
			getCaseData()
		}
	}, [props.entity.name, state.setupExists])



	const handleClickOpen = () => {
		setOpen(true);
	};


	const handleClose = () => {
		setOpen(false);
	};

	console.log('state, === ', state, status);

	return (<div className='setup' style={{ paddingBottom: '30px', backgroundColor: isIBC ? '#f2f2f2' : undefined }}>
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#000" }}>{"Confirmation"}</DialogTitle>
			<DialogContent className={classes.dialog}>
				<DialogContentText id="alert-dialog-slide-description">
					{typeof alertMessageText == 'object'
						? <>
							<h3 style={{ color: '#000', textAlign: 'center' }}> {alertMessageText.p1} </h3>
							<h5 style={{ color: '#000', textAlign: 'center' }}> {alertMessageText.p2} </h5>
						</>
						: alertMessageText}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} className="btn btn-primary btn-color border-radius-0 text-color-white">
					{"OK"}
				</Button>
			</DialogActions>
		</Dialog>

		<div className="col-lg-12" style={{ margineft: '100px', marginTop: '0px', color: '#fff' }}>
			<h2 className={`text-center ${isIBC ? 'ibc-black-heading' : ''}`}>{"Census File Mapping"}</h2>
		</div>
		<div className="col-lg-12" style={{ marginLeft: '100px', marginRight: "100px" }}>
			<hr style={{ borderTop: '1px solid #fff;' }} />
		</div>
		{/* {!state.isTableShow && */}
		{/* <div className={`${classes.mappingTypeCon} ${isIBC ? 'ibc-drop-down-con' : ''}`}>
			<DropDown
				keyIndex={'MTKI'}
				value={mappingType}
				pIndex={'Mapping Type'}
				keyName={"Mapping Type"}
				options={setupTypeValues}
				labelName={"Mapping Type"}
				onDropDownSelect={({ target }) => {
					if (target && target.value && target.value.length > 0) {
						setMappingType(target.value);
					}
				}}
			/>
		</div> */}
		{/* } */}

		{/* <Container maxWidth={'lg'}> */}
		{!state.isTableShow && mappingType === 'CensusMapping' &&
			<div className="col-lg-8 file-upload-container">
				<div className="col-lg-3">
					<h5 className={`text-center ${isIBC ? 'ibc-black-heading' : ''}`}>Please provide sample data to generate dynamic setup</h5>
				</div>
				<div className="col-lg-6" style={{ display: "inline-block" }}>
					<input type='file' id="uploadInput"
						style={{ width: "600px", height: "35px", padding: "5px", backgroundColor: "#444", border: "none" }}
						onChange={handleInputChange}
						ref={inputRef}
					/>
					<p ><h5 id="uploadInputError" style={{ "color": "red" }}></h5></p>
				</div>
				<div className="col-lg-2" style={{ display: "inline-block", marginLeft: "20px" }}>
					<button className="btn btn-primary btn-color border-radius-0" id="btnAnalyze"
						onClick={e => generateSetup(e)}
					>Generate Setup</button>
				</div>
			</div>
		}

		{!state.isTableShow
			? mappingType === 'CensusMapping'
				? <React.Fragment>
					<ActionButtons onClickHandler={onClickHandler} />
					{status !== 'loading' && <><SetupEdit
						groups={state.groups}
						fields={state.fields}
						onDropDownSelect={onDropDownSelect}
						onSubmitData={onSubmitData}
						onDeleteHandler={onDeleteHandler}
					/>
						<FormAction onSubmitData={onSubmitData} /> </>}
				</React.Fragment>
				:
				<BasicKeyMapping {...props} state={state} categories={categories} />
			: mappingType === 'CensusMapping'
				? <React.Fragment>
					<div className='main-table'>
						<SetupTable tableData={state} />
					</div>
					<div className='table-action-btn'>
						<Button className="btn btn-primary btn-color border-radius-0" disableElevation={true} onClick={() => editMode()}>
							{"Edit Configuration"}
						</Button>
						<Button className="btn btn-primary btn-color border-radius-0" disableElevation={true} onClick={() => editCancel()}>
							{"Cancel"}
						</Button>
					</div>
				</React.Fragment>
				: <BasicKeyMapping {...props} state={state} categories={categories} />
		}

		{status === 'loading' && <div className="loading" style={{ marginLeft: "500px", marginTop: "100px" }}>
			<h3>Loading data.....</h3>
			<img src={Loading} />
		</div>}
		{/* </Container> */}
	</div>
	)
}

const mapDispatchToProps = {
	getEntity
};

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Setup)));

// export default withRouter(Setup);
