const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { request } from './../client';

export class TalkSheetService {
	static getTsData(data) {
		const payload = data.payload;
		let uri = API_BASE_URL + `/talkSheet/${payload.brand}/${payload.year}/${payload.currentFF}/${payload.scenario}`;
		if (payload.version) {
			uri += '?version=true'
		}

		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appId
			}
		});
	}

	static getForCorpData(data) {
		let uri = API_BASE_URL + '/report/forCorp' + data.url;
		return request(uri, {
			method: 'GET',
			headers: {
				appId: data.appId,
			},
		});
	}

}