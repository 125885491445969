import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/styles';

const StyledInput = withStyles({
	root: {
		'label + &': {
			marginTop: 25,
		},
	},

	input: {
		borderRadius: 4,
		position: 'relative',
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 12px',
		verticalAlign: 'top',
	},
})(InputBase);

export default StyledInput