import {
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearError } from '../../../../../../store/actions';
import Loader from '../../../../../Loader';
import { convertToNumber } from '../../../PlanningPages/planningHelperFunctions';
import ConfirmDialog from '../../../SpendPlan/ConfirmDialog';
import { columnsWithNumberValue, data, fixedWidthColumns75, fixedWidthColumns90, headingsLabels } from './data';

const useStyles = makeStyles(theme => ({
  tableBodyLoading: {
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100vh - 200px)',
  },
  textInput: {
    border: '1px solid lightgray',
    width: '100%',
    height: 75,
    background: '#fff',
    outline: 'none',
    resize: 'none',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Imar1Table = (props) => {
  const classes = useStyles();

  const { loading, imar1ReportData, error } = props.imar;
  const [reportData, setReportData] = useState(null);
  
  useEffect(() => {
    if (imar1ReportData) {
      mapResponse(props.modalData);
    } else if (reportData) {
      setReportData(null);
    }
  }, [imar1ReportData]);

  const mapResponse = (isVersionedData) => {
    //TODO: need to refactor this function when all 15 is done.
    try {
      let data1Rows = [], data2Rows = [], data3Rows = [], data4Rows = [], data5Rows = [], data6Rows = [], data7Rows = [],
        data8Rows = [],
        data9Rows = [], data10Rows = [], data11Rows = [], data12Rows = [], data13Rows = [], data14Rows = [],
        data15Rows = [];
      let response = isVersionedData ? (imar1ReportData[0] ? (imar1ReportData[0].detail || []) : []) : imar1ReportData
      response.forEach((item) => {
        if (item.Section === 1) {
          data1Rows.push(item);
        }
        if (item.Section === 2) {
          data2Rows.push(item);
        }
        if (item.Section === 3) {
          data3Rows.push(item);
        }
        if (item.Section === 4) {
          data4Rows.push(item);
        }
        if (item.Section === 5) {
          data5Rows.push(item);
        }
        if (item.Section === 6) {
          data6Rows.push(item);
        }
        if (item.Section === 7) {
          data7Rows.push(item);
        }
        if (item.Section === 8) {
          data8Rows.push(item);
        }
        if (item.Section === 9) {
          data9Rows.push(item);
        }
        if (item.Section === 10) {
          data10Rows.push(item);
        }
        if (item.Section === 11) {
          data11Rows.push(item);
        }
        if (item.Section === 12) {
          data12Rows.push(item);
        }
        if (item.Section === 13) {
          data13Rows.push(item);
        }
        if (item.Section === 14) {
          data14Rows.push(item);
        }
        if (item.Section === 15) {
          data15Rows.push(item);
        }
      });

      let _data = JSON.parse(JSON.stringify(data));
      _data['data1']['tableData']['rows'] = data1Rows;
      _data['data2']['tableData']['rows'] = data2Rows;
      _data['data3']['tableData']['rows'] = data3Rows;
      _data['data4']['tableData']['rows'] = data4Rows;
      _data['data5']['tableData']['rows'] = data5Rows;
      _data['data6']['tableData']['rows'] = data6Rows;
      _data['data7']['tableData']['rows'] = data7Rows;
      _data['data8']['tableData']['rows'] = data8Rows;
      _data['data9']['tableData']['rows'] = data9Rows;
      _data['data10']['tableData']['rows'] = data10Rows;
      _data['data11']['tableData']['rows'] = data11Rows;
      _data['data12']['tableData']['rows'] = data12Rows;
      _data['data13']['tableData']['rows'] = data13Rows;
      _data['data14']['tableData']['rows'] = data14Rows;
      _data['data15']['tableData']['rows'] = data15Rows;
      setReportData(_data);
    } catch (e) {
      console.log(e)
    }
  };

  if (loading || props.loading) {
    return (
      <section className={classes.tableBodyLoading}>
        <Loader />
      </section>
    );
  }

  const renderNoDataText = () => {
    let textToShow = 'No Data Found';
    return (
      <StyledTableRow>
        <StyledTableCell colSpan="9" style={{ textAlign: 'center' }}>
          <h4 style={{ color: '#000' }}>{textToShow}</h4>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const getBG = (value) => {
    let colors = {
      Yes: '#05b050',
      No: '#fe0100',
      NA: '#bfbfbf',
      TBC: '#ffff00',
    }
    return colors[value] || '#fff'
  }

  const renderHeadingRow = ({ num, text }) => {
    let remarks = '';
    let status = '';
    let ind = props.imarDetails?.length ? props.imarDetails.findIndex(i => i.section === num) : -1;
    if (ind > -1) {
      remarks = props.imarDetails[ind].remarks || ''
      status = props.imarDetails[ind].status || ''
    }
    return (
      <StyledTableRow>
        <StyledTableCell className='white-background row-bold'>
          <span>{num}</span>
        </StyledTableCell>
        <StyledTableCell className='row-bold top-border' style={{ textAlign: 'start' }} colSpan={8}>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </StyledTableCell>
        <StyledTableCell className='top-border' style={{ backgroundColor: getBG(status) }}>
          <Select
            style={{ height: 15 }}
            value={status}
            onChange={(e) => props.onChangeDetails('status', e.target.value, num, ind)}>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
            <MenuItem value="TBC">TBC</MenuItem>
            <MenuItem value="NA">NA</MenuItem>
          </Select>
        </StyledTableCell>
        <StyledTableCell rowSpan={2} colSpan={5} style={{ padding: '0px' }}>
          <textarea disabled={props.modalData || false} value={remarks} className={classes.textInput}
            onChange={(e) => props.onChangeDetails('remarks', e.target.value, num, ind)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const renderSubHeadingRows = (arr) => {
    return (<>
      {
        arr.map((i, index) =>
          <StyledTableRow key={index}>
            <StyledTableCell className='white-background'>
            </StyledTableCell>
            <StyledTableCell style={{ textAlign: 'start' }} colSpan={8}
              className={index + 1 === arr.length ? 'bottom-border' : ''}>
              <span>{i}</span>
            </StyledTableCell>
            {/*<StyledTableCell className={index + 1 === arr.length ? 'bottom-border' : ''}>*/}
            {/*</StyledTableCell>*/}
          </StyledTableRow>)
      }
      <StyledTableRow>
        <StyledTableCell className='white-background remove-right-border'>
        </StyledTableCell>
      </StyledTableRow>
    </>);
  };

  const renderTableHeading = (headings, _index) => {
    const getCss = (header) => {
      if (fixedWidthColumns75.includes(header)) {
        return { whiteSpace: 'normal', textAlign: 'center', width: 75 };
      }
      if (fixedWidthColumns90.includes(header)) {
        return { whiteSpace: 'normal', textAlign: 'center', width: 90 };
      }
      return { whiteSpace: 'normal', textAlign: 'center' };
    };

    const isBrandFormKey = (header) => {
      return [5,6,15].includes(_index) && header === 'Metrics'
    }
    return (
      <StyledTableRow className='sub-table-head'>
        <StyledTableCell className='white-background'>
        </StyledTableCell>
        {
          headings.map((header, index) =>
          (<StyledTableCell key={index} className='top-border' style={getCss(header)}>
            <span dangerouslySetInnerHTML={{ __html: headingsLabels[header] ? headingsLabels[header] : isBrandFormKey(header)?'Brandforms':header }} />
          </StyledTableCell>))
        }
      </StyledTableRow>
    );
  };

  const renderTableBody = (num, rows, headers, highlightLastThreeRow, percentageColumns) => {

    const arr = [3, 4, 5, 6, 7, 11, 12, 13, 15]; // last row class needed

    const getClass = (ind) => {
      let _class = '';
      if (ind + 1 === rows.length && arr.includes(num)) {
        _class += 'last-row';
      }
      if (highlightLastThreeRow && (ind + 2 === rows.length || ind + 3 === rows.length)) {
        _class += ' bold-blue-bg';
      }
      return _class;
    };

    const getClass1 = (ind, column, rowSpan) => {
      let _class = '';
      if (ind + 1 === rows.length || rowSpan === 2) {
        _class += 'bottom-border';
      }
      if (column['Description'] === 'Difference') {
        _class += ' bold-blue-bg';
      }
      return _class;
    };


    const leftAlignColumns = ['Description', 'Metrics', 'Channel', 'Brandforms'];

    const getVal = (header, i) => {
      if (percentageColumns?.includes(header)) {
        return i[header] === 0 ? '0.0%' : (Number(i[header])
          .toFixed(2) + '%');
      }
      return columnsWithNumberValue.includes(header) ? convertToNumber(i[header], 1) : i[header];
    };

    const getStyles = (header) => {
      let style = {};
      if (leftAlignColumns.includes(header)) {
        Object.assign(style, { textAlign: 'left', width: 175 });
      }
      if (['UoM', 'UoM_Cover', 'UoM_Trials', 'UoM_Pieces', 'UoM_Spend'].includes(header)) {
        Object.assign(style, { textAlign: 'center' });
      }
      if (fixedWidthColumns75.includes(header)) {
        Object.assign(style, { width: 75 });
      }
      if (fixedWidthColumns90.includes(header)) {
        Object.assign(style, { width: 90 });
      }
      return style;
    };

    const getBGColor = (key, value, rowItem, index) => {
      if (value === "" || num === 15 || ((num === 4 || num === 7 || num === 3) && index + 1 === rows.length)) {// 15th formatting not required
        return {}  // row 4 ,7, 3 will not be override with green, red, white color
      }
      let style = {};
      if (key === 'BOP_FY' && !(index + 1 === rows.length && arr.includes(num))) {  // excluding last row of table
        Object.assign(style, { backgroundColor: '#ffff99' })
      }

      if (num === 1 && rowItem['Description'] === 'Difference' && rowItem['Metrics'] === 'Volume') {
        if (['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'].includes(key)) {
          if (value > 0) {
            Object.assign(style, { backgroundColor: '#ff0000', color: '#ffffff' })
            return style;
          }
          if (value < 0) {
            Object.assign(style, { backgroundColor: '#05af50', color: '#ffffff' })
            return style;
          }
          if (value === 0) {
            Object.assign(style, { backgroundColor: '#ffffff' })
          }
        }
      }

      if (num === 1 && rowItem['Description'] === 'Difference' && rowItem['Metrics'] === 'Net Realization') {
        if (['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'].includes(key)) {
          if (value > 0) {
            Object.assign(style, { backgroundColor: '#05af50' })
            return style;
          }
          if (value < 0) {
            Object.assign(style, { backgroundColor: '#ff0000' })
            return style;
          }
          if (value === 0) {
            Object.assign(style, { backgroundColor: '#ffffff' })
          }
        }
      }
      if (num === 2 && rowItem['Description'] === 'Difference' && rowItem['Metrics'] === 'MSASD') {
        if (['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'].includes(key)) {
          if (value > 0) {
            Object.assign(style, { backgroundColor: '#05af50' })
            return style;
          }
          if (value < 0) {
            Object.assign(style, { backgroundColor: '#ff0000' })
            return style;
          }
          if (value === 0) {
            Object.assign(style, { backgroundColor: '#ffffff' })
          }
        }
      }
      if ((num === 3 || num === 4) && (key === 'H2_H1' || key === 'H1' || key === 'H2')) {
        if (value && Number(value.toString().replace("%", "")) > 0) {
          Object.assign(style, { backgroundColor: '#05af50' })
          return style;
        }
        if ((value && Number(value.toString().replace("%", "")) < 0) || (value.startsWith && value.startsWith("-"))) {
          Object.assign(style, { backgroundColor: '#ff0000' })
          return style;
        }
        if (value && Number(value.toString().replace("%", "")) === 0) {
          Object.assign(style, { backgroundColor: '#ffffff' })
        }
      }
      if ((num === 5 || num === 6) && key === 'H2_H1' || key === 'Delta') {
        if (value && Number(value.toString().replace("%", "")) > 0) {
          Object.assign(style, { backgroundColor: '#05af50' })
          return style;
        }
        if ((value && Number(value.toString().replace("%", "")) < 0) || (value.startsWith && value.startsWith("-"))) {
          Object.assign(style, { backgroundColor: '#ff0000' })
          return style;
        }
        if (value && Number(value.toString().replace("%", "")) === 0) {
          Object.assign(style, { backgroundColor: '#ffffff' })
        }
      }
      if (num === 7 && (key === 'Delta' || key === 'IYADeltaVsTotal')) {
        if (value > 0) {
          Object.assign(style, { backgroundColor: '#05af50' })
          return style;
        }
        if (value < 0 || (value.startsWith && value.startsWith("-"))) {
          Object.assign(style, { backgroundColor: '#ff0000' })
          return style;
        }
        if (value === 0) {
          Object.assign(style, { backgroundColor: '#ffffff' })
        }
      }
      if (num === 8 && rowItem['Description'] === 'Difference' &&
        ['Retailing (PM - MR)', 'KBD 1 TFI (PM - MR)', 'KBD 2 TFI (PM - MR)'].includes(rowItem['Metrics'])) {
        if (value > 0) {
          Object.assign(style, { backgroundColor: '#05af50' })
          return style;
        }
        if (value < 0 || (value.startsWith && value.startsWith("-"))) {
          Object.assign(style, { backgroundColor: '#ff0000' })
          return style;
        }
        if (value === 0) {
          Object.assign(style, { backgroundColor: '#ffffff' })
        }
      }
      if (num === 9 && rowItem['Description'] === 'Difference' &&
        ['Retailing (PM - Ecommerce)', 'Retailing (PM - Ecommerce)', 'Retailing (PM - Ecommerce)'].includes(rowItem['Metrics'])) {
        if (value > 0) {
          Object.assign(style, { backgroundColor: '#05af50' })
          return style;
        }
        if (value < 0 || (value.startsWith && value.startsWith("-"))) {
          Object.assign(style, { backgroundColor: '#ff0000' })
          return style;
        }
        if (value === 0) {
          Object.assign(style, { backgroundColor: '#ffffff' })
        }
      }
      if (num === 10 && rowItem['Description'] === 'Difference' &&
        ['Retailing (Corp - TS Sheet)', 'Retailing (Corp - TS Sheet)'].includes(rowItem['Metrics'])) {
        if (value > 0) {
          Object.assign(style, { backgroundColor: '#05af50' })
          return style;
        }
        if (value < 0 || (value.startsWith && value.startsWith("-"))) {
          Object.assign(style, { backgroundColor: '#ff0000' })
          return style;
        }
        if (value === 0) {
          Object.assign(style, { backgroundColor: '#ffffff' })
        }
      }


      if ([11, 12, 13].includes(num) && ['Spend_Change', 'Cover_Change', 'Trials_Change', 'SKU_Change'].includes(key)) {
        if (value > 0) {
          Object.assign(style, { backgroundColor: '#ff0000' })
          return style;
        }
        if (value < 0 || (value.startsWith && value.startsWith("-"))) {
          Object.assign(style, { backgroundColor: '#05af50' })
          return style;
        }
        if (value === 0) {
          Object.assign(style, { backgroundColor: '#ffffff' })
        }
      }
      if (num === 14) {
        if (key === 'PY_AMJ') {
          Object.assign(style, { backgroundColor: '#ffff99' })
          return style;
        }
        if (['JAS_Perc', 'OND_Perc', 'JFM_Perc', 'AMJ_Perc'].includes(key)) {
          if (value && Number(value.toString().replace("%", "")) > 0) {
            Object.assign(style, { backgroundColor: '#05af50' })
            return style;
          }
          if ((value && Number(value.toString().replace("%", "")) < 0) || (value.startsWith && value.startsWith("-"))) {
            Object.assign(style, { backgroundColor: '#ff0000' })
          }
        }

      }

      return style;
    }

    const getRowSpan = (num, rowIndex, colIndex) => {
      return (num === 8 || num === 9) && (rowIndex === 0 || rowIndex === 2) && colIndex === 0 ? 2 : 1;
    };

    const hideCell = (num, rowIndex, colIndex) => {
      return (num === 8 || num === 9) && (rowIndex === 1 || rowIndex === 3) && colIndex === 0;
    };

    const formatting = (row) => {
      return (row['UoM']?.includes('INR') || row['UoM']?.includes('Crores')) ? 'en-In' : 'en-US';
    }

    return <>
      {
        rows.map((i, index) => {
          return (
            <StyledTableRow key={index} className={getClass(index)}>
              <StyledTableCell className='white-background remove-bottom-border'>
              </StyledTableCell>
              {
                headers.map((header, index1) => {
                  let rowSpan = getRowSpan(num, index, index1);
                  if (!hideCell(num, index, index1))
                    return (
                      <StyledTableCell key={index1}
                        style={((index + 1 !== rows.length || !arr.includes(num)) || index + 1 === rows.length && arr.includes(num)) ? { ...getStyles(header), ...getBGColor(header, i[header] || "", i, index) } : { ...getStyles(header) }}
                        className={getClass1(index, i, rowSpan)}
                        rowSpan={rowSpan}>
                        <span title={((i[header] + "") + (percentageColumns?.includes(header) ? '%' : ''))}>{getVal(header, i) ? getVal(header, i).toLocaleString(formatting(i)) : ''}</span>
                      </StyledTableCell>
                    );
                })
              }
            </StyledTableRow>
          );
        })
      }
      <StyledTableRow>
        <StyledTableCell className='white-background remove-right-border top-border'>
        </StyledTableCell>
      </StyledTableRow>
    </>;
  };

  return (
    <div className='imar1-report'>
      <TableContainer className='table-container' component={Paper}>
        <Table className='table' aria-label="simple table"
        >
          <TableHead className='table-head'>

            <TableRow>
              <TableCell style={{ width: 60 }} colSpan={1}>
                <span>S NO</span>
              </TableCell>
              <TableCell colSpan={8}>
                <span>IMAR Checklist</span>
              </TableCell>
              <TableCell colSpan={1}>
                <span>Status</span>
              </TableCell>
              <TableCell colSpan={5}>
                <span>Remarks/Next Steps</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='table-body'>
            {!reportData && renderNoDataText()}
            {
              reportData &&
              <>
                <>
                  {/*table 1 start*/}
                  {
                    renderHeadingRow({ num: 1, text: reportData.data1.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data1.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data1.tableData.headings)
                  }
                  {
                    renderTableBody(1, reportData.data1.tableData.rows, reportData.data1.tableData.headings)
                  }
                  {/*table 1 end*/}
                </>
                <>
                  {/*table 2 start*/}
                  {
                    renderHeadingRow({ num: 2, text: reportData.data2.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data2.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data2.tableData.headings)
                  }
                  {
                    renderTableBody(2, reportData.data2.tableData.rows, reportData.data2.tableData.headings)
                  }
                  {/*table 2 end*/}
                </>
                <>
                  {/*table 3 start*/}
                  {
                    renderHeadingRow({ num: 3, text: reportData.data3.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data3.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data3.tableData.headings)
                  }
                  {
                    renderTableBody(3, reportData.data3.tableData.rows, reportData.data3.tableData.headings,
                      null, ['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY', 'H2_H1'])
                  }
                  {/*table 3 end*/}
                </>
                <>
                  {/*table 4 start*/}
                  {
                    renderHeadingRow({ num: 4, text: reportData.data4.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data4.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data4.tableData.headings)
                  }
                  {
                    renderTableBody(4, reportData.data4.tableData.rows, reportData.data4.tableData.headings, null,
                      ['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY', 'H1', 'H2', 'H2_H1'])
                  }
                  {/*table 4 end*/}
                </>
                <>
                  {/*table 5 start*/}
                  {
                    renderHeadingRow({ num: 5, text: reportData.data5.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data5.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data5.tableData.headings, 5)
                  }
                  {
                    renderTableBody(5, reportData.data5.tableData.rows, reportData.data5.tableData.headings, null,
                      ['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY', 'H2_H1', 'RetailingSalience', 'SpendSalience', 'Delta'])
                  }
                  {/*table 5 end*/}
                </>
                <>
                  {/*table 6 start*/}
                  {
                    renderHeadingRow({ num: 6, text: reportData.data6.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data6.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data6.tableData.headings, 6)
                  }
                  {
                    renderTableBody(6, reportData.data6.tableData.rows, reportData.data6.tableData.headings, null,
                      ['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY', 'H2_H1', 'RetailingSalience', 'SpendSalience', 'Delta'])
                  }
                  {/*table 6 end*/}
                </>
                <>
                  {/*table 7 start*/}
                  {
                    renderHeadingRow({ num: 7, text: reportData.data7.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data7.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data7.tableData.headings)
                  }
                  {
                    renderTableBody(7, reportData.data7.tableData.rows, reportData.data7.tableData.headings, true,
                      ['PY', 'BOP', 'Delta'])
                  }
                  {/*table 7 end*/}
                </>
                <>
                  {/*table 8 start*/}
                  {
                    renderHeadingRow({ num: 8, text: reportData.data8.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data8.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data8.tableData.headings)
                  }
                  {
                    renderTableBody(8, reportData.data8.tableData.rows, reportData.data8.tableData.headings)
                  }
                  {/*table 8 end*/}
                </>
                <>
                  {/*table 9 start*/}
                  {
                    renderHeadingRow({ num: 9, text: reportData.data9.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data9.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data9.tableData.headings)
                  }
                  {
                    renderTableBody(9, reportData.data9.tableData.rows, reportData.data9.tableData.headings)
                  }
                  {/*table 9 end*/}
                </>

                <>
                  {/*table 10 start*/}
                  {
                    renderHeadingRow({ num: 10, text: reportData.data10.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data10.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data10.tableData.headings)
                  }
                  {
                    renderTableBody(10, reportData.data10.tableData.rows, reportData.data10.tableData.headings)
                  }
                  {/*table 10 end*/}
                </>
                <>
                  {/*table 11 start*/}
                  {
                    renderHeadingRow({ num: 11, text: reportData.data11.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data11.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data11.tableData.headings)
                  }
                  {
                    renderTableBody(11, reportData.data11.tableData.rows, reportData.data11.tableData.headings)
                  }
                  {/*table 11 end*/}
                </>
                <>
                  {/*table 12 start*/}
                  {
                    renderHeadingRow({ num: 12, text: reportData.data12.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data12.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data12.tableData.headings)
                  }
                  {
                    renderTableBody(12, reportData.data12.tableData.rows, reportData.data12.tableData.headings)
                  }
                  {/*table 12 end*/}
                </>
                <>
                  {/*table 111 start*/}
                  {
                    renderHeadingRow({ num: 13, text: reportData.data13.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data13.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data13.tableData.headings)
                  }
                  {
                    renderTableBody(13, reportData.data13.tableData.rows, reportData.data13.tableData.headings)
                  }
                  {/*table 13 end*/}
                </>
                <>
                  {/*table 14 start*/}
                  {
                    renderHeadingRow({ num: 14, text: reportData.data14.headingData })
                  }
                  {
                    renderSubHeadingRows(reportData.data14.subHeadingData)
                  }
                  {
                    renderTableHeading(reportData.data14.tableData.headings)
                  }
                  {
                    renderTableBody(14, reportData.data14.tableData.rows, reportData.data14.tableData.headings, false,
                      ['JAS_Perc', 'OND_Perc', 'JFM_Perc', 'AMJ_Perc'])
                  }
                  {/*table 14 end*/}
                </>

                {/*table 15 start*/}
                {
                  renderHeadingRow({ num: 15, text: reportData.data15.headingData })
                }
                {
                  renderSubHeadingRows(reportData.data15.subHeadingData)
                }
                {
                  renderTableHeading(reportData.data15.tableData.headings, 15)
                }
                {
                  renderTableBody(15, reportData.data15.tableData.rows, reportData.data15.tableData.headings, false, ['H1', 'H2', 'H2_H1'])
                }
                {/*table 15 end*/}
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {error && <ConfirmDialog open={error !== null} handleClose={props.clearError} alertMessageText={{
        title: 'Error Loading Page',
        p2: 'There was an error loading this content. Please reload the page or try again later.', p4: error
      }} classes={classes} />}

    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    imar: state.ImarReportsReducer,
  };
};

const mapDispatchToProps = {
  clearError,
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Imar1Table)));
