import { hot } from 'react-hot-loader/root';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SpendPlan from '../../SpendPlan';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const AccrualsHC = props => {
    return <SpendPlan {...props} />
}

const mapDispatchToProps = {
}

const mapStateToProps = ({ user, navigation }) => {
    return {}
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(AccrualsHC)))
