import { Button, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, Input, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Step, StepLabel, Stepper, Switch, Tab, Tabs, TextField, Typography, withStyles, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import classNames from 'classnames';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import StepConnector from '@material-ui/core/StepConnector';
import { bindActionCreators } from 'redux';
import { getMemberById, loadPages, getAllForms } from 'store/actions';
import moment from 'moment'
// import { apiUrl } from '../constants/constants';
import InnerForm from './InnerForm';
import InnerCard from './InnerCard';
import MultiLine from './MultiLine';
import TableStatic from './TableStatic';
import './formbuilder.css'
import Upload from './Upload'
import DataSelector from './DataSelector'
import DataTable from './DataTable'
import GetAppIcon from '@material-ui/icons/GetApp';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#2196f3',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#2196f3',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const styles = theme => ({
    grid: {
        paddingLeft: '20px',
        paddingRight: "20px"
    },
    textField: {
        height: 48,
        "padding-right": "10px",
        "margin-top": "0px",
        "margin-bottom": "16px",
        "border-radius": 0,
        '& .MuiOutlinedInput-multiline': {
            padding: 0,
        },
        '& .MuiInput-input':{
            border: '1px solid rgba(0, 0, 0, 0.23)'
        }
    },
    label: {
        display: "block !important"
    },
    title: {
        color: '#fff',
    },
    formlabel_renderer: {
        "padding-top": "20px",
        "padding-left": "0px",
        minWidth: 100,
        display: "block"
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    modal: {
        top: `30%`,
        left: `30%`,
        transform: `translate(-30%, -30%)`,
        position: 'absolute',

    },
    inputLabel: {
        "padding-bottom": "5px",
        "position": "static",
        "font-weight": 900,
        color: 'red'
    },
    native_select_dropdown: {
        "padding-bottom": "10px", "width": "100%"
    },
    spacing_element: {
        "padding-bottom": "10px", "padding-top": "10px"
    },
    stepperIcon: {
        xwidth: "3em",
        xheight: "3em"
    },
    nativeStyle:{
        paddingLeft: '15px',
        paddingRight: '15px',
        marginBottom: '15px',
        '& .MuiNativeSelect-select':{
            border: '1px solid #ccc',
            marginRight: '0 !important',
            '& .option-style':{
                backgroundColor:'#fff !important'
            }
        },
    },
    radioStyle:{
        '& .MuiSvgIcon-root':{
            fontSize:'17px'
        }
    }
});

class FormElements extends Component {
    constructor(props) {
        super(props);

        // let activeStep = props.submittedDataToBeEdited ?
        //     (props.submittedDataToBeEdited.workflow && props.submittedDataToBeEdited.workflow.isDraft
        //         ? 1
        //         : actions.length + 1
        //     )
        //     : 1
        this.state = {
            isLoading: true,
            design: {
                rows: []
            },
            hoverElement: "",
            activeStep: 1,
            activeStepCard: 1,
            activeCard: 1,
            dataModelValue: [],
            workflowTask: [],
            items: [{ name: 'A', id: 'A' }],
            selectedItem: [],
            lookupDataModelInnerValue: null,
            open: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.submittedDataToBeEdited && state.isLoading) {
            let actions = []
            if (props.submittedDataToBeEdited && props.submittedDataToBeEdited.actions) {
                function getUnique(arr, comp) {
                    // store the comparison  values in array
                    const unique = arr.map(e => e[comp])
                        // store the indexes of the unique objects
                        .map((e, i, final) => final.indexOf(e) === i && i)
                        // eliminate the false indexes & return unique objects
                        .filter((e) => arr[e]).map(e => arr[e]);

                    return unique;
                }
                actions = getUnique(props.submittedDataToBeEdited.actions, 'action')
            }

            let activeStep = props.submittedDataToBeEdited ?
                (props.submittedDataToBeEdited.workflow && props.submittedDataToBeEdited.workflow.isDraft
                    ? 1
                    : actions.length + 1
                )
                : 1;
            return {
                activeStep: activeStep,
                activeStepCard: activeStep,
                activeCard: activeStep,
                isLoading: false
            }
        }
    }

    componentDidMount() {
        // ExternalScripts["testPopulate"]();
        const { dataType, dataModel, dataElement, computed, lookupDataModal, lookupDataElement,
            lookupDataKey, workflow, type, listType } = this.props.item

        if (dataType === 'dataModel') {
            this.getDataModel(`${API_BASE_URL}/dataconnect/${dataModel}?q=${dataElement}&pagination=false`);
        }

        if (computed === 'Lookup' && type !== 'card') {
            this.getDataModelValueForLookup(`${API_BASE_URL}/dataconnect/${lookupDataModal}?q=${lookupDataElement},${lookupDataKey}&pagination=false`)
        }

        if (type === 'namePicker') {
            let path = window.location.pathname.split('/')
            let appId = path[2];
            this.props.getMemberById(appId);
        }
        console.log('typezzzz', type);
        if (type === 'pageOrFormPicker') {
            let path = window.location.pathname.split('/')
            let appId = path[2];
            console.log('pageOrFormPicker ==?>.', type);
            if (listType === 'form')
                this.props.getAllForms(appId);
            else
                this.props.loadPages(appId);
        }
    }

    componentDidUpdate(props, state) {
        const { dataType, dataModel, dataElement, computed, lookupDataModal, lookupDataElement, lookupDataKey, lookupDataValue, workflow, type } = this.props.item

        if (this.props.filledData && this.props.filledData[lookupDataValue]) {
            if (this.props.filledData[lookupDataValue] !== props.filledData[lookupDataValue])
                if (computed === 'Lookup' && type === 'card') {
                    this.getDataModelCardValueForLookup(`${API_BASE_URL}/dataconnect/${lookupDataModal}?type=list&fields=all&page=0&rowsPerPage=10&key=${lookupDataKey}&value=${this.props.filledData[lookupDataValue]}`)
                }
        }
    }

    handleChange = selectedItem => {
        if (this.state.selectedItem.includes(selectedItem)) {
            this.removeSelectedItem(selectedItem);
        } else {
            this.addSelectedItem(selectedItem);
        }
    };

    addSelectedItem(item) {
        this.setState(({ selectedItem, items }) => ({
            inputValue: "",
            selectedItem: [...selectedItem, item],
            items: items.filter(i => i.name !== item)
        }));
    }

    removeSelectedItem = item => {
        this.setState(({ selectedItem, items }) => ({
            inputValue: "",
            selectedItem: selectedItem.filter(i => i !== item),
            items: [...items, { name: item, id: item.toLowerCase() }]
        }));
    };

    handleChangeInput = inputVal => {
        const t = inputVal.split(",");
        if (JSON.stringify(t) !== JSON.stringify(this.state.selectedItem)) {
            this.setState({ inputValue: inputVal });
        }
    };

    getDataModelValueForLookup(url) {
        axios.get(url, { headers: { appId: this.props.match.params.appid, 'Content-Type': 'application/json', } })
            .then(res => {
                let lookupDataModelValue = res.data.data
                this.setState({ lookupDataModelValue: lookupDataModelValue })
            })
    }
    getDataModelCardValueForLookup(url) {
        axios.get(url, { headers: { appId: this.props.match.params.appid, 'Content-Type': 'application/json',} })
            .then(res => {
                this.setState({ lookupDataModelInnerValue: res.data.data })
            })
    }
    getDataModel = (url) => {
        if (!url) {
            this.setState({ dynamicData: null, apiUrl: null });
        }
        else {
            axios.get(url, { headers: { appId: this.props.match.params.appid, 'Content-Type': 'application/json',} })
                .then(res => {
                    let dataModelValue = []
                    const { dataElement, type } = this.props.item
                    for (let i = 0; i < res.data.data.length; i++) {
                        let val = res.data.data[i][dataElement]
                        if (type == 'radio' || type === 'checkbox') {
                            dataModelValue.push({
                                value: val,
                                label: val
                            })
                        } else {
                            dataModelValue.push(val)
                        }
                    }
                    this.setState({ dataModelValue })
                }).catch((err) => {
                    alert("Unable to fetch data from the provided endpoint" + err);
                })
        }
    }

    elementDragStart = (event, selectedElement) => {
        if (this.props.viewOnly) {
            return;
        }
        this.selectedElement = selectedElement;
        console.log("dragstart")
        console.log(this.selectedElement, "dragged element")
        console.log(this, "elementdragthis")
        // this.props.draggedElement(this.selectedElement);

    }

    downloadUploadedFile = (name) => {
        console.log(name, "name of file to be downloaded")
        axios({
            // url: `${apiUrl}image/${fileName}`,
            url: `${API_BASE_URL}/submittedData/images/${name}`,
            method: 'GET',
            headers: { appId: this.props.match.params.appid }
        }).then((response) => {
            console.log(response.data, "response data");
            var a = document.createElement("a");
            a.href = "data:image/png;base64," + response.data;
            a.download = name;
            a.click();
        });
    };

    elementDropped = (e, selectedPosition) => {
        console.log("view Only", this.props.viewOnly);
        if (this.props.viewOnly) {
            return;
        }
        console.log("elementdropcalled", selectedPosition);
        console.log(this, "drop this")
        this.selectedPosition = selectedPosition;
        console.log("elements rearrage", this.selectedElement, this.selectedPosition)
        this.props.rearrangeElements(this.state.selectedElement, this.selectedPosition);
    }
    showEditOptions = (itemPosition) => {
        if (!this.props.viewOnly) {
            this.setState({ hoverElement: itemPosition })
        }
    }

    isPanelManage = (label) => {
        //TODO: Need to discuss with N.K.
        if (this.props.item.panelVisibility.length === 0) {
            return { isDisable: true, isShow: true }
        }
        let findData = this.props.item.panelVisibility && this.props.item.panelVisibility.find((item) => {
            return item.name === label;
        })

        const { event, actions } = this.props
        let action = actions && actions.find((item) => {
            return item.action === findData.event;
        })
        if (action) {
            return { isDisable: true, isShow: true }
        }
        else if (findData.event === event) {
            return { isDisable: false, isShow: true }
        }
        else {
            return { isDisable: false, isShow: false }
        }

    }

    isMultiStepFormCard = (index) => {
        if (this.state.activeStepCard > index + 1) {
            return true
        }
    }


    render() {
        //console.log(this.state.selectedElement, "selectedElement in render");
        const { classes } = this.props;
        console.log("form builder");
        console.log("rows data render", this.state)
        console.log("data filled", this.props.filledData)
        const { item, outerIndex, gridSize, index } = this.props;
        let itemPosition = `${outerIndex}-${index}`;
        console.log(item, "min in json", this.props.editSubmittedData);
        const { selectedItem, items } = this.state;

        return (
            <>
                <Grid style={item.backgroundColor ? { backgroundColor: item.backgroundColor } : {}}
                    // onMouseEnter={() => { this.showEditOptions(itemPosition) }}
                    // onMouseLeave={() => { this.setState({ hoverElement: "" }) }}
                    className={classNames(classes.grid,"p-left-0 p-right-0")}
                    // draggable="true"

                    onDragStart={(e) => {
                        this.setState({ selectedElement: `${outerIndex}-${index}` }); this.selectedElement = `${outerIndex}-${index}`;
                        ; this.elementDragStart(e, `${outerIndex}-${index}`)
                    }}
                    onDragOver={(event) => event.preventDefault()}
                    onDrop={(e) => { e.stopPropagation(); this.elementDropped(e, `${outerIndex}-${index}`) }}
                    item xs={item.columns ? item.columns : gridSize} key={outerIndex + "-" + index}>
                    {this.generateElement()}
                </Grid>
            </>
        )
    }

    generateElement = () => {
        const { classes } = this.props;
        const { item, outerIndex, gridSize, index } = this.props;
        let itemPosition = `${outerIndex}-${index}`;
        let visibility = item.hidden ? "hidden" : "visible";
        if (item.validation && item.validation.required && !item.label.labelText.endsWith("*")) {
            item.label.labelText += "*";
        }

        else if ((item.type == 'select' || item.type == 'text' || item.type == 'multiselect' ||
            item.type == 'date' || item.typeOfText == 'number' || item.typeOfText == 'time' ||
            item.typeOfText == 'datetime-local' || item.typeOfText == 'password' || item.typeOfText == 'email' ||
            item.typeOfText == 'tel') && item.required && !item.label.endsWith("*")) {
            item.label += "*";
        }

        if (item.type == 'text' && item.rows != null) {
            let value;
            if (item.computed === 'Computed') {
                let formula = item.formula;
                let result;
                const { id, displayName, email } = this.props.user

                if (formula === '{CURRENT_USER_EMAIL}') {
                    result = email
                } else if (formula === '{CURRENT_USER_FULLNAME}') {
                    result = displayName
                } else if (formula === '{CURRENT_USER_ID}') {
                    result = id
                } else if (formula === '{CURRENT_DATE}') {
                    result = new Date()
                } else if (formula === '{CURRENT_DATETIME}') {
                    result = new Date()
                } else {
                    result = formula.replace(/\{(.*?)\}/g, (match, key) => {
                        return this.props.filledData[key] && this.props.filledData[key];
                    });
                }

                value = result
            } else if (item.computed === 'Lookup') {
                const { lookupDataElement, lookupDataKey, lookupDataValue } = item
                if (this.state.lookupDataModelValue && this.props.filledData[item.lookupDataValue]) {
                    let filterData = this.state.lookupDataModelValue.filter(data => {
                        return data[lookupDataKey] === this.props.filledData[item.lookupDataValue]
                    })
                    value = filterData && filterData[0][lookupDataElement]
                }
            } else {
                value = this.props.filledData[item.name] || item.defaultValue
            }


            return (
                <>
                    {item.labelPosition == 'top' && <InputLabel className={classNames(classes.inputLabel)}>{item.label}</InputLabel>}
                    <TextField className={classNames(classes.textField)}
                        style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
                        onChange={(e) => {
                            this.props.onChange(item.name, e.target.value, item.type)
                        }}
                        margin="normal"
                        value={value || this.props.filledData[item.name]}
                        variant={item.variant ? item.variant : "standard"}
                        InputProps={item.labelPosition == 'end' ? {
                            endAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                        } : (item.labelPosition == 'start' ? {
                            startAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                        } : {})}
                        multiline
                        fullWidth
                        disabled={item.disabled || !this.props.editSubmittedData || item.computed !== 'Editable'}
                        // value={this.props.filledData[item.name]}
                        required={item.required}
                        inputProps={{ minLength: item.minLength, maxLength: item.maxLength, rows: item.rows }}
                    />
                </>

            )
        }
        if (item.type == 'text') {
            let value;

            if (item.computed === 'Computed') {

                let formula = item.formula;
                const { id, displayName, email } = this.props.user
                let result;
                if (formula === '{CURRENT_USER_EMAIL}') {
                    result = email
                } else if (formula === '{CURRENT_USER_FULLNAME}') {
                    result = displayName
                } else if (formula === '{CURRENT_USER_ID}') {
                    result = id
                } else if (formula === '{CURRENT_DATE}') {
                    result = new Date();
                } else if (formula === '{CURRENT_DATETIME}') {
                    result = new Date()
                } else {
                    result = formula.replace(/\{(.*?)\}/g, (match, key) => {
                        return this.props.filledData[key] && this.props.filledData[key];
                    });
                }
                value = result

            } else if (item.computed === 'Lookup') {
                const { lookupDataElement, lookupDataKey, lookupDataValue } = item
                if (this.state.lookupDataModelValue && this.props.filledData[item.lookupDataValue]) {
                    let filterData = this.state.lookupDataModelValue.filter(data => {
                        return data[lookupDataKey] === this.props.filledData[item.lookupDataValue]
                    })
                    value = filterData && filterData[0][lookupDataElement]
                }

            } else {
                if (this.props.parentType === 'card') {
                    const { parentIndex, parentName, lookupDataModelInnerValue, computed } = this.props;
                    if (computed === 'Lookup') {
                        value = lookupDataModelInnerValue && lookupDataModelInnerValue[0][item.name] || ''
                    } else {
                        value = this.props.filledData[parentName] && this.props.filledData[parentName][parentIndex] && this.props.filledData[parentName][parentIndex][item.name] || item.defaultValue
                    }
                } else {
                    value = this.props.filledData[item.name] || item.defaultValue
                }
            }
            return (
                <>
                    {item.labelPosition == 'top' && <InputLabel className={classNames(classes.inputLabel)}>{item.label}</InputLabel>}
                    <TextField className={classNames(classes.textField)}
                        style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
                        onChange={(e) => {
                            this.props.onChange(item.name, e.target.value, item.type)
                        }}
                        margin="normal"
                        variant={item.variant ? item.variant : "standard"}
                        InputProps={item.labelPosition == 'end' ? {
                            endAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                        } : (item.labelPosition == 'start' ? {
                            startAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                        } : {})}
                        value={value}
                        fullWidth
                        disabled={item.disabled || !this.props.editSubmittedData || item.computed !== 'Editable'}
                        required={item.required}
                        inputProps={{ minLength: item.minLength, maxLength: item.maxLength, pattern: item.pattern,
                             //title: "Pattern Required:" + item.pattern       Note :: removing this title for P& G because pattern is undefined
                             }}
                    />
                </>

            )
        }
        else if (item.type == 'html' && item.tag == 'label') {
            return (
                <>
                    <FormControl className={`${classes.label} custom-control`}
                    >
                        <FormLabel
                            className={classNames(classes.formlabel_renderer)}
                            style={{ fontSize: item.size, color: item.color, textAlign: item.align, ...JSON.parse(item.style ? item.style : "{}") }}>{item.content}</FormLabel>
                    </FormControl>

                </>
            )
        }
        else if (item.type == 'html') {
            return (
                <>
                    <Typography variant={item.tag}
                        align={item.align}
                        className="brand-card-title"
                        style={{ backgroundColor: '#047dd8', color: item.color, visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
                        gutterBottom>
                        {item.content}
                    </Typography>
                </>
            )
        }
        else if (item.type == 'radio') {
            let values = []
            let fillData = ''
            if (item.dataType === 'dataModel') {
                values = this.state.dataModelValue;
            } else if (item.dataType === 'workflow') {
                values = this.state.workflowTask;
            } else {
                values = item.data && item.data.values;
            }
            const { parentIndex, parentName, lookupDataModelInnerValue, computed } = this.props;

            if (this.props.parentType === 'card') {
                fillData = this.props.filledData[parentName] && this.props.filledData[parentName][parentIndex] && this.props.filledData[parentName][parentIndex][item.name] || item.defaultValue
            } else {
                fillData = this.props.filledData[item.name] || item.defaultValue;
            }

            return (
                <div className='form-element'>
                    <FormControl style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}  disabled={item.disabled || !this.props.editSubmittedData}>
                        <FormLabel>{item.label.labelText}</FormLabel>
                        <RadioGroup className={item.ui.className}
                            row={item.alignment == 'inline' ? true : false} required={true}
                            inputProps={{ required: item.validation.required }}
                            onChange={(e) => { this.props.onChange(item.name, e.target.value, item.type) }}>
                            {values && values && Array.isArray(values) && values.map((el) => {
                                return <FormControlLabel value={el.value}
                                    checked={fillData == el.value}
                                    control={<Radio
                                    className={classes.radioStyle}
                                        disabled={item.disabled || !this.props.editSubmittedData}
                                        required={item.validation.required} />}
                                    label={el.label} />
                            })}

                        </RadioGroup>
                    </FormControl>

                </div>
            )
        }
        else if (item.type == 'checkbox') {
            let values = []
            if (item.dataType === 'dataModel') {
                values = this.state.dataModelValue
            } else if (item.dataType === 'workflow') {
                values = this.state.workflowTask
            } else {
                values = item.data && item.data.values
            }
            return (
                <>
                    <FormControl style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}  disabled={item.disabled || !this.props.editSubmittedData}>
                        <FormLabel>{item.label.labelText}</FormLabel>
                        <FormGroup row={item.alignment == 'inline' ? true : false}
                        >
                            {values.map((el) => {
                                return <FormControlLabel value={el.value} control={<Checkbox
                                    disabled={item.disabled || !this.props.editSubmittedData}
                                    checked={this.props.filledData[item.name] != null && this.props.filledData[item.name].includes(el.value)}
                                    onChange={(e) => { this.props.onChange(item.name, e.target.value, item.type) }} className={item.ui.className} />} label={el.label} />
                            })}
                        </FormGroup>
                    </FormControl>

                </>
            )
        }
        else if (item.type == 'dataSelector') {
            return (
                <>
                    <DataSelector
                        data={this.props.item}
                        editSubmittedData={this.props.editSubmittedData}
                        onChangeData={(data) => this.props.onChange(item.name, data, item.type)}
                        fillData={this.props.filledData[item.name]}
                        // disabled={!this.props.editSubmittedData}
                    />
                </>

            )
        }
        else if (item.type == 'namePicker') {
            let fillData;
            if (this.props.parentType === 'card') {
                const { parentIndex, parentName, lookupDataModelInnerValue, computed } = this.props;
                fillData = this.props.filledData[parentName] && this.props.filledData[parentName][parentIndex] && this.props.filledData[parentName][parentIndex][item.name] || item.defaultValue
            } else {
                fillData = this.props.filledData[item.name] || item.defaultValue;
            }
            if (!this.props.members) return
            let values = this.props.members;
            return (
                <>
                    <FormControl style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }} className={classNames(classes.native_select_dropdown)}  disabled={item.disabled || !this.props.editSubmittedData}>
                        <FormLabel>{item.label}</FormLabel>
                        <NativeSelect
                            fullWidth
                            className={classNames(item.className, classes.nativeStyle)}
                            onChange={(e) => {
                                this.props.onChange(item.name, e.target.value, item.type)
                            }}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-helper',
                                required: item.required
                            }}
                        >
                            <option value="" selected disabled>Select an option</option>
                            {values.map((el) => {
                                return <option
                                    selected={fillData._id == el.userId}
                                    disabled={item.disabled || !this.props.editSubmittedData} value={JSON.stringify({
                                        "_id": el.userId,
                                        "name": `${el.firstName} ${el.lastName}`
                                    })}>{`${el.firstName} ${el.lastName}`}</option>
                            })}
                        </NativeSelect>
                    </FormControl>
                </>
            )
        }
        else if (item.type == 'pageOrFormPicker') {
            let fillData;
            if (this.props.parentType === 'card') {
                const { parentIndex, parentName, lookupDataModelInnerValue, computed } = this.props;
                fillData = this.props.filledData[parentName] && this.props.filledData[parentName][parentIndex]
                    && this.props.filledData[parentName][parentIndex][item.name] || item.defaultValue
            } else {
                fillData = this.props.filledData[item.name] || item.defaultValue;
            }
            let values;
            if (item.listType === 'form') {
                if (!this.props.forms) return
                values = this.props.forms;
            } else {
                if (!this.props.pages) return
                values = this.props.pages;
            }
            console.log('page/FOrm = item.listType', item, values);
            return (
                <>
                    <FormControl style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
                        className={classNames(classes.native_select_dropdown)}  disabled={item.disabled || !this.props.editSubmittedData}>
                        <FormLabel>{item.label}</FormLabel>
                        <NativeSelect
                            fullWidth
                            className={classNames(item.className,classes.nativeStyle)}
                            onChange={(e) => {
                                this.props.onChange(item.name, e.target.value, item.type)
                            }}
                            inputProps={{
                                name: 'pageOrFormPicker',
                                id: 'pageOrFormPicker',
                                required: item.required
                            }}>
                            <option value="" selected disabled>Select an option</option>
                            {values?.map((el) => {
                                return <option
                                    selected={fillData == el._id}
                                    disabled={item.disabled || !this.props.editSubmittedData}
                                    value={item.listType === 'form' ? el.name : el.url}>{`${el.name}`}</option>
                            })}
                        </NativeSelect>
                    </FormControl>
                </>
            )
        }
        else if (item.type == 'select') {
            let values = [];
            let fillData = ''
            if (item.dataType === 'dataModel') {
                values = this.state.dataModelValue
            } else if (item.dataType === 'workflow') {
                values = this.state.workflowTask
            } else {
                values = item && item.values
            }
            const { parentIndex, parentName, lookupDataModelInnerValue, computed } = this.props;

            if (this.props.parentType === 'card') {
                fillData = this.props.filledData[parentName] && this.props.filledData[parentName][parentIndex] && this.props.filledData[parentName][parentIndex][item.name] || item.defaultValue
            } else {
                fillData = this.props?.filledData[item?.name] || item?.defaultValue;
            }
            return (
                <>
                    <FormControl style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }} className={classNames(classes.native_select_dropdown)}  disabled={item.disabled || !this.props.editSubmittedData}>
                        <FormLabel>{item?.label}</FormLabel>
                        <NativeSelect
                            fullWidth
                            className={item?.className ? item.className:classes.nativeStyle}
                            onChange={(e) => { this.props.onChange(item.name, e.target.value, item?.type) }}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-helper',
                                required: item.required
                            }}
                        >
                            <option className="option-style" value="" selected disabled>Select an option</option>
                            {values.map((el) => {
                                let value = el.value ? el.value : el
                                let label = el.label ? el.label : el
                                return <option className="option-style" selected={fillData == value}
                                    disabled={item.disabled || !this.props.editSubmittedData} value={value}>{label}</option>

                            })}
                        </NativeSelect>
                    </FormControl>
                </>
            )
        }
        else if (item.type == 'multiselect') {
            let selectedvalue = this.props?.filledData[item?.name];
            console.log("value of multiselect", selectedvalue);
            let values = []
            if (item.dataType === 'dataModel') {
                values = this.state?.dataModelValue
            } else if (item.dataType === 'workflow') {
                values = this.state?.workflowTask
            } else {
                values = item && item?.values
            }
            const { selectedItem, items } = this.state;

            return (
                <>
                    <FormControl style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
                        className={`${classes?.native_select_dropdown}`}  disabled={item?.disabled || !this.props.editSubmittedData} >
                        <label style={{ marginBottom: 5 }}>{item.label}</label>
                        <Select
                            multiple
                            inputProps={{
                                required: item?.required
                            }}
                            fullWidth
                            value={selectedvalue ? selectedvalue : (item?.defaultValue ? [{
                                value: item?.defaultValue,
                                label: item?.defaultValue
                            }] : [])} className={item?.className}
                            onChange={(e) => { this.props.onChange(item?.name, e.target.value, item?.type) }}
                            input={<Input />}
                            renderValue={(selected) => (
                                <div styles={{
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} styles={{ margin: 2 }} />
                                    ))}
                                </div>
                            )}
                        >
                            {values && values.map((name, i) => (
                                <MenuItem key={`menu_${i}`}
                                    disabled={item?.disabled || !this.props?.editSubmittedData} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>

                    </FormControl>
                </>
            )
        }
        else if (item.type == 'date') {
            console.log("========this.props.filledData[item.name]", this.props?.filledData[item?.name])
            let value = item?.defaultValue;
            if (this.props.filledData[item.name]) {
                value = moment(this.props?.filledData[item?.name]).format('YYYY-MM-DD');
            }
            return (
                <>
                    {item.labelPosition == 'top' && <InputLabel className={classNames(classes?.inputLabel)}>{item?.label}</InputLabel>}
                    <TextField className={classNames(classes.textField)}
                        style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
                        onChange={(e) => { this.props.onChange(item.name, e.target.value, item.type) }}
                        type="date"
                        margin="normal"
                        disabled={item.disabled || !this.props.editSubmittedData}
                        variant={item.variant ? item.variant : "standard"}
                        InputProps={item.labelPosition == 'end' ? {
                            endAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                        } : (item.labelPosition == 'start' ? {
                            startAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                        } : {})}
                        value={value}
                        required={item.required}
                        inputProps={{ min: item.min, max: item.max, disabled: item.disabled, visibility: visibility }}
                        fullWidth
                    />
                </>

            )
        }
        else if (item.type == 'button') {
            return (
                <>
                    <Button style={{ visibility: visibility }}
                        onClick={(e) => { this.props.buttonAction(itemPosition) }}
                        variant="contained" color="secondary"
                    >{item.label}</Button>
                </>

            )
        }
        else if (item.type == 'chip') {

            return (
                <>
                    <FormControl style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
                        className={classNames(classes.spacing_element)} >
                        <InputLabel>{item.label}</InputLabel>
                        {item.values.map(data => {
                            return (
                                <Chip
                                    key={data.value}
                                    label={data.label}
                                />
                            );
                        })}
                    </FormControl>
                </>
            )
        }
        else if (item.type == 'switch') {
            console.log(item, "Switch", this.props.filledData[item.name])
            return (
                <>
                    <FormControlLabel disabled={item.disabled || !this.props.editSubmittedData} style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }} 
                        control={
                            <Switch
                                disabled={item.disabled || !this.props.editSubmittedData}
                                checked={this.props.filledData[item.name] || item.defaultValue} onChange={(e) => { this.props.onChange(item.name, e.target.checked, item.type) }}

                            />
                        }
                        label={item.label}
                    />

                </>
            )
        }
        else if (item.typeOfText != null) {
            if (item.typeOfText === 'file') {
                return (
                    <>

                        <FormLabel>{item.label}</FormLabel>
                        <Upload handleSave={
                            (files) => {
                                this.props.setFilesToUpload(item.name, files);
                                this.props.onChange(item.name, files[0].name, "file");
                            }
                        } type="logo"
                            acceptedFiles={[
                                'image/svg',
                                'image/gif',
                                'image/jpg',
                                'image/jpeg',
                                'image/png',
                                'application/pdf',
                                'application/x-csv',
                                'application/csv',
                                'text/x-csv',
                                'text/csv',
                                '.csv',
                                'application/vnd.ms-excel',
                                'text/x-comma-separated-values',
                                'text/comma-separated-values',
                                'text/tab-separated-values',
                                'text/plain',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                '', // TO DO - Excel sheets update
                            ]}
                            disabled={!this.props.editSubmittedData}
                        />
                        <br />
                        {item.typeOfText == 'file' && this.props.match && this.props.match.params && this.props.match.params.id != null && <Button
                            onClick={(e) => { this.downloadUploadedFile(this.props.filledData[item.name]) }}
                            style={{ backgroundColor: 'transparent', color: '#fff', textTransform: 'none', padding: '8px 0px', textDecoration: 'underline' }}
                            variant="contained"
                        >{this.props.filledData[item.name]}
                            <GetAppIcon fontSize="small" />

                        </Button>}
                    </>
                )
            } else {
                let value = this.props.filledData[item.name];
                // if(this.props.filledData[item.name] && item.typeOfText == 'datetime-local'){
                //     value = moment(this.props.filledData[item.name]).format('YYYY-MM-DD, hh:mm A');
                // }
                let newValue = value;
                if (item.currencySymbol !== undefined) {
                    let addCommas = num => num.toString().replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
                    let removeNonNumeric = num => num.toString().replace(/[^0-9.]/g, "");
                    newValue = value && addCommas(removeNonNumeric(value));
                }

                return (
                    <>
                        {item.labelPosition == 'top' && <InputLabel className={classNames(classes.inputLabel)}>{item.label}</InputLabel>}
                        {(item.typeOfText != 'number' && item.typeOfText != 'file') ?
                            (<>
                                <TextField className={classNames(classes.textField)}
                                    style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
                                    onChange={(e) => {
                                        this.props.onChange(item.name, e.target.value, item.type)
                                    }}
                                    value={value}
                                    margin={item.typeOfText != 'file' ? "dense" : "none"}
                                    type={item.typeOfText}
                                    name={item.name}
                                    id={item.name}
                                    disabled={item.disabled || !this.props.editSubmittedData}
                                    required={item.required}
                                    // style={{ visibility: visibility }}
                                    variant={item.variant ? item.variant : "standard"}
                                    fullWidth={item.typeOfText != 'file' ? true : false}
                                    InputProps={item.labelPosition == 'end' ? {
                                        endAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                                    } : (item.labelPosition == 'start' ? {
                                        startAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                                    } : {})}
                                    inputProps={item.typeOfText == 'tel' ? { pattern: item.pattern, 
                                        //title: "Pattern Required:" + item.pattern     Note :: removing this title for P& G because pattern is undefined
                                     } : {}} 
                                />
                            </>
                            )
                            : item.typeOfText != 'file' ? (
                                <Input className={classNames(classes.textField)}
                                    // style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
                                    onChange={(e) => {
                                        this.props.onChange(item.name, e.target.value, item.typeOfText, item)
                                    }}
                                    margin={item.typeOfText != 'file' ? "dense" : "none"}
                                    type={item.currencySymbol !== undefined ? 'text' : 'number'}
                                    disabled={item.disabled || !this.props.editSubmittedData}
                                    InputProps={item.labelPosition == 'end' ? {
                                        endAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                                    } : (item.labelPosition == 'start' ? {
                                        startAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                                    } : {})}
                                    value={newValue}
                                    required={item.required}
                                    variant={item.variant ? item.variant : "standard"}
                                    fullWidth={item.typeOfText != 'file' ? true : false}
                                    inputProps={{ min: item.min, max: item.max, step: item.step, defaultValue: item.defaultValue, visibility: visibility }}
                                    defaultValue={parseInt(item.value)}
                                    startAdornment={<InputAdornment position="start">{item.currencySymbol || ''}</InputAdornment>}
                                />) : (
                                    <>
                                        <TextField className={classNames(classes.textField)}
                                            style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}
                                            onChange={(e) => {
                                                if (item.typeOfText == 'file') {
                                                    this.props.setFilesToUpload(item.name, e.target.files);
                                                    console.log(e.target.files[0].name, "file changes")
                                                    this.props.onChange(item.name, e.target.files[0].name, "file");
                                                    return;
                                                }
                                                this.props.onChange(item.name, e.target.value, item.typeOfText, item)
                                            }}
                                            margin={item.typeOfText != 'file' ? "dense" : "none"}
                                            type={item.typeOfText}
                                            disabled={item.disabled || !this.props.editSubmittedData}
                                            InputProps={item.labelPosition == 'end' ? {
                                                endAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                                            } : (item.labelPosition == 'start' ? {
                                                startAdornment: <InputAdornment position={item.labelPosition}>{item.label}</InputAdornment>,
                                            } : {})}
                                            required={item.required}
                                            variant={item.variant ? item.variant : "standard"}
                                            fullWidth={item.typeOfText != 'file' ? true : false}
                                            inputProps={{ min: item.min, max: item.max, step: item.step, defaultValue: item.defaultValue, visibility: visibility }}
                                            defaultValue={parseInt(item.value)}
                                        />
                                        {item.typeOfText == 'file' && this.props.match && this.props.match.params && this.props.match.params.submissionId != null && <Button
                                            onClick={(e) => { this.downloadUploadedFile(this.props.filledData[item.name]) }}
                                            variant="contained" color="secondary"
                                        >Download File Uploaded</Button>}
                                    </>
                                )}
                    </>

                )
            }

        }

        else if (item.type == 'HTML_content') {
            console.log("innerhtml to insert", item);
            return (
                <>
                    <FormControl style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }} className={classNames(classes.spacing_element)}>
                        <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    </FormControl>
                </>
            )
        }
        else if (item.type == 'expansionPanels') {
            console.log("innerhtml to insert", item);

            let selectedvalue = this.props.filledData[item.name];
            console.log("tab value", selectedvalue);
            return (
                <>
                    <FormControl style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }} className={classNames(classes.native_select_dropdown)}>
                        {item.data && item.data.values && item.data.values.map((el, indexOuter) => {
                            const { isShow, isDisable } = this.isPanelManage(el.label)
                            if (isShow) {
                                return (<ExpansionPanel defaultExpanded={item.showExpandPanel}
                                    disabled={item.disabled || !this.props.editSubmittedData}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>{el.label}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {item.innerForms && item.innerForms.map((item, index) => {
                                            return (
                                                <>
                                                    {
                                                        <Card className="card-element" style={index == indexOuter ? {} : { display: "none" }} >
                                                            <CardContent>
                                                                {isDisable && (

                                                                    <InnerCard
                                                                        design={item.innerForm}
                                                                        innerFormUpdate={(jsonCopy, modalData) => { this.props.innerFormUpdateMultiple(itemPosition, jsonCopy, modalData, index); }}
                                                                        filledData={this.props.filledData} />
                                                                )}
                                                                {!isDisable && (
                                                                    <InnerForm
                                                                        editSubmittedData={this.props.editSubmittedData}
                                                                        design={item.innerForm}
                                                                        viewOnly={true}
                                                                        setFilesToUpload={(name, files) => { this.props.setFilesToUpload(name, files) }}
                                                                        match={this.props.match}
                                                                        setData={(name, val, item) => { this.props.onChange(name, val, item) }}
                                                                        innerFormUpdate={(jsonCopy, modalData) => { this.props.innerFormUpdateMultiple(itemPosition, jsonCopy, modalData, index); }}
                                                                        modalData={item.innerFormModalData}
                                                                        filledData={this.props.filledData}
                                                                    />
                                                                )}



                                                            </CardContent>
                                                        </Card>
                                                    }
                                                </>)

                                        })}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>)
                            }

                        })}
                    </FormControl>
                </>
            )
        }
        else if (item.type == 'tab') {
            console.log("innerhtml to insert", item);

            let selectedvalue = this.props.filledData[item.name];
            console.log("tab value", selectedvalue);
            return (
                <>
                    <FormControl
                        style={{ visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }} className={classNames(classes.spacing_element)}>
                        <AppBar position="static" className="formelements-appbar">
                            <Tabs
                                className="formElements-tab"
                                value={selectedvalue}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                onChange={(e, val) => { this.props.onChange(item.name, val, item.type) }}
                            >
                                {item.data && item.data.values && item.data.values.map((el) => {
                                    return <Tab label={el.label}
                                        disabled={item.disabled || !this.props.editSubmittedData} value={el.value} />
                                })}
                            </Tabs>
                        </AppBar>
                    </FormControl>
                    {item.innerForms && item.innerForms.map((item, index) => {
                        return <Typography
                            component="div"
                            role="tabpanel"
                            hidden={selectedvalue != item.value}
                        >
                            {selectedvalue == item.value &&
                                <Card className={classNames(classes.card)} >
                                    <CardContent>
                                        <InnerForm design={item.innerForm}
                                            editSubmittedData={this.props.editSubmittedData}
                                            setFilesToUpload={(name, files) => { this.props.setFilesToUpload(name, files) }}
                                            viewOnly={this.props.viewOnly}
                                            match={this.props.match}
                                            setData={(name, val, item) => { this.props.onChange(name, val, item) }}
                                            innerFormUpdate={(jsonCopy, modalData) => { this.props.innerFormUpdateMultiple(itemPosition, jsonCopy, modalData, index); }}
                                            modalData={item.innerFormModalData}
                                            filledData={this.props.filledData}
                                        />
                                    </CardContent>
                                </Card>
                            }
                        </Typography>
                    })}
                </>
            )
        }
        else if (item.type == 'card') {
            let selectedvalue = this.props.filledData[item.name];
            const { computed, lookupDataModal, lookupDataElement, lookupDataKey, workflow, type } = item
            return (
                <>
                    <Card className={classNames(classes.card)}
                        style={{ color: item.color, visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }} >
                        <CardHeader
                            title={item.label}
                            style={{ backgroundColor: '#000080', color: '#fff' }}
                            classes={{
                                title: classes.title,
                            }}

                            action={
                                item.isAddbutton ? <IconButton aria-label="settings" onClick={() => this.props.addColumn(item.index)}>
                                    <AddIcon style={{ color: '#fff' }} />
                                </IconButton> : null
                            }
                        />
                        <CardContent>
                            {visibility != 'hidden' && <InnerForm design={item.innerForm}
                                editSubmittedData={this.props.editSubmittedData}
                                match={this.props.match}
                                viewOnly={this.props.viewOnly}
                                setData={(name, val, item) => { this.props.onChange(name, val, item) }}
                                setFilesToUpload={(name, files) => { this.props.setFilesToUpload(name, files) }}
                                innerFormUpdate={(jsonCopy, modalData) => { this.props.innerFormUpdate(itemPosition, jsonCopy, modalData); }}
                                modalData={item.innerFormModalData}
                                filledData={this.props.filledData}
                                parentType={item.type}
                                parentName={item.name}
                                index={item.index}
                                lookupDataModelInnerValue={this.state.lookupDataModelInnerValue}
                                computed={computed}
                            // lookupDataModal={item.lookupDataModal}
                            // lookupDataElement={item.lookupDataElement}
                            // lookupDataValue={item.lookupDataValue}
                            // lookupDataKey={item.lookupDataKey}
                            />}
                        </CardContent>
                    </Card>
                </>
            )
        }
        else if (item.type == 'multiLine') {
            return (
                <>
                    <Card className={classNames(classes.card)}
                        style={{ color: item.color, visibility: visibility, ...JSON.parse(item.style ? item.style : "{}") }}  >
                        <CardContent>
                            {visibility != 'hidden' && <MultiLine json={item}
                                filledData={this.props.filledData[item.name]}
                                setData={(name, val) => { this.props.onChange(name, val, "multiLine") }} />
                            }
                        </CardContent>
                    </Card>
                </>
            )
        }
        else if (item.type == 'multiStepForm') {
            console.log("innerhtml to insert", item);
            let selectedvalue = this.props.filledData[item.name];
            let values = item.data && item.data.values;

            console.log("==================================================3", this.state.activeStep)
            return (
                <>
                    <Stepper alternativeLabel activeStep={this.state.activeStep - 1} connector={<QontoConnector />}>
                        {values.map((el, index) => {
                            return (
                                <Step key={index} onClick={() => {
                                    if (index < this.state.activeStep && item.navigationStyle === 'Click') {
                                        this.setState({
                                            activeCard: index + 1
                                        })
                                    }
                                }}>
                                    <StepLabel style={{ fontWeight: 'bold', color: 'red' }}>{el.label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {item.innerForms && item.innerForms.map((item, index) => {
                        return <Typography
                            component="div"
                            role="tabpanel"
                            style={this.state.activeCard == (index + 1) ? {} : { display: "none" }}
                        >
                            {
                                <Card className={classNames(classes.card)}>
                                    <CardContent>
                                        {
                                            (this.isMultiStepFormCard(index)) ? (
                                                <InnerCard
                                                    design={item.innerForm}
                                                    innerFormUpdate={(jsonCopy, modalData) => { this.props.innerFormUpdateMultiple(itemPosition, jsonCopy, modalData, index); }}
                                                    filledData={this.props.filledData} />
                                            ) : (
                                                    <InnerForm design={item.innerForm}
                                                        editSubmittedData={this.state.activeStep > index}
                                                        setFilesToUpload={(name, files) => { this.props.setFilesToUpload(name, files) }}
                                                        viewOnly={this.props.viewOnly}
                                                        match={this.props.match}
                                                        setData={(name, val, item) => { this.props.onChange(name, val, item) }}
                                                        innerFormUpdate={(jsonCopy, modalData) => { this.props.innerFormUpdateMultiple(itemPosition, jsonCopy, modalData, index); }}
                                                        modalData={item.innerFormModalData}
                                                        filledData={this.props.filledData}
                                                    />
                                                )
                                        }

                                    </CardContent>
                                </Card>
                            }
                        </Typography>
                    })}
                    {item.navigationStyle === 'Default' && (
                        <div className="mui-button-group">
                            <Button variant="contained" className="border-radius-0" disabled={this.state.activeCard === 1}
                                onClick={(e) => {
                                    this.setState({
                                        // activeStep: this.state.activeStep - 1,
                                        activeCard: this.state.activeCard - 1
                                    })
                                }}>
                                Back
                            </Button>
                            <Button
                                className="btn btn-primary btn-color border-radius-0"
                                onClick={(e) => {
                                    this.setState({
                                        // activeStep: this.state.activeStep + 1,
                                        activeCard: this.state.activeCard + 1
                                    })
                                }}
                            // disabled={this.state.activeCard == this.state.activeStep}
                            >
                                Next
                            </Button>
                        </div>
                    )}

                </>
            )
        }
        else if (item.type == 'table') {
            return (
                <>
                    <TableStatic data={item.data} />
                </>
            )
        }
        else if (item.type == 'dataTable') {
            console.log('item.type', this.props.item, '==> Invokcked', this.props.filledData);
            const { dataModel, dataElement } = this.props.item
            return (
                <>
                    <DataTable item={item} filledData={this.props.filledData}
                        itemPosition={itemPosition} dataModel={dataModel} dataElement={dataElement} />
                </>
            )
        }
        else {
            return (
                <>
                </>
            )
        }
    }
}


const mapDispatchToProps = {
    getMemberById,
    loadPages,
    getAllForms
};


// function mapDispatchToProps(dispatch) {
//     return bindActionCreators({
//         // draggedElement: Actions.draggedElement
//     }, dispatch);
// }

const mapStateToProps = state => {
    console.log("=========state", state)
    return {
        members: state.appConfig.members,
        user: state.user,
        pages: Array.isArray(state.pages.page) && state.pages.page || [],
        forms: state.form.forms,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(FormElements)));
