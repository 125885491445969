import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { withRouter } from 'react-router-dom'
import {
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Grid
} from '@material-ui/core';
import classNames from 'classnames';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import ReplaceValuesPopup from './ReplaceValuesPopup'
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { getMonth } from "../../../../utility/utils";
import { getCurrentFFForPayload } from '../../spendplan/spendplanUtils'
import { toast } from 'react-toastify';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 300,
        flexGrow: 1,
        minWidth: 300,
        transform: 'translateZ(0)',
        // The position fixed scoping doesn't work in IE 11.
        // Disable this demo to preserve the others.
        '@media all and (-ms-high-contrast: none)': {
            display: 'none',
        },
    },
    imar1table: {
        '& .MuiTableContainer-root': {
            height: 'fit-content',
        },
    },
    downloadBtn: {
        '& .MuiButton-startIcon': {
            margin: 0,
        },
        marginRight: 15,
        float: 'right'
    },
    circularBar: {
        color: 'rgb(25, 118, 210)',
        height: 20,
        width: 20,
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'inherit'
    },
    displayHeading: {
        color: 'black',
        fontSize: '20px',
        float: 'left'
    },


  
    quarterHead: {
        paddingRight: '5px',
        borderRight: '1px solid #7b7272',
        marginRight: '5px',
    },
    quarterBody: {
        paddingRight: '5px',
        marginRight: '5px',
    },
    greenFont: {
        '& span': {
            color: 'green',
        },
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            // height: '320px',
            width: '600px',
        },
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000',
    },
    headingBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    circularBar: {
        color: 'rgb(25, 118, 210)',
        height: 20,
        width: 20,
    },
    countTag: {
        marginRight: 10,
        color: '#fff',
        background: '#ff6503',
        padding: 8,
        height: 36,
        borderRadius: 2,
    }
}));

let capsValues = {
    omniSkuMaster: 'SKU Master',
    omniIttMaster: 'ITT Master',
    omniMasterClassification: 'Master Classification',
    omniSubBrandFormMapping: 'Sub Brandform Mapping',
    omniSkuLevelOfftake: 'SKU Level Offtake',
    omniRetailingOffTakePlanning: 'Retailing Offtake Planning',
    offtakeBrandVsod: 'Offtake Brand VSOD',
    retailBrandVsod: 'Retail Brand VSOD',
    tsSkuSalience: 'TS SKU Salience',
    omniPromoCalender: 'Promo Calendar',
    omniSpendsPlanning: 'Spends Planning',
    omniTiff: 'TFI Earnings',
    mediaCalendar: 'Media Calendar',
    omniFyActuals: 'FY Actuals'
}

const ReplaceValues = (props) => {
    const classes = useStyles();
    const [tableData, setTableData] = useState();
    const [pagesTableData, setPagesTableData] = useState();
    const [showUpdateValuePopup, setShowUpdateValuePopup] = useState(false);
    const [dataFromGetAPI, setDataFromGetAPI] = useState();
    const [alertMessageText, setAlertMessage] = useState('');

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        setShowUpdateValuePopup(false);
        axios({
            url: API_BASE_URL + '/mrspendplan/omni/replacement/master',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                appId: props.match.params.appid,
            },
        }).then((response) => {
            if (response && response.data && response.data.result._id) {
                setDataFromGetAPI(response.data.result);
                setTableData([
                    { val: response.data.result.type, key: 'Type' },
                    { val: response.data.result.oldValue, key: 'Old Value' },
                    { val: response.data.result.newValue, key: 'New Value' },

                ]);
                let keys = Object.keys(response.data.result.status);
                let obj = response.data.result.status;
                let newArr = [];
                for (let i = 0; i < keys.length; i++) {
                    newArr.push({key: keys[i], val: obj[keys[i]]});
                }
                setPagesTableData([...newArr]);
            }
        }).catch((error) => {
            console.log({ "error": error });
        })
    }

    const openValuesPopup = () => {
        setShowUpdateValuePopup(!showUpdateValuePopup);
    }

    const handleCloseReplace = (val, payload) => {
        if (val) {
            setShowUpdateValuePopup(false)
        } else {
            payload['currentFF'] = getCurrentFFForPayload(getMonth((props.account && props.account['CurrentFF']) || '', 'monthNumber'));
            payload['fiscalYear'] = (props.account && props.account['FinancialYear']) || '';
            payload['type'] = JSON.parse(JSON.stringify(payload.fieldValue));
            delete payload['fieldValue'];
            let uri = API_BASE_URL + '/mrspendplan/omni/replacement/master';
            axios({
                url: uri,
                method: 'POST',
                responseType: 'blob',
                data: payload,
                headers: {
                    appId: props.match.params.appid,
                },
            })
            .then((response) => {
                toast.success('Update successfull');
                fetchData();
            })
            .catch((error) => {
                console.log(error,  '--')
                toast.error('Some issue occured');
            });
        }
    }

    const refreshValues = () => {
        if (dataFromGetAPI && dataFromGetAPI._id) {
            let uri = API_BASE_URL + '/mrspendplan/omni/replacement/master/' + dataFromGetAPI._id;
            axios({
                url: uri,
                method: 'POST',
                headers: {
                    appId: props.match.params.appid,
                },
            })
            .then((response) => {
                fetchData();
                toast.success('Update successfull');
            })
            .catch((error) => {
                toast.error('Some issue occured');
            });
        }
    }

    return (
        <div className={classNames(classes.root, classes.imar1table)} style={{ padding: '15px', border: '1px solid #c1c1c1' }}>
            <Grid item style={{display: 'flow-root'}}>
                <h1 className={classes.displayHeading}>Replace Values</h1>
                <Button
                        variant="contained"
                        color="primary"
                        className={classes.downloadBtn}
                        size="medium"
                        startIcon={<AutorenewIcon />}
                        onClick={refreshValues}
                    > {'Re-run'} </Button>
                <Button
                        variant="contained"
                        color="primary"
                        className={classes.downloadBtn}
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={openValuesPopup}
                    > {'Add New'} </Button>
            </Grid>
            <div className={classes.flexContainer}>
                {/* <div className='oumchecklist-report'> */}
                    <TableContainer className='table-container' style={{margin: '10px'}} component={Paper}>
                        <Table className='table' aria-label="simple table"
                        >
                            <TableHead className='table-head-replace'>
                                <TableRow>
                                    <TableCell style={{ width: 150 }} colSpan={3}>
                                        <span>Key</span>
                                    </TableCell>
                                    <TableCell style={{ width: 200 }} colSpan={4}>
                                        <span>Value</span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody class="table-body-replace">
                                {
                                    tableData && tableData.length &&
                                    <>
                                        {
                                            tableData.map((dataVal, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell style={{ width: 150 }} colSpan={3}>
                                                            <span>{dataVal.key}</span>
                                                        </TableCell>
                                                        <TableCell style={{ width: 200 }} colSpan={4}>
                                                            <span>{dataVal.val}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
              
                
                    <TableContainer className='table-container' style={{margin: '10px', maxHeight: 600, overflowY: 'auto'}} component={Paper}>
                        <Table className='table' aria-label="simple table"
                        >
                            <TableHead className='table-head-replace'>
                                <TableRow>
                                    <TableCell style={{ width: 150 }} colSpan={3}>
                                        <span>Key</span>
                                    </TableCell>
                                    <TableCell style={{ width: 200 }} colSpan={4}>
                                        <span>Value</span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody class="table-body-replace">
                                {
                                    pagesTableData && pagesTableData.length &&
                                    <>
                                        {
                                            pagesTableData.map((dataVal, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell style={{ width: 150 }} colSpan={3}>
                                                            <span>{capsValues[dataVal.key]}</span>
                                                        </TableCell>
                                                        <TableCell style={{ width: 200 }} colSpan={4}>
                                                            <span>{dataVal.val ? 'YES' : 'NO'}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
            

                </div>
            {/* </div> */}
            <ReplaceValuesPopup open={showUpdateValuePopup} handleClose={handleCloseReplace} alertMessageText={alertMessageText}
            classes={classes} confirm={true} />
        </div>
    )

}

export default hot(withRouter(ReplaceValues))