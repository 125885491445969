import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMonth } from "utility/utils";
import { clearError, getTsData } from '../../../../../store/actions';
import Loader from '../../../../Loader';
import { convertToNumber } from '../../PlanningPages/planningHelperFunctions';
import ConfirmDialog from '../../SpendPlan/ConfirmDialog';
import { getCurrentFFForPayload } from '../../spendplanUtils';

export const useStyles = makeStyles(theme => ({
    tableContainer: {
        maxWidth: "94vw",
        "@media(max-width:1700px)": {
            maxWidth: "93.5vw",
        },
        borderRadius: "8px",
        margin: "5px",
        maxHeight: 'calc(100vh - 200px)',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.8em',
            height: '0.8em'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cdcdcd',
            outline: '1px solid slategrey',
        },
    },
    table: {
        minWidth: 700,
        "&:focus": {
            border: "0"
        },
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
    },
    tableHead: {
        "& th": {
            textAlignLast: "center",
            paddingBottom: 0,
            paddingTop: 5,
            backgroundColor: '#3CA7FD',
            color: '#fff',
            padding: "6px !important",
        },
        "& th:not(.adjustBorder)": {
            borderRight: "1px solid rgba(224, 224, 224, 1)"
        },
        "& th.adjustBorder": {
            paddingRight: "0",
            "& span": {
                paddingRight: "16px",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                display: "flex",
                height: "40px",
                alignItems: "center",
            }
        },
        '& th.lastYearHeader': {
            "& span": {
                height: "40px",
                display: "flex",
                padding: "0 16px",
                textAlign: 'center',
                alignItems: "center",
            }
        },
        "& span": {
            lineHeight: 1.2,
            fontWeight: "bold",
            display: 'block',
            wordBreak: 'normal',
            whiteSpace: 'normal',
            textAlign: 'center',
        },

    },
    tableBody: {
        "& td": {
            padding: "9px !important",
            "@media(max-width:1366px)": {
                padding: "8px !important"
            },
            cursor: "pointer",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            borderBottom: "0",
            textAlign: "center",
            "& input": {
                textAlign: "center"
            }
        },
        "& td.noBorder": {
            border: "0"
        },
        "& p": {
            display: "flex"
        },
    },
    rowBold: {
        backgroundColor: '#c0d4ec !important',
        "& span": {
            fontWeight: "bold !important"
        }
    },
    darkBG: {
        backgroundColor: '#0874c4 !important',
        "& span": {
            fontWeight: "bold !important",
            color: '#fff',
        }
    },
    modalCloseBtn: {
        position: "absolute",
        top: "10px",
        right: "10px",
        fontWeight: "bold",
        fontSize: "1.2em",
        color: "#2f2929",
        cursor: "pointer",
        transition: "color 0.3s",
        "&:hover": {
            color: "#2196f3"
        }
    },
    combinedHeaderSection: {
        justifyContent: 'center',
        paddingTop: '10px',
        '@media(max-width:1366px)': {
            height: '45px',
        },
        display: 'flex',
        '& p': {
            color: '#fff',
            flex: 1,
            alignSelf: 'center',
            paddingLeft: 10,
            paddingRight: 10,
            margin: 0,
        }
    },
    divider: {
        marginTop: 'auto',
        height: 30,
        '@media(max-width:1366px)': {
            height: 25,
        },
        width: 1,
        background: 'rgba(224, 224, 224, 1)',
        marginBottom: 'auto',
    },
    combinedHeaderHeading: {
        margin: "5px 0px 0px 0px",
        // margin: "0",
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
        borderRadius: "4px",
        padding: "4px 2px 2px",
        background: "rgb(16, 114, 189)",
    },
    combinedBodySection: {
        display: "flex",
        alignItems: "center",
        "& p": {
            display: "flex",
            textAlign: 'center',
            alignItems: "center",
            borderRight: "0",
            width: "82px",
            justifyContent: "space-around",
        },
        "& p:not(:last-of-type)": {
            // height: "40px",
            height: "55px",
            textAlign: 'center',
            paddingRight: "16px",
            paddingTop: "2px",
            paddingBottom: "2px",
            margin: '0px',
            "& input": {
                height: "100%",
            }
        },
        "& p:not(:first-of-type)": {
            paddingLeft: "16px",
            textAlign: 'center',
        }
    },
    tableBodyLoading: {
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 200px)',
    },
    alignLeft: {
        textAlign: 'left !important',
    },
    icon: {
        color: '#fff',
        background: '#008000',
        borderRadius: '50%',
        fontSize: '12px !important',
    }
}));

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        overflow: 'hidden',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const requiredDataObj = {
    "INR Crores": ["Ecommerce", "CSD/CPC/UPC/B2B", "DTC", "All HFS", "Modern Retail", "All Channels"],
    "Total %RLP": ["Ecommerce", "CSD/CPC/UPC/B2B", "DTC", "All HFS", "Modern Retail", "All Channels"]
}

const TalkSheetTable = (props) => {
    const classes = useStyles()
    console.log('props', props);
    const { getTsData, match, tsData, brandData, modalData } = props;

    const [tsFieldData, setTsData] = useState([]);
    const [currentFF, setCurrentFF] = useState('');

    const isBold = [13, 25, 36, 50, 51, 65, 66, 80, 81, 95, 96, 110, 111, 125, 126];
    const percentRange = [26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
        80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106,
        107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127];
    const DarkBGRows = [14, 37, 52, 67, 82, 97, 112, 127];
    const [_brand, setBrand] = useState(brandData);

    const fetchData = () => {
        const params = match.params,
            urlSearchParams = new URLSearchParams(window.location.search);
        let payload, fetchData = false;

        if (modalData && modalData.brand && modalData.month && modalData.year) {
            payload = {
                brand: modalData.brand, year: modalData.year, currentFF: getCurrentFFForPayload(modalData.month),
                version: true,
            };
            fetchData = true;
        } else if (params.brand && params.currentFF && params.year) {
            payload = {
                brand: params.brand, year: params.year, currentFF: getCurrentFFForPayload(params.currentFF),
            };
            fetchData = true;
        } else if (urlSearchParams.get('brand') && urlSearchParams.get('year') && urlSearchParams.get('month')) {
            payload = {
                brand: urlSearchParams.get('brand'), year: urlSearchParams.get('year'), currentFF: getCurrentFFForPayload(urlSearchParams.get('month')),
            };
            fetchData = true;
        } else {
            payload = {
                brand: brandData ? brandData.BrandName : "",
                year: brandData ? `${brandData.FinancialYear}` : '',
                currentFF: brandData ? `${getCurrentFFForPayload(getMonth(brandData.CurrentFF, 'monthNumber'))}` : ''
            }
            fetchData = true;
        }

        setCurrentFF(payload.currentFF)
        if (fetchData) {
            getTsData({ appId: match.params.appid, updateToCurrentFF: payload.currentFF, payload });
            getTsData({ appId: match.params.appid, updateToCurrentFF: payload.currentFF - 1, payload: { ...payload, currentFF: payload.currentFF - 1 } });
        }
    }

    const isPercentageValue = (x) => {
        return percentRange.includes(x)
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_brand !== brandData) {
            setBrand(brandData)
            fetchData();
        }
    }, [brandData]);

    useEffect(() => {
        if (tsData && (tsData[`data_` + currentFF.replace(/^0+/, '')]?.result?.data || tsData[`data_` + `${currentFF.replace(/^0+/, '') - 1}`]?.result?.data) && !tsData?.data?.result?.data?.error) {
            let currentData = tsData[`data_` + currentFF.replace(/^0+/, '')]?.result?.data || []
            let lastYearData = tsData[`data_` + `${currentFF.replace(/^0+/, '') - 1}`]?.result?.data || []
            if (currentData?.length) {
                currentData = JSON.parse(JSON.stringify(currentData))
            }
            if (lastYearData?.length) {
                lastYearData = JSON.parse(JSON.stringify(lastYearData))
            }
            let modifiedData = [];
            let allTotalValue = "";
            currentData.map(item => {
                if (item.UoM && requiredDataObj[item.UoM] && requiredDataObj[item.UoM].includes(item.Metrics)) {
                    let lyd = lastYearData.find(pyi => item.UoM === pyi.UoM && item.Metrics === pyi.Metrics)
                    if (lyd) {
                        item['BOP_LAST_FY'] = lyd.BOP_FY
                    }
                    modifiedData.push(item)
                }
                if (item.UoM === "INR Crores" && item.Metrics === "All Channels") {
                    allTotalValue = item.BOP_FY
                }
            })

            if (allTotalValue) {
                modifiedData = modifiedData.map(mi => {
                    if (mi.UoM === "INR Crores" && (mi.BOP_FY || mi.BOP_FY === 0)) {
                        mi['salience'] = (mi.BOP_FY / allTotalValue) * 100
                    } else {
                        mi['salience'] = " "
                    }
                    return mi;
                })
            }
            setTsData(modifiedData);
            // BOP_FY
        } else if (tsFieldData.length) {
            setTsData([])
        }

        // if (tsData && tsData.data && tsData.data.result && tsData.data.result.data && !tsData.data.result.data.error) {
        //     const { data } = tsData.data.result;
        //     console.log("it is data in lookup modal ===", data)
        //     if (modalData) {
        //         console.log("it is data in lookup modal === inside modalData if condition===")
        //         setTsData(data[0] ? (data[0].detail || []) : []);
        //     } else {
        //         let modifiedData = []
        //         JSON.parse(JSON.stringify(data)).map(item => {
        //             if (item.UoM && requiredDataObj[item.UoM] && requiredDataObj[item.UoM].includes(item.Metrics)) {
        //                 modifiedData.push(item)
        //             }
        //         })
        //         setTsData(modifiedData);
        //     }
        // } else if (tsFieldData.length) {
        //     setTsData([])
        // }
    }, [tsData]);

    const renderNoDataText = () => {
        let textToShow = "No Data Found";
        return (
            <StyledTableRow>
                <StyledTableCell colSpan="100%">
                    <h4 style={{ color: '#000' }}>{textToShow}</h4>
                </StyledTableCell>
            </StyledTableRow>
        );
    }

    if (tsData.loading) {
        return (
            <section className={classes.tableBodyLoading}>
                <Loader />
            </section>
        )
    }

    const { error } = tsData;

    return (
        <React.Fragment>
            <TableContainer className={classNames(classes.tableContainer, "tpr-container")} component={Paper}>
                <Table className={classNames(classes.table, "tpr-table")} aria-label="simple table" stickyHeader>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {/* <TableCell>
                                <span>FF</span>
                            </TableCell> */}
                            <TableCell>
                                <span>Metrics</span>
                            </TableCell>
                            <TableCell>
                                <span>UoM</span>
                            </TableCell>
                            <TableCell>
                                <span>JAS</span>
                            </TableCell>
                            <TableCell>
                                <span>OND</span>
                            </TableCell>
                            <TableCell>
                                <span>JFM</span>
                            </TableCell>
                            <TableCell>
                                <span>AMJ</span>
                            </TableCell>
                            <TableCell>
                                <span>FY</span>
                            </TableCell>
                            <TableCell>
                                <span>vs Last FF</span>
                            </TableCell><TableCell>
                                <span>Salience</span>
                            </TableCell>
                            {/* <TableCell colSpan="5">
                                <p className={classes.combinedHeaderHeading}>As per BOP</p>
                                <section className={classes.combinedHeaderSection}>
                                    <p><span>JAS</span></p>
                                    <div className={classes.divider} />
                                    <p><span>OND</span></p>
                                    <div className={classes.divider} />
                                    <p><span>JFM</span></p>
                                    <div className={classes.divider} />
                                    <p><span>AMJ</span></p>
                                    <div className={classes.divider} />
                                    <p><span>FY</span></p>
                                </section>
                            </TableCell> */}
                            {/* <TableCell colSpan="3">
                                <p className={classes.combinedHeaderHeading}>YTD Actuals and Forecast</p>
                                <section className={classes.combinedHeaderSection}>
                                    <p><span>YTD Act</span></p>
                                    <div className={classes.divider} />
                                    <p><span>Current Month Fcst</span></p>
                                    <div className={classes.divider} />
                                    <p><span>Remaining Months </span></p>
                                </section>
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {tsFieldData && tsFieldData.length > 0 ? tsFieldData.map((row, index) => {
                            let ispercent = isPercentageValue(row.Row);
                            // let isbold = row.Metrics ==='Net Realization' && row.UoM === '$M';
                            let isbold = isBold.includes(row.Row);
                            let formatting = row.UoM?.includes('INR') ? 'en-In' : 'en-US'
                            let isDarkBG = DarkBGRows.includes(row.Row);
                            let decimalPoints = ispercent ? 2 : 1;
                            let isPercent = ispercent ? '%' : ""
                            return (
                                <StyledTableRow key={index} className={isbold ? classes.rowBold : isDarkBG ? classes.darkBG : ''}>
                                    {/* <StyledTableCell className={classes.alignLeft}>
                                        <span>{row.FF}</span>
                                    </StyledTableCell> */}
                                    <StyledTableCell className={classes.alignLeft}>
                                        <span>{row.Metrics}</span>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span>{row.UoM}</span>
                                    </StyledTableCell>
                                    <StyledTableCell className="noBorder">
                                        <span title={(row.BOP_JAS) + (isPercent)}>{(convertToNumber(row.BOP_JAS, decimalPoints)).toLocaleString(formatting) + (isPercent)}</span>
                                    </StyledTableCell>
                                    <StyledTableCell className="noBorder">
                                        <span title={(row.BOP_OND) + (isPercent)}>{convertToNumber(row.BOP_OND, decimalPoints).toLocaleString(formatting) + (isPercent)}</span>
                                    </StyledTableCell>
                                    <StyledTableCell className="noBorder">
                                        <span title={(row.BOP_JFM) + (isPercent)}>{convertToNumber(row.BOP_JFM, decimalPoints).toLocaleString(formatting) + (isPercent)}</span>
                                    </StyledTableCell>
                                    <StyledTableCell className="noBorder">
                                        <span title={(row.BOP_AMJ) + (isPercent)}>{convertToNumber(row.BOP_AMJ, decimalPoints).toLocaleString(formatting) + (isPercent)}</span>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span title={(row.BOP_FY) + (isPercent)}>{convertToNumber(row.BOP_FY, decimalPoints).toLocaleString(formatting) + (isPercent)}</span>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span title={(row.BOP_LAST_FY) + (isPercent)}>{convertToNumber(row.BOP_LAST_FY, decimalPoints).toLocaleString(formatting) + (isPercent)}</span>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span title={(row.salience) + "%"}>{row.salience === " " ? row.salience : convertToNumber(row.salience, decimalPoints).toLocaleString(formatting) + "%"}</span>
                                    </StyledTableCell>
                                    {/* <StyledTableCell className="noBorder">
                                        <span title={(row.YTD_Act_CurrentFF) + (isPercent)}>{convertToNumber(row.YTD_Act_CurrentFF, decimalPoints).toLocaleString(formatting) + (isPercent)}</span>
                                    </StyledTableCell>
                                    <StyledTableCell className="noBorder">
                                        <span title={(row.Current_Month_Fcst) + (isPercent)}>{convertToNumber(row.Current_Month_Fcst, decimalPoints).toLocaleString(formatting) + (isPercent)}</span>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span title={(row.Remaining_Months) + (isPercent)}>{convertToNumber(row.Remaining_Months, decimalPoints).toLocaleString(formatting) + (isPercent)}</span>
                                    </StyledTableCell> */}
                                </StyledTableRow>)
                        }) :
                            renderNoDataText()
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {error && <ConfirmDialog open={error !== null} handleClose={props.clearError} alertMessageText={{
                title: 'Error Loading Page',
                p2: 'There was an error loading this content. Please reload the page or try again later.', p4: error
            }} classes={classes} />}

        </React.Fragment>
    )
}


const mapStateToProps = state => {
    return {
        user: state.user,
        tsData: state.talkSheetReducer
    };
};

const mapDispatchToProps = {
    getTsData,
    clearError,
};

export default hot(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(TalkSheetTable)),
);
