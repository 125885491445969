import { Divider, Grid, Paper, TableContainer, TablePagination } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import classNames from 'classnames'
import FileDownload from 'js-file-download'
import * as mathjs from "mathjs"
import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'styles/react-datasheet.scss'
import { addReconReviewBaseline, deleteMRData, deleteSelectedFormData, removeMRSuccessMessage } from '../../../../store/actions'
import { getFileNameWithTimeStamp, getMonth } from '../../../../utility/utils'
import Loader from '../../../Loader'
import { UploadLoading } from '../../../Loading/uploadLoading'
import ConfirmDialog from '../../spendplan/SpendPlan/ConfirmDialog'
import { convertToNumber } from '../../spendplan/PlanningPages/planningHelperFunctions'
import MathExcelSheet from '../../spendplan/SpendPlan/Worksheet/ds/MathExcelSheet'
import { generateSheetKey, parsePaste, readOnlyColumn } from '../../spendplan/SpendPlan/Worksheet/sheetTableHelper'
import ValueViewerCheckbox from '../../spendplan/SpendPlan/Worksheet/ValueViewerCheckbox'
import { getFiscalYearFormat } from '../../spendplan/spendplanUtils'
import { URLs } from '../ApiConstants'
import MRPlanUploadConfirmDialog from '../planingPages/Headers/planUploadConfirmDialog'
import { actualForcastMonths, columnTitles, fyActualHeadingKeys, isMonthForecast, ittMasterObjectKeys, KeyTypes, lastFFMonths, months, ObjectKeyTypes, skuMappingKeys } from './data'
import SheetHeader from './header'
import { save, setSticky } from './mrSheettableHelper'
import ValueViewerSelect from './valueViewerSelect'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const useStyles = makeStyles(theme => ({
    tableContainer: {
        maxWidth: "95vw",
        // margin: "40px auto",
        overflowY: "auto",
        height: 'calc(100vh - 335px) !important',
        borderRadius: "8px",
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0.8em',
            height: '0.8em'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cdcdcd',
            outline: '1px solid slategrey',
        },
    },
    table: {
        minWidth: 700,
    },
    tableHead: {
        "& th": {
            borderRight: "1px solid rgba(224, 224, 224, 1)"
        },
        "& span": {
            fontWeight: "bold"
        },
    },
    tableBody: {
        "& td": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            cursor: "pointer"
        },
        "& p": {
            display: "flex"
        }
    },
    tableInput: {
        border: "0",
        background: "transparent",
        maxWidth: "55px",
        appearance: "none",
    },
    quarterHead: {
        paddingRight: "5px",
        borderRight: "1px solid #7b7272",
        marginRight: "5px",
    },
    quarterBody: {
        paddingRight: "5px",
        marginRight: "5px",
    },
    greenFont: {
        "& span": {
            color: "green"
        }
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            height: '320px',
            width: '600px'
        }
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000'
    },
    headerMainCon: {
        maxWidth: '95vw',
        // overflowX: 'scroll'
    },
    headerPaperContainer: {
        minWidth: '1066px'
    },
    setupConfirmationDialog: {
        '& .MuiDialog-paperWidthSm': {
            height: '320px',
            width: '600px'
        }
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 400px)',
    },
    totalValue: {
        color: '#0070C0',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 12,
    },
}))

const MRSheetTable = props => {
    const classes = useStyles();
    let { user, type, objectKey, view, tprReducer, deleteSelectedFormData, form, conditions } = props;
    const { setupUploadLoading } = tprReducer

    const [fieldData, setFieldData] = useState([]);
    const [cols, setCols] = useState([]);
    const [edited, setEdited] = useState(false)
    const [open, setOpen] = useState(false)
    const [alertMessageText, setAlertMessage] = useState("")
    const [deleteList, setDeleteList] = useState([]);
    const [deleteConfirmState, setDeleteConfirm] = useState({ isOpen: false, deleteMessage: 'Are you sure you want to delete the selected row(s)?' })
    const [errorDialogVisible, setErrorDialogVisible] = useState(false)
    const [localLoading, setLocalLoading] = useState(false)
    const [confirmState, setConfirm] = useState({ isOpen: false, confirmMessage: '' });

    useEffect(() => {
        setSticky()
    })

    useEffect(() => {
        let fieldData;
        if (props.state.data) {
            if (props.type === 'skuMaster') {
                let _updatedData = props.state.data.map(i => {
                    let j = { ...i };
                    months.forEach(m => {
                        j.gstrate[m] = i.gstrate[m] * 100
                        j.frontmargin[m] = i.frontmargin[m] * 100
                    })
                    return j
                })
                fieldData = generateFieldData(_updatedData)
            } else if (props.type === 'ittMaster') {
                let _updatedData = props.state.data.map(i => {
                    let j = { ...i };
                    ittMasterObjectKeys.forEach(obj => {
                        for (let key in j[obj.key]) {
                            j[obj.key][key] = j[obj.key][key] * 100;
                        }

                    })
                    return j
                })
                fieldData = generateFieldData(_updatedData)
            } else if (props.type === 'fyActuals') {
                let _updatedData = (props.fyActualData || props.state.data).map(i => {
                    let j = { ...i };
                    j.account = i.account
                    return j
                })
                fieldData = generateFieldData(_updatedData)
            } else {
                fieldData = generateFieldData(props.state.data)
            }
            setFieldData(fieldData)
            if (deleteList.length) {
                setDeleteList([]);
            }
        }
    }, [props.state?.data, props?.account, props?.fyActualData])

    // useEffect(() => {
    //     let lastYearData1 = []
    //     if (props.type === 'fyActuals') {
    //         console.log('-----props.fyActualData', props.fyActualData, 'props.type', props.type)
    //         let updatedResponse = [];
    //         if (!props.fyActualData?.length) { // last year data not present
    //             updatedResponse = props.state?.data.map(item => {
    //                 lastFFMonths(props.account).forEach(m => {
    //                     if (item) {
    //                         item.value[m] = 0
    //                         item.volume[m] = 0
    //                     }
    //                 })
    //                 return item;
    //             })
    //         } else {
    //             // let uniqueConstraintArr = props.state?.data.map(i => (i.brand + '_' + i.account + i.sku + i.brandForm))
    //             // let updatedLastYearData = props.fyActualData.map(i => {
    //             //     if (uniqueConstraintArr.includes(i.brand + '_' + i.account + i.sku + i.brandForm)) {
    //             //         return i
    //             //     } else {
    //             //         let obj = { // merge last year data also for last ff (06)
    //             //             ...i,
    //             //             value: { jul: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0, jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0 },
    //             //             volume: { jul: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0, jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0 }
    //             //         }
    //             //         lastFFMonths(props.account).forEach(month => {
    //             //             obj.value[month] = i.value ? (i.value[month] || 0) : 0
    //             //             obj.volume[month] = i.volume ? (i.volume[month] || 0) : 0
    //             //         })
    //             //         lastYearData1.push(obj)
    //             //     }
    //             // }).filter(Boolean);
    //             // updatedResponse = props.state?.data.map(item => {
    //             //     // let objToBeMatched = {
    //             //     //     brand: item.brand,
    //             //     //     account: item.account,
    //             //     //     sku: item.sku,
    //             //     //     brandForm: item.brandForm,
    //             //     // }
    //             //     // let keysToMatch = Object.keys(objToBeMatched)
    //             //     // let matchedObj = updatedLastYearData?.find(el => keysToMatch.every(key => el[key] === objToBeMatched[key]))
    //             //     // console.log('matchedObj', matchedObj);
    //             //     // lastFFMonths(props.account).forEach(m => {
    //             //     //     if (item) {
    //             //     //         item.value[m] = matchedObj?.value ? (matchedObj.value[m] || 0) : 0
    //             //     //         item.volume[m] = matchedObj?.volume ? (matchedObj.volume[m] || 0) : 0
    //             //     //     } else {
    //             //     //         item.value[m] = 0
    //             //     //         item.volume[m] = 0
    //             //     //     }
    //             //     // })
    //             //     return item;
    //             // })

    //         }
    //         setLocalLoading(true)
    //         let fieldData = generateFieldData(_updatedData, '')
    //         if (fieldData) {
    //             setLocalLoading(false)
    //         }
    //         setFieldData(fieldData)
    //         if (deleteList.length) {
    //             setDeleteList([]);
    //         }
    //     }

    // }, [props.fyActualData, props.state?.data])

    useEffect(() => {
        props.setEditMode(edited)
    }, [edited])

    let deleteData = props.data ? props.data['DELETE_' + props.type] : null
    useEffect(() => {
        if (deleteData) {
            props.fetchData(conditions ?? []);
            props.removeMRSuccessMessage({ dataUniqueId: 'DELETE_' + props.type });
            setDeleteList([])
        }
    }, [deleteData])

    const getDeleteList = () => {
        console.log("deleteList ==", deleteList);
        return deleteList
    }

    const checkboxOnChanges = (e, id) => {
        if (id === 'All') {
            // select all case
            if (allSelectableRows()?.length > 1) {
                if (e.target.checked) {
                    let list = allSelectableRows();
                    setDeleteList(['All', ...list])
                } else {
                    setDeleteList([])
                }
            }
        } else {
            let dl = getDeleteList().filter(i => i !== 'All');
            let list = [...dl]
            if (e.target.checked) {
                list.push(id)
                if (list.length === allSelectableRows().length) {  // when all rows is selected 1 by 1 show all checked.
                    list.push('All')
                }
            } else {
                let ind = list.indexOf(id)
                list.splice(ind, 1)
            }
            setDeleteList(list)
        }
    }

    const allSelectableRows = () => {
        return fieldData.map(d => d[0].ref && d[0].key ? (d[0].ref || d[0].key) : null).filter(i => i);
    }

    const renderFyActualHeader = (object, subTitleArr, monthKey) => {
        let isInput = !isMonthForecast(monthKey, props.account)
        let style = { fontWeight: 'bold', textAlign: 'end', alignSelf: 'center', minWidth: 80, }
        if (isInput && type === 'fyActuals') {
            style.color = 'green';
        }
        let paddings = {
            0: 12,
            1: 12,
            2: 8,
            3: 4,
            4: 4,
            5: 4,
            6: 4,
        }
        const getTotalValue = (m) => {
            if (isInput) {
                return props.state?.totalSum?.value ? (props.state?.totalSum?.value[m] || 0) : 0
            } else {
                return props.fyActualsLastFFSum?.value ? (props.fyActualsLastFFSum?.value[m] || 0) : 0
            }
        }
        const getTotalVolume = (m) => {
            if (isInput) {
                return props.state?.totalSum?.volume ? (props.state?.totalSum?.volume[m] || 0) : 0
            } else {
                return props.fyActualsLastFFSum?.volume ? (props.fyActualsLastFFSum?.volume[m] || 0) : 0
            }
        }
        return <div style={{ minWidth: 140 }}>
            {props.type === 'fyActuals' && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginLeft: -8 }} className={classes.totalValue} title={getTotalValue(monthKey)}>{convertToNumber(getTotalValue(monthKey), 0).toLocaleString()}</div>
                <div style={{ height: 20, width: 1, background: '#cecece' }} />
                <div style={{ marginRight: -8 }} className={classes.totalValue} title={getTotalVolume(monthKey)}>{convertToNumber(getTotalVolume(monthKey), 0).toLocaleString()}</div>
            </div>}
            <div style={{ fontWeight: 'bold', textAlign: "center", background: 'rgb(16, 114, 189)', color: '#fff' }}>{object.title}</div>
            <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                {
                    subTitleArr.map((obj, ind) => {
                        return <Grid item xs={12 / subTitleArr.length} style={{ marginLeft: -14, }}>
                            {(obj.key === 'largepack' || obj.key === 'smallpack') &&
                                <div style={{ ...style, paddingRight: (props.type !== 'ittMaster' ? paddings[ind] : 0) }}> {object.subTitle}</div>}
                            {!(object.overrideSubtitle && (obj.key === 'largepack' || obj.key === 'smallpack')) ?
                                <div key={obj.title} style={{ ...style, paddingRight: (props.type !== 'ittMaster' ? paddings[ind] : 0) }}
                                    dangerouslySetInnerHTML={{ __html: obj.title }} /> : <div style={{ height: 18.19 }} />}
                        </Grid>
                    })
                }
            </Grid>
        </div>
    }

    const generateFieldData = (data) => {
        let forecastMonths;
        if (type === 'fyActuals') {
            forecastMonths = actualForcastMonths(props.account);
        }
        try {
            let arr = []
            let generatedCols = [];
            let _cols = []
            if (type === 'skuMaster' || type === 'ittMaster' || type === 'fyActuals' || type === 'fyActualspff' || type === 'pyskulevel') {
                (type === 'fyActuals' ? fyActualHeadingKeys(props.account) : KeyTypes[type]).map((c, i) => {
                    if (typeof c === 'object') {
                        if (type === "skuMaster" || type === 'fyActuals' || type === 'ittMaster' || type === 'fyActualspff' || type === 'pyskulevel') {
                            if (c.subKeys?.length) {
                                c.subKeys.forEach((ii, ind) => {
                                    if (type === 'ittMaster' || type === 'skuMaster' || type === 'fyActualspff') {
                                        _cols.push(c.key + "." + c.subKeys[ind].key)
                                    } else {
                                        _cols.push(c.subKeys[ind].key + "." + c.key)
                                    }
                                })
                                generatedCols.push({
                                    checkbox: null,
                                    colSpan: (type === 'fyActuals' || type === 'pyskulevel') ? 2 : type === 'ittMaster' ? 8 : 12,
                                    valueViewer: () => renderFyActualHeader(c, c.subKeys, c.key),
                                    _id: c.subKeys[0].key,
                                    readOnly: true, idKey: c.subKeys[0].key,
                                    value: (c.title + ' ' + c.subKeys[0].key), header: true, string: true, key: type + i,
                                    className: `tab-0-${i}`,
                                })
                            }
                        }

                    } else {
                        _cols.push(c)
                        generatedCols.push({
                            checkbox: c === "" ? !props.view && true : null,
                            valueViewer: c === "" ? ValueViewerCheckbox : null,
                            width: c === "" ? 85 : undefined,
                            _id: c === "" ? 'All' : null,
                            align: 'left',
                            readOnly: true, idKey: c,
                            value: columnTitles[c] || c, header: true, string: true, key: type + i,
                            className: `tab-0-${i}` + ((c !== "" || i === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
                        })
                    }
                })
            }
            if (type === 'skuMapping') {
                skuMappingKeys.map((c, i) => {
                    _cols.push(c)
                    generatedCols.push({
                        checkbox: c === "" ? !props.view && true : null,
                        valueViewer: c === "" ? ValueViewerCheckbox : null,
                        _id: c === "" ? 'All' : null,
                        width: c === "" ? 85 : undefined,
                        align: 'left',
                        readOnly: true, idKey: c,
                        value: columnTitles[c] || c, header: true, string: true, key: type + (i + 1),
                        className: `tab-0-${i}` + ((c !== "" || i === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
                    })
                })
            }
            arr[0] = generatedCols;
            setCols(_cols);
            let arrData = data.map((d, i) => {
                let a = [{
                    readOnly: true, value: i + 1, key: d._id, ref: d._id, string: true, checkbox: !props.view && true, _id: d._id,
                    valueViewer: ValueViewerCheckbox, className: `p-0 tab-${i + 1}-0 sticky-column`, checked: false,
                    width: 85,
                }]

                const getValue = (cellObj, oKey) => {
                    let val = convertToNumber(cellObj?.cell?.value, oKey.decimalPlaces || 0)
                    if (type === 'fyActualspff') {
                        return Number(val).toLocaleString('en-In')
                    }

                    if (type === 'skuMaster' && cellObj?.cell.idKey && (cellObj.cell.idKey.split('.')[0] === 'msufactor')) {
                        return Number(cellObj?.cell?.value).toFixed(2)
                    }
                    return val;
                }
                let keyVal = generateSheetKey(i);
                if (type === 'skuMaster' || type === 'ittMaster' || type === 'fyActuals' || type === 'fyActualspff' || type === 'pyskulevel') {
                    ObjectKeyTypes[type].map((oKey, objectKeyIndex) => {
                        Object.keys(d).map(key => {
                            if (key === oKey.key) {
                                Object.keys(d[key]).map(k1 => {
                                    let ind = cols.indexOf(`${oKey.key}.${k1}`)
                                    if (ind > -1)
                                        a[ind] = {
                                            id: k1 + "." + key,
                                            idKey: `${key}.${k1}`,
                                            value: d[key][k1],
                                            edit: false,
                                            valueViewer: (oKey.isPercent || oKey.decimalPlaces) ? (cellObj) => <span>{convertToNumber(cellObj?.cell?.value, oKey.decimalPlaces)}{oKey.isPercent ? '%' : ''}</span> : (cellObj) => <span>{getValue(cellObj, oKey)}</span>,
                                            key: keyVal + ind,
                                            readOnly: type === 'fyActuals' ? (readOnlyColumn[key] || forecastMonths[k1]) : readOnlyColumn[key],
                                            className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : '') + (props.type === 'ittMaster' ? ' l-width' : ' m-width'),
                                        }
                                })
                            } else {
                                let ind = cols.indexOf(key)
                                if (ind > -1)
                                    a[ind] = {
                                        align: 'left',
                                        id: key,
                                        idKey: key,
                                        value: d[key],
                                        edit: false,
                                        valueViewer: (key === 'total') ? (cellObj) => <span>{getValue(cellObj, oKey)}</span> : null,
                                        string: readOnlyColumn[key],
                                        key: keyVal + ind,
                                        readOnly: readOnlyColumn[key],
                                        className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : '') + (props.type === 'ittMaster' ? ' l-width' : ' m-width'),
                                    }
                            }
                        })
                    })
                }
                if (type === 'skuMapping') {
                    skuMappingKeys.map((oKey) => {
                        Object.keys(d).map(key => {
                            if (key === oKey) {
                                let ind = cols.indexOf(`${oKey}`)
                                if (ind > -1)
                                    a[ind] = {
                                        align: 'left',
                                        id: key,
                                        idKey: key,
                                        index: i,
                                        subIndex: ind,
                                        valueViewer: key === 'skuType' ? ValueViewerSelect : null,
                                        value: d[key],
                                        edit: false,
                                        string: true,
                                        key: keyVal + ind,
                                        readOnly: readOnlyColumn[key],
                                        className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : ''),
                                    }
                            }
                        })
                    })
                }
                return a
            })
            let emptyRow = cols.map((c, inx) => ({
                readOnly: inx === 0 && true,
                width: c === "" ? 85 : undefined,
                value: inx === 0 ? arrData.length + 1 : '',
                string: readOnlyColumn[c] ? true : false,
                align: (readOnlyColumn[c] || type === 'skuMapping') ? 'left' : undefined,
                key: generateSheetKey(arrData.length + 1) + inx,
                id: c,
                idKey: c,
                empty: true,
                className: "bg-transparent" + " tab-" + (arrData.length + 1) + "-" + inx + ((c !== "" || inx === 0) && readOnlyColumn[c] ? ' sticky-column' : '') + (props.type === 'ittMaster' ? ' l-width' : ' m-width'),
            }))
            if (arrData && Array.isArray(arrData)) {
                if (view) {
                    arrData = arrData.map(fArr => (fArr.map(fObj => ({ ...fObj, readOnly: view }))))
                    return [...arr, ...arrData]
                }
                return [...arr, ...arrData, emptyRow]
            }
            return [emptyRow]
        } catch (e) {
            console.log(e);
        }
    }

    const onCellsChanged = (changes, additions) => {
        try {
            if (!edited)
                setEdited(true)
            let arrData = [...fieldData]
            if (changes)
                changes.forEach(({ cell, row, col, value, id }) => {
                    if (arrData[row][0].empty)
                        arrData[row][0].empty = false
                    arrData[row][col] = { ...arrData[row][col], value, id: id || arrData[row][col].id, empty: false };
                });
            if (additions) {
                additions.forEach(({ cell, row, col, value, id }) => {
                    if (col < arrData[0].length) {
                        // adding extra columns with empty values and freezing some columns
                        if (!arrData[row]) {
                            arrData[row] = [{
                                readOnly: true, value: row, key: '', ref: '', string: true,
                                className: `bg-transparent tab-${Number(row)}-0 sticky-column`,
                            }];
                            let obj = { readOnly: false, value: '', key: '', ref: '', string: false };
                            let _arr = [...arrData[0]];
                            _arr.shift();
                            _arr.forEach((i, count) => {
                                let _className = `bg-transparent tab-${Number(row)}-${count + 1}`;
                                let isStringField = readOnlyColumn[i.idKey] ? readOnlyColumn[i.idKey] : false;
                                if (isStringField && i.idKey !== '') {
                                    _className += ' sticky-column';
                                }
                                arrData[row].push({ ...obj, string: isStringField, className: _className });
                            });
                        }
                        arrData[row][col] = { ...arrData[row][col], value, id };
                    }
                });
            }
            if (additions && !arrData[arrData.length - 1][0].empty || arrData[arrData.length - 1][0].empty === false) {
                let emptyRow = cols.map((c, inx) => ({
                    readOnly: inx === 0 && true,
                    value: inx === 0 ? arrData.length : '',
                    key: generateSheetKey(arrData.length) + inx,
                    empty: true,
                    align: (readOnlyColumn[c] || type === 'skuMapping') ? 'left' : undefined,
                    string: readOnlyColumn[c],
                }))
                arrData = [...arrData, emptyRow]
            }
            updateAllValues(arrData);
        } catch (e) {
            console.log(e)
        }
    };

    const updateAllValues = (aoaData) => {
        let updatedAoA = aoaData.map((row) => {
            let valueObj = row.reduce((obj, item) => Object.assign(obj, { [item.key]: Number(item.value) }), {});
            return row.map((cell) => {
                const updatedCell = Object.assign(
                    {},
                    cell,
                    computeExpr(cell.key, cell.expr, valueObj, cell)
                );
                return updatedCell;
            })
        })
        setFieldData(updatedAoA)
    }

    const computeExpr = (key, expr, scope, cell) => {
        let value = null;
        if (expr?.charAt(0) === "=") {
            try {
                value = mathjs.evaluate(expr.substring(1), scope);
            } catch (e) {
                value = null;
            }
            if (value !== null) {
                return { value, expr };
            } else {
                return {};
            }
        } else {
            return cell;
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (e, res) => {
        setOpen(false);
    };

    const updateData = () => {
        let data = save(fieldData, type, props.account, props.appName)
        if (data) {
            let url = `${API_BASE_URL}/mrspendplan/upload/${props.page?.pageLevelDataModel}`
            axios({
                url: url,
                method: 'PUT',
                data: data,
                headers: {
                    appId: props.match.params.appid,
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                let message = "Data saved successfully"
                if (response.data && response.data?.result?.data) {
                    message = response.data?.result?.data
                }
                setEdited(false)
                setAlertMessage(message)
                handleClickOpen()
                props.fetchData()
            }).catch((error) => {
                console.log(error.response.data);
                let msg = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something went wrong';
                let d = {
                    title: 'Error',
                    p1: msg,
                }
                setAlertMessage(d)
                setErrorDialogVisible(true)
            })
        }
    }

    const download = () => {
        if (type) {
            let uri = API_BASE_URL + `/setupdata/download/${props.page?.pageLevelDataModel}?`;
            uri = `${uri}&formType=${encodeURIComponent(type)}`;

            axios({
                url: uri,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    appId: props.match.params.appid
                },
            }).then((response) => {
                FileDownload(response.data, getFileNameWithTimeStamp('fileNamePrefix'));
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    const deleteHandler = () => {
        if (deleteList?.length) {
            setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: 'Are you sure you want to delete the selected row(s)?' })
        } else {
            setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: 'Are you sure you want to delete the all row(s)?' })
        }
    }

    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            const account = props.account;
            let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
            let payload = {
                dataUniqueId: 'DELETE_' + props.type,
                url: URLs.deletePlanning + props.page?.pageLevelDataModel,
                appid: props.match.params.appid,
                data: {
                    ids: deleteList.filter(i => i !== 'All'),
                    fiscalYear: account?.FinancialYear,
                },
            }
            if (props.type !== 'pyskulevel') { //for pyskulevel currentFF is not used 
                payload.data.currentFF = m.length === 1 ? '0' + m : m
            }
            if (props.type === 'pyskulevel') {
                payload.data.fiscalYear = (Number(account?.FinancialYear) - 1) + ""
            }
            if (props.type !== 'skuMaster' && props.type !== 'ittMaster') { //for skuMaster & ittMaster account is not used 
                payload.data.account = account?.AccountName
            }
            props.deleteMRData(payload);
        }
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false })
    }

    const onConfirmResponse = (resp) => {
        if (resp) {
            finish();
        }
        setConfirm({ isOpen: false, confirmMessage: '' });
    };

    const onDoneUpdateClick = () => {
        setConfirm({
            ...confirmState, isOpen: true,
            confirmMessage: `${props.page.name} data update done for  ${getMonth(props.account?.CurrentFF, 'month')} ${getFiscalYearFormat(props.account?.FinancialYear)}?`,
        });
    }

    const finish = () => {
        let { AccountName, FinancialYear, CurrentFF } = props.account;
        let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
        m = m.length === 1 ? '0' + m : m;
        let data = {
            'formType': props.type,
            account: AccountName, fiscalYear: FinancialYear, currentFF: m,
            'action': 'finish',
        };
        let url = `${API_BASE_URL}` + URLs.doneUpdates;
        axios({
            url: url,
            method: 'POST',
            data: data,
            headers: {
                appId: props.match.params.appid,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                toast.success(response.data?.result?.message || 'Workflow emails sent successfully');
            })
            .catch((error) => {
                toast.success(error?.data?.message || 'Not able to send workflow emails');
            });
    };


    const onSelectChange = (value, index, subIndex) => {
        setEdited(true)
        fieldData[index + 1][subIndex].value = value
        setFieldData([...fieldData])
    }

    return (
        <React.Fragment>
            {setupUploadLoading && <UploadLoading />}
            <div className={classNames(`${classes.headerMainCon} mr-20 mb3`)}>
                <Paper elevation={3} className={classes.headerPaperContainer}>
                    <SheetHeader {...props} handleFileSave={props.handleFileSave}
                        deleteHandler={deleteHandler}
                        modalData={props.modalData}
                        onDoneUpdateClick={onDoneUpdateClick}
                        onDownload={props.onDownload}
                        fetchData={() => props.fetchData(conditions ?? [])}
                        handlePasteClick={props.handlePasteClick} save={updateData} edited={edited} />
                </Paper>
            </div>
            {
                (props.loading || localLoading) ? <div className={classes.loading}><Loader /></div> :
                    <div className="react-datasheet">
                        <TableContainer className={classes.tableContainer} component={Paper}>
                            <MathExcelSheet
                                type={props.type}
                                checkedRows={deleteList}
                                onCellsChanged={onCellsChanged}
                                valueRenderer={cell => cell.value}
                                dataRenderer={cell => cell.expr}
                                onSelectChange={onSelectChange}
                                checkboxOnChanges={checkboxOnChanges}
                                data={fieldData?.length ? fieldData : []}
                                parsePaste={(pasted) => parsePaste(pasted)}
                            />
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100, 200]}
                            component="div"
                            count={props.state.count ? props.state.count : 0}
                            className="custom-table-pagination"
                            rowsPerPage={props.pagination.rowsPerPage ? props.pagination.rowsPerPage : 50}
                            page={props.pagination.page ? props.pagination.page : 0}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                                'margin-top': '-18px'
                            }}
                            onChangePage={props.handleChangePage}
                            onChangeRowsPerPage={props.handleChangeRowsPerPage}
                        />
                    </div>
            }

            <ConfirmDialog open={errorDialogVisible} handleClose={() => setErrorDialogVisible(false)} alertMessageText={alertMessageText} classes={classes} />
            <ConfirmDialog open={deleteConfirmState.isOpen} handleClose={onDeleteResponse}
                alertMessageText={deleteConfirmState.deleteMessage} classes={classes} confirm />
            <MRPlanUploadConfirmDialog open={open} handleClose={handleClose} alertMessageText={alertMessageText} title={props.page.title} />
            <ConfirmDialog open={confirmState.isOpen} handleClose={onConfirmResponse}
                alertMessageText={confirmState.confirmMessage} classes={classes} confirm />
        </React.Fragment >
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        account: state.mrReducer?.account,
        form: state.form,
        data: state.mrReducer?.data,
        tprReducer: state.tprReducer,
    }
}

const mapDispatchToProps = {
    deleteSelectedFormData,
    addReconReviewBaseline,
    deleteMRData,
    removeMRSuccessMessage,
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(MRSheetTable)))
