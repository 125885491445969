import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PagesIcon from '@material-ui/icons/Pages';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import EventIcon from '@material-ui/icons/Event';
import AppsIcon from '@material-ui/icons/Apps';
import ArchiveIcon from '@material-ui/icons/Archive';
import HomeIcon from '@material-ui/icons/Home';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import FlashAutoIcon from '@material-ui/icons/FlashAuto';
import ConfirmDialog from '../customPages/spendplan/SpendPlan/ConfirmDialog';
import { MR_TITLE } from "../customPages/spendplan/spendplanUtils";

import {
  AccordionSummary, Typography, Tooltip, AccordionDetails, Accordion
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { includes } from 'lodash';

const drawerWidth = 240;

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
    backgroundColor: '#047dd8',
  },
})(Tooltip);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '& .MuiDrawer-paper': {
      top: 'unset',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    //   padding: theme.spacing(3)
  },
  list: {
    '& .MuiListItemIcon-root': {
      minWidth: '56px !important',
    },
  },
}));

function NavBarLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { appId } = props;
  const currentPage = props.page;
  const pages = Array.isArray(props.pages) && [...props.pages];
  const [selectedTab, setSelectedTab] = useState(0);
  const [alertMessageText, setAlertMessage] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [indexVal, setIndexVal] = useState(0);
  const [isOmniChannel, setIsOmniChannel] = useState(false)
  const [expanded, setExpanded] = useState(true)

  useEffect(() => {
    setIsOmniChannel(props.selectedAcc && props.selectedAcc.event && props.selectedAcc.event.length)
  }, [])

  const compare = (a, b) => {
    let comparison = 0;
    //Hanlde when rank is zero and undefined
    // if(!a.rank && !b.rank)
    //   return comparison
    // else if(!b.rank)
    //   return 1
    if (a.rank > b.rank) {
      comparison = 1;
    } else if (a.rank < b.rank) {
      comparison = -1;
    }
    return comparison;
  };
  if (pages && pages.length > 1 && !(props.selectedAcc && props.selectedAcc.event && props.selectedAcc.event.length))
    pages.sort(compare);

  const handleDrawer = () => {
    setOpen(prestate => !prestate);
  };

  const getPages = (pagesN) => {
    let newPages = [];
    let setupPages = [];
    let planningPages = [];
    let reportPages = [];
    if (props.selectedAcc && props.selectedAcc.event && props.selectedAcc.event.length) {
      pagesN.map((pg) => {
        // 'Promo Calendar', 'Omni Engine Earnings', 'PID Mapping'
        if (!pg.hide && (['Home'].includes(pg.title))) {
          newPages.push({ title: pg.title, name: pg.name, hide: pg.hide, isAccordion: false, isActive: pg.isActive, url: pg.url });
        }
        if (['Retailing Offtake Planning', 'Retail Brand VSOD', 'Offtake Brand VSOD', 'TS SKU Salience'].includes(pg.title)) {
          setupPages.push(pg);
        }
        if (['Promo Calendar', 'Omni Spends Planning'].includes(pg.title)) {
          let newPg = JSON.parse(JSON.stringify(pg))
          if (newPg.title.includes('Omni')) {
            newPg.title = newPg.title.slice(5, newPg.title.length)
          }
          planningPages.push(newPg);
        }
        if (['Omni Engine Earnings', 'Omni Executive Summary', 'TS Brand Summary', 'Omni Account Summary', 'Omni Versions', 'Replace Values', 'Auto Populate'].includes(pg.title)) {
          let newPg = JSON.parse(JSON.stringify(pg))
          if (newPg.title.includes('Omni')) {
            newPg.title = newPg.title.slice(5, newPg.title.length)
          }
          reportPages.push(newPg);
        }
      })
      newPages.push({ title: 'Setup Data', name: 'Setup Data', hide: false, isAccordion: true, subPages: setupPages })
      newPages.push({ title: 'Planning', name: 'Planning', hide: false, isAccordion: true, subPages: planningPages })
      newPages.push({ title: 'Report', name: 'Report', hide: false, isAccordion: true, subPages: reportPages })
      return newPages;
    } else {
      newPages = [];
      pagesN.map((pg) => {
        if (!pg.hide && !(['Omni Channels', 'Omni Engine Earnings', 'Omni Executive Summary', 'TS Brand Summary', 'Omni Account Summary', 'Promo Calendar', 'Omni Spends Planning', 'PID Mapping', 'Retailing Offtake Planning', 'Retail Brand VSOD', 'Offtake Brand VSOD', 'TS SKU Salience', 'Omni Versions', 'Replace Values', 'Auto Populate'].includes(pg.title))) {
          newPages.push(pg);
        }
      })
      return newPages;
    }
  }

  const clickNavBarLink = (ind, page) => {
    setIndexVal(ind);
    localStorage.removeItem('sideNavUrl')
    if (localStorage.getItem('editedItem') === 'true') {
      setOpenPopup(true)
      setAlertMessage({
        title: 'Confirmation',
        p1: `You have unsaved changes in ${props.page.name}.`,
        p2: 'Are you sure you want to discard these changes?',
      });
      localStorage.setItem('sideNavUrl', '/apps/' + appId + '/' + page.url);
    } else {
      localStorage.removeItem('editedItem')
      props.history.push('/apps/' + appId + '/' + page.url);
      setSelectedTab(ind)
    }
  }

  const handleClose = (e, res) => {
    setOpenPopup(false);
    if (e) {
      let urlVal = JSON.parse(JSON.stringify(localStorage.getItem('sideNavUrl')))
      localStorage.removeItem('editedItem')
      localStorage.removeItem('sideNavUrl')
      props.history.push(urlVal);
      setSelectedTab(indexVal)
    }
  };

  const getPageText = (page) => {
    if (page.title === 'PID Mapping') {
      return 'Setup'
    } else if (page.title === 'Promo Calendar') {
      return 'Planning'
    } else if (page.title === 'Omni Engine Earnings') {
      return 'Reports'
    } else if (page.title === 'Omni Versions') {
      return 'Versions'
    } else {
      return (page.title ? page.title : page.name)
    }
  }

  const renderListItem = (page, ind) => {
    if(page.title === 'Dashboard' && props?.app.title === MR_TITLE) return <></>;
    return (
      <ListItem
        selected={ind === selectedTab}
        button
        key={ind}
        onClick={() => {
          clickNavBarLink(ind, page)
        }}>
        <ListItemIcon>
          {page.title === 'Configure'
            ? <AppsIcon fontSize='small' />
            : (page.title === 'Dashboard')
              ? <DashboardIcon fontSize='small' /> :
              (page.title === 'Home')
                ? <HomeIcon />
                : page.title === 'Setup Data' || page.title === 'PID Mapping'
                  ? <PermDataSettingIcon fontSize='small' />
                  : page.title === 'Monthly Spend Plan' || page.title === 'Promo Calendar'
                    ? <EventIcon fontSize='small' />
                    : page.title === 'Versions' ? <ArchiveIcon fontSize='small' />
                      : page.title === 'Replace Values' ? <FindReplaceIcon />
                        : page.title === 'Auto Populate' ? <FlashAutoIcon /> :
                          <PagesIcon fontSize='small' />}
        </ListItemIcon>
        {/* getPageText(page) */}
        <ListItemText primary={page.title} />
      </ListItem>
    )
  }

  const handleChange = (ind) => {
    setExpanded(expanded === ind ? false: ind);
  }

  return (
    <div className={classNames(classes.root, 'side-navigation')}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, 'side-navigation-drawer', {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}>
        <div className={classNames(classes.toolbar, 'side-navigation-toolbar')}>
          <IconButton onClick={handleDrawer}>
            <MenuIcon />
          </IconButton>
        </div>
        <Divider />
        <List className={classNames(classes.list, 'side-navigation-toolbar-list')}>
          {pages && pages.length > 0
            ? React.Children.toArray(
              getPages(pages).map((page, ind) => {
                return (((!page.isAccordion && page.isActive && page.url && !page.hide) || (page.isAccordion && !page.hide)) && <React.Fragment key={ind}>
                  {
                    page.isAccordion ? <>
                      <Accordion style={{display: 'grid', cursor: 'pointer'}} expanded={open}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => handleChange(ind)}
                        >
                          {page.title === 'Setup Data' ?  <ListItemIcon><PermDataSettingIcon fontSize='small' /></ListItemIcon>: ''}
                          {page.title === 'Planning' ? <ListItemIcon><EventIcon fontSize='small' /></ListItemIcon>: ''}
                          {page.title === 'Report' ? <ListItemIcon><PagesIcon fontSize='small' /></ListItemIcon> : ''}
                          {open ?<Typography>{page.title}</Typography>:''}
                        </AccordionSummary>
                          {
                            open && page.subPages && page.subPages.length && page.subPages.map((subP, subInd) => (
                              // <Typography>{renderListItem(subP, subInd)}</Typography>
                              // <div><Typography  onClick={() => {
                              //   clickNavBarLink(ind, subP)
                              // }}>{subP.title}</Typography></div>
                              <AccordionDetails style={{padding: '0px'}}><ListItem><ListItemText primary={subP.title} onClick={() => {clickNavBarLink(ind, subP)}}/></ListItem></AccordionDetails>
                            ))
                          }
                        
                      </Accordion>
                    </> : <>
                      <StyledTooltip title={page.title ?? page.name} placement="right">
                        {renderListItem(page, ind)}
                      </StyledTooltip>
                    </>
                  }


                </React.Fragment>);
              }))
            : ''}
        </List>
        <ConfirmDialog open={openPopup} handleClose={handleClose} alertMessageText={alertMessageText}
          classes={classes} confirm={true} />
        <Divider />
      </Drawer>
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        {props.children}
      </main>
    </div>
  );
}

const mapDispatchToProps = {
};

const mapStateToProps = (state) => {
	return {
		app: state.appConfig.app
	}
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBarLeft)));