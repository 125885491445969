import React, { useState, useEffect } from 'react';
import moment from 'moment';
export const shortMonthsUpperCase = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const monthFilterValues = ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'FY']
export const ResText= 'Highly Restricted'
import xlsx from 'xlsx';

export const isJSON = (str) => {
  try {
    return (JSON.parse(str) && !!str);
  } catch (e) {
    return false;
  }
}
export const getMonth = (month, type) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December']
  let index = months.indexOf(month)
  switch (type) {
    case 'month':
      return shortMonthsUpperCase[index];
    case 'monthNumber':
      return index + 1;
    case 'lastMonth':
      return index === 0 ? 12 : index;
    default:
      return '';
  }

}

export const convertDate = (date = "", type = "") => {
  const dateObj = new Date(date);
  switch (type) {
    case 'month':
      if (dateObj instanceof Date && !isNaN(dateObj))
        return shortMonthsUpperCase[dateObj.getMonth()];
      break;
    case 'year':
      if (dateObj instanceof Date && !isNaN(dateObj))
        return dateObj.getFullYear();
      break;
    case 'monthNumber':
      if (dateObj instanceof Date && !isNaN(dateObj))
        return dateObj.getMonth() + 1;
      break;
    case 'lastMonth':
      if (dateObj instanceof Date && !isNaN(dateObj)) {
        let currentFF = dateObj.getMonth();
        currentFF = currentFF == 0 ? 12 : currentFF;
        return currentFF;
      }
      break;
    default:
      return '';
  }
}

export const getFileName = (type = '', brand = {}) => {
  const FY = brand.FinancialYear ? brand.FinancialYear : 'XX',
    FF = getMonth(brand.CurrentFF, 'month') ? getMonth(brand.CurrentFF, 'month') : 'XX',
    currentDate = moment(new Date()).format('YYYYMMDD'),
    currentTime = moment(new Date()).format("HHmmss");
  let fileType = 'Setup Data';
  if (type === 'tpr') {
    fileType = 'Trade Price Reduction'
  } else if (type === 'talksheet') {
    fileType = 'Trade Spends Summary'
  }
  return `${fileType}-${currentDate}-${currentTime}.xlsx`
}

export const getFileNameWithTimeStamp = (name, isPDF = false, isText = false) => {
  const currentDate = moment(new Date()).format('YYYYMMDD'),
    currentTime = moment(new Date()).format('HHmmss');
  if (isPDF) {
    return `${name}-${currentDate}-${currentTime} (${ResText}).pdf`;
  }
  if (isText) {
    return `${name}-${currentDate}-${currentTime} (${ResText}).txt`;
  }
  return `${name}-${currentDate}-${currentTime} (${ResText}).xlsx`;
};


const channelsForSpendPlan = [
  'WS Traditional', 'WS Beauty and Pharmacy',
  'Mini Market', 'Large A Traditional', 'Large A Beauty', 'Large A Pharmacy', 'Large B Traditional', 'Large B Beauty',
  'Large B Pharmacy', 'Medium Traditional', 'Medium Beauty', 'Medium Pharmacy', 'Small A Traditional',
  'Small A Beauty', 'Small A Pharmacy', 'Small B Traditional', 'Small C Traditional', 'Speciality', 'DTC', 'Others',
  'HSM', 'Cash & Carry', 'Ecommerce', 'CSD/CPC/UPC/B2B', 'All Channels', 'All HFS Channels', 'All WS (Trad+Beauty+Pharm',
  'All Large (Trad+ Beauty+Pharma)', 'All Medium (Trad+ Beauty+Pharma)', 'All Small (Trad+ Beauty+Pharma)',
  'Large A (Trad+ Beauty+Pharma)', 'Large B (Trad+ Beauty+Pharma)', 'Small A (Trad+ Beauty+Pharma)',
  'All Pharmacy (Large+Med+Small)', 'All Beauty (Large+Med+Small)', 'Top 50M stores', 'Top 200M stores',
];

const sortBasedOnOrder = (arr, order, key) => {
  arr.sort((a, b) => {
    let A = a[key], B = b[key];
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    } else {
      return -1
    }
  })
  return arr;
}

export const getFilteredChannelList = (channels) => {
  let updatedChannels = [];
  let restChannels = [];
  channels.forEach((i) => {
    if (channelsForSpendPlan.includes(i.channel)) {
      updatedChannels.push(i);
    } else {
      restChannels.push(i);
    }
  });
  let sortedChannel = sortBasedOnOrder(updatedChannels, channelsForSpendPlan, 'channel');

  return [...sortedChannel, ...restChannels];
};

export const addStyle = (element, property, value, important) => {
  if (element.style.setProperty)
    element.style.setProperty(property, '');
  else
    element.style.setAttribute(property, '');
  element.setAttribute('style', element.style.cssText +
    property + ':' + value + ((important) ? ' !important' : '') + ';');
}

export const capitalize = s => (s && s[0]?.toUpperCase() + s.slice(1)) || ""

export const getValue = (obj, desc, value) => {
  var arr = desc ? desc.split(".") : [];

  while (arr.length && obj) {
    var comp = arr.shift();
    var match = new RegExp("(.+)\\[([0-9]*)\\]").exec(comp);

    // handle arrays
    if ((match !== null) && (match.length == 3)) {
      var arrayData = {
        arrName: match[1],
        arrIndex: match[2]
      };
      if (obj[arrayData.arrName] !== undefined) {
        if (typeof value !== 'undefined' && arr.length === 0) {
          obj[arrayData.arrName][arrayData.arrIndex] = value;
        }
        obj = obj[arrayData.arrName][arrayData.arrIndex];
      } else {
        obj = undefined;
      }

      continue;
    }

    // handle regular things
    if (typeof value !== 'undefined') {
      if (obj[comp] === undefined) {
        obj[comp] = {};
      }

      if (arr.length === 0) {
        obj[comp] = value;
      }
    }

    obj = obj[comp];
  }

  return obj;
}


export const formulaCalculation = (row, formula) => {
  const paramsPattern = /[^{\}]+(?=})/g;

  let extractParams = formula.match(paramsPattern);
  extractParams.forEach(key => {
    formula = formula.replace(`{${key}}`, getValue(row, key) || 0)
  })

  return formula
}

export const sortAlphabetically = (arr, key) => arr?.sort((a, b) => !key ? (a + "").localeCompare((b + "")) : (a[key] + "").localeCompare((b[key] + "")))
// localeCompare works only for string not for numbers

export const jsonToExcelDownload = (arrOfObject, fileName, addHeader = false) => {
  const _data = []
  const __data = [...arrOfObject]
  for (const obj of __data) {
    const _newObj = {}
    for (const key in obj) {
      const replacedKey = key.trim().replace(/\r+/g, " ")
      _newObj[replacedKey] = obj[key]
    }
    _data.push(_newObj)
  }
  const workbook = xlsx?.utils.book_new()
  const ws = xlsx?.utils.json_to_sheet(_data, { skipHeader: !addHeader, })
  xlsx?.utils.book_append_sheet(workbook, ws, "sheet")
  xlsx?.writeFile(workbook, `${fileName}.xlsx`, { type: "file" })
}

export const isEditData = (page, config) => {
  const userRole = config?.member?.selectedRoles[0]?.label;
  const role = config?.roles.find(el => el?.name === userRole);
  const privilege = role?.privileges.find(el => el?.includes(page));
  return privilege?.includes(':Edit')
}