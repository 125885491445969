import { hot } from 'react-hot-loader/root';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, makeStyles, Paper, Button } from '@material-ui/core';
import classNames from 'classnames'
import BudgetPlan from './BudgetPlan';

const useStyles = makeStyles(theme => ({
}));

function GroupWidget(props) {
	// const { topFivePlans } = props;
	const classes = useStyles();

	const redirectToDashboard = (item) => {
		props.history.push({
			pathname: "/apps/" + props.match.params.appid + "/mspdashboard",
		})
	}

	const { group } = props

	let style = {}, imgStyle = {}
	if (group?.brands?.length === 1) {
		style.width = '50%'
		style.margin = 'auto'
	} else {
		imgStyle.width = "33%"
	}

	return (<div className="group-widget">
		<Paper className={"paper-table"} style={{ height: '100%', overflow: 'hidden' }}>
			<Button variant="outlined" color="primary" className={"header-btn"}>
				{group?.name}
			</Button>
			{group?.brands?.map(b => {
				return <React.Fragment key={JSON.stringify(b)}> {group?.brands?.length === 1
					? <img src={b.image} style={{ width: '40%', margin: '0% 30% 0% 30%', cursor: 'pointer', maxHeight: '108px' }}
						onClick={redirectToDashboard} />
					: group?.brands?.length === 2
						? <img src={b.image} style={{ width: '33%', margin: '0% 7% 0% 6%', cursor: 'pointer', maxHeight: '108px' }}
							onClick={redirectToDashboard} />
						: <img src={b.image} style={{ width: '33%', cursor: 'pointer', maxHeight: '108px' }} onClick={redirectToDashboard} />
				}</React.Fragment>
			})}
			<BudgetPlan series={group?.data} id={props.index + group?.name.replace(" ", "")} />
		</Paper>
	</div>);
}

export default hot(withRouter(GroupWidget));
