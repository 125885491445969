import { ActionTypes } from "../../../../constants";

export const selectAccount = (data) => dispatch => {
	dispatch({ type: ActionTypes.USER_SELECTED_ACCOUNT, payload: data });
};

export const setAccountEmpty = (data) => dispatch => {
	dispatch({ type: ActionTypes.SET_ACCOUNT_EMPTY, payload: data });
};

export const getMrData = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_DATA, payload: data });
};

export const saveMrData = (data) => dispatch => {
	dispatch({ type: ActionTypes.SAVE_MR_DATA, payload: data });
};

export const deleteMRData = (data) => dispatch => {
	dispatch({ type: ActionTypes.DELETE_MR_DATA, payload: data });
};

export const updateMRData = (data) => dispatch => {
	dispatch({ type: ActionTypes.UPDATE_MR_DATA, payload: data });
};

export const removeMRSuccessMessage = (data) => dispatch => {
	dispatch({ type: ActionTypes.REMOVE_MR_SUCCESS_MESSAGE, payload: data });
};

export const freezeMRPlans = (data) => dispatch => {
	dispatch({ type: ActionTypes.FREEZE_MR_PLANS, payload: data });
};

export const getMrDataViaPost = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_DATA_POST, payload: data })
}

export const getUploadXlList = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_DATA, payload: data })
}