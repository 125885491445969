const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { request } from './../client';
import axios from 'axios';
import { APP_CONFIG } from 'constants/index';

export class FormService {

  static getEntryForms(form) {
    const uri = `${API_BASE_URL}/forms`
    return request(uri, {
      method: 'GET',
      headers: { appId: form.appId }
    })
  }

  static getFormByName(form) {
    const uri = API_BASE_URL + "/formBuilder/getForm/" + form.formName;
    return request(uri, {
      method: 'GET',
      headers: { appId: form.appId }
    });
  }

  static getSubmittedDataBySubmissionId(form) {
    const { id, formName, appId } = form;
    let uri = API_BASE_URL + "/submittedData/submissionId?id=" + id + "&formname=" + formName;
    if (form.ffFy) {
      uri += '&currentFF=' + form.ffFy.currentFF
      uri += '&fiscalYear=' + form.ffFy.fiscalYear
    }
    return request(uri, {
      method: 'GET',
      headers: { appId: appId }
    });
  }

  static saveSubmittedData(data) {
    const { formData } = data
    console.log("====formData", formData)
    const uri = API_BASE_URL + "/submittedData/";

    for (var key in formData.data) {
      if (formData.data.hasOwnProperty(key) && typeof formData.data[key] === 'string') {
        formData.data[key] = formData.data[key].replace(/[^@-_.,&+()'a-zA-Z0-9 \/-]/g, '')
        // replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
        // replace(/[^a-zA-Z0-9]/g, '');
      }
    }
    return request(uri, {
      method: 'POST',
      payload: formData,
      headers: { appId: formData.appId }
    });
  }

  // static saveUploadedFile(data) {
  //   const { files } = data;
  //   console.log("=====files", foiles)
  //   const uri = API_BASE_URL + "/submittedData/upload";
  //   const formData = new FormData();
  //   formData.append("file", files[0].file);
  //   return request(uri, {
  //     method: 'POST',
  //     payload: formData
  //   });
  // }

  static saveUploadedFile({ files, appId, url }) {
    const uri = API_BASE_URL + "/submittedData/upload" + (url ? url : '');
    console.log("=====files", files)
    return axios({
      url: uri,
      method: 'POST',
      data: files,
      headers: {
        'Content-Type': 'multipart/form-data',
        appId: appId
      },
      onUploadProgress: (p) => {

      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
    // return files.map((file) => {
    //   const formData = new FormData();
    //   formData.append('file', file.file);
    //   formData.append('submissionId', id);
    //   formData.append('name', file.name);
    //   console.log("submissionId", file)
    //   const headers = {
    //     'Content-Type': 'multipart/form-data'
    //   }
    //   return request(uri, {
    //     method: 'POST',
    //     payload: formData,
    //     headers
    //   });
    // })

  }

  static updateSubmittedData = (data) => {
    console.log('data', data);
    for (var key in data.data) {
      if (data.data.hasOwnProperty(key) && typeof data.data[key] === 'string') {
        data.data[key] = data.data[key].replace(/[^@-_.,&+()'a-zA-Z0-9 \/-]/g, '')
        // replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
        // replace(/[^a-zA-Z0-9]/g, '');
      }
    }
    const uri = API_BASE_URL + "/submittedData/updateSubmittedData/";
    return request(uri, {
      method: 'PUT',
      payload: data,
      headers: { appId: data.appId }
    });
  }

  static getWorkflowEvents = ({ workflowId, appId }) => {

    if (workflowId) {
      const uri = API_BASE_URL + "/workflows/execute/" + workflowId + "/events/start";
      return request(uri, {
        method: 'GET',
        headers: { appId: appId }
      });
    } else {
      return null
    }

  }

  static deleteSubmittedData = (data) => {
    const uri = API_BASE_URL + `/masterdata/${data.dataModalId}`;
    return request(uri, {
      method: 'DELETE',
      payload: { ids: data.ids, ...data.commonObj, formName: data.formName },
      headers: { appId: data.appId }
    });

  }
}



export function apiBaseAddress() {
  return API_BASE_URL;
}
