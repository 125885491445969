import MomentUtils from "@date-io/moment";
import {
    Checkbox, MenuItem, Paper, Select, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination, TableRow, Tooltip, Typography
} from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import axios from 'axios';
import classNames from 'classnames';
import FileDownload from "js-file-download";
import * as mathjs from "mathjs";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getMonth } from 'utility/utils';
import { generateGUID } from '../../../../modules/page/page.module.js';
import { deleteMRData, getMrData, removeMRSuccessMessage, saveMrData, updateMRData, getMrDataViaPost, getUploadXlList } from '../../../../store/actions/index.js';
import { capitalize, formulaCalculation, getFileNameWithTimeStamp } from '../../../../utility/utils.js';
import Loader from '../../../Loader/index';
import { convertToNumber } from '../../spendplan/PlanningPages/planningHelperFunctions';
import {
    renderNoDataTextMR,
    setSticky,
    StyledTableRow
} from '../../spendplan/PlanningPages/planningHelperFunctions.js';
import ConfirmDialog from '../../spendplan/SpendPlan/ConfirmDialog.js';
import { getFiscalYearFormat } from '../../spendplan/spendplanUtils';
import { sortOrder, URLs } from '../../mrplan/ApiConstants';
import MRPlanUploadConfirmDialog from '../../mrplan/planingPages/Headers/planUploadConfirmDialog';
import ForeCastModal from '../../mrplan/planingPages/foreCastModal';
import { FilterType } from '../../mrplan/planingPages/Headers/headerFilters.js';
import MRPlanHeader, { ActionType } from '../../mrplan/planingPages/Headers/index';
import {
    getFilterFields, getNonPromoHeading, getUoMByType, headingsLabel, KBDTypeValues, Msgs, newPlanJson, planningData, rowDropdownFields,
    shortMonthLowercase, TypeValues, FIRST_CRY
} from '../../mrplan/planingPages/planConfig';
import { useStyles } from '../../mrplan/planingPages/styles.js';
import { AppService } from '../../../../modules/api/index';
import XLSX from 'xlsx';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
let DROPDOWN_DATA = null;
const OmniPlanningPages = props => {
    const classes = useStyles();
    const appid = props.match.params.appid;
    const [open, setOpen] = useState(false);
    const [month, setMonth] = useState();
    const [brand, setBrand] = useState();
    const [brandform, setBrandform] = useState();
    const [sku, setSku] = useState();
    const [type, setType] = useState();
    const [kbdType, setKbdType] = useState();
    // const [period, setPeriod] = useState(undefined);
    const [uom, setUom] = useState(undefined);
    const [inrFilterVal, setInrFilterVal] = useState(undefined);
    const [tsSku, setTSSKU] = useState();
    const [tsBrand, setTSBrand] = useState();
    const [metric, setMetric] = useState();
    const [earningOn, setEarningOn] = useState();
    const [earningType, setEarningType] = useState();
    const [kbd, setKBD] = useState();
    const [category, setCategory] = useState();

    const [pageCount, setPageCount] = useState(0);
    const [fieldData, setFieldData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [rowSelected, setRowSelected] = useState('');
    const [saveButtonState, setSaveButton] = useState(false);
    const [alertMessageText, setAlertMessage] = useState('');
    const [saveBtnDisabled, disableSaveBtn] = useState(false)
    const [checkedRows, setCheckedRows] = useState([]);
    const [deleteConfirmState, setDeleteConfirm] = useState({
        isOpen: false,
        deleteMessage: 'Are you sure you want to delete the selected row(s)?',
    });
    const [confirmState, setConfirm] = useState({ isOpen: false, confirmMessage: '' });
    const [totalSum, setTotalSum] = useState('');
    const [foreCastModel, setForeCastModel] = useState(null);
    const [rowDropdownData, setRowDropdownData] = useState(null);
    const [editedFields, setEditedFields] = useState([]);
    const [originalValues, setOriginalValues] = useState([]);
    const [isFilterAdded, setIsFiltersAdded] = useState(false);
    const [isRowsPerPageChange, setIsRowsPerPageChange] = useState(false);
    const [isPageChange, setIsPageChange] = useState(false);
    const [showClearFilter, setShowClearFilter] = useState(false);
    const [clearAllFilters, setClearAllFilters] = useState(false);
    const [filterInfoID, setFilterInfoID] = useState('');
    const [conditions, setConditions] = useState([])
    const [lastUpdatedData, setLastUpdatedData] = useState({})
    const [xlList, setXlList] = useState();
    const [omniMetricValues, setOmniMetricValues] = useState({});
    const [omniTotalValues, setOmniTotalValues] = useState({});
    const [intialCall, setIntialCall] = useState(false);
    const location = useLocation();

    const { modalData } = props;
    DROPDOWN_DATA = props.data ? props.data['ROW_dropdown' + props.type]?.data : null;

    const getFilters = (forApiCall) => {
        let allFilters = {
            omniPromoCalender: ["category", "brand", "tsBrand", "brandform", "tsSku", "type", "month"],
            mediaCalendar: ["category", "brand", "tsBrand", "brandform", "month"],
            omniSpendsPlanning: ['brand', "tsBrand", "metric", "uom"],
            omniTiff: ["brand", "tsBrand", "brandform", "tsSku", "earningOn", "earningType", "kbd"], // removing period filter
            retailingOfftakePlanning: []
        }
        return allFilters[props.type]
    }

    useEffect(() => {
        setSticky();
    });

    useEffect(() => {
        if (props?.account && !isFilterAdded) {
            setRowsPerPage(50);
            setCurrentPage(0);
            getFilterData();
            getAppliedFilterInfo('init');
        }
    }, [props?.account, props?.type])


    useEffect(() => {
        if (isPageChange) {
            fetchData(conditions);
        }
    }, [currentPage])

    useEffect(() => {
        if (isRowsPerPageChange) {
            fetchData(conditions);
        }
    }, [rowsPerPage])

    const getXlList = () => {
        let url = `${URLs.getOmniTiffXlList}?account=${props?.account?.AccountName}`;
        let formData = {
            url,
            appId: appid,
            dataUniqueId: 'GET_XlList',
        }
        props.getUploadXlList(formData)
    }

    useEffect(() => {
        if (props.type === 'omniTiff') getXlList();
    }, [props?.type])

    useEffect(() => {
        if (props.account && isFilterAdded && (brand || brandform || type || kbdType || type || uom || inrFilterVal || tsSku || tsBrand || metric || earningOn || earningType || kbd || category || month || clearAllFilters)) {
            getTableData()
        }
    }, [brand, brandform, type, kbdType, type, uom, inrFilterVal, tsSku, tsBrand, metric, earningOn, earningType, kbd, category, month, clearAllFilters])

    useEffect(() => {
        if (props?.account) {
            // if (['mediaCalendar'].includes(props.type)) { //TODO: need to move it into constants to avoid errors
            //     getSKUMasterDataForDropdown()
            // }
        }
    }, [props?.account, props?.type])

    const getSKUMasterDataForDropdown = () => {
        let dataModelId = '633ed8695c7fe5001213846a'; // TODO: need to make it configurable
        if (props.type === 'mediaCalendar') {
            dataModelId = '63464949f3978b00209897e2';
        }
        if (props.account) {
            let targetURL = "/dataconnect/table/" + dataModelId;
            targetURL += `?type=list`;
            targetURL += '&page=0' + '&rowsPerPage=all';
            targetURL += '&sum' + 'totalMR';
            targetURL += '&fields=' + rowDropdownFields[props.type];
            if (props.type === 'earningsTFI') {
                targetURL += '&sortOrder=' + JSON.stringify({ brand: 1, brandform: 1, sku: 1 });
            } else {
                targetURL += '&sortOrder=' + JSON.stringify({ brand: 1, brandform: 1, sku: 1 });
            }
            let payload = {
                url: targetURL,
                appId: appid,
                dataUniqueId: 'ROW_dropdown' + props.type
            }
            props.getMrData(payload)
        }
    }


    const getFilterData = (fields = getFilters(true).join(','), _conditions) => {
        if (!fields || fields.length === 0) {
            return;
        }
        let url = '/dataconnect/filter/' + props?.page?.pageLevelDataModel + '?type=chart&label=' + fields;
        let conditions = getCommonConditions();
        let m = '' + getMonth(props?.account?.CurrentFF, 'monthNumber')
        let currentFF = m.length === 1 ? ('0' + m) : m;
        if (props.type === 'omniTiff') {
            let newCond = [{
                "selectedConditionDataElement": 'fiscalYear',
                "selectedConditionOperator": "matches",
                "value": "" + Number(modalData?.year || props.account?.FinancialYear),
                "selectedConditionClause": "and"
            }, {
                "selectedConditionDataElement": 'currentFF',
                "selectedConditionOperator": "matches",
                "value": modalData?.month || currentFF,
                "selectedConditionClause": "and"
            }]
            if (_conditions?.length) {
                conditions = [..._conditions, ...newCond];
            } else {
                conditions = [...newCond];
            }
        } else {
            if (_conditions?.length) {
                conditions = [...conditions, ..._conditions]
            }
        }
        let newConditions = [];
        for (let i = 0; i < conditions.length; i++) {
            if (conditions[i].value && conditions[i].value.length) {
                newConditions.push(conditions[i]);
            }
        }
        if (newConditions?.length) {
            url += '&conditions=' + (newConditions?.length ? JSON.stringify(newConditions) : []);
            setConditions(newConditions);
        }
        let payload = {
            url: url,
            appId: props?.match?.params?.appid,
            dataUniqueId: 'GET_FILTER_' + props?.type,
            isMerge: true,
        }
        props?.getMrData(payload) // true means merge data;
    }

    const getTableData = () => {
        if (props.account) {
            setSaveButton(false);
            localStorage.removeItem('editedItem')
            setCheckedRows([])
            if (props.account && (props.type === 'omniPromoCalender' || props.type === 'omniTiff' || props.type === 'mediaCalendar')) {
                fetchData()
                setRowSelected('');
            } else {
                getApiData(currentPage, rowsPerPage);
            }
        }
    }

    const getAppliedFilterInfo = (str) => {
        const account = props.account
        let payloadToPass = {
            account: modalData?.account || account?.AccountName,
            fiscalYear: modalData?.year.toString() || account?.FinancialYear.toString(),
            dataModelId: props.page.pageLevelDataModel
        }
        let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
        payloadToPass.currentFF = modalData?.month || (m?.length === 1 ? '0' + m : m);
        let url = '/mrspendplan/omni/filter-info'
        let formData = {
            url,
            appId: appid,
            dataUniqueId: 'POST_GET_PAGE_FILTERS',
            payload: payloadToPass,
            str
        }
        props.getMrDataViaPost(formData)
    }

    const getCommonConditions = () => {
        let m = '' + getMonth(props?.account?.CurrentFF, 'monthNumber')
        let currentFF = m.length === 1 ? ('0' + m) : m;
        return [{
            "selectedConditionDataElement": 'account',
            "selectedConditionOperator": "matches",
            "value": "" + encodeURIComponent(modalData?.account || props.account?.AccountName),
            "selectedConditionClause": "and"
        }, {
            "selectedConditionDataElement": 'fiscalYear',
            "selectedConditionOperator": "matches",
            "value": "" + Number(modalData?.year || props.account?.FinancialYear),
            "selectedConditionClause": "and"
        }, {
            "selectedConditionDataElement": 'currentFF',
            "selectedConditionOperator": "matches",
            "value": modalData?.month || currentFF,
            "selectedConditionClause": "and"
        }]
    }

    const getURL = (page, rowsPerPage, isDownloadUrl) => {
        let targetURL = "/dataconnect/" + (isDownloadUrl ? 'download/' : 'table/') + props.page.pageLevelDataModel;
        targetURL += `?type=list&`;
        targetURL += '&page=' + page || 0;
        if (rowsPerPage) {
            targetURL += '&rowsPerPage=' + rowsPerPage;
        }
        targetURL += '&fields=';
        let conditions = getCommonConditions();
        if (brandform) {
            conditions.push({
                "selectedConditionDataElement": 'brandform',
                "selectedConditionOperator": "in",
                "value": brandform,
                "selectedConditionClause": "and"
            })
        }
        if (brand) {
            conditions.push({
                "selectedConditionDataElement": 'brand',
                "selectedConditionOperator": "in",
                "value": brand,
                "selectedConditionClause": "and"
            })
        }
        if (type) {
            conditions.push({
                "selectedConditionDataElement": 'type',
                "selectedConditionOperator": "in",
                "value": type,
                "selectedConditionClause": "and"
            })
        }
        if (sku) {
            conditions.push({
                "selectedConditionDataElement": 'sku',
                "selectedConditionOperator": "in",
                "value": sku,
                "selectedConditionClause": "and"
            })
        }
        if (tsBrand) {
            conditions.push({
                "selectedConditionDataElement": 'tsBrand',
                "selectedConditionOperator": "in",
                "value": tsBrand,
                "selectedConditionClause": "and"
            })
        }
        if (metric) {
            conditions.push({
                "selectedConditionDataElement": 'metric',
                "selectedConditionOperator": "in",
                "value": metric,
                "selectedConditionClause": "and"
            })
        }
        if (uom) {
            conditions.push({
                "selectedConditionDataElement": 'uom',
                "selectedConditionOperator": "in",
                "value": uom,
                "selectedConditionClause": "and"
            })
        }
        if (month) {
            conditions.push({
                "selectedConditionDataElement": 'month',
                "selectedConditionOperator": "in",
                "value": month,
                "selectedConditionClause": "and"
            })
        }
        if (category) {
            conditions.push({
                "selectedConditionDataElement": 'category',
                "selectedConditionOperator": "in",
                "value": category,
                "selectedConditionClause": "and"
            })
        }
        if (conditions && conditions.length && isFilterAdded) {
            const account = props.account;
            let payload;
            if (account) {
                payload = {
                    data: {
                        account: modalData?.account || account?.AccountName,
                        fiscalYear: modalData?.year || account?.FinancialYear,
                    }
                }
                let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
                payload.data.currentFF = modalData?.month || (m?.length === 1 ? '0' + m : m);
            }
            for (let i = 0; i < conditions.length; i++) {
                payload.data[conditions[i].selectedConditionDataElement] = conditions[i].value;
            }
            sendFiltersToAPI(payload)
        }

        targetURL += '&conditions=' + encodeURIComponent(JSON.stringify(conditions));
        targetURL += `&sortOrder=${encodeURIComponent(JSON.stringify(sortOrder[props.type]))}`;
        if (!isDownloadUrl) {
            targetURL += '&sum=totalOmni'
        }
        return targetURL
    }

    const getApiData = (page, rowsPerPage) => {
        if (props?.account) {
            let payload = {
                url: getURL(page, rowsPerPage),
                appId: appid,
                dataUniqueId: 'GET_' + props.type
            }
            props?.getMrData(payload)
        }
    }

    let deleteData = props.data ? props.data['DELETE_' + props.type] : null
    useEffect(() => {
        if (deleteData?.message) {
            setAlertMessage({
                title: Msgs[props.type],
                p1: deleteData?.message,
            });
            handleClickOpen();
        }
        if (deleteData) {
            getFilterData();
            getTableData();
        }
    }, [deleteData]);

    let isError = props.mrData?.error ? props.mrData.error['GET_' + props.type] : null;
    useEffect(() => {
        if (isError) {
            setAlertMessage({
                title: Msgs[props.type],
                p1: isError.message || isError.response?.error,
            });
            handleClickOpen();
        }
    }, [isError]);

    let saveSuccess = props.data ? props.data['POST_' + props.type] : null;
    let forecastSuccess = props.data ? props.data['PUT_FORECAST_' + props.type] : null;

    useEffect(() => {
        if (saveSuccess) {
            setAlertMessage(saveSuccess.result.data ? saveSuccess.result.data : saveSuccess.result)
            handleClickOpen();
            getTableData()
            getFilterData()

        }
        if (forecastSuccess) {
            let msg = forecastSuccess?.result?.data?.message || 'Updated successfully';
            setAlertMessage({ title: 'Value Forecast - Save', p1: msg })
            handleClickOpen();
            getTableData()
        }
    }, [saveSuccess, forecastSuccess]);

    useEffect(() => {
        getTableData();
        console.log('--------10');
    }, [location, props?.account]);

    useEffect(() => {
        const handleClick = () => {
            if(intialCall) setIntialCall(false);
        };
        document.addEventListener('click', handleClick);
        return () => {
          // Cleanup: Remove the event listener
          document.removeEventListener('click', handleClick);
        };
      }, []);

    const appliedFiltersN = props?.data && props?.data['POST_GET_PAGE_FILTERS'] ? props.data['POST_GET_PAGE_FILTERS'] : {};
    useEffect(() => {
        let appliedFilters = appliedFiltersN?.result;
        if (appliedFilters && appliedFilters._id && appliedFilters.filter) {
            setFilterInfoID(appliedFilters._id)
            let keys = Object.keys(appliedFilters.filter);
            if (keys.length) {
                let fields;
                let conditions = [];
                for (let i = 0; i < keys.length; i++) {
                    if (keys[i] === 'category') {
                        setCategory(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'brand') {
                        setBrand(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'brandform') {
                        setBrandform(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'tsBrand') {
                        setTSBrand(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'skuType') {
                        setSkuType(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'sku') {
                        setSku(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'account') {
                        setAccount(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'subBrandForm') {
                        setSubBrandForm(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'productId') {
                        setProductId(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'kbd') {
                        setKBD(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'uom') {
                        setUom(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'eventName') {
                        setEventName(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'legalEntity') {
                        setLegalEntity(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'buisnessUnit') {
                        setBuisnessUnit(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'type') {
                        setType(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'month') {
                        setMonth(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'tsSku') {
                        setTSSKU(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'earningOn') {
                        setEarningOn(appliedFilters['filter'][keys[i]])
                    }
                    if (keys[i] === 'earningType') {
                        setEarningType(appliedFilters['filter'][keys[i]])
                    }
                    conditions.push({
                        "selectedConditionDataElement": keys[i],
                        "selectedConditionOperator": 'in',
                        "value": appliedFilters['filter'][keys[i]],
                        "selectedConditionClause": "and"
                    })
                }
                if (conditions && conditions.length) {
                    setShowClearFilter(true);
                }
                let keyObj = appliedFilters['filter'];
                if (keyObj['month']) {
                    fields = getFilterFields[props.type]['month']
                } else if (keyObj['type']) {
                    fields = getFilterFields[props.type]['type']
                } else if (keyObj['tsSku']) {
                    fields = getFilterFields[props.type]['tsSku']
                } else if (keyObj['brandform']) {
                    fields = getFilterFields[props.type]['brandform']
                } else if (keyObj['tsBrand']) {
                    fields = getFilterFields[props.type]['tsBrand']
                } else if (keyObj['brand']) {
                    fields = getFilterFields[props.type]['brand']
                } else if (keyObj['category']) {
                    fields = getFilterFields[props.type]['category']
                } else if (keyObj['earningOn']) {
                    fields = getFilterFields[props.type]['earningOn']
                } else if (keyObj['earningType']) {
                    fields = getFilterFields[props.type]['earningType']
                }

                setIsFiltersAdded(true);
                setShowClearFilter(true)
                if (appliedFiltersN && appliedFiltersN.str === 'clearAll') {
                    onClearAll();
                } else {
                    if (fields && fields.length) {
                        getFilterData(fields, conditions)
                    }
                }
            } else {
                getFilterData();
                getTableData();
                setShowClearFilter(false);
            }
        } else {
            if(!intialCall) {
                getTableData();
                setIntialCall(true);
            }
            setShowClearFilter(false);
        }
    }, [appliedFiltersN]);

    useEffect(() => {
        if (props.data && props.data['GET_' + props.type] && props.data['GET_' + props.type].result) {
            let planData = props.data['GET_' + props.type].result?.data;
            let total = props.data['GET_' + props.type].result.total;
            if (props.data['GET_' + props.type].result?.updatedAt) {
                setLastUpdatedData({
                    updatedAt: props.data['GET_' + props.type].result.updatedAt || "",
                    updatedBy: props.data['GET_' + props.type].result.updatedBy || ""
                })
            }
            setOriginalValues(JSON.parse(JSON.stringify(planData)))
            if (props.type === 'earningsTFI') {
                let _planData = JSON.parse(JSON.stringify(planData));
                planData = _planData.map(i => {
                    shortMonthLowercase.forEach(m => {
                        if (i.tfiEarningsPerc && i.tfiEarningsPerc[m]) {
                            i.tfiEarningsPerc[m] = convertToNumber(i.tfiEarningsPerc[m] * 100, 2)
                        }
                    })
                    return i
                })
            }
            setPageCount(total);
            setFieldData(planData)
            setIsFiltersAdded(false);
            setIsPageChange(false);
            setClearAllFilters(false)
            setIsRowsPerPageChange(false);
        } else if (props.data && props.data['GET_' + props.type]) {
            let planData = props.data['GET_' + props.type].data || props.data['GET_' + props.type].list;
            let total = props.data['GET_' + props.type].total || props.data['GET_' + props.type].count || 0;
            let totalList = props.data['GET_' + props.type]?.totalData || props.data['GET_' + props.type]?.list || 0;
            let totalSum = props.data['GET_' + props.type].totalSum || 0;
            if (props.data['GET_' + props.type]?.updatedAt) {
                setLastUpdatedData({
                    updatedAt: props.data['GET_' + props.type].updatedAt || "",
                    updatedBy: props.data['GET_' + props.type].updatedBy || ""
                })
            }
            setOriginalValues(JSON.parse(JSON.stringify(planData)))
            setOmniTotalValues(JSON.parse(JSON.stringify(totalList)))
            if (props.type === 'spendsPlanning' || props.type === 'omniSpendsPlanning') {
                let _planData = JSON.parse(JSON.stringify(planData));
                planData = _planData.map(i => {
                    shortMonthLowercase.forEach(m => {
                        if (i.uom?.includes('%')) {
                            i.months[m] = convertToNumber(i.months[m] * 100, 2)
                        }
                    })
                    return i
                })
            } else if (props.type === 'omniPromoCalender') {
                let _planData = JSON.parse(JSON.stringify(planData));
                planData = _planData.map(i => {
                    i.depthMrp = i.depthMrp * 100;
                    i.depthRlp = i.depthRlp * 100;
                    return i
                })
            }
            if (props.type === 'omniTiff') {
                if (props.data && props.data['GET_XlList']) {
                    let xlList = [...props.data['GET_XlList']?.data];
                    setXlList(xlList);
                }
            }
            setTotalSum(totalSum);
            setEditedFields([]);
            setPageCount(total);
            setFieldData(planData)
            setIsFiltersAdded(false);
            setIsPageChange(false);
            setClearAllFilters(false);
            setIsRowsPerPageChange(false);
            // getAppliedFilterInfo('again')
        }
    }, [props.data]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (deleteData?.message) {
            props.removeMRSuccessMessage({ dataUniqueId: 'DELETE_' + props.type });
            getTableData()
            getFilterData()
            setCheckedRows([])
        } else if (saveSuccess) {
            props.removeMRSuccessMessage({ dataUniqueId: 'POST_' + props.type });
        } else if (forecastSuccess) {
            props.removeMRSuccessMessage({ dataUniqueId: 'PUT_FORECAST_' + props.type });
        } else {
            props.removeMRSuccessMessage({ dataUniqueId: 'GET_' + props.type });
        }

    };

    const handleFieldChange = (target, index, column, subKey, id, str) => {
        // logic to save edited fields data (Data used on save button click)
        let obj = {
            index,
            column,
            value: target.value,
            id
        }
        let flag = false;
        let newEditedFields = JSON.parse(JSON.stringify(editedFields));
        for (let i = 0; i < editedFields.length; i++) {
            if (editedFields[i].index === obj.index && editedFields[i].column === obj.column) {
                newEditedFields[i].value = obj.value;
                flag = true;
                break;
            }
        }
        if (!flag && str === 'change') {
            newEditedFields.push(obj);
        }
        setEditedFields([...newEditedFields]);
        // logic ends
        const { name, value } = target;
        setSaveButton(true)
        localStorage.setItem('editedItem', 'true');
        saveBtnDisabled && disableSaveBtn(false);
        let oldData = JSON.parse(JSON.stringify(fieldData));
        if (oldData && oldData[index]) {
            if (!subKey) {
                oldData[index][column] = value;
                if (props.type === 'promo' && column === 'retailingForecast') {
                    oldData[index]['offtakeForecast'] = value;
                    oldData[index]['offtakeActuals'] = value;
                }
                if (props.type === 'spendsPlanning' && name === 'brand') {
                    let _rowDropDownData = { ...rowDropdownData };
                    _rowDropDownData[oldData[index]['_id']] = {
                        ..._rowDropDownData[oldData[index]['_id']],
                        _brandform: DROPDOWN_DATA ? ['ALL', ...new Set(DROPDOWN_DATA.filter(i => i.brand === value).map(i => i.brandform))] : [],
                    }
                    setRowDropdownData(_rowDropDownData)
                }
                if (props.type === 'earningsTFI' && name === 'brand') {
                    oldData[index]['brandform'] = ''
                    oldData[index]['sku'] = ''
                    if (value === '') {
                        let _rowDropDownData = { ...rowDropdownData };
                        _rowDropDownData[oldData[index]['_id']] = {
                            ..._rowDropDownData[oldData[index]['_id']],
                            _brandform: [],
                            _sku: [],
                        }
                        setRowDropdownData(_rowDropDownData)
                    } else {
                        let _rowDropDownData = { ...rowDropdownData };
                        _rowDropDownData[oldData[index]['_id']] = {
                            ..._rowDropDownData[oldData[index]['_id']],
                            _brandform: DROPDOWN_DATA ? [...new Set(DROPDOWN_DATA.filter(i => i.brand === value).map(i => i.brandform))] : [],
                        }
                        setRowDropdownData(_rowDropDownData)
                    }
                }
                if (props.type === 'earningsTFI' && name === 'brandform') {
                    oldData[index]['sku'] = ''
                    if (value === '') {
                        let _rowDropDownData = { ...rowDropdownData };
                        _rowDropDownData[oldData[index]['_id']] = {
                            ..._rowDropDownData[oldData[index]['_id']],
                            _sku: [],
                        }
                        setRowDropdownData(_rowDropDownData)
                    } else {
                        let conditions = oldData[index]['brand']
                        if (conditions) {
                            let allSKUs = DROPDOWN_DATA ? [...new Set(DROPDOWN_DATA.filter(i => i.brand === conditions && i.brandform === value).map(i => i.sku))] : []
                            let _rowDropDownData = { ...rowDropdownData };
                            _rowDropDownData[oldData[index]['_id']] = {
                                ..._rowDropDownData[oldData[index]['_id']],
                                _sku: ['ALL', ...allSKUs],
                            }
                            setRowDropdownData(_rowDropDownData)
                        }
                    }
                }
                if (props.type === 'spendsPlanning' && name === 'type') {
                    if (value === '') {
                        oldData[index]['uom'] = ''
                    } else {
                        oldData[index]['uom'] = getUoMByType(props.account.Format1, value) || ''
                    }
                }
                // if (props.type === 'omniPromoCalender') {
                //     if (value && value !== '') {
                //         if (oldData[index] && oldData[index][column]) {
                //             oldData[index][column] = parseInt(value, 0);
                //         }
                //     }
                // }
            } else {
                oldData[index][column][subKey] = value;
            }
            setFieldData([...oldData]);
        }

    };

    const displayError = (field, rowNo) => {
        setAlertMessage({
            title: Msgs[props.type] + ' - Mandatory Field',
            p1: `${field} field is mandatory for a plan`,
            p2: `Please check row number ${rowNo}`,
        });
        handleClickOpen();
    }

    const validate = () => {
        if (props.type === 'spendsPlanning' || props.type === 'earningsTFI') {
            let isFound = fieldData.find((item, index) => {
                if (props.type === 'earningsTFI') {
                    if (item.format?.length === 0) {
                        displayError('Format', index + 1)
                        return item;
                    }
                    if (item.kbdType?.length === 0) {
                        displayError('KBD Type', index + 1)
                        return item;
                    }
                }
                if (item.brand?.length === 0) {
                    displayError('Brand', index + 1)
                    return item;
                }
                if (props.type === 'spendsPlanning') {
                    if (item.type?.length === 0) {
                        displayError('Type', index + 1)
                        return item;
                    }
                    if (item.uom?.length === 0) {
                        displayError('UOM', index + 1)
                        return item;
                    }
                }
                if (props.type === 'earningsTFI') {
                    if (item.brandform?.length === 0) {
                        displayError('Brandform', index + 1)
                        return item;
                    }
                    if (item.sku?.length === 0) {
                        displayError('SKU', index + 1)
                        return item;
                    }
                }
            })
            if (isFound) {
                return false;
            }
        }
        return true;
    }

    const checkWithPrevValue = (newObj) => {
        let flag = false;
        for (let i = 0; i < originalValues.length; i++) {
            if (originalValues[i]._id === newObj.id && newObj.value === (originalValues[i][newObj.column]).toString()) {
                flag = false;
                break;
            } else {
                flag = true;
            }
        }
        return flag;
    }

    const saveButtonHandler = async () => {
        let isValidated = validate()
        if (isValidated) {
            let dataToSend = JSON.parse(JSON.stringify(fieldData));
            dataToSend.forEach(element => {
                if (element.editable) {
                    delete element.editable;
                }
                if (element._kbdType) {
                    delete element._kbdType;
                }
                if (element._brand) {
                    delete element._brand;
                }
                if (element._brandform) {
                    delete element._brandform;
                }
                if (element._sku) {
                    delete element._sku;
                }
                if (element._type) {
                    delete element._type;
                }
                if (element._id.startsWith('newplan_')) {
                    delete element._id
                }
                if (props.type === 'earningsTFI') {
                    shortMonthLowercase.forEach(m => {
                        if (element.tfiEarningsPerc && element.tfiEarningsPerc[m]) {
                            element.tfiEarningsPerc[m] = element.tfiEarningsPerc[m] / 100
                        }
                    })
                }
                if (props.type === 'spendsPlanning' || props.type === 'omniSpendsPlanning') {
                    shortMonthLowercase.forEach(m => {
                        if (element.uom?.includes('%')) {
                            element.months[m] = element.months[m] / 100
                        }
                    })
                }
            });
            let payloadToSend = [];
            if (props.type === 'omniPromoCalender') {
                for (let j = 0; j < editedFields.length; j++) {
                    let dataPresent = false;
                    for (let i = 0; i < payloadToSend.length; i++) {
                        if (editedFields[j].id === payloadToSend[i]._id) {
                            payloadToSend[i][editedFields[j].column] = editedFields[j].value
                            dataPresent = true;
                            switch (editedFields[j].column) {
                                case 'retailQtyForecast': {
                                    payloadToSend[i]['isEditRTQ'] = editedFields[j].value === '' ? false : checkWithPrevValue(editedFields[j]);
                                    break;
                                }
                                case 'offtakeForecastPieces': {
                                    payloadToSend[i]['isEditOFP'] = editedFields[j].value === '' ? false : checkWithPrevValue(editedFields[j]);
                                    break;
                                }
                                case 'offtakeActualPieces': {
                                    payloadToSend[i]['isEditOAP'] = editedFields[j].value === '' ? false : checkWithPrevValue(editedFields[j]);
                                    break;
                                }
                            }
                        }
                    }
                    if (!dataPresent) {
                        let obj = {
                            'account': dataToSend[editedFields[j].index].account,
                            'fiscalYear': dataToSend[editedFields[j].index].fiscalYear,
                            'currentFF': dataToSend[editedFields[j].index].currentFF,
                            'category': dataToSend[editedFields[j].index].category,
                            'brand': dataToSend[editedFields[j].index].brand,
                            'tsBrand': dataToSend[editedFields[j].index].tsBrand,
                            'brandform': dataToSend[editedFields[j].index].brandform,
                            'tsSku': dataToSend[editedFields[j].index].tsSku,
                            'type': dataToSend[editedFields[j].index].type,
                            'month': dataToSend[editedFields[j].index].month,
                            '_id': dataToSend[editedFields[j].index]._id,
                        }
                        switch (editedFields[j].column) {
                            case 'retailQtyForecast': {
                                obj['isEditRTQ'] = editedFields[j].value === '' ? false : checkWithPrevValue(editedFields[j]);
                                break;
                            }
                            case 'offtakeForecastPieces': {
                                obj['isEditOFP'] = editedFields[j].value === '' ? false : checkWithPrevValue(editedFields[j]);
                                break;
                            }
                            case 'offtakeActualPieces': {
                                obj['isEditOAP'] = editedFields[j].value === '' ? false : checkWithPrevValue(editedFields[j]);
                                break;
                            }
                        }

                        if (editedFields[j].column === 'depthMrp' && editedFields[j].value !== '') {
                            obj['depthMrp'] = Number(editedFields[j].value) / 100;
                        } else if (editedFields[j].column === 'depthRlp' && editedFields[j].value !== '') {
                            obj['depthRlp'] = Number(editedFields[j].value) / 100;
                        } else {
                            if (editedFields[j].value !== '') {
                                obj[editedFields[j].column] = Number(editedFields[j].value)
                            } else {
                                obj[editedFields[j].column] = editedFields[j].value
                            }
                        }
                        if (checkWithPrevValue(editedFields[j])) {
                            payloadToSend.push(obj)
                        }
                    }

                }
            }
            if ((props.type === 'omniPromoCalender' && payloadToSend.length) || (props.type !== 'omniPromoCalender' && dataToSend.length)) {
                let formData = {
                    url: '/mrspendplan/upload/' + props.page.pageLevelDataModel,
                    appId: appid,
                    dataUniqueId: 'POST_' + props.type,
                    payload: props.type === 'omniPromoCalender' ? payloadToSend : dataToSend
                }
                let res = await props.updateMRData(formData); //update response
                disableSaveBtn(true)
            } else {
                setAlertMessage({ title: 'Promo Planning', p1: 'Please change a value' });
            }
        }
    };

    const fetchData = () => {
        const account = props.account;
        if (account) {
            let objToSend = {
                rowsPerPage: inrFilterVal || rowsPerPage,
                page: currentPage,
                fiscalYear: modalData?.year || account?.FinancialYear,
                account: modalData?.account || account?.AccountName,
            };
            let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
            objToSend.currentFF = modalData?.month || (m?.length === 1 ? '0' + m : m);
            getDataFromApi(objToSend);
        }
    };

    const sendFiltersToAPI = (payload) => {
        let payloadToPass = JSON.parse(JSON.stringify(payload.data));
        let url = '/mrspendplan/omni/filters'
        payloadToPass['filter'] = JSON.parse(JSON.stringify(payloadToPass));
        delete payloadToPass.filter['account'];
        delete payloadToPass.filter['currentFF'];
        delete payloadToPass.filter['fiscalYear'];
        payloadToPass['dataModelId'] = props.page.pageLevelDataModel;
        let formData = {
            url,
            appId: appid,
            dataUniqueId: 'POST_ADD_FILTER',
            payload: payloadToPass
        }
        props.getMrDataViaPost(formData)
    }

    //TODO: need to break this files into parts
    const getDataFromApi = (params) => {
        let url;
        if (props.type === 'omniPromoCalender') {
            url = URLs.getPromoCalendar;
        } else if (props.type === 'mediaCalendar') {
            url = URLs.getMediaCalendar;
        } else if (props.type === 'omniTiff') {
            url = URLs.omniTiff;
        }
        if (props.type === 'omniPromoCalender' || props.type === 'omniTiff' || props.type === 'mediaCalendar') {
            const account = props.account;
            if (account) {
                const payload = {
                    data: {
                        account: modalData?.account || account?.AccountName,
                        fiscalYear: modalData?.year?.toString() || account?.FinancialYear?.toString(),
                    },
                    pagination: {
                        page: currentPage.toString(),
                        rowsPerPage: (inrFilterVal || rowsPerPage).toString()
                    },
                    // sortOrder: {}
                }
                let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
                if(props?.modalData && props.type === 'omniPromoCalender') payload.version = true;
                payload.data.currentFF = modalData?.month || (m?.length === 1 ? '0' + m : m);
                if (!clearAllFilters) {
                    if (month && month.length) {
                        payload.data.month = month;
                    }
                    if (brand && brand.length) {
                        payload.data.brand = brand;
                    }
                    if (brandform && brandform.length) {
                        payload.data.brandform = brandform;
                    }
                    if (tsSku && tsSku.length) {
                        payload.data.tsSku = tsSku;
                    }
                    if (earningOn && earningOn.length) {
                        payload.data.earningOn = earningOn;
                    }
                    if (earningType && earningType.length) {
                        payload.data.earningType = earningType;
                    }
                    if (kbd && kbd.length) {
                        payload.data.kbd = kbd;
                    }
                    if (tsBrand && tsBrand.length) {
                        payload.data.tsBrand = tsBrand;
                    }
                    if (category && category.length) {
                        payload.data.category = category;
                    }
                    if (type && type.length) {
                        payload.data.type = type;
                    }
                    if (Object.keys(payload.data).length > 2 && isFilterAdded) {
                        sendFiltersToAPI(payload)
                    }
                }
                let formData = {
                    url,
                    appId: appid,
                    dataUniqueId: 'GET_' + props.type,
                    payload
                }
                props.getMrDataViaPost(formData)
            }
        } else {
            url = getURL(currentPage, rowsPerPage);
            // url += '?' + new URLSearchParams(params).toString();
            // if (month) {
            //     url += '&month=' + month;
            // }
            // if (brand) {
            //     url += '&brand=' + encodeURIComponent(brand);
            // }
            // if (brandform) {
            //     url += '&brandform=' + encodeURIComponent(brandform);
            // }
            // if (sku) {
            //     url += '&sku=' + encodeURIComponent(sku);
            // }
            // if (props.type === 'earningsTFI') {
            //     if (kbdType) {
            //         url += '&kbdType=' + encodeURIComponent(kbdType)
            //     }
            // }
            // if (inrFilterVal) {
            //     url += '&sortByINRTotal=' + inrFilterVal;
            // }
            // url += `&sortOrder=${encodeURIComponent(JSON.stringify(sortOrder[props.type]))}`;

            let payload = {
                url: url,
                appId: appid,
                dataUniqueId: 'GET_' + props.type
            }
            props.getMrData(payload)
        }
    }

    const handleXlDownload = (name) => {
        let uri = `${API_BASE_URL}${URLs?.downloadOmniTiffXlList}?file=${name?.trim()}`
        axios({
            url: uri,
            method: 'GET',
            responseType: 'blob',
            headers: {
                appId: props.match.params.appid
            },
        }).then((response) => {
            FileDownload(response.data, name);
        })
    }

    const downloadButtonHandler = () => {
        let uri;
        const account = props.account;
        let params = {}
        if (account) {
            let objToSend = {
                fiscalYear: account?.FinancialYear?.toString(),
                account: account?.AccountName,
                rowsPerPage: props.data ? (props.data['GET_' + props.type]?.result?.total || 10000) : 10000
            };
            let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
            objToSend.currentFF = m?.length === 1 ? '0' + m : m;
            params = objToSend;
            if (props.type === 'omniPromoCalender' || props.type === 'omniTiff' || props.type === 'mediaCalendar') {
                uri = URLs.download[props.type]
                const payload = {
                    data: {
                        account: modalData?.account || account?.AccountName,
                        fiscalYear: modalData?.year?.toString() || account?.FinancialYear?.toString(),
                    },
                    pagination: {
                        page: currentPage,
                    },
                }
                let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
                if(props?.modalData && props.type === 'omniPromoCalender') payload.version = true;
                payload.data.currentFF = modalData?.month || (m?.length === 1 ? '0' + m : m);
                if (month && month.length) {
                    payload.data.month = month;
                }
                if (brand && brand.length) {
                    payload.data.brand = brand;
                }
                if (brandform && brandform.length) {
                    payload.data.brandform = brandform;
                }
                if (tsSku && tsSku.length) {
                    payload.data.tsSku = tsSku;
                }
                if (earningOn && earningOn.length) {
                    payload.data.earningOn = earningOn;
                }
                if (earningType && earningType.length) {
                    payload.data.earningType = earningType;
                }
                if (kbd && kbd.length) {
                    payload.data.kbd = kbd;
                }
                if (tsBrand && tsBrand.length) {
                    payload.data.tsBrand = tsBrand;
                }
                if (category && category.length) {
                    payload.data.category = category;
                }
                if (type && type.length) {
                    payload.data.type = type;
                }
                axios({
                    url: API_BASE_URL + uri,
                    method: 'POST',
                    responseType: 'blob',
                    data: payload,
                    headers: {
                        appId: props.match.params.appid
                    },
                }).then((response) => {
                    let fyFF = getFiscalYearFormat(account.FinancialYear) + '-' + moment().month(account.CurrentFF).format('MMM');
                    FileDownload(response.data, getFileNameWithTimeStamp(props.page.name + '-' + params.account + '-' + fyFF));
                }).catch((error) => {
                    console.log(error);
                })
            } else {
                let _allRows = props.data ? (props.data['GET_' + props.type]?.total || 1000) : 1000;
                uri = getURL(0, _allRows, true);
            }
            if (props.type !== 'omniPromoCalender' && props.type !== 'mediaCalendar') {
                axios({
                    url: API_BASE_URL + uri,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                        appId: props.match.params.appid
                    },
                }).then((response) => {
                    let fyFF = getFiscalYearFormat(account.FinancialYear) + '-' + moment().month(account.CurrentFF).format('MMM');
                    FileDownload(response.data, getFileNameWithTimeStamp(props.page.name + '-' + params.account + '-' + fyFF));
                }).catch((error) => {
                    console.log(error);
                })
            }
        }
    }

    const selectRow = (index) => {
        setRowSelected(rowSelected === index ? '' : index);
    };

    const handleChangePage = (event, newPage) => {
        setIsPageChange(true);
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setIsRowsPerPageChange(true);
        setRowsPerPage(parseInt(event.target?.value, 10));
        setCurrentPage(0);
    };

    const onCheckHandler = (row, index) => {
        let newCheckedRows = JSON.parse(JSON.stringify(checkedRows));
        if (!newCheckedRows.includes(row)) {
            newCheckedRows.push(row);
        } else {
            newCheckedRows.splice(newCheckedRows.indexOf(row), 1);
        }
        setCheckedRows(newCheckedRows);
    };

    const onDeleteRecords = () => {
        if (checkedRows?.length) {
            setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: 'Are you sure you want to delete the selected row(s)?' })
        } else {
            setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: 'Are you sure you want to delete the all row(s)?' })
        }
    }


    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            const account = props.account;
            let index = checkedRows.findIndex(i => i.startsWith('newplan'));
            let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
            let payload = {
                dataUniqueId: 'DELETE_' + props.type,
                url: URLs.deletePlanning + props.page.pageLevelDataModel,
                appid,
                data: {
                    ids: checkedRows,
                    fiscalYear: account?.FinancialYear,
                    account: account?.AccountName,
                    currentFF: m?.length === 1 ? '0' + m : m
                },
            }
            // if (props.type === 'omniTiff') {
            //     delete payload.data.account;
            // }
            if (index !== -1) {
                let newPlanIds = checkedRows.filter(i => i.startsWith('newplan'));
                let _fieldData = fieldData.filter(i => !newPlanIds.includes(i._id));
                setFieldData(_fieldData);
                if (checkedRows?.length > newPlanIds?.length) {
                    let restIds = checkedRows.filter(i => !newPlanIds.includes(i));
                    payload.data = { ids: restIds }
                    props.deleteMRData(payload);
                }
            } else {
                props.deleteMRData(payload);
            }
            setDeleteConfirm({ ...deleteConfirmState, isOpen: false });
        };
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false });
    }

    const createNewPlanPress = () => {
        var startTime = performance.now()

        let oldData = JSON.parse(JSON.stringify(fieldData));
        let m = '' + getMonth(props.account?.CurrentFF, 'monthNumber')
        let currentFF = m?.length === 1 ? '0' + m : m;
        let dataToAdd = newPlanJson[props.type];
        dataToAdd['account'] = props.account?.AccountName;
        dataToAdd['formType'] = props.type;
        dataToAdd['currentFF'] = currentFF;
        dataToAdd['fiscalYear'] = Number(props.account?.FinancialYear) + "";
        dataToAdd['_id'] = 'newplan_' + generateGUID();
        if (kbdType) {
            dataToAdd['kbdType'] = kbdType;
        }
        if (brand) {
            dataToAdd['brand'] = brand;
        }
        if (brandform) {
            dataToAdd['brandform'] = brandform;
        }
        if (sku) {
            dataToAdd['sku'] = sku;
        }
        if (type) {
            dataToAdd['type'] = type;
        }
        // if (period) {
        //     dataToAdd['period'] = period;
        // }
        if (props.type === 'earningsTFI') {
            dataToAdd['format'] = props.account.Format1;
            let _rowDownData = rowDropdownData ? { ...rowDropdownData } : {};
            _rowDownData[dataToAdd['_id']] = {
                _brand: DROPDOWN_DATA ? [...new Set(DROPDOWN_DATA.map(i => i.brand))] : [],
                _kbdType: KBDTypeValues,
            }
            setRowDropdownData(_rowDownData);
        }
        if (props.type === 'spendsPlanning') {
            let _rowDownData = rowDropdownData ? { ...rowDropdownData } : {};
            _rowDownData[dataToAdd['_id']] = {
                _brand: DROPDOWN_DATA ? [...new Set(DROPDOWN_DATA.map(i => i.brand))] : [],
                _type: TypeValues(props.account.Format1),
            }
            setRowDropdownData(_rowDownData);
        }
        oldData = [dataToAdd, ...oldData];
        setFieldData(oldData);
        setRowSelected(0);
        setSaveButton(true);
        localStorage.setItem('editedItem', 'true')
        saveBtnDisabled && disableSaveBtn(false);
        var endTime = performance.now()

    }

    const onEdit = () => {
        let record = { ...fieldData[rowSelected] };
        let _rowDropDownData = { ...rowDropdownData };
        if (props.type === 'earningsTFI') {
            let allSKUs = DROPDOWN_DATA ? ['ALL', ...new Set(DROPDOWN_DATA.filter(i => i.brand === record['brand'] &&
                i.brandform === record['brandform']).map(i => i.sku))] : []
            _rowDropDownData[record._id] = { _sku: allSKUs }
            setRowDropdownData(_rowDropDownData)
            record['_sku'] = true;
            let _fieldData = [...fieldData];
            _fieldData[rowSelected] = record;
            setFieldData(_fieldData)
        }
        if (props.type === 'spendsPlanning') {
            let record = { ...fieldData[rowSelected] };
            let _rowDropDownData = { ...rowDropdownData };
            let allBrandforms = DROPDOWN_DATA ? ['ALL', ...new Set(DROPDOWN_DATA.filter(i => i.brand === record['brand']).map(i => i.brandform))] : []
            _rowDropDownData[record._id] = { _brandform: allBrandforms }
            setRowDropdownData(_rowDropDownData)
            record['_brandform'] = true;
            let _fieldData = [...fieldData];
            _fieldData[rowSelected] = record;
            setFieldData(_fieldData)
        }
    }

    const onDoneUpdateClick = () => {
        setConfirm({
            ...confirmState, isOpen: true,
            confirmMessage: `${props.page.name} data update done for  ${getMonth(props.account?.CurrentFF, 'month')} ${getFiscalYearFormat(props.account?.FinancialYear)}?`,
        });
    }

    const onAction = (actionType) => {
        switch (actionType) {
            case ActionType.Save:
                saveButtonHandler();
                if (props.type === 'omniTiff') getXlList();
                break;
            case ActionType.NewPlan:
                createNewPlanPress();
                break;
            case ActionType.DoneUpdates:
                onDoneUpdateClick();
                break;
            case ActionType.Delete:
                onDeleteRecords();
                break;
            case ActionType.Download:
                downloadButtonHandler();
                break;
            case ActionType.Upload:
                getTableData()
                getFilterData()
                if (props.type === 'omniTiff') getXlList();
                break;
            case ActionType.Edit:
                onEdit()
                break;
            case ActionType.Clear:
                onClearAll()
                break;
        }
    };

    const onClearAll = () => {
        if (filterInfoID) {
            let url = `${API_BASE_URL}` + '/mrspendplan/omni/filter/' + props?.page?.pageLevelDataModel;
            let account = props.account;
            let dataToPass = {
                account: modalData?.account || account?.AccountName,
                fiscalYear: modalData?.year || account?.FinancialYear,
            }
            let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
            dataToPass.currentFF = modalData?.month || (m?.length === 1 ? '0' + m : m);
            axios({
                url: url,
                method: 'DELETE',
                headers: {
                    appId: props.match.params.appid,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    getAppliedFilterInfo('clearAll');
                    setShowClearFilter(false);
                    setIsFiltersAdded(true)
                    setClearAllFilters(true);
                    getFilterData();
                    setBrand(''); setBrandform(''); setType(''); setKbdType(''); setType(''); setUom(''); setInrFilterVal(''); setTSSKU(''); setTSBrand(''); setMetric(''); setEarningOn(''); setEarningType(''); setKBD(''); setCategory(''); setMonth('');
                    toast.success(response.data?.message || 'Cleared successfully');
                })
                .catch((error) => {
                    toast.error(error?.data?.message || 'Not able to send workflow emails');
                });
        } else {
            getAppliedFilterInfo('clearAll');
        }
    }

    const onFilterChange = (filterType, value) => {
        let _conditions = [];
        switch (filterType) {
            case FilterType.Category:
                if (category !== value) {
                    setCategory(value);
                    setBrand('');
                    setBrandform('');
                    setSku('')
                    setType('')
                    setEarningOn('')
                    setEarningType('')
                    setKBD('')
                    setTSBrand('')
                    setTSSKU('')
                    setMonth('')

                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    break;
                }
            case FilterType.Month:
                if (month !== value) {
                    let newValue = value.map(v => v.toLowerCase())
                    setMonth(newValue);
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (category) {
                        _conditions.push({
                            "selectedConditionDataElement": 'category',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? category : encodeURIComponent(category),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brand : encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsBrand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsBrand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsBrand : encodeURIComponent(tsBrand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brandform : encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsSku) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsSku',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsSku : encodeURIComponent(tsSku),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (type) {
                        _conditions.push({
                            "selectedConditionDataElement": 'type',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? type : encodeURIComponent(type),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.KBDType:
                if (kbdType !== value) {
                    setKbdType(value);
                    setBrand('');
                    setBrandform('');
                    setSku('')
                    setType('')
                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    break;
                }
            case FilterType.Brand:
                if (brand !== value) {
                    setBrand(value);
                    setBrandform('');
                    setSku('')
                    setType('')
                    setEarningOn('')
                    setEarningType('')
                    setKBD('')
                    setTSBrand('')
                    setTSSKU('')
                    setMonth('')
                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (category) {
                        _conditions.push({
                            "selectedConditionDataElement": 'category',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? category : encodeURIComponent(category),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (kbdType) {
                        _conditions.push({
                            "selectedConditionDataElement": 'kbdType',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? kbdType : encodeURIComponent(kbdType),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.Brandform:
                if (brandform !== value) {
                    setBrandform(value);
                    setSku('')
                    setType('')
                    setUom('');
                    setEarningOn('')
                    setEarningType('')
                    setKBD('')
                    setMonth('')
                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (category) {
                        _conditions.push({
                            "selectedConditionDataElement": 'category',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? category : encodeURIComponent(category),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brand : encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (kbdType) {
                        _conditions.push({
                            "selectedConditionDataElement": 'kbdType',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? kbdType : encodeURIComponent(kbdType),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.SKU:
                if (sku !== value) {
                    setSku(value);
                    setType('')
                    setEarningOn('')
                    setEarningType('')
                    setKBD('')
                    setMonth('')
                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (category) {
                        _conditions.push({
                            "selectedConditionDataElement": 'category',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? category : encodeURIComponent(category),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (kbdType) {
                        _conditions.push({
                            "selectedConditionDataElement": 'kbdType',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? kbdType : encodeURIComponent(kbdType),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brand : encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsBrand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsBrand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsBrand : encodeURIComponent(tsBrand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brandform : encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.TSSKU:
                if (tsSku !== value) {
                    setTSSKU(value);
                    setType('')
                    setEarningOn('')
                    setEarningType('')
                    setKBD('')
                    setMonth('');
                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (category) {
                        _conditions.push({
                            "selectedConditionDataElement": 'category',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? category : encodeURIComponent(category),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brand : encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsBrand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsBrand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsBrand : encodeURIComponent(tsBrand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brandform : encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.TSBrand:
                if (tsBrand !== value) {
                    setTSBrand(value);
                    setMetric('')
                    setUom('')
                    setEarningOn('')
                    setEarningType('')
                    setKBD('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    setMonth('')
                    if (category) {
                        _conditions.push({
                            "selectedConditionDataElement": 'category',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? category : encodeURIComponent(category),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brand : encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.Metric:
                if (metric !== value) {
                    setMetric(value);
                    setUom('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brand : encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsBrand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsBrand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsBrand : encodeURIComponent(tsBrand),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            case FilterType.Type: {
                if (type !== value) {
                    setType(value);
                    setMonth('');
                    if (props.type === 'spendsPlanning') {
                        setBrandform('')
                    }
                    // setPeriod('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (category) {
                        _conditions.push({
                            "selectedConditionDataElement": 'category',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? category : encodeURIComponent(category),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brand : encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsBrand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsBrand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsBrand : encodeURIComponent(tsBrand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brandform : encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (sku) {
                        _conditions.push({
                            "selectedConditionDataElement": 'sku',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? sku : encodeURIComponent(sku),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (kbdType) {
                        _conditions.push({
                            "selectedConditionDataElement": 'kbdType',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? kbdType : encodeURIComponent(kbdType),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            }
            case FilterType.UOM:
                if (uom !== value) {
                    setUom(value);
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    break;
                }
            case FilterType.INR:
                if (inrFilterVal !== value) {
                    setInrFilterVal(value);
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    break;
                }
            case FilterType.EarningOn: {
                if (earningOn !== value) {
                    setEarningOn(value);
                    setEarningType('');
                    setKBD('');
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brand : encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsBrand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsBrand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsBrand : encodeURIComponent(tsBrand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brandform : encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsSku) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsSku',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsSku : encodeURIComponent(tsSku),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            }
            case FilterType.Earningtype: {
                if (earningType !== value) {
                    setEarningType(value);
                    setKBD('')
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brand : encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsBrand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsBrand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsBrand : encodeURIComponent(tsBrand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brandform : encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsSku) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsSku',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsSku : encodeURIComponent(tsSku),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (earningOn) {
                        _conditions.push({
                            "selectedConditionDataElement": 'earningOn',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? earningOn : encodeURIComponent(earningOn),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            }
            case FilterType.KBD: {
                if (kbd !== value) {
                    setKBD(value);
                    setCurrentPage(0);
                    setRowsPerPage(200);
                    if (brand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brand : encodeURIComponent(brand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsBrand) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsBrand',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsBrand : encodeURIComponent(tsBrand),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (brandform) {
                        _conditions.push({
                            "selectedConditionDataElement": 'brandform',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? brandform : encodeURIComponent(brandform),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (tsSku) {
                        _conditions.push({
                            "selectedConditionDataElement": 'tsSku',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? tsSku : encodeURIComponent(tsSku),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (earningOn) {
                        _conditions.push({
                            "selectedConditionDataElement": 'earningOn',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? earningOn : encodeURIComponent(earningOn),
                            "selectedConditionClause": "and"
                        })
                    }
                    if (earningType) {
                        _conditions.push({
                            "selectedConditionDataElement": 'earningType',
                            "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                            "value": props.appName === 'omnichannel' ? earningType : encodeURIComponent(earningType),
                            "selectedConditionClause": "and"
                        })
                    }
                    break;
                }
            }
        }
        setIsFiltersAdded(true);
        setShowClearFilter(true)
        if ([FilterType.Brand, FilterType.SKU, FilterType.Brandform, FilterType.KBDType, FilterType.Type, FilterType.TSBrand, FilterType.Metric, FilterType.UOM, FilterType.TSSKU, FilterType.KBD, FilterType.EarningOn, FilterType.Earningtype, FilterType.Category, FilterType.Month].includes(filterType)) {
            if (value === '' || !(value && value.length)) {
                let fields = getFilterFields[props.type][filterType];
                if (!fields.includes(filterType)) {
                    fields.push(filterType)
                }
                getFilterData(fields, _conditions)
            } else if (getFilterFields[props.type][filterType]?.length) {
                _conditions.push({
                    "selectedConditionDataElement": filterType,
                    "selectedConditionOperator": props.appName === 'omnichannel' ? 'in' : "is_equal_to",
                    "value": props.appName === 'omnichannel' ? value : encodeURIComponent(value),
                    "selectedConditionClause": "and"
                })
                getFilterData(getFilterFields[props.type][filterType], _conditions)
            }
        }
    };

    const isMonthForecast = (monthKey) => {
        // monthKey: jan, feb ,mar
        if (props.account?.CurrentFF && shortMonthLowercase.indexOf(monthKey) + 1 > shortMonthLowercase.indexOf((moment().month(props.account.CurrentFF).format('MMM').toLowerCase()))) {
            return true;
        }
        return null
    }

    const handleForeCastModalClose = (save, data, lastYearData) => {
        if (save) {
            let m = '' + getMonth(props.account?.CurrentFF, 'monthNumber')
            let currentFF = m?.length === 1 ? '0' + m : m;
            let fiscalYear = Number(props.account?.FinancialYear);
            let payload = {
                fiscalYear: Number(props.account?.FinancialYear) + "",
                currentFF: m?.length === 1 ? '0' + m : m,
                month: foreCastModel.month,
                forecast: data.foreCast,
                account: props.account?.AccountName,
                data: {
                    actual: {
                        months: data.actualMonths,
                        currentFF: currentFF,
                        fiscalYear: fiscalYear + "",
                    },
                    forecast: {
                        months: data.foreCastMonths,
                        currentFF: "06", // this current ff will be same always
                        fiscalYear: (fiscalYear - 1) + "",
                    }
                },
                lastYear: {
                    actual: {
                        months: lastYearData.actualMonths,
                        currentFF: "06", // this current ff will be same always
                        fiscalYear: (fiscalYear - 1) + "",
                    },
                    forecast: {
                        months: lastYearData.foreCastMonths,
                        currentFF: "06", // this current ff will be same always
                        fiscalYear: (fiscalYear - 2) + "",
                    }
                }
            }
            let url = URLs.saveNonPromoForeCast + "?";
            if (brand) {
                url += '&brand=' + encodeURIComponent(brand)
            }
            if (brandform) {
                url += '&brandform=' + encodeURIComponent(brandform)
            }
            if (sku) {
                url += '&sku=' + encodeURIComponent(sku)
            }
            let formData = {
                url: url,
                appId: appid,
                dataUniqueId: 'PUT_FORECAST_' + props.type,
                payload: payload
            }
            props.updateMRData(formData)
            setForeCastModel({ isOpen: false, heading: null, month: null, monthLabel: null })
        } else {
            setForeCastModel({ isOpen: false, heading: null, month: null, monthLabel: null })
        }

    }

    const onSelectAll = (event, val) => {
        if (val) {
            let newCheckedItems = fieldData.map(i => `${i._id}`)
            setCheckedRows(newCheckedItems);
        } else {
            setCheckedRows([]);
        }
    }

    const getTotal = (key) => {
        let data = props.data ? (props.data['GET_' + props.type]?.result?.sum || props.data['GET_' + props.type]?.totalSum) : null
        if (data) {
            if (typeof (key) === 'string') {
                let keys = key.split('.')
                if (keys?.length === 2) {
                    return data[keys[0]] ? (data[keys[0]][keys[1]] || 0) : ''
                }
                return data[key] || 0
            } else {
                key = key.key
                let keys = key.split('.')
                if (keys?.length === 2) {
                    return data[keys[0]] ? (data[keys[0]][keys[1]] || 0) : ''
                }
                return data[key] || 0
            }
        }
        return ''
    }

    let headerOrder = 0;

    const isFreezeColumn = (index) => {
        return index < planningData[props.type].freezeColumnUpto
    }

    const isEditableFieldNonPromo = (monthKey) => {
        // monthKey: jan, feb ,mar
        if (props.account && shortMonthLowercase.indexOf(monthKey) + 1 > shortMonthLowercase.indexOf((moment().month(props.account.CurrentFF).format('MMM').toLowerCase()))) {
            return 'input'
        }
        return null
    }

    const getEarningsValue = (row, keys) => {
        if (keys[0] === 'factor') {
            return row[keys[0]][keys[1]]
        }
        if (row[keys[0]]) {
            if (!(keys[0] === 'tfiEarningsPerc')) {
                return Math.round(row[keys[0]][keys[1]])
            }
            return row[keys[0]][keys[1]]
        }
        return null
    }

    const renderSubCell = (row, c, i, rowIndex) => {
        if (typeof i === 'string') {
            let keys = i.split('.');
            if (props.type === 'nonPromo') {
                return renderCellElement(row, row[keys[0]] ? !(keys[0] === 'totalNonPromoRetailing' && isEditableFieldNonPromo(keys[1])) ?
                    Math.round(row[keys[0]][keys[1]]) : row[keys[0]][keys[1]] : null, rowIndex,
                    (keys[0] === 'totalNonPromoRetailing' && isEditableFieldNonPromo(keys[1])) ? 'input' : null, null, keys[0], keys[1], c.field)
            } else if (props.type === 'earningsTFI') {

                return renderCellElement(row, getEarningsValue(row, keys), rowIndex, (keys[0] === 'tfiEarningsPerc') ? 'input' : null, null, keys[0], keys[1], c.field)
            }
        } else if (props.type === 'earningsTFI' && i.formula) {
            return renderCellElement(row, null, rowIndex, 'formula', i.formula)
        }
    }

    const onForecastClick = (month) => {
        setForeCastModel({
            isOpen: true,
            heading: 'Value Forecast for ' + capitalize(month) + " " + getFiscalYearFormat(props.account?.FinancialYear, true).substring(2, 4),
            month: month,
            monthLabel: capitalize(month) + " " + getFiscalYearFormat(props.account?.FinancialYear, true).substring(2, 4),
        })
    }

    const renderCellHeader = (c, i, cIndex) => {
        if (typeof i === 'string') {
            let keys = i.split('.');
            const isNonPromoEditable = () => (keys[0] === 'totalNonPromoRetailing' && isEditableFieldNonPromo(keys[1])) // show value forecast icon on value field
            if (props.type === 'nonPromo') {
                return <>
                    <p className={isNonPromoEditable() ? classes.greenFont : ''} style={props.type === 'nonPromo' ? { height: 35, marginBottom: -5 } : {}}>
                        <span>{headingsLabel[keys[0]] || i.key}</span>
                        {
                            isNonPromoEditable() && <Tooltip title='Value Forecast'>
                                <TrendingUpIcon style={{ cursor: 'pointer', fontSize: 14 }} onClick={() => onForecastClick(c.key)} />
                            </Tooltip>
                        }
                    </p>
                </>
            } else if (props.type === 'earningsTFI') {
                return <> <p className={(keys[0] === 'tfiEarningsPerc') ? classes.greenFont : ''}><span>{headingsLabel[keys[0]] || i.key}</span></p>
                </>
            }
        } else if (props.type === 'earningsTFI' && i.key && c.subKeys) {
            let keys = i.key.split('.');
            return <> <p><span>{headingsLabel[keys[0]]}</span></p>
            </>
        }

    }

    const selectOptions = (type, rowId) => {
        let filterOptions = getFilterOptions();
        if (filterOptions && rowDropdownData && rowDropdownData[rowId]) {
            return React.Children.toArray(
                rowDropdownData[rowId]['_' + type]?.map((item, index) =>
                    <MenuItem value={item} key={index}>{item}</MenuItem>,
                ));
        }
    };

    const renderCellElement = (row, renderVal, index, inputType, formula, column, subKey, fieldType, defaultValue, keyField) => {
        let classToAdd = '';
        if (subKey === 'retailing') {
            if (row.retailColor && row.retailColor[column]) {
                if (row.retailColor[column] === 'Consumption') {
                    classToAdd = classes.redClass
                } else if (row.retailColor[column] === 'Core') {
                    classToAdd = classes.blueClass
                } else if (row.retailColor[column] === 'Growth') {
                    classToAdd = classes.greenClass1
                } else if (row.retailColor[column] === 'Small') {
                    classToAdd = classes.orangeClass
                }
            }
        }
        // show green color only for promo calendar
        let addgreen = false;
        if (props.type === 'omniPromoCalender') {
            if (column === 'retailQtyForecast' && row.isEditRTQ) {
                addgreen = true;
            } else if (column === 'offtakeForecastPieces' && row.isEditOFP) {
                addgreen = true;
            } else if (column === 'offtakeActualPieces' && row.isEditOAP) {
                addgreen = true;
            }
        }
        if (column === 'kbd') {
            renderVal = renderVal?.toUpperCase();
        }
        if (props.type === 'spendsPlanning' && (row['uom'] !== '$M' && row['uom'] !== 'INR') && column === 'total') {
            return ''
        }
        if (props.type === 'spendsPlanning' && column === 'brandform' && row['type'] !== 'KBD 2 Spend - Discretionary Visibility') {
            return row['brandform']
        }
        if (((row._sku && column === 'sku') || (column === 'brandform' && row._brandform)) || row.editable) { //edit click functionality
            if (inputType === 'select') {
                return (
                    <Select
                        name={column}
                        value={renderVal}
                        displayEmpty={true}
                        className="filter-container"
                        style={{ minWidth: '100px' }}
                        onChange={(e) => {
                            const { name, value } = e.target;
                            handleFieldChange({ name, value }, index, column, subKey, row._id, 'change');
                        }}
                    >
                        {defaultValue && <MenuItem value="">{defaultValue}</MenuItem>}
                        {selectOptions(column, row._id)}
                    </Select>
                );
            }

            if (inputType === 'newInput') {
                return (
                    <input
                        step={fieldType === 'number' ? "any" : undefined}
                        type={fieldType || 'text'}
                        className={classes.tableInput}
                        onChange={(e) => {
                            const { name, value } = e.currentTarget;
                            handleFieldChange({ name, value }, index, column, subKey, row._id, 'change');

                        }}
                        onBlur={(e) => {
                            const { name, value } = e.currentTarget;
                            handleFieldChange({ name, value: (fieldType === 'number' && value === "") ? 0 : value }, index, column, subKey, row._id, 'blur')
                        }}
                        value={renderVal}
                    />)
            }
        }
        if (inputType === 'input') {
            const id = props.type + (subKey ? (column + subKey) : column) + index;
            let newVal;
            // = row.uom === '%' ? (renderVal*100) : renderVal;
            if (!newVal) newVal = renderVal;
            return (
                <input
                    step={(fieldType === 'number' || fieldType === 'percent') ? "any" : undefined}
                    id={id}
                    disabled={row.account === FIRST_CRY && column === 'depthMrp' ? true : row.account !== FIRST_CRY && column === 'depthRlp' ? true : false}
                    type={(fieldType === 'percent' ? 'number' : (fieldType || 'text'))}
                    className={classes.tableInput + ' ' + (addgreen ? classes.greenClass : '')}
                    onChange={(e) => {
                        const { name, value } = e.currentTarget;
                        handleFieldChange({ name, value }, index, column, subKey, row._id, 'change');
                    }}
                    onBlur={(e) => {
                        const { name, value } = e.currentTarget;
                        handleFieldChange({ name, value: ((fieldType === 'number' || fieldType === 'percent') && value === "" && props.type !== 'omniPromoCalender') ? 0 : value }, index, column, subKey, row._id, 'blur')
                    }}
                    value={(fieldType !== 'number') ? convertToNumber(newVal, 2) : (document.getElementById(id) ===
                        document.activeElement ? newVal : (keyField === 'percent') ? (convertToNumber(newVal * 100, 2)) : convertToNumber(newVal, 2))}
                />
            )
        } else if (inputType === 'formula') {
            let val = mathjs.evaluate(formulaCalculation(row, formula));
            return <span style={{ alignSelf: 'center', margin: '0 10px' }} title={val}>
                {convertToNumber(val, 2).toLocaleString()}
            </span>
        } else if (inputType === 'customFormula') {
            let isFormula = isMonthForecast(row['month']); // promo retailing value doing rlp*forecast only when month is forecast otherwise showing api value
            if (isFormula) {
                let val = mathjs.evaluate(formulaCalculation(row, formula));
                return <span style={{ alignSelf: 'center', margin: '0 10px' }} title={val}>
                    {convertToNumber(val, 2).toLocaleString(column === 'retailingValue' ? 'en-In' : 'en-US')}
                </span>
            }
        } else if (inputType === 'percent') {
            if (props.type === 'omniTiff' && row && row[subKey]) {
                renderVal = row[subKey][column];
            }
            return <span title={fieldType === 'number' ? renderVal : ''} className={classes.tableCell}>
                {convertToNumber(renderVal * 100, 2).toLocaleString() + '%'}</span>
        } else if (inputType === 'date') {
            let minDate = (column === 'endDate' && row['startDate']) ? moment(row['startDate'], 'DD-MM-YYYY').format('MM-DD-YYYY') : 'Invalid date'
            let maxDate = (column === 'startDate' && row['endDate']) ? moment(row['endDate'], 'DD-MM-YYYY').format('MM-DD-YYYY') : 'Invalid date'
            return (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        autoOk={true}
                        format="DD-MM-YYYY"
                        inputValue={renderVal}
                        minDate={minDate !== 'Invalid date' ? minDate : undefined}
                        maxDate={maxDate !== 'Invalid date' ? maxDate : undefined}
                        onChange={(momentObj, value) => handleFieldChange({ name: column, value }, index, column, subKey)}
                        rifmFormatter={(str) => str}
                        value={moment(renderVal)}
                        style={{ minWidth: 140 }}
                        error={false}
                        helperText={null}
                    />
                </MuiPickersUtilsProvider>)
        } else if (props.type === 'omniTiff' && inputType === 'number' && row[subKey] && row[subKey][column]) {
            return <span title={fieldType === 'number' ? row[subKey][column] : ''} className={classes.tableCell + ' ' + classToAdd}>
                {convertToNumber(row[subKey][column], 2).toLocaleString()}</span>
        }
        return <span title={fieldType === 'number' ? renderVal : ''} className={classes.tableCell + ' ' + classToAdd}>
            {fieldType === 'number' ? convertToNumber(renderVal, 2).toLocaleString((column === 'rlp' || column === 'totalNonPromoRetailing' || column === 'retailingValue') ?
                'en-In' : 'en-In') : renderVal}</span>
    }

    const onConfirmResponse = (resp) => {
        if (resp) {
            finish();
        }
        setConfirm({ isOpen: false, confirmMessage: '' });
    };

    const finish = () => {
        let { AccountName, FinancialYear, CurrentFF } = props.account;
        let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
        m = m?.length === 1 ? '0' + m : m;
        let data = {
            'formType': props.type,
            account: AccountName, fiscalYear: FinancialYear, currentFF: m,
            'action': 'finish',
        };
        let url = `${API_BASE_URL}` + URLs.doneUpdates;
        axios({
            url: url,
            method: 'POST',
            data: data,
            headers: {
                appId: props.match.params.appid,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                toast.success(response.data?.result?.message || 'Workflow emails sent successfully');
            })
            .catch((error) => {
                toast.error(error?.data?.message || 'Not able to send workflow emails');
            });
    };

    const getFilterOptions = () => {
        if (props.data && props.data['GET_FILTER_' + props.type]) {
            let newData = props.data['GET_FILTER_' + props.type];
            let keys = Object.keys(newData);
            let newObj = {};
            if (keys && keys.length) {
                for (let i = 0; i < keys.length; i++) {
                    if (newData[keys[i]] && newData[keys[i]].length) {
                        let newArr = [];
                        for (let j = 0; j < newData[keys[i]].length; j++) {
                            let val = newData[keys[i]][j];
                            if (val !== null && val !== undefined && val !== '') {
                                newArr.push(val);
                            }
                        }
                        newObj[keys[i]] = newArr;
                    }
                }
            }
            return newObj;
        } else {
            return [];
        }
    }

    const linkChange = () => {
        setCategory('');
        setBrand('');
        setBrandform('');
        setSku('')
        setType('')
        setEarningOn('')
        setEarningType('')
        setKBD('')
        setTSBrand('')
        setTSSKU('')
        setMonth('')
    }

    const checkAccountType = (key) => {
        if (key === 'skuType') {
            if (props.type === 'omniPromoCalender' && (props.account?.Format === 'eB2B' || props.account?.Format === 'Cash & carry')) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const checkOtherKeys = (obj, tableKey) => {
        let keys = Object.keys(obj);
        let flag = false;
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === tableKey) {
                flag = true;
                break;
            }
        }
        return flag;
    }

    const fetchClassesForTFI = () => {
        if (props.type === 'omniTiff') {
            return ['tfiPer', 'factor', 'retailing', 'tfiInr'];
        } else {
            return [''];
        }
    }

    const sumByKey = (arr, key) => arr?.reduce((acc, obj) => acc + obj[key], 0);

    useEffect(() => {
        if (omniTotalValues?.length > 0) {
            const obj = {
                retailing: 0,
                offtake: 0,
                sDSpend: 0,
                KDB1: 0
            }
            obj['retailingValue'] = sumByKey(omniTotalValues, 'retailingValue');
            obj['offtakeActualINR'] = sumByKey(omniTotalValues, 'offtakeActualINR');
            obj['sdSpendFinal'] = sumByKey(omniTotalValues, 'sdSpendFinal');
            obj['sdSpend'] = sumByKey(omniTotalValues, 'sdSpend');
            obj['KDB1'] = (obj['sdSpendFinal'] / obj['retailingValue']);
            obj['promoValue'] = sumByKey(omniTotalValues, 'promoValue');
            obj['sdSpendPromoDiff'] = obj['sdSpend'] - obj['promoValue'];
            obj['retailQtyForecast'] = sumByKey(omniTotalValues, 'retailQtyForecast');
            obj['offtakeForecastPieces'] = sumByKey(omniTotalValues, 'offtakeForecastPieces');
            obj['offtakeActualPieces'] = sumByKey(omniTotalValues, 'offtakeActualPieces');
            obj['claimAmount'] = sumByKey(omniTotalValues, 'claimAmount');
            obj['minQty'] = sumByKey(omniTotalValues, 'minQty');
            obj['asp'] = sumByKey(omniTotalValues, 'asp');
            // obj['minPromo%'] = ' ';
            setOmniMetricValues({ ...obj });
        } else {
            setOmniMetricValues({});
        }
    }, [omniTotalValues])

    const showAggregateTotalColumns = [
        'retailingValue',
        'offtakeActualINR',
        'sdSpendFinal',
        'sdSpend',
        'promoValue',
        'retailQtyForecast',
        'offtakeActualPieces',
        'offtakeForecastPieces',
        'claimAmount',
        'asp',
        'minQty',
        // 'minPromo%'
    ];

    return (
        <div style={{ marginTop: props.modalData ? 30 : 0 }}>
            <MRPlanHeader
                {...props}
                onAction={onAction}
                onFilterChange={onFilterChange}
                showClearFilter={showClearFilter}
                filters={getFilters(false)}
                filterValues={{ brand, brandform, tsSku, month, metric, tsBrand, earningOn, earningType, type, kbd, category }}
                saveButtonState={saveButtonState}
                filterData={getFilterOptions()}
                showEditBtn={rowSelected && !(fieldData[rowSelected] && fieldData[rowSelected]['_id'].startsWith('newplan'))}
                rowSelected={rowSelected}
                saveBtnDisabled={saveBtnDisabled}
                linkChange={linkChange}
                handleXlDownload={handleXlDownload}
                xlList={xlList}
                omniMetricValues={omniMetricValues}
            />
            {
                props.mrData && props.mrData['GET_' + props.type + '_loading'] ?
                    <section className={classes.tableBodyLoading}>
                        <Loader />
                    </section> :

                    <React.Fragment>
                        {
                            props.type === 'omniTiff' && <div style={{ display: "flex", marginBottom: "6px" }}>
                                <Typography fontSize="16px"> <span style={{ fontWeight: 500 }}>Last updated at : </span>{lastUpdatedData.updatedAt ? moment(lastUpdatedData.updatedAt).format('DD-MM-YYYY hh:mm:ss A z') : ""}</Typography>
                                <Typography fontSize="16px" style={{ marginLeft: "20px" }}> <span style={{ fontWeight: 500 }}>Updated By : </span>{lastUpdatedData.updatedBy || ""}</Typography>
                            </div>
                        }
                        {(props.type === 'omniPromoCalender' || props.type === 'omniSpendsPlanning' || props.type === 'omniTiff' || props.type === 'mediaCalendar') && <TableContainer className={classes.tableContainer} component={Paper}>
                            <Table className={classNames(classes.table, 'tpr-table', 'custom-omni-table')} aria-label="simple table" stickyHeader>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell data-order={headerOrder++} className={"sticky_column"}>
                                        <>
                                            <div className="dummyState"></div>
                                            <Checkbox
                                                disabled={fieldData?.length === 0}
                                                onChange={(e, v) => onSelectAll(e, v)}
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                checked={fieldData?.length !== 0 ? checkedRows?.length === fieldData?.length : false}
                                            />
                                            </>
                                        </TableCell>
                                        {
                                            planningData[props.type].columnKeys.map((c, index) => {

                                                if (!c.subKeys) {
                                                    return (
                                                        checkAccountType(c.key) && <TableCell data-order={headerOrder++} style={{ paddingTop: 5, paddingBottom: 5 }}
                                                        className={classNames(c.type === 'input' ? classes.greenFont : '', isFreezeColumn(index) ? "sticky_column" : "")}>
                                                        <div className="custom-th-body">
                                                        {showAggregateTotalColumns.indexOf(c.key) !== -1 && Boolean(fieldData?.length) ? (<p className={`${classes.totalValue}`}></p>
                                                        // <p className={`${classes.totalValue} customLableInfo`} style={{ margin: '0rem 0px 15px 0px',  }} title={omniMetricValues?.[c.key]}>{convertToNumber(omniMetricValues?.[c.key], )}</p>
                                                    ) : (props.type === 'omniPromoCalender' || props.type === 'omniSpendsPlanning' || props.type === 'omniTiff' || props.type === 'mediaCalendar') ? <div className="dummyState"></div> : null}
                                                            {
                                                                (totalSum && checkOtherKeys(totalSum, c.key)) ? <div style={{ display: 'flex', flexDirection: 'column', height: 60, justifyContent: 'flex-start' }}>
                                                                    <span className={classes.totalValue} title={totalSum[c.key]}>{convertToNumber(totalSum[c.key], 2).toLocaleString(c.key === 'retailingValue' ? 'en-In' : 'en-US')}</span>
                                                                    <span title={c.type === 'input' ? 'Editable' : 'Non Editable'}>{headingsLabel[c.key] || c.key} </span>
                                                                </div> : <span title={c.type === 'input' ? 'Editable' : 'Non Editable'}>{headingsLabel[c.key] || c.key} </span>
                                                                // <span>{headingsLabel[c.key] || c.key} </span>
                                                            }
                                                         </div>
                                                        </TableCell>
                                                    )
                                                } else {
                                                    // const isKeyExists = c.subKeys.some(obj => {
                                                    //     return showAggregateTotalColumns.indexOf(obj.key) !== -1;
                                                    // });
                                                    return (<TableCell data-order={headerOrder++}
                                                        className={classNames(isFreezeColumn(index) ? "sticky_column" : "")}
                                                        colSpan={c.subKeys?.length}>
                                                        {/* {isKeyExists ? ( */}
                                                        <section className={`${classes.combinedHeaderSection} ${props.type === 'omniPromoCalender' ? 'customLablecombinedHeaderSection2' : ''}`}
>
                                                            {c.subKeys.map((i, cIndex) => {
                                                                return (
                                                                    <React.Fragment key={cIndex}>
                                                                        {/* <p><span>
                                                                            {showAggregateTotalColumns.indexOf(i.key) !== -1 && Boolean(fieldData?.length) ? convertToNumber(omniMetricValues?.[i.key], 2) : (props.type === 'omniPromoCalender' ) ? '' : ''}
                                                                        </span></p> */}
                                                                        {/* {cIndex + 1 !== c.subKeys?.length && <div className={classes.divider} />} */}
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </section>
                                                        {/* ) : null} */}
                                                        <p className={classes.combinedHeaderHeading}>{headingsLabel[c.key] || c.key}</p>
                                                        <section className={classes.combinedHeaderSection}>
                                                            {
                                                                c.subKeys.map((i, cIndex) => {
                                                                    return <><p className={!(['total', 'claimAmount', 'asp', 'minQty', 'minPromo%', 'promoValue', ...fetchClassesForTFI()].includes(i.key)) ? classes.greenFont : ''}><span title={!(['total', 'claimAmount', 'asp', 'minQty', 'minPromo%', 'promoValue', ...fetchClassesForTFI()].includes(i.key)) ? 'Editable' : 'Non Editable'}>{headingsLabel[i.key] || i.key}</span></p>
                                                                        {cIndex + 1 !== c.subKeys?.length && <div className={classes.divider} />}
                                                                    </>
                                                                })
                                                            }

                                                        </section>
                                                    </TableCell>)
                                                }
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {props.type === "omniPromoCalender" || props.type === "omniSpendsPlanning" || props.type === 'omniTiff' || props.type === 'mediaCalendar' ?
                                        fieldData?.map((row, rowIndex) => {
                                            let order = 0;
                                            return (
                                                <StyledTableRow key={rowIndex} onClick={() => selectRow(rowIndex)}
                                                    className={rowSelected === rowIndex ? classes.rowSelectedBg : ''}
                                                >
                                                    <TableCell id={`sticky_body_column_${rowIndex}_${order}`} className={`sticky_body_column`}
                                                        data-order={order++} data-index={rowIndex}>
                                                        <Checkbox
                                                            onChange={() => onCheckHandler(row._id, rowIndex)}
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            checked={checkedRows.includes(row._id)}
                                                        />
                                                    </TableCell>
                                                    {
                                                        planningData[props.type].columnKeys.map((c, colIndex) => {
                                                            if (!c.subKeys) {
                                                                return (checkAccountType(c.key) && <TableCell style={{ minWidth: 75, textTransform: c.key === 'month' ? 'capitalize' : '', }}
                                                                    className={isFreezeColumn(colIndex) ? `sticky_body_column ${rowSelected === rowIndex ? classes.rowSelectedBg : ''}` : ""}
                                                                    id={`sticky_body_column_${rowIndex}_${order}`} data-order={order++} data-index={rowIndex}>
                                                                    {renderCellElement(row, row[c.key], rowIndex, c.type, c.formula, c.key, null, c.field, c.defaultValue, c.fieldType)}
                                                                </TableCell>)
                                                            } else {
                                                                return (<TableCell style={{ minWidth: 75 }}
                                                                    className={classNames(isFreezeColumn(colIndex) ? `sticky_body_column  ${rowSelected === rowIndex ? classes.rowSelectedBg : ''}` : "")}
                                                                    id={`sticky_body_column_${rowIndex}_${order}`} data-order={order++} data-index={rowIndex} colSpan={c.subKeys?.length}>
                                                                    <section className={classes.combinedHeaderSection}>
                                                                        {
                                                                            c.subKeys?.map(i => {
                                                                                return <div style={{ width: `${(100 / (c.subKeys?.length))}%`, display: 'flex', justifyContent: 'center' }}>
                                                                                    {renderCellElement(row, (props.type === 'omniPromoCalender' ? row[i.key] : (row[c.key] ? row[c.key][i.key] : null)), rowIndex, i.type, i.formula, c.key, i.key, i.type)}
                                                                                </div>
                                                                            })
                                                                        }

                                                                    </section>
                                                                </TableCell>)
                                                            }
                                                        })
                                                    }
                                                </StyledTableRow>
                                            );
                                        }) : null}
                                    {!Boolean(fieldData?.length) &&
                                        renderNoDataTextMR(brand, brandform, sku, month)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        }

                        {(props.type === 'nonPromo' || props.type === 'earningsTFI') && <TableContainer className={classes.tableContainer} component={Paper}>
                            <Table className={classNames(classes.table, 'tpr-table')} aria-label="simple table" stickyHeader>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell data-order={headerOrder++} className={"sticky_column"} rowSpan={3}>
                                            <Checkbox
                                                disabled={fieldData?.length === 0}
                                                onChange={(e, v) => onSelectAll(e, v)}
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                checked={fieldData?.length !== 0 ? checkedRows?.length === fieldData?.length : false}
                                            />
                                        </TableCell>
                                        {
                                            planningData[props.type].columnKeys.map((c, index) => {
                                                if (!c.subKeys) {
                                                    return <TableCell rowSpan={3} data-order={headerOrder++} className={classNames(isFreezeColumn(index) ? "sticky_column" : "", c.type === 'date' ? classes.greenFont : '')}>
                                                        <span>{headingsLabel[c.key] || c.key}</span></TableCell>
                                                } else {
                                                    return (
                                                        <>
                                                            {c.subKeys.map((subKey, _ind) => {
                                                                return (
                                                                    <TableCell style={{ minWidth: 75, border: 'none', boxShadow: 'none' }}>
                                                                        <span key={_ind} className={classes.totalValue} title={getTotal(subKey)}>
                                                                            {getTotal(subKey) !== '' ? convertToNumber(getTotal(subKey), 2).toLocaleString(_ind !== 0 ? 'en-In' : 'en-US') : ''}
                                                                        </span>
                                                                    </TableCell>)
                                                            })}
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </TableRow>
                                    <TableRow>
                                        {
                                            planningData[props.type].columnKeys.map((c, index) => {
                                                if (!c.subKeys) {
                                                    return null
                                                } else {
                                                    return (
                                                        <TableCell colSpan={c.subKeys?.length} className="sticky_column">
                                                            {
                                                                props.type === 'nonPromo' ? <p className={classes.combinedHeaderHeading}>
                                                                    {index > 2 ? getNonPromoHeading(c.key, (c.lastYear ? getFiscalYearFormat(props.account?.FinancialYear, true) :
                                                                        getFiscalYearFormat(props.account?.FinancialYear))) : headingsLabel[c.key] || c.key}</p> :
                                                                    <p className={classes.combinedHeaderHeading}> {headingsLabel[c.key] || c.key}</p>
                                                            }

                                                        </TableCell>
                                                    )
                                                }
                                            })
                                        }
                                    </TableRow>
                                    <TableRow>
                                        {
                                            planningData[props.type].columnKeys.map((c, index) => {
                                                if (c.subKeys?.length) {
                                                    return c.subKeys.map((i, cIndex) => {
                                                        return <TableCell data-order={headerOrder++} style={{ minWidth: 75, }}
                                                            className={classNames(isFreezeColumn(index) ? "sticky_column" : "")}>
                                                            {
                                                                renderCellHeader(c, i, cIndex)
                                                            }

                                                        </TableCell>
                                                    })
                                                }
                                            })
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {fieldData?.map((row, rowIndex) => {
                                        let order = 0;
                                        return (
                                            <StyledTableRow key={rowIndex} onClick={() => selectRow(rowIndex)}
                                                className={rowSelected === rowIndex ? classes.rowSelectedBg : ''}>
                                                <TableCell id={`sticky_body_column_${rowIndex}_${order}`} className={`sticky_body_column`}
                                                    data-order={order++} data-index={rowIndex}>
                                                    <Checkbox
                                                        onChange={() => onCheckHandler(row._id, rowIndex)}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        checked={checkedRows.includes(row._id)}
                                                    />
                                                </TableCell>

                                                {
                                                    planningData[props.type].columnKeys.map((c, colIndex) => {
                                                        if (!c.subKeys) {
                                                            return <TableCell style={{ minWidth: 75 }}
                                                                className={isFreezeColumn(colIndex) ? `sticky_body_column  ${rowSelected === rowIndex ? classes.rowSelectedBg : ''}` : ""}
                                                                id={`sticky_body_column_${rowIndex}_${order}`} data-order={order++} data-index={rowIndex}>
                                                                {renderCellElement(row, row[c.key], rowIndex, c.type, null, c.key, null, c.field, c.defaultValue)}
                                                            </TableCell>
                                                        } else {
                                                            return (
                                                                <>
                                                                    {
                                                                        c.subKeys?.map(i => {

                                                                            return <TableCell className={classNames(isFreezeColumn(colIndex) ? `sticky_body_column` : "")}
                                                                                style={{ minWidth: 75 }}
                                                                                id={`sticky_body_column_${rowIndex}_${order}`} data-order={order++} data-index={rowIndex} >
                                                                                {
                                                                                    renderSubCell(row, c, i, rowIndex)
                                                                                }
                                                                            </TableCell>

                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    })
                                                }
                                            </StyledTableRow>
                                        );
                                    })
                                    }
                                    {!Boolean(fieldData?.length) &&
                                        renderNoDataTextMR(brand, brandform, sku, month)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        }
                        <TablePagination
                            rowsPerPageOptions={[20, 50, 100, 200]}
                            component="div"
                            count={pageCount}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </React.Fragment>
            }
            <MRPlanUploadConfirmDialog open={open} handleClose={handleClose} alertMessageText={alertMessageText} title={props.page.title} />
            {foreCastModel?.isOpen && <ForeCastModal open={true} heading={foreCastModel.heading} monthLabel={foreCastModel.monthLabel}
                handleClose={handleForeCastModalClose} />}
            <ConfirmDialog open={deleteConfirmState.isOpen} handleClose={onDeleteResponse} alertMessageText={deleteConfirmState.deleteMessage}
                classes={classes} confirm />
            <ConfirmDialog open={confirmState.isOpen} handleClose={onConfirmResponse}
                alertMessageText={confirmState.confirmMessage} classes={classes} confirm />
        </div>
    );
};

const mapDispatchToProps = {
    getMrData,
    deleteMRData,
    removeMRSuccessMessage,
    saveMrData,
    updateMRData,
    getMrDataViaPost,
    getUploadXlList
};

const mapStateToProps = state => {
    return {
        account: state.mrReducer?.account,
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(OmniPlanningPages)));