import React from 'react';
import { Grid, Paper, makeStyles, Divider } from '@material-ui/core';
import classNames from 'classnames'

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const useStyles = makeStyles(theme => ({
	paperTable: {
		textAlign: 'left',
		padding: '5px 15px',
		// backgroundColor: 'transparent',
		borderRadius: 0,
		margin: '0 10px 0 10px',
		height: '100%'
	},
	anchor: {
		fontSize: '14px !important',
		textDecoration: 'underline !important',
	},
	panel: {
		marginBottom: '20px',
		backgroundColor: '#222222',
		border: '1px solid transparent',
		// borderRadius: '4px',
		boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)',
	},
	panelDefault: {
		borderColor: '#282828',
	},
	panelHeading: {
		color: '#888888',
		// backgroundColor: '#3c3c3c',
		borderColor: '#282828',
		textAlign: 'left',
		padding: '13px',
		borderBottom: '4px solid #000',
		marginBottom: '13px',
		'@media(max-width:1366px)': {
			padding: 5,
			borderBottom: '1px solid #000',
			marginBottom: 5,
		},
	},
	headingStyle: {
		fontSize: '25px',
		'@media(max-width:1366px)': {
			fontSize: 20,
		},
		fontWeight: 'bold',
		color: '#000',
		// display: 'flex',
		alignItems: 'center',
		textAlign: 'center'
	},
	svgIcon: {
		marginRight: 10,
		fontSize: 21,
		'@media(max-width:1366px)': {
			fontSize: 18,
		},
	},
	checkIcon: {
		color: '#fff',
		background: '#008000',
		borderRadius: '19px',
		display: 'flex'
	},
	innerHeading: {
		textAlign: 'center'
	},
	innerBody: {
		paddingTop: '35px'
	},
	innerValue: {
		paddingTop: '35px',
		fontSize: '45px',
	},
	innerDivider: {
		paddingTop: '15px',
	},
	dividerColor:{
		backgroundColor: '#4472c4',
		color: '#4472c4'
	}
}));

function BudgetWidget(props) {
	const { myTasks } = props;
	const classes = useStyles();
	return (<>
		<Paper className={classes.paperTable}>
			<div className={(classes.panel, classes.paneldefault)}>
				<div className={classes.panelHeading}>
					<div className={classes.headingStyle}>
						{/* <DescriptionIcon className={classes.svgIcon} /> */}
						{"Ariel Current FF ( Dec 21 )"}
					</div>
				</div>
				<Grid container >
					<Grid item xs={5} className={classes.innerBody}>
						<div>
							<div className={classNames(classes.innerHeading, classes.headingStyle)}>
								{"Total no: of plans"}
							</div>
							<div className={classNames(classes.innerValue, classes.headingStyle)}>
								{"332"}
							</div>
						</div>
					</Grid>
					<Grid item xs={1}className={classes.innerDivider} ><Divider  style={{
						width: '20%',
						height: '100%',
						textAlign: 'right',
						marginLeft: "50%"
					}} classes={{root: classes.dividerColor}} orientation="vertical" /></Grid>

					<Grid item xs={6} className={classes.innerBody}>
						<div>
							<div className={classNames(classes.innerHeading, classes.headingStyle)}>
								{"Total Budget Planned"}
							</div>
							<div className={classNames(classes.innerValue, classes.headingStyle)}>
								{"$ 2.9 M"}
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</Paper>
	</>);
}

export default BudgetWidget;
