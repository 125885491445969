import {
    Button, Dialog, DialogActions,
    DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, Slide, Typography
} from '@material-ui/core'
import React, { useState } from 'react'
import { hot } from 'react-hot-loader/root'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    confirmationDialog: {
        '& .MuiDialog-paperWidthSm': {
            height: '300px',
            width: '600px'
        }
    },
    radio: {
        '& .MuiSvgIcon-root': {
            height: '24px !important',
            weight: '22px !important',
            color: 'grey',
        },
    },
    dialogContent: {
        display: 'flex',
        alignItems: 'center',
    }
})

const FreezePlanModal = (props) => {
    let { open, handleClose, brands } = props;
    const classes = useStyles();
    const [isCopyData, setCopyData] = useState('true');

    return (<>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.confirmationDialog}
            onKeyDown={(e) => (e.key == 'Enter' && !confirm) && handleClose()} tabIndex={0}
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#047dd8" }}>
                {"Are you sure you want to freeze the monthly plan for the selected " + (props?.isMR? 'accounts?':'brands?')}
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <FormControl component="fieldset">
                    <Typography variant="h6" gutterBottom component="div">
                        {'Selected ' + (props?.isMR? 'accounts' : 'brands')}: {brands}</Typography>
                    <RadioGroup
                        aria-label="gender"
                        name="controlled-radio-buttons-group"
                        value={isCopyData}
                        onChange={(e) => setCopyData(e.target.value)}
                    >
                        <FormControlLabel value={'true'} control={<Radio className={classes.radio} />} label={'Freeze Monthly Plan - Copy Data to Next Month'} />
                        {props.page !== 'OmniChannels' && <FormControlLabel value={'false'} control={<Radio className={classes.radio} />} label={'Freeze Monthly Plan - Data Not Copied to Next Month'} />}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <React.Fragment>
                    <Button
                        color="primary" onClick={() => {
                            handleClose(true, isCopyData)
                        }}
                        className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {"Yes"}
                    </Button>
                    <Button
                        color="primary" onClick={() => handleClose(false)}
                        className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {"No"}
                    </Button>
                </React.Fragment>
            </DialogActions>
        </Dialog>

    </>)
}

export default hot(FreezePlanModal);