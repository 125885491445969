

import { hot } from 'react-hot-loader/root'
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
	Button, Paper, Grid, Slide, Dialog, DialogActions, makeStyles,
	DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		textAlign: 'center',
	},
}));

const titles = {
	brandIssues: 'Brands',
	skuIssues: 'SKUs',
	accountIssues: 'Accounts',
  }
  
const HandleErrors = (props) => {
	let { errorArr, type } = props
	const classes = useStyles();

	let message = `${titles[type] || type} not present in master database:`;
	let obj = {}
	if (type === "Channels") {
		errorArr.map(e => {
			if (obj[e.channel]) {
				obj[e.channel].push(e.row)
			} else {
				obj[e.channel] = [e.row];
			}
		})
	} else if (type === "Brandforms") {
		errorArr.map(e => {
			if (obj[e.brandform]) {
				obj[e.brandform].push(e.row)
			} else {
				obj[e.brandform] = [e.row];
			}
		})
	} else if (type === "data") {
		errorArr.map(e => {
			if (obj['Data Issues']) {
				obj['Data Issues'].push(e.row);
			} else {
				obj['Data Issues'] = [e.row];
			}
		})
		message = `Data issues found in following rows: ${obj['Data Issues'].join(', ')}`
	} else if (type === 'brandIssues') {
		errorArr.map(e => {
		  if (obj[e.brand]) {
			let tempArr = obj[e.brand];
			tempArr.push(e.row);
			obj[e.brand] = tempArr;
		  } else {
			obj[e.brand] = [e.row];
		  }
		});
	} else if (type === 'skuIssues') {
		errorArr.map(e => {
		  if (obj[e.sku]) {
			let tempArr = obj[e.sku];
			tempArr.push(e.row);
			obj[e.sku] = tempArr;
		  } else {
			obj[e.sku] = [e.row];
		  }
		});
	} else if (type === 'accountIssues') {
		errorArr.map(e => {
		  if (obj[e.account]) {
			let tempArr = obj[e.account];
			tempArr.push(e.row);
			obj[e.account] = tempArr;
		  } else {
			obj[e.account] = [e.row];
		  }
		});
	} else if (type === "duplicate") {
		errorArr.map(e => {
      if(obj['duplicate']){
        obj['duplicate'].push(e.row);
      } else {
        obj['duplicate'] = [e.row];
      }
		})
		message = `Duplicate records found in following rows: ${obj['duplicate'].join(', ')}`
	} 
	return (<>
		<h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '5px 0' }}>
			{message}</h6>
		{type !== "data" && <Grid container style={{ textAlign: "center", fontSize: '16px', fontWeight: 700 }}>
			<Grid item xs={6} sm={6}>{titles[type] || type}</Grid>
			<Grid item xs={6} sm={6}>{"Row No."}</Grid>
			{Object.keys(obj).map((err, index) => {
				return <React.Fragment key={index}>
					<Grid item xs={6} sm={6} style={{ fontWeight: 500 }}>{err}</Grid>
					<Grid item xs={6} sm={6} style={{ fontWeight: 500 }}>{obj[err].join(', ')}</Grid>
				</React.Fragment>
			})}
		</Grid>}
	</>)
}

const SetupConfirmDialog = (props) => {
	let { open, handleClose, alertMessageText, classes, confirm, type } = props

	const renderErrorMsgs = (errorObject) => {
		return <>
			{
				typeof(errorObject) === 'string' &&
				<h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '5px 0' }}>
					{errorObject}</h6>
			}
			<h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '5px 0' }}>
				{errorObject.message}</h6>
			{errorObject.brandFormIssues && errorObject.brandFormIssues.length > 0
				&& <HandleErrors key="Brandforms" errorArr={errorObject.brandFormIssues} type="Brandforms" />}
			{errorObject.channelIssues && errorObject.channelIssues.length > 0
				&& <HandleErrors key="Channels" errorArr={errorObject.channelIssues} type="Channels" />}
			{errorObject.dataValidationIssues && errorObject.dataValidationIssues.length > 0
				&& <HandleErrors key="data" errorArr={errorObject.dataValidationIssues} type="data" />}
		</>
	}
	return (<>
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			className={classes.setupConfirmationDialog}
		>
			<DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#047dd8" }}>
				{typeof alertMessageText == 'object' && alertMessageText.title ? alertMessageText.title
					:
					(alertMessageText.brandFormIssues && alertMessageText.brandFormIssues.length > 0 ||
						alertMessageText.channelIssues && alertMessageText.channelIssues.length > 0 ||
						alertMessageText.dataValidationIssues && alertMessageText.dataValidationIssues.length > 0)
						? "Error" :
						"Confirmation"}
			</DialogTitle>
			<DialogContent className={classes.dialog}>
				{
					props.uploadType === 'allUpload' ?
						<DialogContentText component="div" className={classes.para}>
							{
								alertMessageText?.map(errorObj => {
									return (<div style={{ paddingBottom: 10, borderBottom: '1px solid #ddd' }}>
										<h5 style={{ color: '#000', textAlign: 'center', marginBottom: 10, marginTop: 15,}}>{errorObj.sheetName}</h5>
										{renderErrorMsgs(errorObj.message)}
									</div>)
								})

							}
						</DialogContentText>
						: <DialogContentText component="div" className={classes.para}>
							{type === "setup"
								? <>
									{typeof alertMessageText == 'object'
										? renderErrorMsgs(alertMessageText)
										: <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
											{alertMessageText}
										</h6>
									}
								</>
								: <>
									{typeof alertMessageText == 'object'
										? <>
											{alertMessageText.p1 &&
												<h4 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '20px 0' }}>
													{alertMessageText.p1} </h4>}
											{alertMessageText.p2 && <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
												{alertMessageText.p2} </h6>}
											{alertMessageText.p3 && <h3 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
												{alertMessageText.p3} </h3>}
										</>
										: <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
											{alertMessageText}
										</h6>
									}
								</>
							}
						</DialogContentText>

				}

			</DialogContent>
			<DialogActions>
				{
					confirm ?
						<React.Fragment>
							<Button
								color="primary" onClick={() => {
									handleClose(true)
								}}
								className="btn btn-primary btn-color border-radius-0 text-color-white">
								{"Yes"}
							</Button>
							<Button
								color="primary" onClick={() => handleClose(false)}
								className="btn btn-primary btn-color border-radius-0 text-color-white">
								{"No"}
							</Button>
						</React.Fragment>
						:
						<Button
							color="primary" onClick={() => handleClose()}
							className="btn btn-primary btn-color border-radius-0 text-color-white">
							{"OK"}
						</Button>
				}
			</DialogActions>
		</Dialog>

	</>)
}

const mapStateToProps = state => {
	return {
		user: state.user,
		pageLoading: state.pages.loading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {}
	};
};
export default hot(withRouter(connect(mapStateToProps, null)(SetupConfirmDialog)));
