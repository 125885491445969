import React, { Component } from 'react';
import {
	withStyles, Icon, Table, TableBody, TableCell,
	Button, TablePagination, TableRow, Checkbox
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import TableHead from './TableHead';
import _ from 'modules/lodash';
import history from 'modules/history';
import { AppService } from 'modules/api/index';

import {
	getFilteredArray,
	handleSelectedRow,
	isRowselected,
	requestSort,
	handleTableSelectAllClick,
} from 'modules/page/common';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = theme => ({
	root: {}
});

class CustomTable extends Component {
	state = {
		order: 'asc',
		orderBy: null,
		selected: [],
		data: [["Loading Data..."]],
		page: 0,
		count: 1,
		rowsPerPage: 10,
		searchText: ""
	};

	componentDidUpdate(prevProps, prevState) {
	}

	componentWillUnmount() {
	}

	handleRequestSort = (event, property) => {
		let obj = requestSort(event, property, this.state)
		this.setState(obj);
	};

	handleSelectAllClick = event => {
		this.setState({ selected: handleTableSelectAllClick(event, this.state.data.datasets) });
	};

	handleCheck = (event, id) => {
		console.log('====================================');
		console.log('handleCheck====', event, id);
		console.log('====================================');
		const { selected } = this.state;
		let newSelected = handleSelectedRow(event, id, selected)
		this.setState({ selected: newSelected });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	componentDidMount() {
		this.getApiData(this.state.page, this.state.rowsPerPage);
	}

	getTableHeader = (data) => {
		let tableHeader = [];
		let { chartData } = this.props;
		console.log("chartData ===", chartData);

		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			console.log("chartData.selectedDataElement", chartData.selectedDataElement);
			chartData.selectedDataElement.map(element => {
				let { label, value } = element;
				if (value != '_id') {
					let headerItem = {
						id: label.replace(' ', ''),
						name: value,
						label: label,
						disablePadding: false,
						numeric: false,
						numeric: false,
						sort: true
					}
					tableHeader.push(headerItem)
				}
			})
		} else {
			if (data && data.length > 0) {
				for (let key in data[0]) {
					if (key != '_id') {
						let headerItem = {
							id: key.replace(' ', ''),
							name: key,
							label: key,
							disablePadding: false,
							numeric: false,
							numeric: false,
							sort: true
						}
						tableHeader.push(headerItem)
					}
				}
			}
		}
		return tableHeader;
	};

	setDatafromApi = (res) => {
		if (res && Array.isArray(res.data) && !res.data.columns && !res.data.datasets) {
			let tableHeader = this.getTableHeader(res.data);
			let r = {
				columns: tableHeader,
				datasets: res.data
			}
			this.setState({ resData: r, data: r, isLoading: false, count: res.total ? res.total : res.data.length });
		} else if (res.columns && res.datasets) {
			this.setState({ data: res, isLoading: false, count: res.total ? res.total : res.datasets.length });
		} else {
			throw new Error('Expect data is object with columns and datasets keys');
		}
	}

	getApiDataFromDataUrl = (url) => {
		AppService.getApiDataFromDataUrl(url)
			.then(res => {
				this.setDatafromApi(res)
			})
	}

	getApiData = (page, rowsPerPage) => {
		console.log("getAPIData ====>", page);
		this.setState({ isLoading: true });
		let { chartData } = this.props;
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			console.log("chartData==>", chartData);
			let targetURL = API_BASE_URL + "/dataconnect/table/" + chartData.selectedDataModel;
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			// targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
			targetURL += `?type=list&`;
			targetURL += chartData.selectedDataElement.reduce(reducer, '&fields=');
			targetURL += '&page=' + page + '&rowsPerPage=' + rowsPerPage;
			if (this.props.queryString) {
				targetURL += '&' + this.props.queryString;
			}
			console.log("chartData.conditions", chartData.conditions);

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (this.props.page.conditions && this.props.page.conditions.length > 0) {
				let cond = JSON.stringify(this.props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
      let encodedData = encodeURIComponent(targetURL)
      let _targetUrl = JSON.stringify(encodedData);
			console.log("getAPIData ====>targetURL==>" + targetURL);
			this.getApiDataFromDataUrl(_targetUrl);
		} else if (this.props.dataUrl) {
			this.getApiDataFromDataUrl(props.dataUrl);
		} else {
			console.log('====================================');
			console.log(this.props.data, "this.props.data");
			console.log('====================================');
			this.setState({
				resData: this.props.data,
				data: this.props.data, isLoading: false,
				count: this.props.data.datasets.length
			});
		}
	}

	handleMoreClick = (e) => {
		console.log("handleMoreClick", e);
		history.push({
			pathname: `${this.props.chartData.extendedTablePage.url}`
		});
	}

	handleAddClick = (e) => {
		console.log("handleAddClick", e);
		history.push({
			pathname: `/apps/${this.props.appId}/form/${this.props.chartData.associatedFormName}/new`
		});
	}

	getPageUrl = (selectedLinkValue) => {
		let page = this.props.pages.find(p => {
			return p._id === selectedLinkValue
		})
		return page && page.url;
	}

	onSearchChange = (searchText) => {
		console.log("searchText", searchText);
		console.log("data", data);
		let d = { ...data }
		d.datasets = this.state.resData.datasets.filter(ds => {
			let flag = false
			Object.keys(ds).map((k) => {
				console.log("ds", ds, k, ds[k]);

				if (String(ds[k]).includes(searchText))
					flag = true
			})
			return flag
		});
		console.log("datatttt => d", d);
		this.setState({ data: d, searchText: searchText });
	}

	onTableChange = (action, tableState) => {

		// a developer could react to change on an action basis or
		// examine the state as a whole and do whatever they want

		switch (action) {
			case 'changePage':
				this.getApiData(tableState.page, tableState.rowsPerPage);
				break;
		}
	}

	onRowClick = (rowData, rowMeta) => {
		let { extendedTablePage, associatedFormName } = this.props.chartData;
		console.log('====================================');
		console.log("onRowClick");
		console.log("rowData, rowMeta", rowData, rowMeta);
		console.log('====================================');
		// if (associatedFormName && data.datasets[rowMeta.rowIndex]._id) {
		if (associatedFormName && rowData._id) {
			history.push({
				pathname: `/apps/${this.props.appId}/form/${associatedFormName}/${rowData._id}`
			});
		}
	}

	onCellClick = (colData, cellMeta) => {
		let { selectedDataPages, selectedDataColumns, links,
			extendedTablePage, associatedFormName, name } = this.props.chartData;

		let { data } = this.state;

		console.log("Oncellclick", colData, cellMeta, colData[cellMeta.name]);
		console.log(" ==> links", links);
		if (links && links.length > 0)
			links.map((l, i) => {
				console.log("l, column", l.selectedLinkDataElement, cellMeta.name);
				if (cellMeta.name === l.selectedLinkDataElement) {
					let url;
					if (l.selectedLinkType === "page") {
						let pageUrl = this.getPageUrl(l.selectedLinkValue);
						if (pageUrl)
							url = `/apps/${this.props.appId}/${pageUrl}/${l.selectedLinkDataElement}/${colData[cellMeta.name]}`
					} else {
						if (l.selectedLinkDataModel && l.selectedLinkKey) {
							url = `/apps/${this.props.appId}/form/${l.selectedLinkValue}/new`
							url += `?dm=${l.selectedLinkDataModel}&k=${l.selectedLinkKey}`
							url += `&v=${colData[l.selectedLinkKey]}`
							const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
							url += l.additionalFields.reduce(reducer, '&page=0&rowsPerPage=10&type=list&fields=');
						} else
							url = `/apps/${this.props.appId}/form/${l.selectedLinkValue}/${colData._id}`

					}
					if (url)
						history.push({
							pathname: url
						});
					else
						alert(`Please provide valid links for column ${l.selectedLinkDataElement}`)
				}
			})
	}

	render() {
		const { order, orderBy, selected, rowsPerPage, page, data } = this.state;

		let { selectedDataPages, selectedDataColumns, links,
			extendedTablePage, associatedFormName, name } = this.props.chartData;

		console.log("this.props==", this.props);
		console.log('====================================');
		console.log("data==+==", data);
		console.log('====================================');


		return (<>
			<div className="pl-10">
				<span className="pl-20" style={{fontSize: "1.8em" }}><b>{name}</b></span>
				{associatedFormName &&
					<Button onClick={this.handleAddClick} className="btn-new-btn"
						variant="contained" color="primary">
						+ NEW
				</Button>}
			</div>
			<div className="w-full flex flex-col mt-10 mb-20">
				<Table className="min-w-xl" aria-labelledby="tableTitle">
					<TableHead
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onSelectAllClick={this.handleSelectAllClick}
						onRequestSort={this.handleRequestSort}
						rowCount={data.length}
						data={this.state.data}
						chartData={this.props.chartData}
						hasCheckbox={false}
					/>
					<TableBody>
						{_.orderBy(data.datasets, [
							(o) => {
								switch (orderBy) {
									default:
										{
											return o[orderBy];
										}
								}
							}
						], [order])
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((n, i) => {
								const isSelected = isRowselected(n._id, this.state.selected);
								console.log('====================================');
								console.log("n======", n);
								console.log('====================================');
								return (
									<TableRow
										className="h-64 cursor-pointer table-row-tr"
										hover
										role="checkbox"
										aria-checked={isSelected}
										tabIndex={-1}
										key={n._id}
										selected={isSelected}
										onClick={event => this.onRowClick(n, i)}
									>
										{this.props.hasCheckbox
											&& <TableCell className="w-48 pl-4 sm:pl-12" padding="checkbox">
												<Checkbox
													style={{ backgroundColor: "#fff" }}
													checked={isSelected}
													onClick={event => event.stopPropagation()}
													onChange={event => this.handleCheck(event, n._id)}
												/>
											</TableCell>}
										{data && data.columns && data.columns.length > 0 &&
											data.columns.map(c => {
												console.log('====================================');
												console.log('c=====', c, c.name);
												console.log('====================================');
												console.log("n[c.value]", n[c.name]);
												console.log('====================================');
												return (<TableCell
													component="td" scope="row"
													className="table-row-tr"
													style={{ borderTop:none }}
													onClick={event => this.onCellClick(n, c)}>
													{n[c.name]}
												</TableCell>)
											})
										}
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</div>
		</>);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

function mapStateToProps({ appConfigApp }) {
	return {
	}
}

export default connect(mapStateToProps, null)(CustomTable)
