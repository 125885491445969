import { ActionTypes } from 'constants/index';

export const getMrAndEcommTFIsData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_MRANDECOMMTFIS_DATA, payload: data });
};

export const addNewMrAndEcommTFIs = (data) => dispatch => {
  dispatch({ type: ActionTypes.ADD_NEW_MRANDECOMMTFIS, payload: data });
};

export const deleteCheckedMrAndEcommTFIs = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_CHECKED_MRANDECOMMTFIS, payload: data });
};

export const getMrAndEcommTFIsExtraData = (payload) => dispatch => {
  dispatch({ type: ActionTypes.GET_MRANDECOMMTFIS_EXTRA_DATA, payload });
}

export const getPlanFilterDataTFIs = (data) => dispatch => {
	dispatch({ type: ActionTypes.GET_PLAN_FILTER_DATA_TFIS, payload: data });
}

export const modifyTFIs = (records) => dispatch => {
	dispatch({ type: ActionTypes.MODIFY_MRECOMM_TFI, payload: records });
}

export const clearModifyTFIs = () => dispatch => {
	dispatch({ type: ActionTypes.CLEAR_MODIFY_MRECOMM_TFI });
}

export const removeModifyTFIs = (ids) => dispatch => {
	dispatch({ type: ActionTypes.REMOVE_MODIFY_MRECOMM_TFI, payload: ids });
}
