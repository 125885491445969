import React from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { TSP_TITLE } from '../spendplanUtils';
import { useStyles } from './Mytask';

function TopFivePlans(props) {
	const { topFivePlans } = props;
	const classes = useStyles();

	const convertToNumber = (value, point) => {
		if (value) {
			return Number(parseFloat(('' + value).replace(/,/g, '').trim()).toFixed(point || point === 0 ? point : 2))
		}
		else return 0
	}

	const redirectToPlan = (item) =>{
	  const { brandform, cluster, channel, planName, month, brand, currentFF, fiscalYear} = item;
    props.history.push({ pathname: "/apps/" + props.match.params.appid + "/TradePriceReduction",
      search: `&brandForm=${encodeURIComponent(brandform)}&cluster=${cluster}&channel=${encodeURIComponent(channel)}&month=${encodeURIComponent(currentFF)}&brand=${encodeURIComponent(brand)}&planMonth=${encodeURIComponent(month)}&year=${encodeURIComponent(fiscalYear)}&planName=${encodeURIComponent(planName)}`,
    })
	}

	return (
		<Grid item xs={7}>
			<Paper className={classes.paperTable}>
				<div className={(classes.panel, classes.paneldefault)}>
					<div className={classes.panelHeading}>
						<div className={classes.headingStyle}>
							<DescriptionIcon className={classes.svgIcon} />
							{"TOP 5 PLANS"}
						</div>
					</div>
					<table
						className={`table table-dark spnd-table-container spnd-report-table`}
						style={{ width: '100%' }} >
						<thead>
							<tr>
								<th>{"Plan Name"}</th>
								<th>{"Month"}</th>
								<th>{"Brandform"}</th>
								<th>{"Channel"}</th>
								<th style={{textAlign:'end'}}>{"Budget $M"}</th>
							</tr>
						</thead>
						<tbody>
							{(topFivePlans && props.app.title === TSP_TITLE) && topFivePlans.map((item, i) => {
								return (
										<tr key={i} onClick={()=> redirectToPlan(item)} className={classes.rowStyle}>
											<td title={item.planName} style={{ maxWidth: '150px' }}>{item.planName}</td>
											<td title={item.month}>{item.month?.charAt(0)?.toUpperCase() + item.month.slice(1)}</td>
											<td title={item.brandform}>{item.brandform}</td>
											<td title={item.channel}>{item.channel}</td>
											<td style={{textAlign:'end'}}>
												{`$${convertToNumber(item.planData?.estimates?.BudgetInMillions ?? 0, 0)} `}
											</td>
										</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</Paper>
		</Grid >
	);
}

export default TopFivePlans;
