import { ActionTypes } from "../../../constants";

let initialFreezePlan = {
    loading: false,
    loading1: false,
    message: null,
    accounts: null,
    freezedAccounts: null,
    failedAccounts: null,
    success: false,
    failure: false
}

const initialState = {
    account: null,
    data: null,
    error: null,
    freezePlan: initialFreezePlan,
};

export default function mrReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.USER_SELECTED_ACCOUNT: {
            return {
                ...state,
                account: action.payload,
            };
        }
        case ActionTypes.GET_DATA: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true
            };
        }
        case ActionTypes.GET_DATA_SUCCESS: {
            let updatedData;
            if (action.payload.isMerge) {
                let data = state.data?(state.data[action.payload.dataUniqueId] || {}):{}
                data = JSON.parse(JSON.stringify(data));
                let _newData = {...data, ...action.payload.data};
                if(state.data && state.data[action.payload.dataUniqueId]){
                    updatedData = { ...state.data, [action.payload.dataUniqueId]: null }
                } else {
                    updatedData = { ...state.data}
                }
                updatedData = { ...updatedData, [action.payload.dataUniqueId]: _newData }
            } else {
                updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }
            }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.GET_DATA_FAILURE: {
            let error = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: null }

            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: error,
                data: updatedData,

            };
        }

        case ActionTypes.DELETE_MR_DATA: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true
            };
        }
        case ActionTypes.DELETE_MR_DATA_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.DELETE_MR_DATA_FAILURE: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: action.error,
            };
        }
        case ActionTypes.REMOVE_MR_SUCCESS_MESSAGE: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: null }
            return {
                ...state,
                data: updatedData,
                error: null,
            };
        }
        case ActionTypes.SAVE_MR_DATA: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true
            };
        }
        case ActionTypes.SAVE_MR_DATA_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }

            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.SAVE_MR_DATA_FAILURE: {
            let error = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: error,
            };
        }
        case ActionTypes.UPDATE_MR_DATA: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true
            };
        }
        case ActionTypes.UPDATE_MR_DATA_SUCCESS: {
            let updatedData = { ...state.data, [action.payload.dataUniqueId]: action.payload.data }

            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                data: updatedData,
            }
        }
        case ActionTypes.UPDATE_MR_DATA_FAILURE: {
            let error = { ...state.error, [action.payload.dataUniqueId]: action.payload.error }
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: false,
                error: error,
            };
        }
        case ActionTypes.FREEZE_MR_PLANS: {
            return {
                ...state,
                freezePlan: { ...initialFreezePlan, loading: true, brands: action.payload }
            };
        }
        case ActionTypes.FREEZE_MR_PLANS_SUCCESS: {
            return {
                ...state,
                freezePlan: {
                    ...state.freezePlan, loading: false, success: true,
                    freezedAccounts: action.payload.freezedAccounts,
                    failedAccounts: action.payload.failedAccounts
                }
            };
        }
        case ActionTypes.FREEZE_MR_PLANS_FAILURE: {
            return {
                ...state,
                freezePlan: {
                    ...state.freezePlan, loading: false, failure: false,
                    freezedAccounts: action.payload.freezedAccounts,
                    failedAccounts: action.payload.failedAccounts
                }
            };
        }
        case ActionTypes.GET_DATA_POST: {
            return {
                ...state,
                [action.payload.dataUniqueId + '_loading']: true
            };
        }
        default: {
            return state;
        }

    }

}