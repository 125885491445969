import { Button, CircularProgress } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMrData, removeMRSuccessMessage } from '../../../../store/actions';
import { capitalize, getMonth, monthFilterValues } from '../../../../utility/utils';
import MixedChart from '../../../highcharts/MixedChart';
import Loader from '../../../Loader';
import PlanningErrorModal from '../../spendplan/PlanningPages/planningErrorModal';
import { convertToNumber } from '../../spendplan/PlanningPages/planningHelperFunctions';
import Header from '../../spendplan/SpendPlan/Header';
import { URLs } from '../ApiConstants';
import CockpitTable from './cockpittable';
import { selectComponent, useStyles } from './index';

const OneCr = 10000000

const tableConfig1 = [
    {
        headingData: 'Brand Change vs. LR',
        headings: [
            { title: 'SKU', rowSpan: 2, key: 'sku', leftAlign: true, isString: true, isLink: true },
            , { title: 'Type', rowSpan: 2, key: 'type', leftAlign: true, isString: true },
            , { title: 'Sal %', rowSpan: 2, key: 'sal', isPercent: true },
            { title: 'Retailing', colSpan: 4 },
            { title: 'IYA', colSpan: 3 },
        ],
        subHeadings: [
            { key: 'retailingValue', title: 'FY' },
            { key: 'prevRetailValue', title: 'Prev FF' },
            { key: 'changeValue', title: 'Change Vs FF (INR)' },
            { key: 'changeValuePerc', title: 'Change Vs FF (%)', isPercent: true },
            { key: 'retaling.currentFF', title: 'FY Ret' },
            { key: 'retaling.previousFF', title: 'Prev FF' },
            { key: 'retaling.changeFF', title: 'Change Vs FF' },
        ],
    },
]

const options = {
    maintainAspectRatio: true
};

const SKUAnalysis = (props) => {
    const [brand, setBrand] = useState("All Brands");
    const [month, setMonth] = useState(null)
    const [open, setOpen] = useState(false);
    const [alertMessageText, setAlertMessage] = React.useState("");
    const [sku, setSKU] = useState(null)
    const classes = useStyles();

    const onBrandChange = (v) => {
        if (v !== brand) {
            setBrand(v)
        }
    }
    const onMonthChange = (v) => {
        if (v !== month) {
            setMonth(v)
        }
    }

    useEffect(() => {
        if (month) {
            fetchData()
        }
    }, [month, brand])

    useEffect(() => {
        let cff = props.account?.CurrentFF
        getDataFromSKUMasterDataForDropdown()
        setMonth(moment().month(cff)?.format("MMM").toLowerCase())
    }, [props.account])


    const getDataFromSKUMasterDataForDropdown = () => {
        let dataModelId = '617f826eb336d60019d00226'; // TODO: need to make it configurable
        if (props.account) {
            let targetURL = "/dataconnect/filter/" + dataModelId;
            targetURL += `?type=chart`;
            targetURL += '&label=brand';
            let payload = {
                url: targetURL,
                appId: props.match.params.appid,
                dataUniqueId: 'spendReviewDropdown'
            }
            props.getMrData(payload)
        }
    }


    const fetchData = () => {
        const account = props.account;
        if (account) {
            let params = {
                fiscalYear: account.FinancialYear,
                account: encodeURIComponent(account.AccountName),
            };
            let m = '' + getMonth(account.CurrentFF, 'monthNumber')
            params.currentFF = (m.length === 1 ? '0' + m : m);
            let url = URLs.cockpitSkuAnalysis;
            url += `?account=${params.account}&fiscalYear=${params.fiscalYear}&currentFF=${params.currentFF}`
            if (month && month !== 'All Months') {
                url += '&month=' + month.toLowerCase()
            }
            if (brand && brand !== 'All Brands') {
                url += '&brand=' + encodeURIComponent(brand)
            }
            let payload = {
                url: url,
                appId: props.match.params.appid,
                dataUniqueId: 'GET_SKUData'
            }
            props.getMrData(payload)
        }
    };

    const fetchChartData = (row) => {
        const account = props.account;
        if (account) {
            let params = {
                fiscalYear: account.FinancialYear,
                account: encodeURIComponent(account.AccountName),
            };
            let m = '' + getMonth(account.CurrentFF, 'monthNumber')
            params.currentFF = (m.length === 1 ? '0' + m : m);
            let url = URLs.cockpitSkuAnalysisBarChart;
            url += `?account=${params.account}&fiscalYear=${params.fiscalYear}&currentFF=${params.currentFF}`
            if (row.brand) {
                url += '&brand=' + encodeURIComponent(row.brand)
            }
            if (row.brandform) {
                url += '&brandform=' + encodeURIComponent(row.brandform)
            }
            if (row.sku) {
                url += '&sku=' + encodeURIComponent(row.sku)
            }
            let payload = {
                url: url,
                appId: props.match.params.appid,
                dataUniqueId: 'GET_SKUChartData'
            }
            props.getMrData(payload)
        }
    }


    useEffect(() => {
        if (props.mrData.error && props.mrData.error['GET_SKUData']) {
            setOpen(true)
            setAlertMessage({
                title: 'SKU Analysis',
                p1: props.mrData.error['GET_SKUData']?.message || 'Something went wrong',
            });
        }
        if (props.mrData.error && props.mrData.error['GET_SKUChartData']) {
            setOpen(true)
            setAlertMessage({
                title: 'SKU Analysis',
                p1: props.mrData.error['GET_SKUChartData']?.message || 'Something went wrong',
            });
        }
    }, [props.mrData.error]);

    const getData = () => {
        if (props.data && props.data['GET_SKUData']) {
            return props.data['GET_SKUData']?.result;
        }
    }

    const getChartData = () => {
        if (props.data && props.data['GET_SKUChartData']) {
            return props.data['GET_SKUChartData']?.result;
        }
    }

    const handleClose = () => {
        setOpen(false);
        props.removeMRSuccessMessage({ dataUniqueId: 'GET_SKUData' });
    };

    const onRowClick = (row) => {
        setSKU(row.sku)
        fetchChartData(row)
    }



    const getFilterData = () => {
        if (props.data && props.data['spendReviewDropdown']) {
            return props.data['spendReviewDropdown'] || []
        }
        return []
    }
    
    const mixedChartConfig = {
        xAxis: {
            categories: getChartData()?.bar?.value ? Object.keys(getChartData().bar.value).map(i => capitalize(i)) : []
        },
        yAxis: {
            title: {
                text: 'Retailing Cr.'
            }
        },
        labels: {
            items: []
        },
        series: [
            {
                type: 'column',
                name: 'Ret',
                data: (sku && getChartData()?.bar?.value) ? Object.values(getChartData().bar.value).map(i => convertToNumber(i / OneCr)) : []
            }, {
                type: 'line',
                name: 'IYA',
                data: (sku && getChartData()?.line) ? (Object.values(getChartData()?.line).map(i => convertToNumber(i / OneCr)) || []) : [],
                marker: {
                    lineWidth: 2,
                    fillColor: 'white'
                }
            }
        ]
    }

    if (props.mrData && props.mrData['GET_SKUData' + '_loading']) {
        return (
            <section className={classes.tableBodyLoading}>
                <Loader />
            </section>
        )
    }

    const getMonths = () => {
        let cff = props.account?.CurrentFF
        return monthFilterValues.map(i => {
                return {
                    label: i === moment().month(cff)?.format("MMM") ? (i + ' (Current FF)'):i,
                    value: i.toLowerCase(),
                }
        })
    }

    const isChartLoading = props.mrData && props.mrData['GET_SKUChartData' + '_loading']

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Header header={`Top SKUs Analysis`} />
                <div style={{ display: 'flex' }}>
                    <Button
                        style={{ width: 240 }}
                        variant="contained"
                        onClick={() => {
                            props.history.push({
                                pathname: `/apps/${props.match.params.appid}/RetailingSummary`
                            });
                        }}
                        startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} />}
                        color="primary">Back to Retailing Summary</Button>
                    <Button
                        style={{ marginLeft: 10, width: 200 }}
                        variant="contained"
                        onClick={() => {
                            props.history.push({
                                pathname: `/apps/${props.match.params.appid}/Dashboard2`
                            });
                        }}
                        startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} />}
                        color="primary">Back to Cockpit</Button>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                {selectComponent(['All Brands', ...(getFilterData()?.brand || [])], brand, onBrandChange)}
                {selectComponent(getMonths(), month, onMonthChange, true)}
            </div>
            <h6 style={{ color: 'black', margin: 10, }}>Top 20 SKUs by Vol</h6>
            <CockpitTable data={getData()?.final || []} tableConfig={tableConfig1} onRowClick={onRowClick} />
            <h6 style={{ color: 'black', margin: 10, }}>SKU  {!sku ? <i>- Click on an SKU in the above table to see their Retailing data</i> : ('- ' + sku)}</h6>

            <div style={{ width: '100%', }}>
                {
                    isChartLoading ?
                        <div style={{ display: 'flex', height: 400, }}>
                            <CircularProgress className={classes.circularBar} size={40} />
                        </div>
                        : <MixedChart page={'props.page'} data={mixedChartConfig} chartData={{}} isCustom={true}
                            dataUrl={null} styles={{}} legendOpts={null}
                            options={options} queryString={null} appId={props.appId} _id={'data._id'} />
                }

            </div>
            <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} />

        </div>
    )
}

const mapDispatchToProps = {
    getMrData,
    removeMRSuccessMessage,
};

const mapStateToProps = state => {
    return {
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
        account: state.mrReducer?.account,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(SKUAnalysis)));
