/**
 * @module Sagas/Pages
 */
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { FormService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';
import { checkForBGProcesses } from '../components/customPages/spendplan/spendplanUtils';

/**
 * Application
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
  const response = arg ? await func(arg) : await func();
  return response;
}

function* getEntryForms(action) {
  try {
    console.log("action", action);
    let forms = yield fetchAsync(FormService.getEntryForms, action);
    yield put({ type: ActionTypes.GET_FORMS_SUCCESS, payload: forms });

  } catch (e) {
    yield put({ type: ActionTypes.GET_FORMS_ERROR, error: e.message });
  }
}

function* getEntryFormByName(action) {
  try {
    console.log("action", action);
    let form = yield fetchAsync(FormService.getFormByName, action);
    let events = yield fetchAsync(FormService.getWorkflowEvents, { workflowId: form[0].workflowId, appId: action.appId });
    if (action.submissionId) {
      const submittedForm = yield fetchAsync(FormService.getSubmittedDataBySubmissionId, { id: action.submissionId, formName: action.formName, appId: action.appId, ffFy: action.ffFy });
      yield put({ type: ActionTypes.GET_FORM_BY_NAME_SUCCESS, payload: { form: form && form[0], events } });
      yield put({ type: ActionTypes.GET_SUBMITTED_DATA_BY_ID_SUCCESS, payload: submittedForm });
    } else {
      yield put({ type: ActionTypes.GET_FORM_BY_NAME_SUCCESS, payload: { form: form && form[0], events } });
    }

  } catch (e) {
    yield put({ type: ActionTypes.GET_FORM_BY_NAME_ERROR, error: e.message });
  }
}

function* getSubmittedDataBySubmissionId(action) {
  try {
    const form = yield fetchAsync(FormService.getSubmittedDataBySubmissionId, { id: action.id, formName: action.formName, appId: action.appId });
    yield put({ type: ActionTypes.GET_SUBMITTED_DATA_BY_ID_SUCCESS, payload: form });
  } catch (e) {

    console.error("error===", e);
    yield put({ type: ActionTypes.GET_SUBMITTED_DATA_BY_ID_ERROR, error: e.message });
  }
}

function* saveSubmittedData(action) {
  try {
    if (action.files.length > 0) {
      const imagesName = [];
      const formData = new FormData();
      let files = action.files
      for (let i = 0; i < files.length; i++) {
        imagesName.push(files[i].name);
        formData.append("file", files[i].file);
      }
      const fileUpload = yield fetchAsync(FormService.saveUploadedFile, { files: formData, appId: action.data.appId });
      let actionData = []
      for (let j = 0; j < fileUpload.data.files.length; j++) {
        let uploadedImageId = fileUpload.data.files[j].filename
        actionData = action.data
        actionData.data[imagesName[j]] = uploadedImageId
      }
      const form = yield fetchAsync(FormService.saveSubmittedData, { formData: actionData });
      yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_SUCCESS, payload: form });
      yield put({ type: ActionTypes.SHOW_MESSAGE, message: 'Form submitted successfully!!' });
    } else {
      const form = yield fetchAsync(FormService.saveSubmittedData, { formData: action.data });
      yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_SUCCESS, payload: form });
      let isDuplicateError = form?.error?.code === 11000;
      // custom code
      let msg = 'Value is already present'
      if (action.data.name === 'BrandForm') {
        msg = 'The Brandform \'' + action.data.data.BrandFormName + '\' already exists in the system. Only Unique brandform names are allowed.'
      }
      yield put({
        type: ActionTypes.SHOW_MESSAGE, message: isDuplicateError ? 'Error' : 'Saving data....',
        description: isDuplicateError ? msg : 'The data has been saved successfully'
      });
    }
  } catch (e) {
    console.error('error=> ', e);
    yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_ERROR, error: e.message });
  }
}

function* updateSubmittedData(action) {
  console.log("==============updateSubmittedData", action)

  try {
    if (action.files.length > 0) {
      const imagesName = [];
      const formData = new FormData();
      let files = action.files
      for (let i = 0; i < files.length; i++) {
        imagesName.push(files[i].name);
        formData.append("file", files[i].file);
      }
      const fileUpload = yield fetchAsync(FormService.saveUploadedFile, { files: formData, appId: action.data.appId });
      let actionData = []
      for (let j = 0; j < fileUpload.data.files.length; j++) {
        let uploadedImageId = fileUpload.data.files[j].filename
        actionData = action.data
        actionData.data[imagesName[j]] = uploadedImageId
      }
      const form = yield fetchAsync(FormService.updateSubmittedData, actionData);
      yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_SUCCESS, payload: form });
      yield put({ type: ActionTypes.SHOW_MESSAGE, message: 'Form updated successfully!!' });
    } else {
      const form = yield fetchAsync(FormService.updateSubmittedData, action.data);
      yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_SUCCESS, payload: form });
      yield put({ type: ActionTypes.SHOW_MESSAGE, message: 'Saving data....', description: 'The data has been saved successfully' });
    }
  } catch (e) {
    yield put({ type: ActionTypes.SAVE_SUBMITTED_DATA_ERROR, error: e.response?.message });
    yield put({ type: ActionTypes.SHOW_MESSAGE,message: 'Error', description: e.response?.message || e.message });
  }
}

function* deleteSubmittedData(action) {
  try {
    const form = yield fetchAsync(FormService.deleteSubmittedData, action.payload);
    yield put({ type: ActionTypes.DELETE_SUBMITTED_DATA_SUCCESS, payload: form });
    if(checkForBGProcesses(action.payload.type)){
      let obj = {
				...action.payload.commonObj,
				appId: action.payload.appId,
			}
      yield put({ type: ActionTypes.CHECK_SP_BG_PROCESS, payload: obj });
    }     
  } catch (e) {
    yield put({ type: ActionTypes.DELETE_SUBMITTED_DATA_FAILURE, error: e.message });
  }
}

export function* watchFormSaga() {
  yield takeEvery(ActionTypes.GET_FORMS_LOADING, getEntryForms);
  yield takeEvery(ActionTypes.GET_FORM_BY_NAME_LOADING, getEntryFormByName);
  yield takeEvery(ActionTypes.GET_SUBMITTED_DATA_BY_ID_LOADING, getSubmittedDataBySubmissionId);
  yield takeEvery(ActionTypes.SAVE_SUBMITTED_DATA_LOADING, saveSubmittedData);
  yield takeEvery(ActionTypes.UPDATE_SUBMITTED_DATA_LOADING, updateSubmittedData);
  yield takeLatest(ActionTypes.DELETE_SUBMITTED_DATA_LOADING, deleteSubmittedData);
}

export default watchFormSaga;
