import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography, Button, withStyles, Paper, Grid } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
import axios from 'axios'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { MessageDialog } from './messageDialog';
import {Link} from 'react-router-dom'
import { FormElements } from './index';

import { getFormByName, getSubmittedDataBySubmissionId, saveSubmittedData, hideMessage, updateSubmittedData, clearSubmittedData, updateSelectedFormData } from 'store/actions';
import { select } from 'redux-saga/effects';
import { FormService } from 'modules/api/index';
import { getCurrentFFForPayload } from '../customPages/spendplan/spendplanUtils';
import { getMonth } from '../../utility/utils';
import ConfirmDialog from '../customPages/spendplan/SpendPlan/ConfirmDialog';

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        "padding-right": "10px",
    },
    paper: {
        "xheight": "90vh",
        // "padding-left": "180px",
        // "padding-right": "180px",
        "xoverflow-y": "scroll"
    },
    modal: {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        position: 'absolute',
    },
    heading_no_data: {
        "color": "gray",
        "padding-top": "250px"
    },
    bold: {
        "font-weight": "bold"
    },
    backDivStyle:{
        display: 'flex',
        padding: '10px',
        '& svg':{
            color:'#fff',
            background: '#69b4ef',
            padding: '6px 0 6px 6px',
            borderRadius: '5px',
            marginRight: '10px',
        },
        '& a':{
            textDecoration:'none',
            color:'#000',
            '&:hover':{
                color:'#2a9fd6'
            }
        }
    },
    confirmationDialoge: {
		'& .MuiDialog-paperWidthSm': {
			height: '320px',
			width: '600px'
		}
	},
	para: {
		fontSize: '28px !important',
		fontWeight: 600,
		color: '#000'
	}
});

class FormSubmit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            dataType: {},
            filesToUpload: [],
            editSubmittedData: this.props.queryString.submissionId ? false : true,
            selectedForm: this.props.selectedForm,
            isLoading: false,
            called: false,
            confirmState: {
                isOpen: false,
                confirmMessage: ''
            }
        }
    }

    componentDidUpdate() {
        const { formName, submissionId, appId } = this.props.queryString;
        const { brand } = this.props;
        if(['Brand', 'BrandForm', 'Forex'].includes(formName) && Object.keys(brand).length && !this.state.called){
            let ffFY = {}
            let m = getMonth(brand.CurrentFF, 'monthNumber');
            ffFY['currentFF'] = getCurrentFFForPayload(m)
            ffFY['fiscalYear'] = brand.FinancialYear + "";
            this.props.getFormByName(formName, submissionId, appId, ffFY);
            this.setState({called: true})
        } 
        if(this.props.selectedForm?.defaultEdit && !this.state.editSubmittedData){
            this.setState({editSubmittedData: true})
        }
    }

    componentDidMount() {
        const { formName, submissionId, appId } = this.props.queryString;
        if(!['Brand', 'BrandForm', 'Forex'].includes(formName)){
            this.props.getFormByName(formName, submissionId, appId);
        }
        if (!submissionId) {
            let queryParams = window.location.search
            if (queryParams) {
                var params = {};
                var query = queryParams.substring(1);
                var vars = query.split('&');
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split('=');
                    params[pair[0]] = decodeURIComponent(pair[1]);
                }
                if (params && params['dm']) {
                    let q = queryParams.replace(`?${vars[0]}`, '').replace('&k=', '&key=').replace('&v=', '&value=');
                    let url = `${params['dm']}?${q}`;
                    this.getDataFromUrl(url)
                }
            }
        }
    }
    getDataFromUrl = (queryParams) => {
        // let url = 'http://localhost:4000/dataconnect/5ecd3c735f32a035204d16af?key=email&value=nakul&type=list&fields=email,LastName,username,Gender,&page=0&rowsPerPage=10'
        let url = `${API_BASE_URL}/dataconnect/${queryParams}`
        axios.get(url, { headers: { appId: this.props.match.params.appid, 'Content-Type': 'application/json',} })
            .then(res => {
                let rows = res.data.data;
                if (rows && rows[0]) {
                    this.setState({
                        data: rows[0]
                    })
                }

            })
    }

    componentWillUnmount() {
        this.props.clearSubmittedData()
    }

    static getDerivedStateFromProps(props, state) {
        let stateToBeMerged = {}
        let propDataId = props.submittedDataToBeEdited && Array.isArray(props.submittedDataToBeEdited) &&
            props.submittedDataToBeEdited.length > 0 ? props.submittedDataToBeEdited[0]._id : "";
        if (props.selectedForm != null) {
            stateToBeMerged.selectedForm = JSON.parse(JSON.stringify(props.selectedForm))
        }

        if (propDataId == state.dataId) {
            return stateToBeMerged;
        }
        if (props.submittedDataToBeEdited && Array.isArray(props.submittedDataToBeEdited) &&
            props.submittedDataToBeEdited.length > 0) {
            console.log("in merge state==>", props.selectedForm);
            stateToBeMerged.selectedForm = props.selectedForm && JSON.parse(JSON.stringify(props.selectedForm));
            stateToBeMerged.data = (props.submittedDataToBeEdited && Array.isArray(props.submittedDataToBeEdited) &&
                props.submittedDataToBeEdited.length > 0) ? props.submittedDataToBeEdited[0] : state.data;
            stateToBeMerged.dataId = props.submittedDataToBeEdited[0]._id;
        }
        console.log(stateToBeMerged.data, "merged state final", props.submittedDataToBeEdited)
        return stateToBeMerged;

    }
    isConditionInvalid = (el, action) => {
        let element = el;
        let testValidationsData = this.state.data;
        if (el.clause) {
            let _element;
            let _value;
            let _element1;
            let _value1;
            if (el.operator == '==' || el.operator == '!=') {
                _element = testValidationsData[el.element];
                _value = element.value
            }
            if (el.operator == '<' || el.operator == '>') {
                _element = parseInt(testValidationsData[el.element]);
                _value = parseInt(element.value)
            }
            if (el.operator1 == '==' || el.operator1 == '!=') {
                _element1 = testValidationsData[el.element1];
                _value1 = element.value1
            }
            if (el.operator1 == '<' || el.operator1 == '>') {
                _element1 = parseInt(testValidationsData[el.element1]);
                _value1 = parseInt(element.value1);
            }
            if (!eval(`(_element ${el.operator} _value) ${el.clause} (_element1 ${el.operator1} _value1)`)) {
                return true;
            }
        } else {
            if (el.operator == "<") {
                if (!eval(`parseInt(testValidationsData[el.element]) < parseInt(element.value) `)) {
                    action != null && alert("condition required Failed :- elements [" + el.element + "] <" + element.value)
                    return true;
                }
            }
            else if (el.operator == ">") {
                if (!eval(`parseInt(testValidationsData[el.element]) > parseInt(element.value) `)) {
                    action != null && alert("condition required Failed :- elements [" + el.element + "] >" + element.value)
                    return true;
                }

            }
            else if (el.operator == "==") {
                if (!eval(`testValidationsData[el.element] == element.value `)) {
                    action != null && alert("condition required Failed :- elements [" + el.element + "] ==" + element.value)
                    return true;
                }
            }
            else if (el.operator == "!=") {
                if (!eval(`testValidationsData[el.element] != element.value `)) {
                    action != null && alert("condition required Failed :- elements [" + el.element + "] !=" + element.value)
                    return true;
                }
            }
        }
    }
    checkVisibility = (position) => {
        if (this.props.selectedForm.modalData.rows[position.split("-")[0]]) {
            let visibilityConditions = this.props.selectedForm.modalData.rows[position.split("-")[0]].columns[position.split("-")[1]].data.visibility;
            let visibility = true;
            visibilityConditions.map((el) => {
                if (this.isConditionInvalid(el)) {
                    visibility = false;
                }
            })
            return visibility;
        }
        return

    }

    hideMessageDialog = () => {
        this.props.hideMessage()
      if(this.props.selectedForm){
        const { name, appId } = this.props.selectedForm
        var win = window.open(`/apps/${appId}/${name}`, '_self');
        win.focus();
      }else{
        // error case
         // error case
         const { formName, submissionId, appId } = this.props.queryString; 
         this.props.history.push({ pathname: `/apps/${appId}/${formName}` });

         // if(['Brand', 'BrandForm', 'Forex'].includes(formName) && this.props.brand){ // send ffFY in api when get api hits after save done
         //     let ffFY = {}
         //     let m = getMonth(this.props.brand.CurrentFF, 'monthNumber');
         //     ffFY['currentFF'] = getCurrentFFForPayload(m)
         //     ffFY['fiscalYear'] = this.props.brand.FinancialYear + "";
         //     this.props.getFormByName(formName, submissionId, appId, ffFY);
         //     this.setState({called: true})
         // } else {
         //     this.props.getFormByName(formName, submissionId, appId);
         // }
      }
    }

    setFilesToUpload = (name, files) => {
        this.setState({ filesToUpload: [...this.state.filesToUpload, { name: name, file: files[0] }] });
    }

    generateRow(Columns, outerIndex, isApiForm) {
        const { classes, submittedDataToBeEdited, events } = this.props;

        return Columns.map((item, index) => {

            if (!(item.name in this.state.data) && item.name != null) {
                let data = JSON.parse(JSON.stringify(this.state.data));
                data = { ...data, [item.name]: "" }
                // this.setState({ data: data }, () => {
                // })
            }
            let gridSize = 12 / Columns.length;
            let visible = this.checkVisibility(`${outerIndex}-${index}`);
            if (!visible && this.state.data[item.name] != null) {
                this.setData(item.name, null, null, item)
            }
            return visible && <FormElements viewOnly={true} gridSize={gridSize} item={item}
                editSubmittedData={isApiForm ? !isApiForm : this.state.editSubmittedData}
                setFilesToUpload={(name, files) => {
                    this.setFilesToUpload(name, files)
                }}
                match={this.props.match}
                outerIndex={outerIndex} index={index}
                onChange={(name, val, _item) => {
                    this.setData(name, val, _item, item)
                }}
                event={
                    this.props.queryString.submissionId
                        ? submittedDataToBeEdited && submittedDataToBeEdited[0] && submittedDataToBeEdited[0].workflow
                        && (submittedDataToBeEdited[0].workflow.currentTaskName)
                        : events && (events.status)
                }
                buttonAction={this.buttonAction}
                lookupDataModelInnerValue={this.state.lookupDataModelInnerValue}
                submittedDataToBeEdited={this.props.queryString.submissionId && submittedDataToBeEdited
                    && submittedDataToBeEdited[0] && submittedDataToBeEdited[0]}
                addColumn={(cardIndex) => this.props.updateSelectedFormData(cardIndex, outerIndex)}
                actions={this.props.queryString.submissionId && submittedDataToBeEdited
                    && submittedDataToBeEdited[0] && submittedDataToBeEdited[0].actions}
                filledData={this.state.data} />
        })
    }

    setData = (name, val, item, row) => {
        let clone = JSON.parse(JSON.stringify(this.state.data));
        let dataType = JSON.parse(JSON.stringify(this.state.dataType));
        //  val=Array.isArray(val)?val[val.length-1]:val;
        if (val == null && item == null) {
            clone[name] = null;
            this.setState({
                data: clone
            })
        }
        else if (item == 'switch') {
            clone[name] = !clone[name];
            this.setState({
                data: clone
            })
        }
        else if (item == 'multiselect') {
            clone[name] = Array.isArray(val) ? val : [val];
            this.setState({
                data: clone
            })
        }

        else if(item === 'namePicker'){
            clone[name] = JSON.parse(val)
            dataType[name] = {
                type: 'Object',
                unique: row.unique,
            }
            this.setState({
                data: clone,
                dataType
            })
        }

        else if (item != 'checkbox') {
            if (row.type === 'card') {
                if (!Array.isArray(clone[row.name])) {
                    clone[row.name] = []
                }

                clone[row.name][row.index] = {
                    ...clone[row.name][row.index],
                    [name]: val
                }

                this.setState({
                    data: clone
                })
            } else {
                let type = 'String'
                if (row.type) {
                    type = row.type === 'number' ? 'Number' : (row.type === 'date' ? 'Date' : (row.type === 'dataSelector' ? 'Array' : 'String'))
                } else {
                    type = row.typeOfText === 'number' ? 'Number' : (row.typeOfText === 'datetime-local' ? 'Date' : 'String')
                }
                dataType[name] = {
                    type: type,
                    unique: row.unique,
                }

                clone[name] = val;
                this.setState({
                    data: clone,
                    dataType
                })
            }

        }

        else {
            let selectedValues = clone[name];
            if (Array.isArray(selectedValues)) {
                if (selectedValues.includes(val)) {
                    let filteredArr = selectedValues.filter((el) => {
                        return el != val
                    });
                    clone[name] = filteredArr;
                }
                else {
                    clone[name].push(val);
                }
            }
            else {
                clone[name] = [val];
            }

            this.setState({
                data: clone,
            })
        }
    }

    generateFormFromJson() {
        const { selectedForm } = this.props
        console.log("========selectedForm", selectedForm)
        const Jsondata = (selectedForm && selectedForm.design && selectedForm.design.rows) ? selectedForm.design.rows : [];
        if (!Jsondata) {
            return
        }
        return Jsondata.map((row, outerIndex) => {
            const Columns = row.columns
            return (
                <React.Fragment key={outerIndex}>
                    {this.generateRow(Columns, outerIndex, selectedForm.isApiForm)}
                </React.Fragment>
            )
        })
    }

    checkValidations = (modalData) => {
        let invalid = false;
        modalData.rows.map((rows) => {
            rows.columns.map((column) => {
                column.data && column.data.conditionalRequired.map((el) => {
                    console.log("inside 180", el);
                    let element = el;
                    let testValidationsData = this.state.data;
                    console.log(testValidationsData, "test data", element);
                    if (this.isConditionInvalid(el, "submit")) {
                        invalid = true;
                    }
                })
            })
        })
        return invalid;
    }

    checkInnerValidations = (completeForm) => {
        let invalid = false;
        completeForm.design.rows.map((row, index) => {
            row.columns.map((item, index) => {
                if (invalid) {
                    return;
                }
                if (item.type == 'card') {
                    console.log("273 check", invalid);
                    invalid = this.checkValidations(item.innerFormModalData);
                }
                else if (item.innerForms != null && item.innerForms.length > 0) {
                    for (var i = 0; i < item.innerForms.length; i++) {
                        invalid = this.checkValidations(item.innerForms[i].innerFormModalData);
                        console.log("inner validations", invalid);
                    }
                }
            })
        })
        console.log("273 check", invalid);
        return invalid;
    }

    generateGUID = () => {
        function S4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        return S4() + S4();
    }

    //TODO: Not fully customizied as required for UI
    buttonAction = (position) => {
        console.log('position', position);
        let row = position.split("-")[0];
        let column = position.split("-")[1];
        let buttonJson = JSON.parse(JSON.stringify(this.props.selectedForm.design.rows[row].columns[column]));
        let body = this.state.data;
        let headers = {
            'Content-Type': 'application/json',
        };
        buttonJson.headers.map((el) => {
            headers[el.label] = el.value;
        });
        console.log(buttonJson, "buttonjson");
        if (buttonJson.function == 'api') {
            axios.post(buttonJson.url, this.state.data, { headers: { ...headers, appId: this.props.match.params.appid } }).then(response => {
                this.setState({
                    showApiResponse: true, apiResponse: {
                        body: body,
                        headers: headers,
                        response: response,
                        url: buttonJson.url,
                        buttonType: buttonJson.function
                    }
                });
            }).catch((exp) => { console.log(exp) })

        }
        else if (buttonJson.function == 'save') {
            console.log('buttonJson', buttonJson);
            const formData = new FormData();
            let files = this.state.filesToUpload
            for (let i = 0; i < files.length; i++) {
                formData.append("file", files[i].file);
            }
            const { _id, name, workflowId, appId } = this.props.selectedForm
            const { events, user, submittedDataToBeEdited } = this.props

            let data = {
                data: this.state.data,
                dataType: this.state.dataType,
                // workflow,
                // actions,
                name,
                _id,
                appId,
                sys_current_user: user?.id || this.props.member?._id
            }
            let dm = ''
            // console.log('this.props.selectedForm.design', this.props.selectedForm);
            this.props.selectedForm.modalData.rows.map(r => {
                r.columns.map(c => {
                    if (c.type === 'file' || c.typeOfText === 'file') {
                        dm = c.data.data.data.dataModel
                    }
                })
            })
            if (buttonJson.action.submitFileData) {
                let reqObj = {}
                buttonJson.action.requiredFormElement && buttonJson.action.requiredFormElement.length > 0 && buttonJson.action.requiredFormElement.map(rfe => {
                    reqObj[rfe] = this.state.data[rfe]
                })
                data.data = { ...reqObj }
                formData.append('data', JSON.stringify(data))
                let invalid = buttonJson.action.requiredFormElement && buttonJson.action.requiredFormElement.length > 0 && buttonJson.action.requiredFormElement.find(rfe => {
                    return !this.state.data[rfe]
                })
                if (buttonJson.action.requiredFormElement && buttonJson.action.requiredFormElement.length > 0) {
                    console.log('buttonJson.action.requiredFormElement', buttonJson.action.requiredFormElement);
                }
                // console.log('this.state.data == ', this.state.data);
                console.log('dm -=== ', dm, invalid);
                if (!invalid) {
                    if (dm && files.length > 0) {
                        FormService.saveUploadedFile({ files: formData, appId, url: `/data?dmId=${dm}` })
                            .then(res => {
                                // console.log('file saved.', res)
                                console.log('buttonJson??', buttonJson.name, this.state.data);
                                this.setState({ data: { ...this.state.data, [buttonJson.name]: 'submittedToDB' + this.generateGUID() } })
                            })
                    } else
                        alert('Fileupload datamodel is not connected')
                } else {
                    alert('Required fields are missing "' + invalid + '"')
                }
            }
        }
    }

    submit = (e, isDraft, isRenameAllBrandForms) => {
        // console.log(e.target, "e.target")
        e.preventDefault();
        // if (!e || !e.target) {
        //     return;
        // }
        // if (!e.target.checkValidity()) {
        //     // form is invalid! so we do nothing
        //     console.log("invalid form");
        //     return;
        // }
        let invalid = false;
        let modalData = this.props.selectedForm.modalData;
        invalid = this.checkValidations(modalData);
        if (invalid) {
            return;
        }
        invalid = this.checkInnerValidations(this.props.selectedForm);
        if (invalid) {
            return
        }
        console.log(invalid, "invalid");
        const { _id, name, workflowId, appId } = this.props.selectedForm
        const { events, user, submittedDataToBeEdited } = this.props
        let workflow = {};
        let actions = []
        if (this.props.queryString.submissionId) {
            if (submittedDataToBeEdited && submittedDataToBeEdited[0] && submittedDataToBeEdited[0].workflow) {
                let tempWorkflow = submittedDataToBeEdited && submittedDataToBeEdited[0].workflow;
                workflow = JSON.parse(JSON.stringify(tempWorkflow));
                workflow.isDraft = isDraft
                // workflow.currentRespondent = this.state.data[workflow.currentRespondentField]
                actions = submittedDataToBeEdited && submittedDataToBeEdited[0].actions || [];
            }


        } else {
            if (events) {
                const { status, action, id, name, respondentfield } = events
                workflow = {
                    workflowId,
                    currentTaskName: name,
                    currentStatus: status,
                    currentRespondent: this.state.data[respondentfield]['_id'] || '',
                    currentAction: action,
                    currentTaskId: id,
                    isDraft: isDraft
                }
            }

        }

        let data = {
            data: this.state.data,
            dataType: this.state.dataType,
            workflow,
            actions,
            name,
            _id,
            appId,
            sys_current_user: user?.id || this.props.member?._id
        }
        for (const [key, value] of Object.entries(this.state.dataType)) {
            if (value.type === 'Number') {
                console.log(`${key}: ${value.type}`);
                data.data[key] = data.data[key].replace(/\,/g, "")
            } else if (value.type === 'Date') {
                console.log(`${key}: ${value.type}`);
                data.data[key] = new Date(data.data[key])
            }
        }
        if(data?.name === 'Forex'){
            const { brand } = this.props;
            let m = getMonth(brand.CurrentFF, 'monthNumber');
            data.data['currentFF'] = getCurrentFFForPayload(m)
            data.data['fiscalYear'] = brand.FinancialYear + "";
        }
        if(data?.name === 'BrandForm' && isRenameAllBrandForms){
            if(this.props.submittedDataToBeEdited?.length){
                data.data['oldBrandFormName'] = this.props.submittedDataToBeEdited[0]?.BrandFormName
            }
        }
        if (this.props.queryString.submissionId) {
            data._id = this.props.queryString.submissionId;
            this.props.updateSubmittedData(data, this.state.filesToUpload);
        }
        else {
            this.props.saveSubmittedData(data, this.state.filesToUpload);
        }

    }

    onSaveClick = (e, bool) => {
        const { formName } = this.props.queryString;
        if(formName === 'BrandForm'){
            e.preventDefault();
            if(this.state.data?.BrandFormName && this.props.submittedDataToBeEdited?.length && (this.props.submittedDataToBeEdited[0]?.BrandFormName !== this.state.data?.BrandFormName)){
                this.setState({confirmState:{ ...this.state.confirmState, isOpen: true, confirmMessage: `Are you sure you want to rename all 
                Brandform from \"${this.props.submittedDataToBeEdited[0]?.BrandFormName || '-'}\" to \"${this.state.data?.BrandFormName}\" ?`,
                    data: {
                    e,bool
                }}})
            } else {
                this.submit(e, bool)
            }
        } else {
            this.submit(e, bool)
        }

    }

    onConfirmResponse = (y)=> {
        if(y){
            this.submit(this.state.confirmState.data.e, this.state.confirmState.data.bool, y)
        }
        this.setState({confirmState:{ ...this.state.confirmState, isOpen: false, data: null, confirmMessage: ''}})

    }

    render() {
        const { classes, message, description, submittedDataToBeEdited, user, events, selectedForm } = this.props;
        const { editSubmittedData } = this.state;
        return (
            <div className={'app-dark-form'}>
                <Grid container>
                    {(this.props.message != null
                        && this.props.message.state) && MessageDialog(this.props.message.message, this.props?.message?.description, () => this.hideMessageDialog(), classes)}
                    <Grid item xs={12}>
                        <form className="form-inner" onSubmit={(e) => { this.onSaveClick(e, false) }}>
                            <Paper style={{ backgroundColor: isIBC ? '#fff' : '', marginTop: '10px' }}>
                                {(!(selectedForm && selectedForm.design && selectedForm.design.rows) || this.props.form?.isLoading) ? (
                                    <>
                                        <Typography variant="h4" align="center"
                                            className={classes.heading_no_data}
                                        >
                                            Loading Form.....
                                        </Typography>
                                    </>
                                ) : (
                                        <>
                                            <Grid>
                                                <Typography variant="h6" align="right"
                                                >
                                                    {this.props.queryString.submissionId
                                                        ? submittedDataToBeEdited && submittedDataToBeEdited[0] && submittedDataToBeEdited[0].workflow && ('Status : ' + submittedDataToBeEdited[0].workflow.currentTaskName)
                                                        : events && ('Status : ' + events.status)
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid container style={{ marginBottom: '20px' }}>
                                            <div className={classes.backDivStyle}  onClick={()=>{const { name, appId } = this.props.selectedForm; this.props.history.push(`/apps/${appId}/${name}`)}}>
                                            <ArrowBackIosIcon fontSize='small'/>
                                            <Link href='#'> Back</Link>
                                            </div>
                                                {this.generateFormFromJson()}
                                            </Grid>
                                            <Grid container>
                                                {
                                                    !selectedForm.isApiForm &&
                                                    <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px', marginBottom: '10px' }}>
                                                        <React.Fragment>
                                                            {!editSubmittedData && submittedDataToBeEdited && submittedDataToBeEdited[0] && (
                                                                <Button className={`btn btn-color border-radius-0 ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} style={{ marginRight: "20px" }}
                                                                    disabled={
                                                                        editSubmittedData ||
                                                                        (submittedDataToBeEdited[0].workflow && submittedDataToBeEdited[0].workflow.isDraft ? false :
                                                                            (submittedDataToBeEdited[0].workflow ?
                                                                                (submittedDataToBeEdited[0].workflow.currentRespondent == (user?.id || this.props.member?._id) ?
                                                                                    false : true) : false
                                                                            ))}
                                                                    onClick={() => { this.setState({ editSubmittedData: true }) }} color="secondary">Edit</Button>
                                                            )}
                                                            {((editSubmittedData && submittedDataToBeEdited &&
                                                                submittedDataToBeEdited[0] &&
                                                                submittedDataToBeEdited[0].workflow &&
                                                                submittedDataToBeEdited[0].workflow.isDraft) || (events && events && events.action && !this.props.queryString.submissionId)) && (
                                                                    <Button className={`btn btn-color border-radius-0 ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} style={{ marginRight: "20px" }}
                                                                        type="submit" color="primary"
                                                                        onClick={(e) => this.onSaveClick(e, true)}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                )}
                                                            {editSubmittedData && (<Button className={`btn btn-color border-radius-0 ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`}
                                                                disabled={!editSubmittedData} type="submit">
                                                                {this.props.queryString.submissionId
                                                                    ? (submittedDataToBeEdited && submittedDataToBeEdited[0] && submittedDataToBeEdited[0].workflow && submittedDataToBeEdited[0].workflow.currentAction || 'Save')
                                                                    : (events && events.action) ? events.action : 'Submit'}
                                                            </Button>)}
                                                        </React.Fragment>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </>
                                    )}

                                {this.props.queryString.submissionId && submittedDataToBeEdited && submittedDataToBeEdited[0] && submittedDataToBeEdited[0].actions && (
                                    <>
                                        <Grid>
                                            <Typography variant="h4" align="left"
                                            >
                                                Audit Trail
                                            </Typography>
                                        </Grid>
                                        <Table className={classNames(classes.table)} size="small" aria-label="a dense table fs">
                                            <TableHead className={classNames(classes.head)}>
                                                <TableRow>
                                                    <TableCell className={classNames(classes.customFont)}>Action</TableCell>
                                                    <TableCell className={classNames(classes.customFont)}>Action By</TableCell>
                                                    <TableCell className={classNames(classes.customFont)}>Date & Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {submittedDataToBeEdited[0].actions.map((item => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell className={classNames(classes.customFont)}>
                                                                {item.action}
                                                            </TableCell>
                                                            <TableCell className={classNames(classes.customFont)}>
                                                                {item.user && item.user.displayName}
                                                            </TableCell>
                                                            <TableCell className={classNames(classes.customFont)}>
                                                                {item.actionDate && moment(item.actionDate).format("MM/DD/YYYY HH:MM:SS")}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }))}


                                            </TableBody>
                                        </Table>
                                    </>
                                )}
                                {this.props.queryString.submissionId && submittedDataToBeEdited
                                    && submittedDataToBeEdited[0] && submittedDataToBeEdited[0].ledger
                                    && submittedDataToBeEdited[0].ledger.hash
                                    && submittedDataToBeEdited[0].ledger.metadata && (<>
                                        <Grid>
                                            <Typography variant="h4" align="left" >{"Ledger Info"}</Typography>
                                        </Grid>
                                        <Table className={classNames(classes.table)} size="small" aria-label="a dense table fs2">
                                            {/* <TableHead className={classNames(classes.head)}>
                                                <TableRow>
                                                    <TableCell className={classNames(classes.customFont)}>
                                                        {"Key"}
                                                    </TableCell>
                                                    <TableCell className={classNames(classes.customFont)}>
                                                        {"Value"}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead> */}
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classNames(classes.customFont, classes.bold)}>
                                                        {"Hash"}
                                                    </TableCell>
                                                    <TableCell className={classNames(classes.customFont)}>
                                                        {submittedDataToBeEdited[0].ledger.hash}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classNames(classes.customFont, classes.bold)}>
                                                        {"metadata Id"}
                                                    </TableCell>
                                                    <TableCell className={classNames(classes.customFont)}>
                                                        {submittedDataToBeEdited[0].ledger.metadata.id}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classNames(classes.customFont, classes.bold)}>
                                                        {"Version"}
                                                    </TableCell>
                                                    <TableCell className={classNames(classes.customFont)}>
                                                        {submittedDataToBeEdited[0].ledger.metadata.version}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classNames(classes.customFont, classes.bold)}>
                                                        {"Transaction Id"}
                                                    </TableCell>
                                                    <TableCell className={classNames(classes.customFont)}>
                                                        {submittedDataToBeEdited[0].ledger.metadata.txId}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classNames(classes.customFont, classes.bold)}>
                                                        {"Transaction Time"}
                                                    </TableCell>
                                                    <TableCell className={classNames(classes.customFont)}>
                                                        {submittedDataToBeEdited[0].ledger.metadata.txTime}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </>)}
                            </Paper>

                        </form>

                    </Grid>

                </Grid>
                <ConfirmDialog open={this.state.confirmState.isOpen} handleClose={this.onConfirmResponse}
                alertMessageText={this.state.confirmState.confirmMessage} classes={classes} confirm />
            </div >
        )

    }
}

const mapDispatchToProps = {
    getFormByName,
    getSubmittedDataBySubmissionId,
    saveSubmittedData,
    hideMessage,
    updateSubmittedData,
    clearSubmittedData,
    updateSelectedFormData
};


const mapStateToProps = state => {
    return {
        selectedForm: state.form.selectedForm,
        submittedDataToBeEdited: state.form.submittedDataToBeEdited,
        message: state.message,
        events: state.form.events,
        user: state.user,
        app: state.appConfig.app,
		member: state.appConfig.member,
        brand: state.tprReducer?.selectBrand,
        form: state.form,

    }
}

export default hot(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(FormSubmit))));
