import React from 'react';

export const data = {
  data1: {
    headingData: 'Volume and Net Realization in <span style=color:#0070C0>F1 and TS Sheet</span> are same',
    subHeadingData: [
      'a) Since the NR is directlt taken from F1, there should be no difference',
      'b) Some volume difference might come on account of NIT as planners don’t adjust NIT',
      'c) Volume difference should be <0.5% even after accounting for NIT',
    ],
    tableData: {
      headings: ['Description', 'Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'],
      rows: [],
    },
  },

  data2: {
    headingData: 'Bottoms-up MSASD estimate matches with <span style=color:#0070C0>F1 submission</span>',
    subHeadingData: [
      'a) Check if the difference is due to Falcon releases/lean forwards, OTI Reclass',
      'b) Immediately highlight any material helps/hurts (>2% of MSASD Budget)',
    ],
    tableData: {
      headings: ['Description', 'Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'],
      rows: [],
    },
  },

  data3: {
    headingData: '<span style=color:#0070C0>Quarterly spends for each bucket</span> is in line with category choices. Spends are <span style=color:#0070C0>sufficient</span> to deliver BOP',
    subHeadingData: [
      'a) Check if the relative amount of spend in each bucket is as per the category choice',
      'b) Check if the quarterly flighting for each spend bucket is as per choice. No unnecessary reserves/Lean fwds',
    ],
    tableData: {
      headings: ['Description', 'Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY', 'H2_H1'],
      rows: [],
    },
  },

  data4: {
    headingData: 'The <span style=color:#0070C0>Total Spends by Channel</span> are as in line with the category "Where to Play/How to Win" choices',
    subHeadingData: [
      'a) Check if the amount of spend in each channel is as per category choice',
      'b) Check if the quarterly flighting for each category is as per choice',
      'c) In case of major changes in quarterly flighting check for spend % and VSOD changes by month',
    ],
    tableData: {
      headings: ['Description', 'Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'H1', 'H2', 'H2_H1', 'BOP_FY', 'Change_vs_Previous_F_F', 'Change_vs_Previous_F_Y'],
      rows: [],
    },
  },

  dashBoardData4: {
    headingData: 'The <span style=color:#0070C0>Total Spends by Channel </span> are as in line with the category "Where to Play/How to Win" choices',
    subHeadingData: [
      'a) Check if the amount of spend in each channel is as per category choice',
      'b) Check if the quarterly flighting for each category is as per choice',
      'c) In case of major changes in quarterly flighting check for spend % and VSOD changes by month',
    ],
    tableData: {
      headings: ['Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY', 'H1', 'H2', 'H2_H1', 'Change_vs_Previous_F_F'],
      rows: [],
    },
  },

  dashBoard2Data4: {
    headingData: 'The <span style=color:#0070C0>Total Spends by Channel</span> are as in line with the category "Where to Play/How to Win" choices',
    subHeadingData: [
      'a) Check if the amount of spend in each channel is as per category choice',
      'b) Check if the quarterly flighting for each category is as per choice',
      'c) In case of major changes in quarterly flighting check for spend % and VSOD changes by month',
    ],
    tableData: {
      headings: ['Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'H1', 'H2', 'H2_H1', 'BOP_FY', 'Previous_BOP_FY','Change_vs_Previous_F_F', 'BOP_FY_02', 'vs_FY_02', 'BOP_FY_03', 'vs_FY_03'],
      rows: [],
    },
  },

  data5: {
    headingData: 'The <span style=color:#0070C0>Total Spends by Brandform</span> are as in line with the category "Where to Play" choices',
    subHeadingData: [
      'a) Check if the amount of spend for each of the top 10 brandforms is as per category choice',
      'b) Check if the quarterly flighting for each brandform is as per design',
      'c) In case of major changes in quarterly flighting check for spend % and VSOD changes by month',
    ],
    tableData: {
      headings: ['Description', 'Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY', 'H2_H1',
        'Retailing_Crores', 'SD_M', 'RetailingSalience', 'SpendSalience', 'Delta'],
      rows: [],
    },
  },
  data6: {
    headingData: 'The <span style=color:#0070C0>TPR Spends</span> by brandform are as in line with the category "Where to Play" choices',
    subHeadingData: [
      'a) Check if the amount of spend for each of the top 10 brandforms is as per category choice',
      'b) Check if the quarterly flighting for each brandform is as per design',
      'c) In case of major changes in quarterly flighting check for spend % and VSOD changes by month',
    ],
    tableData: {
      headings: ['Description', 'Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY', 'H2_H1',
        'Retailing_Crores', 'SD_M', 'RetailingSalience', 'SpendSalience', 'Delta'],
      rows: [],
    },
  },

  data7: {
    headingData: '<span style=color:#0070C0>Channel saliences/</span>Channel Retailing IYA and  are as per expectations',
    subHeadingData: [
      'a) Check if the saliences and RLP IYA assumed for each channel is as per building blocks',
      'b) Double click in case of drastic changes in channel saliences',
      'c) Check the impact of new launches, GTR impact on channel retailing IYAs',
    ],
    tableData: {
      headings: ['Description', 'Channel', 'PY', 'BOP', 'Delta', 'IYA', 'IYADeltaVsTotal'],
      rows: [],
    },
  },

  data8: {
    headingData: '<span style=color:#0070C0>MS&P PM and MR team</span> are aligned on Retailing and spends estimates for Q0 and Q1 quarters',
    subHeadingData: [
      'a) Retailing estimates should be same for current and immediate quarter. Immediately align on one number',
      'b) Check if the assumptions on MR TFIs is the same for MS&P PM and MR Team',
      'c) MS&P TL is aligned with the TFIs mentioned by the MS&P PM',
    ],
    tableData: {
      headings: ['Description', 'Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'],
      rows: [],
    },
  },

  data9: {
    headingData: '<span style=color:#0070C0>MS&P PM and Ecommerce team</span> are aligned on Retailing and spends estimates for Q0 and Q1 quarters',
    subHeadingData: [
      'a) Retailing estimates should be same for current and immediate quarter. Immediately align on one number',
      'b) Check if the assumptions on Ecommerce TFIs is the same for MS&P PM and Ecommerce Team',
      'c) MS&P TL is aligned with the TFIs mentioned by the MS&P PM',
    ],
    tableData: {
      headings: ['Description', 'Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'],
      rows: [],
    },
  },

  data10: {
    headingData: 'Retailing estimates provided to Corporate Team should match with the TS Sheet retailing estimates',
    subHeadingData: [
      'a) PMs should provide the correct retailing estimate to corporate teams. No reserves should be kept here',
      'b) Minor differences could be there for the current quarter. No differences for outer quarters. Please adjust BOP',
    ],
    tableData: {
      headings: ['Description', 'Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'],
      rows: [],
    },
  },

  data11: {
    headingData: 'For <span style=color:#0070C0>Consumer Promotions</span>, the change in cover/depth vs last cycle is aligned with TL',
    subHeadingData: [
      'a) Check the changes in consumer promotion cover and spendsvs last cycle for the Top 5 brandforms',
    ],
    tableData: {
      headings: ['Description', 'Brandforms', 'UoM_Cover', 'Old_Cover', 'New_Cover', 'Cover_Change', 'UoM_Spend', 'Old_Spend', 'New_Spend', 'Spend_Change'],
      rows: [],
    },
  },

  data12: {
    headingData: 'For <span style=color:#0070C0>Trials</span>, the change in no of Trials, Spends vs last cycle is aligned with TL',
    subHeadingData: [
      'a) Check the changes in consumer promotion cover and spendsvs last cycle for the Top 5 brandforms',
    ],
    tableData: {
      headings: ['Description', 'Brandforms', 'UoM_Trials', 'Old_Trials', 'New_Trials', 'Trials_Change', 'UoM_Spend', 'Old_Spend', 'New_Spend', 'Spend_Change'],
      rows: [],
    },
  },

  data13: {
    headingData: 'The changes in <span style=color:#0070C0>KBD 1 TFIs</span> vs last cycle are aligned with the TL',
    subHeadingData: [
      'a) Check the changes in  cover and spends vs last cycle for the Top 8 brandforms',
    ],
    tableData: {
      headings: ['Description', 'Brandforms', 'UoM_Pieces', 'Old_SKU', 'New_SKU', 'SKU_Change', 'UoM_Spend', 'Old_Spend', 'New_Spend', 'Spend_Change'],
      rows: [],
    },
  },

  data14: {
    headingData: 'The <span style=color:#0070C0>RLP/MSU</span> for all key brandforms are accurate',
    subHeadingData: [
      'a) Check the changes in  RLP/MSU for key brandforms vs last fiscal for Top 10 Brandforms',
      'b) Some volume difference might come on account of NIT as planners don’t adjust NIT',
      'c) Volume difference should be <0.5% even after accounting for NIT',
    ],
    tableData: {
      headings: ['Description', 'Brandforms', 'UoM', 'PY_AMJ', 'JAS', 'OND', 'JFM', 'AMJ', 'JAS_Perc', 'OND_Perc', 'JFM_Perc', 'AMJ_Perc'],
      rows: [],
    },
  },

  data15: {
    headingData: 'The <span style=color:#0070C0>SDe</span> by spend type is the current best estimate',
    subHeadingData: [
      'a) Check the SDe and compare to the target',
    ],
    tableData: {
      headings: ['Metrics', 'UoM', 'BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY', 'H1', 'H2', 'H2_H1'],
      rows: [],
    },
  },
};

export const fixedWidthColumns75 = ['JAS', 'OND', 'JFM', 'AMJ', 'FY', 'H2_H1', 'AMJ_Perc', 'JFM_Perc', 'OND_Perc', 'AMJ_Perc',
  'JAS_Perc', 'SpendSalience', 'SKU_Change', 'Old_Spend', 'New_Spend', 'Spend_Change', 'Trials_Change', 'Old_Cover', 'New_Cover',
  'Cover_Change', 'UoM_Cover', 'UoM_Trials', 'UoM_Pieces', 'UoM_Spend', 'Delta'];

export const fixedWidthColumns90 = ['New_Trials', 'Old_Trials', 'Old_SKU', 'New_SKU'];

export const columnsWithNumberValue = ['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY', 'H2_H1',
  'Retailing_Crores', 'SD_M', 'IYADeltaVsTotal', 'IYA', 'PY_AMJ', 'JAS', 'OND', 'JFM', 'AMJ',
  'Old_SKU', 'New_SKU', 'SKU_Change', 'Old_Spend', 'New_Spend', 'Spend_Change', 'Old_Trials',
  'New_Trials', 'Trials_Change', 'Old_Cover', 'New_Cover', 'Cover_Change',];

  export const headingsLabels = {
    UoM_Spend: 'UoM',
    UoM_Pieces: 'UoM',
    UoM_Trials: 'UoM',
    UoM_Cover: 'UoM',
    JAS: 'JAS',
    OND: 'OND',
    JFM: 'JFM',
    channel: 'Channel',
    AMJ: 'AMJ',
    FY: 'FY',
    H2_H1: 'H2-H1',
    New_SKU: 'New <br/># Pieces',
    Old_SKU: 'Old <br/># Pieces',
  
    Retailing_Crores: 'Retailing Crores',
    SD_M: 'SD $M',
    RetailingSalience: 'Retailing Salience',
    SpendSalience: 'Spend <br/>Salience',
    PY: 'PY',
    bop: 'BOP',
    IYA: 'IYA',
    IYADeltaVsTotal: 'IYA Delta vs Total',
    Old_Cover: 'Old Cover',
    New_Cover: 'New cover',
    Cover_Change: 'Change',
    Old_Spend: 'Old <br/>Spend',
    New_Spend: 'New <br/>Spend',
    SKU_Change: 'Change',
    Spend_Change: 'Change',
    Trials_Change: 'Change',
    Old_Trials: 'Old  <br/># of Trails',
    New_Trials: 'New  <br/># of Trails',
    JAS_Perc: '% JAS',
    OND_Perc: '% OND',
    JFM_Perc: '% JFM',
    AMJ_Perc: '% AMJ',
    PY_AMJ: 'PY AMJ',
    BOP_JAS: 'JAS',
    BOP_OND: 'OND',
    BOP_JFM: 'JFM',
    BOP_AMJ: 'AMJ',
    BOP_FY: 'FY',
    Previous_BOP_FY: 'vs Last FY',
    Change_vs_Previous_F_F: 'vs Last FF',
    BOP_FY_02: 'BOP_FY_02',
    vs_FY_02: 'vs_FY_02',
    BOP_FY_03: 'BOP_FY_03',
    vs_FY_03: 'vs_FY_03'
  };
