import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import NavBar from 'components/NavBar';
import reappicon from 'images/company.svg';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadApps } from 'store/actions';
import Loading from '../components/Loading';
import { NoAppsFound } from '../components/NoAppsFound';
import { clearPages } from '../store/actions/pages';
import { MR_TITLE, OMNI_TITLE } from "../components/customPages/spendplan/spendplanUtils";


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const useStyles = makeStyles(theme => ({

}));

const AppDashboard = props => {
	const classes = useStyles();
	const [apps, setApps] = useState({ ...props.app });
	let { user } = props;

	useEffect(() => {
		if(!props.app?.length){
			props.loadApps(user.orgId);
		}
		props.clearPages()
	}, []);

	useEffect(() => {
		setApps(props.app);
	},[props.app]);

	return (
		<React.Fragment>
			{/* <NavBar/> */}
			<NavBar user={user} />
			<div className="app-spendplan--wrapper--body">
				<div className="app-spendplan--wrapper--content">
					<Grid item xs={12} className="mb-20-p">
						<Typography variant="h4" className="black-text">{"Applications"}</Typography>
					</Grid>
					<Grid container spacing={1}>
						{apps && apps.length > 0 && (
							apps.map(app => (
								<Grid item xs={3} key={app._id}>
									<Card className={classNames(classes.card, 'mx-auto m-16 md:m-0', 'app--dashboard--cards')}>
										<CardContent className={[classes.content, "app--dashboard--cards--content"]}>
											<h5 className="app--dashboard--cards--content--main-heading">{app.title === MR_TITLE ? OMNI_TITLE : app.title}</h5>
											<h6 className="app--dashboard--cards--content--status">{app.status}</h6>
										</CardContent>
										{/* <hr className={classes.hr1} /> */}
										<CardHeader title={app.subTitle} className={"app--dashboard--cards--content--title--heading card-heading-title-dashboard"} />
										<div className={classNames(classes.details)}>
											<div className={[classes.column, "app--dashboard--cards--content--columns"]}>
												<div className={"app--dashboard--cards--content--columns--column--one"}>
													<img src={(app.logoId) ? `${API_BASE_URL}/logo/${app.logoId}` : reappicon} alt="" width="100%" />
												</div>
												<div className={"app--dashboard--cards--content--columns--column--two app--dashboard--cards--content--main--content"}>{app.description}</div>
											</div>
											<div className={"app--dashboard--cards--content--footer"}>
												<Link className="custom--card--link" to={'/apps/' + app._id}>
													<span className={"m-all-10"}>View</span>
												</Link>
												<Link to={'/builder/dapps/' + app._id} target="_blank">
													<span className={"m-all-10"}>Build</span>
												</Link>
													</div>
										</div>
									</Card>
								</Grid>
							))
						)}
						{ props.loading && <Loading /> }
					</Grid>
				</div>
			</div>
			{
				(!props.loading && !Boolean(props.app?.length)) && <NoAppsFound />
			}

		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		app: state.app.app,
		loading: state.app.loading,
		error: state.app.error,
		user: state.user
	};
};

const mapDispatchToProps = {
	loadApps,
	clearPages,
};

export default hot(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(AppDashboard),
);
