import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import TradePriceReduction from '../PlanningPages/tpr';
import ConsumerPromotions from '../PlanningPages/ConsumerPromotions';
import TrialsDiscounts from '../PlanningPages/TrialsDiscounts';
import Displays from '../PlanningPages/Displays';
import ConversionFunds from '../PlanningPages/ConversionFunds';
import MrAndEcommTFIs from '../PlanningPages/MrAndEcommTFIs';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Slide,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import Header from '../SpendPlan/Header';
import classNames from 'classnames';
import { getFiscalYearFormat } from "../spendplanUtils";

const useStyles = makeStyles(theme => ({
  confirmationDialog: {
    '& .MuiDialog-paperWidthSm': {
      height: '80vh',
      maxWidth: '100%',
      width: '90%',
    },
    '& .setup-data-headings': {
      fontSize: '25px !important',
      fontWeight: 'bold',
      lineHeight: '1',
      color: '#000',
    },
    '& .MuiTab-root': {
      minWidth: 'auto !important',
    },
  },
  minus30BottomMargin: {
    marginBottom: -30
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PlanningDialog = props => {
  const { open, handleClose, pages } = props;
  const modalData = props.modalData ? props.modalData : '';
  const [planningPage, setPlanningPages] = React.useState('');
  const [value, setValue] = React.useState(0);

  const classes = useStyles();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    let setupPagesList = [];
    pages.map(p => {
      if (p.name === 'Trade Price Reduction') {
        setupPagesList[0] = p;
      } else if (p.name === 'Consumer Promotions') {
        setupPagesList[1] = p;
      } else if (p.name === 'Trial Discounts') {
        setupPagesList[2] = p;
      } else if (p.name === 'Displays') {
        setupPagesList[3] = p;
      } else if (p.name === 'Conversion Funds') {
        setupPagesList[4] = p;
      } else if (p.name === 'MR and Ecomm TFIs') {
        setupPagesList[5] = p;
      }
    });
    setPlanningPages(setupPagesList);
  }, [pages]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      disableEnforceFocus
      onClose={handleClose}
      disableBackdropClick={true}
      // disableEscapeKeyDown={true}
      aria-labelledby="Talk Sheet"
      aria-describedby="Talk Sheet Table"
      className={classNames('app-spendplan', 'spendplan-tpr', classes.confirmationDialog)}
    >
      <DialogContent>
        {modalData ? (
          <Header header={`Planning - Version - ${modalData.monthName} ${getFiscalYearFormat(modalData.year)}`}/>
        ) : null}
        <AppBar position="static">
          <Tabs value={value} onChange={(e, v) => setValue(v)} variant='scrollable' aria-label="simple tabs example">
            <Tab label="Trade Price Reduction" {...a11yProps(0)} />
            <Tab label="Consumer Promotions" {...a11yProps(1)} />
            <Tab label="Trial Discounts" {...a11yProps(2)} />
            <Tab label="Displays" {...a11yProps(3)} />
            <Tab label="Conversion Funds" {...a11yProps(4)} />
            <Tab label="MR and Ecomm TFIs" {...a11yProps(5)} />
          </Tabs>
        </AppBar>
        <div className={classes.minus30BottomMargin} />
        <TabPanel value={value} index={0}>
          <TradePriceReduction modalData={modalData} pages={pages} page={planningPage[0]}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ConsumerPromotions modalData={modalData} pages={pages} page={planningPage[1]}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TrialsDiscounts modalData={modalData} pages={pages} page={planningPage[2]}/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Displays modalData={modalData} pages={pages} page={planningPage[3]}/>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ConversionFunds modalData={modalData} pages={pages} page={planningPage[4]}/>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <MrAndEcommTFIs modalData={modalData} pages={pages} page={planningPage[5]}/>
        </TabPanel>

      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
          className="btn btn-primary btn-color border-radius-0 text-color-white"
        >
          {'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanningDialog)));
