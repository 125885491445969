import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { AppService } from 'modules/api/index';
import { isJSON } from "utility/utils"
import { withRouter } from "react-router";

import { getPageUrl } from 'modules/page/common'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles({
	card: {
		// minHeight: '27.5vh',
		// height: '27.5vh',
		height: '100%',
		backgroundColor: 'rgb(34, 34, 34)',
		color: '#fff'
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 14,
		textAlign: 'center',
		backgroundColor: '#222',
		color: '#fff'
	},
	pos: {
		marginBottom: 12,
	},
});
let d = {
	title: 'Shrimp and Chorizo Paella',
	subheader: 'September 14, 2016',
	body: 'This is simple body, It can be multiple lines.',
	footer: 'This is footer text',
};
const SimpleCard = props => {
	const [data, setData] = useState(null);
	const [style, setStyle] = useState({});
	const [chartClassName, setChartClassName] = useState("");
	const [linkURL, setLinkURL] = useState('')
	const classes = useStyles();

	const setDatafromApi = (res, data) => {
		let d = { ...data };
		let { chartData } = props;

		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			console.log("res ===", res);
			if (chartData.isCardCategories
				&& chartData.categories && chartData.categories.length) {
				d.categoriesData = res;
			} else if (chartData.useGrouping && (chartData.selectedGroupingDataElement.length > 0
				|| (chartData.selectedGroupingDataElement && chartData.selectedGroupingDataElement.value))) {
					console.log("chartData", chartData);
				chartData.groupingData = res.data;
			} else {
				d.title = chartData.selectedDataElement[0].label;
				d.body = res[chartData.metric];
			}
		} else {
			d.body = res.body;
			d.title = res.title;
		}
		setData(d);
	}

	const getApiDataFromDataUrl = (url, data) => {
		AppService.getApiDataFromDataUrl(url, props.appId)
			.then(res => {
				setDatafromApi(res, data)
			})
	}

	const generateCardBody = (metric, data) => {
		console.log("data card", data);
		if (data && metric)
			if (metric === "count") {
				return data.length;
			} else if (metric === "total") {
				return data.reduce((acc, item) => {
					return acc + item;
				}, 0);
			} else if (metric === "average") {
				let sum = data.reduce((acc, item) => {
					return acc + item;
				}, 0);
				return sum / data.length;
			} else if (metric === "max") {
				return Math.max(...data.map(Number));
			} else if (metric === "min") {
				return Math.min(...data.map(Number));
			} else {
				return data[0];
			}
	}

	useEffect(() => {
		let s = props.data && isJSON(props.data.style)
			? { ...style, ...(JSON.parse(props.data.style)) } : { ...style };
		let chartClassName = props.data && props.data.className;
		setStyle(s);
		setChartClassName(chartClassName);
	}, [props.data.style, props.data.className]);

	useEffect(() => {
		let { chartData } = props;

		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + "/dataconnect/" + chartData.selectedDataModel;
			targetURL += `?type=card`

			if (chartData.isCardCategories) {
				if (chartData.categories && chartData.categories.length > 0) {
					let cate = JSON.stringify(chartData.categories);
					targetURL += "&categories=" + cate;
				} else {
					console.error("Categories are madatory")
				}
			} else {
				targetURL += `&field=${chartData.selectedDataElement[0].value}`;
				targetURL += "&metric=" + chartData.metric;
				if (chartData.useGrouping && chartData.selectedGroupingDataElement && chartData.selectedGroupingDataElement.length > 0)
					targetURL += "&groupBy=" + chartData.selectedGroupingDataElement[0].value;
			}

			// `&metric=${chartData.metric}`;
			// const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			// targetURL += chartData.selectedDataElement.reduce(reducer, '&field=');
			//targetURL += '&metric='+ chartData.metric;
			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (props.page && props.page.conditions && props.page.conditions.length > 0) {
				let cond = JSON.stringify(props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
      let encodedData = encodeURIComponent(targetURL)
      let _targetUrl = JSON.stringify(encodedData);
			getApiDataFromDataUrl(_targetUrl, props.data);
		} else if (props.dataUrl) {
			getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			let d = { ...props.data }
			d.body = generateCardBody(d.metric, d.data)
			setData(d);
		}
	}, [])
	useEffect(() => {
		let { chartData } = props;
		if (props.chartData.cardLinking === "custom_link") {
			let { selectedLinkType, selectedLinkDataModel, selectedLinkValue } = props.chartData.linkCard;
			let url, search = '';
			if (selectedLinkType === "page") {
				let pageUrl = getPageUrl(selectedLinkValue, props.page);
				if (pageUrl) {
					url = `/apps/${props.appId}/${pageUrl}`
					search += `?dm=${chartData.selectedDataModel}`
					// search += `&k=${chartData.selectedDataElement[0].value}`
					// search += `&v=${event.point.name}`
				}
			} else {
				if (selectedDataModel) {
					url = `/apps/${props.appId}/form/${selectedLinkValue}/new`
					search += `?dm=${chartData.selectedDataModel}`
					// search += `&k=${chartData.selectedDataElement[0].value}`
					// search += `&v=${event.point.name}`
				}
			}
			if (url) {
				search += `&pid=${props.page._id}&cid=${props._id}`
				setLinkURL(url + search)
			}
			else
				alert(`Please provide valid links for column ${l.selectedLinkDataElement}`)
		} else {
			setLinkURL(props.chartData.linkURL ? props.chartData.linkURL : "")
		}
	})

	const numFormatter = (num) => {
		return (Math.abs(num) > 999999
			? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + 'M'
			: (Math.abs(num) > 999
				? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K'
				: Math.sign(num) * Math.abs(num)))
	}

	return (
		<React.Fragment>
			{data ? (
				<Card className={classes.card + ' ' + chartClassName} style={style}>
					<CardHeader className={classes.title} title={data.name} subheader={data.subheader} />
					<CardContent>
						{data.isCardCategories && data.categories && data.categories.length > 0
							? <> {data.categoriesData.length === 1
								? <Typography gutterBottom variant="h5" component="h2"
									style={{ textAlign: "center", verticalAlign: "center" }}>
									<>{data.categories[0].title}</>
									<br></br>
									<>{numFormatter(data.categoriesData[0].value)}</>
								</Typography>
								: <>
									<Typography gutterBottom variant="h5"
										style={{
											textAlign: "center",
											fontSize: "2.0em",
											fontWeight: "bold"
										}}>
										<>{data.categories[0].title}</>
										<br></br>
										<>{numFormatter(data.categoriesData[0].value)}</>
									</Typography>
									<Divider variant="middle" style={{
										marginTop: "20px",
										marginBottom: "20px",
										border: 0,
										borderTop: "1px solid #282828"
									}} />
									<Typography gutterBottom variant="h5"
										style={{
											textAlign: "center",
											fontSize: "2.0em",
											fontWeight: "bold"
										}}>
										<>{data.categories[1].title}</>
										<br></br>
										<>{numFormatter(data.categoriesData[1].value)}</>
									</Typography>
								</>
							}</>
							: <>
								{data.dataOrHTML && data.dataHandler === "data_html"
									? <div dangerouslySetInnerHTML={{
										__html: data.dataOrHTML
									}} />
									: <Typography gutterBottom variant="h5" component="h2"
										style={{ textAlign: "center", fontSize: 60 }}>
										{linkURL
											? <Link href={linkURL}>{numFormatter(data.body)}</Link>
											: numFormatter(data.body)
										}
									</Typography>
								}
							</>
						}
						<Typography variant="body2" component="p" style={{ textAlign: "center", fontSize: 20 }}>
							{data.description}
						</Typography>
					</CardContent>
				</Card>
			) : (
					''
				)}
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		page: state.pages.page,
	};
};

export default withRouter(connect(mapStateToProps, null)(SimpleCard));
