import React, { useEffect, useState } from 'react';

const footerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: "center",
  padding: "10px",
  backgroundColor: "#1B2A47",
  position: "fixed",
  width: "100%",
  bottom: "0",
  zIndex: "1201"
};

const paragraph = {
  color: "#A1ABB7",
  textAlign: "center",
  margin: "0px"
}

const version = {
  color: "#A1ABB7",
  textAlign: "end",
  margin: "0px"
}

const Footer = (props) => {

  const [versionInfo, setVersionInfo] = useState(null)

  useEffect(() => {
    getMetaJSONData()
  }, [])

  const getMetaJSONData = () => {
    fetch('/meta.json')
      .then((response) => {
        console.log('response', response);
        return response.json()
      })
      .then((meta) => {
        setVersionInfo(meta)
      }).catch(e => {
        console.log(e)
      })
  }

  const getBuildVersion = () => {
    return versionInfo ? ((versionInfo.VERSION || versionInfo.version) + (versionInfo['BUILD NUMBER'] ? '.' : '') + (versionInfo['BUILD NUMBER'] || '')) : '-'
  }

  return (
    <main>
      <div style={footerStyle} className="footer-copyright">
        <p style={paragraph}></p>
        <p style={paragraph}>Copyrights to DSilo, Inc</p>
        <p style={version}>Version - {getBuildVersion()}</p>
      </div>
    </main>
  )
};

export default Footer;
