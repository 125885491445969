import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StackedChart from '../../../../components/highcharts/stackedChart';
import { getMrData, removeMRSuccessMessage } from '../../../../store/actions';
import { capitalize, getMonth } from '../../../../utility/utils';
import Loader from '../../../Loader';
import PlanningErrorModal from '../../spendplan/PlanningPages/planningErrorModal';
import Header from '../../spendplan/SpendPlan/Header';
import { URLs } from '../ApiConstants';
import CockpitTable from './cockpittable';
import { colors, selectComponent, useStyles } from './index';

const tableConfig1 = [
    {
        headingData: 'Totals - Retailing, Spend and Earnings',
        subHeadings: [],
        headings: [
            { title: 'Brand', key: 'brand', leftAlign: true, isString: true },
            { title: 'Brandform', key: 'brandform', leftAlign: true, isString: true },
            { title: 'FY', key: 'fyVsod', isPercent: true },
            { title: 'LY', key: 'actualFF' },
            { key: 'forecastQtr.JAS', title: 'JAS', isPercent: true },
            { key: 'forecastQtr.OND', title: 'OND', isPercent: true },
            { key: 'forecastQtr.JFM', title: 'JFM', isPercent: true },
            { key: 'forecastQtr.AMJ', title: 'AMJ', isPercent: true },
        ]
    }
]


const VSOD = (props) => {

    const [brand, setBrand] = useState("All Brands");
    const [open, setOpen] = useState(false);
    const [alertMessageText, setAlertMessage] = React.useState("");

    const classes = useStyles();

    useEffect(() => {
        fetchData()
        getDataFromSKUMasterDataForDropdown()
    }, [props.account])

    const fetchData = () => {
        const account = props.account;
        if (account) {
            let params = {
                fiscalYear: account.FinancialYear,
                account: encodeURIComponent(account.AccountName),
            };
            let m = '' + getMonth(account.CurrentFF, 'monthNumber')
            params.currentFF = (m.length === 1 ? '0' + m : m);
            let url = URLs.cockpitVsod;
            url += `?account=${params.account}&fiscalYear=${params.fiscalYear}&currentFF=${params.currentFF}`
            let payload = {
                url: url,
                appId: props.match.params.appid,
                dataUniqueId: 'GET_VSODData'
            }
            props.getMrData(payload)
        }
    };

    const getDataFromSKUMasterDataForDropdown = () => {
        let dataModelId = '617f826eb336d60019d00226'; // TODO: need to make it configurable
        if (props.account) {
            let targetURL = "/dataconnect/filter/" + dataModelId;
            targetURL += `?type=chart`;
            targetURL += '&label=brand';
            let payload = {
                url: targetURL,
                appId: props.match.params.appid,
                dataUniqueId: 'spendReviewDropdown'
            }
            props.getMrData(payload)
        }
    }


    useEffect(() => {
        if (props.mrData.error && props.mrData.error['GET_VSODData']) {
            setOpen(true)
            setAlertMessage({
                title: 'VSOD',
                p1: props.mrData.error['GET_VSODData']?.message || 'Something went wrong',
            });
        }
    }, [props.mrData.error]);

    const getData = () => {
        if (props.data && props.data['GET_VSODData']) {
            return props.data['GET_VSODData']?.result;
        }
    }

    const handleClose = () => {
        setOpen(false);
        props.removeMRSuccessMessage({ dataUniqueId: 'GET_VSODData' });
    };

    const getNonPromoData = (_brand) => {
        if ((_brand || brand) === 'All Brands') {
            return getData()?.nofilterChart ? Object.values(getData()?.nofilterChart).map(i => i.NonPromo || 0) : []
        }
        return getData()?.brandWiseData ? Object.values(getData()?.brandWiseData[(_brand || brand)]).map(i => i.nonPromo || 0) : []
    }

    const getPromoData = (_brand) => {
        if ((_brand || brand) === 'All Brands') {
            return getData()?.nofilterChart ? Object.values(getData()?.nofilterChart).map(i => i.Promo || 0) : []
        }
        return getData()?.brandWiseData ? Object.values(getData()?.brandWiseData[(_brand || brand)]).map(i => i.Promo || 0) : []
    }

    const getXAxisValues = (_brand) => {
        if ((_brand || brand) === 'All Brands') {
            return (getData()?.nofilterChart && getData()?.nofilterChart) ? Object.keys(getData()?.nofilterChart) : []
        } else {
            return (getData()?.brandWiseData && getData()?.brandWiseData[(_brand || brand)]) ? Object.keys(getData()?.brandWiseData[(_brand || brand)]) : []
        }
    }

    const getChartOptions = (_brand) => {
        return {
            credits: {
                enabled: false
            },
            title: {
                text: null,
            },
            xAxis: {
                categories: getXAxisValues(_brand).map(i => capitalize(i))
            },
            yAxis: {
                min: 0,
                title: {
                    text: undefined
                }
            },
            legend: {
                reversed: true
            },
            colors: colors,
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [
                {
                    name: 'Non Promo',
                    data: getNonPromoData(_brand)
                },
                {
                    name: 'Promo',
                    data: getPromoData(_brand)
                },
            ]
        }
    }

    const [chartOptions, setChartOptions] = useState(getChartOptions())

    const onBrandChange = (v) => {
        if (v !== brand) {
            setBrand(v)
        }
        setChartOptions({ ...getChartOptions(v) })
    }

    const getTableData = () => {
        let dd = getData()
        if (dd) {
            let _data = JSON.parse(JSON.stringify(dd))
            if (brand === 'All Brands') {
                return (_data?.noFilterTable || []).sort((a, b) => (a.fyVsod <= b.fyVsod) ? 1 : -1).slice(0, 10)
            } else {
                return (_data?.brandWiseTableData && _data?.brandWiseTableData[brand]) ? ((_data?.brandWiseTableData[brand] || []).sort((a, b) => (a.fyVsod <= b.fyVsod) ? 1 : -1).slice(0, 10)) : []

            }
        }
        return []
    }

    const getFilterData = () => {
        if (props.data && props.data['spendReviewDropdown']) {
            return props.data['spendReviewDropdown'] || []
        }
        return []
    }


    if (props.mrData && props.mrData['GET_VSODData' + '_loading']) {
        return (
            <section className={classes.tableBodyLoading}>
                <Loader />
            </section>
        )
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Header header={`VSOD`} />
                <div style={{ display: 'flex' }}>
                    <Button
                        style={{ width: 240 }}
                        variant="contained"
                        onClick={() => {
                            props.history.push({
                                pathname: `/apps/${props.match.params.appid}/RetailingSummary`
                            });
                        }}
                        startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} />}
                        color="primary">Back to Retailing Summary</Button>
                    <Button
                        style={{ marginLeft: 10, width: 200 }}
                        variant="contained"
                        onClick={() => {
                            props.history.push({
                                pathname: `/apps/${props.match.params.appid}/Dashboard2`
                            });
                        }}
                        startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} />}
                        color="primary">Back to Cockpit</Button>
                </div>
            </div>
            {selectComponent(['All Brands', ...(getFilterData()?.brand || [])], brand, onBrandChange)}
            <div style={{ width: '100%', }}>
                <h6 style={{ color: 'black', textAlign: 'center', margin: 10, }}>VSOD</h6>
                <StackedChart width={800} options={brand === 'All Brands' ? getChartOptions() : chartOptions} />
            </div>
            <h6 style={{ color: 'black', margin: 10, }}>Top 10 brandform by VSOD  {brand === 'All Brands' ? '' : ('- ' + brand)}</h6>
            <CockpitTable data={getTableData()} tableConfig={tableConfig1} />
            <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} />
        </div>
    )
}

const mapDispatchToProps = {
    getMrData,
    removeMRSuccessMessage,
};

const mapStateToProps = state => {
    return {
        data: state.mrReducer?.data,
        mrData: state.mrReducer,
        account: state.mrReducer?.account,
    };
};

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(VSOD)));