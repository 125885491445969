import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { AppService } from 'modules/api/index';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const legendOpts = {
	display: true,
	position: 'top',
	fullWidth: true,
	reverse: false,
	labels: {
		fontColor: 'rgb(255, 99, 65)'
	}
};

const DoughnutChart = (props) => {
	const [data, setData] = useState({});
	const [legend, setLegend] = useState(legendOpts);

	const [options, setOptions] = useState({
		responsive: true,
		maintainAspectRatio: true
	});

	const setDatafromApi = (res, data) => {
		let d = { ...data };
		let { chartData } = props; console.log('data', data, res);

		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			d.labels = res.labels;
			d.datasets[0].data = res.data;
			d.datasets[0].label = res.label;
		} else {
			d.labels = res.data.labels;
			d.datasets[0].data = res.data.datasets[0].data;
			d.datasets[0].label = res.data.datasets[0].label;
		}
		setData(d);
	}

	const getApiDataFromDataUrl = (url, data) => {
		AppService.getApiDataFromDataUrl(url)
			.then(res => {
				setDatafromApi(res, data)
			})
	}

	useEffect(() => {
		setData(props.data);
	}, [props.data]);

	useEffect(() => {
		setLegend(props.legendOpts);
	}, [props.legendOpts]);

	useEffect(() => {
		setOptions(props.options);
	}, [props.options]);

	useEffect(() => {
		let { chartData } = props;
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + "/dataconnect/chart/" + chartData.selectedDataModel + "?";
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
			// targetURL += chartData.selectedDataElement.reduce(reducer, '?q=');
			targetURL += chartData.selectedDataElement.reduce(reducer, '&measure=');
			targetURL += chartData.selectedDataLabels.reduce(reducer, '&label=');
			if (props.queryString) {
				targetURL += '&' + props.queryString;
			}
			//labeltype=date&format=%Y-%m
			if (chartData.datelabeltype) {
				targetURL += '&labeltype=date'
				targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
			}

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
            if (props.page.conditions && props.page.conditions.length > 0) {
                let cond = JSON.stringify(props.page.conditions);
                targetURL += "&pConditions=" + cond;
            }
      let encodedData = encodeURIComponent(targetURL)
      let _targetUrl = JSON.stringify(encodedData);
			getApiDataFromDataUrl(_targetUrl, props.data);
		} else if (props.dataUrl) {
			getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			setData(props.data);
		}
	}, [])

	return (
		<div>
			{data ?
				<div>
					<div>{data.title}</div>
					<Doughnut data={data} legend={legend} options={options} />
				</div>
				: ''
			}
		</div>
	)
};

export default DoughnutChart;