import { ActionTypes } from 'constants/index';

const initialState = {
    success: false,
    isLoading: false,
    error: {
        username: null,
        password: null
    },
    token: '',
    reset: {
        isLoading: false,
        success: false,
        user: {},
        error: {}
    },
    loading: false,
    isUserExist: null,
    orgNotFound: null,
};

const login = function (state = initialState, action) {

    switch (action.type) {
        case ActionTypes.LOGIN_LOADING: {
            return {
                ...state,
                isLoading: true,
                error: ''
            };
        }
        case ActionTypes.LOGIN_SUCCESS:
            {
                return {
                    ...initialState,
                    success: true,
                    isLoading: false
                };
            }
        case ActionTypes.LOGIN_ERROR:
            {
                return {
                    success: false,
                    error: action.error,
                    isLoading: false
                };
            }
        case ActionTypes.RESET_PASSWORD_LOADING: {
            return {
                ...state,
                reset: {
                    isLoading: true,
                }
            };
        }
        case ActionTypes.RESET_PASSWORD_SUCCESS:
            {
                return {
                    ...state,
                    reset: {
                        isLoading: false,
                        success: true,
                        user: action.payload
                    }
                };
            }
        case ActionTypes.RESET_PASSWORD_ERROR:
            {
                return {
                    ...state,
                    reset: {
                        isLoading: false,
                        success: false,
                        error: action.payload
                    }
                };
            }
        case ActionTypes.IS_USER_EXIST_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        case ActionTypes.IS_USER_EXIST_SUCCESS:
            {
                return {
                    ...state,
                    loading: false,
                    isUserExist: action.payload,
                    orgNotFound: null,
                };
            }
        case ActionTypes.IS_USER_EXIST_FAILURE:
            {
                return {
                    ...state,
                    loading: false,
                    orgNotFound: action.error,
                    isUserExist: null,
                };
            }
        default:
            {
                return state
            }
    }
};

export default login;