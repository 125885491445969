import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { AppService } from 'modules/api/index';
// import Highcharts from 'highcharts';
import Highcharts from '../HighchartsWrapper'
import {
	getLineChartConfig,
	getDefaultLineChart,
	generateLineData
} from 'modules/page/highcharts/line.module';
import {
	generateGUID
} from 'modules/page/page.module';

import {
	getTheme
} from 'modules/page/component.module';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isSpendplan = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'spendplan';

const LineChart = (props) => {
	const [options, setOptions] = useState(getDefaultLineChart());
	const [id, setId] = useState(`line-${generateGUID()}`)

	const highChartsRender = () => {
		let lineConfig = getLineChartConfig(options, id);
		if(!isSpendplan){
			Highcharts.theme = getTheme();
			// Apply the theme
			Highcharts.setOptions(Highcharts.theme);
		}
		Highcharts.chart(lineConfig);
	}

	const setDatafromApi = (res, data) => {
		let d = { ...data };
		let chartData = { ...props.chartData };

		let labels = [], dArray = [], label = '';
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			d.labels = res.labels;
			if (res.datasets) {
				d.datasets = res.datasets.map((ds, i) => {
					if (!d.datasets)
						d.datasets = []
					if (!d.datasets[i])
						d.datasets[i] = {};
					return { ...d.datasets[i].data, ...ds };
				})
			} else {
				if (!d.datasets || (d.datasets && d.datasets.length > 0))
					d.datasets = [];
				d.datasets[0] = { label: res.label, data: res.data }
			}
		} else {
			d.labels = res.labels;
			d.datasets = res.datasets;
		}
		chartData.data = d;
		let ops = generateLineData(chartData, options)
		setOptions({ ...options, ...ops });
	}

	const getApiDataFromDataUrl = (url, data) => {
		AppService.getApiDataFromDataUrl(url, props.appId)
			.then(res => {
				setDatafromApi(res, data)
			})
	}

	// useEffect(() => {
	//     setData(props.data);
	// }, [props.data]);

	// useEffect(() => {
	//     setOptions(props.options);
	// }, [props.options]);


	useEffect(() => {
		highChartsRender();
	}, [options]);

	useEffect(() => {
		let { chartData } = props;
		if (chartData.selectedDataModel && chartData.selectedDataElement) {
			let targetURL = API_BASE_URL + `/dataconnect/${chartData.isGrouping ? 'bar' : 'line'}/` + chartData.selectedDataModel + "?";
			const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';

			if (chartData.isGrouping) {
				targetURL += `&barXAxis=${chartData.selectedDataLabels[0].value}`;
				targetURL += `&barYAxis=${chartData.selectedDataElement[0].value}`;
			} else {
				targetURL += chartData.selectedDataElement.reduce(reducer, '&measure=');
				targetURL += chartData.selectedDataLabels.reduce(reducer, '&label=');
			}
			targetURL += '&metric=' + chartData.metric;
			if (props.queryString) {
				targetURL += '&' + props.queryString;
			}
			if (chartData.datelabeltype) {
				targetURL += '&labeltype=date'
				targetURL += chartData.labelformat ? `&format=${chartData.labelformat}` : '&format=%Y-%m-%d';
			}

			if (chartData.conditions && chartData.conditions.length > 0) {
				let cond = JSON.stringify(chartData.conditions);
				targetURL += "&conditions=" + cond;
			}
			if (props.page && props.page.conditions && props.page.conditions.length > 0) {
				let cond = JSON.stringify(props.page.conditions);
				targetURL += "&pConditions=" + cond;
			}
      let encodedData = encodeURIComponent(targetURL)
      let _targetUrl = JSON.stringify(encodedData);
			getApiDataFromDataUrl(_targetUrl, props.data);
		} else if (props.dataUrl) {
			getApiDataFromDataUrl(props.dataUrl, props.data);
		} else {
			let ops = generateLineData(props, options)
			setOptions({ ...options, ...ops });
		}
	}, [])

	return (
		id && <div id={id} className="chartEqualHeight"></div>
	)
}
export default LineChart;