

import { hot } from 'react-hot-loader/root'
import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
    Button, Select, MenuItem, Slide, TextField, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel
} from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ReplaceValuesPopup = (props) => {
    let { open, handleClose, alertMessageText, classes, confirm, type } = props
    const [allFieldValues, setAllFieldValues] = useState([]);
    const [fieldValue, setFieldValue] = useState('');
    const [oldValue, setOldValue] = useState('');
    const [newValue, setNewValue] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        setAllFieldValues([
            { label: 'Legal Entity', value: 'legalEntity' },
            { label: 'Category', value: 'category' },
            { label: 'Brand', value: 'brand' },
            { label: 'TS Brand', value: 'tsBrand' },
            { label: 'Brandform', value: 'brandform' },
            { label: 'TS SKU', value: 'tsSku' },
            { label: 'Sub Brandform', value: 'subBrandForm' },
        ])
    }, [])

    const onEventChange = (event, val) => {
        switch (event) {
            case 'field': {
                setFieldValue(val.value);
                break;
            }
            case 'oldValue': {
                setOldValue(val.value);
                break;
            }
            case 'newValue': {
                setNewValue(val.value);
                break;
            }
        }
    }

    const closePopup = (val) => {
        if (val) {
            setFieldValue('');
            setOldValue('');
            setNewValue('');
            setMessage('');
            handleClose(true)
        } else {
            if (fieldValue === '' && oldValue === '' && newValue === '') {
                setMessage('Please add all the fields');
            } else {
                setMessage('');
                handleClose(false, { fieldValue, oldValue, newValue })
            }
        }
    }

    return (<>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.confirmationDialoge}
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#047dd8" }}>
                {typeof alertMessageText == 'object' && alertMessageText?.title ? alertMessageText.title : "Confirmation"}
            </DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText component="div" id="alert-dialog-slide-description" className={classes.para}>
                    <form noValidate>
                        <FormControl>
                            <FormLabel variant="h6" className="label-headings" htmlFor="email">
                                Field
                            </FormLabel>
                            <Select
                                value={fieldValue}
                                className="custom-select fontWhite"
                                placeholder="Select Field"
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                    },
                                }}
                                onChange={e => onEventChange('field', e.target)}>
                                 {React.Children.toArray(
                                    allFieldValues?.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
                                )}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel variant="h6" className="label-headings" htmlFor="email">
                                Old Value
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                type="text"
                                id="oldvalue"
                                name="oldvalue"
                                placeholder="Enter Old Value"
                                autoFocus
                                value={oldValue}
                                onChange={e => onEventChange('oldValue', e.target)}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel variant="h6" className="label-headings" htmlFor="email">
                                New Value
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                type="text"
                                id="newValue"
                                name="newValue"
                                placeholder="Enter New Value"
                                autoFocus
                                value={newValue}
                                onChange={e => onEventChange('newValue', e.target)}
                            />
                        </FormControl>
                    </form>
                    {message !== '' && <div style={{ margin: '10px', fontSize: '11px', color: 'red' }}>
                        {message}
                    </div>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    <React.Fragment>
                        <Button
                            style={{ textTransform: 'none' }}
                            color="primary" onClick={() => {
                                closePopup(false)
                            }}
                            className="btn btn-primary btn-color border-radius-0 text-color-white">
                            {"Replace"}
                        </Button>
                        <Button
                            style={{ textTransform: 'none' }}
                            color="primary" onClick={() => closePopup(true)}
                            className="btn btn-primary btn-color border-radius-0 text-color-white">
                            {"Cancel"}
                        </Button>
                    </React.Fragment>
                }
            </DialogActions>
        </Dialog>

    </>)
}

const mapStateToProps = state => {
    return {
        user: state.user,
        pageLoading: state.pages.loading,
        member: state.appConfig.member,
        memberLoading: state.appConfig.memberLoading,
        entity: state.appConfig.entity ? state.appConfig.entity : {}
    };
};
export default hot(withRouter(connect(mapStateToProps, null)(ReplaceValuesPopup)));