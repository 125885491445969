const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

import { request } from '../../../client';

export class ConversionFundsService {
  static getConversionFunds(data) {
    let uri = API_BASE_URL + `/conversionFunds/brand/${data.brand}?page=${data.page}&rowsPerPage=${data.rowsPerPage}`;
    if (data.brandform?.length) {
      uri = `${uri}${`&brandform=${encodeURIComponent(JSON.stringify(data.brandform))}`}`;
    }
    if (data.cluster?.length) {
      uri = `${uri}${`&cluster=${encodeURIComponent(JSON.stringify(data.cluster))}`}`;
    }
    if (data.month?.length) {
      uri = `${uri}${`&month=${encodeURIComponent(JSON.stringify(data.month))}`}`;
    }
    if (data.quarter?.length) {
      uri = `${uri}${`&quarter=${encodeURIComponent(JSON.stringify(data.quarter))}`}`;
    }
    if (data.currentFF) {
      uri = `${uri}${`&currentFF=${encodeURIComponent(data.currentFF)}`}`;
    }
    if (data.fiscalYear) {
      uri = `${uri}${`&fiscalYear=${encodeURIComponent(data.fiscalYear)}`}`;
    }
    if (data?.scenario) {
      uri = `${uri}${`&scenario=${encodeURIComponent(data.scenario)}`}`;
    }
   	if (data.planName?.length) {
			uri = `${uri}${`&conversionFundsDescription=${encodeURIComponent(JSON.stringify(data.planName))}`}`;
		}

    return request(uri, {
      method: 'GET',
      headers: {
        appId: data.appId
      }
    });
  }

  static addNewConversionFunds = (data) => {
    const uri = API_BASE_URL + "/conversionFunds";
    return request(uri, {
      method: 'POST',
      payload: data.row,
      headers: {
        appId: data.appid
      }
    });
  }

  static deleteCheckedConversionFunds(data) {
    const uri = API_BASE_URL + "/conversionFunds";
    return request(uri, {
      method: 'DELETE',
      payload: { conversionFundsIds: data.conversionFundsIds ,...data.commonObj},
      headers: {
        appId: data.appid
      }
    });
  }

  static getConversionFundsExtraData(data) {
    const uri = `${API_BASE_URL}/conversionFunds/calculationData/${data.dataModalId}`;
    return request(uri, {
      method: 'POST',
      payload: data.payload,
      headers: {
        appId: data.appid
      }
    });
  }

  static getPlans(data) {
		let { brand, fiscalYear, currentFF, appid, url, scenario } = data
		const uri = API_BASE_URL + `/${url}/plans/${brand}?fiscalYear=${fiscalYear}&currentFF=${currentFF}&scenario=${scenario}`;
		return request(uri, {
			method: 'GET',
			headers: {
				appId: appid
			}
		});
	}
}
