

import { hot } from 'react-hot-loader/root'
import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
    Button, Slide, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,  Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Paper, TablePagination
} from '@material-ui/core'
import { connect } from 'react-redux'
import axios from 'axios';
import Loading from '../../../Loading'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const monthVal = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const PopulateHistory = (props) => {
    let { open, handleClose, alertMessageText, classes, confirm, type } = props
    const [allFieldValues, setAllFieldValues] = useState([]);
    const [fieldValue, setFieldValue] = useState('');
    const [oldValue, setOldValue] = useState('');
    const [newValue, setNewValue] = useState('');
    const [message, setMessage] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (open) fetchHistoryData();
    }, [open])

    const closePopup = (val) => {
        if (val) {
            setFieldValue('');
            setOldValue('');
            setNewValue('');
            setMessage('');
            handleClose(true)
        } else {
            if (fieldValue === '' && oldValue === '' && newValue === '') {
                setMessage('Please add all the fields');
            } else {
                setMessage('');
                handleClose(false, { fieldValue, oldValue, newValue })
            }
        }
    }

    const fetchHistoryData = () => {
        const payload = {
            data: {
                currentFF: props.currentFF,
                fiscalYear: `${props.fiscalYear}`,
            },
            pagination: {
                page: page.toString(),
                rowsPerPage: rowsPerPage.toString()
            },
        }
        axios({
            url: API_BASE_URL + '/mrspendplan/omni/autopopulate-history',
            method: 'POST',
            data: payload,
            headers: {
                'Content-Type': 'application/json',
                appId: props.match.params.appid,
            },
        }).then((response) => {
            if (response && response.data && response.data.list && response.data.list.length) {
                setAllFieldValues(response.data.list);
                setTotalPage(response.data.count)
                setLoading(false);
            }
        }).catch((error) => {
            console.log({ "error": error });
        })
    }

    const viewHistoryPage = (data) => {
        setRowsPerPage(50);
        setPage(0);
        setCurrentPage(0);
        props.viewHistoryPageById(data._id)
    }

    const getDateValue = (dateVal) => {
        let date = new Date(dateVal).getDate();
        let month = new Date(dateVal).getMonth();
        let year = new Date(dateVal).getFullYear();
        let h = new Date(dateVal).getHours();
        let m = new Date(dateVal).getMinutes();
        let s = new Date(dateVal).getSeconds();
        return (date + '/' + (month+1) + '/' + year + '  ' + (h < 10 ? '0' + h : h) + ':' + (m<10?('0'+m) : m) + ':' + (s<10?('0'+s) : s))
    }

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target?.value, 10));
        setCurrentPage(0);
    };

    return (<>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.confirmationDialoge}
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: "#047dd8" }}>
                {typeof alertMessageText == 'object' && alertMessageText?.title ? alertMessageText.title : "Confirmation"}
            </DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText component="div" id="alert-dialog-slide-description" className={classes.para}>
                    {!loading && allFieldValues && allFieldValues.length && <TableContainer className='table-container' style={{ margin: '10px', maxHeight: 600, overflowY: 'auto' }} component={Paper}>
                        <Table className='table' aria-label="simple table"
                        >
                            <TableHead className='table-head-replace'>
                                <TableRow>
                                    <TableCell style={{ width: 150 }} colSpan={3}>
                                        <span>Date & Time</span>
                                    </TableCell>
                                    <TableCell style={{ width: 150 }} colSpan={3}>
                                        <span>View</span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table-body-replace">
                                {
                                    allFieldValues && allFieldValues.length &&
                                    <>
                                        {
                                            allFieldValues.map((dataVal, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell style={{ width: 150 }} colSpan={3}>
                                                            <span>{getDateValue(dataVal.createdAt)}</span>
                                                        </TableCell>
                                                        <TableCell style={{ width: 150 }} colSpan={3}>
                                                            <Button
                                                                style={{ textTransform: 'none' }}
                                                                color="primary" onClick={() => viewHistoryPage(dataVal)}
                                                                className="btn btn-primary btn-color border-radius-0 text-color-white">
                                                                {"View"}
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }
                    {!loading && !(allFieldValues && allFieldValues.length) ? 'No Data Found' : ''}
                     {(!loading && allFieldValues && allFieldValues.length) && <TablePagination
                            rowsPerPageOptions={[20, 50, 100, 200]}
                            component="div"
                            count={totalPage}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                    />}
                    {loading ? <Loading/> : ''}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    <React.Fragment>
                        <Button
                            style={{ textTransform: 'none' }}
                            color="primary" onClick={() => closePopup(true)}
                            className="btn btn-primary btn-color border-radius-0 text-color-white">
                            {"Cancel"}
                        </Button>
                    </React.Fragment>
                }
            </DialogActions>
        </Dialog>

    </>)
}

const mapStateToProps = state => {
    return {
        user: state.user,
        pageLoading: state.pages.loading,
        member: state.appConfig.member,
        memberLoading: state.appConfig.memberLoading,
        entity: state.appConfig.entity ? state.appConfig.entity : {}
    };
};
export default hot(withRouter(connect(mapStateToProps, null)(PopulateHistory)));