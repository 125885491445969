import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    Slide,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    paper: { minWidth: 650, height: "100%", },
    contents: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000',
    },
    textInput: {
        marginLeft: 20,
        border: '1px solid lightgray',
        width: 50,
        height: 35,
        background: 'transparent',
        outline: 'none',
    },
    monthsRow: {
        display: 'flex',
    },
    heading1: {
        fontSize: '20px !important',
        fontWeight: 500,
        color: '#000',
    },
    monthContainer: {
        marginTop: 20,
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            minHeight: '280px',
            width: '600px'
        }
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000'
    },
    headerSection: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    incrementSection: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginLeft:"50px"
        marginLeft: "12%"
    },
    textField: {
        width: "60px"
    },
    buttonAction: {
        cursor: "pointer"
    },
    selectedMonthDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));

let headerview = {
    'brandform': 'Brandform', 'channel': 'Channel', 'jul': 'Jul', 'aug': 'Aug',
    'sep': 'Sep', 'oct': 'Oct', 'nov': 'Nov', 'dec': 'Dec', 'jan': 'Jan', 'feb': 'Feb', 'mar': 'Mar',
    'apr': 'Apr', 'may': 'May', 'jun': 'Jun', 'FY': 'FY', 'pyamj': 'PY AMJ',
    hfsretailingcrores: 'HFS Cr.', hfsretailingmsu: 'HFS MSU',
    rlpcrores: 'RLP Cr.', rlpsalience: 'RLP Salience'
}

const IncrementModal = (props) => {
    let { open, handleClose } = props;
    let { heading, month } = props.data

    const classes = useStyles();

    const [dataObj, setDataObj] = useState({
        // headers: [
        //     {
        //         label: "BrandForm",
        //         value: "brandform"
        //     },
        //     {
        //         label: "Channel",
        //         value: "channel"
        //     }
        // ],
        data: [],
        total: 0
    })
    const [selectedRows, setSelectedRows] = useState([])
    const [inputValue, setInputValue] = useState(0)
    const [selectedMonth, setSelectedMonth] = useState('')

    useEffect(() => {
        if (props.data.month) {
            setSelectedMonth(props.data.month)
        }
    }, [props.data])

    useEffect(() => {
        if (selectedMonth && props.mainData?.length) {
            let total = 0;
            props.mainData.map((item, index) => {
                if (index < 25 && item.channel !== "Channel Combinations" && item.channel !== "All Channels") {
                    total += item.bopsalience[selectedMonth]
                }
            })
            setDataObj({
                // headers: [...dataObj.headers, {
                //     label: headerview[selectedMonth],
                //     value: selectedMonth
                // }],
                total: total,
                data: props.mainData
            })
        }
    }, [props.mainData, selectedMonth])

    const onIncrementOrDecrement = (type) => {
        let obj = JSON.parse(JSON.stringify(dataObj))
        let byValue = parseFloat(inputValue)
        let total = 0;
        if (selectedRows.length) {
            obj.data = obj.data.map((item, index) => {
                if (index < 25 && item.channel !== "Channel Combinations" && item.channel !== "All Channels") {
                    if (selectedRows.includes(item.uniqueConstraint)) {
                        if (type === 'increment') {
                            item.bopsalience[selectedMonth] += byValue
                        } else {
                            item.bopsalience[selectedMonth] -= byValue
                            if (item.bopsalience[selectedMonth] < 0) {
                                item.bopsalience[selectedMonth] = 0
                            }
                        }
                    }
                    total += item.bopsalience[selectedMonth]
                }
                return item
            })
        } else {
            obj.data = obj.data.map((item, index) => {
                if (index < 25 && item.channel !== "Channel Combinations" && item.channel !== "All Channels") {
                    if (type === 'increment') {
                        item.bopsalience[selectedMonth] += byValue
                    } else {
                        item.bopsalience[selectedMonth] -= byValue
                        if (item.bopsalience[selectedMonth] < 0) {
                            item.bopsalience[selectedMonth] = 0
                        }
                    }
                    total += item.bopsalience[selectedMonth]
                }
                return item
            })
        }
        obj.total = total;
        setDataObj(obj)
        setSelectedRows([])
    }

    const onIndividualIncrementOrDecrement = (rowId, type) => {
        let obj = JSON.parse(JSON.stringify(dataObj))
        let byValue = parseFloat(inputValue)
        let total = 0;

        obj.data = obj.data.map((item, index) => {
            if (index < 25 && item.channel !== "Channel Combinations" && item.channel !== "All Channels") {
                if (item.uniqueConstraint === rowId) {
                    if (type === 'increment') {
                        item.bopsalience[selectedMonth] += byValue
                    } else {
                        item.bopsalience[selectedMonth] -= byValue
                        if (item.bopsalience[selectedMonth] < 0) {
                            item.bopsalience[selectedMonth] = 0
                        }
                    }
                }
                total += item.bopsalience[selectedMonth]
            }
            return item
        })
        obj.total = total;
        setDataObj(obj)
    }

    const checkRow = (id, value) => {
        if (value) {
            setSelectedRows([...selectedRows, id])
        } else {
            setSelectedRows(selectedRows.filter(i => i !== id))
        }
    }

    const renderCheckBox = (row) => {
        return (
            <Checkbox
                style={{
                    transform: "scale(0.6)", padding: 0,
                }}
                onChange={(e, v) => checkRow(row.uniqueConstraint, v)}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                checked={selectedRows.includes(row.uniqueConstraint)}
            />
        )
    }

    return (<>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            classes={{ paper: classes.paper }}
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: '#047dd8' }}>
                {heading}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component="div" className={classes.contents}>
                    <div className={classes.headerSection}>
                        <div></div>
                        <div className={classes.incrementSection}>
                            {/* <Button variant="contained" size='small'> */}
                            <RemoveIcon
                                color='primary'
                                className={classes.buttonAction}
                                onClick={() => onIncrementOrDecrement('decrement')}
                            />
                            {/* </Button> */}
                            <TextField
                                variant='outlined'
                                className={classes.textField}
                                value={inputValue}
                                onChange={(event) => setInputValue(event.target.value)}
                            />
                            {/* <Button variant="contained" size='small'> */}
                            <AddIcon
                                color='primary'
                                className={classes.buttonAction}
                                onClick={() => onIncrementOrDecrement('increment')}
                            />
                            {/* </Button> */}
                        </div>
                        <Typography style={{ fontWeight: 500, fontSize: "20px" }}>Total : {parseFloat(dataObj.total).toFixed(1)}%</Typography>
                    </div>
                    <div>
                        <TableContainer>
                            <Table>
                                <TableHead className={classes.tableHead}>
                                    <TableCell></TableCell>
                                    <TableCell>Brandform</TableCell>
                                    <TableCell>Channel</TableCell>
                                    <TableCell>{headerview[selectedMonth]}</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        dataObj.data.slice(0, 25).map(row => {
                                            if (row.channel !== "Channel Combinations" && row.channel !== "All Channels") {
                                                return (
                                                    <TableRow>
                                                        <TableCell>
                                                            {renderCheckBox(row)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.brandform}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.channel}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className={classes.selectedMonthDiv}>
                                                                <RemoveIcon
                                                                    color='primary'
                                                                    fontSize='small'
                                                                    className={classes.buttonAction}
                                                                    onClick={() => onIndividualIncrementOrDecrement(row.uniqueConstraint, 'decrement')}
                                                                />
                                                                <span>
                                                                    {parseFloat(row.bopsalience[selectedMonth]).toFixed(1)}%
                                                                </span>
                                                                <AddIcon
                                                                    color='primary'
                                                                    fontSize='small'
                                                                    className={classes.buttonAction}
                                                                    onClick={() => onIndividualIncrementOrDecrement(row.uniqueConstraint, 'increment')}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                    {/* <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{parseFloat(dataObj.total).toFixed(1)}%</TableCell>
                                    </TableRow> */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <React.Fragment>
                    <Button
                        style={{ textTransform: 'none' }}
                        color="primary" onClick={() => handleClose(dataObj.data)}
                        className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {"OK"}
                    </Button>
                    <Button
                        style={{ textTransform: 'none' }}
                        color="primary" onClick={() => handleClose(false)}
                        className="btn btn-primary btn-color border-radius-0 text-color-white">
                        {"Cancel"}
                    </Button>
                </React.Fragment>
            </DialogActions>
        </Dialog>
    </>);
};

const mapStateToProps = state => {
    return {
        account: state.mrReducer?.account,
    };
};

export default hot(withRouter(connect(mapStateToProps, null)(IncrementModal)));