import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, makeStyles,
    Slide
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { getFileNameWithTimeStamp, jsonToExcelDownload } from '../../../../../utility/utils';
import { errorMsgs, HandleErrors } from './ErrorDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    confirmationDialog: {
        '& .MuiDialog-paperWidthSm': {
            minHeight: '320px',
            width: '600px'
        }
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000'
    }
}));

const IssuesType = {
    accountIssues: 'accountIssues',
    skuIssues: 'skuIssues',
    skuMasterIssues: 'skuMasterIssues',
    brandIssues: 'brandIssues',
    brandFormIssues: 'brandFormIssues',
    skuMappingIssues: 'skuMappingIssues',
    skuTypeIssues: 'skuTypeIssues',
    dataValidationIssues: 'dataValidationIssues',
    duplicateRecords: 'duplicate',
    dateIssues: 'dateIssues',
    tsBrandIssues: 'tsBrandIssues',
    tsSkuIssues: 'tsSkuIssues',
    subBrandformIssues: 'subBrandformIssues',
    productIdIssues: 'productIdIssues',
    subBrandFormMappingIssue: 'subBrandFormMappingIssue',
    monthIssues: 'monthIssues',
    numaricIssues: 'numaricIssues',
    kbdIssues: 'kbdIssues',
    uomIssues: 'uomIssues',
    metricIssues: 'metricIssues',
    eventIssues: 'eventIssues',
    categoryIssues: 'categoryIssues',
    ittIssues: 'ittIssues',
    typeIssues: 'typeIssues',
    earningOnIssues: 'earningOnIssues'
}


const MRPlanUploadConfirmDialog = (props) => {
    let { open, handleClose, alertMessageText } = props;
    const classes = useStyles();

    const isError = () => {
        return (alertMessageText?.accountIssues?.length > 0 ||
            alertMessageText?.brandIssues?.length > 0 ||
            alertMessageText?.brandFormIssues?.length > 0 ||
            alertMessageText?.skuIssues?.length > 0 ||
            alertMessageText?.skuTypeIssues?.length > 0 ||
            alertMessageText?.skuMasterIssues?.length > 0 ||
            alertMessageText?.dataValidationIssues?.length > 0 ||
            alertMessageText?.duplicateRecord?.length > 0 ||
            alertMessageText?.skuMappingIssues?.length > 0 ||
            alertMessageText?.dateIssues?.length > 0 ||
            alertMessageText?.tsBrandIssues?.length > 0 ||
            alertMessageText?.tsSkuIssues?.length > 0 ||
            alertMessageText?.subBrandformIssues?.length > 0 ||
            alertMessageText?.subBrandFormMappingIssue?.length > 0 ||
            alertMessageText?.numaricIssues?.length > 0 ||
            alertMessageText?.monthIssues?.length > 0 ||
            alertMessageText?.productIdIssues?.length > 0 || 
            alertMessageText?.kbdIssues?.length > 0 ||
            alertMessageText?.uomIssues?.length > 0 ||
            alertMessageText?.metricIssues?.length > 0 ||
            alertMessageText?.eventIssues?.length > 0 ||
            alertMessageText?.categoryIssues?.length > 0 || 
            alertMessageText?.ittIssues?.length > 0 || 
            alertMessageText?.typeIssues?.length > 0 || 
            alertMessageText?.earningOnIssues?.length > 0)
    }

    const downloadError = () => {
        let downloadSheetType = 'excel'; // text: for text file
        if (downloadSheetType === 'text') {
            const element = document.createElement("a");
            let title = typeof alertMessageText == 'object' ? ('Error Report - ' + props.title || '') : 'Error';
            let text = title + " ";
            text += '\n';
            text += '\n';

            text += alertMessageText?.message + '\n';
            text += '\n';

            let obj = {};
            let msgs = {};
            const errorHandler = (err, type) => {
                err?.forEach(i => {
                    if (obj[i.message]) {
                        obj[i.message].push(i.row)
                    } else {
                        obj[i.message] = [i.row]
                        msgs[i.message] = errorMsgs[type]

                    }
                })
            } // not using text format for now. if we use need to add omnichannel errors
            errorHandler(alertMessageText?.accountIssues, IssuesType.accountIssues)
            errorHandler(alertMessageText?.skuIssues, IssuesType.skuIssues)
            errorHandler(alertMessageText?.skuMasterIssues, IssuesType.skuMasterIssues)
            errorHandler(alertMessageText?.brandIssues, IssuesType.brandIssues)
            errorHandler(alertMessageText?.brandFormIssues, IssuesType.brandFormIssues)
            errorHandler(alertMessageText?.skuMappingIssues, IssuesType.skuMappingIssues)
            errorHandler(alertMessageText?.skuTypeIssues, IssuesType.skuTypeIssues)
            errorHandler(alertMessageText?.dataValidationIssues, IssuesType.dataValidationIssues)
            errorHandler(alertMessageText?.duplicateRecord, IssuesType.duplicateRecords)

            Object.keys(obj).forEach(i => {
                text += msgs[i] + '\n';
                text += i;
                text += ' (' + obj[i].length + ')' + '\n';
                text += obj[i].join(', ');
                text += '\n';
                text += '\n';
            })

            const file = new Blob([text], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = getFileNameWithTimeStamp(title, false, true)
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        } else if (downloadSheetType === 'excel') {
            let errors = {};
            let maxRow = 0;
            const errorHandler2 = (errorObj) => {
                errorObj?.forEach(i => {
                    if (i.row > maxRow) {
                        maxRow = i.row
                    }
                    if (errors[i.row]) {
                        errors[i.row] = errors[i.row] + ", " + i.message;
                    } else {
                        errors[i.row] = i.message;
                    }
                })
            }
            errorHandler2(alertMessageText?.accountIssues)
            errorHandler2(alertMessageText?.skuIssues)
            errorHandler2(alertMessageText?.skuMasterIssues)
            errorHandler2(alertMessageText?.brandIssues)
            errorHandler2(alertMessageText?.brandFormIssues)
            errorHandler2(alertMessageText?.skuMappingIssues)
            errorHandler2(alertMessageText?.skuTypeIssues)
            errorHandler2(alertMessageText?.dataValidationIssues)
            errorHandler2(alertMessageText?.duplicateRecord)
            errorHandler2(alertMessageText?.dateIssues)
            errorHandler2(alertMessageText?.subBrandformIssues)
            errorHandler2(alertMessageText?.productIdIssues)
            errorHandler2(alertMessageText?.subBrandFormMappingIssue)
            errorHandler2(alertMessageText?.numaricIssues)
            errorHandler2(alertMessageText?.monthIssues)
            errorHandler2(alertMessageText?.kbdIssues)
            errorHandler2(alertMessageText?.tsSkuIssues)
            errorHandler2(alertMessageText?.tsBrandIssues)
            errorHandler2(alertMessageText?.uomIssues)
            errorHandler2(alertMessageText?.metricIssues)
            errorHandler2(alertMessageText?.eventIssues)
            errorHandler2(alertMessageText?.categoryIssues)
            errorHandler2(alertMessageText?.ittIssues)
            errorHandler2(alertMessageText?.typeIssues)
            errorHandler2(alertMessageText?.earningOnIssues)


            let arr = []
            let title = typeof alertMessageText == 'object' ? ('Error Report - ' + props.title || '') : 'Error';
            title = title + ' '
            for (let i = 1; i <= maxRow; i++) {
                arr.push({ error: errors[i] || '' })
            }
            jsonToExcelDownload(arr, getFileNameWithTimeStamp(title))
        }
        handleClose()

    }

    return (<>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={classes.confirmationDialog}
            id={alertMessageText.p1 ?"error-upload-confirm-modal":"success-upload-confirm-modal"}
           
           
        > 
            <DialogTitle id="alert-dialog-slide-title" style={{ backgroundColor: '#047dd8',}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h6 style={{ margin: 0, }}>{typeof alertMessageText == 'object' && alertMessageText?.title ? alertMessageText?.title :
                        isError()
                            ? "Error" :
                            "Confirmation"}
                           
                    </h6>
                    {
                        props.isUpload &&
                        <div title='Download errors' style={{ display: 'contents' }}>
                            <GetAppIcon onClick={downloadError} style={{ color: '#fff', fontSize: 20, cursor: 'pointer' }} />
                        </div>
                    }

                </div>
            </DialogTitle>
            <DialogContent className={classes.dialog}>
                <DialogContentText component="div" className={classes.para}>
                    {typeof alertMessageText == 'object'
                        ? <>
                            {alertMessageText.p1 &&
                                <h4 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '20px 0' }}>
                                    {alertMessageText.p1} </h4>}
                            {alertMessageText.p2 && <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
                                {alertMessageText.p2} </h6>}
                            {alertMessageText.p3 && <h3 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
                                {alertMessageText.p3} </h3>}
                            <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '5px 0' }}>
                                {alertMessageText?.message}</h6>
                            {alertMessageText?.accountIssues?.length > 0
                                && <HandleErrors key={IssuesType.accountIssues} errorArr={alertMessageText?.accountIssues} type={IssuesType.accountIssues} />}
                            {alertMessageText?.skuIssues?.length > 0
                                && <HandleErrors key={IssuesType.skuIssues} errorArr={alertMessageText?.skuIssues} type={IssuesType.skuIssues} />}
                            {alertMessageText?.skuMasterIssues?.length > 0
                                && <HandleErrors key={IssuesType.skuMasterIssues} errorArr={alertMessageText?.skuMasterIssues} type={IssuesType.skuMasterIssues} />}
                            {alertMessageText?.brandIssues?.length > 0
                                && <HandleErrors key={IssuesType.brandIssues} errorArr={alertMessageText?.brandIssues} type={IssuesType.brandIssues} />}
                            {alertMessageText?.brandFormIssues?.length > 0
                                && <HandleErrors key={IssuesType.brandFormIssues} errorArr={alertMessageText?.brandFormIssues} type={IssuesType.brandFormIssues} />}
                            {alertMessageText?.skuMappingIssues?.length > 0
                                && <HandleErrors key={IssuesType.skuMappingIssues} errorArr={alertMessageText?.skuMappingIssues} type={IssuesType.skuMappingIssues} />}
                            {alertMessageText?.skuTypeIssues?.length > 0
                                && <HandleErrors key={IssuesType.skuTypeIssues} errorArr={alertMessageText?.skuTypeIssues} type={IssuesType.skuTypeIssues} />}
                            {alertMessageText?.dataValidationIssues?.length > 0
                                && <HandleErrors key={IssuesType.dataValidationIssues} errorArr={alertMessageText?.dataValidationIssues} type={IssuesType.dataValidationIssues} />}
                            {alertMessageText?.duplicateRecord?.length > 0
                                && <HandleErrors key={IssuesType.duplicateRecords} errorArr={alertMessageText?.duplicateRecord} type={IssuesType.duplicateRecords} />}
                            {alertMessageText?.dateIssues?.length > 0
                                && <HandleErrors key={IssuesType.dateIssues} errorArr={alertMessageText?.dateIssues} type={IssuesType.dateIssues} />}
                            {alertMessageText?.tsSkuIssues?.length > 0
                                && <HandleErrors key={IssuesType.tsSkuIssues} errorArr={alertMessageText?.tsSkuIssues} type={IssuesType.tsSkuIssues} />}
                            {alertMessageText?.subBrandformIssues?.length > 0
                                && <HandleErrors key={IssuesType.subBrandformIssues} errorArr={alertMessageText?.subBrandformIssues} type={IssuesType.subBrandformIssues} />}
                            {alertMessageText?.productIdIssues?.length > 0
                                && <HandleErrors key={IssuesType.productIdIssues} errorArr={alertMessageText?.productIdIssues} type={IssuesType.productIdIssues} />}
                            {alertMessageText?.subBrandFormMappingIssue?.length > 0
                                && <HandleErrors key={IssuesType.subBrandFormMappingIssue} errorArr={alertMessageText?.subBrandFormMappingIssue} type={IssuesType.subBrandFormMappingIssue} />}
                            {alertMessageText?.monthIssues?.length > 0
                                && <HandleErrors key={IssuesType.monthIssues} errorArr={alertMessageText?.monthIssues} type={IssuesType.monthIssues} />}
                            {alertMessageText?.numaricIssues?.length > 0
                                && <HandleErrors key={IssuesType.numaricIssues} errorArr={alertMessageText?.numaricIssues} type={IssuesType.numaricIssues} />}
                            {alertMessageText?.kbdIssues?.length > 0
                                && <HandleErrors key={IssuesType.kbdIssues} errorArr={alertMessageText?.kbdIssues} type={IssuesType.kbdIssues} />}
                            {alertMessageText?.tsBrandIssues?.length > 0
                                && <HandleErrors key={IssuesType.tsBrandIssues} errorArr={alertMessageText?.tsBrandIssues} type={IssuesType.tsBrandIssues} />}
                            {alertMessageText?.uomIssues?.length > 0
                                && <HandleErrors key={IssuesType.uomIssues} errorArr={alertMessageText?.uomIssues} type={IssuesType.uomIssues} />}
                            {alertMessageText?.metricIssues?.length > 0
                                && <HandleErrors key={IssuesType.metricIssues} errorArr={alertMessageText?.metricIssues} type={IssuesType.metricIssues} />}
                            {alertMessageText?.eventIssues?.length > 0
                            && <HandleErrors key={IssuesType.eventIssues} errorArr={alertMessageText?.eventIssues} type={IssuesType.eventIssues} />}
                            {alertMessageText?.categoryIssues?.length > 0
                            && <HandleErrors key={IssuesType.categoryIssues} errorArr={alertMessageText?.categoryIssues} type={IssuesType.categoryIssues} />}
                               {alertMessageText?.ittIssues?.length > 0
                            && <HandleErrors key={IssuesType.ittIssues} errorArr={alertMessageText?.ittIssues} type={IssuesType.ittIssues} />}
                             {alertMessageText?.typeIssues?.length > 0
                            && <HandleErrors key={IssuesType.typeIssues} errorArr={alertMessageText?.typeIssues} type={IssuesType.typeIssues} />}
                                {alertMessageText?.earningOnIssues?.length > 0
                            && <HandleErrors key={IssuesType.earningOnIssues} errorArr={alertMessageText?.earningOnIssues} type={IssuesType.earningOnIssues} />}
                        </> : <h6 style={{ color: '#000', textAlign: 'center', textTransform: 'none', margin: '10px 0' }}>
                            {alertMessageText}
                        </h6>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id={isError() ? "downloadError-btn" : "handleClose-btn"}
                    color="primary" onClick={() => isError() ? downloadError() : handleClose()}
                    className="btn btn-primary btn-color border-radius-0 text-color-white">
                    {isError() ? 'Click to download erorr file' : 'OK'}
                </Button>
            </DialogActions>
        </Dialog>

    </>);
};

export default hot(MRPlanUploadConfirmDialog);


