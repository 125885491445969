import React from 'react';
// import TextField from "@material-ui/core/TextField";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inpt: {
    width: '100%',
    // marginBottom: '10px',
    background: '#fff',
    borderRadius: 0,
    '& input': {
      padding: '1px 1px 1px 15px !important',
    },
  },
}));

function Input(props) {
  const { keyName, keyIndex, pIndex, onDropDownSelect, value } = props;
  const classes = useStyles();
  return (
    <div className="main-input">
      <OutlinedInput
        name={keyName}
        value={value}
        // value={values.weight}
        onChange={e => onDropDownSelect(e, pIndex, keyIndex)}
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          'aria-label': 'weight',
        }}
        className={classes.inpt}
        labelWidth={0}
        placeholder="text"
      />
    </div>
  );
}

export default Input;
