import React from 'react';
import { Grid, Paper, makeStyles, Divider } from '@material-ui/core';
import classNames from 'classnames'
import brandLogo from 'images/spendplan/Ariel.jpg'
import indiaLogo from 'images/india.svg'
import greenArrow from 'images/green-up-arrow-tt.png'

const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const useStyles = makeStyles(theme => ({
	paperTable: {
		textAlign: 'left',
		padding: '5px 15px',
		// backgroundColor: 'transparent',
		borderRadius: 0,
		margin: '0 10px 0 10px',
		height: '100%'
	},
	anchor: {
		fontSize: '14px !important',
		textDecoration: 'underline !important',
	},
	panel: {
		marginBottom: '20px',
		backgroundColor: '#222222',
		border: '1px solid transparent',
		// borderRadius: '4px',
		boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)'
	},
	panelDefault: {
		borderColor: '#282828',
	},
	panelHeading: {
		color: '#888888',
		// backgroundColor: '#3c3c3c',
		borderColor: '#282828',
		textAlign: 'left',
		padding: '13px',
		borderBottom: '4px solid #000',
		marginBottom: '13px',
		'@media(max-width:1366px)': {
			padding: 5,
			borderBottom: '1px solid #000',
			marginBottom: 5,
		},
	},
	headingStyle: {
		fontSize: '25px',
		'@media(max-width:1366px)': {
			fontSize: 20,
		},
		fontWeight: 'bold',
		color: '#000',
		// display: 'flex',
		alignItems: 'center',
		textAlign: 'center'
	},
	svgIcon: {
		marginRight: 10,
		fontSize: 21,
		'@media(max-width:1366px)': {
			fontSize: 18,
		},
	},
	checkIcon: {
		color: '#fff',
		background: '#008000',
		borderRadius: '19px',
		display: 'flex'
	},
	innerHeading: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '25px'
	},
	innerHeading1: {
		paddingTop: '35px',
		textAlign: 'center',
		fontSize: '20px'
	},
	innerContainer: {
		textAlign: 'center',
	},
	innerBody: {
		paddingTop: '20px',
		textAlign: 'center',
    	// margin: 'auto',
    	// width: '50%'
	},
	innerBodyLogo: {
		paddingTop: '20px',
		textAlign: 'center',
    	margin: 'auto',
    	width: '50%'
	},
	innerValue: {
		fontWeight: 'bold',
		fontSize: '30px',
	},
	innerDivider: {
		paddingTop: '15px',
		paddingBottom: '15px',
	},
	dividerColor: {
		backgroundColor: '#203864',
		color: '#203864'
	}
}));

function BudgetWidget(props) {
	const { myTasks } = props;
	const classes = useStyles();
	return (<>
		<Paper className={classes.paperTable}>
			<div style={{ height: '100%' }} className={(classes.panel, classes.paneldefault)}>
				<Grid container style={{ height: '100%' }} >
					<Grid item xs={3} className={classes.innerBodyLogo}>
						<div>
							{/* <img src={brandLogo} /> */}
							<img src={indiaLogo} style={{width: '100%'}} />
						</div>
					</Grid>
					<Grid item xs={1} className={classes.innerDivider} ><Divider style={{
						width: '10%',
						height: '100%',
						textAlign: 'left',
						marginLeft: "50%",
					}} classes={{ root: classes.dividerColor }} orientation="vertical" /></Grid>
					<Grid item xs={4} className={classes.innerBody}>
						<div className={classNames(classes.innerContainer)}>
							<div className={classNames(classes.innerHeading)}>
								{"Previous FF"}
							</div>
						</div>
						<div className={classNames(classes.innerContainer)}>
							<Divider style={{
								border: "solid 1px",
								marginTop: "3px",
								padding: "0 30px",
								marginLeft: '35px',
								fontWeight: 'bold'
							}} />
							<div className={classNames(classes.innerHeading1)}>
								{"Total no: of plans"}
							</div>
							<div className={classNames(classes.innerValue)}>
								{"290"}
							</div>
							<div className={classNames(classes.innerHeading1)}>
								{"Total Budget Planned"}
							</div>
							<div className={classNames(classes.innerValue)}>
								{"$ 2.5 M"}
							</div>
						</div>
					</Grid>
					<Grid item xs={4} className={classes.innerBody}>
						<div className={classNames(classes.innerContainer)}>
							<div className={classNames(classes.innerHeading)} >
								{"Current FF"}
							</div>
						</div>
						<div className={classNames(classes.innerContainer)}>
							<Divider style={{
								border: "solid 1px",
								marginTop: "3px",
								padding: "0 30px",
								marginRight: '35px',
								fontWeight: 'bold',
								marginLeft: '35px'
							}} />
							<div >
								<div className={classNames(classes.innerHeading1)}>
									{"Total no: of plans"}
								</div>
								<div className={classNames(classes.innerValue)}>
									{"332"}<img src={greenArrow} style={{width: '8%'}}/>
								</div>
								{/* <img src={greenArrow} style={{width: '10%'}}/> */}
							</div>
							<div >
								<div className={classNames(classes.innerHeading1)}>
									{"Total Budget Planned"}
								</div>
								<div className={classNames(classes.innerValue)}>
									{"$ 2.9 M"}<img src={greenArrow} style={{width: '8%'}}/>
								</div>
								{/* <img src={greenArrow} style={{width: '10%'}}/> */}
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</Paper>
	</>);
}

export default BudgetWidget;
