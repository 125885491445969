import Grid from '@material-ui/core/Grid'
import React from 'react'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MRSheetTable from '../../../mrplan/Sheettable/index'
import SheetTable from './SheetTable'

const Worksheet = props => {
	return (
		<>
			<Grid container>
				<Grid item xs={12} sm={12}>
					{props.appName === 'MRPLAN' ? <MRSheetTable {...props} /> : <SheetTable {...props} />}
				</Grid>
			</Grid>
		</>
  );
}

const mapDispatchToProps = {
}

const mapStateToProps = ({ user, navigation }) => {
	return {
		user,
		navigation,
	}
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Worksheet)))