import { CircularProgress, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import React from 'react';
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

export const useStyles = makeStyles(theme => ({
	paperTable: {
		textAlign: 'left',
		padding: '5px 15px',
		borderRadius: 20,
		margin: '0 20px'
	},
	anchor: {
		fontSize: '14px !important',
		textDecoration: 'underline !important',
	},
	panel: {
		marginBottom: '10px',
		backgroundColor: '#222222',
		border: '1px solid transparent',
		boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)',
	},
	panelDefault: {
		borderColor: '#282828',
	},
	panelHeading: {
		color: '#888888',
		borderColor: '#282828',
		textAlign: 'left',
		padding: '13px 0',
		borderBottom: '2px solid #cecece',
		marginBottom: '13px',
		'@media(max-width:1366px)': {
			padding: '5px 0',
			borderBottom: '1px solid #cecece',
			marginBottom: 5,
		},
	},
	headingStyle: {
		fontSize: '25px',
		'@media(max-width:1366px)': {
			fontSize: 20,
		},
		fontWeight: 'bold',
		color: '#000',
		display: 'flex',
		alignItems: 'center',
	},
	svgIcon: {
		marginRight: 10,
		fontSize: 21,
		'@media(max-width:1366px)': {
			fontSize: 18,
		},
	},
	checkIcon: {
		color: '#fff',
		background: '#008000',
		borderRadius: '19px',
		display: 'flex'
	},
	tableContainer: {
		height: 220,
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '0.4em',
			height: '0.4em'
		},
		'&::-webkit-scrollbar-track': {
			borderRadius: 10,
			background: '#cdcdcd',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: 10,
			backgroundColor: 'gray',
		},
	},
	header: {
		fontWeight: '600',
		paddingLeft: 1,
	},
	loader: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 140,
	},
	heading: {
		color: '#000000',
		textAlign: 'center',
		fontWeight: '600',
		fontSize: 18,
	},
	value: {
		color: '#000000',
		textAlign: 'center',
		fontWeight: '600',
		fontSize: 30,
	},
	noContent: {
		display: 'flex',
		width: '100%',
		minHeight: '139px',
		alignItems: 'center',
		justifyContent: 'center'
	}
}));

function Mytask(props) {

	const classes = useStyles();
	const loading = props.mrReducer && props.mrReducer['GET_myActionTask_loading']

	const getMrActionData = () => {
		if (props.mrData && props.mrData['GET_myActionTask']) {
			return props.mrData['GET_myActionTask']?.result
		}
	}

	return (
		<Grid item xs={5}>
			<Paper className={classes.paperTable}>
				<div className={(classes.panel, classes.paneldefault)}>
					<div className={isIBC ? 'ibc-panelHeading' : classes.panelHeading}>
						<div className={classes.headingStyle}>
							<AssignmentOutlinedIcon className={classes.svgIcon} />
							My Action Tasks
						</div>
					</div>
					{loading ? <div className={classes.loader}><CircularProgress size={30} /></div> :
						<TableContainer className={classes.tableContainer} style={{height: 140}}>
							{props?.isOmni ? <div className={classes.noContent}>No Action item</div> : (<Table className={'table table-dark spnd-table-container spnd-report-table'}
								size="small">
								<TableHead>
									<TableRow>
										<TableCell align="left" className={classes.header}>Tab</TableCell>
										<TableCell align="left" className={classes.header}>Action Needed</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell align="left">SKU Type Mapping</TableCell>
										<TableCell align="left">Undefined : {getMrActionData()?.count || 0}</TableCell>
									</TableRow>
								</TableBody>
							</Table>)}
						</TableContainer>
					}
				</div>
			</Paper>
		</Grid>
	);
}

export default Mytask;
