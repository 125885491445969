import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Paper, makeStyles, Table, TableBody } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
	tableContainer: {
		maxWidth: "95vw",
		overflowY: "scroll !important",
		// margin: "40px auto",
		overflowY: "hidden !important",
		borderRadius: "8px"
	},
	table: {
		minWidth: 700,
	},
}));

const Sheet = (props) => {
	const classes = useStyles();
	return (
		<Table className={classNames(props.className, classes.table)} aria-label="simple table">
			<TableBody className={classes.tableBody}>
				{props.children}
			</TableBody>
		</Table>
	)
}

Sheet.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array.isRequired,
};

export default Sheet;
