import { makeStyles, Slide } from "@material-ui/core";
import React from "react";

export const useStyles = makeStyles(theme => ({
    confirmationDialog: {
        '& .MuiDialog-paperWidthSm': {
            height: '85vh',
            maxWidth: '100%',
            width: '90%'
        },
        '& .setup-data-headings': {
            fontSize: '25px !important',
            fontWeight: 'bold',
            lineHeight: '1',
            color: '#000'
        },
    },
}));

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});