import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { ExtractionResultServices } from 'modules/api/index'
import { ActionTypes } from 'constants/index';


async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* fetchPdfData(action){
    const formData = new FormData();
    console.log(action,"here is ac")
    formData.append('file', action.file);
    try{
        const appConfig = yield fetchAsync(ExtractionResultServices.uploadPdf, formData);
        console.log(appConfig,"appcon")
    }
    catch(err){
        console.log(err)
    }
}

export function* extractionSaga() {
    yield takeLatest(ActionTypes.LOAD_PDF, fetchPdfData);
}

export default extractionSaga;