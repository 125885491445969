import { put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import { ImarReportsService } from '../../../modules/api';


async function fetchAsync(func, arg) {
  return arg ? await func(arg) : await func();
}

function* fetchImar1Report(action) {
  try {
    const response = yield fetchAsync(ImarReportsService.getImar1ReportData, action.payload);
    yield put({
      type: ActionTypes.GET_IMAR1_REPORT_DATA_SUCCESS,
      payload: (response && response.result) ? response.result.data : [],
    });
  } catch (err) {
    yield put({ type: ActionTypes.GET_IMAR1_REPORT_DATA_FAILURE, error: err?.response?.error || 'Something went wrong' });
  }
}

function* fetchImar2Report(action) {
  try {
    const response = yield fetchAsync(ImarReportsService.getImar2ReportData, action.payload);
    yield put({
      type: ActionTypes.GET_IMAR2_REPORT_DATA_SUCCESS,
      payload: (response && response.result) ? response.result.data : [],
    });
  } catch (err) {
    yield put({ type: ActionTypes.GET_IMAR2_REPORT_DATA_FAILURE, error: err?.response?.error || 'Something went wrong' });
  }
}

export function* imarReports() {
  yield takeLatest(ActionTypes.GET_IMAR1_REPORT_DATA, fetchImar1Report);
  yield takeLatest(ActionTypes.GET_IMAR2_REPORT_DATA, fetchImar2Report);

}

export default imarReports;
