import React from 'react';
import { Grid, Paper, Typography, Box } from '@material-ui/core';
import LoginBox from 'components/Login/LoginBox';
import { makeStyles } from '@material-ui/core/styles';
import dsilologo from '../images/dsilo-white.svg';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles({
  root: {
    padding: '50px',
    background: '#11111180',
  },
  loginText: {
    color: '#fff',
    textAlign: 'center',
    '& .ins-heading': {
      color: ' #ff6503',
    },
    '& .ins-body': {
      marginBottom: '10px',
      fontSize: '20px',
    },
  },
  loginBox: {
    padding: '20px 30px 0 30px',
    background: 'rgba(0,148,255,0.5) !important',
    '& input': {
      background: '#fff',
      color: '#000 !important',
    },
    '& button': {
      margin: '20px 0',
      background: ' #ff6503 !important',
    },
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: '20px !important',
    display: 'inline',
  },
  dsiloStyle: {
    fontsize: '15px',
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center',
    '& img':{
      marginLeft: '5px'
    }
  },
});

function ibcLogin() {
  const classes = useStyles();
  return (
    <div className="login-sec" style={{display:'flex', alignItems:'center'}}>
      <Grid container className={classes.root}>
        <Grid item md={6} lg={8} className={classes.loginText}>
          <div>
            <img src={`${API_BASE_URL}/logo/602a848d17d1630011338082`} alt="ibc logo" height="170" />
          </div>
          <h2 className="ins-heading">{"Group Billing UI"}</h2>
          <div className="ins-body">
            {/* Driving Accelerated Value Creation and Cost Savings at Enterprises */}
            {/* For smart intake and AI/ML powered reconciliation */}
          </div>
          {/* <div className="ins-body">
            Through{' '}
            <div variant="inherit" className={classes.boldText}>
              Automation, intelligence{' '}
            </div>
            and{' '}
            <div variant="inherit" className={classes.boldText}>
              Trust
            </div>
          </div> */}
          <div className={classes.dsiloStyle}>
            Powered by <img src={dsilologo} alt="dsiloLogo" height="20" />
          </div>
        </Grid>
        <Grid item md={6} lg={4}>
          <Paper className={classes.loginBox + ' login-frm'}>
            <Typography component="h1" variant="h5" style={{ color: '#fff' }}>
              SIGN IN
            </Typography>
            <LoginBox />
            <Box component="div" my={1} whiteSpace="normal" style={{ marginTop: '20px' }}>
              {/* <Link className={classes.linkOne} to="/register">Don't have an account?</Link> */}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ibcLogin;
