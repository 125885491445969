import React, { useEffect, useState } from 'react';
import {
	Grid
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import DropDown from '../Select/DropDown';
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		height: 140,
		width: 100,
	},
	control: {
		padding: theme.spacing(2),
	},
	marginview: {
		margin: '0px',
		textAlign: 'left',
	},
	alignRight: {
		textAlign: 'right'
	},
	tableContainer: {
		backgroundColor: '#000000 !important'
	},
	gridContainer: {
		flexWrap: 'unset',
		flexDirection: 'column'
	},
	tableInnerHeader: {
		width: '100%',
		height: '52px',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#242323',
		justifyContent: 'space-between',
		borderBottom: '1px solid #6c6a6a'
	},
	tableHeading: {
		width: '100%',
		height: '52px',
		marginBottom: '5px'
	},
	rowContainer: {
		width: '100%',
		height: '52px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: '1px solid #6c6a6a'
	},
	itemContainer: {
		width: '50%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		'& .main-select': {
			width: '100%'
		}
	}
}));

export default function KeyMappings(props) {
	const classes = useStyles();
	let { empInfo, empComp, setEmpComp, setEmpInfo, headers, setHeaders } = props

	useEffect(() => {
		if (props.resolution && props.fileData && props.fileData.headers) {
			console.log('props.resolution && props.fileData', props.fileData.headers, props.resolution);
			setHeaders(props.fileData.headers)

			// 			AD&D volume: "2"
			// ADD premium: "10"
			// Age: "Age"
			// Base Annual Salary: "Annual Bonus"
			// Basic Life Volume: "1"
			// Basic Life premium: "9"
			// Billing Date: "20"
			// Child Life premium: "0"
			// Child Life volume: "Supp CH Life Volume"
			// Employee Name: "Employee Name"
			// Employee Number: "Policy Number"
			// Employee Optional Life premium: "Debit"
			// Employee Optional Life volume: "Supp EE Life Volume"
			// FMLA ADA Administration: "4"
			// Gulfport Class: "Class"
			// LTD premium: "11"
			// LTD volume: "3"
			// STD premium: "12"
			// STD volume: "5"
			// Spouse Life premium: "14"
			// Spouse Life volume: "Supp SP Life Volume"
			// Total Premium: "15"
			let o = {
				EmployeeID: ['Employee ID', 'EID', 'ID', 'Emp ID', 'Employee Number', 'Employee No'],
				EmployeeName: ['Employee Name', 'EName', 'Name', 'Emp Name'],
				DateOfBirth: ['Date of Birth', 'date of birth', 'Date Of Birth', 'DOB', 'dob'],
				BillGroup: ['Bill Group', 'bill Group', 'Bill group', 'BillGroup', 'billGroup', 'Billgroup', 'billgroup',
					'BILLGROUP', 'BILL GROUP', 'Division', 'division', 'Group'],
				Class: ['class', 'GulfportClass', 'Class', 'Classes'],
				BaseAnnualSalary: ['Base Annual Salary', 'Annual Salary', 'Basic Annual Salary', 'Base Salary', 'Basic Salary', 'Salary'],
				AnnualBonus: ['Annual Bonus', 'Bonus', 'Total Annual Bonus', 'Total Bonus'],
				EmployeeOptionalLifeVolume: ['Employee Optional Life Volume', 'Employee Optional Life volume', "Supp EE Life Volume", "Supp Employee Life Volume",
					"Supplementary Employee Life Volume", "Employee Life Volume", "Employee Life"],
				SpouseLifeVolume: ['Spouse Life volume', "Supp SP Life Volume", "Supp Spouse Life Volume",
					"Supplementary Spouse Life Volume", "Spouse Life Volume", "Spouse Life"],
				ChildLifeVolume: ['Child Life volume', "Supp CH Life Volume", "Supp Child Life Volume",
					"Supplementary Child Life Volume", "Child Life Volume", "Child Life"],
				Credit: ['Credit', 'credit'],
				Debit: ['Debit', 'debit']
			}
			empInfo.map((emp, i) => {
				let v = props.resolution[emp.key]
				if (v) {
					if (isNaN(v)) {
						props.fileData.headers.map(h => {
							console.log("z4", o[emp.key.replace(/ /g, '')]);
							if (o[emp.key.replace(/ /g, '')].includes(h)) {
								empInfo[i].value = h
							}
						})
					} else {
						empInfo[i].value = v
					}
				} else {
					props.fileData.headers.map(h => {
						console.log("z3", o[emp.key.replace(/ /g, '')]);
						if (o[emp.key.replace(/ /g, '')].includes(h)) {
							empInfo[i].value = h
						}
					})
				}
			})
			setEmpInfo(empInfo)

			empComp.map((emp, i) => {
				let v = props.resolution[emp.key]
				if (v) {
					if (isNaN(v)) {
						if (v === "Annual Bonus" && empComp[i].value === "Base Annual Salary") {
							props.fileData.headers.map(h => {
								console.log("z2", o[emp.key.replace(/ /g, '')]);
								if (o[emp.key.replace(/ /g, '')].includes(h)) {
									empComp[i].value = h
								}
							})
						} else {
							empComp[i].value = v
						}
					}
				} else {
					props.fileData.headers.map(h => {
						console.log("z1", emp.key, o[emp.key.replace(/ /g, '')]);
						if (o[emp.key.replace(/ /g, '')].includes(h)) {
							empComp[i].value = h
						}
					})
				}
			})
			setEmpComp(empComp)
		}
	}, [props.resolution, props.fileData])

	const onDropDownSelect = (e, index, type) => {
		console.log(e.target.value, index, type);
		if (type === 'empComp') {
			empComp[index].value = e.target.value;
			console.log(empComp);
			setEmpComp([...empComp])
		} else if (type === 'empInfo') {
			empInfo[index].value = e.target.value;
			setEmpInfo([...empInfo])
		}
	}
	console.log('empComp', empComp, 'empInfo', empInfo, 'headers', headers);

	const getName = (key) => {
		console.log('e.key).toLowerCase()', key.toLowerCase())
		if (key.toLowerCase() === "age") {
			return "Date of Birth"
		} else if (key.toLowerCase() === "credit") {
			return "Back Credit"
		} else if (key.toLowerCase() === "debit") {
			return "Back Charges"
		} else {
			return key
		}
	}

	return (<div className="setup-table" style={{ color: '#fff', textAlign: 'center' }}>
		<div className={`select-measure-container ${isIBC ? 'ibc-measure-container' : classes.tableContainer}`}>
			<Grid container className={classes.root} spacing={2}>
				<Grid container item xs={6} classes={{ root: classes.gridContainer }}>
					<div className={isIBC ? 'ibc-table-heading' : classes.tableHeading}>
						<h4 className={classes.marginview}>{"Employee Information"}</h4>
					</div>
					<div style={{ marginBottom: '5px' }} className={`${classes.tableInnerHeader} ${isIBC ? 'ibc-table-inner-header' : ''}`}>
						<div className={classes.itemContainer}>
							<h6 className={classes.marginview}>{"Carrier Template field"}</h6>
						</div>
						<div className={classes.itemContainer}>
							<h6 className={classes.marginview}>{"ER Census Field"}</h6>
						</div>
					</div>
					{empInfo.map((e, i) => <Grid container xs={12} alignItems="center">
						<div className={classes.rowContainer}>
							<div className={classes.itemContainer}>
								<h8 className={classNames(classes.marginview, classes.alignRight)}>
									{getName(e.key)}
								</h8>
							</div>
							<div className={classes.itemContainer}>
								<DropDown
									value={e.value}
									options={headers}
									onDropDownSelect={onDropDownSelect}
									pIndex={i}
									keyIndex={'empInfo'}
								/>
							</div>
						</div>
					</Grid>
					)}
				</Grid>
				<Grid container item xs={6} classes={{ root: classes.gridContainer }}>
					<div className={isIBC ? 'ibc-table-heading' : classes.tableHeading}>
						<h4 className={classes.marginview}>{"Employee Compensation"}</h4>
					</div>
					<div className={`${classes.tableInnerHeader} ${isIBC ? 'ibc-table-inner-header' : ''}`}>
						<div className={classes.itemContainer}>
							<h6 className={classes.marginview}>{"Carrier Template field"}</h6>
						</div>
						<div className={classes.itemContainer}>
							<h6 className={classes.marginview}>{"ER Census Field"}</h6>
						</div>
					</div>
					{empComp.map((e, i) => <Grid container xs={12} alignItems="center">
						<div className={classes.rowContainer}>
							<div className={classes.itemContainer}>
								<h8 className={classNames(classes.marginview, classes.alignRight)}>
									{getName(e.key)}
								</h8>
							</div>
							<div className={classes.itemContainer}>
								<DropDown
									value={e.value}
									options={headers}
									onDropDownSelect={onDropDownSelect}
									pIndex={i}
									keyIndex={'empComp'}
								/>
							</div>
						</div>
					</Grid>
					)}
				</Grid>
			</Grid>
		</div>
	</div>)
}