import { put, takeEvery } from 'redux-saga/effects';
import { ActionTypes } from '../../../constants';
import { MRPlanService } from '../../../modules/api';

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* getMRData(action) {
    try {
        const response = yield fetchAsync(MRPlanService.getMRData, action.payload); // isMerge is used to merge data into existing data
        yield put({ type: ActionTypes.GET_DATA_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId, isMerge: action.payload.isMerge } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_DATA_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* getUploadXlList(action) {
    try {
        const response = yield fetchAsync(MRPlanService.getUploadXlList, action.payload); // isMerge is used to merge data into existing data
        yield put({ type: ActionTypes.GET_DATA_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId, isMerge: action.payload.isMerge } });
    } catch (e) {
        yield put({ type: ActionTypes.GET_DATA_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* deleteMRData(action) {
    try {
        const response = yield fetchAsync(MRPlanService.deleteMRData, action.payload);
        yield put({ type: ActionTypes.DELETE_MR_DATA_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.DELETE_MR_DATA_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* saveMRData(action) {
    try {
        const response = yield fetchAsync(MRPlanService.saveMRData, action.payload);
        yield put({ type: ActionTypes.SAVE_MR_DATA_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.SAVE_MR_DATA_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* updateMRData(action) {
    try {
        const response = yield fetchAsync(MRPlanService.updateMRData, action.payload);
        yield put({ type: ActionTypes.UPDATE_MR_DATA_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId } });
    } catch (e) {
        yield put({ type: ActionTypes.UPDATE_MR_DATA_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

function* freezeMRPlans(action) {
    try {
        const response = yield fetchAsync(MRPlanService.freezeMRPlans, action.payload)
        yield put({ type: ActionTypes.FREEZE_MR_PLANS_SUCCESS, payload: response ?? [] })
    }
    catch (err) {
        let errorMsg = err.message != 'Bad Request' ? err.message : 'Freeze plan request Failed';
        yield put({ type: ActionTypes.FREEZE_MR_PLANS_FAILURE, error: errorMsg });
    }
}

function* getDataPost(action) {
    try {
        const response = yield fetchAsync(MRPlanService.getMRDataPostReq, action.payload); // isMerge is used to merge data into existing data
        if (response) {
            response['str'] = action?.payload?.str;
        }
        yield put({ type: ActionTypes.GET_DATA_SUCCESS, payload: { data: response, dataUniqueId: action.payload.dataUniqueId, isMerge: action.payload.isMerge} });
    } catch (e) {
        yield put({ type: ActionTypes.GET_DATA_FAILURE, payload: { error: e, dataUniqueId: action.payload.dataUniqueId } });
        console.log(e);
    }
}

export function* watchFormSaga() {
    yield takeEvery(ActionTypes.GET_DATA, getMRData);
    yield takeEvery(ActionTypes.DELETE_MR_DATA, deleteMRData);
    yield takeEvery(ActionTypes.SAVE_MR_DATA, saveMRData);
    yield takeEvery(ActionTypes.UPDATE_MR_DATA, updateMRData);
    yield takeEvery(ActionTypes.FREEZE_MR_PLANS, freezeMRPlans);
    yield takeEvery(ActionTypes.GET_DATA_POST, getDataPost)
    yield takeEvery(ActionTypes.GET_DATA, getUploadXlList)
}

export default watchFormSaga;
