
import { hot } from 'react-hot-loader/root'
import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    Button, Select, Table, TableBody, TableCell, TableHead, TableRow, Input, MenuItem, Grid,
    Typography, Box, TextField, TablePagination, makeStyles, TableContainer, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio, FormControlLabel, FormControl, Tooltip
} from '@material-ui/core'
import CensusErrors from './CensusErrors'
import MemberEditDialog from './dialogs/MemberEditDialog'

let style = {
    backgroundColor: '#ed6d2f', padding: '10px',
    marginTop: '50px',
    // marginRight: '50px',
    textAlign: 'center',
    cursor: 'pointer',
    marginLeft: '2%'
}


const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';

const CensusValidations = (props) => {
    let { members, dataErrors, invalidMembers, missingData, saveMembers, fileData, mappedKeys, setup, runMemberValidation, calculatePremium } = props
    console.log('members, dataErrors, invalidMembers, saveMembers', members, dataErrors, invalidMembers, saveMembers, missingData);
    const reloadPage = () => {
        props.histoy.push(`/apps/${props.match.params.appid}/ibcSupportingDocument`)
    }

    const [editTable, showTable] = useState(false)
    const [data, setData] = useState()
    const showInvalidTable = () => {
        console.log("showInvalidTable");
        let data = invalidMembers
        setData(data)
        showTable(true)
    }
    const showMissingDataTable = () => {
        console.log("showMissingDataTable");
        let data = missingData
        setData(data)
        showTable(true)
    }

    const closeEditTable = () => {
        console.log("closeEditTable");
        showTable(false)
    }
    console.log('fileData ===', fileData, mappedKeys);
    return (<>
        <div style={{ color: 'white', width: '100%', margin: 'auto', marginBottom: '100px' }}>
            <Grid container spacing={3} style={{ marginBottom: isIBC ? '20px' : '' }}>
                <div className={'census-items-container'}>
                    <Grid item xs={3} lg={2} style={{ ...style }}>
                        <p>
                            <h4>{"Total no: of employees"}</h4>
                            <h3>{members.length}</h3>
                        </p>
                    </Grid>
                    <Grid item xs={3} lg={2} style={{ ...style }}>
                        <p>
                            <h4>{"No: of employees with valid data"}</h4>
                            <h3>{members.length - invalidMembers.length - missingData.length}</h3>
                        </p>
                    </Grid>
                    <Grid item xs={3} lg={2} style={{ ...style }} onClick={e => showInvalidTable()}>
                        <p>
                            <h4>{"No: of employees with invalid data"}</h4>
                            <h3>{invalidMembers.length}</h3>
                        </p>
                    </Grid>
                    <Grid item xs={3} lg={2} style={{ ...style }} onClick={e => showMissingDataTable()}>
                        <p>
                            <h4>{"No: of employees with missing data"}</h4>
                            <h3>{missingData.length}</h3>
                        </p>
                    </Grid>
                </div>
            </Grid>

            {invalidMembers.length > 0 && missingData.length > 0 && <CensusErrors dataErrors={dataErrors} />}
            <Grid container spacing={3} style={{ margin: 'auto' }} >
                <div className={'census-items-container'}>
                    <button className={`btn btn-lg ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} id="btnAnalyze"
                        // onClick={e => saveMembers(e)}
                        // disabled={!!censusError}
                        onClick={e => calculatePremium(e)}
                    >
                        {invalidMembers.length === 0
                            ? "Save and Proceed"
                            : "Save valid records and proceed with Summary invoice"}
                    </button>
                    <button className={`btn btn-lg ${isIBC ? 'ibc-btn-primary' : 'btn-primary'}`} id="btnAnalyze"
                        onClick={e => reloadPage(e)}
                    // disabled={!!censusError}
                    >
                        {invalidMembers.length === 0
                            ? "Cancel" : "Let me correct invalid records and upload again"
                        }
                    </button>
                </div>
            </Grid>
        </div >
        {fileData.headers && members &&
            <MemberEditDialog editTable={editTable} setup={setup} runMemberValidation={runMemberValidation}
                headers={mappedKeys || fileData.headers} closeEditTable={closeEditTable} data={data} />
        }

    </>)
}

const mapStateToProps = state => {
    return {
        user: state.user,
        pageLoading: state.pages.loading,
        member: state.appConfig.member,
        memberLoading: state.appConfig.memberLoading,
        entity: state.appConfig.entity ? state.appConfig.entity : {}
    };
};
export default hot(withRouter(connect(mapStateToProps, null)(CensusValidations)));