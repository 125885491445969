import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { DataConnectService } from '../modules/api/index'
import { ActionTypes } from 'constants/index';

async function fetchAsync(func, arg) {
    const response = arg ? await func(arg) : await func();
    return response;
}

function* getMRAccountsById(action) {
    try {
        const response = yield fetchAsync(DataConnectService.getMRAccounts, action.payload);
        if(response.data.length === 0){
            yield put({ type: ActionTypes.SET_ACCOUNT_EMPTY, payload: true });
        }
        yield put({ type: ActionTypes.GET_ACCOUNTS_SUCCESS, payload: response });
    } catch (e) {
        yield put({ type: ActionTypes.GET_ACCOUNTS_FAILURE, error: e.message });
    }
}

export function* watchCappsSaga() {
    yield takeEvery(ActionTypes.GET_ACCOUNTS, getMRAccountsById);
}

export default watchCappsSaga;