import React from 'react';
import { Link } from 'react-router-dom';

const PrivilegeFailure = () => (
  <div style={{color: "white"}} align="center">
    <h1 >Error 401: You don't have enough privileges to access this page, please contact your administrator</h1>
    <Link to="/"><p style={{color: "white"}}>Back to home</p></Link>
  </div>
);

export default PrivilegeFailure;
