import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getBrandformfilter, getChannelfilter, getTSPData } from '../../../../../store/actions';
import { capitalize, getFileNameWithTimeStamp, getMonth } from '../../../../../utility/utils';
import { shortMonthLowercase } from '../../../mrplan/planingPages/planConfig';
import { setSalienceColumnBold } from '../../SpendPlan/Worksheet/sheetTableHelper';
import { getFiscalYearFormat } from '../../spendplanUtils';
import PlanHeader, { ActionType, FilterType } from '../PlanHeader';
import PlanningErrorModal from '../planningErrorModal';
import { PlanningLoading, convertToNumber, renderNoDataText, StyledTableCell, StyledTableRow, downloadFile } from '../planningHelperFunctions';
import { useStyles } from '../tpr';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const RLPActFcst = props => {
    const classes = useStyles();
    const { brand, selectScenario } = props;
    const [open, setOpen] = useState(false);
    const [alertMessageText, setAlertMessage] = useState('');
    const [brandForm, setBrandForm] = useState([])
    const [channel, setChannel] = useState([])
    const [downloadLoading, setDownloadLoading] = useState(false)
    const { tspData } = props;

    useEffect(() => {
        if (brand) {
            let { BrandName, FinancialYear, CurrentFF } = brand;
            if(!BrandName || !FinancialYear) return;
            let m = '' + getMonth(CurrentFF, 'monthNumber')
            m = m.length === 1 ? '0' + m : m;
            let getActionsPayload = {
                url: '/setupdata/rlp-act-fcst/' + `${BrandName}/${FinancialYear}/${m}/${selectScenario || '01'}`,
                appId: props.match.params.appid,
                dataUniqueId: 'GET_RLPActFcst'
            }
            props.getTSPData(getActionsPayload)
            !tspData.filterData?.brands?.length && props.getBrandformfilter({
                brandName: brand.BrandName,
                appid: props.match.params.appid,
            });
            !tspData.filterData?.channels?.length && props.getChannelfilter({
                brandFormName: '',
                formType: '',
                appid: props.match.params.appid,
            });
        }

    }, [brand])

    const error = props.tspData?.errorTSP ? props.tspData?.errorTSP['GET_RLPActFcst'] : null;

    useEffect(() => {
        if (error) {
            if (typeof error === 'string') {
                setAlertMessage({
                    title: 'RLP (Act + Fcst)',
                    p1: error,
                });
                setOpen(true);
            } else {
                setAlertMessage({
                    title: 'RLP (Act + Fcst)',
                    ...error,
                });
                setOpen(true);
            }
        }
    }, [error]);

    const isMonthForecast = (monthKey) => {
        if (props.brand?.CurrentFF && shortMonthLowercase.indexOf(monthKey) + 1 > shortMonthLowercase.indexOf((moment().month(props.brand.CurrentFF).format('MMM').toLowerCase()))) {
            return true;
        }
        return null
    }

    const getMonths = (isForeCast) => {
        return shortMonthLowercase.map((i) => {
            if (isForeCast) {
                if (!isMonthForecast(i)) {
                    return i;
                }
            } else {
                if (isMonthForecast(i)) {
                    return i;
                }
            }
        }).filter(Boolean)
    }

    const onFilterChange = (filterType, { value }) => {
        switch (filterType) {
            case FilterType.Channel:
                setChannel(value);
                break;
            case FilterType.Brandform:
                setBrandForm(value);
                break;
        }
    };

    const downloadButtonHandler = () => {
        let { BrandName, FinancialYear, CurrentFF } = brand;
        let m = '' + getMonth(CurrentFF, 'monthNumber')
        m = m.length === 1 ? '0' + m : m;
        let uri = API_BASE_URL + `/setupdata/rlp-act-fcst/download/${BrandName}/${FinancialYear}/${m}?`;
        if (brandForm?.length) {
            uri = `${uri}${`&brandform=${encodeURIComponent(JSON.stringify(brandForm))}`}`;
        }
        if (channel?.length) {
            uri = `${uri}&channel=${encodeURIComponent(JSON.stringify(channel))}`;
        }
        let fyFF = getFiscalYearFormat(brand.FinancialYear) + '-' + moment().month(brand.CurrentFF).format('MMM');

        downloadFile(uri, props.match.params.appid, getFileNameWithTimeStamp('RLP (Act + Fcst)' + '-' + brand.BrandName + '-' + fyFF), setDownloadLoading)
    };


    const handleClose = () => {
        setOpen(false);
        props?.removeSuccessMessage(null);
    };

    let loading = props.tspData['GET_RLPActFcst_loading']
    const data = (props.getData && props.getData['GET_RLPActFcst']) ? (props.getData['GET_RLPActFcst']?.data || []) : []
    const total = (props.getData && props.getData['GET_RLPActFcst']) ? (props.getData['GET_RLPActFcst']?.allChannelSum || []) : null
    const getData = () => {
        return data.filter(item => {
            return (brandForm?.length ? brandForm.includes(item.brandForm) : true) && (channel?.length ? channel.includes(item.channel) : true)
        }) || []
    }

    const onAction = (actionType) => {
        switch (actionType) {
            case ActionType.Download:
                downloadButtonHandler();
                break;
        }
    };

    return (
        <div className="spendplan-tpr">
            <PlanHeader
                {...props}
                buttons={['download']}
                onAction={onAction}
                onFilterChange={onFilterChange}
                filters={["brandForms", "channels"]}
                filterValues={{ brandForm, channel }}
                readOnly={true}
                newPlanDisabled={true}
                saveButtonState={false}
                dataSource={tspData}
                downloadLoading1={downloadLoading}
                selectBrand={brand}
                rowSelected={null}
                fieldData={[]}
            />
            {!loading ? (
                <div>
                    <TableContainer className={classes.tableContainer} component={Paper} style={{ height: 'calc(100vh - 265px)' }}>
                        <Table className={classNames(classes.table, 'tpr-table')} aria-label="simple table" stickyHeader>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell rowSpan={3}>
                                        <span>Brandform</span>
                                    </TableCell>
                                    <TableCell rowSpan={3}>
                                        <span>Channel</span>
                                    </TableCell>
                                    {
                                        [true, false].map(ii => {
                                            return Boolean(getMonths(ii).length) && getMonths(ii).map((month, index) => {
                                                return (<TableCell key={index} style={{ paddingTop: 5 }} className={classNames(classes.totalValue, classes.noBorder)}>
                                                    <span title={(total ? total[month] : 0)}>{total ? convertToNumber(total[month], 2).toLocaleString() : 0}</span>
                                                </TableCell>)
                                            })
                                        })
                                    }

                                </TableRow>
                                <TableRow>
                                    {
                                        Boolean(getMonths(true).length) &&
                                        <TableCell colSpan={getMonths(true).length} style={{ borderBottom: 'none' }}>
                                            <p className={classes.combinedHeaderHeading}>Actuals</p>
                                        </TableCell>
                                    }
                                    {
                                        Boolean(getMonths(false).length) &&
                                        <TableCell colSpan={getMonths(false).length} style={{ borderBottom: 'none' }}>
                                            <p className={classes.combinedHeaderHeading}>Forecast</p>
                                        </TableCell>
                                    }
                                </TableRow>
                                <TableRow style={{ height: 30, }}>
                                    {
                                        [true, false].map(ii => {
                                            return Boolean(getMonths(ii).length) && getMonths(ii).map((month, index) => {
                                                return (<TableCell key={index}>
                                                    <span>{capitalize(month)}</span>
                                                </TableCell>)
                                            })
                                        })
                                    }

                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {
                                    Boolean(data.length) ? getData().map((row, index) => {
                                        return (
                                            <StyledTableRow key={index} className={classes.cell}>
                                                <TableCell className={setSalienceColumnBold('rlpsalience', row['channel'], null, true)}>
                                                    <span>{row.brandForm}</span>
                                                </TableCell>
                                                <TableCell className={setSalienceColumnBold('rlpsalience', row['channel'], null, true)}>
                                                    <span>{row.channel}</span>
                                                </TableCell>
                                                {
                                                    [true, false].map(ii => {
                                                        return getMonths(ii).map((month, ii) => {
                                                            return <TableCell key={ii} className={setSalienceColumnBold('rlpsalience', row['channel'], row.months ? row.months[month] : 0, true)}
                                                                title={row.months ? row.months[month] : ''}>{row.months ? convertToNumber(row.months ? row.months[month] : 0, 1).toLocaleString('en-In') : ''}</TableCell>

                                                        })
                                                    })
                                                }

                                            </StyledTableRow>)

                                    }) :
                                        renderNoDataText()
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ) : (
                <PlanningLoading />
            )}

            <PlanningErrorModal open={open} handleClose={handleClose} alertMessageText={alertMessageText} />
        </div>
    );
};


const mapDispatchToProps = {
    getTSPData,
    getBrandformfilter,
    getChannelfilter,
}

const mapStateToProps = (state) => {
    return {
        getData: state.tprReducer?.getData,
        tspData: state.tprReducer,
        brand: state.tprReducer?.selectBrand,
        selectScenario: state.tprReducer?.selectScenario
    }
}


export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(RLPActFcst)));
