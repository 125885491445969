import { all, fork } from 'redux-saga/effects';

import app from './app';
import pages from './pages';
import login from './login';
import org from './org';
import theme from './theme';
import entities from './entities';
import form from './form';
import capps from './capps';
import extractionResult from './extractionResult';
import tradePriceReduction from './custom/spendplan/tradePriceReduction';
import talkSheetSaga from './custom/spendplan/talkSheet';
import consumerPromotion from './custom/spendplan/ConsumerPromotions';
import trialsDiscounts from './custom/spendplan/TrialsDiscounts';
import displays from './custom/spendplan/Displays';
import conversionFunds from './custom/spendplan/conversionFunds';
import MrAndEcommTFIs from './custom/spendplan/mrAndEcommTFIs';
import ReconReview from './custom/spendplan/ReconReview';
import ImarReports from './custom/spendplan/ImarReports';
import MrEcommSpendReconcilication from './custom/spendplan/mrEcommBudspendReconcilication';
import DataModel from './datamodel';
import MRPlan from './custom/mrplan/index';

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(app),
    fork(login),
    fork(org),
    fork(pages),
    fork(theme),
    fork(entities),
    fork(form),
    fork(capps),
    fork(extractionResult),
    fork(tradePriceReduction),
    fork(talkSheetSaga),
    fork(consumerPromotion),
    fork(trialsDiscounts),
    fork(displays),
    fork(conversionFunds),
    fork(MrAndEcommTFIs),
    fork(ReconReview),
    fork(ImarReports),
    fork(MrEcommSpendReconcilication),
    fork(DataModel),
    fork(MRPlan),
  ]);
}
