import React from 'react';
import JsonData from './JSONData';
import ResultTable from './ResultTable';

function Extractedinfo(props) {
  return (
    <div className="extraction-results-Extractedinfo" style={{ overflow: "scroll", width: "100%", height: "100%" }}>
      <h3>Extracted Info</h3>
      <div>
        {console.log("Extractedinfo", props.supportingDocument)}
        <ResultTable {...props} />
      </div>
    </div>
  );
}

export default Extractedinfo;
