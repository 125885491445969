import React from 'react';
import history from 'modules/history';

export function generatePieData(data, series) {
	// let { data } = element;
	let d = data && data.labels.map((l, i) => {
		return {
			name: l,
			y: data.datasets[0].data[i],
			// color: data.datasets[0].backgroundColor[i]
		}
	})
	series[0].name = data.datasets[0].label;
	series[0].data = d;
	series[0].colorByPoint = true;
	return series;
}

export function getDefaultPieChart() {
	return {
		series: [{
			name: 'Gases',
			colorByPoint: true,
			data: [
				{
					name: 'Argon',
					y: 0.9,
					color: '#3498db'
				},
				{
					name: 'Nitrogen',
					y: 78.1,
					color: '#9b59b6'
				},
				{
					name: 'Oxygen',
					y: 20.9,
					color: '#2ecc71'
				},
				{
					name: 'Trace Gases',
					y: 0.1,
					color: '#f1c40f'
				}
			]
		}]
	}
}

const getPageUrl = (selectedLinkValue, pages) => {
	let page = pages.find(p => {
		return p._id === selectedLinkValue
	})
	return page && page.url;
}

export function getPieChartConfig(series, renderTo, chartData, props) {
	return {
		chart: {
			type: 'pie',
			renderTo: renderTo,
      height: '50%',
    },
		title: {
			text: ''
		},
		plotOptions: {
			// series: {
			// 	dataLabels: {
			// 		enabled: true,
			// 		format: '{point.name}: {point.y:.1f}%'
			// 	}
			// },
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: true
				},
				showInLegend: false,
				events: {
					click: function (e) {
						console.log('====================================');
						console.log("index", event);
						console.log("click", event.point);
						console.log("click == e", e);
						console.log("click == props", props);
						console.log("click == chartData linkPie", chartData);
						console.log('====================================');
						if(props.onClickEvent){
							props.onClickEvent(e)
						}
						if (chartData.linkPie) {
							let { selectedLinkType, selectedLinkDataModel, selectedLinkValue } = chartData.linkPie;
							let url, search = '';
							if (selectedLinkType === "page") {
								let pageUrl = getPageUrl(selectedLinkValue, props.pages);
								if (pageUrl) {
									url = `/apps/${props.appId}/${pageUrl}`
									search += `?dm=${chartData.selectedDataModel}`
									search += `&k=${chartData.selectedDataLabels[0].value}`
									search += `&v=${event.point.name}`
								}
							} else {
								if (selectedDataModel) {
									url = `/apps/${props.appId}/form/${selectedLinkValue}/new`
									search += `?dm=${chartData.selectedDataModel}`
									search += `&k=${chartData.selectedDataLabels[0].value}`
									search += `&v=${event.point.name}`
								}
							}

							if (url) {
								search += `&pid=${props.page._id}&cid=${props._id}`

								history.push({
									pathname: url,
									search: search
								});
							}
							else
								alert(`Please provide valid links for column ${l.selectedLinkDataElement}`)
						}

						// alert(`clicked on arc ${event.point.x}, ${event.point.y}`);
					},
					dblclick: function (e) {
						console.log('====================================');
						console.log("dblclick", event.point);
						console.log("dblclick == e", e.point);
						console.log("click == chartData", chartData);
						console.log('====================================');
						// alert(`double clicked on arc ${event.point.x}, ${event.point.y}`);
					},
					contextmenu: function (e) {
						console.log('====================================');
						console.log("contextmenu", event.point);
						console.log("contextmenu == e", e.point);
						console.log("click == chartData", chartData);
						console.log('====================================');
						// alert(`right clicked on arc ${event.point.x}, ${event.point.y}`);
					}
				},
        size: '100%',
        // innerSize: '70%'
			}
		},
		credits: {
			enabled: false
		},
		series: series
	};
}

export function getDoughnutChartConfig(series, renderTo) {
	let pieConfig = getPieChartConfig(series, renderTo);
	pieConfig.plotOptions.pie.innerSize = '70%';
	return pieConfig;
}
