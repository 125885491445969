import { CircularProgress, Grid, Paper } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMrData } from '../../../../store/actions';
import { useStyles } from './Mytask';
import React from 'react';
import { URLs } from '../../mrplan/ApiConstants';
import { getMonth } from '../../../../utility/utils';
import { convertToNumber } from '../PlanningPages/planningHelperFunctions';


function MRStats(props) {
    const classes = useStyles();

    useEffect(() => {
        if (props.account) {
            let _currentFF = '' + getMonth(props.account?.CurrentFF, 'monthNumber')
            _currentFF = _currentFF.length === 1 ? '0' + _currentFF : _currentFF;
            let payloadForAction = {
                url: URLs.getMRStats + `?account=${encodeURIComponent(props.account?.AccountName)}&fiscalYear=${props.account?.FinancialYear}&currentFF=${_currentFF}`,
                appId: props.match.params.appid,
                dataUniqueId: 'GET_MR_STATS'
            }
            props.getMrData(payloadForAction)
        }

    }, [props.account])

    const loading = props.mrReducer?.['GET_MR_STATS_loading']

    const getMrStatsData = () => {
        if (props.mrData?.['GET_MR_STATS']) {
            return props.mrData['GET_MR_STATS']?.result
        }
    }

    let currentFFValues = getMrStatsData()?.currentFF

    const onHeaderClick = () => {
        props.history.push({
            pathname: `/apps/${props.match.params.appid}/Dashboard2`
        });
    }



    return (
        <Grid item xs={7}>
            <Paper className={classes.paperTable}>
                <div className={(classes.panel, classes.paneldefault)}>
                    <div className={classes.panelHeading}>
                        <div className={classes.headingStyle} onClick={onHeaderClick}
                            style={{ cursor: 'pointer', color: '#1976d2' }}>
                            <DescriptionIcon className={classes.svgIcon} />
                            {"Managerial Dashboard"}
                        </div>
                    </div>
                    {props?.isOmni ? <div className={classes.noContent}>No Action item</div> : (<Grid container spacing={2} style={{ height: 156 }}>
                        <Grid item xs={5} className={classes.heading}>

                            Retailing (Retailing IYA)
                        </Grid>
                        <Grid item xs={2} className={classes.heading}>
                            VSOD (%)
                        </Grid>
                        <Grid item xs={2} className={classes.heading}>
                            Spends %RLP
                        </Grid>
                        <Grid item xs={3} className={classes.heading}>
                            Spends to Earnings%
                        </Grid>
                        {loading ? <Grid item xs={12}>
                            <div className={classes.loader} style={{ height: 80 }}>
                                <CircularProgress size={30} />
                            </div></Grid> :
                            <>
                                <Grid item xs={5} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div className={classes.value} title={currentFFValues?.['Retailing'] || ''}> {convertToNumber(currentFFValues?.['Retailing'])}Cr.&nbsp;</div>
                                    <div className={classes.value} title={currentFFValues?.['Retailing IYA'] || ''}> ({convertToNumber(currentFFValues?.['Retailing IYA'])})</div>
                                </Grid>
                                <Grid item xs={2} className={classes.value} title={currentFFValues?.['VSOD'] * 100 || ''}>
                                    {convertToNumber(currentFFValues?.['VSOD'] * 100)}%</Grid>
                                <Grid item xs={2} className={classes.value} title={currentFFValues?.['SD Spends (%)'] * 100 || ''}>
                                    {convertToNumber(currentFFValues?.['SD Spends (%)'] * 100)}%</Grid>
                                <Grid item xs={3} className={classes.value} title={currentFFValues?.['SD Earnings(%)'] * 100 || ''}>
                                    {convertToNumber(currentFFValues?.['SD Earnings(%)'] * 100)}%</Grid>
                            </>
                        }

                    </Grid>)}
                </div>
            </Paper>
        </Grid >
    );
}

const mapDispatchToProps = {
    getMrData,
}

const mapStateToProps = (state) => {
    return {
        app: state.appConfig.app,
        account: state.mrReducer?.account,
        mrData: state.mrReducer?.data,
        mrReducer: state.mrReducer,
    }
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(MRStats)));
