import { put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import { ReconReviewService } from '../../../modules/api/reconReview';
import { toast } from 'react-toastify';

async function fetchAsync(func, arg) {
  const response = arg ? await func(arg) : await func();
  return response;
}

function* addReconReviewBaselineData(action) {
  try {
    const response = yield fetchAsync(ReconReviewService.addReconBaseLine, action.payload);
    toast.info('Recon Baseline added successfully.');  
    yield put({ type: ActionTypes.ADD_RECON_BASELINE_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: ActionTypes.ADD_RECON_BASELINE_FAILURE, error: err.message });
  }
}

function* getReconReviewData(action) {
  try {
    const response = yield fetchAsync(ReconReviewService.getReconData, action.payload);
    yield put({
      type: ActionTypes.GET_RECON_REVIEW_DATA_SUCCESS,
      payload: (response.result && response.result.data) ? response.result.data : [],
    });
  } catch (err) {
    yield put({ type: ActionTypes.GET_RECON_REVIEW_DATA_FAILURE, error: err?.response?.error || 'Something went wrong' });
  }
}

function* updateReconBaseLine(action) {
  try {
    const response = yield fetchAsync(ReconReviewService.updateReconLabel, action.payload);
    yield put({ type: ActionTypes.UPDATE_RECON_REVIEW_LABEL_SUCCESS, payload: response });

  } catch (err) {
    yield put({ type: ActionTypes.UPDATE_RECON_REVIEW_LABEL_FAILURE, error: err.message });
  }
}

function* removeReconReviewData(action) {
  try {
    const response = yield fetchAsync(ReconReviewService.removeReconData, action.payload);
    yield put({ type: ActionTypes.REMOVE_RECON_REVIEW_DATA_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: ActionTypes.REMOVE_RECON_REVIEW_DATA_FAILURE, error: err.message });
  }
}

function* removeReconReviewColumn(action) {
  try {
    const response = yield fetchAsync(ReconReviewService.removeReconColumn, action.payload);
    yield put({ type: ActionTypes.REMOVE_RECON_REVIEW_COLUMN_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: ActionTypes.REMOVE_RECON_REVIEW_COLUMN_FAILURE, error: err.message });
  }
}

export function* consumerPromotion() {
  yield takeLatest(ActionTypes.ADD_RECON_BASELINE, addReconReviewBaselineData);
  yield takeLatest(ActionTypes.GET_RECON_REVIEW_DATA, getReconReviewData);
  yield takeLatest(ActionTypes.UPDATE_RECON_REVIEW_LABEL, updateReconBaseLine);
  yield takeLatest(ActionTypes.REMOVE_RECON_REVIEW_DATA, removeReconReviewData);
  yield takeLatest(ActionTypes.REMOVE_RECON_REVIEW_COLUMN, removeReconReviewColumn);
}

export default consumerPromotion;
