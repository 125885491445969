import React, { Component } from 'react';
import {
	withStyles, Icon, Table, TableBody, TableCell, Input, List, ListItem, ListItemText,
	Button, TablePagination, TableRow, Checkbox, TextField, FormControlLabel, FormGroup, Typography, GridList, Select, GridListTile, InputLabel, MenuItem, FormControl
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import TableHead from './TableHead';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { CSVLink } from "react-csv";
import { Parser } from "json2csv";

import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import Popper from '@material-ui/core/Popper';
import FilterIcon from '@material-ui/icons/FilterList';
import _ from 'modules/lodash';
import history from 'modules/history';
import { AppService } from 'modules/api/index';

import {
	getFilteredArray,
	handleSelectedRow,
	isRowselected,
	requestSort,
	handleTableSelectAllClick,
	getTableRowStyles
} from 'modules/page/common';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = theme => ({
	root: {},
	paper: {
		border: '1px solid',
		padding: theme.spacing(1),
		backgroundColor: theme.palette.background.paper,
		maxHeight: "350px",
		overflowY: 'scroll',
		overflowX: 'hidden'
	},
	reset: {
		alignSelf: 'left',
	},
	resetLink: {
		marginLeft: '16px',
		fontSize: '12px',
		cursor: 'pointer',
	}
});

class CustomTable extends Component {
	state = {
		order: 'asc',
		orderBy: null,
		selected: [],
		data: [["Loading Data..."]],
		page: 0,
		count: 10,
		rowsPerPage: 10,
		searchText: "",
		isSearchIconHide: false,
		anchorEl: null,
		anchorEl2: null,
		anchorEl3: null,
	};

	componentWillUnmount() {
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState) {
	}

	objectByStringKey = (o, s) => {
		s = s.replace(/\[(\w+)\]/g, '.$1');
		s = s.replace(/^\./, '');
		var a = s.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			var k = a[i];
			if (k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		return o;
	}

	handleSearchClick = () => {
		this.setState({ isSearchIconHide: !this.state.isSearchIconHide }, () => { if (!this.state.isSearchIconHide) this.props.searchHandler() });
	}

	handleColumnPopperClick = (event) => {
		this.setState({ anchorEl: this.state.anchorEl ? null : event.currentTarget });
	}
	handleFilterPopperClick = (event) => {
		this.setState({ anchorEl2: this.state.anchorEl2 ? null : event.currentTarget });
	}
	handleExportPopperClick = (event) => {
		this.setState({ anchorEl3: this.state.anchorEl3 ? null : event.currentTarget });
	}

	generateTableRows = (data, orderBy, order) => {
		let toReturn = _.orderBy(data, [
			(o) => {
				switch (orderBy) {
					default:
						{
							return o[orderBy];
						}
				}
			}
		], [order]);
		const { page, rowsPerPage } = this.props;
		if (rowsPerPage > 0) {
			toReturn = toReturn.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		}
		return toReturn;
	}

	renderSelect(column, index) {
		const { filterList, filterData, handleDropdownChange } = this.props;
		return (
			<GridListTile key={index} cols={2}>
				<FormControl key={index} fullWidth>
					<InputLabel htmlFor={column.name}>{column.label}</InputLabel>
					<Select
						fullWidth
						value={filterList[index] ? filterList[index] : "All"}
						name={column.name}
						onChange={event => handleDropdownChange(event, index, column)}
						input={<Input name={column.name} id={column.name} />}>
						<MenuItem value={"All"} key={0}>
							All
						</MenuItem>
						{filterData.length > 0 && filterData[index].map((filterValue, filterIndex) => (
							<MenuItem value={filterValue} key={filterIndex + 1}>
								{filterValue}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</GridListTile>
		);
	}


	render() {
		console.log("this.props.chartData1=====>", this.props);
		const open = Boolean(this.state.anchorEl);
		const id = open ? 'simple-popper' : undefined;

		const open2 = Boolean(this.state.anchorEl2);
		const id2 = open2 ? 'simple-popper-1' : undefined;

		const open3 = Boolean(this.state.anchorEl3);
		const id3 = open3 ? 'simple-popper-2' : undefined;

		const { order, orderBy, selected, rowsPerPage, count, page, filterTableData, classes, exportArray, headerstyle, subHeaderstyle, rowStyle, isTableSubHeader } = this.props;
		let { selectedDataPages, selectedDataColumns, links,
			extendedTablePage, associatedFormName, name } = this.props.chartData;
		let columnsArray = filterTableData && filterTableData.columns && filterTableData.columns.map((item) => item.name);
		return (<>
			<div className="pt-10 float-left">
				<span className="pl-20 text-color-white" style={{fontSize: "1.8em"}}><b>{name}</b></span>
				{associatedFormName &&
					<Button onClick={this.handleAddClick} className="btn-new-btn"
						variant="contained" color="primary">
						+ NEW
				</Button>}
			</div>
			<div className="pt-10 float-right">
				{this.props.isSearch && <>
					{console.log("this.state.isSearchIconHide", this.state.isSearchIconHide)}
					{this.state.isSearchIconHide &&
						<div style={{ backgroundColor: "#FFF", display: "inline-block", position: "relative", top: "-18px" }}>
							<TextField id="standard-basic" label="Search" value={this.props.searchText} onChange={this.props.searchHandler} />
						</div>}
					{this.state.isSearchIconHide ? <CloseIcon className="text-color-white" onClick={this.handleSearchClick} /> : <SearchIcon className="text-color-white" onClick={this.handleSearchClick} />}
				</>
				}
				{
					this.props.isExport && <div className="text-color-white d-inline-block">
						<CloudDownloadIcon onClick={this.handleExportPopperClick} />
						<Popper id={id3} open={open3} anchorEl={this.state.anchorEl3}>
							<div className={classes.paper}>
								<List component="nav">
									{exportArray.map((exp, index) => {
										return exp.isVisible && <>
											{exp.key === "csv" || exp.key === "txt" || exp.key === "xls" ? (<ListItem button><CSVLink
												className="text-decoration text-color-white"
												data={columnsArray && new Parser({ columnsArray }).parse(filterTableData.datasets)}
												filename={name + "." + exp.key}><ListItemText primary={exp.name} /></CSVLink></ListItem>)
												: (<ListItem
													button
													onClick={() => this.props.handleExoprtItemClick(exp, index)}>
													<ListItemText primary={exp.name} />
												</ListItem>)}
										</>
									})}
								</List>
							</div>
						</Popper>
					</div>
				}
				{this.props.isViewCoulmns && <div className="text-color-white d-inline-block">
					<ViewColumnIcon onClick={this.handleColumnPopperClick} />
					<Popper id={id} open={open} anchorEl={this.state.anchorEl}>
						<div className={classes.paper}>
							<FormGroup>
								{filterTableData.columns && filterTableData.columns.map((item, i) => {
									return <FormControlLabel key={i}
										control={
											<Checkbox
												checked={item.checked}
												onChange={this.props.handleInputChange}
												name={item.name}
												color="primary"
											/>
										}
										label={item.label}
										value={item.name}
									/>
								})
								}
							</FormGroup>
						</div>
					</Popper>
				</div>}
				{
					this.props.isFilter && <div className="text-color-white d-inline-block">
						<FilterIcon onClick={this.handleFilterPopperClick} />
						<Popper id={id2} open={open2} anchorEl={this.state.anchorEl2} >
							<div className={classes.paper}>
								<div className={classes.reset}>
									<Typography variant="body2" className="d-inline-block">Filters</Typography>
									<Button className="float-right"
										className={classes.resetLink}
										color="primary"
										onClick={this.props.resetFilters}>
										Reset
									</Button>
								</div>
								<GridList cellHeight="auto" cols={2} spacing={34} style={{ marginTop: "13px" }} >
									{filterTableData.columns && filterTableData.columns.map((column, index) => {
										return this.renderSelect(column, index);
									})}
								</GridList>
							</div>
						</Popper>
					</div>
				}
			</div>
			<div className="w-full flex flex-col mt-10 mb-20">
				<Table className="min-w-xl" aria-labelledby="tableTitle">
					{this.props.isTableHead && <TableHead
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						isTableSubHeader={isTableSubHeader}
						headerstyle={headerstyle}
						subHeaderstyle={subHeaderstyle}
						onSelectAllClick={this.props.handleSelectAllClick}
						onRequestSort={this.props.handleRequestSort}
						rowCount={filterTableData.datasets ? filterTableData.datasets.length : 0}
						data={filterTableData}
						chartData={this.props.chartData}
						hasCheckbox={true}
					/>}

					<TableBody>
						{this.generateTableRows(filterTableData.datasets, orderBy, order)
							.map((n, i) => {
								const isSelected = isRowselected(n._id, selected);
								return (
									<TableRow
										className="h-64 cursor-pointer"
										style={getTableRowStyles(rowStyle, ['backgroundColor'], i)}
										hover
										role="checkbox"
										aria-checked={isSelected}
										tabIndex={-1}
										key={n._id}
										selected={isSelected}
										onClick={event => this.props.onRowClick(n, i)}
									>
										{this.props.hasCheckbox
											&& <TableCell className="w-48 pl-4 sm:pl-12" padding="checkbox">
												<Checkbox
													style={getTableRowStyles(rowStyle, ['color'], i)}
													checked={isSelected}
													onClick={event => event.stopPropagation()}
													onChange={event => this.props.handleCheck(event, n._id)}
												/>
											</TableCell>}
										{filterTableData && filterTableData.columns && filterTableData.columns.length > 0 &&
											filterTableData.columns.map(c => {
												if (c.checked) {
													return (<TableCell
														component="td" scope="row"
														style={getTableRowStyles(rowStyle, ['backgroundColor', 'color', 'fontSize'], i)}
														onClick={event => this.onCellClick(n, c)}>
														{c.name === "workflow" && n.workflow ? <Button variant="contained">{n.workflow.actions}</Button> : this.objectByStringKey(n, c.name)}
													</TableCell>)
												}
											})
										}
										<TableCell>
											{this.props.actions.edit && <EditIcon style={getTableRowStyles(rowStyle, ['color'], i)} onClick={() => this.props.tableCrudOperations(n, "edit")} />}
											{this.props.actions.view && <VisibilityIcon style={getTableRowStyles(rowStyle, ['color'], i)} onClick={() => this.props.tableCrudOperations(n, "view")} />}
											{this.props.actions.delete && <DeleteIcon style={getTableRowStyles(rowStyle, ['color'], i)} onClick={() => this.props.tableCrudOperations(n, "delete")} />}
											{this.props.actions.copy && <FileCopyIcon style={getTableRowStyles(rowStyle, ['color'], i)} onClick={() => this.props.tableCrudOperations(n, "copy")} />}
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
				{this.props.isTablePagination &&
					<TablePagination
						component="div"
						count={count ? count : 0}
						className="text-color-white"
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page',
							'marginTop': '-18px'
						}}
						onChangePage={this.props.handleChangePage}
						onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
					/>
				}

			</div>
		</>);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

const mapStateToProps = state => {
	return {
		pages: state.pages.page,
	};
};

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(CustomTable));