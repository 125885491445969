import { put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import { MrEcommSpendReconciliationService } from '../../../modules/api';

async function fetchAsync(func, arg) {
  return arg ? await func(arg) : await func();
}

function* fetchMrEcommSpendReconciliation(action) {
  try {
    const response = yield fetchAsync(MrEcommSpendReconciliationService.getmrEcommReconciliationData, action.payload);
    yield put({
      type: ActionTypes.GET_MRECOMMSPENDRECONCILIATION_DATA_SUCCESS,
      payload: (response && response.result) ? response.result.data : [],
    });
  } catch (err) {
    yield put({ type: ActionTypes.GET_MRECOMMSPENDRECONCILIATION_DATA_FAILURE, error: err?.response?.error || 'Something went wrong' });
  }
}

function* fetchCommonReportData(action) {
  try {
    const response = yield fetchAsync(MrEcommSpendReconciliationService.fetchCommonReportData, action.payload);
    yield put({
      type: ActionTypes.GET_COMMON_REPORT_DATA_SUCCESS,
      payload: (response && response.result) ? response.result.data : [],
    });
  } catch (err) {
    yield put({ type: ActionTypes.GET_COMMON_REPORT_DATA_FAILURE, error: err?.response?.error || 'Something went wrong' });
  }
}

export function* mrEcommBudSpendReconciliation() {
  yield takeLatest(ActionTypes.GET_MRECOMMSPENDRECONCILIATION_DATA, fetchMrEcommSpendReconciliation);
  yield takeLatest(ActionTypes.GET_COMMON_REPORT_DATA, fetchCommonReportData);

}

export default mrEcommBudSpendReconciliation;
