import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import history from "../../modules/history";

const useStyles = makeStyles(theme => ({
  errorWrap: {
    textAlign: 'center',
    '& h4': {
      color: '#000',
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
export const ErrorFallBackComponent = () => {

  const classes = useStyles();
  return (
    <div className={classes.errorWrap}>
      <h4>Something went wrong!</h4>
      <p>Sorry, the page you were looking for has some problem.</p>
      {/* <Button
        variant="contained"
        size="medium"
        onClick={() => history.goBack()}
      >
        Go Back
      </Button> */}
    </div>);
};
