import React from 'react';
import {
	Button, Grid, FormLabel, Table, TableBody, TableCell, TableHead,
	TableRow, TableContainer, TablePagination
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import './table.scss'

const BasicRulesTable = (props) => {
	let { type, pInd, dataModels } = props;
	let tableHead = ["Data Elements", "Data Elements", "Result", "Actions"]
	if (props.values[type]
		&& Array.isArray(props.values[type])) {
		return (<>{type === "pairs" &&
			<TableContainer><Table className="min-w-xl" aria-labelledby="tableTitle" size="small">
				<TableHead>
					<TableRow>
						{tableHead.map((item, i) => {
							return <TableCell key={i}>{item}</TableCell>
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{props.values[type].map((o, index) => {
						return (!o.editMode && <TableRow key={index}>
							<TableCell>
								{o.ruleLeftDE}
							</TableCell>
							<TableCell>
								{o.ruleRightDE}
							</TableCell>
							<TableCell>
								{o.result ? o.result
									: `${dataModels[0].name}.${o.ruleLeftDE} Mismatch ${dataModels[1].name}.${o.ruleRightDE}`
								}
							</TableCell>
							<TableCell>
								<Button onClick={() => {
									props.editBasicRule(o, index, pInd, type);
								}} ><EditIcon /></Button>
								<Button onClick={() => {
									props.deleteBasicRule(o, index, pInd, type);
								}} ><DeleteIcon /></Button>
							</TableCell>
						</TableRow>)
					})}
				</TableBody>
			</Table></TableContainer>}
			{type === "keys" && props.values[type].map((o, index) => {
				if (!o.editMode)
					return (
						<Grid container key={index} xs={12}>
							<Grid style={{ "padding": "10px" }} xs={3}>
								<FormLabel >{o.ruleLeftDE}</FormLabel>
							</Grid>
							<Grid style={{ "padding": "10px" }} xs={3}>
								<FormLabel >{o.ruleRightDE}</FormLabel>
							</Grid>
							<Grid style={{ "padding": "10px" }} xs={2}>
								<Button variant="contained" onClick={() => {
									props.editBasicRule(o, index, pInd, type);
								}} ><EditIcon /></Button>
								<Button variant="contained" onClick={() => {
									props.deleteBasicRule(o, index, pInd, type);
								}} ><DeleteIcon /></Button>
							</Grid>
						</Grid>
					)
				else return ''
			})}
		</>)
	} else return ''
}
export default BasicRulesTable;