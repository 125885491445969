/**
 * @module Sagas/Login
 * @desc App
 */
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import { AppService } from 'modules/api/index'
import { ActionTypes } from 'constants/index';
import jwtService from 'jwtService';

/**
 * Application
 *
 * @param {Object} action
 *
 */

async function fetchAsync(func, arg) {
    const response = await func(arg);
    return response;
}

function* fetchAppLogin(action) {
    try {
        const user = yield call(fetchAsync, jwtService.signInWithEmailAndPassword, action.payload);
        yield put({ type: ActionTypes.LOGIN_SUCCESS });
        yield put({ type: ActionTypes.SET_USER_DATA, payload: user });
    } catch (e) {
        yield put({ type: ActionTypes.LOGIN_ERROR, error: e });
    }
}

function* signInWithPingToken(action) {
    try {
        const user = yield call(fetchAsync, jwtService.signInWithPingToken, action.payload);
        yield put({ type: ActionTypes.LOGIN_SUCCESS });
        yield put({ type: ActionTypes.SET_USER_DATA, payload: user });
    } catch (e) {
        yield put({ type: ActionTypes.LOGIN_ERROR, error: e });
    }
}

function* resetPassword(action) {
    try {
        const user = yield call(fetchAsync, jwtService.resetPassword, action.payload);
        yield put({ type: ActionTypes.RESET_PASSWORD_SUCCESS, payload: user });
    } catch (e) {
        yield put({ type: ActionTypes.RESET_PASSWORD_ERROR, error: e.message });
    }
}

function* isUserExist(action) {
    try {
        const userExist = yield call(fetchAsync, jwtService.isUserExist, action.payload);
        yield put({ type: ActionTypes.IS_USER_EXIST_SUCCESS, payload: userExist });
    } catch (e) {
        yield put({ type: ActionTypes.IS_USER_EXIST_FAILURE, error: e });
    }
}

export function* watchLoginSaga() {
    yield takeEvery(ActionTypes.LOGIN_LOADING, fetchAppLogin);
    yield takeEvery(ActionTypes.RESET_PASSWORD_LOADING, resetPassword);
    yield takeEvery(ActionTypes.SIGN_IN_WITH_PING_TOKEN, signInWithPingToken);
    yield takeEvery(ActionTypes.IS_USER_EXIST, isUserExist);
}

export default watchLoginSaga;
