const labels = {
    currentFF: 'Current FF',
    changeFF: 'Change vs. Previous FF',
    previousFF: 'Previous FF',
    fyActual: 'Past Year',
}

const retailingObj = (key) => [
    { key: key + '.retailing.FY', title: 'FY Retailing', collapse: true, divideByCr: true, showInFilter: true, label: labels[key], uom: 'INR Cr', mainKey: 'retailing' },
    { key: key + '.retailing.JAS', title: 'JAS', divideByCr: true, parentKey: key + '.retailing.FY', label: labels[key], uom: 'INR Cr' },
    { key: key + '.retailing.OND', title: 'OND', divideByCr: true, parentKey: key + '.retailing.FY', label: labels[key], uom: 'INR Cr' },
    { key: key + '.retailing.JFM', title: 'JFM', divideByCr: true, parentKey: key + '.retailing.FY', label: labels[key], uom: 'INR Cr' },
    { key: key + '.retailing.AMJ', title: 'AMJ', divideByCr: true, parentKey: key + '.retailing.FY', label: labels[key], uom: 'INR Cr' },
]

const retailingIYAObj = (key) => [
    { key: key + '.retailingIya.JAS', title: 'FY Retailing IYA', collapse: true, showInFilter: true, label: labels[key], uom: 'IYA', mainKey: 'retailingIya'  },
    { key: key + '.retailingIya.JAS', title: 'JAS', parentKey: key + '.retailingIya.JAS', label: labels[key], uom: 'IYA' },
    { key: key + '.retailingIya.OND', title: 'OND', parentKey: key + '.retailingIya.JAS', label: labels[key], uom: 'IYA' },
    { key: key + '.retailingIya.JFM', title: 'JFM', parentKey: key + '.retailingIya.JAS', label: labels[key], uom: 'IYA' },
    { key: key + '.retailingIya.AMJ', title: 'AMJ', parentKey: key + '.retailingIya.JAS', label: labels[key], uom: 'IYA' },
]

const jasTargetObj = (key) => [
    { key: key, title: "Target", collapse: true, showInFilter: true, label: labels[key], uom: 'INR Cr', mainKey: 'target' },
    { key: key, title: 'JAS', parentKey: key, label: labels[key], uom: 'INR Cr' },
    { key: key, title: 'OND', parentKey: key, label: labels[key], uom: 'INR Cr' },
    { key: key, title: 'JFM', parentKey: key, label: labels[key], uom: 'INR Cr' },
    { key: key, title: 'AMJ', parentKey: key, label: labels[key], uom: 'INR Cr' },
]

const sdEObj = (key) => [
    { key: key + '.sdEffectiveness.FY', title: 'FY SDe', collapse: true, showInFilter: true, label: labels[key], uom: '#', mainKey: 'sdEffectiveness' },
    { key: key + '.sdEffectiveness.JAS', title: 'JAS', parentKey: key + '.sdEffectiveness.FY', label: labels[key], uom: '#' },
    { key: key + '.sdEffectiveness.OND', title: 'OND', parentKey: key + '.sdEffectiveness.FY', label: labels[key], uom: '#' },
    { key: key + '.sdEffectiveness.JFM', title: 'JFM', parentKey: key + '.sdEffectiveness.FY', label: labels[key], uom: '#' },
    { key: key + '.sdEffectiveness.AMJ', title: 'AMJ', parentKey: key + '.sdEffectiveness.FY', label: labels[key], uom: '#' },
]

const sdSpendPercRLPObj = (key) => [
    { key: key + '.sdSpendsPercRlp.FY', title: 'FY SD Spends', collapse: true, isPercent: true, showInFilter: true, label: labels[key], uom: "% RLP", mainKey: 'sdSpendsPercRlp' },
    { key: key + '.sdSpendsPercRlp.JAS', title: 'JAS', isPercent: true, parentKey: key + '.sdSpendsPercRlp.FY', label: labels[key], uom: "% RLP" },
    { key: key + '.sdSpendsPercRlp.OND', title: 'OND', isPercent: true, parentKey: key + '.sdSpendsPercRlp.FY', label: labels[key], uom: "% RLP" },
    { key: key + '.sdSpendsPercRlp.JFM', title: 'JFM', isPercent: true, parentKey: key + '.sdSpendsPercRlp.FY', label: labels[key], uom: "% RLP" },
    { key: key + '.sdSpendsPercRlp.AMJ', title: 'AMJ', isPercent: true, parentKey: key + '.sdSpendsPercRlp.FY', label: labels[key], uom: "% RLP" },

]

const sdSpendsInrObj = (key) => [
    { key: key + '.sdSpendsInr.FY', title: 'FY SD Spends', collapse: true, divideByCr: true, showInFilter: true, label: labels[key], uom: 'INR Cr', mainKey: 'sdSpendsInr' },
    { key: key + '.sdSpendsInr.JAS', title: 'JAS', divideByCr: true, parentKey: key + '.sdSpendsInr.FY', label: labels[key], uom: 'INR Cr' },
    { key: key + '.sdSpendsInr.OND', title: 'OND', divideByCr: true, parentKey: key + '.sdSpendsInr.FY', label: labels[key], uom: 'INR Cr' },
    { key: key + '.sdSpendsInr.JFM', title: 'JFM', divideByCr: true, parentKey: key + '.sdSpendsInr.FY', label: labels[key], uom: 'INR Cr' },
    { key: key + '.sdSpendsInr.AMJ', title: 'AMJ', divideByCr: true, parentKey: key + '.sdSpendsInr.FY', label: labels[key], uom: 'INR Cr' },

]
const sDEarningsPercRlpObj = (key) => [
    { key: key + '.sDEarningsPercRlp.OND', title: 'FY SD Earnings', collapse: true, isPercent: true, showInFilter: true, label: labels[key], uom: '% RLP', mainKey: 'sDEarningsPercRlp' },
    { key: key + '.sDEarningsPercRlp.JAS', title: 'JAS', isPercent: true, parentKey: key + '.sDEarningsPercRlp.OND', label: labels[key], uom: '% RLP' },
    { key: key + '.sDEarningsPercRlp.OND', title: 'OND', isPercent: true, parentKey: key + '.sDEarningsPercRlp.OND', label: labels[key], uom: '% RLP' },
    { key: key + '.sDEarningsPercRlp.JFM', title: 'JFM', isPercent: true, parentKey: key + '.sDEarningsPercRlp.OND', label: labels[key], uom: '% RLP' },
    { key: key + '.sDEarningsPercRlp.AMJ', title: 'AMJ', isPercent: true, parentKey: key + '.sDEarningsPercRlp.OND', label: labels[key], uom: '% RLP' },

]
const sDEarningsInrObj = (key) => [
    { key: key + '.sDEarningsInr.FY', title: 'FY SD Earnings', collapse: true, divideByCr: true, showInFilter: true, label: labels[key], uom: 'INR Cr', mainKey: 'sDEarningsInr' },
    { key: key + '.sDEarningsInr.JAS', title: 'JAS', divideByCr: true, parentKey: key + '.sDEarningsInr.FY', label: labels[key], uom: 'INR Cr' },
    { key: key + '.sDEarningsInr.OND', title: 'OND', divideByCr: true, parentKey: key + '.sDEarningsInr.FY', label: labels[key], uom: 'INR Cr' },
    { key: key + '.sDEarningsInr.JFM', title: 'JFM', divideByCr: true, parentKey: key + '.sDEarningsInr.FY', label: labels[key], uom: 'INR Cr' },
    { key: key + '.sDEarningsInr.AMJ', title: 'AMJ', divideByCr: true, parentKey: key + '.sDEarningsInr.FY', label: labels[key], uom: 'INR Cr' },
]

export const currentFFCols = () => [
    { key: 'currentFF.opNos.FY', title: 'FY OP NOS', divideByCr: true, showInFilter: true, label: 'Current FF', colSpan: 1, uom: 'INR Cr', mainKey: 'opNos' },
    { key: 'currentFF.opNosIya.FY', title: "FY Op NOS IYA", showInFilter: true, label: 'Current FF', colSpan: 1, uom: 'IYA', mainKey: 'opNosIya'  },
    ...jasTargetObj('currentFF'),
    ...retailingObj('currentFF'),
    ...retailingIYAObj('currentFF'),
    ...sdEObj('currentFF'),
    ...sdSpendPercRLPObj('currentFF'),
    ...sdSpendsInrObj('currentFF'),
    ...sDEarningsPercRlpObj('currentFF'),
    ...sDEarningsInrObj('currentFF'),
]


export const changeVsPrevCols = () => [
    { key: 'changeFF.opNos.FY', title: 'FY OP NOS', divideByCr: true, showInFilter: true, label: 'Change vs. Previous FF', colSpan: 1, uom: 'INR Cr', mainKey: 'opNos' },
    { key: 'changeFF.opNosIya.FY', title: "FY Op NOS IYA", showInFilter: true, label: 'Change vs. Previous FF', colSpan: 1, uom: 'IYA', mainKey: 'opNosIya'  },
    ...jasTargetObj('changeFF'),
    ...retailingObj('changeFF'),
    ...retailingIYAObj('changeFF'),
    ...sdEObj('changeFF'),
    ...sdSpendPercRLPObj('changeFF'),
    ...sdSpendsInrObj('changeFF'),
    ...sDEarningsPercRlpObj('changeFF'),
    ...sDEarningsInrObj('changeFF'),
]

export const previousCols = () => [
    { key: 'previousFF.opNos.FY', title: 'FY OP NOS', divideByCr: true, showInFilter: true, label: 'Previous FF', colSpan: 1, uom: 'INR Cr', mainKey: 'opNos' },
    { key: 'previousFF.opNosIya.FY', title: "FY Op NOS IYA", showInFilter: true, label: 'Previous FF', colSpan: 1, uom: 'IYA', mainKey: 'opNosIya'  },
    ...jasTargetObj('previousFF'),
    ...retailingObj('previousFF'),
    ...retailingIYAObj('previousFF'),
    ...sdEObj('previousFF'),
    ...sdSpendPercRLPObj('previousFF'),
    ...sdSpendsInrObj('previousFF'),
    ...sDEarningsPercRlpObj('previousFF'),
    ...sDEarningsInrObj('previousFF'),

]

export const lastYearCols = () => [
    { key: 'fyActual.opNos.FY', title: 'FY OP NOS', divideByCr: true, showInFilter: true, label: 'Past Year', colSpan: 1, uom: 'INR Cr', mainKey: 'opNos' },
    { key: 'fyActual.opNosIya.FY', title: "FY Op NOS IYA", showInFilter: true, label: 'Past Year', colSpan: 1, uom: 'IYA', mainKey: 'opNosIya' },
    ...jasTargetObj('fyActual'),
    ...retailingObj('fyActual'),
    ...retailingIYAObj('fyActual'),
    ...sdEObj('fyActual'),
    ...sdSpendPercRLPObj('fyActual'),
    ...sdSpendsInrObj('fyActual'),
    ...sDEarningsPercRlpObj('fyActual'),
    ...sDEarningsInrObj('fyActual'),
]

export const columnTypes = [
    { key: 'opNos', title: 'FY OP NOS (INR Cr)', isActive: true, },
    { key: 'opNosIya', title: 'FY Op NOS IYA (IYA)', isActive: true, },

    { key: 'target', title: 'Target (INR Cr)', isActive: true }, // colSpan 5
    { key: 'retailing', title: 'FY Retailing (INR Cr)', isActive: true },
    { key: 'retailingIya', title: 'FY Retailing IYA (IYA)', isActive: true },
    { key: 'sdEffectiveness', title: 'FY SDe (#)', isActive: true },

    { key: 'sdSpendsPercRlp', title: 'FY SD Spends (% RLP)', isActive: true },
    { key: 'sdSpendsInr', title: 'FY SD Spends (INR Cr)', isActive: true },
    { key: 'sDEarningsPercRlp', title: 'FY SD Earnings (% RLP)', isActive: true },
    { key: 'sDEarningsInr', title: 'FY SD Earnings (INR Cr)', isActive: true },
]